import { MaskitoOptions, maskitoUpdateElement } from '@maskito/core';
import { maskitoEventHandler, maskitoWithPlaceholder, maskitoPrefixPostprocessorGenerator } from '@maskito/kit';
import { numbersOnly, phoneMaskExtended, phoneMaskShortened, phoneNumberExtensionMaskPlaceholder } from 'src/app/constants/validators';

let useExtension = true;
let useExtraBlank = true;
let extension = phoneNumberExtensionMaskPlaceholder;

let shortPhonePlaceholder = useExtraBlank ? `+ (____)-___-____` : `+ (____)-___-____`;
let extendedPhonePlaceholder = shortPhonePlaceholder + extension;

let shortPhoneMask = phoneMaskShortened;
let extendedPhoneMask = phoneMaskExtended;

let placeholderToShow = useExtension ? extendedPhonePlaceholder : shortPhonePlaceholder;
let maskToUse = useExtension ? extendedPhoneMask : shortPhoneMask;

const {
  plugins,
  removePlaceholder,
  ...placeholderOptions
} = maskitoWithPlaceholder(placeholderToShow);

// const removeExtension = (value) => {
//   let obj = { val: value };
//   let newVal = obj.val;
//   if (newVal?.includes(extension)) newVal = newVal.slice(0, 17);
//   if (newVal?.includes(extension)) newVal = newVal.replace(extension, ``);
//   return newVal;
// }

export const phoneMaskitoMask = {
  mask: maskToUse,
  preprocessors: placeholderOptions.preprocessors,
  postprocessors: [maskitoPrefixPostprocessorGenerator(`+1`), ...placeholderOptions.postprocessors],
  plugins: [
    ...plugins,

    maskitoEventHandler(`focus`, element => {
      const initialValue = element?.value || `+1 (`;
      maskitoUpdateElement(element, initialValue + placeholderToShow.slice(initialValue.length));
    }),

    maskitoEventHandler(`input`, element => {
      const initialValue = element?.value || `+1 (`;
      const currentLength = numbersOnly(element?.value)?.length || numbersOnly(initialValue)?.length;
      if (currentLength > 11 && currentLength < 12) {
        let cleanValue = removePlaceholder(element?.value);
        maskitoUpdateElement(element, cleanValue);      
      }
      // else if (currentLength > 1 && currentLength <= 11) {
      //   let cleanValue = removePlaceholder(removeExtension(element?.value));
      //   maskitoUpdateElement(element, cleanValue);
      // }
    }),

    maskitoEventHandler(`blur`, element => {
      const cleanValue = removePlaceholder(element?.value);
      maskitoUpdateElement(element, cleanValue === `+1` ? `` : cleanValue);
    }),
  ],
} as MaskitoOptions;