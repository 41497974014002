import { Injectable } from '@angular/core';
import { WebserviceURL } from 'src/app/constants/webservice';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: ''
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  constructor(private http: HttpClient) { }

  getData(gatewayId) {
    return this.http.put(
      WebserviceURL + 'gateway/test_run',
      {
        gateway_id: gatewayId,
      },
      httpOptions
    );
  }
}
