import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'maint-refrigerant-notification',
  templateUrl: './maint-refrigerant-notification.component.html',
  styleUrls: ['./maint-refrigerant-notification.component.scss'],
})
export class MaintRefrigerantNotificationComponent implements OnInit {

  constructor(private modalController: ModalController) { }
  ngOnInit() { 
    // empty
  }

  close(isCheckStart: boolean) {

    this.modalController.dismiss(
      {
        "isCheckStart": isCheckStart
      }
    );
  }
}
