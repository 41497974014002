<ion-app>
  <ion-header id="main-header" main-header>
    <ion-toolbar color="dark">
      <ion-buttons slot="start">
        <ion-menu-button menu="side-menu" id="side-menu"></ion-menu-button>
      </ion-buttons>

      <ion-item id="headerTopStartContent" class="headerTopStartContent automationTesting" lines="none" color="dark" style="white-space: nowrap; min-width: max-content;">

        <!-- OLD LOGO -->
        <!-- <div (click)="handleMenuItemSelection(kCloudMenuIds.Home,kCloudMenuIds.NoSelection)"
          logo-grouping>
          <ion-text color="primary" title-logo-kenza id="kenzaCloudLogoKenza" name="kenzaCloudLogoKenza"><span translate='no'>kenza</span>
          </ion-text>
          <ion-text id="kenzaCloudLogoCloud" name="kenzaCloudLogoCloud" title-logo-cloud> cloud</ion-text>
          <ion-text id="kenzaCloudLogoTM" name="kenzaCloudLogoTM" title-logo-tm>TM</ion-text>
          <img id="kenzaCloudLogo" name="kenzaCloudLogo" title-logo src="../assets/imgs/logo.svg">
        </div> -->

        <!-- NEW LOGO -->
        <div id="kenzaCloudLogoHomeLink" class="kenzaCloudLogoHomeLink automationTesting" [routerLink]="user.signedIn ? ['/home'] : ['/signin']" (click)="handleMenuItemSelection(kCloudMenuIds.Home,kCloudMenuIds.NoSelection)" logo-grouping-img>
          <img id="kenzaCloudLogo" name="kenzaCloudLogo" title-logo-image src="/assets/imgs/kenza-cloud-logo-1.png">
        </div>

        <ion-label *ngIf="user.signedIn" id="siteNameField" class="siteNameField oflow-site-selection ion-hide-md-down automationTesting" (click)="openSiteSelect()">
          {{ getUserActiveSiteName(false) }}
        </ion-label>
        <div id="selectSiteDropdown" class="selectSiteDropdown ion-icon-wrapper-raw-button automationTesting" (click)="openSiteSelect()">
          <ion-icon *ngIf="user.signedIn" class="oflow-site-selection ion-hide-md-down" name="caret-down-sharp"
            color="light"></ion-icon>
        </div>
      </ion-item>

      <!-- <ion-item *ngIf="devEnv && !user.signedIn" title="System Status" id="systemStatusMessage" slot="end" color="dark" class="systemStatusMessage {{ user.signedIn ? 'shortened' : 'expanded' }}"> -->
        <!-- <p class="scroll-text" [innerHTML]="systemStatusMessage"></p> -->
        <!-- <marquee class="systemStatus" scrollAmount="8" [innerHTML]="systemStatusMessage"></marquee> -->
      <!-- </ion-item> -->

      <ion-item *ngIf="isConnectedToInternet && user.signedIn" id="connectedGlobeIcon" slot="end" color="dark" class="header-item-padding connectedGlobeIcon automationTesting"
        title="Internet Status: Connected">
        <ion-icon color="success" size="large" name="globe-outline"></ion-icon>
      </ion-item>
      <ion-item *ngIf="!isConnectedToInternet && user.signedIn" id="disconnectedGlobeIcon" slot="end" color="dark" class="header-item-padding disconnectedGlobeIcon automationTesting"
        title="Internet Status: Disconnected">
        <ion-icon color="danger" size="large" name="globe-outline"></ion-icon>
      </ion-item>
      <ion-item *ngIf="user.signedIn" id="userActionsUsernameButton" slot="end" color="dark" class="oflow-user-name ion-hide-md-down userActionsButton userActionsUsernameButton automationTesting" (click)="userActions($event)">
        <ion-label id="userName" name="userName">{{ user ? user.name : ''}}</ion-label>
      </ion-item>
      <ion-item *ngIf="user.signedIn" id="userActionsPersonIconButton" slot="end" color="dark" class="person-item-padding userActionsButton userActionsPersonIconButton automationTesting" (click)="userActions($event)">
        <ion-icon size="large" color="medium" name="person-circle"></ion-icon>
      </ion-item>
      <ion-item *ngIf="user.signedIn" id="notificationBell" title="({{ mainSiteUIService?.getGlobalNotifications()?.length }}) Global Notification(s) + ({{ mainSiteUIService?.getSiteNotifications()?.length }}) Alert(s)" slot="end" color="dark" class="bellIcon person-item-padding"
        (click)="showNotifications($event)">
        <ion-icon [name]="notificationsOpen ? 'notifications' : 'notifications'"
          [class]="notificationsOpen ? 'notificationsOpen' : mainSiteUIService?.totalNotifications?.length > 0 ? '' : 'notificationsClosed'"></ion-icon>
        <!-- <img [class]="notificationsOpen ? 'notificationsOpen' : mainSiteUIService?.totalNotifications?.length > 0 ? 'notificationsFill' : 'notificationsClosed'" src="assets/imgs/logo.svg" alt="Kenza Cloud Logo" /> -->
        <ion-badge *ngIf="mainSiteUIService?.notifications?.length > 0" id="notificationsCount" color="primary">{{
          mainSiteUIService?.totalNotifications?.length }}</ion-badge>
      </ion-item>
    </ion-toolbar>

    <!--Site Selection II-->
    <ion-toolbar color="dark" me-toolbar-filler-sub-header *ngIf="user.signedIn" class="ion-hide-md-up">

      <ion-title class="site-selection-small" style="text-align: center;padding-bottom: 5px;" (click)="openSiteSelect()"
        lines="none" *ngIf="user.signedIn" me-header-button>
        {{ getUserActiveSiteName(true) }}
        <ion-icon name="caret-down-sharp" color="light" style="margin-bottom: 0px;"></ion-icon>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-split-pane *ngIf="!mainSiteUIService.showLeftNav" me-app-component-ion-split-pane
    [ngClass]="user.signedIn ? 'me-app-footer-spacer' : ''" when="md" content-id="main-content" color="medium"
    (ionSplitPaneVisible)="splitPaneChange()" (click)="toggleMenu($event)">
    <app-sidebar-slider *ngIf="!user.signedIn" [class]="devEnv ? 'experimentalShadows sidebarSlider' : 'sidebarSlider'"></app-sidebar-slider>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <ion-split-pane *ngIf="!user.signed_out_while_disconnected && mainSiteUIService.showLeftNav" when="md"
    me-app-component-ion-split-pane [ngClass]="user.signedIn ? 'me-app-footer-spacer' : ''" content-id="main-content"
    color="medium" (ionSplitPaneVisible)="splitPaneChange()" (click)="toggleMenu($event)">
    <ion-menu [disabled]="!user.signedIn" main-menu type="overlay" menu-id="side-menu" content-id="main-content"
      id="nav-menu" (ionWillOpen)="toggleDimBackground()" (ionWillClose)="toggleDimBackground()"
      [ngClass]="dimBackground? 'dim-everything' : ''">
      <ion-content id="menu-content" main-background
        [ngClass]="(mainSiteUIService.showLeftNav==true) ? 'me-nav-menu' : 'me-no-nav-menu' ">
        <ion-list *ngIf="mainSiteUIService.showLeftNav && siteID" id="sidenav" lines="full" class="ion-no-padding"
          color="medium">
          <ion-item class="ion-hide-md-up" me-item-30-height color="light">

          </ion-item>

          <ion-item me-item-top button id="dashboardMenuItem"
            [disabled]="isUserSiteSelected() || disableDashboardMenuItems==true "
            (click)="handleMenuItemSelection(kCloudMenuIds.Dashboard,kCloudMenuIds.Dashboard)"
            [routerLink]="['site', siteID, 'dashboard']" lines="full"
            [ngClass]="isHighlighted(kCloudMenuIds.Dashboard,kCloudMenuIds.Dashboard)">
            <ion-icon slot="start" name='speedometer-outline'
              [ngStyle]="{'opacity' : setOpacity() || disableDashboardMenuItems==true? .2 : 1 }"
              [ngClass]="isSelectedIcon(kCloudMenuIds.Dashboard)"></ion-icon>
            <ion-label id="nav_Dashboard" name="nav_Dashboard"
              [ngStyle]="{'opacity' : setOpacity()  || disableDashboardMenuItems==true ? .2 : 1 }">
              Dashboard
            </ion-label>
          </ion-item>

          <ion-item id="siteMenuItem" button [disabled]="isUserSiteSelected() || disableSiteSubMenuItems==true"
            (click)="viewMenuSelection(kCloudMenuIds.Site)" detail lines="full" class="main-menu-item"
            [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.NoSelection)">
            <ion-icon [ngStyle]="{'opacity' : setOpacity() || disableSiteSubMenuItems==true ? .2 : 1 }" slot="start"
              name='business-sharp' [ngClass]="isSelectedIcon(kCloudMenuIds.Site)"></ion-icon>
            <ion-label id="nav_Site" name="nav_Site"
              [ngStyle]="{'opacity' : setOpacity() || disableSiteSubMenuItems==true ? .2 : 1 }" id="navSite"
              name="navSite">
              Site
            </ion-label>
          </ion-item>
          <ion-item-group submenu [class.visible]="showSiteSubMenus" [disabled]="disableSiteSubMenuItems">
            <ion-item id="siteControlItem" button submenu-item [routerLink]="['/site', siteID, 'control']"
              [disabled]="disableSiteSubMenuItems"
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.SiteControl)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.SiteControl)">
              <ion-label id="siteControlLabel" [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel
                id="nav_Site_Control" name="nav_Site_Control">
                Control
              </ion-label>
            </ion-item>
            <ion-item id="siteScheduleItem" button submenu-item [routerLink]="['/site', siteID, 'schedule']"
              [disabled]="disableSiteSubMenuItems"
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.SiteSchedule)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.SiteSchedule)">
              <ion-label id="siteScheduleLabel" [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel
                id="nav_Site_Schedule" name="nav_Site_Schedule">
                Schedule
              </ion-label>
            </ion-item>
            <ion-item id="siteMonitorItem" *ngIf="hasPermissionForSiteMonitor()" button
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.SiteMonitor)"
              [routerLink]="['/site', siteID, 'monitor']" lines="full" [disabled]="disableSiteSubMenuItems"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.SiteMonitor)">
              <ion-label id="nav_Site_Monitor" name="nav_Site_Monitor"
                [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel>
                Monitor
              </ion-label>
            </ion-item>
            <ion-item id="siteAnalyticsItem" *ngIf="hasPermissionForSiteAnalytics()" button submenu-item
              [routerLink]="['/site', siteID, 'analytics']" [disabled]="disableSiteSubMenuItems"
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.SiteAnalytics)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.SiteAnalytics)">
              <ion-label [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel id="nav_Site_Analytics"
                name="nav_Site_Analytics">
                Analytics
              </ion-label>
            </ion-item>
            <ion-item button submenu-item
              [routerLink]="['/maintenance', user.active?.id]" [disabled]="disableSiteSubMenuItems"
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.SiteMaintenance)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.SiteMaintenance)" *ngIf="devEnv">
              <ion-label [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel id="nav_Site_Maintenance"
                name="nav_Site_Maintenance">
                Maintenance
              </ion-label>
            </ion-item>
            <ion-item button submenu-item
              [routerLink]="['/testdrive', user.active?.id, 'list']" [disabled]="disableSiteSubMenuItems"
              (click)="handleMenuItemSelection(kCloudMenuIds.Site,kCloudMenuIds.TestDrive)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Site,kCloudMenuIds.TestDrive)" *ngIf="devEnv">
              <ion-label [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel>
                Test Run
              </ion-label>
            </ion-item>
            <ion-item me-item-0-height>
              <!-- The purpose of this item is to provide a line -->
            </ion-item>
          </ion-item-group>

          <ion-item id="alertsMenuItem" class="me-ion-item me-item-inner" button
            (click)="handleMenuItemSelection(kCloudMenuIds.Alerts,kCloudMenuIds.Alerts)"
            [disabled]="isUserSiteSelected() || disableAlertsSubMenuItems==true"
            [routerLink]="['/site', siteID, 'alerts']" lines="full"
            [ngClass]="isHighlighted(kCloudMenuIds.Alerts,kCloudMenuIds.NoSelection)">
            <ion-icon [ngStyle]="{'opacity' : setOpacity() || disableAlertsSubMenuItems==true ? .2 : 1 }" slot="start"
              name='alert-circle' [ngClass]="isSelectedIcon(kCloudMenuIds.Alerts)"></ion-icon>
            <ion-label [ngStyle]="{'opacity' : setOpacity() || disableAlertsSubMenuItems==true ? .2 : 1 }"
              id="nav_Alerts" name="nav_Alerts">
              Alerts&nbsp;&nbsp;
            </ion-label>
            <ion-note id="menuAlertsNotes" [ngStyle]="{'opacity' : setOpacity() || disableAlertsSubMenuItems==true ? .2 : 1 }" slot="end"
              *ngIf="mainSiteUIService?.siteAlertMetricsData.v2RawTotalActCount > 0" color="white"
              class="alert-notification-bookmark">{{mainSiteUIService.siteAlertMetricsData.v2RawTotalActCount}}</ion-note>
          </ion-item>
          <ion-item-group id="alertsSubMenuItemGroup" submenu [class.visible]="showAlertsSubMenus" [disabled]="disableAlertsSubMenuItems"
          [ngClass]="{'disabled-checkbox-container': disableAlertsSubMenuItems}">
            <ion-list [ngClass]="isEnabledAlertFilter()">
              <ion-item color="dark" lines="none" [disabled]="disableAlertsSubMenuItems" style="--min-height:36px;">
                <ion-label class="alert-filter" id="alert_filter_header" name="alert_filter_header"><strong>Alert
                    Filter</strong></ion-label>
                <ion-label id="alert_filter_header_value" name="alert_filter_header_value" color="medium"><i>
                    ({{mainSiteUIService.siteAlertMetricsData.v2RawTotalCount}})</i>
                </ion-label>
              </ion-item>
              <ion-item color="light" lines="none" [disabled]="disableAlertsSubMenuItems"
                style="--min-height:32px; --padding-start:10px">
                <ion-button fill="clear" me-ion-buttons class="ion-no-margin" (click)="toggleUIGroupState('status')">
                  <ion-icon [name]="uiState.showStatusGroup==true? 'caret-up-sharp' : 'caret-down-sharp'" color="dark">
                  </ion-icon>
                </ion-button>
                <ion-label id="alert_filter_status_header" name="alert_filter_status_header" class="ion-no-margin"
                  color="dark">
                  <strong>Status</strong>
                </ion-label>
              </ion-item>

              <ion-item-group *ngIf=" uiState.showStatusGroup==true">
                <div float-left me-side-menu-checkbox-container >
                  <ion-checkbox
                    id="chk_alert_filter_active" 
                    name="chk_alert_filter_active" 
                    me-side-menu-checkbox 
                    justify="start"
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.StatusActive" 
                    label-placement="end"  
                    (ionChange)="applySiteAlertFilter($event)">Active <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2ActiveCount}})</i>
                  </ion-checkbox>                
                </div>

                <div float-left me-side-menu-checkbox-container  >
                  <ion-checkbox 
                    id="chk_alert_filter_history" 
                    name="chk_alert_filter_history" 
                    me-side-menu-checkbox
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.StatusHistorical"
                    (ionChange)="applySiteAlertFilter($event)" 
                    label-placement="end" 
                    justify="start">History <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2AckedCount}})</i>
                  </ion-checkbox>
                </div>

              </ion-item-group>

              <ion-item color="light" lines="none" [disabled]="disableAlertsSubMenuItems"
                style="--min-height:32px; --padding-start:10px; margin-top:10px">
                <ion-button (click)="toggleUIGroupState('alerts')" fill="clear" me-ion-buttons class="ion-no-margin">
                  <ion-icon [name]="uiState.showAlertsGroup==true? 'caret-up-sharp' : 'caret-down-sharp'" color="dark">
                  </ion-icon>
                </ion-button>
                <ion-label id="alert_filter_notifications_header" name="alert_filter_notifications_header" color="dark"
                  class="ion-no-margin">
                  <strong>Alarms</strong>
                </ion-label>
              </ion-item>

              <ion-item-group *ngIf="uiState.showAlertsGroup==true">

                <div float-left me-side-menu-checkbox-container >
                  <ion-checkbox 
                    id="chk_alert_filter_equipment" 
                    name="chk_alert_filter_equipment"
                    me-side-menu-checkbox
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.AlertEquipment" 
                    (ionChange)="applySiteAlertFilter($event)" 
                    label-placement="end" 
                    justify="start">Equipment <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2EquipmentCount}})</i>
                  </ion-checkbox>
                </div>

              </ion-item-group>

              <ion-item color="light" lines="none" [disabled]="disableAlertsSubMenuItems"
                style="--min-height:32px; --padding-start:10px; margin-top:12px">
                <ion-button (click)="toggleUIGroupState('notifications')" fill="clear" me-ion-buttons
                  class="ion-no-margin">
                  <ion-icon [name]="uiState.showNotificationsGroup==true? 'caret-up-sharp' : 'caret-down-sharp'"
                    color="dark"></ion-icon>
                </ion-button>
                <ion-label id="alert_filter_notifications_header" name="alert_filter_notifications_header" color="dark"
                  class="ion-no-margin">
                  <strong>Notifications</strong>
                </ion-label>
              </ion-item>

              <ion-item-group *ngIf="uiState.showNotificationsGroup==true">

                <!-- Hide the Equipment Notifications for now CB: Apr 2024-->
                <!--
                <div float-left me-side-menu-checkbox-container [disabled]="disableAlertsSubMenuItems">
                  <ion-checkbox 
                    id="chk_alert_filter_equipment_notification"
                    name="chk_alert_filter_equipment_notification" 
                    me-side-menu-checkbox
                    [(ngModel)]="siteAlertFilter.NotificationEquipment"
                    (ionChange)="applySiteAlertFilter($event)"
                    label-placement="end"
                    justify="start">Equipment <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2EquipmentNotificationCount}})</i>
                  </ion-checkbox>
                </div>            
              -->

                <div float-left me-side-menu-checkbox-container >
                  <ion-checkbox 
                    id="chk_alert_filter_membership" 
                    name="chk_alert_filter_membership"
                    me-side-menu-checkbox
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.NotificationMembership"
                    (ionChange)="applySiteAlertFilter($event)" 
                    label-placement="end"
                    justify="start">Membership <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2MembershipCount}})</i>
                  </ion-checkbox>
                </div>

                <div float-left me-side-menu-checkbox-container >
                  <ion-checkbox 
                    id="chk_alert_filter_gateway" 
                    name="chk_alert_filter_gateway" 
                    me-side-menu-checkbox
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.NotificationGateway" 
                    (ionChange)="applySiteAlertFilter($event)"
                    label-placement="end"  
                    justify="start">Gateway <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2GatewayCount}})</i>
                  </ion-checkbox>
                </div>

                <div float-left me-side-menu-checkbox-container *ngIf="user.activeSiteUserLevel===levelEnum.Owner" >
                  <ion-checkbox 
                    id="chk_alert_filter_payments" 
                    name="chk_alert_filter_payments" 
                    me-side-menu-checkbox
                    [disabled]="disableAlertsSubMenuItems"
                    [(ngModel)]="siteAlertFilter.NotificationPayments" 
                    (ionChange)="applySiteAlertFilter($event)" 
                    label-placement="end"
                    justify="start">Payment <i me-side-menu-label-sub>({{mainSiteUIService.siteAlertMetricsData.v2PaymentCount}})</i> 
                  </ion-checkbox>
                </div>

                <ion-item color="light" lines="none" [disabled]="disableAlertsAubMenuItems" style="height: 10px;">
                  <!-- this is a spacer for the bottom-->
                </ion-item>

              </ion-item-group>

            </ion-list>

            <ion-item color="light" style="--min-height:6px; height:8px;">
              <!-- The purpose of this item is to provide a line -->
            </ion-item>
          </ion-item-group>

          <ion-item id="manageMenuItem" button (click)="viewMenuSelection(kCloudMenuIds.Manage)" detail lines="full"
            [disabled]="isUserSiteSelected() || disableManageMenuItems" class="main-menu-item"
            [ngClass]="isHighlighted(kCloudMenuIds.Manage,kCloudMenuIds.NoSelection)">
            <ion-icon [ngStyle]="{'opacity' : setOpacity() || disableManageMenuItems==true ? .2 : 1 }" slot="start"
              name='briefcase' [ngClass]="isSelectedIcon(kCloudMenuIds.Manage)"></ion-icon>
            <ion-label [ngStyle]="{'opacity' : setOpacity() || disableManageMenuItems==true ? .2 : 1 }">
              Manage
            </ion-label>
          </ion-item>

          <ion-item-group submenu [class.visible]="showManageSubMenus">
            <ion-item id="manageMembersItem" button [routerLink]="['/manage',  siteID, 'members']" submenu-item
              (click)="handleMenuItemSelection(kCloudMenuIds.Manage,kCloudMenuIds.ManageMembers)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Manage,kCloudMenuIds.ManageMembers)"
              [disabled]="disableManageMenuItems==true">
              <ion-label [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel
                id="nav_Manage_Site_Members" name="nav_Manage_Site_Members">
                Members
              </ion-label>
            </ion-item>
            <ion-item id="manageGatewaysItem" button [routerLink]="['/manage',  siteID, 'gateways']" submenu-item
              (click)="handleMenuItemSelection(kCloudMenuIds.Manage,kCloudMenuIds.ManageGateways)" lines="full"
              [ngClass]="isHighlighted(kCloudMenuIds.Manage,kCloudMenuIds.ManageGateways)"
              [disabled]="disableManageMenuItems==true">
              <ion-label [ngStyle]="{'opacity' : lowerSubMenuOpacity() ? .2 : 1 }" submenulabel
                id="nav_Manage_Site_Gateways" name="nav_Manage_Site_Gateways">
                Gateways
              </ion-label>
            </ion-item>

            <ion-item me-item-0-height>
              <!-- The purpose of this item is to provide a line -->

            </ion-item>
          </ion-item-group>


        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <div class="div-content">
    <ion-content *ngIf="user.signed_out_while_disconnected">
      <div style="margin-top: 10%">

        <ion-grid style="width: 100%;border-style: none; border-width: 0px;">
          <ion-row>
            <ion-col>
              <div class="ion-text-center">
                <h3>You have no internet connectivity</h3>
                Your account has been automatically signed out of <span translate="no">kenza cloud</span>™.
                <br>
                Attempt to sign back into <span translate="no">kenza cloud</span>™ after restoring your internet connection.
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>


      </div>

    </ion-content>

  </div>

  <ion-footer *ngIf="user.signedIn" kenza-app-footer>
    <ion-toolbar kenza-footer-toolbar>
      <ion-item id="kenzaCloudFooterLogo" class="kenzaCloudFooterLogo automationTesting" lines="none" kenza-footer-item slot="start" lines="none">
        <ion-text footer-logo-text id="kenzaCloudLogoKenza" name="kenzaCloudLogoKenza"><span translate='no'>kenza</span></ion-text>
        &nbsp;
        <ion-text id="kenzaCloudLogoCloud" name="kenzaCloudLogoCloud" footer-logo-text> cloud</ion-text>
        <sup>
          <ion-text id="kenzaCloudLogoTM" name="kenzaCloudLogoTM" footer-trademark style="vertical-align: 50%;">TM
          </ion-text>
        </sup>
      </ion-item>

      <ion-item slot="end" kenza-footer-item lines="none">
        <a white-anchor [routerLink]="'/privacypolicy'" id="privacyPolicy" name="privacyPolicy" class="privacyPolicyFooterLink automationTesting">
          Privacy Policy
        </a>
        &nbsp;|&nbsp;
        <a white-anchor [routerLink]="'/subscriberagreement'" id="subscriberAgreement" name="subscriberAgreement" class="subscriberAgreementFooterLink automationTesting">
          Subscriber Agreement
        </a>
        &nbsp;|&nbsp;
        <a white-anchor [routerLink]="'/contactus'" id="contactUs" name="contactUs" class="contactUsFooterLink automationTesting">
          Contact Us
        </a>
      </ion-item>
    </ion-toolbar>
  </ion-footer>

</ion-app>