import { maintenanceNotification } from 'src/app/enumerations/enums';
import { UserService } from 'src/app/common/services/user/user.service';
import moment from 'moment-timezone';

// Data for one row of the table for displaying results
export class BCPortResultData {
    index: number;
    result: string;
    group?: string;
    address?: string;
    ocAddress: number;
    branchAddress?: number;
    branchPair?: number;
    date?: string;
    note?: Array<string>;

    constructor(index: number, resultType: string, ocAddress: number, note?: Array<string>, date?: string, group?: string, branchAddress?: number, address?: string, branchPair?: number) {
        this.index = index;
        this.result = resultType;
        this.group = group;
        this.address = address;
        this.ocAddress = ocAddress;
        this.branchAddress = branchAddress;
        this.branchPair = branchPair;
        this.date = date;
        this.note = note;
    }
    addIcAddress(address: string) {
        this.address = this.address + ',' + String(address);
    }
}

const CANCEL_MASSAGE = 'BC port check has failed.(';
const ERROR_MASSAGE = 'An error has occurred.';
const OK_JUDGE = 'No incorrect settings were found. For the final assessment, please review the maintenance data and proceed with the necessary actions on your own.';
const NG_JUDGE_TYPE0 = 'The liquid pipe temperature (TH2) of the IDU is higher compared to theroom temperature (TH1) of the IDU.';
const NG_JUDGE_TYPE1 = 'The temperature difference between the gas pipe temperature (TH3) and the room temperature (TH1) of the IDU is lower than expected.';
const NG_JUDGE_TYPE2 = 'The gas pipe temperature (TH3) of the IDU is lower compared to the room temperature (TH1) of the IDU.';
const NG_JUDGE_TYPE3 = 'The outdoor unit failed to start up properly.';
const NG_JUDGE_TYPE4 = 'Failed to get value.';
const CANCEL_JUDGE = 'BC port check could not be performed as the IDU did not change the thermos ON state.';

// A class that manages data for displaying results
export class BCPortResultDataList {
    list: BCPortResultData[];
    timeDif = new Date().getTimezoneOffset();
    utcOffset = moment().tz(this.user.active.timezone).utcOffset();

    constructor(
        private user: UserService
    ) {
        this.list = [];
    }

    createlist(datas: BCPortRecvData) {
        for (const result of datas.result) {
            let massage = [];
            switch (result.judgeStatus) {
                case 'ok':
                    this.judgeStatusOk(result)
                    break;

                case 'cancel':
                    if (result.error === undefined) {
                        massage = [CANCEL_MASSAGE + '-)'];
                    } else {
                        massage = [CANCEL_MASSAGE + String(result.error) + ')'];
                    }
                    this.massageShow(result.ocAddress, massage);
                    break;

                default:
                    this.massageShow(result.ocAddress, [ERROR_MASSAGE]);
                    break;
            }
        }
    }

    private judgeStatusOk(result: any) {
        let resultNum = 0;
        const ocAddress = result.ocAddress;
        if (result.okJudge !== null) {
            resultNum = result.okJudge.length;
            this.addList(result.okJudge, 'okJudge', ocAddress);
        }
        if (result.ngJudge !== null) {
            resultNum = resultNum + result.ngJudge.length;
            this.addList(result.ngJudge, 'ngJudge', ocAddress);
        }
        if (result.cancelJudge !== null) {
            resultNum = resultNum + result.cancelJudge.length;
            this.addList(result.cancelJudge, 'cancelJudge', ocAddress);
        }
        if (resultNum == 0) {
            // If the inspection is canceled before it has started, you will be notified of an all-empty array.
            // Assume that all inspections have failed Display "ALL" on the screen
            this.massageShow(result.ocAddress)
        }
    }

    private massageShow(ocAddress: number, massage?: Array<string>) {
        const resultData = new BCPortResultData(0, 'none', ocAddress, massage);
        this.list.push(resultData);
    }

    private addList(judges: Array<any>, resultType: string, ocAddress: number) {
        const index = this.list.length;
        for (const num in judges) {
            const group = String(judges[num].group);
            const startDate = judges[num].startDate;
            const endDate = judges[num].endDate;
            const error = judges[num].error;
            for (const unit of judges[num].unit) {
                const icAddress = unit.address;
                const branchAddress = unit.branchAddress;
                const branchPair = unit.branchPair;
                const note = this.createMassage(resultType, unit, startDate, endDate, error);
                const resultData = new BCPortResultData(index, resultType, ocAddress, note.massage, note.date, group, branchAddress, icAddress, branchPair);
                this.list.push(resultData);
            }
            if (judges[num].unit.length === 0) {
                // Show default value if value does not exist
                const note = this.createMassage(resultType, judges[num].unit, startDate, endDate, error);
                const resultData = new BCPortResultData(index, resultType, ocAddress, note.massage, note.date, group);
                this.list.push(resultData);
            }
        }
    }

    private createMassage(resultType: string, unit: any, startDate: string, endDate: string, error: number) {
        const note = {
            date: null,
            massage: []
        }
        if (startDate !== undefined && endDate !== undefined) {
            // Change to PC time zone
            const startTimezone = new Date(moment(startDate).format(`YYYY-MM-DD HH:mm:ss`));
            const endTimezone = new Date(moment(endDate).format(`YYYY-MM-DD HH:mm:ss`));
            // Change to site time zone
            startTimezone.setMinutes(startTimezone.getMinutes() + this.timeDif + this.utcOffset);
            endTimezone.setMinutes(endTimezone.getMinutes() + this.timeDif + this.utcOffset);
            note.date =  moment(String(startTimezone)).format(`YYYY-MM-DD HH:mm:ss`) + ' - ' + moment(String(endTimezone)).format(`YYYY-MM-DD HH:mm:ss`);
        }

        switch (resultType) {
            case 'okJudge':
                note.massage = [OK_JUDGE];
                break;

            case 'ngJudge':
                if (unit.length !== 0) {
                    if ((unit.errorType & 0b00000001) > 0) {
                        note.massage.push(NG_JUDGE_TYPE0);
                    }
                    if ((unit.errorType & 0b00000010) > 0) {
                        note.massage.push(NG_JUDGE_TYPE1);
                    }
                    if ((unit.errorType & 0b00000100) > 0) {
                        note.massage.push(NG_JUDGE_TYPE2);
                    }
                    if ((unit.errorType & 0b00001000) > 0) {
                        note.massage.push(NG_JUDGE_TYPE3);
                    }
                    if ((unit.errorType & 0b00010000) > 0) {
                        note.massage.push(NG_JUDGE_TYPE4);
                    }
                }
                if (note.massage.length === 0) {
                    note.massage.push(ERROR_MASSAGE);
                }
                break;

            case 'cancelJudge':
                if (error === 2) {
                    note.massage = [CANCEL_JUDGE];
                } else {
                    note.massage = ['BC port check could not be performed(' + String(error) + ')'];
                }
                break;

            default:
                break;
        }
        return note;
    }
}

// Class for parsing incoming data
export class BCPortRecvData {
    private typeJudge = {
        'reception_start': notificationType.START,
        'reception_suspended': notificationType.SUSPEND,
        'notify': notificationType.NOTIFY,
        'finish': notificationType.FINISH,
        'err_start': notificationType.ERR_START,
        'err_mfk': notificationType.ERR_MFK,
        'err': notificationType.ERR,
    }
    type: notificationType;
    progress: number;
    status: string;
    managementTableId: string;
    result: {
        ocAddress: number;
        judgeStatus: string;
        okJudge: Array<object>;
        ngJudge: Array<object>;
        cancelJudge: Array<object>;
        error?: number;
    }[]
    target: {
        address: number,
        systemName: string,
        modelName: string
    }[];

    constructor(data) {
        this.type = notificationType.ERR;
        this.progress = 0;
        this.status = '-';
        this.managementTableId = '-';
        this.result = [];
        this.target = [];
        this.updateData(data);
    }

    private updateData(data) {
        if (data.type === maintenanceNotification.BCPORT) {
            this.type = this.typeJudge[data.responseData.type];
            this.progress = data.responseData.progress;
            this.result = data.responseData.result;
            this.status = data.responseData.status;
            this.managementTableId = data.responseData.managementTableId;
            this.target = data.responseData.target;
        }
    }
}

export enum notificationType {
    START,
    SUSPEND,
    NOTIFY,
    FINISH,
    ERR_START,
    ERR_MFK,
    ERR,
    NOTIFICATION_NUM
}
