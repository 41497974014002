export class ControlIgnorable {
    // used to tack the ignorable state of a control w.r.t. profile updates

    readonly IGNORABLE_TIMEOUT = 15000;

    // are this control profile updates current disabled
    groupCountWaitingOnUpdatesFor: number
    // the timer to control when this become expired
    timer: ReturnType<typeof setTimeout>;
    // has this ignorable expired?
    expired: boolean

    constructor() {
        this.groupCountWaitingOnUpdatesFor = 0;
        this.timer = null;
        this.expired = true;
    }

    reset() {
        //wipe to not active
        this.groupCountWaitingOnUpdatesFor = 0;
        this.expired = true;
        if (this.timer != null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    set(count:number) {
        // set to expire in 15 seconds.
        if (this.timer != null) clearTimeout(this.timer)
        this.expired = false;
        this.timer = setTimeout(() => { 
            this.expired = true; 
            this.groupCountWaitingOnUpdatesFor = 0;
        }, this.IGNORABLE_TIMEOUT);
        this.groupCountWaitingOnUpdatesFor = count;
    }

    status():boolean {
        // are we pausing updates waiting on a change request?
        return this.groupCountWaitingOnUpdatesFor != 0; 
    }

    groupReported() {
        // acknowledge reporting of one of the groups in the batch
        if (this.groupCountWaitingOnUpdatesFor > 0) this.groupCountWaitingOnUpdatesFor--;
    }
}
