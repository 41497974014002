<ion-header>
  <ion-toolbar>
    <ion-title slot="start" id="i_title_title" name="i_title_title">Select Outdoor Unit{{ multiSelect ? '(s)' : '' }}</ion-title>
  <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
    <ion-icon slot="end" name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
  </div>  
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row *ngIf="multiSelect">
      <ion-col class="ion-padding-start me-topm-20" size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <ion-button id="i_btn_select_all" color="primary" size="small" class="me-padr-15" (click)="selectAll()">Select All</ion-button>
        <ion-button id="i_btn_select_none" color="primary" size="small" class="me-padr-15" (click)="unselectAll()">Unselect All</ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let odu of outdoor_units" [attr.data-gatewayname]="odu?.gateway_name" class="me-odu-row selectODURow {{ showGatewayName ? 'showGatewayName' : '' }} {{ readyForSubmission() && shouldODUBeDisabled(odu) && !multiSelect ? 'disableRow' : '' }}">
      <ion-col [ngClass]="{
        'maxwidth38': odu.gateway_is_expired, 
        'disabledCol': shouldODUBeDisabled(odu),
        'maxwidth51': odu.gateway_in_maintenance, 
        'maxwidth66': !functionSupported(odu) || (odu.gateway_subscription_needs_upgrade && !odu.gateway_is_expired), 
        'maxwidth60': functionSupported(odu) && (odu.gateway_is_disconnected && !odu.gateway_is_expired && !odu.gateway_subscription_needs_upgrade && !odu.gateway_in_maintenance)
      }" class="odu_checkbox_container me-odu-col" (click)="checkbox(odu)">
        <ion-checkbox [(ngModel)]="odu.checked" class="me-marl-20" [disabled]="shouldODUBeDisabled(odu)"></ion-checkbox>
        <div title="{{odu.name}}" class="checkbox_label_container me-marl-20 oflow oduColumnLabel {{ shouldODUBeDisabled(odu) ? 'disableLabel' : '' }}">
          {{odu.name}}
        </div>
      </ion-col>
      <ion-col *ngIf="functionSupported(odu) && odu.gateway_is_expired" class="oduColumnLabel warningLabelCol odu_col_right_side gateway_expired_label ion-text-right">
        <i>Gateway expired</i>
      </ion-col>
      <ion-col *ngIf="!functionSupported(odu)" class="odu_col_right_side ion-text-right">
        <ion-label class="oduColumnLabel disableLabel" deadSubscriptionLink><i>Function Unsupported</i></ion-label>
      </ion-col>
      <ion-col *ngIf="functionSupported(odu) && odu.gateway_in_maintenance" class="odu_col_right_side gateway_expired_label ion-text-right">
        <i class="oduColumnLabel disableLabel">Gateway performing maintenance</i>
      </ion-col>
      <ion-col *ngIf="functionSupported(odu) && (odu.gateway_is_disconnected && !odu.gateway_is_expired && !odu.gateway_subscription_needs_upgrade && !odu.gateway_in_maintenance)" class="oduColumnLabel disableLabel odu_col_right_side gateway_expired_label ion-text-right">
        <i>Gateway is not connected</i>
      </ion-col>
      <ion-col *ngIf="functionSupported(odu) && odu.gateway_subscription_needs_upgrade" class="odu_col_right_side ion-text-right">
        <ion-label *ngIf="odu.upgradable" (click)="upgradeSubscription(odu.gateway_id)" subscriptionLink><i>Upgrade Subscription</i></ion-label>
        <div class="upgradeSubscriptionText" title="Request Site Owner Upgrade Subscription">
          <ion-label *ngIf="!odu.upgradable" class="oduColumnLabel disableLabel" deadSubscriptionLink><i>Upgrade Subscription</i></ion-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid> 
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button id="i_btn_select" name="i_btn_select" (click)="onSelect()" [disabled]="!readyForSubmission()"
      class="me-padr-15" color="primary" slot="end">Select
    </ion-button>
  </ion-toolbar>
</ion-footer>