import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { AccountService } from '../../services/accountService';
import { TemperaturePreference } from '../../classes/TemperaturePreference';
import { PressurePreference } from '../../classes/PressurePreference';
import { AccountPreferences } from '../../classes/AccountPreferences';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { PressurePreferenceEnum, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';
import { globalFunctions } from 'src/app/constants/globalFunctions';

@Component({
  selector: 'app-account-preferences-admin',
  templateUrl: './account-preferences-admin.component.html',
  styleUrls: ['./account-preferences-admin.component.scss'],
})
export class AccountPreferencesAdminComponent implements OnInit {

  @Input() parent_account_id: string;

  accoutPreferencesForm: UntypedFormGroup;
  temperature_preferences_list: TemperaturePreference[] = [];
  pressure_preferences_list: PressurePreference[] = [];
  account_preferences: AccountPreferences = this.get_default_account_preferences();
  detectFormChanges: any = globalFunctions.detectFormChanges;
  saveEnabled: boolean = false;

  constructor(
    private user: UserService,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private accountService: AccountService,
    private siteService: SiteService,

  ) {
  }

  ngOnInit() {
    this.createAccountPreferencesForm();
    this.getTemperaturePreferences();
    this.getPressurePreferences();
    this.getAccountPreferences();
  }

  get_default_account_preferences() {

    const default_account_preferences: AccountPreferences = {
      id: '',
      account_id: this.user.id,
      pressurepreference_id: PressurePreferenceEnum.PoundFourcePerSquareInch,
      pressure_preference_abbreviation: '',
      pressure_preference_name: '',
      temperaturepreference_id: TemperaturePreferenceEnum.Fahrenheit,
      temperature_preference_abbreviation: '',
      temperature_preference_name: '',
      create_at: null,
      removed_at: null,
    }
    return default_account_preferences;
  }

  createAccountPreferencesForm() {
    this.accoutPreferencesForm = this.formBuilder.group({
      pressure_preference: [PressurePreferenceEnum.PoundFourcePerSquareInch, Validators.compose([Validators.min(1)]),],
      temperature_preference: [TemperaturePreferenceEnum.Fahrenheit, Validators.compose([Validators.min(1)]),],
    });
  }

  getTemperaturePreferences() {
    this.accountService.getTemperaturePreferences().subscribe((temperaturePreferences: TemperaturePreference[]) => {
      this.temperature_preferences_list = temperaturePreferences;
    });
  }

  get_temperature_preference_abbreviation_from_id(temperature_preference_id: number) {
    const temperature_preference: TemperaturePreference = this.temperature_preferences_list.find(tp => tp.id == temperature_preference_id);
    if (temperature_preference == undefined) {
      return '';
    }
    return temperature_preference.abbreviation;
  }

  getPressurePreferences() {
    this.accountService.getPressurePreferences().subscribe((pressurereferences: PressurePreference[]) => {
      this.pressure_preferences_list = pressurereferences;
    });
  }

  get_pressure_preference_abbreviation_from_id(pressure_preference_id: number) {
    const pressure_preference: PressurePreference = this.pressure_preferences_list.find(pp => pp.id == pressure_preference_id);
    if (pressure_preference == undefined) {
      return '';
    }

    return pressure_preference.abbreviation;
  }

  get_pressure_preference_name_abbreviation_from_id(pressure_preference_id: number) {
    const pressure_preference: PressurePreference = this.pressure_preferences_list.find(pp => pp.id == pressure_preference_id);
    if (pressure_preference == undefined) {
      return '';
    }

    if (pressure_preference.id == PressurePreferenceEnum.PoundFourcePerSquareInch) {
      return `${pressure_preference.name} (${pressure_preference.abbreviation.toLowerCase()})`
    }

    return `${pressure_preference.name} (${pressure_preference.abbreviation})`
  }

  getAccountPreferences() {
    this.accountService.getAccountPreferences(this.parent_account_id).subscribe((accountPreferences: AccountPreferences) => {

      if (accountPreferences) {
        this.account_preferences = accountPreferences;
        this.accoutPreferencesForm.controls.temperature_preference.setValue(this.account_preferences.temperaturepreference_id);
        this.accoutPreferencesForm.controls.pressure_preference.setValue(this.account_preferences.pressurepreference_id);
        this.detectFormChanges(this.accoutPreferencesForm);
      }
    });
  }

  async save_account_preferences() {
    if (!this.siteService.handleIsConnected())
      return false;

    if (this.account_preferences && this.accoutPreferencesForm.controls.pressure_preference && this.accoutPreferencesForm.controls.temperature_preference) {
      this.account_preferences.pressurepreference_id = Number(this.accoutPreferencesForm.controls.pressure_preference.value);
      this.account_preferences.temperaturepreference_id = Number(this.accoutPreferencesForm.controls.temperature_preference.value);
    }

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Saving account preferences...',
    });

    load.present();

    if (this.account_preferences.id && this.account_preferences.id.length > 0) {
      this.accountService.updateAccountPreferences(this.account_preferences).subscribe(create_result => {

        this.modalController.dismiss(
          {
            account_preference_saved: true
          }
        )
        load.dismiss();

      }, err => {
        load.dismiss();
      });

    } else {
      this.accountService.createAccountPreferences(this.account_preferences).subscribe(update_result => {

        this.modalController.dismiss(
          {
            account_preference_saved: true
          }
        )
        load.dismiss();

      }, err => {
        load.dismiss();
      });
    }

  }


  close() {
    this.modalController.dismiss();
  }

}
