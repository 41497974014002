/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NEVER, Observable, from } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { UserService } from "src/app/common/services/user/user.service";
import { Logger } from "../logging/log.service";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private logger: Logger,
    public auth: UserService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.auth || !this.auth.user) {
      // if we are asking for a non-external path (i.e. one where uyou dont have to be authenticated)
      // then we should abort this - can't make a request without an auth.user
      const path = new URL(req.url).pathname;
      if (
        this.auth.externalPaths.findIndex((extPath) => extPath == path) == -1
      ) {
        // send the user home - can't make the call you're trying to make.
        this.router.navigate(["home"]);

        // abort the observable - i.e. do not complete the http call against the server
        // as we dont have an authenticated user at this point to provide. It will fail if attempted.
        return NEVER;
      }

      let headers = null;

      if (req.method !== "GET") {
        headers = req.headers.append("Content-Type", "application/json");
      }
      const reqClone = req.clone({
        headers,
      });

      return next.handle(reqClone).pipe(
        tap({
          // Succeeds when there is a response; ignore other events
          next: (event) => {
            if (event instanceof HttpResponse) {
              // do nothing
            }
          },
          // Operation failed; error is an HttpErrorResponse
          error: (err) => {
            if (err instanceof HttpErrorResponse) {
              // perform any required custom http error response handling within the interceptor
              switch (err.status) {
                case 0:
                  return err;
                case 400:
                  break;

                case 500:
                  return err;
              }
            }
          },
        })
      );
    } else {
      return from(this.auth.getToken()).pipe(
        switchMap((token) => {
          let headers = null;

          if (req.method !== "GET") {
            headers = req.headers
              .set("Authorization", "" + token)
              .append("Content-Type", "application/json");
          } else {
            headers = req.headers.set("Authorization", "" + token);
          }

          const reqClone = req.clone({
            headers,
          });

          return next.handle(reqClone).pipe(
            tap({
              next: (event) => {
                if (event instanceof HttpResponse) {
                  // do nothing
                }
              },
              error: (err) => {
                if (err instanceof HttpErrorResponse) {
                  // perform any required custom http error response handling within the interceptor
                  switch (err.status) {
                    case 0:
                      return err;
                    case 400:
                      break;

                    case 500:
                      return err;
                  }
                }
              },
            })
          );
        })
      );
    } //end if
  }
}
