<ion-item class="searchAndFilter {{ itemClass }}" lines="none">
    <ion-button id="checkBoxSelectAllButton" title="Select All" color="black" class="checkBoxSelectAllButton checkBoxButton {{ sizeXL == true ? '' : 'sizeS' }} me-clear-filter"
        fill="clear" (click)="toggleSelectAll($event)" #checkBoxSelectAllButton>
        <ion-icon color="black" name="layers"></ion-icon>
    </ion-button>
    <ion-button id="checkBoxFiltersButton" title="Filters" color="black" class="checkBoxFiltersButton checkBoxButton {{ sizeXL == true ? '' : 'sizeS' }} me-clear-filter" fill="clear"
        (click)="toggleFilterSelection($event)" #checkBoxFiltersButton>
        <ion-text *ngIf="sizeXL == true" class="filterButtonLabel filterTypeLabel">Filter</ion-text>
        <ion-icon class="checkBoxFilterIconStyle" name="filter"></ion-icon>
        <ion-badge class="filterSelectionBadge" *ngIf="checkedBoxes.length > 0" color="secondary">{{ checkedBoxes.length }}</ion-badge>
    </ion-button>
    <ion-searchbar (ionInput)="onSearchInput($event)" placeholder="Search..." class="me-search-bar {{ searchClass }} {{ searchElementTag }}" #{{searchElementTag}}>
    </ion-searchbar>

    <div *ngIf="useInternalList == true" class="filterSelectionListContainer">
        <div id="filterSelectionList" class="filterSelectionList {{ filterSelectionOpen ? 'open' : 'closed' }}" (window:resize)="resizeFiltersContainer($event)" [style.maxHeight.px]="windowHeight < 690 ? (windowHeight - 250 < 170 ? 170 : windowHeight - 250) : 450" #filterSelectionList>
            <ion-list *ngIf="filterSelectionOpen == true" class="checkBoxFilterContainer" [style.maxHeight.px]="windowHeight < 690 ? ((windowHeight - 250 < 170 ? 170 : windowHeight - 250) - 49) : 401">
                <ion-item *ngIf="mainSiteUIService.gsFilters.length == 0" lines="full"><i>No Filters Currently</i></ion-item>
                <ion-item class="itemWrapper" [lines]="last ? 'none' : 'full'"
                *ngFor="let gsFilter of mainSiteUIService.gsFilters; index as i; let first = first; let last = last;" title="{{ gsFilter.name }}" #itemWrapper>
                    <div class="itemContent">
                        <ion-text class="filterTypeLabel">{{ gsFilter?.name }}</ion-text>
                        <ng-container *ngIf="mainSiteUIService.isArrayOfStrings(gsFilter?.filters)" title="Unit / Group Type / Location Filters || Filters are from Strings">
                            <ion-item class="checkBoxContent {{ isActiveFilter({gsFilter, itemFilter}) ? 'active' : 'inactive' }} {{ itemFilter == '( None )' ? 'italics' : '' }}" [title]="itemFilter" lines="none" (click)="setActiveFilters({name: gsFilter.name, itemFilter}, $event)"
                            *ngFor="let itemFilter of gsFilter.filters; index as idx; let first = firstItem; let last = lastItem;" #checkBoxContent>
                                <ion-checkbox [checked]="isActiveFilter({gsFilter, itemFilter})" class="gsFilterCheckBox textOverflow" [value]="itemFilter" label-placement="end" justify="start" #gsFilterCheckBox>
                                    {{ mainSiteUIService.renderFilterName(itemFilter) }}
                                </ion-checkbox>
                            </ion-item>
                        </ng-container>
                        <ng-container *ngIf="!mainSiteUIService.isArrayOfStrings(gsFilter?.filters)" title="Gateway Filters || Filters are from Objects">
                            <ion-item class="checkBoxContent {{ isActiveFilter(itemFilter?.id) ? 'active' : 'inactive' }}" [title]="itemFilter?.filter" *ngFor="let itemFilter of filteredGatewayFilters(gsFilter); index as idx; let first = firstItem; let last = lastItem;" lines="none" (click)="setActiveFilters(itemFilter, $event)" #checkBoxContent>
                                <ion-checkbox [checked]="isActiveFilter(itemFilter?.id)" class="gsFilterCheckBox gwCheckbox textOverflow" [value]="itemFilter?.id" label-placement="end" justify="start" #gsFilterCheckBox>
                                    {{ itemFilter.filter }}
                                </ion-checkbox>
                                <div *ngIf="devEnv && itemFilter?.category == GroupSelectorFilterTypes.GATEWAY" class="additionalFilterDetails">
                                    <span *ngIf="itemFilter?.connected == true" class="connectedGW">✔</span>
                                    {{ itemFilter?.subscription_features[SubscriptionFeatures.SYSTEM_SCHEDULING] == SubscriptionFeatures.INCLUDED ? 'Standard' : 'Limited' }}
                                    <ion-badge class="gatewayGroupsBadge">{{ groupsInSearchAndFilter(itemFilter?.groups)?.length }}</ion-badge> Groups
                                </div>
                            </ion-item>
                        </ng-container>
                    </div>
                </ion-item>
            </ion-list>
            <ion-list class="filterListFooter">
                <ion-item title="Check all" [hidden]="gsFilterCheckBoxes && gsFilterCheckBoxes.length > 0 && checkedBoxes.length == gsFilterCheckBoxes.length || checkedBoxes.length == mainSiteUIService.totalFilters || !devEnv" (click)="checkAllCheckboxes($event)" lines="full" button="true">
                    <ion-text class="filterTypeLabel" color="primary">Check all</ion-text>
                </ion-item>
                <ion-item title="Clear filters" (click)="clearAllCheckBoxFilters($event)" lines="full" button="true">
                    <ion-text class="filterTypeLabel" color="primary">Clear filters</ion-text>
                </ion-item>
            </ion-list>
        </div>
    </div>
</ion-item>