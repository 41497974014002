<ion-content main-background>
    <ion-toolbar color="light">
        <ion-title color="dark">
            <h1 id="header" name="header">Refrigerant Check : {{statusStr}}</h1>
        </ion-title>
    </ion-toolbar>
    <ion-card>
        <ion-item lines="none">
            <ion-title class="ion-no-padding">
                <h4>Target Equipment</h4>
            </ion-title>
        </ion-item>
        <ion-card-content>

            <div class="table-container">
                <table class="mat-elevation-z8">
                    <tr>
                        <th class="col-width header-col ">SystemName</th>
                        <th class="col-width header-col ">ModelName</th>
                    </tr>
                    <tr>
                        <td class="col-width">{{target.systemName}}</td>
                        <td class="col-width">{{target.modelName}}</td>
                    </tr>
                </table>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-item lines="none">
            <ion-title class="ion-no-padding">
                <h4 (click)="degug($event)" (contextmenu)="degug2($event)">Progress:&nbsp;&nbsp;{{progress}}</h4>

            </ion-title>
            <div class="ion-icon-wrapper-card-header">
                <ion-button (click)="checkDownload()" [disabled]="!isShowResult"> download </ion-button>
            </div>
            <div class="ion-icon-wrapper-card-header">
                <ion-button [disabled]="cancelDisabled" (click)="checkCancel()"> Cancel </ion-button>
            </div>
        </ion-item>
        <ion-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                    [class.hidden-resule]="!isShowResult">
                    <ng-container matColumnDef="label">
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.label}} </td>
                    </ng-container>
                    <ng-container matColumnDef="value">
                        <td mat-cell *matCellDef="let element" class="col-width"> {{element.value}} </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: rowColumns"></tr>
                </table>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>