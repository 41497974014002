import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/common/services/user/user.service';
import { AccountDetail } from 'src/app/features/account/classes/accountdetail';

@Component({
  selector: 'app-manage-member-detail',
  templateUrl: './manage-member-detail.component.html',
  styleUrls: ['./manage-member-detail.component.scss'],
})
export class ManageMemberDetailComponent {

  @Input() show_acknowledged_at: boolean;
  @Output() show_acknowledged_at_valueChange = new EventEmitter<boolean>();
  @Input() member_since: string;
  @Input() member_image_url: string;
  @Input() member_account_detail: AccountDetail;

  constructor(
    private user: UserService,
    private router: Router,
  ) { }

  // ngOnInit() {
  // }

  backToManageMembers() {
    this.emit_show_acknowledged_at_value_change();
    this.router.navigate(['/manage', this.user.active.id, 'members']);
  }

  display_name(fname: string, lname: string, email: string): string {
    let displayName = '';

    if (fname || lname) {
      if (fname && lname) {
        displayName = fname + ' ' + lname;
      } else if (fname) {
        displayName = fname;
      } else if (lname) {
        displayName = lname;
      }
    } else {
      displayName = '';
    }

    return displayName;
  }

  emit_show_acknowledged_at_value_change() {
    this.show_acknowledged_at_valueChange.emit(false);
  }

}
