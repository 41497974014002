/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from "@angular/core";
import { timer } from "rxjs";
import { DataService } from "src/app/common/services/data/DataService";
import { SiteService } from "src/app/features/sites/services/site.service";
import { Logger } from "src/app/common/services/logging/log.service";
import { UserService } from "src/app/common/services/user/user.service";
import { SiteNotification } from "../../classes/sitenotification";
import { MainSiteUIService } from "src/app/common/services/ui/main-site-ui.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  LoadingController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import {
  EQUIPMENT_ALERT_RESET_WAIT_TIME,
  TOAST_SUCCESS_TITLE,
  ERROR_RESET_SUCCESS,
  TOAST_GENERAL_ERROR_TITLE,
  GATEWAY_NOT_ASSOCIATED_WITH_SITE,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  ERROR_RESET_NO_GATEWAY,
  devEnv,
} from "src/app/constants/kenzaconstants";
import { AppAuthenticationService } from "src/app/common/services/authentication/app-authentication.service";
import { SiteAlertFilter } from "src/app/common/classes/filters";
import {
  DriveDataDownloadStateEnum,
  GatewayModelClass,
  MaintenanceJobTypeEnum,
  MaintenanceJobTypeEnumTitle,
  ServerDriveDataDownloadStatusEnum,
  ToastMessageTypeEnum,
  WebSocketResponseTypeEnum,
} from "src/app/enumerations/enums";
import { AccountService } from "src/app/features/account/services/accountService";
import { TroubleshootingComponent } from "../troubleshooting/troubleshooting.component";
import { KenzaCloudMenuIds } from "src/app/enumerations/enums";
import { ErrorDetailComponent } from "../equipment-alert-admin/error-detail/error-detail.component";
import { SocketService } from "src/app/common/services/websockets/socket.service";
import { v4 as uuid } from "uuid";
import moment from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import {
  requestAlertDumpStart,
  requestAlertDumpGetfile,
} from "src/app/common/services/websockets/classes/requests/WebSocketRequest";
import { DateStringUtilities } from "src/app/common/utilities/stringUtilities";
import { Subscription } from "rxjs";
import { Gateway } from "src/app/features/manage/components/classes/Gateway";

@Component({
  selector: "app-equipment-alert-admin",
  templateUrl: "./equipment-alert-admin.component.html",
  styleUrls: ["./equipment-alert-admin.component.scss"],
})
export class EquipmentAlertAdminComponent implements OnInit {
  siteNotification: SiteNotification = null;
  showIndoorUnit = false;
  showOutdoorUnit = false;
  showGroupName = false;
  loadMain: any = null;
  showNavigationIssueMessage = false;
  navigationIssueMessage = "";
  showSuccessfulReset = false;
  showFailedReset = false;
  showAutoEquipmentReset = false;
  autoResetMessage = "";
  hideErrorResetPart = false;
  troubleshootingDetails: any = [];
  devEnv = devEnv;
  acknowledgedOrResetByActiveMember = true;
  interval;
  socketEventSubscription: Subscription = null;
  createAlertDumpRequestId: uuid = null;
  getAlertDumpRequestId: uuid = null;
  utcOffset = moment().tz(this.user.active.timezone).utcOffset();
  getAlertDumpURL = false;
  getAlertDumpCnt = 0;
  getAlertDumpTimer: any = null;
  getAlertDumpEvent: Subscription = null;
  waitTime = 180000; //3minutes
  loopTime = 10000; //10seconds

  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;

  dddButtonStateDisabled = false; // disabled by gw or date state
  dddButtonEnabled = false;
  dddButtonTooltip = "";
  dddJobTitleFromEnum = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Download_Drive_Data];
  dddMainLabelText = this.dddJobTitleFromEnum;
  dddAltLabelText = "";
  showDddButton = true;
  downloadDriveDataState: DriveDataDownloadStateEnum = DriveDataDownloadStateEnum.ReadyToGenerate;
  downloadDataAlertSubscription: Subscription = null;
  driveDataDownloadStateEnum = DriveDataDownloadStateEnum;
  dddShowSpinner: boolean; // show the lines spinner on the secondary text line.

  constructor(
    public dataService: DataService,
    public logger: Logger,
    public user: UserService,
    public siteService: SiteService,
    public socketService: SocketService,
    private toastService: ToastrService,
    public mainSiteUIService: MainSiteUIService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public modalController: ModalController,
    private appAuthService: AppAuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    const equipmentAlertSiteNotificationID = this.activatedRoute.snapshot.paramMap.get("alertId");
    this.mainSiteUIService.viewSiteAlert("toEquipmentAlerts");
    const notifica =
      this.mainSiteUIService.getSiteNotificationFromGlobal(
        equipmentAlertSiteNotificationID
      ) ||
      this.mainSiteUIService.siteAlertMetricsData.v2RawSiteNotifications.find(
        (not) => not.id == equipmentAlertSiteNotificationID
      );
    if (notifica) {
      if (notifica?.acknowledged_at != null) {
        if (
          this.user.activeSiteAccounts
            .map((acc) => acc?.id)
            .includes(notifica?.acknowledged_account_id)
        ) {
          this.acknowledgedOrResetByActiveMember = true;
        } else {
          this.acknowledgedOrResetByActiveMember = false;
        }
      }
      this.setEquipmentAlertDetail(notifica); 
      this.siteService
        .getErrorCodeTroubleshooting(notifica.context.error_code)
        .subscribe(
          (res: any) => {
            this.troubleshootingDetails = res;
            if (this.troubleshootingDetails.length == 0)
              console.log(
                `Warning: No troubleshooting data available for error code ${notifica.context.error_code}`
              );
          },
          (error: any) => {
            console.log("error in getErrorCodeTroubleShooting: " + error);
          }
        );
    } else {
      // usally means someone refreshed while in an alert detail, as alerts aren't loaded yet going back to the main alerts page
      this.returnToSiteAlerts(true);
    }
  }

  ionViewDidEnter() {
    // check for if Data Drive Download should be enabled
    if (!this.downloadDataAlertSubscription) {
      this.downloadDataAlertSubscription = this.socketService.AlertEmitter.subscribe(
        (socketResult: any) => {
          if ( socketResult && socketResult.response_type) {
            if (socketResult.response_type == WebSocketResponseTypeEnum.Receive_Create_Alert_Dump) {
              // what is the status?
              const serverStatus = socketResult.status;
              if (serverStatus == ServerDriveDataDownloadStatusEnum.Ready) {
                this.setDDDStatus(DriveDataDownloadStateEnum.ReadyToDownload);
              } else if (serverStatus == ServerDriveDataDownloadStatusEnum.Generating ) {
                this.setDDDStatus(DriveDataDownloadStateEnum.Generating);
              } else if (serverStatus == ServerDriveDataDownloadStatusEnum.Error) {
                this.setDDDStatus(DriveDataDownloadStateEnum.Failed);
              }
            }
          }
        }
      );
    }
  }

  ionViewDidLeave(){
    this.communicationEnd()
    if (this.downloadDataAlertSubscription) {
      this.downloadDataAlertSubscription.unsubscribe();
      this.downloadDataAlertSubscription = null;
    }
  }

  returnToSiteAlerts(replaceUrl = false) {
    this.mainSiteUIService.viewSiteAlert("from");
    if (replaceUrl) {
      this.router.navigate(["/site", this.user.active.id, "alerts"], {
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["/site", this.user.active.id, "alerts"]);
    }
  }

  setEquipmentAlertDetail(siteNotification: SiteNotification) {
    // populate the component with details from this equipment site alert/notification
    this.siteNotification = siteNotification;
    this.showSuccessfulReset = this.siteNotification.acknowledged_at == null ? false : true;

    if (this.siteNotification.context) {
      if(this.siteNotification.context["reset_by_move"]) {
        this.autoResetMessage = `Equipment Alarm moved to history because gateway was moved to another site at ${this.timeFormat(this.siteNotification.acknowledged_at)}.`;
      } else if(this.siteNotification.context["reset_by_suspension"]) {
        this.autoResetMessage = `Equipment Alarm moved to history because gateway was expired at ${this.timeFormat(this.siteNotification.acknowledged_at)}.`;
      }
      this.showAutoEquipmentReset = true;
      this.hideErrorResetPart = true;
    }

    this.showIndoorUnit = false;
    this.showOutdoorUnit = false;

    switch (this.siteNotification.context.unit_type) {
      case "LC":
      case "IU":
        this.showIndoorUnit = true;
        this.showOutdoorUnit = true;
        this.showGroupName = this.siteNotification.context.unit_type == "IU";
        break;
      case "OU":
        this.showOutdoorUnit = true;
        break;
    }
    // update this also
    this.getDownloadDriveDataStatus()
  }

  getEquipmentAlertDetail(site_id: string, sitenotification_id: string) {
    this.siteService
      .equipmentAlertDetail(site_id, sitenotification_id)
      .subscribe((equipmentAlertDetail) => {
        if (
          equipmentAlertDetail &&
          equipmentAlertDetail["site_notification"] &&
          equipmentAlertDetail["site_notification_gateway"]
        ) {
          this.siteNotification = equipmentAlertDetail["site_notification"];
          this.setEquipmentAlertDetail(this.siteNotification);
        }
      });
  }

  getDownloadDriveDataStatus() {
    // based on this.siteNotification - what is the drivedownload status ofthis one?
    if (!this.siteNotification) return;

    const site_id = this.siteNotification.site_id
    this.siteService.getEquipmentNotificationDownloadDriveDataStatus(this.siteNotification.id, this.siteNotification.site_id)
      .subscribe((res:any)  => {
        if (res) {
          if ('status' in res) {
            const serverStatus:ServerDriveDataDownloadStatusEnum = parseInt(res.status)
            switch(serverStatus) {
              case ServerDriveDataDownloadStatusEnum.Starting: {
                this.setDDDStatus(DriveDataDownloadStateEnum.RequestedGeneration);
                break;
              }
              case ServerDriveDataDownloadStatusEnum.Active: {
                this.setDDDStatus(DriveDataDownloadStateEnum.Generating);
                break;
              }
              case ServerDriveDataDownloadStatusEnum.Ready: {
                this.setDDDStatus(DriveDataDownloadStateEnum.ReadyToDownload);
                break;
              }
              case ServerDriveDataDownloadStatusEnum.Error: {
                this.setDDDStatus(DriveDataDownloadStateEnum.Failed, 'error' in res ? res['error'] : '' );
              }
            }
          } else {
            this.setDDDStatus(DriveDataDownloadStateEnum.ReadyToGenerate);
          }
        }
      })
  }

  timeFormat(t) {
    const d = new Date(t + "Z");
    return d.toLocaleString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  async handleActionClick() {
    if (!this.siteService.handleIsConnected()) return;

    this.showSuccessfulReset = false;
    this.showFailedReset = false;

    this.loadMain = await this.loadingController.create({
      message: "Resetting error...",
      spinner: "lines",
      duration: EQUIPMENT_ALERT_RESET_WAIT_TIME,
    });
    await this.loadMain.present();
    this.spinnerResettingResponseHandler();

    this.siteService.resetEquipmentAlert(this.siteNotification.id).subscribe(
      (result) => {
        const siteAlertFilter: SiteAlertFilter =
          this.mainSiteUIService.getSiteAlertFilter();
        siteAlertFilter.site_id = this.siteNotification.site_id;
        siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

        this.mainSiteUIService.dismissNotification(this.siteNotification);
        this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
        // this reset *may* reset more than just this single alert - need to go back to the server
        this.getEquipmentAlertDetail(
          this.siteNotification.site_id,
          this.siteNotification.id
        );

        if (this.loadMain !== undefined && this.loadMain !== null) {
          if (
            "message" in result &&
            (<string>result["message"]).includes("Gateway not found")
          ) {
            this.loadMain.dismiss("ErrorReset_GWNotFound");
          } else {
            this.loadMain.dismiss("ErrorReset_Success");
          }
        }
      },
      (error) => {
        this.loadMain.dismiss("ErrorReset_Failure");
      }
    );
  }

  async spinnerResettingResponseHandler() {
    const { data } = await this.loadMain.onDidDismiss();
    if (data !== undefined && data == "ErrorReset_Success") {
      this.showSuccessfulReset = true;
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Success,
        TOAST_SUCCESS_TITLE,
        ERROR_RESET_SUCCESS
      );
    } else if (data !== undefined && data == "ErrorReset_GWNotFound") {
      this.showAutoEquipmentReset = true;
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Success,
        TOAST_SUCCESS_TITLE,
        ERROR_RESET_NO_GATEWAY
      );
    } else {
      this.showFailedReset = true;
    }
  }

  async presentToast(toastMessage: string, color: string) {
    const toast = await this.toastController.create({
      message: toastMessage,
      color: color,
      position: "middle",
      duration: 3000,
    });

    toast.present();
  }

  navigateToGateway() {
    const site_id: string = this.siteNotification.site_id;
    const gateway_id: string = this.siteNotification.context.gateway_id;

    if (!this.siteService.handleIsConnected()) return;

    if (!gateway_id) {
      // then we dont know what the gateway is
      this.showNavigationIssueMessage = true;
      this.navigationIssueMessage = GATEWAY_NOT_ASSOCIATED_WITH_SITE;
      return;
    }

    this.siteService.getGatewayDetails(site_id, gateway_id).subscribe(
      (result) => {
        const site_gateway_url = `/manage/${site_id}/gateway/${gateway_id}`;
        this.router.navigate([site_gateway_url]);
      },
      (error) => {
        this.showNavigationIssueMessage = true;
        this.navigationIssueMessage = GATEWAY_NOT_ASSOCIATED_WITH_SITE;
      }
    );
  }

  navigateToErrorResetBy() {
    const acknowledged_by_id: string =
      this.siteNotification.acknowledged_account_id;
    const acknowledged_by_site_id: string = this.siteNotification.site_id;

    if (!this.siteService.handleIsConnected()) return;

    this.accountService
      .getSiteAccountDetails(acknowledged_by_site_id, acknowledged_by_id)
      .subscribe(
        (result) => {
          if (acknowledged_by_id === this.user.id) {
            const site_member_url = `/account/${acknowledged_by_id}/details`;
            this.router.navigate([site_member_url]);
          } else {
            const site_member_url = `/manage/${acknowledged_by_site_id}/members/${acknowledged_by_id}`;
            this.router.navigate([site_member_url]);
          }
        },
        (err) => {
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_CONNECTIVITY_ISSUE_TITLE,
            TOAST_CONNECTIVITY_ISSUE
          );
        }
      );
  }

  backToNotification() {
    this.showNavigationIssueMessage = false;
  }
  openHandBook() {
    window.open("/assets/Yシリーズ_北米3STEP.pdf#page=176", "_blank");
  }

  /**
   * 詳細ダイアログ表示
   * show details dialog
   */
  async onClickDetail() {
    const modal = await this.modalController.create({
      component: ErrorDetailComponent,
      backdropDismiss: true,
      componentProps: {
        parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl,
      },
    });
    return await modal.present();
  }

  openShopLink() {
    window.open("https://www.mitsubishielectric.co.jp/products/", "_blank");
  }

  onClickDownload() {
    alert("Operation data downloaded.");
  }

  async handleTroubleshootingClick() {
    // display trouble shooting components as a model

    const modal = await this.modalController.create({
      component: TroubleshootingComponent,
      cssClass: `me-troubleshooting-modal-select`,
      backdropDismiss: false,
      componentProps: {
        troubleshootingDetails: this.troubleshootingDetails,
      },
    });

    return await modal.present();
  }

  handleMyLinkDriveClick() {
    window.open("https://mylinkdrive.com/USA");
  }

  handleDownloadDriveDataClick() {
    // based on current state - perform different options.
    if (this.downloadDriveDataState == DriveDataDownloadStateEnum.ReadyToGenerate) {
      // then this is a request to start generation
      this.setDDDStatus(DriveDataDownloadStateEnum.RequestedGeneration);
      this.siteService.startEquipmentNotificationDownloadDriveData(this.siteNotification.id, this.siteNotification.site_id)
      .subscribe( (res) => {
        if ('result' in res && !res['result']) {
          // then we failed...
          if ('error' in res) console.log(res['error'])
            this.setDDDStatus(DriveDataDownloadStateEnum.Failed,res['error'])
        }
      })
    } else if (this.downloadDriveDataState == DriveDataDownloadStateEnum.ReadyToDownload) {
      // then trigger a download
      this.setDDDStatus(DriveDataDownloadStateEnum.RequestedDownload);

      this.siteService.downloadEquipmentNotificationDriveData(this.siteNotification.id, this.siteNotification.site_id).subscribe((r: Blob) => {
        const fileURL = URL.createObjectURL(r);
        const a = document?.createElement(`a`);
        if (document && document?.body) document?.body?.appendChild(a);
        a.href = fileURL;
        a.download = `driveData_${this.siteNotification.id}.zip`;
        a.click();
        a.remove();
        this.setDDDStatus(DriveDataDownloadStateEnum.ReadyToDownload);
      }, (error) => {
        this.setDDDStatus(DriveDataDownloadStateEnum.Failed);
      })        
    } else if (this.downloadDriveDataState == DriveDataDownloadStateEnum.Failed) {
      // delete and switch to ReadyToGenerate
      this.setDDDStatus(DriveDataDownloadStateEnum.ReadyToGenerate);
      this.siteService.deleteEquipmentNotificationDownloadDriveData(this.siteNotification.id, this.siteNotification.site_id)
      .subscribe( (res) => {
        // pass
      })
    }
    // else do nothing
  }

  setDDDStatus(newState: DriveDataDownloadStateEnum, error: string = '') {
    // update status to match this newStatus

    if (this.siteNotification) {
      const site = this.user.sites.find((s) => s.id == this.siteNotification.site_id);
      const gw: Gateway = site?.gateways.find((gw) => gw.id == this.siteNotification.context.gateway_id);

      if (!gw) {
        // during reload/refresh races sometimes the site gw objects dont exist yet
        this.dddButtonTooltip = "This gateway is no longer registered";
        this.dddButtonStateDisabled = true;
        return;
      }
      
      if (gw.model.class_name == GatewayModelClass.MCC) { // DDD not for MCC
        this.showDddButton = false;
        return;
      }  

      const ts = new Date(this.siteNotification.created_at + "Z").getTime();
      const now = new Date().getTime();

      const oneHourAgo = now - (1 * 60 * 60 * 1000);
      const thirtyDaysAgo = now - (30 * 24 * 60 * 60 * 1000);

      this.dddButtonStateDisabled = true;
      if (!gw.activeSubscription.isPaid) {
        this.dddButtonTooltip = `Upgrade kenza cloud plan to ${this.dddJobTitleFromEnum}`;
      } else if (ts > oneHourAgo) {
        this.dddButtonTooltip = `${this.dddJobTitleFromEnum} is not available until 1 hour after the alarm`;
      } else if (ts < thirtyDaysAgo) {
        this.dddButtonTooltip = `${this.dddJobTitleFromEnum} is not available after 30 days of the alarm`;
      } else {
        this.dddButtonTooltip = `${this.dddJobTitleFromEnum}`;
        this.dddButtonStateDisabled = false;
      }
    }

    this.downloadDriveDataState = newState;

    switch(newState) {

      case DriveDataDownloadStateEnum.Failed:
        // something went wrong/unexpected
        console.log(`Error with drive data download: ${error}`);
        this.dddMainLabelText = `${this.dddJobTitleFromEnum}`;        
        this.dddAltLabelText = `Error: ${error} Click to clear`;
        this.dddButtonEnabled = true;
        this.dddShowSpinner = false;
        break;

      case DriveDataDownloadStateEnum.ReadyToGenerate:
        this.dddMainLabelText = `${this.dddJobTitleFromEnum}`;
        this.dddAltLabelText = ``;
        this.dddButtonEnabled = !this.dddButtonStateDisabled;
        this.dddShowSpinner = false;
        break;

      case DriveDataDownloadStateEnum.RequestedGeneration:
        this.dddMainLabelText = `${this.dddJobTitleFromEnum}`;
        this.dddAltLabelText = `Requesting data...`;        
        this.dddButtonEnabled = false;
        this.dddShowSpinner = true;
        break;

      case DriveDataDownloadStateEnum.Generating:
        this.dddMainLabelText = `${this.dddJobTitleFromEnum}`;        
        this.dddAltLabelText = `Collecting data...`;
        this.dddButtonEnabled = false;
        this.dddShowSpinner = true;
        break;

      case DriveDataDownloadStateEnum.ReadyToDownload:
        this.dddMainLabelText = `Click to ${this.dddJobTitleFromEnum}`;
        this.dddAltLabelText = ``;
        this.dddButtonEnabled = !this.dddButtonStateDisabled;
        this.dddShowSpinner = false;
        break;

      case DriveDataDownloadStateEnum.RequestedDownload:
        this.dddMainLabelText = `Click to ${this.dddJobTitleFromEnum}`;        
        this.dddAltLabelText = `Starting download...`
        this.dddButtonEnabled = false;
        this.dddShowSpinner = true;
        break;
    }
  }

  async downladAlertDetailClick() {
    if (!this.siteService.handleIsConnected()) {
      this.toastService.error(
        TOAST_CONNECTIVITY_ISSUE,
        TOAST_CONNECTIVITY_ISSUE_TITLE
      );
      return;
    }

    if (!this.socketService.is_connected()) {
      this.toastService.error(
        TOAST_CONNECTIVITY_ISSUE,
        TOAST_CONNECTIVITY_ISSUE_TITLE
      );
      console.log("The socket service is not connected");
      return;
    }

    await this.present_loadMain();

    // アラートダンプ開始要求API呼出
    // Alert dump start request API call
    await this.createAlertDumpAPI();
  }

  createAlertDumpAPI() {
    // アラートダンプ開始要求API呼出
    // Alert dump start request API call

    // 物件タイムゾーンとUTCの時差を"XX:XX形式"の文字列に変換(formatTimeZoneString関数)
    // Convert the time difference between Property Time Zone and UTC into a "XX:XX" format string (formatTimeZoneString function)
    this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();
    const timeZone = DateStringUtilities.formatTimeZoneString(this.utcOffset);

    const get_url = location.href;
    const split_url = get_url.split("/");
    const sitenotification_id = split_url[split_url.length - 1];

    this.createAlertDumpRequestId = uuid();

    this.sendRequestAlertDumpStart(
      "maint_alert_information_start",
      timeZone,
      sitenotification_id
    );
    this.loopTime = 60000; //1minute

    if (this.socketEventSubscription == null) {
      // アラートダンプファイル作成応答待ち、作成結果応答待ち
      // Wait for alert dump file creation response, wait for creation result response
      this.getAlertDumpTimer = timer(this.loopTime);
      this.getAlertDumpEvent = this.getAlertDumpTimer.subscribe(() => {
        this.communicationEnd();
        this.toastService.error(
          TOAST_CONNECTIVITY_ISSUE,
          TOAST_GENERAL_ERROR_TITLE
        );
      });
      this.response_alert_dump();
    }
  }

  sendRequestAlertDumpStart(command: string, time_zone, sitenotification_id) {
    const request = new requestAlertDumpStart();
    request.setBaseIds(
      this.siteNotification.context.gateway_id,
      this.user.active.id,
      this.createAlertDumpRequestId
    );
    request.setData(time_zone, sitenotification_id);
    console.info("send : " + command);
    const result = this.socketService.sendCommonRequest(request, command);
  }

  async response_alert_dump() {
    // アラートダンプファイル作成応答、作成結果応答
    // Alert dump file creation response, creation result response
    this.socketEventSubscription = this.socketService.AlertEmitter.subscribe(
      (socketResult: any) => {
        if (socketResult && socketResult.response_type) {
          switch (socketResult.response_type) {
            case WebSocketResponseTypeEnum.Receive_Create_Alert_Dump:
              if (this.getAlertDumpEvent) {
                this.getAlertDumpEvent.unsubscribe();
                this.getAlertDumpEvent = null;
              }
              this.receiveResCreateAlertDump(socketResult);
              break;
            case WebSocketResponseTypeEnum.Receive_Result_Create_Alert_Dump:
              this.receiveResGetAlertDump(socketResult);
              break;
            default:
              break;
          }
        }
      }
    );
  }

  async present_loadMain() {
    this.loadMain = await this.loadingController.create({
      message: "Now Loading... ",
      spinner: "lines",
    });
    await this.loadMain.present();
  }

  receiveResCreateAlertDump(socketResult) {
    this.getAlertDumpCnt = 0;
    this.getAlertDumpURL = false;
    const getAlertDumpSuccess = 0;
    if (socketResult.status === getAlertDumpSuccess) {
      this.loopTime = 10000; //10seconds
      this.getAlertDumpTimer = timer(this.waitTime, this.loopTime);
      this.getAlertDumpEvent = this.getAlertDumpTimer.subscribe(() => {
        this.getAlertDumpContinueCondition();
      });
    } else {
      this.communicationEnd();
      this.toastService.error(
        TOAST_CONNECTIVITY_ISSUE,
        TOAST_GENERAL_ERROR_TITLE
      );
    }
  }

  getAlertDumpContinueCondition() {
    // ダウンロード用のURLを取得するか、10分間(10秒間隔×60)を超えるまで、アラートダンプ結果要求を行う
    // Get the URL for download or do the alert dump result until it exceeds 10 minutes (10 second interval x 60)
    if (this.getAlertDumpURL === false && this.getAlertDumpCnt < 60) {
      this.sendRequestAlertDumpGetfile(
        "maint_alert_information_getfile",
        this.createAlertDumpRequestId
      );
      this.getAlertDumpCnt = this.getAlertDumpCnt + 1;
    } else {
      this.toastService.error(
        TOAST_CONNECTIVITY_ISSUE,
        TOAST_GENERAL_ERROR_TITLE
      );
      this.communicationEnd();
    }
  }

  sendRequestAlertDumpGetfile(command: string, alert_information_request_id) {
    const request = new requestAlertDumpGetfile();
    request.setBaseIds(
      this.siteNotification.context.gateway_id,
      this.user.active.id,
      this.createAlertDumpRequestId
    );
    request.setData(alert_information_request_id);
    console.info("send : " + command);
    this.socketService.sendCommonRequest(request, command);
  }

  receiveResGetAlertDump(socketResult) {
    const getAlertDumpSuccess = 0;
    if (socketResult.status === getAlertDumpSuccess && socketResult.url) {
      this.getAlertDumpURL = true;
      // 取得したURLからアラートダンプをダウンロード
      // Download the alert dump from the obtained URL
      window.open(socketResult.url, "_blank");
      this.communicationEnd();
    }
  }

  communicationEnd() {
    if (this.loadMain && this.loadMain.dismiss) {
      this.loadMain.dismiss();
    }
    if (this.getAlertDumpEvent) {
      this.getAlertDumpEvent.unsubscribe();
      this.getAlertDumpEvent = null;
    }
    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
      this.socketEventSubscription = null;
    }
  }
}
