import { Injectable } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { OutdoorUnitList, OutdoorUnit, TestType } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';

@Injectable({
  providedIn: 'root'
})

export class OutdoorunitService {
  public outdoorUnitList: OutdoorUnitList;
  public notificationEmitter = new EventEmitter<any>();
  private communicator: Subscription = null;
  public selectUnit: OutdoorUnit[] = null;
  public testType = TestType['bcport'];

  constructor(
    private user: UserService,
    private siteService: SiteService,
  ) {
    this.outdoorUnitList = null;
    this.selectUnit = null;
    this.communicator = null;
    this.testType = TestType['bcport'];
  }

  getUnitList(testType: TestType) {
    this.testType = testType;
    if (this.outdoorUnitList === null) {
      if (this.communicator === null) {
        this.outdoorUnitList = new OutdoorUnitList(this.siteService, this.user.active.id, testType);
        // Subscribing to unique events, since the screen side cannot detect changes in the attribute array
        this.communicator = this.outdoorUnitList.notification.subscribe(msg => {
          // Redraw when you have finished retrieving data for display
          this.notification(msg);
        });
      }
    } else {
      this.notification("alrady update UnitList");
    }
  }

  notification(msg: string) {
    console.log(msg);
    if (this.communicator !== null) {
      this.communicator.unsubscribe();
      this.communicator = null;
    }
    this.notificationEmitter.emit("unitList update finished");

  }

  cleaningUp() {
    console.info("OutdoorunitService cleaningUp");
    if (this.communicator !== null) {
      this.communicator.unsubscribe();
      this.communicator = null;
    }
    this.outdoorUnitList = null;
    this.selectUnit = null;
  }

  setSelectUnit(target: OutdoorUnit[]) {
    this.selectUnit = [];
    for (const index in target) {
      const tmpUnit = new OutdoorUnit();
      tmpUnit.copyData(target[index]);
      this.selectUnit.push(tmpUnit);
    }
  }
}
