/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { Logger } from 'src/app/common/services/logging/log.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { MapGatewayRequest } from 'src/app/common/services/websockets/classes/requests/MapGatewayRequest';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import {
  TOAST_GENERAL_ERROR_TITLE,
  TOAST_SUCCESS_TITLE,
  MAPUNITS_WAIT_TIME,
  GATEWAY_MOVED_SUCCESS,
  GATEWAY_DETAIL_UNABLE_TO_RETRIEVE,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_UNABLE_TO_MAP_GATEWAY_MESSAGE,
  CONNECTION_UPDATE_TIMER_INTERVAL,
  MODAL_MAINT_UPDATE_UNIT_ERROR, LICENSED_GW_MESSAGE, devEnv,
} from 'src/app/constants/kenzaconstants';
import {
  GatewayUnitTwoDigitType,
  GatewayUnitTypeEnum,
  LevelEnum,
  ToastMessageTypeEnum,
  WebSocketResponseTypeEnum,
  ModelState,
  GatewayModelClass,
GatewaySubscriptionStatusTypeEnum,
MaintenanceJobTypeEnum,
MaintenanceJobTypeEnumTitle,
} from 'src/app/enumerations/enums';
import { v4 as uuid } from 'uuid';
import { forkJoin, interval, Observable, Subscription, timer } from 'rxjs';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { GatewayGroup } from 'src/app/features/manage/components/classes/GatewayGroup';
import { GatewayUnit } from 'src/app/features/manage/components/classes/GatewayUnit';
import { SiteGatewayViewData } from 'src/app/features/sites/classes/site-gateway_detail_view_data';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SiteGatewayEditPage } from 'src/app/features/sites/pages/site-gateway-edit/site-gateway-edit.page';
import { SiteGatewayDetailData } from 'src/app/features/sites/classes/site-gateway_detail_data';
import { SiteGatewayMovePage } from 'src/app/features/sites/pages/site-gateway-move/site-gateway-move.page';
import { Site } from 'src/app/features/sites/classes/site';
import { GroupIndoorUnitAdminComponent } from 'src/app/features/sites/components/site-gateway-groups/group-indoor-units/group-indoor-unit-admin/group-indoor-unit-admin.component';
import { SiteGatewayUnitEditPage } from 'src/app/features/sites/pages/site-gateway-unit-edit/site-gateway-unit-edit.page';
import { MapUnitsComponent } from '../../modals/map-units/map-units.component';
import { MapUnitsErrorComponent } from '../../modals/map-units-error/map-units-error.component';
import { ToastrService } from 'ngx-toastr';
import { ApplicationErrorsEnum } from 'src/app/enumerations/enums'
import { ErrorTypes } from 'src/app/constants/errors'
import { SiteGatewayMovePaymentWarningPage } from 'src/app/features/sites/pages/site-gateway-move-payment-warning/site-gateway-move-payment-warning.page';
import { SiteGatewayMoveNoPaymentModal } from 'src/app/features/sites/pages/site-gateway-move-new-payment/site-gateway-move-new-payment.page';
import { AccountService } from 'src/app/features/account/services/accountService';
import { LIMITED_GW_WARNING } from 'src/app/constants/kenzaconstants';
import { maintenanceJobStatusEnum } from 'src/app/common/classes/MaintenanceJob';

@Component({
  selector: 'manage-gateway-detail',
  templateUrl: './manage-gateway-details.component.html',
  styleUrls: ['./manage-gateway-details.component.scss'],
})

export class ManageGatewayDetailsComponent implements OnInit, OnDestroy {
  selectedGateway: Gateway = new Gateway();
  siteGateways: Gateway[];
  gatewayGroups: GatewayGroup[];
  indoorUnits: GatewayUnit[];
  outdoorUnits: GatewayUnit[];
  site_gateway_detail_view_data: SiteGatewayViewData[];

  selected_odu_odus: GatewayUnit[];

  LIMITED_GW_WARNING = LIMITED_GW_WARNING;
  LICENSED_GW_MESSAGE = LICENSED_GW_MESSAGE;

  GSSTE = GatewaySubscriptionStatusTypeEnum;

  mapunitsRequestId: uuid = null;

  selected_odu: GatewayUnit = null;
  selected_odu_indoor_units: GatewayUnit[];
  selected_odu_groups: GatewayGroup[];

  lossnays: GatewayUnit[];

  SiteGatewayViewData;
  paymentMethod: any;
  showMovedGateway = false;
  validataion_error_message = 'A validation error occurred';
  error_general_message = 'Unable to complete request.';
  unable_to_map_gateway_message = 'Unable to map units at this time';
  loadMain;

  configureGroupsOpen = false;

  gateway_group_created_subscription: Subscription = null;
  gateway_group_deleted_subscription: Subscription = null;
  gateway_group_updated_subscription: Subscription = null;
  gateway_unit_changed_subscription: Subscription = null;
  refresh_site_gateway_detail_subscription: Subscription = null;
  connectionUpdateTimer: Observable<number> = null;
  timerSubscription: Subscription = null;
  socketEventSubscription: Subscription = null;

  mappingIsActive = false;
  mappingStatus = "";
  mappingProgressBarValue = 0;

  firmware_progress_bar_percent = 0;
  firmware_progress_bar_value = 0;
  firmware_progress_message = "";
  firmware_state_message_map = {
    'TRANSFERING': "Firmware downloading to device...",
    'REBOOTING': "Firmware installing on device...",
    'DONE': "Firmware update complete.",
    'FAILED': "Firmware install failed"
  };

  firmware_show_progress_bar = false;
  firmware_update_finished = false;
  firmware_update_failed = false;
  barColor = "success";

  firmware_state_timer: any;
  firmware_timer_event: Subscription;

  gatewayModelClass = GatewayModelClass;
  gatewaySubPlansRouterLink: Array<string>;
  paymentMethodsRouterLink: Array<string>;
  
  maintenancejobid;
  maintUpdateUnitId;
  modalOpen = false;
  interval;

  oduShown = true;
  iduShown = true;
  lcShown = false;
  otherShown = false;
  otherUnits = [];

  // types that don't belong in the other unit types category
  NOT_OTHER_UNITS = [GatewayUnitTwoDigitType.OutdoorUnit, GatewayUnitTwoDigitType.IndoorUnit, GatewayUnitTwoDigitType.Lossnay,
    GatewayUnitTwoDigitType.LossnayHolder, GatewayUnitTwoDigitType.MelshiHolder];

  gatewayLoaded = false;

  expiredAlertHeader = 'This action cannot be taken with an expired gateway.';
  expiredAlertBody = 'To proceed with this action, upgrade subscription.'
  expiredAlertOpen = false;

  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle; // For HTML Enum Access
  maintenanceJobStatusEnum = maintenanceJobStatusEnum; // For HTML Enum Access
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // For HTML Enum Access

  iconMaintenanceType = `maintenance`;

  constructor(
    public user: UserService,
    public appAuth: AppAuthenticationService,
    private mainSiteUIService: MainSiteUIService,
    private logger: Logger,
    private modalController: ModalController,
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private toastController: ToastController,
    private socketService: SocketService,
    private loadingController: LoadingController,
    private router: Router,
    private toastService: ToastrService,
    private accountService: AccountService,
    private alertController: AlertController,
  ) {
    this.site_gateway_detail_view_data = [];
  }

  async ngOnInit() {
    let siteIsTransferring = this.user && this.user.active && this.user.active.transfer_locked;
    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }
    await this.user.appAuthService.getLevelPermissions();
  }

  performingMaintenance = (status: maintenanceJobStatusEnum) => {
    let performingMaintenance = status == maintenanceJobStatusEnum.new 
                             || status == maintenanceJobStatusEnum.booting 
                             || status == maintenanceJobStatusEnum.running 
                             || status == maintenanceJobStatusEnum.complete;
    return performingMaintenance;
  }

  isActionRestricted() {
    let jobID;
    let mappingIsActive = this.mappingIsActive;
    let siteIsTransferring = this?.user && this?.user?.active && this?.user?.active?.transfer_locked;
    let mfk_active = this.selectedGateway && this.selectedGateway?.mfk_status ? this.selectedGateway?.mfk_status != null : false;
    let gatewayHasMaintenanceJob = this.selectedGateway && this.selectedGateway?.maintenance_job ? this.selectedGateway?.maintenance_job != null : false;
    let gatewayOrGroupsPerformingMaintenance = gatewayHasMaintenanceJob ? this.performingMaintenance(this.selectedGateway?.maintenance_job.status) : false;

    if (gatewayHasMaintenanceJob) {
      let maintJobID = this.selectedGateway.maintenance_job.job_id;
      jobID = typeof maintJobID == `string` ? parseFloat(maintJobID) : maintJobID;
      let maintType = MaintenanceJobTypeEnumTitle[jobID];
      let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
      this.iconMaintenanceType = maintTypeToUse.toLowerCase();
    }

    if (mfk_active) {
      let mfkJobID = this.selectedGateway.mfk_status.job_id;
      jobID = typeof mfkJobID == `string` ? parseFloat(mfkJobID) : mfkJobID;
      let maintType = MaintenanceJobTypeEnumTitle[jobID];
      let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
      this.iconMaintenanceType = maintTypeToUse.toLowerCase();
    }

    if (mappingIsActive) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Mapping_Units].toLowerCase();
    }

    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }

    let actionIsRestricted = siteIsTransferring || gatewayOrGroupsPerformingMaintenance || mfk_active || mappingIsActive;

    // devEnv && console.log(`Gateway Details Params`, {
    //   mfk_active,
    //   mappingIsActive,
    //   actionIsRestricted,
    //   siteIsTransferring,
    //   gatewayHasMaintenanceJob,
    //   gatewayOrGroupsPerformingMaintenance,
    //   selectedGateway: this.selectedGateway,
    //   iconMaintenanceType: this.iconMaintenanceType,
    // })
 
    return actionIsRestricted;
  }

  ionViewWillEnter() {
    // page coming up - turn on subscriptins and fix move issues.
    const siteId = this.activatedRoute.snapshot.paramMap.get('siteId');
    const gatewayId = this.activatedRoute.snapshot.paramMap.get('gatewayId');
    this.showGatewayDetails(gatewayId, siteId);

    this.oduShown = true;
    this.iduShown = true;
    this.lcShown = false;
    this.otherShown = false;

    if(this.user && this.user.active && this.user.active.transfer_locked) {
      this.gatewaySubPlansRouterLink = [];
      this.paymentMethodsRouterLink = [];
    } else {
      this.gatewaySubPlansRouterLink = ['/account', this.user?.id, 'details', 'subscriptions'];
      this.paymentMethodsRouterLink = ['/account', this.user?.id, 'details', 'payment-methods'];
    }

    if (this.socketEventSubscription == null) {
      this.socketEventSubscription =
        this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
          if (socketResult && socketResult.response_type && socketResult.request_id === this.maintUpdateUnitId &&
            socketResult.response_type == WebSocketResponseTypeEnum.Maint_Update_Unit_Complete || 
            socketResult.response_type == WebSocketResponseTypeEnum.Maint_Update_Unit_Error) {
            switch (socketResult.response_type) {
              case WebSocketResponseTypeEnum.Maint_Update_Unit_Complete:
                if(socketResult.response.id){
                  this.maintenancejobid = socketResult.response.id;
                  this.startPolling();
                  break;
                }else{
                  this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
                  break;
                }
              case WebSocketResponseTypeEnum.Maint_Update_Unit_Error:
                this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
                break;
            }
          } else if (socketResult && socketResult.response_type) {
            switch (socketResult.response_type) {
              case ApplicationErrorsEnum.MAPUNITS_WEBSOCKET_DISCONNECT:
                if (this.mappingIsActive) {
                  const errorObject = ErrorTypes[ApplicationErrorsEnum.MAPUNITS_WEBSOCKET_DISCONNECT];
                  this.siteService.presentToastMessage(
                    ToastMessageTypeEnum.Error,
                    errorObject['title'] + ": " + errorObject['code'],
                    errorObject['message']
                  );
                }
                this.mappingIsActive = false;
                if (this.loadMain && this.loadMain.dismiss) {
                  this.loadMain.dismiss();
                }
                break;

              case WebSocketResponseTypeEnum.Map_Gateway_Progress:
                // so we don't get cross messages
                if (this.selectedGateway.id != socketResult.gateway_id) return;
                
                this.mappingStatus = socketResult.status['message'];
                this.updateMappingProgressBar(socketResult.status);
                break;

              case WebSocketResponseTypeEnum.Map_Gateway_Complete:
                // map units clears out all site locations
                if (this.selectedGateway.id != socketResult.gateway_id) return;

                this.user.active.update_locations(socketResult.site_locations)
                this.showGatewayDetails(
                  this.selectedGateway.id,
                  this.selectedGateway.site_id
                );
                this.siteService.presentToastMessage(
                  ToastMessageTypeEnum.Success,
                  TOAST_SUCCESS_TITLE,
                  socketResult.message
                );
                this.mappingIsActive = false;
                if (this.loadMain && this.loadMain.dismiss) {
                  this.loadMain.dismiss();
                }

                this.mappingStatus = "";
                this.mappingProgressBarValue = 0;
                
                break;

              case WebSocketResponseTypeEnum.Map_Gateway_Started:
                if (this.selectedGateway.id != socketResult.gateway_id) return;

                this.mappingIsActive = true;
                break;

              case WebSocketResponseTypeEnum.Map_Gateway_Error:
                if (this.selectedGateway.id != socketResult.gateway_id) return;
                {
                  const errorObject = ErrorTypes[ApplicationErrorsEnum.MAPUNITS_ERROR];
                  this.siteService.presentToastMessage(
                    ToastMessageTypeEnum.Error,
                    errorObject['title'] + ": " + errorObject['code'],
                    errorObject['message']
                  );
                  this.mappingIsActive = false;

                  this.mappingStatus = "";
                  this.mappingProgressBarValue = 0;
                }
                break;
            }
          }
        });
    }

    if (this.gateway_group_created_subscription == null ) {
      this.gateway_group_created_subscription =
        this.siteService.siteGatewayGroupCRUDEvent.subscribe((crudDetails) => {
          switch (crudDetails.modelState) {
            case ModelState.Created:
              this.refresh_selected_outdoor_unit_details(this.selected_odu, true);
              break;

            case ModelState.Updated:
              if (this.selected_odu) this.refresh_selected_outdoor_unit_details(this.selected_odu, true);
              break;

            case ModelState.Deleted:
              this.refresh_selected_outdoor_unit_details(this.selected_odu, true);
              break;

            case ModelState.Quick_Updated:
              this.refresh_selected_outdoor_unit_details(this.selected_odu, false);
              break;
          }
        });
    }

    if (this.gateway_unit_changed_subscription == null ) {
      this.gateway_unit_changed_subscription =
        this.siteService.siteGatewayUnitChanged.subscribe(
          (edited_unit: GatewayUnit) => {
            if (edited_unit.unit_type === GatewayUnitTypeEnum.OutdoorUnit || 
                edited_unit.unit_type === GatewayUnitTypeEnum.Lossnay || 
                edited_unit.unit_type == GatewayUnitTypeEnum.Other) {
              this.refresh_selected_outdoor_unit_details(edited_unit, false);
            } else {
              if (edited_unit['skip_configure_groups_modal']) {
                this.refresh_selected_outdoor_unit_details(edited_unit, false);
              } else {
                this.refresh_selected_outdoor_unit_details(edited_unit, true);
              }

            }

          }
        );
    }

    if (this.refresh_site_gateway_detail_subscription == null) {
      this.refresh_site_gateway_detail_subscription =
        this.siteService.siteGatewayDetailRefresh.subscribe(
          (gateway: Gateway) => {
            this.showGatewayDetails(gateway.id, gateway.site_id);
          }
        );
    }

    if (this.connectionUpdateTimer == null) 
      this.connectionUpdateTimer = interval(CONNECTION_UPDATE_TIMER_INTERVAL);

    if (this.timerSubscription == null) {
      this.timerSubscription = this.connectionUpdateTimer.subscribe((tick) => this.updateConnectionStatus());
    }

  }

  ionViewWillLeave() {
    // moving away - turn off the subscriptions
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    if (this.gateway_group_created_subscription) {    
      this.gateway_group_created_subscription.unsubscribe();
      this.gateway_group_created_subscription = null;      
    }

    if (this.gateway_group_deleted_subscription) { 
      this.gateway_group_deleted_subscription.unsubscribe();
      this.gateway_group_deleted_subscription = null;      
    }

    if (this.gateway_group_updated_subscription) {  
      this.gateway_group_updated_subscription.unsubscribe();
      this.gateway_group_updated_subscription = null;      
    }

    if (this.gateway_unit_changed_subscription) { 
      this.gateway_unit_changed_subscription.unsubscribe();
      this.gateway_unit_changed_subscription = null;      
    }

    if (this.refresh_site_gateway_detail_subscription) {
      this.refresh_site_gateway_detail_subscription.unsubscribe();
      this.refresh_site_gateway_detail_subscription = null;      
    }

    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
      this.socketEventSubscription = null;      
    }

    if (this.firmware_timer_event) {
      this.firmware_timer_event.unsubscribe();
      this.firmware_timer_event = null;      
    }

  }  

  startPolling() {
    // 機種情報更新状況ポーリング
    // updating the model information status of progress polling
    this.interval = setInterval(() => {

      this.siteService.getMaintenancejobProgress(this.maintenancejobid, this.user.active.id).subscribe(
        (resData: any) => {
          if(resData.status === 'complete'){
            console.log('polling-complete');
            clearInterval(this.interval);
          }
          else if(resData.status === 'error'){
            console.log('polling-error');
            this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
            clearInterval(this.interval);
          }
        },
        (err) => {
          console.log('getMaintenancejobProgress=>err', err);
          clearInterval(this.interval);
        }
      );

    },60000);
  }

  updateConnectionStatus(): void {
    // update the connection status of this sites gateways
    if (this.siteService.isConnectedToInternet) {
      if (this.selectedGateway && this.selectedGateway.id) {
        this.siteService.getGatewayConnectionStatus(this.selectedGateway.id).subscribe((res) => {
          if (res.length == 0) return

          this.selectedGateway.connected = res.connected.toLowerCase() == 'true'

        }, (error) => {
          // stop trying untill the next refresh
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.gateway_group_created_subscription) {  
      this.gateway_group_created_subscription.unsubscribe();
    }

    if (this.gateway_group_deleted_subscription) {
      this.gateway_group_deleted_subscription.unsubscribe();
    }

    if (this.gateway_group_updated_subscription) {
      this.gateway_group_updated_subscription.unsubscribe();
    }

    if (this.gateway_unit_changed_subscription) {
      this.gateway_unit_changed_subscription.unsubscribe();
    }

    if (this.firmware_timer_event) {
      this.firmware_timer_event.unsubscribe();
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  async showGatewayDetails(gatewayId: string, site_id: string) {
    if (!this.siteService.handleIsConnected()) return;

    this.paymentMethod = null;
    this.mainSiteUIService.viewSiteAlert('toGatewayDetail');

    const obs_getGatewayDetails = this.siteService.getGatewayDetails(site_id, gatewayId);
    const obs_getGatewayGroups = this.siteService.get_gateway_groups_by_gateway_id(gatewayId);

    const obs_fork_joined = forkJoin({
      obs_getGatewayDetails,
      obs_getGatewayGroups,
    });

    obs_fork_joined.subscribe({
      next: (siteGatewayDetailDataResult) => {
        const siteGatewayDetailData = this.parseShowGatewayDetailData(siteGatewayDetailDataResult);

        if (siteGatewayDetailData === null) {
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            GATEWAY_DETAIL_UNABLE_TO_RETRIEVE
          );
        } else {

          this.selectedGateway = Gateway.newFromGateway(siteGatewayDetailData.gateway);
          // devEnv && console.log(`Gateway Details`, this.selectedGateway);
          this.gatewayLoaded = true;
          this.mappingIsActive = this.selectedGateway.map_units_active;

          this.lossnays = [];
          this.otherUnits = [];
          siteGatewayDetailData.units.forEach((unit) => {
            if (unit.type == GatewayUnitTwoDigitType.Lossnay) {
              this.lossnays.push(unit);
            } else if (!this.NOT_OTHER_UNITS.includes(unit.type as GatewayUnitTwoDigitType)) {
              this.otherUnits.push(unit);
            }
          });

          if (this.lossnays.length > 0) {
            this.sortLossnays();
          }

          if (this.otherUnits.length > 0) {
            this.sortOtherUnits();
          }

          if (this.selectedGateway) {
            if (this.selectedGateway.firmware_update_active
              && this.appAuth.doesLevelHavePermission(this.user.activeSiteUserLevel, this.appAuth.permissionEnums.PerformFirmwareUpgrade)) {
              this.firmware_show_progress_bar = true;
              // give the bar a higher starting value as it has been running for a bit already
              if (this.selectedGateway.firmware_update_state) {
                if (this.selectedGateway.firmware_update_state === "TRANSFERING") {
                  this.firmware_progress_bar_percent = 20;
                } else if (this.selectedGateway.firmware_update_state === "REBOOTING") {
                  this.firmware_progress_bar_percent = 70;
                }
              }

              this.startStatusUpdateTimer();
            }

          }

          this.paymentMethod = siteGatewayDetailData.payment_method;
          this.gatewayGroups = siteGatewayDetailData.groups;
          this.displaySiteGatewayDetails(site_id, siteGatewayDetailData.units);
        }
      },
      error: (e) => {
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          GATEWAY_DETAIL_UNABLE_TO_RETRIEVE
        );
      },
    });
  }

  async refresh_selected_outdoor_unit_details(selected_unit: GatewayUnit, display_configure_groups: boolean) {
    if (!this.siteService.handleIsConnected()) return;

    const obs_getGatewayDetails = this.siteService.getGatewayDetails(selected_unit.site_id, selected_unit.gateway_id);
    const obs_getGatewayGroups = this.siteService.get_gateway_groups_by_gateway_id(selected_unit.gateway_id);

    const obs_fork_joined = forkJoin({
      obs_getGatewayDetails,
      obs_getGatewayGroups,
    });

    obs_fork_joined.subscribe({
      next: (siteGatewayDetailDataResult) => {
        const siteGatewayDetailData = this.parseShowGatewayDetailData(siteGatewayDetailDataResult);

        if (siteGatewayDetailData === null) {
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            GATEWAY_DETAIL_UNABLE_TO_RETRIEVE
          );
        } else {

          this.gatewayGroups = siteGatewayDetailData.groups;

          this.refresh_site_gateway_details(
            selected_unit.site_id,
            siteGatewayDetailData.units
          );

          if (selected_unit.type === GatewayUnitTwoDigitType.OutdoorUnit
            || selected_unit.type === GatewayUnitTwoDigitType.MelshiHolder
            || selected_unit.type === GatewayUnitTwoDigitType.LossnayHolder) {
            this.display_outdoor_unit_units(selected_unit);
          } else {
            this.display_outdoor_unit_units(selected_unit.odu);
          }

          this.outdoorUnits = this.outdoorUnits.map(odu => {
            if (odu.id === selected_unit.id) {
              odu.name = selected_unit.name;
            }
            return odu;
          })

          this.sortOutdoorUnits();

          if (display_configure_groups) {
            this.configureGroups(selected_unit);
          }
        }
      },
      error: (e) => {
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          GATEWAY_DETAIL_UNABLE_TO_RETRIEVE
        );
      },
    });
  }

  displaySiteGatewayDetails(siteId: string, units: GatewayUnit[]) {
    this.indoorUnits = [];
    this.outdoorUnits = [];

    // check for units and outdoor units
    const len = units.filter((ou) =>
      ou.type.toLowerCase() === GatewayUnitTwoDigitType.OutdoorUnit.toLowerCase() ||
      ou.type.toLowerCase() === GatewayUnitTwoDigitType.MelshiHolder.toLowerCase()
    ).length;

    if (units == null || len === 0) {
      return;
    }

    if (units && units.length > 0) {
      units = units.map((u) => {
        u.site_id = siteId;
        if (!u.bus_address) {
          u.bus_address = '';
        }

        if (!u.name) {
          u.name = '';
        }

        if (u.type === GatewayUnitTwoDigitType.OutdoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.OutdoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.IndoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.IndoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.Lossnay) {
          u.unit_type = GatewayUnitTypeEnum.Lossnay;
        } else if (u.type === GatewayUnitTwoDigitType.MelshiHolder) {
          u.unit_type = GatewayUnitTypeEnum.OutdoorUnit;
        } else {
          u.unit_type = GatewayUnitTypeEnum.Other
        }

        u.isExpanded = false;

        return u;
      });

      this.indoorUnits = units.filter((ou) =>
        [
          GatewayUnitTwoDigitType.IndoorUnit.toLowerCase(),
          GatewayUnitTwoDigitType.Lossnay.toLowerCase(),
        ].includes(ou.type.toLowerCase())
      );
      this.outdoorUnits = units.filter(
        (ou) =>
          ou.type.toLowerCase() === GatewayUnitTwoDigitType.OutdoorUnit.toLowerCase() ||
          ou.type.toLowerCase() === GatewayUnitTwoDigitType.MelshiHolder.toLowerCase()

      );

      this.sortOutdoorUnits();
    }
  }

  refresh_site_gateway_details(
    siteId: string,
    units: GatewayUnit[]) {
    this.indoorUnits = [];

    // check for units and outdoor units
    if (
      units == null ||
      units.filter(
        (ou) =>
          ou.type.toLowerCase() ===
          GatewayUnitTwoDigitType.OutdoorUnit.toLowerCase()
      ).length === 0
    ) {
      return;
    }

    if (units && units.length > 0) {
      units = units.map((u) => {
        u.site_id = siteId;
        if (!u.bus_address) {
          u.bus_address = '';
        }

        if (!u.name) {
          u.name = '';
        }

        if (u.type === GatewayUnitTwoDigitType.OutdoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.OutdoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.IndoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.IndoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.Lossnay) {
          u.unit_type = GatewayUnitTypeEnum.Lossnay;
        }

        u.isExpanded = false;

        return u;
      });

      this.indoorUnits = units.filter((ou) =>
        [
          GatewayUnitTwoDigitType.IndoorUnit.toLowerCase(),
          GatewayUnitTwoDigitType.Lossnay.toLowerCase(),
        ].includes(ou.type.toLowerCase())
      );
    }
  }

  display_outdoor_unit_units(outdoorUnit: GatewayUnit) {
    if (outdoorUnit) {
      this.selected_odu = outdoorUnit;
      this.ui_select_outdoor_unit(outdoorUnit.id);
      // this.synch_units_and_groups(this.indoorUnits, this.mou_synch_units_and_groups(outdoorUnit.id, this.indoorUnits, this.mou_synch_units_and_groups(outdoorUnit.id, this.indoorUnits, this.gatewayGroups)));  
      this.synch_units_and_groups(
        this.indoorUnits, 
        this.mou_synch_units_and_groups(
          outdoorUnit.id, 
          this.indoorUnits, 
          this.mou_synch_units_and_groups(
            outdoorUnit.id, 
            this.indoorUnits, 
            this.gatewayGroups
          )
        )
      );  
    }
    
  }

  ui_select_outdoor_unit(outdoor_unit_id: string) {
    this.outdoorUnits = this.outdoorUnits.map(ou => {
      ou.isSelected = ou.id == outdoor_unit_id ? true : false;
      return ou;
    })
  }

  ui_is_outdoor_unit_selected() {
    if (!this.outdoorUnits)
      return false;

    return this.outdoorUnits.filter(ou => ou.isSelected == true).length > 0 ? true : false;
  }

  mou_synch_units_and_groups(selected_outdoor_unit_id: string, units: GatewayUnit[], gateway_groups: GatewayGroup[]): GatewayGroup[] {
    const filtered_odu_groups: GatewayGroup[] = [];
    let selected_odu_units: GatewayUnit[] = [];
    let filtered_odu_units: GatewayUnit[] = [];
    let selected_odu: GatewayUnit = null;

    if (gateway_groups && gateway_groups.length > 0) {
      gateway_groups.forEach(gateway_group => {
        //get group units associated with selected outdoor unit
        selected_odu_units = gateway_group.units.filter(u => (u.odu) && (u.odu.id === selected_outdoor_unit_id));

        if (selected_odu_units.length > 0) {
          filtered_odu_units = filtered_odu_units.concat(selected_odu_units);
          filtered_odu_groups.push(gateway_group);
        }
      });

      if ((filtered_odu_units && filtered_odu_units.length > 0)) {
        selected_odu = filtered_odu_units[0].odu;
      }

      this.selected_odu_groups = [];
      this.selected_odu_groups = filtered_odu_groups;
      this.selected_odu_odus = [];
      this.selected_odu_odus.push(selected_odu);
      this.selected_odu_indoor_units = [];
      this.selected_odu_indoor_units = filtered_odu_units;
    }

    return filtered_odu_groups;
  }

  synch_units_and_groups(units: GatewayUnit[], gateway_groups: GatewayGroup[]) {
    this.site_gateway_detail_view_data = [];

    gateway_groups.forEach((group) => {
      const sgdvd: SiteGatewayViewData = {
        id: group.group_id,
        view_type: 'GROUP',
        view_type_name: "Group",
        name: group.group_name,
        bus_address: '',
        group_id: group.group_id,
        group_name: group.group_name,
        type: '',
        type_name: '',
        isExpanded: group.isExpanded,
        view_item: group,
        autocreated: group.autocreated,
      };
      // making the autocreated groups look like a unit per Eric -- david
      if (group.autocreated) {
        // make the location match the units location
        sgdvd.view_item.location = sgdvd.view_item.units[0].location
        if (sgdvd.view_item.units[0].type == 'LC') {
          sgdvd.view_type = 'LC';
          sgdvd.view_type_name = "Lossany";
        } else {
          sgdvd.view_type = 'IU';
          sgdvd.view_type_name=  "Indoor Coil";
        }

        sgdvd.name = sgdvd.view_item.units[0].name;
        sgdvd.bus_address = sgdvd.view_item.units[0].bus_address;
      }

      this.site_gateway_detail_view_data.push(sgdvd);
    });

    this.sort_site_gateway_detail_view_data();
  }

  nameSort(units: any) {
    units.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    return units;
  }

  sortLossnays() {
    this.lossnays.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }

  sortOtherUnits() {
    this.otherUnits.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }

  sortOutdoorUnits() {
    this.outdoorUnits.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }

  sort_site_gateway_detail_view_data() {
    this.site_gateway_detail_view_data.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }

  synch_created_gateway_group(updated_gateway_group: GatewayGroup) {
    this.gatewayGroups.push(updated_gateway_group);
    this.synch_units_and_groups(this.indoorUnits, this.gatewayGroups);
  }

  synch_deleted_gateway_group(group_id: string) {
    this.gatewayGroups = this.gatewayGroups.filter(
      (group) => group.group_id !== group_id
    );
    this.synch_units_and_groups(this.indoorUnits, this.gatewayGroups);
  }

  synch_updated_gateway_group(updated_gateway_group: GatewayGroup) {
    this.gatewayGroups = this.gatewayGroups.map((group) => {
      if (group.group_id === updated_gateway_group.group_id) {
        group.group_name = updated_gateway_group.group_name;
      }
      return group;
    });
    this.synch_units_and_groups(this.indoorUnits, this.gatewayGroups);
  }

  synch_updated_unit(edited_unit: GatewayUnit) {
    this.indoorUnits = this.indoorUnits.map((iu) => {
      if (iu.id === edited_unit.id) {
        iu.name = edited_unit.name;
        iu.group_id = edited_unit.group_id;
      }
      return iu;
    });
    this.synch_units_and_groups(this.indoorUnits, this.gatewayGroups);
  }

  async onEditGateway(selectedGateway: Gateway) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayEditPage,
      cssClass: 'me-modal-gw-select detail',
      backdropDismiss: false,
      componentProps: {
        parentSelectedGateway: this.selectedGateway,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data?.saved) {
        if (data.data?.savedGateway) {
          this.accountService.subscriptionDetailsUpdated = true;
          this.siteService.emit_siteGateChanged(data.data.savedGateway);
        } else {
          this.siteService.emit_siteGateChanged(null);
        }

        if (data.data.moved) this.sortUnits();
      }
    });

    return await modal.present();
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      const comparison = a[key].localeCompare(b[key]);

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  parseShowGatewayDetailData(dataToParse: any): SiteGatewayDetailData {
    let siteGatewayDetailData: SiteGatewayDetailData = new SiteGatewayDetailData();
    siteGatewayDetailData = {
      ...dataToParse.obs_getGatewayDetails,
      ...dataToParse.obs_getGatewayGroups,
    };

    siteGatewayDetailData.gateway = Gateway.newFromGateway(dataToParse.obs_getGatewayDetails.gateway);
    if (siteGatewayDetailData.groups) {
      siteGatewayDetailData.groups.forEach((gateway_group) => {
        gateway_group.isExpanded = false;
      });
    }

    return siteGatewayDetailData;
  }

  get_group_name_by_id(group_id: string): string {
    const group: GatewayGroup = this.gatewayGroups.find(
      (grp) => grp.group_id === group_id
    );
    if (group.autocreated == true) {
      return 'No Group Assigned';
    }
    return group ? group.group_name : '';
  }

  hasPermissionForGatewayDetails() {
    return this.appAuth.doesLevelHavePermission(
      this.user.activeSiteUserLevel,
      this.appAuth.permissionEnums.ViewGatewayDetails
    );
  }

  permissionCheckCanEditGatewaySubscription(activeSiteUserLevel: LevelEnum) {
    const result = true;

    if (
      this.appAuth.doesLevelHavePermission(
        activeSiteUserLevel,
        this.appAuth.permissionEnums.EditGatewaySubscription
      ) == false
    ) {
      return false;
    }
    return result;
  }

  permissionCheckCanEditPaymentMethod(activeSiteUserLevel: LevelEnum) {
    const result = true;

    if (
      this.appAuth.doesLevelHavePermission(
        activeSiteUserLevel,
        this.appAuth.permissionEnums.EditGatewaySubscription
      ) == false
    ) {
      return false;
    }
    return result;
  }

  firstOfNextMonth() {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return next.toLocaleDateString('en', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  viewAccountGatewaySubscriptionPlans() {
    if (!this.siteService.handleIsConnected()) return;

    this.mainSiteUIService.viewAccountGatewaySubscriptionPlans();
  }

  viewAccountPaymentMethods() {
    if (!this.siteService.handleIsConnected()) return;

    this.mainSiteUIService.viewAccountPaymentMethods();
  }

  async openSiteGatewayMove() {
    if (!this.siteService.handleIsConnected()) return;

    // if the gateway details have not loaded yet - ignore
    if (this.selectedGateway.model?.name === '') return;

    const modal = await this.modalController.create({
      component: SiteGatewayMovePage,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: false,
      componentProps: {
        parentSelectedGateway: this.selectedGateway,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.moved &&
        data.data.movedGateway
      ) {
        if (data.data.moved) {
          // update selected gateway with details on move success.
          this.displayMovedGatewayDetails(
            data.data.movedToSite,
            data.data.movedGateway
          );
        } else {
          // perform any necessary processing after unsuccessful update
        }
      } else if (data.data !== undefined && data.data !== null
        && data.data.displayPaymentWarning && data.data.displayPaymentWarning == true) {

        if (data.data.needsNewPayment && data.data.needsNewPayment == true) {
          this.makeNewPaymentNeededModal(data.data.movedToSite);
        } else {
          this.makePaymentWarningModal(data.data.movedToSite, data.data.cardType, data.data.cardLastFour);
        }

      }
    });
    return await modal.present();
  }

  async makePaymentWarningModal(movedToSite: Site, cardType: string, cardLastFour: string) {
    const warningModal = await this.modalController.create({
      component: SiteGatewayMovePaymentWarningPage,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: false,
      componentProps: {
        selectedGateway: this.selectedGateway,
        currentSiteId: this.selectedGateway.site_id,
        movedToSite: movedToSite,
        cardType: cardType,
        cardLastFour: cardLastFour
      },
    });

    warningModal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.moved &&
        data.data.movedGateway
      ) {
        if (data.data.moved) {
          this.displayMovedGatewayDetails(
            data.data.movedToSite,
            data.data.movedGateway
          );
        } else {
          // perform any necessary processing after unsuccessful update
        }
      }
    });

    return await warningModal.present();
  }

  async makeNewPaymentNeededModal(movedToSite: Site) {
    const warningModal = await this.modalController.create({
      component: SiteGatewayMoveNoPaymentModal,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: false,
      componentProps: {
        selectedGateway: this.selectedGateway,
        currentSiteId: this.selectedGateway.site_id,
        movedToSite: movedToSite,
      },
    });

    warningModal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.moved &&
        data.data.movedGateway
      ) {
        if (data.data.moved) {
          this.displayMovedGatewayDetails(
            data.data.movedToSite,
            data.data.movedGateway
          );
        } else {
          // perform any necessary processing after unsuccessful update
        }
      }
    });

    return await warningModal.present();
  }

  displayMovedGatewayDetails(movedToSite: Site, movedGateway: Gateway) {
    this.showMovedGateway = true;
    this.showGatewayDetails(movedGateway.id, movedToSite.id);
    this.user.setActiveSite(movedToSite, true);

    this.router.navigate([
      '/manage',
      movedToSite.id,
      'gateway',
      movedGateway.id,
    ] );
        
    this.siteService.presentToastMessage(
      ToastMessageTypeEnum.Success,
      TOAST_SUCCESS_TITLE,
      GATEWAY_MOVED_SUCCESS
    );
  }

  async presentToast(toastColor: string, displayMessage: string) {
    const toast = await this.toastController.create({
      message: displayMessage,
      duration: 5000,
      color: toastColor,
      position: 'middle',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-outline',
        },
      ],
    });
    toast.present();
  }

  showSelectedIndoorUnit(gatewayId: string) {
    this.site_gateway_detail_view_data.forEach((io) => {
      if (io.id === gatewayId) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  showSelectedOutdoorUnit(outdoor_unit_id: string) {
    this.outdoorUnits.forEach((io) => {
      if (io.id === outdoor_unit_id) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  showSelectedLossnayUnit(lossnay_unit_id: string) {
    this.lossnays.forEach((io) => {
      if (io.id === lossnay_unit_id) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  showSelectedOtherUnit(unitId: string) {
    this.otherUnits.forEach((io) => {
      if (io.id === unitId) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  sortUnits() {
    this.indoorUnits.sort((a, b) => (a.bus_address > b.bus_address ? 1 : -1));
  }

  back() {
    if (!this.siteService.handleIsConnected()) return;
    this.router.navigate(['/manage', this.user.active.id, 'gateways']);
  }

  showSelectedGatewayGroup(group_id: string) {
    this.gatewayGroups.forEach((io) => {
      if (io.group_id === group_id) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  async configureGroups(selected_outdoor_unit: GatewayUnit) {
    if (!this.siteService.handleIsConnected() || this.modalOpen) return;

    if (this.selectedGateway.activeSubscription?.gateway_subscription_status_type_id == this.GSSTE.Suspended) {
      this.displayExpiredGatewayAlert();
      return;
    }

    if (this.configureGroupsOpen == true) {
      return;
    }

    if (this.gatewayGroups && this.gatewayGroups.length > 0) {
      this.gatewayGroups = this.gatewayGroups.map((gg) => {
        gg.isExpanded = false;
        return gg;
      });
    }

    if (this.indoorUnits && this.indoorUnits.length > 0) {
      this.indoorUnits = this.indoorUnits.map((iu) => {
        iu.isExpanded = false;
        return iu;
      });
    }

    this.selected_odu = selected_outdoor_unit;

    this.configureGroupsOpen = true;
    const modal = await this.modalController.create({
      component: GroupIndoorUnitAdminComponent,
      cssClass: '',
      backdropDismiss: true,
      componentProps: {
        selected_gateway: this.selectedGateway,
        selected_outdoor_unit: selected_outdoor_unit,
        selected_odu_groups: this.selected_odu_groups,
        selected_odu_indoor_units: this.selected_odu_indoor_units.filter(
          odu_idu => (
            odu_idu.type.toUpperCase() === GatewayUnitTwoDigitType.IndoorUnit ||
            odu_idu.type.toUpperCase() === GatewayUnitTwoDigitType.Lossnay)
        ),
      },
    });

    modal.onDidDismiss().then((data) => {
      this.configureGroupsOpen = false;
      this.modalOpen = false;
    });

    this.modalOpen = true;
    if (selected_outdoor_unit?.site_id == this.user.active.id) return await modal.present();
  }

  async onEditUniVI(view_item: any) { 
    if (view_item.view_type == GatewayUnitTwoDigitType.IndoorUnit) {
      const gwu = {};
      gwu['id'] = view_item['view_item']['units'][0]['id'];
      gwu['gateway_id'] = view_item['view_item']['gateway_id'];
      gwu['group_id'] = view_item['group_id'];
      gwu['name'] = view_item['name'];
      gwu['type_name'] = "Indoor Coil";
      gwu['type'] = view_item['view_type'];
      gwu['bus_address'] = view_item['view_item']['number'];
      gwu['unit_type'] = GatewayUnitTypeEnum.IndoorUnit;
      gwu['odu'] = this.selected_odu;
      gwu['skip_configure_groups_modal'] = true;
      gwu['location'] = view_item['view_item']['location']
      this.onEditUnit(gwu as any);
    } else {
      const giuac = new GroupIndoorUnitAdminComponent(
        this.user,
        this.appAuth,
        this.siteService,
        this.modalController,
        this.toastController,
        this.socketService,
        this.loadingController,
        this.toastService
      );

      giuac.selected_gateway = this.selectedGateway;
      giuac.selected_outdoor_unit = this.selected_odu;
      giuac.selected_odu_groups = this.selected_odu_groups;
      giuac.selected_odu_indoor_units = this.selected_odu_indoor_units;

      const gwGroup = new GatewayGroup();
      gwGroup['gateway_id'] = view_item['view_item']['gateway_id'];
      gwGroup['group_id'] = view_item['group_id'];
      gwGroup['group_name'] = view_item['group_name'];
      gwGroup['isExpanded'] = view_item['isExpanded'];
      gwGroup['number'] = view_item['view_item']['number'];
      gwGroup['location'] = view_item['view_item']['location'];

      const selectedOutdoorUnit = new GatewayUnit();
      giuac.on_open_group_admin(gwGroup, this.selected_odu, ModelState.Quick_Update, true);
    }
  }

  async displayExpiredGatewayAlert() {
    const actionRestricted = await this.alertController.create({
      id: `actionRestricted`,
      cssClass: `alertMsg actionRestrMsg me-info-button-css`,
      header: `Action Restricted`,
      subHeader: this.expiredAlertHeader,
      message: this.expiredAlertBody,
      buttons: [{
        text: `Ok`, role: `cancel`, cssClass: `ok-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.expiredAlertOpen = false;
        })
      }]
    });
    this.expiredAlertOpen = true;
    return await actionRestricted.present();
  }

  async onEditUnit(gatewayUnit: GatewayUnit) {  
    if (!this.siteService.handleIsConnected()) return;

    const savedGroupId = gatewayUnit.group_id;

    if (this.selectedGateway.activeSubscription?.gateway_subscription_status_type_id == this.GSSTE.Suspended) {
      this.displayExpiredGatewayAlert();
      return;
    }

    const modal = await this.modalController.create({
      component: SiteGatewayUnitEditPage,
      cssClass: `me-modal-gw-unit ${gatewayUnit.type}`,
      backdropDismiss: false,
      componentProps: {
        parentGateway: this.selectedGateway,
        parentGatewayUnit: gatewayUnit,
        parentGatewayGroups: this.gatewayGroups,
        showConfigureUnits: false,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.edited &&
        data.data.editedUnit
      ) {
        if (data.data.edited) {
          this.siteService.emit_siteGateChanged(data.data.editedUnit);
          const editedGatewayUnit: GatewayUnit = data.data.editedUnit;

          switch (editedGatewayUnit.unit_type) {
            case GatewayUnitTypeEnum.IndoorUnit:
              this.indoorUnits = this.indoorUnits.map((iu) => {
                if (iu.id === editedGatewayUnit.id) {
                  iu.name = editedGatewayUnit.name;
                  if (editedGatewayUnit.group_id === '') {
                    iu.group_id = savedGroupId;
                  } else {
                    iu.group_id = editedGatewayUnit.group_id;
                  }
                  iu.isExpanded = true; // overwritten below
                }
                return iu;
              });

              this.synch_units_and_groups(this.indoorUnits, this.gatewayGroups);
              this.sort_site_gateway_detail_view_data();
              break;

            case GatewayUnitTypeEnum.Lossnay:
              this.lossnays = this.lossnays.map((lc) => {
                if (lc.id === editedGatewayUnit.id) {
                  lc.name = editedGatewayUnit.name;
                  lc.isExpanded = false;
                }
                return lc;
              });

              this.sortLossnays();

              break;

            case GatewayUnitTypeEnum.OutdoorUnit:
              this.outdoorUnits = this.outdoorUnits.map((ou) => {
                if (ou.id === editedGatewayUnit.id) {
                  ou.name = editedGatewayUnit.name;
                  ou.isExpanded = false;
                }
                return ou;
              });

              this.sortOutdoorUnits();

              break;

            case GatewayUnitTypeEnum.Other:
                this.otherUnits = this.otherUnits.map((o) => {
                  if (o.id === editedGatewayUnit.id) {
                    o.name = editedGatewayUnit.name;
                    o.isExpanded = false;
                  }
                  return o;
                });
  
                this.sortOtherUnits();
  
                break;
          }
        }
      }
    });
    return await modal.present();
  }

  async openMapUnits() {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: MapUnitsComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
    });

    modal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.map_units &&
        data.data.map_units === true
      ) {
        this.map_units();
      }
    });
    return await modal.present();
  }

  async map_units() {

    if (!this.siteService.handleIsConnected()) return;

    if (!this.socketService.is_connected()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      return;
    }

    const mapGatewayRequest: MapGatewayRequest = new MapGatewayRequest();
    mapGatewayRequest.request_id = uuid();
    this.mapunitsRequestId = mapGatewayRequest.request_id;
    mapGatewayRequest.gateway_id = this.selectedGateway.id;
    mapGatewayRequest.site_id = this.selectedGateway.site_id;

    if (this.socketService.mapGateway(mapGatewayRequest)) {
      this.mappingIsActive = true;
    } else {
      this.toastService.error(TOAST_UNABLE_TO_MAP_GATEWAY_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
      return;
    }
  }

  async openMapUnitsError(err_msg) {
    const modal = await this.modalController.create({
      component: MapUnitsErrorComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
      componentProps: {
        errorMessage: err_msg
      },
    });
    return await modal.present();
  }

  async startFirmwareUpgrade() {
    if (this.firmware_show_progress_bar) {
      return;
    }
    const spinner = await this.loadingController.create({
      message: 'Starting firmware update...',
      spinner: 'lines',
      duration: 15_000,
    });

    await spinner.present();

    this.siteService.startFirmwareUpdate(this.selectedGateway.id, this.selectedGateway.serial_number).subscribe(
      (resp: any) => {
        this.startStatusUpdateTimer();
        this.firmware_show_progress_bar = true;
        spinner.dismiss();
      },
      (err) => {
        console.log('startFirmwareUpgrade error');
        if (err === 'ALREADY_INSTALLED') {
          this.selectedGateway.firmware_update_available = false;
        }
        spinner.dismiss();
      }
    );
  }

  startStatusUpdateTimer() {
    this.firmware_state_timer = timer(100, 10_000);
    this.firmware_timer_event = this.firmware_state_timer.subscribe(() => {
      this.siteService.getFirmwareUpdateStatus(this.selectedGateway.id, this.selectedGateway.serial_number).subscribe(
        (resp: any) => {
          this.setFirmwareUpdateProgressBar(resp['state'])
          if (resp['state'] === "DONE" || resp['state'] === "FAILED") {
            this.firmware_timer_event.unsubscribe();
          }

          // Once update is finished this call gets the new version and updates the SG obj so UI displays new value
          this.siteService.getFirmwareVersion(this.selectedGateway.id, this.selectedGateway.serial_number).subscribe(
            (resp: any) => {
              this.selectedGateway.firmware_version = resp['message'];
            },
            (err) => {
              console.log('getFirmwareVersion error');
            }
          );

        },
        (err) => {
          console.log('getFirmwareUpdateStatus error');
        }
      );
    });
  }

  setFirmwareUpdateProgressBar(state: string) {
    if (state === "TRANSFERING") {
      if (this.firmware_progress_bar_percent < 40) {
        this.firmware_progress_bar_percent = this.firmware_progress_bar_percent + 1;
      } else {
        this.firmware_progress_bar_percent = 40;
      }
    } else if (state === "REBOOTING") {
      if (this.firmware_progress_bar_percent <= 40) {
        this.firmware_progress_bar_percent = 40;
      }

      if (this.firmware_progress_bar_percent < 95) {
        this.firmware_progress_bar_percent = this.firmware_progress_bar_percent + 1;
      } else {
        this.firmware_progress_bar_percent = 96;
      }

    } else if (state === "DONE") {
      this.firmware_progress_bar_percent = 100;
      this.firmware_update_finished = true;
    } else if (state === "FAILED") {
      this.firmware_progress_bar_percent = 100;
      this.firmware_update_finished = true;
      this.firmware_update_failed = true;
      this.barColor = "danger";
    }

    this.firmware_progress_message = this.firmware_state_message_map[state];
    this.firmware_progress_bar_value = this.firmware_progress_bar_percent / 100;
  }


  updateMappingProgressBar(status) {
    // the status bar expects a value between 0 and 1 so dividing everything by 100
    switch (status.type) {
      case 'CONFIG':
        this.mappingProgressBarValue = 5/100;
        break;
      case 'INFO_PENDING':
        this.mappingProgressBarValue = 10/100;
        break;
      case 'INFO_PERCENT':
        // while this percent from the backend is obviously between 0 and 100 we need to make it fit between 10 and 90
        // so take the percent given, multiply by .8 and add 10.
        this.mappingProgressBarValue = (10 + (status['percent'] * .8))/100;
        break;
      case 'PARSING':
        this.mappingProgressBarValue = 90/100;
        break;
      case 'CLEANUP':
        this.mappingProgressBarValue = 95/100;
        break;
      case 'SAVING':
        this.mappingProgressBarValue = 98/100;
        break;
      case 'DONE':
        this.mappingProgressBarValue = 100/100;
        break;
    }
  }

  toggleSection(section) {
    switch(section) {
      case 'odu':
        if(this.oduShown) {
          this.oduShown = false;
        } else {
          this.oduShown = true;
        }
        break;
      case 'idu':
        if(this.iduShown) {
          this.iduShown = false;
        } else {
          this.iduShown = true;
        }
        break;
      case 'lc':
        if(this.lcShown) {
          this.lcShown = false;
        } else {
          this.lcShown = true;
        }
        break;
      case 'other':
        if(this.otherShown) {
          this.otherShown = false;
        } else {
          this.otherShown = true;
        }
        break;
    }
  }
}