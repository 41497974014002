import { SelectedGroup } from "src/app/common/components/group-selector/group-selector.component"
import { GatewayUnit, Power } from "src/app/features/manage/components/classes/GatewayUnit"
import { ControlDisplay } from "./controlDisplay"
import { ControlSection } from "./controlSection"

export class PowerControl extends ControlSection {
    // represents he on|off option for an IDU and LC

    label: string                   // the text to display beside the control
    on: ControlDisplay              // how to render the ON button
    off: ControlDisplay             // how to render the OFF button


    constructor() {
        // call parent ctor
        super();
        // define our Controls
        this.on = new ControlDisplay;
        this.off = new ControlDisplay;
     }

    resetSectionCounters() {
        // set our couters to zero
        this.on.reset();
        this.off.reset();
    }

    addGroupToBatch(unit: GatewayUnit) {
        // include this groups power state into our state counters
        if (unit.isPowerOn()) this.on.addSelected();
        else this.off.addSelected();
    }

    setDisplayOptions(groupCount: number, maintenanceJobActive: boolean) {
        // called when all groups are added and we should set our controllable/display states
        this.on.setDisplayOptions(groupCount, maintenanceJobActive);
        this.off.setDisplayOptions(groupCount, maintenanceJobActive);
        this.label = (this.on.selected ) ? 'On' : (this.off.selected) ? 'Off' : 'Mixed';
    }
}
