import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppMessageTypeCode } from 'src/app/enumerations/enums';

@Component({
  selector: 'app-app-message-unauth',
  templateUrl: './app-message-unauth.component.html',
  styleUrls: ['./app-message-unauth.component.scss'],
})
export class AppMessageUnauthComponent implements OnInit {
  appMessageTypeCode= AppMessageTypeCode;
  app_message_type: AppMessageTypeCode;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if ((this.activatedRoute.snapshot.paramMap.has('appmessagetype'))
      ) {
        const app_message_type_str: string = this.activatedRoute.snapshot.paramMap.get('appmessagetype');
        this.app_message_type = Number(app_message_type_str);
      }
    });
  }
}
