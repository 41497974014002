import { ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { ModelState, LevelEnum } from 'src/app/enumerations/enums';
import { Account } from 'src/app/features/account/classes/account';
import { SiteMemberRoles } from 'src/app/constants/sitememberroles';
import { Logger } from 'src/app/common/services/logging/log.service';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SiteMemberInvitee } from 'src/app/features/sites/classes/site-member-invitee';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ToggleButtonClickabilityDetails } from 'src/app/common/directives/ToggleButtonClickability/toggle-button-clickability.directive';
import {CustomValidators, emailRegex} from "../../../../constants/validators";

@Component({
  selector: 'app-site-member-admin',
  templateUrl: './site-member-admin.page.html',
  styleUrls: ['./site-member-admin.page.scss']
})

export class SiteMemberAdminPage implements OnInit {
  @Input() parentModelState: ModelState;
  @Input() parentSelectedMember: Account;
  selectedAccount: Account;
  formHasErrors = false;
  pageTitle = '';
  siteMemberAdminForm: UntypedFormGroup;
  adminButtonText = '';
  siteMemberRoles: any[] = [];
  success = true;
  displayErrorMessage = '';
  logger: Logger;
  isReadyForSubmission = false;
  emailInputClass = 'me-ion-input-invalid';
  public toggleButtonClickabilityDetails: ToggleButtonClickabilityDetails = {
    reenableButton: new EventEmitter<number>(),
    wait_time_in_milliseconds: 5000,
  }

  newSiteMemberInviteelist: SiteMemberInvitee[] = [
    {
      ui_rowid: (new Date).getTime(),
      site_id: this.user.active.id,
      accound_Id: '',
      level: 0,
      siteMemberEmail: '',
      siteMemberRole: 'Select a Role',
      isValidAccount: true,
      isSiteMember: false,
      isValid: false,
      isRemoved: false
    }
  ];

  constructor(
    private modalController: ModalController,
    private loading: LoadingController,
    private siteService: SiteService,
    private user: UserService,
    private mainSiteUIService: MainSiteUIService,
    public formBuilder: UntypedFormBuilder,
  ) {
    // Empty
  }

  ngOnInit() {
    this.logger = new Logger();
    this.filterRoleListByUserLevel(this.user.activeSiteUserLevel);
    this.initializeSiteMemberAdminForm();
    this.createSiteMemberAdminForm();
  }

  createSiteMemberAdminForm() {
    const initialRowId = this.newSiteMemberInviteelist[0].ui_rowid;
    this.siteMemberAdminForm = this.formBuilder.group({});
    this.siteMemberAdminForm.addControl('email' + initialRowId,
      new UntypedFormControl('',
        {
          validators: [Validators.required,
          CustomValidators.validateEmail,
          Validators.maxLength(50)],
        },
      )
    )
  }

  initializeSiteMemberAdminForm() {
    switch (this.parentModelState) {
      case ModelState.Create:
        this.pageTitle = 'Invite a Member';
        this.adminButtonText = 'Invite';
        break;
    }
  }

  filterRoleListByUserLevel(userLevel: LevelEnum) {
    switch (userLevel) {
      case LevelEnum.Unknown:
        this.siteMemberRoles = [];
        break;

      case LevelEnum.Viewer:
        this.siteMemberRoles = [];
        break;

      case LevelEnum.User:
        this.siteMemberRoles = [];
        break;

      case LevelEnum.Administrator:
        this.siteMemberRoles = SiteMemberRoles.list.filter(
          role =>
            role.id !== LevelEnum.Owner && role.id !== LevelEnum.Administrator
        );
        break;

      case LevelEnum.Owner:
        this.siteMemberRoles = SiteMemberRoles.list.filter(
          role => role.id !== LevelEnum.Owner
        );
        break;
    }
    // sort
    this.siteMemberRoles.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    this.siteMemberRoles.unshift({
      'id': '0',
      'name': 'Select a Role'
    })

  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  getEmailControl(ui_rowId) {
    const controlName = `email` + ui_rowId;
    return this.siteMemberAdminForm.controls[controlName];
  }

  anyControlsHaveErrors() {
    let allFormControls = Object.values(this.siteMemberAdminForm.controls);
    let activeAccountEmails = this.user.activeSiteAccounts.map(acc => acc.email.toLowerCase());
    let activeInviteEmails = this.user.activeSiteInvites.map(inv => inv.email.toLowerCase());

    allFormControls.forEach(ctrl => {
      let controlEmail = ctrl.value.toLowerCase();
      let accountEmailExists = activeAccountEmails.some(activeAccEmail => activeAccEmail == controlEmail);
      let inviteEmailExists = activeInviteEmails.some(activeInvEmail => activeInvEmail == controlEmail);
      if (accountEmailExists) {
        ctrl.setErrors({ exists: true });
      } else if (inviteEmailExists) {
        ctrl.setErrors({ invited: true });
      }
    });

    let allFormErrors = allFormControls.map(ctrl => ctrl.errors).filter(err => err && Object.keys(err).length > 0);
    this.formHasErrors = allFormErrors.length > 0;
    this.isReadyForSubmission = this.isReadyForSubmit();

    return allFormErrors;
  }

  async updateNewSiteMemberList(ui_rowid, updateColumn, e) {
    let rowIndex = 0;
    for (rowIndex = 0; rowIndex < this.newSiteMemberInviteelist.length; rowIndex++) {
      if (this.newSiteMemberInviteelist[rowIndex].ui_rowid === ui_rowid) {
        switch (updateColumn) {
          case 'siteMemberEmail':
            e.target.value = e.target.value.replace(/ /g, '');
            this.newSiteMemberInviteelist[rowIndex].siteMemberEmail = e.target.value;
            this.newSiteMemberInviteelist[rowIndex].isValid = !!e.target.value.match(emailRegex);

            if (this.newSiteMemberInviteelist[rowIndex].isValid)
              await this.siteService
                .getAccountSitePermission(
                  this.user.active.id,
                  this.newSiteMemberInviteelist[rowIndex].siteMemberEmail
                )
                .toPromise()
                .then(kenzaPermission => {
                  if (kenzaPermission.invited) {
                    this.newSiteMemberInviteelist[rowIndex].accound_Id = '';
                    this.newSiteMemberInviteelist[rowIndex].isValidAccount = false;
                    this.newSiteMemberInviteelist[rowIndex].isSiteMember = false;
                    this.newSiteMemberInviteelist[rowIndex].isRemoved = false;
                  } else if (kenzaPermission.account_id && kenzaPermission.site_id && kenzaPermission.removed_at) {
                    this.newSiteMemberInviteelist[rowIndex].accound_Id = kenzaPermission.account_id;
                    this.newSiteMemberInviteelist[rowIndex].isValidAccount = true;
                    this.newSiteMemberInviteelist[rowIndex].isSiteMember = true;
                    this.newSiteMemberInviteelist[rowIndex].isRemoved = true;
                  } else if (kenzaPermission.account_id && kenzaPermission.site_id) {
                    this.newSiteMemberInviteelist[rowIndex].accound_Id = kenzaPermission.account_id;
                    this.newSiteMemberInviteelist[rowIndex].isValidAccount = true;
                    this.newSiteMemberInviteelist[rowIndex].isSiteMember = true;
                    this.newSiteMemberInviteelist[rowIndex].isRemoved = false;
                  } else if (kenzaPermission.account_id && !kenzaPermission.site_id) {
                    this.newSiteMemberInviteelist[rowIndex].accound_Id = kenzaPermission.account_id;
                    this.newSiteMemberInviteelist[rowIndex].isValidAccount = true;
                    this.newSiteMemberInviteelist[rowIndex].isSiteMember = false;
                    this.newSiteMemberInviteelist[rowIndex].isRemoved = false;
                  } else if (!kenzaPermission.account_id) {
                    this.newSiteMemberInviteelist[rowIndex].accound_Id = '';
                    this.newSiteMemberInviteelist[rowIndex].isValidAccount = true;
                    this.newSiteMemberInviteelist[rowIndex].isSiteMember = false;
                    this.newSiteMemberInviteelist[rowIndex].isRemoved = false;
                  }
                  this.isReadyForSubmission = this.isReadyForSubmit();
                  this.anyControlsHaveErrors();
                })
                .catch(error => {
                  this.newSiteMemberInviteelist[rowIndex].accound_Id = '';
                  this.newSiteMemberInviteelist[rowIndex].isValid = true;
                  this.newSiteMemberInviteelist[rowIndex].isValidAccount = false;
                  this.newSiteMemberInviteelist[rowIndex].isSiteMember = false;
                  this.isReadyForSubmission = this.isReadyForSubmit();
                  this.anyControlsHaveErrors();
                });
            else this.isReadyForSubmission = this.isReadyForSubmit();
            break;

          case 'siteMemberRole':
            this.newSiteMemberInviteelist[rowIndex].level = e.target.value;
            this.isReadyForSubmission = this.isReadyForSubmit();
            break;
        }
      }
    }
  }

  isReadyForSubmit(): boolean {
    let result = true;
    let rowIndex = 0;

    if (
      !this.newSiteMemberInviteelist ||
      this.newSiteMemberInviteelist.length == 0
    ) {
      return false;
    }

    for (
      rowIndex = 0;
      rowIndex < this.newSiteMemberInviteelist.length;
      rowIndex++
    ) {
      if (
        // this.newSiteMemberInviteelist[rowIndex].isValid == false ||
        this.newSiteMemberInviteelist[rowIndex].isValidAccount == false ||
        this.newSiteMemberInviteelist[rowIndex].level == 0
      ) {
        result = false;
      } else if (
        this.newSiteMemberInviteelist[rowIndex].isValidAccount == true &&
        this.newSiteMemberInviteelist[rowIndex].isSiteMember == true &&
        this.newSiteMemberInviteelist[rowIndex].isRemoved == false
        ) {
        result = false;
      }
    }

    let latestEmailsToSendInvitesTo = Object.values(this.siteMemberAdminForm.controls).map(x => x?.value.toLowerCase());
    if (latestEmailsToSendInvitesTo.indexOf(``) !== -1) result = false;
    else {
      let hasDuplicates = new Set(latestEmailsToSendInvitesTo).size !== latestEmailsToSendInvitesTo.length;
      latestEmailsToSendInvitesTo.forEach((x, i, a) => {
        if (a.indexOf(x) !== i || hasDuplicates) {
          result = false;
          this.displayErrorMessage = `Duplicate emails detected`;
          this.success = false;
        } else {
          this.displayErrorMessage = ``;
          this.success = true;
        }
      });
    }

    if (this.siteMemberAdminForm.status == `INVALID`) result = false;

    return result;
  }

  compareWithFn = (o1, o2) => {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };

  addInviteeRow() {
    const rowid = (new Date).getTime();
    const newInvitee: SiteMemberInvitee = {
      ui_rowid: rowid,
      site_id: this.user.active.id,
      accound_Id: '',
      level: 0,
      siteMemberEmail: '',
      siteMemberRole: 'Select a Role',
      isValidAccount: true,
      isSiteMember: false,
      isValid: false,
      isRemoved: false
    };

    // add the validator for the new field
    this.siteMemberAdminForm.addControl('email' + rowid,
        new UntypedFormControl('',
            {
              validators: [Validators.required,
                CustomValidators.validateEmail,
                Validators.maxLength(50)],
            },
        )
    );

    this.newSiteMemberInviteelist.push(newInvitee);
    this.anyControlsHaveErrors();
  }

  removeInvitee(ui_rowid) {
    this.newSiteMemberInviteelist = this.newSiteMemberInviteelist.filter(
      nm => nm.ui_rowid !== ui_rowid
    );
    // remove the validator for the row removed...
    this.siteMemberAdminForm.removeControl('email' + ui_rowid);

    this.anyControlsHaveErrors();
  }

  onCancel() {
    this.modalController.dismiss({ xcancelled: true });
  }

  closeModal() {
    this.modalController.dismiss({
      modelState: ModelState.None,
      adminMember: null,
      cancelled: false
    });
  }

  async onInviteSiteMembers(e) {

    if (!this.siteService.handleIsConnected()) return;

    this.isReadyForSubmission = false;

    const load = await this.loading.create({
      spinner: 'lines',
      message:
        this.parentModelState == ModelState.Create
          ? 'Inviting site members...'
          : '...'
    });

    load.present();

    switch (this.parentModelState) {
      case ModelState.Create:
        await this.siteService
          .inviteSiteMembers(this.newSiteMemberInviteelist)
          .toPromise()
          .then(result => {
            this.toggleButtonClickabilityDetails.reenableButton.emit();

            const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
            siteAlertFilter.site_id = this.user.active.id;
            siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

            this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
            this.user.refreshSiteAccounts(this.user.active.id);
            this.closeModal();

          }).catch(err => {
            load.dismiss();
            // dismiss model if critical site error
            if (err.message.includes("kenza rule")) this.modalController.dismiss({ modelState: ModelState.None });
          });
        break;
    }
    load.dismiss();
    this.isReadyForSubmission = true;
  }
}