import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestDriveRoutingModule } from './testdrive-routing.module';
import { TestDriveListComponent } from '../../components/testdrive-list/testdrive-list.component';
import { TestDriveSettingsComponent } from '../../components/testdrive-settings/testdrive-settings.component';
import { TestDriveProgressComponent } from '../../components/testdrive-progress/testdrive-progress.component';
import { TestDriveHistoryComponent } from '../../components/testdrive-history/testdrive-history.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from 'src/app/common/modules/shared/shared.module';
import { MatTableModule } from '@angular/material/table' 

import { SelectTestRunMode } from '../../pages/selectMode/select-mode.page';
import { SelectTestRunTime } from '../../pages/selectTime/select-time.page';
import { TestDriveCancelNotificationComponent } from '../../modals/testdrive-cancel-notification/testdrive-cancel-notification.component';


@NgModule({
  declarations: [
    TestDriveListComponent,
    TestDriveSettingsComponent,
    TestDriveProgressComponent,
    TestDriveHistoryComponent,
    SelectTestRunMode,
    SelectTestRunTime,
    TestDriveCancelNotificationComponent
  ],
  imports: [
    CommonModule,
    TestDriveRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatTableModule,
  ]
})
export class TestDriveModule { }
