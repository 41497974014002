<ion-card me-ion-card-site-analytics>

    <ion-item class="cardHeader" lines="none">
        <ion-label me-card-header>
            <strong>{{ title }}</strong>
        </ion-label>
        <div *ngIf="hasPermissionToCheckBranchPort" 
            (click)="navigateToAnalyticsHistory()"
            title="View Analytics Report History"
            class="ion-icon-wrapper-card-header branchPortCheckNavigationArrow" 
        >
            <ion-icon id="viewAnalyticsReportHistory" name="arrow-forward" class="ion-padding-start" color="black" me-icon-nav></ion-icon>
        </div>
    </ion-item>

    <ion-card-content class="cardContent" color="white" me-top-border>
        <ion-grid>
            <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                    <div class>
                        <ion-icon name="list" style="font-size: 100px; color: black;"></ion-icon>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>&nbsp;</ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
                <ion-col size="auto" class="ion-no-padding">
                    <div>
                        <ion-button *ngIf="hasPermissionToCheckBranchPort" 
                            (click)="user.active.transfer_locked ? user.presentActionRestricted() : startBranchPortCheck()" 
                            [class]="user.active.transfer_locked ? 'site-transfer-disable-modal' : ''"
                            [disabled]="disableWhenActive && !completedProgressStatuses.includes(status)"
                        >
                            Start Check
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>

    <ion-row *ngIf="status != statuses.Ready" class="cardfooter" class="ion-no-padding">
        <ion-col class="statusIcon" size="1.5">
            <div title="{{ progressUpdateMessage }}">
                <ion-spinner *ngIf="status.icon == icons.spinner" color=primary me-spinner-icon name="lines"></ion-spinner>
                <ion-icon *ngIf="status.icon == icons.check" me-download-icon name="checkmark"></ion-icon>
                <ion-icon *ngIf="status.icon == icons.error" me-error-icon color="danger" name="close-circle"></ion-icon>
            </div>
        </ion-col>

        <ion-col class="statusMessageAndProgress" class="ion-text-center">
            <ion-label class="jobState jobStateMessage" italic title="{{ progressUpdateMessage }}">
                {{ progressUpdateMessage }}
            </ion-label>
            <ion-progress-bar me-progress-bar color="{{ errorOrCancelStatuses.includes(status) ? 'danger' : 'success' }}"
                value="{{ completedProgressStatuses.includes(status) ? 1 : branchPortsProgress }}" title="{{ progressUpdateMessage }}">
            </ion-progress-bar>
        </ion-col>
    </ion-row>

</ion-card>