<ion-header>
  <ion-toolbar>
    <ion-title>Move Gateway to a Different Site</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-center">

  <ion-grid class="ion-no-padding" style="width: 80%">
    <ion-text color="danger" class="modalMessage">
      You are moving this gateway to a site that does not
      have a payment method associated with it:
    </ion-text>
    <ion-text class="modalMessage modalMessageInner">
      We are not able to transfer the existing payment method for
      this gateway to this site. Enter a payment method which
      will be used for your site.
    </ion-text>
    <app-credit-card-form (formChange)="creditCardStatusDidChange($event)" #ccForm></app-credit-card-form>
    <ion-row>
      <ion-col class="ion-text-start ion-padding-top">
        <p style="padding-left: 10px;">
          Your new payment method will be charged on the first of next month.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>


</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-label *ngIf="moveError" style="flex:auto" color="danger" class="moveError">{{moveError}}</ion-label>
        <ion-button style="margin: 0 0 0 1em;" color="primary" (click)="moveGateway()" [disabled]='disableMoveButton'>
          Move
        </ion-button>
        <ion-button id="btnCancelMove" name="btnCancelMove" (click)="cancelMove()" style="margin: 0 0 0 1em;"
          color="reverse-primary">
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>