import { Injectable } from "@angular/core";
@Injectable()
export class Logger {

  //constructor() {}

  debug(...objects: any[]) {
    this.log(console.log, objects);
  }

  info(...objects: any[]) {
    this.log(console.info, objects);
  }

  warn(...objects: any[]) {
    this.log(console.warn, objects);
  }

  error(...objects: any[]) {
    this.log(console.error, objects);
  }

  log(func: () => void, objects: any[]) {
    func.apply(console, objects);
  }
}

const Log = new Logger();
export { Log };
