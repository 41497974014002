import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { ModelState, ToastMessageTypeEnum, GatewayUnitTwoDigitType, SelectionTypeEnum } from 'src/app/enumerations/enums';
import { GatewayGroup } from '../../../../../manage/components/classes/GatewayGroup';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GatewayUnit } from '../../../../../manage/components/classes/GatewayUnit';
import { Gateway } from '../../../../../manage/components/classes/Gateway';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { TOAST_GENERAL_ERROR_TITLE, GATEWAY_GROUP_UNABLE_TO_CREATE, GATEWAY_GROUP_UNABLE_TO_UPDATE, GATEWAY_GROUP_UNABLE_TO_DELETE } from 'src/app/constants/kenzaconstants';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { UserService } from 'src/app/common/services/user/user.service';

@Component({
  selector: 'app-group-admin',
  templateUrl: './group-admin.component.html',
  styleUrls: ['./group-admin.component.scss'],
})
export class GroupAdminComponent implements OnInit {
  @Input() group: GatewayGroup;
  @Input() selectedGateway: Gateway;
  @Input() group_model_state: ModelState;
  @Input() selected_outdoor_unit: GatewayUnit;
  @Input() selected_odu_groups: GatewayGroup[];
  @Input() selected_odu_indoor_units: GatewayUnit[];

  model_state = ModelState;
  saveEnabled = true;
  detectFormChanges: (any) = globalFunctions.detectFormChanges;
  title_text: string;
  save_button_text: string;
  cancel_button_text: string;
  groupForm: UntypedFormGroup;

  disable_idus = true;

  locationOptions: Array<string> = [];
  filteredLocationOptions: Array<string> = [];  

  constructor(
    private user: UserService,
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private siteService: SiteService,
    private loadingController: LoadingController,
  ) {

  }

  ngOnInit() {
    if (this.group_model_state == this.model_state.Update) this.saveEnabled = false;
    this.init_display(this.group_model_state, this.selected_odu_indoor_units, this.selected_odu_groups, this.group);
    this.groupForm_create();
    const initialUnitsSelected = this.selected_odu_indoor_units.filter(unit => unit.isSelected).length;
    this.detectFormChanges(this.groupForm);
    // setup to work with locations
    this.locationOptions = this.user.active.locations
    this.filteredLocationOptions = this.locationOptions
    this.groupForm.controls.group_location.valueChanges.subscribe(val => {
      // filter the list of options to present.
      this.filteredLocationOptions = this._filter(val);
    })    
  }

  _filter(val: any) {
    // filter the option list based on keyboard input
    return this.locationOptions.filter(location => location.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }  

  groupForm_create() {
    this.groupForm = this.formBuilder.group({
      group_name: [this.group ? this.group.group_name : '', Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
      ]),],
      group_location: [this.group ? this.group.location : '']
    });
  }

  init_display(model_state: ModelState, units: GatewayUnit[], selected_odu_groups: GatewayGroup[], selected_outdoor_unit_group: GatewayGroup) {
    this.save_button_text = "Save";
    this.cancel_button_text = "Cancel";
    const indoor_units: GatewayUnit[] = units.filter(idu => idu.type == GatewayUnitTwoDigitType.IndoorUnit);

    switch (model_state) {
      case ModelState.Create:
        this.title_text = "Add Group Information";

        this.selected_odu_indoor_units = this.filter_selected_odu_indoor_units_by_is_selected(SelectionTypeEnum.UnSelected, selected_odu_groups, selected_outdoor_unit_group);
        this.selected_odu_indoor_units.sort(compareValues('name', 'asc'));
        break;

      case ModelState.Update:
      case ModelState.Quick_Update:
        this.title_text = "Edit Group Information";

        this.selected_odu_indoor_units = this.filter_selected_odu_indoor_units_by_is_selected(SelectionTypeEnum.UnSelected, selected_odu_groups, selected_outdoor_unit_group);
        this.selected_odu_indoor_units = this.selected_odu_indoor_units.concat(this.filter_selected_odu_indoor_units_by_is_selected(SelectionTypeEnum.Selected, selected_odu_groups, selected_outdoor_unit_group));
        this.selected_odu_indoor_units = this.selected_odu_indoor_units.filter(idu => idu.type == GatewayUnitTwoDigitType.IndoorUnit);
        this.selected_odu_indoor_units.sort(compareValues('name', 'asc'));
        break;

      case ModelState.Delete:
        this.title_text = "Delete Group Information";
        this.save_button_text = "Yes";
        this.cancel_button_text = "No";

        this.selected_odu_indoor_units = this.filter_selected_odu_indoor_units_by_is_selected(SelectionTypeEnum.Selected, selected_odu_groups, selected_outdoor_unit_group);
        this.selected_odu_indoor_units.sort(compareValues('name', 'asc'));
        break;
    }
  }

  filter_selected_odu_indoor_units_by_is_selected(selection_type: SelectionTypeEnum, selected_odu_groups: GatewayGroup[], selected_outdoor_unit_group: GatewayGroup): GatewayUnit[] {
    let filtered_units: GatewayUnit[] = [];

    switch (selection_type) {
      case SelectionTypeEnum.UnSelected:
        selected_odu_groups.forEach(group => {
          if (group.autocreated) {
            if (group.units && group.units.length > 0) {
              filtered_units = filtered_units.concat(group.units);
            }
          }
        });

        filtered_units = filtered_units.map(unit => {
          unit.isSelected = false;
          return unit;
        });
        break;

      case SelectionTypeEnum.Selected:
        selected_odu_groups.forEach(group => {
          if ((group.autocreated == false) && (group.group_id === selected_outdoor_unit_group.group_id)) {
            if (group.units && group.units.length > 0) {
              filtered_units = filtered_units.concat(group.units);
            }
          }
        });

        filtered_units = filtered_units.map(idu => {
          idu.isSelected = true;
          return idu;
        });
        break;
    }

    return filtered_units.filter(idu => idu.type == GatewayUnitTwoDigitType.IndoorUnit);
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0
      //if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].localeCompare(b[key]);
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  setTrimmedValue(e) {
    const trimmedValue = e.target.value.trim();
    this.groupForm.controls.group_name.setValue(trimmedValue);
  }

  toggle_unit_selected_value(gateway_unit: GatewayUnit) {
    this.selected_odu_indoor_units = this.selected_odu_indoor_units.map(idu => {
      if (idu.id === gateway_unit.id) {
        idu.isSelected = !idu.isSelected;
        this.saveEnabled = true;
      }
      return idu;
    })
  }

  disable_save_button(): boolean {
    const count_of_selected_indoor_units: number = this.selected_odu_indoor_units.filter(idu => idu.isSelected).length;

    switch (this.group_model_state) {
      case ModelState.Create:
      case ModelState.Update:
      case ModelState.Quick_Update:
        if (this.groupForm.valid && (count_of_selected_indoor_units > 0)) {
          return false;
        }
        break;
      case ModelState.Delete:
        if (this.groupForm.valid) {
          return false;
        }
        break;
    }

    return true;
  }

  async on_save() {
    if (!this.siteService.handleIsConnected()) return;

    const group_units: GatewayUnit[] = this.selected_odu_indoor_units.filter(idu => idu.isSelected === true);

    const loading = await this.loadingController.create({
      message: this.group_model_state !== ModelState.Delete ? 'Saving group information...' : 'Deleting group information...',
      spinner: 'lines',
    });

    await loading.present();

    switch (this.group_model_state) {
      case ModelState.Create:
        this.siteService.create_gateway_group(this.selectedGateway.id, this.groupForm.controls.group_name.value, group_units, this.groupForm.controls.group_location.value).subscribe((new_gateway_group_data) => {
          // update the object in the user site list
          this.user.updateSiteLocations(this.user.active.id, new_gateway_group_data['site_locations'])
          this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Created);

          const new_gateway_group: GatewayGroup = new GatewayGroup();
          new_gateway_group.gateway_id = new_gateway_group_data['gateway_id'];
          new_gateway_group.group_id = new_gateway_group_data['group_id'];
          new_gateway_group.group_name = new_gateway_group_data['group_name'];
          new_gateway_group.isExpanded = false;
          this.selected_odu_groups.push(new_gateway_group);
          this.selected_odu_groups = this.selected_odu_groups.sort(this.compareValues('group_name', 'asc'));

          this.modalController.dismiss({
            cancelled: false,
            selected_gateway: this.selectedGateway,
            selected_odu_groups: this.selected_odu_groups,
          });

          loading.dismiss();

        }, err => {
          this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, TOAST_GENERAL_ERROR_TITLE, GATEWAY_GROUP_UNABLE_TO_CREATE);
          loading.dismiss();
        });
        break;

      case ModelState.Update:
      case ModelState.Quick_Update:
        this.siteService.update_gateway_group(this.selectedGateway.id, this.group.group_id, this.groupForm.controls.group_name.value, group_units, this.groupForm.controls.group_location.value).subscribe((updated_gateway_group_data) => {
          // update the object in the user site list
          this.user.updateSiteLocations(this.user.active.id, updated_gateway_group_data['site_locations'])
         
          if (this.group_model_state == ModelState.Update) {
            this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Updated);
          } else {
            this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Quick_Updated);
          }


          this.modalController.dismiss({
            cancelled: false,
            selected_gateway: this.selectedGateway,
            selected_odu_groups: this.selected_odu_groups,
          });

          loading.dismiss();
        }, err => {
          loading.dismiss();
        });

        break;

      case ModelState.Delete:
        this.siteService.delete_gateway_group(this.selectedGateway.id, this.group.group_id).subscribe((deleted_gateway_group_data: any) => {
          // update the object in the user site list
          this.user.updateSiteLocations(this.user.active.id, deleted_gateway_group_data['site_locations'])
          this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Deleted);
          this.selected_odu_groups = this.selected_odu_groups.filter(group => group.group_id !== this.group.group_id);
          this.selected_odu_groups = this.selected_odu_groups.sort(this.compareValues('group_name', 'asc'));

          this.modalController.dismiss({
            cancelled: false,
            selected_gateway: this.selectedGateway,
            selected_odu_groups: this.selected_odu_groups,
          });

          loading.dismiss();
        }, err => {
          this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, TOAST_GENERAL_ERROR_TITLE, GATEWAY_GROUP_UNABLE_TO_DELETE);
          loading.dismiss();
        });

        break;


    }
  }

  on_cancel() {
    if (this.group_model_state != ModelState.Quick_Update) {
      this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Updated);
    }


    this.modalController.dismiss({
      cancelled: false,
      selected_gateway: this.selectedGateway,
      selected_odu_groups: this.selected_odu_groups,
    });

  }

}
