
<ion-card id="dashboardAlertsCard" class="dashboardAlertsCard" me-dashboard-card>

  <ion-item lines="none" no-padding>
    <ion-label class="me-header">
      <strong>Site Alerts</strong>
    </ion-label>

    <div class="ion-icon-wrapper-card-header" (click)="navigateToSiteAlerts()" title="To Alerts">
      <ion-icon name="arrow-forward-outline" slot="end" me-icon-nav color="black"></ion-icon>
    </div>
  </ion-item>

  <ion-card-content me-top-border>
    <ion-grid id="siteAlertsCardGrid" class="siteAlertsCardGrid ion-no-padding">
      <ion-row>
        <ion-col>
          <div class="chartWrapper">
            <div id="chartContainer" class="chartContainer"></div>
          </div>
        </ion-col>
      </ion-row>

      <ion-grid class="siteAlertButtonsGrid">
        <!-- <ion-row class="siteAlertButtonsRow">
          <ion-col class="ion-no-padding me-alert-label">
            <span class="equipment-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Equipment)">
              <span class="useBlue linkLike">Equipment ({{ mainSiteUIService.siteAlertMetricsData.v2RawEquipmentActCount }})</span>
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding me-alert-label">
            <span class="equipment-notification-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.EquipmentNotification)">
              <span class="useBlue linkLike">EquipmentNotification ({{
                mainSiteUIService.siteAlertMetricsData.v2RawEquipmentNotificationActCount }})</span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="siteAlertButtonsRow">
          <ion-col class="ion-no-padding me-alert-label">
            <span class="membership-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Membership)">
              <span class="useBlue linkLike">Membership ({{ mainSiteUIService.siteAlertMetricsData.v2RawMembershipActCount }})</span>
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding me-alert-label">
            <span class="gateway-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Gateway)">
              <span class="useBlue linkLike">Gateway ({{ mainSiteUIService.siteAlertMetricsData.v2RawGatewayActCount }})</span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="siteAlertButtonsRow">
          <ion-col class="ion-no-padding me-alert-label">
            <span class="payment-square" *ngIf="user.activeSiteUserLevel===levelEnum.Owner">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Payments)"
              *ngIf="user.activeSiteUserLevel===levelEnum.Owner">
              <span class="useBlue linkLike">Payment ({{ mainSiteUIService.siteAlertMetricsData.v2RawPaymentActCount }})</span>
            </ion-button>
          </ion-col>
        </ion-row> -->

        <ion-row class="siteAlertButtonsRow">
          <ion-col class="ion-no-padding me-alert-label catigory-label">
            <span class="equipment-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Equipment)">
              <span class="useBlue linkLike">Equipment ({{ mainSiteUIService.siteAlertMetricsData.v2RawEquipmentActCount }})</span>
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding me-alert-label catigory-label">
            <span class="gateway-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Gateway)">
              <span class="useBlue linkLike">Gateway ({{ mainSiteUIService.siteAlertMetricsData.v2RawGatewayActCount }})</span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="siteAlertButtonsRow">
          <ion-col class="ion-no-padding me-alert-label catigory-label">
            <span class="membership-square">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Membership)">
              <span class="useBlue linkLike">Membership ({{ mainSiteUIService.siteAlertMetricsData.v2RawMembershipActCount }})</span>
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding me-alert-label catigory-label">
            <span class="payment-square" *ngIf="user.activeSiteUserLevel===levelEnum.Owner">&#9632;</span>
            <ion-button appHighlightButton legend-button fill='clear'
              (click)="viewAlerts(siteNotificationTypeEnum.Payments)"
              *ngIf="user.activeSiteUserLevel===levelEnum.Owner">
              <span class="useBlue linkLike">Payment ({{ mainSiteUIService.siteAlertMetricsData.v2RawPaymentActCount }})</span>
            </ion-button>
          </ion-col>

        </ion-row>
      </ion-grid>

      <ion-row id="noAlertZeroStateMsgCont"
        *ngIf="mainSiteUIService.siteAlertMetricsData.v2RawTotalActCount === 0">
        There are no alerts currently on your site.
      </ion-row>

    </ion-grid>
  </ion-card-content>
  <ion-card *ngIf="mainSiteUIService.siteAlertMetricsData.v2RawTotalActCount > 0"
    class="alerts-card ion-no-padding ion-no-margin {{ mainSiteUIService.siteAlertMetricsData.v2RawTotalActCount >= 3 ? 'moreList' : (mainSiteUIService.siteAlertMetricsData.v2RawTotalActCount == 2 ? 'twoList' : 'oneList') }}">
    <ion-card-content class="ion-no-padding ion-no-margin" me-top-border>
      <ion-item *ngFor="let sn of mainSiteUIService.siteAlertMetricsData.v2RawActSiteNotifications"
        class="ion-no-padding">
  
        <!-- Equipment Alarm -->
        <div *ngIf="sn.sitenotificationtype_id==siteNotificationTypeEnum.Equipment" style="width:100%">
          <div *ngIf="user.activeSiteUserLevel!==levelEnum.Viewer" style="width:100%"
            (click)="navigateToSiteNotificationByType(sn.sitenotificationtype_id,sn.id)"
            [routerLink]="['/site', sn.site_id, 'alerts', 'equipment', sn.id]" allow-item-nav>
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.context ? 'Error Code: ' + sn.context.error_code : sn.notification}}
            </ion-label>
          </div>
          <div *ngIf="user.activeSiteUserLevel===levelEnum.Viewer" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.context ? 'Error Code: ' + sn.context.error_code : sn.notification}}
            </ion-label>
          </div>
        </div>
  
        <!-- Membership Notification -->
        <div *ngIf="sn.sitenotificationtype_id==siteNotificationTypeEnum.Membership" style="width:100%">
          <div *ngIf="user.activeSiteUserLevel!==levelEnum.Viewer" allow-item-nav
            (click)="navigateToSiteNotificationByType(sn.sitenotificationtype_id,sn.id)"
            [routerLink]="['/site', sn.site_id, 'alerts', 'notification', sn.id]" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
          <div *ngIf="user.activeSiteUserLevel===levelEnum.Viewer" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
        </div>
  
        <!-- Gateway Notification -->
        <div *ngIf="sn.sitenotificationtype_id==siteNotificationTypeEnum.Gateway" style="width:100%">
          <div *ngIf="user.activeSiteUserLevel!==levelEnum.Viewer" allow-item-nav
            (click)="navigateToSiteNotificationByType(sn.sitenotificationtype_id,sn.id)"
            [routerLink]="['/site', sn.site_id, 'alerts', 'notification', sn.id]" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
          <div *ngIf="user.activeSiteUserLevel===levelEnum.Viewer" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
        </div>
  
        <!-- Payment Notification -->
        <div
          *ngIf="(sn.sitenotificationtype_id==siteNotificationTypeEnum.Payments) && (user.activeSiteUserLevel===levelEnum.Owner)"
          allow-item-nav (click)="navigateToSiteNotificationByType(sn.sitenotificationtype_id,sn.id)"
          [routerLink]="['/site', sn.site_id, 'alerts', 'notification', sn.id]" style="width:100%">
          <ion-label class="alert-label-hdr">
            {{ getNotificationHeader(sn) }}
          </ion-label>
          <ion-label class="alert-label">
            {{sn.notification}}
          </ion-label>
        </div>
  
        <!-- Equipment Notification -->
        <div *ngIf="sn.sitenotificationtype_id==siteNotificationTypeEnum.EquipmentNotification" style="width:100%">
          <div *ngIf="user.activeSiteUserLevel!==levelEnum.Viewer" allow-item-nav
            (click)="navigateToSiteNotificationByType(sn.sitenotificationtype_id,sn.id)"
            [routerLink]="['/site', sn.site_id, 'alerts', 'notification', sn.id]" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
          <div *ngIf="user.activeSiteUserLevel===levelEnum.Viewer" style="width:100%">
            <ion-label class="alert-label-hdr">
              {{ getNotificationHeader(sn) }}
            </ion-label>
            <ion-label class="alert-label">
              {{sn.notification}}
            </ion-label>
          </div>
        </div>

      </ion-item>
    </ion-card-content>
  
  </ion-card>

  <ion-grid id="siteAlertsCardBottom" class="siteAlertsCardBottom cardBottomFooter noIonPadding automationTesting {{ user ? 'cardDataLoaded' : 'cardDataLoading' }}">
    <!-- Empty Container for Automation Locators // Identifiers -->
  </ion-grid>

</ion-card>