/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationEnd, Router } from "@angular/router";
import { Component, ElementRef, OnInit } from "@angular/core";
import { AlertController, IonCheckbox, ModalController } from "@ionic/angular";
import {
  ECharts,
  EChartsOption,
  GridComponentOption,
  init,
  LineSeriesOption,
  SeriesOption,
  YAXisComponentOption,
  XAXisComponentOption,
  DataZoomComponentOption,
} from "echarts";
import { Subscription } from "rxjs";
import { CustomObservers } from "src/app/common/classes/observers";
import { GraphService } from "src/app/common/services/graph/GraphService";
import { AppStorageService } from "src/app/common/services/storage/app-storage.service";
import { UserService } from "src/app/common/services/user/user.service";
import { PressureConversions, TemperatureConversions } from "src/app/common/utilities/conversionUtilities";
import {
  GatewayUnitTwoDigitType,
  PressurePreferenceEnum,
  SubscriptionFeatures,
  TemperaturePreferenceEnum,
} from "src/app/enumerations/enums";
import { Gateway } from "src/app/features/manage/components/classes/Gateway";
import { GatewayUnit } from "src/app/features/manage/components/classes/GatewayUnit";
import { Site } from "../../classes/site";
import { SelectGatewayPage } from "../../pages/select-gateway/select-gateway.page";
import { SiteService } from "../../services/site.service";
import { date_time_utilities } from "src/app/common/utilities/datetimeUtilities";

@Component({
  selector: "app-site-monitor",
  templateUrl: "./site-monitor.component.html",
  styleUrls: ["./site-monitor.component.scss"],
})

export class SiteMonitorComponent implements OnInit {
  viewState: SiteMonitorViewStateObject = new SiteMonitorViewStateObject();

  siteGateways: Gateway[] = [];

  navigationSubscription: Subscription;
  onActiveSiteChangedSubscription: Subscription;
  siteGatewayChanged: Subscription;

  selectedGatewayName = "Select a Gateway";
  selectedGateway: Gateway = null;
  selectedGatewayUnits: GatewayUnit[] = [];
  selectedUnit: GatewayUnit = null;
  selectedDatapoint: string = null;

  unitData = null;
  dataPoints = [];

  unit = {
    form: null
  }

  chart: ECharts = null;
  chartElmSelector = "#chartContainer";
  chartOptions: EChartsOption = null;

  chartedDatapoints: Array<string> = null;

  iduMapping: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();
  oduMapping: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

  showMode = false;


  initialLoad = true;

  modelInfo = null;

  selectorsDisabled = false;

  isMxzSm = false;

  // series that are 'special' and have their own creation functions.  Ignored for min/max of y-axis  
  SPECIAL_SERIES = ["Mode", "State"];

  modes = [
    "Cooling",
    "Heating",
    "Dry",
    "Fan",
    "Auto",
    "Setback",
    "null_vals"
  ];

  states = [
    "On",
    "Stand by",
    "Stop"
  ]

  modeColors = {
    fan: `#00FF00`,
    dry: `#FFA600`,
    heating: `#ff0000`,
    cooling: `#1e90ff`,
    auto: `#FF00FF`,
    setback: `#00b7b9`,
    null_vals: `#FFFFFF`
  };

  stateColors = {
    on: `#00FF00`,
    stand_by: `#FFA600`,
    off: `#ff0000`,
  };

  colorMapping = {
    TH1: "#7b241c",
    SC: "#e74c3c",
    SH: "#633974",
    TO: "#8e44ad",
    TH2: "#1a5276",
    TH3: "#3498db",
    TH4: "#d35400",
    Li: "#117864",
    Fan: "#45b39d",
    State: "rgba(0,0,0,0)", // custom graphing so main dataseries used for tooltip is blank
    Mode: "rgba(0,0,0,0)", // custom graphing so main dataseries used for tooltip is blank
  };

  /* Settings some values configuration values */
  // Mode Hidden
  gridNormal: GridComponentOption = { left: '50px', bottom: '70px', right: '6px', top: '6px', height: 'auto' };
  gridModeHidden: GridComponentOption = { left: '50px', bottom: '0%', right: '6px', height: '0%' };  // just want it off the chart

  // Mode shown
  gridModeShown: GridComponentOption = { left: '50px', bottom: '105px', right: '6px', top: '6px', height: 'auto' };
  gridModeChart: GridComponentOption = { left: '50px', bottom: '55px', right: '6px', height: '25px' };

  // ODU
  gridOdu: GridComponentOption = { left: '50px', bottom: '70px', right: '6px', top: '6px', height: 'auto' };

  // IDU xaxis
  mainXAxis: any = {
    gridIndex: 0, // main chart
    type: "time",
    boundaryGap: false,
    animation: false
  };
  modeXAxis: any = {
    gridIndex: 1, // mode chart
    type: "time",
    boundaryGap: false,
    animation: false,
    axisLabel: {
      show: false
    }
  };

  mainYAxis: YAXisComponentOption = { gridIndex: 0 };
  modeYAxis: YAXisComponentOption = { gridIndex: 1, show: false };

  iduDataZoom :DataZoomComponentOption = {
    type: "slider",
    start: 0,
    end: 100,
    xAxisIndex: [0, 1],
  };

  normalDataZoom: DataZoomComponentOption = {
    type: "slider",
    start: 0,
    end: 100,
    xAxisIndex: [0],
  };


  TEMPERATURE_MARKS = ['TH1','TH2','TH3','TH4','TH5','TH6','TH7','SC','SH','TO', 'THHS', 'Tc', 'Te']
  PRESSURE_MARKS = ['63HS1', '63LS', '63HS']

  IDU_DEFAULT_DATAPOINTS = ['TH1', 'Mode', 'TO', 'State']
  ODU_DEFAULT_DATAPOINTS = ['63HS1', '63HS', '63LS', 'TH4', 'F(Hz)', 'FAN']

  constructor(
    private user: UserService,
    private appStorageService: AppStorageService,
    private graphService: GraphService,
    private router: Router,
    private siteService: SiteService,
    private modalController: ModalController,
    private elm: ElementRef,
    private alertController: AlertController
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.user && this.user.active) {
          this.refreshGatewayListByActiveSite(this.user.active.id);
        }
      }
    });

    this.onActiveSiteChangedSubscription =
      this.user.activeSiteChanged.subscribe((activeSite: any) => {
        if (activeSite && activeSite.id) {
          this.refreshGatewayListByActiveSite(activeSite.id);
          this.cleanUp();
          this.viewState.showSelectGateway();
        }
      });

    this.siteGatewayChanged = this.siteService.siteGatewayChanged.subscribe(
      (res) => {
        this.refreshGatewayListByActiveSite(this.user.active.id);
      }
    );

    this.siteService.getAllModelInfo().then((resp) => {
      // need to map the datapoints from MCC to the model info
      this.modelInfo = resp;
      this.mapIduDataPoints();

    });

    this.refreshGatewayListByActiveSite(this.user.active.id);
  }

  ngOnInit(): void {}

  ionViewDidLeave() {
    // reset these when leaving so that if coming back and off the stack the page resets
    this.selectedGatewayName = "Select a Gateway";
    this.selectedGateway = null;
    this.initialLoad = true;
    this.viewState.showSelectGateway();
    this.unit.form = null;
  }


  mapDataPoints(datapoints, mapping) {
    let mapFilter = (mapArray, val) => {
      let obj = null;
      mapArray.forEach((el) => {
        if (el["mark"] === val) {
          obj = el;
        }
      });

      return obj;
    };
    for(let i = 0; i < datapoints.length; i++) {
      let dp = datapoints[i];
      mapping.set(dp['mark'], mapFilter(datapoints, dp['mark']));
    }
  }

  mapIduDataPoints() {
    const iduDataPoints: Array<Map<string, string>> = this.modelInfo[GatewayUnitTwoDigitType.IndoorUnit]["series"][0]["type"][0]["maintaince_data"];
    this.mapDataPoints(iduDataPoints, this.iduMapping);
    
  }

  mapOduDataPoints() {
    // Have to to get the currect data points from the models list
    let seriesIdx = null;
    const seriesArr = this.modelInfo[GatewayUnitTwoDigitType.OutdoorUnit]["series"];
    for(let i = 0; i < seriesArr.length; i++) {
      const seriesObj = seriesArr[i];
      if (seriesObj['name'] === this.selectedUnit.series) {
        seriesIdx = i;
        break;
      }
    }

    if (seriesIdx == null) {
      this.viewState.showSetModelAndSeries();
      this.selectorsDisabled = false;
      throw new Error("Series not found, probably need to set in the GW details");
    }

    let modelIdx = null;
    const typeArr =  this.modelInfo[GatewayUnitTwoDigitType.OutdoorUnit]["series"][seriesIdx]["type"];
    for(let i = 0; i < typeArr.length; i++) {
      let modelsStr: string = typeArr[i]["maintaince_data"][0]['model'];
      if (modelsStr == null || modelsStr == undefined) {
        modelsStr = typeArr[i]["maintaince_data"][0]['units'];
      }
      const modelArr = modelsStr.split(",")

      for(let j = 0; j < modelArr.length; j++) {
        let model = modelArr[j];
        if (model === this.selectedUnit.model) {
          modelIdx = i;
          break;
        }
      }
    }

    if (modelIdx == null) {
      this.viewState.showSetModelAndSeries();
      this.selectorsDisabled = false;
      throw new Error("Model not found, probably need to set in the GW details");
    }

    const oduDataPoints: Array<Map<string, string>> = this.modelInfo["OU"]["series"][seriesIdx]["type"][modelIdx]["maintaince_data"];
    this.mapDataPoints(oduDataPoints, this.oduMapping);
  }

  getDate(index, dataArr) {
    const obj = dataArr[index];
    let timeStr: string = obj['time'];
    if(timeStr.includes("+")) {
      timeStr = timeStr.split("+")[0];
    }
    return new Date(timeStr + "Z");
  }

  getVal(index, dataArr) {
    const obj = dataArr[index];
    let val = obj["value"];
    if(val === '') {
      val = null;
    }
    return val;
  }

  makeModeSeriesData() {
    const arr = this.unitData["Mode"];
    // This is a tricky one, I can't do a line segement change with Echarts so I need to make series for each mode and then set the area
    // for each of them.  Going to keep an empty one for the tooltip though.
    // Setup a data struct
    const modeSeriesData = new Map();
    this.modes.forEach((m) => {
      modeSeriesData.set(m, []);
    });
    for (let i = 0; i < arr.length; i++) {
      const date = this.getDate(i, arr);
      const val: string = this.getVal(i, arr);

      this.modes.forEach((m) => {
        if (val == null) {
          const dp = [date, 10];
          modeSeriesData.get("null_vals").push(dp);
        }else if (val.startsWith(m)) {
          const dp = [date, 10];
          modeSeriesData.get(m).push(dp);
        } else {
          const dp = [date, 0];
          modeSeriesData.get(m).push(dp);
        }
      });
    }

    return modeSeriesData;
  }

  makeStateSeriesData() {
    const arr = this.unitData["State"];
    // This is a tricky one, I can't do a line segement change with Echarts so I need to make series for each mode and then set the area
    // for each of them.  Going to keep an empty one for the tooltip though.
    // Setup a data struct
    const stateSeriesData = new Map();
    this.states.forEach((m) => {
      stateSeriesData.set(m, []);
    });
    for (let i = 0; i < arr.length; i++) {
      const date = this.getDate(i, arr);
      const val: string = this.getVal(i, arr);

      this.states.forEach((state) => {
        if (val == null) {
          return; // continue
        } else if(state.toLowerCase() === "on") {
          if (val === 'Run' || val === 'ON') {
            const dp = [date, 100_000];
            stateSeriesData.get(state).push(dp);
          } else {
            const dp = [date, -100_000];
            stateSeriesData.get(state).push(dp);
          }
        } else if(state.toLowerCase() === "stand by") {
          if (val === 'Stand by') {
            const dp = [date, 100_000];
            stateSeriesData.get(state).push(dp);
          } else {
            const dp = [date, -100_000];
            stateSeriesData.get(state).push(dp);
          }
        } else if(state.toLowerCase() === "stop") {
          if (val === 'Stop' || val === 'OFF') {
            const dp = [date, 100_000];
            stateSeriesData.get(state).push(dp);
          } else {
            const dp = [date, -100_000];
            stateSeriesData.get(state).push(dp);
          }
        }
      });
    }

    return stateSeriesData;
  }

  makeSeriesData(key) {
    const seriesData = [];
    const arr = this.unitData[key];

    if(!arr) {
      return null;
    }

    for (let i = 0; i < arr.length; i++) {
        const date = this.getDate(i, arr);
        let val: number = this.getVal(i, arr);

        if(this.TEMPERATURE_MARKS.includes(key) && this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit && val != null) {
          if(key === "TO") { // set temp
            val = Number(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(val));
          } else {
            val = TemperatureConversions.convertCelsiusToFahrenheit(val);
          }
        }

        if(this.PRESSURE_MARKS.includes(key) && val != null) {
          if(this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
            val = Number(PressureConversions.convert_from_kg_per_cm2_to_kilopascal(val).toFixed(1));
          } else if(this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.PoundFourcePerSquareInch) {
            val = Number(PressureConversions.convert_from_kg_per_cm2_to_psi(val).toFixed(1));
          }
        }

        const dataPoint = [date, val];
        seriesData.push(dataPoint);
    }

      return seriesData;  
  }

  loadSeries() {
    this.chartOptions.series = new Array<SeriesOption>();
    let seriesList = <Array<SeriesOption>>this.chartOptions.series;

    const removeDatapointsList = [];
    const savedDatapointsList = [];

    if (this.selectedUnit.model == "MXZ-SM (all)") { // KEN-4725: "Temp" hack to get smartmultis to fully work in kenza
      this.dataPoints = this.unitData['keys'];
      this.isMxzSm = true;
    }

    this.dataPoints.forEach((datapoint) => {
      const seriesData = this.makeSeriesData(datapoint);
      if(!seriesData) { // Could not make this into a proper graph
        return;
      } else { // just saving this for later because easier then trying to remove from list in javascript
        savedDatapointsList.push(datapoint);
      }

      const obj: LineSeriesOption = {
        name: datapoint,
        type: "line",
        data: seriesData,
        symbol: "none",
        smooth: true,
        zlevel: 100,
        connectNulls: false,
        animation: false,
        xAxisIndex: 0,
        yAxisIndex: 0,
      };

      if (datapoint in this.colorMapping) {
        obj.color = this.colorMapping[datapoint];
      }

      if (datapoint === "State") {
        let stateSeriesData = this.makeStateSeriesData();
        for (let key of stateSeriesData.keys()) {
          const d = stateSeriesData.get(key);
          const stateSeriesObj: LineSeriesOption = {
            name: datapoint,
            type: "line",
            data: d,
            symbol: "none",
            smooth: true,
            step: "end",
            areaStyle: {
              opacity: 0.2,
              origin: "start",
            },
            lineStyle: {
              width: 0,
            },
            color: this.stateColors[key.toLowerCase().replace(" ", "_")],
            tooltip: {
              show: false,
            },
            zlevel: 0,
            animation: false
          };
          seriesList.push(stateSeriesObj);
        }
      } else if (datapoint == "Mode") {
        // Easist way I can figure to show mode in the background is to make a series for each mode type and then push it in
        let modeSeriesData = this.makeModeSeriesData();
        for (let key of modeSeriesData.keys()) {
          const d = modeSeriesData.get(key);
          const modeSeriesObj: LineSeriesOption = {
            name: datapoint,
            type: "line",
            data: d,
            xAxisIndex: 1,
            yAxisIndex: 1,
            symbol: "none",
            smooth: true,
            step: "end",
            areaStyle: {
              opacity: 0.2,
              origin: "start",
            },
            lineStyle: {
              width: 0,
            },
            color: this.modeColors[key.toLowerCase()],
            tooltip: {
              show: false,
            },
            zlevel: 0,
            animation: false
          };
          seriesList.push(modeSeriesObj);
        }
      } else {
        if(this.TEMPERATURE_MARKS.includes(datapoint)) {
          obj.tooltip = {
            valueFormatter: (value) => {
              if(value != null || value != undefined) {
                if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
                  return `${value} °F`
                } else {
                  return `${value} °C`
                }
              } else {
                return "";
              }
            },
          };

        } else if(this.PRESSURE_MARKS.includes(datapoint)) {
          obj.tooltip = {
            valueFormatter: (value) => {
              if(value != null || value != undefined) {
                if (this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
                  return `${value} kPa`
                } else if (this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.PoundFourcePerSquareInch) {
                  return `${value} psi`
                }
              } else {
                return "";
              }
            },
          };
        }
      }

      seriesList.push(obj);
    });

    this.dataPoints = savedDatapointsList;
  }

  initialSeriesHide() {
    this.dataPoints.forEach((datapoint) => {
      this.chart.dispatchAction({
        type: "legendToggleSelect",
        name: datapoint,
      });
    });
  }

  modeChartToggle(show: boolean) {
    if(show) {
      this.chartOptions.grid = [
        this.gridModeShown,
        this.gridModeChart
      ]
    } else {
      this.chartOptions.grid = [
        this.gridNormal,
        this.gridModeHidden
      ]
    }

    // when we change the grid options the zoom gets reset so get the current zoom and zoom back end after the change
    const opts = this.chart.getOption()
    if (opts != null) { // will be null when switching from IDU -> ODU graph
      const start = opts.dataZoom[0].start;
      const end = opts.dataZoom[0].end;

      this.chart.setOption(this.chartOptions);

      this.chart.dispatchAction({
        type: "dataZoom",
        start: start,
        end: end,
      });
    }
  }

  datapointChange(evt, datapoint) {
    if (evt.detail["checked"]) {
      if (datapoint == "Mode") {
        this.modeChartToggle(true);
      }
      this.chartedDatapoints.push(datapoint);
    } else {
      if (datapoint == "Mode") {
        this.modeChartToggle(false);
      }
      this.chartedDatapoints = this.chartedDatapoints.filter(
        (dp) => dp != datapoint
      );
    }

    this.chart.dispatchAction({
      type: "legendToggleSelect",
      name: datapoint,
    });

    this.setYAxisRange();
  }

  setYAxisRange() {
    let min = 999999;
    let max = -999999;

    for (let i = 0; i < this.chartedDatapoints.length; i++) {
      let dp = this.chartedDatapoints[i];
      if(!this.SPECIAL_SERIES.includes(dp)){

        let dpMin = this.unitData['min'][dp];
        let dpMax = this.unitData['max'][dp];

        if(this.TEMPERATURE_MARKS.includes(dp) && this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
          dpMin = TemperatureConversions.convertCelsiusToFahrenheit(dpMin);
          dpMax = TemperatureConversions.convertCelsiusToFahrenheit(dpMax);
        }

        if(this.PRESSURE_MARKS.includes(dp)) {
          if(this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
            dpMin = Number(PressureConversions.convert_from_kg_per_cm2_to_kilopascal(dpMin).toFixed(1));
            dpMax = Number(PressureConversions.convert_from_kg_per_cm2_to_kilopascal(dpMax).toFixed(1));
          } else if(this.user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.PoundFourcePerSquareInch) {
            dpMin = Number(PressureConversions.convert_from_kg_per_cm2_to_psi(dpMin).toFixed(1));
            dpMax = Number(PressureConversions.convert_from_kg_per_cm2_to_psi(dpMax).toFixed(1));
          }
        }
        
        if ((dpMin != null) && (min > dpMin)) {
          min = dpMin;
        }

        if ((dpMax != null) && (max < dpMax)) {
          max = dpMax
        }
      }
    }

    if (min == null || min == 999999) {
      min = 0;
      max = 100;
    }

    this.chart.setOption({
      yAxis: {
        min: Math.floor(min) - 5,
        max: Math.floor(max) + 5,
      }
    });
  }

  async datapointInfo(datapoint) {
    let dp = null;
    if(this.selectedUnit.type == GatewayUnitTwoDigitType.OutdoorUnit) {
      dp = this.oduMapping.get(datapoint);
    } else if(this.selectedUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
      dp = this.iduMapping.get(datapoint);
    }

    const alert = await this.alertController.create({
      header: `${dp['officialName']}`,
      message: `<div>${dp['meaning']}</div>`,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',
        }
      ]
    });

    await alert.present()
  }

  makeGraph() {
    this.dataPoints = new Array<string>();

    if(this.selectedUnit.type == GatewayUnitTwoDigitType.OutdoorUnit) {
      this.oduMapping.forEach((val, key) => {
        this.dataPoints.push(key);
      });

      this.chartOptions = this.initOptions(false);
    } else if(this.selectedUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
      this.iduMapping.forEach((val, key) => {
        this.dataPoints.push(key);
      });

      this.chartOptions = this.initOptions(true);
    }

    this.dataPoints.sort();

    this.chartedDatapoints = new Array<string>();

    const root = this.elm.nativeElement.querySelector(this.chartElmSelector);
    this.chart = init(root);
    
    this.loadSeries();

    setTimeout(() => {
      // need a small delay for the series to get loaded
      this.initialSeriesHide();
    }, 150);

    this.chart.setOption(this.chartOptions);

    let myChart = this.chart; // really don't know why i need to do this...
    window.addEventListener("resize", function () {
      myChart.resize();
    });

    this.viewState.showChart();
    this.viewState.loadingSpinner = true;  // keeping spinner while chart rendering
    setTimeout(() => {
      // small delay so that the dom is rendered
      this.viewState.loadingSpinner = false;
      myChart.resize();
      // this.zoomTo("1h");
    }, 100);

    this.setDefaultDatapoints()

  }

  setDefaultDatapoints() {
    if (this.selectedUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
      this.showDefaultDatapoints(this.IDU_DEFAULT_DATAPOINTS)
    } else if(this.selectedUnit.type == GatewayUnitTwoDigitType.OutdoorUnit) {
      this.showDefaultDatapoints(this.ODU_DEFAULT_DATAPOINTS)
    }
  }

  showDefaultDatapoints(datapointArr: string[]) {
    datapointArr.forEach((mark) => {
      this.dataPoints.forEach((dp) => {
        if(mark == dp) {
          const id = this.cleanId(dp);
          const selector = "#" + id;
          CustomObservers.waitForElementToRenderObserver(selector).then(() =>{
          // creating a synthitic event once the element is rendered to triggger the initial drawing
            let checkbox: IonCheckbox = this.elm.nativeElement.querySelector(selector);
            checkbox.checked= true;  // making it checked
            const event = new CustomEvent("initCheck", {
              detail: {
                checked: true,
                value: 'on'
              },
            });
            this.datapointChange(event, mark);
          })
        }
      });
    });
  }

  // doing this because having a parenthese in the generated ID messes everything up.  Add chars to the bannedCharArr if you run into other issues
  cleanId(datapoint: string) {
    let id = `datapoint_${datapoint}_checkbox`;
    const bannedCharArr = ["(", ")", "[", "]", "{", "}", "\\", "#"];
    bannedCharArr.forEach((char) => {
      id = id.replace(char, "");
    });

    return id;
  }

  initOptions(isIdu): EChartsOption {
    const opts: EChartsOption = {
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          saveAsImage: {},
        },
      },
      legend: { show: false },
    };

    if(isIdu) {
      opts.grid = [this.gridModeChart, this.gridModeHidden];
      opts.xAxis = [this.mainXAxis, this.modeXAxis];
      opts.yAxis = [this.mainYAxis, this.modeYAxis];
      opts.dataZoom = [this.iduDataZoom]
    } else {
      opts.grid = [this.gridNormal];
      opts.xAxis = [this.mainXAxis];
      opts.yAxis = [this.mainYAxis];
      opts.dataZoom = [this.normalDataZoom];
    }

    return opts;
  }

  refreshGatewayListByActiveSite(activeSiteId: string) {
    if (activeSiteId === "default-site") {
      const s: any = JSON.parse(
        this.appStorageService.localStorageGetItem("ActiveSite")
      );
      if (s) {
        activeSiteId = s["id"];
        const selectedSiteInfo = new Site({
          id: s["id"],
          name: s["name"],
          phone: s["phone"],
          company: s["company"],
          addressOne: s["addressOne"],
          addressTwo: s["addressTwo"],
          city: s["city"],
          state: s["state"],
          zip: s["zip"],
          country: s["country"],
          site_photo_name: s["site_photo_name"],
          sitestatustype_id: 1,
          current_owner_id: "",
          locations: s["locations"]
        });
        this.user.setActiveSite(selectedSiteInfo, true);
      }
    }


    if (activeSiteId.toLowerCase() !== "default-site") {
      this.siteService
        .getSiteGatewaysWithUnits(activeSiteId, true)
        .subscribe((resp: Gateway[]) => {
          this.siteGateways = [];

          if (resp) {
            this.cleanUp(false, true);

            this.siteGateways = resp.map((sgw) => {
              let gateway_outdoor_unit: GatewayUnit = null;
              sgw.outdoor_unit = null;

              if (sgw["units"].length > 0) {
                const gatewayUnits: GatewayUnit[] = sgw["units"];
                gateway_outdoor_unit = gatewayUnits.find(
                  (gwu) =>
                    gwu.type == GatewayUnitTwoDigitType.OutdoorUnit ||
                    gwu.type == GatewayUnitTwoDigitType.MelshiHolder
                );
                if (gateway_outdoor_unit)
                  gateway_outdoor_unit.gateway_model_class_name =
                    sgw.model.class_name;
                sgw.outdoor_unit =
                  gateway_outdoor_unit == undefined
                    ? null
                    : gateway_outdoor_unit;
                sgw.outdoor_unit_name =
                  gateway_outdoor_unit == undefined
                    ? ""
                    : gateway_outdoor_unit.name;
              }
              return sgw;
            });

            if(this.siteGateways.length == 0) {
              this.viewState.showNoGateways();
            }

            this.siteGateways = this.siteGateways.sort(
              this.compareValues("outdoor_unit_name", "asc")
            );

            // get a list of all the ODUs on in the site
            for (let i = 0; i < resp.length; i++) {
              const gw = resp[i];
              if (gw.units) {
                for (let j = 0; j < gw.units.length; j++) {
                  const gwu = gw.units[j];
                  if (
                    gwu.type == GatewayUnitTwoDigitType.OutdoorUnit ||
                    gwu.type == GatewayUnitTwoDigitType.MelshiHolder
                  ) {
                    gwu.outdoor_unit = gwu; //hack to get the reused module to work
                    gwu.subscription_features = gw.subscription_features;
                  }
                }
              }
            }
          }
        });
    }
  }

  async onSelectGateway() {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SelectGatewayPage,
      componentProps: {
        parentGatewayList: this.siteGateways,
        subscriptionCheckField: SubscriptionFeatures.SYSTEM_GRAPHING,
        subscriptionCheckValue: SubscriptionFeatures.INCLUDED,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.gatewaySelected) {
        if(this.selectedGateway && this.selectedGateway.id === data.data.selectedGateway.id) {
          // same gateway was selected so just get out.
          return;
        }
        this.cleanUp();
        this.selectedGatewayUnits = [];
        this.selectedUnit = null;
        if(this.unit.form) {
          this.unit.form = null;
        }
        

        this.viewState.showSelectUnit();

        this.selectedGateway = data.data.selectedGateway;
        this.selectedGatewayName = this.selectedGateway.name;

        this.selectedGateway.units.forEach((gwu) => {
          if (gwu.type == GatewayUnitTwoDigitType.OutdoorUnit || gwu.type == GatewayUnitTwoDigitType.IndoorUnit) {
            this.selectedGatewayUnits.push(gwu);
          }
        });

        this.selectedGatewayUnits.sort((a: GatewayUnit, b: GatewayUnit) => {
          return a.name.localeCompare(b.name);
        });

        if(this.selectedGatewayUnits.length == 0) {
          this.viewState.showNoUnits();
          // initialLoad exists to prevent a graph event from firing twice, once manually and once from the ionChange.  The unit selector 
          // disapears so it won't happen this time.
          this.initialLoad = true;
          return;
        }
    
        /* code use to set a default idu/odu when gateway was selected.  Feature removed, keeping if it comes back */
        // // set a default unit
        // if(this.selectedGatewayUnits.length > 0) {
        //   if(this.initialLoad) {
        //     this.initialLoad = false;
        //     this.onUnitSelect({
        //       detail: {
        //         value: this.selectedGatewayUnits[0].id,
        //         message: "from gateway select"
        //       }
        //     });
        //   }

        //   // if loading a new gateway the event handler can get wierd so only doing the manual call on initial load
        //   this.unit.form = this.selectedGatewayUnits[0].id;
        // }
      }
    });

    return await modal.present();
  }

  onUnitSelect(evt) {
    if (!this.siteService.handleIsConnected()) return;

    if(evt && evt.detail && evt.detail['value'] === '') {
      // skip all this
      return;
    }

    this.viewState.showLoading();
    this.cleanUp(true);

    this.selectorsDisabled = true;

    const unit_id = evt.detail.value;
    this.selectedUnit = this.selectedGatewayUnits.filter(
      (u) => u.id == unit_id
    )[0];

    if(this.selectedUnit.type == GatewayUnitTwoDigitType.OutdoorUnit) {
      try{
        this.mapOduDataPoints();
      } catch(e){
        console.warn("Model or series not set for this ODU.")
        return;
      }
      
    }

    this.graphService
      .getUnitData(this.selectedGateway.id, this.selectedUnit.id)
      .then((resp) => {
        if('error' in resp) {
          this.viewState.showNoData();
          
        } else {
          this.unitData = resp;
          try {
            this.makeGraph();
          } catch(e) {
            console.error(e);
            this.viewState.showError();
          }

          
        }
        this.selectorsDisabled = false;
      });
  }

  cleanUp(fromSelectUnit = false, fromSiteSelect = false) {
    if (this.chart) {
      this.chart.dispose();
    }

    this.uncheckAllDataPoints();

    this.chart = null;
    if (!fromSelectUnit) {
      this.selectedGatewayUnits = [];
    }

    if(fromSiteSelect) {
      this.selectedGatewayName = "Select a Gateway"
      this.selectedGateway = null;
    }

    this.dataPoints = [];
    this.isMxzSm = false;
  }

  uncheckAllDataPoints() {
    const boxes: HTMLCollection = document.getElementsByClassName("ionCheckbox");
    for(let i = 0; i < boxes.length; i++) {
      let cbox: any = boxes[i];
      if(cbox.checked) {
        cbox.checked = false;
        let tag: string = cbox.id;
        tag = tag.replace("datapoint_", "").replace("_checkbox", "");
        const event = new CustomEvent("initCheck", {
          detail: {
            checked: false,
            value: 'on'
          },
        });
        this.datapointChange(event, tag);
      }
    }
  }

  resetGraph() {
    this.zoomTo("1w"); // reset zoom to full week
    this.uncheckAllDataPoints(); // toggle off all the checkboxes
    this.setDefaultDatapoints(); // show default datapoints
  }

  zoomTo(suppliedTime) {
    const now = new Date();
    let startDate = new Date();
    if (suppliedTime === "1h") {
      startDate.setHours(now.getHours() - 1);
      this.chart.dispatchAction({
        type: "dataZoom",
        startValue: startDate.getTime(),
        endValue: now.getTime(),
      });
    } else if (suppliedTime === "12h") {
      startDate.setHours(now.getHours() - 12);
      this.chart.dispatchAction({
        type: "dataZoom",
        startValue: startDate.getTime(),
        endValue: now.getTime(),
      });
    } else if (suppliedTime === "1d") {
      startDate.setDate(now.getDate() - 1);
      this.chart.dispatchAction({
        type: "dataZoom",
        startValue: startDate.getTime(),
        endValue: now.getTime(),
      });
    } else if (suppliedTime === "1w") {
      startDate.setDate(now.getDate() - 7);
      this.chart.dispatchAction({
        type: "dataZoom",
        start: 0,
        end: 100,
      });
    } 
    // else if (suppliedTime === "1m") {
    //   startDate.setDate(now.getDate() - 30);
    //   this.chart.dispatchAction({
    //     type: "dataZoom",
    //     startValue: startDate.getTime(),
    //     endValue: now.getTime(),
    //   });
    // }
  }

  compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (
        !Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)
      )
        return 0;
      //if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].toString().localeCompare(b[key]);
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  async displayInformation() {
    const midnightUTC = date_time_utilities.getUtcDate(0,0);
    const threeAMUTC = date_time_utilities.getUtcDate(3,0);

    const formatter = new Intl.DateTimeFormat('en-US', { 
      hour: '2-digit', 
      minute: '2-digit', 
      timeZone: this.user.active.timezone,
      hour12: true 
    });
    
    const startFormatted = formatter.format(midnightUTC);
    const endFormatted = formatter.format(threeAMUTC);

    const alert = await this.alertController.create({
      message: `For MCC devices, data from the previous day may be limited between ${startFormatted} and ${endFormatted}.`,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
  }
}


class SiteMonitorViewStateObject {
  loadingSpinner = false;
  selectGateway = false;
  selectUnit = false;
  noGateways = false;
  noData = false;
  noUnits = false;
  setOduModel = false;
  error = false;
  chart = false


  showLoading() {
    this.clear();
    this.loadingSpinner = true;
  }

  showSetModelAndSeries() {
    this.clear();
    this.setOduModel = true;
  }

  showNoGateways() {
    this.clear();
    this.noGateways = true;
  }

  showNoUnits() {
    this.clear();
    this.noUnits = true;
  }

  showChart() {
    this.clear();
    this.chart = true;
  }

  showSelectGateway() {
    this.clear();
    this.selectGateway = true;
  }

  showSelectUnit() {
    this.clear();
    this.selectUnit = true;
  }

  showError() {
    this.clear();
    this.error = true;
  }

  showNoData() {
    this.clear();
    this.noData = true;
  }

  clear() {
    this.loadingSpinner = false;
    this.selectGateway = false;
    this.selectUnit = false;
    this.noGateways = false;
    this.noData = false;
    this.noUnits = false;
    this.setOduModel = false;
    this.error = false;
    this.chart = false;
  }

}