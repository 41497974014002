import { GatewayUnit } from './GatewayUnit';

export class GatewayGroup {
    gateway_id: string;
    group_id: string;
    bus_address: string;
    group_name: string;
    created_at: Date;
    last_modified: Date;
    isExpanded: boolean;
    isDisabled: boolean;
    autocreated: boolean;
    number: number;
    location: string;
    units: GatewayUnit[];

    constructor() {
        this.gateway_id = '';
        this.group_id = '';
        this.group_name = '';
        this.bus_address = '';
        this.created_at = null;
        this.last_modified = null;
        this.isExpanded = false;
        this.isDisabled = false;
        this.autocreated = false;
        this.number = null;
        this.location = '';
        this.units = [];

    }
}