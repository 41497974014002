import {
    Input,
    Output,
    Component,
    ViewChild,
    ElementRef,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ViewEncapsulation,
    ChangeDetectionStrategy,
} from '@angular/core';
import {
    addDays,
    subDays,
    subWeeks,
    addWeeks,
    addMonths,
    subMonths,
    isSameDay,
    endOfWeek,
    startOfDay,
    endOfMonth,
    startOfHour,
    isSameMonth,
    startOfWeek,
    startOfMonth,
    differenceInMinutes,
} from 'date-fns';
import {
    CalendarEvent,
    CalendarEventTimesChangedEvent,
    CalendarDayViewBeforeRenderEvent,
    CalendarWeekViewBeforeRenderEvent,
    CalendarMonthViewBeforeRenderEvent,
} from 'angular-calendar';
import moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { SiteService } from '../../services/site.service';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { DisplayOptions } from 'src/app/common/classes/displayOptions';
import { UserService } from 'src/app/common/services/user/user.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { BatchGroupDisplay } from '../site-control/batchControl/batchGroupDisplay';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { SelectedGroup } from 'src/app/common/components/group-selector/group-selector.component';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SiteSchedulingEventFormComponent } from '../site-scheduling-event-form/site-scheduling-event-form.component';
import { FanSpeedStages, IndoorFanSpeed, LossnayFanSpeed, Presence, SetTempModeEnum } from 'src/app/features/manage/components/classes/GatewayUnit';
import { GatewayUnitTwoDigitType, SubscriptionFeatures, TemperaturePreferenceEnum, LevelEnum, ModesSpeedsDirections } from 'src/app/enumerations/enums';

enum CalendarView {
    Events = `events`,
    Month = `month`,
    Week = `week`,
    Day = `day`,
}

type CalendarPeriod = CalendarView.Week | CalendarView.Month;

declare global {
    interface EventControlPoints {
        id,
        days?,
        active?,
        batchID?,
        batchName?,
        batchEvents?,
        batchGroupIDs?,
        batchScheduleID?,
        batchScheduleName?,
        singleAndDualSetEvent?,
        onGroup?,
        seriesID?,
        updatedBy?,
        startDate?,
        startString?,
        event_dates?,
        createdDate?,
        updatedDate?,
        eventAuthor?,
        dualSetEvent?,
        is_recurring?,
        seriesOrIcon?,
        mode?: string,
        power?: string,
        databaseEvent?,
        multiDayEvent?,
        startX?: string,
        startH?: string,
        startT?: string,
        startD?: string,
        startMM?: string,
        fanSpeedEnabled?,
        eventAuthorRole?,
        fanSpeed?: string,
        ventMode?: string,
        temperatureRange?,
        group_id?: string,
        unitType?: string,
        temperatureNumber?,
        maintenance?: any,
        tempString?: string,
        gateway_id?: string,
        groupedEvents?: any,
        sameTimeEvents?: any,
        daysArray?: string[],
        temperature?: number,
        airDirection?: string,
        temperatureRangeString?,
        gatewayUnit_id?: string,
        [key: string]: unknown
    }
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: `app-site-scheduling-calendar`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./site-scheduling-calendar.component.html`,
    styleUrls: ['../site-scheduling/site-scheduling.component.scss'],
})

export class SiteSchedulingCalendarComponent {

    private destroy$ = new Subject<void>();

    // Notify parent that events have changed
    @Output() onAddEvent = new EventEmitter<any>();
    @Output() onDeleteEvent = new EventEmitter<any>();
    @Output() onChangeEvent = new EventEmitter<any>();
    @Output() newGroupsSelected = new EventEmitter<SelectedGroup[]>();

    // Controls
    minYear = 2000;
    maxYear = 2099;
    updateIndex = 0;
    prevBtnDisabled = false;
    nextBtnDisabled = false;
    globalFunctions = globalFunctions;
    DisplayOptions = new DisplayOptions;
    capWords = globalFunctions.capitalizeAllWords;
    minDate: Date = new Date(`Jan 1 ${this.minYear} 00:00:00`);
    maxDate: Date = new Date(`Dec 31 ${this.maxYear} 23:59:59`);

    // Events
    @Input() allSiteEvents;
    @Input() unfilteredEvents;
    @Input() hasAnyDisabledEvents;
    @Input() hasAnyMultiDayEvents;
    @Input() allSiteDatabaseEvents;
    @Input() databaseEvents;
    @Input() groupsOfEvents;
    @Input() backEndToEvents;
    @Input() createFrontEndEventsFromBackEndEvents;
    @Input() getEventsForSelection;
    @Input() dataTime;
    @Input() dataCity;
    minuteBarrier = 30;
    levelEnum = LevelEnum;
    onNewGroupsSelected: Subscription = null;
    uniqueEvents: CalendarEvent<EventControlPoints>[];
    defaultEvents: CalendarEvent<EventControlPoints>[];
    formattedEvents: CalendarEvent<EventControlPoints>[];
    @Input() events: CalendarEvent<EventControlPoints>[];
    storedEvents: CalendarEvent<EventControlPoints>[] = JSON.parse(localStorage.getItem(`Events`));

    errorMsg = {
        header: `Improper Unit Values Provided`,
        message: `The Unit(s) you selected have provided improper values to Scheduling such as Missing, Disabled or Invalid Mode, Fan Speed, or Temperature. Please try a different Unit instead for better results.`,
        cssClass: `me-info-button-css alertMsg`,
        buttons: [{ text: `Ok`, cssClass: `ok-button`, }],
    };

    // Scheduling
    today;
    eventID;
    changes;
    tzSwitch;
    weekStart;
    todayView;
    filterTerm;
    schedColors;
    degreesType;
    filters = [];
    width: number;
    weekStartYear;
    commandsArray;
    height: number;
    daysInWeek = 7;
    @Input() onMac;
    @Input() colors;
    devEnv = devEnv;
    changed = false;
    @Input() actions;
    formOpen = false;
    staticTop: number;
    alertOpen = false;
    clickedTime: Date;
    initialGatewayUnit;
    @Input() eventAdmin;
    timerTimeout = null;
    getSiteTime = false;
    updateTimerFn = null;
    @Input() setWeekTime;
    updateInterval = 999;
    tempPoints: number[];
    isTodayActive = true;
    @Input() gatewayUnits;
    @Input() is_recurring;
    fetchingEvents = true;
    eventsFetched = false;
    activeDayIsOpen = true;
    @Input() getEventColor;
    @Input() groupSelector;
    @Input() selectedGroup;
    @Input() selectedGroups;
    @Input() recurringSched;
    @Input() batchScheduling;
    backendEventsNum: number;
    groupNamesInTitle = false;
    currentHoverTarget = null;
    createEventButton = false;
    frontendEventsNum: number;
    @Input() scheduleForGroups;
    siteTimezoneOffset: number;
    viewDate: Date = new Date();
    @Input() refreshGroupEvents;
    timerInitialUpdated = false;
    CalendarView = CalendarView;
    @Input() lastSelectedByOrder;
    refresh = new Subject<void>();
    @Input() consolidateBatchEvents;
    @Input() siteGateways: Gateway[];
    calendarTimeFormat = `h:mm:ss A`;
    @Input() selectedGatewayUnit: any;
    @Input() selectedGateway: Gateway;
    degreesTypeAbbreviation: any = `°F`;
    calendarDateFormat = `dddd, MMMM Do`;
    localMomentUTC: any = moment().utc();
    seriesTimeFormat: any = `hh:mm A dddd`;
    @Input() mapEventsFromBackEndToFrontEnd;
    evnt: CalendarEvent<EventControlPoints>;
    @Input() presentSubscriptionOptions: any;
    localDateTime: any = new Date().getTime();
    @Input() selected_site_gateway_group: any;
    @Input() recurringAndSingleEvents: boolean;
    ModesSpeedsDirections = ModesSpeedsDirections;
    calendarTimerFormat = `dddd, MMMM Do, h:mm:ss A`;
    simpleTimeFormat: any = `hh:mm A dddd MM/DD/YYYY`;
    GatewayUnitTwoDigitType = GatewayUnitTwoDigitType;
    databaseTimeFormat: any = `YYYY-MM-DD HH:mm:ss.SSS`;
    timezoneAPIKey = `86bda2f21d2d487dbfc13109a9268ef1`;
    localMomentTimezoneOffset: any = moment().utcOffset();
    view: any = localStorage.getItem(`CalView`) || CalendarView.Week;
    localDateTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    @ViewChild('calendarContainer') calendarContainer: ElementRef<HTMLElement>;
    siteTime: string = moment.tz(this.user?.active?.timezone).format(this.calendarTimerFormat);
    weekEnd = (<any>moment().tz(this.user?.active?.timezone).endOf(`week`).add(`4`, `hours`))._d;
    timeZoneTitle = (globalFunctions.capitalizeAllWords(this.user?.active?.city) || `Site`) + ` `;
    weekBegin = (<any>moment().tz(this.user?.active?.timezone).startOf(`week`).add(`4`, `hours`))._d;
    public bgd: BatchGroupDisplay = new BatchGroupDisplay(this.user, this.appAuth, this.mainSiteUIService);
    calendarTimerMarker: HTMLDivElement = (<HTMLDivElement>document.querySelector(`.cal-week-view .cal-current-time-marker`));
    week = { Sunday: moment(this.weekBegin).format(`YYYY-MM-DD`), Monday: moment(this.weekBegin).add(`1`, `days`).format(`YYYY-MM-DD`), Tuesday: moment(this.weekBegin).add(`2`, `days`).format(`YYYY-MM-DD`), Wednesday: moment(this.weekBegin).add(`3`, `days`).format(`YYYY-MM-DD`), Thursday: moment(this.weekBegin).add(`4`, `days`).format(`YYYY-MM-DD`), Friday: moment(this.weekBegin).add(`5`, `days`).format(`YYYY-MM-DD`), Saturday: moment(this.weekEnd).format(`YYYY-MM-DD`) };
    allowedTempValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0, 15.5, 16.0, 16.5, 17.0, 17.5, 18.0, 18.5, 19.0, 19.5, 20.0, 20.5, 21.0, 21.5, 22.0, 22.5, 23.0, 23.5, 24.0, 24.5, 25.0, 25.5, 26.0, 26.5, 27.0, 27.5, 28.0, 28.5, 29.0, 29.5, 30.0, 30.5, 31.0, 31.5, 32.0, 32.5, 33.0, 33.5, 34.0, 34.5, 35.0, 35.5, 36.0, 36.5, 37.0, 37.5, 38.0, 38.5, 39.0, 39.5, 40.0, 40.5, 41.0, 41.5, 42.0, 42.5, 43.0, 43.5, 44.0, 44.5, 45.0, 45.5, 46.0, 46.5, 47.0, 47.5, 48.0, 48.5, 49.0, 49.5, 50.0, 50.5, 51.0, 51.5, 52.0, 52.5, 53.0, 53.5, 54.0, 54.5, 55.0, 55.5, 56.0, 56.5, 57.0, 57.5, 58.0, 58.5, 59.0, 59.5, 60.0, 60.5, 61.0, 61.5, 62.0, 62.5, 63.0, 63.5, 64.0, 64.5, 65.0, 65.5, 66.0, 66.5, 67.0, 67.5, 68.0, 68.5, 69.0, 69.5, 70.0, 70.5, 71.0, 71.5, 72.0, 72.5, 73.0, 73.5, 74.0, 74.5, 75.0, 75.5, 76.0, 76.5, 77.0, 77.5, 78.0, 78.5, 79.0, 79.5, 80.0, 80.5, 81.0, 81.5, 82.0, 82.5, 83.0, 83.5, 84.0, 84.5, 85.0, 85.5, 86.0, 86.5, 87.0, 87.5, 88.0, 88.5, 89.0, 89.5, 90.0, 90.5, 91.0, 91.5, 92.0, 92.5, 93.0, 93.5, 94.0, 94.5, 95.0, 95.5, 96.0, 96.5, 97.0, 97.5, 98.0, 98.5, 99.0, 99.5, 100.0, 100.5, 101.0, 101.5, 102.0, 102.5, 103.0, 103.5, 104.0];

    mapFanSpeed: any = (unitType, eventFanSpeed, mappedFanSpeed?) => {
        switch (unitType) {
            case GatewayUnitTwoDigitType.IndoorUnit:
                switch (eventFanSpeed) {
                    case `disabled`:
                        return mappedFanSpeed = IndoorFanSpeed.disabled;
                    case `very low`:
                        return mappedFanSpeed = IndoorFanSpeed.very_low;
                    case `low`:
                        return mappedFanSpeed = IndoorFanSpeed.low;
                    case `medium`:
                        return mappedFanSpeed = IndoorFanSpeed.medium;
                    case `high`:
                        return mappedFanSpeed = IndoorFanSpeed.high;
                    case `auto`:
                        return mappedFanSpeed = IndoorFanSpeed.auto;
                }
                return mappedFanSpeed;
            case GatewayUnitTwoDigitType.Lossnay:
                switch (eventFanSpeed) {
                    case `disabled`:
                        return mappedFanSpeed = LossnayFanSpeed.disabled;
                    case `very low`:
                        return mappedFanSpeed = LossnayFanSpeed.very_low;
                    case `low`:
                        return mappedFanSpeed = LossnayFanSpeed.low;
                    case `medium`:
                        return mappedFanSpeed = LossnayFanSpeed.medium;
                    case `high`:
                        return mappedFanSpeed = LossnayFanSpeed.high;
                    case `auto`:
                        return mappedFanSpeed = LossnayFanSpeed.auto;
                }
                return mappedFanSpeed;
        }
        return mappedFanSpeed;
    };

    mapFanSpeedNumber: any = (unitType, eventFanSpeed, mappedFanSpeed?) => {
        switch (unitType) {
            case GatewayUnitTwoDigitType.IndoorUnit:
                switch (eventFanSpeed) {
                    case IndoorFanSpeed.very_low:
                        return mappedFanSpeed = `very low`;
                    case IndoorFanSpeed.low:
                        return mappedFanSpeed = `low`;
                    case IndoorFanSpeed.medium:
                        return mappedFanSpeed = `medium`;
                    case IndoorFanSpeed.high:
                        return mappedFanSpeed = `high`;
                    case IndoorFanSpeed.auto:
                        return mappedFanSpeed = `auto`;
                }
                return mappedFanSpeed;
            case GatewayUnitTwoDigitType.Lossnay:
                switch (eventFanSpeed) {
                    case LossnayFanSpeed.very_low:
                        return mappedFanSpeed = `very low`;
                    case LossnayFanSpeed.low:
                        return mappedFanSpeed = `low`;
                    case LossnayFanSpeed.medium:
                        return mappedFanSpeed = `medium`;
                    case LossnayFanSpeed.high:
                        return mappedFanSpeed = `high`;
                    case LossnayFanSpeed.auto:
                        return mappedFanSpeed = `auto`;
                }
                return mappedFanSpeed;
        }
        return mappedFanSpeed;
    };

    // INITIALIZE 
    constructor(
        public user: UserService,
        private cd: ChangeDetectorRef,
        private siteService: SiteService,
        private alertController: AlertController,
        public appAuth: AppAuthenticationService,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private mainSiteUIService: MainSiteUIService,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.dateOrViewChanged();
    }

    onEventMouseMove(event: MouseEvent) {
        this.currentHoverTarget = event.target;
    }

    getModeBorder(event: CalendarEvent<EventControlPoints>) {
        let mode = event.meta.mode.split(` `)[0];
        let active = this.hasAnyDisabledEvents(event) == false;
        if (mode) {
            if (active) {
            // if (active && (!event.meta.groupedEvents || event.meta.groupedEvents && event.meta.groupedEvents.length == 0)) {
                return `1px solid var(--${mode}Border)`;
            } else {
                return `1px solid var(--disabledBorder)`;
            }
        } else {
            return `none`;
        }
    }

    // Init
    ngOnInit() {

        // Init Calendar
        this.responsiveCalendar();
        this.weekStartYear = this.viewDate.toDateString();
        this.initialGatewayUnit = this.selectedGatewayUnit;
        this.today = new Date().toDateString().replace(` ${new Date().getFullYear()}`, ``);
        this.weekStart = this.viewDate.toDateString().replace(` ${new Date().getFullYear()}`, ``);
        if (this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) this.degreesType = ` Celsius`;
        if (this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) this.degreesTypeAbbreviation = `°C`;
        if (this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) this.degreesType = ` Fahrenheit`;
        if (this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) this.degreesTypeAbbreviation = `°F`;
        this.tempPoints = [parseFloat(this.selectedGatewayUnit?.SetTemp), parseFloat(this.selectedGatewayUnit?.SetTemp1), parseFloat(this.selectedGatewayUnit?.SetTemp2), parseFloat(this.selectedGatewayUnit?.SetTemp3), parseFloat(this.selectedGatewayUnit?.SetTemp4), parseFloat(this.selectedGatewayUnit?.SetTemp5)];

        this.schedColors = Object.entries(this.colors);

        // Update Dynamic Timer
        if (this.updateTimerFn != null) {
            clearInterval(this.updateTimerFn);
            this.updateTimerFn = null;
        };

        this.updateTimerFn = setInterval(() => {
            if (document.querySelector(`.cal-week-view .cal-current-time-marker`) && this.user?.active && this.user?.active?.id != `default-site`) {
                this.setCurrentTime(true);
            } else {
                clearInterval(this.updateTimerFn);
                this.updateTimerFn = null;
            };
            if (this.user?.active && this.user?.active?.id != `default-site`) {
                this.tzSwitch = this.user?.active?.timezone ? this.user?.active?.timezone : `America/New_York`;
            }
            this.siteTime = moment.tz(this.tzSwitch).format(this.calendarTimerFormat);
            document.querySelectorAll(`.siteTime`).forEach((element: any) => {
                if (element.classList.contains(`siteTimeFormFooter`)) {
                    element.innerHTML = `Current Time: <br> ${this.siteTime}`;
                } else {
                    element.innerHTML = `${this.siteTime}`;
                }
            });
        }, this.updateInterval);

        this.timeZoneTitle = (globalFunctions.capitalizeAllWords(this.user?.active?.city) || `Site`) + ` `;

        // Init Window Size Listener
        this.windowEvents();
        window.addEventListener(`resize`, () => this.windowEvents());
        window.addEventListener(`scroll`, () => this.windowEvents());

        return () => {
            window.removeEventListener(`resize`, () => this.windowEvents());
            window.removeEventListener(`scroll`, () => this.windowEvents());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes[`is_recurring`]) {
            if (changes[`is_recurring`].currentValue) {
                if (this.view != CalendarView.Week) {
                    this.setView(CalendarView.Week);
                }
                if (document.querySelector(`#todayButton`)) (<HTMLButtonElement>document.querySelector(`#todayButton`)).click();
            } else {
                this.isTodayActive = true;
            }
        }
        if (changes[`recurringSched`]) {
            if (changes[`recurringSched`].currentValue) {
                this.changed = true;
                this.eventAdmin = true;
                this.view = CalendarView.Week;
            } else {
                this.eventAdmin = false;
                this.is_recurring = false;
                this.view = CalendarView.Week;
            }
        }
        if (changes[`eventAdmin`]) {
            if (changes[`eventAdmin`].currentValue) {
                this.view = this.CalendarView.Week;
            } else {
                this.view = this.CalendarView.Week;
            }
        }
    }

    windowEvents = () => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

    ngAfterViewInit() {
        document.querySelectorAll(`.weekHourLabel`).forEach((label:any) => label.title = label.title.substr(0, label.title.lastIndexOf(`:`)) + label.title.substr(label.title.lastIndexOf(` `)));
        if (this.view == CalendarView.Week) {
            this.weekStart == this.today && this.updateIndex >= 2 ? this.setCurrentTime(true) : this.setCurrentTime(false);
            this.mainSiteUIService.schedulingCalendarWeeklyView = true;
        } else {
            localStorage.setItem(`CalView`, this.CalendarView.Week);
            this.mainSiteUIService.schedulingCalendarWeeklyView = true;
        }
    }

    // getLinkForTRHistory() {
        // return `http://localhost:8100/#/testdrive/99d3add2-b6ad-11ed-ade9-028065f5ce91/history`;
    // }

    getLastEventFired() {
        let lastEventFired = `Last Event Fired`;
        if (this.events.length > 0) {
            let activeEvents = this.events.filter(ev => ev.meta.active);
            if (activeEvents.length > 0) {
                lastEventFired = `${activeEvents.length} Active Event(s)`;
            } else {
                lastEventFired = `No Active Event(s)`;
            }
        }
        return lastEventFired;
    }

    renderSelectedUnits() {
        return this.selectedGroups.map(grp => grp?.gatewayUnits[0]?.name).join(`, `);
    }

    private scrollToCurrentView() {
        if (this.view === CalendarView.Week || CalendarView.Day) {
            // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
            const minutesSinceStartOfDay = differenceInMinutes(
                startOfHour(new Date()),
                startOfDay(new Date())
            );
            const headerHeight = this.view === CalendarView.Week ? 60 : 0;
            this.calendarContainer.nativeElement.scrollTop = (minutesSinceStartOfDay + headerHeight) - 300;
        }
    }

    dateIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }

    changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }

    changeView(view: CalendarPeriod): void {
        this.view = view;
        this.dateOrViewChanged();
    }

    viewChanged() {
        this.cd.detectChanges();
        this.scrollToCurrentView();
    }

    dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.dateIsValid(
            endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.dateIsValid(
            startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }

    setCurrentTime(updateTime?) {
        this.timerTimeout = setTimeout(() => {
            if (!updateTime) this.calendarTimerMarker = (<HTMLDivElement>document.querySelector(`.cal-week-view .cal-current-time-marker`));
            if (document.querySelector(`.cal-week-view .cal-current-time-marker`) && this.user?.active && this.user?.active?.id != `default-site`) {
                const citySwitch = globalFunctions.cutOffTextAndReplace(globalFunctions.capitalizeAllWords(this.user?.active?.city) || `Site`, 17);
                const timeSwitch = moment.tz(this.user?.active?.timezone).format(`h:mm:ss A`) || `Time`;
                let timers = document.querySelectorAll(`.cal-week-view .cal-current-time-marker`);
                if (timers?.length > 0) {
                    timers?.forEach((timerElem: HTMLDivElement, index) => {
                        if (index + 1 == timers?.length) {
                            this.calendarTimerMarker = timerElem;
                            timerElem?.setAttribute(`data-time`, timeSwitch);
                            timerElem?.setAttribute(`data-city`, citySwitch);
                            timerElem?.setAttribute(`data-content`, citySwitch + ` ` + timeSwitch);
                            
                            if (this.timerInitialUpdated == false) {
                                let minuteOffset = (new Date().getHours() - moment.tz(this.user?.active?.timezone).hour());
                                if (!updateTime) this.staticTop = parseFloat(timerElem?.style?.top);
                                timerElem.style.top = this.staticTop - minuteOffset * 60 + `px`;
                                this.timerInitialUpdated = true;
                            }

                            if (!updateTime) this.scrollToCurrentView();
                            
                            let hrs = moment.tz(this.user?.active?.timezone).format(`h A`);
                            let mins = moment.tz(this.user?.active?.timezone).format(`mm`);
                            let secs = moment.tz(this.user?.active?.timezone).format(`ss`);
                            this.staticTop = parseFloat(timerElem?.style?.top);
                            if (hrs != `12 AM` && mins != `00` && secs != `00`) timerElem.style.top = this.staticTop + 0.016 + `px`;
                            this.staticTop = parseFloat(timerElem?.style?.top);
                        }
                    });
                }
            } else {
                clearTimeout(this.timerTimeout);
            }
        }, 1000);
    }

    todayActive(event) {
        if (parseFloat(this.viewDate.toDateString().split(` `)[3]) < this.minYear) {
            this.prevBtnDisabled = true;
        } else {
            this.prevBtnDisabled = false;
        }
        if (parseFloat(this.viewDate.toDateString().split(` `)[3]) > this.maxYear) {
            this.nextBtnDisabled = true;
        } else {
            this.nextBtnDisabled = false;
        }
        this.today = new Date().toDateString().replace(` ${new Date().getFullYear()}`, ``);
        this.weekStart = this.viewDate.toDateString().replace(` ${new Date().getFullYear()}`, ``);
        if (this.weekStart != this.today) this.isTodayActive = false;
        if (event) {
            if (event.id == `nextView`) this.changeDate(addPeriod(this.view, this.viewDate, 1));
            if (event.id == `prevView`) this.changeDate(subPeriod(this.view, this.viewDate, 1));
            if (event.id == `todayButton`) this.isTodayActive = true;
        }
        if (this.weekStart == this.today) {
            this.setCurrentTime(false);
            (<any>document.querySelector(`#todayButton`)).classList.add(`active`);
        } else {
            (<any>document.querySelector(`#todayButton`)).classList.remove(`active`);
        }
    }

    hasLimitedSubscriptionEvents() {
        return this.unfilteredEvents.filter(evt => !evt.meta.active)?.length > 0;
    }

    filterEvents(e) {
        this.filterTerm = e.target.value;

        if (e.target.classList.contains(`activeFilter`)) {
            e.target.classList.remove(`activeFilter`);
            this.filters = [...new Set(this.filters.filter(filter => filter != this.filterTerm))];

            if (this.filters.length > 0) {
                if (this.filterTerm == `disabled`) {
                    this.events = this.unfilteredEvents.filter(evt => evt.meta.active);
                } else if (this.filterTerm == `off`) {
                    this.events = this.unfilteredEvents.filter(evt => this.filters.includes(evt.meta.power));
                } else {
                    this.events = this.unfilteredEvents.filter(evt => this.filters.includes(evt.meta.mode));
                }
            } else {
                this.events = this.unfilteredEvents;
            }
        } else { // Clicked
            e.target.classList.add(`activeFilter`);
            if (!this.filters.includes(this.filterTerm)) this.filters.push(this.filterTerm);
            this.filters = [...new Set(this.filters)];

            if (this.filters.length > 0) {
                if (this.filterTerm == `disabled`) {
                    this.events = this.unfilteredEvents.filter(evt => !evt.meta.active);
                } else if (this.filterTerm == `off`) {
                    this.events = this.unfilteredEvents.filter(evt => this.filters.includes(evt.meta.power));
                } else {
                    this.events = this.unfilteredEvents.filter(evt => this.filters.includes(evt.meta.mode));
                }
            } else {
                this.events = this.unfilteredEvents;
            }
        }
    }

    async handleEvent(action: string, event: CalendarEvent<EventControlPoints>, e?) {
        if (!this.siteService.handleIsConnected()) return;
        let currentTarget = this.currentHoverTarget != null ? this.currentHoverTarget : null;

        if (currentTarget) {
            if (currentTarget?.id) {
                if (currentTarget?.id == `eventInfoIcon`) {
                    this.presentSubscriptionOptions();
                } else if (currentTarget?.id == `delEventIcon`) {
                    this.deleteEventUI(event);
                } else {
                    this.presentEventForm(e, event, action);
                }
            } else {
                this.presentEventForm(e, event, action);
            }
        }
    }

    async presentEventForm(e, event, action) {
        this.eventForm(event.start, `Edit Event`, action, event);
        if (e && e.stopPropagation) e.stopPropagation();
    }

    async infoIconClicked(e, event) {
        this.presentSubscriptionOptions();
        if (e && e.stopPropagation) e.stopPropagation();
    }

    async deleteIconClicked(e, event) {
        this.deleteEventUI(event);
        if (e && e.stopPropagation) e.stopPropagation();
    }

    async deleteEventUI(event) {
        this.askEventOrSeries(event, `Delete`, false);
    }

    // Default Add Event
    async addEvent() {
        if (!this.siteService.handleIsConnected()) return;
        this.createEventButton = true;
        this.eventForm(moment(new Date()).tz(this.user?.active?.timezone), `Create Event`);
    }

    async addEventOnCalCell(day) {
        if (!this.siteService.handleIsConnected()) return;
        this.eventForm(day.date, `Create Event`);
    }

    async hourSegmentClicked(clickedTime) {
        if (!this.siteService.handleIsConnected()) return;
        this.clickedTime = clickedTime;
        this.eventForm(clickedTime, `Create Event`);
    }

    async dayClicked({ date: clickedDate, events }: { date: Date; events: CalendarEvent<EventControlPoints>[] }) {
        if (!this.siteService.handleIsConnected()) return;
        this.clickedTime = clickedDate;
        if (isSameMonth(clickedDate, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, clickedDate) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = clickedDate;
        }
        // let defaultEndTime = moment(clickedDate).add(2, `hours`);
        if (events.length === 0) {
            if (!this.siteService.handleIsConnected()) return;
            this.eventForm(clickedDate, `Create Event`);
        }
    }

    async sameTimeConflict(optionalParameters?) {
        const sameTimeConflictMsg = await this.alertController.create({
            id: `sameTimeConflictMsg`,
            cssClass: `me-info-button-css alertMsg`,
            header: `Event Not Allowed`,
            subHeader: `Event is within ${this.minuteBarrier} minutes of another event.`,
            message: `Check your existing events. You have an event that starts within ${this.minuteBarrier} minutes of the one you are trying to create/edit.`,
            buttons: [
                {
                    text: `Ok`, role: `cancel`, cssClass: `ok-button`, handler: () => this.alertController.dismiss().then(data => {
                        // devEnv && console.log(data);
                        this.alertOpen = false;
                    })
                }
            ]
        });
        if (optionalParameters) devEnv && console.log(`sameTimeConflict optionalParameters`, optionalParameters);
        this.alertOpen = true;
        return await sameTimeConflictMsg.present();
    }

    gatewaysConnected() {
        let allGWsConnected = this.selectedGroups.every(grp => grp?.gateway?.connection?.connected == `true`);
        return allGWsConnected;
    }

    // Submit Form
    async createNewEvent(form, groups = this.selectedGroups, showSpinner = true) {
        if (!this.siteService.handleIsConnected()) return;
        let spinningLoader = null;

        if (showSpinner == true) {
            spinningLoader = await this.loadingController.create({
               spinner: `lines`,
               message: `Creating Event(s)...`,
            });
       
           await spinningLoader.present();
        }

        // devEnv && console.log(`Create New Event Parameters`, form);

        // Format & Validate Dates
        const changeDate = moment(`${moment(form.value.startDay).format(`MMM D, YYYY`)}, ${form.value.startTime}`, `MMM D, YYYY, h:mm A`).format();
        const changeTitle = `${form.value.temperature} @ ${form.value.startTime}`;

        let eventsForSelection = this.allSiteEvents.filter(evt => this.selectedGroups.map(gr => gr.group_id).includes(evt.meta.group_id) && !this.events.map(ev => ev.meta.batchID).includes(evt.meta.batchID) && !this.events.map(ev => ev.meta.id).includes(evt.meta.id) && moment(changeDate).tz(this.user?.active?.timezone).isBetween(moment(evt.start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(evt.start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`)));
        let eventsToConsider = this.evnt && this.evnt != null ? this.events : [...this.events, ...eventsForSelection];

        if (form.value.days && (form.value.days.length > 1 || (form.value.days.length == 1 && form.value.days[0] != moment().tz(this.user?.active?.timezone).format(`dddd`)))) {
            // Same Time Conflict Multi Days
            let multiDates = [];
            eventsToConsider.forEach(evt => multiDates.push(moment(evt.start).tz(this.user?.active?.timezone).format(`hh:mm A dddd`)));

            for (let j = 1; j < this.minuteBarrier; j++) {
                eventsToConsider.forEach(evt => multiDates.push(moment(evt.start).tz(this.user?.active?.timezone).add(j, `minutes`).format(`hh:mm A dddd`)));
                eventsToConsider.forEach(evt => multiDates.push(moment(evt.start).tz(this.user?.active?.timezone).subtract(j, `minutes`).format(`hh:mm A dddd`)));
            }

            multiDates = [...new Set(multiDates)].sort();

            for (let i = 0; i < form.value.days.length; i++) {
                if (multiDates.includes(moment(changeDate).tz(this.user?.active?.timezone).format(`hh:mm A`) + ` ` + form.value.days[i])) {
                    this.sameTimeConflict();
                    if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
                    return;
                }
            }
        } else {
            // Same Time Conflict
            for (let i = 0; i < eventsToConsider.length; i++) {
                if (moment(new Date(changeDate) as Date).tz(this.user?.active?.timezone).isBetween(moment(eventsToConsider[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(eventsToConsider[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                    this.sameTimeConflict();
                    if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
                    return;
                }
            }
        }

        let batchEventsToConvert = [];

        groups.forEach((grp, grpIndex) => {
            let gateway = grp?.gateway;
            let gatewayUnit = grp?.gatewayUnits[0];

            const calendarEventObject = {
                actions: this.actions,
                allDay: form.value.allday,
                start: new Date(changeDate) as Date,
                title: form.value.title || changeTitle,
                resizable: { afterEnd: false, beforeStart: false },
                draggable: this.user?.activeSiteUserLevel > this.levelEnum.Viewer,
                color: gateway.subscription_features[SubscriptionFeatures.SYSTEM_SCHEDULING] != SubscriptionFeatures.INCLUDED ? this.colors.disabled : this.getEventColor(form.value.mode), // Get Color Based on Mode,
                cssClass: gateway.subscription_features[SubscriptionFeatures.SYSTEM_SCHEDULING] != SubscriptionFeatures.INCLUDED ? `disabled eventBlock ${form.value.mode}` : `eventBlock ${form.value.mode}`,
                meta: {
                    active: gateway.subscription_features[SubscriptionFeatures.SYSTEM_SCHEDULING] != SubscriptionFeatures.INCLUDED ? false : true,
                    mode: form.value.mode,
                    days: form.value.days,
                    power: form.value.power,
                    updatedBy: this.user.name,
                    id: this.events.length + 1,
                    unitType: gatewayUnit.type,
                    eventAuthor: this.user.name,
                    fanSpeed: form.value.fanSpeed,
                    group_id: gatewayUnit.group_id,
                    is_recurring: this.is_recurring,
                    onGroup: gatewayUnit.group_name,
                    gateway_id: gatewayUnit.gateway_id,
                    airDirection: form.value.airDirection,
                    startD: moment(changeDate).format(`dddd`),
                    startT: moment(changeDate).format(`h:mm A`),
                    eventAuthorRole: this.user?.activeSiteUserLevel,
                    createdDate: moment().format(`YYYY-MM-DD HH:mm:ss.SSS`),
                    updatedDate: moment().format(`YYYY-MM-DD HH:mm:ss.SSS`),
                    startDate: moment(new Date(changeDate) as Date).format(`M/D`),
                    event_dates: form.value.days.map(day => `${day}: ${this.week[day]}`),
                    seriesID: `${this.events.length + 1}` + `${form.value.days.map(day => `-` + day).join().replace(/[,]/g, ``)}`,
                    dualSetEvent: gatewayUnit.GroupType == `enabled` && form.value.mode == (`setback` || `auto` || `dual_auto`) ? true : false,
                    fanSpeedEnabled: gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay ? gatewayUnit.lc_fan_speed_sw != `disabled` : (gatewayUnit.fan_mode_sw != `disabled` && gatewayUnit.fan_speed_sw != `none`),

                    ...(gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay ? { ventMode: form.value.ventMode } : {
                        temperature: form.value.temperature,
                        tempString: typeof form.value.temperature == `number` ? form.value.temperature + ` ` + this.degreesTypeAbbreviation : `${form.value.temperature.lower + ` ` + this.degreesTypeAbbreviation} - ${form.value.temperature.upper + ` ` + this.degreesTypeAbbreviation}`,
                    }),
                }
            } as CalendarEvent<EventControlPoints>;
    
            const eventMode = form.value.mode;
            let sentTemp: any = new Object();
            if (eventMode == `heat`) {
                sentTemp['lower'] = document.querySelector(`#lowerTemp`) ? parseFloat((<any>document.querySelector(`#lowerTemp`)).value) : form.value.temperature;
            } else if (eventMode == `cool` || eventMode == `dry`) {
                sentTemp['upper'] = document.querySelector(`#upperTemp`) ? parseFloat((<any>document.querySelector(`#upperTemp`)).value) : form.value.temperature;
            } else if (eventMode == `auto`) {
                sentTemp = document.querySelector(`#lowerTemp`) ? { upper: parseFloat((<any>document.querySelector(`#upperTemp`)).value), lower: parseFloat((<any>document.querySelector(`#lowerTemp`)).value) } : form.value.temperature;
            }

            // Default Commands
            let commands = [
                {
                    gateway_id: gatewayUnit.gateway_id,
                    group_id: gatewayUnit.group_id,
                    value: form.value.power.toLowerCase(),
                    param: `Drive`,
                },
            ];
    
            if (((gatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && gatewayUnit.fan_mode_sw != `disabled` && gatewayUnit.fan_speed_sw != `none`) || (gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay && gatewayUnit.lc_fan_speed_sw != `disabled`)) && this.mapFanSpeed(gatewayUnit.type, form.value.fanSpeed.toLowerCase()) != `disabled` && this.mapFanSpeed(gatewayUnit.type, form.value.fanSpeed.toLowerCase()) != null && this.mapFanSpeed(gatewayUnit.type, form.value.fanSpeed.toLowerCase()) != undefined) {
                commands.push({
                    gateway_id: gatewayUnit.gateway_id,
                    group_id: gatewayUnit.group_id,
                    value: this.mapFanSpeed(gatewayUnit.type, form.value.fanSpeed.toLowerCase()),
                    param: `FanSpeed`,
                })
            }
    
            // If Dual Set
            if (gatewayUnit.type != GatewayUnitTwoDigitType.Lossnay && gatewayUnit.GroupType == `enabled` && (form?.value?.mode == `auto` || form?.value?.mode == `setback` || form?.value?.mode == `dual_auto` || form?.value?.mode == `auto_heat` || form?.value?.mode == `auto_cool` || form?.value?.mode == `setback_heat` || form?.value?.mode == `setback_cool`)) {
                let formMode = form?.value?.mode?.includes(`_`) ? form?.value?.mode.split(`_`)[0] : form?.value?.mode;
                let upperSetTempValue = (this.mainSiteUIService.multipleRangeSliders == true ? form?.value?.temperature2Upper : form?.value?.upperSetTemp) || this.bgd.setTemp.minMaxPoints[formMode].max;
                let lowerSetTempValue = (this.mainSiteUIService.multipleRangeSliders == true ? form?.value?.temperature2Lower : form?.value?.lowerSetTemp) || this.bgd.setTemp.minMaxPoints[formMode].min;
                commands.push(
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: form?.value?.mode?.toLowerCase(),
                        param: `Mode`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: form?.value?.airDirection?.toLowerCase(),
                        param: `AirDirection`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                        param: `MultiSetTemp1`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                        param: `MultiSetTemp2`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                        param: `MultiSetTemp4`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                        param: `MultiSetTemp5`
                    },
                );
    
            } else if (gatewayUnit.GroupType == `enabled` && (form.value.mode == `heat` || form.value.mode == `cool` || form.value.mode == `dry`)) {
                commands.push(
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: form?.value?.mode?.toLowerCase(),
                        param: `Mode`
                    },
                    {
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: form?.value?.airDirection?.toLowerCase(),
                        param: `AirDirection`
                    }
                );
    
                if (form?.value?.mode == `heat`) {
                    let formMode = form?.value?.mode?.includes(`_`) ? form?.value?.mode.split(`_`)[0] : form?.value?.mode;
                    let lowerSetTempValue = (this.mainSiteUIService.multipleRangeSliders == true ? form?.value?.temperature2Lower : form?.value?.lowerSetTemp) || this.bgd.setTemp.minMaxPoints[formMode].min;
                    commands.push(
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp1`, // Auto Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp2`, // Setback Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp4`, // Auto Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempValue?.toString()) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp5`, // Setback Heat // Lowest Point
                        }
                    );
                } else { // COOL or DRY
                    let formMode = form?.value?.mode?.includes(`_`) ? form?.value?.mode.split(`_`)[0] : form?.value?.mode;
                    let upperSetTempValue = (this.mainSiteUIService.multipleRangeSliders == true ? form?.value?.temperature2Upper : form?.value?.upperSetTemp) || this.bgd.setTemp.minMaxPoints[formMode].max;
                    commands.push(
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp1`, // Auto Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp2`, // Setback Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp4`, // Auto Heat // Lowest Point
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTempValue?.toString()) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`,
                            param: `MultiSetTemp5`, // Setback Heat // Lowest Point
                        }
                    );
                }
            }
    
            if (gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
                commands.push({
                    gateway_id: gatewayUnit.gateway_id,
                    group_id: gatewayUnit.group_id,
                    value: form.value.ventMode.toLowerCase().replace(/[ ]/g, `_`).replace(`energy`, `heat`),
                    param: `VentMode`,
                });
            } else {
                if (typeof form.value.temperature == `number` && form.value.mode == `heat` && parseFloat(form.value.temperature) != parseFloat(sentTemp.lower)) form.value.temperature = parseFloat(sentTemp.lower);
                let singleTempModes = [`heat`, `cool`, `dry`, `fan`, `setback`, `setback_heat`, `setback_cool`];
                let dualTempModes = [`auto`, `setback`, `auto_cool`, `auto_heat`, `setback_heat`, `setback_cool`, `dual_auto`];
                if (singleTempModes.includes(form.value.mode) || (dualTempModes.includes(form.value.mode) && (gatewayUnit.setTempMode == SetTempModeEnum.single || gatewayUnit.setTempMode == SetTempModeEnum.singleAndDual))) {
                    commands.push(
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: form.value.mode.toLowerCase(),
                            param: `Mode`
                        },
                        {
                            gateway_id: gatewayUnit.gateway_id,
                            group_id: gatewayUnit.group_id,
                            value: form.value.airDirection.toLowerCase(),
                            param: `AirDirection`
                        },
                    );
                }
    
                if (form.value.mode.toLowerCase() == ModesSpeedsDirections.cool || form.value.mode.toLowerCase() == ModesSpeedsDirections.dry) {
                    let formMode = form?.value?.mode?.includes(`_`) ? form?.value?.mode.split(`_`)[0] : form?.value?.mode;
                    let upperSetTempValue = form?.value?.upperSetTemp || this.bgd.setTemp.minMaxPoints[formMode].max;
                    commands.push({
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: form.value.mode != `fan` ? (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(parseInt(form.value.upperSetTemp)) : upperSetTempValue?.toString().includes(`.`) ? upperSetTempValue?.toString() : upperSetTempValue?.toString() + `.0`) : `5`,
                        param: `SetTemp`,
                    });
                } else {
                    let lowerSetTempToUse = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == false ? form?.value?.lowerSetTemp2 : this.mainSiteUIService.multipleRangeSliders == false && this.mainSiteUIService.temp1DualKnobs == false ? (this.mainSiteUIService.multipleRangeSliders == false ? form?.value?.lowerSetTemp : form?.value?.temperature2Lower) : form?.value?.lowerSetTemp;
                    let formMode = form?.value?.mode?.includes(`_`) ? form?.value?.mode.split(`_`)[0] : form?.value?.mode;
                    let lowerSetTempValue = !formMode.includes(`fan`) ? (lowerSetTempToUse || this.bgd.setTemp.minMaxPoints[formMode].min) : `5`;
                    commands.push({
                        gateway_id: gatewayUnit.gateway_id,
                        group_id: gatewayUnit.group_id,
                        value: !form?.value?.mode.includes(`fan`) ? (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(parseInt(lowerSetTempValue)) : lowerSetTempValue?.toString().includes(`.`) ? lowerSetTempValue?.toString() : lowerSetTempValue?.toString() + `.0`) : `5`,
                        param: `SetTemp`,
                    });
                }
            }
    
            // Pushing an event for each time the user clicked
            this.siteService.eventsToMake.push(calendarEventObject);

            let batchEventParameters = {
                form, 
                commands,
                changeDate, 
                changeTitle, 
                gatewayUnit, 
                spinningLoader,
                calendarEventObject, 
            }

            batchEventsToConvert.push(batchEventParameters);

            // this.postEventToBackEnd(batchEventParameters);
        })

        this.convertBatchEventParametersToDatabaseEvents(batchEventsToConvert);
    }

    async convertBatchEventParametersToDatabaseEvents(batchEventsToConvert) {
        let { calendarEventObject, spinningLoader } = batchEventsToConvert[0];
        let convertedDatabaseEventObjects = batchEventsToConvert.map((batchEvt, batchEvtIndex) => {
            let { commands, form, changeTitle, gatewayUnit, changeDate } = batchEvt;
            let databaseEventObject = {
                commands,
                group_id: gatewayUnit.group_id,
                gateway_id: gatewayUnit.gateway_id,
                name: form.value.title || changeTitle,
                time: this.is_recurring ? moment(this.week[form.value.days[0]] + ` ${form.value.startTime}`).format(`YYYY-MM-DD HH:mm:ss.SSS`) : moment(changeDate).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                ...(this.is_recurring && { 
                    is_recurring: true, 
                    days: { 
                        sunday: form.value.days.includes(`Sunday`), 
                        monday: form.value.days.includes(`Monday`), 
                        tuesday: form.value.days.includes(`Tuesday`), 
                        wednesday: form.value.days.includes(`Wednesday`), 
                        thursday: form.value.days.includes(`Thursday`), 
                        friday: form.value.days.includes(`Friday`), 
                        saturday: form.value.days.includes(`Saturday`),
                    } 
                }),
            }

            return databaseEventObject;
        })
        this.postBatchEventsToBackEnd(convertedDatabaseEventObjects, calendarEventObject, spinningLoader);
    }

    async postBatchEventsToBackEnd(convertedDatabaseEventObjects, calendarEventObject, spinningLoader) {

        if (spinningLoader == null) {
            spinningLoader = await this.loadingController.create({
                spinner: `lines`,
                message: `Updating Event(s)...`,
             });
        
            await spinningLoader.present();
        }

        let batchEventToPost = {
            site_id: this.user.active.id,
            events: convertedDatabaseEventObjects,
            name: convertedDatabaseEventObjects[0].name,
        }

        await this.createBatchEvent(batchEventToPost, calendarEventObject, spinningLoader);

        if (spinningLoader != null && this.formOpen == true) this.modalController.dismiss().then(() => this.siteService.eventsToMake = []);
    }

    async createBatchEvent(batchEventToPost, calendarEventObject = null, spinningLoader = null) {
        await this.siteService.createBatchEvent(batchEventToPost).subscribe(data => {
            let id = (<any>data).id;
            this.eventID = id;
            if (calendarEventObject != null) calendarEventObject.meta.id = id;
            if (spinningLoader != null) spinningLoader.dismiss();
            this.mainSiteUIService.uniqueGateways = [];
            data = { ...data, spinner: spinningLoader != null };
            devEnv && console.log(`Created/Updated Database Batch Event`, data);
            this.onAddEvent.emit(data);
        }, error => {
            console.log(`Error Moving Event`, error);
            if (spinningLoader != null) spinningLoader.dismiss();
        })
    }

    // Create Event & Post to Database
    async postEventToBackEnd(batchEventParameters) {
        let { commands, form, changeTitle, gatewayUnit, changeDate, calendarEventObject, spinningLoader } = batchEventParameters;
        let databaseEventObject = {
            commands,
            group_id: gatewayUnit.group_id,
            gateway_id: gatewayUnit.gateway_id,
            name: form.value.title || changeTitle,
            time: this.is_recurring ? moment(this.week[form.value.days[0]] + ` ${form.value.startTime}`).format(`YYYY-MM-DD HH:mm:ss.SSS`) : moment(changeDate).format(`YYYY-MM-DD HH:mm:ss.SSS`),
            ...(this.is_recurring && { 
                is_recurring: true, 
                days: { 
                    sunday: form.value.days.includes(`Sunday`), 
                    monday: form.value.days.includes(`Monday`), 
                    tuesday: form.value.days.includes(`Tuesday`), 
                    wednesday: form.value.days.includes(`Wednesday`), 
                    thursday: form.value.days.includes(`Thursday`), 
                    friday: form.value.days.includes(`Friday`), 
                    saturday: form.value.days.includes(`Saturday`),
                } 
            }),
        }

        await this.siteService.createSiteEvent(databaseEventObject).subscribe(data => {
            let id = (<any>data).id;
            this.eventID = id;
            calendarEventObject.meta.id = id;
            spinningLoader.dismiss();
            this.mainSiteUIService.uniqueGateways = [];
            this.onAddEvent.emit(data);
        }, error => {
            console.log(`Error Creating Event(s)`, error);
            spinningLoader.dismiss();
        });
        this.modalController.dismiss().then(() => this.siteService.eventsToMake = []);
    }

    // Move/Drag Event
    eventTimesChanged({
        event: eventToChange,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        if (!this.siteService.handleIsConnected()) return;

        // Default Commands
        this.commandsArray = [
            {
                gateway_id: this.selectedGatewayUnit.gateway_id,
                group_id: this.selectedGatewayUnit.group_id,
                value: eventToChange.meta.power.toLowerCase(),
                param: `Drive`,
            },
        ];

        if (((this?.selectedGatewayUnit?.type == GatewayUnitTwoDigitType?.IndoorUnit && this?.selectedGatewayUnit?.fan_mode_sw != `disabled` && this?.selectedGatewayUnit?.fan_speed_sw != `none`) || (this?.selectedGatewayUnit?.type == GatewayUnitTwoDigitType?.Lossnay && this?.selectedGatewayUnit?.lc_fan_speed_sw != `disabled`)) && this?.mapFanSpeed(this?.selectedGatewayUnit?.type, eventToChange?.meta?.fanSpeed?.toLowerCase()) != `disabled` && this?.mapFanSpeed(this?.selectedGatewayUnit?.type, eventToChange?.meta?.fanSpeed?.toLowerCase()) != null && this?.mapFanSpeed(this?.selectedGatewayUnit?.type, eventToChange?.meta?.fanSpeed?.toLowerCase()) != undefined) {
            this.commandsArray.push({
                gateway_id: this.selectedGatewayUnit.gateway_id,
                group_id: this.selectedGatewayUnit.group_id,
                value: this.mapFanSpeed(this.selectedGatewayUnit.type, eventToChange.meta.fanSpeed.toLowerCase()),
                param: `FanSpeed`,
            })
        }

        if (typeof eventToChange.meta.temperature != `number` && eventToChange.meta.unitType == GatewayUnitTwoDigitType.IndoorUnit) {

            this.commandsArray.push({
                param: `Mode`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: eventToChange.meta.mode.toLowerCase(),
            },
            {
                param: `AirDirection`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: eventToChange.meta.airDirection.toLowerCase(),
            },
            {
                param: `MultiSetTemp1`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(eventToChange.meta.temperature.upper.toString()) : eventToChange.meta.temperature.upper.toString().includes(`.`) ? eventToChange.meta.temperature.upper.toString() : eventToChange.meta.temperature.upper.toString() + `.0`,
            },
            {
                param: `MultiSetTemp2`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(eventToChange.meta.temperature.lower.toString()) : eventToChange.meta.temperature.lower.toString().includes(`.`) ? eventToChange.meta.temperature.lower.toString() : eventToChange.meta.temperature.lower.toString() + `.0`,
            },
            {
                param: `MultiSetTemp4`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(eventToChange.meta.temperature.upper.toString()) : eventToChange.meta.temperature.upper.toString().includes(`.`) ? eventToChange.meta.temperature.upper.toString() : eventToChange.meta.temperature.upper.toString() + `.0`,
            },
            {
                param: `MultiSetTemp5`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(eventToChange.meta.temperature.lower.toString()) : eventToChange.meta.temperature.lower.toString().includes(`.`) ? eventToChange.meta.temperature.lower.toString() : eventToChange.meta.temperature.lower.toString() + `.0`,
            });

        } else if (eventToChange.meta.unitType == GatewayUnitTwoDigitType.Lossnay) {

            this.commandsArray.push({
                scale: `C`,
                param: `VentMode`,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                value: eventToChange.meta.mode.toLowerCase().replace(/[ ]/g, `_`).replace(`energy`, `heat`),
            });

        } else {

            this.commandsArray.push(
                {
                    param: `Mode`,
                    group_id: eventToChange.meta.group_id,
                    gateway_id: eventToChange.meta.gateway_id,
                    value: eventToChange.meta.mode.toLowerCase(),
                },
                {
                    param: `AirDirection`,
                    group_id: eventToChange.meta.group_id,
                    gateway_id: eventToChange.meta.gateway_id,
                    value: eventToChange.meta.airDirection.toLowerCase(),
                },
                {
                    param: `SetTemp`,
                    group_id: eventToChange.meta.group_id,
                    gateway_id: eventToChange.meta.gateway_id,
                    value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(eventToChange.meta.temperature.toString()) : eventToChange.meta.temperature.toString().includes(`.`) ? eventToChange.meta.temperature.toString() : eventToChange.meta.temperature.toString() + `.0`,
                }
            );
        }

        // Event Dragged
        if (moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`) != moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`)) {
            if (eventToChange.meta.days.length > 1 || Object.entries(eventToChange.meta.days).filter(day => day[1]).length > 1) {
                // console.log(`Event Days`, Object.entries(eventToChange.meta.days).filter(day => day[1]).map(dayTrue => globalFunctions.capitalizeAllWords(dayTrue[0])));
                if (Object.entries(eventToChange.meta.days).filter(day => day[1] && globalFunctions.capitalizeAllWords(day[0]) != eventToChange.meta.startD).map(dayTrue => globalFunctions.capitalizeAllWords(dayTrue[0])).includes(moment(newStart).format(`dddd`))) {
                    // console.log(`Same Day Conflict`, moment(newStart).format(`dddd`));
                    this.sameDayConflict(eventToChange, newStart);
                } else {
                    if (!Object.entries(eventToChange.meta.days).filter(day => day[1]).map(dayTrue => globalFunctions.capitalizeAllWords(dayTrue[0])).includes(moment(newStart).format(`dddd`))) { // Different Day
                        // Same Time Conflict
                        // console.log(`Different Day Conflict`, moment(newStart).format(`dddd`));
                        for (let i = 0; i < this.events.length; i++) {
                            if (moment(newStart).tz(this.user?.active?.timezone).isBetween(moment(this.events[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(this.events[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                                this.sameTimeConflict();
                                return;
                            }
                        }
                        if (moment(newStart).format(`HH:mm:ss.SSS`) == moment(eventToChange.start).format(`HH:mm:ss.SSS`)) {
                            this.events = this.events.map((iEvent) => {
                                if (iEvent === eventToChange) {
                                    const updatedFrontEndEvent = {
                                        ...eventToChange,
                                        start: newStart,
                                        meta: {
                                            ...eventToChange.meta,
                                            startD: moment(newStart).format(`dddd`),
                                            startT: moment(newStart).format(`h:mm A`),
                                            startDate: moment(newStart).format(`M/D`),
                                            ...(this.is_recurring && eventToChange.meta.days && {
                                                ...eventToChange.meta.days
                                            }),
                                        }
                                        // end: newEnd,
                                    };
                                    // devEnv && console.log(`Event Dragged`, updatedFrontEndEvent);
                                    const initalLocalEvents = (this.events || JSON.parse(localStorage.getItem(`Events`)) || []).map(calendarEvent => {
                                        return calendarEvent = {
                                            ...calendarEvent,
                                            start: new Date(calendarEvent.start) as Date,
                                            meta: {
                                                ...calendarEvent.meta,
                                                updatedBy: this.user.name,
                                                startD: moment(calendarEvent.start).format(`dddd`),
                                                startT: moment(calendarEvent.start).format(`h:mm A`),
                                                startDate: moment(calendarEvent.start).format(`M/D`),
                                                updatedDate: moment().format(this.databaseTimeFormat),
                                                ...(this.is_recurring && eventToChange.meta.days && {
                                                    ...eventToChange.meta.days
                                                }),
                                            },
                                        } as CalendarEvent<EventControlPoints>
                                    })
                                    const initialEvent = initalLocalEvents.filter(evt => evt.meta.id == eventToChange.meta.id)[0];
                                    initalLocalEvents.splice(initalLocalEvents.indexOf(initialEvent), 1, updatedFrontEndEvent);
                                    // localStorage.setItem(`Events`, JSON.stringify(initalLocalEvents));
                                    return updatedFrontEndEvent;
                                }
                                return iEvent;
                            });

                            if (eventToChange.meta.batchEvents.length > 1) {
                                let batchEventsToSave = eventToChange.meta.batchEvents.map(ev => {
                                    return {
                                        id: ev.meta.id,
                                        name: ev.title,
                                        group_id: ev.meta.group_id,
                                        gateway_id: ev.meta.gateway_id,
                                        commands: ev.meta.databaseEvent.commands.commands,
                                        time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                                        ...(this.is_recurring && ev.meta.is_recurring && { is_recurring: ev.meta.is_recurring, days: { ...ev.meta.days, [moment(newStart).format(`dddd`).toLowerCase()]: true, [ev.meta.startD.toLowerCase()]: false } }),
                                    }
                                });

                                let batchEventToUpdate = {
                                    id: eventToChange.meta.batchID,
                                    events: batchEventsToSave,
                                    site_id: this.user.active.id,
                                    name: batchEventsToSave[0].name,
                                }
                    
                                this.siteService.updateBatchEvent(batchEventToUpdate).subscribe((data) => {
                                    devEnv && console.log(`Database Batch Event Updated${(<any>data).time == moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`) ? ` After Same Day` : ``} Drag & Drop`, data);
                                    this.onChangeEvent.emit(data);
                                }, error => {
                                    console.log(`Error Updating Batch Event(s)`, error);
                                })
                            } else {
                                this.siteService.updateSiteEvent({
                                    id: eventToChange.meta.id,
                                    name: eventToChange.title,
                                    group_id: eventToChange.meta.group_id,
                                    gateway_id: eventToChange.meta.gateway_id,
                                    time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                                    commands: eventToChange.meta.databaseEvent.commands.commands,
                                    ...(this.is_recurring && eventToChange.meta.is_recurring && { is_recurring: eventToChange.meta.is_recurring, days: { ...eventToChange.meta.days, [moment(newStart).format(`dddd`).toLowerCase()]: true, [eventToChange.meta.startD.toLowerCase()]: false } }),
                                }).subscribe(data => {
                                    devEnv && console.log(`Database Event Updated${(<any>data).time == moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`) ? ` After Same Day` : ``} Drag & Drop`, data);
                                    this.onChangeEvent.emit(data);
                                }, error => console.log(error));
                            }
                        } else {
                            this.askEventOrSeries(eventToChange, `Move`, false, newStart, false);
                        }
                    } else {
                        // Move series
                        const multiDates = [];

                        // Handle Drag Series Same Day / Time Conflict
                        for (let i = 0; i < this.events.filter(evt => evt.meta.id != eventToChange.meta.id).length; i++) {

                            let eventStartTime = this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start;
                            let newStartTimeAtPositionOfDrop = moment(newStart).tz(this.user?.active?.timezone);
                            let earliestTimeAllowed = moment(eventStartTime).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`);
                            let latestTimeAllowed = moment(eventStartTime).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`);
                            multiDates.push(moment(eventStartTime).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));

                            for (let j = 1; j < this.minuteBarrier; j++) {
                                multiDates.push(moment(eventStartTime).add(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
                                multiDates.push(moment(eventStartTime).subtract(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
                            }

                            if (newStartTimeAtPositionOfDrop.isBetween(earliestTimeAllowed, latestTimeAllowed)) {
                                this.sameTimeConflict({
                                    newStart, 
                                    multiDates, 
                                    eventToChange, 
                                    eventStartTime, 
                                    latestTimeAllowed,
                                    events: this.events, 
                                    earliestTimeAllowed, 
                                    newStartTimeAtPositionOfDrop,
                                    latest: latestTimeAllowed.format(`hh:mm A dddd`),
                                    earliest: earliestTimeAllowed.format(`hh:mm A dddd`),
                                    time: newStartTimeAtPositionOfDrop.format(`hh:mm A dddd`),
                                    type: `Event Dragged 2: Handle Drag Series Same Day / Time Conflict`, 
                                });
                                return;
                            }
                        }

                        // Handle Drag Series Different Day Conflict
                        // for (let i = 0; i < eventToChange.meta.daysArray.length; i++) {
                        //     if ([...new Set(multiDates)].includes(newStartMoment.format(`hh:mm A`) + ` ` + eventToChange.meta.daysArray[i])) {
                        //         this.sameTimeConflict();
                        //         return;
                        //     }
                        // }

                        this.askEventOrSeries(eventToChange, `Move`, false, newStart, true);
                    }
                }
            } else { // Move Event
                // Same Time Conflict
                let eventsForThisGroup = this.events.filter(ev => ev.meta.group_id == eventToChange.meta.group_id);
                if (eventsForThisGroup.length > 1) {
                    for (let i = 0; i < eventsForThisGroup.length; i++) {
                        if (moment(newStart).tz(this.user?.active?.timezone).isBetween(moment(eventsForThisGroup[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(eventsForThisGroup[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                            this.sameTimeConflict();
                            return;
                        }
                    }
                }
                
                const actuallyMoveEvent = () => {
                    this.events = this.events.map((iEvent) => {
                        if (iEvent === eventToChange) {
                            const updatedFrontEndEvent = {
                                ...eventToChange,
                                start: newStart,
                                meta: {
                                    ...eventToChange.meta,
                                    startD: moment(newStart).format(`dddd`),
                                    startT: moment(newStart).format(`h:mm A`),
                                    startDate: moment(newStart).format(`M/D`),
                                    ...(this.is_recurring && eventToChange.meta.days && {
                                        ...eventToChange.meta.days
                                    }),
                                }
                                // end: newEnd,
                            };
                            // devEnv && console.log(`Event Moved`, updatedFrontEndEvent);
                            const initalLocalEvents = (this.events || JSON.parse(localStorage.getItem(`Events`)) || []).map(calendarEvent => {
                                return calendarEvent = {
                                    ...calendarEvent,
                                    start: new Date(calendarEvent.start) as Date,
                                    meta: {
                                        ...calendarEvent.meta,
                                        updatedBy: this.user.name,
                                        startD: moment(calendarEvent.start).format(`dddd`),
                                        startT: moment(calendarEvent.start).format(`h:mm A`),
                                        startDate: moment(calendarEvent.start).format(`M/D`),
                                        updatedDate: moment().format(this.databaseTimeFormat),
                                        ...(this.is_recurring && eventToChange.meta.days && {
                                            ...eventToChange.meta.days
                                        }),
                                    },
                                } as CalendarEvent<EventControlPoints>
                            });
                            const initialEvent = initalLocalEvents.filter(evt => evt.meta.id == eventToChange.meta.id)[0];
                            initalLocalEvents.splice(initalLocalEvents.indexOf(initialEvent), 1, updatedFrontEndEvent);
                            // localStorage.setItem(`Events`, JSON.stringify(initalLocalEvents));
                            return updatedFrontEndEvent;
                        }
                        return iEvent;
                    });
                }

                if (eventToChange.meta.batchEvents.length > 1) {
                    let batchEventsToSave = eventToChange.meta.batchEvents.map(ev => {
                        return {
                            id: ev.meta.id,
                            name: ev.title,
                            group_id: ev.meta.group_id,
                            gateway_id: ev.meta.gateway_id,
                            commands: ev.meta.databaseEvent.commands.commands,
                            time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                            ...(this.is_recurring && ev.meta.is_recurring && { is_recurring: ev.meta.is_recurring, days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` } }),
                        }
                    });

                    let batchEventToUpdate = {
                        id: eventToChange.meta.batchID,
                        events: batchEventsToSave,
                        site_id: this.user.active.id,
                        name: batchEventsToSave[0].name,
                    }

                    let selectedGroupIDs = [...new Set(this.selectedGroups.map(gr => gr?.id))];
                    let groupIDsInEvent = [...new Set(eventToChange?.meta?.batchEvents.map(evt => evt?.meta?.group_id))];
                    let batchGroupIDsAllSelected = groupIDsInEvent.every(id => selectedGroupIDs.includes(id));
                    let batchGroupIDsSomeSelected = groupIDsInEvent.some(id => selectedGroupIDs.includes(id));

                    if (batchGroupIDsAllSelected == true && batchGroupIDsSomeSelected == true) {
                        actuallyMoveEvent();
                        this.siteService.updateBatchEvent(batchEventToUpdate).subscribe((data) => {
                            devEnv && console.log(`Database Batch Event Updated${(<any>data).time == moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`) ? ` After Same Day` : ``} Move`, data);
                            this.onChangeEvent.emit(data);
                        }, error => {
                            console.log(`Error Updating Batch Event(s)`, error);
                        })
                    } else {
                        this.askEventOrSeries(eventToChange, `Move`, false, newStart, true);
                    }
                } else {
                    this.siteService.updateSiteEvent({
                        id: eventToChange.meta.id,
                        name: eventToChange.title,
                        group_id: eventToChange.meta.group_id,
                        gateway_id: eventToChange.meta.gateway_id,
                        time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                        commands: eventToChange.meta.databaseEvent.commands.commands,
                        ...(this.is_recurring && eventToChange.meta.is_recurring && { is_recurring: eventToChange.meta.is_recurring, days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` } }),
                    }).subscribe(data => {
                        devEnv && console.log(`Database Event Updated${(<any>data).time == moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`) ? ` After Same Day` : ``} Move`, data);
                        this.onChangeEvent.emit(data);
                    }, error => console.log(error));
                }
            }
        } else {
            this.handleEvent(`Clicked`, eventToChange, true);
        }
    }

    async sameDayConflict(eventMoved: any, newStart?) {
        const sameDayConflictMsg = await this.alertController.create({
            id: `sameDayConflictMsg`,
            cssClass: `me-info-button-css alertMsg`,
            header: `Cannot Move Event`,
            subHeader: `Multiday event moved to same day.`,
            message: `You cannot move a multiday event to a day it already exists on.`,
            buttons: [
                {
                    text: `Move Event`, role: `ok`, cssClass: `ok-button series-button ${!devEnv && `hiddenAlertButton`}`, handler: () => {
                        // Same Time Conflict
                        for (let i = 0; i < this.events.length; i++) {
                            if (moment(newStart).tz(this.user?.active?.timezone).isBetween(moment(this.events[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(this.events[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                                this.sameTimeConflict();
                                return;
                            }
                        }
                        this.siteService.createSiteEvent({
                            is_recurring: true,
                            name: eventMoved.title,
                            group_id: eventMoved.meta.group_id,
                            gateway_id: eventMoved.meta.gateway_id,
                            time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                            commands: this.formOpen == true ? this.commandsArray : eventMoved.meta.databaseEvent.commands.commands,
                            days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` }
                        }).subscribe(data => {
                            this.eventID = (<any>data).id;
                            devEnv && console.log(`New Event for ${this.selectedGatewayUnit.group_name}`, eventMoved);
                            this.onAddEvent.emit(data);
                        }, error => console.log(error));

                        this.siteService.updateSiteEvent({
                            id: eventMoved.meta.id,
                            name: eventMoved.title,
                            group_id: eventMoved.meta.group_id,
                            gateway_id: eventMoved.meta.gateway_id,
                            time: moment(eventMoved.start).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                            commands: this.formOpen == true ? this.commandsArray : eventMoved.meta.databaseEvent.commands.commands,
                            ...(this.is_recurring && eventMoved.meta.is_recurring && {
                                is_recurring: eventMoved.meta.is_recurring, days: {
                                    ...eventMoved.meta.days,
                                    [moment(newStart).format(`dddd`).toLowerCase()]: false
                                }
                            }),
                        }).subscribe(data => {
                            // devEnv && console.log(`----------------------------------------`);
                            // devEnv && console.log(`DB Event Updated`, data);
                            // if (moment((<any>data).time).format(`YYYY-MM-DD HH:mm:ss.SSS`) == moment(eventMoved.start).format(`YYYY-MM-DD HH:mm:ss.SSS`)) {
                            //     console.log(`----------------------------------------`);
                            //     console.log(`Same Day Drag`);
                            // }
                            // inform parent
                            this.onChangeEvent.emit(data);
                        }, error => console.log(error));
                    }
                },
                // {
                //     text: `Copy Event`, role: `ok`, cssClass: `ok-button redAlertButton ${!devEnv && `hiddenAlertButton`}`, handler: () => {
                //         this.siteService.createSiteEvent({
                //             is_recurring: true,
                //             name: eventMoved.title,
                //             commands: this.commandsArray,
                //             group_id: eventMoved.meta.group_id,
                //             gateway_id: eventMoved.meta.gateway_id,
                //             time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                //             days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` }
                //         }).subscribe(data => {
                //             this.eventID = (<any>data).id;
                //             // devEnv && console.log(`New Event in DB for Unit ${this.selectedGatewayUnit.group_name}`, data);
                //             devEnv && console.log(`New Event for ${this.selectedGatewayUnit.group_name}`, eventMoved);
                //             // devEnv && console.log(`Updated Events for ${this.selectedGatewayUnit.group_name}`, this.events);
                //             // inform parent there is a new event.
                //             this.onAddEvent.emit(data);
                //         }, error => console.log(error));
                //     }
                // },
                {
                    text: `Ok`, role: `cancel`, cssClass: `ok-button ${!devEnv ? `editE` : `cancel-button`}`, handler: () => this.alertController.dismiss().then(data => {
                        // devEnv && console.log(data);
                        this.alertOpen = false;
                    })
                }]
        });
        this.alertOpen = true;
        return await sameDayConflictMsg.present();
    }

    async askMoveOrCopy(eventMoved: any, newStart?, newEnd?) {
        const moveOrCopyMsg = await this.alertController.create({
            header: `Event Moved or Clicked on ${eventMoved.meta.startD}`,
            id: `moveOrCopyMessage`,
            cssClass: `me-info-button-css alertMsg`,
            message: `Would you like to actually Move the Event? Or did you mean to Edit the Event? You can also move the entire series, or click out to exit.`,
            buttons: [
                { text: `Edit Event`, role: `ok`, cssClass: `ok-button redAlertButton`, handler: () => this.handleEvent(`Clicked`, eventMoved, false) },
                { text: `Move Event`, role: `ok`, cssClass: `ok-button series-button`, handler: () => this.eventTimesChanged(eventMoved) },
                {
                    text: `Move Series`, role: `ok`, cssClass: `ok-button cancel-button`, handler: () => this.alertController.dismiss().then(data => {
                        // devEnv && console.log(data);
                        this.alertOpen = false;
                    })
                }]
        });
        this.alertOpen = true;
        return await moveOrCopyMsg.present();
    }

    async deleteBatchEventFromBackEnd(batchID, formOpen, showSpinner = true, batchEvent = true) {

        let spinningLoader = null;

        if (showSpinner == true) {
            spinningLoader = await this.loadingController.create({
                spinner: `lines`,
                message: `Deleting${batchEvent == true ? ` Batch` : ``} Event...`,
            });
        
            await spinningLoader.present();
        }

        await this.siteService.deleteBatchEvent(this.user.active.id, batchID).subscribe(data => {
            devEnv && console.log(`Batch Event Deleted`, data);
            if (showSpinner == true && spinningLoader != null) this.onDeleteEvent.emit(data);
            if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            if (formOpen) this.modalController.dismiss();
        }, error => {
            console.log(`Delete Batch Event Error`, error);
            if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            if (formOpen) this.modalController.dismiss();
        });
    }
    
    async removeGroupFromBatch(batchID, siteID, arrayOfGroupIDs, eventToChange, formOpen, showSpinner = true) {

        let spinningLoader = null;
        if (showSpinner == true) {
            spinningLoader = await this.loadingController.create({
                spinner: `lines`,
                message: `Removing Group...`,
            });
        
            await spinningLoader.present();
        }

        await this.siteService.removeGroupFromBatchEvent(batchID, siteID, arrayOfGroupIDs).subscribe(data => {
            devEnv && console.log(`Group removed from Batch Event`, data);
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            this.onDeleteEvent.emit({...eventToChange, spinner: showSpinner == true && spinningLoader != null});
            if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
            if (formOpen) this.modalController.dismiss();
        }, error => {
            console.log(`Error Removing Group from Batch Event`, error);
            if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            if (formOpen) this.modalController.dismiss();
        })
    }

    async deleteFromBackEnd(eventToDeleteGatewayID, eventToDeleteID, eventToDelete) {

        const spinningLoader = await this.loadingController.create({
            spinner: `lines`,
            message: `Deleting Event(s)...`,
        });
    
        await spinningLoader.present();

        await this.siteService.deleteSiteEvent(eventToDeleteGatewayID, eventToDeleteID).subscribe(eventDeleted => {
            devEnv && console.log(`Event Deleted`, eventToDelete);
            this.onDeleteEvent.emit(eventDeleted);
            spinningLoader.dismiss();
        }, error => {
            devEnv && console.log(`Error Deleting Event(s)`, error);
            spinningLoader.dismiss();
            return error;
        });
    }

    async deleteDayFromEvent(eventToChange, showSpinner = true) {
        
        let spinningLoader = null;
        
        if (showSpinner == true) {
            spinningLoader= await this.loadingController.create({
                spinner: `lines`,
                message: `Deleting Event(s)...`,
            });
        
            await spinningLoader.present();
        }

        await this.siteService.getSiteEvents(this.user?.active?.id).subscribe((dbEvents: any) => {
            this.siteService.updateSiteEvent({ // Event Instance Deleted
                id: eventToChange.meta.id,
                name: eventToChange.title,
                group_id: eventToChange.meta.group_id,
                gateway_id: eventToChange.meta.gateway_id,
                time: dbEvents.events.filter(evt => evt.id == eventToChange.meta.id)[0].time,
                commands: dbEvents.events.filter(evt => evt.id == eventToChange.meta.id)[0].commands.commands,
                ...(this.is_recurring && eventToChange.meta.is_recurring && {
                    is_recurring: eventToChange.meta.is_recurring
                }),
                ...(this.is_recurring && eventToChange.meta.is_recurring && eventToChange.meta.days && { days: { ...eventToChange.meta.days, [eventToChange.meta.startD.toLowerCase()]: false } }),
            }).subscribe(data => {
                data = { ...data, spinner: showSpinner == true && spinningLoader != null };
                if (showSpinner == true && spinningLoader != null) this.onChangeEvent.emit(data);
                if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
            }, error => {
                console.log(`Error Deleting Event(s)`, error);
                if (showSpinner == true && spinningLoader != null) spinningLoader.dismiss();
                return error;
            });
        });
    }

    async askEventOrSeries(eventToChange: CalendarEvent<EventControlPoints>, typeOfChange?, formOpen?, newStart?, seriesEnabled?, form?, daysChanged?) {
        let selectedGroupIDs = [...new Set(this.selectedGroups.map(gr => gr?.id))];
        let groupIDsInEvent = [...new Set(eventToChange?.meta?.batchEvents.map(evt => evt?.meta?.group_id))];
        let batchGroupIDsAllSelected = groupIDsInEvent.every(id => selectedGroupIDs.includes(id));
        let batchGroupIDsSomeSelected = groupIDsInEvent.some(id => selectedGroupIDs.includes(id));
        let sameTimeEvent = eventToChange?.meta?.sameTimeEvents && eventToChange?.meta?.sameTimeEvents.length > 1;
        let groupedEvent = eventToChange?.meta?.groupedEvents && eventToChange?.meta?.groupedEvents.length > 0;
        let batchEvent = eventToChange?.meta?.batchID && eventToChange?.meta?.batchID != `` && eventToChange?.meta?.batchEvents?.length > 0;
        let buttonTitle = batchEvent == true ? `${typeOfChange} ${eventToChange?.meta?.batchEvents.length > 1 ? `Batch` : ``} Event${(sameTimeEvent == true) ? `(s)` : ``}` : `${typeOfChange} Event${(sameTimeEvent == true) ? `(s)` : ``}`;
        let allBatchSeriesButtonsEnabled = batchEvent == true && eventToChange.meta.multiDayEvent == true && batchGroupIDsSomeSelected == true && batchGroupIDsAllSelected == false;
        
        let changeBatchButtonTitle = `${typeOfChange}${eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1 ? ` Batch` : ``} Event${eventToChange.meta.multiDayEvent == true ? ` Series` : ``}`;
        let changeSeriesButtonTitle = `${typeOfChange} Series`;
        let changeGroupOrDayOrEventButtonTitle = batchEvent == true && eventToChange.meta.multiDayEvent == false ? `Remove Selected Group(s) from Batch Event` : batchEvent == true ? `${typeOfChange} ${eventToChange?.meta?.batchEvents.length > 1 ? `Batch` : ``} Event Day` : `${typeOfChange} Event`;

        let subHeader = `Are you sure you would like to ${typeOfChange.toLowerCase()} this ${batchEvent == true && eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1 ? `batch ` : ``}event titled "${eventToChange.title}"?`;
        let message = eventToChange.meta.multiDayEvent == true ? `You can ${typeOfChange.toLowerCase()} this ${eventToChange.meta.startD} ${batchEvent == true && eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1 ? `batch ` : ``}event${allBatchSeriesButtonsEnabled == true ? `, ${typeOfChange == `Delete` ? `re` : ``}move ${this.selectedGroups.length} group(s) from the batch event ` : ``}${seriesEnabled == true ? ` or ${typeOfChange.toLowerCase()} the entire${batchEvent == true && eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1 ? ` batch` : ``} multiday series.` : `.`}` : `You can ${batchEvent == true ? batchGroupIDsSomeSelected == true && batchGroupIDsAllSelected == false ? `${typeOfChange == `Delete` ? `re` : ``}move` : `delete` : typeOfChange.toLowerCase()} ${batchEvent == true && batchGroupIDsSomeSelected == true && batchGroupIDsAllSelected == false ? `${this.selectedGroups.length} group(s) from the` : `this ${eventToChange.meta.startD}`} ${batchEvent == true && eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1 ? ` batch ` : ``}event${batchEvent == true && batchGroupIDsSomeSelected == true && batchGroupIDsAllSelected == false ? ` or ${typeOfChange.toLowerCase()} the entire batch event` : ``}.`;

        if (typeOfChange == `Delete`) { // Event Deleted
            let buttons = [
                {
                    text: changeBatchButtonTitle, role: `ok`, cssClass: `${typeOfChange}BatchButton ok-button ${batchEvent == true && eventToChange.meta.multiDayEvent == true || this.selectedGroups.length > 1 ? `` : `series-button`} eventOptionButton`, handler: () => {
                        let isBatchEventWithMultiple = eventToChange?.meta?.batchEvents && eventToChange?.meta?.batchEvents.length > 1;
                        this.deleteBatchEventFromBackEnd(eventToChange?.meta?.batchID, formOpen, true, isBatchEventWithMultiple);                            
                    }
                },
                {
                    text: changeSeriesButtonTitle, role: `ok`, cssClass: `${typeOfChange}SeriesButton ok-button series-button eventOptionButton`, handler: () => {
                        this.events = this.events.filter((evt) => evt !== eventToChange && evt.meta.gateway_id == this.selectedGatewayUnit.gateway_id && evt.meta.group_id == this.selectedGatewayUnit.group_id).sort((event1: any, event2: any) => event1.start - event2.start);
                        this.deleteFromBackEnd(eventToChange.meta.gateway_id, eventToChange.meta.id, eventToChange);
                        if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                        if (formOpen) this.modalController.dismiss();
                    }
                },
                {
                    text: changeGroupOrDayOrEventButtonTitle, role: `ok`, cssClass: `${typeOfChange}EventButton ok-button eventOptionButton`, handler: () => {
                        if (eventToChange.meta.multiDayEvent && batchEvent == false) {
                            this.events = this.events.filter(evt => evt?.meta?.seriesID != eventToChange.meta.seriesID);
                            this.deleteDayFromEvent(eventToChange);
                            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                            if (formOpen) this.modalController.dismiss();
                        } else {
                            if (groupedEvent == true && batchEvent == false) {
                                eventToChange?.meta?.groupedEvents?.forEach(evt => {
                                    this.deleteFromBackEnd(evt.meta.gateway_id, evt.meta.id, evt);
                                })
                                if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                                if (formOpen) this.modalController.dismiss();
                            } else if (batchEvent == true && eventToChange.meta.multiDayEvent == false) {
                                let batchID = eventToChange.meta.batchID;
                                let siteID = this.user.active.id;
                                let arrayOfGroupIDs = this.selectedGroups.map(gr => gr.id);
                                this.removeGroupFromBatch(batchID, siteID, arrayOfGroupIDs, eventToChange, formOpen);
                            } else if (batchEvent == true && eventToChange.meta.multiDayEvent == true) {
                                devEnv && console.log(`After Delete Batch Event Day ${changeGroupOrDayOrEventButtonTitle}`, eventToChange);
                                this.events = this.events.filter(evt => evt?.meta?.seriesID != eventToChange.meta.seriesID);
                                eventToChange.meta.batchEvents.forEach((evt, evtIndex) => this.deleteDayFromEvent(evt, evtIndex == 0));
                                if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                                if (formOpen) this.modalController.dismiss();
                            } else {
                                this.deleteFromBackEnd(eventToChange.meta.gateway_id, eventToChange.meta.id, eventToChange);
                                if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                                if (formOpen) this.modalController.dismiss();
                            }
                        }
                    }
                },
                {
                    text: `Save Day(s)`, role: `ok`, cssClass: `saveDaysButton ok-button deleteDaysBtn eventOptionButton`, handler: () => this.updateEvent(form, eventToChange)
                },
            ];

            if (batchEvent == false) buttons = buttons.filter(btn => btn.text != changeBatchButtonTitle);
            if (eventToChange.meta.multiDayEvent == false || batchGroupIDsAllSelected == false || (batchEvent == true && eventToChange.meta.multiDayEvent == true)) buttons = buttons.filter(btn => btn.text != changeSeriesButtonTitle);
            if (batchEvent == true && batchGroupIDsAllSelected == true && eventToChange.meta.multiDayEvent == false) buttons = buttons.filter(btn => btn.text != changeGroupOrDayOrEventButtonTitle);
            if (!daysChanged || !devEnv) buttons = buttons.filter(btn => btn.text != `Save Day(s)`);
            if (allBatchSeriesButtonsEnabled == true) {
                buttons.push({
                    text: `Remove Selected Group(s) from ${eventToChange?.meta?.batchEvents.length > 1 ? `Batch` : ``} Event`, role: `ok`, cssClass: `${typeOfChange}EventButton ok-button eventOptionButton`, handler: () => {
                        let batchID = eventToChange.meta.batchID;
                        let siteID = this.user.active.id;
                        let arrayOfGroupIDs = this.selectedGroups.map(gr => gr.id);
                        this.removeGroupFromBatch(batchID, siteID, arrayOfGroupIDs, eventToChange, formOpen);
                    }
                })
            }
            buttons.push({
                text: `Cancel`, role: `cancel`, cssClass: `cancelButton ok-button cancel-button`, handler: () => this.alertController.dismiss().then(data => {
                    this.alertOpen = false;
                })
            });
            buttons = buttons.map(btn => ({ ...btn, cssClass: `${btn.cssClass} ${(buttons.length - 1) > 1 ? `multi` : `single`}EventOptionButton` }));

            const eventOrSeriesMessage = await this.alertController.create({
                buttons,
                message,
                subHeader,
                header: buttonTitle,
                id: `eventOrSeriesMessage`,
                cssClass: `alertMsg ${devEnv && daysChanged ? `devMsg` : ``} me-info-button-css ${(allBatchSeriesButtonsEnabled == true || buttons.length > 3) ? `extended` : ``}`,
            });
            this.alertOpen = true;
            return await eventOrSeriesMessage.present();
        } else if (typeOfChange == `Move`) { // Event Moved
            let moveBatchEventSeriesOrButtonTitle = eventToChange.meta.batchEvents && eventToChange.meta.batchEvents.length > 1 ? changeBatchButtonTitle : `${typeOfChange} Series`;
            let buttons = [{
                text: moveBatchEventSeriesOrButtonTitle, role: `ok`, cssClass: `ok-button ${seriesEnabled && `series-button`} ${!seriesEnabled && `hiddenAlertButton`} eventOptionButton`, handler: () => {

                    // Move series
                    const multiDates = [];

                    // Drag Series Same Time Conflict
                    for (let i = 0; i < this.events.filter(evt => evt.meta.id != eventToChange.meta.id).length; i++) {

                        multiDates.push(moment(this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));

                        for (let j = 1; j < this.minuteBarrier; j++) {
                            multiDates.push(moment(this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start).add(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
                            multiDates.push(moment(this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start).subtract(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
                        }

                        if (moment(newStart).tz(this.user?.active?.timezone).isBetween(moment(this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(this.events.filter(evt => evt.meta.id != eventToChange.meta.id)[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                            this.sameTimeConflict();
                            return;
                        }
                    }

                    for (let i = 0; i < eventToChange.meta.daysArray.length; i++) {
                        if ([...new Set(multiDates)].includes(moment(newStart).tz(this.user?.active?.timezone).format(`hh:mm A`) + ` ` + eventToChange.meta.daysArray[i])) {
                            this.sameTimeConflict();
                            return;
                        }
                    }

                    if (eventToChange.meta.batchEvents.length > 1) {
                        let batchEventsToSave = eventToChange.meta.batchEvents.map(ev => {
                            let days = { ...ev.meta.days };
                            if (moveBatchEventSeriesOrButtonTitle == `${typeOfChange} Batch Event`) {
                                for (let day in days) {
                                    days[day] = day === moment(newStart).format(`dddd`).toLowerCase();
                                }
                            }
                            return {
                                name: ev.title,
                                id: ev.meta.id,
                                group_id: ev.meta.group_id,
                                gateway_id: ev.meta.gateway_id,
                                commands: ev.meta.databaseEvent.commands.commands,
                                time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                                ...(this.is_recurring && ev.meta.is_recurring && { is_recurring: ev.meta.is_recurring }),
                                ...(this.is_recurring && ev.meta.is_recurring && ev.meta.days && { days }),
                            }
                        });
    
                        let batchEventToUpdate = {
                            id: eventToChange.meta.batchID,
                            events: batchEventsToSave,
                            site_id: this.user.active.id,
                            name: batchEventsToSave[0].name,
                        }
            
                        this.siteService.updateBatchEvent(batchEventToUpdate).subscribe((data: any) => {
                            devEnv && console.log(`Database Event Updated After Move Batch Series or Move Batch Event`, {
                                ...data,
                                events: data.events.map(ev => ({ ...ev, groupName: this.mainSiteUIService.allSiteGroups.find(gr => gr.group_id == ev.group_id)?.name }))
                            });
                            this.onChangeEvent.emit(data);
                        }, error => {
                            console.log(`Error Updating Batch Event(s)`, error);
                        })
                    } else {
                        this.siteService.updateSiteEvent({
                            name: eventToChange.title,
                            id: eventToChange.meta.id,
                            group_id: eventToChange.meta.group_id,
                            gateway_id: eventToChange.meta.gateway_id,
                            time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                            commands: eventToChange.meta.databaseEvent.commands.commands,
                            ...(this.is_recurring && eventToChange.meta.is_recurring && { is_recurring: eventToChange.meta.is_recurring }),
                            ...(this.is_recurring && eventToChange.meta.is_recurring && eventToChange.meta.days && { days: { ...eventToChange.meta.days } }),
                        }).subscribe((data) => {
                            // devEnv && console.log(`----------------------------------------`);
                            // devEnv && console.log(`DB Event Updated`, data);
                            // inform parent
                            this.onChangeEvent.emit(data);
                        }, error => console.log(error));
                    }
                    if (formOpen) this.modalController.dismiss();
                }
            }];

            if (batchGroupIDsSomeSelected == true && batchGroupIDsAllSelected == false) {
                let buttonTitle = `${typeOfChange} Selected Group(s) in Batch`;
                buttons.push({
                    text: buttonTitle, role: `ok`, cssClass: `ok-button eventOptionButton`, handler: () => {
    
                        // Same Time Conflict
                        for (let i = 0; i < this.events.length; i++) {
                            if (moment(newStart).tz(this.user?.active?.timezone).isBetween(moment(this.events[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(this.events[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                                this.sameTimeConflict();
                                return;
                            }
                        }
    
                        // Move Event
                        const actuallyMoveEvent = () => {
                            this.events = this.events.map((iEvent) => {
                                if (iEvent === eventToChange) {
                                    const updatedFrontEndEvent = {
                                        ...eventToChange,
                                        start: newStart,
                                        meta: {
                                            ...eventToChange.meta,
                                            startD: moment(newStart).format(`dddd`),
                                            startT: moment(newStart).format(`h:mm A`),
                                            startDate: moment(newStart).format(`M/D`),
                                            ...(this.is_recurring && eventToChange.meta.days && {
                                                ...eventToChange.meta.days
                                            }),
                                        }
                                        // end: newEnd,
                                    };
                                    // devEnv && console.log(`Event Moved`, updatedFrontEndEvent);
                                    const initalLocalEvents = (this.events || JSON.parse(localStorage.getItem(`Events`)) || []).map(calendarEvent => {
                                        return calendarEvent = {
                                            ...calendarEvent,
                                            start: new Date(calendarEvent.start) as Date,
                                            meta: {
                                                ...calendarEvent.meta,
                                                updatedBy: this.user.name,
                                                startD: moment(calendarEvent.start).format(`dddd`),
                                                startT: moment(calendarEvent.start).format(`h:mm A`),
                                                startDate: moment(calendarEvent.start).format(`M/D`),
                                                updatedDate: moment().format(this.databaseTimeFormat),
                                                ...(this.is_recurring && eventToChange.meta.days && {
                                                    ...eventToChange.meta.days
                                                }),
                                            },
                                        } as CalendarEvent<EventControlPoints>
                                    });
                                    const initialEvent = initalLocalEvents.filter(evt => evt.meta.id == eventToChange.meta.id)[0];
                                    initalLocalEvents.splice(initalLocalEvents.indexOf(initialEvent), 1, updatedFrontEndEvent);
                                    // localStorage.setItem(`Events`, JSON.stringify(initalLocalEvents));
                                    return updatedFrontEndEvent;
                                }
                                return iEvent;
                            });
                        }
    
                        // let errorsOccured = false;
                        // if (this.selectedGroups.length == 1) {
                        //     this.siteService.createSiteEvent({
                        //         is_recurring: true,
                        //         name: eventToChange.title,
                        //         group_id: eventToChange.meta.group_id,
                        //         gateway_id: eventToChange.meta.gateway_id,
                        //         time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                        //         commands: formOpen == true ? this.commandsArray : eventToChange.meta.databaseEvent.commands.commands,
                        //         days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` }
                        //     }).subscribe(data => {
                        //         this.eventID = (<any>data).id;
                        //         devEnv && console.log(`New Event for ${this.selectedGatewayUnit.group_name} After ${buttonTitle}`, data);
                        //         this.onAddEvent.emit(data);
                        //     }, error => {
                        //         errorsOccured = true;
                        //         console.log(`Error Creating Event After ${buttonTitle}`, error);
                        //     });
        
                        //     this.siteService.updateSiteEvent({
                        //         id: eventToChange.meta.id,
                        //         name: eventToChange.title,
                        //         group_id: eventToChange.meta.group_id,
                        //         gateway_id: eventToChange.meta.gateway_id,
                        //         time: moment(eventToChange.start).format(`YYYY-MM-DD HH:mm:ss.SSS`),
                        //         commands: formOpen == true ? this.commandsArray : eventToChange.meta.databaseEvent.commands.commands,
                        //         ...(this.is_recurring && eventToChange.meta.is_recurring && {
                        //             is_recurring: eventToChange.meta.is_recurring, days: {
                        //                 ...eventToChange.meta.days,
                        //                 [eventToChange.meta.startD.toLowerCase()]: false,
                        //                 [moment(newStart).format(`dddd`).toLowerCase()]: false
                        //             }
                        //         }),
                        //     }).subscribe(data => {
                        //         devEnv && console.log(`Updated Event After ${buttonTitle}`, data);
                        //         actuallyMoveEvent();
                        //         if (errorsOccured == true) this.onChangeEvent.emit(data);
                        //     }, error => {
                        //         errorsOccured = true;
                        //         console.log(`Error Updating Event After ${buttonTitle}`, error);
                        //     });
                        // } else {
                            this.moveEventDay(eventToChange, newStart, false, true);
                        // }
                        
                        if (formOpen) this.modalController.dismiss();
                    }
                });
            }

            if (eventToChange.meta.multiDayEvent == true) {
                buttons.push({
                    text: buttonTitle, role: `ok`, cssClass: `moveBatchEventButton ok-button eventOptionButton`, handler: () => {
                        this.moveEventDay(eventToChange, newStart, true);
                        if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                        if (formOpen) this.modalController.dismiss();
                    }
                });
            }

            buttons.push({
                text: `Cancel`, role: `cancel`, cssClass: `ok-button cancel-button`, handler: () => this.alertController.dismiss().then(data => {
                    this.alertOpen = false;
                })
            })

            // if (seriesEnabled == false && devEnv == true) {
            //     buttons.unshift({
            //         text: `Copy Event`, role: `ok`, cssClass: `ok-button series-button ${seriesEnabled && `hiddenAlertButton`} ${!devEnv && `hiddenAlertButton`} eventOptionButton`, handler: () => {
            //             this.siteService.createSiteEvent({
            //                 is_recurring: true,
            //                 name: eventToChange.title,
            //                 commands: this.commandsArray,
            //                 group_id: eventToChange.meta.group_id,
            //                 gateway_id: eventToChange.meta.gateway_id,
            //                 time: moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`),
            //                 days: { sunday: moment(newStart).format(`dddd`) == `Sunday`, monday: moment(newStart).format(`dddd`) == `Monday`, tuesday: moment(newStart).format(`dddd`) == `Tuesday`, wednesday: moment(newStart).format(`dddd`) == `Wednesday`, thursday: moment(newStart).format(`dddd`) == `Thursday`, friday: moment(newStart).format(`dddd`) == `Friday`, saturday: moment(newStart).format(`dddd`) == `Saturday` }
            //             }).subscribe(data => {
            //                 this.eventID = (<any>data).id;
            //                 // devEnv && console.log(`New Event in DB for Unit ${this.selectedGatewayUnit.group_name}`, data);
            //                 devEnv && console.log(`New Event for ${this.selectedGatewayUnit.group_name}`, eventToChange);
            //                 // devEnv && console.log(`Updated Events for ${this.selectedGatewayUnit.group_name}`, this.events);
            //                 // inform parent there is a new event.
            //                 this.onAddEvent.emit(data);
            //             }, error => console.log(error));
            //         }
            //     });
            // }

            buttons = buttons.map(btn => ({ ...btn, cssClass: `${btn.cssClass} ${(buttons.length - 1) > 1 ? `multi` : `single`}EventOptionButton` }));

            const eventOrSeriesMessage = await this.alertController.create({
                message,
                buttons,
                subHeader,
                header: buttonTitle,
                id: `eventOrSeriesMessage`,
                cssClass: `me-info-button-css alertMsg ${(allBatchSeriesButtonsEnabled == true || buttons.length > 3) ? `extended` : ``}`,
            });
            this.alertOpen = true;
            return await eventOrSeriesMessage.present();
        }
    }

    moveEventDay(eventToChange, newStart, moveBatchEvent = false, moveSelectedGroupsInBatch?) {
        let startD = eventToChange.meta.startD;
        let daysArray = eventToChange.meta.daysArray;
        let batchSelectionIDs = this.selectedGroups.map(gr => gr.id);
        let time = moment(newStart).format(`YYYY-MM-DD HH:mm:ss.SSS`);
        let eventsToUpdate = this.allSiteDatabaseEvents.filter(ev => ev.batch.id == eventToChange.meta.batchID);
        let filteredEventsToUpdate = moveBatchEvent == true ? eventsToUpdate : eventsToUpdate.filter(ev => batchSelectionIDs.includes(ev.group_id));
        let filteredEventsToKeep = moveBatchEvent == true ? eventsToUpdate : eventsToUpdate.filter(ev => !batchSelectionIDs.includes(ev.group_id));

        let convertedDatabaseEventObjects = filteredEventsToUpdate.map(ev => {
            delete ev.id;
            delete ev.batch;
            delete ev.site_id;
            delete ev.is_active;

            let days = { ...ev.days };
            for (let day in days) {
                days[day] = day === moment(newStart).format(`dddd`).toLowerCase();
            }

            return {
                ...ev,
                time,
                days,
                commands: ev.commands.commands,
            };
        });

        let databaseEventObjectsToKeep = filteredEventsToKeep && filteredEventsToKeep.length > 0 ? filteredEventsToKeep.map(ev => {
            delete ev.id;
            delete ev.batch;
            delete ev.site_id;
            delete ev.is_active;

            let days = { ...ev.days };
            for (let day in days) {
                days[day] = day === startD.toLowerCase();
            }

            return {
                ...ev,
                days,
                commands: ev.commands.commands,
            };
        }) : [];

        // Create New Batch on Day and New Time
        let batchEventToPost = {
            site_id: this.user.active.id,
            events: convertedDatabaseEventObjects,
            name: convertedDatabaseEventObjects[0].name,
        }

        let batchEventToRemain = {
            site_id: this.user.active.id,
            events: databaseEventObjectsToKeep,
            name: databaseEventObjectsToKeep[0].name,
        }

        devEnv && console.log(`Move Selected Multiple${moveBatchEvent == true ? `` : ` but not All Group(s)`} from Batch`, {
            startD,
            daysArray,
            eventsToUpdate, 
            batchEventToPost,
            batchSelectionIDs, 
            batchEventToRemain,
            filteredEventsToKeep,
            filteredEventsToUpdate,
            databaseEventObjectsToKeep,
        });

        this.createBatchEvent(batchEventToPost);

        const keepRemainingEvents = async () => {
            const spinningLoader = await this.loadingController.create({
                spinner: `lines`,
                message: `Refreshing Event(s)...`,
            });

            this.createBatchEvent(batchEventToRemain, null, spinningLoader);
        }
        
        let shouldKeepRemainingEvents = moveSelectedGroupsInBatch && moveSelectedGroupsInBatch == true && databaseEventObjectsToKeep.length > 0 && daysArray.length > 1;
        if (shouldKeepRemainingEvents) {
            keepRemainingEvents();
        }

        // Delete Day from Old Batch
        if (eventToChange.meta.multiDayEvent == true) {
            this.events = this.events.filter(evt => evt?.meta?.seriesID != eventToChange.meta.seriesID);
            eventToChange.meta.batchEvents.forEach(evt => this.deleteDayFromEvent(evt, false));
        } else {
            let groupIDsToRemove = filteredEventsToUpdate.map(ev => ev.group_id);
            this.removeGroupFromBatch(eventToChange.meta.batchID, this.user.active.id, groupIDsToRemove, eventToChange, false, true);
        }
    }

    // Submit Form // Edit Event Form
    async updateEvent(editEventForm, calendarEvent: CalendarEvent<EventControlPoints>, gatewayUnitIDsToAffect?) {
        if (!this.siteService.handleIsConnected()) return;
        
        const spinningLoader = await this.loadingController.create({
            spinner: `lines`,
            message: `Saving Event(s)...`,
        });
    
        await spinningLoader.present();

        const changedDate = moment(`${moment(editEventForm.value.startDay).format(`MMM D, YYYY`)}, ${editEventForm.value.startTime}`, `MMM D, YYYY, h:mm A`).format();

        // Same Time Conflict Event Form // Move series
        const multiDates = [];

        let eventsForSelection = this.allSiteEvents.filter(evt => this.selectedGroups.map(gr => gr.group_id).includes(evt.meta.group_id) && !this.events.map(ev => ev.meta.batchID).includes(evt.meta.batchID) && !this.events.map(ev => ev.meta.id).includes(evt.meta.id) && moment(changedDate).tz(this.user?.active?.timezone).isBetween(moment(evt.start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(evt.start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`)));
        let eventsToConsider = [...this.events, ...eventsForSelection];

        for (let i = 0; i < eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id).length; i++) {
            multiDates.push(moment(eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id)[i].start).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));

            for (let j = 1; j < this.minuteBarrier; j++) {
                multiDates.push(moment(eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id)[i].start).add(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
                multiDates.push(moment(eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id)[i].start).subtract(j, `minutes`).tz(this.user?.active?.timezone).format(`hh:mm A dddd`));
            }

            if (moment(changedDate).tz(this.user?.active?.timezone).isBetween(moment(eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id)[i].start).tz(this.user?.active?.timezone).subtract(this.minuteBarrier, `minutes`), moment(eventsToConsider.filter(evt => evt.meta.id != calendarEvent.meta.id)[i].start).tz(this.user?.active?.timezone).add(this.minuteBarrier, `minutes`))) {
                this.sameTimeConflict();
                spinningLoader.dismiss();
                return;
            }
        }

        for (let i = 0; i < editEventForm.value.days.length; i++) {
            if ([...new Set(multiDates)].includes(moment(changedDate).tz(this.user?.active?.timezone).format(`hh:mm A`) + ` ` + editEventForm.value.days[i])) {
                this.sameTimeConflict();
                spinningLoader.dismiss();
                return;
            }
        }

        const eventMode = editEventForm.value.mode;
        let sentTemp: any = new Object();
        let upperTemp = document.querySelector(`#upperTemp`);
        let lowerTemp = document.querySelector(`#lowerTemp`) || document.querySelector(`#lowerTempSingleCol`);
        if (eventMode == ModesSpeedsDirections.heat) {
            sentTemp[`lower`] = parseFloat((<any>lowerTemp).value);
        } else if (eventMode == ModesSpeedsDirections.cool || eventMode == ModesSpeedsDirections.dry) {
            sentTemp[`upper`] = parseFloat((<any>upperTemp).value);
        } else if (eventMode == ModesSpeedsDirections.auto) {
            sentTemp = document.querySelector(`#lowerTemp`) ? { 
                upper: parseFloat((<any>upperTemp).value), 
                lower: parseFloat((<any>lowerTemp).value),
            } : editEventForm.value.temperature;
        }

        let batchEventsToSave = [];
        let batchID = calendarEvent.meta.batchID;
        let groupsToAffect = this.mainSiteUIService.allSiteGroups.filter(grp => gatewayUnitIDsToAffect.includes(grp.gatewayUnits[0].id));

        // if (groupsToAffect.length == calendarEvent.meta.batchEvents.length) {
        //     groupsToAffect.forEach((grp, grpIndex) => {
        //         let gateway = grp?.gateway;
        //         let gatewayUnit = grp?.gatewayUnits[0];
        //         let batchEventToAffect = calendarEvent.meta.batchEvents.find(evt => evt.meta.group_id == grp.gatewayUnits[0].id || evt.meta.gatewayUnit_id == grp.gatewayUnits[0].id) || calendarEvent;

        //         let commands: any[] = [
        //             {
        //                 gateway_id: gatewayUnit.gateway_id,
        //                 group_id: gatewayUnit.group_id,
        //                 value: editEventForm.value.power.toLowerCase(),
        //                 param: `Drive`,
        //             },
        //         ];

        //         if (((gatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && gatewayUnit.fan_mode_sw != ModesSpeedsDirections.disabled && gatewayUnit.fan_speed_sw != ModesSpeedsDirections.none) || (gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay && gatewayUnit.lc_fan_speed_sw != ModesSpeedsDirections.disabled)) && this.mapFanSpeed(gatewayUnit.type, editEventForm.value.fanSpeed.toLowerCase()) != ModesSpeedsDirections.disabled && this.mapFanSpeed(gatewayUnit.type, editEventForm.value.fanSpeed.toLowerCase()) != null && this.mapFanSpeed(gatewayUnit.type, editEventForm.value.fanSpeed.toLowerCase()) != undefined) {
        //             commands.push({
        //                 gateway_id: gatewayUnit.gateway_id,
        //                 group_id: gatewayUnit.group_id,
        //                 value: this.mapFanSpeed(gatewayUnit.type, editEventForm.value.fanSpeed.toLowerCase()),
        //                 param: `FanSpeed`,
        //             })
        //         }

        //         if (typeof editEventForm.value.temperature != `number` && gatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {

        //             commands.push(
        //                 {
        //                     group_id: gatewayUnit.group_id,
        //                     gateway_id: gatewayUnit.gateway_id,
        //                     value: editEventForm.value.mode.toLowerCase(),
        //                     param: `Mode`,
        //                 },
        //                 {
        //                     group_id: gatewayUnit.group_id,
        //                     gateway_id: gatewayUnit.gateway_id,
        //                     value: editEventForm.value.airDirection.toLowerCase(),
        //                     param: `AirDirection`,
        //                 },
        //             );
        
        //             if (editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto_heat || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto_cool || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback_heat || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback_cool || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.dual_auto) {
        //                 let upperSetTemp = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == false ? editEventForm?.value?.temperature2Upper : editEventForm?.value?.upperSetTemp;
        //                 let lowerSetTemp = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == false ? editEventForm?.value?.temperature2Lower : editEventForm?.value?.lowerSetTemp;
        //                 commands.push(
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTemp?.toString()) : upperSetTemp?.toString().includes(`.`) ? upperSetTemp?.toString() : upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp1`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTemp?.toString()) : lowerSetTemp?.toString().includes(`.`) ? lowerSetTemp?.toString() : lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp2`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTemp?.toString()) : upperSetTemp?.toString().includes(`.`) ? upperSetTemp?.toString() : upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp4`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTemp?.toString()) : lowerSetTemp?.toString().includes(`.`) ? lowerSetTemp?.toString() : lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp5`,
        //                     }
        //                 )
        //                 // devEnv && console.log(`Multis 1`, {
        //                 //     lowerSetTemp,
        //                 //     upperSetTemp
        //                 // })
        //             } else if (editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.heat) {
        //                 commands.push(
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp1`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp2`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp4`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp5`,
        //                     }
        //                 )
        //                 // devEnv && console.log(`Multis 2`);
        //             } else {
        //                 commands.push(
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.upperSetTemp?.toString()) : editEventForm?.value?.upperSetTemp?.toString().includes(`.`) ? editEventForm?.value?.upperSetTemp?.toString() : editEventForm?.value?.upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp1`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp2`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.upperSetTemp?.toString()) : editEventForm?.value?.upperSetTemp?.toString().includes(`.`) ? editEventForm?.value?.upperSetTemp?.toString() : editEventForm?.value?.upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp4`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(editEventForm?.value?.lowerSetTemp?.toString()) : editEventForm?.value?.lowerSetTemp?.toString().includes(`.`) ? editEventForm?.value?.lowerSetTemp?.toString() : editEventForm?.value?.lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp5`,
        //                     }
        //                 )
        //                 // devEnv && console.log(`Multis 3`);
        //             }
        
        //         } else if (gatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
        //             commands.push({
        //                 group_id: gatewayUnit.group_id,
        //                 gateway_id: gatewayUnit.gateway_id,
        //                 value: editEventForm.value.ventMode.toLowerCase().replace(/[ ]/g, `_`).replace(ModesSpeedsDirections.energy, ModesSpeedsDirections.heat),
        //                 param: `VentMode`,
        //                 scale: `C`,
        //             });
        
        //         } else {
        //             commands.push(
        //                 {
        //                     group_id: gatewayUnit.group_id,
        //                     gateway_id: gatewayUnit.gateway_id,
        //                     value: editEventForm.value.mode.toLowerCase(),
        //                     param: `Mode`,
        //                 },
        //                 {
        //                     group_id: gatewayUnit.group_id,
        //                     gateway_id: gatewayUnit.gateway_id,
        //                     value: editEventForm.value.airDirection.toLowerCase(),
        //                     param: `AirDirection`,
        //                 }
        //             );
        
        //             if (gatewayUnit.GroupType == `enabled` && gatewayUnit.auto_mode == ModesSpeedsDirections.dual_setpoint && (editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto_heat || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.auto_cool || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback_heat || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.setback_cool || editEventForm.value.mode.toLowerCase() == ModesSpeedsDirections.dual_auto)) {
        //                 let upperSetTemp = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == true ? editEventForm?.value?.temperature2Upper : editEventForm?.value?.upperSetTemp;
        //                 let lowerSetTemp = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == true ? editEventForm?.value?.temperature2Lower : editEventForm?.value?.lowerSetTemp;
        //                 commands.push(
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTemp?.toString()) : upperSetTemp?.toString().includes(`.`) ? upperSetTemp?.toString() : upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp1`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTemp?.toString()) : lowerSetTemp?.toString().includes(`.`) ? lowerSetTemp?.toString() : lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp2`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(upperSetTemp?.toString()) : upperSetTemp?.toString().includes(`.`) ? upperSetTemp?.toString() : upperSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp4`,
        //                     },
        //                     {
        //                         group_id: gatewayUnit.group_id,
        //                         gateway_id: gatewayUnit.gateway_id,
        //                         value: this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTemp?.toString()) : lowerSetTemp?.toString().includes(`.`) ? lowerSetTemp?.toString() : lowerSetTemp?.toString() + `.0`,
        //                         param: `MultiSetTemp5`,
        //                     }
        //                 )
        //                 // devEnv && console.log(`Multis 4`, {
        //                 //     lowerSetTemp,
        //                 //     upperSetTemp
        //                 // });
        //             } else {
        //                 let lowerSetTempToUse = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == false ? editEventForm.value.lowerSetTemp2 : editEventForm.value.lowerSetTemp;
        //                 if (lowerSetTempToUse == null) lowerSetTempToUse = editEventForm.value.upperSetTemp;
        //                 commands.push({
        //                     group_id: gatewayUnit.group_id,
        //                     gateway_id: gatewayUnit.gateway_id,
        //                     value: editEventForm.value.mode != `fan` ? (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempToUse?.toString()) : lowerSetTempToUse?.toString().includes(`.`) ? lowerSetTempToUse?.toString() : lowerSetTempToUse?.toString() + `.0`) : `5`,
        //                     param: `SetTemp`,
        //                 });
        //             }
        //         }

        //         if (this.mainSiteUIService.multipleRangeSliders == true && !commands.find(com => com.param == `SetTemp`)) {
        //             let lowerSetTempToUse = this.mainSiteUIService.multipleRangeSliders == true && this.mainSiteUIService.temp2DualKnobs == false ? editEventForm.value.lowerSetTemp2 : editEventForm.value.lowerSetTemp;
        //             if (lowerSetTempToUse == null) lowerSetTempToUse = editEventForm.value.upperSetTemp;
        //             commands.push({
        //                 group_id: gatewayUnit.group_id,
        //                 gateway_id: gatewayUnit.gateway_id,
        //                 value: editEventForm.value.mode != `fan` ? (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(lowerSetTempToUse?.toString()) : lowerSetTempToUse?.toString().includes(`.`) ? lowerSetTempToUse?.toString() : lowerSetTempToUse?.toString() + `.0`) : `5`,
        //                 param: `SetTemp`,
        //             });
        //         }

        //         let eventToSave = {
        //             commands,
        //             id: batchEventToAffect.meta.id,
        //             name: editEventForm.value.title,
        //             group_id: gatewayUnit.group_id,
        //             gateway_id: gatewayUnit.gateway_id,
        //             time: moment(changedDate).format(`YYYY-MM-DD HH:mm:ss.SSS`),
        //             ...(this.is_recurring && batchEventToAffect.meta.is_recurring && { is_recurring: batchEventToAffect.meta.is_recurring }),
        //             ...(this.is_recurring && batchEventToAffect.meta.is_recurring && batchEventToAffect.meta.days && {
        //                 days: {
        //                     sunday: editEventForm.value.days.includes(`Sunday`),
        //                     monday: editEventForm.value.days.includes(`Monday`),
        //                     tuesday: editEventForm.value.days.includes(`Tuesday`),
        //                     wednesday: editEventForm.value.days.includes(`Wednesday`),
        //                     thursday: editEventForm.value.days.includes(`Thursday`),
        //                     friday: editEventForm.value.days.includes(`Friday`),
        //                     saturday: editEventForm.value.days.includes(`Saturday`),
        //                 }
        //             }),
        //         };

        //         batchEventsToSave.push(eventToSave);
        //     })

        //     let batchEventToUpdate = {
        //         id: batchID,
        //         events: batchEventsToSave,
        //         site_id: this.user.active.id,
        //         name: batchEventsToSave[0].name,
        //     }

        //     await this.siteService.updateBatchEvent(batchEventToUpdate).subscribe((data: any) => {
        //         devEnv && console.log(`Database Event Updated From Form`, {
        //             ...data,
        //             events: data.events.map(ev => ({ ...ev, groupName: this.mainSiteUIService.allSiteGroups.find(gr => gr.group_id == ev.group_id)?.name }))
        //         });
        //         this.onChangeEvent.emit(data);
        //         spinningLoader.dismiss();
        //     }, error => {
        //         console.log(`Error Updating Batch Event(s)`, error);
        //         spinningLoader.dismiss();
        //     })
        // } else {
            this.updateBatchEvent(calendarEvent, editEventForm, groupsToAffect);
            spinningLoader.dismiss();
        // }

        this.modalController.dismiss();
    }

    async updateBatchEvent(event, form, groups) {

        const spinningLoader = await this.loadingController.create({
            spinner: `lines`,
            message: `Updating Event(s)...`,
        });
    
        await spinningLoader.present();

        await this.siteService.deleteBatchEvent(this.user.active.id, event?.meta?.batchID).subscribe(data => {
            this.events = this.events.filter(ev => ev.meta.id != event.meta.id);
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            spinningLoader.dismiss();
            this.createNewEvent(form, groups, false);
        }, error => {
            console.log(`Update Batch Event Error`, error);
            if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
            spinningLoader.dismiss();
        });
    }

    // Delete All Events // Dev Only
    deleteAllEvents(e) {
        this.databaseEvents.forEach(dbEvent => this.siteService.deleteSiteEvent(dbEvent.gateway_id, dbEvent.id).subscribe(eventDeletedMessage => {
            devEnv && console.log(`Delete All Events`, eventDeletedMessage);
        }));
        this.events = [];
        this.unfilteredEvents = this.events;
    }

    // Delete Event from DB 
    deleteEvent(eventToDelete: CalendarEvent<EventControlPoints>, formOpen?, form?, daysChanged?) {
        if (!this.siteService.handleIsConnected()) return;
        if (eventToDelete.meta.batchID && eventToDelete.meta.batchID != `` && eventToDelete.meta.batchEvents && eventToDelete.meta.batchEvents.length > 1) {
            if (!this.alertOpen) {
                this.askEventOrSeries(eventToDelete, `Delete`, formOpen, ``, ``, form, daysChanged);
            }
        } else if (eventToDelete.meta.days.length > 1 || Object.entries(eventToDelete.meta.days).filter(day => day[1]).length > 1) {
            if (!this.alertOpen) {
                if (formOpen == false) {
                    this.events = this.events.filter(evt => evt?.meta?.seriesID != eventToDelete.meta.seriesID);
                    this.deleteDayFromEvent(eventToDelete);
                    if (this.events.length == 0 && this.view != CalendarView.Week) this.setView(CalendarView.Week);
                } else {
                    this.askEventOrSeries(eventToDelete, `Delete`, formOpen, ``, ``, form, daysChanged);
                }
            }
        } else {
            this.askEventOrSeries(eventToDelete, `Delete`, formOpen);
        }
    }

    getRealLossnayFanStages() {
        let realFanStages = null;
        if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            realFanStages = FanSpeedStages.four_stages
        } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.disabled) {
            realFanStages = FanSpeedStages.three_stages
        } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.disabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            realFanStages = FanSpeedStages.three_stages
        } else {
            realFanStages = this.selectedGatewayUnit.lc_fan_speed_sw;
        }

        return realFanStages;
    }

    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
        renderEvent.body.forEach((day) => {
            const dayOfMonth = day.date.getDate();
            if (dayOfMonth > 5 && dayOfMonth < 10 && day.inMonth) {
                day.cssClass = `nextWeek`;
            }
        });
        renderEvent.body.forEach((day) => {
            if (!this.dateIsValid(day.date)) {
                day.cssClass = `cal-disabled`;
            }
        });
    }

    beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
        renderEvent.hourColumns.forEach((hourColumn) => {
            hourColumn.hours.forEach((hour) => {
                hour.segments.forEach((segment) => {
                    if (
                        segment.date.getHours() >= 2 &&
                        segment.date.getHours() <= 5 &&
                        segment.date.getDay() === 2
                    ) {
                        segment.cssClass = `nextWeek`;
                    }
                });
            });
        });
        renderEvent.hourColumns.forEach((hour: any) => {
            if (!this.dateIsValid(hour.date)) {
                hour.cssClass = `cal-disabled`;
            }
        });
    }

    beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
        renderEvent.hourColumns.forEach((hourColumn) => {
            hourColumn.hours.forEach((hour) => {
                hour.segments.forEach((segment) => {
                    if (segment.date.getHours() >= 2 && segment.date.getHours() <= 5) {
                        segment.cssClass = `nextWeek`;
                    }
                });
            });
        });
    }

    async responsiveCalendar() {
        // Responsive Calendar
        const CALENDAR_RESPONSIVE = {
            small: {
                breakpoint: `(max-width: 576px)`,
                daysInWeek: 2,
            },
            medium: {
                breakpoint: `(max-width: 768px)`,
                daysInWeek: 3,
            },
            large: {
                breakpoint: `(max-width: 960px)`,
                daysInWeek: 5,
            },
        }
        this.breakpointObserver
            .observe(
                Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((state: BreakpointState) => {
                const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
                    ({ breakpoint }) => !!state.breakpoints[breakpoint]
                );
                if (foundBreakpoint) {
                    this.daysInWeek = foundBreakpoint.daysInWeek;
                } else {
                    this.daysInWeek = 7;
                }
                this.cd.markForCheck();
            });
    }

    selectGroupsInBatchOnBadgeClick(event) {
        let groupIDsToSelect = event.meta.batchEvents.map(evt => evt.meta.group_id);
        let groupsToSelect = this.mainSiteUIService.allSiteGroups.filter(grp => groupIDsToSelect.includes(grp.id));
        // this.scheduleForGroups(groupsToSelect, true);
        devEnv && console.log(`Groups`, groupsToSelect);
        // this.selectedGroups = groupsToSelect;
        // this.bgd.update(groupsToSelect, true);
    }

    // Present the Event Form to User
    async eventForm(start, title?, action?, evnt?) {
        if (!this.siteService.handleIsConnected()) return;
        if (this.formOpen == true) return;
        this.groupNamesInTitle = false;

        const permissionMsg = {
            header: `Unauthorized Access`,
            message: `You must be a Site Owner, Administrator, or User to Create/Edit Events!`,
            cssClass: `me-info-button-css alertMsg`,
            buttons: [{ text: `Ok`, cssClass: `ok-button`, }],
        };

        // console.log(`Selected Gateway Unit`, this.selectedGatewayUnit);

        let minimumSchedulingParameters = [this.selectedGatewayUnit.power];
        if (this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay) {
            minimumSchedulingParameters.push(this.selectedGatewayUnit.mode);
            if (this.selectedGatewayUnit.mode != ModesSpeedsDirections.fan) minimumSchedulingParameters.push(this.selectedGatewayUnit.inlet_temp);
        } else {
            minimumSchedulingParameters.push(this.selectedGatewayUnit.vent_mode);
        }

        let hasSelectedGroups = this.selectedGroups && this.selectedGroups.length > 0;
        let isInvalidTemp = isNaN(this.selectedGatewayUnit.inlet_temp) && this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay;
        let isSelectable = minimumSchedulingParameters.includes(ModesSpeedsDirections.unknown) && this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay;
        let hasInvalidTemps = hasSelectedGroups && this.selectedGroups.some(grp => grp.gatewayUnits && grp.gatewayUnits.length > 0 && grp.gatewayUnits.some(unt => isNaN(unt.inlet_temp) && unt.type != GatewayUnitTwoDigitType.Lossnay));

        if (this.user?.activeSiteUserLevel <= 1) {
            this.formOpen = false;
            const permissionAlert = await this.alertController.create(permissionMsg);
            this.alertOpen = true;
            return await permissionAlert.present();
        } else if (isSelectable) {
            this.formOpen = false;
            const errorAlert = await this.alertController.create(this.errorMsg);
            this.alertOpen = true;
            return await errorAlert.present();
        } else if (isInvalidTemp || hasInvalidTemps) {
            this.formOpen = false;
            const errorAlert = await this.alertController.create(this.errorMsg);
            this.alertOpen = true;
            return await errorAlert.present();
        } else {
            this.formOpen = true;

            let schedulingMode;
            let selectedGroup = this.selectedGroups[0];
            let batchSchedulingEnabled = this.batchScheduling == true;
            let gateway = evnt ? this.mainSiteUIService.allSiteGroups.find(grp => grp.id == evnt.meta.group_id).gateway : selectedGroup.gateway;
            let unit = evnt ? this.mainSiteUIService.allSiteGroups.find(grp => grp.id == evnt.meta.group_id).gatewayUnits[0] : selectedGroup.gatewayUnits[0];
            let unitTypes = evnt ? evnt.meta.unitType : batchSchedulingEnabled ? this.selectedGroups.map(grp => grp?.gatewayUnits[0].type) : this.selectedGatewayUnit.type;
            let showIcForm = unitTypes.includes(GatewayUnitTwoDigitType.IndoorUnit);
            this.selectedGatewayUnit = unit;
            this.selectedGateway = gateway;
            let activePower = unit.power;

            if (batchSchedulingEnabled) {
                title = `Batch ${title}`;
                schedulingMode = `batchScheduling`;
            } else {
                schedulingMode = `singleScheduling`;
            }

            let temperature = this.selectedGatewayUnit.showLowerSetTemp && this.selectedGatewayUnit.showUpperSetTemp ? {
                lower: parseFloat(this.selectedGatewayUnit.lowerSetTemp) || parseInt(this.selectedGatewayUnit.inlet_temp),
                upper: parseFloat(this.selectedGatewayUnit.upperSetTemp)
            } : parseFloat(this.selectedGatewayUnit.lowerSetTemp) || parseInt(this.selectedGatewayUnit.inlet_temp) || parseFloat(this.selectedGatewayUnit.upperSetTemp) || Math.floor(((this.tempPoints.reduce((num: number, nextNum: number) => num + nextNum)) / this.tempPoints.length));

            const modal = await this.modalController.create({
                cssClass: showIcForm ? `eventAdminModal ${schedulingMode}` : `eventAdminModal lcForm ${schedulingMode}`,
                component: SiteSchedulingEventFormComponent,
                id: `eventAdminModal`,
                backdropDismiss: false,
                componentProps: {

                    // outputs
                    onAddEvent: this.onAddEvent,
                    onDeleteEvent: this.onDeleteEvent,
                    onChangeEvent: this.onChangeEvent,

                    // Event Data
                    title,
                    showIcForm,
                    activePower,
                    temperature,
                    view: this.view,
                    width: this.width,
                    onMac: this.onMac,
                    evnt: evnt ?? null,
                    height: this.height,
                    events: this.events,
                    colors: this.colors,
                    startDateClick: start,
                    actions: this.actions,
                    action: action ?? null,
                    siteTime: this.siteTime,
                    errorMsg: this.errorMsg,
                    eventAdmin: this.eventAdmin,
                    // endDateClick: end || start,
                    siteService: this.siteService,
                    setWeekTime: this.setWeekTime,
                    degreesType: this.degreesType,
                    deleteEvent: this.deleteEvent,
                    updateEvent: this.updateEvent,
                    mapFanSpeed: this.mapFanSpeed,
                    is_recurring: this.is_recurring,
                    windowEvents: this.windowEvents,
                    getEventColor: this.getEventColor,
                    allSiteEvents: this.allSiteEvents,
                    deleteEventUI: this.deleteEventUI,
                    createNewEvent: this.createNewEvent,
                    recurringSched: this.recurringSched,
                    selectedGroups: this.selectedGroups,
                    backEndToEvents: this.backEndToEvents,
                    batchScheduling: this.batchScheduling,
                    sameTimeConflict: this.sameTimeConflict,
                    updateBatchEvent: this.updateBatchEvent,
                    selectedGroup: this.selectedGatewayUnit,
                    askEventOrSeries: this.askEventOrSeries,
                    createBatchEvent: this.createBatchEvent,
                    loadingController: this.loadingController,
                    scheduleForGroups: this.scheduleForGroups,
                    deleteFromBackEnd: this.deleteFromBackEnd,
                    createEventButton: this.createEventButton,
                    refreshGroupEvents: this.refreshGroupEvents,
                    postEventToBackEnd: this.postEventToBackEnd,
                    deleteDayFromEvent: this.deleteDayFromEvent,
                    removeGroupFromBatch: this.removeGroupFromBatch,
                    getEventsForSelection: this.getEventsForSelection,
                    degreesTypeAbbreviation: this.degreesTypeAbbreviation,
                    postBatchEventsToBackEnd: this.postBatchEventsToBackEnd,
                    presentSubscriptionOptions: this.presentSubscriptionOptions,
                    deleteBatchEventFromBackEnd: this.deleteBatchEventFromBackEnd,
                    fanSpeed: this.DisplayOptions.getFanSpeedDescription(this.selectedGatewayUnit),
                    createFrontEndEventsFromBackEndEvents: this.createFrontEndEventsFromBackEndEvents,
                    convertBatchEventParametersToDatabaseEvents: this.convertBatchEventParametersToDatabaseEvents,
                    avgTemperature: Math.floor(((this.tempPoints.reduce((num: number, nextNum: number) => num + nextNum)) / this.tempPoints.length)),
                    fanSpeedEnabled: this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay ? this.selectedGatewayUnit.lc_fan_speed_sw != `disabled` : (this.selectedGatewayUnit.fan_mode_sw != `disabled` && this.selectedGatewayUnit.fan_speed_sw != `none`),
                    activeMode: evnt ? evnt.meta.unitType == GatewayUnitTwoDigitType.IndoorUnit ? evnt.meta.mode : evnt.meta.ventMode : this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit ? (<string>this.selectedGatewayUnit.mode) : (<string>this.selectedGatewayUnit.vent_mode),
                    activeVentMode: this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay ? (<string>this.selectedGatewayUnit.vent_mode) : undefined,
                    airDirection: (this.selectedGatewayUnit.air_direction_auto === `disabled` && this.selectedGatewayUnit.air_direction === `auto`) ? `stationary` : this.selectedGatewayUnit.air_direction,

                    minuteBarrier: this.minuteBarrier,
                    // Format Date & Time Data
                    week: this.week,
                    devEnv: devEnv,
                    startD: moment(start).format(`dddd`),
                    startHour: moment(start).format(`h A`),
                    startTime: moment(start).format(`HH:mm`),
                    startXMode: moment(start).format(`A`),
                    startMomentFormat: (<any>moment(start))._d,
                    allowedTempValues: this.allowedTempValues,
                    startIsoDateFormat: moment(start).format(),
                    startDay: moment(start).format(`ddd, MMM Do`),
                    startTimeConverted: moment(start).format(`h:mm A`),
                    startCalFormat: moment(start).format(`YYYY-MM-DD`),
                    startDate: moment(start).format(`dddd, MMMM Do YYYY`),
                    recurringAndSingleEvents: this.recurringAndSingleEvents,

                    // Unit Data
                    units: this.gatewayUnits,
                    tempPoints: this.tempPoints,
                    selectedGateway: this.selectedGateway,
                    selectedGatewayUnit: this.selectedGatewayUnit,
                    unitDetails: {
                        gatewayId: this.selectedGateway.id,
                        gatewayName: this.selectedGateway.name,
                        siteName: this.selectedGateway.site_id,
                        serial_number: this.selectedGateway.serial_number,
                        gatewayType: this.selectedGateway.model.class_name,
                        roomTemp: this.selectedGatewayUnit.inlet_temp || this.selectedGatewayUnit.lowerSetTemp || this.selectedGatewayUnit.upperSetTemp
                    },

                }
            });
            modal.onDidDismiss().then((data) => {
                this.formOpen = false;
            });
            return await modal.present();
        }
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    setView(view: any) {
        this.view = view;
        if (this.view == CalendarView.Week) {
            if (document.querySelector(`#todayButton`)) {
                if (this.weekStart == this.today) {
                    this.setCurrentTime(false);
                    (<any>document.querySelector(`#todayButton`)).classList.add(`active`);
                } else {
                    (<any>document.querySelector(`#todayButton`)).classList.remove(`active`);
                }
            } else {
                this.weekStart == this.today ? this.setCurrentTime(false) : null;
            }
            localStorage.setItem(`CalView`, this.CalendarView.Week);
            this.mainSiteUIService.schedulingCalendarWeeklyView = true;
        } else {
            this.mainSiteUIService.schedulingCalendarWeeklyView = false;
            localStorage.setItem(`CalView`, this.CalendarView.Week);
        }
    }

    ionViewWillLeave() {
        if (this.updateTimerFn != null) {
            clearInterval(this.updateTimerFn);
            this.updateTimerFn = null;
        };
        if (this.timerTimeout != null) {
            clearTimeout(this.timerTimeout);
            this.timerTimeout = null;
        };
        if (this.currentHoverTarget != null) {
            this.onEventMouseMove = null;
        }
        this.destroy$.next();
    }

    ionViewDidLeave() {
        if (this.updateTimerFn != null) {
            clearInterval(this.updateTimerFn);
            this.updateTimerFn = null;
        };
        if (this.timerTimeout != null) {
            clearTimeout(this.timerTimeout);
            this.timerTimeout = null;
        };
        this.destroy$.next();
    }

    ngOnDestroy() {
        if (this.updateTimerFn != null) {
            clearInterval(this.updateTimerFn);
            this.updateTimerFn = null;
        };
        if (this.timerTimeout != null) {
            clearTimeout(this.timerTimeout);
            this.timerTimeout = null;
        };
        if (this.currentHoverTarget != null) {
            this.onEventMouseMove = null;
        }
        this.destroy$.next();
    }

}

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
        day: addDays,
        week: addWeeks,
        month: addMonths,
    }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
        day: subDays,
        week: subWeeks,
        month: subMonths,
    }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
        week: startOfWeek,
        month: startOfMonth,
    }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
        week: endOfWeek,
        month: endOfMonth,
    }[period](date);
}