import { ChangeDetectorRef, Component, OnInit, NgModule, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2FlatpickrComponent, Ng2FlatpickrModule } from 'ng2-flatpickr';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';
import { FlatpickrOptions } from 'ng2-flatpickr';
import flatpickr from 'flatpickr/dist/flatpickr.min.js';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { ToastrService } from 'ngx-toastr';
import {
  TOAST_GENERAL_ERROR_TITLE,
  TOAST_SUCCESS_TITLE,
  MAPUNITS_WAIT_TIME,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_UNABLE_TO_TEST_RUN_MESSAGE,
  TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE,
} from 'src/app/constants/kenzaconstants';
import {
  ToastMessageTypeEnum,
  WebSocketResponseTypeEnum,
  TemperaturePreferenceEnum,
  PressurePreferenceEnum
 } from 'src/app/enumerations/enums';
import { Logger } from 'src/app/common/services/logging/log.service';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { PressureConversions, TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { Chart } from "chart.js";
import 'chartjs-adapter-moment';
import { vertical_line_plugin } from "src/app/features/sites/components/site-monitor/vertical_line_plugin";

@Component({
  selector: 'maintenance-spreadsheet',
  templateUrl: './maintenance-spreadsheet.component.html',
  styleUrls: ['./maintenance-spreadsheet.component.scss'],
})
// @NgModule({
//   declarations: [
//     AppComponent,
//     Ng2FlatpickrComponent
//   ],
//   imports: [
//     BrowserModule,
//     Ng2FlatpickrModule
//   ],
//   providers: [],
//   bootstrap: [AppComponent]
// })
export class MaintenanceSpreadsheetComponent /*implements OnInit*/ {
  @ViewChild('flatpickr') flatpickr?: Ng2FlatpickrComponent;
  @ViewChild('flatpickr2') flatpickr2? :Ng2FlatpickrComponent;
  @ViewChild('test_line') test_line: ElementRef;

  timeDif = new Date().getTimezoneOffset();                        // Time difference between Coordinated Universal Time and system time
  utcOffset = moment().tz(this.user.active.timezone).utcOffset();  // Time difference between Coordinated Universal Time and property time
  endDatetime = new Date();   // end date and time (For flatpickr setting)
  strEndDatetime: string;    // end日時（テキスト表示用）
  startDatetime = new Date(); // start date and time（For flatpickr setting）
  strStartDatetime: string;  // start date and time (param)

  options: FlatpickrOptions = {
    enableTime: true,      // Enable Time Selection
    time_24hr: true,       // Enable 24-hour notation
    dateFormat: 'Y/m/d(D)H:i'
  };
  options2: FlatpickrOptions = {
    enableTime: true,      // Enable Time Selection
    time_24hr: true,       // Enable 24-hour notation
    dateFormat: 'Y/m/d(D)H:i'
  };

  displayedColumns: string[] = ['Key1'];   // extracting only the key names of the dataSource ('Key1', TimeData_0 to TimeData_XXX will be included)
  timeHeader =  []; // Key name and item name (display name) of time part (other than columns 1 and 2)
  dataSource = [];  // Operation data (for screen display) Structure is [{Key1: attribute/address/item name, TimeData_0: value, TimeData_1: value, ...} ...]
  unitList = [];    // Unit name (array of numbers 0-6)
  unitName = '';    // Unit name ......0: Numeric (no unit), 1:Temperature (other than set temperature), 2:Temperature (set temperature), 3:Temperature difference, 4:Pressure (Kg/cm2), 5:Pressure (MPa), 6:String

  autoUpdate = true;
  interval;

  graphMode = false; // if true, put the screen in graph mode

  filterTarget = [];  // Graphing target selection list
  gColumn = 'xxxxxxxx';
  gColumnNames = [];
  gOptions = {
    colors: ['#000080', '#FF0000', '#FFFF00'],
    width: 900,
    height: 400
  };

  formatted_data = [];
  canvas = null;
  chart = null;

  socketEventSubscription: Subscription;    // for web socket communications
  maintRequestId: uuid = null;    // Test Run Data Starting Parameters
  gatewayId: string;    // OutDoorUnit's gatewayID
  loadMain;     // For Effects during loading display

  constructor(
    public _ref: ChangeDetectorRef,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    private router: Router,
    private user: UserService,
    private siteService: SiteService,
    private socketService: SocketService,
    private toastService: ToastrService,
    private logger: Logger
    ) { }

  // ngOnInit(){

  // }

  ionViewDidEnter() {
    // 試運転データ受信
    // Test Run Data Received
    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      this.receiveData(socketResult);
    });
    // クエリパラメータ取得
    // Query parameter acquisition
    this.route.queryParams.subscribe(params => {
      this.gatewayId = params.target;
    });
    // 画面初期表示時処理
    // Processing at initial screen display
    this.displayedColumns = ['Key1'];
    this.timeHeader = [];
    this.dataSource = [];
    this.unitList = [];
    this.unitName = '';
    this.autoUpdate = true;
    (document.querySelector("input[id='fixCheck'") as HTMLInputElement).checked = false;
    this.graphMode = false;
    this.filterTarget = [];
    this.gColumnNames = [];

    this.endDatetime = new Date();
    this.startDatetime = new Date();
    this.endDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3); // The initial value of end date and time is Current time and date - 3 minutes
    this.strEndDatetime = this.dateToString(this.endDatetime);
    this.startDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 63);  // The initial value of start date and time is Current time and date - 63 minutes
    this.strStartDatetime = this.dateToString(this.startDatetime);

    this.callApi(new Date(this.strStartDatetime), new Date(this.strEndDatetime), true);
    this.startTimer();
  }

  ionViewWillLeave() {
    clearInterval(this.interval);
    if(this.socketEventSubscription){
      this.socketEventSubscription.unsubscribe();
    }
    if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
  }

  toMTDZpage() {
    let start: string;
    let end:string;
    if(this.autoUpdate){
      start = this.date12(this.strStartDatetime);
      end = this.date12(this.strEndDatetime)
    }else{
      const fp2 = this.flatpickr2.flatpickr as flatpickr; // strStartDatetime = start
      const fp = this.flatpickr.flatpickr as flatpickr;   // strEndDatetime = end
      start = this.date12(this.dateToString(new Date(fp2.selectedDates[0])));
      end = this.date12(this.dateToString(new Date(fp.selectedDates[0])));
    }
    this.router.navigate(['/maintenance', this.user.active.id, 'MTDZ'], { queryParams: { target: this.gatewayId, start, end}});
  }

  startTimer() {
    /* 最新時刻でデータ取得した場合に起動(毎分更新データを取りに行く) */
    /* Starts when data is retrieved at the latest time (goes to retrieve data updated every minute) */
    this.interval = setInterval(() => {

      // タイムゾーン変更確認
      // Time zone change confirmation
      this.timeDif = new Date().getTimezoneOffset();
      this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();

      // end日時更新
      // end date and time update
      this.endDatetime = new Date();
      this.endDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3);
      this.strEndDatetime = this.dateToString(this.endDatetime);

      // Fixed Start TimeがOFFであれば、start日時=end日時-60分に設定
      // If Fixed Start Time is OFF, set start date/time = end date/time - 60 minutes
      const element = document.getElementById('fixCheck') as HTMLInputElement;
      if(!element.checked){
        this.startDatetime = new Date();
        this.startDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 63);
        this.strStartDatetime = this.dateToString(this.startDatetime);
      }

    // データ取得API呼出（自動更新の場合ロード表示はしない=3つ目の引数false）
    // Data acquisition API call (do not load display in case of automatic update = 3rd argument false)
    this.callApi(new Date(this.strStartDatetime), new Date(this.strEndDatetime), false);

    },60000);
  }

  stopTimer() {
    /* Stopボタン押下時処理 */
    /* Processing when Stop button is pressed */

    // 自動更新停止
    // Automatic Renewal Suspension
    this.autoUpdate = false;
    clearInterval(this.interval);
    this._ref.detectChanges();

    // fpに時刻反映
    // Reflects time in fp
    const fp = this.flatpickr.flatpickr as flatpickr;
    const fp2 = this.flatpickr2.flatpickr as flatpickr;

    fp.setDate(new Date(this.strEndDatetime));
    fp2.setDate(new Date(this.strStartDatetime));

    // データ取得は行わない
    // No data acquisition
}

  setTime() {
    /* TimeSettingボタン押下時処理 */
    /* Processing when TimeSetting button is pressed */

    const fp = this.flatpickr.flatpickr as flatpickr;   // strEndDatetime = end
    const fp2 = this.flatpickr2.flatpickr as flatpickr; // strStartDatetime = start

    // タイムゾーン変更確認
    // Time zone change confirmation
    this.timeDif = new Date().getTimezoneOffset();
    this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();

    const nowTime = new Date();
    nowTime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3);

    // 選択日時が未来の場合、自動更新を開始する。
    // If the selected date/time is in the future, initiate auto-renewal.
    if(nowTime < fp.selectedDates[0]){
      // fp.setDate(this.endDatetime);
      this.setNowTime();
      return;
    }

    // 時刻未選択の場合エラー
    // Error if time not selected
    if(!fp.selectedDates[0] || !fp2.selectedDates[0]){
      // alert('時刻を選択してください');
      // alert('Please select a time');
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'Please select a date and time.'
      );
      return;
    }

    // 下限時刻＞上限時刻の場合エラー
    // Error if lower time limit > upper limit time
    if(fp.selectedDates[0] - fp2.selectedDates[0] < 0){
      // alert('終了日時は開始日時よりあとの日時を指定してください');
      // alert('End date and time must be after the start date and time');
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'The end date and time must be after the start date and time.'
      );
      return;
    }
    // データ取得API呼出
    // Data acquisition API call
    this.callApi(new Date(fp2.selectedDates[0]), new Date(fp.selectedDates[0]), true);
  }

  setNowTime(){
    /* Auto Updateボタン押下時処理 */
    /* Processing when Auto Update button is pressed */

    // タイムゾーン変更確認
    // Time zone change confirmation
    this.timeDif = new Date().getTimezoneOffset();
    this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();

    // 表示時刻更新(fp更新はしない)
    // Display time update (fp update is not done)
    this.endDatetime = new Date();
    this.endDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3);
    this.strEndDatetime = this.dateToString(this.endDatetime);

    // 【保留】開始日時は、終了日時より前でかつ差が60分以下の場合、選択日時を引き継ぐ
    // （上記以外の場合は終了日時-60分とする）
    // [Reserved] If the start date/time is before the end date/time and the difference is less than 60 minutes, the selected date/time is taken over.
    // (If other than above, end date and time - 60 minutes)
    // let diff = Math.floor((new Date(this.strEndDatetime).getTime() - new Date(fp2.selectedDates[0]).getTime()) / (60*1000));
    // if(  diff > 0 && diff <= 60 ){
    //   this.startDatetime = new Date(fp2.selectedDates[0]);
    //   this.strStartDatetime = this.dateToString(this.startDatetime);
    // }else{
      this.startDatetime = new Date();
      this.startDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 63);
      this.strStartDatetime = this.dateToString(this.startDatetime);
    // }

    // データ入れ替え
    // Data replacement
    this.callApi(new Date(this.strStartDatetime), new Date(this.strEndDatetime), true);

    // 自動更新開始
    // Auto Update Start
    this.autoUpdate = true;
    this.startTimer();
  }

  callApi(start_date, end_date, loading) {
    // 運転データ取得API呼出
    // Run Data acquisition API call

    if (!this.siteService.handleIsConnected()) return;

    if (!this.socketService.is_connected()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      console.log('The socket service is not connected');
      return;
    }

    // 認証時にメンテナンスデータ取得要求を実行する
    // Execute maintenance data acquisition request upon authentication
    if(!this.socketService.judge_authenticated() && !loading){
      console.log('auth expired');
      return;
    }

    // 要素数設定
    // end-startが2時間以内なら分+1point、1日以内なら121point, 1441分以上なら601point
    // Element count setting
    // minutes + 1 point if end-start is within 2 hours, 121 points if within 1 day, 601 points if over 1441 minutes
    let point = Math.floor((end_date.getTime() - start_date.getTime()) / (60*1000));
    if(point <= 120){
      point = point + 1;
    }
    else if(point <= 1440){
      point = 121;
    }
    else{
      point = 601;
    }

    // 物件時刻をUTCに変換
    // Convert property time to UTC
    start_date.setMinutes(start_date.getMinutes() - this.utcOffset);
    end_date.setMinutes(end_date.getMinutes() - this.utcOffset);

    this.maintRequestId = uuid();

    const obj = {
      gateway_id: this.gatewayId,
      site_id: this.user.active.id,
      request_id: this.maintRequestId,
      start_date: this.date12(this.dateToString(start_date)),
      end_date: this.date12(this.dateToString(end_date)),
      resolution: point,
      monitor_mode: 99,
      address: []
    }
    if (this.socketService.getMaintData(obj)) {
      if (loading) {
        if (!this.autoUpdate){
          // time setting ボタンを非活性にする
          // Deactivate the time setting button
          const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
          setTime[0].disabled = true;
        }
        this.present_loadMain();
      }
    } else {
      this.toastService.error(TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
    }
  }

  receiveData(socketResult){
    try {
      if (socketResult && socketResult.response_type){
        if(socketResult.request_id === this.maintRequestId){
          if(this.importRunData(socketResult.response.data)){
            this.siteService.presentToastMessage(
              ToastMessageTypeEnum.Success,
              TOAST_SUCCESS_TITLE,
              'Maintenance data has been received.'
            );
          }else{
            this.siteService.presentToastMessage(
              ToastMessageTypeEnum.Error,
              TOAST_GENERAL_ERROR_TITLE,
              'Maintenance data is not available.'
            );
          }
          if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();

          if (!this.autoUpdate){
            // time setting ボタンを活性にする
            // Activate the time setting button
            const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
            setTime[0].disabled = false;
          }

          this._ref.detectChanges();
        }
      }
    } catch(exception) {
      console.log('receiveData=>exception');
    }
  }

  async present_loadMain() {
    this.loadMain = await this.loadingController.create({
      message: 'loading...',
      spinner: 'lines',
      duration: MAPUNITS_WAIT_TIME,
    });

    await this.loadMain.present();
  }

  importRunData(json) {
    try {
      // dataSourceにデータ追加(丸ごと入れ替え)
      // Add data to dataSource (replace the entire data source)
      const data = JSON.parse(json);

      // 表示用配列初期化
      // Initialize array for display
      this.timeHeader = [];
      this.filterTarget = [];
      this.dataSource = [];
      this.displayedColumns = ['Key1'];

      this._ref.detectChanges();

      let machineName: string;

      /* jsonファイルから項目リスト(表の1列目)の作成 */
      /* Create item list (first column of table) from json file */

      // 機器台数を取得
      // Get number of devices
      const machineCount = Object.keys(data['operationMonitor']['cycles'][0]['units']).length;

      // 各機器の項目一覧を取得
      // Get a list of items for each device
      for(let i=0; i<machineCount; i++){
        // アドレス取得（桁数を3桁に揃える）
        // Get address (align digits to 3 digits)
        machineName = data['operationMonitor']['cycles'][0]['units'][i]['address'];
        machineName = ('00' + machineName).slice(-3);
        // 属性設定
        // Attribute Settings
        machineName = data['operationMonitor']['cycles'][0]['units'][i]['attribute'] + '(' + machineName + ')';

        // 項目数カウント
        // Item Count
        let itemCount = Object.keys(data['operationMonitor']['cycles'][0]['units'][i]['items']).length;
        // 項目名設定
        // Item name setting
        for(let k=0; k<itemCount; k++){
          const addData = {
            Key1: machineName + ' ' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['name']
          }
          this.dataSource.push(addData);
          this.filterTarget.push(machineName + ' ' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['name']);
          this.unitList.push(data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['unitId']);
        }
      }

      /* 運転データを表示用配列に格納 */
      /* Store operation data in display array */

      // 受信データの時間の数（列数）カウント
      // Number of hours (number of columns) count of received data
      const timeCount = Object.keys(data['operationMonitor']['cycles']).length;
      let columnName = '';  // dataSource item name (TimeData_X) is entered

      // 順に配列に格納
      // stored in an array in order
      for(let i=0; i<timeCount; i++){

        // html用カラム名設定
        // set column name for html
        columnName = 'TimeData_' + i;
        this.displayedColumns.push(columnName);
        const timeDataIdx = data['operationMonitor']['cycles'][i]['date']; // timeHeader's name (YYYY/MM/DD HH:MM) will be entered
        this.timeHeaderPlus(timeDataIdx, columnName); // 日付時刻をヘッダへ格納  Date and time are stored in the header

        // dataSourceにデータセット
        // 一番早い時刻のデータをTimeData_0に、そこから順にTimeData_1, TimeData_2, ...と格納していく。
        // データは単位変換し格納する。
        // Data set in dataSource
        // The earliest time data is stored in TimeData_0, then TimeData_1, TimeData_2, .... and so on.
        // Data is converted to units and stored.
        let rowIdx = 0;
        const unitCount = Object.keys(data['operationMonitor']['cycles'][i]['units']).length;

        for(let k=0; k<unitCount; k++){

          const itemCount = Object.keys(data['operationMonitor']['cycles'][i]['units'][k]['items']).length;
          for(let m=0; m<itemCount; m++){
            this.dataSource[rowIdx][columnName] =
              this.dataConv(data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['data2'],
                data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['unitId']);
            rowIdx++;
          }
        }
      }
      this.chartDestroy();
      this.makeGraph();
      this.createChart();
      this._ref.detectChanges();

      return true;

    } catch (error) {
      return false;

    }
  }

  timeHeaderPlus(timeDataIdx, columnName){
    // 受信時刻(UTC)を物件時刻に変換
    // Convert received time (UTC) to property time
    const td = new Date(timeDataIdx);
    td.setMinutes(td.getMinutes() + this.timeDif + this.utcOffset);
    timeDataIdx = this.dateToString(td);

    // timeHeaderに項目追加
    // Add item to timeHeader
    this.timeHeader.push({def: columnName, name: timeDataIdx});
  }

  dataConv(data2, unitId){
    // 単位変換
    // Unit Conversion
    let resp: string;

    // 数値以外の場合、空欄・nullの場合は変換しない
    // Do not convert if non-numeric, blank, or null
    if(isNaN(data2) || !data2){
      return data2;
    }

    switch(unitId){
      case 0:
        // 数値：何もしない
        // Value: Do nothing
        resp = data2;
        break;
      case 1:
        // 温度(設定温度以外)：通常変換
        // Temperature (other than setpoint): Normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5) + 32).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 2:
        // 温度(設定温度)：変換ロジック使用
        // Temperature (setpoint): conversion logic used
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(data2);
        }else{
          resp = data2;
        }
        break;
      case 3:
        // 温度差：通常変換
        // Temperature difference: Normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 4:
        // 圧力(kg/cm2)：変換ロジック使用
        // Pressure (kg/cm2): conversion logic used
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_kilopascal(Number(data2)).toFixed(1);
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 5:
        // 圧力(MPa)：kPaは通常変換、psiは変換ロジック使用
        // Pressure (MPa): kPa is normal conversion, psi uses conversion logic
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = (Number(data2) * 1000).toString();
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_megapascal_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 6:
        // 文字列表示：何もしない
        // String display: do nothing
        resp = data2;
        break;
      default:
        resp = data2;
    }
    return resp;
  }

  toSheetMode(){
    // 画面表示をグラフモードから表モードに変更
    // Change screen display from graph mode to table mode
    this.graphMode = false;
    this._ref.detectChanges();
  }

  toGraphMode(targetColumn){
    // 画面表示を表モードからグラフモードに変更
    // Change screen display from table mode to graph mode
    this.graphMode = true;
    this.gColumn = targetColumn;
    this.makeGraph();
    this.chartDestroy();
    this.createChart();
  }

  makeGraph() {
    // dataSourceの[gColumn]項目からグラフを作成する
    // Create a graph from the [gColumn] item in the dataSource
    let columnName = '';  // dataSource item name (TimeData_X) is entered

    for(let i=0; i<this.dataSource.length; i++){

      if(this.dataSource[i]['Key1'] === this.gColumn){

        switch(this.unitList[i]){
          case 0:
            this.unitName = '';
            break;
          case 1:
          case 2:
          case 3:
            if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
              this.unitName = '(°F)';
            }else{
              this.unitName = '(°C)';
            }
            break;
          case 4:
            if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
              this.unitName = '(kPa)';
            }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
              this.unitName = '(psi)';
            }else{
              this.unitName = '(kg/cm2)';
            }
            break;
          case 5:
            if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
              this.unitName = '(kPa)';
            }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
              this.unitName = '(psi)';
            }else{
              this.unitName = '(MPa)';
            }
            break;
          case 6:
          default:
            this.unitName = '';
        }

        const timeCount = this.timeHeader.length;

        this.formatted_data = []
        for(let k=0; k<timeCount; k++){
          columnName = 'TimeData_' + k;
          let addData = [];
          if(isNaN(this.dataSource[i][columnName])){
            addData = [this.timeHeader[k]['name'], 0];
          }else{
            addData = [this.timeHeader[k]['name'], Number(this.dataSource[i][columnName])];
          }

          this.formatted_data.push({
            x: addData[0],
            y: addData[1]
          })
        }
      }
    }
    this._ref.detectChanges();
  }

  dateToString(date){
    // 日付型のデータを受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Takes data from Date type data and returns a string in format "YYYY/MM/DD hh:mm"
    const strYear = date.getFullYear();
    let strMonth = 1 + date.getMonth();
    let strDay = date.getDate();
    let strHour = date.getHours();
    let strMinute = date.getMinutes();

    strMonth = ('0' + strMonth).slice(-2);
    strDay = ('0' + strDay).slice(-2);
    strHour = ('0' + strHour).slice(-2);
    strMinute = ('0' + strMinute).slice(-2);

    let strFormat = 'YYYY/MM/DD hh:mm'
    strFormat = strFormat.replace(/YYYY/g, strYear);
    strFormat = strFormat.replace(/MM/g, strMonth);
    strFormat = strFormat.replace(/DD/g, strDay);
    strFormat = strFormat.replace(/hh/g, strHour);
    strFormat = strFormat.replace(/mm/g, strMinute);
    
    return strFormat;
  }

  date12(strFormat){
    // "YYYY/MM/DD hh:mm"形式の文字列を受け取り、"YYYYMMDDhhmm"形式の文字列を返す
    // Takes a string in format "YYYY/MM/DD hh:mm" and returns a string in format "YYYYMMDDhhmm"
    strFormat = strFormat.replace('/', '');
    strFormat = strFormat.replace('/', '');
    strFormat = strFormat.replace(' ', '');
    strFormat = strFormat.replace(':', '');
    return strFormat;
  }  
  
  createChart(){
    if(this.graphMode){
      this.canvas = this.test_line.nativeElement
      // canvas.clearRect(0, 0, canvas.canvas.clientWidth, canvas.canvas.clientHeight);
      // canvas.clearRect(0, 0, 900, 400);
      this.chart = new Chart(this.canvas, ({
        type: "line",
        data: {
          datasets: [{
            label: this.gColumn + this.unitName,  // legend name
            data: this.formatted_data,            // data group to display [{x0, y0}, ... {xn, yn}]
            borderColor: 'rgba(0, 0, 211, 1)',    // Specify line color
            backgroundColor: 'rgba(0, 0, 211, 1)',// Specify fill color
            pointRadius: 0,                       // The size of the points on the graph is set to 0 (except when the mouse cursor is hovering over them).
            tension: 0.2,                         // Specifies the line tension, e.g., 0.3, when drawing a line graph with Bezier curves. Default is 0.
            cubicInterpolationMode: 'monotone',   // completion algorithm.' default' performs a custom weighted cubic completion with appropriate curves for all types of data sets; ' monotone' will result in one that preserves the monotonicity of the data, appropriate for y = f(x)-format datasets.
            }
          ]
        },
        options: {
          maintainAspectRatio: false, // Whether or not to keep the original canvas aspect ratio when resizing. Default is true.
          responsive: true,           // Whether to resize the chart if the container is resized. Default is true.
          interaction: {              // Settings for tooltips that appear when the mouse hovers over a point
            intersect: false,         // true means only when mouse is on point, false means always visible. Default is true.
            mode: "index"             // Specify which data to display in the tooltip by 'point' (only the target point), 'nearest' (nearest point), 'index' (value of the corresponding index for each dataset), 'dataset' (value of the corresponding dataset), 'x' (value on the same X axis) or 'y' (value on the same Y axis).
          },
          scales: {
            x: {
              type: 'time'          // Specify time notation
            },
          },
        },
        plugins: [
          vertical_line_plugin,     // Use custom plug-ins
        ]
      })
      );
    }
  }

  chartDestroy(){
    if(this.chart){
      this.chart.destroy();
    }
  }

}