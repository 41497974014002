import { UserService } from 'src/app/common/services/user/user.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  GatewayUnit, Presence, AirDirection, FanSpeedStages, AutoMode,
  Power, Mode, VentMode, Temp, LossnayFanSpeed, IndoorFanSpeed, TempControl, SetTempModeEnum, BatchTempControl, UnitGroup
} from '../../../manage/components/classes/GatewayUnit';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { SiteService } from '../../services/site.service';
import {
  GatewayUnitTwoDigitType, LevelEnum, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumDescriptions, MaintenanceJobTypeEnumTitle, TemperaturePreferenceEnum, ToastMessageTypeEnum
} from 'src/app/enumerations/enums';
import {
  ModalController,
  LoadingController
} from '@ionic/angular';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { ProfileUpdateResponse } from 'src/app/common/services/websockets/classes/responses/WebSocketResponses';
//import { GatewayGroup } from '../../../manage/components/classes/GatewayGroup';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SiteGatewayPlansComponent } from '../../pages/site-gateway-plans/site-gateway-plans.component';
//import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { CONNECTION_UPDATE_TIMER_INTERVAL, devEnv } from 'src/app/constants/kenzaconstants';
import { DisplayOptions } from 'src/app/common/classes/displayOptions';
import { GroupSelectorComponent, SelectedGroup, SelectedGroupGateway, SelectedGroupGroup } from 'src/app/common/components/group-selector/group-selector.component';
import { BatchGroupDisplay, GatewaysConnectionStatusEnum, GatewaysControlFeatureStatusEnum } from './batchControl/batchGroupDisplay';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import moment from 'moment';
import { Router } from '@angular/router';

export enum ControlMaintenanceJobStatusEnum {
  // how to handle maintenance job selected in group selector
  NoneInMaintenance,
  AllInMaintenance,
  SomeInMaintenance
}

// message to banner on screen based on maintenance job active on group selector
export const ControlMaintenanceJobStatusMessage: { [type:number]: string} = {};
ControlMaintenanceJobStatusMessage[ControlMaintenanceJobStatusEnum.NoneInMaintenance] = ``;
ControlMaintenanceJobStatusMessage[ControlMaintenanceJobStatusEnum.AllInMaintenance] = 
  `Indoor Coil/Group(s) in #JobName# mode.  To control unit(s), you must cancel the #JobName#.`;
ControlMaintenanceJobStatusMessage[ControlMaintenanceJobStatusEnum.SomeInMaintenance] = 
  `Some Indoor Coil/Group(s) in #JobName# mode.  Unselect those in #JobName# mode to control units.`;

@Component({
  selector: 'app-site-control',
  templateUrl: './site-control.component.html',
  styleUrls: ['./site-control.component.scss'],
})

export class SiteControlComponent {
  @ViewChild(GroupSelectorComponent) groupSelector: GroupSelectorComponent
  timerSubscription: Subscription = null;
  connectionUpdateTimer: Observable<number>;

  profileUpdateSubscription: Subscription;

  // zero state control
  siteGatewaysLoading = true;
  siteGatewaysExist = false;
  siteGatewayUnitsExist = false;
  siteGatewaysLoadError = false;

  // Batch Mode Selections from the GroupSelector
  // groups selected from the groupSelector  1 -> n
  selectedGroups: SelectedGroup[];
  // selected Gateways
  selectedGateways: SelectedGroupGateway[];

  // support to render the page and display batch options
  public bgd: BatchGroupDisplay = new BatchGroupDisplay(this.user, this.appAuth, this.mainSiteUIService);

  displayDataNotAvailable = false;
  debounceTimeout: ReturnType<typeof setTimeout>;

  // cached unit details to display based on gateway-serial-number
  cachedProfileUpdateMap = new Object();

  private commandQueue = [];

  public do: DisplayOptions = new DisplayOptions;

  // some enums for use in HTML
  ventModeEnum = VentMode;
  modeEnum = Mode;
  airDirectionEnum = AirDirection;
  powerEnum = Power;
  devEnv = devEnv;

  // maintenance job display control
  controlMaintenanceJobStatusEnum = ControlMaintenanceJobStatusEnum;
  maintenanceJobStatus: ControlMaintenanceJobStatusEnum;
  maintenanceJobMessage: string;

  // expired gateway selected id, if so
  expiredGatewayIdSelected: string;
  
  // Dynamic Timer
  tzSwitch;
  updateTimerFn = null;
  updateInterval = 999;
  private destroy$ = new Subject<void>();
  calendarTimerFormat = `dddd, MMMM Do, h:mm:ss A`;
  siteTime: string = moment.tz(this.user?.active?.timezone).format(this.calendarTimerFormat);

  constructor(
    public user: UserService,
    public siteService: SiteService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    private socket: SocketService,
    public appAuth: AppAuthenticationService,
    private mainSiteUIService: MainSiteUIService,
    private router: Router
  ) {
    this.profileUpdateSubscription = this.socket.ProfileUpdateEmitter.subscribe(
      // a data update has arrived for a gateway
      (profileUpdateResponse) => {
        const profileUpdate = this.mapProfileUpdateResponse(profileUpdateResponse);

        const device_serial = profileUpdate.device_serial;
        if (device_serial in this.cachedProfileUpdateMap) {

          profileUpdate.units.forEach((unit) => {
            const unit_group_id = unit.group_id;
            if (unit_group_id) {
              // try to find maching row in this.cachedProfileUpdateMap[profileUpdate.device_serial].units
              const result = this.cachedProfileUpdateMap[device_serial].units.find((o, i) => {
                if (o.group_id == unit_group_id) {
                  this.cachedProfileUpdateMap[device_serial].units[i] = unit;
                  return true; // stop looking
                }
              })
              if (!result) {
                // then it wasn't there.  Add
                this.cachedProfileUpdateMap[device_serial].units.push(unit);
              }
            }
          })
        } else {
          // add new
          this.cachedProfileUpdateMap[profileUpdate.device_serial] = profileUpdate;
        }

        this.refreshSelectedGateway(profileUpdate);
      }
    );

    this.maintenanceJobStatus = ControlMaintenanceJobStatusEnum.NoneInMaintenance;
    this.maintenanceJobMessage = '';

    // gateway connection polling update timer
    this.connectionUpdateTimer = interval(CONNECTION_UPDATE_TIMER_INTERVAL);
  }

  ngOnInit() {
    this.init_ui();
  }

  ngAfterViewInit() {
    // Empty
  }

  multipleControlsExpanded() {
    let multipleControlsAreExpanded = false;

    let controlsWithExpandCollapseFeature = this?.bgd && this?.bgd != undefined && this?.bgd != null && Object?.keys(this?.bgd) && Object?.keys(this?.bgd)?.length > 0 ? Object?.entries(this?.bgd)?.filter(([key, val]) => val && val != undefined && val != null && Object?.keys(val) && Object?.keys(val)?.length > 0 && Object?.prototype?.hasOwnProperty?.call(val, `expanded`)) : [];
    let controlObjectsArrayWithExpandCollapseFeature = controlsWithExpandCollapseFeature && controlsWithExpandCollapseFeature.length > 0 ? controlsWithExpandCollapseFeature.map(([key, val]) => val) : [];
    let expandedControls = controlObjectsArrayWithExpandCollapseFeature && controlObjectsArrayWithExpandCollapseFeature.length > 0 ? controlObjectsArrayWithExpandCollapseFeature.filter(ctrl => ctrl.expanded == true) : [];
    let controlsLength = controlObjectsArrayWithExpandCollapseFeature && controlObjectsArrayWithExpandCollapseFeature.length > 0 ? controlObjectsArrayWithExpandCollapseFeature.length : 0;
    let manyExpandedThreshold = controlsLength && controlsLength > 0 ? Math.round(controlsLength / 2) : 0;

    multipleControlsAreExpanded = expandedControls && expandedControls.length > manyExpandedThreshold;
    
    return multipleControlsAreExpanded;
  }

  startDynamicTimer() {
    if (this.updateTimerFn != null) {
      clearInterval(this.updateTimerFn);
      this.updateTimerFn = null;
    }
  
    this.updateTimerFn = setInterval(() => {
      if (this.user?.active && this.user?.active?.id != `default-site`) {
        this.tzSwitch = this.user?.active?.timezone ? this.user?.active?.timezone : `America/New_York`;
      }
      this.siteTime = moment.tz(this.tzSwitch).format(this.calendarTimerFormat);
    }, this.updateInterval);
  }  

  ionViewWillEnter() {
    // enter control
    this.startDynamicTimer();
    if (this.timerSubscription == null) {
      this.timerSubscription = this.connectionUpdateTimer.subscribe((/*tick*/) => this.updateConnectionStatus());
    }

    this.groupSelector.ionViewWillEnter();
  }

  ionViewWillLeave() {
    // moving away - turn off the subscriptions
    if (this.updateTimerFn != null) {
      clearInterval(this.updateTimerFn);
      this.updateTimerFn = null;
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.groupSelector.ionViewWillLeave();
    this.destroy$.next();
  }

  ionViewDidLeave() {
    if (this.updateTimerFn != null) {
      clearInterval(this.updateTimerFn);
      this.updateTimerFn = null;
    }
    this.destroy$.next();
  }

  controlGroups(groups: SelectedGroup[]) {
    // new list of groups selected.
    this.selectedGroups = groups;

    // populate the selectedGateways
    // empty current list of gateways and re-populate
    this.selectedGateways = [];
    // track which gateways we've found
    const gatewayIds = [];
    // track how many gateways have the control feature (subscription limit)
    let maintenanceJobStatus: ControlMaintenanceJobStatusEnum = ControlMaintenanceJobStatusEnum.NoneInMaintenance;
    let activeMaintenanceJobId: MaintenanceJobTypeEnum = MaintenanceJobTypeEnum.None;

    let gatewaysWithControlFeature = 0;
    let groupsInMaintenance = 0;
    let expiredGatewayIdSelected = ``;

    devEnv && console.log(`Control Selected Group(s)`, this.selectedGroups);
    this.selectedGroups.forEach((group) => {
      const groupGatewayId = group.gateway.id;

      if (group.subscriptionExpired) expiredGatewayIdSelected = groupGatewayId;

      if (!gatewayIds.includes(groupGatewayId)) {
        gatewayIds.push(groupGatewayId);
        // then add this gateway to our list
        this.selectedGateways.push(group.gateway);
        // does this gw have the subscription feature?
        if (group.gateway.supportsControlFeature()) gatewaysWithControlFeature += 1;
      }
      
      if (group.maintenanceJobId != MaintenanceJobTypeEnum.None) {
        activeMaintenanceJobId = group.maintenanceJobId;
        groupsInMaintenance += 1;
      }
    })

    this.expiredGatewayIdSelected = expiredGatewayIdSelected;

    // now set the gatewaysFeatureAllowedStatus
    if (gatewaysWithControlFeature == this.selectedGateways.length) this.bgd.gatewaysControlFeatureAllowedStatus = GatewaysControlFeatureStatusEnum.all;
    else if (gatewaysWithControlFeature > 0) this.bgd.gatewaysControlFeatureAllowedStatus = GatewaysControlFeatureStatusEnum.some;
    else this.bgd.gatewaysControlFeatureAllowedStatus = GatewaysControlFeatureStatusEnum.none;

    // now handle groups that are in a maintenance job of some sort
    if ( groupsInMaintenance > 0 )  {
      if (groupsInMaintenance == this.selectedGroups.length) maintenanceJobStatus = ControlMaintenanceJobStatusEnum.AllInMaintenance;
      else maintenanceJobStatus = ControlMaintenanceJobStatusEnum.SomeInMaintenance;
    }

    this.maintenanceJobStatus = maintenanceJobStatus;
    let maintJobIDs = [...new Set(this.selectedGroups.map(grp => grp.maintenanceJobId))].filter(jobID => jobID > 0);
    // devEnv && console.log(`Maint Jobs`, maintJobIDs);
    if (maintJobIDs && maintJobIDs.length > 0) {
      if (maintJobIDs.length > 1) {
        let maintenanceJobName = `Multiple Indoor Coil/Group(s) are running maintenance jobs.`;
        this.maintenanceJobMessage = `${maintenanceJobName} To control unit(s), you must cancel the active maintenance jobs.`;
      } else {
        let maintenanceJobName = MaintenanceJobTypeEnumTitle[activeMaintenanceJobId];
        this.maintenanceJobMessage = ControlMaintenanceJobStatusMessage[maintenanceJobStatus].replaceAll(`#JobName#`, maintenanceJobName);
      }
    }

    this.bgd.update(this.selectedGroups, true, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance));
    // devEnv && this.selectedGroups.length > 0 && console.log(`Selected Unit`, this.selectedGroups[0]?.gatewayUnits[0], this.bgd);

    this.updateConnectionStatus();
  }

  zeroStateMessage(state: string) {
    // startup messages from group_selector
    this.siteGatewaysLoadError = false;
    if (state === 'Loading') this.siteGatewaysLoading = true;
    if (state === 'LoadError') this.siteGatewaysLoadError = true;
    if (state === 'NoGateways') this.siteGatewaysExist = false;
    if (state === 'Gateways') this.siteGatewaysExist = true;
    if (state === 'NoUnits') this.siteGatewayUnitsExist = false;
    if (state === 'Units') this.siteGatewayUnitsExist = true;
    if (state == 'Ready') this.siteGatewaysLoading = false;
  }

  isExpiredGatewaySelected() {
    return this.expiredGatewayIdSelected != '';
  }

  updateConnectionStatus() {
    // update the connection status of this sites gateways
    if (this.siteService.isConnectedToInternet) {

      if (this.selectedGroups && this.selectedGroups.length > 0) {

        // get an update on the sites gateways connection status       
        this.siteService.getSiteGatewayConnectionStatus(this.selectedGroups[0].gateway.site_id).subscribe((res) => {

          // now walk what we got
          let gateway_connected_count = 0;
          res.forEach((gw) => {
            // is this gateway in our group list
            // update the gateways connection status to these values.
            const found = this.selectedGateways.find(sgw => { return sgw.id == gw.gateway_id })
            if (found != undefined) {
              // then this is one we care about
              if (gw.connected.toLowerCase() == 'true') gateway_connected_count += 1
              // update the connection object detail
              found.connection = gw;
            }
          })
          // now update the overall gateway connection status detail for the UI
          if (gateway_connected_count == this.selectedGateways.length) this.bgd.gatewaysConnectionStatus = GatewaysConnectionStatusEnum.online
          else if (gateway_connected_count > 0) this.bgd.gatewaysConnectionStatus = GatewaysConnectionStatusEnum.warning;
          else this.bgd.gatewaysConnectionStatus = GatewaysConnectionStatusEnum.offline;
        }, (error) => {
          console.log(`Error Getting Site Gateway Connection Status`, error);
          // stop trying untill the next refresh
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
          }
        });
      }

    }
  }

  async init_ui() {
    // set the UI options initially
    this.siteGatewaysExist = false;
    this.siteGatewayUnitsExist = false;
  }

  mapProfileUpdateResponse(profileUpdateResponse): ProfileUpdateResponse {
    // translate a raw profile update to one the client can understand

    const profileUpdate: ProfileUpdateResponse = new ProfileUpdateResponse();

    if (!profileUpdateResponse) return null;
    if (!(profileUpdateResponse.device_serial || profileUpdateResponse.units)) return null;

    profileUpdate.device_serial = profileUpdateResponse.device_serial;
    profileUpdate.units = [];

    const temp_units: GatewayUnit[] = [];

    const units = profileUpdateResponse.units;
    if (!units.length) return profileUpdate;

    for (const unit of units) {
      const gatewayUnit = new GatewayUnit({'group_id': unit.group_id, 'type': unit.type});
      gatewayUnit.update(unit, true);
      temp_units.push(gatewayUnit);
    }

    const converted_units: GatewayUnit[] = this?.perform_unit_temperature_conversion(this?.user?.accountPreferences?.temperaturepreference_id, temp_units);
    profileUpdate.units = converted_units;

    return profileUpdate;
  }

  refreshSelectedGateway(profileUpdate: ProfileUpdateResponse) {
    // profile update arrived for a gateway - do we care?

    if (profileUpdate && this.selectedGateways) {
      const profileSerialNumber = profileUpdate.device_serial;

      // if we are currently controlling unit(s) from this gateway - update them
      if (this.selectedGateways.find((gw) => { return gw.serial_number == profileSerialNumber })) {
        // then yes - we are showing some groups/units on this gateway

        // for each selected group - find the ones on this gateway
        this.selectedGroups.forEach((group) => {
          if (group.gateway_serial == profileSerialNumber) {
            //then consider this groups units - and update them based on values in the profile update

            if (this.mainSiteUIService.isDevTestAltSite) {
              // dev/local only special cases
              if (profileSerialNumber == `12345-123`) {
                // this unit will be identified by KenzaServer with the group_id that it belongs to
                // based on the first site this gateway serial number is registered to.
                // this doesn't work quite right for our fake 12345-123 gateway that is reused on 
                // many many sites.  So special case handler to update the group id's to values
                // that are relavent to THIS instance of 12345-123

                // set the group_id in the profileUpdate to match the group_ids of this gateway - based on bus_address matching
                group.gatewayUnits.forEach((unit) => {
                  const profileUnit: GatewayUnit = profileUpdate.units.find((profileUnit) => profileUnit.bus_address == unit.bus_address);
                  // if we found something - set its group_id
                  if (profileUnit) profileUnit.group_id = unit.group_id;
                })
              }
            }
        
            group.gatewayUnits.forEach((unit) => {
              const updatedUnit: GatewayUnit = profileUpdate.units.find((profileUnit) => profileUnit.group_id == unit.group_id);

              if (updatedUnit) {
                // then we need to update this unit's value.

                // clear ignorable on a control?
                GatewayUnit.controlAttributes.forEach((control) => {
                  const bgd_control = control.startsWith(`SetTemp`) ? `setTemp` : control;
                  if (unit[control] == updatedUnit[control] || this.bgd[bgd_control].ignorable.expired) {
                    // notify the ignorable someone has reported in
                    this.bgd[bgd_control].ignorable.groupReported();
                    unit[control] = updatedUnit[control];
                  }
                })

                // now update ALL fields of the unit with details from the profile update.
                unit.update(updatedUnit, false)
              }
            })
          }
        })
        // now push an update to the display values using these updated profile values
        this.bgd.update(this.selectedGroups, false, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance))
      }

    }
  }

  perform_unit_temperature_conversion(convertToUnit: TemperaturePreferenceEnum, units: GatewayUnit[]): GatewayUnit[] {
    // convert temps to the logged in account requested type (F/C)
    let converted_units: GatewayUnit[] = [];

    switch (convertToUnit) {
      case TemperaturePreferenceEnum.Celsius:
        return units;

      case TemperaturePreferenceEnum.Fahrenheit:
        converted_units = units.map(unit => {

          unit.AutoMin = unit.AutoMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMin) : Temp.zero;
          unit.AutoMax = unit.AutoMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMax) : Temp.hundred;
          unit.CoolMin = unit.CoolMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMin) : Temp.zero;
          unit.CoolMax = unit.CoolMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMax) : Temp.hundred;
          unit.HeatMin = unit.HeatMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMin) : Temp.zero;
          unit.HeatMax = unit.HeatMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMax) : Temp.hundred;
          unit.inlet_temp = unit.inlet_temp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.inlet_temp) : Temp.unk;

          unit.SetTemp = unit.SetTemp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp) : Temp.unk;
          unit.SetTemp1 = unit.SetTemp1 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp1) : Temp.unk;
          unit.SetTemp2 = unit.SetTemp2 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp2) : Temp.unk;
          unit.SetTemp3 = unit.SetTemp3 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp3) : Temp.unk;
          unit.SetTemp4 = unit.SetTemp4 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp4) : Temp.unk;
          unit.SetTemp5 = unit.SetTemp5 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp5) : Temp.unk;

          // deadband is a simple double to go to F
          unit.deadband = unit.deadband * 2.0;
          return unit;
        });
        break;
    }

    return converted_units;
  }

  batchControl(param: string, value: string, updateableParameter = '') {
    // issue control point for each group selected into that group

    // not connected? - then no.
    if (!this.siteService.handleIsConnected()) return;

    // fail if all gw's are offline
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.offline) {
      this.toastGatewaysOffline();
      return;
    }

    // fail if all gateways dont have control feature/subscription
    if (this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.none) {
      this.presentSubscriptionOptions();
      return;
    }

    // set the ignorable for this index(control)
    const index = updateableParameter || param.toLowerCase();
    this.bgd[index].ignorable.set(this.selectedGroups.length);

    // ok now walk each group.
    this.selectedGroups.forEach((group: SelectedGroup) => {
      // should we issue the command against this group?
      if (group.gateway.isConnected() && group.gateway.supportsControlFeature()) {
        // then yes - do it.

        // value for auto changes based on unit capabilities
        if (param == 'Mode' && value == Mode.auto) {
          // the value here is auto or dual_auto based on capabilities of this unit
          value = group.gatewayUnits[0].isDualAuto() ? Mode.dual_auto : Mode.auto;
        }
        this._control(group.gatewayUnits[0], param, value, updateableParameter);
      }
    })

    // now do a soft update to the displayControlGroup
    this.bgd.update(this.selectedGroups, false, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance));

    // are there post change warnings we should put up?
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning ||
      this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.some) {
      this.toastFailedControl();
    }

  }

  batchControlFanSpeed(position: number) {
    // issue control point for each group selected into that group

    // do nothing for none mode.
    if (position == -1) return; 

    // not connected? - then no.
    if (!this.siteService.handleIsConnected()) return;

    // fail if all gw's are offline
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.offline) {
      this.toastGatewaysOffline();
      return;
    }

    // fail if all gateways dont have control feature/subscription
    if (this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.none) {
      this.presentSubscriptionOptions();
      return;
    }

    // set the ignorable for fan_speed
    this.bgd[`fan_speed`].ignorable.set(this.selectedGroups.length);

    // ok now walk each group.
    this.selectedGroups.forEach((group: SelectedGroup) => {
      // should we issue the command against this group?
      if (group.gateway.isConnected() && group.gateway.supportsControlFeature()) {
        // then yes - do it.
        this._controlFanSpeed(group.gatewayUnits[0], position);
      }
    })

    // now do a soft update to the displayControlGroup
    this.bgd.update(this.selectedGroups, false, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance));

    // are there post change warnings we should put up?
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning ||
      this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.some) {
      this.toastFailedControl();
    }

  }

  _control(gatewayUnit: GatewayUnit, param: string, value:string, updateableParameter = null, debouneTimeout=1000) {
    // private method to issue a single control command at a single gateway unit/group

    const index = updateableParameter || param.toLowerCase();

    // compare to old value
    let oldValue = gatewayUnit[index];
    
    // if we are already at this value - there is nothing to do for this group.
    if (value == oldValue) return;

    // if we are changing the mode we need to do a check on the SetTemp min/max values if it is a single setpoint system
    if (param.toLowerCase() == "Mode".toLowerCase()
      && gatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit
      && gatewayUnit.GroupType == Presence.disabled
      && value != 'fan') {
      if (gatewayUnit.SetTemp > gatewayUnit?.minMaxPoints[value]?.max) {
        this._control(gatewayUnit, 'SetTemp', this.tempToString(gatewayUnit.minMaxPoints[value].max), 'SetTemp');
      } else if (gatewayUnit.SetTemp < gatewayUnit?.minMaxPoints[value]?.min) {
        this._control(gatewayUnit, 'SetTemp', this.tempToString(gatewayUnit.minMaxPoints[value].min), 'SetTemp');
      }
    }

    // set the value in the object
    gatewayUnit[index] = value;

    // if a SetTemp value we may need to convert to C from F for control call
    if ((param.startsWith("SetTemp") || param.startsWith("MultiSetTemp"))
      && this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
      value = TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(value);
    }

    // create and queue command
    const command = {
      param: param,
      value: value,
      gateway_id: gatewayUnit.gateway_id,
      group_id: gatewayUnit.group_id,
      scale: 'C'
    };

    clearTimeout(this.debounceTimeout);

    // take it out if its the same group_id and the same param (control)
    this.commandQueue = this.commandQueue.filter(c => !((c.command.param == command.param) && (c.command.group_id == command.group_id)));
    this.commandQueue.push({ command, gatewayUnit, oldValue, index });

    this.debounceTimeout = setTimeout(() => {
      this.submitControlMulti(this.commandQueue);
      this.commandQueue.length = 0;
    }, debouneTimeout);

    gatewayUnit.adjustSetTemps();
  }

  allGatewaysOffline() {
    // are all the gateways on the current group selection offline?
    let allOffline = true;
    this.selectedGateways.forEach((gw) => {
      // if THIS gw is online - then they are not all offline
      if (gw.isConnected()) allOffline = false;
    })
    // if we get there - they are all online. 
    return allOffline;
  }

  someGatewaysOffline() {
    // are any of the gateways on the current group selection offline?
    let someOffline = false;
    this.selectedGateways.forEach((gw) => {
      // if THIS gw is offline - then they are not all offline
      if (!gw.isConnected()) someOffline = true;
    })
    return someOffline;
  }

  async toastGatewaysOffline() {
    // throw up a toaster message that we can't do that right now.
    const plural = this.bgd.singleGroup ? '' : 's'
    const message = `Unable to control group${plural} at this time.`
    this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, `Gateway${plural} Disconnected`, message)
  }

  async toastFailedControl() {
    // can't complete the request due to some gateways offline or some gateway subscriptions.
    let msg = ''
    const title = 'Not all Groups Updated...'

    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning &&
      this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.some) msg = 'Gateway connection status and subscription settings are blocking some groups from updates.'
    else if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning) msg = 'Gateway connection status is blocking some groups from updates.'
    else msg = "Gateway subscription settings are blocking some groups from updates."

    this.siteService.presentToastMessage(ToastMessageTypeEnum.Warning, title, msg)
  }

  submitControlMulti(commands) {
    // push all commands in the command queue to the unit
    this.siteService.controlGatewayUnit(commands.map(c => c.command)).subscribe(
      //() => { },
      (/*err*/) => {
        // failed - reset values and ignorable
        commands.forEach((command) => {
          const selectedGatewayUnit = command.selectedGatewayUnit;
          const oldValue = command.oldValue;
          const index = command.index;

          selectedGatewayUnit[index] = oldValue;

          this.bgd[index].ignorable.reset()

          this.bgd.update(this.selectedGroups, true, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance))

          this.siteService.presentToastMessage(ToastMessageTypeEnum.Warning, 'Failed to Control Unit', 'Unable to change state at this time, please try again later');
        });
      });
  }

  newToggleIsExpanded(control) {
    // toggle the control group UI display
    control.expanded = !control.expanded
  }

  _controlFanSpeed(selectedGatewayUnit: GatewayUnit, position: number) {
    // issue a single fanspeed control request to this gateway at this position
    // these objects represent the speed number to provide based on the fanSpeed stage/capabilitie and the
    // 'position' number provided.  If the speed is disabled or none then that speed isn't supported in that stage
    // position is 0:very_log, 1:low, 2:medium, 3:high

    const fanSpeeds = {
      [GatewayUnitTwoDigitType.IndoorUnit]: {
        [FanSpeedStages.none]: [ IndoorFanSpeed.disabled, IndoorFanSpeed.disabled, IndoorFanSpeed.disabled, IndoorFanSpeed.disabled],
        [FanSpeedStages.two_stages]: [IndoorFanSpeed.disabled, IndoorFanSpeed.low, IndoorFanSpeed.disabled, IndoorFanSpeed.high],
        [FanSpeedStages.three_stages]: [IndoorFanSpeed.disabled, IndoorFanSpeed.low, IndoorFanSpeed.medium, IndoorFanSpeed.high],
        [FanSpeedStages.four_stages]: [IndoorFanSpeed.very_low, IndoorFanSpeed.low, IndoorFanSpeed.medium, IndoorFanSpeed.high]
      },
      [GatewayUnitTwoDigitType.Lossnay]: {
        [FanSpeedStages.one_stage]: [LossnayFanSpeed.disabled, LossnayFanSpeed.disabled, LossnayFanSpeed.medium, LossnayFanSpeed.disabled],
        [FanSpeedStages.two_stages]: [LossnayFanSpeed.disabled, LossnayFanSpeed.low, LossnayFanSpeed.medium, LossnayFanSpeed.disabled],
        [FanSpeedStages.three_stages]: [LossnayFanSpeed.very_low, LossnayFanSpeed.low, LossnayFanSpeed.medium, LossnayFanSpeed.disabled],
        [FanSpeedStages.four_stages]: [LossnayFanSpeed.very_low, LossnayFanSpeed.low, LossnayFanSpeed.medium, LossnayFanSpeed.high]
      }
    };

    // small change for LC when FanExHighSW is enabled but FanExLowSW is not
    if (selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
      if (selectedGatewayUnit.fan_extra_low_sw == Presence.disabled &&
        selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {

        fanSpeeds[GatewayUnitTwoDigitType.Lossnay][FanSpeedStages.three_stages] =
          [LossnayFanSpeed.disabled, LossnayFanSpeed.low, LossnayFanSpeed.medium, LossnayFanSpeed.high];
      }

      if (selectedGatewayUnit.fan_extra_low_sw == Presence.enabled &&
        selectedGatewayUnit.fan_extra_high_sw == Presence.disabled) {

        fanSpeeds[GatewayUnitTwoDigitType.Lossnay][FanSpeedStages.three_stages] =
          [LossnayFanSpeed.very_low, LossnayFanSpeed.low, LossnayFanSpeed.medium, LossnayFanSpeed.disabled];
      }
    }

    let speedSw;

    if (selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay) {
      speedSw = this.do.getRealLossnayFanStages(selectedGatewayUnit);
    } else {
      speedSw = selectedGatewayUnit.fan_speed_sw;
    }

    const fanSpeed = fanSpeeds[selectedGatewayUnit.type][speedSw][position];

    this._control(selectedGatewayUnit, 'FanSpeed', fanSpeed, 'fan_speed');
  }

  _getControlPointsForCurrentMode(selectedGatewayUnit: GatewayUnit, tempControl: BatchTempControl) {
    // return [mode, setting, control, dbSetting, dbControl]
    // mode - mode limits to consider
    // setting - value for read of this point
    // control - value for set of this point
    // dbSetting - value for related deadband read of this point or null for no deadband
    // dbControl - value for related deadband set of this point or null for no deadband
    const groupTypeEnabled = selectedGatewayUnit.GroupType === Presence.enabled
    switch (selectedGatewayUnit.mode) {
      case Mode.heat:
        if (groupTypeEnabled) return [Mode.heat, 'SetTemp2', 'MultiSetTemp2', null, null]
        else return [ Mode.heat, 'SetTemp', 'SetTemp', null, null]
      case Mode.cool:
        if (groupTypeEnabled) return [Mode.cool, 'SetTemp1', 'MultiSetTemp1', null, null]
        else return [ Mode.cool, 'SetTemp', 'SetTemp', null, null]
      case Mode.dry:
        if (groupTypeEnabled) return [Mode.dry, 'SetTemp1', 'MultiSetTemp1', null, null]
        else return [ Mode.dry, 'SetTemp', 'SetTemp', null, null]
      case Mode.dual_auto:
      case Mode.auto:
      case Mode.auto_heat:
      case Mode.auto_cool:
        if (groupTypeEnabled) {
          // Group Type Enabled - new temp modes
          if (tempControl == BatchTempControl.single) {
            // single set temp mode
            return [Mode.auto, 'SetTemp3', 'MultiSetTemp3', null, null]
          } else if (tempControl == BatchTempControl.dualLow) {
            // dual mode - heat setting
            return [Mode.heat, 'SetTemp2', 'MultiSetTemp2', 'SetTemp1', 'MultiSetTemp1']
          } else {
            // dual mode - cool setting - BatchTempControl.dualHigh
            return [Mode.cool, 'SetTemp1', 'MultiSetTemp1', 'SetTemp2', 'MultiSetTemp2']
          }
        } else {
          // GroupType Disalbed - only Set Temp
          return [Mode.auto, 'SetTemp', 'SetTemp', null, null]
        }
        case Mode.setback:
        case Mode.setback_heat:
        case Mode.setback_cool:
          if (!groupTypeEnabled) throw new Error("SetBack mode with no new groupType!")
          if (tempControl == BatchTempControl.single) throw new Error("SetBack with a single mode change?")
          if (tempControl == BatchTempControl.dualLow) {
            // low/heat mode change for setback
            return [Mode.heat, 'SetTemp5', 'MultiSetTemp5', 'SetTemp4', 'MultiSetTemp4']
          } else {
            // high/cool mode change for setback
            return [Mode.cool, 'SetTemp4', 'MultiSetTemp4', 'SetTemp5', 'MultiSetTemp5']
          }

    }
  }

  // convertTemperatureIfNeeded(value: string) {
  //   if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
  //     value = TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(value);
  //   }
  //   return value;
  // }

  tempToString(value:number): string {
    // convert this number to a string with decimals based on temp setting
    // F -> no decimals
    // C -> 1 decimal
    if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) return value.toFixed(0);
    return value.toFixed(1);
  }

  batchAdjustSetPoint(direction: number, tempControl: BatchTempControl) {
    // issue a temp set command for the selected group - based on group issue different commands

    // high level checks

    // not connected? - then no.
    if (!this.siteService.handleIsConnected()) return;

    // fail if all gw's are offline
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.offline) {
      this.toastGatewaysOffline();
      return;
    }

    // fail if all gateways dont have control feature/subscription
    if (this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.none) {
      this.presentSubscriptionOptions()
      return
    }

    // set the ignorable for SetTemp
    this.bgd.setTemp.ignorable.set(this.selectedGroups.length)

    // in the case where multiple groups are selected - with a different setpoint - there will be a range of values in the control.
    // in that case we want to set all units to the same upper or lower level  value - depending on which was clicked in the ui.
    let setTempOverride = 0;
    // step direction is based on C vs F
    let step = this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? 1 * direction : 0.5 * direction

    if (tempControl == BatchTempControl.single) {
      if (this.bgd.setTemp.maxSingleSetTemp != this.bgd.setTemp.minSingleSetTemp) {
        // then yes we have a range of values for single set temp.
        // we will make the new desired set temp the value less the step the adjustSetTemp is about it add (or remove)
        if (direction > 0) {
          // then we are going up - make the new setTemp the upper value less the step
          setTempOverride = parseFloat(this.bgd.setTemp.maxSingleSetTemp) - step;
        } else {
          // direction is negative - so going down.
          setTempOverride = parseFloat(this.bgd.setTemp.minSingleSetTemp) - step;
        }
      }
    } else if (tempControl == BatchTempControl.dualLow) {
      if (this.bgd.setTemp.maxDualLowSetTemp != this.bgd.setTemp.minDualLowSetTemp) {
        // then yse we have a range of values for dual low set temp
        // we will make the new desired set temp the value less the step for adjustSetTemp is about to add (or remove)
        if (direction > 0) {
          // then we are going up.
          setTempOverride = parseFloat(this.bgd.setTemp.maxDualLowSetTemp) - step;
        } else {
          // direction is netative - so going down
          setTempOverride = parseFloat(this.bgd.setTemp.minDualLowSetTemp) - step;
        }
      }
    } else {
      // dualHigh
      if (this.bgd.setTemp.maxDualHighSetTemp != this.bgd.setTemp.minDualHighSetTemp) {
        if (direction > 0) {
          // going up
          setTempOverride = parseFloat(this.bgd.setTemp.maxDualHighSetTemp) - step;
        } else {
          // going down
          setTempOverride = parseFloat(this.bgd.setTemp.minDualHighSetTemp) - step;
        }
      }
    }

    // ok now walk each group.
    this.selectedGroups.forEach((group: SelectedGroup) => {
      // based on the tempControl clicked - does it apply to THIS group?
      // it might not.
      const unit: GatewayUnit = group.gatewayUnits[0];

      let selectedGroupSetTempType:SetTempModeEnum = SetTempModeEnum.single;
      if (unit.isInAuto()) { 
        // then its based on the auto type
        if (unit.isDualModeAuto() ) selectedGroupSetTempType = SetTempModeEnum.dual;
        else selectedGroupSetTempType = SetTempModeEnum.single;
      }
      else if ( unit.isInSetback()) selectedGroupSetTempType = SetTempModeEnum.dual

      // if the unit is expecing a single control and we clicked a single control - then apply it.
      if ((tempControl == BatchTempControl.single) && (selectedGroupSetTempType == SetTempModeEnum.single)) {
        // then this click is trying to change the single set temp this group/unit.
        this._adjustSetPoint(unit, step, tempControl, setTempOverride);
      // else if the unit is expecting a dual option and we clicked a dual button (high|low) - then apply it.
      } else if (((tempControl == BatchTempControl.dualHigh) || (tempControl == BatchTempControl.dualLow)) && (selectedGroupSetTempType == SetTempModeEnum.dual)) {
        // then this click is trying to change the dual mode set temp (upper or lower) of this group/unit.
        this._adjustSetPoint(unit, step, tempControl, setTempOverride);
      }

   })

    // now do a soft update to the displayControlGroup
    this.bgd.update(this.selectedGroups, false, (this.maintenanceJobStatus != ControlMaintenanceJobStatusEnum.NoneInMaintenance));

    // are there post change warnings we should put up?
    if (this.bgd.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning ||
      this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.some) {
      this.toastFailedControl();
    }
  }

  private _adjustSetPoint(unit: GatewayUnit, step: number, tempControl: BatchTempControl, setTempOverride:number) {
    // issue set temp 

    // now - what control are we changing?
    if (unit.GroupType == Presence.disabled ) {
      
      let currentMode = unit.mode;
      if (unit.isInAuto()) currentMode = Mode.auto;
      if (unit.isInSetback()) currentMode = Mode.setback

      // old style temp control - everything is 'SetTemp'
      let setTemp = ( setTempOverride == 0 ? parseFloat(unit.SetTemp) : setTempOverride ) + step;

      // check for range edges

      // under the min
      if (setTemp < this.bgd.setTemp.minMaxPoints[currentMode].min) {
        setTemp = this.bgd.setTemp.minMaxPoints[currentMode].min;

      // over the max
      } else if (setTemp > this.bgd.setTemp.minMaxPoints[currentMode].max) {
        setTemp = this.bgd.setTemp.minMaxPoints[currentMode].max;
      }

      // convert to C if the value is F
      //const convertedTempValue = this.convertTemperatureIfNeeded(setTemp.toFixed(1));

      // send down as SetTemp
      this._control(unit, 'SetTemp', this.tempToString(setTemp), 'SetTemp', 3000);

    } else {
      // new style temp control - should be using MultiSetTemp[1-5]
      // MultiSetTemp1 - cooling/upper & dry set and upper auto set
      // MultiSetTemp2 - heating/lower set and lower auto set
      // MultiSetTemp3 - Single Set Point auto
      // MultiSetTemp4 - Setback cool/upper set
      // MultiSetTemp5 - Setback heat/lower set

      let [mode, setting, control, dbSetting, dbControl] = this._getControlPointsForCurrentMode(unit, tempControl);

      // update settemp to the desired set point with the step offset
      let setTemp = ( setTempOverride == 0 ? parseFloat(unit[setting]) : setTempOverride) + step;

      // ensure we're not outside the range min|max values
      if (setTemp < this.bgd.setTemp.minMaxPoints[mode].min) {
        setTemp = this.bgd.setTemp.minMaxPoints[mode].min;
      } else if (setTemp > this.bgd.setTemp.minMaxPoints[mode].max) {
        setTemp = this.bgd.setTemp.minMaxPoints[mode].max;
      }

      // deadband checks
      if (dbSetting !== null) {

        // then there is a deadband to consider.
        // other value to watch
        let dbSetTemp = parseFloat(unit[dbSetting]);

        // logic is as follows
        // if new setpoint puts us 'within' the deadband of the other setTemp then
        //   calculate a new other set point that would honor the deadband
        //   if this new other set point is valid for ITS range limits (min|max)
        //     change the other set point to the new value and our set point as requested
        //   else
        //     deny the change to original set temp as it pushes the other set temp to invalid
        // else
        //   allow the change - within deadband.

        if (tempControl == BatchTempControl.single) throw new Error("deadband with single mode?!?");

        // are we going up from dual low?
        if ((tempControl == BatchTempControl.dualLow) && (step > 0)) {
          // then the 'other' temp control is the cool
          if (setTemp + this.bgd.setTemp.deadband > dbSetTemp) {
            // then yes we need to push up the other set temp
            dbSetTemp = setTemp + this.bgd.setTemp.deadband;
            if (dbSetTemp > this.bgd.setTemp.minMaxPoints[Mode.cool].max) {
              // then we have a problem, new other set temp is over max, deny change to setTemp
              setTemp = parseFloat(unit[setting]);
            } else {
              // push in this change to other set temp
              //const convertedTempValue = this.convertTemperatureIfNeeded(dbSetTemp.toFixed(1));
              this._control(unit, dbControl, this.tempToString(dbSetTemp), dbSetting, 3000);                
            }
          }
        // else are we going down from cool low
        } else if ((tempControl == BatchTempControl.dualHigh) && (step < 0)) {
          // then the 'other' temp conrol is the heat
          if (setTemp - this.bgd.setTemp.deadband < dbSetTemp) {
            // then yes we need to push down the other set temp
            dbSetTemp = setTemp - this.bgd.setTemp.deadband;
            if (dbSetTemp < this.bgd.setTemp.minMaxPoints[Mode.heat].min) {
              // then we have a problem, new other set temp is under min, deny change to setTemp
              setTemp = parseFloat(unit[setting]);
            } else {
              // push in this change to other set temp
              //const convertedTempValue = this.convertTemperatureIfNeeded(dbSetTemp.toFixed(1));
              this._control(unit, dbControl, this.tempToString(dbSetTemp), dbSetting, 3000);
            }
          }
        }
      }

      // push in SetTemp change.
      //const convertedTempValue = this.convertTemperatureIfNeeded(setTemp.toFixed(1));
      this._control(unit, control, this.tempToString(setTemp), setting, 3000);
    }
  }

  formatLastUpdated() {
    // format what to display in last updated
    if (this.bgd.singleGroup) {
      if (this.bgd.lastUpdate) {
        return this.bgd.lastUpdate.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
        }) || '--';
      } else {
        return '--'
      }
    }
    return 'Batch'
  }

  showUnitLastUpdated() {
    if (this.bgd.singleGroup) {
      if (this.bgd.unitLastUpdated) {
        return moment(this.bgd.unitLastUpdated).format(`h:mm:ss A`) || `--`;
      } else {
        return `--`;
      }
    } else {
      return `Batch`;
    }
  }

  ngOnDestroy() {
    if (this.profileUpdateSubscription) {
      this.profileUpdateSubscription.unsubscribe();
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    if (this.updateTimerFn != null) {
      clearInterval(this.updateTimerFn);
      this.updateTimerFn = null;
    }
    this.destroy$.next();
  }

  async presentSubscriptionOptions() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      backdropDismiss: true,
      componentProps: {
        title: "Upgrade your Subscription Plan",
        initialModelClassNameToDisplay: this.selectedGateways[0].model.class_name
      },
    });
    return await modal.present();

  }

  upgradeExpiredSubscription() {
    // we have an expired gateway group selected in the sushi bar - and the owner
    // has selected the option to upgrade it.  Navigate to the gateway subscription page
    this.router.navigate(['/account/' + this.user.id + '/details/subscriptions'], { queryParams: { upgrade: this.expiredGatewayIdSelected}});    
  }

  singleButtonControlClick(e) {
    if (this.bgd.gatewaysControlFeatureAllowedStatus == GatewaysControlFeatureStatusEnum.none) {
      this.presentSubscriptionOptions();
      return;
    }
  }

  activityHistory() {
    // link to MaintenanceJob Activity History page - with option to return to Site Control.
    this.router.navigate(['/account/' + this.user.id + '/details/analytics-reports'], { queryParams: { fromSiteId: this.user.active.id} } );
  }

  hasPermissionForSiteAnalytics() {
    return this.appAuth.doesLevelHavePermission(
      this.user.activeSiteUserLevel,
      this.appAuth.permissionEnums.ViewSiteAnalytics
    );
  }  

  isSiteOwner() {
    // is this user the owner of the site?
    return this.user.activeSiteUserLevel ==  LevelEnum.Owner;
  }

}