import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Logger, Log } from '../../../../common/services/logging/log.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { States } from 'src/app/constants/states';
import { CustomValidators } from 'src/app/constants/validators';
import { DateStringUtilities } from 'src/app/common/utilities/stringUtilities';
import { AccountDetail } from '../../classes/accountdetail';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.page.html',
  styleUrls: ['./account-detail.page.scss'],
})
export class AccountDetailPage implements OnInit {
  @ViewChild('uploadPictureFile') uploadPictureFile: ElementRef;

  accountDetailText = 'Save';
  imageUrl: string;
  accountDetailForm: UntypedFormGroup;
  showClass = true;
  stateList: Array<any>;
  formatPhone: (any) = CustomValidators.formatPhone;
  setPhoneField: (any) = CustomValidators.setPhoneField;
  clearPhoneField: (any) = CustomValidators.clearPhoneField;
  maskPhoneField: (any) = CustomValidators.maskPhoneField;
  setZipField: (any) = CustomValidators.setZipField;
  maskZipField: (any) = CustomValidators.maskZipField;
  clearZipField: (any) = CustomValidators.clearZipField;
  formatZip: (any) = CustomValidators.formatZip;
  memberSince: string;
  selectedFile: File | null;
  uploadPictureFileMessage: string;

  tabDetails = {
    profileSelected: true,
    companyInformationSelected: false,
  };

  constructor(
    private user: UserService,
    private logger: Logger,
    private loading: LoadingController,
    private modalController: ModalController,
    public formBuilder: UntypedFormBuilder) {
    this.imageUrl = '';
    this.tabDetails.profileSelected = true;
    this.stateList = States.list;
  }

  ngOnInit() {
    this.createAccountDetailForm();
    this.displayAccountPicture(this.user.id);
    this.getAccountDetails();
    this.uploadPictureFileMessage = '';
  }

  getAccountDetails() {
    this.user.getAccountDetails().subscribe((data: any) => {
      let update: any;

      if (data.email) {
        update = {
          firstName: data.fname ? data.fname : '',
          lastName: data.lname ? data.lname : '',
          title: data.title ? data.title : '',
          phoneNumber: data.phone ? data.phone : '',
          email: data.email ? data.email : '',
          memberSince: DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy'),

          company: data.company ? data.company : '',
          companyPhone: data.company_phone ? data.company_phone : '',
          addressLine1: data.street_line1 ? data.street_line1 : '',
          addressLine2: data.street_line2 ? data.street_line2 : '',

          city: data.city ? data.city : '',
          state: data.state ? data.state : '',
          zip: data.postal_code ? data.postal_code : '',
        };
      } else {
        update = {
          firstName: '',
          lastName: '',
          title: '',
          phoneNumber: '',
          email: data.email,
          memberSince: DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy'),

          company: '',
          companyPhone: '',
          addressLine1: '',
          addressLine2: '',

          city: '',
          state: '',
          zip: '',
        };
      }
      this.memberSince = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MMMM dd, yyyy'),
        this.accountDetailForm.setValue(update);
    });
  }

  createAccountDetailForm() {
    this.accountDetailForm = this.formBuilder.group({

      firstName: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      lastName: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      title: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      phoneNumber: ['', Validators.compose([
        CustomValidators.phoneMinLength,
        Validators.maxLength(28)
      ])
      ],
      email: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(50)
      ])
      ],
      memberSince: [''],
      company: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      companyPhone: ['', Validators.compose([
        CustomValidators.phoneMinLength,
        Validators.maxLength(28)
      ])
      ],
      addressLine1: ['', Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(50)
      ])
      ],
      addressLine2: ['', Validators.compose([
        Validators.maxLength(50)
      ])
      ],
      city: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      state: ['', Validators.compose([
        Validators.minLength(2)
      ])
      ],
      zip: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d{5}$|^\d{5}-$|^\d{5}-[_]{4}$|^\d{5}-\d{4}$/),
          Validators.minLength(5),
          Validators.maxLength(10),
        ]),
      ],
    });
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  onTabClick(selectedTabName) {
    this.toggleTabSelection(selectedTabName);
  }

  toggleTabSelection(selectedTabName) {
    switch (selectedTabName) {
      case 'Profile':
        this.tabDetails.profileSelected = true;
        this.tabDetails.companyInformationSelected = false;
        break;

      case 'CompanyInformation':
        this.tabDetails.companyInformationSelected = true;
        this.tabDetails.profileSelected = false;
        break;
    }
  }

  onCancel() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    if (this.accountDetailForm.valid) {
      const accountDetail = {
        account_photo_name: '',
        fname: this.accountDetailForm.value.firstName,
        lname: this.accountDetailForm.value.lastName,
        title: this.accountDetailForm.value.title,
        memberSince: this.accountDetailForm.value.memberSince,
        phone: this.accountDetailForm.value.phoneNumber,
        company: this.accountDetailForm.value.company,
        company_phone: this.accountDetailForm.value.companyPhone,
        street_line1: this.accountDetailForm.value.addressLine1,
        street_line2: this.accountDetailForm.value.addressLine2,
        city: this.accountDetailForm.value.city,
        state: this.accountDetailForm.value.state,
        postal_code: this.accountDetailForm.value.zip,
        country: ''
      };

      const load = await this.loading.create({
        spinner: 'lines',
        message: 'Saving account details...',
      });

      load.present();

      this.user.setAccountDetails(accountDetail)
        .subscribe(data => {
          load.dismiss();
          this.modalController.dismiss();
        },
          error => {
            load.dismiss();
          });

      load.dismiss();
    }
  }



  saveAccountPhoto(event) {

    const allowedAccountPhotoTypes = ['gif', 'jpg', 'jpeg', 'png'];

    const accountDetail: AccountDetail = new AccountDetail();

    accountDetail.id = this.user.id;
    accountDetail.account_photo_name = event.target.files[0].name;
    accountDetail.country = '';
    accountDetail.last_login = null;
    accountDetail.removed_at = null;
    accountDetail.status = 0;
    accountDetail.fname = '';
    accountDetail.lname = '';
    accountDetail.title = '';
    accountDetail.phone = '';
    accountDetail.email = '';
    accountDetail.created_at = '';
    accountDetail.company = '';
    accountDetail.company_phone = '';
    accountDetail.street_line1 = '';
    accountDetail.street_line2 = '';
    accountDetail.city = '';
    accountDetail.state = '';
    accountDetail.postal_code = '';

    const fileDetails = event.target.files[0];
    const fileExtension = fileDetails.name.substr(fileDetails.name.lastIndexOf('.') + 1);
    const found = allowedAccountPhotoTypes.filter(ext => ext.toLowerCase() === fileExtension.toLowerCase());

    if (found.length === 0) {
      this.uploadPictureFileMessage = 'Photo must be .png, .gif, or .jpg';
      return (true);
    }

    if (fileDetails.size > 1200000) {
      this.uploadPictureFileMessage = 'File size must not exceed 1MB';
      return (true);
    } else {
      this.uploadPictureFileMessage = '';
    }

    this.user.saveAccountPhoto(accountDetail, event.target.files[0])
      .then(res => {
        this.displayAccountPicture(this.user.id);
      })
      .catch(err => {
        Log.error(err);
      });
  }

  removeAccountPhoto() {
    this.user.removeAccountPhoto(this.user.id)
      .then(res => {
        this.displayAccountPicture(this.user.id);
      })
      .catch(err => {
        Log.error(err);
      });
  }

  displayAccountPicture(account_id) {
    this.user.getAccountPhotoUrl(account_id)
      .then(accountPictureUrl => {
        this.imageUrl = '';
        this.uploadPictureFile.nativeElement.value = "";
        if (accountPictureUrl.length > 0) {
          // this.logger.debug(accountPictureUrl);
          this.imageUrl = accountPictureUrl;
        }
      })
      .catch(err => Log.error(err));
  }

}
