import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../common/services/user/user.service';
import { LoadingController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { States } from '../../../../constants/states';
import { Animations } from '../../../../constants/animations';
import { Log } from '../../../../common/services/logging/log.service';
import { CustomValidators } from '../../../../constants/validators';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
  animations: [Animations.enableDisable]
})
export class AccountComponent implements OnInit {
  accountForm: UntypedFormGroup;
  submitAttempt: boolean;
  error: string;
  stateList: Array<any>;
  stateOptions: any;
  edit: boolean;
  trim: (any) = CustomValidators.trim;
  formatPhone: (any) = CustomValidators.formatPhone;
  setPhoneField: (any) = CustomValidators.setPhoneField;
  clearPhoneField: (any) = CustomValidators.clearPhoneField;
  maskPhoneField: (any) = CustomValidators.maskPhoneField;
  setZipField: (any) = CustomValidators.setZipField;
  maskZipField: (any) = CustomValidators.maskZipField;
  clearZipField: (any) = CustomValidators.clearZipField;
  formatZip: (any) = CustomValidators.formatZip;

  constructor(private user: UserService, private loading: LoadingController, public formBuilder: UntypedFormBuilder) {
    this.submitAttempt = false;
    this.error = null;
    this.stateList = States.list;
    this.edit = false;

    this.accountForm = formBuilder.group({
      first: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      last: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      phone: ['', Validators.compose([
        Validators.minLength(10),
        Validators.maxLength(25)
      ])
      ],
      company: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      addressOne: ['', Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(50)
      ])
      ],
      addressTwo: ['', Validators.compose([
        Validators.maxLength(50)
      ])
      ],
      city: ['', Validators.compose([
        Validators.minLength(2),
        Validators.maxLength(50)
      ])
      ],
      state: [''],
      zip: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d{5}$|^\d{5}-$|^\d{5}-[_]{4}$|^\d{5}-\d{4}$/),
          Validators.minLength(5),
          Validators.maxLength(10),
        ]),
      ],
    });
  }

  ngOnInit() {
    this.user.getAccountDetails().subscribe((data: any) => {
      const update = {
        first: data.fname,
        last: data.lname,
        phone: data.phone,
        company: data.company,
        addressOne: data.street_line1,
        addressTwo: data.street_line2,
        city: data.city,
        state: data.state,
        zip: data.postal_code
      };
      this.accountForm.setValue(update);
    });
  }

  editDetails() {
    this.edit = true;
  }

  async submit() {
    this.submitAttempt = true;
    this.edit = false;
    const obj = {
      fname: this.accountForm.value.first,
      lname: this.accountForm.value.last,
      phone: this.accountForm.value.phone,
      company: this.accountForm.value.company,
      street_line1: this.accountForm.value.addressOne,
      street_line2: this.accountForm.value.addressTwo,
      city: this.accountForm.value.city,
      state: this.accountForm.value.state,
      postal_code: this.accountForm.value.zip,
      country: ''
    };
    const load = await this.loading.create({
      spinner: 'lines',
      message: 'Saving account details...'
    });
    load.present();
    this.user.setAccountDetails(obj)
      .subscribe(data => {
        load.dismiss();
      },
        error => {
          load.dismiss();
        });
  }

  uploadAvatar(event) {
    this.user.setAvatar(event.target.files[0])
      .then(res => Log.debug(res))
      .catch(err => Log.error(err));
  }
}
