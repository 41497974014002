<ion-grid class="weatherGrid {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? 'fahrenheitWeather' : 'celsiusWeather' }} {{ siteService?.weatherDataError ? 'weatherUnavail' : '' }}">
    <div class="weatherInfo wSpinner slim ion-padding-horizontal" *ngIf="!siteService?.weatherDataLoaded && !siteService?.weatherDataError || siteService?.weatherDataLoading && !siteService?.weatherDataError">
        <ion-spinner name="dots"></ion-spinner> {{ user.active.city }} Weather Loading
    </div>
    <div *ngIf="isWeatherObject(siteService?.weatherData) && !siteService?.weatherDataLoading && !siteService?.weatherDataError && siteService?.weatherDataLoaded && siteService?.isConnectedToInternet && siteService?.weatherData != null; else weatherError" (click)="weatherClick($event)" [title]="siteService?.weatherData?.siteCondition" class="weatherInfo slim ion-padding-horizontal">
        <div *ngIf="siteService?.weatherDataError == false" class="weatherLeft">
            <div class="weatherIconImage"><img class="weatherConditionIcon" alt="Weather Condition" [src]="siteService?.getConditionIcon(siteService?.weatherData)" /></div>
            <div class="weatherMiddle">
                <div class="weatherMinMax" title="{{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitMax : siteService?.weatherData?.celsiusMax }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }} | {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitMin : siteService?.weatherData?.celsiusMin }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}">
                    {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitMax : siteService?.weatherData?.celsiusMax }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }} <span class="seperator">|</span> {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitMin : siteService?.weatherData?.celsiusMin }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}
                </div>
                <!-- <div title="{{ user.active.city }}, {{ user.active.state }}" class="weatherCityState textOverflow">
                    {{ user.active.city }}, {{ user.active.state }}
                </div> -->
                <div title="{{ user?.active?.zip }}" class="weatherCityState textOverflow">
                    {{ user?.active?.zip }}
                </div>
                <!-- <div *ngIf="devEnv" class="weatherUpdatedTimestamp weatherCityState textOverflow">
                    {{ siteService?.weatherData?.lastUpdated }}
                </div> -->
            </div>
        </div>
        <span title="{{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDegrees : siteService?.weatherData?.celsiusDegrees }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}" *ngIf="siteService?.weatherDataError == false" class="weatherText weatherTemp">
            {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDegrees : siteService?.weatherData?.celsiusDegrees }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}
        </span>
        <!-- <div class="weatherMoreDetail">
            More Detail <ion-icon name="caret-down-sharp"></ion-icon>
        </div> -->
    </div>
    <!-- <div *ngIf="devEnv" class="weatherUpdatedTimestamp">
        Last Updated: {{ siteService?.weatherData?.lastUpdated }}
    </div> -->
    <ng-template #weatherError>
        <div title="Weather Unavailable" *ngIf="!siteService?.weatherDataLoading" class="weatherInfo slim ion-padding-horizontal">
            <!-- <img *ngIf="siteService?.isConnectedToInternet" class="weatherConditionIcon" alt="Weather Condition" src="/assets/icon/weatherIcons/colored/Sunny.svg" /> -->
            <div *ngIf="!siteService?.isConnectedToInternet" class="weatherErrorMessage">Weather Unavailable</div>
            <div *ngIf="siteService?.isConnectedToInternet && siteService?.weatherDataError" class="weatherErrorMessage">Weather unavailable for {{ user?.active?.zip }}</div>
            <!-- <div class="loadingDots">
                <ion-spinner name="dots"></ion-spinner>
            </div> -->
        </div>
    </ng-template>
</ion-grid>