/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from '../../services/site.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { GatewayUnit } from '../../../manage/components/classes/GatewayUnit';
import { GatewayUnitTwoDigitType, GatewayUnitTypeEnum, ModelState } from 'src/app/enumerations/enums';
import { GatewayGroup } from '../../../manage/components/classes/GatewayGroup';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { globalFunctions } from 'src/app/constants/globalFunctions';

@Component({
  selector: 'app-site-gateway-unit-edit',
  templateUrl: './site-gateway-unit-edit.page.html',
  styleUrls: ['./site-gateway-unit-edit.page.scss'],
})
export class SiteGatewayUnitEditPage implements OnInit {
  @Input() parentGateway: Gateway;
  @Input() parentGatewayUnit: GatewayUnit;
  @Input() parentGatewayGroups: GatewayGroup[];
  @Input() showConfigureUnits: boolean;

  unitType = "";
  selectedGatewayUnit: GatewayUnit;
  outdoorUnitEditForm: UntypedFormGroup;
  editResult: string;
  gatewayUnitTypeEnum = GatewayUnitTypeEnum;
  detectFormChanges = globalFunctions.detectFormChanges;
  gatewayUnitTwoDigitType = GatewayUnitTwoDigitType;
  gateway_groups: GatewayGroup[];
  saveEnabled = false;

  availModels: unknown;
  seriesList: Array<string> = [];
  modelsList: Array<string> = [];
  newSeries = "";
  newModel = "";

  locationOptions: Array<string> = [];
  filteredLocationOptions: Array<string> = [];

  default_gateway_group: GatewayGroup = {
    gateway_id: "",
    group_id: "",
    bus_address: "",
    group_name: 'No Group Assigned',
    created_at: null,
    last_modified: null,
    isExpanded: false,
    isDisabled: true,
    autocreated: true,
    number: null,
    location: "",
    units: [],
  }

  constructor(public modalController: ModalController,
    public user: UserService,
    public siteService: SiteService,
    public formBuilder: UntypedFormBuilder,
    public loadingController: LoadingController,) {
  }

  ngOnInit() {
    this.selectedGatewayUnit = this.parentGatewayUnit;
    this.displayGatewayUnit(this.selectedGatewayUnit);
    this.get_gateway_groups();
    this.gateway_groups.unshift(this.default_gateway_group);
    if (!this.gateway_groups.find(i => i.group_id == this.selectedGatewayUnit.group_id)) {
      // then it wasn't in the list - and its an autogenerated
      // make the default our selection
      this.default_gateway_group.group_id = this.selectedGatewayUnit.group_id;
    }

    this.createIndoorUnitEditForm();

    this.populateOutoorUnitEditForm(this.selectedGatewayUnit);

    this.siteService.getModelsList().then(resp => {
      // calling the backend to get the models list
      this.availModels = resp[`OU`];
      this.availModels[`series`].forEach(seriesObj => {
        this.seriesList.push(seriesObj[`name`]);
      });
      this.seriesList.sort();

      // set the series value and call the event handler function to set the model if possible
      this.outdoorUnitEditForm.get(`unit_series`).enable();
      
      if(this.selectedGatewayUnit.series) {
        this.outdoorUnitEditForm.get(`unit_model`).enable();
        this.outdoorUnitEditForm.get(`unit_series`).setValue(this.selectedGatewayUnit.series);
        this.seriesSelected(null); // setup the models list and then set it
        if(this.selectedGatewayUnit.model) {
          this.outdoorUnitEditForm.get(`unit_model`).setValue(this.selectedGatewayUnit.model);  
        } else {
          this.outdoorUnitEditForm.get(`unit_model`).setValue(``);  
        }
      } else {
        this.outdoorUnitEditForm.get(`unit_series`).setValue(``);
      }
      
    });

    if ((!this.selectedGatewayUnit.group_id) || (this.gateway_groups.find(gg => gg.group_id == this.selectedGatewayUnit.group_id) == undefined)) {
      this.selectedGatewayUnit.group_id = ``;
    }

    this.locationOptions = this.user.active.locations
    this.filteredLocationOptions = this.locationOptions
    this.outdoorUnitEditForm.controls.location.valueChanges.subscribe(val => {
      // filter the list of options to present.
      if (val) this.filteredLocationOptions = this._filter(val);
      else this.filteredLocationOptions = this.locationOptions;
    })
    this.outdoorUnitEditForm.controls.group_id.valueChanges.subscribe(val => {
      // find the group object for this group_id
      const selected_group = this.gateway_groups.find(grp => grp.group_id == val);
      const locationEnabled = selected_group.autocreated;
      if (locationEnabled) this.outdoorUnitEditForm.controls.location.enable();
      else { 
        // adopt the groups value
        this.outdoorUnitEditForm.controls.location.setValue(selected_group.location);
        // can't edit the field.        
        this.outdoorUnitEditForm.controls.location.disable();
 
      }
    })
  }

  _filter(val: any) {
    // filter the option list based on keyboard input
    return this.locationOptions.filter(location => location.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  // If we stop making the dropdown ugly and go back to ion-select instead of select change `event.target.value` 
  // to `event.detail.value` for the ionChange event
  seriesSelected(event) {
    let eventChange = true;
    if(!event) {
      event = {
        target: {
          value: this.selectedGatewayUnit.series
        }
      }
      eventChange = false;
    } else {
      // series change so enable save button
      this.saveEnabled = true;
    }

    this.newSeries = event.target.value;

    this.newModel = '';
    if(event.target && event.target.value == '') { // unknown
      // reset model selection
      this.modelsList = [];
      this.outdoorUnitEditForm.get('unit_model').disable();
      this.outdoorUnitEditForm.get('unit_model').setValue('');
    } else {
      for (let i = 0; i < this.availModels['series'].length; i++) {
        const series = this.availModels['series'][i];
        if (series['name'] == event.target.value) {
          this.modelsList = series['models'];
          this.modelsList.sort()

          this.outdoorUnitEditForm.get('unit_model').enable();
          if(eventChange) {
            this.outdoorUnitEditForm.get('unit_model').setValue('');
          } else {
            this.outdoorUnitEditForm.get('unit_model').setValue(this.selectedGatewayUnit.model);
          }
        }
      }
    }
  }

  
  modelSelected(event) {
    this.newModel = event.target.value;
    this.saveEnabled = true;
  }

  get_gateway_groups() {

    if ((this.parentGatewayGroups) && this.parentGatewayGroups.length > 0) {
      this.gateway_groups = this.parentGatewayGroups.map(gateway_group => {
        const g_grp: GatewayGroup = new GatewayGroup();
        g_grp.gateway_id = gateway_group.gateway_id;
        g_grp.group_id = gateway_group.group_id;
        g_grp.group_name = gateway_group.group_name;
        g_grp.created_at = gateway_group.created_at;
        g_grp.last_modified = gateway_group.last_modified;
        g_grp.isExpanded = false;
        g_grp.isDisabled = false;
        g_grp.autocreated = gateway_group.autocreated;
        g_grp.location = gateway_group.location;
        return g_grp;
      });
      this.gateway_groups = this.gateway_groups.filter(g => g.autocreated == false);
    }
  }

  displayGatewayUnit(gatewayUnit: GatewayUnit) {

    switch (gatewayUnit.unit_type) {
      case GatewayUnitTypeEnum.IndoorUnit:
        this.unitType = "Indoor";
        break;

      case GatewayUnitTypeEnum.OutdoorUnit:
        this.unitType = "Outdoor";
        break;

      case GatewayUnitTypeEnum.Lossnay:
        this.unitType = "Lossnay";
        break;
    }
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  createIndoorUnitEditForm() {

    // location should be disabled if we are on any group but the autogenerated one
    const selected_group = this.gateway_groups.find(i => i.group_id == this.selectedGatewayUnit.group_id)

    this.outdoorUnitEditForm = this.formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/(\S+\s*){3,}/)
      ]),
      ],
      type_name: [{ value: '', disabled: true }],
      bus_address: [{ value: '', disabled: true }],
      group_id: [{ value: '' }],
      unit_series: [{ value: '', disabled: true }],
      unit_model: [{ value: '', disabled: true }],
      location: [{value:'', disabled: !selected_group.autocreated}]
    })

    this.outdoorUnitEditForm.get("group_id").setValue("");
  }

  populateOutoorUnitEditForm(outdoorUnit) {
    const outdoorUnitData = {
      name: outdoorUnit.name,
      type_name: outdoorUnit.type_name,
      bus_address: outdoorUnit.bus_address,
      group_id: outdoorUnit.group_id,
      unit_series: outdoorUnit.series ? outdoorUnit.series : '',
      unit_model: outdoorUnit.model ? outdoorUnit.model : '',
      location: outdoorUnit.location
    }
    this.outdoorUnitEditForm.setValue(outdoorUnitData);
    this.detectFormChanges(this.outdoorUnitEditForm);
  }

  async editUnit() {

    if (!this.siteService.handleIsConnected())
      return;

    const loading = await this.loadingController.create({
      message: "Saving unit information...",
      spinner: 'lines',
    });

    await loading.present();

    this.selectedGatewayUnit.series = this.newSeries;
    this.selectedGatewayUnit.model = this.newModel;

    const unitUpdateRequest = {
      'gateway_id': this.selectedGatewayUnit.gateway_id,
      'site_id': this.parentGateway.site_id,
      'unit': {
        'id': this.selectedGatewayUnit.id,
        'name': this.outdoorUnitEditForm.value.name,
        'group_id': this.outdoorUnitEditForm.value.group_id,
        'series': this.outdoorUnitEditForm.value.unit_series ? this.outdoorUnitEditForm.value.unit_series : null,
        'model': this.outdoorUnitEditForm.value.unit_model ? this.outdoorUnitEditForm.value.unit_model : null,
        'location': this.outdoorUnitEditForm.value.location ? this.outdoorUnitEditForm.value.location : null
      }
    }

    await this.siteService.updateGatewayUnit(unitUpdateRequest).toPromise().then((result) => {
      this.selectedGatewayUnit.name = result['name'];
      this.selectedGatewayUnit.location = result['location'];
      this.selectedGatewayUnit.site_id = unitUpdateRequest.site_id;
      // update location in the user site list
      this.user.updateSiteLocations(this.user.active.id, result['site_locations'])
      this.siteService.emit_siteGatewayUnitChanged(this.selectedGatewayUnit);

      this.modalController.dismiss({
        edited: true,
        editedUnit: this.selectedGatewayUnit,
      });

    }).catch(() => {
      let errorMessage = `Unable to edit unit...`;

      switch (this.unitType.toUpperCase()) {
        case `INDOOR`:
          errorMessage = `Unable to edit indoor unit.`;
          break;

        case `OUTDOOR`:
          errorMessage = `Unable to edit outdoor unit.`;
          break;
      }
    }).finally(() => {
      loading.dismiss();
    });
  }

  cancelEdit() {
    if (this.showConfigureUnits) {
      this.siteService.emit_siteGatewayGroupCRUDEvent(ModelState.Updated);
    }
    this.modalController.dismiss({
      edited: false,
      editedUnit: null,
    });
  }
}
