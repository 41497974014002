import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from '../../services/site.service';
import { Site } from '../../classes/site';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { AccountService } from 'src/app/features/account/services/accountService';

@Component({
  selector: 'app-site-gateway-move',
  templateUrl: './site-gateway-move.page.html',
  styleUrls: ['./site-gateway-move.page.scss'],
})
export class SiteGatewayMovePage implements OnInit {
  @Input() parentSelectedGateway: Gateway;
  selectedGateway: Gateway = null;
  siteSelected: boolean = false;
  selectedSite: Site = null;
  ownersSites: Site[] = [];
  moveResult: string = '';

  displayZeroState = true;
  backButtonIsHidden = true;

  zeroStateStyle = "md-0state";
  siteSelectStyle = "md-siteselect";

  moveError: string = ``;

  constructor(
    public modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    public user: UserService,
    public siteService: SiteService,
    public loadingController: LoadingController,
    public accountService: AccountService
  ) {
    // Empty
  }


  ngOnInit() {
    this.selectedGateway = this.parentSelectedGateway;
    this.getAccountSitesByLevel(this.user.id, 4);
  }

  getAccountSitesByLevel(account_id: string, level: number) {
    this.siteService.getAccountSitesByLevel(account_id, level).subscribe(
      (unsortedOwnerSiteObjs: []) => {
        let unsortedOwnersSites: Site[] = unsortedOwnerSiteObjs.map(site_obj => new Site(site_obj))
        unsortedOwnersSites = unsortedOwnersSites.filter(
          (site: Site) => site.id !== this.user.active.id
        );
        this.ownersSites = unsortedOwnersSites.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.ownersSites.unshift(new Site({
          id: ``,
          name: `Select a Site`,
          phone: `Not Applicable`,
          company: `Select a Site`,
          addressOne: `Not Applicable`,
          addressTwo: `Not Applicable`,
          city: `Not Applicable`,
          state: `Not Applicable`,
          zip: `Not Applicable`,
          country: `Not Applicable`,
          site_photo_name: `site_photo_name`,
          sitestatustype_id: 1,
          current_owner_id: ``,
          locations: []
        }));

        this.ownersSites = this.ownersSites.filter(i => i.transfer_locked != true);
      },
      (err) => {
        console.log(`getAccountSitesByLevel=>err`, err);
      }
    );
  }

  onOwnerSiteSelected(event) {
    if (event && event.target && event.target) {
      this.selectedSite = this.ownersSites.find(
        (site) => site.id === event.target.value
      );

      if (
        this.selectedSite !== null &&
        this.selectedSite !== undefined &&
        this.selectedSite.id !== ''
      ) {
        this.siteSelected = true;
      } else {
        this.siteSelected = false;
      }
    }
  }

  cancelMove() {
    this.modalController.dismiss({
      moved: false,
      movedGateway: null,
      movedFromSite: null,
      movedToSite: null,
    });
  }

  async moveGateway() {
    this.moveError = '';
    if (!this.siteService.handleIsConnected()) return;

    const loading = await this.loadingController.create({
      message: 'Moving gateway...',
      spinner: 'lines',
    });

    await loading.present();

    const params = {
      last_modified_account_id: this.user.id,
      move_from_site_id: this.user.active.id,
      move_to_site_id: this.selectedSite.id,
      gateway_id: this.selectedGateway.id,
      skip_payment_check: false,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    await this.siteService
      .moveGateway(
        params
      )
      .toPromise()
      .then((result) => {

        if ('error' in result) {
          // error handling
          this.moveError = <string>result['error'];
        }
        else if ('message' in result) {
          if (result['message'] == "display payment warning") {
            this.modalController.dismiss({
              moved: false,
              displayPaymentWarning: true,
              cardType: result['card']['type'],
              cardLastFour: result['card']['lastFour'],
              movedToSite: this.selectedSite,
              needsNewPayment: false
            });

          } else if (result['message'] == "display payment input form") {
            this.modalController.dismiss({
              moved: false,
              displayPaymentWarning: true,
              movedToSite: this.selectedSite,
              needsNewPayment: true

            });
          } else { // Gateway Move Success
            this.selectedGateway.site_id = this.selectedSite.id;
            // update locations in the user site list
            this.user.updateSiteLocations(this.user.active.id, result['from_site_locations']);
            this.user.updateSiteLocations(this.selectedSite.id, result['to_site_locations']);
            // update locations in the current object in this component
            this.selectedSite.update_locations(result['to_site_locations']);
            this.siteService.siteEdited.emit({ type: `Refresh Notifications` });  
            this.accountService.subscriptionDetailsUpdated = true;       

            this.modalController.dismiss({
              moved: true,
              movedGateway: this.selectedGateway,
              movedFromSite: this.user.active,
              movedToSite: this.selectedSite,
            });
          }
        }
      })
      .catch((error) => {
        if (error.message){
          this.moveError = `Error: ${error.message}`;
        }
      })
      .finally(() => {
        loading.dismiss();
      });
  }

  onClose() {
    this.modalController.dismiss({
      saved: false,
      savedGateway: null,
    });
  }

  goNext() {
    this.moveError = '';
    this.displayZeroState = false;
    this.backButtonIsHidden = false;

    let moveGatewayGridDOMObject = document.getElementById("moveGatewayGrid");
    let currClass = moveGatewayGridDOMObject.getAttribute("class");
    currClass = currClass.replace(this.zeroStateStyle, "")
    moveGatewayGridDOMObject.setAttribute("class", `${currClass} ${this.siteSelectStyle}`);
  }

  goBack() {
    this.moveError = '';
    this.displayZeroState = true;
    this.backButtonIsHidden = true;
    this.siteSelected = false;

    let moveGatewayGridDOMObject = document.getElementById("moveGatewayGrid");
    let currClass = moveGatewayGridDOMObject.getAttribute("class");
    currClass = currClass.replace(this.siteSelectStyle, "")
    moveGatewayGridDOMObject.setAttribute("class", `${currClass} ${this.zeroStateStyle}`);
  }
}
