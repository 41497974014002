import { Component, OnInit, ViewChild } from '@angular/core';
import { GatewayChangePaymentMethodStepEnum, GatewayChangeSubscriptionStepEnum, GatewaySubscriptionStatusTypeEnum } from 'src/app/enumerations/enums';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ModalController, AlertController, NavParams, LoadingController } from '@ionic/angular';
import { Logger } from 'src/app/common/services/logging/log.service';
import { SiteGatewaySubscriptionPlan } from '../../classes/SiteGatewaySubscriptionPlan';
import { Subscription } from 'src/app/features/manage/components/classes/Subscription';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { PaymentMethod } from '../../classes/PaymentMethod';
import { CustomValidators } from 'src/app/constants/validators';
import { AccountService } from '../../services/accountService';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { isProductionEnvironment } from 'src/app/common/services/feature/feature.service';
import { CreditCardFormComponent } from 'src/app/common/components/credit-card-form/credit-card-form.component';
import {License} from "../../classes/License";
import {GetAccountPaymentResult} from "../../classes/HttpResult";

@Component({
  selector: 'app-account-subscription-change-payment-method',
  templateUrl: './account-subscription-change-payment-method.component.html',
  styleUrls: ['./account-subscription-change-payment-method.component.scss'],
})
export class AccountSubscriptionChangePaymentMethodComponent implements OnInit {
  @ViewChild(CreditCardFormComponent) ccForm: CreditCardFormComponent;
  //navigation
  stepUILayouts = [{
    stepIndex: GatewayChangePaymentMethodStepEnum.InformationStep,
    stepTitle: 'Change Payment Method',
    showBackButton: false,
    showNextButton: true,
    showCancelButton: true,
    showCloseButton: false,
    nextButtonText: 'Next',
  }, {
    stepIndex: GatewayChangePaymentMethodStepEnum.GatewayStep,
    stepTitle: 'Change Payment Method (Step 1 of 3)',
    showBackButton: true,
    showNextButton: true,
    showCancelButton: true,
    showCloseButton: false,
    nextButtonText: 'Next',
  }, {
    stepIndex: GatewayChangePaymentMethodStepEnum.ChangeStep,
    stepTitle: 'Change Payment Method (Step 2 of 3)',
    showBackButton: true,
    showNextButton: true,
    showCancelButton: true,
    showCloseButton: false,
    nextButtonText: 'Change',
  }, {
    stepIndex: GatewayChangePaymentMethodStepEnum.SummaryStep,
    stepTitle: 'Change Payment Method (Step 3 of 3)',
    showBackButton: false,
    showNextButton: false,
    showCancelButton: false,
    showCloseButton: true,
    nextButtonText: 'Next',
  }];
  currentStepUILayout: any = null;
  subscriptionPlans: SiteGatewaySubscriptionPlan[] = [];
  gatewayChangePaymentMethodStepEnum: any;

  //payment
  calculatedSubscriptionTotal: any = 0;
  calculatedNewSubscriptionTotal: any = 0;
  paymentMethods: PaymentMethod[] = [];
  submitAttempt = false;
  formatExpiry: any = CustomValidators.formatExpiration;
  paymentError = false;
  paymentErrorString = '';
  registrationError = false;
  disableChangeButton = false;
  paymentMethodToChange: any;
  affectedDevices: any;

    // auto process the change after get get a new token
    autoProcessAfterToken = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public navParams: NavParams,
    private accountService: AccountService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private logger: Logger,
    private modalController: ModalController,
    private planService: SubscriptionPlanService,
    private siteService: SiteService,
  ) {
    this.gatewayChangePaymentMethodStepEnum = GatewayChangePaymentMethodStepEnum;
    this.paymentMethodToChange = navParams.get('paymentMethod');
  }

  ngOnInit() {
    this.handleNavigationUISetup(GatewayChangePaymentMethodStepEnum.InformationStep);
    this.planService.getPlans();

    this.accountService
      .getAccountPaymentMethodsSub()
      .subscribe({next: (result: GetAccountPaymentResult) => {
        this.paymentMethods = result.pmt_methods || [];
        this.paymentMethods.sort((a, b) =>
          (a.card_type + a.last_four).localeCompare(b.card_type + b.last_four));
      }, error: () => this.paymentMethods = []});

    this.accountService
      .getOwnedGatewaysSub()
      .subscribe((data: any) => {
        const site = data.find((site) => site.site_id === this.paymentMethodToChange.site_id);
        this.affectedDevices = [];
        if (site.gateways.length) {
          this.affectedDevices = site.gateways.filter((gw) => gw?.gatewaysubscription?.length).filter((gw) => {
            const sub = gw?.gatewaysubscription?.find(sub =>
              [GatewaySubscriptionStatusTypeEnum.Active,
               GatewaySubscriptionStatusTypeEnum.Suspended]
                  .includes(sub?.gateway_subscription_status_type_id));
            gw.gatewaysubscription = sub;
            return sub.subscriptions.monthly_rate > 0;
          });
        }
      });
  }

  getParams() {
    return {};
  }

  creditCardStatusDidChange(event) {
    this.disableChangeButton = !event.valid;
    this.paymentErrorString = ``;
    this.paymentError = false;

    if (event.valid) {
      this.getParams = function () {
        return {
          token_id: event.token,
          customer_info: event.customerForm,
          tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      };
    } else {
      this.getParams = function () { return {} };
    }

    if (this.autoProcessAfterToken) {
      // then continue the change process
      this.changePaymentMethod()
    }
  }

  //navigation
  handleNavigationUISetup(gatewayChangePaymentMethodStepEnum: GatewayChangePaymentMethodStepEnum) {
    this.currentStepUILayout = this.stepUILayouts[gatewayChangePaymentMethodStepEnum];
    switch (this.currentStepUILayout.stepIndex) {
      case GatewayChangePaymentMethodStepEnum.InformationStep:
      case GatewayChangePaymentMethodStepEnum.GatewayStep:
        this.disableChangeButton = false;

        break;
      case GatewayChangePaymentMethodStepEnum.ChangeStep:
        this.disableChangeButton = true;

        break;
      case GatewayChangePaymentMethodStepEnum.SummaryStep:
        break;
    }
  }

  handleBackButton() {
    const nextStep = this.currentStepUILayout.stepIndex - 1;
    this.handleNavigationUISetup(nextStep);
  }

  handleNextButton() {
    let nextStep = 0;

    switch (this.currentStepUILayout.stepIndex) {
      case GatewayChangePaymentMethodStepEnum.ChangeStep:
        this.changePaymentMethod();

        break;
      case GatewayChangePaymentMethodStepEnum.GatewayStep:
        nextStep = this.currentStepUILayout.stepIndex + 1;
        this.handleNavigationUISetup(nextStep);

        break;
      default:
        nextStep = this.currentStepUILayout.stepIndex + 1;
        this.handleNavigationUISetup(nextStep);

        break;
    }
  }

  async handleCancelButton() {

    const alert = await this.alertController.create({
      header: 'Cancel Payment Method Change',
      message: '<div class="me-redClass">Are you sure you want to cancel?</div></br><div class="me-centerText">If you cancel, your information will not be saved.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.logger.debug('Cancellation confirmed.');
            this.modalController.dismiss();
          }
        }, {
          text: 'No',
          cssClass: 'back-button',
          handler: () => {
            this.logger.debug('Cancellation cancelled.');
          }
        }
      ]
    });

    await alert.present();
  }

  handleCloseButton() {
    if (this.currentStepUILayout.stepIndex == GatewayChangePaymentMethodStepEnum.SummaryStep) {
      this.modalController.dismiss({ requiresUpdate: true });

      return;
    }
  }

  async changePaymentMethod() {
    if (!this.siteService.handleIsConnected())
      return;


    if (this.ccForm && this.ccForm.tokenIsDirty) {
      // then we need to refresh the token
      this.autoProcessAfterToken = true
      this.ccForm.submitTokenRequest(true)
      return
    }

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Processing payment...'
    });

    load.present();

    this.submitAttempt = true;
    this.paymentError = false;
    this.paymentErrorString = '';

    const params = {
      site_id: this.paymentMethodToChange.site_id,
      ...this.getParams()
    };

    const response = await this.siteService.updatePaymentMethod(params);

    load.dismiss();

    if (response.error) {

      if ( !isProductionEnvironment() && ( response.error == 'The transaction was declined. Please use a different card or contact your bank.') ){
        // accept this card as ok - even though it threw an error
        // its used as part of failed payment processing.
        this.paymentError = false;
        this.paymentErrorString = ''
        this.disableChangeButton = false;
        this.handleNavigationUISetup(GatewayChangePaymentMethodStepEnum.SummaryStep);
      } else {
        this.paymentError = true;
        this.paymentErrorString = response.error;
        this.disableChangeButton = true;
      }
    } else {
      this.handleNavigationUISetup(GatewayChangePaymentMethodStepEnum.SummaryStep);
    }
  }

}
