
export class CustomHeader {

    public getExtraHeaders(accessToken: string) {

        let extraHeader = {
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: accessToken,
                    }
                }
            }
        };

        return JSON.stringify(extraHeader);
    }

}