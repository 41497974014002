<ion-content class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1>Site Members</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <ion-button
            *ngIf="!user.active.transfer_locked && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.AddRemoveMembers)"
            (click)="user.active.transfer_locked ? user.presentActionRestricted() : adminSiteMember(memberModelState.Create)"
            [class]="user.active.transfer_locked ? 'site-transfer-disable-modal' : ''"
            style="margin-right: 15px;text-transform:none" id="InviteAMemberBtn">
            Invite a Member</ion-button>
          <ion-button class="manageButton transferring"
            *ngIf="user.active.transfer_locked && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.AddRemoveMembers)"
            (click)="user.active.transfer_locked ? user.presentActionRestricted() : adminSiteMember(memberModelState.Create)">Invite a
            Member</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <div *ngIf="dataLoading">
    <ion-label id="zeroStateLoading" name="zeroStateLoading" style="font-size:20px; margin-left:20px;"><i>Loading</i>
    </ion-label>
    <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
  </div>
  <ion-grid>
    <ion-row>
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12" *ngFor="let member of memberList">
        <ion-card me-ion-card id="{{member.id}}">
          <ion-item lines="none" me-ion-card-header class="me-item-header">
            <ion-thumbnail *ngIf="!member.account_photo_url" slot="start" (click)="handleSiteMemberClick(member)">
              <ion-icon name="images" style="height:50px;width:50px"></ion-icon>
            </ion-thumbnail>
            <ion-thumbnail *ngIf="member.account_photo_url" slot="start" (click)="handleSiteMemberClick(member)"
              class="me-thumbnail-img">
              <img [src]="member.account_photo_url" id="uploadAccountPicture" name="uploadAccountPicture"
                color="primary">
            </ion-thumbnail>
            <ion-label class="memberCardLabels confirmedMemberCardLabels automationTesting ion-padding me-ion-card-label" (click)="handleSiteMemberClick(member)"
              style="cursor: pointer">
              <strong class="memberUsernameOrEmail confirmedMemberUsernameOrEmail automationTesting {{ member.id }} {{ member.email }}">{{!member.fname && !member.lname ? member.email : ''}}{{member.fname ? member.fname : ''}}
                {{member.lname ? member.lname : ''}}</strong><br>
              <div class="memberRole confirmedMemberRole memberIs{{ member.levelDisplayText }} automationTesting" me-ion-card-sub-title>{{ member.levelDisplayText }}</div>
            </ion-label>
            <div class="confirmedMemberIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" (click)="toggleMemberInfo(member)"
              [title]="getIsMemberExpanded(member) ? 'Collapse' : 'Expand'">
              <ion-icon id="toggleMemberCard" [name]="getIsMemberExpanded(member) ? 'caret-up-sharp' : 'caret-down-sharp'" class="memberIcon confirmedMemberIcon automationTesting" pointer></ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content *ngIf="getIsMemberExpanded(member)" me-top-border
            style="padding-left: 20px; padding-bottom: 24px;">
            <ion-grid class="confirmedMemberDetails ion-no-padding">
              <ion-row white-background>
                <ion-col size="4"><strong>Email:</strong></ion-col>
                <ion-col class="memberEmail confirmedMemberEmail automationTesting">{{ member.email }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Phone:</strong></ion-col>
                <ion-col class="memberPhone confirmedMemberPhone automationTesting">{{ member.phone }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Company:</strong></ion-col>
                <ion-col class="memberCompany confirmedMemberCompany automationTesting">{{ member.company }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Site Role:</strong></ion-col>
                <ion-col class="memberSiteRole confirmedMemberSiteRole automationTesting">{{ member.levelDisplayText }}</ion-col>
              </ion-row>
            </ion-grid>
            <ion-toolbar class="ion-no-padding"
              *ngIf="appAuth.getChangeableRoleList(user.activeSiteUserLevel,member.level).length > 0 || siteAuthorizationSrvice.permissionCheckCanRemoveSiteMember(user.activeSiteUserLevel,member.level) || memberIsMeAndNotOwner(member)">
              <ion-buttons slot="end">
                <ion-button *ngIf="appAuth.getChangeableRoleList(user.activeSiteUserLevel,member.level).length > 0"
                  (click)="user.active.transfer_locked ? user.presentActionRestricted() : openSiteMemberDetailAdminPage(user.active.id,user.active.name,member)" pointer
                  id="btnChangeRole" title="Change Role" [ngClass]="user.active.transfer_locked ? 'site-transfer-disable-modal': ''">
                  <ion-icon name="people" size="large"></ion-icon>
                </ion-button>

                <ion-button id="btnRemoveMember" name="btnRemoveMember"
                  *ngIf="siteAuthorizationSrvice.permissionCheckCanRemoveSiteMember(user.activeSiteUserLevel,member.level) || memberIsMeAndNotOwner(member)"
                  (click)="user.active.transfer_locked ? user.presentActionRestricted() : uninviteMember(member, false)" pointer
                  title="Remove {{memberIsMe(member) ? 'Yourself' : 'Member'}}" [ngClass]="user.active.transfer_locked ? 'site-transfer-disable-modal': ''">
                  <ion-icon name="person-remove-sharp" id="remove-member" color="danger"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12" *ngFor="let member of inviteList">
        <ion-card me-ion-card id="{{member.id}}">
          <ion-item lines="none" me-ion-card-header class="me-item-header">
            <ion-thumbnail slot="start">
              <ion-icon *ngIf="!member.account_photo_url" name="images" style="height:50px;width:50px"></ion-icon>
              <img *ngIf="member.account_photo_url" me-img-account-detail [src]="member.account_photo_url"
                id="uploadAccountPicture" name="uploadAccountPicture" color="primary">
            </ion-thumbnail>
            <ion-label class="memberCardLabels pendingMemberCardLabels automationTesting ion-padding me-ion-card-label">
              <strong class="memberUsernameOrEmail pendingMemberUsernameOrEmail automationTesting {{ member.id }} {{ member.email }}">{{ member.email }}</strong><br>
              <div class="memberRoleOrPending pendingMemberRoleOrPending memberWillBe{{ levelDisplayText(member.level) }} automationTesting" me-ion-card-sub-title>Pending</div>
            </ion-label>
            <div class="pendingMemberIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" (click)="toggleMemberInfo(member)"
              [title]="getIsMemberExpanded(member) ? 'Collapse' : 'Expand'">
              <ion-icon id="togglePendingMemberCard" [name]="getIsMemberExpanded(member) ? 'caret-up-sharp' : 'caret-down-sharp'" class="memberIcon pendingMemberIcon automationTesting" pointer></ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content *ngIf="getIsMemberExpanded(member)" me-top-border>
            <ion-grid class="pendingMemberDetails ion-no-padding">
              <ion-row white-background>
                <ion-col size="4"><strong>Email:</strong></ion-col>
                <ion-col class="memberEmail pendingMemberEmail automationTesting">{{ member.email }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Site Role:</strong></ion-col>
                <ion-col class="memberSiteRole pendingMemberSiteRole automationTesting">{{ levelDisplayText(member.level) }}</ion-col>
              </ion-row>
            </ion-grid>
            <ion-toolbar
              *ngIf="siteAuthorizationSrvice.permissionCheckCanRemoveSiteMember(user.activeSiteUserLevel,member.level)">
              <ion-buttons slot="end">
                <ion-button id="btnRemoveInvitee" name="btnRemoveInvitee"
                  *ngIf="siteAuthorizationSrvice.permissionCheckCanRemoveSiteMember(user.activeSiteUserLevel,member.level)"
                  (click)="user.active.transfer_locked ? user.presentActionRestricted() : uninviteMember(member, true)" title="Remove Invitee" [ngClass]="user.active.transfer_locked ? 'site-transfer-disable-modal': ''">
                  <ion-icon name="person-remove-sharp" id="btnUninviteMember" color="danger"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-icon name="person-remove-sharp" id="loader-person-remove-sharp" me-do-not-show-loader-icon></ion-icon>
<ion-icon name="people" id="loader-people" me-do-not-show-loader-icon></ion-icon>