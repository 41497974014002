import { LevelEnum } from "src/app/enumerations/enums";

export class SiteAlertFilter {
    constructor(
        public site_id = '',
        public StatusActive = true,
        public AlertEquipment = true,
        public StatusHistorical = false,
        public NotificationMembership = true,
        public NotificationGateway = true,
        public NotificationSubscription = true,
        public NotificationPayments = true,
        public NotificationEquipment = true,
        public UserLevelEnum = LevelEnum.Unknown,
    ) { }
}
