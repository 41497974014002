/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AccountService } from '../../services/accountService';
import { UserService } from 'src/app/common/services/user/user.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { ModalController, AlertController } from '@ionic/angular';
import { AccountGatewayChangeSubscriptionPlanPage } from '../account-gateway-change-subscription-plan/account-gateway-change-subscription-plan.page';
import { GatewayModelClass, GatewaySubscriptionStatusTypeEnum, LevelEnum, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle } from 'src/app/enumerations/enums';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { AccountGatewayDecommissionComponent } from '../account-gateway-decommission/account-gateway-decommission.component';
import { forkJoin } from 'rxjs';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { date_time_utilities } from 'src/app/common/utilities/datetimeUtilities';
import { DateParts } from 'src/app/common/components/datetime/datedayandrangepicker/date-day-and-range-picker/date-day-and-range-picker.component';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { ActivatedRoute } from '@angular/router';
import { LIMITED_GW_WARNING, LICENSED_GW_MESSAGE, } from 'src/app/constants/kenzaconstants';
import {GetAccountPaymentResult} from "../../classes/HttpResult";

@Component({
  selector: 'app-account-gateway-subscription-plans',
  templateUrl: './account-gateway-subscription-plans.component.html',
  styleUrls: ['./account-gateway-subscription-plans.component.scss'],
})

export class AccountGatewaySubscriptionPlansComponent implements OnInit {
  @ViewChildren('siteRow') siteRows : QueryList<any>
  LIMITED_GW_WARNING = LIMITED_GW_WARNING;
  LICENSED_GW_MESSAGE = LICENSED_GW_MESSAGE;
  siteGateways: any[] = [];
  siteGatewaysLoading: boolean;
  transferLocked = false;

  gatewayModelClass = GatewayModelClass;
  devEnv = devEnv;

  preSelectedGatewayId = ``;

  GSSTE = GatewaySubscriptionStatusTypeEnum;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  checked_gateways = [];
  checked_gateway;
  selected_site;

  iconMaintenanceType = `maintenance`;

  constructor(
    public user: UserService,
    private accountService: AccountService,
    public mainSiteUIService: MainSiteUIService,
    private modalController: ModalController,
    public appAuth: AppAuthenticationService,
    private alertController: AlertController,
    private siteService: SiteService,
    private route: ActivatedRoute
  ) { 
    // Empty
  }

  ngOnInit() {
    let siteIsTransferring = this.user && this.user.active && this.user.active.transfer_locked;
    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }
    this.getAccountOwnerGateways();
  }

  ionViewDidEnter() {
    // preselect something?
    this.route.queryParams.subscribe(params => {
      this.preSelectedGatewayId = `upgrade` in params ? params.upgrade : ``;
      // don't like `upgrade` being in the URL if alrady on a standard+ plan
      if (this.preSelectedGatewayId == ``) {
        this.preSelectedGatewayId = `modify` in params ? params.modify : ``;
      }
      this.cleanupSelectedGateway();
      this.scrollToPreselectedGateway();
      if (this.accountService.subscriptionDetailsUpdated) {
        this.getAccountOwnerGateways();
        this.accountService.subscriptionDetailsUpdated = false;
      }      
    });
  }

  getAccountOwnerGateways() {
    this.siteGatewaysLoading = true;
    forkJoin({
      sites: this.accountService.getOwnedGateways(),
      paymentMethods: this.accountService.getAccountPaymentMethods().then((result: GetAccountPaymentResult) => result.pmt_methods || []),
    }).subscribe(this.prepareSiteGatewaySubscriptionPlans.bind(this));
  }

  prepareSiteGatewaySubscriptionPlans(accountData) {
    this.siteGateways = accountData.sites
      .sort(compareValues(`site_name`, `asc`))
      .reduce((acc, site) => {
        if (site.gateways.length) {
          const gw_list: Gateway[] = [];
          site.gateways
            .sort(compareValues(`name`, `asc`))
            .forEach(gw => { gw_list.push(Gateway.newFromGateway(gw, site.site_id)) });
          site.paymentMethod = accountData.paymentMethods.find(pm => pm.site_id === site.site_id);
          site.nextPayment = this.firstOfNextMonth();
          site.gateways = gw_list;
          acc.push(site);
        }

        return acc;
      }, []);
    this.siteGatewaysLoading = false;

    devEnv && console.log(`Site Gateways Subscription Plans`, this.siteGateways);
    this.scrollToPreselectedGateway();
  }

  isActionRestricted() {
    let jobTitleIndex = MaintenanceJobTypeEnum.Default;
    let siteIsTransferring = this.transferLocked;
    let gatewayIsPerformingMaintenance = this.checked_gateway ? this.checked_gateway.maintenance_job != null : false;
    let gatewaysPerformingMFK = this.checked_gateways.length > 0 ? this.checked_gateways.filter(sgw => sgw.gateways.some(gw => gw.mfk_status != null)) : [];
    let gatewaysPerformingMaintenance = this.checked_gateways.length > 0 ? this.checked_gateways.filter(sgw => sgw.gateways.some(gw => gw.maintenance_job != null)) : [];

    if (gatewayIsPerformingMaintenance) {
      jobTitleIndex = this.checked_gateway.maintenance_job.job_id;
    } 
    
    if (gatewaysPerformingMFK.length > 0) {
      if (gatewaysPerformingMFK.length > 1) {
        jobTitleIndex = MaintenanceJobTypeEnum.Download_Drive_Data;
      } else {
        jobTitleIndex = this.checked_gateway.mfk_status.job_id;
      }
    }

    if (siteIsTransferring) {
      jobTitleIndex = MaintenanceJobTypeEnum.Site_Transfer;
    }

    let maintType = MaintenanceJobTypeEnumTitle[jobTitleIndex];
    let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
    this.iconMaintenanceType = maintTypeToUse.toLowerCase();

    let actionIsRestricted = siteIsTransferring 
                             || gatewayIsPerformingMaintenance 
                             || gatewaysPerformingMaintenance.length > 0 
                             || gatewaysPerformingMFK.length > 0;

    return actionIsRestricted;
  }
  
  scrollToPreselectedGateway() {
    if (this.preSelectedGatewayId) {
      // find this gateway - set a timer to select it.
      this.siteGateways.forEach((site) => {
        // walk all gateways on site
        site.gateways.forEach((gw) => {
          if (gw.id == this.preSelectedGatewayId) {
            // this is the one to select.
            gw.isSelected = true;
            setTimeout(() => {
              this.on_gateway_checked(gw);
              // try to scroll screen to this site.
              const site_id = gw.site_id;
              const element = this.siteRows.find( (item) => ( item.el.id == site_id))
              if (element) element.el.scrollIntoView({ behavior: 'smooth' })
            }, 500);            
          }
        })
      })
    }
  }

  on_gateway_checked(checked_gateway) {
    const selected_site = this.siteGateways.find(sgw => sgw.gateways.filter(gw => gw.id === checked_gateway.id).length > 0);
    const count_of_selected_gateways: number = this.get_count_of_selected_gateways();
    this.selected_site = selected_site;
    this.checked_gateway = checked_gateway;

    if (count_of_selected_gateways == 0) {
      setTimeout(() => {
        this.enable_all_gateways(checked_gateway.id);
        this.transferLocked = false;
      }, 100);
    } else {

      setTimeout(() => {
        this.disable_gateways_not_in_site(selected_site.site_id, checked_gateway.id);
        // need to disable the buttons if the site is locked because of transfer
        if (selected_site.transfer_locked) {
          this.transferLocked = true;
        }

      }, 100);
    }
  }

  get_count_of_selected_gateways() {
    let count_of_selected_gateways = 0;
    this.checked_gateways = this.siteGateways.filter(sgw => sgw.gateways.some(gw => gw.isSelected === true));
    devEnv && console.log(`Checked Gateways`, this.checked_gateways);
    this.siteGateways.forEach(sgw => {
      count_of_selected_gateways = count_of_selected_gateways + sgw.gateways.filter(gw => gw.isSelected === true).length;
    });
    return count_of_selected_gateways;
  }

  enable_all_gateways(exclude_gateway_id: string) {
    this.siteGateways = this.siteGateways.map(sgw => {
      sgw.isDisabled = false;
      sgw.gateways = sgw.gateways.map(gw => {
        if (gw.id !== exclude_gateway_id) {
          gw.isDisabled = false;
        }
        return gw;
      });
      return sgw;
    });
  }

  disable_gateways_not_in_site(site_id: string, exclude_gateway_id: string) {
    this.siteGateways = this.siteGateways.map(sgw => {
      if (sgw.site_id !== site_id) {
        sgw.isDisabled = true;
      }
      sgw.gateways = sgw.gateways.map(gw => {
        if ((sgw.site_id !== site_id) && (gw.id !== exclude_gateway_id)) {
          gw.isDisabled = true;
        }
        return gw;
      });
      return sgw;
    });
  }

  firstOfNextMonth() {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return next.toLocaleDateString('en', { month: '2-digit', day: '2-digit', year: 'numeric' });
  }

  toggleExpandGateway(sgw) {
    sgw.isExpanded = !sgw.isExpanded;
  }

  setGatewaySelected(setValueTo: boolean) {
    this.siteGateways = this.siteGateways.map(site => {
      site.isSelected = setValueTo;
      return site;
    })
  }

  toggleGatewaySelected(siteGateway) {
    siteGateway.isSelected = !siteGateway.isSelected;
  }

  returnToAccountDetail() {
    if (!this.siteService.handleIsConnected())
      return;

    this.mainSiteUIService.viewAccountDetail();
  }

  validate_selected_plans(gateway_details: any): boolean {
    let are_plans_valid = true;

    gateway_details.forEach(gateway_detail => {
      if (this.validate_plans_expires_at(gateway_detail) == false)
        are_plans_valid = false;
    });

    return are_plans_valid;
  }

  validate_plans_expires_at(gateway_detail: any): boolean {
    let result = true;

    if (
      (!gateway_detail) ||
      (!gateway_detail?.gatewaysubscription) ||
      (gateway_detail?.gatewaysubscription?.length == 0)) {
      return false;
    }

    const todays_date_parts: DateParts = date_time_utilities.get_date_parts_from_date(new Date());
    const todays_compare_date: Date = new Date(todays_date_parts.date_string + `T00:00:00`);
    const subscriptions_with_expired_at = gateway_detail?.gatewaysubscription?.filter(sub => (sub?.expires_at !== null));

    subscriptions_with_expired_at.forEach(sub => {
      const plans_expired_at_date_parts: DateParts = date_time_utilities.get_date_parts_from_date(new Date(sub.expires_at + `T00:00:00`));
      const plans_expired_at_date_parts_compare_date: Date = new Date(plans_expired_at_date_parts.date_string + `T00:00:00`);
      if (date_time_utilities.calculate_date_diff(todays_compare_date, plans_expired_at_date_parts_compare_date) <= 0) {
        result = false;
      }
    });

    return result;
  }

  async changeSubscriptionPlan() {
    if (!this.siteService.handleIsConnected()) return;

    const subscriptionPlans = [];

    this.siteGateways.forEach(sgw => {
      sgw.gateways.forEach(gw => {
        const { site_id, ...gwl } = gw; // this takes the site_id out of gw
        if (gw.isSelected) subscriptionPlans.push({
          site_name: sgw.site_name,
          site_id: sgw.site_id,
          ...gwl
        });
      });
    });

    if (!subscriptionPlans.length) {
      this.show_message('Change Subscription Plan', 'Please select a gateway');
      return;
    }

    if (this.validate_selected_plans(subscriptionPlans) == false) {
      this.show_message('Change Subscription Plan', 'One of the gateways you selected has already changed from a Standard to a Limited subscription this month.  To upgrade back to a Standard subscription, please wait until the beginning of the following month.');
      return false;
    }

    const modal = await this.modalController.create({
      component: AccountGatewayChangeSubscriptionPlanPage,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',

      backdropDismiss: false,
      componentProps: { subscriptionPlans }
    });

    modal.present();

    modal.onDidDismiss().then((data: any) => {
      if (data.data?.requiresUpdate) {
        this.getAccountOwnerGateways();
        // tag the accountService with an update to subscriptions has occurred
        this.accountService.subscriptionDetailsUpdated = true;
        this.cleanupOnModalDismiss();
      }
    });
  }

  getGatewayId(gw) {
    return gw.id;
  }

  async decommissionGateway() {
    if (!this.siteService.handleIsConnected()) return;

    const subscriptionPlans = [];
    
    let maintJobActive = false;

    this.siteGateways.forEach(sgw => {
      sgw.gateways.forEach( gw => {
        if (gw.isSelected) { 
          subscriptionPlans.push({
            site_name: sgw.site_name,
            site_id: sgw.site_id,
            ...gw});
          if (gw.maintenance_job != null) maintJobActive = true;
        }
      });
    });

    if (subscriptionPlans.length == 0) {
      this.show_message('Decommission Gateway', 'Please select a gateway');
      return;
    }

    if (maintJobActive) {
      this.show_message('Maintenance Jobs are active on this gateway', 'Wait or Cancel them to decomission gateway');
      return
    }

    const modal = await this.modalController.create({
      component: AccountGatewayDecommissionComponent,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',

      backdropDismiss: false,
      componentProps: { subscriptionPlans }
    });

    modal.present();

    modal.onDidDismiss().then((data: any) => {
      if (data?.data?.decommissioned) this.getAccountOwnerGateways();
      this.cleanupOnModalDismiss();
    });
  }

  permissionCheckCanEditGatewaySubscription(activeSiteUserLevel: LevelEnum): boolean {
    return this.appAuth.doesLevelHavePermission(
      activeSiteUserLevel,
      this.appAuth.permissionEnums.EditGatewaySubscription);
  }

  async show_message(messageHeader: string, message: string) {

    const alert = await this.alertController.create({
      header: messageHeader,
      message: '<div class="me-redClass">' + message + '</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Ok',
          cssClass: 'exit-button',
          handler: () => {
            this.alertController.dismiss();
          }
        },
      ]
    });

    await alert.present();
  }

  cleanupOnModalDismiss() {
    // selected gw and the jump to should clear on dismiss/sub change
    this.preSelectedGatewayId = "";

    // clear from URL
    window.history.pushState({}, document.title, (window.location.pathname + window.location.hash).split('?')[0]);
    this.cleanupSelectedGateway();
  }

  cleanupSelectedGateway() {
    if(this.siteGateways) {
      this.enable_all_gateways(null);
      this.siteGateways.forEach((site) => {
        // walk all gateways on site
        site.gateways.forEach((gw) => {
          gw.isSelected = false;
        })
      });
    }
  }
}