// pretend to be the recurly backend.  give mock answers to calls
// and just say yes.

export class mockFieldState {
    // mock a cc field state
    valid = true;
    empty = false;
    focus = false;
}

export class mockGoodEventState {
    // mock a state change message
    valid = true;
    number = new mockFieldState()
    expiry = new mockFieldState()
    cvv = new mockFieldState()
}

export class mockCardElement {
    // handle the card element

    on(event, callback) {
        // handle a request for an 'on' callback

        // simulate the call back now to record the form is good.
        const state = new mockGoodEventState()
        setTimeout(() => { callback(state) }, 500);
    }

    attach(any) {
        // nothing to do here - but we must exist.
    }

    off(any) {
        // nothing to do here - but we must exist.
    }
}

export class mockCardElements {
    // handle the card elements object
    CardElement(any) {
        // called to configure the card elements.  We do nothing with this.
        return new mockCardElement()
    }
}

export class mockToken {
    // a mock recurly token response.
    id = `fake-token`;
    type = `credit_card`;
}

export class mockRecurlyJSClient {
    // mock the javascript recurly client
   static mockElements() {
        // make a mock recurly elements object and return it.
        return new mockCardElements();
    }

    static mockToken(elements, doc, callback) {
        // mock the call to get a token from recurly js
        // reply with the mock token
        setTimeout(() => callback(null, new mockToken()));
    }
}