<ion-header color="white">
  <ion-toolbar>
    <ion-title slot="start">Site Selection</ion-title>
    <div id="x-close-modal" slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
      <ion-icon slot="end" name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-item *ngFor="let site of siteList" (click)="setClickedSelection(site)" lines="none" button>
      <ion-label title="{{site.name}}">{{site.name}}</ion-label>
      <div *ngIf="site.transfer_locked && !site.transfer_site" class="transfer-emblem" title="Site is being transferred">
        <ion-icon name="time"></ion-icon>
      </div>
      <div *ngIf="site.transfer_site" class="transfer-emblem">
        <ion-icon name="ribbon"></ion-icon>
      </div>
    </ion-item>
  </ion-list>
</ion-content>