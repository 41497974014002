<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Cancel TestRun</ion-title>
    <ion-item lines="none" slot="end">
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(true)"> OK </ion-button>
      </div>
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(false)"> Back </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-label>
        All refrigerant system test runs are canceled. Are you sure?
      </ion-label>
    </ion-row>
    <ion-row bcport-example class="flex ion-padding-vertical ion-justify-content-center me-ring-col"></ion-row>
  </ion-grid>
</ion-content>