import { MaskTypes } from "../enumerations/enums";
import { lettersAndNumbersOnly, numbersOnly, phoneNumberExtensionMaskPlaceholder, phoneNumberMaskPlaceholder } from "./validators";

export const isObject = (possibleObj) => possibleObj != null && typeof possibleObj === `object` && Object.keys(possibleObj)?.length > 0;

export const objectsAreEqual = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    for (const key of obj1Keys) {
        const val1 = obj1[key];
        const val2 = obj2[key];

        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !objectsAreEqual(val1, val2) || !areObjects && val1 !== val2) {
            return false;
        }
    }

    return JSON.stringify(obj1) == JSON.stringify(obj2);
}

// Helper Functions
export const globalFunctions = {
    
    // Capitalize First Letter of Every Word
    // Lowercase Every Other Letter in String & Remove Double Spaces
    capWords: (str) => {
        return str.replace(/\b\w/g, (match) => {
            return match.toUpperCase();
        });
    },
    
    // Capitalize First Letter of Every Word
    // Lowercase Every Other Letter in String & Remove Double Spaces
    capitalizeAllWords: string => {
        if (string != null || string != undefined) {
            return string.replace(`  `, ` `).split(` `).map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()).join(` `)
        }
    },

    // Get Current Page State
    getCurrentPageName: () => {
        return window.location.hash.slice(window.location.hash.lastIndexOf(`/`)).replace(`/`, ``);
    },

    // Cut Off Long Strings of Text & Replace with Custom Character... Also known as Truncation
    cutOffTextAndReplace: (string: string, end: number, replacement?: string) => {
        if (!replacement) {
            replacement = `...` || `-`;
        }
        return string?.length > end ? string?.substring(0, end - 1) + replacement : string;
    },

    // Remove Duplicate Objects from Array
    removeDuplicateObjectFromArray: (arrayOfObjects?: any) => {
        const uniqueArray = arrayOfObjects?.filter((value?: any, index?: any) => {
            const _value = JSON.stringify(value);
            return index === arrayOfObjects?.findIndex((obj?: any) => {
                return JSON.stringify(obj) === _value;
            });
        });
        return uniqueArray;
    },

    // Get a decimal like 12.9 or if its 12.0, return 12
    removeTrailingZeroDecimal: (number, decimalPlaces = 1, rounded = true, cutOffDecimal = false) => {
        let num = typeof number == `string` ? parseFloat(number) : number;
        const wholeNumber = Math.trunc(num);
        const decimalPart = num - wholeNumber;
        const unroundedDecimalPart = parseFloat(decimalPart.toString().slice(0, decimalPlaces + 2));

        let showOnlyWholeNumber = cutOffDecimal ? decimalPart === 0 || decimalPart < 1 : decimalPart === 0;
        if (showOnlyWholeNumber) {
          return wholeNumber;
        } else {
          return rounded ? num.toFixed(decimalPlaces) : wholeNumber + unroundedDecimalPart;
        }
    },

    // Detect Angular Form Changes
    // Enable Save Button if Value is different from Initial Value
    detectFormChanges(angularFormWithControls) {
        if (angularFormWithControls) {
            const initialFormValue = { ...angularFormWithControls?.value };
            Object.keys(angularFormWithControls?.value).map(formControlKey => {
                angularFormWithControls.get(formControlKey).valueChanges.subscribe(latestFormControlValue => {

                    // On Each Form Change
                    const isMaskedControl = (formControlName) => MaskTypes[formControlName] != undefined;
                    const isMaskedMacAddressControl = (formControlName) => isMaskedControl(formControlName) && (MaskTypes[formControlName] == MaskTypes.mac_address || MaskTypes[formControlName] == MaskTypes.rmd_mac_address);
                    const isMaskedPhoneControl = (formControlName) => isMaskedControl(formControlName) && (MaskTypes[formControlName] == MaskTypes.phone || MaskTypes[formControlName] == MaskTypes.company_phone);

                    const phoneMaskHasInitialValue = (value) => numbersOnly(value)?.length <= 1;
                    const phoneMaskIsPlaceholder = (value) => value?.includes(phoneNumberMaskPlaceholder);
                    
                    if (isMaskedControl(formControlKey)) {
                        if (isMaskedPhoneControl(formControlKey)) {
                            if (phoneMaskHasInitialValue(latestFormControlValue) || phoneMaskIsPlaceholder(latestFormControlValue)) {
                                latestFormControlValue = ``;
                            } else if (latestFormControlValue?.includes(phoneNumberExtensionMaskPlaceholder)) {
                                latestFormControlValue = latestFormControlValue?.replace(phoneNumberExtensionMaskPlaceholder, ``);
                            }
                        } else if (isMaskedMacAddressControl(formControlKey)) {
                            latestFormControlValue = lettersAndNumbersOnly(latestFormControlValue);
                        } else {
                            latestFormControlValue = numbersOnly(latestFormControlValue);
                        }
                    }

                    let formChanged = Object.entries(initialFormValue).some(([key, initialValue]: any) => {
                        let currentFormControlValue = angularFormWithControls?.value[key];
                        if (isMaskedControl(key)) {
                            if (isMaskedPhoneControl(key)) {
                                if (phoneMaskHasInitialValue(currentFormControlValue) || phoneMaskIsPlaceholder(currentFormControlValue)) {
                                    currentFormControlValue = ``;
                                } else if (currentFormControlValue?.includes(phoneNumberExtensionMaskPlaceholder)) {
                                    currentFormControlValue = currentFormControlValue?.replace(phoneNumberExtensionMaskPlaceholder, ``);
                                }
                                return currentFormControlValue != initialValue;
                            } else if (isMaskedMacAddressControl(key)) {
                                return lettersAndNumbersOnly(currentFormControlValue) != lettersAndNumbersOnly(initialValue);
                            } else {
                                return numbersOnly(currentFormControlValue) != numbersOnly(initialValue);
                            }
                        } else {
                            return formControlKey == key ? latestFormControlValue != initialValue : currentFormControlValue != initialValue;
                        }
                    });

                    setTimeout(() => {
                        let formValueChanged = !objectsAreEqual(angularFormWithControls?.value, initialFormValue);
               
                        // console.log(`Form Parameters`, {
                        //     formChanged,
                        //     formValueChanged,
                        //     latestFormControlValue,
                        //     formStatus: angularFormWithControls?.status,
                        //     initialValue: initialFormValue[formControlKey],
                        // });

                        this.saveEnabled = formChanged && formValueChanged && angularFormWithControls?.status == `VALID`;
                    })
                });
            });
        }
    },
    
}