import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { Site } from '../../features/sites/classes/site';
import { SiteService } from '../../features/sites/services/site.service';
import { AppMessageTypeCode, AppMessageCode, SiteStatusTypeEnum, LevelEnum, PermissionDetailEnum } from 'src/app/enumerations/enums';
import { DEFAULT_SITE } from 'src/app/constants/kenzaconstants';
import { map } from 'rxjs/operators'
import { AppAuthenticationService } from '../services/authentication/app-authentication.service';
@Injectable({
  providedIn: 'root'
})
export class SiteGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private siteService: SiteService,
    private appAuth: AppAuthenticationService,
    private user: UserService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // can we go to this site page?
    const url_parameter_site_id = next.paramMap.get('siteId');
    const requiredPermission:PermissionDetailEnum = 'requiredPermission' in next.data ? next.data.requiredPermission : PermissionDetailEnum.NonPermission;
    let site_id: string = this.reconcile_site_id(url_parameter_site_id);

    return new Promise((resolve) => {
      if (this.userService.signedIn) {
        // confirm this user can view this site
        this.primeAndResolveSiteList(this.userService.id, site_id, resolve, requiredPermission);
      } else {
        // not signed in.
        resolve(false);
      }
    });
  }

  reconcile_site_id(site_id: string): string | undefined{
    let potentialSites: Site[];
    if ((this.userService.active?.id === site_id) || (this.userService.active?.id === DEFAULT_SITE)) {
      return site_id;
    }else if (this.userService.sites.length) {

      potentialSites = this.userService.sites.filter(s => s.id === site_id);

      if (!potentialSites.length) {
        return undefined;
      } else{
        return potentialSites[0].id;
      }
    }
    return undefined;
  }

  primeAndResolveSiteList(userId, siteId, resolve, requiredPermission:PermissionDetailEnum) {
    this.siteService
      .isAMember(siteId)
      .subscribe((level: any) => {
        const site_level = level.level;
        if (site_level == LevelEnum.NotAMember) {
          this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
          return resolve(false);
        }

        // set the 'active site' to this site.
        const uriSite = this.userService.sites.find((x) => x.id === siteId);

        if (uriSite) {
          // set the site in the user service
          if (this.userService.active?.id != siteId) {
            // then we need to set this site as active.            
            this.userService.setActiveSite(uriSite, false);
          }
          // check for a specific permission?
          if (requiredPermission != PermissionDetailEnum.NonPermission) {
            // then confirm we have permission too this permission
            const auth = this.appAuth.doesLevelHavePermission(
                                this.user.activeSiteUserLevel == LevelEnum.Unknown ? site_level : this.user.activeSiteUserLevel,
                                requiredPermission)  
            if (!auth) return resolve(false);
          }

          // and we're good.
          return resolve(true);
        } else {
          // hard pull this site from the server
          this.siteService.getSite(siteId).pipe(map(s => new Site(s))).subscribe((site) => {

            // return from call to get site
            if (!site) {
              /// then we really dont have the site...
              this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
              return resolve(false);
            }
            
            // set the site in the user service
            if (this.userService.active?.id != siteId) {
              // then we need to set this site as active.              
              this.userService.setActiveSite(site, false);
            }

            // check for a specific permissino?
            if (requiredPermission != PermissionDetailEnum.NonPermission) {
              // then confirm we have permission too this permission
              const auth = this.appAuth.doesLevelHavePermission(
                                  this.user.activeSiteUserLevel == LevelEnum.Unknown ? site_level : this.user.activeSiteUserLevel,
                                  requiredPermission)  
              if (!auth) return resolve(false);
            }            

            // and we're good.
            return resolve(true);
          })
        } 
      }, () => {
         resolve(false);
      }
      );
  }
}
