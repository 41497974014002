import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { ToastMessageTypeEnum } from 'src/app/enumerations/enums';
import {
  TOAST_GENERAL_ERROR_TITLE,
  TOAST_CONNECTIVITY_ISSUE,
  MODAL_MAINT_UPDATE_UNIT_BLOCK,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
} from 'src/app/constants/kenzaconstants';
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import { SelectTestRunMode } from '../../pages/selectMode/select-mode.page';
import { SelectTestRunTime } from '../../pages/selectTime/select-time.page';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { MaintMapUnitsErrorComponent } from 'src/app/features/maintenance/modals/maint-map-units-error/maint-map-units-error.component';

@Component({
  selector: 'testdrive-settings',
  templateUrl: './testdrive-settings.component.html',
  styleUrls: ['./testdrive-settings.component.scss'],
})
export class TestDriveSettingsComponent /*implements OnInit*/ {
  displayedColumns: string[] = ['Checkbox', 'SystemName', 'ModelName', 'SerialNo']; // list of table items
  dataSource = []; //Outdoor unit (OC) data
  target: number;
  outdoorUnitIdList = [];
  siteId: string;
  apicalling = false;

  gatewayId: string;    // GatewayID of the OutdoorUnit

  selectedMode: string;   // Currently selected commissioning mode
  selectedTime: string;   // Currently selected commissioning time

  // loadMain;
  degreeKind: number;     // Celsius Fahrenheit setting (table of statements when operation mode = Auto)

  /** スケジュール */
  schedule = null;

  constructor(
    public _ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public user: UserService,
    private siteService: SiteService,
    public modalController: ModalController,
    private toastService: ToastrService,
    private socketService: SocketService
  ) {}

  // ngOnInit() { 

  // }

  ionViewWillEnter() {
    this.dataSource = [];
    // 試運転設定値 初期値格納
    // Stores initial commissioning set values
    this.selectedMode = 'Auto';
    this.selectedTime = '120 min';
    this.degreeKind = this.user.accountPreferences.temperaturepreference_id;

    // OutdoorUnit情報取得
    // Acquisition of OutdoorUnit information
    this.route.queryParams.subscribe(params => {
      this.gatewayId = params.target;
      this.siteService.get_site_gateways_with_groups(this.user.active.id, true).subscribe(
        (gateways: GatewayWithGroups) => {
          this.setGateways(gateways);
        });
    });
  }

  checkboxCount(){
    // チェックがついてるOCの確認
    // Confirm OC with check
    let i = 0;
    this.target = -1;
  
    const checkboxies = document.querySelectorAll('input[name=\'checkbox_tr\']') as NodeListOf<HTMLInputElement>;
    checkboxies.forEach(check => {
      if(check && check.checked){
        this.target = i;
      }
      i++;
    });
    if (this.target > -1){
      const save = document.getElementById('save') as HTMLInputElement;
      save.disabled = false;
    }
    else{
      const save = document.getElementById('save') as HTMLInputElement;
      save.disabled = true;
    }
    
  }

  startTestRun(){
    if (!this.siteService.handleIsConnected()){
      return;
    }

    // ソケット通信に問題がある、もしくは認証が切れていた場合はエラーとする
    // Error if there is a problem with socket communication or if the authentication is broken
    if (!this.socketService.is_connected() || !this.socketService.judge_authenticated()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      console.log('The socket service is not connected');
      return;
    }

    // チェックがついてる場合のみ、試運転を実行する
    // Execute trial run only if checked
    if(this.target >= 0){
      this.apicalling = true;

      // 選択中のOCのみ指定する
      this.outdoorUnitIdList = [];
      
      let i = 0;
      this.target = -1;
    
      const checkboxies = document.querySelectorAll('input[name=\'checkbox_tr\']') as NodeListOf<HTMLInputElement>;
      checkboxies.forEach(check => {
        if(check && check.checked){
          this.outdoorUnitIdList.push(this.dataSource[i].OutdoorUnitId);
        }
        i++;
      });
      this.getRedisInfo();
    }
  }

  backToTestRunTop() {
    if (!this.siteService.handleIsConnected()){
      return;
    }
    this.router.navigate(['/testdrive', this.user.active.id, 'list']);
  }

  setGateways(gateways: GatewayWithGroups) {
    // OutdoorUnitデータ取得
    // Acquisition of OutdoorUnit data
    let noRMDflg = true;
    let gatewaysWithGroups: Gateway[] = [];

    const newDataSource = [];

    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True' && gwg.outdoor_units.length > 0){
          if(gwg.id === this.gatewayId){
            for (let i=0; i< gwg.outdoor_units.length; i++){
              const addData = {
                Checked: true,
                SystemName: gwg.outdoor_units[i].name,
                ModelName: '',
                SerialNo: '',
                GatewayId: gwg.id,
                Address: gwg.outdoor_units[i].bus_address,
                OutdoorUnitId: gwg.outdoor_units[i].id
              };
              newDataSource.push(addData);
            }
            noRMDflg =  false;
          }
        }
      });

      // 対象のRMDが存在しない場合エラー表示
      // Error display if the target RMD does not exist
      if(noRMDflg){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          TOAST_CONNECTIVITY_ISSUE
        );
        return;
      }

      if(newDataSource.length > 0){
        this.target = 0
        const save = document.getElementById('save') as HTMLInputElement;
        save.disabled = false;
      }

      // restAPI経由でModelNameとSerialNo.を取得
      // Acquire ModelName and SerialNo. via restAPI
      newDataSource.forEach((value) => {
        this.siteService.getMachineDetail(value.GatewayId, value.Address).subscribe(
          (resData: {modelName: string, serialNo:string}) => {
            if(!resData.modelName){
              value.ModelName = '-';
            }else{
              value.ModelName = resData.modelName;
            }
            if(!resData.serialNo){
              value.SerialNo = '-';
            }else{
              value.SerialNo = resData.serialNo;
            }
            this.dataSource = newDataSource;
            this._ref.detectChanges();
          },
          () => {
            value.ModelName = '-';
            value.SerialNo = '-';
            this.dataSource = newDataSource;
            this._ref.detectChanges();
          },
        );
      });
      this._ref.detectChanges();
    }
  }

  async onSelectMode(){
    // 運転モード選択
    // Select operation mode
    const modal = await this.modalController.create({
      component: SelectTestRunMode,
      backdropDismiss: true,
      componentProps: {
        parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl,
        parentSelectedName: this.selectedMode,
      },
    });

    modal.onDidDismiss().then((data) => {      
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.parentSelectedName !== undefined &&
        data.data.parentSelectedName !== null
      ) {
        if(this.selectedMode !== data.data.parentSelectedName){
          this.selectedMode = data.data.parentSelectedName;
          this._ref.detectChanges();
        }
      }
    });
    return await modal.present();
  }

  async onSelectTime(){
    // 運転時間選択
    // Operation time selection
    const modal = await this.modalController.create({
      component: SelectTestRunTime,
      backdropDismiss: true,
      componentProps: {
        parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl,
        parentSelectedName: this.selectedTime,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.parentSelectedName !== undefined &&
        data.data.parentSelectedName !== null
      ) {
        if(this.selectedTime !== data.data.parentSelectedName){
          this.selectedTime = data.data.parentSelectedName;
          this._ref.detectChanges();
        }
      }
    });
    return await modal.present();
  }

  getRedisInfo(){
    const gatewayId = this.gatewayId;
    this.siteService.getUnitHistoryTop(gatewayId).subscribe(
      (resData: {[key:string]: number | boolean}) => {
        this.apicalling = false;
        if (resData){
          this.router.navigate(['/testdrive', this.user.active.id, 'progress'], {queryParams: {
            target: this.gatewayId,
            mode: this.selectedMode,
            time: this.selectedTime.split(' ')[0], // Only the numerical part is stored.
            outdoorUnitIdList: this.outdoorUnitIdList
          }});
        }else{
          this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_BLOCK);
        }
      },
      (err) => {
        this.apicalling = false;
        console.log('getUnitHistoryTop=>err', err);
      }
    );
  }
  async openMapUnitsError(err_msg) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: MaintMapUnitsErrorComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
      componentProps: {
        errorMessage: err_msg
      },
    });
    return await modal.present();
  } 
}
