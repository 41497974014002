/* eslint-disable @typescript-eslint/no-unused-vars */
import { MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle } from 'src/app/enumerations/enums';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { UserService } from '../../services/user/user.service';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-large-icon-button',
  templateUrl: './large-icon-button.component.html',
  styleUrls: ['./large-icon-button.component.scss'],
})

export class LargeIconButtonComponent implements OnInit {
  devEnv = devEnv;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;

  @Input() iconId: string;
  @Input() iconName: string;
  @Input() iconTitle: string;
  @Input() iconLocked = false;
  @Input() iconCustomHeader: string = null;
  @Input() iconCustomMessage: string = null;
  @Output() iconAction = new EventEmitter();
  
  @Input() iconContainerID?;
  @Input() iconMaintenanceType?: string = `maintenance`;

  constructor(
    public user: UserService,
    public siteService: SiteService,
  ) {
    // Empty
  }

  ngOnInit() {
    let siteIsTransferring = this.user && this.user.active && this.user.active.transfer_locked;
    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }
    if (!this.iconId) this.iconId = `i_${this.iconTitle.toLowerCase().replace(/\s+/g, "-")}`;
    if (!this.iconContainerID) this.iconContainerID = `iconContainer_${this.iconId}`;
  }

  doAction(event) {
    if (!this.iconLocked) {
      this.iconAction.emit();
    } else {
      this.user.presentActionRestricted(this.iconCustomHeader, this.iconCustomMessage, this.iconMaintenanceType);
    }
  }
}
