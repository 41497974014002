import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsAlertsComponent } from '../../components/settings-alerts/settings-alerts.component';
import { SettingsReportsComponent } from '../../components/settings-reports/settings-reports.component';
import { SettingsSubscriptionComponent } from '../../components/settings-subscription/settings-subscription.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SettingsAlertsComponent,
    SettingsReportsComponent,
    SettingsSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class SettingsModule { }
