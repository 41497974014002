<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Maintenance</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/maintenance', user.active?.id]"me-button-as-link>
        &#8592;&nbsp;Back to Maintenance Top </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Processing status</h4>
            </ion-title>
          </ion-item>
          
          <ion-card-content >
            <ion-grid>
              <ion-row class="row-title">
                <ion-col>
                  Start Date and Time
                </ion-col>
                <ion-col>
                  System name
                </ion-col>
                <ion-col>
                  Model name
                </ion-col>
                <ion-col>
                  Serial No.
                </ion-col>
                <ion-col>
                  Processing status
                </ion-col>
                <ion-col>
                  Detail
                </ion-col>
                <ion-col>
                  Change BC port
                </ion-col>
                <ion-col>
                  Cancel
                </ion-col>
              </ion-row>
              <ion-row me-top-border>
                <ion-col>
                  4/27/2022 00:01:23
                </ion-col>
                <ion-col>
                  ACP1-1
                </ion-col>
                <ion-col>
                  PUHY-P200DMG6
                </ion-col>
                <ion-col>
                  1ZW00001
                </ion-col>
                <ion-col>
                  In process
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style" (click)="onClickDetail()">
                    Detail
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style" (click)="onClickBranch()">
                    Change BC port
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="danger"  size="small" class="button-style" (click)="pauseTimer()">
                    Cancel
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  4/27/2022 00:01:23
                </ion-col>
                <ion-col>
                  ACP1-2
                </ion-col>
                <ion-col>
                  PUHY-P200DMG6
                </ion-col>
                <ion-col>
                  1ZW00002
                </ion-col>
                <ion-col>
                  Complete
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style" (click)="onClickDetail()">
                    Detail
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style" (click)="onClickBranch()">
                    Change BC port
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button color="danger"  size="small" class="button-style" [disabled]="true">
                    Cancel
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col >
        <ion-card me-ion-card>
          <ion-item lines="none">
            <ion-title>
              <h4>{{progressMessage}}</h4>
            </ion-title>
          </ion-item>
          
          <ion-card-content color="white" me-top-border class="progress-row">
            <div class="table-area">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <ng-container matColumnDef="Key">
                  <th mat-header-cell *matHeaderCellDef> Item name </th>
                  <td mat-cell *matCellDef="let element" > {{element.Key}} </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_1">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[0]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                    [ngStyle]="{'background-color': 
                      (element.Key == 'OC(051) 63HS2' && element.TimeData_1 > 30) 
                      || (element.Key == 'IC(001) TH2' && (element.TimeData_1 < 1.6 || element.TimeData_1 > 8.9)) 
                      || (element.Key == 'IC(001) SH' && element.TimeData_1 > 18) 
                      || (element.Key == 'IC(002) TH2' && (element.TimeData_1 < 1.6 || element.TimeData_1 > 8.9)) 
                      || (element.Key == 'IC(002) SH' && element.TimeData_1 > 18) 
                      || (element.Key == 'IC(003) TH2' && (element.TimeData_1 < 1.6 || element.TimeData_1 > 8.9)) 
                      || (element.Key == 'IC(003) SH' && element.TimeData_1 > 18) 
                      || (element.Key == 'IC(004) TH2' && (element.TimeData_1 < 1.6 || element.TimeData_1 > 8.9)) 
                      || (element.Key == 'IC(004) SH' && element.TimeData_1 > 18) 
                      || (element.Key == 'IC(005) TH2' && (element.TimeData_1 < 1.6 || element.TimeData_1 > 8.9)) 
                      || (element.Key == 'IC(005) SH' && element.TimeData_1 > 18) 
                      ? 'pink' : 'none'}"> 
                      {{element.TimeData_1}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_2">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[1]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_2 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_2 < 1.6 || element.TimeData_2 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_2 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_2 < 1.6 || element.TimeData_2 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_2 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_2 < 1.6 || element.TimeData_2 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_2 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_2 < 1.6 || element.TimeData_2 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_2 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_2 < 1.6 || element.TimeData_2 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_2 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_2}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_3">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[2]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_3 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_3 < 1.6 || element.TimeData_3 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_3 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_3 < 1.6 || element.TimeData_3 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_3 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_3 < 1.6 || element.TimeData_3 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_3 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_3 < 1.6 || element.TimeData_3 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_3 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_3 < 1.6 || element.TimeData_3 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_3 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_3}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_4">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[3]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_4 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_4 < 1.6 || element.TimeData_4 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_4 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_4 < 1.6 || element.TimeData_4 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_4 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_4 < 1.6 || element.TimeData_4 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_4 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_4 < 1.6 || element.TimeData_4 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_4 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_4 < 1.6 || element.TimeData_4 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_4 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_4}} 
                  </td>                  
                </ng-container>
                
                <ng-container matColumnDef="TimeData_5">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[4]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_5 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_5 < 1.6 || element.TimeData_5 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_5 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_5 < 1.6 || element.TimeData_5 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_5 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_5 < 1.6 || element.TimeData_5 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_5 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_5 < 1.6 || element.TimeData_5 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_5 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_5 < 1.6 || element.TimeData_5 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_5 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_5}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_6">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[5]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_6 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_6 < 1.6 || element.TimeData_6 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_6 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_6 < 1.6 || element.TimeData_6 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_6 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_6 < 1.6 || element.TimeData_6 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_6 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_6 < 1.6 || element.TimeData_6 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_6 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_6 < 1.6 || element.TimeData_6 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_6 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_6}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_7">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[6]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_7 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_7 < 1.6 || element.TimeData_7 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_7 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_7 < 1.6 || element.TimeData_7 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_7 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_7 < 1.6 || element.TimeData_7 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_7 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_7 < 1.6 || element.TimeData_7 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_7 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_7 < 1.6 || element.TimeData_7 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_7 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_7}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_8">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[7]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_8 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_8 < 1.6 || element.TimeData_8 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_8 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_8 < 1.6 || element.TimeData_8 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_8 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_8 < 1.6 || element.TimeData_8 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_8 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_8 < 1.6 || element.TimeData_8 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_8 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_8 < 1.6 || element.TimeData_8 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_8 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_8}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_9">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[8]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_9 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_9 < 1.6 || element.TimeData_9 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_9 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_9 < 1.6 || element.TimeData_9 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_9 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_9 < 1.6 || element.TimeData_9 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_9 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_9 < 1.6 || element.TimeData_9 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_9 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_9 < 1.6 || element.TimeData_9 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_9 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_9}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_10">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[9]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_10 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_10 < 1.6 || element.TimeData_10 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_10 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_10 < 1.6 || element.TimeData_10 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_10 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_10 < 1.6 || element.TimeData_10 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_10 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_10 < 1.6 || element.TimeData_10 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_10 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_10 < 1.6 || element.TimeData_10 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_10 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_10}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_11">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[10]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_11 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_11 < 1.6 || element.TimeData_11 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_11 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_11 < 1.6 || element.TimeData_11 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_11 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_11 < 1.6 || element.TimeData_11 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_11 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_11 < 1.6 || element.TimeData_11 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_11 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_11 < 1.6 || element.TimeData_11 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_11 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_11}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_12">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[11]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_12 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_12 < 1.6 || element.TimeData_12 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_12 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_12 < 1.6 || element.TimeData_12 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_12 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_12 < 1.6 || element.TimeData_12 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_12 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_12 < 1.6 || element.TimeData_12 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_12 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_12 < 1.6 || element.TimeData_12 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_12 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_12}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_13">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[12]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_13 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_13 < 1.6 || element.TimeData_13 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_13 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_13 < 1.6 || element.TimeData_13 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_13 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_13 < 1.6 || element.TimeData_13 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_13 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_13 < 1.6 || element.TimeData_13 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_13 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_13 < 1.6 || element.TimeData_13 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_13 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_13}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_14">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[13]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_14 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_14 < 1.6 || element.TimeData_14 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_14 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_14 < 1.6 || element.TimeData_14 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_14 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_14 < 1.6 || element.TimeData_14 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_14 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_14 < 1.6 || element.TimeData_14 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_14 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_14 < 1.6 || element.TimeData_14 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_14 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_14}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_15">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[14]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_15 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_15 < 1.6 || element.TimeData_15 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_15 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_15 < 1.6 || element.TimeData_15 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_15 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_15 < 1.6 || element.TimeData_15 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_15 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_15 < 1.6 || element.TimeData_15 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_15 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_15 < 1.6 || element.TimeData_15 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_15 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_15}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_16">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[15]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_16 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_16 < 1.6 || element.TimeData_16 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_16 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_16 < 1.6 || element.TimeData_16 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_16 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_16 < 1.6 || element.TimeData_16 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_16 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_16 < 1.6 || element.TimeData_16 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_16 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_16 < 1.6 || element.TimeData_16 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_16 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_16}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_17">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[16]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_17 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_17 < 1.6 || element.TimeData_17 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_17 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_17 < 1.6 || element.TimeData_17 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_17 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_17 < 1.6 || element.TimeData_17 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_17 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_17 < 1.6 || element.TimeData_17 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_17 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_17 < 1.6 || element.TimeData_17 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_17 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_17}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_18">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[17]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_18 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_18 < 1.6 || element.TimeData_18 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_18 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_18 < 1.6 || element.TimeData_18 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_18 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_18 < 1.6 || element.TimeData_18 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_18 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_18 < 1.6 || element.TimeData_18 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_18 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_18 < 1.6 || element.TimeData_18 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_18 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_18}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_19">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[18]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_19 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_19 < 1.6 || element.TimeData_19 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_19 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_19 < 1.6 || element.TimeData_19 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_19 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_19 < 1.6 || element.TimeData_19 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_19 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_19 < 1.6 || element.TimeData_19 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_19 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_19 < 1.6 || element.TimeData_19 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_19 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_19}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_20">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[19]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_20 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_20 < 1.6 || element.TimeData_20 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_20 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_20 < 1.6 || element.TimeData_20 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_20 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_20 < 1.6 || element.TimeData_20 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_20 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_20 < 1.6 || element.TimeData_20 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_20 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_20 < 1.6 || element.TimeData_20 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_20 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_20}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_21">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[20]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_21 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_21 < 1.6 || element.TimeData_21 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_21 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_21 < 1.6 || element.TimeData_21 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_21 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_21 < 1.6 || element.TimeData_21 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_21 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_21 < 1.6 || element.TimeData_21 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_21 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_21 < 1.6 || element.TimeData_21 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_21 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_21}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_22">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[21]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_22 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_22 < 1.6 || element.TimeData_22 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_22 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_22 < 1.6 || element.TimeData_22 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_22 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_22 < 1.6 || element.TimeData_22 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_22 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_22 < 1.6 || element.TimeData_22 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_22 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_22 < 1.6 || element.TimeData_22 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_22 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_22}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_23">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[22]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_23 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_23 < 1.6 || element.TimeData_23 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_23 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_23 < 1.6 || element.TimeData_23 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_23 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_23 < 1.6 || element.TimeData_23 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_23 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_23 < 1.6 || element.TimeData_23 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_23 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_23 < 1.6 || element.TimeData_23 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_23 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_23}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_24">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[23]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_24 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_24 < 1.6 || element.TimeData_24 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_24 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_24 < 1.6 || element.TimeData_24 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_24 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_24 < 1.6 || element.TimeData_24 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_24 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_24 < 1.6 || element.TimeData_24 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_24 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_24 < 1.6 || element.TimeData_24 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_24 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_24}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_25">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[24]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_25 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_25 < 1.6 || element.TimeData_25 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_25 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_25 < 1.6 || element.TimeData_25 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_25 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_25 < 1.6 || element.TimeData_25 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_25 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_25 < 1.6 || element.TimeData_25 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_25 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_25 < 1.6 || element.TimeData_25 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_25 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_25}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_26">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[25]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_26 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_26 < 1.6 || element.TimeData_26 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_26 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_26 < 1.6 || element.TimeData_26 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_26 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_26 < 1.6 || element.TimeData_26 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_26 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_26 < 1.6 || element.TimeData_26 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_26 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_26 < 1.6 || element.TimeData_26 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_26 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_26}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_27">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[26]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_27 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_27 < 1.6 || element.TimeData_27 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_27 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_27 < 1.6 || element.TimeData_27 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_27 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_27 < 1.6 || element.TimeData_27 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_27 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_27 < 1.6 || element.TimeData_27 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_27 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_27 < 1.6 || element.TimeData_27 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_27 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_27}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_28">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[27]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_28 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_28 < 1.6 || element.TimeData_28 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_28 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_28 < 1.6 || element.TimeData_28 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_28 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_28 < 1.6 || element.TimeData_28 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_28 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_28 < 1.6 || element.TimeData_28 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_28 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_28 < 1.6 || element.TimeData_28 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_28 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_28}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_29">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[28]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_29 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_29 < 1.6 || element.TimeData_29 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_29 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_29 < 1.6 || element.TimeData_29 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_29 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_29 < 1.6 || element.TimeData_29 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_29 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_29 < 1.6 || element.TimeData_29 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_29 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_29 < 1.6 || element.TimeData_29 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_29 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_29}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_30">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[29]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_30 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_30 < 1.6 || element.TimeData_30 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_30 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_30 < 1.6 || element.TimeData_30 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_30 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_30 < 1.6 || element.TimeData_30 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_30 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_30 < 1.6 || element.TimeData_30 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_30 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_30 < 1.6 || element.TimeData_30 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_30 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_30}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_31">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[30]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_31 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_31 < 1.6 || element.TimeData_31 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_31 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_31 < 1.6 || element.TimeData_31 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_31 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_31 < 1.6 || element.TimeData_31 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_31 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_31 < 1.6 || element.TimeData_31 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_31 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_31 < 1.6 || element.TimeData_31 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_31 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_31}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_32">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[31]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_32 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_32 < 1.6 || element.TimeData_32 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_32 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_32 < 1.6 || element.TimeData_32 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_32 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_32 < 1.6 || element.TimeData_32 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_32 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_32 < 1.6 || element.TimeData_32 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_32 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_32 < 1.6 || element.TimeData_32 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_32 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_32}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_33">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[32]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_33 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_33 < 1.6 || element.TimeData_33 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_33 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_33 < 1.6 || element.TimeData_33 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_33 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_33 < 1.6 || element.TimeData_33 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_33 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_33 < 1.6 || element.TimeData_33 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_33 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_33 < 1.6 || element.TimeData_33 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_33 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_33}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_34">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[33]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_34 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_34 < 1.6 || element.TimeData_34 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_34 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_34 < 1.6 || element.TimeData_34 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_34 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_34 < 1.6 || element.TimeData_34 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_34 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_34 < 1.6 || element.TimeData_34 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_34 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_34 < 1.6 || element.TimeData_34 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_34 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_34}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_35">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[34]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_35 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_35 < 1.6 || element.TimeData_35 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_35 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_35 < 1.6 || element.TimeData_35 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_35 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_35 < 1.6 || element.TimeData_35 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_35 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_35 < 1.6 || element.TimeData_35 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_35 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_35 < 1.6 || element.TimeData_35 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_35 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_35}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_36">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[35]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_36 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_36 < 1.6 || element.TimeData_36 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_36 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_36 < 1.6 || element.TimeData_36 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_36 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_36 < 1.6 || element.TimeData_36 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_36 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_36 < 1.6 || element.TimeData_36 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_36 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_36 < 1.6 || element.TimeData_36 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_36 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_36}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_37">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[36]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_37 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_37 < 1.6 || element.TimeData_37 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_37 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_37 < 1.6 || element.TimeData_37 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_37 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_37 < 1.6 || element.TimeData_37 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_37 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_37 < 1.6 || element.TimeData_37 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_37 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_37 < 1.6 || element.TimeData_37 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_37 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_37}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_38">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[37]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_38 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_38 < 1.6 || element.TimeData_38 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_38 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_38 < 1.6 || element.TimeData_38 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_38 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_38 < 1.6 || element.TimeData_38 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_38 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_38 < 1.6 || element.TimeData_38 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_38 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_38 < 1.6 || element.TimeData_38 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_38 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_38}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_39">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[38]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_39 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_39 < 1.6 || element.TimeData_39 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_39 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_39 < 1.6 || element.TimeData_39 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_39 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_39 < 1.6 || element.TimeData_39 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_39 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_39 < 1.6 || element.TimeData_39 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_39 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_39 < 1.6 || element.TimeData_39 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_39 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_39}} 
                  </td>
                </ng-container>
                              
                <ng-container matColumnDef="TimeData_40">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[39]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_40 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_40 < 1.6 || element.TimeData_40 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_40 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_40 < 1.6 || element.TimeData_40 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_40 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_40 < 1.6 || element.TimeData_40 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_40 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_40 < 1.6 || element.TimeData_40 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_40 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_40 < 1.6 || element.TimeData_40 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_40 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_40}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_41">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[40]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_41 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_41 < 1.6 || element.TimeData_41 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_41 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_41 < 1.6 || element.TimeData_41 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_41 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_41 < 1.6 || element.TimeData_41 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_41 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_41 < 1.6 || element.TimeData_41 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_41 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_41 < 1.6 || element.TimeData_41 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_41 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_41}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_42">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[41]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_42 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_42 < 1.6 || element.TimeData_42 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_42 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_42 < 1.6 || element.TimeData_42 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_42 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_42 < 1.6 || element.TimeData_42 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_42 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_42 < 1.6 || element.TimeData_42 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_42 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_42 < 1.6 || element.TimeData_42 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_42 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_42}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_43">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[42]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_43 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_43 < 1.6 || element.TimeData_43 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_43 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_43 < 1.6 || element.TimeData_43 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_43 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_43 < 1.6 || element.TimeData_43 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_43 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_43 < 1.6 || element.TimeData_43 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_43 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_43 < 1.6 || element.TimeData_43 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_43 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_43}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_44">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[43]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_44 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_44 < 1.6 || element.TimeData_44 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_44 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_44 < 1.6 || element.TimeData_44 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_44 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_44 < 1.6 || element.TimeData_44 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_44 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_44 < 1.6 || element.TimeData_44 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_44 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_44 < 1.6 || element.TimeData_44 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_44 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_44}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_45">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[44]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_45 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_45 < 1.6 || element.TimeData_45 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_45 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_45 < 1.6 || element.TimeData_45 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_45 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_45 < 1.6 || element.TimeData_45 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_45 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_45 < 1.6 || element.TimeData_45 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_45 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_45 < 1.6 || element.TimeData_45 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_45 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_45}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_46">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[45]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_46 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_46 < 1.6 || element.TimeData_46 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_46 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_46 < 1.6 || element.TimeData_46 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_46 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_46 < 1.6 || element.TimeData_46 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_46 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_46 < 1.6 || element.TimeData_46 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_46 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_46 < 1.6 || element.TimeData_46 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_46 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_46}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_47">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[46]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_47 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_47 < 1.6 || element.TimeData_47 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_47 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_47 < 1.6 || element.TimeData_47 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_47 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_47 < 1.6 || element.TimeData_47 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_47 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_47 < 1.6 || element.TimeData_47 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_47 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_47 < 1.6 || element.TimeData_47 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_47 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_47}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_48">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[47]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_48 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_48 < 1.6 || element.TimeData_48 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_48 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_48 < 1.6 || element.TimeData_48 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_48 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_48 < 1.6 || element.TimeData_48 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_48 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_48 < 1.6 || element.TimeData_48 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_48 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_48 < 1.6 || element.TimeData_48 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_48 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_48}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_49">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[48]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_49 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_49 < 1.6 || element.TimeData_49 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_49 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_49 < 1.6 || element.TimeData_49 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_49 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_49 < 1.6 || element.TimeData_49 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_49 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_49 < 1.6 || element.TimeData_49 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_49 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_49 < 1.6 || element.TimeData_49 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_49 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_49}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_50">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[49]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_50 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_50 < 1.6 || element.TimeData_50 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_50 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_50 < 1.6 || element.TimeData_50 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_50 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_50 < 1.6 || element.TimeData_50 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_50 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_50 < 1.6 || element.TimeData_50 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_50 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_50 < 1.6 || element.TimeData_50 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_50 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_50}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_51">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[50]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_51 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_51 < 1.6 || element.TimeData_51 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_51 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_51 < 1.6 || element.TimeData_51 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_51 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_51 < 1.6 || element.TimeData_51 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_51 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_51 < 1.6 || element.TimeData_51 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_51 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_51 < 1.6 || element.TimeData_51 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_51 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_51}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_52">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[51]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_52 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_52 < 1.6 || element.TimeData_52 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_52 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_52 < 1.6 || element.TimeData_52 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_52 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_52 < 1.6 || element.TimeData_52 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_52 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_52 < 1.6 || element.TimeData_52 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_52 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_52 < 1.6 || element.TimeData_52 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_52 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_52}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_53">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[52]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_53 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_53 < 1.6 || element.TimeData_53 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_53 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_53 < 1.6 || element.TimeData_53 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_53 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_53 < 1.6 || element.TimeData_53 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_53 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_53 < 1.6 || element.TimeData_53 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_53 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_53 < 1.6 || element.TimeData_53 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_53 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_53}} 
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="TimeData_54">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[53]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_54 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_54 < 1.6 || element.TimeData_54 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_54 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_54 < 1.6 || element.TimeData_54 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_54 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_54 < 1.6 || element.TimeData_54 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_54 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_54 < 1.6 || element.TimeData_54 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_54 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_54 < 1.6 || element.TimeData_54 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_54 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_54}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_55">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[54]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_55 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_55 < 1.6 || element.TimeData_55 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_55 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_55 < 1.6 || element.TimeData_55 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_55 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_55 < 1.6 || element.TimeData_55 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_55 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_55 < 1.6 || element.TimeData_55 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_55 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_55 < 1.6 || element.TimeData_55 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_55 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_55}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_56">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[55]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_56 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_56 < 1.6 || element.TimeData_56 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_56 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_56 < 1.6 || element.TimeData_56 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_56 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_56 < 1.6 || element.TimeData_56 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_56 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_56 < 1.6 || element.TimeData_56 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_56 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_56 < 1.6 || element.TimeData_56 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_56 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_56}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_57">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[56]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_57 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_57 < 1.6 || element.TimeData_57 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_57 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_57 < 1.6 || element.TimeData_57 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_57 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_57 < 1.6 || element.TimeData_57 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_57 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_57 < 1.6 || element.TimeData_57 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_57 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_57 < 1.6 || element.TimeData_57 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_57 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_57}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_58">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[57]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_58 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_58 < 1.6 || element.TimeData_58 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_58 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_58 < 1.6 || element.TimeData_58 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_58 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_58 < 1.6 || element.TimeData_58 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_58 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_58 < 1.6 || element.TimeData_58 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_58 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_58 < 1.6 || element.TimeData_58 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_58 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_58}} 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="TimeData_59">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[58]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_59 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_59 < 1.6 || element.TimeData_59 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_59 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_59 < 1.6 || element.TimeData_59 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_59 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_59 < 1.6 || element.TimeData_59 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_59 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_59 < 1.6 || element.TimeData_59 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_59 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_59 < 1.6 || element.TimeData_59 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_59 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_59}} 
                  </td>
                </ng-container>

                <ng-container matColumnDef="TimeData_60">
                  <th mat-header-cell *matHeaderCellDef> 
                    <div>{{timeHeader[59]}}</div>
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  [ngStyle]="{'background-color': 
                    (element.Key == 'OC(051) 63HS2' && element.TimeData_60 > 30) 
                    || (element.Key == 'IC(001) TH2' && (element.TimeData_60 < 1.6 || element.TimeData_60 > 8.9)) 
                    || (element.Key == 'IC(001) SH' && element.TimeData_60 > 18) 
                    || (element.Key == 'IC(002) TH2' && (element.TimeData_60 < 1.6 || element.TimeData_60 > 8.9)) 
                    || (element.Key == 'IC(002) SH' && element.TimeData_60 > 18) 
                    || (element.Key == 'IC(003) TH2' && (element.TimeData_60 < 1.6 || element.TimeData_60 > 8.9)) 
                    || (element.Key == 'IC(003) SH' && element.TimeData_60 > 18) 
                    || (element.Key == 'IC(004) TH2' && (element.TimeData_60 < 1.6 || element.TimeData_60 > 8.9)) 
                    || (element.Key == 'IC(004) SH' && element.TimeData_60 > 18) 
                    || (element.Key == 'IC(005) TH2' && (element.TimeData_60 < 1.6 || element.TimeData_60 > 8.9)) 
                    || (element.Key == 'IC(005) SH' && element.TimeData_60 > 18) 
                    ? 'pink' : 'none'}"> 
                    {{element.TimeData_60}} 
                  </td>
                </ng-container>
                

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>