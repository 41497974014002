import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteAlertMetricsData } from 'src/app/common/classes/Metrics';
import { SiteNotification } from '../../components/site-alerts/classes/sitenotification';
import { HttpClient } from '@angular/common/http';
import { WebserviceURL } from 'src/app/constants/webservice';

@Injectable({ providedIn: 'root' })
export class DashboardResolver implements Resolve<SiteAlertMetricsData> {
    constructor(private http: HttpClient) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const siteId: string = route.paramMap.get('siteId');
        return this.http.get<SiteNotification[]>(WebserviceURL + 'site/alerts?site_id=' + siteId);
    }
}