<ion-header>
  <ion-toolbar>
    <ion-title id="title" name="title">{{steps[currentStep].title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- HEADER -->
  <ion-grid me-header-grid>
    <ion-row class="registration-step-counter ion-justify-content-around">
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= 1 ? 'me-current-process-step' : 'me-process-step'" name="mail"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= 1 ? 'me-current-process-step-text' : 'me-process-step-text'">Enter Email Address</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">-</ion-col>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= 2 ? 'me-current-process-step' : 'me-process-step'" name="cloud-upload"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= 2 ? 'me-current-process-step-text' : 'me-process-step-text'">Confirm Site & Gateways</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">-</ion-col>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= 3 ? 'me-current-process-step' : 'me-process-step'" name="list"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= 3 ? 'me-current-process-step-text' : 'me-process-step-text'">Summary</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid transfer-body>
    <!-- STEP 0 -->
    <div *ngIf="currentStep == 0">
      <ion-row class="modalTitleRow">
        <ion-col class="modalTitleCol" col col-12 me-header-col>
          <div class="transfer-header1">Transfer ownership of this site</div>
          <ion-label color="secondary">You are initiating the transfer of the following site to another person:</ion-label>
          <p class="siteToTransfer" title="{{this.user.active.name}}"><strong>{{this.user.active.name}}</strong></p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12>
          <p>Transferring ownership of a <span translate="no">kenza cloud</span>&#8482; site means you will lose all rights to access, view, and manage this site and its
            gateway(s).  The new owner will have the sole right over the <span translate="no">kenza cloud</span>&#8482; site, its gateway(s) and its services.</p>
          <p>On transferring this site to the new site owner, we will refund a prorated amount for your remaining billing cycle for applicable paid subscription plan.</p>
        </ion-col>
      </ion-row>
    </div>

    <!-- STEP 1 -->
    <div *ngIf="currentStep == 1">
      <form [formGroup]="emailForm">
        <ion-row>
          <ion-col col col-12 me-header-col>
            <ion-label color="secondary">Enter the information below for the new site owner</ion-label>
            <input type="text" hidden formControlName="username" id="username" value="{{user.username}}">
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col col col-12>
            <div id="siteTransferSenderEmailFieldContainer" style="min-height: 83px;">
                <ion-input type="text" id="email1" formControlName="email1" (keydown.space)="$event.preventDefault()" (ionInput)="emailFieldUpdated()" class="form-control iInputEmailSiteTransferSender siteTransferSenderEmailField automationTesting" labelPlacement="floating">
                  <ion-text slot="label" color="secondary">Email<sup validation-error>*</sup></ion-text>
                </ion-input>
              <div validation-error [hidden]="!((emailForm.controls.email1.hasError('required') && emailForm.controls.email1.touched) || (emailForm.controls.email1.hasError('required') && emailForm.controls.email1.touched))">Email address is required</div>
              <div validation-error [hidden]="!(!emailForm.controls.email1.valid && (!emailForm.controls.email1.hasError('required') && (emailForm.controls.email1.dirty)) && !emailForm.controls.email1.hasError('emailMatchesOwner'))">Enter a valid email address</div>
              <div validation-error [hidden]="!(emailForm.controls.email1.hasError('emailMatchesOwner') && !(emailForm.controls.email1.hasError('validateEmail') && (!emailForm.controls.email1.hasError('required') && (emailForm.controls.email1.dirty))))">Email address cannot be current site owner</div>
            </div>
            <div id="siteTransferSenderEmailConfirmFieldContainer" style="min-height: 83px;">
                <ion-input type="text" id="email2" formControlName="email2" (keydown.space)="$event.preventDefault()" (paste)="(false)" (ionInput)="emailConfirmFieldUpdated()" class="form-control iInputEmailSiteTransferSenderConfirm siteTransferSenderEmailConfirmField automationTesting" labelPlacement="floating">
                  <ion-text slot="label" color="secondary">Confirm Email<sup validation-error>*</sup></ion-text>
                </ion-input>
              <div validation-error *ngIf=" (emailForm.controls.email2.hasError('required') && emailForm.controls.email2.touched) || (emailForm.controls.email2.hasError('required') && emailForm.controls.email2.touched)">Email address is required</div>
              <div validation-error *ngIf="emailForm.controls.email2.hasError('pattern') && (!emailForm.controls.email2.hasError('required') && (emailForm.controls.email2.dirty))">Enter a valid email address</div>
              <div validation-error *ngIf="emailForm.controls.email2.hasError('emailsDontMatch') && !(emailForm.controls.email2.hasError('validateEmail') && (!emailForm.controls.email2.hasError('required') && (emailForm.controls.email2.dirty)))">Email address mismatch</div>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </div>

    <!-- STEP 2 -->
    <div *ngIf="currentStep == 2">
      <form [formGroup]="confirmForm">
        <ion-row>
          <ion-col col col-12 me-header-col>
            <ion-label  *ngIf="gateways.length > 0" color="secondary">Confirm the gateways below are to be transferred along with the site.</ion-label>
          </ion-col>
        </ion-row>
          
        <ion-row *ngFor="let gw of gateways">
          <ion-col col col-12>
            <ion-grid bottom-border-light>
              <ion-row>
                <ion-col col col-6>
                  <strong>Gateway</strong>
                </ion-col>
                <ion-col col col-6>
                  <strong>Current <span translate="no">kenza</span> cloud Plan</strong>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col col col-6>
                  <ion-label>{{gw.name}}</ion-label>
                  <br>
                  <ion-label>{{gw.serial}}</ion-label>
                </ion-col>
                <ion-col class="gwSubTypeCol" col col-6  *ngIf="gw.licenses.length == 0">
                      <ion-icon *ngIf="gw.coupons.length > 0" name="pricetag"></ion-icon>
                      <div *ngIf="gw.isExpired">
                        <ion-label class="gwSubType me-expired-limited-plan">Expired</ion-label>
                      </div>
                      <div *ngIf="!gw.isExpired">
                        <ion-label class="gwSubType">{{gw.subType}} </ion-label>
                        <ion-label [class]="gw.coupons.length > 0 ? 'gwSubTypePrice' : ''">{{ gw.monthlyRate | currency }}</ion-label>
                      </div>
                </ion-col>
                <ion-col class="gwSubTypeCol"  *ngIf="gw.licenses.length > 0">
                  <i class="fa-solid fa-ticket-simple"></i>
                  <ion-label>{{gw.licenses[0]?.type.name}}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="gatewaysWithCoupons.length > 0" me-gateway-label>
          <ion-col size="1">
          </ion-col>
          <ion-col col col-8 me-header-col me-gateway-label>
            <ion-label>
              <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
                have coupons applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="1">
          </ion-col>
        </ion-row>
        <ion-row *ngIf="gatewaysWithLicenses.length > 0" me-gateway-label>
          <ion-col size="1">
          </ion-col>
          <ion-col col col-8 me-header-col me-gateway-label>
            <ion-label>
                <i class="fa-solid fa-ticket-simple margin-right "></i><i>Gateway(s) above
                have licenses applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="1">
          </ion-col>
        </ion-row>

        <ion-row *ngIf="gateways.length == 0">
          <ion-col col col-12 modal-warning-message-container>
            <div modal-warning-message-content>
              <p>There are no gateways on this site to be transferred.</p>
            </div>
          </ion-col>
        </ion-row>
        
        <ion-row>
          <ion-col col col-12>
            <ion-item>
              <ion-label *ngIf="gateways.length > 0" align-right slot="end">I confirm this site and gateway(s) are to be transferred.</ion-label>
              <ion-label *ngIf="gateways.length == 0" align-right slot="end">I confirm this site is to be transferred.</ion-label>
              <ion-checkbox class="iChkConfirmTransferSite transferSiteConfirmCheckbox automationTesting" slot="end" id="transfer_site" formControlName="transferSite"
                (ionChange)="transferConfirmed($event)">
              </ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
      </form>
    </div>

    <!-- STEP 3 -->
    <div *ngIf="currentStep == 3">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="secondary">You have successfully initiated transferring your site.</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12>
          <ion-item-group modal-center-item-list>
            <ion-item>
              <ion-label>Email Sent</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item>
              <ion-label>Site Ownership Transfer In Progress</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          Your transfer of site ownership to <strong>{{emailAddr}}</strong> has begun. They will receive an email request to accept ownership, which will remain valid for 24 hours. We will notify you of their response.
        </ion-col>
      </ion-row>
    </div>

  </ion-grid>
</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <div *ngIf="currentStep != 3">
          <ion-button class="iBtnSiteTransferSenderPrevious siteTransferSenderPreviousButton automationTesting btnText-{{ steps[currentStep].prevBtnText }}" id="btnPrevStep" name="btnPrevStep" style="margin: 0 0 0 1em;" color="primary"
            [hidden]="!steps[currentStep].prevBtnShown" (click)="prevStep()">
            {{ steps[currentStep].prevBtnText }}</ion-button>
          <ion-button class="iBtnSiteTransferSenderNext siteTransferSenderNextButton automationTesting btnText-{{ steps[currentStep].nextBtnText }} {{ !steps[currentStep].nextBtnEnabled ? 'functionDisabled' : 'functionEnabled' }}" id="btnNextStep" name="btnNextStep" style="margin: 0 0 0 1em;" color="primary"
            [disabled]="!steps[currentStep].nextBtnEnabled" (click)="nextStep()">
            {{ steps[currentStep].nextBtnText }}</ion-button>
          <ion-button class="iBtnSiteTransferSenderCancel siteTransferSenderCancelButton automationTesting" id="btnCancel" name="btnCancel" (click)="onCancel()" style="margin: 0 0 0 1em;"
            color="reverse-primary">Cancel</ion-button>
        </div>
        <div *ngIf="currentStep == 3">
          <ion-button class="iBtnSiteTransferSenderClose siteTransferSenderCloseButton automationTesting" id="btnClose" name="btnClose" (click)="onCancel()" style="margin: 0 0 0 1em;"
            color="reverse-primary">Close</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>