<ion-header>
  <ion-toolbar>
    <ion-title>
      {{currentStepUILayout.stepTitle}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- Default backdrop -->
  <form [formGroup]="decommissionForm" [ngSwitch]="currentStepUILayout.stepIndex">
    <!--Header Section -->
    <ion-grid me-header-grid>
      <ion-row class="registration-step-counter ion-justify-content-around">
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconGateway" name="iconGateway"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.GatewayStep) ? 'me-current-process-step' : 'me-process-step' "
                name="cloud-upload"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.GatewayStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Gateway</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
        </ion-col>
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconPay" name="iconPay"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.DecommissionStep) ? 'me-current-process-step' : 'me-process-step' "
                name="remove-circle-sharp"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.DecommissionStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Decommission</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
        </ion-col>
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconPay" name="iconPay"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.SummaryStep) ? 'me-current-process-step' : 'me-process-step' "
                name="list"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayDecommissionStepEnum.SummaryStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Summary</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>

    </ion-grid>

    <!--Information Step-->
    <ion-grid *ngSwitchCase="gatewayDecommissionStepEnum.InformationStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger">
            You are decommissioning<br>your gateway(s).
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label>
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <ion-label class="me-sub-header-padding">
            Note the following:
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12>
          <ul>
            <li class="me-li">
              <ion-label>
                Upon decommissioning a gateway, data will no longer be recorded for any connected HVAC equipment.
              </ion-label>
            </li>
            <li class="me-li">
              <ion-label>
                Historical data will still be accessible for view access only.
              </ion-label>
            </li>
            <li class="me-li">
              <ion-label>
                Once a gateway is decommissioned, it will be immediately available to register again.
              </ion-label>
            </li>
          </ul>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Gateway & Decommission Step-->
    <ion-grid
      *ngSwitchCase="[gatewayDecommissionStepEnum.GatewayStep,gatewayDecommissionStepEnum.DecommissionStep].includes(currentStepUILayout.stepIndex) ? currentStepUILayout.stepIndex : -1  ">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger" *ngIf="currentStepUILayout.stepIndex==gatewayDecommissionStepEnum.GatewayStep">
            You are decommissioning<br>the following gateway(s):
          </ion-label>
          <ion-label color="danger" *ngIf="currentStepUILayout.stepIndex==gatewayDecommissionStepEnum.DecommissionStep">
            Confirm you want to decommission<br>the following gateway(s):
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let sp of subscriptionPlans">
        <ion-col col col-12>
          <ion-grid>
            <ion-row>
              <ion-col col col-6>
                <strong>Gateway</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>Current Subscription Plan</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="decommissionGatewayNameColumn textOverflow" col col-6>
                {{sp.name}}
              </ion-col>
              <ion-col col col-6>
                <ion-icon *ngIf="sp?.has_coupon" name="pricetag" me-demo-pricetag-footer></ion-icon>
                <ion-label>{{ getCurrentSubscription(sp)?.currentSubscriptionName }} </ion-label>
                <ion-label [style.text-decoration]="sp?.has_coupon ? 'line-through': 'none'">
                  {{ getCurrentSubscription(sp)?.monthly_rate | currency }}/mo
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                (S/N: {{sp.serial_number}}) <i *ngIf="sp?.has_coupon">({{sp?.coupon_name}})</i>
              </ion-col>
              <ion-col col col-6>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                <strong>Site Name</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>Current Payment Method</strong>
              </ion-col>
            </ion-row>
            <ion-row bottom-border-light>
              <ion-col col col-6>
                {{sp.site_name}}
              </ion-col>
              <ion-col col col-6 *ngIf="sp.paymentMethod && !sp.has_coupon">
                {{sp.paymentMethod.card_type | titlecase }} ending in {{sp.paymentMethod.last_four}} Exp.
                {{sp.paymentMethod.expiration}}
              </ion-col>
              <ion-col col col-6 *ngIf="!sp.paymentMethod || sp.has_coupon">
                n/a
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="currentStepUILayout.stepIndex==gatewayDecommissionStepEnum.DecommissionStep">
        <ion-col col col-12>
          <!-- <ion-item> -->
            <!-- <ion-label style="text-align: right; pointer-events: none;" slot="end">Decommission gateway(s)</ion-label> -->
            <ion-checkbox 
              id="chkDecommissionGateways" 
              formControlName="decommission_gateways"
              (ionChange)="setReadyToDecommission()"
              justify="end"
              me-gateway-decomission-chkbox
              >Decomission gateway(s)
            </ion-checkbox>
          <!-- </ion-item> -->
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Summary Step-->
    <ion-grid *ngSwitchCase="gatewayDecommissionStepEnum.SummaryStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-item-group>
            <ion-item>
              <ion-label>Gateway(s) Confirmed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item *ngIf="!noPaymentNeeded">
              <ion-label>Gateway(s) Decommissioned</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <!-- <ion-item>
              <ion-label>You will receive an email confirmation shortly.</ion-label>
            </ion-item> -->
          </ion-item-group>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row
      *ngIf="atLeastOneCoupon && currentStepUILayout.stepIndex!=gatewayDecommissionStepEnum.InformationStep && currentStepUILayout.stepIndex!=gatewayDecommissionStepEnum.SummaryStep"
      me-gateway-label>
      <ion-col size="2">
      </ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
            have coupons applied and are not subject to a recurring monthly subscription cost.</i>
        </ion-label>
      </ion-col>
      <ion-col size="2">
      </ion-col>
    </ion-row>
  </form>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-col class="flex ion-justify-content-end ion-align-items-center">
      <ion-label color="danger" *ngIf="decomissionError">{{decomissionError}}</ion-label>
      <ion-button id="btnBack" color="primary" (click)="handleBackButton()" *ngIf="currentStepUILayout.showBackButton"
        class="me-button-decommission-gateway">
        Back
      </ion-button>
      <ion-button id="btnNext" color="primary" (click)="handleNextButton()" *ngIf="currentStepUILayout.showNextButton"
        [disabled]="currentStepUILayout.stepIndex==gatewayDecommissionStepEnum.DecommissionStep && !readyToDecommission"
        class="me-button-decommission-gateway">
        {{currentStepUILayout.nextButtonText}}
      </ion-button>
      <ion-button id="btnCancel" color="reverse-primary" (click)="handleCancelButton()"
        *ngIf="currentStepUILayout.showCancelButton">
        Cancel
      </ion-button>
      <ion-button id="btnClose" color="reverse-primary" (click)="handleCloseButton()"
        *ngIf="currentStepUILayout.showCloseButton">
        Close
      </ion-button>
    </ion-col>
  </ion-grid>
</ion-footer>