<ion-header>
  <ion-toolbar>
    <ion-title>Edit Profile Information</ion-title>
    <!-- <ion-toggle *ngIf="devEnv" class="devToggles devOnlyToggles" [(ngModel)]="useMaskito" [enableOnOffLabels]="true" title="Dev Only Temporary Toggle, Will Remove Later" slot="end" style="padding-right: 20px;">
      Dev Only: Use Maskito
    </ion-toggle> -->
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="accountDetailForm" (ngSubmit)="onSubmit()" class="accountDetailForm">
    <ion-grid class="profileGridContainer">
      <ion-row>
        <ion-col col col-12>
          <ion-input type="text" class="form-control" id="email" formControlName="email"  labelPlacement="floating">
            <ion-text slot="label" for="email" color="secondary">Email</ion-text>
          </ion-input>
        <div validation-error *ngIf="accountDetailForm.controls.email.hasError('required')">
              Email is required
        </div>
        <div validation-error *ngIf="accountDetailForm.controls.email.hasError('email')">
              Please enter a valid Email Address
        </div>
        </ion-col>
      </ion-row>
      <ion-row class="validationRow">
        <ion-col col col-6>
          <ion-input type="text" minlength="2" maxlength="50" class="form-control" id="fname" formControlName="fname" (ionBlur)="trim($event)" labelPlacement="floating">
            <ion-text slot="label" for="fname" color="secondary">First Name</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.fname.errors">
            First Name must be between 2 and 50 characters
          </div>
        </ion-col>
        <ion-col col col-6>
          <ion-input type="text" minlength="2" maxlength="50" class="form-control" id="lname" formControlName="lname" (ionBlur)="trim($event)" labelPlacement="floating">
            <ion-text slot="label" for="lname" color="secondary">Last Name</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.lname.errors">
            Last Name must be between 2 and 50 characters
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="phoneRow validationRow">
        <ion-col col col-6>
          <ion-input type="text" minlength="2" maxlength="50" class="form-control" id="title" formControlName="title" (ionBlur)="trim($event)" labelPlacement="floating">
            <ion-text slot="label" for="title" color="secondary">Title</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.title.errors">
            Title must be between 2 and 50 characters
          </div>
        </ion-col>
        <ion-col col col-6>
          <app-text-mask formControlName="phone" [control]="accountDetailForm.controls.phone" [formGroup]="accountDetailForm" extraLabel="Personal" [useMaskito]="useMaskito" />
          <div validation-error
            *ngIf="!accountDetailForm.controls.phone.valid && accountDetailForm.controls.phone.dirty">
            Please enter a valid phone number
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
<ion-footer class="ion-margin-top">
  <ion-toolbar>
    <ion-button slot="end" [disabled]="!accountDetailForm.valid || !saveEnabled" class="editProfileInfoSave automationTesting" (click)="onSubmit()">
      {{ accountDetailText }}
    </ion-button>
    <ion-button slot="end" color="reverse-primary" class="editProfileInfoCancel ion-padding-horizontal automationTesting" (click)="onCancel()">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>