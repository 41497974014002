<ion-content class="ion-no-padding" main-white-background>

  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up"></ion-toolbar>
  <ion-toolbar color="white-background">
    <ion-title color="dark">
      <h1>{{ reportTitle }} Details</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/account', account_id, 'details', 'analytics-reports']" [queryParams]="{fromSiteId: returnToSiteId}" me-button-as-link
        id="btnBackToSiteAnalytics" name="btnBackToSiteAnalytics">
        &#8592;&nbsp;Back to Analytics Report History</ion-button>
    </ion-buttons>
  </ion-toolbar>
  
  <ion-grid class="reportDetails me-grid-main">
    <ion-row>
      <ion-col size="5">
        <!-- Column1 -->
        <ion-grid>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Site Name:</ion-text>
            </ion-col>
            <ion-col class="headerData oflow">
              <div *ngIf="reportDetail?.linkEnabled" title="Go to Site {{ reportDetail?.siteName }}" class="linkLike oflow" (click)="navigateToSite()" tappable>{{ reportDetail?.siteName }}</div>
              <div *ngIf="!reportDetail?.linkEnabled" title="{{ reportDetail?.siteName }}" class="oflow">{{ reportDetail?.siteName }}</div>       
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>ODU Name:</ion-text>
            </ion-col>
            <ion-col class="headerData oflow">
              <div class="oflow" title="{{ reportDetail?.oduName }}">{{ reportDetail?.oduName }}</div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Model Name:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text>{{ reportDetail?.oduModel }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="reportDetail?.isActive() && (testRunMaintenance || isRC())">
            <ion-col class="headerLabel">
              <ion-text>Time Remaining:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text>{{ renderProgressMessage() }}</ion-text>
            </ion-col>
          </ion-row>
          <!-- <ion-row *ngIf="devEnv && reportDetail && reportDetail.trueDuration && completedChart() && !errorOrCanceled()">
            <ion-col class="headerLabel">
              <ion-text>True Duration:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text>{{ reportDetail?.trueDuration }}</ion-text>
            </ion-col>
          </ion-row> -->
        </ion-grid>
      </ion-col>
      <ion-col size="5">
        <!-- Column2 --->
        <ion-grid>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Start Time:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text title="{{ reportDetail?.startTitle }}">{{ reportDetail?.startString }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Status:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text *ngIf="!reportInProcess()">{{ reportDetail?.statusString }}</ion-text>
              <ion-text *ngIf="reportInProcess()">{{ reportDetail?.statusString }} ({{ reportDetail?.progressTitle }})</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Mode:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text>{{ reportDetail?.mode | titlecase }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="headerLabel">
              <ion-text>Duration:</ion-text>
            </ion-col>
            <ion-col class="headerData">
              <ion-text>{{ reportDetail?.duration }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size="2">
        <!-- Column3 -->
        <ion-button *ngIf="testRunMaintenance == true && reportInProcess()" [disabled]="cancelRequested" (click)="cancelTestRun()">
          Cancel {{ reportTitle }}
        </ion-button>
        <ion-button id="btnValueDetail" name="btnValueDetail" (click)="valueDetails()" color="secondary" title="{{ testRunMaintenance == true ? 'Value' : reportTitle }} Details">
          {{ testRunMaintenance == true ? 'Value' : shortTitle }} Details
        </ion-button>
        <!-- Not using this download csv button yet -->
        <!-- Its a future enhancement: KEN-4702  -->
        <!-- <ion-button *ngIf="reportDetail?.csvdownloadable && !dataLoading" (click)="actionCsvdownload()" color="secondary" title="Download CSV">
          Download CSV
        </ion-button> -->
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="testRunMaintenance == false && !dataLoading" class="reportDetailNotTestRun {{ isRC() ? 'rcReportDetail' : 'pwcReportDetail' }}">
    <div *ngIf="!dataLoading" id="analyticsReportContainer" class="analyticsReportContainer customHBar size10">
      <ion-row class="alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong>Progress:</strong> 
          <!-- <span *ngIf="reportDetail?.isActive()">
            {{ cleanedProgress() }}%
          </span>  -->
          <!-- <span *ngIf="!reportDetail?.isActive() && (!completedChart() && errorOrCanceled())" class="columnLabelValue">
            {{ reportDetail?.statusString }}
          </span> 
          <span *ngIf="!reportDetail?.isActive() && (completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ cleanedProgress() >= 100 ? 'Complete' : '' }}
          </span> -->
          <div class="progressBarAndStatusContainer columnLabelValue">
            <div class="progressBarAndStatus innerColumnProgressBarAndStatus size12 {{ completedChart() ? 'completedProgressBar' : 'incompleteProgressBar' }} {{ reportDetail?.isActive() ? 'inProgressProgressBar' : '' }}">
              <ion-label class="progressIconAndLabel">
                <ion-spinner 
                  name="lines" 
                  color="primary" 
                  class="progressIcon"
                  *ngIf="(reportDetail?.isActive() && progress >= 0) || !(progress >= 1) && (progress >= 0 && progress < 1)" 
                />
                <ion-icon 
                  color="success"
                  name="checkmark"
                  class="progressIcon"
                  *ngIf="!reportDetail?.isActive() && (progress >= 1) && !errorOrCanceled()"
                />
                <ion-icon 
                  color="danger" 
                  name="close-circle"
                  class="progressIcon"
                  *ngIf="errorOrCanceled() || (progress < 0)"
                />
                <i *ngIf="progress >= 0 && cleanedProgress(1) < 100">{{ cleanedProgress() }}%</i>
                <i *ngIf="progress < 0 || cleanedProgress() >= 100">{{ reportDetail?.statusString }}</i>
              </ion-label>
              <ion-progress-bar 
                me-progress-bar 
                class="ionProgressBar"
                title="{{ reportDetail?.statusString }}"
                value="{{ errorOrCanceled() ? 1 : progress }}" 
                color="{{ (errorOrCanceled() || (progress < 0)) ? 'danger' : 'success' }}"
              />
            </div>
          </div> 
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isRC()" class="analyticsReportRow alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong>Target AL%:</strong> 
          <span class="columnLabelValue">{{ recommendations?.charged?.range }}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isBPC()" class="analyticsReportRow alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong title="Outdoor Coil Address">ODU Address:</strong> 
          <span class="columnLabelValue">{{ reportDetail?.ou_address }}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isBPC()" class="analyticsReportRow alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong title="Branch Controller Address">BC Address:</strong> 
          <span *ngIf="!reportDetail?.isActive() && (!completedChart() && errorOrCanceled())" class="columnLabelValue">
            {{ notApplicableString }}
          </span>
          <span *ngIf="reportDetail?.isActive() && (!completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ reportDetail?.statusString }}...
          </span>
          <span *ngIf="!reportDetail?.isActive() && (completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ tableData.tableRows.rows && tableData.tableRows.rows.length > 0 ? tableData.tableRows.rows[0].unit[0]?.branchAddress : bpcInfoLoading ? 'Loading...' : '?' }}
          </span>
        </ion-col>
      </ion-row>
      <!-- This row will just be an empty spacer for now -->
      <ion-row *ngIf="isBPC()" class="analyticsReportRow analyticsReportRowHidden alPercentageLabelRow">
        <!-- <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong title="Sub Branch Address">BS Address:</strong> 
          <span *ngIf="!reportDetail?.isActive() && (!completedChart() && errorOrCanceled())" class="columnLabelValue">
            {{ notApplicableString }}
          </span>
          <span *ngIf="reportDetail?.isActive() && (!completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ reportDetail?.statusString }}...
          </span>
          <span *ngIf="!reportDetail?.isActive() && (completedChart() && !errorOrCanceled())" class="columnLabelValue">
            ?
          </span>
        </ion-col> -->
      </ion-row>
      <ion-row *ngIf="isRC()" class="analyticsReportRow alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong>Actual AL%:</strong> 
          <span *ngIf="reportDetail?.isActive() && (!completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ reportDetail?.statusString }}...
          </span> 
          <span *ngIf="!reportDetail?.isActive() && (!completedChart() && errorOrCanceled())" class="columnLabelValue">
            {{ notApplicableString }}
          </span> 
          <span *ngIf="!reportDetail?.isActive() && (completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ cleanedALPercentage() }}%
          </span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isRC()" class="analyticsReportRow alPercentageLabelRow">
        <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
          <strong>Result:</strong> 
          <span *ngIf="reportDetail?.isActive() && (!completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ reportDetail?.statusString }}...
          </span> 
          <span *ngIf="!reportDetail?.isActive() && (!completedChart() && errorOrCanceled())" class="columnLabelValue">
            {{ notApplicableString }}
          </span> 
          <span *ngIf="!reportDetail?.isActive() && (completedChart() && !errorOrCanceled())" class="columnLabelValue">
            {{ recommendation?.resultTitle }}
          </span>
        </ion-col>
      </ion-row>
      <ion-grid *ngIf="isRC()" class="customHBARChartGrid HBarProgressContainer ionNoPadding {{ devEnv && completedChart() ? 'withExtraRow' : 'noExtraRow' }}">
        <!-- <ion-grid *ngIf="devEnv && completedChart()" class="devOnlyALPercentageRangeSlider ionNoPadding">
          <ion-row class="alPercentageRangeSliderRow">
            <ion-col class="alColumn ionNoPadding alPercentageColumnLabel">
              <strong>Dev Only Range Slider</strong>
            </ion-col>
          </ion-row>
          <ion-row class="alPercentageRangeSliderRow">
            <ion-range color="secondary" (ionInput)="changePercentage($event)" [(ngModel)]="alPercentage" step="0.1" pin="false" />
          </ion-row>
        </ion-grid> -->
        <ion-row class="customHBARChartRow {{ completedChart() ? 'completedChart' : 'incompleteChart' }} {{ reportDetail?.isActive() ? 'inProgressChart' : '' }}">
          <div class="customHBARChart" title="{{ completedChart() ? 'AL Percentage Chart: ' + alPercentage + '%' : 'AL Percentage Chart' }}">
            <div *ngIf="completedChart()" class="customHBARChartLine" [style]="'left: ' + alPercentage + '%;'">
              <div class="alPercentageTooltip {{ alPercentage >= 88 ? 'reversedTooltip' : '' }}">
                AL%: {{ cleanedALPercentage() }}%
              </div>
            </div>
          </div>
          <div class="customHBARChartAxesLabels">
            <div class="HBARChartAxisLabel HBARChartAxisLabelXStart">0%</div>
            <div class="HBARChartAxisLabel HBARChartAxisLabelXEnd">100%</div>
          </div>
        </ion-row>
        <ion-row class="alPercentageMessageRow">
          <ion-col class="alColumn ionNoPadding alPercentageMessage">
            <strong>Recommendation:</strong>
            <span *ngIf="reportDetail?.isActive() && (!completedChart() && !errorOrCanceled())" class="columnLabelValue">
              {{ reportDetail?.statusString }}...
            </span>
            <span *ngIf="!completedChart() && errorOrCanceled()" class="columnLabelValue">{{ notApplicableString }}</span>
          </ion-col>
        </ion-row>
        <ion-grid *ngIf="completedChart() && !errorOrCanceled()" class="ionNoPadding">
          <ion-row class="alPercentageMessageRow">
            <ion-col class="alColumn ionNoPadding alPercentageMessage">
              {{ recommendation?.range }}: {{ recommendation?.messageTitle }}
            </ion-col>
          </ion-row>
          <ion-row class="alPercentageMessageRow">
            <ion-col class="alColumn ionNoPadding alPercentageMessage">
              {{ recommendation?.recommendationMessage }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-grid>
    </div>
  </div>

  <div class="reportDetailData reportDetailDataTable {{ reportDetail?.isActive() ? 'inProgressDataTable' : '' }}" [hidden]="isRC() || !showReportTable() || testRunMaintenance || errorOrCanceled()">
    <ion-grid *ngIf="isBPC()" id="{{ tableData?.id }}" class="customDataTable me-grid-main {{ tableData?.class }}" [style]="'width: ' + tableData?.width + '%;'" #{{tableData?.id}}>
      <ion-row class="tableHeaderRow">
        <ion-col *ngFor="let column of tableData?.headerRow?.columns; index as i;" class="customDataTableColumn {{ column?.class }}">
          <ion-icon *ngIf="column?.icon != 'none'" name="{{ column?.icon }}"></ion-icon> {{ column?.label }}
        </ion-col>
      </ion-row>
      <ion-grid *ngIf="tableData?.tableRows?.rows.length > 0" class="bpcGroupTableData customDataTableScrollContainer">
        <ion-row *ngFor="let row of tableData?.tableRows?.rows; index as idx;" class="tableEntryRow bpcTableDataRow">
          <ion-col class="customDataTableColumn {{ tableData?.headerRow?.columns[0]?.class }} oflow" title="{{ row?.name || row?.group }}">
            <span *ngIf="row?.name" class="customDataTableValue">{{ row?.name }}</span>
            <span *ngIf="!row?.name" class="customDataTableValue">
              <span *ngIf="gatewayInfoLoading || !gatewayInfoLoading && row.groups && row.groups.length == 0">
                Group {{ row.group }}
              </span>
              <span *ngIf="!gatewayInfoLoading && row.groups && row.groups.length > 0">
                {{ row.groups[idx]?.name ? row.groups[idx]?.name : 'Group ' + row.group }}
              </span>
            </span>
          </ion-col>
          <ion-col class="customDataTableColumn {{ tableData?.headerRow?.columns[1]?.class }} oflow" title="{{ row?.unit[0]?.branchPair }}">
            <span class="customDataTableValue">
              Branch Pair {{ row?.unit[0]?.branchPair }}
            </span>
          </ion-col>
          <ion-col class="customDataTableColumn {{ row.icAddress && row.icAddress.length > 0 ? 'customDataTableColumnMulti' : '' }} {{ tableData?.headerRow?.columns[2]?.class }} oflow" title="{{ tableData?.headerRow?.columns[2]?.label }} Name">
            <ion-grid *ngIf="row.unit && row.unit.length > 0" class="noIonPadding">
              <ion-grid *ngIf="row.unit[0]?.name" class="zeroOutGrid noIonPadding">
                <span *ngFor="let rowUnit of row.unit" title="{{ rowUnit?.name }}">{{ rowUnit?.name }}</span>
              </ion-grid>
              <span *ngIf="!row.unit[0]?.name && row.icAddress.length == 0">IDU {{ row.unit[0].address }}</span>
              <ion-grid *ngIf="!row.unit[0]?.name && row.icAddress.length > 0" class="zeroOutGrid noIonPadding">
                <span [hidden]="!(gatewayInfoLoading || !gatewayInfoLoading && row.units && row.units.length == 0) && !(!gatewayInfoLoading && (row.rowAddresses.length > 0 && row.rowAddresses.includes(unitAddressOrUnit.bus_address)))" *ngFor="let unitAddressOrUnit of row.icAddress" class="customDataTableValue">
                  <span *ngIf="gatewayInfoLoading || !gatewayInfoLoading && row.units && row.units.length == 0">
                    IDU {{ unitAddressOrUnit }}
                  </span>
                  <span *ngIf="!gatewayInfoLoading && (row.rowAddresses.length > 0 && row.rowAddresses.includes(unitAddressOrUnit.bus_address))">
                    {{ unitAddressOrUnit?.name }}
                  </span>
                </span>
              </ion-grid>
            </ion-grid>
          </ion-col>
          <ion-col class="customDataTableColumn {{ tableData?.headerRow?.columns[3]?.class }} oflow" title="{{ tableData?.headerRow?.columns[3]?.label }}: {{ judgeStatuses[row?.status]?.label }}{{ row.error > 0 ? ' ' + row.error : '' }}">
            <span class="rowStatusIcon customDataTableValue">
              <ion-icon 
                name="{{ judgeStatuses[row?.status]?.icon.name }}" 
                color="{{ judgeStatuses[row?.status]?.icon.color }}" 
                class="statusIcon {{ judgeStatuses[row?.status]?.status }}" 
              />
              <span class="rowStatusLabel_{{ row?.status }}">{{ judgeStatuses[row?.status]?.label }}</span>
            </span>
          </ion-col>
          <ion-col class="customDataTableColumn {{ tableData?.headerRow?.columns[4]?.class }} oflow" title="{{ tableData?.headerRow?.columns[4]?.label }}">
            <span *ngIf="row.error > 14 && row.messages && row.messages.length > 0" class="customDataTableValue extendedWidthField hasAddInfo">
              <ion-grid *ngFor="let errMsg of row.messages" title="{{ errMsg }}" class="errorMessagesContainer noIonPadding">
                <ion-icon 
                  color="{{ judgeStatuses[row?.status]?.icon.color }}" 
                  name="{{ judgeStatuses[row?.status]?.icon.altName }}" 
                  class="progressIcon tableStatusIcon {{ judgeStatuses[row?.status]?.status }}" 
                /> {{ errMsg }}
              </ion-grid>
            </span>
            <span *ngIf="row.error <= 14 && row.errors && row.errors.length > 0" class="customDataTableValue extendedWidthField hasAddInfo">
              <ion-grid *ngFor="let err of row.errors" title="{{ err?.message }}{{ err?.errorType != '-' ? ' - ' + err?.errorType : '' }}" class="errorMessagesContainer noIonPadding">
                <ion-icon 
                  color="{{ judgeStatuses[row?.status]?.icon.color }}" 
                  name="{{ judgeStatuses[row?.status]?.icon.altName }}" 
                  class="progressIcon tableStatusIcon {{ judgeStatuses[row?.status]?.status }}" 
                /> {{ err?.message }}
              </ion-grid>
            </span>
          </ion-col>
        </ion-row>  
      </ion-grid>
      <ion-grid *ngIf="tableData?.tableRows?.rows.length == 0" class="customDataTableScrollContainer">
        <ion-row class="tableEntryRow">
          <ion-col class="oflow" title="{{ bpcZeroStateMessage }}">
            <span class="customDataTableZeroStateMessage customDataTableValue">
              {{ reportDetail?.isActive() && (!completedChart() && !errorOrCanceled()) ? reportDetail?.statusString + '...' : bpcInfoLoading ? 'Loading...' : bpcZeroStateMessage }}
            </span>
          </ion-col>
        </ion-row>  
      </ion-grid>
    </ion-grid>
  </div>

  <div *ngIf="noData && !dataLoading && testRunMaintenance" class="reportDetailNoData">
    <ion-title>
      <ion-label id="zeroStateLoading" name="zeroStateLoading" class="loadingState">
        <i>No {{ reportTitle }} data is available.</i>
      </ion-label>
    </ion-title>  
  </div>

  <div *ngIf="dataLoading" class="reportDetailDataLoading">
    <ion-title>
      <ion-label id="zeroStateLoading" name="zeroStateLoading" class="loadingState">
        <i>Loading</i>
      </ion-label>
      <ion-spinner name="dots"  style="position: absolute; height: 40px"></ion-spinner>
    </ion-title>
  </div>

  <div class="reportDetailData table-area" [hidden]="!showReportTable() || !testRunMaintenance">
    <table #metricsTable mat-table [dataSource]="metrics" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- label/time Column -->
      <ng-container matColumnDef="label" sticky>
        <th class="col-width-left" mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell class="row-header" *matCellDef="let element"> {{ element.label }} </td>
      </ng-container>

      <!-- columns for each time slot -->
      <!-- now dynamically define the rest of the columns for each time slot -->
      <ng-container *ngFor="let slot of timeSlots" [matColumnDef]="slot.def">
        <th class="col-width" mat-header-cell *matHeaderCellDef> {{ slot.label }} </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'errorCell' : elementError(element, slot) }"> {{ element[slot.def]['cooked'] }} </td>
      </ng-container>
    
      <tr mat-header-row class="col-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
  
</ion-content>