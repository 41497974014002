import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from '../../sites/services/site.service';
import { ModalController } from '@ionic/angular';
import { MaintMapUnitsErrorComponent } from '../modals/maint-map-units-error/maint-map-units-error.component';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import { MODAL_MAINT_UPDATE_UNIT_BLOCK, MODAL_MAINT_UPDATE_UNIT_ERROR, TOAST_GENERAL_ERROR_TITLE } from 'src/app/constants/kenzaconstants';
import { TestType } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { MaintenanceBCPortListComponent } from 'src/app/features/maintenance/components/maintenance-bcport-list/maintenance-bcport-list.component';
import { MaintenanceRefrigerantListComponent } from 'src/app/features/maintenance/components/maintenance-refrigerant-list/maintenance-refrigerant-list.component';
import { MaintenanceTestRunComponent } from 'src/app/features/maintenance/components/maintenance-testrun/maintenance-testrun.component';
import { OutdoorunitService } from 'src/app/features/maintenance/service/outdoorunit.service';
import { FlatpickrOptions, Ng2FlatpickrComponent } from 'ng2-flatpickr';
import flatpickr from 'flatpickr/dist/flatpickr.min.js';
import moment from 'moment';
import { ToastMessageTypeEnum } from 'src/app/enumerations/enums';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})

export class MaintenanceComponent /*implements OnInit*/ {
  @ViewChild(MaintenanceBCPortListComponent)
  private maintenanceBCPortListComponent: MaintenanceBCPortListComponent;
  @ViewChild(MaintenanceRefrigerantListComponent)
  private maintenanceRefrigerantListComponent: MaintenanceRefrigerantListComponent;
  @ViewChild(MaintenanceTestRunComponent)
  private maintenanceTestRunComponent: MaintenanceTestRunComponent;
  @ViewChild('flatpickr') flatpickr?: Ng2FlatpickrComponent;

  displayedColumns: string[] = ['RadioButton', 'GatewayName', 'DeviceType', 'SerialNumber'];
  dataSourceM = [];
  dataSourceMverTB7 = [];

  options: FlatpickrOptions = {
    enableTime: true,      // Enable Time Selection
    time_24hr: true,       // Enable 24-hour notation
    dateFormat: 'Y/m/d(D)H:i',
    defaultDate: '',
    maxDate: ''
  };
  timeDif = new Date().getTimezoneOffset();                        // Time difference between Coordinated Universal Time and system time
  utcOffset = moment().tz(this.user.active.timezone).utcOffset();  // Time difference between Coordinated Universal Time and property time
  testTypes = [
    { title: "BC port check", typeId: TestType['bcPort'], component: null, isShow: true },
    { title: "Refrigerant check", typeId: TestType['refrigerant'], component: null, isShow: true },
    { title: "MTDZ/Spreadsheet", typeId: TestType['mtdz'], component: null, isShow: true },
    { title: "Tesn run", typeId: TestType['testRun'], component: null, isShow: false }
  ];
  selectType: number = TestType['bcPort'];
  showMTDZ = false;
  target: number;
  apicalling = false;
  message: string;

  constructor(
    public user: UserService,
    private siteService: SiteService,
    private modalController: ModalController,
    private router: Router,
    public _ref: ChangeDetectorRef,
    public outdoorunitService: OutdoorunitService
  ) { }

  ionViewWillEnter() {
    this.testTypes[Number(TestType['bcPort'])].component = this.maintenanceBCPortListComponent;
    this.testTypes[Number(TestType['refrigerant'])].component = this.maintenanceRefrigerantListComponent;
    this.testTypes[Number(TestType['testRun'])].component = this.maintenanceTestRunComponent;
    this.dataSourceM = [];
    this.dataSourceMverTB7 = [];
    this.apicalling = false;
    this.siteService.get_site_gateways_with_groups(this.user.active.id, true)
      .subscribe((gateways: GatewayWithGroups) => {
        this.setGateways(gateways);
    });

    if (!this.outdoorunitService.testType) {
      this.outdoorunitService.testType = TestType['bcPort'];
    }
    this.selectType = this.outdoorunitService.testType;
    // 2023.10.02
    // Release ready
    // Preventing selection of some features
    this.selectType = TestType['bcPort']
    this.show(Number(this.selectType));

    // now date and time（For flatpickr setting）
    const date = this.dateToString(new Date());
    this.options.defaultDate = new Date(date);
    this.options.maxDate = new Date(date);
  }

  ionViewWillLeave() {
    const newDataSource = [];
    this.dataSourceM = newDataSource;
    this._ref.detectChanges();
    this.outdoorunitService.testType = TestType['bcPort'];
  }

  async openMapUnitsError(err_msg) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: MaintMapUnitsErrorComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
      componentProps: {
        errorMessage: err_msg
      },
    });
    return await modal.present();
  }

  setGateways(gateways: GatewayWithGroups) {
    // OutdoorUnitデータ取得
    // Get OutdoorUnit Data
    let gatewaysWithGroups: Gateway[] = [];
    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      const newDataSource = [];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True' && gwg.outdoor_units.length > 0){
          const addData = {
            checked: false,
            SystemName: gwg.outdoor_units[0].name,
            ModelName: '',
            SerialNo: '',
            GatewayId: gwg.id,
            GatewaySerialNo: gwg.serial_number
          };
          newDataSource.push(addData);
        }
      });

      if(newDataSource.length > 0){
        newDataSource[0].checked = true;  // Make the radio button on the first line checked by default.
      }
      this.dataSourceM = newDataSource;
      this._ref.detectChanges();
    }
    // RMDデータ取得
    // RMD acquisition
    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      const newDataSourceVerTB7 = [];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True'){
          let gatewayName = '-';
          let deviceType = '-';
          let serialNumber = '-';
          if (gwg.name){
            gatewayName = gwg.name
          }
          if (gwg.model.name){
            deviceType = gwg.model.name
          }
          if (gwg.serial_number){
            serialNumber = gwg.serial_number
          }
          const addData = {
            Checked: false,
            GatewayName: gatewayName,
            DeviceType: deviceType,
            SerialNumber: serialNumber,
            GatewayId: gwg.id
          };
          newDataSourceVerTB7.push(addData);
        }
      });

      if(newDataSourceVerTB7.length > 0){
        newDataSourceVerTB7[0].Checked = true;  // The first line of the radio button is checked by default.
      }

      this.dataSourceMverTB7 = newDataSourceVerTB7;
      this._ref.detectChanges();
    }
  }

  PageTransition(pageName) {
    if (!this.siteService.handleIsConnected()){
      return;
    }

    if (this.apicalling){
      return;
    }

    let i = 0;
    this.target = -1;
    const allRadioButtons = document.querySelectorAll('input[name=\'radiobutton_mt\']') as NodeListOf<HTMLInputElement>;
    allRadioButtons.forEach(radio => {
      if(radio && radio.checked){
        this.target = i;
      }
      i++;
    });
    if(this.target >= 0){
      this.apicalling = true;

      if (this.showMTDZ == false){
        this.getRedisInfo(this.target, pageName);
      }
      if(this.showMTDZ == true && pageName === 'spreadsheet'){
        this.getRedisInfoTB7(this.target, pageName);
      }
      if(this.showMTDZ == true && pageName === 'MTDZ'){
        const fp = this.flatpickr.flatpickr as flatpickr;

        // 時刻未選択の場合エラー
        // Error when time is not selected
        if(!fp.selectedDates[0]){
          // alert('時刻を選択してください');
          // alert('Please select a time');
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            'Please select a date and time.'
          );
          this.apicalling = false;
          return;
        }
        this.getRedisInfoTB7(this.target, pageName);
      }
    }
  }

  getRedisInfo(targetNo, pageName){
    // 試運転データ取得restAPI呼出
    // Calling the test run data acquisition restAPI
    const serialNo = this.dataSourceM[targetNo].GatewaySerialNo;
    const gatewayId = this.dataSourceM[targetNo].GatewayId;
    this.siteService.checkLock(serialNo, this.user.active.id).subscribe(
      (resData: number) => {
        if (resData === 2100){
          this.apicalling = false;
          this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
        }else{
          this.siteService.getUnitHistoryTop(gatewayId).subscribe(
            (resData2: {[key:string]: number | boolean}) => {
              this.apicalling = false;
              if (resData2){
                this.router.navigate(['/maintenance', this.user.active.id, pageName], { queryParams:
                  { target: this.dataSourceM[this.target].GatewayId}});
              }else{
                this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_BLOCK);
              }
            },
            (err) => {
              this.apicalling = false;
              console.log('getUnitHistoryTop=>err', err);
            }
          );
        }
      },
      (err) => {
        this.apicalling = false;
        console.log('getRedisInfo=>err', err);
        this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
      }
    );
  }

  getRedisInfoTB7(targetNo, pageName){
    // 試運転データ取得restAPI呼出
    // Calling the test run data acquisition restAPI
    const serialNo = this.dataSourceMverTB7[targetNo].SerialNumber;
    const gatewayId = this.dataSourceMverTB7[targetNo].GatewayId;

    // 履歴の時刻を取得
    // Get history time
    const fp = this.flatpickr.flatpickr as flatpickr;
    const historyDate = fp.selectedDates[0];

    this.siteService.checkLock(serialNo, this.user.active.id).subscribe(
      (resData: number) => {
        if (resData === 2100){
          this.apicalling = false;
          this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
        }else{
          this.siteService.getUnitHistoryTop(gatewayId).subscribe(
            (resData2: {[key:string]: number | boolean}) => {
              this.apicalling = false;
              if (resData2){
                this.router.navigate(['/maintenance', this.user.active.id, pageName], { queryParams:{
                    target: this.dataSourceMverTB7[this.target].GatewayId,
                    historyDate: historyDate
                  }});
              }else{
                this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_BLOCK);
              }
            },
            (err) => {
              this.apicalling = false;
              console.log('getUnitHistoryTop=>err', err);
            }
          );
        }
      },
      (err) => {
        this.apicalling = false;
        console.log('getRedisInfo=>err', err);
        this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_ERROR);
      }
    );
  }

  show(i: number) {
    console.log('Select Menu : ' + this.testTypes[i].title);
    this.maintenanceBCPortListComponent.hide();
    this.maintenanceRefrigerantListComponent.hide();
    this.maintenanceTestRunComponent.hide();
    this.showMTDZ = false;

    if (this.testTypes[i].component !== null) {
      this.testTypes[i].component.show();
      this.testTypes[i].component.ionViewWillEnter();
    } else {
      this.showMTDZ = true;
    }
  }

  dateToString(date){
    // 日付型のデータを受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Takes data from Date type data and returns a string in format "YYYY/MM/DD hh:mm"
    const strYear = date.getFullYear();
    let strMonth = 1 + date.getMonth();
    let strDay = date.getDate();
    let strHour = date.getHours();
    let strMinute = date.getMinutes();

    strMonth = ('0' + strMonth).slice(-2);
    strDay = ('0' + strDay).slice(-2);
    strHour = ('0' + strHour).slice(-2);
    strMinute = ('0' + strMinute).slice(-2);

    let strFormat = 'YYYY/MM/DD hh:mm'
    strFormat = strFormat.replace(/YYYY/g, strYear);
    strFormat = strFormat.replace(/MM/g, strMonth);
    strFormat = strFormat.replace(/DD/g, strDay);
    strFormat = strFormat.replace(/hh/g, strHour);
    strFormat = strFormat.replace(/mm/g, strMinute);

    return strFormat;
  }
}
