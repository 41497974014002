import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class WindowService {
    width$: Observable<number>;
    height$: Observable<number>;

    constructor() {
        let windowSize$ = fromEvent(window, 'resize').pipe(startWith(0)); // Emit event on start

        this.width$ = windowSize$.pipe(
            map(event => window.innerWidth)
        );

        this.height$ = windowSize$.pipe(
            map(event => window.innerHeight)
        );
    }
}
