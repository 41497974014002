import { GatewayModelClass } from "src/app/enumerations/enums";

// track the state of the mtdz download through stages
export enum mtdzDownloadStage {
    noDownload = 0,
    startAnEEngine = 1,
    generateData = 2,
    downloadReady = 3,
    errorState = 4
}

// object that tracks a site based mtdz download of an ODU
export class mtdzDownloadTracker {
    site_id = '';
    site_name = '';
    request_id = '';
    gateway_id = '';
    gateway_model_class:GatewayModelClass = GatewayModelClass.MCC;
    startTime = 0;
    bus_address = 0;
    s3_file_name_to_download = '';
    error_msg = '';
    start_date = '';
    end_date = '';
    generate_progress = 0;
    stage: mtdzDownloadStage = mtdzDownloadStage.startAnEEngine;

    // for RMD we have an estimated end time
    estRunTime = 0;
}