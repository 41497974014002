
export class AccountDetail {
    account_photo_name: string;
    city: string;
    company: string;
    company_phone: string;
    country: string;
    created_at: string;
    email: string;
    fname: string;
    id: string;
    last_login: string;
    lname: string;
    phone: string;
    postal_code: string;
    removed_at: string;
    state: string;
    status: number;
    street_line1: string;
    street_line2: string;
    title: string;

    get display_name(): string {
        let displayName: string = '';

        if (this.fname || this.lname) {
            if (this.fname && this.lname) {
                displayName = this.fname + ' ' + this.lname;
            }

            if (this.fname) {
                displayName = this.fname;
            }

            if (this.lname) {
                displayName = this.lname;
            }
        } else {
            displayName = '';
        }

        return displayName;
    }

}


