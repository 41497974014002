/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'src/app/features/manage/components/classes/Subscription';
import { UnregisteredDevice } from 'src/app/features/manage/components/classes/UnregisteredDevice';
import { SubscriptionPlanService } from '../../services/subscription-plan/subscription-plan.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-plan-selector',
  templateUrl: './plan-selector.component.html',
  styleUrls: ['./plan-selector.component.scss'],
})
export class PlanSelectorComponent implements OnInit {

  @Output()
  selectionChanged = new EventEmitter<any>();

  @Input()
  gw: UnregisteredDevice | any;

  @Input()
  filterCurrent = false;

  plans: Subscription[];

  constructor(
    public planService: SubscriptionPlanService
  ) {
    this.plans = [];
  }

  // Create our number formatter.
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  ngOnInit() {
    this.planService.resetFilter();

    // using setTimeout to avoid changes to the planService.plans object WHILE the page is initially rendering
    setTimeout(() => {
      this.planService.filterDisplayByModelClass(this.gw.model_class_name, this.filterCurrent, this.gw.subscription_plan_name)

      this.plans = cloneDeep(this.planService.plans);

      // filter out plans that are a license type - only plan_type subscription are allowed to pick from
      this.plans = this.plans.filter((plan) => plan.plan_type == 'subscription');

      if(this.gw.hasTrialTimeLeft == false) {
        // filter out free plans if no trial bank left (KEN-3849)
        this.plans = this.plans.filter((plan) => plan.id == '' || plan.monthly_rate != 0); // default value has a rate of 0....
      }

      // create the plan cost values for display
      this.plans.forEach((plan) => {
        const plan_dollars = this.formatter.format(plan.monthly_rate);
        plan.cost_string = `${plan_dollars}/mo`;
        if (this.gw.has_coupon) {
          // update this thing to be striken through
          plan.cost_string = this.strikeThrough(plan.cost_string)
        }
      });
    });
  }


  strikeThrough(text: string): string {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }

  updateSubscription(e) {
    const kenzaId = e.target.value;
    this.gw.subscription_id = kenzaId;
    this.gw.new_subscription_plan_id = kenzaId;
    this.gw.kenza_plan_id = kenzaId;

    const selectedPlan = this.planService.plansKeyed[kenzaId];
    this.gw.new_subscription_plan_name = selectedPlan.name;

    this.selectionChanged.emit(selectedPlan);
  }

}
