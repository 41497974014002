/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  OnInit,
  OnDestroy,
  Component,
  ViewChild,
  ElementRef,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { UserService } from 'src/app/common/services/user/user.service';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { SiteAdminPage } from 'src/app/features/sites/components/site-admin/site-admin.page';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import {
  LevelEnum,
  GroupType,
  ModelState,
  GatewayUnitTwoDigitType,
  SiteNotificationTypeEnum,
  TemperaturePreferenceEnum,
  PageState,
  MaintenanceJobTypeEnumTitle,
  MaintenanceJobTypeEnum,
} from 'src/app/enumerations/enums';
import moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Site } from 'src/app/features/sites/classes/site';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { Log } from 'src/app/common/services/logging/log.service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { DataService } from 'src/app/common/services/data/DataService';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { maintenanceJobStatusEnum } from 'src/app/common/classes/MaintenanceJob';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { devEnv, TOAST_CONFIG_FULL_WIDTH } from 'src/app/constants/kenzaconstants';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { CONNECTION_UPDATE_TIMER_INTERVAL } from 'src/app/constants/kenzaconstants';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import { getNotificationHeader } from 'src/app/features/sites/components/site-alerts/classes/shared';
import { SiteTransferModal } from 'src/app/features/sites/pages/site-transfer-modal/site-transfer-modal.page';
import { SiteDeactivateComponent } from 'src/app/features/sites/pages/site-deactivate/site-deactivate.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../dashboard.shared.scss'],
})

export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('uploadDashboardPictureFile')
  uploadDashboardPictureFile: ElementRef;
  @ViewChild('selectGroupType') selectGroupType: ElementRef;
  @ViewChild('statusBarChart') statusBarChart: ElementRef;
  @ViewChild('canvasDonughnutChart') canvasDonughnutChart: ElementRef;
  @ViewChild('uploadAccountPicture') uploadAccountPicture: ElementRef;
  imageUrl: string;
  uploadDashboardPictureFileMessage: string;
  onActiveSiteChanged: Subscription = null;
  onRefreshSiteAlertData: Subscription = null;
  donughnutChart: Chart;
  site: Site;
  alertChart: Chart = null;
  siteNotificationTypeEnum = SiteNotificationTypeEnum;
  levelEnum = LevelEnum;
  includeImgInDOM = true;
  getNotificationHeader = getNotificationHeader

  gatewayConnectionStatus: Array<any> = [];
  gatewayDisconnectedCount = 0;
  gatewayTotal: string;
  connectionUpdateTimer: Observable<number> = null;
  noGatewaysDefined = false;
  allGatewaysExpiredFlag = false;
  timerSubscription: Subscription = null;
  kenzaLinkFailed = false;

  // Events Dashboard Card
  events_loading = true;
  events_noEventsDefined = true;
  events_events: Array<any> = [];

  // Group Status Variables
  allCoils;
  modalOpen;
  allProfiles;
  totalGroups;
  width: number;
  height: number;
  devEnv = devEnv;
  allProfileUnits;
  totalGateways = 0;
  gatewaysMappedNum;
  mapLoading = false;
  unitsMapped = false;
  timerUpdate = false;
  catchMapTimer = null;
  GroupType = GroupType;
  recentlyMapped = false;
  barChart: Chart = null;
  totalMappedGateways = 0;
  createdBarGraph = false;
  connectionStatusTick = 0;
  groupStatusLoading = true;
  groupUpdateInterval = null;
  groupStatusZeroState = false;
  degreesTypeAbbreviation = `°F`;
  lastUpdatedMessage = `Last Updated`;
  gatewayGroups: GatewayWithGroups = null;
  groupStatusZeroStateMessage = `No Status`;
  siteGatewayUnitsMapped: Subscription = null;
  onSiteGatewayCreatedSubscrption: Subscription = null;
  TemperaturePreferenceEnum = TemperaturePreferenceEnum;
  groupStatusLastUpdated = moment().format(`h:mm:ss A`);
  onSiteGatewayDecomissionedSubscription: Subscription = null;
  groupTypes = Object.values(GroupType);
  selectedType = GroupType.IU;
  modeProfileError = false;
  tempsLoaded = false;
  gwAvail = true;
  siteGateways;
  powerOffs = 0;
  powerOns = 0;
  groupNum = 0;
  highTemp = 0;
  coilNum = 0;
  avgTemp = 0;
  lowTemp = 0;
  colors = {
    fan: `#00FF00`,
    dry: `#FFA600`,
    heat: `#ff0000`,
    cool: `#1e90ff`,
    auto: `#FF00FF`,
    setback: `#00b7b9`,
    unknown: `#e1e1e1`,
    energyReco: `#00FF00`,
    Gray: `rgb(201, 203, 207)`,
    fanGreen: `#00b900`,
    heatRed: `rgb(255, 99, 132)`,
    coolBlue: `rgb(54, 162, 235)`,
    dryOrange: `#d08800`,
    autoOpac: `rgba(255, 0, 255, 0.2)`,
    coolOpac: `rgba(54, 162, 235, 0.2)`,
    setbackCyan: `#028c8d`,
    setbackCyanOpac: `rgba(167, 254, 255, 0.5)`,
    heatOpac: `rgba(255, 99, 132, 0.2)`,
    grayOpac: `rgba(201, 203, 207, 0.2)`,
    fanGreenOpac: `rgba(0, 255, 0, 0.2)`,
    dryOrangeOpac: `rgba(255, 166, 0, 0.2)`,
    unknownOpac: `rgba(225, 225, 225, 0.2)`,
    bypassPurple: `rgb(153, 102, 255)`,
    bypassPurpleOpac: `rgba(153, 102, 255, 0.2)`,
    energySage: `#04acac`,
    energyOpac: `rgba(75, 192, 192, 0.2)`,
  };

  eventsSubject: Subject<PageState> = new Subject<PageState>();

  hasSuspendedGateways = false;
  hasSuspendedGatewaysAlertOpen = false;

  gettingConnectionInfo = true;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle; // For HTML Enum Access
  maintenanceJobStatusEnum = maintenanceJobStatusEnum; // For HTML Enum Access
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // For HTML Enum Access
  gatewayMaintenanceJobs = [];
  gatewayMaintenanceJob;

  iconMaintenanceType = `maintenance`;

  constructor(
    public user: UserService,
    public appAuth: AppAuthenticationService,
    private modalController: ModalController,
    private socket: SocketService,
    private siteService: SiteService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    public mainSiteUIService: MainSiteUIService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService,
    private toastService: ToastrService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.imageUrl = '';
    this.includeImgInDOM = false;
    this.site = this.user.active;
  }

  ngOnInit() {
    this.uploadDashboardPictureFileMessage = '';
    this.connectionUpdateTimer = interval(CONNECTION_UPDATE_TIMER_INTERVAL);
    let siteIsTransferring = this.user && this.user.active && this.user.active.transfer_locked;
    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }

    // Init Window Size Listener
    this.windowEvents();
    window.addEventListener(`resize`, () => this.windowEvents());
    window.addEventListener(`scroll`, () => this.windowEvents());

    return () => {
      window.removeEventListener(`resize`, () => this.windowEvents());
      window.removeEventListener(`scroll`, () => this.windowEvents());
    }
  }

  formatTimezone = (timezone) => this.formatTimezoneLong(timezone).split(` - `)[0];

  formatTimezoneLong = (timezone) => timezone.split('/')[timezone.split('/').length - 1].replace('_', ' ') + ' (UTC' + moment.tz(timezone).format('Z') + ')' + ' - ' + moment.tz(timezone).format('h:mm A');

  windowEvents = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  ionViewWillEnter() {
    // this happens when the page is created or comes from the router cache
    // refresh the gatewayConnectionStatus
    this.groupStatusLoading = true;
    this.noGatewaysDefined = false;
    this.allGatewaysExpiredFlag = false;
    this.updateConnectionStatus();
    if (this.timerSubscription == null) {
      this.timerSubscription = this.connectionUpdateTimer.subscribe((tick) => {
        this.connectionStatusTick = tick;
        this.updateConnectionStatus();
      });
    }
    this.kenzaLinkFailed = false;

    this.getGatewayGroups();

    if (this.onActiveSiteChanged == null) {

      this.onActiveSiteChanged = this.user.activeSiteChanged.subscribe(
        (activeSite) => {
          // drop the onRefreshSiteAlert subscription - we are switching sites.
          if (this.onRefreshSiteAlertData) {
            this.onRefreshSiteAlertData.unsubscribe();
            this.onRefreshSiteAlertData = null;
          }
          if (this.groupUpdateInterval) {
            clearInterval(this.groupUpdateInterval);
            this.groupUpdateInterval = null;
          }
          if (activeSite) {
            this.displaySitePicture(this.user.active.id);
            if (this.router.url.indexOf('/dashboard') > -1) {
              this.router.navigate(['/site', activeSite.id, 'dashboard']);
            }
          }
        }
      );
    }

    this.eventsSubject.next(PageState.Enter);

    if (this.onSiteGatewayCreatedSubscrption == null) {
      this.onSiteGatewayCreatedSubscrption = this.siteService.siteGatewayCreated.subscribe((res) => {
        this.getGatewayGroups();
      });
    }
 
    if (this.onSiteGatewayDecomissionedSubscription == null) {
      this.onSiteGatewayDecomissionedSubscription = this.siteService.siteGatewayDecomissioned.subscribe((detail) => {
        this.getGatewayGroups();
      });
    }

    if (this.siteGatewayUnitsMapped == null) {
      this.siteGatewayUnitsMapped = this.socket.siteGatewayUnitsMapped.subscribe((mapGatewayCompleteResponse) => {
        this.mapLoading = true;
        this.recentlyMapped = true;
        this.getGatewayGroups();
        setTimeout(() => this.getGatewayGroups(), 5000);
      })
    }
    
    if (!this.groupUpdateInterval) {
      this.groupUpdateInterval = setInterval(() => { this.timerUpdate = true; this.getGatewayGroups(); }, 30_000);
    }

    this.displaySitePicture(this.user.active.id);

    this.loadTodaysEvents();

    this.mainSiteUIService.customFilter = null;

    if (this.mainSiteUIService.siteAlertMetricsData.v2RawActSiteNotifications.length == 0) {
      this.mainSiteUIService.refreshSiteAlertData(new SiteAlertFilter);
    }
  }

  performingMaintenance = (status: maintenanceJobStatusEnum) => {
    let performingMaintenance = status == maintenanceJobStatusEnum.new 
                             || status == maintenanceJobStatusEnum.booting 
                             || status == maintenanceJobStatusEnum.running 
                             || status == maintenanceJobStatusEnum.complete;
    return performingMaintenance;
  }

  isActionRestricted() {
    let gwGroupsGateways = [];
    let jobTitleIndex = MaintenanceJobTypeEnum.Default;
    let gatewayOrGroupsPerformingMaintenance = false;
    let siteIsTransferring = this?.user && this?.user?.active && this?.user?.active?.transfer_locked;

    if (this?.gatewayGroups?.gateways) {
      gwGroupsGateways = this?.gatewayGroups?.gateways;
      gwGroupsGateways = gwGroupsGateways.map(gwg => Gateway.newFromGateway(gwg, gwg?.site_id));
      if (gwGroupsGateways && Array.isArray(gwGroupsGateways) && gwGroupsGateways?.length > 0) {
        let gatewaysHaveMFKJobs = gwGroupsGateways.some(gwg => gwg.mfk_status != null);
        let gatewaysHaveMaintenanceJobs = gwGroupsGateways.some(gwg => gwg.maintenance_job != null);
        if (gatewaysHaveMaintenanceJobs || gatewaysHaveMFKJobs) {
          this.gatewayMaintenanceJobs = gwGroupsGateways.filter(gwg => gwg.maintenance_job != null || gwg.mfk_status != null);
          if (this.gatewayMaintenanceJobs.length > 0) {
            if (this.gatewayMaintenanceJobs.length > 1) {
              devEnv && console.log(`Multiple Gateway Maintenance Jobs`, this.gatewayMaintenanceJobs);
              this.gatewayMaintenanceJob = this.gatewayMaintenanceJobs[0]?.maintenance_job; // Temporary
            } else {
              this.gatewayMaintenanceJob = this.gatewayMaintenanceJobs[0]?.maintenance_job;
            }
          }
          jobTitleIndex = this.gatewayMaintenanceJob.job_id;
          gatewayOrGroupsPerformingMaintenance = this.performingMaintenance(this.gatewayMaintenanceJob.status);
        }
      }
    }

    if (siteIsTransferring) {
      jobTitleIndex = MaintenanceJobTypeEnum.Site_Transfer;
    }

    let maintType = MaintenanceJobTypeEnumTitle[jobTitleIndex];
    let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
    this.iconMaintenanceType = maintTypeToUse.toLowerCase();

    let hasMaintJobs = this.gatewayMaintenanceJobs && this.gatewayMaintenanceJobs.length > 0;
    let actionIsRestricted = siteIsTransferring || gatewayOrGroupsPerformingMaintenance || hasMaintJobs;
 
    // devEnv && console.log(`Is Action Restricted?`, {
    //   gwGroupsGateways,
    //   actionIsRestricted,
    //   iconMaintenanceType: this.iconMaintenanceType,
    //   gatewayMaintenanceJobs: this.gatewayMaintenanceJobs,
    // });
 
    return actionIsRestricted;
  }

  groupTypeChanged(value) {
    if (value) this.selectedType = value;
    this.setIndoorGroupStatusCard();
  }

  async getGatewayGroups() {
    await this.siteService.get_site_gateways_with_groups(this.user.active.id, true, true).subscribe((gateways: GatewayWithGroups) => {
      this.gatewayGroups = gateways;
      this.setIndoorGroupStatusCard();
    });
  }

  fHigh = 0;
  fLow = 0;
  fAvg = 0;
  cHigh = 0;
  cLow = 0;
  cAvg = 0;
  setTemps() {
    let temps = [];
    const lossnayGroups = [];
    const indoorGroups = [];
    const indoorCoils = [];
    const lossnayCoils = [];
    const average = array => array?.length > 0 ? array?.reduce((a, b) => a + b) / array?.length : 0;
    
    this.gatewayGroups.gateways.filter(gw => gw.subscription_expired == false).map(gw => gw.groups).forEach(grpArray => grpArray.forEach(grp => grp.units[0]?.type == GatewayUnitTwoDigitType.IndoorUnit ? indoorGroups.push(grp) : lossnayGroups.push(grp)));
    
    this.gatewayGroups.gateways.filter(gw => gw.subscription_expired == false).map(gw => gw.groups).forEach(grpArray => grpArray.forEach(grp => grp.units.forEach(unit => unit.type == GatewayUnitTwoDigitType.IndoorUnit ? indoorCoils.push(unit) : lossnayCoils.push(unit))));
    
    this.selectedType == GroupType.IU ? this.groupNum = indoorGroups.length : this.groupNum = lossnayGroups.length;
    this.selectedType == GroupType.IU ? this.coilNum = indoorCoils.length : this.coilNum = lossnayCoils.length;

    const inletTemps = this.gatewayGroups.gateways.filter(gw => gw.subscription_expired == false).map(gw => gw.profile.units).map(profileArray => profileArray.filter(profile => profile.inlet_temp)).map(tempProfileArray => tempProfileArray.map(tempProfile => {
      if (!isNaN(parseFloat(tempProfile.inlet_temp)) && parseFloat(tempProfile.inlet_temp) > 0) {
        return parseFloat(tempProfile.inlet_temp);
      } else {
        return 0;
      }
    }));

    inletTemps.forEach(tempArray => tempArray.forEach(temp => temps.push(temp)));

    if (temps.length > 0) {
      temps = [...new Set(temps)].filter(val => val != 0);
      this.cHigh = temps.sort((a, b) => b - a)[0];
      this.cLow = temps.sort((a, b) => a - b)[0];
      this.cAvg = parseFloat(average(temps).toString().substring(0, 4));
      this.fHigh = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temps.sort((a, b) => b - a)[0]));
      this.fLow = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temps.sort((a, b) => a - b)[0]));
      this.fAvg = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(average(temps).toString()).substring(0, 4));
      if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) {
        this.highTemp = temps.sort((a, b) => b - a)[0];
        this.lowTemp = temps.sort((a, b) => a - b)[0];
        this.avgTemp = parseFloat(average(temps).toString().substring(0, 4));
      } else {
        this.highTemp = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temps.sort((a, b) => b - a)[0]));
        this.lowTemp = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temps.sort((a, b) => a - b)[0]));
        this.avgTemp = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(average(temps).toString()).substring(0, 4));
      }
    } else {
      this.cHigh = 0;
      this.cLow = 0;
      this.cAvg = 0;
      this.fHigh = 0;
      this.fLow = 0;
      this.fAvg = 0;
      this.highTemp = 0;
      this.lowTemp = 0;
      this.avgTemp = 0;
    }

    this.tempsLoaded = true;
  }

  setIndoorGroupStatusCard() {
    this.createdBarGraph = false;

    // Set User Temp Preference
    if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) this.degreesTypeAbbreviation = `°C`;
    if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) this.degreesTypeAbbreviation = `°F`;

    let totalMapped = 0;
    let gateWaysMapped = false;

    // Indoor Group Status Updater
    if (this.user.active && this.gatewayGroups) {
      if (this.gatewayGroups.gateways.length > 0) {
        let gatewaysWithUnits = [];
        this.gatewayGroups.gateways.forEach(gw => {

          if (gw.groups.length > 0 || gw.profile.units.length > 0) {
            gatewaysWithUnits.push(gw);
          }

          if (gw.subscription_expired == true) {
            this.hasSuspendedGateways = true;
          }

          this.allProfileUnits = gw.profile.units.length;
          if (gw.groups.length == 0 || gw.profile.units.length == 0) {
            return this.unitsMapped = false;
          } else {
            totalMapped++;
            this.totalMappedGateways = totalMapped;
            return gateWaysMapped = true;
          }
        });

        let allGatewaysAreExpired = true;
        this.gatewayGroups.gateways.forEach((gw) => {
          if (gw.subscription_expired == false) {
            allGatewaysAreExpired = false;
          }
        });

        // devEnv && console.log(`Gateways Mapped?`, {
        //   gateWaysMapped,
        //   gatewaysWithUnits,
        //   allGatewaysAreExpired,
        //   unitsMapped: this.unitsMapped,
        //   totalMappedGateways: this.totalMappedGateways,
        // })

        // KEN-3854: If all GWs are expired show a zero state message
        if (allGatewaysAreExpired == true) {
          this.setGroupStatusZeroStateMessage(`You have Expired gateway(s) on your site.  To view indoor group status, upgrade your subscription plan.`);
        } else if (gateWaysMapped || gatewaysWithUnits.length > 0) {
          this.mapLoading = false;
          this.recentlyMapped = false;
          this.unitsMapped = true;
          this.groupStatusZeroState = false;
          this.setTemps();
          this.getModesAndPowers(this.selectedType);
        } else { // Map gateway units to view group status
          this.setGroupStatusZeroStateMessage(`<span>Gateway(s) registered, not mapped.<br><br>${(this.recentlyMapped || this.mapLoading) ? `Or a map is in progress.<br><br>Please wait a bit for an update,<br><br>` : ``}Visit the <a title="Site Gateways Page" class="groupStatusLink" href="#/manage/${this.user.active.id}/gateways">Site Gateways Page</a> and map units for each gateway to view group status on this page.</span>`);
        }
      } else { // Register a gateway to view indoor group status
        this.setGroupStatusZeroStateMessage(`Register a gateway to view indoor group status.`);
      }
    } else { // Register a gateway to view indoor group status
      this.groupNum = 0;
      this.coilNum = 0;
      this.highTemp = 0;
      this.lowTemp = 0;
      this.avgTemp = 0;
      this.gwAvail = false;
      this.tempsLoaded = true;
      this.createdBarGraph = true;
      this.groupStatusZeroState = true;
      this.groupStatusZeroStateMessage = `<span>Retrieving New Data</span>`;
    }

    this.groupStatusLoading = false;
  }

  setGroupStatusZeroStateMessage(message) {
    this.groupNum = 0;
    this.coilNum = 0;
    this.highTemp = 0;
    this.lowTemp = 0;
    this.avgTemp = 0;
    this.gwAvail = false;
    this.mapLoading = false;
    this.tempsLoaded = true;
    this.unitsMapped = false;
    this.recentlyMapped = false;
    this.createdBarGraph = true;
    this.groupStatusZeroState = true;
    this.groupStatusZeroStateMessage = message;
  }

  getModesAndPowers(selectedType) {

    const indoors = [];
    const lossnays = [];
    const validGroups = [];
    const validProfiles = [];
    const undefinedModes = [];
    const ValidTypes = Object.keys(GroupType);
    this.gatewayGroups.gateways.forEach(gw => {
      if(gw.subscription_expired == false) {
        gw.profile.units.filter(uni => ValidTypes?.includes(uni?.type)).forEach(prf => validProfiles.push(prf));
        gw.groups.forEach(grp => {
          if (ValidTypes.includes(grp?.units[0]?.type)) {
            validGroups.push(grp?.units[0]);
          }
        });
      }
    });
      
    this.allProfiles = [];
    if (validGroups.length == validProfiles.length) {
      validProfiles.forEach(pr => {
        if (pr?.type == GatewayUnitTwoDigitType.IndoorUnit) {
          this.allProfiles.push({mode: pr?.mode ?? `cool`, type: pr?.type, busAddress: parseFloat(pr?.bus_address), power: pr?.power});
        } else {
          this.allProfiles.push({mode: pr?.vent_mode ?? `heat_recovery`, type: pr?.type, busAddress: parseFloat(pr?.bus_address), power: pr?.power});
        }
      });
    } else {
      validGroups.forEach(grp => {
        if (grp?.type == GatewayUnitTwoDigitType.IndoorUnit) {
          this.allProfiles.push({
            type: grp?.type,
            busAddress: grp?.bus_address,
            mode: validProfiles.filter(prof => {
              if (parseFloat(prof?.bus_address) == parseFloat(grp?.bus_address)) {
                return prof;
              }
            })[0]?.mode ?? `cool`,
            power: validProfiles.filter(prof => {
              if (parseFloat(prof?.bus_address) == parseFloat(grp?.bus_address)) {
                return prof;
              }
            })[0]?.power ?? `off`,
          });
        } else {
          this.allProfiles.push({
            type: grp?.type,
            busAddress: grp?.bus_address,
            mode: validProfiles.filter(prof => {
              if (parseFloat(prof?.bus_address) == parseFloat(grp?.bus_address)) {
                return prof;
              }
            })[0]?.vent_mode ?? `heat_recovery`,
            power: validProfiles.filter(prof => {
              if (parseFloat(prof?.bus_address) == parseFloat(grp?.bus_address)) {
                return prof;
              }
            })[0]?.power ?? `off`,
          });
        }
      });
    }

    const fans = [];
    const cools = [];
    const dries = [];
    const heats = [];
    const icAutos = [];
    const lcAutos = [];
    const setbacks = [];
    const bypasses = [];
    const energyRecos = [];

    if (undefinedModes.length == 0) {
      this.allProfiles.forEach(profile => {
        let coilMode = globalFunctions.capitalizeAllWords(profile?.mode);
        if (profile?.type == GatewayUnitTwoDigitType.IndoorUnit) {
          if (coilMode == `Heat`) heats.push(coilMode);
          if (coilMode == `Cool`) cools.push(coilMode);
          if (coilMode == `Dry`) dries.push(coilMode);
          if (coilMode == `Fan`) fans.push(coilMode);
          if (coilMode == `Setback`) setbacks.push(coilMode);
          if (coilMode == `Setback_heat` || coilMode == `Setback_cool`) { coilMode = `Setback`; setbacks.push(coilMode); }
          if (coilMode == `Auto`) icAutos.push(coilMode);
          if (coilMode == `Auto_heat` || coilMode == `Auto_cool`) { coilMode = `Auto`; icAutos.push(coilMode); }
          indoors.push(profile);
        } else {
          if (coilMode == `Bypass`) bypasses.push(coilMode);
          if (coilMode == `Heatrecovery`) { coilMode = `Energy Recovery`; energyRecos.push(coilMode); }
          if (coilMode == `Heat_recovery`) { coilMode = `Energy Recovery`; energyRecos.push(coilMode); }
          if (coilMode == `Auto`) lcAutos.push(coilMode);
          if (coilMode == `Auto_heat` || coilMode == `Auto_cool`) { coilMode = `Auto`; lcAutos.push(coilMode); }
          lossnays.push(profile);
        }
      });
    } else {
      this.groupStatusZeroStateMessage = `No status recieved at this time.`;
      this.modeProfileError = true;
    }

    if (selectedType == GroupType.IU) {
      const indoorPowerOns = [];
      const indoorPowerOffs = [];
      indoors.forEach(indoor => indoor?.power == `on` ? indoorPowerOns.push(indoor) : indoorPowerOffs.push(indoor));
      this.powerOns = indoorPowerOns.length;
      this.powerOffs = indoorPowerOffs.length;
    } else {
      const lossnayPowerOns = [];
      const lossnayPowerOffs = [];
      lossnays.forEach(lossnay => lossnay?.power == `on` ? lossnayPowerOns.push(lossnay) : lossnayPowerOffs.push(lossnay));
      this.powerOns = lossnayPowerOns.length;
      this.powerOffs = lossnayPowerOffs.length;
    }

    // Initialize Chart
    Chart.register(...registerables);

    const indoorCoilModes = [`Heat`, `Cool`, `Dry`, `Fan`, `Setback`, `Auto`];
    const lossnayCoilModes = [`Bypass`, [`Energy`, `Recovery`], `Auto`];

    const indoorModes = [parseFloat(`${heats.length}`), parseFloat(`${cools.length}`), parseFloat(`${dries.length}`), parseFloat(`${fans.length}`), parseFloat(`${setbacks.length}`), parseFloat(`${icAutos.length}`)];
    const lossnayModes = [parseFloat(`${bypasses.length}`), parseFloat(`${energyRecos.length}`), parseFloat(`${lcAutos.length}`)];

    const maxMode = selectedType == GroupType.IU ? indoorModes.sort((a, b) => b - a)[0] : lossnayModes.sort((a, b) => b - a)[0];
    if (lossnays.length == 0) {this.groupTypes = [GroupType.IU]; this.selectedType = GroupType.IU;}

    this.totalGroups = indoorModes.concat(lossnayModes)?.length > 0 ? indoorModes.concat(lossnayModes)?.reduce((acc, item) => acc + item) : 0;
    this.allCoils = heats.concat(cools).concat(dries).concat(fans).concat(setbacks).concat(icAutos).concat(bypasses).concat(energyRecos).concat(lcAutos);

    // Create & Display Chart
    if (this.barChart) { this.barChart.destroy(); this.barChart = null; this.createdBarGraph = false; }
    this.gwAvail = true;
    if (this.barChart == null && !this.createdBarGraph && !this.groupStatusZeroState) {
      this.barChart = new Chart(this.statusBarChart.nativeElement, {
        type: `bar`,
        options: {
          indexAxis: `y`,
          aspectRatio: 1,
          responsive: true,
          maintainAspectRatio: true,
          layout: {
            padding: {
              right: 20,
            },
          },
          animation:{
            onProgress: function(e) {
              const ctx = e.chart.ctx;
              ctx.textAlign = `center`;
              ctx.textBaseline = `bottom`;
              
              e.chart.data.datasets.forEach(function (dataset) {
                  dataset.data.forEach((point, index) => {
                      ctx.fillText(point.toString(), (<any>e.chart)._sortedMetasets[0].data[index].x + 12, (<any>e.chart)._sortedMetasets[0].data[index].y + 6);
                  });
              });
            },
          },
          scales: {
            x: {
              suggestedMax: maxMode + 2,
              display: false,
              ticks: {
                color: `black`,
                font: {
                  weight: `700`
                }
              },
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                color: `black`,
                font: {
                  weight: `700`,
                }
              },
              grid: {
                display: false,
              }
            },
            yModeNums: {
              type: `linear`,
              display: false,
              position: `right`,
              ticks: {
                  color: `#000000`,
                  count: this.selectedType == GroupType.IU ? 6 : 3,
                  display: true,
                  font: {
                    weight: `700`
                  },
                  callback: function(value, index) {
                    const x = selectedType == GroupType.IU ? [parseFloat(`${heats.length}`), parseFloat(`${cools.length}`), parseFloat(`${dries.length}`), parseFloat(`${fans.length}`), parseFloat(`${setbacks.length}`), parseFloat(`${icAutos.length}`)].reverse() : [parseFloat(`${bypasses.length}`), parseFloat(`${energyRecos.length}`), parseFloat(`${lcAutos.length}`)].reverse();
                    return x[index];
                  }
              },
              grid: {
                display: false,
                borderColor: `#ffffff`
              },
            }
          },
          plugins: {
            legend: {
              display: false,
            },
          }
        },
        data: {
          labels: selectedType == GroupType.IU ? [...new Set(indoorCoilModes)] : [...new Set(lossnayCoilModes)],
          datasets: [{
              label: selectedType == GroupType.IU ? `IC's in this Mode` : `LC's in this Mode`,
              data: selectedType == GroupType.IU ? [parseFloat(`${heats.length}`), parseFloat(`${cools.length}`), parseFloat(`${dries.length}`), parseFloat(`${fans.length}`), parseFloat(`${setbacks.length}`), parseFloat(`${icAutos.length}`)] : [parseFloat(`${bypasses.length}`), parseFloat(`${energyRecos.length}`), parseFloat(`${lcAutos.length}`)],
              backgroundColor: selectedType == GroupType.IU ? [this.colors.heatOpac, this.colors.coolOpac, this.colors.dryOrangeOpac, this.colors.fanGreenOpac, this.colors.setbackCyanOpac, this.colors.autoOpac] : [this.colors.bypassPurpleOpac, this.colors.energyOpac, this.colors.autoOpac],
              borderColor: selectedType == GroupType.IU ? [this.colors.heatRed, this.colors.coolBlue, this.colors.dryOrange, this.colors.fanGreen, this.colors.setbackCyan, this.colors.auto] : [this.colors.bypassPurple, this.colors.energySage, this.colors.auto],
              borderWidth: 1
          }],
        },
      });
      this.createdBarGraph = true;
      this.groupStatusLastUpdated = moment().format(`h:mm:ss A`);
    }
  }

  getDayOfWeek(dateTimeStr, timeZone) {
    let dateTimeISO = dateTimeStr.replace(` `, `T`);
    let date = new Date(dateTimeISO);
    let formatOptions: any = { weekday: `long`, timeZone: timeZone };
    let formatter = new Intl.DateTimeFormat(`en-US`, formatOptions);
    let dayOfWeek = formatter.format(date);
    return dayOfWeek;
  }

  consolidateEventsByBatchID = (events) => {
    if (events.some(ev => ev.batch)) {
      let groupedByBatchID = {};
      events.forEach(event => {
        let batchID = event.batch.id;
        let batchName = event.batch.name;
        if (!groupedByBatchID[batchID]) {
          groupedByBatchID[batchID] = {
            ...event,
            batchName,
            batchEvents: [],
            group_name: batchName,
            startD: this.getDayOfWeek(event.time, this.user.active.timezone),
          };
        }
        groupedByBatchID[batchID].batchEvents.push(event);
      });
      return Object.values(groupedByBatchID);
    } else {
      return events;
    }
  };

  loadTodaysEvents() {
    if (this.siteService.isConnectedToInternet) {
      this.events_loading = true;
      this.siteService.getSiteTodaysEvents(this.site.id).subscribe((res) => {
        this.events_loading = false;
        this.events_noEventsDefined = res.events.length == 0;
        this.events_events = this.createEventList(res.events);
        this.events_events = this.consolidateEventsByBatchID(this.events_events);
      })
    }
  }

  createEventList(events) {
    events.forEach(element => {
      element['event_description'] = this.createEventDescription(element);
    });
    return events;
  }

  createEventDescription(element) {
    // create the description of this event
    // HH:MM xm | <SetTemp Value> <F|C> Mode:<mode if mode>
    const dt = new Date(Date.parse(element.time));
    let desc = dt.toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', hour12: true }) + ' |';

    const event_setTemp_command = element.commands.commands.find(cmd => cmd.param == 'SetTemp');
    const event_setMode_command = element.commands.commands.find(cmd => cmd.param == 'Mode');
    const event_setVent_command = element.commands.commands.find(cmd => cmd.param == 'VentMode');

    if (event_setTemp_command) {
      let temp = event_setTemp_command.value;
      let temp_pref = ' °C';
      // temp value is in C - do we want F?
      if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
        temp = TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temp);
        temp_pref = ' °F';
      }
      desc += ` ${temp} ${temp_pref}`;
    }
    if (event_setMode_command) {
      desc += ` ${event_setMode_command.value} Mode`;
    }
    if (event_setVent_command) {
      desc += ` ${event_setVent_command.value} Vent Mode`;
    }

    return desc;
  }

  eventInPast(element_time: string) {
    // is this time in the past?
    const dt = new Date(Date.parse(element_time));
    const now = new Date();

    return now > dt;
  }

  onCustomToolTipItemMouseLeaveLogic(onMouseLeaveEvent) {
    let customToolTipItem = onMouseLeaveEvent?.target;
    if (customToolTipItem.classList.contains(`customToolTipItemIsHoveredOver`)) customToolTipItem.classList.remove(`customToolTipItemIsHoveredOver`);
  }

  onCustomToolTipMouseEnterLogic(onMouseEnterEvent) {
    let toolTipItem = onMouseEnterEvent.target.parentElement;
    let cardParent = toolTipItem.parentElement.parentElement;
    let tooltipToShow = toolTipItem.querySelector(`.customToolTipContent`);

    if (!toolTipItem.classList.contains(`customToolTipItemIsHoveredOver`)) toolTipItem.classList.add(`customToolTipItemIsHoveredOver`);

    let cardParentHeight = cardParent.clientHeight;
    let cardParentScrollTop = cardParent.scrollTop;
    let itemTopPosition = toolTipItem.offsetTop;
    let itemWidth = toolTipItem.offsetWidth;
    let itemHeight = toolTipItem.offsetHeight;

    // Resize Tool Tip based on Parent Item Row Width for Responsiveness
    tooltipToShow.style.left = `${(itemWidth - 54) * -1}px`;
    tooltipToShow.style.minWidth = `${itemWidth - 34}px`;

    // Reanchor Tool Tip Position based on Y Position if needed
    let itemVisibleTopPosition = itemTopPosition - cardParentScrollTop;
    let itemVisibleBottomPosition = itemVisibleTopPosition + itemHeight;
    let distanceFromVisibleBottom = cardParentHeight - itemVisibleBottomPosition;

    let threshold = 200;

    let isCloseToBottom = distanceFromVisibleBottom <= threshold;
    let isCloseToTop = itemVisibleTopPosition <= 55;

    let itemRect = toolTipItem.getBoundingClientRect();

    let windowHeight = window.innerHeight;

    let distanceFromWindowBottom = windowHeight - itemRect.bottom;
    let thresholdToWindowBottom = 150;
    let isCloseToWindowBottom = distanceFromWindowBottom <= thresholdToWindowBottom;

    // If User is Close to Top or Bottom, Toggle Different Classes which Reposition the Custom Tool Tip
    if (isCloseToBottom || isCloseToWindowBottom) {
      if (!tooltipToShow.classList.contains(`closeToBottom`)) {
        if (!isCloseToTop) tooltipToShow.classList.add(`closeToBottom`);
      }
    } else {
      if (tooltipToShow.classList.contains(`closeToBottom`)) {
        tooltipToShow.classList.remove(`closeToBottom`);
      }
    }
  }

  updateConnectionStatus() {
    // update the connection status of this sites gateways
    if (this.siteService.isConnectedToInternet) {

      this.siteService.getSiteGatewayConnectionStatus(this.site.id).subscribe({
        next: (res) => {
          this.gettingConnectionInfo = false;
          this.gatewayConnectionStatus = [];
          this.gatewayDisconnectedCount = 0;
          if (res.length == 0) this.noGatewaysDefined = true;
          this.totalGateways = 0;
          if (this.connectionStatusTick != 0 && (this.mapLoading || this.recentlyMapped || !this.unitsMapped)) {
            this.timerUpdate = false;
            this.groupStatusLastUpdated = moment().format(`h:mm:ss A`);
            this.getGatewayGroups();
          }

          let allGatewaysAreExpired = true; // need a new zero state if all the gws are expired...
          res.forEach(gw => {
            if(gw.gateway_is_expired == false) {
              allGatewaysAreExpired = false;
              this.totalGateways += 1;
              this.gatewayConnectionStatus.push(gw);
              if (gw.connected.toLowerCase() != 'true') this.gatewayDisconnectedCount += 1;
            }
          })

          if (allGatewaysAreExpired == true) {
            this.allGatewaysExpiredFlag = true;
          }

          // Adding Gateway Total String (4/5 gateways are not connected)
          this.gatewayTotal = `${this.gatewayDisconnectedCount + `/` + this.gatewayConnectionStatus.length}`;
        }, 
        error: (error) => {
          devEnv && console.log(`error`, error);
          // stop trying untill the next refresh
          this.timerSubscription.unsubscribe();
          this.timerSubscription = null;
          this.kenzaLinkFailed = true;
        }
      });
    }
  }

  ionViewWillLeave() {
    // moving away - turn off the subscriptions
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    if (this.onActiveSiteChanged) {
      this.onActiveSiteChanged.unsubscribe();
      this.onActiveSiteChanged = null;
    }
    
    if (this.onRefreshSiteAlertData) {
      this.onRefreshSiteAlertData.unsubscribe();
      this.onRefreshSiteAlertData = null;
    }

    if (this.groupUpdateInterval) {
      clearInterval(this.groupUpdateInterval);
      this.groupUpdateInterval = null;
    }

    if (this.siteGatewayUnitsMapped) {
      this.siteGatewayUnitsMapped.unsubscribe();
      this.siteGatewayUnitsMapped = null;
    }

    if (this.onSiteGatewayCreatedSubscrption) {
      this.onSiteGatewayCreatedSubscrption.unsubscribe();
      this.onSiteGatewayCreatedSubscrption = null;
    }

    if (this.onSiteGatewayDecomissionedSubscription) {
      this.onSiteGatewayDecomissionedSubscription.unsubscribe();
      this.onSiteGatewayDecomissionedSubscription = null;
    }

    this.eventsSubject.next(PageState.Leave);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  async viewAlerts(siteNotificationType: SiteNotificationTypeEnum) {
    if (!this.siteService.handleIsConnected()) return;

    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.StatusActive = true;
    siteAlertFilter.AlertEquipment = false;
    siteAlertFilter.StatusHistorical = false;
    siteAlertFilter.NotificationMembership = false;
    siteAlertFilter.NotificationGateway = false;
    siteAlertFilter.NotificationPayments = false;
    siteAlertFilter.NotificationEquipment = false;

    switch (siteNotificationType) {
      case SiteNotificationTypeEnum.Equipment:
        siteAlertFilter.AlertEquipment = true;
        break;

      case SiteNotificationTypeEnum.Membership:
        siteAlertFilter.NotificationMembership = true;
        break;

      case SiteNotificationTypeEnum.Gateway:
        siteAlertFilter.NotificationGateway = true;
        break;

      case SiteNotificationTypeEnum.Payments:
        siteAlertFilter.NotificationPayments = true;
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        siteAlertFilter.NotificationEquipment = true;
        break;
    }

    await this.user.setActiveSite(this.user.active, true);
    await this.mainSiteUIService.setCustomSiteAlertFilter(siteAlertFilter);
    await this.mainSiteUIService.applyCustomSiteAlertFilter(siteAlertFilter);
    this.router.navigate(['/site', this.user.active.id, 'alerts']);
  }

  async navigateToSiteNotificationByType(
    sitenotificationtype_id: number,
    sitenotification_id: string
  ) {
    if (!this.siteService.handleIsConnected()) return;

    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
    this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);

    switch (sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Equipment:
        this.dataService.updateEquipmentAlertNotificationID(
          sitenotification_id
        );
        break;

      case SiteNotificationTypeEnum.Membership:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.Gateway:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.Payments:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;
    }
  }

  async openSiteAdmin() {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteAdminPage,
      cssClass: 'me-sc-ion-modal-md-h-5',
      componentProps: {
        parentModelState: ModelState.Update,
        parentSelectedSite: this.user.active,
      },
    });
    return await modal.present();
  }

  addressComma(): string {
    if (
      (!this.user.active.city && !this.user.active.state) ||
      (!this.user.active.city && this.user.active.state) ||
      (this.user.active.city && !this.user.active.state)
    ) {
      return '';
    }
    return ',';
  }

  displaySitePicture(site_id: string) {
    this.user
      .getSitePhotoUrl(site_id)
      .then((sitePictureUrl) => {


        if (this.uploadDashboardPictureFile) {
          this.uploadDashboardPictureFile.nativeElement.value = '';
        }

        if (sitePictureUrl.length > 0) {
          this.includeImgInDOM = true;
          this.imageUrl = sitePictureUrl + '#' + Math.random().toString();
        } else {
          this.includeImgInDOM = false;
          this.imageUrl = '';
        }
      })
      .catch((err) => Log.error(err));
  }

  on_open_upload_picture() {
    if (!this.siteService.handleIsConnected()) return false;
  }

  async saveSitePhoto(event) {
    if (!this.siteService.handleIsConnected() || !event.target.files) return;

    this.uploadDashboardPictureFileMessage = '';

    const allowedAccountPhotoTypes = ['gif', 'jpg', 'jpeg', 'png'];
    const siteDetail: Site = this.user.active;

    const fileDetails = event.target.files[0];
    const fileExtension = fileDetails.name.substr(
      fileDetails.name.lastIndexOf('.') + 1
    );
    const found = allowedAccountPhotoTypes.filter(
      (ext) => ext.toLowerCase() === fileExtension.toLowerCase()
    );

    if (found.length === 0) {
      this.uploadDashboardPictureFileMessage =
        'Photo must be .png, .gif, or .jpg';
      return true;
    }

    if (fileDetails.size > 1200000) {
      this.uploadDashboardPictureFileMessage = 'File size must not exceed 1MB';
      return true;
    } else {
      this.uploadDashboardPictureFileMessage = '';
    }

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Uploading picture...',
    });

    load.present();

    // await this.user.saveSitePhoto(siteDetail, event.target.files[0])
    this.user
      .saveSitePhoto(siteDetail, event.target.files[0])
      .then(() => {
        this.reloadCurrentRoute();
        siteDetail.site_photo_name = this.user.active.id + '.' + fileExtension;
        this.displaySitePicture(this.user.active.id);
        setTimeout(() => {
          load.dismiss();
        }, 3000);
      })
      .catch((err) => {
        Log.error(err);
        load.dismiss();
      });
  }

  async removeSitePhoto() {
    if (!this.siteService.handleIsConnected()) return;

    this.uploadDashboardPictureFileMessage = '';

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Removing picture...',
    });

    load.present();

    this.user
      .removeSitePhoto(this.user.active.id)
      .then(() => {
        // wipe the image
        this.includeImgInDOM = false;
        this.imageUrl = '';
        setTimeout(() => {
          load.dismiss();
        }, 1500);
      })
      .catch((err) => {
        Log.error(err);
        load.dismiss();
      });
  }

  async transfer_site() {
    if (!this.siteService.handleIsConnected()) return;
    if (this.modalOpen == true) return;

    const modal = await this.modalController.create({
      component: SiteTransferModal,
      cssClass: `me-sc-ion-modal-md-h-5`,
      backdropDismiss: false,
      componentProps: {
        parent_selected_site: this.user.active,
        modalOpen: this.modalOpen,
      },
    });

    this.modalOpen = true;
    modal.onDidDismiss().then(() => {
      this.router.navigate([`/site`, this.user.active.id, `dashboard`]);
      this.modalOpen = false;
    });
    return await modal.present();
  }

  deactivate_site() {
    if (!this.siteService.handleIsConnected()) return;

    const get_site_gateways_promise = this.siteService.get_site_gateways(
      this.user.active.id
    );

    get_site_gateways_promise.then((site_gateways: Gateway[]) => {
      this.show_deactivate_site(site_gateways);
    });
  }

  async show_deactivate_site(site_gateways: Gateway[]) {
    if (!this.siteService.handleIsConnected()) return;
    if (this.modalOpen == true) return;

    const modal = await this.modalController.create({
      component: SiteDeactivateComponent,
      cssClass: `me-sc-ion-modal-md-h-4`,
      backdropDismiss: false,
      componentProps: {
        parent_selected_site: this.user.active,
        parent_site_gateways: site_gateways,
      },
    });

    this.modalOpen = true;
    modal.onDidDismiss().then((data) => {
      this.modalOpen = false;
      if (data.data?.site_deactivated) {
        this.user.updateSites();
        this.router.navigate([`/home`]);
      }
    });

    return await modal.present();
  }

  async cancelSiteTransfer() {
    if (!this.siteService.handleIsConnected()) return;

    const cancelSiteTransfer = await this.alertController.create({
      id: `cancelSiteTransfer`,
      cssClass: `alertMsg actionRestrMsg me-info-button-css`,
      header: `Cancel Transfer Site Ownership`,
      subHeader: `Are you sure you want to cancel the transfer of site ownership?`,
      message: `This site is in the process of transferring site ownership.  If you cancel the transfer you will remain the site owner and maintain all financial responsibilities for the site.`,
      buttons: [
        {text: `Yes`, role: `ok`, id: `cancelTransferSiteInProcessYesButton`, cssClass: `ok-button cancelTransferSiteInProcessYesButton iBtnCancelTransferSiteInProcessYes automationTesting`, handler: () => this.alertController.dismiss().then(async () => {
          const spinner = await this.loadingController.create({
            spinner: `lines`,
            message: `Canceling site transfer...`,
          });
          spinner.present();

          this.siteService.cancelTransfer(this.user.active.id).toPromise()
          .then(async (resp) => {
            await spinner.dismiss();
            this.user.updateSites();
            this.router.navigate([`/site`, this.user.active.id, `dashboard`]);
          }).catch(async (err) => {
            console.log(err);
            await spinner.dismiss();
            this.toastService.error(`Unable to process request at this time or transfer has already be accepted.  Refresh page and try again.`, `Issue Canceling Site Transfer`, TOAST_CONFIG_FULL_WIDTH);
          });
        })},
        {text: `No`, role: `cancel`, id: `cancelTransferSiteInProcessNoButton`, cssClass: `cancel-button cancelTransferSiteInProcessNoButton iBtnCancelTransferSiteInProcessNo automationTesting`, handler: () => this.alertController.dismiss()},
      ]
    });

    return await cancelSiteTransfer.present();
  }

  ngOnDestroy(): void {
    if (this.onActiveSiteChanged) {
      this.onActiveSiteChanged.unsubscribe();
      this.onActiveSiteChanged = null;
    }

    if (this.onRefreshSiteAlertData) {
      this.onRefreshSiteAlertData.unsubscribe();
      this.onRefreshSiteAlertData = null;
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    if (this.groupUpdateInterval) {
      clearInterval(this.groupUpdateInterval);
      this.groupUpdateInterval = null;
    }

    if (this.onSiteGatewayCreatedSubscrption) {
      this.onSiteGatewayCreatedSubscrption.unsubscribe();
      this.onSiteGatewayCreatedSubscrption = null;
    }

    if (this.onSiteGatewayDecomissionedSubscription) {
      this.onSiteGatewayDecomissionedSubscription.unsubscribe();
      this.onSiteGatewayDecomissionedSubscription = null;
    }

    if (this.siteGatewayUnitsMapped) {
      this.siteGatewayUnitsMapped.unsubscribe();
      this.siteGatewayUnitsMapped = null;
    }
  }

  async presentSuspendedGatewaysAlert() {
    if (this.hasSuspendedGatewaysAlertOpen == true) return;
    if (!this.siteService.handleIsConnected()) return;
    const hasSuspendedGatewaysAlert = await this.alertController.create({
      id: `hasSuspendedGatewaysAlertMsg`,
      cssClass: `alertMsg me-info-button-css`,
      message: `There are Expired gateways on the site which do not display information in this card.`,
      buttons: [{
        text: `Ok`, role: `cancel`, cssClass: `ok-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.hasSuspendedGatewaysAlertOpen = false;
        })
      }]
    });
    this.hasSuspendedGatewaysAlertOpen = true;
    return await hasSuspendedGatewaysAlert.present();
  }
}