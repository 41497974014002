<ion-header>
  <ion-toolbar>
    <ion-title id="title" name="title">{{ currentGatewayProcessHeader }}</ion-title>
    <!-- <ion-toggle *ngIf="devEnv" id="devToggle" class="devToggles devOnlyToggles" [(ngModel)]="useMaskito" [enableOnOffLabels]="true" title="Dev Only Temporary Toggle, Will Remove Later" slot="end" style="padding-right: 20px;">
      Dev Only: Use Maskito
    </ion-toggle> -->
  </ion-toolbar>
</ion-header>

<ion-content>

  <!--Header Section -->
  <ion-grid me-header-grid>
    <ion-row class="registration-step-counter ion-justify-content-around">
      <ion-col class="ion-justify-content-center ion-align-items-center">
        <ion-row class="ion-justify-content-center">
          <ion-icon id="iconGateway" name="iconGateway"
            [ngClass]="(currentGatewayRegistrationStep >= gatewayRegistrationStep.GatewayStep) ? 'me-current-process-step' : 'me-process-step' "
            name="cloud-upload"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.GatewayStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
            Gateway</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">
        -
      </ion-col>
      <ion-col class="">
        <ion-row class="ion-justify-content-center">
          <ion-icon id="iconSubscription" name="iconSubscription"
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.SubscriptionStep ? 'me-current-process-step' : 'me-process-step' "
            name="apps"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.SubscriptionStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
            Select Plan</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">
        -
      </ion-col>
      <ion-col class="">
        <ion-row class="ion-justify-content-center">
          <ion-icon id="iconPayAndRegister" name="iconPayAndRegister"
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.PaymentAndRegisterStep ? 'me-current-process-step' : 'me-process-step' "
            name="card"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.PaymentAndRegisterStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
            Pay and Register</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">
        -
      </ion-col>
      <ion-col class="">
        <ion-row class="ion-justify-content-center">
          <ion-icon id="iconPrint" name="iconPrint"
            [ngClass]="(currentGatewayRegistrationStep >= gatewayRegistrationStep.ConfirmationStep) ? 'me-current-process-step' : 'me-process-step' "
            name="list"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label
            [ngClass]="currentGatewayRegistrationStep >= gatewayRegistrationStep.ConfirmationStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
            Summary</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!--Gateway Section-->
  <ion-grid *ngIf="currentGatewayRegistrationStep == gatewayRegistrationStep.InformationStep">
    <ion-row>
      <ion-col col col-12 me-header-col>
        <ion-label color="secondary">
          You are registering a<br>gateway to the site:
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col col col-12 me-header-col>
        <ion-label>
          <strong>{{user.active.name}}</strong>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col col col-12>
        <ion-label class="register-site-label">
          Note the following:
          <ul>
            <li class="register-site-li">When registering gateway(s), the following information is required:</li>
            <ul>
              <li class="register-site-li">Credit card information for a paid monthly subscription plan.
                <ul>
                  <li class="register-site-li">
                    If prepaid through a license, you will not be required to input a payment method.  (The license form must be completed and submitted to the order management team prior to registration).
                  </li>
                </ul>
              </li>
              <li class="register-site-li">
                Gateway serial number
              </li>
              <li class="register-site-li">
                Gateway registration code / device key
              </li>
              <li class="register-site-li">
                Gateway MAC Address (RMD Only)
              </li>
            </ul>
            <li class="register-site-li">Only one payment method can be used for all gateway(s) on a site.</li>
            <li class="register-site-li">If you enter a different payment method than is currently being used for this
              site, it will be applied to all gateways on the next billing cycle.</li>
            <li>You can register up to 5 gateways in one gateway registration session, if you have more than 5, please register those gateways in another session.</li>
          </ul>
        </ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!--Gateway Input Section-->
  <ion-grid *ngIf="currentGatewayRegistrationStep == gatewayRegistrationStep.GatewayStep">
    <ion-row>
      <ion-col col col-12 me-header-col>
        <ion-label color="secondary">
          Enter information below for the <br>gateway(s) you are registering:
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row style="padding-top: 10px;">
      <ion-col style="padding-bottom: 0px; padding-top: 0px;">
        <ion-grid class="ion-no-padding">
          <ion-radio-group [value]="model.id.toString()" (ionChange)="onGatewayTypeChange($event)">
            <ion-row>
              <ion-list-header style="min-height: 20px;">
                <ion-label color="secondary" style="font-size: 12px;">Device Type<sup validation-error>*</sup></ion-label>
              </ion-list-header>
            </ion-row>
            <ion-row>
              <ion-col size="4" class="device_type_radio_toggle_button col-{{ mod.name }} deviceTypeCol automationTesting" *ngFor="let mod of planService.gatewayModels">
                <ion-item class="me-radio-item ionItem-{{ mod.name }} deviceTypeIonItem automationTesting" >
                  <ion-radio class="form-control me-radio radio-{{ mod.name }} deviceTypeRadio automationTesting" slot="start" value="{{ mod.id }}"></ion-radio>
                  <ion-label class="me-radio-label">{{ mod.name }}</ion-label>
                </ion-item>
              </ion-col>
              <ion-col class="gw_reg_form_compare_gateways_link" size="6">
                <ion-toolbar>
                  <ion-buttons>
                    <ion-button (click)="onComapareGateways()" me-button-as-link id="btnCompareGateways" name="btnClickHere">
                      Click Here to Compare Gateways
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
              </ion-col>
            </ion-row>
          </ion-radio-group>
        </ion-grid>
      </ion-col>
    </ion-row>

    <div *ngIf="model.class_name == gatewayModelClass.RMD">
      <form [formGroup]="rmd_gatewayRegistrationForm" class="rmd_gatewayRegistrationForm">
        <ion-row class="requiredRow">
          <ion-col col col-6 style="padding-top: 0px;">
            <ion-input minlength="3" maxlength="50" type="text" class="form-control" id="gateway_name" name="gateway_name"
              formControlName="gateway_name" (ionBlur)="trim($event)" (ionInput)="trackGWNameValue($event)" labelPlacement="floating">
                <ion-text slot="label" for="gateway_name" color="secondary">Gateway Name<sup validation-error>*</sup></ion-text>
            </ion-input>

            <div *ngIf="rmd_gatewayRegistrationForm.controls.gateway_name.errors">
              <div validation-error
                *ngIf="rmd_gatewayRegistrationForm.controls.gateway_name.errors.required && (rmd_gatewayRegistrationForm.controls.gateway_name.dirty || rmd_gatewayRegistrationForm.controls.gateway_name.touched)">
                Gateway Name is required
              </div>
            </div>

            <div *ngIf="rmd_gatewayRegistrationForm.controls.gateway_name.errors">
              <div validation-error
                *ngIf="(rmd_gatewayRegistrationForm.controls.gateway_name.errors.minlength ||
                  rmd_gatewayRegistrationForm.controls.gateway_name.errors.pattern) &&
                  (rmd_gatewayRegistrationForm.controls.gateway_name.dirty
                  || rmd_gatewayRegistrationForm.controls.gateway_name.touched)">
                Gateway Name must be between 3 and 50 characters
              </div>
            </div>
          </ion-col>
          <ion-col col col-6 class="rmd_serial_number_field angularTextMaskColumn">
            <ion-input minlength="14" maxlength="14" type="text" class="form-control" id="serial_number" name="serial_number"
              formControlName="serial_number" (ionInput)="uppercaseInput($event)" (ionBlur)="trim($event)" labelPlacement="floating">
                <ion-text slot="label" for="serial_number" color="secondary">Serial Number<sup validation-error>*</sup></ion-text>
            </ion-input>

            <div *ngIf="rmd_gatewayRegistrationForm.controls.serial_number.errors">
              <div validation-error
                *ngIf="rmd_gatewayRegistrationForm.controls.serial_number.errors.required && (rmd_gatewayRegistrationForm.controls.serial_number.dirty || rmd_gatewayRegistrationForm.controls.serial_number.touched) ">
                Serial Number is required
              </div>
            </div>

            <div *ngIf=" rmd_gatewayRegistrationForm.controls.serial_number.errors">
              <div validation-error
                *ngIf="(rmd_gatewayRegistrationForm.controls.serial_number.errors.minlength ||
                rmd_gatewayRegistrationForm.controls.serial_number.errors.maxlength ||
                rmd_gatewayRegistrationForm.controls.serial_number.errors.pattern)
                && (rmd_gatewayRegistrationForm.controls.serial_number.dirty || rmd_gatewayRegistrationForm.controls.serial_number.touched) ">
                Serial Number must be 14 characters and no spaces
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="requiredRow">
          <ion-col class="rmd_mac_address_field angularTextMaskColumn" col col-6>
            <app-text-mask formControlName="mac_address" [control]="rmd_gatewayRegistrationForm.controls.mac_address" [formGroup]="rmd_gatewayRegistrationForm" [useMaskito]="useMaskito" />
            <div *ngIf="rmd_gatewayRegistrationForm.controls.mac_address.errors">
              <div validation-error
                *ngIf="rmd_gatewayRegistrationForm.controls.mac_address.errors.required && rmd_gatewayRegistrationForm.controls.mac_address.touched">
                MAC Address is required
              </div>
            </div>
            <div *ngIf="rmd_gatewayRegistrationForm.controls.mac_address.errors">
              <div validation-error
                *ngIf="rmd_gatewayRegistrationForm.controls.mac_address.errors.invalidMACAddress && !rmd_gatewayRegistrationForm.controls.mac_address.errors.required && rmd_gatewayRegistrationForm.controls.mac_address.dirty ">
                MAC Address must be 12 characters.<br>Example : XX:XX:XX:XX:XX:XX
              </div>
            </div>
          </ion-col>
          <ion-col col col-6>
            <ion-input  minlength="9" maxlength="9" type="text" class="form-control" id="device_key" name="device_key"
              formControlName="device_key" (ionInput)="uppercaseInput($event)" (ionBlur)="trim($event)" labelPlacement="floating">
                <ion-text slot="label" for="device_key" color="secondary">Device Key<sup validation-error>*</sup></ion-text>
            </ion-input>

            <div *ngIf="rmd_gatewayRegistrationForm.controls.device_key.errors">
              <div validation-error
                *ngIf="rmd_gatewayRegistrationForm.controls.device_key.errors.required && (rmd_gatewayRegistrationForm.controls.device_key.dirty || rmd_gatewayRegistrationForm.controls.device_key.touched) ">
                Device Key is required
              </div>
            </div>

            <div *ngIf="rmd_gatewayRegistrationForm.controls.device_key.errors">
              <div validation-error
                *ngIf="(rmd_gatewayRegistrationForm.controls.device_key.errors.minlength ||
                rmd_gatewayRegistrationForm.controls.device_key.errors.maxlength ||
                rmd_gatewayRegistrationForm.controls.device_key.errors.pattern)
                && (rmd_gatewayRegistrationForm.controls.device_key.dirty || rmd_gatewayRegistrationForm.controls.device_key.touched) ">
                Device key must be 9 characters and no spaces
              </div>
            </div>
          </ion-col>
        </ion-row>
      </form>
      <ion-row>
        <ion-col size="8" col me-center-ion-col-content>
          <ion-label color="danger">
            {{validationResultMessage}}
          </ion-label>
        </ion-col>
        <ion-col size="4" col me-center-ion-col-content>
          <ion-button id="btnAddGateway" name="btnAddGateway" style="float: right;"
            [disabled]="!rmd_gatewayRegistrationForm.valid || canAddGateway()==false" (click)="addVerifiedGateway()">
            Add Gateway
          </ion-button>
        </ion-col>
      </ion-row>
    </div>

    <div *ngIf="model.class_name == gatewayModelClass.MCC">
      <form [formGroup]="mcc_gatewayRegistrationForm" class="mcc_gatewayRegistrationForm">
        <ion-row class="requiredRow">
          <ion-col col col-6 style="padding-top: 0px;">
            <ion-input minlength="3" maxlength="50" type="text" class="form-control" id="mcc_gateway_name" name="mcc_gateway_name"
              formControlName="mcc_gateway_name" (ionBlur)="trim($event)" (ionInput)="trackGWNameValue($event)" labelPlacement="floating">
                <ion-text slot="label" for="mcc_gateway_name" color="secondary">Gateway Name<sup validation-error>*</sup></ion-text>
            </ion-input>

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_gateway_name.errors">
              <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_gateway_name.errors.required && (mcc_gatewayRegistrationForm.controls.mcc_gateway_name.dirty || mcc_gatewayRegistrationForm.controls.mcc_gateway_name.touched)">
                Gateway Name is required
              </div>
            </div>

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_gateway_name.errors">
              <div validation-error
                *ngIf="(mcc_gatewayRegistrationForm.controls.mcc_gateway_name.errors.minlength ||
                 mcc_gatewayRegistrationForm.controls.mcc_gateway_name.errors.pattern) &&
                 (mcc_gatewayRegistrationForm.controls.mcc_gateway_name.dirty ||
                  mcc_gatewayRegistrationForm.controls.mcc_gateway_name.touched)">
                Gateway Name must be between 3 and 50 characters
              </div>
            </div>

          </ion-col>
          <ion-col col col-6 class="mcc_serial_number_field angularTextMaskColumn">
            <app-text-mask formControlName="mcc_serial_number" [control]="mcc_gatewayRegistrationForm.controls.mcc_serial_number" [formGroup]="mcc_gatewayRegistrationForm" [moveTop]="false" [useMaskito]="useMaskito" />

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_serial_number.errors">
              <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_serial_number.errors.required && (mcc_gatewayRegistrationForm.controls.mcc_serial_number.dirty || mcc_gatewayRegistrationForm.controls.mcc_serial_number.touched) ">
                Serial Number is required
              </div>
            </div>

            <div *ngIf=" mcc_gatewayRegistrationForm.controls.mcc_serial_number.errors">
              <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_serial_number.errors.parsingError && (mcc_gatewayRegistrationForm.controls.mcc_serial_number.dirty || mcc_gatewayRegistrationForm.controls.mcc_serial_number.touched) ">
                Serial Number must be 9 characters<br>Example : XXXXX-XXX
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="requiredRow">
          <ion-col col col-6>
            <ion-input minlength="14" maxlength="14"  type="text" class="form-control" id="mcc_registration_code"
              name="mcc_registration_code" formControlName="mcc_registration_code"
              (ionInput)="uppercaseInput($event)" (ionBlur)="trim($event)" labelPlacement="floating">
                <ion-text slot="label" for="mcc_registration_code" color="secondary">Gateway Registration Code<sup validation-error>*</sup></ion-text>
            </ion-input>

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors">
              <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors.required && (mcc_gatewayRegistrationForm.controls.mcc_registration_code.dirty || mcc_gatewayRegistrationForm.controls.mcc_registration_code.touched) ">
                Registration Code is required
              </div>
            </div>

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors">
              <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors.minlength && (mcc_gatewayRegistrationForm.controls.mcc_registration_code.dirty || mcc_gatewayRegistrationForm.controls.mcc_registration_code.touched) ">
                Registration Code must be 14 characters
              </div>
            </div>

            <div *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors">
                  <div validation-error
                *ngIf="mcc_gatewayRegistrationForm.controls.mcc_registration_code.errors.pattern && (mcc_gatewayRegistrationForm.controls.mcc_registration_code.dirty || mcc_gatewayRegistrationForm.controls.mcc_registration_code.touched) ">
                Registration Code cannot contain any spaces
              </div>
            </div>

          </ion-col>
          <ion-col col col-6></ion-col>
        </ion-row>
      </form>

      <ion-row>
        <ion-col size="8" col me-center-ion-col-content>
          <ion-label color="danger">
            {{validationResultMessage}}
          </ion-label>
        </ion-col>
        <ion-col size="4" col me-center-ion-col-content>
          <ion-button id="btnAddGateway" name="btnAddGateway" style="float: right;"
            [disabled]="!mcc_gatewayRegistrationForm.valid || canAddGateway()==false" 
            (click)="addVerifiedGateway()">
            Add Gateway
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <ion-row>
      <ion-col col col-12>
        <strong>
          <ion-label id="lblSubscriptionGateways" name="lblSubscriptionGateways" class="register-site-label">
            Added Gateways
          </ion-label>
        </strong>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col col col-12>
        <ion-card me-ion-card *ngFor="let ugw of unregisteredDevices" id="siteGatewayCard" name="siteGatewayCard">
          <ion-item me-padding-right-7px>
            <ion-label id="lblGatewayName" name="lblGatewayName">
              {{ugw.name}}
            </ion-label>
            <div slot="end" class="ion-icon-wrapper-arrow-hide-show"
              (click)="showDevice(ugw.serial_number, ugw.registration_code, ugw.device_key)"
              [title]="ugw.isExpanded ? 'Collapse' : 'Expand'">
                <ion-icon [name]="ugw.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'" me-cursor-pointer></ion-icon>
            </div>
          </ion-item>

          <ion-card-content *ngIf="ugw.isExpanded" lines="none">
            <ion-grid class="ion-no-padding">
              <ion-row *ngIf="ugw.has_coupon">
                <ion-col me-col-bottom-padding>
                  <ion-label><i>This is a {{ugw.coupon_name}} and not subject to a recurring subscription cost.</i></ion-label>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="ugw.has_license">
                <ion-col me-col-bottom-padding>
                  <ion-label><i>This gateway has a {{ugw.licenseName}}  applied to it, and is not subject to a recurring subscription cost. </i></ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Device Type:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblDeviceType" name="lblDeviceType">
                    {{ ugw.model_name ? ugw.model_name : '' }}
                  </ion-label>
                </ion-col>
                <ion-col me-end-ion-col-content>
                  <ion-buttons>
                    <ion-button id="btnDeleteGateway" name="btnDeleteGateway" me-padding-bottom-0px
                      (click)="removeUnregisteredDevice(ugw.serial_number,ugw.registration_code, ugw.device_key)">
                      <ion-icon color="danger" name="close-circle-outline" me-padding-bottom-0px></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>

              <ion-row white-background>
                <ion-col size="4"><strong>Serial Number:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblSerialNumber" name="lblSerialNumber">
                    {{ ugw.serial_number }}
                  </ion-label>
                  <ion-label *ngIf="ugw.has_coupon"><i>({{ugw.coupon_name}})</i></ion-label>
                </ion-col>
                <ion-col me-col-bottom-padding>
                  &nbsp;
                </ion-col>
              </ion-row>
              <div *ngIf="ugw.model_class_name == gatewayModelClass.RMD">
                <ion-row white-background>
                  <ion-col size="4"><strong>MAC Address:</strong></ion-col>
                  <ion-col>
                    <ion-label name="lblMACAddress" name="lblMACAddress">{{ ugw.mac_address }}</ion-label>
                  </ion-col>
                  <ion-col me-col-bottom-padding>
                    &nbsp;
                  </ion-col>
                </ion-row>
                <ion-row white-background>
                  <ion-col size="4"><strong>Device Key:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblDeviceKey" name="lblDeviceKey">
                      {{ ugw.device_key }}
                    </ion-label>
                  </ion-col>
                  <ion-col></ion-col>
                </ion-row>
              </div>

              <div *ngIf="ugw.model_class_name == gatewayModelClass.MCC">
                <ion-row white-background>
                  <ion-col size="4"><strong>Registration Code:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblRegistrationCode" name="lblRegistrationCode">
                      {{ ugw.registration_code }}
                    </ion-label>
                  </ion-col>
                  <ion-col></ion-col>
                </ion-row>
              </div>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!--Subscription Section-->
  <ion-grid *ngIf="currentGatewayRegistrationStep == gatewayRegistrationStep.SubscriptionStep" class="ion-no-padding">
    <ion-row>
      <ion-col col col-12 me-header-col me-gateway-label>
        <ion-label color="secondary">
          Select a <span translate="no">kenza cloud</span>&#8482;<br>plan for your gateway(s):
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col col col-12>
        <ion-item-group>
          <ion-item me-padding-right-15px>
            <ion-toolbar>
              <ion-buttons slot="end">
                <ion-button (click)="onComparePlans()" me-button-as-link id="btnClickHere" name="btnClickHere">
                  Click Here to Compare Plans
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-item>
          <ion-item me-padding-right-15px>
            <ion-grid>
              <ion-row me-gateway-label>
                <ion-col size="4"><strong>Gateway Name</strong></ion-col>
                <ion-col size="4"><strong>Serial Number</strong></ion-col>
                <ion-col size="4"><strong><span translate="no">kenza cloud</span> Plan</strong></ion-col>
              </ion-row>
              <ion-row white-background *ngFor="let ugw of unregisteredDevices" id="gatewaySubscriptionRow"
                name="gatewaySubscriptionRow" me-gateway-label>
                <ion-col size="4" me-padding-right-15px>{{ ugw.name }}</ion-col>
                <ion-col size="3">{{ ugw.serial_number }}</ion-col>
                <ion-col size="1">
                  <ion-icon *ngIf="ugw.has_coupon" name="pricetag" me-demo-pricetag></ion-icon>
                  <i *ngIf="ugw.has_license"  class="fa-solid fa-ticket-simple margin-right float-right"></i>
                </ion-col>
                <ion-col size="4">
                  <app-plan-selector *ngIf="!ugw.has_license" [gw]="ugw" (selectionChanged)="calculateSubscriptionTotal($event)">
                  </app-plan-selector>
                  <span *ngIf="ugw.has_license">{{ugw.licenseName}}</span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-row me-gateway-label>
            <ion-col size="4">
              <strong></strong>
            </ion-col>
            <ion-col size="4">
              <strong>Recurring Charge Total</strong>
              <div *ngIf="!noPaymentNeeded">(<sup validation-error>*</sup> plus applicable taxes)</div>
            </ion-col>
            <ion-col size="4" me-lineup-with-grid>
              <strong>
                {{retrievedPreview ? ( subscriptionTotal | currency ) + '/mo' : '$-.--'}}<sup *ngIf="!noPaymentNeeded"
                  validation-error>*</sup>
              </strong>
            </ion-col>
          </ion-row>
        </ion-item-group>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="someLicenses" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <i class="fa-solid fa-ticket-simple margin-right"></i><i>Gateway(s) above
            have licenses applied and are not subject to a recurring monthly subscription cost.</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
    <ion-row *ngIf="someCoupons" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
            have coupons applied and are not subject to a recurring monthly subscription cost.</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
  </ion-grid>

  <!--Payment Section-->
  <ion-grid *ngIf="currentGatewayRegistrationStep == gatewayRegistrationStep.PaymentAndRegisterStep">
    <ion-row>
      <ion-col col col-12 me-header-col>
        <ion-label color="secondary">
          Confirm the information below{{ !noPaymentNeeded ? ', and' : '' }} <br>
          {{ !noPaymentNeeded ? 'enter a payment method' : ''}} before proceeding.<br>
          <br *ngIf="!noPaymentNeeded">
          {{ !noPaymentNeeded ? 'Your totals will be amended to include applicable taxes after' : ''}}
          <br *ngIf="!noPaymentNeeded">
          {{ !noPaymentNeeded ? ' selecting a payment method or entering billing information.' : ''}}
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-item me-margin-left-m5></ion-item>
    <ion-item me-margin-left-m5>
      <ion-grid class="ion-no-padding" me-margin-left-5>
        <ion-row me-gateway-label>
          <ion-col size="4"><strong>Gateway Name</strong></ion-col>
          <ion-col size="4"><strong>Serial Number</strong></ion-col>
          <ion-col size="4"><strong><span translate="no">kenza cloud</span> Plan</strong></ion-col>
        </ion-row>
        <ion-row *ngFor="let ugw of unregisteredDevices" id="gatewaySubscriptionRow" me-gateway-label>
          <ion-col size="4" me-padding-right-15px>{{ ugw.name }}</ion-col>
          <ion-col size="3">{{ ugw.serial_number }}</ion-col>
          <ion-col size="1">
            <ion-icon *ngIf="ugw.has_coupon" name="pricetag" me-demo-pricetag></ion-icon>
            <i *ngIf="ugw.has_license"  class="fa-solid fa-ticket-simple margin-right"></i>
          </ion-col>
          <ion-col [style.text-decoration]="ugw.has_coupon ? 'line-through': 'none'" size="4">{{
            (planService.plansKeyed[ugw.subscription_id]?.monthly_rate | currency) + '/mo' }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <ion-row *ngIf="!noPaymentNeeded">
      <form class="paymentForm">
        <ion-radio-group (ionChange)="updatePaymentMethodChoice($event)" [formControl]="useExistingPayment">
          <ion-row>
            <ion-col class="ion-no-padding">
              <ion-item class="payment-method-choice" (click)="useExistingPayment.value='existing'"
                [style.opacity]="paymentMethods.length && !noPaymentNeeded ? 1 : 0.5">
                <ion-label>Use existing site payment method</ion-label>
                <ion-radio class="ion-margin-end" slot="start" value="existing"
                  [disabled]="!paymentMethods.length || noPaymentNeeded">
                </ion-radio>
              </ion-item>
            </ion-col>
            <ion-col class="flex ion-justify-content-center ion-align-items-center"
              *ngIf="useExistingPayment.value=='existing' && !noPaymentNeeded">
              <select [value]="" id="selectPaymentMethod" name="selectPaymentMethod" style="font-size:small"
                *ngIf="useExistingPayment.value=='existing'" (change)="updateExistingPaymentMethodChoice($event)">
                <option value="unselected">Please select a payment method</option>
                <option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.billing_info_id">
                  {{ paymentMethod.card_type | titlecase }} ending in {{ paymentMethod.last_four }}
                  Exp.:{{ paymentMethod.expiration }}
                </option>
              </select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-item class="payment-method-choice" [style.opacity]="noPaymentNeeded ? 0.5 : 1"
              (click)="useExistingPayment.value='new'">
              <ion-label>Enter a new payment method</ion-label>
              <ion-radio class="ion-margin-end" slot="start" value="new" [disabled]="noPaymentNeeded"></ion-radio>
            </ion-item>
          </ion-row>
        </ion-radio-group>
      </form>
    </ion-row>
    <ion-row *ngIf="useExistingPayment.value === 'new' && !noPaymentNeeded">
      <ion-col>
        <ion-item lines="none">
          <ion-label [color]="noPaymentNeeded? 'light' : ''">Secure Payment Information<ion-icon name="lock-closed"></ion-icon></ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <app-credit-card-form [useMaskito]="useMaskito" (formChange)="ccFormValidationChanged($event)"
      *ngIf="useExistingPayment.value === 'new' && !noPaymentNeeded" #ccForm></app-credit-card-form>
    <ion-row *ngIf="!noPaymentNeeded">
      <ion-col class="ion-text-start ion-padding-top">
        <p *ngIf="paymentMethodCollision">
          <ion-text color="danger" me-label-margin>This payment method already exists, please select this option from
            the existing payment methods above or use a different payment method to proceed.</ion-text>
        </p>
        <p me-label-margin>
          Your method of payment will be charged the prorated amount for the first billing cycle immediately upon
          registration. You will be charged on the first of each month thereafter.
        </p>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="noPaymentNeeded">
      <ion-col *ngIf="noPaymentNeeded" me-label-margin class="ion-text-start ion-padding-top">You have selected a <span translate="no">kenza cloud</span> plan that does not require a monthly payment.</ion-col>
    </ion-row>
    <ion-row me-gateway-label *ngIf="!allLicenses">
      <ion-col size="3">
        <string></string>
      </ion-col>
      <ion-col size="5"><strong>Prorated First Month</strong><br>
        <div *ngIf="!noPaymentNeeded">(<sup>*</sup> billed today, approximate value)</div>
      </ion-col>
      <ion-col size="4" me-lineup-with-grid>
        <strong>
          <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
            {{ retrievedPreview ? ( subscriptionProrateTotal | currency ) : '$-.--'}}<sup
              *ngIf="!noPaymentNeeded">*</sup>
          </ion-label><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
        </strong>
      </ion-col>
    </ion-row>
    <ion-row me-gateway-label *ngIf="!allLicenses">
      <ion-col size="3">
        <string></string>
      </ion-col>
      <ion-col size="5"><strong>Recurring Charge Total</strong><br></ion-col>
      <ion-col size="4" me-lineup-with-grid>
        <strong>
          <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
            {{ retrievedPreview ? ( subscriptionTotal | currency ) : '$-.--'}}
          </ion-label><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
        </strong>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="someLicenses" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <i class="fa-solid fa-ticket-simple margin-right"></i><i>Gateway(s) above
            have licenses applied and are not subject to a recurring monthly subscription cost.</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
    <ion-row *ngIf="someCoupons" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
            have coupons applied and are not subject to a recurring monthly subscription cost.</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
    <ion-row *ngIf="allLicenses" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <i>You are registering all licensed gateway(s).</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
    <ion-row *ngIf="someCoupons && someLicenses && !someLimitedStandard" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <i>You are registering a mix of demo gateway(s) and licensed gateway(s).</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
    <ion-row *ngIf="someCoupons && someLicenses && someLimitedStandard" me-gateway-label>
      <ion-col size="2"></ion-col>
      <ion-col col col-8 me-header-col me-gateway-label>
        <ion-label>
          <i>You are registering a mix of subscription gateway(s), demo gateway(s), and licensed gateway(s).</i>
        </ion-label>
      </ion-col>
      <ion-col size="2"></ion-col>
    </ion-row>
  </ion-grid>

  <!--Confirmation Section-->
  <ion-grid *ngIf="currentGatewayRegistrationStep==gatewayRegistrationStep.ConfirmationStep">
    <ion-row>
      <ion-col col col-12 me-header-col>
        <ion-item-group>
          <ion-item>
            <ion-label>Gateway(s) Confirmed</ion-label>
            <ion-icon color="success" name="checkmark-circle"></ion-icon>
          </ion-item>
          <ion-item>
            <ion-label><span translate="no">kenza cloud</span> Plan(s) Selected</ion-label>
            <ion-icon color="success" name="checkmark-circle"></ion-icon>
          </ion-item>
          <ion-item *ngIf="!noPaymentNeeded">
            <ion-label>Payment Method Accepted</ion-label>
            <ion-icon color="success" name="checkmark-circle"></ion-icon>
          </ion-item>
          <ion-item>
            <ion-label>You will receive an email confirmation shortly.</ion-label>
          </ion-item>
        </ion-item-group>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!--Footer-->
<ion-footer>
  <div class="ion-text-center"
    *ngIf="currentGatewayRegistrationStep==gatewayRegistrationStep.PaymentAndRegisterStep && (paymentError || registrationError)">
    <ion-label color="danger" class="ion-margin" *ngIf="registrationError && !paymentError">
      Cannot register a gateway at this time, please try again later.
    </ion-label>
  </div>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex" *ngIf="paymentError">
        <ion-label validation-error>{{paymentErrorMessage}}</ion-label>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center" *ngIf="mainSiteUIService.isDevSite()"
        [style.display]="(currentGatewayRegistrationStep ==gatewayRegistrationStep.GatewayStep) ? 'inline' : 'none'">
        <ion-button (click)="mccEasyButton()" color="dark" [disabled]="model.class_name != gatewayModelClass.MCC">
          {{gatewayModelClass.MCC}} Easy
        </ion-button>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center" *ngIf="mainSiteUIService.isDevSite()"
        [style.display]="(currentGatewayRegistrationStep ==gatewayRegistrationStep.GatewayStep) ? 'inline' : 'none'">
        <ion-button (click)="rmdEasyButton()" color="dark" [disabled]="model.class_name != gatewayModelClass.RMD">
          {{gatewayModelClass.RMD }} Easy
        </ion-button>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-label color="danger" *ngIf="errorMessage">{{errorMessage}}</ion-label>
        <ion-button id="btnPreviousStep" name="btnPreviousStep" color="primary" style="margin:0;"
          [disabled]="currentGatewayRegistrationStep==gatewayRegistrationStep.InformationStep"
          [style.visibility]="(currentGatewayRegistrationStep>gatewayRegistrationStep.InformationStep) && (currentGatewayRegistrationStep<gatewayRegistrationStep.ConfirmationStep) ? 'visible' : 'hidden'"
          (click)="onNavigateRegistrationProcess(navigationDirection.Previous)">
          Back
        </ion-button>
        <ion-button id="btnNextStep" name="btnNextStep" style="margin: 0 0 0 1em;" color="primary"
          *ngIf="currentGatewayRegistrationStep !==gatewayRegistrationStep.ConfirmationStep"
          [disabled]="disableNextButton()" (click)="onNavigateRegistrationProcess(navigationDirection.Next)">
          {{nextButtonText}}
        </ion-button>
        <ion-button *ngIf="currentGatewayRegistrationStep !==gatewayRegistrationStep.ConfirmationStep" id="btnCancel"
          name="btnCancel" (click)="onCancel()" style="margin: 0 0 0 1em;" color="reverse-primary">
          Cancel
        </ion-button>
        <ion-button *ngIf="currentGatewayRegistrationStep==gatewayRegistrationStep.ConfirmationStep" id="btnClose"
          name="btnClose" (click)="onClose()" style="margin: 0 0 0 1em;" color="primary">
          Close
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>