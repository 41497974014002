import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MainSiteUIService } from '../services/ui/main-site-ui.service';
import { UserService } from '../services/user/user.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UnauthGuard implements CanActivate {
  constructor(
    private user: UserService, 
    private mainSiteUIService: MainSiteUIService,
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let baseUrl = state.url.split('?')[0];  // gets rid of url params  ie: /baseurl?asdf=1234
    switch(baseUrl){
      case "/contactus":
      case "/privacypolicy":
      case "/subscriberagreement":
      case "/copyright":
      case "/comparesubscriptionplans":
      case "/transfer":
        // in these cases show the left nav if we're signin
        this.mainSiteUIService.showLeftNav = this.user.signedIn;
        return true;
    default:
      // signin, signup, forget,
      return this.user.auth.currentAuthenticatedUser().then(() => {
        // have a user session - go home.
        this.router.navigate(['home']);
        return true; 
      })
      .catch((error) => {
        // do not have a user - hide side nav but ok to proceed.
        this.mainSiteUIService.showLeftNav = false;
        return true;
      })
    }
  }
}  
