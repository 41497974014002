import moment from 'moment-timezone';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { ECharts, EChartsOption, init } from 'echarts';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { CustomObservers } from 'src/app/common/classes/observers';
import { DataService } from 'src/app/common/services/data/DataService';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Component, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { PressurePreferenceEnum, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';

enum DisplayCharts {
  Temperature = `Temp`,
  Precipitation = `Rain`,
  Wind = `Wind`,
}

@Component({
  selector: 'app-siteWeather-card',
  templateUrl: './siteWeather.component.html',
  styleUrls: ['./siteWeather.component.scss', '../dashboard.shared.scss'],
})

export class SiteWeatherCardComponent {

  lat;
  lon;
  siteZip;
  siteName;
  coordinates;
  chartTimeout;
  devEnv = devEnv;
  weatherChartDiv;
  coordinateMessage;
  alertOpen = false;
  chartDataLoaded = false;
  onActiveSiteChanged = null;
  weatherChart: ECharts = null;
  siteEdited: Subscription = null;
  dynamicSiteTimerInterval = null;
  weatherChartOption: EChartsOption;
  @Input() usePressurePreference = false;
  displayedChart = DisplayCharts.Temperature;
  PressurePreferenceEnum = PressurePreferenceEnum;
  displayedChartTypes = Object.values(DisplayCharts);
  weatherDataLoadedSubscription: Subscription = null;
  TemperaturePreferenceEnum = TemperaturePreferenceEnum;
  @ViewChild('weatherChart') weatherChartElem: ElementRef;
  @ViewChild('selectWeatherType') selectWeatherType: ElementRef;
  dynamicSiteTime = moment().tz(this.siteService?.weatherData?.siteTimezone ?? this.user.active.timezone).format(`h:mm A`);

  constructor(
    protected router: Router,
    protected elm: ElementRef,
    protected user: UserService,
    protected dataService: DataService,
    protected siteService: SiteService,
    private alertController: AlertController,
    protected appAuth: AppAuthenticationService,
    public mainSiteUIService: MainSiteUIService,
  ) {
    // Empty
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (this.weatherChart != null) {
      this.weatherChart.resize();
    }
  }

  capWords = (str) => {
    return str.replace(/\b\w/g, (match) => {
      return match.toUpperCase();
    });
  }

  ngOnInit() {
    // Empty
  }

  ngAfterViewInit() {
    this.weatherDataLoadedSubscription = this.siteService.weatherDataLoaded$.subscribe((loaded) => {
      this.chartDataLoaded = loaded;
      if (loaded == true) {
        this.observeWeatherCardElement();
      }
    });

    // Update Site Time Dynamically
    if (window.location.href.includes(`/dashboard`) && this.siteService.weatherDataLoaded) {
      this.dynamicSiteTimerInterval = setInterval(() => {
        if (this.siteService?.weatherDataError == false) {
          let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          let timezone = this.siteService?.weatherData?.siteTimezone || this.user.active.timezone || browserTimezone;
          this.dynamicSiteTime = moment().tz(timezone).format(`h:mm A`);
          let minutes = moment().tz(timezone).format(`mm`);
          let seconds = moment().tz(timezone).format(`ss`);
          // devEnv && console.log(`Update Weather Timer`, moment().tz(timezone).format(`h:mm:ss A`));

          // Update Weather at the start of each new Hour
          if (minutes == `00` && seconds == `00`) {
            let clearCache = true;
            this.user.getSiteWeather(this.user.active, clearCache);
            this.renderChart();
          }
        } else {
          clearInterval(this.dynamicSiteTimerInterval);
        }
      }, 1000);
    } else {
      if (this.dynamicSiteTimerInterval != null) {
        clearInterval(this.dynamicSiteTimerInterval);
        this.dynamicSiteTimerInterval = null;
      }
    }
  }

  observeWeatherCardElement() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.renderChart();
        } else {
          if (this.dynamicSiteTimerInterval != null && !window.location.href.includes(`/dashboard`)) {
            clearInterval(this.dynamicSiteTimerInterval);
            this.dynamicSiteTimerInterval = null;
          }
        }
      });
    }, {
      rootMargin: `0px`,
      threshold: 0.1,
      root: null,
    });
    observer.observe(this.elm.nativeElement.querySelector(`#weatherChart`));
  }

  renderChart(delay?) {
    if (delay == null || delay == undefined) delay = 50;
    CustomObservers.waitForElementToRenderObserver(`#weatherChart`).then((itm) => {
      this.chartTimeout = setTimeout(() => {
        if (document.querySelector(`#weatherChart`)) {
          if (this.weatherChart) this.weatherChart.dispose();
          this.weatherChartDiv = itm;
          this.makeWeatherChart();
        }
      }, delay);
    });
  }

  weatherTypeChanged(value) {
    this.setDisplayedChart(value);
  }

  setSiteValues() {
    this.siteName = this.user.active.name;
    this.siteZip = this.user.active.zip;
    this.lat = this.siteService?.weatherData?.weather?.lat;
    this.lon = this.siteService?.weatherData?.weather?.lon;
    this.coordinates = `${this.lat < 0 ? `${Math.abs(this.lat)}° S` : `${Math.abs(this.lat)}° N`}, ${this.lon < 0 ? `${Math.abs(this.lon)}° W` : `${Math.abs(this.lon)}° E`}`;
    this.coordinateMessage = `${this.siteName}, ${this.siteZip}: Weather for ${this.coordinates}`;
    // this.coordinateMessage = `
    // <br>
    // <br>
    // Showing weather for ${this.siteZip}
    // <br>
    // ${this.coordinates}
    // <br>
    // ${this.siteName}`;
  }

  setDisplayedChart(displayChart) {
    if (this.displayedChart == displayChart) return;
    this.weatherChart.dispose();
    this.displayedChart = displayChart;
    this.makeWeatherChart();
  }

  async presentAlert() {
    if (this.alertOpen == true) return;
    if (!this.siteService.handleIsConnected()) return;
    this.setSiteValues();
    const weatherAlert = await this.alertController.create({
      id: `weatherAlertMsg`,
      header: `Weather Data`,
      cssClass: `alertMsg me-info-button-css`,
      subHeader: `We use the following methods for weather.`,
      message: `Wet/Dry Bulb Temperatures are calculated via the Stull formula.
      <br>
      <br>
      Weather data, such as current temperature, high/low, chance of rain (%), etc., can potentially show the current conditions relative to the geographic center of the site's ZIP code.`,
      buttons: [{
        text: `Ok`, role: `cancel`, cssClass: `ok-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
        })
      }]
    });
    this.alertOpen = true;
    return await weatherAlert.present();
  }

  makeWeatherChart() {
    this.setSiteValues();
    let chartXAxisHours = 24;
    const root = this.elm.nativeElement.querySelector(`#weatherChart`);

    const get24HourRange = (hours, startTime, desiredHours) => {
      let range = [];
      let startIndex = hours.map(h => h?.tzTime).indexOf(startTime);
      if (startIndex === -1) {
        return range;
      }
      for (let i = 0; i < (desiredHours + 1); i++) {
        let hourIndex = (startIndex + i) % hours.length;
        range.push(hours[hourIndex]);
      }
      return range;
    };

    let timezone = this.siteService?.weatherData?.siteTimezone || this.user.active.timezone;

    if (timezone != undefined && timezone != null) {
      let hourly = chartXAxisHours == 24 ? get24HourRange(this.siteService?.weatherData?.hourly, moment().tz(timezone).format(`h A`), 24) : get24HourRange(this.siteService?.weatherData?.hourly, moment().tz(timezone).format(`h A`), chartXAxisHours);
      let tempData = this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? hourly?.map(h => h?.cTemp) : hourly?.map(h => h?.fTemp);
      let tempPref = this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? `°C` : `°F`;
      let spaceInterval = this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? 5 : 10;
      let interval = this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? 5 : 20;
      let rainData = hourly?.map(h => h?.pop);
      let windData = hourly?.map(h => h?.windSpeed);
      let timeZoneConvertedHours = hourly?.map(h => h?.tzTime);
      this.weatherChart = init(root);

      if (this.displayedChart == DisplayCharts.Temperature) {
        // Temperature Chart
        this.weatherChartOption = {
          title: {
            text: `Temperature (${chartXAxisHours} Hours)`,
            backgroundColor: `rgba(255,255,255,0.75)`,
            borderRadius: 4,
            left: `right`,
            top: 3,
            textStyle: {
              fontSize: 12,
              color: `#287c9f`,
            }
          },
          grid: {
            top: 15,
            left: 45,
            right: 15,
            bottom: 0,
            height: 155,
          },
          tooltip: {
            trigger: `axis`,
            // textStyle: {
            //   color: `white`,
            // },
            // backgroundColor: `var(--ion-color-secondary)`,
            formatter: function(params) {
              return `${hourly[params[0].dataIndex]?.toolTipDateTime} <span style="color: #287c9f">●</span> ${params[0]?.value} ${tempPref}`;
            },
            axisPointer: {
              type: `cross`,
              label: {
                backgroundColor: `#000`
              }
            }
          },
          xAxis: {
            type: `category`,
            boundaryGap: false,
            data: timeZoneConvertedHours,
            axisLabel: {
              fontSize: 10,
            },
            splitLine: {
              show: true
            },
          },
          yAxis: {
            type: `value`,
            max: (Math.ceil((Math.max(...tempData) / spaceInterval)) * spaceInterval) + interval,
            axisLabel: {
              fontSize: 10,
              formatter: function(value) {
                if (value == (Math.ceil((Math.max(...tempData) / spaceInterval)) * spaceInterval) + interval) return ``;
                return `${value} ${tempPref}`;
              },
            },
            axisPointer: {
              label: {
                show: false,
                backgroundColor: `#000`,
                formatter: function (params) {
                  let value: any = params?.value;
                  return `${value.toFixed(2)} ${tempPref}`;
                }
              }
            }
          },
          series: [
            {
              name: `Temperature`,
              type: `line`,
              smooth: true,
              symbol: `circle`,
              symbolSize: 5,
              data: tempData,
              label: {
                show: chartXAxisHours != 24,
                position: `bottom`, // or 'inside', 'right', 'left', 'bottom', etc.
                formatter: (params: any) => {
                  if (params?.dataIndex == 0 || params?.dataIndex >= (chartXAxisHours - 1) || params?.value == 0) return ``;
                  return `${params?.value} ${tempPref}`;
                },
                color: `#287c9f`,
                fontSize: 7,
              },
              areaStyle: {
                color: `rgba(237, 237, 237, 1)`
              },
              lineStyle: {
                color: `#287c9f`
              },
              itemStyle: {
                borderWidth: 2,
                color: `white`,
                borderColor: `#287c9f`
              }
            }
          ]
        } as EChartsOption;
      } else if (this.displayedChart == DisplayCharts.Precipitation) {
        // Precipitation Probability // Rain Chart
        this.weatherChartOption = {
          title: {
            text: `Precipitation Probability (${chartXAxisHours} Hours)`,
            backgroundColor: `rgba(255,255,255,0.75)`,
            borderRadius: 4,
            left: `right`,
            top: 3,
            textStyle: {
              fontSize: 12,
              color: `#287c9f`,
            }
          },
          grid: {
            top: 15,
            left: 40,
            right: 15,
            bottom: 0,
            height: 155,
          },
          tooltip: {
            trigger: `axis`,
            // textStyle: {
            //   color: `white`,
            // },
            // backgroundColor: `var(--ion-color-secondary)`,
            formatter: function(params) {
              return `${hourly[params[0].dataIndex]?.toolTipDateTime} <span style="color: #287c9f">●</span> ${(params[0]?.value * 100).toFixed(1)}%`;
            },
            axisPointer: {
              type: `cross`,
              label: {
                backgroundColor: `#000`,
              }
            }
          },
          xAxis: {
            type: `category`,
            boundaryGap: false,
            data: timeZoneConvertedHours,
            axisLabel: {
              fontSize: 10,
            },
            splitLine: {
              show: true
            },
          },
          yAxis: {
            type: `value`,
            max: 1.2,
            axisLabel: {
              fontSize: 10,
              formatter: function(value) {
                if (value == 1.2) return ``;
                return `${(value * 100)}%`;
              }
            },
            axisPointer: {
              label: {
                show: false,
                backgroundColor: `#000`,
                formatter: function(params) {
                  let value: any = params?.value;
                  return `${(value * 100).toFixed(1)}%`;
                }
              }
            }
          },
          series: [
            {
              name: `Precipitation Probability`,
              type: `line`,
              smooth: true,
              symbol: `circle`,
              symbolSize: 5,
              data: rainData,
              label: {
                show: chartXAxisHours != 24,
                position: `bottom`, // or 'inside', 'right', 'left', 'bottom', etc.
                formatter: (params: any) => {
                  if (params?.dataIndex == 0 || params?.dataIndex >= (chartXAxisHours - 1) || params?.value == 0) return ``;
                  return `${(params?.value * 100).toFixed(0)}%`;
                },
                color: `#287c9f`,
                fontSize: 8,
              },
              areaStyle: {
                color: `rgba(237, 237, 237, 1)`
              },
              lineStyle: {
                color: `#287c9f`
              },
              itemStyle: {
                borderWidth: 2,
                color: `white`,
                borderColor: `#287c9f`
              }
            }
          ]
        } as EChartsOption;
      } else {
        // Wind Speed // Wind Chart
        this.weatherChartOption = {
          title: {
            text: `Wind Speed (${chartXAxisHours} Hours)`,
            backgroundColor: `rgba(255,255,255,0.75)`,
            borderRadius: 4,
            left: `right`,
            top: 3,
            textStyle: {
              fontSize: 12,
              color: `#287c9f`,
            }
          },
          grid: {
            top: 15,
            left: 45,
            right: 15,
            bottom: 0,
            height: 155,
          },
          tooltip: {
            trigger: `axis`,
            // textStyle: {
            //   color: `white`,
            // },
            // backgroundColor: `var(--ion-color-secondary)`,
            formatter: function(params) {
              return `${hourly[params[0].dataIndex]?.toolTipDateTime} <span style="color: #287c9f">●</span> ${params[0]?.value} mph`;
            },
            axisPointer: {
              type: `cross`,
              label: {
                backgroundColor: `#000`
              }
            }
          },
          xAxis: {
            type: `category`,
            boundaryGap: false,
            data: timeZoneConvertedHours,
            splitLine: {
              show: true
            },
            axisLabel: {
              fontSize: 10,
            }
          },
          yAxis: {
            type: `value`,
            max: Math.ceil(Math.max(...windData) + 2),
            axisLabel: {
              fontSize: 10,
              formatter: function(value) {
                if (value == Math.ceil(Math.max(...windData) + 2)) return ``;
                return `${value} mph`;
              }
            },
            axisPointer: {
              label: {
                show: false,
                backgroundColor: `#000`,
                formatter: function (params) {
                  let value: any = params?.value;
                  return `${value.toFixed(2)} mph`;
                }
              }
            }
          },
          series: [
            {
              name: `Wind Speed`,
              type: `line`,
              smooth: true,
              symbol: `circle`,
              symbolSize: 5,
              data: windData,
              label: {
                show: chartXAxisHours != 24,
                position: `bottom`, // or 'inside', 'right', 'left', 'bottom', etc.
                formatter: (params: any) => {
                  if (params?.dataIndex == 0 || params?.dataIndex >= (chartXAxisHours - 1) || params?.value == 0) return ``;
                  return `${params?.value} mph`;
                },
                color: `#287c9f`,
                fontSize: 6,
              },
              areaStyle: {
                color: `rgba(237, 237, 237, 1)`
              },
              lineStyle: {
                color: `#287c9f`
              },
              itemStyle: {
                borderWidth: 2,
                color: `white`,
                borderColor: `#287c9f`
              }
            }
          ]
        } as EChartsOption;
      }
      this.weatherChart.setOption(this.weatherChartOption);
    }
  }

  ionViewWillLeave() {
    if (this.dynamicSiteTimerInterval != null) {
      clearInterval(this.dynamicSiteTimerInterval);
      this.dynamicSiteTimerInterval = null;
    }

    if (this.weatherDataLoadedSubscription != null) {
      this.weatherDataLoadedSubscription.unsubscribe();
      this.user.clearUserSubscriptions();
    }

    if (this.weatherChart != null) {
      this.weatherChart.dispose();
      clearTimeout(this.chartTimeout);
      this.user.clearUserSubscriptions();
    }
  }

  ngOnDestroy() {
    if (this.dynamicSiteTimerInterval != null) {
      clearInterval(this.dynamicSiteTimerInterval);
      this.dynamicSiteTimerInterval = null;
    }

    if (this.weatherDataLoadedSubscription != null) {
      this.weatherDataLoadedSubscription.unsubscribe();
      this.user.clearUserSubscriptions();
    }

    if (this.weatherChart != null) {
      this.weatherChart.dispose();
      clearTimeout(this.chartTimeout);
      this.user.clearUserSubscriptions();
    }
  }
}