import {
    Input,
    Output,
    QueryList,
    Component,
    ViewChild,
    ElementRef,
    EventEmitter,
    ViewChildren,
    ChangeDetectionStrategy,
} from '@angular/core';
import moment from 'moment';
import { Validators } from '@angular/forms';
import { CalendarEvent } from 'angular-calendar';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { AlertController, ModalController } from '@ionic/angular';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { BatchGroupDisplay } from '../site-control/batchControl/batchGroupDisplay';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SelectedGroup } from 'src/app/common/components/group-selector/group-selector.component';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { SearchAndFilterComponent } from 'src/app/common/components/search-and-filter/search-and-filter.component';
import { FanSpeedStages, GatewayUnit, IndoorFanSpeed, LossnayFanSpeed, Presence } from 'src/app/features/manage/components/classes/GatewayUnit';
import { GatewayUnitTwoDigitType, GroupSelectorFilterTypes, LevelEnum, ModesSpeedsDirections, SearchFilterActionTypes, SearchFilterObjectTypes, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';

@Component({
    selector: 'app-site-scheduling-event-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './site-scheduling-event-form.component.html',
    styleUrls: ['./site-scheduling-event-form.component.scss'],
})

export class SiteSchedulingEventFormComponent {

    // Outputs
    @Input() onAddEvent;
    @Input() onDeleteEvent;
    @Input() onChangeEvent;
    @Output() setIsRecurring = new EventEmitter<boolean>();
    internalNewGroupsSelected = new EventEmitter<SelectedGroup[]>();
    @Output() newGroupsSelected = new EventEmitter<SelectedGroup[]>();

    // Inputs
    @Input() week;
    @Input() view;
    @Input() title;
    @Input() width;
    @Input() onMac;
    @Input() height;
    @Input() colors;
    @Input() action;
    @Input() actions;
    @Input() errorMsg;
    @Input() fanSpeed;
    @Input() startDate;
    @Input() showIcForm;
    @Input() temperature;
    @Input() setWeekTime;
    @Input() unitDetails;
    @Input() siteService;
    @Input() mapFanSpeed;
    @Input() degreesType;
    @Input() airDirection;
    @Input() is_recurring;
    @Input() windowEvents;
    @Input() endD: string;
    @Input() allSiteEvents;
    @Input() getEventColor;
    @Input() deleteEventUI;
    @Input() endDate: Date;
    @Input() selectedGroup;
    @Input() recurringSched;
    @Input() endDay: string;
    @Input() selectedGroups;
    @Input() startD: string;
    @Input() backEndToEvents;
    @Input() batchScheduling;
    @Input() endHour: string;
    @Input() endTime: string;
    @Input() createBatchEvent;
    @Input() siteTime: string;
    @Input() startDay: string;
    @Input() askEventOrSeries;
    @Input() units: Gateway[];
    @Input() updateBatchEvent;
    @Input() scheduleForGroups;
    @Input() deleteDayFromEvent;
    @Input() refreshGroupEvents;
    @Input() allowedTempValues;
    @Input() postEventToBackEnd;
    @Input() startHour: string;
    @Input() startTime: string;
    @Input() activeMode: string;
    @Input() startXMode: string;
    @Input() activePower: string;
    @Input() selectedGatewayUnit;
    @Input() endDateClick: string;
    @Input() tempPoints: number[];
    @Input() removeGroupFromBatch;
    @Input() minuteBarrier: number;
    @Input() getEventsForSelection;
    @Input() activeVentMode: string;
    @Input() startDateClick: string;
    @Input() startCalFormat: string;
    @Input() avgTemperature: number;
    @Input() endMomentFormat: string;
    @Input() endIsoDateFormat: string;
    @Input() postBatchEventsToBackEnd;
    @Input() selectedGateway: Gateway;
    @Input() startMomentFormat: string;
    @Input() presentSubscriptionOptions;
    @Input() startIsoDateFormat: string;
    @Input() createEventButton: boolean;
    @Input() startTimeConverted: string;
    @Input() deleteBatchEventFromBackEnd;
    @Input() degreesTypeAbbreviation: string;
    @Input() recurringAndSingleEvents: boolean;
    @Input() createFrontEndEventsFromBackEndEvents;
    @Input() evnt: CalendarEvent<EventControlPoints>;
    @Input() events: CalendarEvent<EventControlPoints>[];
    @Input() convertBatchEventParametersToDatabaseEvents;

    // Event Form
    IDs = [];
    titleCtrl = null;
    groupByGateways = false;
    showBatchBadges = false;
    upperDirection = Direction.UP;
    transitionDualToTriple = false;
    dualSetPointInitialized = false;
    lowerDirection = Direction.DOWN;
    upperDirection2 = Direction.UP;
    lowerDirection2 = Direction.DOWN;
    capWords = globalFunctions.capWords;
    sliderControlToAffect = `#eventStartTempItem`;
    GatewayUnitTwoDigitType = GatewayUnitTwoDigitType;
    SearchFilterActionTypes = SearchFilterActionTypes;
    SearchFilterObjectTypes = SearchFilterObjectTypes;
    @ViewChildren(`unitItemCheckbox`) unitItemCheckboxes: QueryList<ElementRef>;
    @ViewChild(SearchAndFilterComponent) searchAndFilter: SearchAndFilterComponent;
    @ViewChild(`checkboxGridListContainer`, { static: false }) checkboxGridListContainer: ElementRef;
    selectedGroupOptions = [];
    public bgd: BatchGroupDisplay = new BatchGroupDisplay(this.user, this.appAuth, this.mainSiteUIService);
    coolSetTemp: number = null;
    heatSetTemp: number = null;
    devEnv = devEnv;
    deadband = null;
    isMetric = true;
    endTemperature;
    updateIndex = 0;
    customStep = 0.5;
    debounceTime = 0;
    tempCalculations;
    eventPast = false;
    dateLabel: string;
    timeLabel: string;
    buttonMode = true;
    formOpen: boolean;
    tempFloor: number;
    titleLabel: string;
    tempError: boolean;
    timeColLeft = true;
    inDeadband = false;
    daysChanged = false;
    saveEnabled = false;
    eventEnabled = true;
    temperatureErrorMsg;
    alldayEvent = false;
    eventFuture = false;
    ventModes: string[];
    unitModes: string[];
    fanSpeeds: string[];
    airDirects: string[];
    activeModes: string[];
    levelEnum = LevelEnum;
    alldayEnabled = false;
    tempCalculationValues;
    buttonModes: string[];
    placeHolderNum: number;
    eventDisabled: boolean;
    activePowers: string[];
    activeElementContainer;
    minTemperature: number;
    maxTemperature: number;
    minPlusDeadband: number;
    sidebarExpanded = false;
    presentationMode: string;
    LastSelectedByOrder = [];
    activeFanSpeeds: string[];
    fanSpeedControls: string[];
    isDualSetDisabled: boolean;
    filteredUniqueGateways = [];
    presentationModes: string[];
    eventOrSeriesText = `Event`;
    today = moment().format(`dddd`);
    initialFormTitle = `Event`;
    siteSchedulingEventForm: UntypedFormGroup;
    windowHeight: number = window.innerHeight;
    ModesSpeedsDirections = ModesSpeedsDirections;
    alldayStatus = this.alldayEvent ? `Yes` : `No`;
    calendarTimerFormat = `dddd, MMMM Do, h:mm:ss a`;
    GroupSelectorFilterTypes = GroupSelectorFilterTypes;
    detectFormChanges = globalFunctions.detectFormChanges;
    weekEnd = (<any>moment().tz(this.user.active.timezone).endOf(`week`).add(`4`, `hours`))._d;
    weekStart = (<any>moment().tz(this.user.active.timezone).startOf(`week`).add(`4`, `hours`))._d;
    eventDays: string[] = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
    days: string[] = this.eventDays.filter(day => day == this.startD);
    weekdays = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];

    MODE_MIN_MAX_DEFAULTS = {
        'cool': {
            'min': 19,
            'max': 30
        },
        'heat': {
            'min': 17,
            'max': 28
        },
        'auto': {
            'min': 19,
            'max': 28
        },
    }

    upperSetTemp: number;
    lowerSetTemp: number;

    groupsChanged = false;
    temperature2Lower = parseFloat(this.bgd.setTemp.dualSetPointLowLabel);
    temperature2Upper = parseFloat(this.bgd.setTemp.dualSetPointHighLabel);
    temperature2 = this.temp2DualKnobs() ? {
        lowerMin: this.getMinTemp2(),
        upperMax: this.getMaxTemp2(),
    } : this.getMinTemp2();

    constructor(
        private ele: ElementRef,
        public user: UserService,
        public formBuilder: UntypedFormBuilder,
        public appAuth: AppAuthenticationService,
        protected alertController: AlertController,
        protected modalController: ModalController,
        private mainSiteUIService: MainSiteUIService,
        protected planService: SubscriptionPlanService,
    ) {
        // Empty
    }

    // Initialize UI
    ngOnInit() {
        this.timeLabel = `Event Time`;
        this.dateLabel = `Event Date`;
        this.titleLabel = `Event Title`;
        this.endTemperature = this.avgTemperature;
        this.activePowers = [ModesSpeedsDirections.on, ModesSpeedsDirections.off];
        this.evnt && this.evnt.meta.is_recurring ? this.days = Object.entries(this.evnt.meta.days).map(ifDay => ifDay[1] ? ifDay[0] : null).map(day => typeof day == `string` ? globalFunctions.capitalizeAllWords(day) : null).filter(day => typeof day == `string`) : this.days = this.eventDays.filter(day => day == this.startD);
        this.evnt && this.evnt.meta.multiDayEvent ? this.eventOrSeriesText = `Series` : this.eventOrSeriesText = `Event`;

        this.IDs = this.selectedGroups.map(gr => gr.id);
        this.updateBGD(this.IDs);

        this.refreshEventModes();

        // Initialize Form
        this.eventAdminForm();
        let schedulingEventFormElement = document.querySelector(`#siteSchedulingEventForm`);
        if (schedulingEventFormElement) {
            if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
                schedulingEventFormElement.addEventListener(`click`, event => {
                    let targetParent = (<any>event.target).parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
                    if (this.is_recurring && !(<any>event.target).classList.contains(`dayButton`)) (<any>document.querySelector(`#eventDaysItem`)).classList.remove(`customValidation`, `ion-valid`);
                    let element = (<any>document.querySelector(`#eventStartTempItem`));
                    let element2 = (<any>document.querySelector(`#eventStartTempItem2`));
                    if (targetParent.id == element.id || (<any>event.target).id == `eventStartTempRange`) {
                        if (element) {
                            if (element.classList.contains(`item-has-focus`)) {
                                element?.classList.remove(`item-has-focus`);
                            } else {
                                element?.classList.add(`item-has-focus`);
                                setTimeout(() => {
                                    element?.classList.remove(`item-has-focus`);
                                }, 150)
                            }
                       }
                    } else {
                        if (element) element?.classList.remove(`item-has-focus`);
                        if (element2) {
                            if (element2.classList.contains(`item-has-focus`)) {
                                element2?.classList.remove(`item-has-focus`);
                            } else {
                                element2?.classList.add(`item-has-focus`);
                                setTimeout(() => {
                                    element2?.classList.remove(`item-has-focus`);
                                }, 150)
                            }
                        }
                    }
                });
            } else {
                schedulingEventFormElement.addEventListener(`click`, event => {
                    if (this.is_recurring && !(<any>event.target).classList.contains(`dayButton`)) (<any>document.querySelector(`#eventDaysItem`)).classList.remove(`customValidation`, `ion-valid`);
                });
            }
        }

        // After Init Functions
        this.isDualSetDisabled = this.isDualSetPointDisabled();

        this.evnt ? this.minTemperature = this.getMinTemp(this.evnt) : this.minTemperature = this.getMinTemp();
        this.evnt ? this.maxTemperature = this.getMaxTemp(this.evnt) : this.maxTemperature = this.getMaxTemp();

        // Temperature Validation
        if (this.isDualSetDisabled) {
            if (this.allowedTempValues.includes(parseFloat(this.temperature))) {
                this.eventEnabled = true;
            } else {
                if (this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay) this.eventEnabled = false;
                if (!this.evnt) this.temperatureErrorMsg = `Please enter a temperature value in the 0.5 intervals`;
            }
        }
        this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? this.placeHolderNum = 20 : this.placeHolderNum = 70;
        this.tempCalculationValues = Object.values(this.selectedGatewayUnit).filter((val: any) => { if (!isNaN(val) && parseFloat(val) >= 10) return parseFloat(val) }).sort((a: any, b: any) => a - b);
        this.customStep = this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? 0.5 : 1;
        if (this.evnt && !this.evnt.meta.active) this.eventDisabled = true;
        if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && this.activeMode != ModesSpeedsDirections.fan) {
            this.minPlusDeadband = parseFloat(this.minTemperature.toString()) + this.selectedGatewayUnit.deadband;
            if (this.upperSetTemp > this.maxTemperature) this.upperSetTemp = this.maxTemperature;
            if (this.temperature.upper > this.maxTemperature) this.temperature.upper = this.maxTemperature;
            if (this.lowerSetTemp < this.minTemperature) this.lowerSetTemp = this.minTemperature;
            if (this.temperature.lower < this.minTemperature) this.temperature.lower = this.minTemperature;
        } else {
            this.minPlusDeadband = this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? 20 : 70;
        }

        // Track Form Value Changes
        if (this.siteSchedulingEventForm) {
            this.siteSchedulingEventForm.valueChanges.subscribe(eventFormValue => {
                this.formEventHandler(eventFormValue);
            });
        }

        if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
            this.isMetric = false;
        }

        this.deadband = this.bgd.setTemp.deadband;

        if (this.evnt) {
            if (typeof this.evnt.meta.temperature == `number`) { // SSP
                this.coolSetTemp = this.evnt.meta.temperature;
                this.heatSetTemp = this.evnt.meta.temperature;
            } else {
                if (this.evnt.meta.temperature) { // if not a lossnay
                    this.coolSetTemp = this.evnt.meta.temperature[`upper`];
                    this.heatSetTemp = this.evnt.meta.temperature[`lower`];
                }
            }

            if (this.coolSetTemp == null) {
                this.setTemperaturRangeSetPoint(this.heatSetTemp);
            } else if (this.heatSetTemp == null) {
                this.setTemperaturRangeSetPoint(this.coolSetTemp);
            } else {
                this.setTemperaturRangeDualSetPoint(this.heatSetTemp, this.coolSetTemp);
            }
        } else {
            if (this.doesSupportDualSetpoint()) { // set the current temps for each type.  If single setpoint going to still use this but keep the DSP values for easy code
                this.coolSetTemp = parseFloat(this.selectedGatewayUnit.SetTemp1);
                this.heatSetTemp = parseFloat(this.selectedGatewayUnit.SetTemp2);

                // some edge case fixes with valid values of SetPoint1 and SetPoint2 when compared to AutoMin/Max
                if (this.coolSetTemp > this.maxTemperature) {
                    this.coolSetTemp = this.maxTemperature;
                }

                if ((this.coolSetTemp - this.heatSetTemp) < this.deadband) {
                    this.heatSetTemp = this.coolSetTemp - this.deadband;
                }

                if (this.heatSetTemp < this.minTemperature) {
                    this.heatSetTemp = this.minTemperature;
                }

                if ((this.coolSetTemp - this.heatSetTemp) < this.deadband) {
                    this.coolSetTemp = this.heatSetTemp + this.deadband;
                }

            } else {
                this.coolSetTemp = parseFloat(this.selectedGatewayUnit.SetTemp);
                this.heatSetTemp = parseFloat(this.selectedGatewayUnit.SetTemp);
            }
        }

        if (this.temp2DualKnobs() == true) {
            this.temperature2 = {
                lowerMin: this.getMinTemp2(),
                upperMax: this.getMaxTemp2(),
            }
        } else {
            this.temperature2 = this.getMinTemp2()
        }

        this.temperature2Lower = parseFloat(this.bgd.setTemp.dualSetPointLowLabel);
        this.temperature2Upper = parseFloat(this.bgd.setTemp.dualSetPointHighLabel);

        // End NgOnInit
    }

    variablesToLog(value?) {
        return {
            value,
            BGD: this.bgd,
            days: this.days,
            cool: this.coolSetTemp,
            heat: this.heatSetTemp,
            deadband: this.deadband,
            existingEvent: this.evnt,
            customStep: this.customStep,
            activeMode: this.activeMode,
            temperature: this.temperature,
            saveEnabled: this.saveEnabled,
            lowerSetTemp: this.lowerSetTemp,
            upperSetTemp: this.upperSetTemp,
            groupsChanged: this.groupsChanged,
            // temperature2: this.temperature2,
            // selectedGroups: this.selectedGroups,
            bgdDeadband: this.bgd.setTemp.deadband,
            temperature2Upper: this.temperature2Upper,
            isDualSetDisabled: this.isDualSetDisabled,
            temperature2Lower: this.temperature2Lower,
            // selected: this.selectedGroupsForEvent(),
            // firstUnitMode: this.bgd.mode.firstUnitMode,
            LastSelectedByOrder: this.LastSelectedByOrder,
            multipleRangeSliders: this.multipleRangeSliders(),
            // unitDeadband: this.selectedGatewayUnit.deadband,
            transitionDualToTriple: this.transitionDualToTriple,
            dualSetPointInitialized: this.dualSetPointInitialized,
            ...(this.siteSchedulingEventForm && {
                formValues: {
                    // form: this.siteSchedulingEventForm,
                    // errors: this.siteSchedulingEventForm.errors,
                    status: this.siteSchedulingEventForm.status,
                    days: this.siteSchedulingEventForm.value.days,
                    mode: this.siteSchedulingEventForm.value.mode,
                    temperature: this.siteSchedulingEventForm.value.temperature,
                    lowerSetTemp: this.siteSchedulingEventForm.value.lowerSetTemp,
                    airDirection: this.siteSchedulingEventForm.value.airDirection,
                    upperSetTemp: this.siteSchedulingEventForm.value.upperSetTemp,
                    lowerSetTemp2: this.siteSchedulingEventForm.value.lowerSetTemp2,
                    temperatureRange: this.siteSchedulingEventForm.value.temperatureRange,
                    lastSelectedUnits: this.siteSchedulingEventForm.value.lastSelectedUnits,
                    temperatureRange2: this.siteSchedulingEventForm.value.temperatureRange2,
                    temperature2Upper: this.siteSchedulingEventForm.value.temperature2Upper,
                    temperature2Lower: this.siteSchedulingEventForm.value.temperature2Lower,
                    temperatureBatchDual: this.siteSchedulingEventForm.value.temperatureBatchDual,
                },
            }),
            lowerMax2: this.lowerMax2(),
            lowerMin2: this.lowerMin2(),
            temp1RangeValue: this.temperature,
            temp1DualKnobs: this.temp1DualKnobs(),
            temp2DualKnobs: this.temp2DualKnobs(),
            temp1Name: this.temp1DualKnobs() ? `Temperature` + (devEnv ? ` 1` : ``) : this.multipleRangeSliders() == true ? `Single Set Temp` : `Temperature`,
            temp2Name: this.temp2DualKnobs() == false ? `Temperature` + (devEnv ? ` 2` : ``) : `Dual Set Temp`,
            temp1UpperMin: this.upperMin(),
            temp1UpperMax: this.upperMax(),
            lowerMin: this.lowerMin(),
            getMinTemp: this.getMinTemp(),
            getMaxTemp: this.getMaxTemp(),
            temp1LowerMin: this.temp1DualKnobs() ? this.lowerMin() : this.getMinTemp(),
            temp1LowerMax: this.temp1DualKnobs() ? this.lowerMax() : this.getMaxTemp(),
            temp1LowerName: this.temp1DualKnobs() ? `temperature2Lower` : `lowerSetTemp`, 
            temp1UpperName: this.temp1DualKnobs() ? `temperature2Upper` : `upperSetTemp`, 
            temp1RangeName: this.temp1DualKnobs() ? `temperatureBatchDual` : `temperatureRange`,
            temp1LowerValue: this.temp1DualKnobs() ? this.temperature2Lower : this.heatSetTemp,
            temp2LowerValue: this.temp2DualKnobs() == true ? this.temperature2Lower : this.evnt && this.evnt != null ? this.evnt.meta.temperatureNumber : this.heatSetTemp < this.lowerMin2() ? this.lowerMin2() : this.heatSetTemp > this.lowerMax2() ? this.lowerMax2() : this.heatSetTemp,
            temp2UpperValue: this.temp2DualKnobs() == true ? this.temperature2Upper : this.coolSetTemp,
            temp2RangeValue: this.evnt && this.evnt != null && this.temp2DualKnobs() == false ? this.evnt.meta.temperatureNumber : this.temp2DualKnobs() == true ? this.multipleRangeSliders() == true ? this.siteSchedulingEventForm.value.temperatureBatchDual : this.temperature : this.lowerMin2(),
            temp2LowerName: this.temp2DualKnobs() == true ? `temperature2Lower` : this.multipleRangeSliders() == true && this.temp2DualKnobs() == false ? `lowerSetTemp2` : `lowerSetTemp`,
            temp2UpperName: this.temp2DualKnobs() == true ? `temperature2Upper` : `upperSetTemp`, 
            temp2RangeName: this.temp2DualKnobs() == true ? `temperatureBatchDual` : this.multipleRangeSliders() == true && this.temp2DualKnobs() == false ? `temperatureRange2` : `temperatureRange`,
        }
    }

    dayOfDate() {
        let dayOfWeek = this.weekdays[new Date(this.siteSchedulingEventForm.controls.startDay.value).getDay() + 1];
        if (dayOfWeek == undefined) dayOfWeek = this.weekdays[0];
        return dayOfWeek;
    }

    multipleRangeSliders(bgd: any = this.bgd, existingEvent: any = this.evnt) {
        let selectedType = bgd.type;
        let areMultipleRangeSlidersActive = false;
        if (existingEvent && existingEvent != null) {
            areMultipleRangeSlidersActive = selectedType == GatewayUnitTwoDigitType.IndoorUnit && bgd.setTemp.dualCount > 0 && bgd.setTemp.singleCount > 0 && existingEvent.meta.batchEvents != null && (this.activeMode != `` ? this.activeMode == ModesSpeedsDirections.auto || this.activeMode == ModesSpeedsDirections.setback : this.siteSchedulingEventForm.value.mode == ModesSpeedsDirections.auto || this.siteSchedulingEventForm.value.mode == ModesSpeedsDirections.setback);
        } else {
            areMultipleRangeSlidersActive = selectedType == GatewayUnitTwoDigitType.IndoorUnit && bgd.setTemp.dualCount > 0 && bgd.setTemp.singleCount >= 0 && bgd.setTemp.mode == 3 && ((bgd.mode.firstUnitMode as any) == ModesSpeedsDirections.auto || (bgd.mode.firstUnitMode as any) == ModesSpeedsDirections.setback);
        }

        if (areMultipleRangeSlidersActive == true) this.isDualSetDisabled = true;
        this.mainSiteUIService.multipleRangeSliders = areMultipleRangeSlidersActive;
        return areMultipleRangeSlidersActive;
    }

    temp1DualKnobs() {
        let isTemp1InDualMode = false;
        if (this.multipleRangeSliders() == true) {
            isTemp1InDualMode = this.isDualSetDisabled == false && this.bgd.setTemp.dualCount > 0 && (this.activeMode == ModesSpeedsDirections.auto || this.activeMode == ModesSpeedsDirections.setback);
        } else {
            isTemp1InDualMode = this.bgd.setTemp.dualCount > 0 && (this.activeMode == ModesSpeedsDirections.auto || this.activeMode == ModesSpeedsDirections.setback);
        }

        this.mainSiteUIService.temp1DualKnobs = isTemp1InDualMode;
        return isTemp1InDualMode;
    }

    temp2DualKnobs() {
        let isTemp2InDualMode = this.temp1DualKnobs() == false;
        this.mainSiteUIService.temp2DualKnobs = isTemp2InDualMode;
        return isTemp2InDualMode;
    }

    getCheckboxes(getGroups = false) {
        let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
        let checkedCheckBoxes = checkBoxes && checkBoxes.length > 0 ? checkBoxes.filter(ch => ch.el.checked == true) : checkBoxes;
        let groupIDsToAffect = checkedCheckBoxes.map(chbox => chbox.el.id.split(`checkboxFor-`)[1]);
        if (checkBoxes == undefined || checkBoxes == null) {
            if (this?.evnt?.meta?.batchEvents && this?.evnt?.meta?.batchEvents?.length > 1) {
                return this?.evnt?.meta?.batchEvents;
            } else if (this?.evnt?.meta?.groupedEvents && this?.evnt?.meta?.groupedEvents?.length > 0) {
                return this?.evnt?.meta?.groupedEvents;
            }
        } else {
            if (getGroups == true) {
                let groupsToAffect = this.mainSiteUIService.allSiteGroups.filter(gr => groupIDsToAffect.includes(gr.gatewayUnits[0].id));
                return groupsToAffect;
            } else {
                return groupIDsToAffect;
            }
        }
    }

    resizeBatchSchedulingFormContainer(e) {
        this.windowHeight = window.innerHeight;
    }

    detectTempInput(e) {
        if (e.key != `ArrowUp` && e.key != `ArrowDown`) {
            e.preventDefault();
            return false;
        }
    }

    getBatchSchedulingFormContainerHeight() {
        let height;
        let maxHeightPercentage;

        if (this.windowHeight >= 897) {
            maxHeightPercentage = 86;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage - 6;
        } else if (this.windowHeight >= 710 && this.windowHeight < 897) {
            maxHeightPercentage = 81;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage - 2;
        } else if (this.windowHeight >= 610 && this.windowHeight < 710) {
            maxHeightPercentage = 79;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage + 1;
        } else if (this.windowHeight >= 500 && this.windowHeight < 610) { 
            maxHeightPercentage = 76;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage - 3;
        } else if (this.windowHeight >= 400 && this.windowHeight < 500) {
            maxHeightPercentage = 67;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage - 5;
        } else {
            maxHeightPercentage = 65;
            if (this.showIcForm == false) maxHeightPercentage = maxHeightPercentage - 5;
        }
        
        height = `max-height: ${maxHeightPercentage}%`;

        return height;
    }

    isElementHeightOverflowing = (element, height) => {
        if (element != undefined && element != null) {
            let children = Array.from(element.children);
            let childHeights = children.map((ch: any) => ch.clientHeight);
            let elementHeight = childHeights.reduce((total, currentValue) => total + currentValue, 0);
            return elementHeight > height && element.scrollHeight > element.clientHeight;
        } else {
            return true;
        }
    };

    includesGroupInSearchAndFilter(group) {
        let locations = this?.searchAndFilter?.activeLocations || [];
        let gatewayIDs = this?.searchAndFilter?.activeGatewayIDs || [];
        let unitsWithLocationNoneActive = locations?.includes(GroupSelectorFilterTypes.NONE);
        let locationFiltersActive = locations?.length > 0;
        let gatewayFiltersActive = gatewayIDs?.length > 0;
        let groupGatewayGroupUnitLocation = group?.gatewayGroup?.units[0].location;
        
        if (locationFiltersActive && !gatewayFiltersActive) {
            if (unitsWithLocationNoneActive) {
                return locations.includes(groupGatewayGroupUnitLocation) || groupGatewayGroupUnitLocation == ``;
            } else {
                return locations.includes(groupGatewayGroupUnitLocation);
            }
        } else if (!locationFiltersActive && gatewayFiltersActive) {
            return gatewayIDs.includes(group?.gateway?.id);
        } else if (locationFiltersActive && gatewayFiltersActive) {
            if (unitsWithLocationNoneActive) {
                return (locations.includes(groupGatewayGroupUnitLocation) || groupGatewayGroupUnitLocation == ``) && gatewayIDs.includes(group?.gateway?.id);
            } else {
                return (locations.includes(groupGatewayGroupUnitLocation)) && gatewayIDs.includes(group?.gateway?.id);
            }
        } else if (this.mainSiteUIService.searchTerm && this.mainSiteUIService.searchTerm != ``) {
            return group?.gatewayUnits[0]?.group_name.toLowerCase().includes(this.mainSiteUIService.searchTerm);
        } else {
            let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
            let groupsToCheckLength = checkBoxes && checkBoxes != null && checkBoxes != undefined ? checkBoxes && checkBoxes.length > 0 ? checkBoxes.filter(ch => ch.el.checked == true).map(chbox => chbox.el.id.split(`checkboxFor-`)[1]) : checkBoxes : this.selectedGroups;
            let groupsToAffect = this.mainSiteUIService.allSiteGroups.filter(gr => groupsToCheckLength.includes(gr.gatewayUnits[0].id));
            let gwUnitIsLastGroupSelected = groupsToCheckLength?.length <= 1 && (groupsToAffect && groupsToAffect.length > 0 ? groupsToAffect[0].id == group.id : groupsToAffect.length == 0);
            return gwUnitIsLastGroupSelected == true || this.isGrpDisabled(group) == false;
        }
    }

    updateAfterCheck() {
        this.updateBGD(this.IDs);
        // if (updateSelectedGroups == true) {
        //     this.mainSiteUIService.newGroupsSelected.emit(this.updateBGD(this.IDs));
        //     localStorage.setItem(`LastGroupIdsSelected`, JSON.stringify(this.IDs));
        // }
        this.buildSchedulingEventFormGroup();
        this.refreshEventModes();
    }

    onSearchFilterDataSignal(searchFilterDataSignal) {
        let signalType = searchFilterDataSignal.type;
        let objectType = searchFilterDataSignal.objectType;
        let searchTerm = searchFilterDataSignal.searchTerm;

        if (signalType == SearchFilterActionTypes.Search) {
            if (objectType == SearchFilterObjectTypes.Gateways) {
                if (searchTerm != ``) {
                    let currentFilteredGWs = searchFilterDataSignal.checkedBoxes.length > 0 ? this.filteredUniqueGateways : this.mainSiteUIService.uniqueGateways;
                    this.filteredUniqueGateways = currentFilteredGWs.filter(gw => {
                        let gwGroups = gw.groups.map(grp => grp.units).map(gwUnit => gwUnit[0]);
                        let gwGroupNames = gwGroups.map(gwUnit => gwUnit.name.toLowerCase());
                        if (gw.name.toLowerCase().includes(searchTerm) || gwGroupNames.some(name => name.includes(searchTerm)) && gwGroups[0].type == this.selectedGroups[0].type) {
                            return {
                                ...gw,
                                expanded: true
                            };
                        }
                    });
                }
            }
        } else if (signalType == SearchFilterActionTypes.Toggle) {
            if (objectType == SearchFilterObjectTypes.Select) {
                // Select All
                this.groupsChanged = true;
                let allGroups = this.mainSiteUIService.allSiteGroups;
                let groupsInCurrentSelection = allGroups.filter(grp => grp.selected == true || this.includesGroupInSearchAndFilter(grp));
                let filteredGroups = searchTerm != `` ? groupsInCurrentSelection.filter((grp, grpIndex) => (grp?.selected == true || grp?.name?.toLowerCase().includes(searchTerm))) : groupsInCurrentSelection;
                this.selectedGroups = filteredGroups.filter((grp, grpIndex) => grp?.type == this.selectedGroups[0]?.type && grp?.isGatewaySubscriptionExpired() == false).map((grp) => ({ ...grp, selected: grp?.selected }));
                this.IDs = this.selectedGroups.map(gr => gr.id);
                this.updateAfterCheck();
                this.siteSchedulingEventForm.controls.lastSelectedUnits.setValue(this.selectedGroups.map(grp => grp.name).join(`, `));
                if (this.siteSchedulingEventForm.status == `VALID`) this.saveEnabled = true;
                let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
                let checkedCheckBoxes = checkBoxes && checkBoxes.length > 0 ? checkBoxes.filter(ch => ch.el.checked == true) : checkBoxes;
                devEnv && console.log(`Select All Groups`, this.variablesToLog({
                    checkBoxes,
                    IDs: this.IDs,
                    filteredGroups,
                    checkedCheckBoxes,
                    groupsInCurrentSelection,
                    selectedGroups: this.selectedGroups,
                    renderSelectedUnits: this.renderSelectedUnits(),
                    selectedGroupsForEvent: this.selectedGroupsForEvent(),
                }));
            } else if (objectType == SearchFilterObjectTypes.List) {
                this.mainSiteUIService.setGatewayFilters();
                this.filteredUniqueGateways = this.mainSiteUIService.uniqueGateways;
                devEnv && this.searchAndFilter.useInternalList == false && console.log(`Open Toggle Filter Selection`, searchFilterDataSignal);
            }
        } else if (signalType == SearchFilterActionTypes.Filter) {
            if (objectType == SearchFilterObjectTypes.Checkbox) {
                let gateways = this.mainSiteUIService.uniqueGateways;
                let gwLocations = [...new Set(gateways.some(gw => gw.location) ? gateways.map(gw => gw.location) : [])];
                let { gatewayFiltersActive, locationFiltersActive, checkedBoxes, activeLocations, activeGatewayIDs } = searchFilterDataSignal.activeFiltersParams;

                if (checkedBoxes.length > 0) {
                    this.filteredUniqueGateways = gateways.filter(gw => {
                        let gwGroups = gw.groups.map(grp => grp.units).map(gwUnit => gwUnit[0]);
                        let gwGroupUnitLocations = gwGroups.map(gwUnit => gwUnit.location);
                        let gwGroupLocations = gw.groups.some(grp => grp.location) ? gw.groups.map(grp => grp.location) : [];
                        let allGWLocations = [...new Set(gwLocations.concat(gwGroupUnitLocations.concat(gwGroupLocations)))].filter(val => val != ``);

                        if (!locationFiltersActive && gatewayFiltersActive) {
                            if (activeGatewayIDs.includes(gw.id)) {
                                return {
                                    ...gw,
                                    expanded: true
                                };
                            }
                        } else if (locationFiltersActive && !gatewayFiltersActive) {
                            if (allGWLocations.some(location => activeLocations.includes(location))) {
                                return {
                                    ...gw,
                                    expanded: true
                                };
                            }
                        } else if (locationFiltersActive && gatewayFiltersActive) {
                            if (activeGatewayIDs.includes(gw.id) && allGWLocations.some(location => activeLocations.includes(location))) {
                                return {
                                    ...gw,
                                    expanded: true
                                };
                            }
                        }
                    });
                } else {
                    this.filteredUniqueGateways = gateways.map((gw, gwIndex) => {
                        if (gwIndex == (gateways.length - 1)) {
                            return {
                                ...gw,
                                expanded: true
                            }
                        } else {
                            return gw;
                        }
                    });
                }
            }
        } else if (signalType == SearchFilterActionTypes.Clear) {
            if (objectType == SearchFilterObjectTypes.Checkbox) {
                let gateways = this.mainSiteUIService.uniqueGateways;
                this.filteredUniqueGateways = gateways.map((gw, gwIndex) => {
                    if (gwIndex == (gateways.length - 1)) {
                        return {
                            ...gw,
                            expanded: true
                        }
                    } else {
                        return gw;
                    }
                });
            }
        }
    }

    getParamsFromBGD() {
        let activeModesForSelection = Object.entries(this.bgd.mode).filter(entry => entry[1]?.count > 0).map(entr => entr[0]);
        let valuesToConsider = Object.entries(this.bgd.setTemp.minMaxPoints).filter(entry => activeModesForSelection.includes(entry[0]));
        let minValues = valuesToConsider.map(ent => ent[1]?.min).sort((a, b) => a - b);
        let maxValues = valuesToConsider.map(ent => ent[1]?.max).sort((a, b) => b - a);
        let paramsToReturn = {activeModesForSelection, valuesToConsider, minValues, maxValues};
        return paramsToReturn;
    }

    expandFormSidebar(e?: any) {
        this.sidebarExpanded = !this.sidebarExpanded;
        if (this.sidebarExpanded == true) this.initializeFilteredUniqueGateways();
    }

    // Set Initial Form Values
    eventAdminForm() {
        if (this.evnt) this.activeMode = this.evnt.meta.mode;
        if (this.fanSpeeds.length == 1) this.fanSpeed = this.fanSpeeds[0];
        if (this.airDirects.length == 1) this.airDirection = this.airDirects[0];
        if (!this.airDirects.includes(this.airDirection)) this.eventEnabled = false;
        if (this.evnt && this.evnt.meta.unitType == GatewayUnitTwoDigitType.IndoorUnit) {
            this.temperature = this.evnt.meta.temperature;
            typeof this.temperature == `number` ? this.upperSetTemp = this.evnt.meta.temperature : this.upperSetTemp = this.temperature.upper;
            typeof this.temperature == `number` ? this.lowerSetTemp = this.evnt.meta.temperature : this.lowerSetTemp = this.temperature.lower;
        }
        if (!this.fanSpeed) this.eventEnabled = false;
        this.buildSchedulingEventFormGroup();
        this.detectFormChanges(this.siteSchedulingEventForm);
        this.formOpen = true;
    }

    determineLowerSetTemp = (tempRangeToUse = this.temperature) => {
        let tempBeingUsed = this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay && this.activeMode != ModesSpeedsDirections.fan;
        let lowerSetTemp = typeof tempRangeToUse == `number` ? tempRangeToUse : tempBeingUsed == true ? (tempRangeToUse.lower < this.lowerMin2() ? this.lowerMin2() : tempRangeToUse.lower) : this.lowerMin();
        
        if (this.evnt && this.evnt != null) {
            if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false) {
                lowerSetTemp = this.evnt.meta.temperatureNumber || this.lowerSetTemp;
            }
        }

        return lowerSetTemp;
    }

    determineTemperatureRange = (tempRangeToUse = this.temperature) => {
        let temperatureRange = this.evnt ? this.evnt.meta.temperature : tempRangeToUse;

        if (this.evnt && this.evnt != null) {
            if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false) {
                temperatureRange = this.evnt.meta.temperatureNumber || this.lowerSetTemp;
                this.heatSetTemp = temperatureRange;
            }
        } else {
            if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false) {
                temperatureRange = this.lowerSetTemp;
            }
        }

        return temperatureRange;
    }
    
    determineLowerSetTemp2 = (tempRangeToUse = this.temperature) => {
        let tempBeingUsed = this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay && this.activeMode != ModesSpeedsDirections.fan;
        let lowerSetTemp2 = typeof tempRangeToUse == `number` ? tempRangeToUse : tempBeingUsed == true ? (tempRangeToUse.lower < this.lowerMin2() ? this.lowerMin2() : tempRangeToUse.lower) : this.lowerMin();

        if (this.evnt && this.evnt != null) {
            if (this.multipleRangeSliders() == true && this.temp2DualKnobs() == false) {
                lowerSetTemp2 = this.evnt.meta.temperatureNumber;
            }
        }
        
        return lowerSetTemp2;
    }

    determineDays = (params) => {
        let days = this.groupsChanged == true ? this.days : this.evnt && this.evnt.meta.is_recurring ? Object.entries(this.evnt.meta.days).map(ifDay => ifDay[1] ? ifDay[0] : null).map(day => typeof day == `string` ? globalFunctions.capitalizeAllWords(day) : null).filter(day => typeof day == `string`) : this.eventDays.filter(day => day == this.startD);

        if (params) {
            if (params.days) {
                days = params.days;
            }
        }

        return days;
    }

    buildSchedulingEventFormGroup(params?: any) {
        let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
        let LastGroupsSelectedByOrder = localStorage.getItem(`LastGroupsSelectedByOrder`) ? JSON.parse(localStorage.getItem(`LastGroupsSelectedByOrder`)) : [];
        if (this.LastSelectedByOrder.length == 0 && LastGroupsSelectedByOrder.length > 0) {
            this.LastSelectedByOrder = LastGroupsSelectedByOrder;
        }
        
        if (this.evnt && this.evnt != null && this.evnt.meta.batchEvents != null) {
            if (this.groupsChanged == false) {
                if (checkBoxes && checkBoxes.length > 0) {
                    let groupsToUpdate = this.getCheckboxes(true).map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode: this.activeMode }))})) as SelectedGroup[];
                    this.bgd.update(groupsToUpdate);
                    this.deadband = this.bgd.setTemp.deadband;
                    this.minTemperature = this.getMinTemp();
                    this.maxTemperature = this.getMaxTemp();
                } else {
                    let gatewayUnitIDsToAffect = this?.evnt?.meta?.batchEvents.map(evt => evt.meta.gatewayUnit_id);
                    let groupsToAffect = this.mainSiteUIService.allSiteGroups.filter(gr => gatewayUnitIDsToAffect.includes(gr.gatewayUnits[0].id));
                    let groupsToUpdate = groupsToAffect.map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode: this.activeMode }))})) as SelectedGroup[];
                    this.bgd.update(groupsToUpdate);
                    this.deadband = this.bgd.setTemp.deadband;
                    this.minTemperature = this.getMinTemp();
                    this.maxTemperature = this.getMaxTemp();
                }
            }
        } else {
            this.selectedGateway = this.selectedGroups[0].gateway;
            this.selectedGatewayUnit = this.selectedGroups[0].gatewayUnits[0];
            if (this.activeMode != ``) {
                let groupsToUpdate = this.selectedGroups.map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode: this.activeMode }))})) as SelectedGroup[];
                this.bgd.update(groupsToUpdate);
                this.deadband = this.bgd.setTemp.deadband;
                this.minTemperature = this.getMinTemp();
                this.maxTemperature = this.getMaxTemp();
            }
        }

        if (this.multipleRangeSliders() == true) {
            if (this.dualSetPointInitialized == true) {
                this.transitionDualToTriple = true;
            }
            if (this.temp2DualKnobs() == true) {
                this.temperature2 = {
                    lowerMin: this.getMinTemp2(),
                    upperMax: this.getMaxTemp2(),
                }
            } else {
                this.temperature2 = this.getMinTemp2()
            }
        }

        let eventTemp = this.evnt && this.evnt != null ? this.evnt.meta.temperature : this.multipleRangeSliders() == true && this.temp1DualKnobs() == false ? parseFloat(this.bgd.setTemp.dualSetPointLowLabel) : this.temperature;

        this.temperature = eventTemp;

        let tempBeingUsed = this.selectedGatewayUnit.type != GatewayUnitTwoDigitType.Lossnay && this.activeMode != ModesSpeedsDirections.fan;

        let upperTemp = eventTemp;
        let lowerTemp = eventTemp;
        let temp2Upper = eventTemp;
        let temp2Lower = eventTemp;
        
        if (tempBeingUsed == true) {
            upperTemp = typeof eventTemp == `number` ? eventTemp : eventTemp.upper;
            lowerTemp = typeof eventTemp == `number` ? eventTemp : eventTemp.lower;
            temp2Upper = typeof eventTemp == `number` ? eventTemp : eventTemp.upper;
            temp2Lower = typeof eventTemp == `number` ? eventTemp : eventTemp.lower;
        

            if (this.evnt && this.evnt != null) {
                if (typeof eventTemp == `number`) {
                    upperTemp = eventTemp;
                    lowerTemp = eventTemp;
                } else {
                    upperTemp = eventTemp.upper;
                    lowerTemp = eventTemp.lower;
                }
            } else {
                if (this.multipleRangeSliders() == true) {
                    if (this.temp1DualKnobs() == false) {
                        if (typeof eventTemp == `number`) {
                            temp2Lower = eventTemp < this.lowerMin() ? this.lowerMin() : eventTemp;
                            temp2Upper = eventTemp > this.lowerMax() ? this.lowerMax() : eventTemp;
                        } else {
                            temp2Lower = eventTemp.lower < this.lowerMin() ? this.lowerMin() : eventTemp.lower;
                            temp2Upper = eventTemp.upper > this.lowerMax() ? this.lowerMax() : eventTemp.upper;
                        }

                        lowerTemp = parseFloat(this.bgd.setTemp.dualSetPointLowLabel);
                        upperTemp = parseFloat(this.bgd.setTemp.dualSetPointHighLabel);

                        lowerTemp = lowerTemp < this.lowerMin() ? this.lowerMin() : lowerTemp;
                        upperTemp = upperTemp > this.lowerMax() ? this.lowerMax() : upperTemp;

                        this.temperature = lowerTemp;

                        if (temp2Upper - temp2Lower <= this.deadband) {
                            temp2Upper = temp2Lower + this.deadband;
                        }
                    }
                } else {
                    if (this.temp1DualKnobs() == false) {
                        if (typeof eventTemp == `number`) {
                            lowerTemp = eventTemp < this.lowerMin() ? this.lowerMin() : eventTemp;
                            upperTemp = eventTemp > this.lowerMax() ? this.lowerMax() : eventTemp;
                        } else {
                            lowerTemp = eventTemp.lower < this.lowerMin() ? this.lowerMin() : eventTemp.lower;
                            upperTemp = eventTemp.upper > this.lowerMax() ? this.lowerMax() : eventTemp.upper;
                        }
                    } else {

                        if (typeof eventTemp == `number`) {
                            temp2Lower = eventTemp < this.lowerMin() ? this.lowerMin() : eventTemp;
                            temp2Upper = eventTemp > this.lowerMax() ? this.lowerMax() : eventTemp;
                        } else {
                            temp2Lower = eventTemp.lower < this.lowerMin() ? this.lowerMin() : eventTemp.lower;
                            temp2Upper = eventTemp.upper > this.lowerMax() ? this.lowerMax() : eventTemp.upper;
                        }

                        if (temp2Upper - temp2Lower <= this.deadband) {
                            temp2Upper = temp2Lower + this.deadband;
                        }

                        this.temperature = {
                            lower: temp2Lower,
                            upper: temp2Upper
                        }

                        eventTemp = this.temperature;

                        lowerTemp = parseFloat(this.bgd.setTemp.maxDualLowSetTemp) < this.lowerMin() ? this.lowerMin() : parseFloat(this.bgd.setTemp.maxDualLowSetTemp);
                        upperTemp = parseFloat(this.bgd.setTemp.maxDualHighSetTemp) > this.lowerMax() ? this.lowerMax() : parseFloat(this.bgd.setTemp.maxDualHighSetTemp);
                    }
                }
            }
        }

        this.temperature2Lower = (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false || this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) ? temp2Lower : lowerTemp;
        this.temperature2Upper = (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false || this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) ? temp2Upper : upperTemp;

        this.temperature2Lower = this.temperature2Lower < this.lowerMin2() ? this.lowerMin2() : this.temperature2Lower;
        this.temperature2Upper = this.temperature2Upper > this.upperMax2() ? this.upperMax2() : this.temperature2Upper;
        
        this.heatSetTemp = lowerTemp;
        this.coolSetTemp = upperTemp;

        this.lowerSetTemp = lowerTemp;
        this.upperSetTemp = upperTemp;

        let tempRangeToUse = this.temperature;
        this.initialFormTitle = devEnv ? `Event ${this.events.length + 1}` : `Event`;

        this.siteSchedulingEventForm = this.formBuilder.group({
            id: [
                `siteSchedulingEventForm`,
            ],
            title: [
                this.evnt ? this.evnt.title : (this.titleCtrl != null && this.titleCtrl != this.initialFormTitle ? this.titleCtrl : this.initialFormTitle),
                Validators.compose([
                    Validators.minLength(3),
                    Validators.maxLength(50),
                ]),
            ],
            startTime: [
                this.startTime,
            ],
            days: [
                this.determineDays(params)
            ],
            startDay: [
                this.startCalFormat,
                Validators.pattern(/^(20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/),
            ],
            temperatureBatchDual: [
                this.evnt && this.evnt != null && this.evnt.meta.temperatureRange != undefined ? this.evnt.meta.temperatureRange : (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false || this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) ? {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper
                } : eventTemp
            ],
            temperature2Lower: [
                this.evnt && this.evnt.meta.temperatureRange && this.evnt.meta.temperatureRange.lower && this.groupsChanged == false ? this.evnt.meta.temperatureRange.lower : this.temperature2Lower
            ],
            temperature2Upper: [
                this.evnt && this.evnt.meta.temperatureRange && this.evnt.meta.temperatureRange.upper && this.groupsChanged == false ? this.evnt.meta.temperatureRange.upper : this.temperature2Upper
            ],
            temperature: [
                this.evnt ? this.evnt.meta.temperature : tempRangeToUse
            ],
            temperatureRange: [
                this.determineTemperatureRange()
            ],
            temperatureRange2: [
                this.evnt ? this.evnt.meta.temperature : tempRangeToUse
            ],
            upperSetTemp: [
                typeof tempRangeToUse == `number` ? tempRangeToUse : tempBeingUsed == true ? tempRangeToUse.upper > this.lowerMax2() ? this.lowerMax2() : tempRangeToUse.upper : this.upperMin()
            ],
            lowerSetTemp: [
                this.determineLowerSetTemp()
            ],
            lowerSetTemp2: [
                this.determineLowerSetTemp2(tempRangeToUse)
            ],
            allday: [
                this.evnt ? this.evnt.allDay : this.alldayEvent
            ],
            power: [
                this.evnt ? this.evnt.meta.power : this.getBatchPower(),
            ],
            mode: [
                this.evnt ? this.evnt.meta.unitType == GatewayUnitTwoDigitType.IndoorUnit ? this.evnt.meta.mode : this.evnt.meta.ventMode : this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit ? this.getBatchMode() : this.getBatchVentMode(),
            ],
            ventMode: [
                this.evnt ? this.evnt.meta.mode : this.getBatchVentMode(),
            ],
            fanSpeed: [
                this.evnt ? (this.evnt.meta.fanSpeedEnabled ? this.evnt.meta.fanSpeed : `disabled`) : this.getBatchFanSpeed(),
            ],
            airDirection: [
                this.evnt ? this.evnt.meta.airDirection : this.getBatchAirDirection(),
            ],
            lastSelectedUnits: [
                this.renderSelectedUnits(LastGroupsSelectedByOrder)
            ]
        });

        devEnv && console.log(`Form Opened or Added or Removed Group(s), Updated Form Values`, this.variablesToLog({
            eventTemp,
            lowerTemp,
            upperTemp,
            temp2Lower,
            temp2Upper,
            tempBeingUsed,
            tempRangeToUse,
            lower: this.lowerSetTemp,
            heatSetTemp: this.heatSetTemp,
            selectedGroups: this.selectedGroups,
            selectedGroupsForEvent: this.selectedGroupsForEvent(),
            min: this.getMinTemp(),
            max: this.getMaxTemp(),
            temperature: this.temperature,
            temperatureBatchDual: [
                this.evnt && this.evnt != null && this.evnt.meta.temperatureRange != undefined ? this.evnt.meta.temperatureRange : (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false || this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) ? {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper
                } : eventTemp
            ],
            temperature2Lower: [
                this.evnt && this.evnt.meta.temperatureRange && this.evnt.meta.temperatureRange.lower ? this.evnt.meta.temperatureRange.lower : this.temperature2Lower
            ],
            temperature2Upper: [
                this.evnt && this.evnt.meta.temperatureRange && this.evnt.meta.temperatureRange.upper ? this.evnt.meta.temperatureRange.upper : this.temperature2Upper
            ],
            formTemperature: [
                this.evnt ? this.evnt.meta.temperature : tempRangeToUse
            ],
            temperatureRange: [
                this.determineTemperatureRange()
            ],
            temperatureRange2: [
                this.evnt ? this.evnt.meta.temperature : tempRangeToUse
            ],
            upperSetTemp: [
                typeof tempRangeToUse == `number` ? tempRangeToUse : tempBeingUsed == true ? tempRangeToUse.upper > this.lowerMax2() ? this.lowerMax2() : tempRangeToUse.upper : this.upperMin()
            ],
            lowerSetTemp: [
                this.determineLowerSetTemp()
            ],
            lowerSetTemp2: [
                this.determineLowerSetTemp2(tempRangeToUse)
            ],
            days: [
                this.determineDays(params)
            ],
        }));
    }

    eventCreationNotAllowed() {
        let formControls = this.siteSchedulingEventForm.controls;
        let mode = formControls.mode.value;
        let power = formControls.power.value;
        this.titleCtrl = formControls.title.value;
        let ventMode = formControls.ventMode.value;
        let fanSpeed = formControls.fanSpeed.value;
        let airDirection = formControls.airDirection.value;
        let isInICMode = this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit;
        let fanSpeedEnabled = isInICMode == true ? (mode != ModesSpeedsDirections.fan && mode != ``) : true;
        let createDisabled = this.siteSchedulingEventForm.status == `INVALID` || (power == `` || (power != ModesSpeedsDirections.on && power != ModesSpeedsDirections.off)) || (fanSpeedEnabled == true && fanSpeed == ``) || (isInICMode == true ? (mode == `` || airDirection == ``) : ventMode == ``);
        if (this.selectedGroups.length == 1) {
            return !this.eventEnabled || createDisabled;
        } else {
            return createDisabled;
        } 
    }

    eventSaveNotAllowed() {
        let formControls = this.siteSchedulingEventForm.controls;
        let mode = this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit ? formControls.mode.value : formControls.ventMode.value;
        return this.siteSchedulingEventForm.status == `INVALID` || !this.saveEnabled || mode == ``;
    }

    getBatchPower(hardSetPower?) {
        let powerInCommonAndActive = ``;
        if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) {
            let formControls = this.siteSchedulingEventForm.controls;
            powerInCommonAndActive = formControls.power.value;
        }

        powerInCommonAndActive = this.evnt && this.evnt != null ? this.evnt.meta.power : powerInCommonAndActive;

        if (hardSetPower != null && hardSetPower != undefined && hardSetPower != ``) {
            return hardSetPower;
        } else {
            if (this.selectedGroups.length == 1) {
                return this.activePower;
            } else {
                return powerInCommonAndActive;
            } 
        }
    }

    setBatchFanSpeed() {
        this.fanSpeedControls = Object.entries(this.bgd.fan_speed).filter(ent => ent[0] == ModesSpeedsDirections.none || ent[1].disabled == false).map(ent => this.renderSpeed(ent[0]));
        if (this.fanSpeedControls) {
            if (this.fanSpeeds.filter(spd => spd == ModesSpeedsDirections.disabled).length > 1) {
                this.fanSpeeds = this.fanSpeeds.filter(ctrl => ctrl != ModesSpeedsDirections.disabled);
            } else if (this.fanSpeedControls.length == 1) {
                if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) this.siteSchedulingEventForm.controls.fanSpeed.setValue(this.fanSpeedControls[0]);
            }
        }
    }

    getBatchFanSpeed() {
        let fanSpeedInCommonAndActive = ``;
        this.activeFanSpeeds = Object.entries(this.bgd.fan_speed).filter(ent => ent[1].disabled == false).map(ent => this.renderSpeed(ent[0]));

        if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) {
            let formControls = this.siteSchedulingEventForm.controls;
            if (this.activeFanSpeeds.includes(formControls.fanSpeed.value)) {
                fanSpeedInCommonAndActive = formControls.fanSpeed.value;
            }
        }

        let groupsToConsider = this.evnt && this.evnt != null ? this.getCheckboxes(true) : this.selectedGroups;

        fanSpeedInCommonAndActive = this.evnt && this.evnt != null && this.activeFanSpeeds.includes(this.evnt.meta.fanSpeed) ? this.evnt.meta.fanSpeed : fanSpeedInCommonAndActive;

        if (groupsToConsider.length == 1 && this.activeFanSpeeds.includes(this.fanSpeed)) {
            return this.fanSpeed;
        } else {
            if (fanSpeedInCommonAndActive == ``) fanSpeedInCommonAndActive = ModesSpeedsDirections.disabled;
            return this.fanSpeedControls.length == 1 ? this.fanSpeedControls[0] : this.activeFanSpeeds.length == 1 ? this.activeFanSpeeds[0] : fanSpeedInCommonAndActive;
        } 
    }

    getBatchMode() {
        let unitType = this.evnt ? this.evnt.meta.unitType : this.selectedGroups[0].gatewayUnits[0].type;
        if (unitType == GatewayUnitTwoDigitType.IndoorUnit) {
            let modeInCommonAndActive = ``;
            this.activeModes = Object.entries(this.bgd.mode).filter(ent => ent[1].disabled == false).map(ent => ent[0]);
    
            if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) {
                let formControls = this.siteSchedulingEventForm.controls;
                if (this.activeModes.includes(formControls.mode.value)) {
                    modeInCommonAndActive = formControls.mode.value;
                }
            }
    
            if (this.activeModes.includes(this.bgd.mode.label)) modeInCommonAndActive = this.bgd.mode.label;
            let groupsToConsider = this.evnt && this.evnt != null ? this.getCheckboxes(true) : this.selectedGroups;
    
            modeInCommonAndActive = this.evnt && this.evnt != null && this.activeModes.includes(this.evnt.meta.mode) && this.evnt.meta.mode == this.activeMode ? this.evnt.meta.mode : modeInCommonAndActive;
    
            if (groupsToConsider.length == 1 && this.activeModes.includes(this.activeMode)) {
                return this.activeMode;
            } else {
                return this.activeModes.length == 1 ? this.activeModes[0] : modeInCommonAndActive;
            }
        } else {
            return this.activeMode;
        }
    }

    getBatchVentMode() {
        let unitType = this.evnt ? this.evnt.meta.unitType : this.selectedGroups[0].gatewayUnits[0].type;
        if (unitType == GatewayUnitTwoDigitType.Lossnay) {
            let ventModeInCommonAndActive = ``;
            let nonControlTypes = [`number`, `string`, `boolean`];
            let ignorableControls = [`ignorable`, `displayOptions`, `firstUnitMode`];
            let ventModes = Object.entries(this.bgd.vent_mode).filter(ent => !nonControlTypes.includes(typeof ent[1]));
            let ventModeControls = ventModes.filter(vm => vm[1] != null && !ignorableControls.includes(vm[0]));
            let activeControls = ventModeControls.filter(ent => ent[1].disabled == false).map(ent => this.renderVentModeFanSpeed(ent[0]));

            if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) {
                let formControls = this.siteSchedulingEventForm.controls;
                if (activeControls.includes(formControls.ventMode.value)) {
                    ventModeInCommonAndActive = formControls.ventMode.value;
                }
            }

            ventModeInCommonAndActive = this.evnt && this.evnt != null && activeControls.includes(this.evnt.meta.ventMode) && this.evnt.meta.ventMode == this.activeVentMode ? this.evnt.meta.ventMode : ventModeInCommonAndActive;

            let groupsToConsider = this.evnt && this.evnt != null ? this.getCheckboxes(true) : this.selectedGroups;
            if (groupsToConsider.length == 1 && activeControls.includes(this.activeVentMode)) {
                return this.activeVentMode;
            } else {
                return activeControls.length == 1 ? activeControls[0] : ventModeInCommonAndActive;
            }
        } else {
            return this.activeVentMode;
        }
    }
    
    getBatchAirDirection() {
        let unitType = this.evnt ? this.evnt.meta.unitType : this.selectedGatewayUnit.type;
        if (unitType == GatewayUnitTwoDigitType.IndoorUnit) {
            let airDirectionInCommonAndActive = ``;
            let activeControls = Object.entries(this.bgd.air_direction).filter(ent => ent[0] == ModesSpeedsDirections.stationary || ent[1].disabled == false).map(ent => ent[0]);

            if (this.siteSchedulingEventForm && this.siteSchedulingEventForm.controls) {
                let formControls = this.siteSchedulingEventForm.controls;
                if (activeControls.includes(formControls.airDirection.value)) {
                    airDirectionInCommonAndActive = formControls.airDirection.value;
                }
            }

            airDirectionInCommonAndActive = this.evnt && this.evnt != null && activeControls.includes(this.evnt.meta.airDirection) ? this.evnt.meta.airDirection : airDirectionInCommonAndActive;

            let groupsToConsider = this.evnt && this.evnt != null ? this.getCheckboxes(true) : this.selectedGroups;
            if (groupsToConsider.length == 1 && activeControls.includes(this.airDirection)) {
                return this.airDirection;
            } else {
                if (airDirectionInCommonAndActive == ``) airDirectionInCommonAndActive = ModesSpeedsDirections.stationary;
                return activeControls.length == 1 ? activeControls[0] : airDirectionInCommonAndActive;
            }
        } else {
            return this.airDirection;
        }
    }

    selectedGroupsForEvent() {
        let selectedGroups = [this?.evnt?.meta?.onGroup];
        if (this.evnt && this.evnt != null) {
            let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
            if (checkBoxes == undefined || checkBoxes == null) {
                if (this?.evnt?.meta?.batchEvents && this?.evnt?.meta?.batchEvents?.length > 1) {
                    selectedGroups = this?.evnt?.meta?.batchEvents;
                } else if (this?.evnt?.meta?.groupedEvents && this?.evnt?.meta?.groupedEvents?.length > 0) {
                    selectedGroups = this?.evnt?.meta?.groupedEvents;
                }
            } else {
                selectedGroups = this.groupsChanged == true ? this.selectedGroups : this.getCheckboxes(true);
            }
        } else {
            let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
            let checkedCheckBoxes = checkBoxes && checkBoxes.length > 0 ? checkBoxes.filter(ch => ch.el.checked == true) : checkBoxes;

            if (checkBoxes && checkedCheckBoxes) {
                selectedGroups = checkBoxes.length == this.selectedGroups.length ? this.selectedGroups : this.getCheckboxes(true);
            } else {
                selectedGroups = this.selectedGroups;
            }
        }

        return selectedGroups;
    }

    renderSelectedUnits(LastGroupsSelectedByOrder = this.LastSelectedByOrder) {
        let lastSelected;
        if (this.evnt) {
            if (this?.evnt?.meta?.batchID && this?.evnt?.meta?.batchEvents && this?.evnt?.meta?.batchEvents?.length > 0) {
                lastSelected = this?.evnt?.meta?.batchEvents?.map(evt => evt?.meta?.onGroup).join(`, `);
            } else if (this?.evnt?.meta?.groupedEvents && this?.evnt?.meta?.groupedEvents?.length > 0) {
                lastSelected = this?.evnt?.meta?.groupedEvents?.map(evt => evt?.meta?.onGroup).join(`, `);
            } else {
                lastSelected = this?.evnt?.meta?.onGroup;
            }
        } else {
            if (this.selectedGroups.length > 0) {
                let groupsToConsider = LastGroupsSelectedByOrder.filter(grp => this.selectedGroups.map(gr => gr?.id).includes(grp?.id));
                let filteredInSelected = groupsToConsider.map(grp => grp?.name);
                LastGroupsSelectedByOrder = filteredInSelected.length > 0 ? filteredInSelected : this.selectedGroups.map(gr => gr?.gatewayUnits[0].group_name);
                lastSelected = LastGroupsSelectedByOrder.join(`, `);
            } else {
                lastSelected = LastGroupsSelectedByOrder.map(grp => grp?.name).join(`, `);
            }
        }
        return lastSelected;
    }

    refreshEventModes() {
        // Lossnay
        if (this.activeMode == ModesSpeedsDirections.auto_cool) this.activeMode = ModesSpeedsDirections.auto;
        if (this.activeMode == ModesSpeedsDirections.auto_heat) this.activeMode = ModesSpeedsDirections.auto;
        if (this.activeMode == ModesSpeedsDirections.unknown) this.activeMode = ModesSpeedsDirections.disabled;
        if (this.activeMode == ModesSpeedsDirections.setback_heat) this.activeMode = ModesSpeedsDirections.setback;
        if (this.activeMode == ModesSpeedsDirections.setback_cool) this.activeMode = ModesSpeedsDirections.setback;
        if (this.activeMode == ModesSpeedsDirections.heat_recovery) this.activeMode = ModesSpeedsDirections.energy_recovery_with_spaces;
        if (this.activeVentMode == ModesSpeedsDirections.heat_recovery) this.activeVentMode = ModesSpeedsDirections.energy_recovery_with_spaces;
        this.ventModes = [ModesSpeedsDirections.bypass, ModesSpeedsDirections.energy_recovery_with_spaces, ModesSpeedsDirections.auto, this.activeVentMode];

        if (this.selectedGroups.length == 1) {
            if (this.selectedGatewayUnit.lc_bypass_sw == ModesSpeedsDirections.disabled) this.ventModes = this.ventModes.filter(mode => mode != ModesSpeedsDirections.bypass);
            if (this.selectedGatewayUnit.lc_auto_sw == ModesSpeedsDirections.disabled) this.ventModes = this.ventModes.filter(mode => mode != ModesSpeedsDirections.auto);
            if (this.selectedGatewayUnit.lc_humid_sw == ModesSpeedsDirections.disabled) this.ventModes = this.ventModes.filter(mode => mode != ModesSpeedsDirections.heat_recovery_with_spaces);
        }

        this.ventModes = this.ventModes.filter(mode => mode != undefined);

        // Indoor Unit
        this.unitModes = [ModesSpeedsDirections.heat, ModesSpeedsDirections.cool, ModesSpeedsDirections.dry, ModesSpeedsDirections.fan, ModesSpeedsDirections.setback, ModesSpeedsDirections.auto, this.activeMode];
        this.fanSpeeds = [ModesSpeedsDirections.very_low, ModesSpeedsDirections.low, ModesSpeedsDirections.medium, ModesSpeedsDirections.high, ModesSpeedsDirections.auto, ModesSpeedsDirections.disabled];

        // Lossnay
        if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
            if (this.selectedGroups.length > 1) {
                if (this.fanSpeed == ``) {
                    this.fanSpeed = ModesSpeedsDirections.disabled;
                }
            }
        }

        if (this.fanSpeed == ModesSpeedsDirections.disabled) {
            this.fanSpeeds.unshift(this.fanSpeed);
        } else {
            this.fanSpeeds.push(this.fanSpeed);
        }

        this.airDirects = [ModesSpeedsDirections.ceiling, ModesSpeedsDirections.high, ModesSpeedsDirections.middle, ModesSpeedsDirections.low, ModesSpeedsDirections.floor, ModesSpeedsDirections.swing, ModesSpeedsDirections.auto, this.airDirection == `--` ? ModesSpeedsDirections.stationary : this.airDirection, ModesSpeedsDirections.stationary];

        if (this.selectedGroups.length == 1) {
            if (this.selectedGatewayUnit.fan_mode_sw == ModesSpeedsDirections.disabled) this.unitModes = this.unitModes.filter(mode => mode != ModesSpeedsDirections.fan);
            if (this.selectedGatewayUnit.fan_auto_sw == ModesSpeedsDirections.disabled) this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.auto);
            if (this.selectedGatewayUnit.fan_speed_sw == FanSpeedStages.two_stages && this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.medium);
            if (this.selectedGatewayUnit.fan_extra_high_sw == ModesSpeedsDirections.disabled) this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.very_high);
        }

        this.fanSpeeds = this.fanSpeeds.filter(speed => speed != undefined && speed != ModesSpeedsDirections.off);

        if (this.selectedGroups.length == 1) {
            // Indoor Coil vs Lossnay
            if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
                if (this.selectedGatewayUnit.fan_speed_sw == FanSpeedStages.one_stage) this.fanSpeeds = [this.fanSpeed];
                if (this.selectedGatewayUnit.fan_mode_sw == ModesSpeedsDirections.disabled && this.selectedGatewayUnit.fan_speed_sw == ModesSpeedsDirections.none) this.fanSpeeds = [ModesSpeedsDirections.disabled];
                if (this.selectedGatewayUnit.fan_speed_sw == FanSpeedStages.three_stages || this.selectedGatewayUnit.fan_speed_sw == FanSpeedStages.two_stages) {
                    this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.very_low);
                }
            } else { // Lossnay
                if (this.selectedGatewayUnit.lc_fan_speed_sw == FanSpeedStages.one_stage) this.fanSpeeds = [this.fanSpeed];
                if (this.selectedGatewayUnit.fan_mode_sw == ModesSpeedsDirections.disabled && this.selectedGatewayUnit.fan_speed_sw == ModesSpeedsDirections.none) this.fanSpeeds = [ModesSpeedsDirections.disabled];
                if ((this.selectedGatewayUnit.lc_fan_speed_sw == FanSpeedStages.two_stages && (!this.bgd.fan_speed.three.showing || this.selectedGatewayUnit.fan_extra_high_sw == ModesSpeedsDirections.disabled)) || (this.selectedGatewayUnit.lc_fan_speed_sw == FanSpeedStages.three_stages && (this.bgd.fan_speed.zero.showing && this.bgd.fan_speed.one.showing && this.bgd.fan_speed.two.showing))) {
                    this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.high);
                }
                if ((this.selectedGatewayUnit.fan_extra_low_sw == ModesSpeedsDirections.disabled && this.selectedGatewayUnit.fan_speed_sw != FanSpeedStages.four_stages && !this.bgd.fan_speed.zero.showing) || (this.selectedGatewayUnit.fan_extra_low_sw == ModesSpeedsDirections.disabled && this.selectedGatewayUnit.fan_speed_sw == FanSpeedStages.two_stages)) {
                    this.fanSpeeds = this.fanSpeeds.filter(speed => speed != ModesSpeedsDirections.very_low);
                }
            }
        }

        if (this.selectedGroups.length == 1) {
            if (this.selectedGatewayUnit.auto_mode == ModesSpeedsDirections.disabled) this.unitModes = this.unitModes.filter(mode => mode != ModesSpeedsDirections.auto);
            if (this.selectedGatewayUnit.dry_mode_sw == ModesSpeedsDirections.disabled) this.unitModes = this.unitModes.filter(mode => mode != ModesSpeedsDirections.dry);
            if (this.selectedGatewayUnit.ic_kind != ModesSpeedsDirections.heating_cooling) this.unitModes = this.unitModes.filter(mode => mode != ModesSpeedsDirections.heat);
            if (this.selectedGatewayUnit.SetbackModeSW == ModesSpeedsDirections.disabled) this.unitModes = this.unitModes.filter(mode => mode != ModesSpeedsDirections.setback);
        }

        this.unitModes = this.unitModes.filter(mode => mode != undefined);

        if (this.selectedGroups.length == 1) {
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.low)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.low);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.auto)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.auto);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.high)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.high);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.floor)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.floor);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.swing)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.swing);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.middle)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.middle);
            if (!this.shouldDisplayControlIcon(ModesSpeedsDirections.ceiling)) this.airDirects = this.airDirects.filter(direction => direction != ModesSpeedsDirections.ceiling);
        }

        this.airDirects = this.airDirects.filter(direction => direction != undefined);
        this.fanSpeeds = this.fanSpeeds.map(spd => spd == `` ? ModesSpeedsDirections.disabled : spd);

        this.ventModes = [...new Set(this.ventModes)];
        this.fanSpeeds = [...new Set(this.fanSpeeds)];
        this.unitModes = [...new Set(this.unitModes)];
        this.airDirects = [...new Set(this.airDirects)];
        
        this.setBatchFanSpeed();

        if (this.unitModes.length == 1) this.activeMode = this.unitModes[0];

        // devEnv && console.log(`Refresh Event Modes`, this.bgd);
    }

    renderSpeed(speed) {
        if (speed == ModesSpeedsDirections.very_low) {
            return ModesSpeedsDirections.zero;
        } else if (speed == ModesSpeedsDirections.low) {
            return ModesSpeedsDirections.one;
        } else if (speed == ModesSpeedsDirections.medium) {
            return ModesSpeedsDirections.two;
        } else if (speed == ModesSpeedsDirections.high) {
            return ModesSpeedsDirections.three;
        } else if (speed == ModesSpeedsDirections.auto) {
            return ModesSpeedsDirections.four;
        } else if (speed == ModesSpeedsDirections.disabled) {
            return ModesSpeedsDirections.none;
        } else if (speed == ModesSpeedsDirections.none) {
            return ModesSpeedsDirections.disabled;
        } else if (speed == ModesSpeedsDirections.zero) {
            return ModesSpeedsDirections.very_low;
        } else if (speed == ModesSpeedsDirections.one) {
            return ModesSpeedsDirections.low;
        } else if (speed == ModesSpeedsDirections.two) {
            return ModesSpeedsDirections.medium;
        } else if (speed == ModesSpeedsDirections.three) {
            return ModesSpeedsDirections.high;
        } else if (speed == ModesSpeedsDirections.four) {
            return ModesSpeedsDirections.auto;
        }
    }

    renderVentModeFanSpeed(activeVentMode) {
        if (activeVentMode == ModesSpeedsDirections.disabled) {
            return ModesSpeedsDirections.none;
        } else if (activeVentMode == ModesSpeedsDirections.heat_recovery) {
            return ModesSpeedsDirections.energy_recovery_with_spaces;
        } else if (activeVentMode == ModesSpeedsDirections.energy_recovery_with_spaces) {
            return ModesSpeedsDirections.heat_recovery;
        } else {
            return activeVentMode;
        }
    }

    updateBGD(IDs, mode?) {
        let groups = [];

        this.mainSiteUIService.allSiteGroups.forEach((group) => {
            if (IDs.includes(group?.id)) {
                groups.push(group as SelectedGroup);
            }
        });

        if (mode) {
            let groupsToUpdate = this.getCheckboxes(true).map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode }))})) as SelectedGroup[];
            this.bgd.update(groupsToUpdate);
        } else {
            this.bgd.update(groups);
        }

        this.initializeFilteredUniqueGateways();

        return groups;
    }

    initializeFilteredUniqueGateways() {
        let gws = this.mainSiteUIService.allSiteGroups.map(grp => {
            return {
                id: grp?.gateway?.id,
                name: grp?.gateway?.name, 
                groups: grp?.gateway?.groups,
                expired: grp.isGatewaySubscriptionExpired(),
                expanded: this.mainSiteUIService.uniqueGateways?.length > 0 ? this.mainSiteUIService.uniqueGateways?.find(gw => gw?.name == grp?.gateway?.name)?.expanded == true ? true : false : false,
            };
        }).filter(gw => gw.expired == false).sort((gwA, gwB) => gwB?.groups?.length - gwA?.groups?.length);

        if (this.mainSiteUIService.uniqueGateways.length == 0) {
            this.mainSiteUIService.uniqueGateways = this.mainSiteUIService.getUniqueGateways(gws, this.groupByGateways);
        }
        this.filteredUniqueGateways = this.mainSiteUIService.uniqueGateways;
    }

    expandCollapseCheckList(gw, e?: any) {
        if (this.groupByGateways == true) {
            let isItemExpandComponent = e.target.classList.contains(`itemExpandComponent`) && !e.target.classList.contains(`gwItemCheckbox`);
            if (isItemExpandComponent == true) {
                this.mainSiteUIService.uniqueGateways = this.mainSiteUIService.uniqueGateways.map(gaw => {
                    if (gaw?.id == gw?.id) {
                        gaw.expanded = !gaw.expanded;
                        return gaw;
                    } else {
                        return gaw;
                    }
                })
                this.filteredUniqueGateways = this.mainSiteUIService.uniqueGateways;
            }
        }
    }

    getFilteredGroupsForGW(gw, allSiteGroups) {
        return allSiteGroups.filter(grp => grp?.gateway?.id == gw?.id && this.selectedGroups[0].type == grp.type && grp.isGatewaySubscriptionExpired() == false);
    }
    
    getFilteredCheckedGroupsForGW(gw, allSiteGroups) {
        return allSiteGroups.filter(grp => grp?.gateway?.id == gw?.id && this.IDs.includes(grp?.id) && this.selectedGroups[0].type == grp.type && grp.isGatewaySubscriptionExpired() == false);
    }

    isGrpSelected(grp) { 
        if (this.groupsChanged == false) {
            if (this.evnt && this.evnt != null) {
                if (this?.evnt?.meta?.batchEvents && this?.evnt?.meta?.batchEvents.length > 0) {
                    return this.evnt?.meta?.batchEvents.map(evt => evt?.meta?.group_id).includes(grp?.id);
                } else {
                    return this.selectedGroups.map(gr => gr?.id).includes(grp?.id);
                }
            } else {
                return this.selectedGroups.map(gr => gr?.id).includes(grp?.id);
            }
        } else {
            return this.selectedGroups.map(gr => gr?.id).includes(grp?.id);
        }
    }

    isGrpDisabled(grp) {
        let gwUnit = grp?.gatewayUnits[0];
        let checkBoxes = this.unitItemCheckboxes && this.unitItemCheckboxes[`_results`];
        let checkedCheckBoxes = checkBoxes && checkBoxes.length > 0 ? checkBoxes.filter(ch => ch.el.checked == true) : checkBoxes;
        let typeToConsider = this.evnt ? this.evnt.meta.unitType : this.selectedGroups[0]?.type;
        let gwUnitHasInvalidTypes = grp?.type != typeToConsider;
        let gwUnitIsLastGroupSelected = checkedCheckBoxes && checkedCheckBoxes ? checkedCheckBoxes && checkedCheckBoxes.length == 1 && checkedCheckBoxes[0].el.id.split(`checkboxFor-`)[1] == gwUnit.id : this.selectedGroups?.length <= 1 && this.selectedGroups[0]?.id == grp?.id;
        let gwUnitHasInvalidValues = gwUnit.type == GatewayUnitTwoDigitType.IndoorUnit && isNaN(gwUnit.inlet_temp);
        return gwUnitHasInvalidValues == true || gwUnitIsLastGroupSelected == true || gwUnitHasInvalidTypes == true;
    }

    async changeCheckedGroups(e, grp) {
        let gwUnit = grp?.gatewayUnits[0];
        let gwUnitHasInvalidValues = gwUnit.type == GatewayUnitTwoDigitType.IndoorUnit && isNaN(gwUnit.inlet_temp);
        if (gwUnitHasInvalidValues == true) {
            const errorAlert = await this.alertController.create(this.errorMsg);
            return await errorAlert.present();
        } else {
            this.IDs = this.selectedGroups.map(gr => gr.id);
            let setToActive = e?.detail?.checked;
            if (setToActive) {
                this.IDs.push(grp?.id);
            } else {
                this.IDs = this.IDs.filter(id => id != grp?.id);
            }
            this.updateBGD(this.IDs, this.activeMode);
            this.mainSiteUIService.newGroupsSelected.emit(this.updateBGD(this.IDs, this.activeMode));
            localStorage.setItem(`LastGroupIdsSelected`, JSON.stringify(this.IDs));
            this.selectedGroups = this.mainSiteUIService.allSiteGroups.filter(gr => this.IDs.includes(gr.id));
    
            this.buildSchedulingEventFormGroup({days: this.days});
            this.refreshEventModes();
        }
    }

    setTripleSetTemp() {
        let lower = parseFloat(this.bgd.setTemp.dualSetPointLowLabel);
        let lowerMin = this.temp1DualKnobs() ? this.lowerMin() : this.getMinTemp();

        if (this.multipleRangeSliders() == true) {
            if (this.temp1DualKnobs() == false) {
                lower = lower < lowerMin ? lowerMin : lower > this.lowerMax() ? this.lowerMax() : lower;
            } else if (this.temp2DualKnobs() == false) {
                lower = lower < this.lowerMin2() ? this.lowerMin2() : lower > this.lowerMax2() ? this.lowerMax2() : lower;
            }
        }

        let upper = parseFloat(this.bgd.setTemp.dualSetPointHighLabel) <= lower ? parseFloat(this.bgd.setTemp.dualSetPointHighLabel) + this.deadband : parseFloat(this.bgd.setTemp.dualSetPointHighLabel);

        if (upper - lower <= this.deadband) {
            upper = lower + this.deadband;
        }

        if (this.dualSetPointInitialized == true) {
            this.transitionDualToTriple = true;
        } else {
            this.transitionDualToTriple = false;
        }

        if (isNaN(lower)) {
            lower = lowerMin;
            upper = lower + this.deadband;
        }

        devEnv && console.log(`Set Triple Set Temp`, {lower, upper, lowerMin, saveEnabled: this.saveEnabled});

        this.temperature2Lower = lower;
        this.temperature2Upper = upper;

        this.heatSetTemp = this.temperature2Lower;
        this.coolSetTemp = this.temperature2Upper;

        this.lowerSetTemp = this.temperature2Lower;
        this.upperSetTemp = this.temperature2Upper;

        this.temperature = this.multipleRangeSliders() == true && this.temp1DualKnobs() == false ? this.lowerSetTemp : {
            lower: this.temperature2Lower,
            upper: this.temperature2Upper,
        };

        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
            temperature: this.temperature,
            temperatureRange: this.temperature,
            temperatureRange2: this.upperSetTemp,
            lowerSetTemp: this.lowerSetTemp,
            lowerSetTemp2: this.lowerSetTemp,
            upperSetTemp: this.upperSetTemp,
            temperature2Lower: this.temperature2Lower,
            temperature2Upper: this.temperature2Upper,
            temperatureBatchDual: {
                lower: this.temperature2Lower,
                upper: this.temperature2Upper,
            }
        }, { onlySelf: true, emitEvent: false });
    }

    refreshFormForGroups(e?) {
        let power = ``;
        let groupsToAffect = this.getCheckboxes(true) as SelectedGroup[];
        if (this.getBatchMode() != ``) {
            groupsToAffect = groupsToAffect.map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode: this.getBatchMode() }))}))  as SelectedGroup[];
        }
        this.selectedGroups = groupsToAffect;
        this.bgd.update(groupsToAffect);
        let groupNamesInForm = groupsToAffect.map((grp: any) => grp?.name).join(`, `);
        if (this.selectedGroups.length == 1) power = this.selectedGroups[0].gatewayUnits[0].power;

        this.siteSchedulingEventForm.controls.lastSelectedUnits.setValue(groupNamesInForm);
        this.siteSchedulingEventForm.controls.fanSpeed.setValue(this.getBatchFanSpeed());
        this.siteSchedulingEventForm.controls.power.setValue(this.getBatchPower(power));
        
        if (groupsToAffect[0].type == GatewayUnitTwoDigitType.IndoorUnit) {
            this.siteSchedulingEventForm.controls.mode.setValue(this.getBatchMode());
            this.siteSchedulingEventForm.controls.airDirection.setValue(this.getBatchAirDirection());

            if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
                this.transitionDualToTriple = false;
                let lowerMin = this.temp1DualKnobs() ? this.lowerMin() : this.getMinTemp();
                let lower = this.temperature2Lower < lowerMin ? lowerMin : this.temperature2Lower;
                let upper = lower + this.deadband;
                upper = upper < this.upperMin() ? this.upperMin() : upper;

                this.temperature2Lower = lower;
                this.temperature2Upper = upper;
                this.lowerSetTemp = lower;
                this.upperSetTemp = upper;
                this.heatSetTemp = lower;
                this.coolSetTemp = upper;
                this.temperature = {
                    lower,
                    upper
                };

                this.siteSchedulingEventForm.controls.temperature.setValue(this.temperature);
                this.siteSchedulingEventForm.controls.lowerSetTemp.setValue(this.lowerSetTemp);
                this.siteSchedulingEventForm.controls.temperature2Lower.setValue(this.lowerSetTemp);
                this.siteSchedulingEventForm.controls.temperature2Upper.setValue(this.upperSetTemp);
                this.siteSchedulingEventForm.controls.upperSetTemp.setValue(this.upperSetTemp);
                this.siteSchedulingEventForm.controls.temperatureBatchDual.setValue(this.temperature);
                this.siteSchedulingEventForm.controls.temperatureRange.setValue(this.temperature);
                this.siteSchedulingEventForm.controls.temperatureRange2.setValue(this.temperature);

                devEnv && console.log(`Set Dual Set Temp`, {
                    e,
                    getBatchMode: this.getBatchMode(),
                    getMinTemp: this.getMinTemp(),
                    getMaxTemp: this.getMaxTemp(),
                    lowerMin: this.lowerMin(),
                    lowerMax: this.lowerMax(),
                    upperMin: this.upperMin(),
                    upperMax: this.upperMax(),
                    temperature: this.temperature,
                    lowerSetTemp: this.lowerSetTemp,
                    upperSetTemp: this.upperSetTemp,
                    lowerSetTempForm: this.siteSchedulingEventForm.controls.lowerSetTemp.value,
                    upperSetTempForm: this.siteSchedulingEventForm.controls.upperSetTemp.value,
                    temperatureRange: this.siteSchedulingEventForm.controls.temperatureRange.value,
                    temperatureRange2: this.siteSchedulingEventForm.controls.temperatureRange2.value,
                    temperatureBatchDual: this.siteSchedulingEventForm.controls.temperatureBatchDual.value,
                });
            } else if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false) {
                this.setTripleSetTemp();

                devEnv && console.log(`Set Single & Dual Set Temp`, {
                    temperature: this.temperature,
                    temperatureRange: this.temperature,
                    temperatureRange2: this.upperSetTemp,
                    lowerSetTemp: this.lowerSetTemp,
                    lowerSetTemp2: this.lowerSetTemp,
                    upperSetTemp: this.upperSetTemp,
                    temperature2Lower: this.temperature2Lower,
                    temperature2Upper: this.temperature2Upper,
                    temperatureBatchDual: {
                        lower: this.temperature2Lower,
                        upper: this.temperature2Upper,
                    }
                });
            }

            if (this.siteSchedulingEventForm.value.lowerSetTemp2 < this.lowerMin2()) {
                this.siteSchedulingEventForm.controls.lowerSetTemp2.setValue(this.lowerMin2());
                this.siteSchedulingEventForm.controls.temperatureRange2.setValue(this.lowerMin2());
            } else if (this.siteSchedulingEventForm.value.lowerSetTemp2 > this.lowerMax2()) {
                this.siteSchedulingEventForm.controls.lowerSetTemp2.setValue(this.lowerMax2());
                this.siteSchedulingEventForm.controls.temperatureRange2.setValue(this.lowerMax2());
            }
        } else {
            this.siteSchedulingEventForm.controls.mode.setValue(this.getBatchVentMode());
            this.siteSchedulingEventForm.controls.ventMode.setValue(this.getBatchVentMode());
        }
    }

    async checkUnCheckGroup(e?, grp?) {
        if (this.evnt && this.evnt != null) {
            this.refreshFormForGroups(e);
            devEnv && console.log(`Changing Group(s) on Batch Event`, this.variablesToLog({
                selectedGroupsForEvent: this.selectedGroupsForEvent(),
                form: this.siteSchedulingEventForm.value,
                selectedGroups: this.selectedGroups,
            }));
        } else {
            if (this.multipleRangeSliders() == true) {
                // this.changeCheckedGroups(e, grp);
                this.refreshFormForGroups(e);
            } else {
                this.refreshFormForGroups(e);
            }
            devEnv && console.log(`Changing Group(s)`, this.variablesToLog({
                eventsForSelectedGroups: this.allSiteEvents.filter(evt => this.selectedGroups.map(gr => gr.group_id).includes(evt.meta.group_id)),
                selectedGroupsForEvent: this.selectedGroupsForEvent(),
                form: this.siteSchedulingEventForm.value,
                selectedGroups: this.selectedGroups,
            }));
        }

        this.groupsChanged = true;
        this.selectedGroups = this.selectedGroupsForEvent();
    }

    pinFormatter(value: any) {
        return value;
    }

    lowerMin() {
        let lowerMin = this.minTemperature;
        lowerMin = this.temp1DualKnobs() == true ? this.getMinTemp() : lowerMin;
        return lowerMin;
    }

    lowerMax() {
        let lowerMax = this.temp1DualKnobs() == false ? this.maxTemperature : this.maxTemperature;
        let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
        lowerMax = lowerMax > lowMax ? lowMax : lowerMax;
        return lowerMax;
    }
    
    upperMin() {
        if (this.temp1DualKnobs() == true) {
            let upperMin = this.temp1DualKnobs() == false ? this.minTemperature : this.minTemperature;
            let highMin = this.bgd.setTemp.minMaxPoints.cool.min;
            upperMin = upperMin < highMin ? highMin : upperMin;
            return upperMin;
        } else {
            return this.lowerMin();
        }
    }

    upperMax() {
        let upperMax = this.maxTemperature;
        upperMax = this.temp1DualKnobs() == true ? this.getMaxTemp() : upperMax;
        return upperMax;
    }

    getMinTemp2() {
        if (this.activeMode == undefined) this.activeMode == this.bgd.mode.firstUnitMode;
        return (this.temp2DualKnobs() == true || this.activeMode == undefined || this.activeMode == ModesSpeedsDirections.fan) ? this.bgd.setTemp.minMaxPoints.heat.min : this.bgd.setTemp.minMaxPoints[this.activeMode].min;
    }
    
    getMaxTemp2() {
        if (this.activeMode == undefined) this.activeMode == this.bgd.mode.firstUnitMode;
        return (this.temp2DualKnobs() == true || this.activeMode == undefined || this.activeMode == ModesSpeedsDirections.fan) ? this.bgd.setTemp.minMaxPoints.cool.max : this.bgd.setTemp.minMaxPoints[this.activeMode].max;
    }

    lowerMin2() {
        return this.getMinTemp2();
    }

    lowerMax2() {
        let lowerMax = this.temp2DualKnobs() == false ? this.getMaxTemp2() : this.getMaxTemp2() - this.deadband;
        let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
        lowerMax = lowerMax > lowMax ? lowMax : lowerMax;
        return lowerMax;
    }

    upperMin2() {
        let upperMin = this.temp2DualKnobs() == false ? this.getMinTemp2() : this.getMinTemp2() + this.deadband;
        let highMin = this.bgd.setTemp.minMaxPoints.cool.min;
        upperMin = upperMin < highMin ? highMin : upperMin;
        return upperMin;
    }

    upperMax2() {
        return this.getMaxTemp2();
    }

    temp1ControllerTitle() {
        let title = `Lower Min ${this.lowerMin()}, Upper Max ${this.upperMax()}`;

        if (this.temp1DualKnobs() == true) {
            title = `Lower Min ${this.lowerMin()}, Lower Max ${this.temp1DualKnobs() ? this.lowerMax() : this.getMaxTemp()}, Upper Min ${this.upperMin()}, Upper Max ${this.upperMax()}`;
        }

        return title;
    }

    ionViewDidEnter() {
        if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) {
            this.setTemperatureSingleSetPointUpper(this.coolSetTemp);
        } else if (this.activeMode == ModesSpeedsDirections.heat) {
            this.setTemperatureSingleSetPointLow(this.heatSetTemp);
        } else if (this.activeMode == ModesSpeedsDirections.auto || this.activeMode == ModesSpeedsDirections.setback) {
            const dspMode = this.isInDualSetPoint();
            if (dspMode) {
                this.setTemperatureDualSetPoint(this.heatSetTemp, this.coolSetTemp);
                // this.setTemperaturRangeDualSetPoint(this.heatSetTemp, this.coolSetTemp);
            } else {
                this.setTemperatureSingleSetPointLow(this.heatSetTemp);
            }
        }
    }

    isDualSetPointDisabled() {
        if (this.activeMode == ModesSpeedsDirections.setback && this.selectedGatewayUnit.GroupType != `disabled` || this.activeMode == ModesSpeedsDirections.auto && this.selectedGatewayUnit.GroupType != `disabled` || this.activeMode == `dual_auto` && this.selectedGatewayUnit.GroupType != `disabled`) {
            return false;
        } else {
            return true;
        }
    }

    isInDualSetPoint() {
        return ((this.activeMode == ModesSpeedsDirections.auto) || (this.activeMode == ModesSpeedsDirections.setback)) && !this.isDualSetDisabled;
    }

    doesSupportDualSetpoint() {
        if (this.selectedGatewayUnit.GroupType == `enabled`) {
            return true;
        } else {
            return false;
        }
    }

    setTemperatureSingleSetPointLow(val: number) {
        if (isNaN(val)) val = this.placeHolderNum;

        if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == false) {
            let lowMin = this.getMinTemp();
            let lowMax = this.getMaxTemp();
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        } else if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
            let lowMin = this.bgd.setTemp.minMaxPoints.heat.min;
            let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        } else {
            let lowMin = this.bgd.setTemp.minMaxPoints.heat.min;
            let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        }

        if (this.evnt && this.evnt != null) {
            if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == false) {
                val = this.evnt.meta.temperatureNumber;
            }
        }

        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
            temperature: val,
            lowerSetTemp: val,
            temperatureRange: val,
        }, { onlySelf: true, emitEvent: false });

        devEnv && console.log(`setTemperatureSingleSetPointLow`, this.variablesToLog({
            val,
            temperature: val,
            lowerSetTemp: val,
            temperatureRange: val,
        }));
    }

    setTemperatureSingleSetPointUpper(val: number) {
        if (isNaN(val)) val = this.placeHolderNum;

        if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == false) {
            let lowMin = this.getMinTemp();
            let lowMax = this.getMaxTemp();
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        } else if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
            let lowMin = this.bgd.setTemp.minMaxPoints.heat.min;
            let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        } else {
            let lowMin = this.bgd.setTemp.minMaxPoints.heat.min;
            let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
            val = val < lowMax ? val : lowMax;
            val = val > lowMin ? val : lowMin;
        }

        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
            temperature: val,
            upperSetTemp: val,
            temperatureRange: val,
        }, { onlySelf: true, emitEvent: false });

        devEnv && console.log(`setTemperatureSingleSetPointUpper`, this.variablesToLog({
            val,
            temperature: val,
            upperSetTemp: val,
            temperatureRange: val,
        }));
    }

    setTemperatureDualSetPoint(lowVal, highVal) {

        this.dualSetPointInitialized = true;
        
        if (this.bgd.setTemp.dualCount > 0 && this.bgd.setTemp.singleCount == 0) {
            this.transitionDualToTriple = false;
        }

        lowVal = parseFloat(lowVal);
        highVal = parseFloat(highVal);

        let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
        let highMin = this.bgd.setTemp.minMaxPoints.cool.min;

        let lower = lowVal < lowMax ? lowVal : lowMax;
        let upper = highVal > highMin ? highVal : highMin;

        if (upper - lower <= this.deadband) {
            lower = upper - this.deadband;
            upper = lower + this.deadband;
        }

        this.temperature2Lower = lower;
        this.temperature2Upper = upper;

        this.temperature = {
            lower,
            upper
        };

        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
            lowerSetTemp: lower,
            upperSetTemp: upper,
            temperature: this.temperature,
            temperatureRange: this.temperature,
            ...(this.multipleRangeSliders() == true && this.temp2DualKnobs() == false && {
                lowerSetTemp2: this.determineLowerSetTemp2(),
                temperatureRange2: this.determineLowerSetTemp2(),
            })
        }, { onlySelf: true, emitEvent: false });

        devEnv && console.log(`setTemperatureDualSetPoint`, this.variablesToLog({
            lowerSetTemp: lower,
            upperSetTemp: upper,
            temperature: this.temperature,
            temperatureRange: this.temperature,
            ...(this.multipleRangeSliders() == true && this.temp2DualKnobs() == false && {
                lowerSetTemp2: this.determineLowerSetTemp2(),
                temperatureRange2: this.determineLowerSetTemp2(),
            })
        }));
    }

    setTemperaturRangeDualSetPoint(lowVal, highVal) {
        lowVal = parseFloat(lowVal);
        highVal = parseFloat(highVal);

        let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
        let highMin = this.bgd.setTemp.minMaxPoints.cool.min;

        let lower = lowVal < lowMax ? lowVal : lowMax;
        let upper = highVal > highMin ? highVal : highMin;

        if (upper - lower <= this.deadband) {
            lower = upper - this.deadband;
            upper = lower + this.deadband;
        }

        this.temperature2Lower = lower;
        this.temperature2Upper = upper;

        if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
            let lowerMin = this.temp1DualKnobs() ? this.lowerMin() : this.getMinTemp();
            if (lower < lowerMin) {
                lower = lowerMin;
                upper = lowerMin + this.deadband;
            }
            
            if (upper - lower <= this.deadband) {
                lower = upper - this.deadband;
                upper = lower + this.deadband;
            }

            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                lowerSetTemp: lower,
                upperSetTemp: upper,
                temperature: {
                    lower,
                    upper,
                },
                temperatureRange: {
                    lower,
                    upper,
                },
    
                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
                temperatureBatchDual: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                }
            }, { onlySelf: true, emitEvent: false });
            
            devEnv && console.log(`Temperature Change Range Dual Set, Updated Form Values`, this.variablesToLog({
                upper,
                lower,
                lowerSetTemp: lower,
                upperSetTemp: upper,
                temperature: {
                    lower,
                    upper,
                },
                temperatureRange: {
                    lower,
                    upper,
                },
    
                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
                temperatureBatchDual: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                }
            }));
        }
    }

    setTemperaturRangeSetPoint(val, tempController?) {
        val = typeof val == `string` ? parseFloat(val) : val;
        if (typeof val == `number`) {
            if (!tempController) {
                this.sliderControlToAffect = `#eventStartTempItem`;
                if (this.temp1DualKnobs() == false && this.multipleRangeSliders() == false) {
                    this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                        temperature: val,
                        lowerSetTemp: val,
                        upperSetTemp: val,
                        temperatureRange: val,
                        temperature2Lower: val,
                        temperature2Upper: val,
                        temperatureBatchDual: val,
                    }, { onlySelf: true, emitEvent: false });
                    devEnv && console.log(`Temp 1 Single Only Change Range, Updated Form Values`, this.variablesToLog({
                        tempController,
                        eventDetailValue: val, 
                        functionBeingUsed: `setTemperaturRangeSetPoint`,
                        temperature: val,
                        lowerSetTemp: val,
                        upperSetTemp: val,
                        temperatureRange: val,
                        temperature2Lower: val,
                        temperature2Upper: val,
                        temperatureBatchDual: val,
                    }));
                } else {
                    this.temperature = val;
                    this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                        lowerSetTemp: val,
                        upperSetTemp: val,
                        temperature: val,
                        temperatureRange: val,
                        temperatureRange2: val,
                        lowerSetTemp2: val,
                    }, { onlySelf: true, emitEvent: false });
                    devEnv && console.log(`Temp 1 Single & Dual Change Range, Updated Form Values`, this.variablesToLog({
                        tempController,
                        eventDetailValue: val, 
                        functionBeingUsed: `setTemperaturRangeSetPoint`,
                        lowerSetTemp: val,
                        upperSetTemp: val,
                        temperature: val,
                        temperatureRange: val,
                        temperatureRange2: val,
                        lowerSetTemp2: val,
                    }));
                }
            } else {
                this.sliderControlToAffect = `#eventStartTempItem2`;
                if (this.temp2DualKnobs() == true) {
                    devEnv && console.log(`Temp 2 Dual Change Range, Updated Form Values`, this.variablesToLog());
                } else {
                    this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                        lowerSetTemp2: val,
                    }, { onlySelf: true, emitEvent: false });
                    devEnv && console.log(`Temp 2 Single Change Range, Updated Form Values`, this.variablesToLog({
                        tempController,
                        lowerSetTemp2: val,
                    }));
                }
            }
        } else {
            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                temperatureRange: val,
            }, { onlySelf: true, emitEvent: false });
            devEnv && console.log(`setTemperatureRangeSetPoint`, this.variablesToLog({
                temperatureRange: val,
            }));
        }
    }

    modeChange() {
        this.activeMode = this.siteSchedulingEventForm.value.mode; // new mode
        this.isDualSetDisabled = this.isDualSetPointDisabled();

        let groupsToUpdate = this.getCheckboxes(true).map(grp => ({ ...grp, gatewayUnits: grp.gatewayUnits.map(gwunit => new GatewayUnit({ ...gwunit, mode: this.activeMode }))})) as SelectedGroup[];

        this.bgd.update(groupsToUpdate);

        this.deadband = this.bgd.setTemp.deadband;
        this.minTemperature = this.getMinTemp();
        this.maxTemperature = this.getMaxTemp();

        let dspMode = this.isInDualSetPoint();
        const sspMod = (this.activeMode == ModesSpeedsDirections.auto) && !dspMode;

        if (this.multipleRangeSliders() == false) {
            dspMode = this.temp1DualKnobs() == true;
            // fix for when setpoint above allowed value on switch of mode
            if (dspMode) { // DSP Auto
                if (this.coolSetTemp > this.maxTemperature) this.coolSetTemp = this.maxTemperature;
                if (this.heatSetTemp < this.minTemperature) this.heatSetTemp = this.minTemperature;
    
                if (this.coolSetTemp < this.minTemperature) this.coolSetTemp = this.minTemperature;
                if (this.heatSetTemp > this.maxTemperature) this.heatSetTemp = this.maxTemperature;
            } else if (this.activeMode == ModesSpeedsDirections.heat) {
                if (this.heatSetTemp > this.maxTemperature) this.heatSetTemp = this.maxTemperature;
                if (this.heatSetTemp < this.minTemperature) this.heatSetTemp = this.minTemperature;
            } else if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) {
                if (this.coolSetTemp > this.maxTemperature) this.coolSetTemp = this.maxTemperature;
                if (this.coolSetTemp < this.minTemperature) this.coolSetTemp = this.minTemperature;
            } else if (sspMod) {
                if (this.heatSetTemp > this.maxTemperature) this.heatSetTemp = this.maxTemperature;
                if (this.heatSetTemp < this.minTemperature) this.heatSetTemp = this.minTemperature;
            }
    
            // deadband work
            if (dspMode) {
                this.deadbandFix();
            }
            if (this.activeMode == ModesSpeedsDirections.heat) {
                this.setTemperatureSingleSetPointLow(this.heatSetTemp);
                this.setTemperaturRangeSetPoint(this.heatSetTemp);
            } else if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) {
                this.setTemperatureSingleSetPointUpper(this.coolSetTemp);
                this.setTemperaturRangeSetPoint(this.coolSetTemp);
            } else if (dspMode || this.activeMode == ModesSpeedsDirections.setback || this.activeMode == ModesSpeedsDirections.dual_auto) {
                this.setTemperatureDualSetPoint(this.heatSetTemp, this.coolSetTemp);
                this.setTemperaturRangeDualSetPoint(this.heatSetTemp, this.coolSetTemp);
            } else if (sspMod) {
                this.setTemperatureSingleSetPointLow(this.heatSetTemp);
                this.setTemperaturRangeSetPoint(this.heatSetTemp);
            }
        }

        let temp = (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) ? this.coolSetTemp : this.heatSetTemp;
        let temp2 = (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) ? this.heatSetTemp : this.coolSetTemp;
        temp2 = temp2 < this.lowerMin() ? this.lowerMin() : temp2;
        temp2 = temp2 > this.lowerMax() ? this.lowerMax() : temp2;
        
        if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == false && typeof temp == `number`) {

            let lowerMin = this.temp1DualKnobs() ? this.lowerMin() : this.getMinTemp();

            this.minTemperature = this.getMinTemp();
            this.maxTemperature = this.getMaxTemp();

            temp2 = temp2 < this.minTemperature ? this.minTemperature : temp2;
            temp2 = temp2 > this.maxTemperature ? this.maxTemperature : temp2;

            temp = temp < lowerMin ? lowerMin : temp;

            this.temperature2Lower = temp;
            this.temperature2Upper = temp;

            this.temperature = temp;
    
            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                lowerSetTemp: temp,
                upperSetTemp: temp,
                temperatureRange: temp,
                temperatureBatchDual: temp,
                temperature: this.temperature,
                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
            }, { onlySelf: true, emitEvent: false });

            devEnv && console.log(`Mode Change Detected, Updated Form Values, Temp 1 Single Set`, this.variablesToLog({
                temp, 
                temp2,
                lowerSetTemp: temp,
                upperSetTemp: temp2,
                temperatureRange: temp,
                lowerMin: this.lowerMin(),
                lowerMax: this.lowerMax(),
                temperatureBatchDual: temp,
                temperature: this.temperature,
                getMinTemp: this.getMinTemp(),
                getMaxTemp: this.getMaxTemp(),
                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
            }));
        } else if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
            this.temperature2Lower = parseFloat(this.bgd.setTemp.dualSetPointLowLabel);
            this.heatSetTemp = this.temperature2Lower;
            this.lowerSetTemp = this.temperature2Lower;
            this.temperature2Upper = parseFloat(this.bgd.setTemp.dualSetPointHighLabel) <= this.temperature2Lower ? parseFloat(this.bgd.setTemp.dualSetPointHighLabel) + this.deadband : parseFloat(this.bgd.setTemp.dualSetPointHighLabel);
            this.coolSetTemp = this.temperature2Upper;
            this.upperSetTemp = this.temperature2Upper;
            this.temperature = {
                lower: this.temperature2Lower,
                upper: this.temperature2Upper,
            };

            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                lowerSetTemp: this.temperature2Lower,
                upperSetTemp: this.temperature2Upper,
                temperature: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                },
                temperatureRange: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                },

                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
                temperatureBatchDual: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                }
            }, { onlySelf: true, emitEvent: false });

            devEnv && console.log(`Mode Change Detected, Updated Form Values, Temp 1 Dual Set`, this.variablesToLog({
                lowerSetTemp: this.temperature2Lower,
                upperSetTemp: this.temperature2Upper,
                temperature: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                },
                temperatureRange: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                },

                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
                temperatureBatchDual: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                }
            }));
        } else {
            this.setTripleSetTemp();

            devEnv && console.log(`Mode Change Detected, Updated Form Values, Temp Slider Else`, this.variablesToLog({
                temperature: this.temperature,
                temperatureRange: this.temperature,
                temperatureRange2: this.upperSetTemp,
                lowerSetTemp: this.lowerSetTemp,
                lowerSetTemp2: this.lowerSetTemp,
                upperSetTemp: this.upperSetTemp,
                temperature2Lower: this.temperature2Lower,
                temperature2Upper: this.temperature2Upper,
                temperatureBatchDual: {
                    lower: this.temperature2Lower,
                    upper: this.temperature2Upper,
                }
            }));
        }
    }

    deadbandFix(direction?: Direction) {
        // First going to deal with pushing when at the ends.  So using bar to push high above max or low below min
        if ((this.heatSetTemp < this.minTemperature) || (this.coolSetTemp <= this.minTemperature + this.deadband) && direction != Direction.UP) {
            this.heatSetTemp = this.minTemperature;
            this.coolSetTemp = this.minTemperature + this.deadband;
            return;
        }

        if ((this.coolSetTemp > this.maxTemperature) || (this.heatSetTemp >= this.maxTemperature - this.deadband) && direction != Direction.DOWN) {
            this.coolSetTemp = this.maxTemperature;
            this.heatSetTemp = this.maxTemperature - this.deadband;
            return;
        }

        // This is for moving the other knob when in the middle
        const tempDiff = this.coolSetTemp - this.heatSetTemp;  //upper minus lower
        if (tempDiff < this.deadband) {
            if (!direction || direction == Direction.UP) {
                this.coolSetTemp = this.heatSetTemp + this.deadband; // should move coolSetTemp to the min deadband over heat
            } else {
                this.heatSetTemp = this.coolSetTemp - this.deadband;
            }
        }
    }

    async determineUpperDirection(e) {
        let mouseYPosition = e.layerY;
        let clientHeight = e.target.clientHeight;
        let upperDownButtonClicked = mouseYPosition > (clientHeight / 2);
        this.upperDirection = upperDownButtonClicked == true ? Direction.DOWN : Direction.UP;
    }
    
    async determineLowerDirection(e) {
        let mouseYPosition = e.layerY;
        let clientHeight = e.target.clientHeight;
        let lowerDownButtonClicked = mouseYPosition > (clientHeight / 2);
        this.lowerDirection = lowerDownButtonClicked == true ? Direction.DOWN : Direction.UP;
    }

    async determineUpperDirection2(e) {
        let mouseYPosition = e.layerY;
        let clientHeight = e.target.clientHeight;
        let upperDownButtonClicked = mouseYPosition > (clientHeight / 2);
        this.upperDirection2 = upperDownButtonClicked == true ? Direction.DOWN : Direction.UP;
    }
    
    async determineLowerDirection2(e) {
        let mouseYPosition = e.layerY;
        let clientHeight = e.target.clientHeight;
        let lowerDownButtonClicked = mouseYPosition > (clientHeight / 2);
        this.lowerDirection2 = lowerDownButtonClicked == true ? Direction.DOWN : Direction.UP;
    }

    async temperatureChangeInputUpper(event: CustomEvent, inputLabel?: any, dual?) {
        let newVal = parseFloat(event.detail.value);
        let dir: Direction = null;
        let valToCompare = this.coolSetTemp;

        if (newVal < valToCompare) {
            dir = Direction.DOWN;
        } else {
            dir = Direction.UP;
        }
        
        setTimeout(() => {
            if (inputLabel && inputLabel != `` || dual == true) {
                let secondDualInput = inputLabel == `temperature2Lower` || inputLabel == `temperature2Upper` || inputLabel != ``;
                let lowerTemp = secondDualInput == true && inputLabel != `temperature2Upper` ? this.siteSchedulingEventForm.controls[inputLabel].value : this.siteSchedulingEventForm.controls.temperatureBatchDual.value.lower;
                let lowerValPlusDeadband = lowerTemp + this.deadband;
                let inDeadbandRange = newVal <= lowerValPlusDeadband;

                newVal = inDeadbandRange == true ? lowerValPlusDeadband : newVal;

                let upper = inDeadbandRange == true && this.upperDirection2 == Direction.DOWN ? newVal - this.customStep : newVal;
                let lower = inDeadbandRange == true && this.upperDirection2 == Direction.DOWN ? lowerTemp - this.customStep : lowerTemp;

                if (secondDualInput == true) {
                    this.sliderControlToAffect = `#eventStartTempItem2`;
                    if (this.temp2DualKnobs() == true) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 2 Upper Dual Set`, this.variablesToLog({
                            inputLabel,
                            secondDualInput,
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else {
                        if (this.multipleRangeSliders() == true) {
                            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                                lowerSetTemp2: lower,
                            }, { onlySelf: true, emitEvent: false });
                            devEnv && console.log(`Temp 2 Upper Single & Dual Set ${Direction[this.upperDirection2]}`, this.variablesToLog({
                                newVal,
                                upper,
                                lower,
                                inputLabel,
                                secondDualInput,
                                lowerSetTemp2: lower,
                                direction: Direction[this.upperDirection2],
                            }));
                        } else {
                            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                                lowerSetTemp: lower,
                            }, { onlySelf: true, emitEvent: false });
                            devEnv && console.log(`Temp 2 Upper Single Only Set ${Direction[this.upperDirection2]}`, this.variablesToLog({
                                newVal,
                                upper,
                                lower,
                                inputLabel,
                                secondDualInput,
                                lowerSetTemp: lower,
                                direction: Direction[this.upperDirection2],
                            }));
                        }
                    }
                } else {
                    if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == false) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Upper Single Set`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Upper Dual Set`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == true) {
                        this.temperature2Lower = lower;
                        this.temperature2Upper = upper;
                        this.temperature = {
                            upper,
                            lower,
                        };
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            temperature: {
                                upper,
                                lower,
                            },
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Upper Dual Set & Single`, this.variablesToLog({
                            inputLabel,

                            temperature: {
                                upper,
                                lower,
                            },
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Upper Else`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },
                        }));
                    }
                }
            } else {
                this.sliderControlToAffect = `#eventStartTempItem`;
                this.temperatureChangeInput(event, this.upperDirection, ChangedSP.UPPER);
                devEnv && console.log(`Temp Upper Else`, this.variablesToLog({
                    dual,
                    event, 
                    inputLabel,
                    ChangedSP: ChangedSP.UPPER,
                    upperDirection: this.upperDirection, 
                }));
            };
        }, 1);
    }

    temperatureChangeInputLower(event: CustomEvent, inputLabel?: any, dual?) {
        let newVal = parseFloat(event.detail.value);
        let dir: Direction = null;
        let valToCompare = this.heatSetTemp;
        if (newVal < valToCompare) {
            dir = Direction.DOWN;
        } else {
            dir = Direction.UP;
        }

        setTimeout(() => {
            if (inputLabel && inputLabel != `` || dual == true) {
                let secondDualInput = inputLabel == `temperature2Lower` || inputLabel == `temperature2Upper` || inputLabel != ``;
                let upperTemp = secondDualInput == true && inputLabel != `temperature2Lower` ? this.siteSchedulingEventForm.controls[inputLabel].value : this.siteSchedulingEventForm.controls.temperatureBatchDual.value.upper;
                let upperValPlusDeadband = upperTemp - this.deadband;
                let inDeadbandRange = newVal >= upperValPlusDeadband;

                newVal = inDeadbandRange == true ? upperValPlusDeadband : newVal;

                let lower = inDeadbandRange == true && this.lowerDirection2 == Direction.UP ? newVal + this.customStep : newVal;
                let upper = inDeadbandRange == true && this.lowerDirection2 == Direction.UP ? upperTemp + this.customStep : upperTemp;

                if (inDeadbandRange == true) {
                    upper = lower + this.deadband;
                }

                if (secondDualInput == true) {
                    this.sliderControlToAffect = `#eventStartTempItem2`;
                    if (this.temp2DualKnobs() == true) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 2 Lower Dual Set`, this.variablesToLog({
                            inputLabel,
                            secondDualInput,
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else {
                        if (this.multipleRangeSliders() == true) {
                            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                                lowerSetTemp2: upper,
                                temperatureRange2: upper,
                            }, { onlySelf: true, emitEvent: false });
                            devEnv && console.log(`Temp 2 Lower Single & Dual Set ${Direction[this.lowerDirection2]}`, this.variablesToLog({
                                inputLabel,
                                lowerSetTemp2: upper,
                                temperatureRange2: upper,
                                direction: Direction[this.lowerDirection2],
                            }));
                        } else {
                            this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                                lowerSetTemp: upper,
                            }, { onlySelf: true, emitEvent: false });
                            devEnv && console.log(`Temp 2 Lower Single Only Set ${Direction[this.lowerDirection2]}`, this.variablesToLog({
                                lower,
                                upper,
                                newVal,
                                inputLabel,
                                secondDualInput,
                                lowerSetTemp: upper,
                                direction: Direction[this.lowerDirection2],
                            }));
                        }
                    }
                } else {
                    if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == false) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Lower Single Set`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else if (this.multipleRangeSliders() == false && this.temp1DualKnobs() == true) {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Lower Dual Set`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },

                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else if (this.multipleRangeSliders() == true && this.temp1DualKnobs() == true) {
                        this.temperature2Lower = lower;
                        this.temperature2Upper = upper;
                        this.temperature = {
                            upper,
                            lower,
                        };
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            temperature: {
                                upper,
                                lower,
                            },
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Lower Dual Set & Single`, this.variablesToLog({
                            inputLabel,
                            temperature: {
                                upper,
                                lower,
                            },
                            temperature2Upper: upper,
                            temperature2Lower: lower,
                            temperatureBatchDual: {
                                upper,
                                lower,
                            },
                        }));
                    } else {
                        this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },
                        }, { onlySelf: true, emitEvent: false });
                        devEnv && console.log(`Temp 1 Lower Else`, this.variablesToLog({
                            inputLabel,
                            upperSetTemp: upper,
                            lowerSetTemp: lower,
                            temperatureRange: {
                                upper,
                                lower,
                            },
                        }));
                    }
                }
            } else {
                this.sliderControlToAffect = `#eventStartTempItem`;
                this.temperatureChangeInput(event, this.lowerDirection, ChangedSP.LOWER);
                devEnv && console.log(`Temp Lower Else`, {
                    dual,
                    event,
                    inputLabel,
                    ChangedSP: ChangedSP.LOWER,
                    lowerDirection: this.lowerDirection, 
                })
            };
        }, 1);
    }

    temperatureChangeInput(event: CustomEvent, direction?: Direction, changed?: ChangedSP) {
        let ele: ValueHTMLElement, lowerEle: ValueHTMLElement, upperEle: ValueHTMLElement, rangeEle: ValueHTMLElement = null;

        const dspMode = this.multipleRangeSliders() == false && this.temp1DualKnobs() == false ? false : this.isInDualSetPoint();

        if (this.activeMode != ModesSpeedsDirections.fan) {
            rangeEle = this.ele.nativeElement.querySelector(`#eventStartTempRange`);
        }

        const val = event.detail.value;
        if (!dspMode) {
            this.setTemperaturRangeSetPoint(val);
        } else { // DSP
            devEnv && console.log(`Single Control Dual Point`, val, dspMode);
            // determine which SP changed
            upperEle = this.ele.nativeElement.querySelector(`#upperTemp`);
            let upperVal = parseFloat(upperEle.value);

            lowerEle = this.ele.nativeElement.querySelector(`#lowerTemp`);
            let lowerVal = parseFloat(lowerEle.value);

            if (isNaN(upperVal)) {
                upperVal = this.coolSetTemp;
            }

            if (isNaN(lowerVal)) {
                lowerVal = this.heatSetTemp;
            }

            // all this to fix the corner edge cases
            if (changed == ChangedSP.UPPER && direction == Direction.DOWN && (upperVal - lowerVal < this.deadband)) {
                this.setTemperaturRangeDualSetPoint(lowerVal - this.customStep, upperVal);
            } else if (changed == ChangedSP.LOWER && direction == Direction.UP && (upperVal - lowerVal < this.deadband)) {
                this.setTemperaturRangeDualSetPoint(lowerVal, upperVal + this.customStep);
            } else {
                this.setTemperaturRangeDualSetPoint(lowerVal, upperVal);
            }
        }
    }

    temperatureChangeRange(event: CustomEvent, secondRange?) {
        if (secondRange == true) {
            if (this.temp2DualKnobs() == true) {
                let lowVal = event.detail.value.lower;
                let highVal = event.detail.value.upper;
        
                let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
                let highMin = this.bgd.setTemp.minMaxPoints.cool.min;
        
                let lower = lowVal < lowMax ? lowVal : lowMax;
                let upper = highVal > highMin ? highVal : highMin;

                if (upper - lower <= this.deadband) lower = upper - this.deadband;
                
                this.siteSchedulingEventForm && this.siteSchedulingEventForm.patchValue({
                    temperature2Lower: lower,
                    temperature2Upper: upper,
                    temperatureBatchDual: {
                        lower,
                        upper,
                    }
                }, { onlySelf: true, emitEvent: false });

                devEnv && console.log(`Temperature 2 Change Range Dual Set, Updated Form Values`, this.variablesToLog({
                    eventDetailValue: event.detail.value,
                    temperature2Lower: lower,
                    temperature2Upper: upper,
                    temperatureBatchDual: {
                        lower,
                        upper,
                    }
                }));
            } else {
                this.setTemperaturRangeSetPoint(event.detail.value, true);
            }
        } else {
            const dspMode = this.temp1DualKnobs();
            if (dspMode) {
                let lowVal = event.detail.value.lower;
                let highVal = event.detail.value.upper;
        
                let lowMax = this.bgd.setTemp.minMaxPoints.heat.max;
                let highMin = this.bgd.setTemp.minMaxPoints.cool.min;
        
                let lower = lowVal < lowMax ? lowVal : lowMax;
                let upper = highVal > highMin ? highVal : highMin;
    
                if (upper - lower <= this.deadband) lower = upper - this.deadband;

                this.setTemperaturRangeDualSetPoint(lower, upper);
            } else {
                // catch issue when no number entered
                if (isNaN(parseFloat(event.detail.value))) {
                    return;
                }
        
                const oldHighVal = this.coolSetTemp; // used later to determine movement direction
                const oldLowVal = this.heatSetTemp;
        
                let ele: ValueHTMLElement, lowerEle: ValueHTMLElement, upperEle: ValueHTMLElement, rangeEle: ValueHTMLElement = null;
                let rangeVal: any;
        
                if (this.activeMode != ModesSpeedsDirections.fan) {
                    rangeEle = this.ele.nativeElement.querySelector(`#eventStartTempRange`);
                    rangeVal = rangeEle.value;
                }
        
                // updates the inputs
                if (this.activeMode == ModesSpeedsDirections.heat) {
                    const newVal = parseFloat(rangeVal);
                    if (oldLowVal == newVal) {
                        return;
                    }
                    this.heatSetTemp = newVal;
                } else if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) {
                    const newVal = parseFloat(rangeVal);
                    if (oldHighVal == newVal) {
                        return;
                    }
                    this.coolSetTemp = newVal;
                } else if ((this.activeMode == ModesSpeedsDirections.auto || this.activeMode == ModesSpeedsDirections.setback) && !dspMode) { // SSP auto
                    const newVal = parseFloat(rangeVal);
                    if (oldLowVal == newVal) {
                        return;
                    }
                    this.heatSetTemp = newVal;
                }

                this.setTemperaturRangeSetPoint(event.detail.value);
                // this.setTemperatureSingleSetPointLow(this.heatSetTemp);
                // this.setTemperatureSingleSetPointUpper(this.coolSetTemp);
            }
        }

        if (this.evnt && this.evnt != null) {
            if (event.detail.value != this.evnt.meta.temperature) {
                this.saveEnabled = true;
            }
        }
    }

    formEventHandler(eventFormValue) {
        this.titleValidation();
        this.dateValidation();
        this.timeValidation();
        this.eventValidation(eventFormValue);
        this.temperatureValidation(eventFormValue);
        // devEnv && console.log(`Form Changes`, this.siteSchedulingEventForm.value);
    }

    titleValidation() {
        const titleInput = this.siteSchedulingEventForm.controls.title;
        if (titleInput.hasError(`required`)) {
            if (!titleInput.touched) this.titleLabel = `Event Title is required`;
            if (titleInput.touched) this.titleLabel = `Event Title is required`;
        } else if (titleInput.hasError(`maxlength`)) {
            this.titleLabel = `Event Title must be between 3 and 50 characters`;
        } else if (titleInput.hasError(`minlength`)) {
            this.titleLabel = `Event Title must be between 3 and 50 characters`;
        } else {
            this.titleLabel = `Event Title`;
        }
    }

    dateValidation() {
        const dateInput = this.siteSchedulingEventForm.controls.startDay;
        if (dateInput.hasError(`required`)) {
            if (!dateInput.touched) this.dateLabel = `Event Date Invalid`;
            if (dateInput.touched) this.dateLabel = `Event Date Invalid`;
            this.eventPast = false;
            this.eventFuture = false;
        } else if (parseFloat(dateInput.value.slice(0, 4)) > 2099) {
            this.eventFuture = true;
            this.eventPast = false;
            this.dateLabel = `Event in Future!`;
        } else if (parseFloat(dateInput.value.slice(0, 4)) < 2000) {
            this.eventPast = true;
            this.eventFuture = false;
            this.dateLabel = `Event in Past!`;
        } else {
            this.eventPast = false;
            this.eventFuture = false;
            this.dateLabel = `Event Date`;
        }
    }

    timeValidation() {
        const timeInput = this.siteSchedulingEventForm.controls.startTime;
        if (timeInput.hasError(`required`)) {
            if (!timeInput.touched) this.timeLabel = `Event Time Invalid`;
            if (timeInput.touched) this.timeLabel = `Event Time Invalid`;
        } else {
            this.timeLabel = `Event Time`;
        }
    }

    eventValidation(eventFormValue) {
        if (this.siteSchedulingEventForm.status == `INVALID`) {
            this.saveEnabled = false;
            this.eventEnabled = false;
            // devEnv && console.log(`Invalid Form Entries`, this.siteSchedulingEventForm);
        } else {
            if (!this.airDirects.includes(eventFormValue.airDirection) || !eventFormValue.temperature) {
                if (!eventFormValue.temperature && document.activeElement.id != `upperTemp`) {
                    // this.temperature = this.minTemperature;
                } else {
                    this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && eventFormValue.mode != ModesSpeedsDirections.fan ? (this.eventEnabled = false, this.saveEnabled = false, this.temperatureErrorMsg = `Scheduling Error`) : (this.eventEnabled = true, this.saveEnabled = true);
                }
            } else {
                if (typeof eventFormValue.temperature == `number`) {
                    if (eventFormValue.temperature >= this.minTemperature && eventFormValue.temperature <= this.maxTemperature) {
                        this.saveEnabled = true;
                        this.eventEnabled = true;
                    } else {
                        this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && eventFormValue.mode != ModesSpeedsDirections.fan ? (this.eventEnabled = false, this.saveEnabled = false, this.temperatureErrorMsg = `Scheduling Error`) : (this.eventEnabled = true, this.saveEnabled = true);
                    }
                } else { // Dual Set
                    if (eventFormValue.temperature.lower >= this.minTemperature && eventFormValue.temperature.upper >= this.minTemperature && eventFormValue.temperature.lower <= this.maxTemperature && eventFormValue.temperature.upper <= this.maxTemperature) {
                        this.saveEnabled = true;
                        this.eventEnabled = true;
                    } else {
                        this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && eventFormValue.mode != ModesSpeedsDirections.fan ? (this.eventEnabled = false, this.saveEnabled = false) : (this.eventEnabled = true, this.saveEnabled = true);
                    }
                }
            }
        }
    }

    temperatureValidation(eventFormValue) {
        let temperatureInput = (<any>document.querySelector(`#eventStartTempRange`));
        let upperTempInput = (<any>document.querySelector(`#upperTemp`));
        let lowerTempInput = (<any>document.querySelector(`#lowerTemp`));
        if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) {
            if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) {
                if (typeof eventFormValue.temperature == `number`) {
                    if (this.allowedTempValues.includes(parseFloat(eventFormValue.temperature))) {
                        this.eventEnabled = true;
                        this.saveEnabled = true;
                        // (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`item-has-focus`);
                    } else {
                        this.saveEnabled = false;
                        this.eventEnabled = false;
                        this.temperatureErrorMsg = `Please enter a temperature value in the 0.5 intervals`;
                        this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                        (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                        // if (document.activeElement != upperTempInput) this.temperature = parseFloat(eventFormValue.temperature);
                    }
                } else { // Dual Set
                    this.eventEnabled = true;
                    this.saveEnabled = true;
                }
            } else { // Fahrenheit
                temperatureInput = (<any>document.querySelector(`#eventStartTempRange`));
                upperTempInput = (<any>document.querySelector(`#upperTemp`));
                if (typeof eventFormValue.temperature == `number` || typeof this.temperature == `number`) {
                    if (!eventFormValue.temperature.toString().includes(`.`)) {
                        this.eventEnabled = true;
                        this.saveEnabled = true;
                        // if (document.activeElement == temperatureInput || document.activeElement == upperTempInput) (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`item-has-focus`);
                    } else {
                        if (document.activeElement == upperTempInput) {
                            this.saveEnabled = false;
                            this.eventEnabled = false;
                            this.temperatureErrorMsg = `No decimals in Fahrenheit`;
                            this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                            (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                        } else {
                            upperTempInput.value = parseInt(upperTempInput.value);
                        }
                    }
                } else { // Dual Set
                    const lowerTempInput = (<any>document.querySelector(`#lowerTemp`));
                    if (eventFormValue.temperature.upper && eventFormValue.temperature.lower) {
                        if (!isNaN(parseFloat(eventFormValue.temperature.upper)) && eventFormValue.temperature.upper.toString().includes(`.`) || !isNaN(parseFloat(eventFormValue.temperature.lower)) && eventFormValue.temperature.lower.toString().includes(`.`) || (this.temperature.upper.toString().includes(`.`) || this.temperature.lower.toString().includes(`.`))) {
                            if (document.activeElement == upperTempInput || document.activeElement == lowerTempInput) {
                                this.saveEnabled = false;
                                this.eventEnabled = false;
                                this.temperatureErrorMsg = `No decimals in Fahrenheit`;
                                this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                                (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                            } else {
                                this.saveEnabled = false;
                                this.eventEnabled = false;
                                this.temperatureErrorMsg = `No decimals in Fahrenheit`;
                                this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                                (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                            }
                        } else if (lowerTempInput && parseFloat(lowerTempInput.value) > parseFloat(upperTempInput.value)) {
                            if (document.activeElement == upperTempInput || document.activeElement == lowerTempInput) {
                                this.saveEnabled = false;
                                this.eventEnabled = false;
                                this.temperatureErrorMsg = `Min value cannot exceed the max value`;
                                this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                                (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                            }
                        } else {
                            this.eventEnabled = true;
                            this.saveEnabled = true;
                            // (<any>temperatureInput).classList.contains(`range-pressed`) && (document.activeElement == temperatureInput || document.activeElement == upperTempInput || document.activeElement == lowerTempInput) ? (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`item-has-focus`) : (<any>document.querySelector(`#eventStartTempItem`))?.classList.remove(`item-has-focus`);
                        }
                    }
                }
            }

            if (document.activeElement == (<any>document.querySelector(`#upperTemp`)) || document.activeElement == (<any>document.querySelector(`#lowerTemp`))) {
                if ((<any>document.activeElement).value.includes(`-`)) {
                    this.saveEnabled = false;
                    this.eventEnabled = false;
                    this.temperatureErrorMsg = `Temperature cannot be negative`;
                    this.siteSchedulingEventForm.controls.temperature.setErrors({ invalid: true });
                    (<any>document.querySelector(`#eventStartTempItem`))?.classList.add(`ion-invalid`);
                }
            }
            // devEnv && console.log(`Temperature Validation`, this.variablesToLog());
        } else {
            // devEnv && console.log(`Temperature Validation Else`, this.variablesToLog());
            this.eventEnabled = true;
            this.saveEnabled = true;
        }
    }

    // 7 Day Scheduling Recurring Day Picker
    changeEventDays(e) {
        this.daysChanged = true;
        if (e.target.classList.contains(`active`)) {
            this.days = this.days.filter(day => day != e.target.value);
            e.target.classList.remove(`active`);
        } else {
            this.days.push(e.target.value);
            e.target.classList.add(`active`);
        }
        this.days = [...new Set(this.days)];
        this.siteSchedulingEventForm.controls.days.setValue(this.days);
        this.days.length > 1 ? this.eventOrSeriesText = `Series` : this.eventOrSeriesText = `Event`;
        if (this.is_recurring && this.days.length == 0) {
            this.eventEnabled = false;
            this.saveEnabled = false;
            document.querySelector(`#eventDaysItem`).classList.remove(`customValidation`, `ion-valid`);
            document.querySelector(`#eventDaysItem`).classList.add(`ion-invalid`, `ion-dirty`, `item-has-focus`);
        } else {
            document.querySelector(`#eventDaysItem`).classList.remove(`ion-invalid`, `ion-dirty`, `item-has-focus`);
            document.querySelector(`#eventDaysItem`).classList.add(`customValidation`, `ion-valid`);
        }

        devEnv && console.log(`Change Event Days`, this.variablesToLog({
            days: this.days
        }))
    }

    getMaxTemp(evt?) {
        let rtnVal = null;
        if (this.activeMode == ModesSpeedsDirections.heat) {
            const heatMax = parseFloat(this.selectedGatewayUnit.HeatMax);
            if (heatMax) {
                rtnVal = heatMax;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.heat.max;
            }
        } else if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry || this.activeMode == ModesSpeedsDirections.setback || (this.activeMode == ModesSpeedsDirections.auto && !this.isDualSetDisabled) || this.activeMode == ModesSpeedsDirections.setback_heat || this.activeMode == ModesSpeedsDirections.setback_cool) {
            const coolMax = parseFloat(this.selectedGatewayUnit.CoolMax);
            if (coolMax) {
                rtnVal = coolMax;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.cool.max;
            }
        } else if ((this.activeMode == ModesSpeedsDirections.auto && this.isDualSetDisabled) || this.activeMode == ModesSpeedsDirections.dual_auto || this.activeMode == ModesSpeedsDirections.auto_heat || this.activeMode == ModesSpeedsDirections.auto_cool) {
            const autoMax = parseFloat(this.selectedGatewayUnit.AutoMax);
            if (autoMax) {
                rtnVal = autoMax;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.auto.max;
            }
        } else {
            rtnVal = 100;
        }
        
        let coolMaxAsUpperMax = this.bgd.setTemp.minMaxPoints.cool.max;
        let maxTempValue = this.bgd.setTemp.minMaxPoints[this.activeMode]?.max ?? rtnVal;

        if (this.temp1DualKnobs() == true && (this.deadband == null || this.deadband == undefined)) this.deadband = this.bgd.setTemp.deadband;

        let maxValueToUseForTemp1 = this.temp1DualKnobs() == true ? coolMaxAsUpperMax : maxTempValue;

        return maxValueToUseForTemp1;
    }

    getMinTemp(evt?) {
        let rtnVal = null;
        if (this.activeMode == ModesSpeedsDirections.heat || this.activeMode == ModesSpeedsDirections.setback || (this.activeMode == ModesSpeedsDirections.auto && !this.isDualSetDisabled) || this.activeMode == ModesSpeedsDirections.setback_heat || this.activeMode == ModesSpeedsDirections.setback_cool) {
            const heatMin = parseFloat(this.selectedGatewayUnit.HeatMin);
            if (heatMin) {
                rtnVal = heatMin;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.heat.min;
            }
        } else if (this.activeMode == ModesSpeedsDirections.cool || this.activeMode == ModesSpeedsDirections.dry) {
            const coolMin = parseFloat(this.selectedGatewayUnit.CoolMin);
            if (coolMin) {
                rtnVal = coolMin;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.cool.min;
            }
        } else if ((this.activeMode == ModesSpeedsDirections.auto && this.isDualSetDisabled) || this.activeMode == ModesSpeedsDirections.dual_auto || this.activeMode == ModesSpeedsDirections.auto_heat || this.activeMode == ModesSpeedsDirections.auto_cool) {
            const autoMin = parseFloat(this.selectedGatewayUnit.AutoMin);
            if (autoMin) {
                rtnVal = autoMin;
            } else {
                rtnVal = this.MODE_MIN_MAX_DEFAULTS.auto.min;
            }
        } else {
            rtnVal = 0;
        }

        let valToReturn = rtnVal > parseFloat(this.selectedGatewayUnit.lowerSetTemp) ? parseFloat(this.selectedGatewayUnit.lowerSetTemp) : rtnVal;
        
        let heatMinAsLowerMin = this.bgd.setTemp.minMaxPoints.heat.min;
        let minTempValue = this.bgd.setTemp.minMaxPoints[this.activeMode]?.min ?? valToReturn;

        if (this.temp1DualKnobs() == true && (this.deadband == null || this.deadband == undefined)) this.deadband = this.bgd.setTemp.deadband;

        let minValueToUseForTemp1 = this.temp1DualKnobs() == true ? heatMinAsLowerMin : minTempValue;

        return minValueToUseForTemp1;
    }

    isAirDirectionControlEnabled(): boolean {
        if (this.selectedGatewayUnit.air_direction_sw == Presence.disabled) {
            return false;
        } else {
            return true;
        }
    }

    shouldDisplayControlIcon(direction: string): boolean {
        let rtn = false;
        switch (direction) {
            case ModesSpeedsDirections.stationary:
                if (!this.isAirDirectionControlEnabled()) {
                    rtn = true;
                }
                break;
            case ModesSpeedsDirections.floor:
            case ModesSpeedsDirections.ceiling:
            case ModesSpeedsDirections.middle:
            case ModesSpeedsDirections.low:
                if (this.isAirDirectionControlEnabled()) {
                    rtn = true;
                }
                break;
            case ModesSpeedsDirections.high:
                if (this.isAirDirectionControlEnabled() && this.selectedGatewayUnit.air_direction_sw as any == `5_stages`) {
                    rtn = true;
                }
                break;
            case ModesSpeedsDirections.swing:
                if (this.isAirDirectionControlEnabled() && this.selectedGatewayUnit.swing_sw == Presence.enabled) {
                    rtn = true;
                }
                break;
            case ModesSpeedsDirections.auto:
                if (this.isAirDirectionControlEnabled() && this.selectedGatewayUnit.air_direction_auto == Presence.enabled) {
                    rtn = true;
                }
                break;
        }
        return rtn;
    }

    getLCModeIcon(mode: string, icon?) {
        switch (mode) {
            case ModesSpeedsDirections.bypass:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 4 NOFILL.svg`;
                break;
            case ModesSpeedsDirections.energy_recovery_with_spaces:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 3 NOFILL.svg`;
                break;
            case ModesSpeedsDirections.heat_recovery_with_spaces:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 3 NOFILL.svg`;
                break;
            case ModesSpeedsDirections.auto:
            case ModesSpeedsDirections.auto_cool:
            case ModesSpeedsDirections.auto_heat:
            case ModesSpeedsDirections.dual_auto:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 5 NOFILL.svg`;
                break;
        }
        return icon;
    }

    getFanSpeedIcon(name: string, icon?) {
        switch (name) {
            case ModesSpeedsDirections.disabled:
                icon = `/assets/imgs/fan-speeds/fanSpeedDisabled.svg`;
                break;
            case ModesSpeedsDirections.very_low:
                icon = `/assets/imgs/fan-speeds/fanSpeedVeryLow.svg`;
                break;
            case ModesSpeedsDirections.low:
                icon = `/assets/imgs/fan-speeds/fanSpeedLow.svg`;
                break;
            case ModesSpeedsDirections.medium:
                icon = `/assets/imgs/fan-speeds/fanSpeedMedium.svg`;
                break;
            case ModesSpeedsDirections.high:
            case ModesSpeedsDirections.very_high:
                icon = `/assets/imgs/fan-speeds/fanSpeedHigh.svg`;
                break;
            case ModesSpeedsDirections.auto:
                icon = `/assets/imgs/sync.svg`;
                break;
        }
        return icon
    }

    getAirDirectionIcon(name: string, icon?) {
        switch (name) {
            case ModesSpeedsDirections.disabled:
                icon = `/assets/imgs/fan-speeds/fanSpeedDisabled.svg`;
                break;
            case ModesSpeedsDirections.stationary:
                icon = `/assets/imgs/fan-speeds/fanSpeedDisabled.svg`;
                // icon = `/assets/imgs/air-direction-stationary.svg`;
                break;
            case ModesSpeedsDirections.ceiling:
                icon = `/assets/imgs/air-direction-1-ceiling.svg`;
                break;
            case ModesSpeedsDirections.high:
                icon = `/assets/imgs/air-direction-2-high.svg`;
                break;
            case ModesSpeedsDirections.middle:
                icon = `/assets/imgs/air-direction-3-middle.svg`;
                break;
            case ModesSpeedsDirections.low:
                icon = `/assets/imgs/air-direction-4-low.svg`;
                break;
            case ModesSpeedsDirections.floor:
                icon = `/assets/imgs/air-direction-5-floor.svg`;
                break;
            case ModesSpeedsDirections.swing:
                icon = `/assets/imgs/airDirectionSwing.svg`;
                break;
            case ModesSpeedsDirections.auto:
                icon = `/assets/imgs/sync.svg`;
                break;
        }
        return icon
    }

    getModeIcon(mode: string, icon?) {
        switch (mode) {
            case ModesSpeedsDirections.heat:
                icon = `flame`;
                break;
            case ModesSpeedsDirections.cool:
                icon = `snow`;
                break;
            case ModesSpeedsDirections.dry:
                icon = `water`;
                break;
            case ModesSpeedsDirections.fan:
                icon = `aperture`;
                break;
            case ModesSpeedsDirections.unknown:
            case ModesSpeedsDirections.disabled:
                icon = `remove-circle`;
                break;
            case ModesSpeedsDirections.heat_recovery:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 3 NOFILL.svg`;
                break;
            case ModesSpeedsDirections.bypass:
                icon = `/assets/imgs/lossnay-modes/Lossnay Control 4 NOFILL.svg`;
                break;
            case ModesSpeedsDirections.setback:
            case `setback-cool`:
            case `setback-heat`:
                icon = `exit`;
                break;
            case ModesSpeedsDirections.auto:
            case ModesSpeedsDirections.auto_cool:
            case ModesSpeedsDirections.auto_heat:
            case ModesSpeedsDirections.dual_auto:
                icon = `sync`;
                break;
            // eslint-disable-next-line no-duplicate-case
            case ModesSpeedsDirections.setback:
                icon = `help-outline`;
                break;
        }
        return icon;
    }

    getFanSpeedPreviewIcon() {
        const fanSpeedImages = {
            [GatewayUnitTwoDigitType.IndoorUnit]: {
                [Presence.disabled]: {
                    [FanSpeedStages.two_stages]: {
                        [IndoorFanSpeed.very_low]: `tile_fan_speed_1_of_2.svg`,
                        [IndoorFanSpeed.high]: `tile_fan_speed_2_of_2.svg`,
                    },
                    [FanSpeedStages.three_stages]: {
                        [IndoorFanSpeed.very_low]: `tile_fan_speed_1_of_3.svg`,
                        [IndoorFanSpeed.medium]: `tile_fan_speed_2_of_3.svg`,
                        [IndoorFanSpeed.high]: `tile_fan_speed_3_of_3.svg`,
                    },
                    [FanSpeedStages.four_stages]: {
                        [IndoorFanSpeed.very_low]: `tile_fan_speed_1_of_4.svg`,
                        [IndoorFanSpeed.low]: `tile_fan_speed_2_of_4.svg`,
                        [IndoorFanSpeed.medium]: `tile_fan_speed_3_of_4.svg`,
                        [IndoorFanSpeed.high]: `tile_fan_speed_4_of_4.svg`,
                    }
                },
                [Presence.enabled]: {
                    [FanSpeedStages.two_stages]: {
                        [IndoorFanSpeed.low]: `fan-speed-2-verylow-auto.svg`,
                        [IndoorFanSpeed.high]: `fan-speed-2-low-auto.svg`,
                        [IndoorFanSpeed.auto]: `fan-speed-2-auto.svg`
                    },
                    [FanSpeedStages.three_stages]: {
                        [IndoorFanSpeed.low]: `fan-speed-3-verylow-auto.svg`,
                        [IndoorFanSpeed.medium]: `fan-speed-3-low-auto.svg`,
                        [IndoorFanSpeed.high]: `fan-speed-3-med-auto.svg`,
                        [IndoorFanSpeed.auto]: `fan-speed-3-auto.svg`
                    },
                    [FanSpeedStages.four_stages]: {
                        [IndoorFanSpeed.very_low]: `fan-speed-4-verylow-auto.svg`,
                        [IndoorFanSpeed.low]: `fan-speed-4-low-auto.svg`,
                        [IndoorFanSpeed.medium]: `fan-speed-4-med-auto.svg`,
                        [IndoorFanSpeed.high]: `fan-speed-4-high-auto.svg`,
                        [IndoorFanSpeed.auto]: `fan-speed-4-auto.svg`
                    }
                }
            },
            [GatewayUnitTwoDigitType.Lossnay]: {
                [Presence.disabled]: {
                    [FanSpeedStages.two_stages]: {
                        [LossnayFanSpeed.low]: `tile_fan_speed_1_of_2.svg`,
                        [LossnayFanSpeed.medium]: `tile_fan_speed_2_of_2.svg`,
                    },
                    [FanSpeedStages.three_stages]: {
                        [LossnayFanSpeed.very_low]: `tile_fan_speed_1_of_3.svg`,
                        [LossnayFanSpeed.low]: `tile_fan_speed_2_of_3.svg`,
                        [LossnayFanSpeed.medium]: `tile_fan_speed_3_of_3.svg`,
                    },
                    [FanSpeedStages.four_stages]: {
                        [LossnayFanSpeed.very_low]: `tile_fan_speed_1_of_4.svg`,
                        [LossnayFanSpeed.low]: `tile_fan_speed_2_of_4.svg`,
                        [LossnayFanSpeed.medium]: `tile_fan_speed_3_of_4.svg`,
                        [LossnayFanSpeed.high]: `tile_fan_speed_4_of_4.svg`,
                    }
                },
                [Presence.enabled]: {
                    [FanSpeedStages.two_stages]: {
                        [LossnayFanSpeed.low]: `fan-speed-2-verylow-auto.svg`,
                        [LossnayFanSpeed.medium]: `fan-speed-2-low-auto.svg`,
                        [LossnayFanSpeed.auto]: `fan-speed-2-auto.svg`
                    },
                    [FanSpeedStages.three_stages]: {
                        [LossnayFanSpeed.very_low]: `fan-speed-3-verylow-auto.svg`,
                        [LossnayFanSpeed.low]: `fan-speed-3-low-auto.svg`,
                        [LossnayFanSpeed.high]: `fan-speed-3-med-auto.svg`,
                        [LossnayFanSpeed.auto]: `fan-speed-3-auto.svg`
                    },
                    [FanSpeedStages.four_stages]: {
                        [LossnayFanSpeed.very_low]: `fan-speed-4-verylow-auto.svg`,
                        [LossnayFanSpeed.low]: `fan-speed-4-low-auto.svg`,
                        [LossnayFanSpeed.medium]: `fan-speed-4-med-auto.svg`,
                        [LossnayFanSpeed.high]: `fan-speed-4-high-auto.svg`,
                        [LossnayFanSpeed.auto]: `fan-speed-4-auto.svg`
                    }
                }
            }
        };

        // small change for LC when FanExHighSW is enabled but FanExLowSW is not
        if (this.selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay &&
            this.selectedGatewayUnit.fan_extra_low_sw == Presence.disabled &&
            this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.low] = `tile_fan_speed_1_of_3.svg`;
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.medium] = `tile_fan_speed_2_of_3.svg`;
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.high] = `tile_fan_speed_3_of_3.svg`;
        }

        let hasAuto = this.selectedGatewayUnit.fan_auto_sw;
        let fanSpeedCount = this.selectedGatewayUnit.fan_speed_sw;
        if (fanSpeedCount === ModesSpeedsDirections.none) {
            fanSpeedCount = FanSpeedStages.four_stages;
        }
        const type = this.selectedGatewayUnit.type;
        const fanSpeed = this.selectedGatewayUnit.fan_speed;

        if (this.selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay) {
            hasAuto = this.selectedGatewayUnit.lc_fan_auto_sw;
            if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
                if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
                    fanSpeedCount = FanSpeedStages.four_stages
                } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.disabled) {
                    fanSpeedCount = FanSpeedStages.three_stages
                } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.disabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
                    fanSpeedCount = FanSpeedStages.three_stages
                } else {
                    fanSpeedCount = this.selectedGatewayUnit.lc_fan_speed_sw;
                }
            }
        }
        try {
            return fanSpeedImages[type][hasAuto][fanSpeedCount][fanSpeed] !== undefined ? `/assets/imgs/fan-speeds/` + fanSpeedImages[type][hasAuto][fanSpeedCount][fanSpeed] : ``;
        } catch {
            return ``;
        }
    }

    getLCFanSpeedIcon(position: number) {
        const fanSpeedImages = {
            [FanSpeedStages.two_stages]: [
                `fan-speed-2-a-middlegrey-128x60.svg`,
                `fan-speed-2-b-middlegrey-127x60.svg`
            ],
            [FanSpeedStages.three_stages]: [
                `fan-speed-3-a-middlegrey-85x60.svg`,
                `fan-speed-3-b-middlegrey-85x60.svg`,
                `fan-speed-3-c-middlegrey-85x60.svg`
            ],
            [FanSpeedStages.four_stages]: [
                `fan-speed-4-a-middlegrey-63x60.svg`,
                `fan-speed-4-b-middlegrey-64x60.svg`,
                `fan-speed-4-c-middlegrey-64x60.svg`,
                `fan-speed-4-d-middlegrey-64x60.svg`
            ]
        };
        let fanSpeedCount = null;
        if (this.selectedGatewayUnit.type == GatewayUnitTwoDigitType.Lossnay) {
            if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
                fanSpeedCount = FanSpeedStages.four_stages
            } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.disabled) {
                fanSpeedCount = FanSpeedStages.three_stages
            } else if (this.selectedGatewayUnit.fan_extra_low_sw == Presence.disabled && this.selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
                fanSpeedCount = FanSpeedStages.three_stages
            } else {
                fanSpeedCount = this.selectedGatewayUnit.lc_fan_speed_sw;
            }
        }
        try {
            return fanSpeedImages[fanSpeedCount][position] != undefined ? `/assets/imgs/fan-speeds/` + fanSpeedImages[fanSpeedCount][position] : ``;
        } catch {
            return ``;
        }
    }

    // Close Form Modal
    async close() {
        this.formOpen = false;
        this.groupsChanged = false;
        this.mainSiteUIService.searchTerm = ``;
        await this.modalController.dismiss();
        this.mainSiteUIService.uniqueGateways = [];
        this.filteredUniqueGateways = [];
    }

    ngOnDestroy() {
        this.formOpen = false;
        this.groupsChanged = false;
        localStorage.setItem(`CalView`, this.view);
    }

}

interface ValueHTMLElement extends HTMLElement {
    value: any;
}

enum Direction {
    UP = 1,
    DOWN
}

enum ChangedSP {
    LOWER = 1,
    UPPER
}