<ion-content class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1>Site Monitor</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <!-- Empty For Now -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-title class="stateMessage">
          <ion-label id="zeroStateMessage1" name="zeroStateMessage1" color="medium"
            *ngIf="viewState.noGateways">
            <i>There are no gateways currently registered to this site.</i>
          </ion-label>
        </ion-title>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <ion-grid *ngIf="!viewState.noGateways">
    <ion-row>
      <ion-col size="12">
        <ion-card me-ion-card>
          <ion-row>
            <ion-col size="8">
              <ion-item class="me-title-item" lines="none">
                <ion-title class="ion-no-padding">
                  <ion-label>Select Equipment to View Data</ion-label>
                </ion-title>
                <div class="ion-icon-wrapper-card-header me-info-align" (click)="displayInformation()" title="Information">
                  <ion-icon name="information-circle" color="black" me-icon-nav></ion-icon>
                </div>
              </ion-item>
            </ion-col>
            <ion-col size="4" class="gatewaySelect">
              <ion-item>
                <ion-toolbar>
                  <ion-buttons>
                    <ion-button slot="start" id="btnSelectGateway" gateway-selector button-padding-top
                      (click)="onSelectGateway($event)" [disabled]="selectorsDisabled">
                      {{ selectedGatewayName }}
                    </ion-button>
                    <ion-button button-padding-top (click)="onSelectGateway($event)" [disabled]="selectorsDisabled">
                      <ion-icon slot="end" style="margin-left:20px" name="caret-down-sharp"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-card-content color="white" me-top-border class="ion-no-padding cardContent">
            <ion-row *ngIf="selectedGateway">
              <ion-col size="12" size-lg="8" size-xl="9">
                <ion-row>
                  <ion-col *ngIf="!viewState.noUnits">
                    <ion-item>
                      <ion-select [(ngModel)]="unit.form" [disabled]="selectorsDisabled" (ionChange)="onUnitSelect($event)" interface="popover"
                        placeholder="Select a unit">
                        <ion-select-option class="select_a_unit_popover_select_option" [value]="u.id" *ngFor="let u of selectedGatewayUnits">
                          {{u.type}} - {{u.name}}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-toolbar class="timeSelectToolbar" [hidden]="!viewState.chart">
                      <ion-buttons class="timeSelectButtons">
                        <ion-button me-button-as-link (click)="zoomTo('1h')">1h</ion-button>
                        <ion-button me-button-as-link (click)="zoomTo('12h')">12h</ion-button>
                        <ion-button me-button-as-link (click)="zoomTo('1d')">1d</ion-button>
                        <ion-button me-button-as-link (click)="zoomTo('1w')">1w</ion-button>
                      </ion-buttons>
                    </ion-toolbar>
                  </ion-col>
                  <ion-col size="auto">
                    <ion-toolbar class="resetToolbar" [hidden]="!viewState.chart">
                      <ion-buttons class="timeSelectButtons">
                        <ion-button me-button-as-link (click)="resetGraph()"><ion-icon name="refresh-outline"></ion-icon></ion-button>
                      </ion-buttons>
                    </ion-toolbar>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="viewState.loadingSpinner">
                  <ion-col>
                    <ion-row>
                      <div class="spinnerContainer" id="spinnerContainer">
                        <ion-spinner color="primary" class="bigSpinner" name="lines"></ion-spinner>
                      </div>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <div class="center"><p><i class="loadText">Loading graph data...</i></p></div>
                      </ion-col>
                    </ion-row>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div *ngIf="!viewState.setOduModel">
                      <div [hidden]="!viewState.chart" class="chartWrapper">
                        <div id="chartContainer" class="chartContainer"></div>
                      </div>
                    </div>
                    <div *ngIf="viewState.setOduModel">
                      <ion-label id="setModelAndSeries" name="setModelAndSeries" color="medium" class="inline-error-message">
                        <i>Configure the outdoor unit series and model in the Gateway Details page to view data points.</i>
                      </ion-label>
                    </div>
                    <div *ngIf="viewState.noData">
                      <ion-label id="noDataReturned" name="noDataReturned" color="medium" class="inline-error-message">
                        <i>No data returned for selected unit.</i>
                      </ion-label>
                    </div>
                    <div *ngIf="viewState.noUnits">
                      <ion-label id="setModelAndSeries" name="setModelAndSeries" color="medium" class="inline-error-message">
                        <i>No units are currently mapped to this gateway.</i>
                      </ion-label>
                    </div>
                    <div *ngIf="viewState.error">
                      <ion-label id="setModelAndSeries" name="setModelAndSeries" color="medium" class="inline-error-message">
                        <i>Error graphing the data.  Make sure units are running and connected.  If error persists contact support.</i>
                      </ion-label>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="12" size-lg="4" size-xl="3">
                <div [hidden]="!viewState.chart" id="datapoints">
                  <ion-label class="datapoints-title">Data Points</ion-label>
                  <ion-item lines="full" *ngFor="let dp of dataPoints; let first = first;" id="datapoint_{{dp}}">
                    <ion-checkbox id="{{cleanId(dp)}}" (ionChange)="datapointChange($event, dp)" slot="start" class="ionCheckbox" aria-label="{dp}}"></ion-checkbox>
                    <ion-label id="datapoint_{{dp}}_label">{{dp}}</ion-label>
                    <div *ngIf="!isMxzSm" class="datapointInfoPoint" (click)="datapointInfo(dp)">
                      <ion-icon slot="end" class="datapointInfoPointIcon" name="information-circle"></ion-icon>
                    </div>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="viewState.selectGateway"><ion-col>
              <div class="center"><p><i>Please select a gateway.</i></p></div>
            </ion-col></ion-row>
            <ion-row *ngIf="viewState.selectUnit"><ion-col>
              <div class="center"><p><i>Please select an outdoor unit or indoor coil.</i></p></div>
            </ion-col></ion-row>
          </ion-card-content>
        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>