<ion-content main-background>
    <ion-toolbar color="light">
        <ion-title color="dark">
            <h1 id="header" name="header">BC Port Check : {{statusStr}}</h1>
        </ion-title>
    </ion-toolbar>
    <ion-card>
        <ion-item lines="none">
            <ion-title class="ion-no-padding">
                <h4>Target Equipment</h4>
            </ion-title>
        </ion-item>
        <ion-card-content>

            <div class="table-container table-scroll-y">
                <table mat-table [dataSource]="dataSourceOc" class="mat-elevation-z8">
                    <ng-container matColumnDef="SystemName">
                        <th mat-header-cell *matHeaderCellDef class="col-width">SystemName</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.systemName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ModelName">
                        <th mat-header-cell *matHeaderCellDef class="col-width">ModelName</th>
                        <td mat-cell *matCellDef="let element" class="col-width"> {{element.modelName}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-item lines="none">
            <ion-title class="ion-no-padding">
                <h4>Progress:&nbsp;&nbsp;{{progress}}</h4>

            </ion-title>
            <div class="ion-icon-wrapper-card-header" style="display: none;">
                <ion-button> Change BC Port </ion-button>
            </div>
            <div class="ion-icon-wrapper-card-header">
                <ion-button [disabled]="cancelDisabled" (click)="checkCancel()"> Cancel </ion-button>
            </div>
        </ion-item>
        <ion-card-content>
            <div class="table-container table-scroll">
                <ion-title class="ion-no-padding">
                    <h4>{{statusMassage}}</h4>
                </ion-title>
                <table mat-table [dataSource]="dataSourceResult" class="mat-elevation-z8"
                    [class.hidden-resule]="!isShowResult">
                    <ng-container matColumnDef="ocAddress">
                        <th mat-header-cell *matHeaderCellDef class="col-width">OC address</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.ocAddress}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="branchAddress">
                        <th mat-header-cell *matHeaderCellDef class="col-width">BC/BS address</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.branchAddress === undefined ? '-' : element.branchAddress}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef class="col-width">IC address</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.address === undefined ? '-' : element.address}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="group">
                        <th mat-header-cell *matHeaderCellDef class="col-width">Group</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.group === undefined ? '-' : element.group}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="branchPair">
                        <th mat-header-cell *matHeaderCellDef class="col-width">branch number</th>
                        <td mat-cell *matCellDef="let element" class="col-width">{{element.branchPair === undefined ? '-' : element.branchPair}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef class="col-width">note</th>
                        <td mat-cell *matCellDef="let element" class="col-width">
                            <p>{{element.date === undefined ? null : element.date}}</p>
                            <div *ngFor="let note of element.note;">
                                <p>{{note === undefined ? '-' : note}}</p>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="rowColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: rowColumns"></tr>
                </table>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>