import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { MaintenanceDetailComponent } from '../maintenance-detail/maintenance-detail.component'
import { MaintenanceBranchComponent } from '../maintenance-branch/maintenance-branch.component'
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';
//import 'rxjs/add/observable/interval';

import DEMO_DATA from '../../../../../assets/demo-data.json'

const TIME_HEADER = [
  "00:00",
  "00:01",
  "00:02",
  "00:03",
  "00:04",
  "00:05",
  "00:06",
  "00:07",
  "00:08",
  "00:09",
  "00:10",
  "00:11",
  "00:12",
  "00:13",
  "00:14",
  "00:15",
  "00:16",
  "00:17",
  "00:18",
  "00:19",
  "00:20",
  "00:21",
  "00:22",
  "00:23",
  "00:24",
  "00:25",
  "00:26",
  "00:27",
  "00:28",
  "00:29",
  "00:30",
  "00:31",
  "00:32",
  "00:33",
  "00:34",
  "00:35",
  "00:36",
  "00:37",
  "00:38",
  "00:39",
  "00:40",
  "00:41",
  "00:42",
  "00:43",
  "00:44",
  "00:45",
  "00:46",
  "00:47",
  "00:48",
  "00:49",
  "00:50",
  "00:51",
  "00:52",
  "00:53",
  "00:54",
  "00:55",
  "00:56",
  "00:57",
  "00:58",
  "00:59",
]

const KEY_NAMES = [
  'OC(051) TH2',
  'OC(051) TH3',
  'OC(051) TH4',
  'OC(051) TH5',
  'OC(051) TH6',
  'OC(051) TH7',
  'OC(051) THHS',
  'OC(051) SCo',
  'OC(051) SHb',
  'OC(051) Tc',
  'OC(051) Te',
  'OC(051) Vdc',
  'OC(051) AL',
  'OC(051) Ope mode',
  'OC(051) Cln Timer(min)',
  'OC(051) 63HS1',
  'OC(051) 63HS2',
  'OC(051) 63LS',
  'OC(051) FAN-Ver',
  'OC(051) Iu',
  'OC(051) Iw',
  'OC(051) Ctrl mode',
  'OC(051) Save(%)',
  'OC(051) ΣQj(C)',
  'OC(051) ΣQj(H)',
  'OC(051) Ope Status',
  'OC(051) M-NET Supply Unit',
  'OC(051) Start-up unit',
  'OC(051) Demand',
  'OC(051) SNOW',
  'OC(051) Night',
  'OC(051) Demand 2',
  'OC(051) Night 2',
  'OC(051) AK',
  'OC(051) FAN（1）',
  'OC(051) LEV1',
  'OC(051) LEV2',
  'OC(051) FAN (rpm)',
  'OC(051) FAN -Free',
  'OC(051) F1',
  'OC(051) F',
  'OC(051) Foc',
  'OC(051) Rotation Timer',
  'OC(051) IH',
  'OC(051) Repeater',
  'OC(051) 72C',
  'OC(051) Comp ON',
  'OC(051) 21S4a',
  'OC(051) SV1a',
  'OC(051) SV8',
  'OC(051) SV2',
  'OC(051) M-NET Supply',
  'IC(001) Mode',
  'IC(001) TH1',
  'IC(001) TH2',
  'IC(001) TH3',
  'IC(001) TH4',
  'IC(001) SC',
  'IC(001) SH',
  'IC(001) Li',
  'IC(001) TO',
  'IC(001) Save',
  'IC(001) O/F',
  'IC(001) State',
  'IC(001) IC S',
  'IC(001) Fan',
  'IC(002) Mode',
  'IC(002) TH1',
  'IC(002) TH2',
  'IC(002) TH3',
  'IC(002) TH4',
  'IC(002) SC',
  'IC(002) SH',
  'IC(002) Li',
  'IC(002) TO',
  'IC(002) Save',
  'IC(002) O/F',
  'IC(002) State',
  'IC(002) IC S',
  'IC(002) Fan',
  'IC(003) Mode',
  'IC(003) TH1',
  'IC(003) TH2',
  'IC(003) TH3',
  'IC(003) TH4',
  'IC(003) SC',
  'IC(003) SH',
  'IC(003) Li',
  'IC(003) TO',
  'IC(003) Save',
  'IC(003) O/F',
  'IC(003) State',
  'IC(003) IC S',
  'IC(003) Fan',
  'IC(004) Mode',
  'IC(004) TH1',
  'IC(004) TH2',
  'IC(004) TH3',
  'IC(004) TH4',
  'IC(004) SC',
  'IC(004) SH',
  'IC(004) Li',
  'IC(004) TO',
  'IC(004) Save',
  'IC(004) O/F',
  'IC(004) State',
  'IC(004) IC S',
  'IC(004) Fan',
  'IC(005) Mode',
  'IC(005) TH1',
  'IC(005) TH2',
  'IC(005) TH3',
  'IC(005) TH4',
  'IC(005) SC',
  'IC(005) SH',
  'IC(005) Li',
  'IC(005) TO',
  'IC(005) Save',
  'IC(005) O/F',
  'IC(005) State',
  'IC(005) IC S',
  'IC(005) Fan',
]

@Component({
  selector: 'maintenance-result',
  templateUrl: './maintenance-result.component.html',
  styleUrls: ['./maintenance-result.component.scss'],
})
export class MaintenanceResultComponent implements OnInit {
    displayedColumns: string[] = DISPLAYED_COLUMNS;
    dataSource = [];
    bgColorStyle= "{}";
    timeHeader = TIME_HEADER;
    
    timeLeft: number = 60;
    counter: number = 0;
    interval;

    // progressMessage = `残り ${ this.timeLeft } 分`;
    // progressMessage = `${ this.timeLeft } minutes remaining`;
    progressMessage = `Progress: ${ this.timeLeft } min left`;

    constructor(public _ref: ChangeDetectorRef, 
                public _router: Router, 
                public modalController: ModalController, 
                public user: UserService) { 
      this._router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          this.dataSource = [];
        }
      });
    }
  
    ngOnInit() {
      this._ref.detectChanges();
      this.startTimer()
    }
  
    /**
     * 詳細ダイアログ表示
     * Show Details Dialog
     */
    async onClickDetail(){    
      const modal = await this.modalController.create({
        component: MaintenanceDetailComponent,
        backdropDismiss: true,
        componentProps: {
          parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl
        },
      });
      return await modal.present();
    }

    /**
     * 分岐口変更ダイアログ表示
     * Show BC port change Dialog
    */
     async onClickBranch(){    
      const modal = await this.modalController.create({
        component: MaintenanceBranchComponent,
        backdropDismiss: true,
        componentProps: {
          parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl
        },
      });
      return await modal.present();
    }
    
    onDownloadReport() {
      alert('Test run data downloaded.');
      // alert('試運転結果をダウンロードしました');
      // alert('Downloaded Test Run Result.');
    }
  
    onClickTimer() {
      this.dataSource = [
        {
          'Key': 'OC(051) TH2',
          'TimeData_1': '30.8'
        }
      ];
      // this.progressRunning = true;
    }


    startTimer() {
      this.timeLeft = 60;
      this.counter=0;
      // this.progressMessage = `残り ${ this.timeLeft } 分`;
      // this.progressMessage = `${ this.timeLeft } minutes remaining`;
      this.progressMessage = `Progress: ${ this.timeLeft } min left`;
      this.dataSource = [];
      clearInterval(this.interval);

      // 項目名を全部表示
      // Show all item names
      const newDataSource = []
      for(let idx=1; idx <= 122; idx++){
        const addData = {
          'Key': KEY_NAMES[idx-1],
        }
        newDataSource.push(addData)
      }
      this.dataSource = newDataSource


      // ループ開始
      // Loop start
      this.interval = setInterval(() => {
        if(this.timeLeft > 0) {
          const jsonData = DEMO_DATA['data'][this.counter];
          const timeDataIdx = "TimeData_" + (this.counter+1)
          for(let idx=1; idx <= 122; idx++){
            const jsonDataKeyName = 'Column_' + idx;
            newDataSource[idx-1][timeDataIdx] = jsonData[jsonDataKeyName]
          }
    
          this.dataSource = newDataSource
    
          DEMO_DATA
          this.timeLeft--;
          // this.progressMessage = `残り ${ this.timeLeft } 分`;
          // this.progressMessage = `${ this.timeLeft } minutes remaining`;
          this.progressMessage = ` Progress: ${ this.timeLeft } min left`;
          this.counter++;

        } else {
          this.timeLeft = 60;
          this.counter=0;
          // this.progressMessage = `試運転完了`;
          // this.progressMessage = `Test Run completed`;
          this.progressMessage = `Complete`;
          clearInterval(this.interval);
        }
      },1000)
    }

    pauseTimer() {
      this.timeLeft = 60;
      this.counter=0;
      // this.progressMessage = `残り ${ this.timeLeft } 分`;
      // this.progressMessage = `${ this.timeLeft } minutes remaining`;
      this.progressMessage = ` Progress: ${ this.timeLeft } min left`;
      this.dataSource = [];
      clearInterval(this.interval);
    }
}

/** 表示カラム */
/** Display Columns */
const DISPLAYED_COLUMNS = [
  'Key', 
  'TimeData_1', 
  'TimeData_2',
  'TimeData_3',
  'TimeData_4',
  'TimeData_5',
  'TimeData_6',
  'TimeData_7',
  'TimeData_8',
  'TimeData_9',
  'TimeData_10',
  'TimeData_11',
  'TimeData_12',
  'TimeData_13',
  'TimeData_14',
  'TimeData_15',
  'TimeData_16',
  'TimeData_17',
  'TimeData_18',
  'TimeData_19',
  'TimeData_20',
  'TimeData_21',
  'TimeData_22',
  'TimeData_23',
  'TimeData_24',
  'TimeData_25',
  'TimeData_26',
  'TimeData_27',
  'TimeData_28',
  'TimeData_29',
  'TimeData_30',
  'TimeData_31',
  'TimeData_32',
  'TimeData_33',
  'TimeData_34',
  'TimeData_35',
  'TimeData_36',
  'TimeData_37',
  'TimeData_38',
  'TimeData_39',
  'TimeData_40',
  'TimeData_41',
  'TimeData_42',
  'TimeData_43',
  'TimeData_44',
  'TimeData_45',
  'TimeData_46',
  'TimeData_47',
  'TimeData_48',
  'TimeData_49',
  'TimeData_50',
  'TimeData_51',
  'TimeData_52',
  'TimeData_53',
  'TimeData_54',
  'TimeData_55',
  'TimeData_56',
  'TimeData_57',
  'TimeData_58',
  'TimeData_59',
  'TimeData_60',
];
