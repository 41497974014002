import { GatewayUnitTwoDigitType, GatewayUnitTypeEnum } from 'src/app/enumerations/enums';

export enum Presence {
    default = 'disabled',
    disabled = 'disabled',
    enabled = 'enabled',
}

export enum Power {
    default = 'off',
    off = 'off',
    on = 'on',
    testrun = 'testrun'
}

export enum AirDirection {
    default = 'ceiling',
    stationary = 'stationary',
    ceiling = 'ceiling',
    high = 'high',
    middle = 'middle',
    low = 'low',
    floor = 'floor',
    swing = 'swing',
    auto = 'auto'
}

export enum AirDirectionStages {
    disabled = 'disabled',
    four_stages = '4_stages',
    five_stages = '5_stages'
}

export enum FanSpeedStages {
    default = 'none',
    none = 'none',
    one_stage = '1_stage',
    two_stages = '2_stages',
    three_stages = '3_stages',
    four_stages = '4_stages'
}

export enum FanSpeed {
    disabled = 'disabled',
    very_low = 'very low',
    low = 'low',
    medium = 'medium',
    high = 'high',
    very_high = 'very high',
    auto = 'auto',
}

export enum IndoorFanSpeed {
    disabled = 'disabled',
    very_low = '4',
    low = '5',
    medium = '6',
    high = '7',
    auto = '11'
}

export enum LossnayFanSpeed {
    disabled = 'disabled',
    very_low = '2',
    low = '4',
    medium = '7',
    high = '9',
    auto = '11',
}

export enum Mode {
    unknown = `--`,
    setback = 'setback',
    heat = 'heat',
    cool = 'cool',
    auto = 'auto',
    dual_auto = 'dual_auto',
    defrost = 'defrost',
    fan = 'fan',
    dry = 'dry',
    ventilation = 'ventilation',
    auto_heat = 'auto_heat',
    auto_cool = 'auto_cool',
    setback_cool = 'setback_cool',
    setback_heat = 'setback_heat'
}

export enum VentMode {
    default = 'heat_recovery',
    bypass = 'bypass',
    heat_recovery = 'heat_recovery',
    auto = 'auto'
}

export enum AutoMode {
    default = 'disabled',
    disabled = 'disabled',
    single_setpoint = 'single_setpoint',
    dual_setpoint = 'dual_setpoint',
    double_setpoint = 'double_setpoint'
}

export enum IcKind {
    default = 'cooling_only',
    cooling_only = 'cooling_only',
    heating_cooling = 'heating_cooling'
}

export enum Temp {
    unk = '--',
    zero = '0',
    hundred = '100',
    mforty = '-40' // this value is reported when the thermisor is in err
}

export enum TempControl {
    upper = 'upper',
    lower = 'lower'
}

export enum SetTempModeEnum {
    disabled = 0,
    single = 1,
    dual = 2,
    singleAndDual = 3
}

export enum BatchTempControl {
    // indicates which set temp control was clicked
    single = 'single',
    dualHigh = 'dualHigh',
    dualLow = 'dualLow'
}

export class UnitGroup {
    autocreated = false;
    id = '';
    name = '';
    gateway_id = '';
    number = '';
    created_at: Date = null;
    last_modified: Date = null;
}

//const ignorableProperties = { status: false, timer: 0, expired: true };
export class GatewayUnit {
    // a single idu or lc

    // this is the list of attributes that 'control' the unit.
    // this is also the list of items that can be 'controlled' from the siteControl page

    public static controlAttributes = ['power', 'mode', 'vent_mode', 'fan_speed', 'air_direction', 'SetTemp', 'SetTemp1', 'SetTemp2', 'SetTemp3', 'SetTemp4', 'SetTemp5']

    // id's.  including 
    id = '';
    bus_address = '';
    name = 'Select Group / Indoor Coil';
    gateway_id = '';
    created_at = '';
    last_updated: Date = null;
    model = '';
    series = '';
    serial = '';
    type = '';
    group_id = '';
    group_number = '';
    group_name = '';
    group: UnitGroup;
    outdoor_unit: GatewayUnit;
    location = '';

    odu: GatewayUnit = null;

    inlet_temp:string = Temp.unk;
    last_profile_update: Date = null;

    //ui related properties
    unit_type: GatewayUnitTypeEnum;
    site_id = '';
    isExpanded = false;
    isSelected = false;
    isProfileReady = false;

    /**
     * Unit power status
     * @enum Power
     */
    power = Power.default;

    deadband = 0;

    deadband_sw = Presence.disabled;
    /**
     * Current mode for this unit
     */
    mode: Mode = Mode.cool;
    /**
     * Has fan mode
     * @enum Presence
     */
    fan_mode_sw = Presence.default;
    /**
     * Fan has auto mode
     * @enum Presence
     */
    fan_auto_sw = Presence.default;
    /**
     * Air Direction
     * @enum AirDirection
     */
    air_direction = AirDirection.default;
    /**
     * Unit has Air Direction Auto mode
     * @enum Presence
     */
    air_direction_auto = Presence.default

    /**
     * Swing Enabled
     * @enum Presence
     */
    swing_sw = Presence.default;
    /**
     * Air Direction Enabled
     * @enum 'disabled'|'4_stages'|'5_stages'
     */
    air_direction_sw = AirDirectionStages.disabled;
    /**
     * Dry mode enabled
     * @enum Presence
     */
    dry_mode_sw = Presence.default;
    /**
     * IDU kind - cooling w/o heating
     * @enum IcKind
     */
    ic_kind = IcKind.default;
    /**
     * Fan speed
     * @enum FanSpeed
     */
    fan_speed = FanSpeed.low;
    /**
     * Number of fan speeds supported by the unit
     * @enum FanSpeedStages
     */
    fan_speed_sw = <string>FanSpeedStages.default;
    /**
     * Presence of auto mode and set point count
     * @enum AutoMode
     */
    auto_mode = AutoMode.default;
    SetbackModeSW = Presence.disabled;

    vent_mode = VentMode.default;
    // Lossnay Stuff
    lc_auto_sw = Presence.disabled;
    lc_bypass_sw = Presence.disabled;
    lc_fan_auto_sw = Presence.disabled;
    lc_fan_speed_sw = FanSpeedStages.default;
    lc_humid_sw = Presence.disabled;
    /**
     * Determins if set temp is provided by MultiSetTemp
     */
    GroupType = Presence.enabled;
    /**
     * Auto minimum, not used?
     */
    AutoMin = '0';
    /**
     * Auto maximum, not used?
     */
    AutoMax = '100';
    /**
     * Cool minimum
     */
    CoolMin = '0';
    /**
     * Cool maximum
     */
    CoolMax = '100';
    /**
     * Heat minimum
     */
    HeatMin = '0';
    /**
     * Heat maximum
     */
    HeatMax = '100';
    /**
     * I don't know what this does
     */
    MultiOverRide = Presence.default;
    /**
     * Single set temp setting
     */
    SetTemp:string = Temp.unk;
    /**
     * Cooling set temp
     */
    SetTemp1:string = Temp.unk;
    /**
     * Heating set temp
     */
    SetTemp2:string = Temp.unk;
    /**
     * Auto single set point set temp
     */
    SetTemp3:string = Temp.unk;
    /**
     * Setback cooling set temp
     */
    SetTemp4:string = Temp.unk;
    /**
     * Setback heating set temp
     */
    SetTemp5:string = Temp.unk;

    minMaxPoints = {
        cool: { min: 0, max: 100 },
        dry: { min: 0, max: 100 },
        heat: { min: 0, max: 100 },
        auto: { min: 0, max: 100 },
        setback: { min: 0, max: 100 }
    };

    // ignorable = {
    //     power: { ...ignorableProperties },
    //     mode: { ...ignorableProperties },
    //     vent_mode: { ...ignorableProperties },
    //     fan_speed: { ...ignorableProperties },
    //     air_direction: { ...ignorableProperties },
    //     SetTemp: { ...ignorableProperties },
    //     SetTemp1: { ...ignorableProperties },
    //     SetTemp2: { ...ignorableProperties },
    //     SetTemp3: { ...ignorableProperties },
    //     SetTemp4: { ...ignorableProperties },
    //     SetTemp5: { ...ignorableProperties }
    // };

    // /**
    //  * Lower set temp control, typically cooling or heating(single set temp)
    //  */
    // lowerSetTemp = '0.0';
    // /**
    //  * Upper set temp control, typically heating
    //  */
    // upperSetTemp = '31.0';
    /**
    * Whether upper set temp should be shown on controls - Auto/Setback
    */
    showUpperSetTemp = false;
    /**
     * Whether lower set temp should be shown on controls - Almost always
     */
    showLowerSetTemp = false;

    // what type of set temp does this unit support
    setTempMode:SetTempModeEnum = SetTempModeEnum.disabled;

    // unit field names for upper/lower set temp.
    // consider which setTemp to use based on mode and grouptype
    upperSetTemp:string
    lowerSetTemp:string

    /**
     * Whether extra high and low fan speeds are enabled
     */
    fan_extra_low_sw = Presence.default;
    fan_extra_high_sw = Presence.default;

    /**
     * @param gatewayUnitDefinition Some serialized version of a gateway unit.
     * This may have been retrieved from a web service or otherwise may have
     * existed as an object
     */
    constructor(gatewayUnitDefinition = {}) {
        // Deserializer
        Object.keys(gatewayUnitDefinition).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(this, key)) return;
            //if (!this.hasOwnProperty(key)) return;

            this[key] = gatewayUnitDefinition[key];
        });

        // adjust some inconsistencies
        this.handleInconsistencies();

        this.adjustSetTemps();

    }

    handleInconsistencies() {

        // add a few consistency checks to catch bogus data from a ... misbehaving profile
        // if we dont support a mode - dont claim we're IN that mode...
        if (this.isPowerTestRun()) this.setPowerOn();
        if (this.isHeatModeDisabled() && this.isInHeat()) this.setToCoolMode();
        if (this.isDryModeDisabled() && this.isInDry()) this.setToCoolMode();
        if (this.isSetbackModeDisabled() && this.isInSetback()) this.setToCoolMode();
        if (this.isFanModeDisabled() && this.isInFan()) this.setToCoolMode();
        if (this.isAutoModeDisabled() && this.isInAuto()) this.setToCoolMode();

        // handle some inconsistent lossnay profile data..
        if (this.isLCBypassDisabled() && this.isInLCBypass()) this.setToHeatRecoveryVentMode();
        if (this.isLCAutoDisabled() && this.isInLCAuto()) this.setToHeatRecoveryVentMode();

    }

    isPowerOn():boolean {
        // is drive on for this unit?
        return this.power == Power.on;
    }

    isPowerTestRun(): boolean {
        return this.power == Power.testrun;
    }

    // type helpers
    isLossnay():boolean {
        // is this a lossnay unit?
        return this.type == GatewayUnitTwoDigitType.Lossnay;
    }

    isCoil():boolean {
        // is this a coiled unit?
        return this.type == GatewayUnitTwoDigitType.IndoorUnit;
    }

    // coil helper methods

    isHeatModeDisabled():boolean {
        // if we are cooling only - then we dont support heat
        return this.ic_kind == IcKind.cooling_only
    }

    isDryModeDisabled():boolean {
        // based on dry_mode_sw
        return this.dry_mode_sw == Presence.disabled
    }

    isFanModeDisabled():boolean {
        // based on fan_mode_sw
        return this.fan_mode_sw == Presence.disabled
    }

    isSetbackModeDisabled():boolean {
        // based on setback_mode_sw
        return this.SetbackModeSW == Presence.disabled
    }

    isAutoModeDisabled():boolean {
        // based on AutoModeSW
        return this.auto_mode == AutoMode.disabled
    }

    isDualModeAuto() {
        // do we support dual mode auto?
        return (this.auto_mode == AutoMode.dual_setpoint) && (this.GroupType == Presence.enabled)
    }

    setPowerOn() {
        this.power = Power.on;
    }

    isInThisMode(mode: Mode): boolean {
        // is this unit in this mode?
        
        if (mode == Mode.heat) return this.isInHeat();
        if (mode == Mode.cool) return this.isInCool();
        if (mode == Mode.dry) return this.isInDry();
        if (mode == Mode.fan || mode == Mode.unknown) return this.isInFan();
        if (mode == Mode.defrost) return this.mode == Mode.defrost;
        if ([Mode.auto, Mode.auto_cool, Mode.auto_heat, Mode.dual_auto].includes(mode)) return this.isInAuto();
        if ([Mode.setback, Mode.setback_cool, Mode.setback_heat].includes(mode)) return this.isInSetback();

        // should never be here.
        throw new Error("Unkonwn Mode");

        return false;
    }

    baseMode():Mode {
        // return the base mode - usually the same but auto/setback have variants
        if (this.isInAuto()) return Mode.auto;
        if (this.isInSetback()) return Mode.setback
        return this.mode;
    }

    isSingleAuto():boolean {
        // does this unit support auto single set point?
        // note this does not tell you the unit is IN auto mode!
        return this.auto_mode === AutoMode.single_setpoint;
    }

    isDualAuto():boolean {
        // does this unit support auto dual set point?
        // note this does not tell you the unit is IN auto mode!
        return this.auto_mode === AutoMode.dual_setpoint;
    }

    // coil mode helpers

    isInHeat():boolean {
        // are we in mode heat?
        return this.mode == Mode.heat;
    }

    isInCool():boolean {
        // are we in cool mode?
        return this.mode == Mode.cool;
    }

    isInDry():boolean {
        // are we in dry mode?
        return this.mode == Mode.dry;
    }
    
    isInFan():boolean {
        // are we in fan mode?
        return this.mode == Mode.fan;
    }

    isInAuto() {
        return [
            Mode.auto,
            Mode.auto_cool,
            Mode.auto_heat,
            Mode.dual_auto
        ].includes(this.mode);
    }

    isInSetback() {
        return [
            Mode.setback,
            Mode.setback_cool,
            Mode.setback_heat
        ].includes(this.mode);
    }

    isInThisVentMode(ventMode:VentMode):boolean {
        if (ventMode === VentMode.bypass) return this.isInLCBypass()
        if (ventMode === VentMode.heat_recovery) return this.isInLCHeatRecovery()
        if (ventMode === VentMode.auto) return this.isInLCAuto()

        // should not get here.
        throw new Error("Unkonwn VentMode");
        
        return false;
    }

    // lossnay helpers
    isLCBypassDisabled() {
        // is lossnay bypass mode disabled? based on lc_byass_sw
        return this.lc_bypass_sw != Presence.enabled;
    }

    isLCAutoDisabled() {
        // is lossnay auto mode disabled?  basedon lc_auto_sw
        return this.lc_auto_sw != Presence.enabled;
    }

    isInLCBypass():boolean {
        // are we in lossnay bypass mode?
        return this.vent_mode == VentMode.bypass;
    }

    isInLCHeatRecovery():boolean {
        // are we in lossnay heat recovery mode?
        return this.vent_mode == VentMode.heat_recovery;
    }

    isInLCAuto():boolean {
        // are we in lossnay auto mode?
        return this.vent_mode == VentMode.auto;
    }

    // air direction helpers

    isAirDirectionStationaryDisabled():boolean {
        // is stationary option disabled?
        // based on air_direction_sw - if its not 'disabled' - then we hvae 4 or 5 stages and stationary is out
        return this.air_direction_sw != AirDirectionStages.disabled;
    }

    isAirDirectionCeilingDisabled():boolean {
        // is ceiling air direction disabled? if stationary isn't disabled then yes ceiling is disabled
        return !this.isAirDirectionStationaryDisabled()
    }

    isAirDirectionHighDisabled():boolean {
        // is high air direction disabled? if stationary isn't disabled OR we are only 4 stage then yes its disabled
        return !this.isAirDirectionStationaryDisabled() || (this.air_direction_sw == AirDirectionStages.four_stages) 
    }
 
    isAirDirectionMiddleDisabled():boolean {
        // is middle direction disabled? if stationary isn't disalbed then yes middle is disabled.
        return !this.isAirDirectionStationaryDisabled()
    }
 
    isAirDirectionLowDisabled():boolean {
        // is low direction disabled? if stationary isn't disabled then yes low is disabled
        return !this.isAirDirectionStationaryDisabled()
    }

    isAirDirectionFloorDisabled():boolean {
        // is floor direction disabled if stationary isn't disalbed then yes floor is disabled
        return !this.isAirDirectionStationaryDisabled()
    }

    isAirDirectionSwingDisabled():boolean {
        // is swing mode disabled? if stationary isn't disabled OR swing mode is disabled then yes its disabled
        return !this.isAirDirectionStationaryDisabled() || this.swing_sw == Presence.disabled;
    }

    isAirDirectionAutoDisabled():boolean { 
        // is auto mode disabled? if stationary ins't disabled OR no auto support then yes its disabled
        return !this.isAirDirectionStationaryDisabled() || this.air_direction_auto == Presence.disabled
    }

    // air direction mode helpers

    isInAirDirectionStationary():boolean {
        // this is only true when we dont support an air_direction
        return this.air_direction_sw == AirDirectionStages.disabled
    }

    isInAirDirectionCeiling():boolean {
        // are we in ceiling air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.ceiling;
    }

    isInAirDirectionHigh():boolean {
        // are we in high air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.high;
    }

    isInAirDirectionMiddle():boolean {
        // are we in middle air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.middle;
    }

    isInAirDirectionLow():boolean {
        // are we in low air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.low;
    }

    isInAirDirectionFloor():boolean {
        // are we in floor air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.floor;
    }

    isInAirDirectionSwing():boolean {
        // are we in swing air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.swing;
    }

    isInAirDirectionAuto():boolean {
        // are we in auto air_direction mode?
        return this.air_direction_sw != AirDirectionStages.disabled && this.air_direction == AirDirection.auto;
    }

    isNewGroupType():boolean {
        // are we using extended temp settings?
        return this.GroupType === Presence.enabled
    }

    isOldGroupType():boolean {
        // are we using the single SetTemp value?
        return this.GroupType === Presence.disabled
    }

    // set temp helper

    adjustSetTemps() {
        // configure SetTempMode and upper/lower Set Temp field names
        this.setTempMode = SetTempModeEnum.disabled

        // these should go.  update site schedule to use setTempMode
        this.showLowerSetTemp = false;
        this.showUpperSetTemp = false;
        // these should go.  update site schedule to use setTempMode

        if (this.isInSetback()) {
        
            this.setTempMode = SetTempModeEnum.dual
            this.upperSetTemp = this.SetTemp4;
            this.lowerSetTemp = this.SetTemp5;

            // these should go.  update site schedule to use setTempMode
            this.showLowerSetTemp = true;
            this.showUpperSetTemp = true;
            // these should go.  update site schedule to use setTempMode

        } else if (this.GroupType === Presence.enabled) {
            if (this.isInAuto()) {
                if (this.auto_mode === AutoMode.single_setpoint) {
                    this.setTempMode = SetTempModeEnum.single;
                    this.lowerSetTemp = this.SetTemp3;
                    // these should go.  update site schedule to use setTempMode
                    this.showLowerSetTemp = true;
                    // these should go.  update site schedule to use setTempMode
                } else if (this.auto_mode === AutoMode.dual_setpoint) {
                    this.setTempMode = SetTempModeEnum.dual
                    this.upperSetTemp = this.SetTemp1;
                    this.lowerSetTemp = this.SetTemp2;
                    // these should go.  update site schedule to use setTempMode
                    this.showLowerSetTemp = true;
                    this.showUpperSetTemp = true;
                    // these should go.  update site schedule to use setTempMode
                }
            } else if ([Mode.cool, Mode.dry].includes(this.mode)) {
                this.setTempMode = SetTempModeEnum.single;
                this.upperSetTemp = this.SetTemp1;
                // these should go.  update site schedule to use setTempMode
                this.showUpperSetTemp = true;
                // these should go.  update site schedule to use setTempMode
            } else if (Mode.heat === this.mode) {
                this.setTempMode = SetTempModeEnum.single;
                this.lowerSetTemp = this.SetTemp2;
                // these should go.  update site schedule to use setTempMode
                this.showLowerSetTemp = true
                // these should go.  update site schedule to use setTempMode
            }
        } else {
            // old GT - everything is SetTemp
            this.setTempMode = SetTempModeEnum.single;
            this.lowerSetTemp = this.SetTemp;
            this.upperSetTemp = this.SetTemp;
            // these should go.  update site schedule to use setTempMode
            this.showLowerSetTemp = true;
            // these should go.  update site schedule to use setTempMode
        }
    }

    // subscription features from the active subscription for this units gateway
    subscription_features = {};
    // gateway model type of controlling gateway - only valid if type is ODU
    gateway_model_class_name: string;

    assertEqual(a: string, b: string) {
        if(a == b) {
            return true;
        }

        throw `AssertEqual Failed (${a} != ${b})`;
    }

    setToCoolMode() {
        // force this unit to be in 'cool' mode.
        this.mode = Mode.cool
    }

    setToHeatRecoveryVentMode() {
        // force this vent_mode to be in 'heat_recovery' mode.
        this.vent_mode = VentMode.heat_recovery;
    }

    update(from: GatewayUnit, updateControlAttributes = false) {
        // update this unit with properties/values from the provided unit.
        // generally used during reception of profile updates.
        // updateControlAttributes:  should the gatewayUnits.controlAttributes also be copied in.

        // first - make sure a few things that should be impossible.
        // these really can't change...
        this.assertEqual(from.group_id, this.group_id);
        this.assertEqual(from.type, this.type);

        if (updateControlAttributes) {
            // copy 'em
            GatewayUnit.controlAttributes.forEach((control) => this[control] = from[control] || this[control]);
        }

        // copy the rest - non control atributes
        this.air_direction_sw = from.air_direction_sw || this.air_direction_sw;
        this.auto_mode = from.auto_mode || this.auto_mode;
        this.deadband_sw = from.deadband_sw || this.deadband_sw;
        this.dry_mode_sw = from.dry_mode_sw || this.dry_mode_sw;
        this.fan_mode_sw = from.fan_mode_sw || this.fan_mode_sw;
        this.fan_speed_sw = from.fan_speed_sw || this.fan_speed_sw;
        this.fan_auto_sw = from.fan_auto_sw || this.fan_auto_sw;
        this.ic_kind = from.ic_kind || this.ic_kind;
        this.inlet_temp = from.inlet_temp || this.inlet_temp;
        this.lc_auto_sw = from.lc_auto_sw || this.lc_auto_sw;
        this.lc_bypass_sw = from.lc_bypass_sw || this.lc_bypass_sw;
        this.lc_fan_auto_sw = from.lc_fan_auto_sw || this.lc_fan_auto_sw;
        this.lc_fan_speed_sw = from.lc_fan_speed_sw || this.lc_fan_speed_sw;
        this.lc_humid_sw = from.lc_humid_sw || Presence.disabled;
        this.fan_extra_low_sw = from.fan_extra_low_sw || Presence.disabled;
        this.fan_extra_high_sw = from.fan_extra_high_sw || Presence.disabled;
        this.swing_sw = from.swing_sw || this.swing_sw;
        this.MultiOverRide = from.MultiOverRide || this.MultiOverRide;
        this.GroupType = from.GroupType || this.GroupType;
        this.air_direction_auto = from.air_direction_auto || Presence.default
        this.SetbackModeSW = from.SetbackModeSW || Presence.disabled
        this.bus_address = from.bus_address

        this.AutoMin = from.AutoMin || Temp.zero
        this.AutoMax = from.AutoMax || Temp.hundred
        this.CoolMin = from.CoolMin || Temp.zero
        this.CoolMax = from.CoolMax || Temp.hundred
        this.HeatMin = from.HeatMin || Temp.zero
        this.HeatMax = from.HeatMax || Temp.hundred

        this.updateMinMaxPoints(from);

        // deadband special handler - cause its a number here but a string from the api
        if (typeof(from.deadband) == 'string' && from.deadband != '') this.deadband = parseFloat(from.deadband);
        else this.deadband = from.deadband || this.deadband;

        // refresh last update
        this.last_updated = new Date();

        this.isProfileReady = true;

        this.handleInconsistencies();

        this.adjustSetTemps();

    }

    updateMinMaxPoints(from) {
        // update the minMaxPoints structure from the member min/max values
        
        this.minMaxPoints.cool = {
            min: parseFloat(from.CoolMin), max: parseFloat(from.CoolMax)
        };

        // a unit in dry mode should use the cool modes min/max values
        this.minMaxPoints.dry = this.minMaxPoints.cool;

        this.minMaxPoints.heat = {
            min: parseFloat(from.HeatMin), max: parseFloat(from.HeatMax)
        };

        this.minMaxPoints.auto = {
            min: parseFloat(from.AutoMin), max: parseFloat(from.AutoMax)
        };

        this.minMaxPoints.setback = {
            // this is a copy of auto
            min: parseFloat(from.AutoMin), max: parseFloat(from.AutoMax)
        }

    }

}
