import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'select-mode-modal',
  templateUrl: './select-mode.page.html',
  styleUrls: ['./select-mode.page.scss'],
})
export class SelectTestRunMode /*implements OnInit*/ {
  @Input() parentSelectedName: string;

  ModeList = TEST_RUN_MODE;

  constructor(private modalController: ModalController) { }

  // ngOnInit() {
  // }
  
  setClickedSelection(selectMode: any) {

    this.modalController.dismiss({
      parentSelectedName: selectMode,
    });
  }

  close() {
    this.modalController.dismiss({
      parentSelectedName: this.parentSelectedName,
    });
  }

}

const TEST_RUN_MODE = [
  'Auto',
  'Cooling',
  'Heating'
];
