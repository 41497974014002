<ion-content class="ion-no-padding" main-white-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="white-background">
    <ion-title color="dark">
      <h1>Payment History <ion-badge *ngIf="!loading && !noInvoices" class="invoicesBadge">{{filteredInvoices.length}}</ion-badge></h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/account', user?.id, 'details']" (click)="returnToAccountDetail()" me-button-as-link
        id="btnBackToAccountDetail" name="btnBackToAccountDetail">
        &#8592;&nbsp;Back to Account Details </ion-button>
    </ion-buttons>
  </ion-toolbar>
  
  <div *ngIf="loading">
    <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i>
    </ion-label>
    <ion-spinner name="dots"></ion-spinner>
  </div>
  
  <ion-row *ngIf="!loading && noInvoices">
    <ion-col>
      <ion-label id="noPaymentHistoryMessage" color="medium"><i>There is no payment history on file with your
          account.</i></ion-label>
    </ion-col>
  </ion-row>
  
  <ion-row class="invoiceSearchBarRow">
    <ion-searchbar *ngIf="!loading && !noInvoices && !invoicesLoading" #invoiceSearchBar
      (ionInput)="searchFilterInvoices($event)" placeholder="Search..." class="me-search-bar">
    </ion-searchbar>
  </ion-row>
  
  <ion-grid *ngIf="!loading && !noInvoices" class="paymentHistoryGrid paymentHistoryTable me-grid-main" #paymentHistoryGrid>
    <ion-row class="paymentHistoryRow tableHeaderRow">
      <ion-col class="paymentHistoryColumn"><ion-icon name="ticket-outline"></ion-icon> Invoice #</ion-col>
      <ion-col class="paymentHistoryColumn siteNameCol"><ion-icon name="business-outline"></ion-icon> Site Name</ion-col>
      <ion-col class="paymentHistoryColumn"><ion-icon name="calendar-outline"></ion-icon> Date</ion-col>
      <ion-col class="paymentHistoryColumn"><ion-icon name="ellipsis-horizontal-outline"></ion-icon> Payment Status</ion-col>
      <ion-col class="paymentHistoryColumn"><ion-icon name="cash-outline"></ion-icon> Amount</ion-col>
      <ion-col class="paymentHistoryColumn iconCol"><ion-icon name="eye-outline"></ion-icon> View</ion-col>
      <ion-col class="paymentHistoryColumn iconCol"><ion-icon name="cloud-download-outline"></ion-icon> Download</ion-col>
    </ion-row>
    <ion-grid class="paymentHistoryScrollContainer" #paymentHistoryScrollContainer>
      <ion-row *ngFor="let invoice of filteredInvoices; let i = index; trackBy:trackByInvoiceId" class="paymentHistoryRow tableEntryRow">
        <ion-col class="paymentHistoryColumn">#{{invoice.number}}</ion-col>
        <ion-col *ngIf="invoice.link_enabled" class="paymentHistoryColumn linkLike siteNameCol" tappable (click)="navigateToSite( invoice.site_id )" title="{{invoice.title}}"><span class="siteName">{{invoice.site_name}}</span></ion-col>
        <ion-col *ngIf="!invoice.link_enabled" class="paymentHistoryColumn siteNameCol" title="{{invoice.title}}"><span class="siteName">{{invoice.site_name}}</span></ion-col>
        <ion-col class="paymentHistoryColumn" title="{{invoice.full_date}}">{{invoice.date}}</ion-col>
        <ion-col class="paymentHistoryColumn"><ion-button disabled="true" strong="true" class="paymentStatus">{{invoice.status | titlecase}}</ion-button></ion-col>
        <ion-col class="paymentHistoryColumn">{{ invoice.total | currency }}</ion-col>
        <ion-col class="paymentHistoryColumn linkIconSize iconCol" tappable (click)="viewInvoice(invoice.id, invoice.site_id)" title="View Invoice"><ion-icon  name="eye" class="tableButton"></ion-icon></ion-col>
        <ion-col class="paymentHistoryColumn linkIconSize iconCol" tappable (click)="downloadInvoice(invoice.id, invoice.site_id)" title="Download Invoice"><ion-icon name="cloud-download" class="tableButton"></ion-icon></ion-col>
      </ion-row>
      <ion-row *ngIf="noResultsFound" id="noResultsFound" class="paymentHistoryRow tableEntryRow noResultsFound">
        <ion-col id="noResults" class="paymentHistoryColumn">Your search with "{{ searchTerm }}" returned no results. Please try again.</ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>

  <ion-row *ngIf="invoicesLoading">
    <ion-col class="invoicesLoadingCol">
      <ion-spinner color="primary" me-spinner-icon name="lines"></ion-spinner>
      <ion-label class="invoicesLoadingMessage">Loading more invoices...</ion-label>
    </ion-col>
  </ion-row>

  <!-- <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll> -->
</ion-content>