/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Platform,
  PopoverController,
  ModalController,
  LoadingController,
  AnimationController,
} from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './common/services/user/user.service';
import { Animations } from './constants/animations';
import { KenzaCloudMenuIds, EnableDisableEnum, LevelEnum, AppMessageCode, AppMessageTypeCode } from './enumerations/enums';
import { MenuController } from '@ionic/angular';
import { UserActionsComponent } from './features/user-actions/components/user-actions.component';
import { Site } from './features/sites/classes/site';
import { SiteSelectPage } from './features/sites/components/site-select/site-select.page';
import { Subscription } from 'rxjs';
import { Logger } from './common/services/logging/log.service';
import { SiteService } from './features/sites/services/site.service';
import { MainSiteUIService } from './common/services/ui/main-site-ui.service';
import { SiteAlertFilter } from './common/classes/filters';
import { ToastrService } from 'ngx-toastr';
import {
  devEnv,
  TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE,
  TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
} from './constants/kenzaconstants';
import { LeftNavMenuState } from './common/classes/commonclasses';
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, Title, DoughnutController, ArcElement, LineController, Tooltip, Legend, TimeSeriesScale, TimeScale, Filler } from 'chart.js';
import { AppAuthenticationService } from './common/services/authentication/app-authentication.service';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppStorageService } from './common/services/storage/app-storage.service';
import { globalFunctions } from './constants/globalFunctions';
import { ConnectionService } from './common/services/connection/connection.service';
import { NotificationsComponent } from './features/user-actions/components/notifications/notifications.component';
import { register as swiper_register } from 'swiper/element/bundle';

Chart.register(CategoryScale, LinearScale, LineElement, PointElement, Title, LineController, Tooltip, DoughnutController, ArcElement, Legend, TimeScale, TimeSeriesScale, Filler);
swiper_register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animations.slideUpDown],
})

export class AppComponent implements OnInit, OnDestroy {

  globalFunctions = globalFunctions;
  onSetActiveSite: Subscription;
  onActiveSiteChangedSubscription: Subscription;
  onUpdateSiteMetricsSubscription: Subscription;
  onActiveSiteChanged: Subscription;
  onRefreshSiteAlertData: Subscription;
  onViewAccountDetailSubscription: Subscription;
  onViewMemberAccountDetailSubscription: Subscription;
  onViewSiteAlertSubscription: Subscription;
  //  onviewGatewaySubscriptionPlansSubscription: Subscription;
  //  onviewAccountPaymentMethodsSubscription: Subscription;
  onDisableLeftNavigation: Subscription;
  onApplyCustomSiteAlertFilter: Subscription;
  onRefreshBrowserSubscription: Subscription;

  showSiteSubMenus = false;
  showAlertsSubMenus = false;
  disableAlertsSubMenuItems = true;
  disableSiteSubMenuItems = false;
  disableDashboardMenuItems = false;
  disableSettingsMenuItems = false;
  disableManageMenuItems = false;
  showSettingsSubMenus = false;
  showDashboardSubMenus = false;
  showManageSubMenus = false;
  kCloudMenuIds = KenzaCloudMenuIds;
  isConnectedToInternet = true;
  expanded: any = { user: false };
  maxSiteSelectionMenuButtonText = 25;
  dimBackground = false;
  fillerText = '';
  selectedMainMenuId: number;
  selectedSubMenuId: number;
  selectedSite: Site;
  selectedSiteId = '';
  internetConnectionLoader: any;
  disableSideNav: boolean;
  mainMenuInView: KenzaCloudMenuIds;

  leftNavMenuState: LeftNavMenuState;
  levelEnum = LevelEnum;

  userSiteList: Site[] = [];
  selectedSiteInfo: Site = new Site({
    id: "default-site",
    name: "Select Site",
    phone: "",
    company: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    site_photo_name: "",
    sitestatustype_id: 1,
    current_owner_id: '',
    locations: []
  });

  siteAlertFilter: SiteAlertFilter = {
    site_id: '',
    StatusActive: true,
    StatusHistorical: false,
    AlertEquipment: true,
    NotificationMembership: true,
    NotificationGateway: true,
    NotificationSubscription: true,
    NotificationPayments: true,
    NotificationEquipment: true,    
    UserLevelEnum: LevelEnum.Viewer,
  };

  uiState = {
    showStatusGroup: true,
    showAlertsGroup: true,
    showNotificationsGroup: true,
  };

  siteID = null;
  devEnv = devEnv;
  systemStatusMessage;
  notificationsCount = 0;
  userActionsOpen = false;
  notificationsOpen = false;

  constructor(
    public user: UserService,
    public appAuth: AppAuthenticationService,
    private router: Router,
    private platform: Platform,
    private statusBar: StatusBar,
    private animationCtrl: AnimationController,
    private popoverCtrl: PopoverController,
    public modalController: ModalController,
    public menuController: MenuController,
    public logger: Logger,
    public siteService: SiteService,
    public mainSiteUIService: MainSiteUIService,
    private connectionService: ConnectionService,
    private loadingController: LoadingController,
    public toastService: ToastrService,
    public location: Location,
    private appStorageService: AppStorageService,
  ) {
    this.systemStatusMessage = this.mainSiteUIService.systemStatusMessage;
    this.disableSideNav = true;
    this.initializeApp();
    this.leftNavMenuState = new LeftNavMenuState();

    this.onRefreshBrowserSubscription = router.events.subscribe((event) => {
      
      if (this.user && this.user.active) {
        this.siteID = this.user.active.id;
      } else if (window.location.href.includes(`site`)) {
        this.siteID = window.location.href.substring(window.location.href.indexOf(`/site/`), window.location.href.lastIndexOf(`/`)).replace(`/site/`, ``);
      } else {
        this.siteID = null;
      }
      if (event instanceof NavigationStart) {
        if (!router.navigated) {
          // then we are in a browser refresh case...
          const leftNavMenuState = this.get_left_nav_state_from_local_storage();
          if (leftNavMenuState) {
            this.selectedSiteInfo = leftNavMenuState.selectedSiteInfo;
            this.selectedSiteId = leftNavMenuState.selectedSiteInfo.id;
          }
        }
      }
    });

    this.connectionService.getMonitor().subscribe((isConnected) => {
      this.isConnectedToInternet = isConnected;
      if (this.isConnectedToInternet) {
        this.siteService.isConnectedToInternet = true;
        this.user.set_signed_out_while_disconnected(false);
        this.set_left_nav_ui_state();
      } else {
        this.get_left_nav_ui_state();
        this.mainSiteUIService.clearNotifications();
        this.siteService.isConnectedToInternet = false;
        this.mainSiteUIService.disableLeftNavigation(EnableDisableEnum.Disable);
        this.toastService.error(
          TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE,
          TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE,
          TOAST_CONFIG_FULL_WIDTH
        );
        this.clearCognitoDataFromLocalStorage();
      }
    });

    this.onApplyCustomSiteAlertFilter = this.mainSiteUIService.applyCustomSiteAlertFilterEmitter.subscribe(
      (customSiteFilter) => {
        this.siteAlertFilter = customSiteFilter;
      }
    );

    this.onActiveSiteChangedSubscription = this.user.activeSiteAndNavigationChanged.subscribe(
      (activeSiteDetails: any) => {
        this.handleActiveSiteChange(activeSiteDetails.activeSite);
      }
    );

    this.onActiveSiteChanged = this.user.activeSiteChanged.subscribe(
      (activeSite: Site) => {
        if (activeSite) {
          this.handleActiveSiteChange(activeSite);
        } else {
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Home,
            KenzaCloudMenuIds.NoSelection
          );
        }

        this.save_left_nav_state_to_local_storage();

        if (this.location.path().endsWith('monitor')) {
          const hasPerm = this.appAuth.doesLevelHavePermission(this.user.activeSiteUserLevel, this.appAuth.permissionEnums.ViewSiteMonitoring);
          if (!hasPerm) {
            this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
          }
        } else if (this.location.path().endsWith('analytics')) {
          const hasPerm = this.appAuth.doesLevelHavePermission(this.user.activeSiteUserLevel, this.appAuth.permissionEnums.ViewSiteAnalytics);
          if (!hasPerm) {
            this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
          }
        }

        // Hack to get URL rewrites working when switching sites on site/control, monitor, analytics, and alerts
        const path = this.location.path();
        if (path.startsWith("/site") && !path.endsWith("dashboard")) {
          const pathSegments = path.split("/");
          if (pathSegments.length == 4) {
            pathSegments[2] = activeSite.id;
            this.location.go(pathSegments.join("/"))
          }
        }
      }
    );

    this.onViewAccountDetailSubscription = this.mainSiteUIService.viewAccountDetailEmitter.subscribe(
      () => {
        this.handleMenuItemSelection(
          KenzaCloudMenuIds.AccountDetail,
          KenzaCloudMenuIds.NoSelection
        );
      }
    );

    this.onViewMemberAccountDetailSubscription = this.mainSiteUIService.viewMemberAccountDetailEmitter.subscribe(
      () => {
        this.handleMenuItemSelection(
          KenzaCloudMenuIds.MemberAccountDetail,
          KenzaCloudMenuIds.NoSelection
        );
      }
    );

    // this.onviewGatewaySubscriptionPlansSubscription = this.mainSiteUIService.viewGatewaySubscriptionPlansEmitter.subscribe(
    //   () => { }
    // );

    // this.onviewAccountPaymentMethodsSubscription = this.mainSiteUIService.viewAccountPaymentMethodsEmitter.subscribe(
    //   () => { }
    // );

    this.onViewSiteAlertSubscription = this.mainSiteUIService.viewSiteAlertEmitter.subscribe(
      (navDirection) => {
        if (!navDirection) return;

        switch (navDirection) {
          case 'toEquipmentAlerts':
            this.disableAlertsSubMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.EquipmentAlert,
              KenzaCloudMenuIds.NoSelection
            );
            break;

          case 'toTestRunHistory':
            this.disableSiteSubMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Home,
              KenzaCloudMenuIds.NoSelection
            );   
            break;     

          case 'toSiteNotifications':
            this.disableAlertsSubMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.SiteNotification,
              KenzaCloudMenuIds.NoSelection
            );
            break;

          case 'from':
            this.disableAlertsSubMenuItems = false;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Alerts,
              KenzaCloudMenuIds.NoSelection
            );
            break;

          case 'siteAlerts':
            this.disableAlertsSubMenuItems = false;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Alerts,
              KenzaCloudMenuIds.Alerts
            );
            break;

          case 'toGatewayDetail':
            this.disableDashboardMenuItems = true;
            this.disableSiteSubMenuItems = true;
            this.disableAlertsSubMenuItems = true;
            this.disableSettingsMenuItems = true;
            this.disableManageMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.GatewayDetail
            );
            break;

          case 'fromGatewayDetail':
            this.disableDashboardMenuItems = false;
            this.disableSiteSubMenuItems = false;
            this.disableAlertsSubMenuItems = false;
            this.disableSettingsMenuItems = false;
            this.disableManageMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Site,
              KenzaCloudMenuIds.SiteGateways
            );
            break;

          case 'toMemberDetail':
            this.disableSiteSubMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.MemberAccountDetail,
              KenzaCloudMenuIds.NoSelection
            );
            break;

          case 'toSiteMemberDetail':
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Site,
              KenzaCloudMenuIds.MemberAccountDetail
            );
            break;

          case 'toSiteAnalytics':
            this.disableSiteSubMenuItems = false;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Site,
              KenzaCloudMenuIds.SiteAnalytics
            )
            break;

          case 'fromSiteMemberDetail':
            this.disableSiteSubMenuItems = false;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Site,
              KenzaCloudMenuIds.SiteMembers
            );
            break;

          case 'fromMemberDetail':
            this.disableSiteSubMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Home,
              KenzaCloudMenuIds.NoSelection
            );
            break;

          case 'toManageMemberDetail':
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.MemberAccountDetail
            );
            break;


          case 'showManageMemberDetail':
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.MemberDetail
            );
            break;

          case 'fromManageMemberDetail':
            this.disableSiteSubMenuItems = false;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.ManageMembers
            );
            break;

          case 'toManageGatewayDetail':
            this.disableDashboardMenuItems = true;
            this.disableSiteSubMenuItems = true;
            this.disableAlertsSubMenuItems = true;
            this.disableSettingsMenuItems = true;
            this.disableManageMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.GatewayDetail
            );
            break;

          case 'fromManageGatewayDetail':
            this.disableDashboardMenuItems = false;
            this.disableSiteSubMenuItems = false;
            this.disableAlertsSubMenuItems = false;
            this.disableSettingsMenuItems = false;
            this.disableManageMenuItems = true;
            this.handleMenuItemSelection(
              KenzaCloudMenuIds.Manage,
              KenzaCloudMenuIds.ManageGateways
            );
            break;
        }
      }
    );

    this.mainSiteUIService.generalNavChangeEmitter.subscribe((e) => {

      const leftNavMenuState: LeftNavMenuState = this.get_left_nav_state_from_local_storage();

      if (!e.parent && !e.child) {
        return;
      } else {
        this.handleMenuItemSelection(e.parent, e.child);
      }
    });

    this.onDisableLeftNavigation = this.mainSiteUIService.disableLeftNavigationEmitter.subscribe(
      (enableDisable: EnableDisableEnum) => {
        switch (enableDisable) {
          case EnableDisableEnum.Enable:
            this.disableDashboardMenuItems = false;
            this.disableSiteSubMenuItems = false;
            this.disableAlertsSubMenuItems = false;
            this.disableSettingsMenuItems = false;
            this.disableManageMenuItems = true;

            break;

          case EnableDisableEnum.Disable:
            this.disableDashboardMenuItems = true;
            this.disableSiteSubMenuItems = true;
            this.disableAlertsSubMenuItems = true;
            this.disableSettingsMenuItems = true;
            this.disableManageMenuItems = true;

            break;
        }
      }
    );

    this.user.setApp(this);
  }

  clearCognitoDataFromLocalStorage() {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key && key.startsWith(`CognitoIdentityServiceProvider`)) {
          localStorage.removeItem(key);
      }
    }
  }

  async ngOnInit() {
    this.user.setActiveSite(this.selectedSiteInfo, true);
    this.selectedMainMenuId = KenzaCloudMenuIds.NoSelection;
    this.selectedSubMenuId = KenzaCloudMenuIds.NoSelection;
  }

  async showNotifications(ev: any) {
    if (!this.siteService.handleIsConnected()) {
      return;
      // this.user.set_signed_out_while_disconnected(true);
      // this.user.auth.signOut();
      // this.user.reset();
    } else {
      // Show Notifications
      if (this.notificationsOpen == true) return;
      if (this.dimBackground) this.menuController.close();
      this.expand('user');

      // const enterAnimation = this.animationCtrl.create()
      //   .duration(250)
      //   .easing('cubic-bezier(0.36,0.66,0.04,1)')
      //   .fromTo('transform', 'translateY(-100%)', 'translateY(0%)');

      // const leaveAnimation = this.animationCtrl.create()
      //   .duration(250)
      //   .easing('cubic-bezier(0.36,0.66,0.04,1)')
      //   .fromTo('transform', 'translateY(0%)', 'translateY(-100%)');

      const popover = await this.popoverCtrl.create({
        event: ev,
        arrow: true,
        animated: true,
        id: `appNotifs`,
        keepContentsMounted: true,
        component: NotificationsComponent,
        // enterAnimation: enterAnimation,
        // leaveAnimation: leaveAnimation,
        // htmlAttributes: {
        //   [`keep-contents-mounted`]: true
        // }
      } as PopoverOptions);

      popover.onWillDismiss().then(() => {
        // document.querySelector(`#appNotifs`).classList.add(`slidingUp`);
        this.expanded.user = false;
        this.notificationsOpen = false;
        // document.querySelector(`#appNotifs`).classList.remove(`slidingUp`);
      });

      this.notificationsOpen = true;

      return await popover.present();
    }
  }

  ionViewWillLeave() {
    // this.notificationsSubscription.unsubscribe();
    // this.notificationsSubscription = null;
  }

  ngOnDestroy() {
    if (this.onSetActiveSite) {
      this.onSetActiveSite.unsubscribe();
    }

    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
    }

    if (this.onUpdateSiteMetricsSubscription) {
      this.onUpdateSiteMetricsSubscription.unsubscribe();
    }

    if (this.onActiveSiteChanged) {
      this.onActiveSiteChanged.unsubscribe();
    }

    if (this.onRefreshSiteAlertData) {
      this.onRefreshSiteAlertData.unsubscribe();
    }

    if (this.onViewAccountDetailSubscription) {
      this.onViewAccountDetailSubscription.unsubscribe();
    }

    if (this.onViewMemberAccountDetailSubscription) {
      this.onViewMemberAccountDetailSubscription.unsubscribe();
    }

    if (this.onViewSiteAlertSubscription) {
      this.onViewSiteAlertSubscription.unsubscribe();
    }

    // if (this.onviewGatewaySubscriptionPlansSubscription) {
    //   this.onviewGatewaySubscriptionPlansSubscription.unsubscribe();
    // }

    // if (this.onviewAccountPaymentMethodsSubscription) {
    //   this.onviewAccountPaymentMethodsSubscription.unsubscribe();
    // }

    if (this.onDisableLeftNavigation) {
      this.onDisableLeftNavigation.unsubscribe();
    }

    if (this.onApplyCustomSiteAlertFilter) {
      this.onApplyCustomSiteAlertFilter.unsubscribe();
    }
  }


  get_left_nav_ui_state(): LeftNavMenuState {

    this.leftNavMenuState.selectedSiteInfo = this.selectedSiteInfo;

    this.leftNavMenuState.selectedMainMenuId = this.selectedMainMenuId;
    this.leftNavMenuState.selectedSubMenuId = this.selectedSubMenuId;

    this.leftNavMenuState.showDashboardSubMenus = this.showDashboardSubMenus;
    this.leftNavMenuState.disableDashboardMenuItems = this.disableDashboardMenuItems;

    this.leftNavMenuState.showSiteSubMenus = this.showSiteSubMenus;
    this.leftNavMenuState.disableSiteSubMenuItems = this.disableSiteSubMenuItems;

    this.leftNavMenuState.showAlertsSubMenus = this.showAlertsSubMenus;
    this.leftNavMenuState.disableAlertsSubMenuItems = this.disableAlertsSubMenuItems;

    this.leftNavMenuState.showManageSubMenus = this.showManageSubMenus;
    this.leftNavMenuState.disableManageMenuItems = this.disableManageMenuItems;

    this.leftNavMenuState.showSettingsSubMenus = this.showSettingsSubMenus;
    this.leftNavMenuState.disableSettingsMenuItems = this.disableSettingsMenuItems;

    this.leftNavMenuState.siteAlertFilter = this.siteAlertFilter;

    return this.leftNavMenuState;
  }

  set_left_nav_ui_state() {

    this.selectedSiteInfo = this.leftNavMenuState.selectedSiteInfo;

    this.selectedMainMenuId = this.leftNavMenuState.selectedMainMenuId;
    this.selectedSubMenuId = this.leftNavMenuState.selectedSubMenuId;

    this.showDashboardSubMenus = this.leftNavMenuState.showDashboardSubMenus;
    this.disableDashboardMenuItems = this.leftNavMenuState.disableDashboardMenuItems;

    this.showSiteSubMenus = this.leftNavMenuState.showSiteSubMenus;
    this.disableSiteSubMenuItems = this.leftNavMenuState.disableSiteSubMenuItems;

    this.showAlertsSubMenus = this.leftNavMenuState.showAlertsSubMenus;
    this.disableAlertsSubMenuItems = this.leftNavMenuState.disableAlertsSubMenuItems;

    this.showManageSubMenus = this.leftNavMenuState.showManageSubMenus;
    this.disableManageMenuItems = this.leftNavMenuState.disableManageMenuItems;

    this.showSettingsSubMenus = this.leftNavMenuState.showSettingsSubMenus;
    this.disableSettingsMenuItems = this.leftNavMenuState.disableSettingsMenuItems;

    this.siteAlertFilter = this.leftNavMenuState.siteAlertFilter;
  }

  set_left_nav_ui_state_from_state(leftNavMenuState: LeftNavMenuState) {

    this.selectedSiteInfo = leftNavMenuState.selectedSiteInfo;

    this.selectedMainMenuId = leftNavMenuState.selectedMainMenuId;
    this.selectedSubMenuId = leftNavMenuState.selectedSubMenuId;

    this.showDashboardSubMenus = leftNavMenuState.showDashboardSubMenus;
    this.disableDashboardMenuItems = leftNavMenuState.disableDashboardMenuItems;

    this.showSiteSubMenus = leftNavMenuState.showSiteSubMenus;
    this.disableSiteSubMenuItems = leftNavMenuState.disableSiteSubMenuItems;

    this.showAlertsSubMenus = leftNavMenuState.showAlertsSubMenus;
    this.disableAlertsSubMenuItems = leftNavMenuState.disableAlertsSubMenuItems;

    this.showManageSubMenus = leftNavMenuState.showManageSubMenus;
    this.disableManageMenuItems = leftNavMenuState.disableManageMenuItems;

    this.showSettingsSubMenus = leftNavMenuState.showSettingsSubMenus;
    this.disableSettingsMenuItems = leftNavMenuState.disableSettingsMenuItems;

    this.siteAlertFilter = leftNavMenuState.siteAlertFilter;
  }

  save_left_nav_state_to_local_storage() {
    const leftNavMenuState: LeftNavMenuState = this.get_left_nav_ui_state();
    leftNavMenuState.saved_to_local_storage = true;
    this.appStorageService.localStorageSetItem('LeftNavMenuState', leftNavMenuState);
  }

  get_left_nav_state_from_local_storage() {

    const local_storage_leftNavMenuState = this.appStorageService.localStorageGetItem('LeftNavMenuState');

    if (local_storage_leftNavMenuState) {
      const leftNavMenuState: LeftNavMenuState = JSON.parse(local_storage_leftNavMenuState);
      return leftNavMenuState;
    }

    return null;
  }

  async presentNoInternetConnection() {
    this.internetConnectionLoader = await this.loadingController.create({
      cssClass: 'me-loader-no-internet-connection',
      spinner: 'lines',
      message: 'No internet connection...',
      backdropDismiss: false,
    });

    await this.internetConnectionLoader.present();

    const { role, data } = await this.internetConnectionLoader.onDidDismiss();

    if (data !== undefined) {
      console.log('Loading dismissed with message:', data);
    } else {
      console.log('Loading dismissed.');
    }
  }

  toggleUIGroupState(groupName: string) {

    console.log('toggleUIGroupState')

    switch (groupName.toLowerCase()) {
      case 'status':
        this.uiState.showStatusGroup = !this.uiState.showStatusGroup;

        break;
      case 'alerts':
        this.uiState.showAlertsGroup = !this.uiState.showAlertsGroup;

        break;
      case 'notifications':
        this.uiState.showNotificationsGroup = !this.uiState
          .showNotificationsGroup;

        break;
    }
  }

  toggleDimBackground() {
    this.dimBackground = !this.dimBackground;
  }

  splitPaneChange() {
    this.dimBackground = false;
  }


  applySiteAlertFilter(e?) {
    if (this.selectedSiteInfo.id !== 'default-site' && this.user.signedIn) {
      this.siteAlertFilter.site_id = this.selectedSiteInfo.id;
      this.siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
      this.mainSiteUIService.refreshSiteAlertData(this.siteAlertFilter, e);
    } else {
      this.mainSiteUIService.clearSiteAlertData();
    }
  }

  hasPermissionForSiteMonitor() {
    return this.appAuth.doesLevelHavePermission(
      this.user.activeSiteUserLevel,
      this.appAuth.permissionEnums.ViewSiteMonitoring
    );
  }

  hasPermissionForSiteAnalytics() {
    return this.appAuth.doesLevelHavePermission(
      this.user.activeSiteUserLevel,
      this.appAuth.permissionEnums.ViewSiteAnalytics
    );
  }

  handleActiveSiteChange(activeSite: Site) {
    this.selectedSiteInfo = activeSite;

    if (!activeSite) {
      this.handleMenuItemSelection(
        KenzaCloudMenuIds.Home,
        KenzaCloudMenuIds.NoSelection
      );
    } else {
      // this is the site now
      this.siteID = activeSite.id;
      switch (this.selectedSubMenuId) {
        case KenzaCloudMenuIds.NoSelection:
        case KenzaCloudMenuIds.Home:
        case KenzaCloudMenuIds.AccountDetail:
        case KenzaCloudMenuIds.AccountGatewaySubscriptionPlans:
        case KenzaCloudMenuIds.AccountPaymentMethods:
        case KenzaCloudMenuIds.Dashboard:
          this.collapseAllMenuItems();
          this.showDashboardSubMenus = true;
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Dashboard,
            KenzaCloudMenuIds.Dashboard
          );
          break;

        case KenzaCloudMenuIds.SiteNotification:
        case KenzaCloudMenuIds.EquipmentAlert:
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Alerts,
            KenzaCloudMenuIds.EquipmentAlert
          );
          break;

        case KenzaCloudMenuIds.GatewayDetail:
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Manage,
            KenzaCloudMenuIds.ManageGateways
          );
          break;

        case KenzaCloudMenuIds.MemberAccountDetail:
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Site,
            KenzaCloudMenuIds.MemberAccountDetail
          );
          break;

        case KenzaCloudMenuIds.ManageGateways:
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Manage,
            KenzaCloudMenuIds.ManageGateways
          );
          break;
        case KenzaCloudMenuIds.ManageMembers:
          this.handleMenuItemSelection(
            KenzaCloudMenuIds.Manage,
            KenzaCloudMenuIds.ManageMembers
          );
          break;
      }
    }
  }

  toggleMenu(e: Event) {

    if (this.user.signedIn) {
      // dont save if we're not online
      if (!this.isConnectedToInternet) return

      this.save_left_nav_state_to_local_storage();

      if (e.composedPath().some((path: any) => path.id === 'sidenav')) return;

      this.menuController.close();

      if (this.mainMenuInView === this.selectedMainMenuId) {
        this.save_left_nav_state_to_local_storage();

        return;
      }

      if (this.mainMenuInView === KenzaCloudMenuIds.Site) {
        this.showSiteSubMenus = false;
      }

      if (this.mainMenuInView === KenzaCloudMenuIds.Manage) {
        this.showManageSubMenus = false;
      }

      this.mainMenuInView = KenzaCloudMenuIds.NoSelection;

      this.save_left_nav_state_to_local_storage();
    }
  }

  async NavigateToSiteAlerts(selectedSite: Site) {
    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

    await this.user.setActiveSite(this.user.active, true);
    await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
    await this.mainSiteUIService.viewSiteAlert('siteAlerts');
  }

  async openSiteSelect() {
    if (!this.siteService.handleIsConnected()) return;

    if (this.dimBackground) this.menuController.close();
    let ignoreSameSiteSelected = false;
    this.userSiteList = this.user.sites;

    switch (this.selectedMainMenuId) {
      case KenzaCloudMenuIds.EquipmentAlert:
      case KenzaCloudMenuIds.SiteNotification:
        ignoreSameSiteSelected = true;
        break;
    }

    const modal = await this.modalController.create({
      component: SiteSelectPage,
      cssClass: 'me-modal-select',
      componentProps: {
        parentIgnoreSameSiteSelected: ignoreSameSiteSelected,
        parentSelectedSiteInfo: this.selectedSiteInfo,
        parentSiteList: this.userSiteList,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.mainSiteUIService.siteSelectionOpen = false;
      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.selectedSite !== undefined &&
        data.data.selectedSite !== null
      ) {
        let newSiteAlertFilter: SiteAlertFilter = null;
        if (data.data.alertFilter == null) {
          newSiteAlertFilter = new SiteAlertFilter();
        } else {
          newSiteAlertFilter = data.data.alertFilter
        }
        const selectedSite: Site = data.data.selectedSite;
        newSiteAlertFilter.site_id = selectedSite.id;
        newSiteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

        this.siteAlertFilter = newSiteAlertFilter;
        this.user.setActiveSite(selectedSite, true);
        this.mainSiteUIService.refreshSiteAlertData(this.siteAlertFilter);
      }
    });
    
    this.mainSiteUIService.siteSelectionOpen = true;
    return await modal.present();
  }

  isUserSiteSelected(): boolean {
    return this.user &&
      this.user.active &&
      (this.user.active.id === 'default-site' ||
        this.selectedMainMenuId == KenzaCloudMenuIds.EquipmentAlert ||
        this.selectedMainMenuId == KenzaCloudMenuIds.SiteNotification)
      ? true
      : false;
  }

  getUserActiveSiteName(limitLength: boolean): string {
    let selectedSiteName = 'Select Site';

    if (this.user && this.user.active) {
      if (
        limitLength &&
        this.user?.active?.name?.length > this.maxSiteSelectionMenuButtonText
      ) {
        selectedSiteName =
          this.user?.active?.name?.substring(
            0,
            this.maxSiteSelectionMenuButtonText - 3
          ) + '...';
      } else {
        selectedSiteName = this.user.active.name;
      }
    }

    return selectedSiteName;
  }
  initializeApp() {
    this.platform.ready().then((success) => {
      if (this.statusBar) this.statusBar.styleDefault();
    }, error => {
      console.log(error, error);
    });
  }

  setOpacity() {
    let result = false;
    if (
      (this.user && this.user.active && this.user.active.id == 'default-site') ||
      this.selectedMainMenuId == KenzaCloudMenuIds.MemberAccountDetail ||
      this.selectedMainMenuId == KenzaCloudMenuIds.EquipmentAlert ||
      this.selectedMainMenuId == KenzaCloudMenuIds.SiteNotification
    )
      result = true;
    return result;
  }

  lowerSubMenuOpacity() {
    let result = false;

    if (this.disableSiteSubMenuItems == true) result = true;

    return result;
  }

  viewMenuSelection(mainMenuId: KenzaCloudMenuIds) {

    this.selectedMainMenuId = mainMenuId;
    this.selectedSubMenuId = mainMenuId;

    this.mainMenuInView = mainMenuId;
    this.showAlertsSubMenus = false;

    if (this.selectedMainMenuId !== KenzaCloudMenuIds.Site) {
      this.showSiteSubMenus = false;
    }
    if (this.selectedMainMenuId !== KenzaCloudMenuIds.Settings) {
      this.showSettingsSubMenus = false;
    }

    if (this.selectedMainMenuId !== KenzaCloudMenuIds.Manage) {
      this.showManageSubMenus = false;
    }

    if (mainMenuId === KenzaCloudMenuIds.Site) {
      this.showSiteSubMenus = true;
    } else if (mainMenuId === KenzaCloudMenuIds.Settings) {
      this.showSettingsSubMenus = true;
    } else if (mainMenuId === KenzaCloudMenuIds.Manage) {
      this.showManageSubMenus = true;
    }

    this.save_left_nav_state_to_local_storage();
  }

  isHighlighted(mainMenuId: KenzaCloudMenuIds, subMenuId: KenzaCloudMenuIds) {
    let classes: string;

    if (
      mainMenuId === this.selectedMainMenuId &&
      subMenuId === KenzaCloudMenuIds.NoSelection
    ) {
      classes = 'menu-selected';
    } else if (
      mainMenuId === this.selectedMainMenuId &&
      subMenuId === this.selectedSubMenuId
    ) {
      classes = 'menu-selected';
    } else if (
      mainMenuId === this.selectedMainMenuId &&
      subMenuId !== this.selectedSubMenuId
    ) {
      classes = 'menu-group-selected';
    } else if (
      mainMenuId === KenzaCloudMenuIds.NoSelection &&
      subMenuId === KenzaCloudMenuIds.NoSelection
    ) {
      classes = 'menu-unselected';
    } else {
      classes = 'menu-unselected';

      if (this.mainMenuInView === mainMenuId && !this.disableSiteSubMenuItems) {
        classes += ' menu-group-in-view';
      }
    }
    return classes;
  }

  isSelectedIcon(menuId: KenzaCloudMenuIds) {
    if (menuId === this.selectedMainMenuId) {
      return 'menu-selected-icon';
    } else {
      return 'menu-unselected';
    }
  }

  isEnabledAlertFilter() {
    return this.disableAlertsSubMenuItems == true
      ? 'me-ion-left-menu-filter-list-disabled'
      : 'me-ion-left-menu-filter-list-enabled';
  }

  expand(which) {
    if (this.expanded[which] === true) this.expanded[which] = false;
    else {
      this.expanded = {
        user: false,
      };
      this.expanded[which] = true;
    }
  }

  async userActions(ev: any) {
    if (this.userActionsOpen == true) return;
    if (!this.siteService.handleIsConnected()) {
      this.user.set_signed_out_while_disconnected(true);
      this.user.auth.signOut();
      this.user.reset();
    } else {

      if (this.dimBackground) this.menuController.close();
      this.expand('user');
      const popover = await this.popoverCtrl.create({
        component: UserActionsComponent,
        event: ev,
      });

      popover.onWillDismiss().then(() => {
        this.expanded.user = false;
        this.userActionsOpen = false;
      });

      this.userActionsOpen = true;
      return await popover.present();
    }
  }

  handleMenuItemSelection(
    mainMenuId: KenzaCloudMenuIds,
    subMenuId: KenzaCloudMenuIds
  ) {
    if (!this.siteService.handleIsConnected()) return;
    const oldMainMenuId = this.selectedMainMenuId;
    const oldSubMenuId = this.selectedSubMenuId;
    this.disableAlertsSubMenuItems = false;
    this.mainMenuInView = this.selectedMainMenuId;

    this.selectedMainMenuId = mainMenuId;
    if (mainMenuId !== subMenuId && this.dimBackground) {
      this.menuController.close();
    }
    if (this.selectedMainMenuId === this.selectedSubMenuId) {
      this.collapseAllMenuItems();
    }

    switch (mainMenuId) {
      case KenzaCloudMenuIds.Home:
      case KenzaCloudMenuIds.AccountDetail:
      case KenzaCloudMenuIds.AccountGatewaySubscriptionPlans:
      case KenzaCloudMenuIds.AccountPaymentMethods:
      case KenzaCloudMenuIds.NoSelection:
        localStorage.removeItem(`ActiveSite`);
        localStorage.removeItem(`ActiveSiteAccount`);
        localStorage.removeItem(`LeftNavMenuState`);
        this.resetSiteSelection();
        this.collapseAllMenuItems();
        break;

      case KenzaCloudMenuIds.MemberAccountDetail:
        this.disableSiteSubMenuItems = true;
        break;

      case KenzaCloudMenuIds.SiteNotification:
      case KenzaCloudMenuIds.EquipmentAlert:
        this.disableAlertsSubMenuItems = true;
        break;

      case KenzaCloudMenuIds.Dashboard:
        if (this.dimBackground) this.menuController.close();
        this.selectedSubMenuId = subMenuId;
        this.disableSiteSubMenuItems = true;
        this.showSiteSubMenus = false;
        this.showSettingsSubMenus = false;
        this.showManageSubMenus = false;
        this.showAlertsSubMenus = false;
        if (this.selectedMainMenuId === this.selectedSubMenuId) {
          this.disableSiteSubMenuItems = false;
          this.disableAlertsSubMenuItems = false;
          this.disableDashboardMenuItems = false;
          this.disableSettingsMenuItems = false;
          this.disableManageMenuItems = false;
          this.selectedSubMenuId = KenzaCloudMenuIds.Dashboard;
          this.showDashboardSubMenus = !this.showDashboardSubMenus;
        }
        break;

      case KenzaCloudMenuIds.Site:
        if ( this.selectedMainMenuId !== oldMainMenuId || !this.showSiteSubMenus ) {
          this.showSiteSubMenus = true;
        }

        if (mainMenuId !== subMenuId) {
          this.selectedSubMenuId = subMenuId;
        }

        this.showAlertsSubMenus = false;
        this.showSettingsSubMenus = false;
        this.showManageSubMenus = false;

        switch (this.selectedSubMenuId) {
          case KenzaCloudMenuIds.GatewayDetail:
          case KenzaCloudMenuIds.MemberAccountDetail:
            this.selectedMainMenuId = KenzaCloudMenuIds.NoSelection;
            this.disableDashboardMenuItems = true;
            this.disableSiteSubMenuItems = true;
            this.disableAlertsSubMenuItems = true;
            this.disableSettingsMenuItems = true;
            this.disableManageMenuItems = true;
            break;
          case KenzaCloudMenuIds.SiteGateways:
          case KenzaCloudMenuIds.SiteMembers:
            this.disableDashboardMenuItems = false;
            this.disableSiteSubMenuItems = false;
            this.disableAlertsSubMenuItems = false;
            this.disableSettingsMenuItems = false;
            this.disableManageMenuItems = false;
            break;
        }
        break;
      case KenzaCloudMenuIds.Alerts:
        if (this.dimBackground) this.menuController.close();
        this.selectedSubMenuId = KenzaCloudMenuIds.Alerts;
        this.showAlertsSubMenus = true;
        this.showSiteSubMenus = false;
        this.showSettingsSubMenus = false;
        this.showManageSubMenus = false;

        this.disableDashboardMenuItems = false;
        this.disableSiteSubMenuItems = false;
        this.disableAlertsSubMenuItems = false;
        this.disableSettingsMenuItems = false;
        this.disableManageMenuItems = false;

        break;

      case KenzaCloudMenuIds.Settings:
        if ( this.selectedMainMenuId !== oldMainMenuId || !this.showSettingsSubMenus ) {
          this.showSettingsSubMenus = !this.showSettingsSubMenus;
        }

        if (mainMenuId !== subMenuId) {
          this.selectedSubMenuId = subMenuId;
        }

        this.showAlertsSubMenus = false;
        this.showSiteSubMenus = false;
        this.showManageSubMenus = false;
        break;

      case KenzaCloudMenuIds.Manage:
        if ( this.selectedMainMenuId !== oldMainMenuId || !this.showManageSubMenus ) {
          this.showManageSubMenus = true;
        }

        if (mainMenuId !== subMenuId) {
          this.selectedSubMenuId = subMenuId;
        }

        this.showSiteSubMenus = false;
        this.showAlertsSubMenus = false;
        this.showSettingsSubMenus = false;

        switch (this.selectedSubMenuId) {
          case KenzaCloudMenuIds.GatewayDetail:
          case KenzaCloudMenuIds.MemberAccountDetail:
            this.selectedMainMenuId = KenzaCloudMenuIds.NoSelection;
            if (this.disableDashboardMenuItems === false) {
              this.disableDashboardMenuItems = true;
            }
            if (this.disableSiteSubMenuItems === false) {
              this.disableSiteSubMenuItems = true;
            }
            if (this.disableSettingsMenuItems === false) {
              this.disableSettingsMenuItems = true;
            }
            if (this.disableManageMenuItems === false) {
              this.disableManageMenuItems = true;
            }
            this.disableAlertsSubMenuItems = true;
            // this.disableSettingsMenuItems = true;
            // this.disableManageMenuItems = true;
            break;
          case KenzaCloudMenuIds.ManageGateways:
          case KenzaCloudMenuIds.ManageMembers:
            if (this.disableDashboardMenuItems === true) {
              this.disableDashboardMenuItems = false;
            }
            if (this.disableSiteSubMenuItems === true) {
              this.disableSiteSubMenuItems = false;
            }
            if (this.disableSettingsMenuItems === true) {
              this.disableSettingsMenuItems = false;
            }
            if (this.disableManageMenuItems === true) {
              this.disableManageMenuItems = false;
            }
            this.disableAlertsSubMenuItems = false;
            // this.disableSettingsMenuItems = true;
            // this.disableManageMenuItems = true;            
            break;
          case KenzaCloudMenuIds.MemberDetail:
            this.selectedMainMenuId = KenzaCloudMenuIds.NoSelection;
            this.showManageSubMenus = false;
            this.disableDashboardMenuItems = true;
            this.disableSiteSubMenuItems = true;
            this.disableAlertsSubMenuItems = true;
            this.disableSettingsMenuItems = true;
            this.disableManageMenuItems = true;
            break;
        }
        break;
    }
  }

  collapseAllMenuItems() {
    this.showSiteSubMenus = false;
    this.showManageSubMenus = false;
    this.showAlertsSubMenus = false;
    this.showSettingsSubMenus = false;
    this.showDashboardSubMenus = false;
    //this.showManageSubMenus = false;
  }

  // Reset Sites
  resetSiteSelection() {
    this.selectedSiteId = 'Select Site';
    this.user.setActiveSite(new Site({
      id: "default-site",
      name: "Select Site",
      phone: "",
      company: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      site_photo_name: "",
      sitestatustype_id: 1
    }), true);
    this.siteAlertFilter = new SiteAlertFilter();
  }

}
