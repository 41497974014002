import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';

import { MaintenanceComponent } from '../../components/maintenance.component';
import { MaintenanceResultComponent } from '../../components/maintenance-result/maintenance-result.component';
import { MaintenanceHistoryComponent } from '../../components/maintenance-history/maintenance-history.component';
import { MaintenanceSpreadsheetComponent } from '../../components/maintenance-spreadsheet/maintenance-spreadsheet.component';
import { MaintenanceMTDZComponent } from '../../components/maintenance-mtdz/maintenance-MTDZ.component';
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';
// import { TestDriveSettings } from '../../components/testdrive-settings/testdrive-settings.component';
// import { TestdriveProgress } from '../../components/testdrive-progress/testdrive-progress.component';
import { MaintenanceBCPortResultComponent } from '../../components/maintenance-bcport-result/maintenance-bcport-result.component';
import { MaintenanceRefrigerantResultComponent } from '../../components/maintenance-refrigerant-result/maintenance-refrigerant-result.component';

const siteGuards = [AuthGuard, HasInternetConnectionGuardService, SiteGuard];
const routes: Routes = [
  {
    path: 'maintenance/:siteId',
    component: MaintenanceComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMaintenance }
  },
  {
    path: 'maintenance/:siteId/result',
    component: MaintenanceResultComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMaintenance }
  },
  {
    path: 'maintenance/:siteId/history',
    component: MaintenanceHistoryComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMaintenance }
  },
  {
    path: 'maintenance/:siteId/spreadsheet',
    component: MaintenanceSpreadsheetComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMaintenance }
  },
  {
    path: 'maintenance/:siteId/MTDZ',
    component: MaintenanceMTDZComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMaintenance }
  },
  {
    path: 'maintenance/:siteId/BCPort',
    component: MaintenanceBCPortResultComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'maintenance/:siteId/Refrigerant',
    component: MaintenanceRefrigerantResultComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MAintenanceRoutingModule {}
