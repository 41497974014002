export const AccountFiller = {
    account_photo_name: 'filler-account',
    account_photo_url: 'filler-account',
    city: 'filler-account',
    company: 'filler-account',
    country: 'filler-account',
    createdAt: 'filler-account',
    email: 'filler-account',
    fname: 'filler-account',
    id: 'filler-account',
    lastLogin: 'filler-account',
    lname: 'filler-account',
    phone: 'filler-account',
    postalCode: 'filler-account',
    removedAt: 'filler-account',
    state: 'filler-account',
    status: 'filler-account',
    streetLine1: 'filler-account',
    streetLine2: 'filler-account',
    imageName: 'filler-account',
    level: 0,
    levelDisplayText: 'filler-account'
};

