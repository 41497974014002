import { GatewayUnitTwoDigitType } from "src/app/enumerations/enums";
import { AirDirectionStages, FanSpeed, FanSpeedStages, GatewayUnit, IndoorFanSpeed, LossnayFanSpeed, Mode, Presence, VentMode } from "src/app/features/manage/components/classes/GatewayUnit";


export class DisplayOptions {
    // A collection of methods to assist in display of IDU and Group modes, states, options... 
    // used by anyone that needs to display icons, words, etc related to a group/idu/odu state or operation

    getDisplayMode(modeValue: string): string {
        // mode to english word translations for 'Mode' of group/idu

        let modeDisplayString = modeValue;

        switch (modeDisplayString) {
            case 'bypass':
                modeDisplayString = 'Bypass';
                break;
            case 'heat_recovery':
                modeDisplayString = 'Energy Recovery';
                break;
            case 'auto':
            case 'auto_cool':
            case 'auto_heat':
            case 'dual_auto':
                modeDisplayString = 'Auto';
                break;
            case 'setback':
            case 'setback_cool':
            case 'setback_heat':
                modeDisplayString = 'Setback';
                break;
            case 'dry':
                modeDisplayString = 'Dry';
                break;
        }

        return modeDisplayString;
    }

    getModeIcon(mode: Mode): string {
        // get the icon to use for this 'mode' 
        let iconName = '';

        switch (mode) {
            case Mode.heat:
                iconName = 'flame';
                break;
            case Mode.cool:
                iconName = 'snow';
                break;
            case Mode.dry:
                iconName = 'water';
                break;
            case Mode.fan:
                iconName = 'aperture';
                break;
            case Mode.setback:
            case Mode.setback_cool:
            case Mode.setback_heat:
                iconName = 'exit';
                break;
            case Mode.auto:
            case Mode.auto_cool:
            case Mode.auto_heat:
            case Mode.dual_auto:
                iconName = 'sync';
                break;
        }

        return iconName;
    }

    getVentModeIcon(ventMode: VentMode): string {
        // get the icon for this lc vent mode
        let iconName = '';

        switch (ventMode) {
            case VentMode.bypass:
                iconName = '/assets/imgs/lossnay-modes/Lossnay Control 4 NOFILL.svg';
                break;

            case VentMode.default:
            case VentMode.heat_recovery:
                iconName = '/assets/imgs/lossnay-modes/Lossnay Control 3 NOFILL.svg';
                break;

            case VentMode.auto:
                iconName = '/assets/imgs/lossnay-modes/Lossnay Control 5 NOFILL.svg';
                break;
        }

        return iconName
    }



    getModeColor(mode: string): string {
        // color to use based on the mode
        let iconColor = '';

        switch (mode.toLowerCase()) {
            case 'heat':
                iconColor = 'primary';
                break;
            case 'cool':
                iconColor = 'secondary';
                break;
            case 'dry':
                iconColor = 'medium';
                break;
            case 'fan':
                iconColor = 'medium';
                break;
            case 'heat_recovery':
                iconColor = 'primary';
                break;
            case 'bypass':
                iconColor = 'medium';
                break;
            case 'setback-cool':
            case 'setback-heat':
                iconColor = 'medium';
                break;
            case 'auto':
            case 'auto_cool':
            case 'auto_heat':
            case 'dual_auto':
                iconColor = 'secondary';
                break;
            case 'setback':
                iconColor = 'secondary';
                break;
        }

        return iconColor;
    }

    getLossnayImageAddress(vent_mode: string): string {
        // get the address to the lossnay image file based on its vent_mode 
        if (vent_mode == 'bypass') {
            return "/assets/imgs/lossnay-modes/Lossnay Control 4.svg";
        } else if (vent_mode == 'heat_recovery') {
            return "/assets/imgs/lossnay-modes/Lossnay Control 3.svg";
        }

        // else...
        return "/assets/imgs/lossnay-modes/Lossnay Control 5.svg";
    }

    selectedFanSpeed(selectedGatewayUnit: GatewayUnit, position: number) {
        /**
         * Returns the selected fan speed based on the number of fan speeds and fan
         * speed settings
         * @param position Which fan speed in the UI is requesting the information
         */
        const description = this.getFanSpeedDescription(selectedGatewayUnit);
        const positionDescription = this.getFanSpeedDescription(selectedGatewayUnit, position).toLowerCase();

        try {
            return positionDescription === description && description != '';
        } catch {
            return false;
        }
    }

    getFanSpeedDescription(selectedGatewayUnit: GatewayUnit, position: number = null, fanSpeed = '') {
        // based on the group and the position get a description of the fan speed

        const fanSpeeds = {
            [GatewayUnitTwoDigitType.IndoorUnit]: {
                [FanSpeedStages.none] : {
                    '0': 'disabled',
                    'position': { 0: 'Disabled' }
                },
                [FanSpeedStages.two_stages]: {
                    '0': 'off', '1': 'very low', '2': 'very low', '3': 'very low',
                    '4': 'very low', '5': 'low', '6': 'medium', '7': 'high', '8': 'high',
                    '9': 'very high', '10': 'very high', '11': 'auto',
                    'position': { 0: 'Low', 1: 'High' }
                },
                [FanSpeedStages.three_stages]: {
                    '0': 'off', '1': 'very low', '2': 'very low', '3': 'very low',
                    '4': 'low', '5': 'low', '6': 'medium', '7': 'high', '8': 'high',
                    '9': 'very high', '10': 'very high', '11': 'auto',
                    'position': { 0: 'Low', 1: 'Medium', 2: 'High' }
                },
                [FanSpeedStages.four_stages]: {
                    '0': 'off', '1': 'very low', '2': 'very low', '3': 'very low',
                    '4': 'very low', '5': 'low', '6': 'medium', '7': 'high', '8': 'high',
                    '9': 'very high', '10': 'very high', '11': 'auto',
                    'position': { 0: 'Very Low', 1: 'Low', 2: 'Medium', 3: 'High' }
                }
            },
            [GatewayUnitTwoDigitType.Lossnay]: {
                [FanSpeedStages.one_stage]: {
                    '0': 'off', '1': 'medium', '2': 'medium', '3': 'medium',
                    '4': 'medium', '5': 'medium', '6': 'medium', '7': 'medium', '8': 'medium',
                    '9': 'medium', '10': 'medium', '11': 'auto',
                    'position': { 0: 'Medium' }
                },
                [FanSpeedStages.two_stages]: {
                    '0': 'low', '1': 'low', '2': 'low', '3': 'low',
                    '4': 'low', '5': 'low', '6': 'low', '7': 'medium',
                    '8': 'medium', '9': 'medium', '10': 'medium', '11': 'auto',
                    'position': { 0: 'Low', 1: 'Medium' }
                },
                [FanSpeedStages.three_stages]: {
                    '0': 'low', '1': 'low', '2': 'low', '3': 'low',
                    '4': 'low', '5': 'low', '6': 'low', '7': 'medium', '8': 'medium',
                    '9': 'high', '10': 'high', '11': 'auto',
                    'position': { 0: 'Very Low', 1: 'Low', 2: 'Medium' }
                },
                [FanSpeedStages.four_stages]: {
                    '0': 'very low', '1': 'very low', '2': 'very low', '3': 'low',
                    '4': 'low', '5': 'low', '6': 'low', '7': 'medium', '8': 'medium',
                    '9': 'high', '10': 'high', '11': 'auto',
                    'position': { 0: 'Very Low', 1: 'Low', 2: 'Medium', 3: 'High' }
                },
            }
        };

        const fanSpeedSw = this.getUnitFanSpeedSw(selectedGatewayUnit);
        const type = selectedGatewayUnit.type;
        const fan_speed = fanSpeedSw == FanSpeedStages.none ? 0 : fanSpeed ? fanSpeed : selectedGatewayUnit.fan_speed;

        // small change for LC when FanExHighSW is enabled but FanExLowSW is not
        if (type === GatewayUnitTwoDigitType.Lossnay &&
            selectedGatewayUnit.fan_extra_low_sw == Presence.disabled &&
            selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {

            fanSpeeds[GatewayUnitTwoDigitType.Lossnay][FanSpeedStages.three_stages] = {
                '0': 'very low', '1': 'very low', '2': 'very low', '3': 'low',
                '4': 'low', '5': 'low', '6': 'low', '7': 'medium', '8': 'medium',
                '9': 'high', '10': 'high', '11': 'high',
                'position': { 0: 'Low', 1: 'Medium', 2: 'High' }
            }
        }


        try {
            let rtn;
            if (position !== null) {
                rtn = fanSpeeds[type][fanSpeedSw].position[position];
            } else {
                rtn = fanSpeeds[type][fanSpeedSw][fan_speed];
            }
            if (rtn == undefined) {
                rtn = ''
            }
            return rtn;
        } catch {
            return '';
        }

    }

    public getUnitFanSpeedSw(selectedGatewayUnit: GatewayUnit):string {
        // resolve what the fanSpeedSW value is for this unit based on its type
        return selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay ?
            this.getRealLossnayFanStages(selectedGatewayUnit) :
            selectedGatewayUnit.fan_speed_sw;
    }

    public getUnitFanSpeedAutoSw(selectedGatewayUnit: GatewayUnit): Presence {
        // does this unit support an auto fan speed mode
        if (selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit) return selectedGatewayUnit.fan_auto_sw

        // else lossnay
        return selectedGatewayUnit.lc_fan_auto_sw

    }

    getRealLossnayFanStages(selectedGatewayUnit: GatewayUnit): string {
        // return the number of stages based on this type of lossnay based on the fan_extra settings
        let realFanStages = '';
        if (selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            realFanStages = FanSpeedStages.four_stages
        } else if (selectedGatewayUnit.fan_extra_low_sw == Presence.enabled && selectedGatewayUnit.fan_extra_high_sw == Presence.disabled) {
            realFanStages = FanSpeedStages.three_stages
        } else if (selectedGatewayUnit.fan_extra_low_sw == Presence.disabled && selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            realFanStages = FanSpeedStages.three_stages
        } else {
            realFanStages = selectedGatewayUnit.lc_fan_speed_sw;
        }
        return realFanStages;
    }

    getFanSpeedPreviewIcon(selectedGatewayUnit: GatewayUnit, requestedFanSpeed = null) {
        // get the preview icon for the fan speed on this unit
        const fanSpeedImages = {
            [GatewayUnitTwoDigitType.IndoorUnit]: {
                [Presence.disabled]: {
                    [FanSpeedStages.none]: {
                        [IndoorFanSpeed.disabled]: 'fan-speed-2-low-lightgrey-oct2016.svg'
                    },
                    [FanSpeedStages.two_stages]: {
                        [IndoorFanSpeed.low]: 'tile_fan_speed_1_of_2.svg',
                        [IndoorFanSpeed.high]: 'tile_fan_speed_2_of_2.svg',
                    },
                    [FanSpeedStages.three_stages]: {
                        [IndoorFanSpeed.low]: 'tile_fan_speed_1_of_3.svg',
                        [IndoorFanSpeed.medium]: 'tile_fan_speed_2_of_3.svg',
                        [IndoorFanSpeed.high]: 'tile_fan_speed_3_of_3.svg',
                    },
                    [FanSpeedStages.four_stages]: {
                        [IndoorFanSpeed.very_low]: 'tile_fan_speed_1_of_4.svg',
                        [IndoorFanSpeed.low]: 'tile_fan_speed_2_of_4.svg',
                        [IndoorFanSpeed.medium]: 'tile_fan_speed_3_of_4.svg',
                        [IndoorFanSpeed.high]: 'tile_fan_speed_4_of_4.svg'
                    }
                },
                [Presence.enabled]: {
                    [FanSpeedStages.none]: {
                        [IndoorFanSpeed.disabled]: 'fan-speed-2-low-lightgrey-oct2016.svg'
                    },
                    [FanSpeedStages.two_stages]: {
                        [IndoorFanSpeed.low]: 'fan-speed-2-verylow-auto.svg',
                        [IndoorFanSpeed.high]: 'fan-speed-2-low-auto.svg',
                        [IndoorFanSpeed.auto]: 'fan-speed-2-auto.svg'
                    },
                    [FanSpeedStages.three_stages]: {
                        [IndoorFanSpeed.low]: 'fan-speed-3-verylow-auto.svg',
                        [IndoorFanSpeed.medium]: 'fan-speed-3-low-auto.svg',
                        [IndoorFanSpeed.high]: 'fan-speed-3-med-auto.svg',
                        [IndoorFanSpeed.auto]: 'fan-speed-3-auto.svg'
                    },
                    [FanSpeedStages.four_stages]: {
                        [IndoorFanSpeed.very_low]: 'fan-speed-4-verylow-auto.svg',
                        [IndoorFanSpeed.low]: 'fan-speed-4-low-auto.svg',
                        [IndoorFanSpeed.medium]: 'fan-speed-4-med-auto.svg',
                        [IndoorFanSpeed.high]: 'fan-speed-4-high-auto.svg',
                        [IndoorFanSpeed.auto]: 'fan-speed-4-auto.svg'
                    }
                }
            },
            [GatewayUnitTwoDigitType.Lossnay]: {
                [Presence.disabled]: {
                    [FanSpeedStages.one_stage]: {
                        [LossnayFanSpeed.medium]: 'fan-speed-3-low-auto.svg'
                    },
                    [FanSpeedStages.two_stages]: {
                        [LossnayFanSpeed.low]: 'tile_fan_speed_1_of_2.svg',
                        [LossnayFanSpeed.medium]: 'tile_fan_speed_2_of_2.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-4-high-auto.svg',
                    },
                    [FanSpeedStages.three_stages]: {
                        [LossnayFanSpeed.very_low]: 'tile_fan_speed_1_of_3.svg',
                        [LossnayFanSpeed.low]: 'tile_fan_speed_2_of_3.svg',
                        [LossnayFanSpeed.medium]: 'tile_fan_speed_3_of_3.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-4-high-auto.svg',
                    },
                    [FanSpeedStages.four_stages]: {
                        [LossnayFanSpeed.very_low]: 'tile_fan_speed_1_of_4.svg',
                        [LossnayFanSpeed.low]: 'tile_fan_speed_2_of_4.svg',
                        [LossnayFanSpeed.medium]: 'tile_fan_speed_3_of_4.svg',
                        [LossnayFanSpeed.high]: 'tile_fan_speed_4_of_4.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-4-high-auto.svg',
                    }
                },
                [Presence.enabled]: {
                    [FanSpeedStages.one_stage]: {
                        [IndoorFanSpeed.medium]: 'fan-speed-3-low-auto.svg'
                    },
                    [FanSpeedStages.two_stages]: {
                        [LossnayFanSpeed.low]: 'fan-speed-2-verylow-auto.svg',
                        [LossnayFanSpeed.medium]: 'fan-speed-2-low-auto.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-2-auto.svg'
                    },
                    [FanSpeedStages.three_stages]: {
                        [LossnayFanSpeed.very_low]: 'fan-speed-3-verylow-auto.svg',
                        [LossnayFanSpeed.low]: 'fan-speed-3-low-auto.svg',
                        [LossnayFanSpeed.medium]: 'fan-speed-3-med-auto.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-3-auto.svg'
                    },
                    [FanSpeedStages.four_stages]: {
                        [LossnayFanSpeed.very_low]: 'fan-speed-4-verylow-auto.svg',
                        [LossnayFanSpeed.low]: 'fan-speed-4-low-auto.svg',
                        [LossnayFanSpeed.medium]: 'fan-speed-4-med-auto.svg',
                        [LossnayFanSpeed.high]: 'fan-speed-4-high-auto.svg',
                        [LossnayFanSpeed.auto]: 'fan-speed-4-auto.svg'
                    }
                }
            }
        };

        // small change for LC when FanExHighSW is enabled but FanExLowSW is not
        if (selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay &&
            selectedGatewayUnit.fan_extra_low_sw == Presence.disabled &&
            selectedGatewayUnit.fan_extra_high_sw == Presence.enabled) {
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.low] = 'tile_fan_speed_1_of_3.svg';
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.medium] = 'tile_fan_speed_2_of_3.svg';
            fanSpeedImages[GatewayUnitTwoDigitType.Lossnay][Presence.disabled][FanSpeedStages.three_stages][LossnayFanSpeed.high] = 'tile_fan_speed_3_of_3.svg';
        }

        let hasAuto = selectedGatewayUnit.fan_auto_sw;
        let fanSpeedStages = selectedGatewayUnit.fan_speed_sw;
        let fanSpeed = requestedFanSpeed ? requestedFanSpeed : selectedGatewayUnit.fan_speed;
        if (fanSpeedStages === FanSpeedStages.none) {
            // then we need to hard code the fanspeed at disabled
            fanSpeed = FanSpeed.disabled;
        }
        const type = selectedGatewayUnit.type;

        if (selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay) {
            hasAuto = selectedGatewayUnit.lc_fan_auto_sw;
            fanSpeedStages = this.getRealLossnayFanStages(selectedGatewayUnit);
        }

        try {
            return fanSpeedImages[type][hasAuto][fanSpeedStages][fanSpeed] !== undefined ? '/assets/imgs/fan-speeds/' + fanSpeedImages[type][hasAuto][fanSpeedStages][fanSpeed] : '';
        } catch {
            return '';
        }
    }

    getFanSpeedIcon(selectedGatewayUnit: GatewayUnit, position: number) {
        // get the icon for this fan speed position of this gatewayunit
        const fanSpeedImages = {
            [FanSpeedStages.two_stages]: [
                'fan-speed-2-a-middlegrey-128x60.svg',
                'fan-speed-2-b-middlegrey-127x60.svg'
            ],
            [FanSpeedStages.three_stages]: [
                'fan-speed-3-a-middlegrey-85x60.svg',
                'fan-speed-3-b-middlegrey-85x60.svg',
                'fan-speed-3-c-middlegrey-85x60.svg'
            ],
            [FanSpeedStages.four_stages]: [
                'fan-speed-4-a-middlegrey-63x60.svg',
                'fan-speed-4-b-middlegrey-64x60.svg',
                'fan-speed-4-c-middlegrey-64x60.svg',
                'fan-speed-4-d-middlegrey-64x60.svg'
            ]
        };

        let fanSpeedCount = selectedGatewayUnit.fan_speed_sw;
        if (selectedGatewayUnit.type === GatewayUnitTwoDigitType.Lossnay) {
            fanSpeedCount = this.getRealLossnayFanStages(selectedGatewayUnit);
        }
        try {
            return fanSpeedImages[fanSpeedCount][position] !== undefined ? '/assets/imgs/fan-speeds/' + fanSpeedImages[fanSpeedCount][position] : '';
        } catch {
            return '';
        }
    }

    getBatchFanSpeedDescription(position: number) {
        // return a raw description of the fan speed at this position
        const fanSpeedDescriptions = ['very low', 'low', ' medium', 'high', 'auto']
        return fanSpeedDescriptions[position]
    }

    getBatchFanSpeedIcon(position: number) {
        // return a raw image for a four_stage fan speed, based on position

        const fanSpeeds = [
            'fan-speed-4-a-middlegrey-63x60.svg',
            'fan-speed-4-b-middlegrey-64x60.svg',
            'fan-speed-4-c-middlegrey-64x60.svg',
            'fan-speed-4-d-middlegrey-64x60.svg'
        ]

        return '/assets/imgs/fan-speeds/' + fanSpeeds[position]
    }

    shouldShowFanSpeed(selectedGatewayUnit: GatewayUnit, position: number) {
        // show this fan speed ?

        const fanSpeedSw = this.getUnitFanSpeedSw(selectedGatewayUnit)
        const fanSpeedAutoSw = this.getUnitFanSpeedAutoSw(selectedGatewayUnit)

        let rtn: boolean;
        if (position == 0) {
            return true
        } else if (position === 1) {
            rtn = fanSpeedSw === FanSpeedStages.two_stages ||
                fanSpeedSw === FanSpeedStages.three_stages ||
                fanSpeedSw === FanSpeedStages.four_stages;
        } else if (position === 2) {
            rtn = fanSpeedSw === FanSpeedStages.three_stages ||
                fanSpeedSw === FanSpeedStages.four_stages;
        } else if (position === 3) {
            rtn = fanSpeedSw === FanSpeedStages.four_stages;
        } else if (position === 4) {
            rtn = fanSpeedAutoSw === Presence.enabled;
        } else {
            rtn = false;
        }

        return rtn;
    }

    getAirDirectionDisplay(selectedGatewayUnit: GatewayUnit, air_direction:string = null): string {
        // what is the display string for the air direction on this gatewayunit
        if (selectedGatewayUnit.air_direction_sw == AirDirectionStages.disabled) {
            return "Stationary";
        }

        return air_direction ? air_direction : selectedGatewayUnit.air_direction;
    }

    getAirDirectionIcon(setting: string, air_direction_sw = AirDirectionStages.five_stages): string {
        // if direction selection is disabled use the stationary icon
        if (air_direction_sw == AirDirectionStages.disabled) {
            setting = "stationary";
        }

        const dir_direction_icons = {
            ceiling: '/assets/imgs/air-direction-1-ceiling.svg',
            high: '/assets/imgs/air-direction-2-high.svg',
            middle: '/assets/imgs/air-direction-3-middle.svg',
            low: '/assets/imgs/air-direction-4-low.svg',
            floor: '/assets/imgs/air-direction-5-floor.svg',
            swing: '/assets/imgs/air-direction-6-swing.svg',
            auto: '/assets/imgs/air-direction-auto.svg',
            stationary: '/assets/imgs/air-direction-stationary.svg'
        };

        return dir_direction_icons[setting];
    }


    isAirDirectionControlEnabled(selectedGatewayUnit: GatewayUnit): boolean {
        // doing this because air_direction_sw isn't a boolean, it can be `disabled`, `4_stages`, or `5_stages`.  Could theoretically be `enabled` as well i suppose
        if (selectedGatewayUnit.air_direction_sw == AirDirectionStages.disabled) {
            return false;
        } else {
            return true;
        }
    }

    shouldDisplayAirDirectionControlIcon(selectedGatewayUnit: GatewayUnit, direction: string): boolean {
        let rtn = false;

        switch (direction) {
            case 'stationary':
                if (!this.isAirDirectionControlEnabled(selectedGatewayUnit)) {
                    rtn = true;
                }
                break;
            case 'floor':
            case 'ceiling':
            case 'middle':
            case 'low':
                if (this.isAirDirectionControlEnabled(selectedGatewayUnit)) {
                    rtn = true;
                }
                break;
            case 'high':
                if (this.isAirDirectionControlEnabled(selectedGatewayUnit) && <string>(selectedGatewayUnit.air_direction_sw) == '5_stages') { //typing issue...
                    rtn = true;
                }
                break;
            case 'swing':
                if (this.isAirDirectionControlEnabled(selectedGatewayUnit) && selectedGatewayUnit.swing_sw == Presence.enabled) {
                    rtn = true;
                }
                break;
            case 'auto':
                if (this.isAirDirectionControlEnabled(selectedGatewayUnit) && selectedGatewayUnit.air_direction_auto == Presence.enabled) {
                    rtn = true;
                }
                break;
        }

        return rtn;
    }

}