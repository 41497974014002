import { KenzaCloudMenuIds, LevelEnum } from "src/app/enumerations/enums";
import { Site } from "src/app/features/sites/classes/site";
import { SiteAlertFilter } from "./filters";

export class IdNamePair {
    id: any;
    name: any;
}

export class NameValuePair {
    name: any;
    value: any;
}

export class LeftNavMenuState {

    saved_to_local_storage: boolean;

    selectedSiteInfo: Site;

    selectedMainMenuId: number;
    selectedSubMenuId: number;

    showDashboardSubMenus: boolean;
    disableDashboardMenuItems: boolean;

    showSiteSubMenus: boolean;
    disableSiteSubMenuItems: boolean;

    showAlertsSubMenus: boolean;
    disableAlertsSubMenuItems: boolean;

    showManageSubMenus: boolean;
    disableManageMenuItems: boolean;

    // 2022.05.13 CEC古河 追加
    // 2022.05.13 Added CEC Furukawa
    showErrorAnalysisSubMenus: boolean;
    disableErrorAnalysisMenuItems: boolean;

    showSettingsSubMenus: boolean;
    disableSettingsMenuItems: boolean;

    siteAlertFilter: SiteAlertFilter;

    constructor() {

        this.saved_to_local_storage = false;

        this.selectedSiteInfo = new Site({
            id: 'default-site',
            name: 'Select Site',
            phone: '',
            company: '',
            addressOne: '',
            addressTwo: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            site_photo_name: '',
            sitestatustype_id: 1,
            current_owner_id: '',
            locations: []
          });

        this.selectedMainMenuId = KenzaCloudMenuIds.NoSelection;
        this.selectedSubMenuId = KenzaCloudMenuIds.NoSelection;

        this.showDashboardSubMenus = false;
        this.disableDashboardMenuItems = false;

        this.showSiteSubMenus = false;
        this.disableSiteSubMenuItems = false;

        this.showAlertsSubMenus = false;
        this.disableAlertsSubMenuItems = false;

        this.showSettingsSubMenus = false;
        this.disableSettingsMenuItems = false;

        this.showManageSubMenus = false;
        this.disableManageMenuItems = false;

        // 2022.05.13 CEC古河 追加
        // 2022.05.13 Added CEC Furukawa
        this.showErrorAnalysisSubMenus = false;
        this.disableErrorAnalysisMenuItems = false;

        this.siteAlertFilter = {
            site_id: '',
            StatusActive: true,
            StatusHistorical: false,
            AlertEquipment: true,
            NotificationMembership: true,
            NotificationGateway: true,
            NotificationSubscription: true,
            NotificationPayments: true,
            NotificationEquipment: true,
            UserLevelEnum: LevelEnum.Unknown,
        };
    }
}