<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Select an ope mode</ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-item *ngFor="let OpeMode of ModeList" (click)="setClickedSelection(OpeMode)" lines="none" button>
      <ion-label>{{OpeMode}}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>