import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SiteService } from '../../services/site.service';
import { SiteAnalyticsTestRunOduSelectComponent } from '../site-analytics-test-run-odu-select/site-analytics-test-run-odu-select.component';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { GatewayModelClass, LevelEnum, SubscriptionFeatures, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';
import { devEnv, TEST_MODE_AUTO_WARNING_DETAIL, TEST_MODE_AUTO_WARNING_TITLE } from 'src/app/constants/kenzaconstants';
import { SiteAnalyticsTestRunModeDurationComponent } from '../site-analytics-test-run-mode-duration/site-analytics-test-run-mode-duration.component';

export class OutdoorUnitDetails {
  // details of an RMD outdoor unit that do a test run
  id: string; // unit id
  gwModel: any;
  name: string;
  bus_address: string;
  gateway_id: string;
  gateway_name: string;
  gateway_serial_number?: string;
  gateway_subscription_needs_upgrade: boolean;
  gateway_is_expired: boolean;
  gateway_in_maintenance: boolean;
  gateway_is_disconnected: boolean;
  checked: boolean;
  checkable: boolean;
  upgradable: boolean;
  capabilities?: any;
  branchPortEnabled?: boolean;
  refrigerantEnabled?: boolean;
  analyticsBusAddress?: boolean;
  indoor_coil_group_count?: number; // IC Group Count associated w/ ODU from GWG

  constructor(odu, gwg, isSiteOwner: boolean) {
    // Maintenance ODU & Report Data
    // devEnv && console.log(`ODU ${odu.name} & GWG`, {odu, gwg});
    this.id = odu.id;
    this.name = odu.name;
    this.bus_address = odu.bus_address;
    this.gateway_id = gwg.id;
    this.gateway_name = gwg.name;
    if (gwg.serial_number) this.gateway_serial_number = gwg.serial_number; 
    this.gateway_subscription_needs_upgrade = gwg.subscription_features[SubscriptionFeatures.GEN_SYSTEM_DIAGRAM] != SubscriptionFeatures.INCLUDED;
    this.gateway_is_expired  = gwg.subscription_expired;
    this.gateway_in_maintenance = (gwg.maintenance_job || gwg.mfk_status) ? true : false;
    this.gateway_is_disconnected = gwg.connection.connected.toLowerCase() != 'true';
    this.checked = false;
    this.checkable = !(this.gateway_subscription_needs_upgrade || this.gateway_is_expired || this.gateway_in_maintenance || this.gateway_is_disconnected);
    this.upgradable = isSiteOwner;
    this.gwModel = gwg.model.class_name;

    if (odu && gwg && Array.isArray(gwg.groups)) {
      this.indoor_coil_group_count = gwg.groups.filter(gr => gr?.units[0]?.outdoor_unit?.id == odu?.id)?.length;
    }
    
    this.branchPortEnabled = false;
    this.refrigerantEnabled = false;
    this.analyticsBusAddress = odu.bus_address >= 0 && odu.bus_address <= 255;
    if (this.capabilities == undefined && odu?.capabilities && odu?.capabilities != null && this.analyticsBusAddress == true) {
      this.capabilities = odu?.capabilities;
      this.refrigerantEnabled = odu?.capabilities?.checking_refrigerant_amount_support_available;
      this.branchPortEnabled = odu?.capabilities?.incorrectportconnectionsetting_detection_supportavailable;
    }
  }
}

export enum TestMode {
  unSelected = 0,
  heating = 1,
  cooling = 2,
  auto = 3
}

// these labels line up with the values in TestMode enum
export const TestModeLabels = [ 'Select Mode', 'Heating', 'Cooling', 'Auto' ];

export enum TestDuration {
  unSelected = 0,
  thirtyMins = 1,
  sixtyMins = 2,
  nintyMins = 3,
  onetwentyMins = 4
}

// these labels line up with the values in TestDuration
export const TestDurationLabels = [ 'Select Duration', '30 min', '60 min', '90 min', '120 min' ];
export const TestDurationMinutes = [ 0, 30, 60, 90, 120 ];

@Component({
  selector: 'app-site-analytics-test-run',
  templateUrl: './site-analytics-test-run.component.html',
  styleUrls: ['./site-analytics-test-run.component.scss'],
})

export class SiteAnalyticsTestRunComponent  implements OnInit {
  @Input() site_id

  selected_odus_label = `Select Outdoor Unit(s)`;
  selected_odus = [];
  selected_mode_label = `Select Mode`;
  selected_mode: TestMode = TestMode.unSelected;
  selected_duration_label = `Select Duration`;
  selected_duration: TestDuration = TestDuration.unSelected;
  error_message: string;
  creatingTestRun: boolean;

  data_loading: boolean;
  outdoor_units = [];
  devEnv = devEnv;

  constructor(
    private userService: UserService,
    private siteService: SiteService,
    private alertController: AlertController,
    private modalController: ModalController,
  ) {
    // Empty
    this.error_message = '';
    this.creatingTestRun = false;
  }

  ngOnInit() {
    this.refreshOutdoorUnits(this.userService.active.id);
  }

  refreshOutdoorUnits(site_id: string) {
    // get a fresh list of outdoor units
    this.outdoor_units = [];
    this.data_loading = true;
    this.siteService.get_site_gateways_with_groups(site_id, true).subscribe(gateways => {
      let gateways_with_groups: Gateway[] = [];

      if (gateways) {
        gateways_with_groups = gateways[`gateways`] as Gateway[];
        gateways_with_groups.forEach(gwg => {
          // in this env we only want RMD gateways - if were here there must be at least 1
          if (gwg.model.class_name == GatewayModelClass.RMD) {
            gwg.outdoor_units.forEach(odu => {
              this.outdoor_units.push(new OutdoorUnitDetails(odu, gwg, this.userService.activeSiteUserLevel === LevelEnum.Owner));
            })
          }
        })

        this.outdoor_units = this.outdoor_units.sort(this.compareValues(`name`, `asc`));
        this.data_loading = false;
      }
    })
  }

  async modeInfo() {
    // display mode info warning about Auto Mode

    let temp = `15 °C`;
    if (this.userService.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) temp = `59 °F`;
    let msg = TEST_MODE_AUTO_WARNING_DETAIL.replaceAll(`#TEMP#`, temp);

    const alert = await this.alertController.create({
      header: TEST_MODE_AUTO_WARNING_TITLE,
      message: msg,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',
        }
      ]
    });

    await alert.present();
  }

  readyForSubmission() {
    // are we good to press start?
    return ! this.creatingTestRun &&
           this.selected_odus.length > 0 && 
           this.selected_duration != TestDuration.unSelected &&
            this.selected_mode != TestMode.unSelected;
  }

  close() {
    this.modalController.dismiss({ 'cancel': true });
  }

  async onSelectOutdoorUnit() {
    // display options to select an ODU
    if (!this.siteService.handleIsConnected()) return;
  
    const modal = await this.modalController.create({
      component: SiteAnalyticsTestRunOduSelectComponent,
      cssClass: 'me-custom-modal-standard',
      backdropDismiss: true,
      componentProps: {
        outdoor_units: this.outdoor_units,
        selected_units: this.selected_odus
      },
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (data && data.data && 'upgrade' in data.data) {
        // close form with a redirect request.
        this.modalController.dismiss(data.data)
      } else if ( data && data.data && 'success' in data.data) {
        // successful return. Update display.
        this.selected_odus = data.data.success;
        this.selected_odus_label = this.selected_odus[0].name
        if (this.selected_odus.length > 1) this.selected_odus_label = 'Multi. units selected'
        
      }

    });
    return await modal.present();
  
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      //      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].toString().localeCompare(b[key]);
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  async onSelectDuration() {
    // display options for duration
    if (!this.siteService.handleIsConnected())
      return;
  
    const modal = await this.modalController.create({
      component: SiteAnalyticsTestRunModeDurationComponent,
      cssClass: 'me-custom-modal-standard',
      backdropDismiss: true,
      componentProps: {
        title: 'Duration',
        labels: TestDurationLabels
      },
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (data && data.data && 'selection' in data.data) {
        this.selected_duration = data.data.selection
        this.selected_duration_label = TestDurationLabels[this.selected_duration]
      }

    });
    return await modal.present();
  }

  async onSelectMode() {
    // display options for mode
    if (!this.siteService.handleIsConnected())
      return;
  
    const modal = await this.modalController.create({
      component: SiteAnalyticsTestRunModeDurationComponent,
      cssClass: 'me-custom-modal-standard',
      backdropDismiss: true,
      componentProps: {
        title: 'Mode',
        labels: TestModeLabels
      },
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (data && data.data && 'selection' in data.data) {
        this.selected_mode = data.data.selection;
        this.selected_mode_label = TestModeLabels[this.selected_mode];
      }

    });
    return await modal.present();
  }

  onPerformTestRun() {
    // ok - start it.
    if (!this.siteService.handleIsConnected()) return;

    // then we going to run one or more tests
    let test_run_objects: { [gateway_id: string]: [ string ] } = {};
    
    this.creatingTestRun = true;

    this.selected_odus.forEach( (odu: OutdoorUnitDetails) => {
      let gateway_id = odu.gateway_id;
      if ( !(gateway_id  in test_run_objects) ) {
        // then add with new value
        test_run_objects[gateway_id] = [ odu.bus_address ];
      } else {
        // then add to existing gateay
        test_run_objects[gateway_id].push(odu.bus_address);          
      }
    })
    let outdoor_units = []
    Object.entries(test_run_objects).forEach( (gw) => {
      outdoor_units.push({
        'gateway_id': gw[0],
        'odu_addresses': gw[1],
      })
    })

    let mode = TestModeLabels[this.selected_mode].toLowerCase();
    let duration = TestDurationMinutes[this.selected_duration];
    
    this.siteService.createTestRun( outdoor_units, duration, mode, this.site_id ).subscribe( (result) => {
      // process result of attempting to start a test run

      if (result && 'error' in result) {
        // then something went wrong.
        this.error_message = result.error;
        this.creatingTestRun = false;
      } else {
        // we have begun
        this.error_message = '';
        this.modalController.dismiss({'cancel': false});
      }
    }, (error) => {
      // this is the error case...
      console.log(`Create Test Run Error.`);
      this.error_message = 'Error connecting to Kenza Server, please try again later.';
      this.creatingTestRun = false;
    })
  }

}