<ion-content id="siteControlContent" class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1 id="header" name="header">Site Control</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <!-- Empty For Now -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-title class="stateMessage">
          <div class="loadingState" color="black" *ngIf="siteGatewaysLoading && !siteGatewaysLoadError">
            <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i></ion-label>
            <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
          </div>
          <div *ngIf="siteGatewaysLoadError">
            <ion-label><i>Unable to manage indoor group control at this time - please try again later.</i></ion-label>
          </div>
          <div *ngIf="!siteGatewaysLoading">
            <ion-label id="zeroStateMessage1" name="zeroStateMessage1" color="medium" *ngIf="!siteGatewaysExist"><i>There
                are no gateways currently registered to this site.</i></ion-label>
            <ion-label id="zeroStateMessage2" name="zeroStateMessage2" color="medium"
              *ngIf="siteGatewaysExist && !siteGatewayUnitsExist"><i>Configure your gateway(s) outdoor and indoor units to
                control units.</i></ion-label>
          </div>
        </ion-title>
      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <ion-grid id="controlGrid">
    <ion-row id="groupSelector">
      <ion-col size="12" style="padding-bottom: 0px">
        <app-group-selector title="Indoor Group Control" (stateMessage)="zeroStateMessage($event)" #groupSelector
          multiSelect=true [checkBoxFilters]="true" (newGroupsSelected)="controlGroups($event)">
        </app-group-selector>
      </ion-col>

      <!-- <ion-col size="12" style="padding-top: 0px" *ngIf="
          (!selectedGatewayUnit || !selectedGatewayUnit?.id) &&
          !displayDataNotAvailable">
        <ion-card id="cardNoSelectedUnit" name="cardNoSelectedUnit" *ngIf="
            (selectedGatewayUnit || !selectedGatewayUnit?.id) &&
            displayDataNotAvailable" class="ion-no-margin" me-ion-card>
          <ion-card-content>
            <div class="center">
              <p me-warning-message>
                Unable to display data at this time, please try again later.
              </p>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col> -->

      <ion-col id="controlCardView" *ngIf="!siteGatewaysLoading && siteGatewaysExist && siteGatewayUnitsExist" style="padding-top: 0px">
        <div *ngIf="maintenanceJobStatus != controlMaintenanceJobStatusEnum.NoneInMaintenance" title="{{ maintenanceJobMessage }} Progress">
          <ion-label me-maint-warning >{{ maintenanceJobMessage }} 
            <ion-label *ngIf="hasPermissionForSiteAnalytics()" (click)="activityHistory()" me-as-link>View progress</ion-label><ion-label *ngIf="hasPermissionForSiteAnalytics()">.</ion-label>
          </ion-label>
        </div>  
        <ion-card id="cardSelectUnit" name="cardSelectUnit" class="ion-no-margin" me-ion-card>
          <ion-card-content class="me-main-card-content">
            <div *ngIf="isExpiredGatewaySelected()" me-expired-block >
              <!-- copy of label control from the not expired Gateway Selected section-->
              <ion-item *ngIf="bgd.singleGroup" class="controlTitleContainer" lines="none" me-control-lbl>
                <ion-icon name="{{ bgd.icon }}" me-group-unit-icon></ion-icon>
                <ion-label id="labelUnitName" name="labelUnitName">
                  <h1>{{ bgd.label }}</h1>
                </ion-label>
              </ion-item>
              <ion-item *ngIf="!bgd.singleGroup" class="controlTitleContainer" lines="none" me-control-lbl>
                <ion-icon name="stop" me-group-batch-icon></ion-icon>
                <h1>.....</h1>
                <ion-icon name="stop" me-group-unit-icon></ion-icon>
                <h1>Batch Control <i>({{ selectedGroups.length }})</i></h1>
              </ion-item>
              <!-- ebnd of label control copied-->
              <div me-expired-spacer></div>
              <ion-label me-expired-label>One or more selected units have an expired gateway subscription. Select non expired units to view control points<ion-label *ngIf="isSiteOwner()">, or <ion-label (click)="upgradeExpiredSubscription()" me-expired-as-link>upgrade your subscription</ion-label></ion-label>.</ion-label>
            </div>

            <ion-grid *ngIf="!isExpiredGatewaySelected()" class="siteControlGrid">

              <ion-row class="siteControlRow">

                <ion-col class="leftColumnControl siteControlCol sideColumnControl me-controls-col" size="4">
                  <div class="me-controls-div">
                    <ion-list class="controlsListContainer" lines="none">

                      <ion-item *ngIf="bgd.singleGroup" class="me-control-lbl controlTitleContainer">
                        <ion-icon name="{{ bgd.icon }}" me-group-unit-icon></ion-icon>
                        <ion-label id="labelUnitName" name="labelUnitName">
                          <h1>{{ bgd.label }}</h1>
                        </ion-label>
                      </ion-item>

                      <ion-item *ngIf="!bgd.singleGroup" class="me-control-lbl controlTitleContainer">
                        <ion-icon name="stop" me-group-batch-icon></ion-icon>
                        <h1>.....</h1>
                        <ion-icon name="stop" me-group-unit-icon></ion-icon>
                        <h1>Batch Control <i>({{ selectedGroups.length }})</i></h1>
                      </ion-item>

                      <div class="controlsContainer">
                        <ion-item>
                          <div class="ion-icon-wrapper-card-header" (click)="newToggleIsExpanded(bgd.power)"
                            [title]="bgd.power.expanded ? 'Collapse' : 'Expand'">
                            <ion-icon id="togglePower" [name]="bgd.power.expanded ? 'caret-down' : 'caret-forward'"
                              color="primary" me-expand-collapse *ngIf="bgd.control_permission">
                            </ion-icon>
                          </div>
                          <ion-label id="labelPower" name="labelPower" color="primary"
                            class="me-control-label-detail-header"><strong>Power:</strong></ion-label>
                          <ion-label capitalize id="powerValue" name="powerValue">{{ bgd.power.label }}
                          </ion-label>
                        </ion-item>
                        <div *ngIf="bgd.power.expanded" class="ion-wrap ion-flex me-control_container ion-padding-start">
                          <div me-tool-tip-div>
                            <ion-button class="ion-text-center me-control me-control_text" me-control-label
                              id="btnPowerOn" name="labelPowerOn" title="Power On"
                              (click)="batchControl('Drive', powerEnum.on , 'power')" disabled="{{ bgd.power.on.disabled }}" 
                              [attr.color]="bgd.power.on.selected ? bgd.power.ignorable.status() ? 'selected': 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-control-selected': bgd.power.on.selected, 'me-control-not-selected': !bgd.power.on.selected}">
                              <span>On</span>
                            </ion-button>
                            <ion-badge *ngIf="bgd.power.on.count > 0" color="secondary" me-control-badge>
                              {{ bgd.power.on.count }}</ion-badge>
                          </div>
                          <div me-tool-tip-div>
                            <ion-button class="ion-text-center me-control me-control_text" me-control-label
                              id="btnPowerOff" name="labelPowerOff" title="Power Off" disabled="{{ bgd.power.off.disabled }}"
                              [attr.color]="bgd.power.off.selected ? bgd.power.ignorable.status() ? 'selected': 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-control-selected': bgd.power.off.selected, 'me-control-not-selected': !bgd.power.off.selected}"
                              (click)="batchControl('Drive', powerEnum.off, 'power')">
                              <span>Off</span>
                            </ion-button>
                            <ion-badge *ngIf="bgd.power.off.count > 0" color="secondary" me-control-badge>
                              {{ bgd.power.off.count }}</ion-badge>
                          </div>
                        </div>
  
                        <ion-item *ngIf="bgd.isIndoorCoil()">
                          <div class="ion-icon-wrapper-card-header" (click)="newToggleIsExpanded(bgd.mode)"
                            [title]="bgd.mode.expanded ? 'Collapse': 'Expand'">
                            <ion-icon [name]="bgd.mode.expanded ? 'caret-down' : 'caret-forward'" id="toggleMode"
                              color="primary" me-expand-collapse *ngIf="bgd.control_permission"></ion-icon>
                          </div>
                          <ion-label id="labelMode" name="labelMode" class="me-control-label-detail-header"
                            color="primary"><strong>Mode:</strong></ion-label>
                          <ion-label id="lableModeValue" name="lableModeValue" class="ion-no-padding ion-no-margin"
                            class="me-control-label-detail" capitalize>{{ bgd.mode.label }}</ion-label>
                          <ion-icon class="ion-text-center me-icon-control-dark" *ngIf="bgd.mode.label_icon != ''"
                            me-control-icon [name]="bgd.mode.label_icon"></ion-icon>
                        </ion-item>
                        <div *ngIf="bgd.mode.expanded && bgd.isIndoorCoil()"
                          class="ion-wrap ion-flex me-control_container ion-padding-start">
  
                          <div title="Mode Heat" *ngIf="bgd.mode.heat.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              id="btnModeHeat" disabled="{{bgd.mode.heat.disabled}}"
                              [attr.color]="bgd.mode.heat.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.mode.heat.selected, 'me-icon-not-selected': !bgd.mode.heat.selected}"
                              (click)="batchControl('Mode', modeEnum.heat)">
                              <ion-icon name="flame" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.heat.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.heat.count}}</ion-badge>
                          </div>
  
                          <div title="Mode Cool" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="snow" id="btnModeCool" disabled="{{bgd.mode.cool.disabled}}"
                              [attr.color]="bgd.mode.cool.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.mode.cool.selected, 'me-icon-not-selected': !bgd.mode.cool.selected }"
                              (click)="batchControl('Mode', modeEnum.cool)">
                              <ion-icon name="snow" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.cool.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.cool.count}}</ion-badge>
                          </div>
  
                          <div title="Mode Dry" *ngIf="bgd.mode.dry.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="water" id="btnModeDry" disabled="{{bgd.mode.dry.disabled}}"
                              [attr.color]="bgd.mode.dry.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.mode.dry.selected, 'me-icon-not-selected': !bgd.mode.dry.selected }"
                              (click)="batchControl('Mode', modeEnum.dry)">
                              <ion-icon name="water" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.dry.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.dry.count}}</ion-badge>
                          </div>
  
                          <div title="Mode Fan" *ngIf="bgd.mode.fan.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="aperture" id="btnModeFan" disabled="{{bgd.mode.fan.disabled}}"
                              [attr.color]="bgd.mode.fan.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected': bgd.mode.fan.selected, 'me-icon-not-selected': !bgd.mode.fan.selected }"
                              (click)="batchControl('Mode', modeEnum.fan)">
                              <ion-icon name="aperture" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.fan.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.fan.count}}</ion-badge>
                          </div>
  
                          <div title="Mode Setback" *ngIf="bgd.mode.setback.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="exit" id="btnModeSetback" disabled="{{bgd.mode.setback.disabled}}"
                              [attr.color]="bgd.mode.setback.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected': bgd.mode.setback.selected, 'me-icon-not-selected': !bgd.mode.setback.selected}"
                              (click)="batchControl('Mode', modeEnum.setback)">
                              <ion-icon name="exit" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.setback.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.setback.count}}</ion-badge>
                          </div>
  
                          <div title="Mode Auto" *ngIf="bgd.mode.auto.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="sync" id="btnModeAuto" disabled="{{bgd.mode.auto.disabled}}"
                              [attr.color]="bgd.mode.auto.selected ? bgd.mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.mode.auto.selected, 'me-icon-not-selected': !bgd.mode.auto.selected }"
                              (click)="batchControl('Mode','auto')">
                              <ion-icon name="sync" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.mode.auto.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.mode.auto.count}}</ion-badge>
                          </div>
                        </div>
  
                        <ion-item *ngIf="bgd.isLossnay()">
                          <div class="ion-icon-wrapper-card-header" (click)="newToggleIsExpanded(bgd.vent_mode)"
                            [title]="bgd.vent_mode.expanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="bgd.vent_mode.expanded ? 'caret-down' : 'caret-forward'" id="toggleVentMode"
                              color="primary" me-expand-collapse *ngIf="bgd.control_permission"></ion-icon>
                          </div>
                          <ion-label id="labelVentMode" name="labelVentMode" class="me-control-label-detail-header"
                            color="primary"><strong>Mode:</strong>
                          </ion-label>
                          <ion-label id="lableVentModeValue" name="lableVentModeValue"
                            class="ion-no-padding ion-no-margin" class="me-control-label-detail" capitalize>
                            {{ bgd.vent_mode.label }}</ion-label>
                          <ion-icon class="ion-text-center me-icon-control-dark" me-control-icon
                            *ngIf="bgd.vent_mode.label_icon != ''" [src]="bgd.vent_mode.label_icon">
                          </ion-icon>
                        </ion-item>
                        <div *ngIf="bgd.vent_mode.expanded && bgd.isLossnay()"
                          class="ion-wrap ion-flex me-control_container ion-padding-start">
  
                          <div title="Ventilation Mode Bypass" *ngIf="bgd.vent_mode.bypass.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              id="btnVentModeBypass" disabled="{{bgd.vent_mode.bypass.disabled}}"
                              [attr.color]="bgd.vent_mode.bypass.selected ? bgd.vent_mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected': bgd.vent_mode.selected, 'me-icon-not-selected': !bgd.vent_mode.selected }"
                              (click)="batchControl('VentMode', ventModeEnum.bypass, 'vent_mode')">
                              <ion-icon src="{{do.getVentModeIcon(ventModeEnum.bypass)}}" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.vent_mode.bypass.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.vent_mode.bypass.count}}</ion-badge>
                          </div>
  
                          <div title="Ventilation Mode Energy Recovery" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="snow" id="btnVentModeHeatRecovery"
                              [attr.color]="bgd.vent_mode.heat_recovery.selected ? bgd.vent_mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.vent_mode.heat_recovery.selected , 'me-icon-not-selected': !bgd.vent_mode.heat_recovery.selected }"
                              disabled="{{bgd.vent_mode.heat_recovery.disabled}}"
                              (click)="batchControl('VentMode', ventModeEnum.heat_recovery, 'vent_mode')">
                              <ion-icon src="{{do.getVentModeIcon(ventModeEnum.heat_recovery)}}" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.vent_mode.heat_recovery.count > 0" color="secondary"
                              me-control-badge-icon>{{bgd.vent_mode.heat_recovery.count}}</ion-badge>
                          </div>
  
                          <div title="Ventilation Mode Auto" *ngIf="bgd.vent_mode.auto.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon" me-control-icon
                              name="sync" id="btnVentModeAuto" disabled="{{bgd.vent_mode.auto.disabled}}"
                              [attr.color]="bgd.vent_mode.auto.selected ? bgd.vent_mode.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected':bgd.vent_mode.auto.selected, 'me-icon-not-selected': !bgd.vent_mode.auto.selected}"
                              (click)="batchControl('VentMode', ventModeEnum.auto, 'vent_mode')">
                              <ion-icon src="{{do.getVentModeIcon(ventModeEnum.auto)}}" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.vent_mode.auto.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.vent_mode.auto.count}}</ion-badge>
                          </div>
                        </div>
  
                        <ion-item>
                          <div class="ion-icon-wrapper-card-header" (click)="newToggleIsExpanded(bgd.fan_speed)"
                            [title]="bgd.fan_speed.expanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="bgd.fan_speed.expanded ? 'caret-down' : 'caret-forward'" id="toggleFanSpeed"
                              color="primary" me-expand-collapse *ngIf="bgd.control_permission"></ion-icon>
                          </div>
                          <ion-label id="labelFanSpeed" name="labelFanSpeed" class="me-control-label-detail-header"
                            color="primary">
                            <strong>Fan Speed:</strong>
                          </ion-label>
                          <ion-label id="lableFanSpeedValue" name="lableFanSpeedValue"
                            class="ion-no-padding ion-no-margin me-control-label-detail" capitalize>
                            {{bgd.fan_speed.label }}
                          </ion-label>
                          <ion-icon class="ion-text-center" *ngIf="bgd.fan_speed.label_icon != ''" icon-only
                            me-control-icon [src]="bgd.fan_speed.label_icon" class="me-icon-control-dark">
                          </ion-icon>
                        </ion-item>
  
                        <div *ngIf="bgd.fan_speed.expanded"
                          class="fanSpeedSiteControlButtonsRow ion-wrap ion-flex me-control_container ion-padding-start">
  
                          <div title="Fan Speed Disabled" *ngIf="bgd.fan_speed.none.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-icon id="btnFanSpeedNone"
                              [attr.color]="bgd.fan_speed.none.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              disabled="{{bgd.fan_speed.none.disabled}}" (click)="singleButtonControlClick($event)">
                              <ion-icon name="remove-circle" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.none.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.none.count}}</ion-badge>
                          </div>
  
                          <!-- Very Low -->
                          <div title="Fan Speed {{ do.getBatchFanSpeedDescription(0) | titlecase }}"
                            *ngIf="bgd.fan_speed.zero.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-icon id="btnFanSpeed0"
                              [attr.color]="bgd.fan_speed.zero.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              disabled="{{bgd.fan_speed.zero.disabled}}" (click)="batchControlFanSpeed(0)">
                              <ion-icon [src]="do.getBatchFanSpeedIcon(0)" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.zero.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.zero.count}}</ion-badge>
                          </div>
  
                          <!-- Low -->
                          <div title="Fan Speed {{ do.getBatchFanSpeedDescription(1) | titlecase }}"
                            *ngIf="bgd.fan_speed.one.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-iconid="btnFanSpeed1"
                              [attr.color]="bgd.fan_speed.one.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              disabled="{{bgd.fan_speed.one.disabled}}" (click)="batchControlFanSpeed(1)">
                              <ion-icon [src]="do.getBatchFanSpeedIcon(1)" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.one.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.one.count}}</ion-badge>
                          </div>
  
                          <!-- Medium -->
                          <div title="Fan Speed {{ do.getBatchFanSpeedDescription(2) | titlecase }}"
                            *ngIf="bgd.fan_speed.two.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-icon id="btnFanSpeed2"
                              [attr.color]="bgd.fan_speed.two.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              disabled="{{bgd.fan_speed.two.disabled}}" (click)="batchControlFanSpeed(2)">
                              <ion-icon [src]="do.getBatchFanSpeedIcon(2)" class="full"> </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.two.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.two.count}}</ion-badge>
                          </div>
  
                          <!-- High -->
                          <div title="Fan Speed {{ do.getBatchFanSpeedDescription(3) | titlecase }}"
                            *ngIf="bgd.fan_speed.three.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-icon id="btnFanSpeed3"
                              [attr.color]="bgd.fan_speed.three.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              disabled="{{bgd.fan_speed.three.disabled}}" (click)="batchControlFanSpeed(3)">
                              <ion-icon [src]="do.getBatchFanSpeedIcon(3)" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.three.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.three.count}}</ion-badge>
                          </div>
  
                          <div title="Fan Speed Auto" *ngIf="bgd.fan_speed.four.showing" me-tool-tip-div>
                            <ion-button icon-only class="ion-text-center me-control me-control_icon me-icon-not-selected"
                              me-control-icon id="btnFanSpeed4" disabled="{{bgd.fan_speed.four.disabled}}"
                              [attr.color]="bgd.fan_speed.four.selected ? bgd.fan_speed.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.fan_speed.four.selected, 'me-icon-not-selected': !bgd.fan_speed.four.selected }"
                              (click)="batchControl('FanSpeed', '11', 'fan_speed')">
                              <ion-icon name="sync" class="full"> </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.fan_speed.four.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.fan_speed.four.count}}</ion-badge>
                          </div>
                        </div>
  
                        <ion-item *ngIf="bgd.isIndoorCoil()">
                          <div class="ion-icon-wrapper-card-header" (click)="newToggleIsExpanded(bgd.air_direction)"
                            [title]="bgd.air_direction.expanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="bgd.air_direction.expanded ? 'caret-down' : 'caret-forward'"
                              id="toggleAirDirection" color="primary" me-expand-collapse *ngIf="bgd.control_permission">
                            </ion-icon>
                          </div>
                          <ion-label id="labelAirDirection" name="labelAirDirection"
                            class="me-control-label-detail-header" color="primary">
                            <strong>Air Direction:</strong>
                          </ion-label>
                          <ion-label id="lableAirDirectionValue" name="lableAirDirectionValue"
                            class="ion-no-padding ion-no-margin" class="me-control-label-detail" capitalize>
                            {{ bgd.air_direction.label }}
                          </ion-label>
                          <ion-icon class="ion-text-center me-icon-control-dark"
                            *ngIf="bgd.air_direction.label_icon != ''" me-control-icon
                            [src]="bgd.air_direction.label_icon">
                          </ion-icon>
                        </ion-item>
                        <div *ngIf="bgd.air_direction.expanded && bgd.isIndoorCoil()"
                          class="ion-wrap ion-flex me-control_container ion-padding-start">
  
                          <div title="Air Direction Stationary" *ngIf="bgd.air_direction.stationary.showing"
                            me-tool-tip-div>
                            <ion-button icon-only me-control-icon disabled="{{bgd.air_direction.stationary.disabled}}"
                              class="ion-text-center me-control me-control_icon me-icon-selected" id="btnAirDirStationary"
                              [attr.color]="bgd.air_direction.stationary.selected ? 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.air_direction.stationary.selected, 'me-icon-not-selected':!bgd.air_direction.stationary.selected}" 
                              (click)="singleButtonControlClick($event)">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.stationary ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.stationary.count > 0" color="secondary"
                              me-control-badge-icon>{{bgd.air_direction.stationary.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Ceiling" *ngIf="bgd.air_direction.ceiling.showing" me-tool-tip-div>
                            <ion-button icon-only me-control-icon disabled="{{bgd.air_direction.ceiling.disabled}}"
                              id="btnAirDirCeiling" class="ion-text-center me-control me-control_icon"
                              [attr.color]="bgd.air_direction.ceiling.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.air_direction.ceiling.selected, 'me-icon-not-selected':!bgd.air_direction.ceiling.selected}"
                              (click)="batchControl('AirDirection', airDirectionEnum.ceiling, 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.ceiling ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.ceiling.count > 0" color="secondary"
                              me-control-badge-icon>{{bgd.air_direction.ceiling.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction High" *ngIf="bgd.air_direction.high.showing" me-tool-tip-div>
                            <ion-button icon-only me-control-icon disabled="{{bgd.air_direction.high.disabled}}"
                              id="btnAirDirHigh" class="ion-text-center me-control me-control_icon"
                              [attr.color]="bgd.air_direction.high.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.air_direction.high.selected, 'me-icon-not-selected': !bgd.air_direction.high.selected }"
                              (click)="batchControl('AirDirection', airDirectionEnum.high, 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.high ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.high.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.air_direction.high.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Middle" *ngIf="bgd.air_direction.middle.showing" me-tool-tip-div>
                            <ion-button icon-only me-control-icon disabled="{{bgd.air_direction.middle.disabled}}"
                              id="btnAirDirMiddle" class="ion-text-center me-control me-control_icon"
                              [attr.color]="bgd.air_direction.middle.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.air_direction.middle.selected, 'me-icon-not-selected': !bgd.air_direction.middle.selected}"
                              (click)="batchControl('AirDirection', airDirectionEnum.middle, 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.middle ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.middle.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.air_direction.middle.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Low" *ngIf="bgd.air_direction.low.showing" me-tool-tip-div>
                            <ion-button icon-only disabled="{{bgd.air_direction.low.disabled}}"
                              class="ion-text-center me-control me-control_icon" me-control-icon id="btnAirDirLow"
                              [attr.color]="bgd.air_direction.low.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{ 'me-icon-selected': bgd.air_direction.low.selected, 'me-icon-not-selected': !bgd.air_direction.low.selected}"
                              (click)="batchControl('AirDirection', airDirectionEnum.low , 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.low ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.low.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.air_direction.low.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Floor" *ngIf="bgd.air_direction.floor.showing" me-tool-tip-div>
                            <ion-button icon-only disabled="{{bgd.air_direction.floor.disabled}}"
                              class="ion-text-center me-control me-control_icon" me-control-icon id="btnAirDirFloor"
                              [attr.color]="bgd.air_direction.floor.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected':bgd.air_direction.floor.selected,'me-icon-not-selected': !bgd.air_direction.floor.selectd}"
                              (click)="batchControl('AirDirection', airDirectionEnum.floor, 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.floor ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.floor.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.air_direction.floor.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Swing" *ngIf="bgd.air_direction.swing.showing" me-tool-tip-div>
                            <ion-button icon-only disabled="{{bgd.air_direction.swing.disabled}}"
                              class="ion-text-center me-control me-control_icon" me-control-icon id="btnAirDirSwing"
                              [attr.color]="bgd.air_direction.swing.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected':bgd.air_direction.swing.selected,'me-icon-not-selected': !bgd.air_direction.swing.selectd}"
                              (click)="batchControl('AirDirection', airDirectionEnum.swing, 'air_direction')">
                              <ion-icon src="{{ do.getAirDirectionIcon( airDirectionEnum.swing ) }}" class="full">
                              </ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.swing.count > 0" color="secondary" me-control-badge-icon>
                              {{bgd.air_direction.swing.count}}</ion-badge>
                          </div>
  
                          <div title="Air Direction Auto" *ngIf="bgd.air_direction.auto.showing" me-tool-tip-div>
                            <ion-button icon-only disabled="{{bgd.air_direction.auto.disabled}}"
                              class="ion-text-center me-control me-control_icon" me-control-icon id="btnAirDirAuto"
                              [attr.color]="bgd.air_direction.auto.selected ? bgd.air_direction.ignorable.status() ? 'selected' : 'primary' : 'reverse-primary'"
                              [ngClass]="{'me-icon-selected':bgd.air_direction.auto.selected,'me-icon-not-selected': !bgd.air_direction.auto.selectd}"
                              (click)="batchControl('AirDirection', airDirectionEnum.auto, 'air_direction')">
                              <ion-icon name="sync" class="full"></ion-icon>
                            </ion-button>
                            <ion-badge *ngIf="bgd.air_direction.auto.count > 0" color="secondary" me-control-badge-icon>{{bgd.air_direction.auto.count}}</ion-badge>
                          </div>
                        </div>
                      </div>
                    </ion-list>
                  </div>
                </ion-col>

                <ion-col size="3" class="middleColumnControl siteControlCol flex ion-padding-vertical ion-justify-content-center {{ multipleControlsExpanded() ? 'expandedMiddleColumnControl' : 'collapsedMiddleColumnControl' }}"
                  *ngIf="bgd.isIndoorCoil()">
                  <div class="currentTimeContainer columnTopContainer">
                    <h1 id="controlText" class="rowCol middleColumnLabel">Control</h1>
                    <h3 title="Current Site Time" class="currentSiteTimeContainer">
                      <span class="siteTime" [value]="siteTime">{{ siteTime }}</span>
                    </h3>
                  </div>
                  <ion-item thermostat-ring class="ion-text-center me-ring-col" color="transparent" lines="none">
                    <div class="me-div-container">
                      <ion-label [ngClass]="{
                        'room-temp': !bgd.inlet_temp.label.includes('...'), 
                        'room-temp-multi': bgd.inlet_temp.label.includes('...')
                      }">
                        <strong>{{ bgd.inlet_temp.label }}</strong>
                      </ion-label>
                      <ion-label class="me-temperature-label me-room-temp-text-spaceing-adjustment">Room Temp</ion-label>
                    </div>
                  </ion-item>
                </ion-col>

                <ion-col *ngIf="bgd.isLossnay()" size="3" class="middleColumnControl siteControlCol flex {{ multipleControlsExpanded() ? 'expandedMiddleColumnControl' : 'collapsedMiddleColumnControl' }} ion-padding-vertical ion-justify-content-center ion-align-items-center">
                  <div class="currentTimeContainer columnTopContainer">
                    <h1 id="controlText" class="rowCol middleColumnLabel">Control</h1>
                    <h3 title="Current Site Time" class="currentSiteTimeContainer">
                      <span class="siteTime" [value]="siteTime">{{ siteTime }}</span>
                    </h3>
                  </div>
                  <img *ngIf="bgd.vent_mode.singleMode" class="me-lossnay-big-icon"
                    src="{{ bgd.vent_mode.lossnayIconSrc }}" />
                  <ion-label *ngIf="!bgd.vent_mode.singleMode" class="me-lossnay-big-word">Mixed</ion-label>
                </ion-col>

                <ion-col size="5" class="rightColumnControl siteControlCol sideColumnControl flex ion-justify-content-center ion-align-items-center me-temp-control-col">
                  <ion-grid class="outerGrid" *ngIf="bgd.setTemp.showing">
                    <ion-row class="blueLabelRow">
                      <ion-col class="blueLabelCol">
                        <ion-label *ngIf="bgd.setTemp.isModeDisabled() && !bgd.isMaintenanceActive()" color="secondary">
                          <i><strong>Selected units for batch control must be in the same mode to adjust the Set Temperature.</strong></i>
                        </ion-label>
                        <ion-label *ngIf="bgd.setTemp.isModeSingleDual() && !bgd.isMaintenanceActive()">
                          Selected units for batch control support a mix of single and dual set points for auto mode. Selected temperature changes below will be applied to respective units.
                        </ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-grid class="innerGrid" *ngIf="bgd.setTemp.isModeSingleDual()">
                      <ion-row>
                        <ion-col size="4">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><i>Single</i></ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><br><i>Set Temp</i></ion-label>
                            <ion-badge *ngIf="bgd.setTemp.autoSingleSetPointCount > 0" color="secondary" style="position: absolute; right: 14px; top: 7px;">
                              {{ bgd.setTemp.autoSingleSetPointCount }}</ion-badge>
                          </div>
                        </ion-col>
                        <ion-col size="2.5" class="ion-text-right">
                          <ion-label style="font-size:32px;">&#9150;</ion-label>
                        </ion-col>
                        <ion-col size="3">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><i>Dual</i></ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><br><i>Set Temp</i></ion-label>
                            <ion-badge *ngIf="bgd.setTemp.autoDualSetPointCount > 0" color="secondary" style="position: absolute; top: 8px; right: 5px;">
                              {{ bgd.setTemp.autoDualSetPointCount }}</ion-badge>
                          </div>
                        </ion-col>
                        <ion-col size="2.5">
                          <ion-label style="font-size: 32px;">&#9163;</ion-label>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="4"
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.auto].max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'single')">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="4"
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max Low Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.heat].max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'dualLow')">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="4"
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max High Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.cool].max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'dualHigh')">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="4">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center">
                            <ion-label class="me-temperature-range-label me-set-temp-text-spaceing-adjustment">{{bgd.setTemp.singleSetPointLabel}}</ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment ion-text-center">
                              <br><i>Set Temp</i>
                            </ion-label>
                          </div>
                        </ion-col>
                        <ion-col size="4">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center"  title="{{bgd.setTemp.deadbandLabel}}">
                            <ion-label class="me-temperature-range-label me-set-temp-text-spaceing-adjustment">{{bgd.setTemp.dualSetPointLowLabel}}</ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment">
                              <br><i>Low</i>
                            </ion-label>
                          </div>
                        </ion-col>
                        <ion-col size="4">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center" title="{{bgd.setTemp.deadbandLabel}}">
                            <ion-label class="me-temperature-range-label me-set-temp-text-spaceing-adjustment">{{bgd.setTemp.dualSetPointHighLabel}}</ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment">
                              <br><i>High</i>
                            </ion-label>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="4" class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.auto].min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'single')">
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min Low Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.heat].min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'dualLow')" >
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min High Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.cool].min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'dualHigh')" >
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                    <ion-grid class="innerGrid" *ngIf="bgd.setTemp.isModeDual()">
                      <ion-row>
                        <ion-col>
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><i>Set Temp</i></ion-label>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="2"></ion-col>
                        <ion-col size="4"
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max Low Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.heat].max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'dualLow')">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="4"
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max High Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.cool].max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'dualHigh')">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="2"></ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="2"></ion-col>
                        <ion-col size="4" style="max-width:240px;">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center" title="{{bgd.setTemp.deadbandLabel}}">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><h1>{{bgd.setTemp.dualSetPointLowLabel}}</h1></ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment">
                              <i>Low</i>
                            </ion-label>
                          </div>
                        </ion-col>
                        <ion-col size="4">
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center" title="{{bgd.setTemp.deadbandLabel}}">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment"><h1>{{bgd.setTemp.dualSetPointHighLabel}}</h1></ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment">
                              <i>High</i>
                            </ion-label>
                          </div>
                        </ion-col>
                        <ion-col size="2"></ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col size="2"></ion-col>
                        <ion-col size="4" class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min Low Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.heat].min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'dualLow')" >
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="4" class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min High Set Temp is {{bgd.setTemp.minMaxPoints[modeEnum.cool].min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'dualHigh')" >
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                        <ion-col size="2"></ion-col>
                      </ion-row>

                    </ion-grid>
                    <ion-grid class="innerGrid" *ngIf="bgd.setTemp.isModeSingle() || bgd.setTemp.isModeDisabled()">
                      <ion-row>
                      </ion-row>
                      <ion-row>
                        <ion-col
                          class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Max Set Temp is {{bgd.setTemp.minMaxPoints[bgd.mode.firstUnitMode]?.max}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-up-margin-adjustment"
                            (click)="batchAdjustSetPoint(1, 'single')" [disabled]="bgd.setTemp.isModeDisabled()">
                            <ion-icon name="caret-up" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col>
                          <div class="ion-justify-content-center ion-align-items-center ion-text-center">
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment" [style.opacity]="bgd.setTemp.isModeDisabled() ? '.5' : '1'"><h1>{{bgd.setTemp.singleSetPointLabel}}</h1></ion-label>
                            <ion-label class="me-temperature-label me-set-temp-text-spaceing-adjustment ion-text-center" [style.opacity]="bgd.setTemp.isModeDisabled() ? '.5' : '1'">
                              <i>Set Temp</i>
                            </ion-label>
                          </div>
                        </ion-col>
                      </ion-row>
                      <ion-row>
                        <ion-col class="ion-justify-content-center ion-align-items-center me-temp-button-container">
                          <ion-button color="transparent" lines="none"
                            *ngIf="bgd.control_permission" title="Min Set Temp is {{bgd.setTemp.minMaxPoints[bgd.mode.firstUnitMode]?.min}}{{bgd.setTemp.tempSymbol}}"
                            class="ion-no-padding me-set_point-control me-set-temp-down-margin-adjustment"
                            (click)="batchAdjustSetPoint(-1, 'single')" [disabled]="bgd.setTemp.isModeDisabled()">
                            <ion-icon name="caret-down" color="medium" large-icon></ion-icon>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-grid>
                </ion-col>

              </ion-row>

            </ion-grid>

            <div id="cardFooter" class="me-card-footer">
              <div class="ion-float-left">
                <ion-text style="margin-left: 28px">Gateway connection status:
                </ion-text>
                <ion-label id="unitGatewayStatus" class="me-connect-status connected"
                  title="All selected groups gateways are online." *ngIf="bgd.gatewaysStatusOnline()">
                  <ion-icon class="me-connect-icon" name="cloud"></ion-icon> Online
                </ion-label>
                <ion-label id="unitGatewayStatus" class="me-connect-status warning"
                  title="Some selected groups gateways are online." *ngIf="bgd.gatewaysStatusWarning()">
                  <ion-icon class="me-connect-icon" name="warning-outline"></ion-icon> Some Online
                </ion-label>
                <ion-label id="unitGatewayStatus" class="me-connect-status disconnected"
                  title="None of the selected groups gateways are online." *ngIf="bgd.gatewaysStatusOffline()">
                  <ion-icon class="me-connect-icon" name="alert-circle-outline"></ion-icon> Disconnected
                </ion-label>
              </div>
              <ion-label class="ion-float-right" color="medium">Last updated: {{ showUnitLastUpdated() }}</ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>