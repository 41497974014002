import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';

@Component({
  selector: 'maintenance-history',
  templateUrl: './maintenance-history.component.html',
  styleUrls: ['./maintenance-history.component.scss'],
})
export class MaintenanceHistoryComponent /*implements OnInit*/ {

  constructor(
    public user: UserService
  ) { }

  // ngOnInit() {}

}
