import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAintenanceRoutingModule } from './maintenance-routing.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MaintenanceComponent } from '../../components/maintenance.component';
import { MaintenanceResultComponent } from '../../components/maintenance-result/maintenance-result.component';
import { MaintenanceHistoryComponent } from '../../components/maintenance-history/maintenance-history.component';
import { MaintenanceDetailComponent } from '../../components/maintenance-detail/maintenance-detail.component';
import { MaintenanceBranchComponent } from '../../components/maintenance-branch/maintenance-branch.component';
import { MaintenanceSpreadsheetComponent } from '../../components/maintenance-spreadsheet/maintenance-spreadsheet.component';
import { MaintenanceMTDZComponent } from '../../components/maintenance-mtdz/maintenance-MTDZ.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { GoogleChartsModule } from 'angular-google-charts';
import { MaintMapUnitsErrorComponent } from '../../modals/maint-map-units-error/maint-map-units-error.component';
import { MaintenanceBCPortListComponent } from '../../components/maintenance-bcport-list/maintenance-bcport-list.component';
import { MaintenanceRefrigerantListComponent } from '../../components/maintenance-refrigerant-list/maintenance-refrigerant-list.component';
import { MaintenanceTestRunComponent } from '../../components/maintenance-testrun/maintenance-testrun.component';
import { MaintBCPortNotificationComponent } from '../../modals/maint-bcport-notification/maint-bcport-notification.component';
import { MaintenanceBCPortResultComponent } from '../../components/maintenance-bcport-result/maintenance-bcport-result.component';
import { OutdoorunitService } from '../../service/outdoorunit.service';
import { MaintRefrigerantNotificationComponent } from '../../modals/maint-refrigerant-notification/maint-refrigerant-notification.component';
import { MaintenanceRefrigerantResultComponent } from '../../components/maintenance-refrigerant-result/maintenance-refrigerant-result.component';


@NgModule({
  declarations: [
    MaintenanceComponent,
    MaintenanceResultComponent,
    MaintenanceHistoryComponent,
    MaintenanceDetailComponent,
    MaintenanceBranchComponent,
    MaintenanceSpreadsheetComponent,
    MaintenanceMTDZComponent,
    MaintMapUnitsErrorComponent,
    MaintenanceBCPortListComponent,
    MaintenanceRefrigerantListComponent,
    MaintenanceTestRunComponent,
    MaintBCPortNotificationComponent,
    MaintenanceBCPortResultComponent,
    MaintRefrigerantNotificationComponent,
    MaintenanceRefrigerantResultComponent
  ],
  imports: [
    CommonModule,
    MAintenanceRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    Ng2FlatpickrModule,
    GoogleChartsModule
    ],
  providers: [
    OutdoorunitService
  ]
})
export class MaintenanceModule { }
