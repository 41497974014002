import {
  Component,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { Site } from 'src/app/features/sites/classes/site';
import { ModalController } from '@ionic/angular';
import { SiteAdminPage } from 'src/app/features/sites/components/site-admin/site-admin.page';
import { ModelState, KenzaCloudMenuIds, CognitoUserPoolCustomAttributeEnum, CognitoUserPoolCustomAttributeValueEnum } from 'src/app/enumerations/enums';
import { UserService } from '../../../common/services/user/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { SiteUI } from 'src/app/common/classes/ui';
import { Logger } from '../../../common/services/logging/log.service';
import { MainSiteUIService } from '../../../common/services/ui/main-site-ui.service';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SiteService } from '../../sites/services/site.service';
import { SiteNotificationCount } from 'src/app/common/classes/Metrics';
import {
  Observable,
  BehaviorSubject,
  Subscription,
} from 'rxjs';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { filter } from 'rxjs/operators';
import { SiteTransferAcceptanceModal } from '../../sites/pages/site-transfer-acceptance-modal/site-transfer-acceptance-modal.page';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})

export class HomePage implements OnDestroy  {
  onRouterEventSubscription: Subscription = null;

  devEnv = devEnv;
  siteList: Site[] = [];
  selectedRow = -1;
  selectedSite: Site;

  siteUIList: SiteUI[] = [];
  defaultSiteExpanded = false;
  navigationSubscription;
  viewSiteAlertEmitter = new EventEmitter<string>();
  getCurrentPageName: () => string = globalFunctions.getCurrentPageName;

  defaultSite: Site = new Site({
    id: 'default-site',
    name: 'Select Site',
    phone: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    site_photo_name: '',
    sitestatustype_id: 1,
    timezone: 'EST',
    current_owner_id:'',
    locations: []
  });

  observableSiteNotificationMetrics: Observable<SiteNotificationCount[]>;
  load$ = new BehaviorSubject('');
  lastTimeValue: string = '';
  accountSiteNotificationCounts: any;
  subscriptionSiteNotification: Subscription = null;
  onRetrivedSitesSubscription: Subscription = null;

  dataLoading: boolean;

  constructor(
    public user: UserService,
    public mainSiteUIService: MainSiteUIService,
    private router: Router,
    public modalController: ModalController,
    public logger: Logger,
    public appAuth: AppAuthenticationService,
    public siteService: SiteService,
    public socketService: SocketService,
  ) {
    // Empty
  }

  ionViewWillEnter() {
    this.dataLoading = true;
    this.user.updateSites();

    if (this.onRetrivedSitesSubscription == null) {
      this.onRetrivedSitesSubscription = this.user.retrievedSites.subscribe((sites) => {
        // remove duplicates (why would there be duplicates?)
        this.siteList = sites.filter(
          (x, y, z) => z.findIndex((t) => t.id == x.id) == y
        );
        // sort by name
        this.siteList = this.siteList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.siteUIList = this.updateSiteUIList(this.siteList);
        this.dataLoading = false;
      });
    }

    if (this.onRouterEventSubscription == null) {
      this.onRouterEventSubscription = this.router.events.
        pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
          if ((event.url == "/home")) {
            this.user.updateSites();
          }
      });
    }
  }

  ionViewDidEnter() {
    if (this.user.showTransferModal) {
      this.acceptSite(this.user.transferSiteId);
      this.user.showTransferModal = false;
      this.user.transferSiteId = "";
    }
  }

  ionViewWillLeave() {
    if (this.onRetrivedSitesSubscription != null) {
      this.onRetrivedSitesSubscription.unsubscribe();
      this.onRetrivedSitesSubscription = null;
    }
    if (this.onRouterEventSubscription != null) {
      this.onRouterEventSubscription.unsubscribe();
      this.onRouterEventSubscription = null;
    }
  }

  ngOnDestroy() {
    if (this.onRetrivedSitesSubscription != null) {
      this.onRetrivedSitesSubscription.unsubscribe();
      this.onRetrivedSitesSubscription = null;
    }
    if (this.onRouterEventSubscription != null) {
      this.onRouterEventSubscription.unsubscribe();
      this.onRouterEventSubscription = null;
    }
  }

  signOut() {
    this.user.signOut();
    this.router.navigate(['/signIn']);
  }

  trackBySiteId(index, site) {
    return site.id;
  }

  // This functionality will allow the ui to maintain the state
  // of a sites id, expanded/collapsed/status values when new
  // sites are added to the list of sites (i.e. new sites may)
  // be added by another user in a different session
  updateSiteUIList(sites: Site[]) {
    const newSiteUIList: SiteUI[] = [];
    let updatedSiteUIList: SiteUI[] = [];

    if (sites && sites.length > 0) {
      let siteIndex = 0;

      for (siteIndex = 0; siteIndex < sites.length; siteIndex++) {
        const newSiteUI = this.siteUIList.find(
          (siteUI) => siteUI.id === sites[siteIndex].id
        );
        if (newSiteUI === undefined) {
          newSiteUIList.push({
            id: sites[siteIndex].id,
            isExpanded: this.defaultSiteExpanded,
            status: '',
          });
        }
      }
    }
    updatedSiteUIList = this.siteUIList.concat(newSiteUIList);

    const siteUIIndex = 0;

    updatedSiteUIList = updatedSiteUIList.map((siteUI) => {
      siteUI.status = '';

      if (siteUIIndex % 2) {
        siteUI.status = 'alertRed';
      }

      if (siteUIIndex % 3) {
        siteUI.status = 'alertYellow';
      }
      return siteUI;
    });

    return updatedSiteUIList;
  }

  getIsSiteExpanded(site: Site) {
    if (site && this.siteUIList && this.siteUIList.length > 0) {
      const asiteUI: SiteUI = this.siteUIList.find(
        (siteUI) => siteUI.id === site.id
      );
      if (asiteUI) {
        return asiteUI.isExpanded;
      } else {
        return this.defaultSiteExpanded;
      }
    } else {
      return this.defaultSiteExpanded;
    }
  }

  toggleSiteInfo(site: Site) {
    if (site) {
      this.siteUIList.find(
        (siteUI) => siteUI.id === site.id
      ).isExpanded = !this.siteUIList.find((siteUI) => siteUI.id === site.id)
        .isExpanded;
    }
  }

  async openSiteAdmin(selectedSite: Site, siteState: ModelState) {
    const modal = await this.modalController.create({
      component: SiteAdminPage,
      cssClass: 'me-sc-ion-modal-md-h-5 createSiteForm',
      componentProps: {
        parentModelState: siteState,
        parentSelectedSite: selectedSite,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (
        data.data !== undefined &&
        data.data !== undefined &&
        data.data.adminSite !== undefined &&
        data.data.adminSite !== null
      ) {
        this.siteList = this.user.sites;
        this.siteList.sort((a, b) => a.name.localeCompare(b.name));
      }

      // Update the site rooms for the new site
      this.socketService.joinRooms();
    });
    return await modal.present();
  }

  async navigateToSiteDashboard(selectedSite: Site) {
    if (!this.siteService.handleIsConnected()) return;

    if (selectedSite.transfer_site) {
      this.acceptSite(selectedSite.id);
      return;
    }

    let siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = selectedSite.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

    await this.user.setActiveSite(selectedSite, true);
    await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);

    this.user.setActiveSiteAndNavigate(
      selectedSite,
      KenzaCloudMenuIds.Dashboard,
      KenzaCloudMenuIds.Dashboard
    );
  }

  async navigateToSiteAlerts(selectedSite: Site) {
    if (!this.siteService.handleIsConnected()) return;

    let siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = selectedSite.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

    await this.user.setActiveSite(selectedSite, true);
    await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
    await this.mainSiteUIService.viewSiteAlert('siteAlerts');
  }

  setClickedRow(rowNumber: number) {
    this.selectedRow = rowNumber;
  }

  createSite() {
    if (!this.siteService.handleIsConnected()) return;

    this.selectedSite = null;
    this.openSiteAdmin(this.selectedSite, ModelState.Create);
  }

  updateSite(site: Site) {
    this.selectedSite = site;
    this.openSiteAdmin(this.selectedSite, ModelState.Update);
  }

  async acceptSite(siteId) {
    if (!this.siteService.handleIsConnected()) return;
    if (this.mainSiteUIService.siteTransferAcceptanceOpen == true) return;

    const modal = await this.modalController.create({
      component: SiteTransferAcceptanceModal,
      cssClass: 'me-sc-ion-modal-md-h-5',
      backdropDismiss: false,
      componentProps: {
        siteId: siteId,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.mainSiteUIService.siteTransferAcceptanceOpen = false;
      if (data.data?.transferSuccessful) {
        this.router.navigate(['/home']);
      } else {
        if (data.data?.wasAuthError) { // doing this because having issue with siteList refreshing after redirecting from the 403 page
          let fixedSiteList = [];
          this.siteList.forEach(s => {
            if (s.id != siteId) {
              fixedSiteList.push(s);
            }
          });
          this.siteList = fixedSiteList;
        }

        this.router.navigate(['/home']);
      }
    });

    if (this.mainSiteUIService.siteTransferAcceptanceOpen == false) {
      this.mainSiteUIService.siteTransferAcceptanceOpen = true;
      return await modal.present();
    }
  }

}