import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'maint-bcport-notification',
  templateUrl: './maint-bcport-notification.component.html',
  styleUrls: ['./maint-bcport-notification.component.scss'],
})
export class MaintBCPortNotificationComponent{

  constructor(private modalController: ModalController) { }

  close(isCheckStart: boolean) {

    this.modalController.dismiss(
      {
        "isCheckStart": isCheckStart
      }
    );
  }
}
