<ion-header *ngIf="!isStandalone">
  <ion-toolbar>
    <ion-title slot="start">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="isStandalone ? 'main-background2' : ''">
  <div *ngIf="isStandalone" move-right>
    <ion-buttons slot="end">
      <ion-button (click)="back()" me-button-as-link id="btnBackToSubAgreement" name="btnBackToSubAgreement">
        &#8592;&nbsp;Back to Subscriber Agreement</ion-button>
    </ion-buttons>
  </div>
  <!--Compare Subscription Plan Section-->
  <ion-grid [ngClass]="isStandalone ? 'center-content2 white-background2 grid-style' : 'grid-style'">
    <ion-row>
      <ion-col col size="12" me-col-no-border me-subscription-compare-header-left me-center-grid-col-content>
        <img id="kenzaCloudLogo" name="kenzaCloudLogo" banner-logo-img src="/assets/imgs/kenza-cloud-logo-1.png">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col col me-center-grid-col-content me-center-class-name-col-content pointer
        *ngFor="let className of modelClassNames" style="border:none;" (click)=selectClassToDisplay(className)>
        <ion-label [ngClass]="{'me-highlight':className==classNameToDisplay,
        'me-standard': className!=classNameToDisplay}">{{className}}-50A</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col col col-6 me-center-grid-col-content me-center-compare-col-content *ngFor="let phr of priceHeaderRow">
        <ion-label>
          <!-- <div *ngIf="phr?.subscription_name == 'Limited'" class="me-centerText">
            {{phr.subscription_name}}
            <ion-icon class="planInfoIcon" (click)="displayPlanInformation(phr)" title="Information" name="information-circle" color="tertiary" me-information-circle-outline></ion-icon>
          </div> -->
          <div class="me-centerText">
            {{ phr.subscription_name }}
          </div>
          <div class="me-centerText priceRow">
            <span me-compare-superscript-text>$</span>
            {{phr.subscription_monthly_rate}}
            <span me-compare-subscript-text>/mo</span>
          </div>
          <!-- Hiding this message for now -->
          <!-- <div *ngIf="phr?.subscription_name == 'Standard'" class="me-centerText supportsMessage">
            (supports 1 ODU)
          </div> -->
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="contentRow">
      <ion-col class="contentCol {{ lastContent ? 'borderLeft' : '' }}" col col-6 me-center-grid-col-content me-center-compare-col-content *ngFor="let phr of priceHeaderRow; last as lastContent; first as firstContent">
        <div class="me-centerText extraContentBox">
          {{ phr.subscription_content }}
        </div>
      </ion-col>
    </ion-row>

    <ion-row *ngFor="let spd of details; last as isLast; first as isFirst" id=" subscription_plan_row"
      name="subscription_plan_row">
      <!--Feature Category Row-->
      <ion-col col col-12 *ngIf="spd.PlanRowType==planRowTypeEnum.FeatureCategory" me-start-ion-col-content
        style="border-right: none" me-col-feature-category [ngClass]="isFirst ? 'me-col-feature-category-first' : ''">
        <strong>
          <ion-label me-label-right>
            {{ spd.feature_category_name }}
          </ion-label>
        </strong>
        <div class="ion-icon-wrapper-raw-button" title="Information"
          (click)="displayInformation(spd.feature_category_name,spd.feature_category_information)">
          <ion-icon name="information-circle-outline" color="primary" me-information-circle-outline></ion-icon>
        </div>
      </ion-col>

      <!--Feature  Row-->
      <ion-col col col-6 *ngIf="spd.PlanRowType==planRowTypeEnum.FeatureDetail"
        [ngClass]="isLast ? 'me-col-feature-left-last' : 'me-col-feature-left'" me-center-grid-col-content>
        <ion-icon *ngIf="spd.feature_detail_description === 'NOT INCLUDED'" color="medium" me-ion-icon-25
          name="lock-closed">
        </ion-icon>
        <ion-label
          *ngIf="spd.feature_detail_description !== 'INCLUDED' && spd.feature_detail_description !=='NOT INCLUDED'">
          {{ spd.feature_detail_description }}
        </ion-label>
        <ion-icon *ngIf="spd.feature_detail_description === 'INCLUDED'" color="primary" me-ion-icon-25
          name="checkmark-circle">
        </ion-icon>
      </ion-col>
      <ion-col col col-6 *ngIf="spd.PlanRowType===planRowTypeEnum.FeatureDetail"
        [ngClass]="isLast ? 'me-col-feature-right-last' : 'me-col-feature-right'" me-center-grid-col-content>
        <ion-label
          *ngIf="spd.feature_detail_description_plan_1 !== 'INCLUDED' && spd.feature_detail_description_plan_1 !== 'NOT INCLUDED'">
          {{ spd.feature_detail_description_plan_1 }}
        </ion-label>
        <ion-icon *ngIf="spd.feature_detail_description_plan_1 === 'NOT INCLUDED'" color="medium" me-ion-icon-25
          name="lock-closed">
        </ion-icon>
        <ion-icon *ngIf="spd.feature_detail_description_plan_1 === 'INCLUDED'" color="primary" me-ion-icon-25
          name="checkmark-circle">
        </ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<!--Footer-->
<ion-footer *ngIf="!isStandalone">
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-button (click)="close()" style="margin: 0 0 0 0 1em;" color="primary">
          Close
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>