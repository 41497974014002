<ion-content main-white-background class="ion-text-center">
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="white">
    <ion-title color="white" class="ion-text-start">
      <h1>{{display_header}}</h1>
    </ion-title>
  </ion-toolbar>
  <div class="center">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="ion-text-center">
            <div style="white-space: pre-line">{{display_message}} </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row message-row>
        <ion-col>
          <div class="ion-text-center">
            <ion-button (click)="navigateHome()">
              Home
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>





  <!-- <div class="ion-text-center" style="height: 100%; width: 100%;">
      <div style="white-space: pre-line">{{display_message}} </div>
    </div> -->

  <!-- <div class="center">
      <ion-grid style="width:100%;border-style: solid;">
        <ion-row>
          <ion-col>
            <div class="ion-text-center">
              <div style="white-space: pre-line">{{display_message}} </div>
            </div>
          </ion-col>
        </ion-row>
  
        <ion-row message-row>
          <ion-col>
            <div class="ion-text-center">
              <ion-button (click)="navigateHome()">
                Home
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid> 
  
    </div>-->

</ion-content>