import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/constants/validators';
import { DateStringUtilities } from 'src/app/common/utilities/stringUtilities';
import { AccountDetail } from '../../classes/accountdetail';
import { UserService } from 'src/app/common/services/user/user.service';
import { ModalController, LoadingController } from '@ionic/angular';
import { States } from 'src/app/constants/states';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-account-detail-company-admin',
  templateUrl: './account-detail-company-admin.page.html',
  styleUrls: ['./account-detail-company-admin.page.scss'],
})

export class AccountDetailCompanyAdminPage implements OnInit {
  accountDetailForm: UntypedFormGroup;
  detectFormChanges: (any) = globalFunctions.detectFormChanges;
  orignalAccountDetail: AccountDetail = null;
  accountDetailText = 'Save';
  changedValue = false;
  saveEnabled = false;
  stateList: Array<any>;
  useMaskito = false;
  devEnv = devEnv;

  constructor(
    private user: UserService,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private loading: LoadingController,
    private siteService: SiteService,
  ) {
    this.stateList = States.list;
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  ngOnInit() {
    this.createAccountDetailForm();
    this.getAccountDetails();
  }

  createAccountDetailForm() {
    this.accountDetailForm = this.formBuilder.group({
      id: '',
      account_photo_name: '',
      country: '',
      last_login: null,
      removed_at: null,
      status: 0,

      fname: ['', Validators.compose([
        Validators.pattern(/\S+/)
      ])
      ],
      lname: ['', Validators.compose([
        Validators.pattern(/\S+/)
      ])
      ],
      title: ['', Validators.compose([
        Validators.pattern(/(\S+\s*){2,}/)
      ])
      ],
      phone: ['', Validators.compose([
        CustomValidators.phoneMinLength,
        Validators.maxLength(28)
      ])
      ],
      email: ['', Validators.compose([
        // Validators.required,
        Validators.email
      ])
      ],
      created_at: [''],
      company: ['', Validators.compose([
        Validators.pattern(/(\S+\s*){2,}/)
      ])
      ],
      company_phone: ['', Validators.compose([
        CustomValidators.phoneMinLength,
        Validators.maxLength(28)
      ])
      ],
      street_line1: ['', Validators.compose([
        Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
      street_line2: ['', Validators.compose([
        Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
      city: ['', Validators.compose([
        Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
      state: ['', Validators.compose([
        Validators.minLength(2)
      ])
      ],
      postal_code: [
        '',
        Validators.compose([
          Validators.pattern(/^\d{5}$|^\d{5}-$|^\d{5}-[_]{4}$|^\d{5}-\d{4}$|^[_]{5}-[_]{4}$/),
          Validators.minLength(5),
          Validators.maxLength(10),
        ]),
      ],
    });
  }

  getAccountDetails() {
    this.user.getAccountDetails().subscribe((data: any) => {
      const acctDetail: AccountDetail = new AccountDetail();

      if (data.email) {
        acctDetail.id = data.id ? data.id : '';
        acctDetail.account_photo_name = '';
        acctDetail.country = '';
        acctDetail.last_login = null;
        acctDetail.removed_at = null;
        acctDetail.status = 0;
        acctDetail.fname = data.fname ? data.fname : '';
        acctDetail.lname = data.lname ? data.lname : '';
        acctDetail.title = data.title ? data.title : '';
        acctDetail.phone = data.phone ? data.phone : '';
        acctDetail.email = data.email ? data.email : '';
        acctDetail.created_at = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy');

        acctDetail.company = data.company ? data.company : '';
        acctDetail.company_phone = data.company_phone ? data.company_phone : '';
        acctDetail.street_line1 = data.street_line1 ? data.street_line1 : '';
        acctDetail.street_line2 = data.street_line2 ? data.street_line2 : '';

        acctDetail.city = data.city ? data.city : '';
        acctDetail.state = data.state ? data.state : '';
        acctDetail.postal_code = data.postal_code ? data.postal_code : '';

      } else {
        acctDetail.id = data.id ? data.id : '';
        acctDetail.account_photo_name = '';
        acctDetail.country = '';
        acctDetail.last_login = null;
        acctDetail.removed_at = null;
        acctDetail.status = 0;
        acctDetail.fname = '';
        acctDetail.lname = '';
        acctDetail.title = '';
        acctDetail.phone = '';
        acctDetail.email = data.email;
        acctDetail.created_at = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy');

        acctDetail.company = '';
        acctDetail.company_phone = '';
        acctDetail.street_line1 = '';
        acctDetail.street_line2 = '';

        acctDetail.city = '';
        acctDetail.state = '';
        acctDetail.postal_code = '';
      }

      this.orignalAccountDetail = acctDetail;
      this.accountDetailForm.setValue(acctDetail);
      this.detectFormChanges(this.accountDetailForm);
    });
  }

  onCancel() {
    this.modalController.dismiss({
      accountDetail: null,
      cancelled: true
    });
  }

  async onSubmit() {
    if (!this.siteService.handleIsConnected()) return;
    if (this.accountDetailForm.valid) {
      const accountDetail = {
        account_photo_name: '',
        email: this.orignalAccountDetail.email,
        fname: this.orignalAccountDetail.fname,
        lname: this.orignalAccountDetail.lname,
        title: this.orignalAccountDetail.title,
        memberSince: this.orignalAccountDetail.created_at,
        phone: this.orignalAccountDetail.phone,
        company: this.accountDetailForm.value.company,
        company_phone: this.accountDetailForm.value.company_phone,
        street_line1: this.accountDetailForm.value.street_line1,
        street_line2: this.accountDetailForm.value.street_line2,
        city: this.accountDetailForm.value.city,
        state: this.accountDetailForm.value.state,
        postal_code: this.accountDetailForm.value.postal_code,
        country: ''
      };

      const load = await this.loading.create({
        spinner: 'lines',
        message: 'Saving account details...'
      });

      load.present();

      this.user.setAccountDetails(accountDetail)
        .subscribe(data => {
          load.dismiss();
          this.modalController.dismiss({
            accountDetail: accountDetail,
            cancelled: false
          });
        },
        error => {
          load.dismiss();
        });
    }
  }
}