import { Component } from '@angular/core';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { PaymentMethod } from '../../classes/PaymentMethod';
import { License } from '../../classes/License';
import { AccountService } from '../../services/accountService';
import { UntypedFormControl } from '@angular/forms';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AccountSubscriptionChangePaymentMethodComponent } from '../account-subscription-change-payment-method/account-subscription-change-payment-method.component';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { GatewaySubscription } from 'src/app/features/manage/components/classes/GatewaySubscription';
import { GatewaySubscriptionStatusTypeEnum, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle, ToastMessageTypeEnum } from 'src/app/enumerations/enums';
import { devEnv, TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE } from 'src/app/constants/kenzaconstants';
import { UserService } from 'src/app/common/services/user/user.service';
import { parseISO as dateFnsParseISO } from 'date-fns';

class SiteIterator {
  siteId: string;
  siteName: string;
  pmtMethodArray: PaymentMethod[];
  licenseArray: License[];
}

@Component({
  selector: 'app-account-payment-methods',
  templateUrl: './account-payment-methods.component.html',
  styleUrls: ['./account-payment-methods.component.scss'],
})

export class AccountPaymentMethodsComponent  {
  accountPaymentMethods: PaymentMethod[] = [];
  accountLicenses: License[] = [];
  selectedPaymentMethod = new UntypedFormControl('');
  siteGateways: Gateway[] = null;
  accountPaymentMethodsLoading: boolean;
  transferLocked = false;
  siteArray: SiteIterator[];
  devEnv = devEnv;

  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  iconMaintenanceType = `maintenance`;
  checkedPaymentMethod;

  constructor(
    public user: UserService,
    private siteService: SiteService,
    private accountService: AccountService,
    private mainSiteUIService: MainSiteUIService,
    private alertController: AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController,
  ) { 
    // Empty
  }

  ngOnInit() {
    let siteIsTransferring = this.user && this.user.active && this.user.active.transfer_locked;
    if (siteIsTransferring) {
      this.iconMaintenanceType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }
  }

  ionViewWillEnter() {
    this.getPaymentMethods();
    this.selectedPaymentMethod.reset();
  }

  onSelectPaymentMethod(paymentMethod) {
    let { pmtMethodLookupId } = paymentMethod;
    this.selectedPaymentMethod.setValue(pmtMethodLookupId);
    const selectedMethod = this.accountPaymentMethods
      .find(obj => obj.pmtMethodLookupId == pmtMethodLookupId);
    this.transferLocked = !!selectedMethod.transfer_locked;
    this.checkedPaymentMethod = paymentMethod;
  }

  async getPaymentMethods() {
    this.accountPaymentMethodsLoading = true;
    const result =  await this.accountService.getAccountPaymentMethods();
    this.accountPaymentMethods = (result.pmt_methods || []).sort(compareValues('site_name', 'asc'));
    this.accountLicenses = (result.license_array || []).sort(compareValues('site_name', 'asc'));
    // create an array of sites as siteArray with each site's associated payment methods and licenses
    let pmtMethodLookupId = 1;
    this.siteArray = [];
    this.accountPaymentMethods.forEach(pmtMethod =>{
      pmtMethod.pmtMethodLookupId = pmtMethodLookupId++;
      const findSite = this.siteArray.find(obj => obj.siteId == pmtMethod.site_id);
      if (findSite) {
        findSite.pmtMethodArray.push(pmtMethod);
      } else {
        this.siteArray.push({
          licenseArray: [],
          siteId: pmtMethod.site_id,
          pmtMethodArray: [pmtMethod],
          siteName: pmtMethod.site_name,
        });
      }
    });
    this.accountLicenses.forEach(license =>{
      license.licenseExpirationDate = dateFnsParseISO(license.license_expiration + 'Z');
      const findSite = this.siteArray.find(obj => obj.siteId == license.site_id);
      if (findSite) {
        findSite.licenseArray.push(license);
      } else {
        this.siteArray.push({
          pmtMethodArray: [],
          siteId: license.site_id,
          licenseArray: [license],
          siteName: license.site_name,
        });
      }
    });
    this.accountPaymentMethodsLoading = false;
  }

  isActionRestricted() {
    let jobTitleIndex = MaintenanceJobTypeEnum.Default;
    let siteIsTransferring = this.transferLocked;

    if (siteIsTransferring) {
      jobTitleIndex = MaintenanceJobTypeEnum.Site_Transfer;
    }

    let maintType = MaintenanceJobTypeEnumTitle[jobTitleIndex];
    let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
    this.iconMaintenanceType = maintTypeToUse.toLowerCase();

    let actionIsRestricted = siteIsTransferring;

    return actionIsRestricted;
  }

  process_payment_method_removal_request(paymentMethod: PaymentMethod) {
    if (!this.siteService.handleIsConnected())
      return;

    this.siteService.getSiteGateways(paymentMethod.site_id).subscribe(site_gateways => {
      let count_of_paid_plans: number = 0;

      site_gateways.forEach(sgw => {
        if ((sgw?.gatewaysubscription) && (sgw?.gatewaysubscription?.length > 0)) {
          let gws: GatewaySubscription[] = sgw?.gatewaysubscription;
          let count_is = gws.filter(o => (o?.subscriptions) && (o?.subscriptions?.monthly_rate > 0.0) && (o?.gateway_subscription_status_type_id != GatewaySubscriptionStatusTypeEnum.Expired) && (sgw?.coupons?.length == 0))?.length;
          count_of_paid_plans = count_of_paid_plans + count_is;
        }
      });

      if (count_of_paid_plans > 0) {
        this.showNotSelected('Remove Payment Method', 'Cannot remove payment method with an active paid gateway subscription');
        return;
      } else {
        this.remove_payment_method(paymentMethod);
      }
    });
  }

  async changePaymentMethod() {
    if (!this.siteService.handleIsConnected())
      return;

    if (!this.selectedPaymentMethod.value) {
      this.showNotSelected('Change Payment Method', 'Please select a payment method');
      return;
    }

    const paymentMethod = this.accountPaymentMethods
        .find(obj => obj.pmtMethodLookupId == this.selectedPaymentMethod.value);
    const modal = await this.modalController.create({
      component: AccountSubscriptionChangePaymentMethodComponent,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',
      backdropDismiss: false,
      componentProps: { paymentMethod }
    });

    modal.present();

    modal.onDidDismiss().then((data) => {
      if (data.data?.requiresUpdate) {
        this.getPaymentMethods();
        this.accountService.paymentDetailsUpdated = true;
      }
    });
  }

  removePaymentMethod() {
    if (!this.siteService.handleIsConnected())
      return;

    if (!this.selectedPaymentMethod.value) {
      this.showNotSelected('Remove Payment Method', 'Please select a payment method');
      return;
    }

    const paymentMethod = this.accountPaymentMethods
        .find(obj => obj.pmtMethodLookupId == this.selectedPaymentMethod.value);

    this.process_payment_method_removal_request(paymentMethod);
  }

  async remove_payment_method(paymentMethod: PaymentMethod) {
    const alert = await this.alertController.create({
      header: "Remove Payment Method",
      message: '<div style="text-align:left" class="me-redClass">You are removing the following payment method:</br>' + paymentMethod.card_type + ' ending in ' + paymentMethod.last_four + '</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',
      buttons: [{
        text: 'Remove',
        cssClass: 'exit-button',
        handler: () => {
          this.confirm_remove_payment_method(paymentMethod);
        },
      }, {
        text: 'Cancel',
        cssClass: 'back-button',

        handler: () => {
          this.alertController.dismiss();
        },
      }]
    });

    await alert.present();
  }

  async show_removing_payment_method_loader() {

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Removing payment method...'
    });

    load.present();

  }
  async confirm_remove_payment_method(paymentMethod: PaymentMethod) {
    const alert = await this.alertController.create({
      header: "Confirm Remove Payment Method",
      message: '<div style="text-align:left" class="me-redClass">Are you sure you want to remove this payment?</br>This action cannot be undone.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',
      buttons: [{
        text: 'Yes',
        cssClass: 'exit-button',
        handler: () => {

          this.alertController.dismiss();
          this.show_removing_payment_method_loader();
          const delete_site_payment_method_promise = this.accountService.delete_site_payment_method(paymentMethod.site_id);

          delete_site_payment_method_promise.then((site_gateways: Gateway[]) => {
            this.getPaymentMethods();
            this.accountService.paymentDetailsUpdated = true;
            this.selectedPaymentMethod.setValue(new UntypedFormControl(''));

          })
            .catch((catch_error) => {
              this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONNECTIVITY_ISSUE);
            })
            .finally(() => {
              this.loadingController.dismiss();
            });
        },
      }, {
        text: 'No',
        cssClass: 'back-button',

        handler: () => {
          this.remove_payment_method(paymentMethod);
        },
      }]
    });
    await alert.present();
  }

  async showNotSelected(messageHeader: string, message: string) {
    const alert = await this.alertController.create({
      header: messageHeader,
      message: '<div class="me-redClass">' + message + '</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',
      buttons: [{
        text: 'Ok',
        cssClass: 'exit-button',
        handler: () => {
          this.alertController.dismiss();
        },
      }]
    });

    await alert.present();
  }

  returnToAccountDetail() {
    this.mainSiteUIService.viewAccountDetail();
  }
}