/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, EventEmitter } from '@angular/core';
import { SiteMemberInvitee } from '../classes/site-member-invitee';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../../common/services/logging/log.service';
import { KenzaPermission } from '../../../common/classes/KenzaPermission';
import { AccountService } from '../../account/services/accountService';
import { WebserviceURL } from 'src/app/constants/webservice';
import { Gateway, GatewayModel, GatewayWithGroups } from '../../manage/components/classes/Gateway';
import { ValidateGatewayRequestData } from '../pages/site-gateway-register/classes/validateGatewayRequestData';
import { Subscription } from '../../manage/components/classes/Subscription';
import { GatewayUnit } from '../../manage/components/classes/GatewayUnit';
import { GetPaymentHistoryResponse } from '../../manage/components/classes/InvoiceDetail';
import { SubscriptionPlanDetail } from '../../manage/components/classes/SubscriptionPlanDetail';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { ToastrService } from 'ngx-toastr';
import {
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE,
  TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE,
  TOAST_CONFIG_FULL_WIDTH_SUCCESS,
  GATEWAY_NOT_ASSOCIATED_WITH_SITE,
} from 'src/app/constants/kenzaconstants';
import { catchError } from 'rxjs/operators';
import { Site } from '../classes/site';
import moment from 'moment-timezone';
import { BehaviorSubject  } from 'rxjs';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { GatewayGroup } from '../../manage/components/classes/GatewayGroup';
import { UnregisteredDevice } from '../../manage/components/classes/UnregisteredDevice';
import { ToastMessageTypeEnum, ModelState, TemperaturePreferenceEnum, PressurePreferenceEnum } from 'src/app/enumerations/enums';
import { TestRunMaintenanceJob } from 'src/app/common/classes/MaintenanceJob';
import { BranchPortCheckRequest, RefrigerantCheckRequest } from 'src/app/common/services/websockets/classes/requests/WebSocketRequest';

@Injectable({
  providedIn: 'root',
})
export class SiteService {

  profileData = {};
  siteGatewayChanged = new EventEmitter<Gateway>();

  previous_graph_from_date: Date = null;
  previous_graph_to_date: Date = null;

  previous_ou_inlet_pressure_graph_from_date: Date = null;
  previous_ou_inlet_pressure_graph_to_date: Date = null;

  previous_ou_outlet_pressure_graph_from_date: Date = null;
  previous_ou_outlet_pressure_graph_to_date: Date = null;

  siteGatewayGroupCRUDEvent = new EventEmitter<{
    modelState: ModelState;
  }>();

  siteGatewayUnitsControlled = new EventEmitter<Gateway>();
  siteGatewayUnitChanged = new EventEmitter<GatewayUnit>();
  siteGatewayDetailRefresh = new EventEmitter<Gateway>();
  siteGatewayCreated = new EventEmitter<UnregisteredDevice[]>();
  siteGatewayDecomissioned = new EventEmitter<any>();
  siteEdited = new EventEmitter<any>();
  isConnectedToInternet = true;

  eventsToMake = [];

  constructor(
    private logger: Logger,
    private http: HttpClient,
    private socket: SocketService,
    private toastService: ToastrService,
    private accountService: AccountService,
  ) {
    this.socket.ProfileUpdateEmitter.subscribe((payload) => {
      if (this.profileData[payload.device_serial]) this.profileData[payload.device_serial] = {
        units: payload.units,
        time: new Date(),
      };
    });
  }

  handleIsConnected(): boolean {
    if (!this.isConnectedToInternet) {
      this.toastService.error(
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE,
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE,
        TOAST_CONFIG_FULL_WIDTH
      );
      return false;
    }
    return true;
  }

  handleLostConnection(): boolean {
    if (!this.isConnectedToInternet) {
      this.toastService.error(
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE,
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE,
        TOAST_CONFIG_FULL_WIDTH
      );
      return false;
    }
    return true;
  }

  presentToastMessage(
    toastMessageType: ToastMessageTypeEnum,
    toastTitle: string,
    toastMessage: string
  ) {
    switch (toastMessageType) {
      case ToastMessageTypeEnum.Error:
        this.toastService.error(
          toastMessage,
          toastTitle,
          TOAST_CONFIG_FULL_WIDTH
        );
        break;

      case ToastMessageTypeEnum.Info:
        this.toastService.info(
          toastMessage,
          toastTitle,
          TOAST_CONFIG_FULL_WIDTH
        );
        break;

      case ToastMessageTypeEnum.Success:
        this.toastService.success(
          toastMessage,
          toastTitle,
          TOAST_CONFIG_FULL_WIDTH_SUCCESS
        );
        break;

      case ToastMessageTypeEnum.Warning:
        this.toastService.warning(
          toastMessage,
          toastTitle,
          TOAST_CONFIG_FULL_WIDTH
        );
        break;
    }
  }

  emit_siteGatewayUnitsControlled(gateway?: Gateway) {
    this.siteGatewayUnitsControlled.emit(gateway);
  }

  emit_siteGateChanged(gateway: Gateway) {
    this.siteGatewayChanged.emit(gateway);
  }

  emit_siteGatewayDetailRefresh(gateway: Gateway) {
    this.siteGatewayDetailRefresh.emit(gateway);
  }

  emit_siteGatewayUnitChanged(gatewayUnit: GatewayUnit) {
    this.siteGatewayUnitChanged.emit(gatewayUnit);
  }

  emit_siteGatewayGroupCRUDEvent(
    modelState: ModelState,
  ) {
    this.siteGatewayGroupCRUDEvent.emit({
      modelState: modelState,
    });
  }

  emit_siteGatewayCreated(new_gateways: UnregisteredDevice[]) {
    this.siteGatewayCreated.emit(new_gateways);
  }

  emit_siteGatewayDecomissioned(detail: any) {
    this.siteGatewayDecomissioned.emit(detail);
    this.siteEdited.emit({type: `Refresh Notifications`});
  }

  getAccountSitePermission(siteId: string, email: string) {
    return this.accountService.getAccountSitePermission(siteId, email);
  }

  setSiteAccountPermission(kenzaPermission: KenzaPermission) {
    return this.http.put(
      WebserviceURL + 'site/account/permissions',
      kenzaPermission
    );
  }

  getErrorCodeTroubleshooting(error_code: any) {
    // lookup trouble shooting details for this error code
    return this.http.get<any>(WebserviceURL + 'site/notifications/error-codes', { params: { error_code } })
  }

  dismissSiteNotification(sitenotification_id: string, acknowledged_account_id: string) {
    return this.http.put(WebserviceURL + 'site/notifications/dismiss', {
      sitenotification_id,
      acknowledged_account_id,
    });
  }

  acknowledgeGlobalNotification(sitenotification_id: string) {
  return this.http.post(WebserviceURL + `account/global-notifications/ack/${sitenotification_id}`, {});
  }

  // ***************** GATEWAY ENDPOINT REQUESTS  ************************/
  validateSiteGateway(validateGatewayRequestData: ValidateGatewayRequestData) {
    return this.http.get(WebserviceURL + 'site/gateways/validate', {
      params: { ...validateGatewayRequestData },
    });
  }

  validateDCCSiteGateway(
    validateGatewayRequestData: ValidateGatewayRequestData
  ) {
    return this.http.get(WebserviceURL + 'site/gateways/validate', {
      params: { ...validateGatewayRequestData },
    });
  }

  getSiteGateways(site_id: string, connect_status_bool = false) {
    const connect_status: string = connect_status_bool.toString();
    return this.http.get<Gateway[]>(
      WebserviceURL + 'site/gateways', { params: { site_id, connect_status } }
    );
  }

  getSiteGatewayConnectionStatus(siteId: string) {
    return this.http.get<any[]>(
      WebserviceURL + 'site/gateways/connection?site_id=' + siteId
    );
  }

  getGatewayConnectionStatus(gateway_id: string) {
    return this.http.get<any>(
      WebserviceURL + 'gateway/connection', { params: { gateway_id } }
    );
  }

  get_site_gateways(siteId: string) {
    return this.http
      .get<Gateway[]>(WebserviceURL + 'site/gateways?site_id=' + siteId)
      .toPromise();
  }

  getSiteGatewaysWithUnits(site_id: string, extended_subscription_features = false) {
    const subscription_features = extended_subscription_features ? "true" : "false"
    return this.http.get<Gateway[]>(
      WebserviceURL + 'site/gateways/units', { params: { site_id, subscription_features } }
    );
  }

  get_site_gateways_with_groups(site_id: string, extended_subscription_features = false, include_group_state_details = false) {
    const subscription_features = extended_subscription_features ? "true" : "false";
    const group_state = include_group_state_details ? "true" : "false";
    return this.http.get<GatewayWithGroups>(
      WebserviceURL + 'site/gateways/groups', { params: { site_id, subscription_features, group_state } }
    );
  }

  getGatewayDetails(site_id: string, gateway_id: string, extended_subscription_features = false) {
    const subscription_features = extended_subscription_features ? "true" : "false"
    if (site_id && gateway_id)
      return this.http.get(WebserviceURL + 'gateway/details', {
        params: { site_id, gateway_id, subscription_features }
      });
  }

  getGatewaySubscriptionPlanDetails() {
    return this.http
      .get<SubscriptionPlanDetail[]>(
        WebserviceURL + 'site/gateway/subscription/plans'
      )
      .toPromise();
  }

  getGatewayUnits(site_id: string, gateway_id: string) {
    return this.http.get<GatewayUnit[]>(
      WebserviceURL +
      'gateway/units/?site_id=' +
      site_id +
      '&gateway_id=' +
      gateway_id
    );
  }

  equipmentAlertDetail(site_id: string, sitenotification_id: string) {
    return this.http.get(
      WebserviceURL +
      'site/alerts/details?site_id=' +
      site_id +
      '&sitenotification_id=' +
      sitenotification_id
    );
  }

  siteNotification(site_id: string, sitenotification_id: string) {
    return this.http.get(
      WebserviceURL +
      'site/notifications/details?site_id=' +
      site_id +
      '&sitenotification_id=' +
      sitenotification_id
    );
  }

  accountSiteNotificationCounts() {
    return this.http.get(WebserviceURL + 'site/notifications/totals');
  }

  resetEquipmentAlert(sitenotification_id: string) {
    return this.http.post(WebserviceURL + 'site/alerts/reset', {
      sitenotification_id: sitenotification_id,
    });
  }

  updateGatewayUnit(unitUpdateRequest) {
    return this.http.put(WebserviceURL + 'gateway/units', unitUpdateRequest);
  }

  controlGatewayUnit(unitCommand) {
    return this.http.post(WebserviceURL + 'gateway/control', unitCommand);
  }

  updateGatewayName(
    last_modified_account_id: string,
    gateway_id: string,
    gateway_name: string
  ) {
    return this.http.put(WebserviceURL + 'gateway/name', {
      last_modified_account_id: last_modified_account_id,
      gateway_id: gateway_id,
      new_name: gateway_name,
    });
  }

  moveGateway( params) {
    // to enforce unique urls here - so we avoid caching issues - add an unsued
    // parameter based on now that will keep changing
    // hoping to avoid some CORS and long stall errors Murali gets.
    return this.http.post(WebserviceURL + 'site/gateway/move',
    { ...params }, { params:{ 'time': Date.now().toString() }});
  }

  decommissionGateway(site_id: string, gateway_ids: string[]) {
    return this.http.post(WebserviceURL + `gateway/decommission`, {
      site_id,
      gateway_ids
    });
  }

  decommissionGatewayPromise(site_id: string, gateway_ids: string[]) {
    return this.decommissionGateway(site_id, gateway_ids).toPromise();
  }

  // ***************** INVITE ENDPOINT REQUESTS  ************************/
  inviteSiteMembers(siteMemberInvitees: SiteMemberInvitee[]) {
    return this.http.post(WebserviceURL + 'invite/inviteSiteMembers/', {
      invitees: siteMemberInvitees,
      site_id: siteMemberInvitees[0]?.site_id
    });
  }

  // ***************** SITE ENDPOINT REQUESTS  ************************/
  getSite(site_id: string) {
    return this.http.get(WebserviceURL + 'site?site_id=' + site_id);
  }

  async getSiteDetail(site_id: string): Promise<Site> {
    return this.http
      .get<Site>(WebserviceURL + 'site?site_id=' + site_id)
      .toPromise();
  }

  getSitesForAccount(id) {
    return this.http.get(WebserviceURL + 'site/sites');
  }

  isAMember(site_id:string) {
    // is the logged in user a member of this site?  and what is the level therein
    return this.http.get(WebserviceURL + 'site/isAMember',
    { params: {site_id} })
  }

  // get_sites_for_account(id) {
  //   return this.http.get(WebserviceURL + 'site/sites').toPromise();
  // }

  getAccountSitesByLevel(account_id: string, level) {
    return this.http.get(
      WebserviceURL + 'account/sites/level',
      { params: { account_id, level } }
    );
  }

  createSite(obj) {
    return this.http.post(WebserviceURL + 'site', obj);
  }

  createSiteEvent(eventObject) {
    return this.http.post(WebserviceURL + 'schedule/event', eventObject);
  }

  createBatchEvent(batchEventObject) {
    return this.http.post(WebserviceURL + 'schedule/batch/event', batchEventObject);
  }

  removeGroupFromBatchEvent(batchID, siteID, arrayOfGroupIDs) {
    return this.http.put(WebserviceURL + `schedule/batch/event/remove`, {
      id: batchID,
      site_id: siteID,
      remove: arrayOfGroupIDs
    })
  }

  updateBatchEvent(batchEventObject) {
    return this.http.put(WebserviceURL + 'schedule/batch/event', batchEventObject);
  }
  
  deleteBatchEvent(siteID, batchID) {
    return this.http.delete(WebserviceURL + `schedule/batch/event?site_id=${siteID}&batch_id=${batchID}`);
  }

  updateSiteEvent(eventObject) {
    return this.http.put(WebserviceURL + 'schedule/event', eventObject);
  }

  deleteSiteEvent(gatewayID, eventID) {
    return this.http.delete(WebserviceURL + `schedule/event?gateway_id=${gatewayID}&id=${eventID}`);
  }

  getSiteEvents(siteID) {
    return this.http.get(`${WebserviceURL}schedule/events?site_id=${siteID}`);
  }

  getSiteTodaysEvents(site_id) {
    const params = { site_id };
    return this.http.get<any>(WebserviceURL + 'schedule/events/today', { params })
  }

  updateSite(obj) {
    return this.http.put(WebserviceURL + 'site/update', obj);
  }

  deactivate_site(site: Site) {
    this.siteEdited.emit({ type: `Site Deactivated` });
    return this.http.put(WebserviceURL + 'site/deactivate', site);
  }

  removeSiteMember(site_id: string, account_id: string) {
    const url =
      WebserviceURL + `site/member/remove?site_id=${site_id}&account_id=${account_id}`;
    return this.http.delete(url);
  }

  uninviteMember(id, site_id) {
    return this.http.post(WebserviceURL + 'invite/deleteSiteInvitation/', {
      invite_id: id,
      site_id:site_id
    });
  }

  checkSitePopups(id) {
    return this.http.get(WebserviceURL + 'invite/confirm/?id=' + id);
  }

  gatewayState(gateway: Gateway) {
    return this.http.get(WebserviceURL + 'gateway/state?gateway_id=' + gateway.id);
  }

  registerGateways(gateways) {
    return this.http.post(WebserviceURL + 'gateway/register/', {
      gateways: gateways,
    });
  }

  // ***************** GROUP ENDPOINT REQUESTS  ************************/

  original_create_gateway_group(gateway_id: string, group_name: string) {
    return this.http.post(WebserviceURL + 'gateway/groups', {
      gateway_id: gateway_id,
      group_name: group_name,
    });
  }

  create_gateway_group(gateway_id: string, group_name: string, units: GatewayUnit[], location:string) {
    return this.http.post(WebserviceURL + 'gateway/groups', {
      gateway_id: gateway_id,
      group_name: group_name,
      units: units,
      location: location
    });
  }

  update_gateway_group(
    gateway_id: string,
    group_id: string,
    group_name: string,
    units: GatewayUnit[],
    location: string
  ) {
    return this.http.put(WebserviceURL + 'gateway/groups', {
      gateway_id: gateway_id,
      group_id: group_id,
      group_name: group_name,
      units: units,
      location: location
    });
  }

  original_update_gateway_group(
    gateway_id: string,
    group_id: string,
    group_name: string
  ) {
    return this.http.put(WebserviceURL + 'gateway/groups', {
      gateway_id: gateway_id,
      group_id: group_id,
      group_name: group_name,
    });
  }

  delete_gateway_group(gateway_id: string, group_id: string) {
    const url = WebserviceURL + `gateway/groups?gateway_id=${gateway_id}&group_id=${group_id}`;
    return this.http.delete(url);
  }

  get_gateway_groups_by_gateway_id(gateway_id: string) {
    return this.http.get(
      WebserviceURL + 'gateway/groups?gateway_id=' + gateway_id
    );
  }

  getMccStatus(device_serial: string, registration_code: string) {
    return this.http.get(WebserviceURL + 'gateway/mcc/' + device_serial + '/status?registration_code=' + registration_code);
  }

  // ***************** SITE PHOTO ENDPOINT REQUESTS  ************************/
  removeDBSitePhoto(site_id): Promise<boolean> {
    return this.http
      .put<boolean>(WebserviceURL + 'site/photo/remove', { site_id })
      .toPromise();
  }

  saveDBSitePhoto(site: Site): Promise<boolean> {
    return this.http
      .put<any>(WebserviceURL + 'site/photo', site)
      .toPromise();
  }

  getPlans(): Promise<Subscription[]> {
    return this.http
      .get<Subscription[]>(WebserviceURL + 'billing/plans')
      .toPromise();
  }

  getGatewayModels(): Promise<GatewayModel[]> {
    return this.http
      .get<GatewayModel[]>(WebserviceURL + 'gateway/models')
      .toPromise();
  }

  getChargePreview(params) {
    return this.http
      .post<any>(WebserviceURL + 'billing/preview', { ...params })
      .toPromise();
  }

  registerDevice(params) {
    return this.http
      .post<any>(WebserviceURL + 'billing/subscribe', { ...params })
      .toPromise();
  }

  updateDeviceSubscription(params) {
    return this.http
      .put<any>(WebserviceURL + 'billing/subscribe', { ...params })
      .toPromise();
  }

  getPaymentMethods(site_id) {
    let params = {};

    if (site_id) {
      params = { site_id };
    }

    return this.http
      .get<any>(WebserviceURL + 'billing/payment-methods', { params })
      .toPromise();
  }

  getPaymentHistory(pagination_key: string) {
    return this.http.get<GetPaymentHistoryResponse>(WebserviceURL + 'invoice/list', { params: { pagination_key } } );
  }

  updatePaymentMethod(params) {
    return this.http.put<any>(WebserviceURL + 'billing/payment-method', params).toPromise();
  }

  downloadRefrigerantCheck(maintenancejob_history_id: string) {
    return this.http.get(WebserviceURL + `mfk/refrig/download/` + maintenancejob_history_id, { responseType: `blob` });
  }

  downloadInvoice(invoice_id: string, site_id: string) {
    return this.http.get(WebserviceURL + '/invoice/download', { responseType: 'blob', params: { invoice_id, site_id }});
  }

  // downloadCsvFile(gatewayId, fileName) {
  //   return this.http.get(WebserviceURL +
  //     'site/analytics/csv/' +
  //     '?gateway_id=' + gatewayId +
  //     '&file_name=' + fileName,
  //     { responseType: 'arraybuffer' });
  // }

  downloadMtdzFile(site_id, file_name) {
    return this.http.get(WebserviceURL + 'site/analytics/mtdz',
      {
        params: { site_id, file_name },
        responseType: 'arraybuffer'
      }
    )
  }

  downloadRmdMtdzFile(site_id: string, file_name: string) {
    // rewquest a get of this file - as an arraybuffer
    return this.http.get(`${WebserviceURL}site/analytics/rmd/mtdz`,
      {
        params: { site_id, file_name },
        responseType: 'arraybuffer'
      }
    )
  }

  downloadGifFile(siteId, fileName) {
    return this.http.get(WebserviceURL +
      'site/analytics/gif' +
      '?site_id=' + siteId +
      '&file_name=' + fileName,
      { responseType: 'arraybuffer' });
  }

  startFirmwareUpdate(gateway_id, serial_number) {
    const resp = this.http.post(`${WebserviceURL}gateway/mcc/${serial_number}/firmware/update`, {
      gateway_id: gateway_id
    });
    return resp;
  }

  getFirmwareUpdateStatus(gateway_id, serial_number) {
    const resp = this.http.get(`${WebserviceURL}gateway/mcc/${serial_number}/firmware/update/status?gateway_id=${gateway_id}`);
    return resp;
  }

  getFirmwareVersion(gateway_id, serial_number) {
    const resp = this.http.get(`${WebserviceURL}gateway/mcc/${serial_number}/firmware?gateway_id=${gateway_id}`);
    return resp;
  }

  // ***************** TEST RUN REQUESTS  ************************/
  getTestrunHistory(gateway_id) {
    return this.http.get(
      WebserviceURL + 'mfk/test_run/history',
      { params: {gateway_id } }
    );
  }

  getTestrunHistory2() {
    // get the test run histories this account should see
    return this.http.get(WebserviceURL + 'mfk/test-run/history');
  }

  getMaintenancejobProgress(id, site_id){
    return this.http.get(
      WebserviceURL + 'mfk/mtdz/progress',
      { params: {id, site_id} }
    );
  }

  // ***************** CHECK MAP UNITS  ************************/
  checkLock(serial_no, site_id){
    return this.http.get(
      WebserviceURL + 'mfk/lock/check',
      { params: {serial_no, site_id} }
    );
  }

  // ***************** CHECK OAT TEMP  ************************/
  getOATemp(serial_no, address, site_id){
    return this.http.get(
      WebserviceURL + 'mfk/test_run/oa_temperature',
      { params: {serial_no, address, site_id} }
    );
  }

  // ***************** CHECK MACHINE NAME  ************************/
  getMachineDetail(gateway_id, address){
    return this.http.get(
      WebserviceURL + 'mfk/test_run/machine_detail',
      { params: {gateway_id, address} }
    );
  }

  // ***************** CHECK MACHINE DETAIL  ************************/
  getUnitHistory(gateway_id, unit_history_id){
    return this.http.get(
      WebserviceURL + 'mfk/test_run/history/unit',
      { params: {gateway_id, unit_history_id} }
    );
  }

  // ************** CHECK UNIT FUNCTION AVAILABLE ********************/
  getUnitIncorrectportconnectionsetting(unit_id, site_id) {
    return this.http.get(
      WebserviceURL + 'site/getUnitIncorrectportconnectionsetting/',
      { params: { unit_id, site_id } }
    );
  }

  getRecentRefrigerantChecks(site_id: string, details: boolean = false) {
    let param = { site_id , details };
    return this.http.get<[RefrigerantCheckRequest]>(WebserviceURL + `mfk/refrig`, { params: param });
  }

  createRefrigerantCheck(parameters) {
    let { request_id, gateway_id, site_id, modelName, unitSerialNo, address, loopNumber, gatewaySerialNo } = parameters;
    return this.http.post<RefrigerantCheckRequest>(WebserviceURL + `mfk/refrig`, {
      gatewaySerialNo,
      unitSerialNo, 
      request_id,
      gateway_id,
      loopNumber,
      modelName, 
      site_id,
      address, 
    })
  }

  getRecentBranchPortChecks(site_id: string, details: boolean = false) {
    let param = { site_id , details };
    return this.http.get<[BranchPortCheckRequest]>(WebserviceURL + `mfk/bcport`, { params: param });
  }

  createBranchPortCheck(parameters) {
    let { request_id, gateway_id, site_id, modelName, unitSerialNo, address, gatewaySerialNo } = parameters;
    return this.http.post<BranchPortCheckRequest>(WebserviceURL + `mfk/bcport`, {
      gatewaySerialNo,
      unitSerialNo, 
      request_id,
      gateway_id,
      modelName, 
      site_id,
      address, 
    })
  }

  getUnitRefrigerant(unit_id, site_id) {
    return this.http.get(
      WebserviceURL + 'site/getUnitRefrigerant/',
      { params: { unit_id, site_id } }
    );
  }

  getUnitHistoryTop(gateway_id){
    return this.http.get(
      WebserviceURL + 'mfk/test_run/history/unit/top',
      { params: {gateway_id} }
    );
  }

  transferSite(site_id: string, email: string) {
    return this.http
      .post<any>(WebserviceURL + 'site/transfer', {
        site_id: site_id,
        email: email
       })
      .toPromise();
  }

  checkTransferWindow(id) {
    return this.http.get(`${WebserviceURL}site/transfer/check?id=${id}`);
  }

  acceptTransfer(params) {
    return this.http.post(WebserviceURL + 'site/transfer/accept', {...params });
  }

  cancelTransfer(siteId) {
    const url = WebserviceURL + `site/transfer?site_id=${siteId}`;
    return this.http.delete(url);
  }

  getModelsList() {
    return this.http.get<unknown>(WebserviceURL + 'models/list').toPromise();
  }

  getAllModelInfo() {
    return this.http.get<unknown>(WebserviceURL + 'models').toPromise();
  }

  createTestRun( test_run_objects, duration, mode, site_id ) {
    // request a test run ...
    return this.http.post<any>(WebserviceURL + 'mfk/test-run', {
      gateways: test_run_objects,
      duration: duration,
      mode: mode,
      site_id: site_id
     })
  }

  getActiveTestRuns(site_id: string, details: boolean = false) {
    // request a list of the active test run jobs for this site
    let param = { site_id , details };
    return this.http.get<[TestRunMaintenanceJob]>(WebserviceURL + 'mfk/test-run', { params: param });
  }

  deleteMaintenanceHistoryJob(maintenance_history_job_id: string) {
    // reqeust this maintenance job history item to be delete.
    return this.http.delete(WebserviceURL + `mfk/test-run/${maintenance_history_job_id}`);
  }

  cancelMaintenanceJob(maintenacejob_id: string) {
    // request this job cancel
    return this.http.post(WebserviceURL + `mfk/test-run/cancel/${maintenacejob_id}`,{});
  }

  // Drive Download Data Methods
  getEquipmentNotificationDownloadDriveDataStatus(equipmentNotification_id:string, site_id:string) {
    // request status of download drive data job
    let param = { site_id }
    return this.http.get(`${WebserviceURL}mfk/drivedata/${equipmentNotification_id}`, { params: param } )
  }

  startEquipmentNotificationDownloadDriveData(equipmentNotification_id:string, site_id:string) {
    // request the start of download drive data job
    return this.http.post(`${WebserviceURL}mfk/drivedata/${equipmentNotification_id}`,
      { 'site_id': site_id }
    )
  }

  downloadEquipmentNotificationDriveData(equipmentNotification_id:string, site_id:string) {
    // request a download
    let param = { site_id }
    return this.http.get(`${WebserviceURL}mfk/drivedata/${equipmentNotification_id}/download`, { params: param, responseType: 'blob' } )
  }

  deleteEquipmentNotificationDownloadDriveData(equipmentNotification_id:string, site_id:string) {
    // cleanup
    let param = { site_id }
    return this.http.delete(`${WebserviceURL}mfk/drivedata/${equipmentNotification_id}`, { params: param } )
}

  // Weather Variables
  weatherData: any = null;
  weatherDataError = false;
  weatherDataLoaded = false;
  weatherDataLoading = false;
  public _weatherDataLoaded = new BehaviorSubject<boolean>(false);
  public weatherDataLoaded$ = this._weatherDataLoaded.asObservable();

  // Get Weather Data from Server using Open Weather Maps
  fetchWeatherData(siteID) {
    return this.http.get(WebserviceURL + `site/${siteID}/weather`).pipe(
      catchError(error => {
        this.weatherDataError = true;
        return error;
      })
    );
  }

  getConditionIcon(weatherData, customCondition?) {
    let condition = customCondition ? customCondition : weatherData?.siteCondition;
    if (condition) {
      if (condition?.toLowerCase().includes(`rain`)) {
        return `/assets/icon/weatherIcons/colored/Rainy.svg`;
      } else if (condition?.toLowerCase().includes(`few clouds`)) {
        return `/assets/icon/weatherIcons/colored/PartlySunny.svg`;
      } else if (condition?.toLowerCase().includes(`clouds`) || condition?.toLowerCase().includes(`haze`)) {
        return `/assets/icon/weatherIcons/colored/Clouds.svg`;
      } else if (condition?.toLowerCase().includes(`mist`)) {
        return `/assets/icon/weatherIcons/colored/Mist.svg`;
      } else if (condition?.toLowerCase().includes(`snow`)) {
        return `/assets/icon/weatherIcons/colored/Snow.svg`;
      } else if (condition?.toLowerCase().includes(`thunder`)) {
        return `/assets/icon/weatherIcons/colored/Thunderstorm.svg`;
      } else {
        return `/assets/icon/weatherIcons/colored/Sunny.svg`;
      }
    }
  }

  // Turn Weather Data from Server into Usable Client Data
  generateWeatherData(weather, user, active) {
    let zip;
    let lat;
    let lng;
    let city;
    let state;
    this.weatherData = null;
    if (!active && user?.active) active = user?.active;

    // Example Format `Friday, March 31st, 3:29:38 AM`
    let calendarTimeFormat = `dddd, MMMM Do, h:mm:ss A`;
    let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let siteTimezone = weather.timezone || active.timezone || browserTimezone;
    const formatDateWithMoment = (timestamp: number, timezone: string) => {
      let shortTime = moment.unix(timestamp).tz(timezone).format(`h A`);
      let dayCastMonthDay = moment.unix(timestamp).tz(timezone).format(`Do`);
      let updatedTime = moment.unix(timestamp).tz(timezone).format(`h:mm A`);
      let dayCastWeekDay = moment.unix(timestamp).tz(timezone).format(`ddd`); // Fri
      let dateTime = moment.unix(timestamp).tz(timezone).format(calendarTimeFormat);
      let toolTipDateTime = moment.unix(timestamp).tz(timezone).format(`ddd Do, h A`);
      return {
        dateTime,
        shortTime,
        updatedTime,
        dayCastWeekDay,
        dayCastMonthDay,
        toolTipDateTime,
      };
    };

    let dt = weather?.current?.dt;
    let siteShortTime = moment().tz(siteTimezone).format(`h A`);
    let siteTime = formatDateWithMoment(dt, siteTimezone)?.dateTime;
    let siteLastUpdated = formatDateWithMoment(dt, siteTimezone)?.updatedTime;
    let openWeatherLastUpdated = siteTime;

    if (user?.active && user?.active?.id != `default-site`) {
      zip = !active?.zip ? user?.active?.zip : active?.zip;
      city = !active?.city ? user?.active?.city : active?.city;
      state = !active?.state ? user?.active?.state : active?.state;

      if (active) {
        lat = active?.lat;
        lng = active?.lng;
      } else {
        lat = user?.active?.lat;
        lng = user?.active?.lng;
      }
    }

    const convertFromKelvinToFahrenheit = (temp) => globalFunctions?.removeTrailingZeroDecimal(((temp - 273.15) * 1.8 + 32.00).toFixed(0));
    const convertCelsiusToFahrenheit = (celsius) => globalFunctions?.removeTrailingZeroDecimal(((celsius * 9 / 5) + 32).toFixed(0));
    const convertFromKelvinToCelsius = (temp) => globalFunctions?.removeTrailingZeroDecimal((temp - 273.15).toFixed(1));

    let lastUpdatedTimeFormat = (date) => {
      let dateObject = new Date(date + 'Z');
      let normalTimeFormat = dateObject.toLocaleString([], { hour: `numeric`, minute: `2-digit` });
      return normalTimeFormat;
    }

    const formatOrdinal = (n) => {
      const s = [`th`, `st`, `nd`, `rd`];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    const formatUnix = (timestamp, getDay?, getHour?) => {
      const days = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
      const months = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
      const date = new Date(timestamp * 1000);

      const dayName = days[date.getDay()];
      const monthName = months[date.getMonth()];
      const dayOfMonth = formatOrdinal(date.getDate());
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, `0`);
      const seconds = String(date.getSeconds()).padStart(2, `0`);
      const amPm = hours >= 12 ? `PM` : `AM`;
      const hours12 = hours % 12 || 12;

      if (getDay) {
        return `${dayName?.split(``)?.slice(0, 3)?.join(``)}, ${dayOfMonth}`;
      } else if (getHour) {
        return `${hours12} ${amPm}`;
      } else {
        return `${dayName}, ${monthName} ${dayOfMonth}, ${hours12}:${minutes}:${seconds} ${amPm}`;
      }
    }

    // Weather Data for Front End
    let lastUpdated = formatDateWithMoment(dt, browserTimezone)?.updatedTime || lastUpdatedTimeFormat(new Date(new Date(Date.now()).toLocaleString(`en-US`, { year: `numeric`, month: `2-digit`, day: `2-digit`, hour: `numeric`, minute: `2-digit`, hour12: true })).toISOString().replace(`Z`, ``).replace(`T`, ` `));

    let tempPref = user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? `Fahrenheit` : `Celsius`;
    let pressurePref = user?.accountPreferences?.pressurepreference_id == PressurePreferenceEnum.PoundFourcePerSquareInch ? `psi` : `kPa`;

    weather = { ...weather, current: { ...weather?.current, openWeatherLastUpdated: formatDateWithMoment(weather?.current?.dt, siteTimezone)?.dateTime } };
    let { current, hourly, daily, minutely } = weather;
    let humidity = current?.humidity;
    let pressure = current?.pressure;
    let dewPoint = current?.dew_point;
    let psi = (pressure * 0.0145038).toFixed(1);
    let kPa = (pressure * 0.1).toFixed(1);
    let pop = globalFunctions?.removeTrailingZeroDecimal((hourly[0].pop * 100).toFixed(1));
    let siteCondition = globalFunctions.capWords(current?.weather[0]?.description);
    let fahrenheitDegrees = convertFromKelvinToFahrenheit(current?.temp);
    let fahrenheitMin = convertFromKelvinToFahrenheit(daily[0]?.temp?.min);
    let fahrenheitMax = convertFromKelvinToFahrenheit(daily[0]?.temp?.max);
    let celsiusDegrees = parseFloat(convertFromKelvinToCelsius(current?.temp));
    let celsiusMin = convertFromKelvinToCelsius(daily[0]?.temp?.min);
    let celsiusMax = convertFromKelvinToCelsius(daily[0]?.temp?.max);
    let celsiusDewPoint = convertFromKelvinToCelsius(dewPoint);
    let fahrenheitDewPoint = convertFromKelvinToFahrenheit(dewPoint);

    // Wet Bulb Stull Formula
    const wetBulbStullFormula = (temperatureInCelsius, relativeHumidityPercentage) => {
      return (temperatureInCelsius * Math.atan(0.151977 * Math.sqrt(relativeHumidityPercentage + 8.313659)) + Math.atan(temperatureInCelsius + relativeHumidityPercentage) - Math.atan(relativeHumidityPercentage - 1.676331) + (0.00391838 * Math.pow(relativeHumidityPercentage, 1.5)) * Math.atan(0.023101 * relativeHumidityPercentage) - 4.686035).toFixed(1);
    }

    let celsiusWetBulb = wetBulbStullFormula(celsiusDegrees, humidity);
    let celsiusDryBulb = globalFunctions?.removeTrailingZeroDecimal(celsiusDegrees);

    let fahrenheitWetBulb = convertCelsiusToFahrenheit(celsiusWetBulb);
    let fahrenheitDryBulb = convertCelsiusToFahrenheit(celsiusDryBulb);

    daily = daily ? daily.map(d => {
      return {
        ...d,
        dateTime: formatUnix(d?.dt)
      }
    }) : [];

    hourly = hourly ? hourly.map(h => {
      return {
        ...h,
        tzTime: formatDateWithMoment(h?.dt, siteTimezone)?.shortTime,
        dateTime: formatDateWithMoment(h?.dt, siteTimezone)?.dateTime,
        toolTipDateTime: formatDateWithMoment(h?.dt, siteTimezone)?.toolTipDateTime,
        // 1 m/s is equal to 2.23694 mph according to the formula
        // Some calculators use 2.24 or 2.237 instead of the official 2.23694 used by Google
        windSpeed: (h?.wind_speed * 2.23694).toFixed(1),
        fTemp: convertFromKelvinToFahrenheit(h?.temp),
        cTemp: convertFromKelvinToCelsius(h?.temp),
      }
    }) : [];

    minutely = minutely ? minutely.map(m => {
      return {
        ...m,
        dt: formatUnix(m?.dt)
      }
    }) : [];

    let fiveDay = daily ? daily?.slice(1,6).map(day => {
      return {
        ...day,
        dayTitle: `${formatDateWithMoment(day?.dt, siteTimezone)?.dayCastWeekDay}, ${formatDateWithMoment(day?.dt, siteTimezone)?.dayCastMonthDay}`,
        dayCastWeekDay: formatDateWithMoment(day?.dt, siteTimezone)?.dayCastWeekDay,
        dayCastMonthDay: formatDateWithMoment(day?.dt, siteTimezone)?.dayCastMonthDay.replace(/(\d+)(\D+)/, (match, number, ordinal) => number),
        dayOfMonthOrdinal: formatDateWithMoment(day?.dt, siteTimezone)?.dayCastMonthDay.replace(/(\d+)(\D+)/, (match, number, ordinal) => ordinal),
        dayOfMonth: formatUnix(day?.dt, true, false).split(`, `)[1].replace(/(\d+)(\D+)/, (match, number, ordinal) => number),
        dayOfWeek: formatUnix(day?.dt, true, false).split(`, `)[0],
        dayF: convertFromKelvinToFahrenheit(day?.temp?.day),
        dayC: convertFromKelvinToCelsius(day?.temp?.day),
        nightF: convertFromKelvinToFahrenheit(day?.temp?.night),
        nightC: convertFromKelvinToCelsius(day?.temp?.night),
        maxF: convertFromKelvinToFahrenheit(day?.temp?.max),
        maxC: convertFromKelvinToCelsius(day?.temp?.max),
        day: formatUnix(day?.dt, true, false).split(`, `)[1],
        minF: convertFromKelvinToFahrenheit(day?.temp?.min),
        minC: convertFromKelvinToCelsius(day?.temp?.min),
        hour: formatUnix(day?.dt, false, true),
      }
    }) : [];

    let fourDay = fiveDay?.slice(0,4);

    let onPageLoad = moment().format(`h:mm A`);
    let weLastUpdatedShort = moment().format(`h:mm A`);
    let weLastUpdated = moment().format(calendarTimeFormat);
    let weLastUpdatedSite = moment().tz(siteTimezone).format(`h:mm A`);

    return {
      dt,
      zip,
      pop,
      psi,
      kPa,
      lat,
      lng,
      city,
      state,
      daily,
      hourly,
      active,
      fourDay,
      fiveDay,
      current,
      weather,
      minutely,
      siteTime,
      humidity,
      pressure,
      dewPoint,
      tempPref,
      onPageLoad,
      celsiusMax,
      celsiusMin,
      lastUpdated,
      pressurePref,
      siteTimezone,
      weLastUpdated,
      siteCondition,
      siteShortTime,
      fahrenheitMax,
      fahrenheitMin,
      celsiusDegrees,
      celsiusWetBulb,
      celsiusDryBulb,
      celsiusDewPoint,
      siteLastUpdated,
      fahrenheitDegrees,
      fahrenheitWetBulb,
      fahrenheitDryBulb,
      weLastUpdatedSite,
      weLastUpdatedShort,
      fahrenheitDewPoint,
      openWeatherLastUpdated,
    };
  }
}