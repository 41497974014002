import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Logger } from '../../../common/services/logging/log.service';
import { KenzaPermission } from '../../../common/classes/KenzaPermission';
import { AccountDetail } from '../../../features/account/classes/accountdetail';
import { WebserviceURL } from 'src/app/constants/webservice';
import { PaymentHistory } from '../classes/PaymentHistory';
import { catchError } from 'rxjs/operators';
import { retry } from 'rxjs/operators';
import { TemperaturePreference } from '../classes/TemperaturePreference';
import { PressurePreference } from '../classes/PressurePreference';
import { AccountPreferences } from '../classes/AccountPreferences';
import {firstValueFrom, Observable} from 'rxjs';
import {GetAccountPaymentResult} from '../classes/HttpResult';
// import { CelsiusToFahrenheit } from '../classes/CelsiusToFahrenheit';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: ''
    })
};

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    // these two booleans control when the AccountDetail page should force a refresh
    // of the gateway subscriptions or payment methods based on changes performed
    // in the sub pages of the Account Details page.
    public paymentDetailsUpdated: boolean;
    public subscriptionDetailsUpdated: boolean;

    constructor(
        private logger: Logger,
        private http: HttpClient,
    ) {
        this.paymentDetailsUpdated = false;
        this.subscriptionDetailsUpdated = false;
    }

    getSiteAccountDetails(site_id: string, member_account_id: string) {
        return this.http.get(WebserviceURL + 'account/site/account/details?site_id=' + site_id + "&member_account_id=" + member_account_id);
    }

    getAccountDetails(id: string) {
        return this.http.get(WebserviceURL + 'account/details?id=' + id);
    }

    async getAccountDetail(account_id: string): Promise<AccountDetail> {
        return this.http.get<AccountDetail>(WebserviceURL + 'account/details?id=' + account_id).toPromise();
    }

    async get_account_site_permissions(id) {
        return this.http.get(WebserviceURL + 'account/site/permissions').pipe(retry(3)).toPromise();
    }

    setAccountDetails(obj) {
        this.logger.debug('setAccountDetails', obj);
        return this.http.put(WebserviceURL + 'account/details', obj);
    }

    getSiteAccounts(siteId: string) {
        return this.http.get(WebserviceURL + 'account/site/accounts?siteId=' + siteId);
    }

    async getAccountPaymentHistory(site_id = ''): Promise<PaymentHistory[]> {
        let params = {};

        if (site_id) {
            params = { site_id };
        }

        return [] as PaymentHistory[];
        // return await this.http.get(WebserviceURL + 'billing/payment-history', { params }).toPromise() as PaymentHistory[];
    }
    
    async getAccountPaymentMethods(site_id = ''): Promise<GetAccountPaymentResult> {
        let params = {};

        if (site_id) {
            params = { site_id };
        }

        return await firstValueFrom(this.http.get(WebserviceURL + 'billing/payment-methods', { params })).then((result: GetAccountPaymentResult)=> result);
    }

    getAccountPaymentMethodsSub(site_id = '') {
        let params = {};

        if (site_id) {
            params = { site_id };
        }

        return this.http.get(WebserviceURL + 'billing/payment-methods', { params });
    }

    delete_site_payment_method(site_id: string) {
        let params = {};

        if (site_id) {
            params = { site_id };
        }
        return this.http.delete(WebserviceURL + 'billing/payment-methods', { params }).toPromise();
    }

    getAccountSitePermission(siteId: string, raw_email: string) {
        const email = encodeURIComponent(raw_email);
        const param = { email, siteId };
        return this.http.get<KenzaPermission>(WebserviceURL + 'account/site/permission', { params: param });
    }

    getAccountPreferences(account_id: string) {
        return this.http.get<AccountPreferences>(WebserviceURL + 'account/preferences?account_id=' + account_id);
    }

    createAccountPreferences(account_preferences: AccountPreferences) {
        return this.http.post<AccountPreferences>(WebserviceURL + 'account/preferences', account_preferences);
    }

    updateAccountPreferences(account_preferences: AccountPreferences) {
        return this.http.put<AccountPreferences>(WebserviceURL + 'account/preferences', account_preferences);
    }

    getTemperaturePreferences() {
        return this.http.get<TemperaturePreference[]>(WebserviceURL + 'account/preferences/temperature');
    }

    getPressurePreferences() {
        return this.http.get<PressurePreference[]>(WebserviceURL + 'account/preferences/pressure');
    }

    removeDBAccountPhoto(account_id): Promise<any> {
        return this.http.put<any>(WebserviceURL + 'account/photo/remove', { account_id }).toPromise();
    }

    saveDBAccountPhoto(accountDetail: AccountDetail): Promise<any> {
        return this.http.put<any>(WebserviceURL + 'account/photo', accountDetail).toPromise();
    }

    updateAccountLoggedIn(account_id: string): Promise<any> {
        return this.http.put<any>(WebserviceURL + 'account/state/logged-in', { account_id }).toPromise();
    }

    async getOwnedGateways() {
        return await this.http.get(WebserviceURL + 'gateway/owner').toPromise() as any;
    }

    getOwnedGatewaysSub() {
        return this.http.get(WebserviceURL + 'gateway/owner');
    }

    cancel_account(account_id: string) {
        return this.http.put<any>(WebserviceURL + 'account/cancel', { account_id: account_id }).pipe(catchError(error => error)).toPromise();
    }

}