<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Register a Gateway</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="grid-style">
    <ion-row>
      <ion-col col size="12" me-col-no-border me-subscription-compare-header-left me-center-grid-col-content>
        <img id="kenzaCloudLogo" name="kenzaCloudLogo" banner-logo-img src="/assets/imgs/kenza-cloud-logo-1.png">
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col col col-6 me-center-grid-col-content me-center-compare-col-content>
        <ion-label id="device1" name="device1">
          <div class="me-centerText" model-text-large>MCC-50A</div>
          <div class="me-centerText" model-text-small>Mitsubishi Cloud Connector</div>
        </ion-label>
      </ion-col>
      <ion-col col col-6 me-center-grid-col-content me-center-compare-col-content>
        <ion-label id="device2" name="device2">
          <div class="me-centerText" model-text-large>RMD-50A</div>
          <div class="me-centerText" model-text-small>Remote Monitoring Device</div>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row img-row>
      <ion-col col col-6 img-col-left>
        <div img-container>
          <img svg-crop src="/assets/imgs/device_mcc.svg">
        </div>
      </ion-col>
      <ion-col col col-6 img-col-right>
        <div img-container>
          <img svg-crop src="/assets/imgs/device_rmd.svg">
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col me-center-grid-col-content img-col-left line-col>
        <ion-label>Connects to TB7 communication line.</ion-label>
      </ion-col>

      <ion-col me-center-grid-col-content img-col-right line-col>
        <ion-label>Connects to TB3 communication line.</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-button (click)="close()" style="margin: 0 0 0 0 1em;" color="primary">
          Close
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>