<ion-content main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Test Run</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    
    <ion-row>
      <ion-col size="12">
        <ion-card id="cardSelectUnit2" name="cardSelectUnit2" class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Equipment Management</h4>
            </ion-title>
            <div class="ion-icon-wrapper-card-header">
              <ion-button id="settings" class="button-style" (click)="PageTransition('settings')">
                Test Run
              </ion-button>
              <ion-button id="history" class="button-style" (click)="PageTransition('history')">
                History
              </ion-button>
            </div>
          </ion-item>

          <ion-card-content>
            <div>
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="RadioButton">
                  <th mat-header-cell *matHeaderCellDef class="col-select"> Select </th>
                  <td mat-cell *matCellDef="let element" class="col-select">
                    <input class="radio_button" type="radio" name="radiobutton_tr" [checked]="element.Checked">
                  </td>
                </ng-container>

                <ng-container matColumnDef="GatewayName">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Gateway Name </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.GatewayName}} </td>
                </ng-container>

                <ng-container matColumnDef="DeviceType">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Device Type </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.DeviceType}} </td>
                </ng-container>

                <ng-container matColumnDef="SerialNumber">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Serial Number </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.SerialNumber}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>