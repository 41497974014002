import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

const DISPLAYED_COLUMNS = [
  'Col_1',
  'Col_2',
  'Col_3',
  'Col_4',
]

@Component({
  selector: 'maintenance-branch',
  templateUrl: './maintenance-branch.component.html',
  styleUrls: ['./maintenance-branch.component.scss'],
})
export class MaintenanceBranchComponent /*implements OnInit*/ {
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  checkResult = [{}];

  constructor(private modalController: ModalController) {
    this.checkResult = [
      {
        "Col_1": "Addr 0001",
        "Col_2": "Port 2",
        "Col_3": "Port 1",
        "Col_4": "NG",
      },
      {
        "Col_1": "Addr 0002",
        "Col_2": "Port 1",
        "Col_3": "Port 2",
        "Col_4": "NG",
      },
      {
        "Col_1": "Addr 0003",
        "Col_2": "Port 3",
        "Col_3": "Port 3",
        "Col_4": "OK",
      }
    ];
  }

  // ngOnInit() {
  // }

  select_outdoor_unit() {

    this.modalController.dismiss({
    });

  }

  close() {
    this.modalController.dismiss({
      selected_outdoor_unit: null,
    });

  }

}
