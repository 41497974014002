import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { UserActionsComponent } from './features/user-actions/components/user-actions.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './features/register/components/register.component';
import { NotificationsComponent, SafeHtmlPipe } from './features/user-actions/components/notifications/notifications.component';
import { UserService } from './common/services/user/user.service';
import { Logger } from './common/services/logging/log.service';
import { SiteService } from './features/sites/services/site.service';
import { AppStorageService } from './common/services/storage/app-storage.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CloudService } from './common/services/cloud/cloud.service';
import { AppAuthenticationService } from './common/services/authentication/app-authentication.service';
import { UninviteComponent } from './features/sites/components/uninvite/uninvite.component';
import { ServerLoggerServiceService } from './common/services/logging/server-logger-service.service';
import { SocketService } from './common/services/websockets/socket.service';
import { DataService } from './common/services/data/DataService';
import { httpInterceptorProviders } from './common/services/interceptors/interceptorIndex';
import { ToastrModule } from 'ngx-toastr';
import { HomePageModule } from './features/home/components/home.module';
import { AccountModule } from './features/account/modules/account/account.module';
import { SubscriptionCostPipe } from './common/pipes/subscription-cost.pipe';
import { SharedModule } from './common/modules/shared/shared.module';
import { SiteModule } from './features/sites/modules/site/site.module';
import { SettingsModule } from './features/settings/modules/settings/settings.module';
import { AppMessageComponent } from './common/views/app-message/app-message.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HasInternetConnectionGuardService } from './common/guards/has-internet-connection-guard.service';
import { SiteGuard } from './common/guards/site.guard';
import { ManageModule } from './features/manage/modules/manage/manage.module';
import { ToggleButtonClickabilityDirective } from './common/directives/ToggleButtonClickability/toggle-button-clickability.directive';
import { AppMessageUnauthComponent } from './common/views/app-message-unauth/app-message-unauth.component';
import { TestDriveModule } from './features/testdrive/modules/testdrive/testdrive.module';
import { MaintenanceModule } from './features/maintenance/modules/maintenance/maintenance.module';
import { GoogleChartsModule } from 'angular-google-charts';
import { ApiDataService } from './features/testdrive/services/data.service';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiteSchedulingEventFormComponent } from './features/sites/components/site-scheduling-event-form/site-scheduling-event-form.component';
import { GraphService } from './common/services/graph/GraphService';

@NgModule({
    declarations: [
        AppComponent,
        UserActionsComponent,
        NotificationsComponent,
        SafeHtmlPipe,
        RegisterComponent,
        UninviteComponent,
        AppMessageComponent,
        AppMessageUnauthComponent,
        // Pipes
        SubscriptionCostPipe,
        ToggleButtonClickabilityDirective,
        SiteSchedulingEventFormComponent,
    ],
    imports: [
        BrowserModule,
        GoogleChartsModule,
        Ng2FlatpickrModule,
        BrowserAnimationsModule,        
        IonicModule.forRoot({ innerHTMLTemplatesEnabled: true } ),
        IonicStorageModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot({
            positionClass: 'toast-center-center',
            preventDuplicates: true,
            closeButton: true,
            disableTimeOut: true,
            toastClass: 'ngx-toastr toast-error  kenza-error',
        }),
        // M
        HomePageModule,
        // FM
        AccountModule,
        SiteModule,
        SettingsModule,
        ManageModule,
        // AC&R
        TestDriveModule,
        MaintenanceModule,        
        //SM
        SharedModule,
        NgbModule,
        BrowserAnimationsModule
    ],
    providers: [
        StatusBar,
        UserService,
        DataService,
        Logger,
        SiteService,
        AppStorageService,
        AppAuthenticationService,
        ServerLoggerServiceService,
        SocketService,
        CloudService,
        GraphService,
        httpInterceptorProviders,
        AuthGuard,
        HasInternetConnectionGuardService,
        SiteGuard,
        ApiDataService,        
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
