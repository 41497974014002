import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SiteNotification } from 'src/app/features/sites/components/site-alerts/classes/sitenotification';
import { Subscription } from 'rxjs';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { LoadingController, IonSearchbar } from '@ionic/angular';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { SiteAlertMetricsData } from 'src/app/common/classes/Metrics';
import { SiteNotificationTypeEnum,  ToastMessageTypeEnum, SiteNotificationTypeActionEnum, PermissionDetailEnum} from 'src/app/enumerations/enums';
import { EQUIPMENT_ALERT_RESET_WAIT_TIME, ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS, ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS, ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS, ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS, TOAST_SUCCESS_TITLE, ERROR_RESET_SUCCESS, TOAST_GENERAL_ERROR_TITLE, ERROR_RESET_ERROR, ERROR_RESET_NO_GATEWAY, devEnv } from 'src/app/constants/kenzaconstants';
import { getNotificationHeader, getNotificationImageName } from './classes/shared';

@Component({
  selector: 'app-site-alerts',
  templateUrl: './site-alerts.component.html',
  styleUrls: ['./site-alerts.component.scss'],
})

export class SiteAlertsComponent implements OnInit {
  onActiveSiteChangedSubscription: Subscription = null;
  onRefreshSiteAlertData: Subscription = null;
  siteNotificationTypes = SiteNotificationTypeEnum;
  siteNotificationList: SiteNotification[] = [];
  resetAlertLoader: any = null;
  siteNotificationTypeAction = SiteNotificationTypeActionEnum;
  permissionDetailEnum = PermissionDetailEnum;

  getNotificationHeader = getNotificationHeader;
  getNotificationImageName = getNotificationImageName;
  siteAlertsFormatted = [];
    acknowledgeable = [];
  resettable = [];
  devEnv = devEnv;
  sitePermissions = {};


  @ViewChild('alertSearchBar', { static: false }) alertSearchBar: IonSearchbar;

  constructor(
    public user: UserService,
    private siteService: SiteService,
    public appAuth: AppAuthenticationService,
    public mainSiteUIService: MainSiteUIService,
    public loadingController: LoadingController,
  ) { }

  async ngOnInit() {
    this.setSubscriptions();
    // perform refresh on initialization
    this.refreshSiteNotificationsListByActiveSite(this.user.active.id);
    Object.values(this.permissionDetailEnum).filter((v) => isNaN(Number(v))).forEach(permName =>{
       this.sitePermissions[permName] = this.appAuth.doesLevelHavePermission(
            this.user.activeSiteUserLevel,this.appAuth.permissionEnums[permName])
    });
  }

  async ionViewDidEnter() {
    // reset the search bar
    this.alertSearchBar.value = "";
    this.mainSiteUIService.setSiteAlertSearchText("");

    let alertFilter;
    if (this.mainSiteUIService.customFilter == null) {
      let newSiteAlertFilter = new SiteAlertFilter();
      newSiteAlertFilter.site_id = this.user.active.id;
      newSiteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
      alertFilter = newSiteAlertFilter
    } else {
      alertFilter = {...this.mainSiteUIService.customFilter, site_id: this.user.active.id, UserLevelEnum: this.user.activeSiteUserLevel};
    }

    this.mainSiteUIService.setSiteAlertFilter(alertFilter);
    this.mainSiteUIService.setCustomSiteAlertFilter(alertFilter);
    this.mainSiteUIService.applyCustomSiteAlertFilter(alertFilter);
    this.mainSiteUIService.refreshSiteAlertData(alertFilter);

    this.setSubscriptions();
  }

  setSearch(text: string): void {
    // set the search string
    this.mainSiteUIService.setSiteAlertSearchText(text);
    // refresh the search
    const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
    this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
  }

  async refreshSiteNotificationsListByActiveSite(activeSiteId: string) {
    this.siteNotificationList = this.mainSiteUIService.siteAlertMetricsData.v2DisplayedSiteNotifications;
  }

  setSubscriptions() {
    // subscribe to things we care about
    if (!this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription = this.user.activeSiteChanged.subscribe((activeSite: any) => {
        this.refreshSiteNotificationsListByActiveSite(activeSite.id);
      });
    }

    if (!this.onRefreshSiteAlertData) {
      this.onRefreshSiteAlertData = this.mainSiteUIService.refreshSiteAlertDataEmitter.subscribe((siteAlertMetricsData: SiteAlertMetricsData) => {
        this.siteNotificationList = siteAlertMetricsData.v2DisplayedSiteNotifications;
        this.checkResettable(this.siteNotificationList);
      });
    }
  }

  checkResettable(alerts) {
    this.resettable = [];
    this.acknowledgeable = [];
    this.siteAlertsFormatted = alerts.length > 0 ? alerts.filter(alert => !alert.acknowledged_at).map(notif => {
      let site = this.user.sites.filter(sit => sit.id == notif?.site_id)[0];
      return {
        ...notif,
        site_name: site?.name,
        type: this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id],
        permission: {
          site_name: site?.name,
          ...site?.permission.filter(perm => perm?.account_id == this.user.id && perm?.removed_at == null)[0],
        },
      }
    }).sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at))) : [];
    this.siteAlertsFormatted.forEach(notification => {
      if (this.appAuth.doesLevelHavePermission(notification?.permission?.level, this.mainSiteUIService.getResetAuth(notification))) {
        if (notification.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) {
          this.resettable.push(notification);
        } else {
          this.acknowledgeable.push(notification);
        }
        return this.resettable.concat(this.acknowledgeable);
      }
    });
  }

  getResettableNotifications() {
    return this.resettable.concat(this.acknowledgeable);
  }

  clearSubscriptions() {
    // unsubscribe
    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
      this.onActiveSiteChangedSubscription = null;
    }

    if (this.onRefreshSiteAlertData) {
      this.onRefreshSiteAlertData.unsubscribe();
      this.onRefreshSiteAlertData = null;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  ionViewDidLeave() {
    this.clearSubscriptions();
  }

  timeFormat(t) {
    const d = new Date(t + 'Z');
    return d.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  }

  async presentToastWithOptions(siteNotification: SiteNotification) {
    let toastMessage = ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS;

    if (siteNotification) {
      switch (siteNotification.sitenotificationtype_id) {
        case SiteNotificationTypeEnum.Equipment:
          toastMessage = ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Gateway:
          toastMessage = ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Membership:
          toastMessage = ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Payments:
          toastMessage = ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Subscription:
          toastMessage = ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.EquipmentNotification:
          toastMessage = ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS;
          break;
      }
    }

    this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, toastMessage)
  }

  dismissNotification(siteNotification: SiteNotification) {
    if (!this.siteService.handleIsConnected()) return;

    this.siteService.dismissSiteNotification(siteNotification.id, this.user.id).subscribe(data => {
      const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
      siteAlertFilter.site_id = siteNotification.site_id
      siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
      this.mainSiteUIService.dismissNotification(siteNotification);
      this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
      this.presentToastWithOptions(siteNotification);
    });
  }

  async dismissNotifications(e?) {
    if (!this.siteService.handleIsConnected()) return;
    if (this.getResettableNotifications().length > 0) {

      if (this.getResettableNotifications().filter(notif => notif?.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length >= 1) {
        this.resetAlertLoader = await this.loadingController.create({
          message: 'Resetting error...',
          spinner: 'lines',
          duration: EQUIPMENT_ALERT_RESET_WAIT_TIME
        });

        await this.resetAlertLoader.present();
      }

      this.getResettableNotifications().forEach(notification => {
        if (notification.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) {
          this.resetEquipmentAlert(notification);
        } else {
          this.dismissNotification(notification);
        }

        if (this.getResettableNotifications().filter(notif => notif?.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length >= 1) this.showSuccess();
      });
    }
    this.checkResettable(this.siteNotificationList);
  }

  async showSuccess() {
    if ((this.resetAlertLoader !== undefined) && (this.resetAlertLoader !== null)) {
      this.resetAlertLoader.dismiss('ErrorReset_Success');
    }

    const { data } = await this.resetAlertLoader.onDidDismiss();

    if ((data !== undefined) && (data == 'ErrorReset_Success')) {
      this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_SUCCESS);
    } else if ((data !== undefined) && (data == 'ErrorReset_GWNotFound')) {
      this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_NO_GATEWAY);
    }
  }


  async resetEquipmentAlert(siteNotification: SiteNotification, singleReset?) {
    if (!this.siteService.handleIsConnected()) return;

    if (singleReset == true) {
      this.resetAlertLoader = await this.loadingController.create({
        message: 'Resetting error...',
        spinner: 'lines',
        duration: EQUIPMENT_ALERT_RESET_WAIT_TIME
      });

      await this.resetAlertLoader.present();
    }

    this.siteService.resetEquipmentAlert(siteNotification.id).subscribe(result => {
      const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
      siteAlertFilter.site_id = siteNotification.site_id;
      siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
      this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);

      if ((this.resetAlertLoader !== undefined) && (this.resetAlertLoader !== null)) {
        if ('message' in result && (<string>result['message']).includes('Gateway not found')) {
          if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_GWNotFound');
        } else {
          if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_Success');
        }
      }
    }, error => {
      if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_Failure');
    });

    if (singleReset == true) {
      const { data } = await this.resetAlertLoader.onDidDismiss();
  
      if ((data !== undefined) && (data == 'ErrorReset_Success')) {
        this.mainSiteUIService.dismissNotification(siteNotification);
        this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_SUCCESS);
      } else if ((data !== undefined) && (data == 'ErrorReset_GWNotFound')) {
        this.mainSiteUIService.dismissNotification(siteNotification);
        this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_NO_GATEWAY);
      }
    }
  }

  notificationIdTracker(index, item) {
    return item.id;
  }
}
