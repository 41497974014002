import { MaskitoOptions, maskitoUpdateElement } from '@maskito/core';
import { maskitoEventHandler, maskitoWithPlaceholder } from '@maskito/kit';
import { macAddressMask, rmdMacAddressMaskPlaceholder } from 'src/app/constants/validators';

let placeholderToShow = rmdMacAddressMaskPlaceholder;
let maskToUse = macAddressMask;

const {
  plugins,
  removePlaceholder,
  ...placeholderOptions
} = maskitoWithPlaceholder(placeholderToShow);

export const macAddressMaskitoMask = {
  mask: maskToUse,
  preprocessors: placeholderOptions.preprocessors,
  postprocessors: placeholderOptions.postprocessors,
  plugins: [
    ...plugins,

    maskitoEventHandler(`focus`, element => {
      const initialValue = element?.value;
      maskitoUpdateElement(element, initialValue + placeholderToShow.slice(initialValue.length));
    }),

    maskitoEventHandler(`blur`, element => {
      const cleanValue = removePlaceholder(element?.value);
      maskitoUpdateElement(element, cleanValue);
    }),
  ],
} as MaskitoOptions;