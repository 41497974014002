<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">History</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Target Equipment</h4>
            </ion-title>
          </ion-item>

          <ion-card-content >
            <ion-grid>
              <ion-row class="row-title">
                <ion-col>
                  Gateway Name
                </ion-col>
                <ion-col>
                  Device Type
                </ion-col>
                <ion-col>
                  Serial Number
                </ion-col>
              </ion-row>
              <ion-row me-top-border>
                <ion-col>
                  {{gatewayName}}
                </ion-col>
                <ion-col>
                  {{deviceType}}
                </ion-col>
                <ion-col>
                  {{serialNumber}}
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-card me-ion-card id="cardSelectUnit" name="cardSelectUnit">
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Recent Test Run History</h4>
            </ion-title>
            <div class="ion-icon-wrapper-card-header">
              <ion-button id="testrun" class="button-style" (click)="backToTestRunTop()">
                Back
              </ion-button>
            </div>
          </ion-item>

          <ion-card-content>
            <div class="table-area">
              <table mat-table [dataSource]="dataSourceHistory" class="mat-elevation-z8">

                <ng-container matColumnDef="ended_at_sitetime">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Completion Date and Time</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ended_at_sitetime}} </td>
                </ng-container>

                <ng-container matColumnDef="system_name">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> System Name</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.system_name}} </td>
                </ng-container>

                <ng-container matColumnDef="ou_model_name">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> OU Model Name</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ou_model_name}} </td>
                </ng-container>

                <ng-container matColumnDef="ou_serial_no">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> OU Serial No.</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ou_serial_no}} </td>
                </ng-container>

                <ng-container matColumnDef="ou_address">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Address</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ou_address}} </td>
                </ng-container>

                <ng-container matColumnDef="testruntime">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Test Run Time</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.testruntime}} </td>
                </ng-container>

                <ng-container matColumnDef="ope_mode">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Ope Mode</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ope_mode}} </td>
                </ng-container>

                <ng-container matColumnDef="outside_temperature">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Outside Temperature</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.outside_temperature}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Result</th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.status}} </td>
                </ng-container>
              
                <ng-container matColumnDef="report">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Report</th>
                  <td mat-cell *matCellDef="let element" class="col-width">
                    <ion-button *ngIf="(element.started_at && element.ended_at)" color="medium"  size="small" id="download" class="download-button-style" 
                    (click)="getMaintData(element.started_at, element.ended_at, element.id, element.unit_history_id, element.ou_address)">
                      Download
                    </ion-button>
                  </td>                
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>