import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MaintenanceBranchComponent } from '../maintenance-branch/maintenance-branch.component'
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';

const DISPLAYED_COLUMNS = [
  'Col_1',
  'Col_2',
  'Col_3',
  'Col_4',
]

@Component({
  selector: 'maintenance-detail',
  templateUrl: './maintenance-detail.component.html',
  styleUrls: ['./maintenance-detail.component.scss'],
})
export class MaintenanceDetailComponent /*implements OnInit*/ {
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  checkResult = [{}];

  constructor(private modalController: ModalController) {
    this.checkResult = [
      {
        "Col_1": "Addr 0001",
        "Col_2": "Port 2",
        "Col_3": "Port 1",
        "Col_4": "Failed",
      },
      {
        "Col_1": "Addr 0002",
        "Col_2": "Port 1",
        "Col_3": "Port 2",
        "Col_4": "Failed",
      },
      {
        "Col_1": "Addr 0003",
        "Col_2": "Port 3",
        "Col_3": "Port 3",
        "Col_4": "Passed",
      }
    ];
  }

  // ngOnInit() {
  // }

  /**
   * 分岐口変更ダイアログ表示
   * Show BC port change Dialog
   */
  async onClickBranch(){    
    const modal = await this.modalController.create({
      component: MaintenanceBranchComponent,
      backdropDismiss: true,
      componentProps: {
        parentKenzaCloudMenuId: KenzaCloudMenuIds.SiteControl
      },
    });
    return await modal.present();
  }

  select_outdoor_unit() {

    this.modalController.dismiss({
    });

  }

  close() {
    this.modalController.dismiss({
      selected_outdoor_unit: null,
    });

  }

}
