/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/common/services/data/DataService';
import { SiteNotification } from '../../classes/sitenotification';
import { Log, Logger } from 'src/app/common/services/logging/log.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { LevelEnum, PermissionDetailEnum, SiteNotificationTypeEnum, ToastMessageTypeEnum, SiteNotificationTypeActionEnum } from 'src/app/enumerations/enums';
import { parseISO as dateFnsParseISO, format as dateFnsFormat } from 'date-fns';
import {
  ACKNOWLEDGE_NOTIFICATION_WAIT_TIME,
  ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS,
  TOAST_SUCCESS_TITLE,
  ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS,
  ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS,
  ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS,
  ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS,
  ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS,
  ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS,
  MEMBER_NOT_ASSOICATED_WITH_SITE,
  GATEWAY_NOT_ASSOCIATED_WITH_SITE
} from 'src/app/constants/kenzaconstants';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { Location } from '@angular/common';
import { AccountService } from 'src/app/features/account/services/accountService';
import { AccountDetail } from 'src/app/features/account/classes/accountdetail';
import { DateStringUtilities } from 'src/app/common/utilities/stringUtilities';
import { Subscription } from 'rxjs';
import { getNotificationHeader, getNotificationImageName} from '../../classes/shared';

@Component({
  selector: 'app-notification-admin',
  templateUrl: './notification-admin.component.html',
  styleUrls: ['./notification-admin.component.scss'],
})
export class NotificationAdminComponent implements OnInit, OnDestroy {
  siteNotificationID: string;
  siteNotification: SiteNotification = null;
  showSuccessfulAcknowledgement = false;
  showFailedAcknowledgement = false;
  loadMain: any = null;
  showNavigationIssueMessage = false;
  navigationIssueMessage = '';
  siteNotification_Gateway_Id = '';
  mmd_show_acknowledged_at = false;
  mmd_image_url = '';
  mmd_member_since = '';
  mmd_accountDetail: AccountDetail;
  navigationSubscription: Subscription = null;
  siteNotificationTypeAction = SiteNotificationTypeActionEnum;

  limitedSuspensionNotification = false;

  LevelEnum = LevelEnum;

  getNotificationHeader = getNotificationHeader;
  getNotificationImageName = getNotificationImageName;

  notificationAckDetails = {
    ackBy: '',
    role: '',
    ackDate: '',
  }

  siteNotificationAckDetails = {
    ackBy: '',
    role: '',
    ackDate: '',
  }
  labels: any[];
  acknowledgedOrResetByActiveMember = true;

  constructor(public dataService: DataService,
    public logger: Logger,
    public user: UserService,
    public siteService: SiteService,
    public mainSiteUIService: MainSiteUIService,
    public loadingController: LoadingController,
    public toastController: ToastController,
    private appAuthService: AppAuthenticationService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
  ) {
    this.showNavigationIssueMessage = false;
  }

  ionViewWillEnter() {
    if (this.navigationSubscription == null) {
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.showNavigationIssueMessage = false;
        }
      });
    }
  }

  ionViewWillLeave() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.navigationSubscription = null;
    }
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.mmd_show_acknowledged_at = false;
    this.siteNotificationID = this.activatedRoute.snapshot.paramMap.get('alertId');
    const notifica = this.mainSiteUIService.getSiteNotification(this.siteNotificationID) || this.mainSiteUIService.getNotification(this.siteNotificationID);
    if (notifica) {
      if (notifica?.acknowledged_at != null) {
        if (this.user.activeSiteAccounts.map(acc => acc?.id).includes(notifica?.acknowledged_account_id)) {
          this.acknowledgedOrResetByActiveMember = true;
        } else {
          this.acknowledgedOrResetByActiveMember = false;
        }
      }
      this.setNotificationAcknowledgementDetails(this.user.active.id);
      this.mainSiteUIService.viewSiteAlert("toSiteNotifications");
      this.setSiteNotificationDetail(notifica);
    } else {
      // usally means someone refreshed while in an alert detail, as alerts aren't loaded yet going back to the main alerts page
      this.returnToSiteAlerts(true);
    }
  }

  mmd_get_account_details(account_id: string) {
    this.set_manage_member_detail_image_url(account_id);
    this.set_account_details(account_id);
  }

  returnToSiteAlerts(replaceUrl = false) {
    this.showNavigationIssueMessage = false;
    this.mainSiteUIService.viewSiteAlert("from");
    if (replaceUrl) {
      this.router.navigate(['/site', this.user.active.id, 'alerts'], { replaceUrl: true });
    } else {
      this.router.navigate(['/site', this.user.active.id, 'alerts']);
    }
  }

  async setNotificationAcknowledgementDetails(siteId: string) {
    const accountList = await this.appAuthService.getSiteAccounts(siteId) as any;

    const currentUser = accountList.users.find(user => user.id === this.user.id);

    if (currentUser) {
      this.notificationAckDetails.ackBy = this.display_name(currentUser.fname, currentUser.lname, currentUser.email);
      this.notificationAckDetails.role = currentUser.levelDisplayText;
    }
  }

  display_name(fname: string, lname: string, email: string): string {
    let displayName = '';

    if (fname || lname) {
      if (fname && lname) {
        displayName = fname + ' ' + lname;
      }

      if (fname) {
        displayName = fname;
      }

      if (lname) {
        displayName = lname;
      }
    } else {
      displayName = email;
    }

    return displayName;
  }

  setSiteNotificationDetail(siteNotification: SiteNotification) {
    // set this notification into the display
    if (siteNotification) {
      if (siteNotification.context == undefined || siteNotification.context == null) siteNotification.context = {};
      if (siteNotification.context.type && siteNotification.context.type == 'LIMITED_SUSPENSION') {
        this.limitedSuspensionNotification = true;
      }

      if (((siteNotification.notification_labels)) && (siteNotification.notification_labels['Gateway ID'])) {
        this.siteNotification_Gateway_Id = siteNotification.notification_labels['Gateway ID'];
      } else {
        // consider the context
        if ((siteNotification.context) && (siteNotification.context['gateway_id'])) {
          this.siteNotification_Gateway_Id = siteNotification.context['gateway_id'];
        } else {
          this.siteNotification_Gateway_Id = '';
        }
      }

      // convert the label string from json into an array of key,value pairs
      // using the order field as the desired order to display on the control
      if (siteNotification.notification_labels && 'order' in siteNotification.notification_labels) {
        const order = siteNotification.notification_labels['order'].split(',');
        const fields = new Map();
        order.forEach(function (field: string) {
          fields.set(field, siteNotification.notification_labels[field]);
        });
        siteNotification.display_notification_labels = fields;
        const licenseExpLabel = 'License Exp.';
        if (siteNotification.display_notification_labels.get(licenseExpLabel)){
          let newDateVar: string = siteNotification.display_notification_labels.get(licenseExpLabel);
          newDateVar = dateFnsFormat(dateFnsParseISO(newDateVar), 'M/d/yyyy');
          siteNotification.display_notification_labels.set(licenseExpLabel, newDateVar);
        }
      }
      
      this.siteNotification = siteNotification;

      if ((this.siteNotification.acknowledged_at !== undefined) && (this.siteNotification.acknowledged_at !== null)) {
        this.showSuccessfulAcknowledgement = true;
        if (this.acknowledgedOrResetByActiveMember) this.mmd_get_account_details(this.siteNotification.acknowledged_account_id);

        this.siteNotificationAckDetails.ackDate = this.siteNotification.acknowledged_at.toString();

        if ((this.siteNotification.context !== undefined) && (this.siteNotification.context !== null)) {
          this.siteNotificationAckDetails.ackBy = this.siteNotification.context.acknowledged_by;
          this.siteNotificationAckDetails.role = this.siteNotification.context.acknowledged_by_role;
        }
      }
    }
  }

  getSiteNotificationDetail(site_id: string, sitenotification_id: string) {
    // go back to the server and pull this notification
    this.siteService.siteNotification(site_id, sitenotification_id).subscribe((siteNotification: SiteNotification) => {
      if (siteNotification) {
        this.setSiteNotificationDetail(siteNotification);
      }
    }, error => {
      console.error('getSiteNotificationDetail=>error : ', error);
    }
    );
  }

  handleActionClick() {

    if (!this.siteService.handleIsConnected())
      return;

    this.showSuccessfulAcknowledgement = false;
    this.showFailedAcknowledgement = false;
    this.notificationAckDetails.ackDate = new Date().toString();
    this.acknowledingNotification(this.siteNotification);
  }

  navigateToGateway() {

    if (!this.siteService.handleIsConnected())
      return;

    if (!this.siteNotification_Gateway_Id) {
      // then we dont know what the gateway is
      this.showNavigationIssueMessage = true;
      this.navigationIssueMessage = GATEWAY_NOT_ASSOCIATED_WITH_SITE;
      return
    }

    this.siteService.getGatewayDetails(this.siteNotification.site_id, this.siteNotification_Gateway_Id).subscribe(result => {
      const site_gateway_url = `/manage/${this.siteNotification.site_id}/gateway/${this.siteNotification_Gateway_Id}`;
      this.router.navigate([site_gateway_url]);
    }, err => {
      this.showNavigationIssueMessage = true;
      this.navigationIssueMessage = GATEWAY_NOT_ASSOCIATED_WITH_SITE;
    });

  }

  navigateToAcknowledgeBy() {
    const acknowledged_by_id: string = this.siteNotification.acknowledged_account_id;
    const acknowledged_by_site_id: string = this.siteNotification.site_id;

    if (!this.siteService.handleIsConnected())
      return;

    this.accountService.getSiteAccountDetails(acknowledged_by_site_id, acknowledged_by_id).subscribe(result => {

      if (acknowledged_by_id === this.user.id) {
        const site_member_url = `/account/${acknowledged_by_id}/details`;
        this.router.navigate([site_member_url]);
      } else {
        this.mmd_show_acknowledged_at = true;
        this.mainSiteUIService.viewSiteAlert('showManageMemberDetail');
      }
    }, err => {
      this.showNavigationIssueMessage = true;
      this.navigationIssueMessage = MEMBER_NOT_ASSOICATED_WITH_SITE;
    }
    );
  }

  backToNotification() {
    this.showNavigationIssueMessage = false;
  }

  getNotificationLabelValueByKey(keyToGet: string, siteNotification: SiteNotification): string {
    let labelValue = '';

    if ((siteNotification) && (siteNotification.notification_labels) && (siteNotification.notification_labels.size > 0)) {
      for (const [key, value] of siteNotification.notification_labels) {
        if (key === keyToGet) {
          labelValue = value;
        }
      }
    }
    return labelValue;
  }


  async acknowledingNotification(siteNotification: SiteNotification) {
    let toastMessage: string = ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS;
    this.loadMain = await this.loadingController.create({
      message: 'Acknowledging notification...',
      spinner: 'lines',
      duration: ACKNOWLEDGE_NOTIFICATION_WAIT_TIME
    });

    await this.loadMain.present();
    this.dismissSiteNotification(siteNotification);

    const { data } = await this.loadMain.onDidDismiss();

    if (data !== undefined) {
      this.showSuccessfulAcknowledgement = true;

      switch (siteNotification.sitenotificationtype_id) {
        case SiteNotificationTypeEnum.Equipment:
          toastMessage = ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Gateway:
          toastMessage = ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Membership:
          toastMessage = ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Payments:
          toastMessage = ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.Subscription:
          toastMessage = ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS;
          break;

        case SiteNotificationTypeEnum.EquipmentNotification:
          toastMessage = ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS;
          break;
      }

      this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, toastMessage);

    } else {
      this.showFailedAcknowledgement = true;
    }
  }

  dismissSiteNotification(siteNotification: SiteNotification) {
    this.siteService.dismissSiteNotification(this.siteNotification.id, this.user.id).subscribe(result => {
      const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
      siteAlertFilter.site_id = this.siteNotification.site_id;
      siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

      this.mainSiteUIService.dismissNotification(this.siteNotification);
      this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
      this.getSiteNotificationDetail(siteNotification.site_id, siteNotification.id);

      if ((this.loadMain !== undefined) && (this.loadMain !== null)) {
        this.loadMain.dismiss('dismissSiteNotification_Success');
      }
    }, error => {
      console.error("dismissSiteNotification", error);
      this.loadMain.dismiss();
    });
  }


  timeFormat(t) {
    const d = new Date(t + 'Z');
    return d.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  }

  async presentToast(toastMessage: string, color: string) {
    const toast = await this.toastController.create({
      message: toastMessage,
      color: color,
      position: 'middle',
      duration: 3000,
    });

    toast.present();
  }

  hasAckPermission(sitenotification: SiteNotification) {
    // does the active user have permission to ack this type of notification?
    if (sitenotification) {
      let perm = null;
      switch (sitenotification.sitenotificationtype_id) {
        case SiteNotificationTypeEnum.Membership:
          perm = PermissionDetailEnum.AckMemberNotification;
          break;
        case SiteNotificationTypeEnum.Equipment:
          perm = PermissionDetailEnum.ResetEquipmentAlert;
          break;
        case SiteNotificationTypeEnum.Gateway:
          // this also covers ack of gateway registration
          perm = PermissionDetailEnum.AckGatewayMove;
          break;
        case SiteNotificationTypeEnum.Payments:
          // this also covers ack of gateway registration
          perm = PermissionDetailEnum.AckPaymentNotification;
          break;
        case SiteNotificationTypeEnum.EquipmentNotification:
          // this also covers ack of EquipmentNotification registration
          perm = PermissionDetailEnum.AckEquipmentAlert;
          break;
      }

      if (perm == null) {
        this.logger.error('Unexpected sitenotificationtype in hasAckPermission: %d', sitenotification.sitenotificationtype_id);
        return false
      }

      return this.appAuthService.doesLevelHavePermission(this.user.activeSiteUserLevel, perm)

    }
    return false;
  }

  // Not being used right now
  getNotificationType(sitenotificationtype_id: number) {
    let header = "";

    switch (sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Membership:
        header = "membership";
        break;

      case SiteNotificationTypeEnum.Gateway:
        header = "gateway";
        break;

      case SiteNotificationTypeEnum.Payments:
        header = "payment";
        break;

      case SiteNotificationTypeEnum.Subscription:
        header = "subscription";
        break;

      case SiteNotificationTypeEnum.Equipment:
        header = "equipment alarm";
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        header = "equipment notification";
        break;
    }
    return header;
  }

  set_manage_member_detail_image_url(account_id) {
    this.user
      .getAccountPhotoUrl(account_id)
      .then((accountPictureUrl) => {
        this.mmd_image_url = '';
        if (accountPictureUrl.length > 0) {
          this.mmd_image_url = accountPictureUrl;
        }
      })
      .catch((err) => Log.error(err));
  }

  set_account_details(account_id: string) {
    this.user.getSiteAccountDetails(this.user.active.id, account_id).subscribe(
      (data: any) => {
        const acctDetail: AccountDetail = new AccountDetail();

        if (data.email) {
          acctDetail.id = data.id ? data.id : '';
          acctDetail.account_photo_name = '';
          acctDetail.country = '';
          acctDetail.last_login = null;
          acctDetail.removed_at = null;
          acctDetail.status = 0;
          acctDetail.fname = data.fname ? data.fname : '';
          acctDetail.lname = data.lname ? data.lname : '';
          acctDetail.title = data.title ? data.title : '';
          acctDetail.phone = data.phone ? data.phone : '';
          acctDetail.email = data.email ? data.email : '';
          acctDetail.created_at = DateStringUtilities.formatDateString(
            data.created_at,
            'en-US',
            'MM/dd/yyyy'
          );

          acctDetail.company = data.company ? data.company : '';
          acctDetail.company_phone = data.company_phone
            ? data.company_phone
            : '';
          acctDetail.street_line1 = data.street_line1 ? data.street_line1 : '';
          acctDetail.street_line2 = data.street_line2 ? data.street_line2 : '';

          acctDetail.city = data.city ? data.city : '';
          acctDetail.state = data.state ? data.state : '';
          acctDetail.postal_code = data.postal_code ? data.postal_code : '';
        } else {
          acctDetail.id = data.id ? data.id : '';
          acctDetail.account_photo_name = '';
          acctDetail.country = '';
          acctDetail.last_login = null;
          acctDetail.removed_at = null;
          acctDetail.status = 0;
          acctDetail.fname = '';
          acctDetail.lname = '';
          acctDetail.title = '';
          acctDetail.phone = '';
          acctDetail.email = data.email;
          acctDetail.created_at = DateStringUtilities.formatDateString(
            data.created_at,
            'en-US',
            'MM/dd/yyyy'
          );

          acctDetail.company = '';
          acctDetail.company_phone = '';
          acctDetail.street_line1 = '';
          acctDetail.street_line2 = '';

          acctDetail.city = '';
          acctDetail.state = '';
          acctDetail.postal_code = '';
        }

        this.mmd_member_since = DateStringUtilities.formatDateString(
          data.created_at,
          'en-US',
          'MMMM dd, yyyy'
        );
        this.mmd_accountDetail = acctDetail;
      },
      (error) => {
        console.error('error:', error);
      }
    );
  }
  // dummy method to force no sorting options in ngFor loops with keyvalue pipe.  causes Map to be sorted in order of the Map
  unsortedOrder() {return false;}

}
