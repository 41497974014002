import { Component, Inject, LOCALE_ID, OnDestroy, Renderer2 } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { OK_MODAL_MAINTENANCE_DATA_TITLE, OK_MODAL_MAINTENANCE_DATA_CONTENT, OK_MODAL_SYSTEM_DATA_TITLE, OK_MODAL_SYSTEM_DATA_CONTENT, TEST_MODE_NO_SUITABLE_ODU_TITLE, TEST_MODE_NO_SUITABLE_ODU_DETAIL, devEnv } from 'src/app/constants/kenzaconstants';
import { SiteService } from '../../services/site.service';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';
//import { SiteAnalyticsDownloadCSVComponent } from '../site-analytics-download-csv/site-analytics-download-csv.component';
import { SiteAnalyticsGenerateGIFComponent } from '../site-analytics-generate-gif/site-analytics-generate-gif.component';
import { SiteAnalyticsGIFDownloadRequest, SiteAnalyticsMtdzDownloadRequest } from '../../classes/site-analytics-request';
import { v4 as uuid } from 'uuid';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { gifDownloadTracker, gifDownloadStage } from 'src/app/common/classes/gifDownloadTracker';
import { GatewayModelClass, GatewayUnitTwoDigitType, MaintenanceJobTypeEnum, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { AppStorageService } from 'src/app/common/services/storage/app-storage.service';
import { SiteAnalyticsGenerateMaintenanceDataComponent } from '../site-analytics-generate-maintenance-data/site-analytics-generate-maintenance-data.component';
import { mtdzDownloadStage, mtdzDownloadTracker } from 'src/app/common/classes/mtdzDownloadTracker';
import { UnregisteredDevice } from 'src/app/features/manage/components/classes/UnregisteredDevice';
import { FeatureService, Features } from 'src/app/common/services/feature/feature.service';
import { Site } from '../../classes/site';
import moment from 'moment';
import { date_time_utilities } from 'src/app/common/utilities/datetimeUtilities';
import { OutdoorUnitDetails, SiteAnalyticsTestRunComponent, TestDurationLabels, TestModeLabels } from '../site-analytics-test-run/site-analytics-test-run.component';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { TestRunMaintenanceJob, maintenanceJobStatusEnum, maintenanceJobStatusEnumMessage, genericMaintenanceJobStatusEnumMessage } from 'src/app/common/classes/MaintenanceJob';
import { ReportDetail } from 'src/app/features/manage/components/classes/AnalyticsReportDetail';
import { formatDate } from '@angular/common';

export enum SiteGatewayState {
  NoGateways = 1,
  Unmapped = 2,
  Mapped = 3
}

export type miniMaintJob = {
  id: string,
  progress: number,
  status: maintenanceJobStatusEnum,
}

@Component({
  selector: 'app-site-analytics',
  templateUrl: './site-analytics.component.html',
  styleUrls: ['./site-analytics.component.scss'],
})

export class SiteAnalyticsComponent {

  dataLoading: boolean;
  siteGatewayState: SiteGatewayState;
  SiteGatewayState = SiteGatewayState;

  rmdClassGatewaysExist:boolean;
  rmdClassGatewaysMapped:boolean;
  mccClassGatewaysExist:boolean;
  mccClassGatewaysMapped:boolean;

  navigationSubscription: Subscription;
  onActiveSiteChangedSubscription: Subscription;
  onSiteGatewayCreatedSubscrption: Subscription;
  onSiteGatewayDecomissionedSubscription: Subscription;

  // DCC constants
  expectedCOEStartTimeInMilliseconds = 540000.0; // 9*60*1000 this is 9 minutes

  // gif downloads
  onGifTimerEvent: Subscription;
  gifDownloadTimer: Observable<number>;
  gifDownloadState: gifDownloadStage = gifDownloadStage.noDownload;
  gifDownloadProgress = 0;
  gifDownloadErrorMsg = '';
  timeoutDownloadGIFMilliseconds = 720000; //12*60*1000  this is 12 minutes
  gifSocketEventSubscription: Subscription;

  // mtdz downloads
  onMtdzTimerEvent: Subscription;
  mtdzDownloadProgress = 0;
  mtdzDownloadErrorMsg = '';
  timeoutDownloadMtdzMilliseconds = 900000; // 15*60*1000 this is 15 minutes
  mtdzDownloadTimer: Observable<number>;
  mtdzSocketEventSubscription: Subscription;

  // test run
  testRunActive: boolean;
  testRunStateMessage: string;
  testRunProgress: number;
  testRunProgressTitle: string;
  testRunDisplayJobStatus: maintenanceJobStatusEnum;  
  // socket notifications
  maintJobSocketEventSubscription: Subscription;
  // active test run jobs we are tracking
  activeTestRunJobs;

  // socket notifications of non test run maint jobs
  maintJobEventSubscription: Subscription;
  
  // featureService enumerations
  maintenanceJobStatusEnum = maintenanceJobStatusEnum;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  features = Features;

  reports = [];
  socketResult;
  devEnv = devEnv;
  rcReports = [];
  bpcReports = [];

  constructor(
    public user: UserService,
    private modalController: ModalController,
    private siteService: SiteService,
    public appAuth: AppAuthenticationService,
    private alertController: AlertController,
    private router: Router,
    private socket: SocketService,
    private appStorageService: AppStorageService,
    private mainSiteUIService: MainSiteUIService,
    private featureService: FeatureService,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.testRunActive = false;
  }

  async getLatestReports() {
    try {
      let reps = [];
      await this.siteService.getTestrunHistory2().subscribe((reports: []) => {
        this.reports = [];
        reports.forEach((rprt: any) => {
          let report = new ReportDetail(rprt, this.locale, this.user.isMemberOfCachedSites(rprt.site_id));
          this.reports.push(report);
          reps.push(report);
        })
      })

      this.rcReports = this.reports.filter(rep => rep.job_id == MaintenanceJobTypeEnum.Refrigerant);
      this.bpcReports = this.reports.filter(rep => rep.job_id == MaintenanceJobTypeEnum.Incorrect_Port);
      devEnv && console.log(`Latest Reports`, { reps, reports: this.reports, rcReports: this.rcReports, bpcReports: this.bpcReports });
      return this.reports;
    } catch (error) {
      console.log(`Error on Get Latest Reports`, error);
      return error;
    }
  }

  ionViewWillEnter() {

    this.onActiveSiteChangedSubscription = this.user.activeSiteChanged.subscribe(
      (activeSite: any) => {
        if ((activeSite) && (activeSite.id) && (activeSite.id.toLowerCase() !== "default-site")) {
          this.refreshGatewayListByActiveSite(activeSite.id);
        }
      }
    );
    this.onSiteGatewayCreatedSubscrption = this.siteService.siteGatewayCreated.subscribe(
      (res: UnregisteredDevice[]) => {
        // update gateway list
        this.refreshGatewayListByActiveSite(this.user.active.id);
      }
    )
    this.onSiteGatewayDecomissionedSubscription = this.siteService.siteGatewayDecomissioned.subscribe(
      (detail) => {
        this.refreshGatewayListByActiveSite(this.user.active.id);
      }
    )
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.user.active) this.refreshGatewayListByActiveSite(this.user.active.id);
      }
    });

    this.refreshGatewayListByActiveSite(this.user.active.id);    

    // listen for upudates to active test runs
    this.maintJobSocketEventSubscription = this.socket.SocketServiceEmitter
    .subscribe((socketResult: any) => {
      if (socketResult && socketResult.response_type) {
        switch (socketResult.response_type) {
          // default:
          //   devEnv && console.log(`Socket Updates`, socketResult);
          //   break;
          case WebSocketResponseTypeEnum.Test_Run_Progress:
            this.updateTestRunProgress(socketResult.response);
            break;
        }
      }
    });

    // listen for alternate maintenance events
    this.maintJobEventSubscription = this.socket.maintenanceEmitter.subscribe((socketResult: any) => {
      // devEnv && console.log(`Socket Result`, socketResult);
      this.socketResult = socketResult.responseData;
    });

    this.checkForRunningTests();
  }

  ngAfterViewInit() {
    // Empty
  }

  ionViewWillLeave() {
    // drop subscriptions
    if (this.onSiteGatewayCreatedSubscrption) {
      this.onSiteGatewayCreatedSubscrption.unsubscribe();
      this.onSiteGatewayCreatedSubscrption = null;
    }
    if (this.onSiteGatewayDecomissionedSubscription) {
      this.onSiteGatewayDecomissionedSubscription.unsubscribe();
      this.onSiteGatewayDecomissionedSubscription = null;
    }
    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
      this.onActiveSiteChangedSubscription = null;
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.navigationSubscription = null;
    }
    this.stopGifTimerAndSocket();
    this.stopMtdzTimerAndSocket();
    this.stopMaintenanceJobSocket();
  }

  ngOnDestroy(): void {
    // empty
  }

  ngOnInit() {
    // Empty
  }

  // git download
  startGifTimerAndSocket() {
    // timer for gif generation progress bar

    this.gifDownloadTimer = timer(1000, 1000);
    this.onGifTimerEvent = this.gifDownloadTimer.subscribe(() => {
      // update the gif download progress?
      const site_id = this.user.active.id;
      if (sessionStorage.gifDownloads) {
        const gifDownloads = JSON.parse(sessionStorage.gifDownloads);
        if (site_id in gifDownloads) {
          const secondsActive = Date.now() - gifDownloads[site_id].startTime;
          // then set the state and progress value of the download for display
          if (gifDownloads[site_id].stage == gifDownloadStage.startAnEEngine) {
            // then progress is based on time since start
            const fracOfTime = secondsActive / this.expectedCOEStartTimeInMilliseconds;
            this.gifDownloadProgress = fracOfTime < 1 ? fracOfTime * 0.9 : 0.9;
          } else if (gifDownloads[site_id].stage == gifDownloadStage.generateImage) {
            // then progress is fixed at 95% - engine is ready
            this.gifDownloadProgress = .95;
          } else if (gifDownloads[site_id].stage == gifDownloadStage.downloadReady) {
            // we're done.
            this.gifDownloadProgress = 1;
          } else if (gifDownloads[site_id].stage == gifDownloadStage.errorState) {
            // we're in error.
            this.gifDownloadErrorMsg = gifDownloads[site_id].error_msg;
            this.gifDownloadProgress = 1;
          }

          if (secondsActive > this.timeoutDownloadGIFMilliseconds) {
            // then we are in a timeout error condition...

            // if there is no error - set it if we're not in error and not done.
            if (gifDownloads[site_id].stage == gifDownloadStage.startAnEEngine ||
              gifDownloads[site_id].stage == gifDownloadStage.generateImage) {
              gifDownloads[site_id].stage = gifDownloadStage.errorState;
              gifDownloads[site_id].error_msg = 'Timeout error'
              sessionStorage.setItem('gifDownloads', JSON.stringify(gifDownloads))
              this.gifDownloadErrorMsg = gifDownloads[site_id].error_msg ? gifDownloads[site_id].error_msg.substring(0, 50) : 'Error';
              this.gifDownloadProgress = 1;
              console.info("Timeout error for image download to error for site:", site_id);
            }
          }
        }
      }
    })

    // subscribe to websocket events re: gif download
    this.gifSocketEventSubscription = this.socket.SocketServiceEmitter
      .subscribe((socketResult: any) => {
        if (socketResult && socketResult.response_type) {
          switch (socketResult.response_type) {
            case WebSocketResponseTypeEnum.GIF_Ready:
              this.updateGifDownload(socketResult);
              break;
            case WebSocketResponseTypeEnum.GIF_Complete:
              this.completeGifDownload(socketResult);
              break;
            case WebSocketResponseTypeEnum.GIF_Error:
              this.errorGifDownload(socketResult);
              break;
          }
        }
      });

  }

  stopGifTimerAndSocket() {
    // unsubscribe and remove the timer.
    if (this.onGifTimerEvent) {
      this.onGifTimerEvent.unsubscribe();
      this.onGifTimerEvent = null;
    }

    if (this.gifSocketEventSubscription) {
      this.gifSocketEventSubscription.unsubscribe();
      this.gifSocketEventSubscription = null;
    }

    if (this.gifDownloadTimer) {
      delete this.gifDownloadTimer;
      this.gifDownloadTimer = null;
    }

  }

  startMtdzTimerAndSocket() {
    // timer for maintenance data progress bar

    this.mtdzDownloadTimer = timer(1000, 1000);
    this.onMtdzTimerEvent = this.mtdzDownloadTimer.subscribe(() => {
      // update the gif download progress?
      const site_id = this.user.active.id;

      if (sessionStorage.mtdzDownloads) {
        const mtdzDownloads = JSON.parse(sessionStorage.mtdzDownloads);
        if (site_id in mtdzDownloads) {

          const secondsActive = Date.now() - mtdzDownloads[site_id].startTime;

          // then set the state and progress value of the download for display
          if (mtdzDownloads[site_id].stage == mtdzDownloadStage.startAnEEngine) {
            // then progress is based on time since start
            const fracOfTime = secondsActive / this.expectedCOEStartTimeInMilliseconds;
            this.mtdzDownloadProgress = fracOfTime < 1 ? fracOfTime * 0.5 : 0.5;
          } else if (mtdzDownloads[site_id].stage == mtdzDownloadStage.generateData) {
            if (mtdzDownloads[site_id].gateway_model_class == GatewayModelClass.MCC) {
              // then progress is based on last reported progress
              // divide by 200 as we want this to be 50%
              this.mtdzDownloadProgress = 0.5 + mtdzDownloads[site_id].generate_progress / 200.0;
            } else {
              // rmd is based on progression towards estEndTime
              this.mtdzDownloadProgress = secondsActive / mtdzDownloads[site_id].estRunTime;
            }
          } else if (mtdzDownloads[site_id].stage == mtdzDownloadStage.downloadReady) {
            // we're done.
            this.mtdzDownloadProgress = 1;
          } else if (mtdzDownloads[site_id].stage == mtdzDownloadStage.errorState) {
            // we're in error.
            this.mtdzDownloadErrorMsg = mtdzDownloads[site_id].error_msg ? mtdzDownloads[site_id].error_msg.substring(0, 50) : 'Error';
            this.mtdzDownloadProgress = 1;
          }

          if (secondsActive > this.timeoutDownloadMtdzMilliseconds) {
            // then we are in a timeout error condition...

            // if there is no error - set it if we're not in error and not done.
            if (mtdzDownloads[site_id].stage == mtdzDownloadStage.startAnEEngine ||
              mtdzDownloads[site_id].stage == mtdzDownloadStage.generateData) {
              mtdzDownloads[site_id].stage = mtdzDownloadStage.errorState;
              mtdzDownloads[site_id].error_msg = 'Timeout error'
              sessionStorage.setItem('mtdzDownloads', JSON.stringify(mtdzDownloads))
              this.mtdzDownloadErrorMsg = mtdzDownloads[site_id].error_msg;
              this.mtdzDownloadProgress = 1;
              console.info("Timeout error for mtdz download to error for site:", site_id);
            }
          }
        }
      }
    })

    // subscribe to websocket events re: mtdz download
    this.mtdzSocketEventSubscription = this.socket.SocketServiceEmitter
      .subscribe((socketResult: any) => {
        if (socketResult && socketResult.response_type) {
          switch (socketResult.response_type) {
            // MCC cases
            case WebSocketResponseTypeEnum.MTDZ_Ready:
            case WebSocketResponseTypeEnum.MTDZ_Progress:
              this.updateMtdzDownload(socketResult);
              break;
            case WebSocketResponseTypeEnum.MTDZ_Complete:
              this.completeMtdzDownload(socketResult);
              break;
            case WebSocketResponseTypeEnum.MTDZ_Error:
              this.errorMtdzDownload(socketResult);
              break;
            // RMD cases
            case WebSocketResponseTypeEnum.Maint_Get_MTDZ_Complete:
              this.completeMtdzDownload(socketResult)
              break;
            case WebSocketResponseTypeEnum.Maint_Get_MTDZ_Error:
              this.errorMtdzDownload(socketResult)
              break;
          }
        }
      });

  }

  stopMtdzTimerAndSocket() {
    // unsubscribe and remove the timer.
    if (this.onMtdzTimerEvent) {
      this.onMtdzTimerEvent.unsubscribe();
      this.onMtdzTimerEvent = null;      
    }

    if (this.mtdzSocketEventSubscription) {
      this.mtdzSocketEventSubscription.unsubscribe();
      this.mtdzSocketEventSubscription = null;
    }

    if (this.mtdzDownloadTimer) {
      delete this.mtdzDownloadTimer;
      this.mtdzDownloadTimer = null;
    }

  }

  stopMaintenanceJobSocket() {
    if (this.maintJobSocketEventSubscription) {
      this.maintJobSocketEventSubscription.unsubscribe();
      this.maintJobSocketEventSubscription = null;
    }
    if (this.maintJobEventSubscription) {
      this.maintJobEventSubscription.unsubscribe();
      this.maintJobEventSubscription = null;
    }
  }

  gifDownloadSiteStage() {
    // where are we in the download stage for this site_id?
    let result = gifDownloadStage.noDownload;
    if (sessionStorage.gifDownloads) {
      // pull map of downloads from session storage
      // map is site_id -> download details
      const downloads = JSON.parse(sessionStorage.gifDownloads);
      result = downloads[this.user.active.id].stage;
    }
    return result;
  }

  mtdzDownloadSiteStage() {
    // where are we in the download stage for this site_id?
    let result = mtdzDownloadStage.noDownload;
    if (sessionStorage.mtdzDownloads) {
      // pull map of downloads from session storage
      // map is site_id -> download details
      const downloads = JSON.parse(sessionStorage.mtdzDownloads);
      result = downloads[this.user.active.id].stage;
    }
    return result;
  }

  mtdzDownloadInAnEStage() {
    return this.mtdzDownloadSiteStage() == mtdzDownloadStage.startAnEEngine;
  }
  mtdzDownloadInCreateState() {
    return this.mtdzDownloadSiteStage() == mtdzDownloadStage.generateData;
  }
  mtdzDownloadInReadyState() {
    return this.mtdzDownloadSiteStage() == mtdzDownloadStage.downloadReady;
  }
  mtdzDownloadInErrorState() {
    return this.mtdzDownloadSiteStage() == mtdzDownloadStage.errorState;
  }

  gifDownloadInAnEStage() {
    return this.gifDownloadSiteStage() == gifDownloadStage.startAnEEngine;
  }
  gifDownloadInCreateState() {
    return this.gifDownloadSiteStage() == gifDownloadStage.generateImage;
  }
  gifDownloadInReadyState() {
    return this.gifDownloadSiteStage() == gifDownloadStage.downloadReady;
  }
  gifDownloadInErrorState() {
    return this.gifDownloadSiteStage() == gifDownloadStage.errorState;
  }

  isGifDownloadActive() {
    // based on the current site - is an odu gif download active?
    let active = false;
    if (sessionStorage.gifDownloads) {
      // pull map of downloads from session storage
      // map is site_id -> download details
      const downloads = JSON.parse(sessionStorage.gifDownloads);
      active = this.user.active.id in downloads;
    }
    return active;
  }

  isMtdzDownloadActive() {
    // based on the current site - is an odu gif download active?
    let active = false;
    if (sessionStorage.mtdzDownloads) {
      // pull map of downloads from session storage
      // map is site_id -> download details
      const downloads = JSON.parse(sessionStorage.mtdzDownloads);
      active = this.user.active.id in downloads;
    }
    return active;
  }

  gifDownloadClear() {
    // forget about the current download.
    if (sessionStorage.getItem("gifDownloads") === null) {
      // not there?
      console.warn("Can't clear download - no downloads tracked")
      return
    }

    // load it
    const trackedDownloads = JSON.parse(sessionStorage.gifDownloads);

    // clear us.
    const site_id = this.user.active.id;

    if (trackedDownloads[site_id] != undefined) {
      delete trackedDownloads[site_id];
      console.info('Removed gif download tracking for site_id:', site_id)

      if (Object.keys(trackedDownloads).length === 0) {
        // then its empty empty - remove the key from session Storage
        sessionStorage.removeItem('gifDownloads');

        this.stopGifTimerAndSocket();
      } else {

        // write it back
        sessionStorage.setItem('gifDownloads', JSON.stringify(trackedDownloads))
      }
    } else {
      console.warn('Can not remove gif download tracking for site_id ', site_id, ' it is not tracked')
    }

  }

  mtdzDownloadClear() {
    // forget about the current download.
    if (sessionStorage.getItem("mtdzDownloads") === null) {
      // not there?
      console.warn("Can't clear download - no downloads tracked")
      return
    }

    // load it
    const trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);

    // clear us.
    const site_id = this.user.active.id;

    if (trackedDownloads[site_id] != undefined) {
      delete trackedDownloads[site_id];
      console.info('Removed mtdz download tracking for site_id:', site_id)

      if (Object.keys(trackedDownloads).length === 0) {
        // then its empty empty - remove the key from session Storage
        sessionStorage.removeItem('mtdzDownloads');

        this.stopMtdzTimerAndSocket();
      } else {

        // write it back
        sessionStorage.setItem('mtdzDownloads', JSON.stringify(trackedDownloads))
      }
    } else {
      console.warn('Can not remove mtdz download tracking for site_id ', site_id, ' it is not tracked')
    }

  }

  async displayInformation(headerText: string, messageText: string) {
    if(headerText == OK_MODAL_MAINTENANCE_DATA_TITLE) {
      const midnightUTC = date_time_utilities.getUtcDate(0,0)
      const threeAMUTC = date_time_utilities.getUtcDate(3,0)

      const formatter = new Intl.DateTimeFormat('en-US', { 
        hour: '2-digit', 
        minute: '2-digit', 
        timeZone: this.user.active.timezone,
        hour12: true 
      });
      
      const startFormatted = formatter.format(midnightUTC);
      const endFormatted = formatter.format(threeAMUTC);

      messageText = messageText.replace("%%", startFormatted);
      messageText = messageText.replace("^^", endFormatted);
    }

    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
    if(headerText == OK_MODAL_MAINTENANCE_DATA_TITLE) {
      document.querySelector(`#myLinkDriveLink`).setAttribute(`target`, `_blank`);
    }
  }

  async showMaintenanceInfo(e) {
    if (!this.siteService.handleIsConnected()) return;
    this.displayInformation(OK_MODAL_MAINTENANCE_DATA_TITLE, OK_MODAL_MAINTENANCE_DATA_CONTENT);
  }

  async showSystemDataInfo(e) {
    if (!this.siteService.handleIsConnected()) return;
    this.displayInformation(OK_MODAL_SYSTEM_DATA_TITLE, OK_MODAL_SYSTEM_DATA_CONTENT);
  }

  // async onDownloadCSV() {
  //   if (!this.siteService.handleIsConnected())
  //     return;

  //   const modal = await this.modalController.create({
  //     component: SiteAnalyticsDownloadCSVComponent,
  //     cssClass: '',
  //     backdropDismiss: true,
  //   });

  //   modal.onDidDismiss().then((data) => {

  //   });
  //   return await modal.present();

  // }

  addGifDownload(gifRequest) {
    // track this request in the session object
    const gifTracker = new gifDownloadTracker;

    // get whats there now.
    let trackedDownloads = new Map();
    if (sessionStorage.gifDownloads) {
      trackedDownloads = JSON.parse(sessionStorage.gifDownloads);
    }
    if (!this.gifDownloadTimer) {
      // then start up the timer and events
      this.startGifTimerAndSocket();
    }


    // add this odu download
    gifTracker.request_id = gifRequest.request_id;
    gifTracker.startTime = Date.now();
    gifTracker.site_id = this.user.active.id;
    gifTracker.site_name = this.user.active.name;
    gifTracker.bus_address = gifRequest.odu_bus_address;
    gifTracker.gateway_id = gifRequest.gateway_id;

    trackedDownloads[this.user.active.id] = gifTracker;
    console.log("Tracking download of gif file for site ", this.user.active.id, "for odu ", gifRequest.odu_bus_address);

    // write it back
    sessionStorage.setItem('gifDownloads', JSON.stringify(trackedDownloads));

  }

  updateGifDownload(socketResult) {
    // update this download to be in the gif_ready state
    const request_id = socketResult.request_id;
    // find this download in the sessionStorage
    let trackedDownloads = new Map();

    if (sessionStorage.getItem("gifDownloads") === null) {
      // not there?
      console.warn("Received a gif image download update with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.gifDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage
        value.stage = gifDownloadStage.generateImage;
        found = true;
        console.info("Updated a gif image download to ready for site:", value.site_id);
      }
    }
    if (!found) {
      console.info("Recieved a gif download update but can't find the request locally. Request_id:", request_id);
      return;
    }

    // write it back
    sessionStorage.setItem('gifDownloads', JSON.stringify(trackedDownloads));
  }

  completeGifDownload(socketResult) {
    // update this download to be in the gif_complete state
    const request_id = socketResult.request_id;
    // find this download in the sessionStorage
    let trackedDownloads = new Map();

    if (sessionStorage.getItem("gifDownloads") === null) {
      // not there?
      console.warn("Received a gif image download complete with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.gifDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage
        value.stage = gifDownloadStage.downloadReady;
        value.image_url = socketResult.image_url;
        found = true;
        console.info("Updated a gif image download to complete for site:", value.site_id);
      }
    }
    if (!found) {
      console.info("Recieved a gif download complete but can't find the request locally. Request_id:", request_id);
      return;
    }

    // write it back
    sessionStorage.setItem('gifDownloads', JSON.stringify(trackedDownloads));
  }

  errorGifDownload(socketResult) {
    // update this download to be in the gif_error state
    const request_id = socketResult.request_id;
    const err_msg = socketResult.error_msg;
    // find this download in the sessionStorage
    let trackedDownloads = new Map();

    if (sessionStorage.getItem("gifDownloads") === null) {
      // not there?
      console.warn("Received a gif image download error msg with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.gifDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage
        value.stage = gifDownloadStage.errorState;
        value.error_msg = err_msg;
        found = true;
        console.info("Updated a gif image download to error for site:", value.site_id, " error:", err_msg);
      }
    }
    if (!found) {
      console.info("Recieved a gif download error but can't find the request locally. Request_id:", request_id);
      return;
    }

    // write it back
    sessionStorage.setItem('gifDownloads', JSON.stringify(trackedDownloads));
  }

  addMtdzDownload(mtdzRequest: SiteAnalyticsMtdzDownloadRequest, estRunTime:number = 0) {

    // track this request in the session object
    const mtdzTracker = new mtdzDownloadTracker;

    // get whats there now.
    let trackedDownloads = new Map();
    if (sessionStorage.mtdzDownloads) {
      trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);
    }
    if (!this.mtdzDownloadTimer) {
      // then start up the timer and events
      this.startMtdzTimerAndSocket();
    }


    // add this download
    mtdzTracker.request_id = mtdzRequest.request_id;
    mtdzTracker.startTime = Date.now();
    mtdzTracker.site_id = this.user.active.id;
    mtdzTracker.site_name = this.user.active.name;
    mtdzTracker.bus_address = parseInt(mtdzRequest.odu_bus_address);
    mtdzTracker.gateway_id = mtdzRequest.gateway_id;
    mtdzTracker.gateway_model_class = mtdzRequest.gateway_model;
    mtdzTracker.start_date = mtdzRequest.start_date;
    mtdzTracker.end_date = mtdzRequest.end_date;
    mtdzTracker.generate_progress = 0;
    mtdzTracker.s3_file_name_to_download = '';
    mtdzTracker.estRunTime = estRunTime;

    // if an rmd download -then we track when we expect to be done.
    if (mtdzRequest.gateway_model == GatewayModelClass.RMD) {
      // no ane engine to start.
      mtdzTracker.stage = mtdzDownloadStage.generateData;
    }

    trackedDownloads[mtdzTracker.site_id] = mtdzTracker;
    console.log("Tracking download of maintenance data for site ", this.user.active.id, "for gateway ", mtdzRequest.gateway_id);

    // write it back
    sessionStorage.setItem('mtdzDownloads', JSON.stringify(trackedDownloads));

  }

  updateMtdzDownload(socketResult) {
    // teo cases to handle here
    // MTDZ_Ready - engine is ready
    // MTDZ_Progress - % complete update received.

    // 50% of progress is waiting for engine
    // 50% of progress is generation of data

    const request_id = socketResult.request_id;
    // find this download in the sessionStorage
    let trackedDownloads = new Map();
    if (sessionStorage.getItem("mtdzDownloads") === null) {
      // not there?
      console.warn("Received an mtdz download update with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage to generate data - this is the case for both MTDZ_Ready and Progress
        value.stage = mtdzDownloadStage.generateData;
        found = true;
        value.generate_progress = 0;
        if (socketResult.response_type == WebSocketResponseTypeEnum.MTDZ_Progress) value.generate_progress = socketResult.progress;
        console.info("Updated an mtdz download for site:", value.site_id);
      }
    }
    if (!found) {
      console.info("Recieved an mtdz download update but can't find the request locally. Request_id:", request_id);
      return;
    }

    // write it back
    sessionStorage.setItem('mtdzDownloads', JSON.stringify(trackedDownloads));
  }

  completeMtdzDownload(socketResult) {
    // update this download to be in the mtdz_complete state
    const request_id = socketResult.request_id;
    // find this download in the sessionStorage
    let trackedDownloads = new Map();

    if (sessionStorage.getItem("mtdzDownloads") === null) {
      // not there?
      console.warn("Received an mtdz download complete with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage
        value.stage = mtdzDownloadStage.downloadReady;
        value.s3_file_name_to_download = socketResult.s3_file_name_to_download;
        found = true;
        console.info("Updated an mtdz download to complete for site:", value.site_id);
      }
    }
    if (!found) {
      console.info("Recieved an mtdz download complete but can't find the request locally. Request_id:", request_id);
      return;
    }

    // write it back
    sessionStorage.setItem('mtdzDownloads', JSON.stringify(trackedDownloads));
  }

  errorMtdzDownload(socketResult) {
    // update this download to be in the gif_error state
    const request_id = socketResult.request_id;
    const err_msg = socketResult.error_msg ? socketResult.error_msg : 'No data exists or unexpected error';
    // find this download in the sessionStorage
    let trackedDownloads = new Map();

    if (sessionStorage.getItem("mtdzDownloads") === null) {
      // not there?
      console.warn("Received a mainenance data download error msg with no downloads active. request_id:", request_id);
      return;
    }

    // load it
    trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);
    let found = false;

    for (const key in trackedDownloads) {
      const value = trackedDownloads[key];
      if (value.request_id == request_id) {
        // then this is our request - set stage
        value.stage = mtdzDownloadStage.errorState;
        value.error_msg = err_msg;
        found = true;
        console.info("Updated a maintenance data download to error for site:", value.site_id, " error:", value.error_msg);
      }
    }
    if (!found) {
      console.info("Recieved a maintenance data error but can't find the request locally. Request_id:", request_id)
      return;
    }

    // write it back
    sessionStorage.setItem('mtdzDownloads', JSON.stringify(trackedDownloads))
  }

  async onGenerateMaintenanceData() {
    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: SiteAnalyticsGenerateMaintenanceDataComponent,
      cssClass: 'me-sc-ion-modal-md-h-5',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.selected_gateway !== undefined &&
        data.data.selected_gateway !== null
      ) {
        // then we queue up another request for download.
        let today = new Date();

        const odu = data.data.selected_outdoor_unit;
        const selected_gateway = data.data.selected_gateway;
        const mtdzRequest: SiteAnalyticsMtdzDownloadRequest = new SiteAnalyticsMtdzDownloadRequest()
        mtdzRequest.request_id = uuid();
        mtdzRequest.gateway_id = selected_gateway.id;
        mtdzRequest.gateway_model = selected_gateway.model.class_name
        mtdzRequest.site_id = selected_gateway.site_id;
        mtdzRequest.start_date = data.data.start_date_value;
        mtdzRequest.end_date = data.data.end_date_value;

        let n = moment(today.toISOString())
        let tzs = n.tz(this.user.active.timezone).format('ZZ')
        // we are going the inverse direction - from non UTC -> UTC, so we need to invert the +/- sign..
        const plusMinus = [ '+','-']
        const newSign = plusMinus[(plusMinus.indexOf(tzs[0]) + 1 ) % 2]
        // re-assemble the time_zone offset
        mtdzRequest.time_zone = `${newSign}${tzs.substring(1,3)}:${tzs.substring(3,5)}`

        let endTimeHours = '23'
        let endTimeMinutes = '59'
        if (today.toISOString().slice(0,10) === mtdzRequest.end_date) {
           // cant ask for an end time in the future
           // get hour minute of now - take off 1 hour to avoid touching 'now'
          endTimeHours = String(today.getUTCHours()-1).padStart(2, '0')
          endTimeMinutes = String(today.getUTCMinutes()).padStart(2, '0')
         }

        let rmdEstRunTime = 0;
        if (mtdzRequest.gateway_model == GatewayModelClass.RMD) {
          // RMD - tweak the dates to the expected format of "YYYYMMDDhhmm"
          const start_of_start_date = mtdzRequest.start_date+' 00:00'
          const end_of_end_date = mtdzRequest.end_date+` ${endTimeHours}:${endTimeMinutes}`

          let startDate = new Date(start_of_start_date)
          let endDate = new Date(end_of_end_date)
          // this value will be in milliseconds
          // this magic /1200 comes from AC&R as estimated length of time needed. Seems arbitary to me.
          rmdEstRunTime = (endDate.getTime() - startDate.getTime()) / 1200

          // we start at start of the first day, and end at end of the last day
          mtdzRequest.start_date = mtdzRequest.start_date.replaceAll('-','')+'0000';
          mtdzRequest.end_date = mtdzRequest.end_date.replaceAll('-','')+endTimeHours+endTimeMinutes;
        }

        // // reset the progress bar
        this.mtdzDownloadProgress = 0;

        // request server perform this
        this.socket.requestMtdzData(mtdzRequest)

        // add this to the active list
        this.addMtdzDownload(mtdzRequest, rmdEstRunTime)

      }

    });
    return await modal.present();
  }

  async onGenerateGIF() {
    // request from the page to generate a gif file...
    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: SiteAnalyticsGenerateGIFComponent,
      cssClass: 'me-custom-modal-standard',
      backdropDismiss: true,
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.selected_outdoor_unit !== undefined &&
        data.data.selected_outdoor_unit !== null
      ) {
        // then we queue up another request for download.
        const odu = data.data.selected_outdoor_unit;
        const selected_gateway = data.data.selected_gateway;
        const gifRequest: SiteAnalyticsGIFDownloadRequest = new SiteAnalyticsGIFDownloadRequest()
        gifRequest.request_id = uuid();
        gifRequest.gateway_id = selected_gateway.id;
        gifRequest.odu_bus_address = String(odu.bus_address);
        gifRequest.site_id = selected_gateway.site_id

        // reset the progress bar
        this.gifDownloadProgress = 0;

        // request server perform this
        this.socket.requestGifData(gifRequest)

        // add this to the active list
        this.addGifDownload(gifRequest)

      }

    });
    return await modal.present();

  }

  async refreshGatewayListByActiveSite(activeSiteId: string) {
    if (activeSiteId === "default-site") {
      const s: any = JSON.parse(this.appStorageService.localStorageGetItem('ActiveSite'));
      if (s) {
        activeSiteId = s['id'];
        const selectedSiteInfo = new Site({
          id: s['id'],
          name: s['name'],
          phone: s['phone'],
          company: s['company'],
          addressOne: s['addressOne'],
          addressTwo: s['addressTwo'],
          city: s['city'],
          state: s['state'],
          zip: s['zip'],
          country: s['country'],
          site_photo_name: s['site_photo_name'],
          sitestatustype_id: 1,
          current_owner_id:'',
          locations: s['locations'],
        });
        this.user.setActiveSite(selectedSiteInfo, true);
      }
    }


    if (activeSiteId.toLowerCase() !== 'default-site') {
      this.dataLoading = true;
      this.rmdClassGatewaysExist = false;
      this.rmdClassGatewaysMapped = false;
      this.mccClassGatewaysExist = false;
      this.mccClassGatewaysMapped = false;
      this.siteService
        .getSiteGatewaysWithUnits(activeSiteId)
        .subscribe((siteGateways: Gateway[]) => {

          this.siteGatewayState = SiteGatewayState.NoGateways;

          if (siteGateways.length > 0) {
            siteGateways.forEach((sgw) => {
              if (sgw.model.class_name == GatewayModelClass.RMD) this.rmdClassGatewaysExist = true;
              if (sgw.model.class_name == GatewayModelClass.MCC) this.mccClassGatewaysExist = true;
              // then we at least have unmapped gateways
              if (this.siteGatewayState == SiteGatewayState.NoGateways) this.siteGatewayState = SiteGatewayState.Unmapped;
              // does THIS site have units?
              if (sgw.units.filter((e) => { return e.type == GatewayUnitTwoDigitType.OutdoorUnit }).length > 0) {
                // then we have mapped units on this gateway
                this.siteGatewayState = SiteGatewayState.Mapped;
                
                // is this an RMD class?
                if (sgw.model.class_name == GatewayModelClass.RMD) this.rmdClassGatewaysMapped = true;
                if (sgw.model.class_name == GatewayModelClass.MCC) this.mccClassGatewaysMapped = true;
              }
            })
          }
          this.dataLoading = false;
        }
        );
    }
  }

  gifDownload() {
    // request to download the completed gif generated file for the active site.

    if (sessionStorage.getItem("gifDownloads") === null) {
      // not there?
      console.warn("Received a gif image download reqeust msg with no downloads active.")
      return
    }

    // load it
    const trackedDownloads = JSON.parse(sessionStorage.gifDownloads);
    const site_id = this.user.active.id;
    if (trackedDownloads[site_id] != undefined) {
      // then download this one.
      let image_url = trackedDownloads[site_id].image_url;
      if (!image_url) {
        image_url = "https://s3.amazonaws.com/dev-app.kenzacloud.com/svg/balloon.svg";
      }
      // downloadGifFile


      this.siteService.downloadGifFile(site_id, image_url).subscribe(function (result) {
        const file = new Blob([result], { type: 'image/gif' });
        const fileURL = window.URL.createObjectURL(file);

        const d = new Date();

        const a = document?.createElement("a");
        if (document && document?.body) document?.body?.appendChild(a);
        a.href = fileURL;
        a.download = 'Site_' + trackedDownloads[site_id].site_name + '_ODU_' + trackedDownloads[site_id].odu_bus_address + '_flowdiagram.gif';
        a.click();
        a.remove();
      });

      // now clear the download state.
      this.gifDownloadClear();

    } else {
      // not found?
      console.info("Recieved a gif download request but can't find the site_id. site_id:", site_id)
    }
  }


  mtdzDownload() {
    // request to download the completed mdtzfile for the active site.

    if (sessionStorage.getItem("mtdzDownloads") === null) {
      // not there?
      console.warn("Received an mtdz download reqeust msg with no downloads active.")
      return
    }

    // load it
    const trackedDownloads = JSON.parse(sessionStorage.mtdzDownloads);
    const site_id = this.user.active.id;
    if (trackedDownloads[site_id] != undefined) {

      const s3_file_name = trackedDownloads[site_id].s3_file_name_to_download;

      if (trackedDownloads[site_id].gateway_model_class == GatewayModelClass.MCC) {
        // MCC

        // downloadmtdzFile
        this.siteService.downloadMtdzFile(site_id, s3_file_name).subscribe(function (result) {
          const file = new Blob([result], { type: 'application/zip' });
          const fileURL = window.URL.createObjectURL(file);

          const d = new Date();

          const a = document?.createElement("a");
          if (document && document?.body) document?.body?.appendChild(a);
          a.href = fileURL;
          a.download = 'Site_' + trackedDownloads[site_id].site_name.replace(' ', '_') + '_MaintenanceData.zip';
          a.click();
          a.remove();
        });
      } else {
        // then RMD
        // downloadmtdzFile
        this.siteService.downloadRmdMtdzFile(site_id, s3_file_name).subscribe(function (result) {
          const file = new Blob([result], { type: 'application/zip' });
          const fileURL = window.URL.createObjectURL(file);

          const d = new Date();

          const a = document?.createElement("a");
          if (document && document?.body) document?.body?.appendChild(a);
          a.href = fileURL;
          a.download = s3_file_name;
          a.click();
          a.remove();
        })
      }
      // now clear the download state.
      this.mtdzDownloadClear();

    } else {
      // not found?
      console.info("Recieved an mtdz download request but can't find the site_id. site_id:", site_id);
    }
  }

  // methods related to test run

  checkForRunningTests() {
    // are we active in any test runs for this site?
    this.siteService.getActiveTestRuns(this.user.active.id).subscribe((result: [TestRunMaintenanceJob]) => {
      this.activeTestRunJobs = [];
      result.forEach((jobObj) => {
        this.activeTestRunJobs.push({
          id: jobObj.id,
          status: jobObj.status,
          progress: jobObj.progress
        })
      })
      // push an update.
      this.updateTestRunCardDisplay();
    }, (error) => {
      // clear out the known list.
      console.log('error getting active test run list');
    })
  }

  sortMaintJobByMostRecent(jobs) {
    let jobsModifiable = Array.isArray(jobs) && jobs.length > 0 && jobs.some(job => job.started_at || job.created_at);
    let validDates = jobsModifiable ? jobs.some(job => new Date(job.started_at) || new Date(job.created_at)) : false;
    let sortedAndModifiedMaintJobs = jobsModifiable && validDates ? jobs.sort((a, b) => {
      // Use started_at if not null, otherwise fall back to created_at
      const dateA = a.started_at || a.created_at;
      const dateB = b.started_at || b.created_at;
      let newestToOldest = new Date(dateB).getTime() - new Date(dateA).getTime();
      return newestToOldest;
    }).map(recentJob => {
      const date = new Date();
      const timeZone = recentJob.details 
                        && Array.isArray(recentJob.details) 
                        && recentJob.details.length > 0 
                        ? recentJob.details[0].site_timezone 
                        : this.user.active.timezone;

      const offsetDate = new Intl.DateTimeFormat(this.locale, { timeZone, timeZoneName: `longOffset` }).format(date);
      const siteTimezoneOffset = offsetDate.substring(offsetDate.length - 6);

      let hasValidCreatedDate = recentJob.created_at && new Date(recentJob.created_at);
      let created = hasValidCreatedDate ? new Date(recentJob.created_at) : recentJob.created_at;
      if (hasValidCreatedDate) {
        let createdDate = new Date(recentJob.created_at + `Z`);
        let createdShortString = formatDate(createdDate, `MM/dd/yyyy hh:mm a`, this.locale, siteTimezoneOffset);
        created = formatDate(createdShortString, `long`, this.locale, siteTimezoneOffset);
      }

      let hasValidStartDate = recentJob.started_at && new Date(recentJob.started_at);
      let started = hasValidStartDate ? new Date(recentJob.started_at) : recentJob.started_at;
      if (hasValidStartDate) {
        let startedDate = new Date(recentJob.started_at + `Z`);
        let startedShortString = formatDate(startedDate, `MM/dd/yyyy hh:mm a`, this.locale, siteTimezoneOffset);
        started = formatDate(startedShortString, `long`, this.locale, siteTimezoneOffset);
      }

      let hasValidUpdatedDate = recentJob.updated_at && new Date(recentJob.updated_at);
      let updated = hasValidUpdatedDate ? new Date(recentJob.updated_at) : recentJob.updated_at;
      if (hasValidUpdatedDate) {
        let updatedDate = new Date(recentJob.updated_at + `Z`);
        let updatedShortString = formatDate(updatedDate, `MM/dd/yyyy hh:mm a`, this.locale, siteTimezoneOffset);
        updated = formatDate(updatedShortString, `long`, this.locale, siteTimezoneOffset);
      }

      let hasValidEndedDate = recentJob.ended_at && new Date(recentJob.ended_at);
      let ended = hasValidUpdatedDate ? new Date(recentJob.ended_at) : recentJob.ended_at;
      if (hasValidEndedDate) {
        let endedDate = new Date(recentJob.ended_at + `Z`);
        let endedShortString = formatDate(endedDate, `MM/dd/yyyy hh:mm a`, this.locale, siteTimezoneOffset);
        ended = formatDate(endedShortString, `long`, this.locale, siteTimezoneOffset);
      }

      let modifiedJob = {
        ...recentJob,
        datetimes: {
          created,
          started,
          updated,
          ended,
        },
      }

      return modifiedJob;
    }) : jobs;
    
    return sortedAndModifiedMaintJobs;
  }

  getCardDisplayData(jobs) {
    // Based on the values in jobs - set the following values
    // Used to display on Maintenance Cards

    jobs = this.sortMaintJobByMostRecent(jobs);

    let mostRecentStatusID = 0;
    let jobsActive: boolean = false; // Are any jobs active?
    let progress: number = 0; // 0, 0.01, 0.02 ... 1 for progress of bar
    let stateMessage: string = ``; // What to display over the progress bar
    let progressTitle: string = ``; // The tooltip for the card progress bar
    let jobStatus: maintenanceJobStatusEnum; // The status of the job being displayed on the card

    // This is the desired most import status to show on the card from top down
    let preferredStatus = [  
      maintenanceJobStatusEnum.cancel, 
      maintenanceJobStatusEnum.complete,          
      maintenanceJobStatusEnum.error,
      maintenanceJobStatusEnum.new,
      maintenanceJobStatusEnum.booting,
      maintenanceJobStatusEnum.running,
    ];

    // jobs.forEach((miniJob, miniJobIndex) => {
    //   // Is this job status preferred for display?
    //   let preferredStatusIndex = preferredStatus.indexOf(miniJob.status);
    //   if (preferredStatusIndex > bestJobStatusIndex) {
    //     // Then this is best JobStatus
    //     bestJobStatusIndex = preferredStatusIndex;
    //   }
    // })

    jobsActive = jobs.length > 0;
    if (jobsActive) mostRecentStatusID = preferredStatus.indexOf(jobs[0].status);
    jobStatus = preferredStatus[mostRecentStatusID];
    let displayInRed = jobStatus == maintenanceJobStatusEnum.error || jobStatus == maintenanceJobStatusEnum.complete || jobStatus == maintenanceJobStatusEnum.cancel;

    if (jobStatus == maintenanceJobStatusEnum.running) {
      // Then we need to calculate how far along we are to done.
      let progressTotal = 0.0;
      let progressCount = 0;

      jobs.forEach((job: miniMaintJob) => {
        if (job.status == maintenanceJobStatusEnum.running) {
          progressTotal += job.progress;
          progressCount += 1;
        }
      })

      // Now get the average as a float from 0..1
      progress = progressTotal / ( progressCount * 100.0 );
      progressTitle = `${Number((progress * 100).toFixed(1))}% Complete`;
    } else if (displayInRed) {
      // Then we need to display in red with 100% progress
      progress = 1.0;
      progressTitle = genericMaintenanceJobStatusEnumMessage[jobStatus];
    } else {
      // Then we set the progress bar to zero.
      progress = 0.0;
      progressTitle = genericMaintenanceJobStatusEnumMessage[jobStatus];        
    }
    
    stateMessage = genericMaintenanceJobStatusEnumMessage[jobStatus];

    let cardDisplayData = {
      jobs,
      progress,
      jobStatus,
      jobsActive,
      stateMessage,
      progressTitle,
      mostRecentStatusID,
    };

    return cardDisplayData;
  }

  updateTestRunCardDisplay() {
    // based on the values in this.activeTestRunJobs - set the following values
    // used to display on the Test Run Card
    // testRunActive - is a run active?
    // testRunDisplayJobStatus - the status of the job being displayed on the card
    // testRunStateMessage - what to display over the progress bar
    // testRunProgress - 0..1 for progress of bar
    // testRUnProgressTitle - the tooltip for the Test Run progress bar and friends.

    // this is the desired most import status to show on the card
    // from top down.
    let preferredStatus = [  
      maintenanceJobStatusEnum.cancel, 
      maintenanceJobStatusEnum.complete,          
      maintenanceJobStatusEnum.error,
      maintenanceJobStatusEnum.new,
      maintenanceJobStatusEnum.booting,
      maintenanceJobStatusEnum.running,
    ];

    let bestJobStatusIndex = 0;
    this.testRunActive = this.activeTestRunJobs.length > 0;
    this.activeTestRunJobs.forEach((miniJob) => {
      // is this job status preferred for display?
      let preferredStatusIndex = preferredStatus.indexOf(miniJob.status);
      if (preferredStatusIndex > bestJobStatusIndex) {
        // then this is best JobStatus
        bestJobStatusIndex = preferredStatusIndex;
      }
    })
    this.testRunDisplayJobStatus = preferredStatus[bestJobStatusIndex];
    if (this.testRunDisplayJobStatus == maintenanceJobStatusEnum.running) {
      // then we need to calculate how far along we are to done.
      let progressTotal = 0.0;
      let progressCount = 0;
      this.activeTestRunJobs.forEach( (job: miniMaintJob) => {
        if (job.status == maintenanceJobStatusEnum.running) {
          progressTotal += job.progress;
          progressCount += 1;
        }
      })
      // now get the average as a float from 0..1
      this.testRunProgress = progressTotal / ( progressCount * 100.0 );
      this.testRunProgressTitle = `${Number((this.testRunProgress * 100).toFixed(1))}% Complete`
    } else if (this.testRunDisplayJobStatus == maintenanceJobStatusEnum.error || 
                  this.testRunDisplayJobStatus == maintenanceJobStatusEnum.complete ||
                  this.testRunDisplayJobStatus == maintenanceJobStatusEnum.cancel) {
        // then we need to display in red with 100% progress
        this.testRunProgress = 1.0;
        this.testRunProgressTitle = maintenanceJobStatusEnumMessage[this.testRunDisplayJobStatus];
      } else {
        // then we set the progress bar to zero.
        this.testRunProgress = 0.0;
        this.testRunProgressTitle = maintenanceJobStatusEnumMessage[this.testRunDisplayJobStatus];        
      }
      this.testRunStateMessage = maintenanceJobStatusEnumMessage[this.testRunDisplayJobStatus];
  }

  isTestRunStatusActive() {
    // is the active job status considered 'active' ?
    return (this.testRunDisplayJobStatus == maintenanceJobStatusEnum.new ||
      this.testRunDisplayJobStatus == maintenanceJobStatusEnum.booting ||
      this.testRunDisplayJobStatus == maintenanceJobStatusEnum.running);
  }
  
  isTestRunStatusComplete() {
    return (this.testRunDisplayJobStatus == maintenanceJobStatusEnum.complete);
  }

  isTestRunStatusError() {
    return (this.testRunDisplayJobStatus == maintenanceJobStatusEnum.cancel ||
      this.testRunDisplayJobStatus == maintenanceJobStatusEnum.error);
  }

  updateTestRunProgress(response) {
    // process notifiction of an update to a test run job
    const maintenanceJobId = response.maintenancejob_id;
    const job = this.activeTestRunJobs && this.activeTestRunJobs.length > 0 ? this.activeTestRunJobs.find((job) => job.id == maintenanceJobId) : null;
    if (job) {
      // then update it with this detail
      job.progress = parseFloat(response.progress);
      job.status = response.status;
      // update
      this.updateTestRunCardDisplay();
    } else {
      // an update for a job we dont have?  refresh.
      this.checkForRunningTests();
    }
  }

  isTestRunActive(): boolean {
    // if not active - then Active!
    return this.testRunActive;
  }

  showAnalyticsHistory() {
    // navigate to history page.
    this.router.navigate(['/account/' + this.user.id + '/details/analytics-reports'], { queryParams: { fromSiteId: this.user.active.id} } );
  }

  async onPerformTestRun() {
    // request from the page to perform a test run...
    if (!this.siteService.handleIsConnected())
      return;

    if (!this.rmdClassGatewaysMapped) {
      // then we have no ODU's to run on ... ask them to map the RMD gateway
      const alert = await this.alertController.create({
        header: TEST_MODE_NO_SUITABLE_ODU_TITLE,
        message: TEST_MODE_NO_SUITABLE_ODU_DETAIL,
        cssClass: 'me-info-button-css',
        buttons: [
          {
            text: 'Ok',
            cssClass: 'ok-button'
          }
        ]
      });
  
      await alert.present();
      return
    }

    const modal = await this.modalController.create({
      component: SiteAnalyticsTestRunComponent,
      cssClass: 'me-custom-modal-standard',
      backdropDismiss: false,
      componentProps: {
        site_id: this.user.active.id
      }
    });

    modal.onDidDismiss().then((data) => {

      if (!this.siteService.handleIsConnected()) return;

      if (data && data.data && 'upgrade' in data.data) {
        // we're doing a redirect for a gateway upgrade
        this.router.navigate(['/account/' + this.user.id + '/details/subscriptions'], { queryParams: { upgrade: data.data.upgrade}});
        this.mainSiteUIService.viewAccountGatewaySubscriptionPlans();
      } else {

        // then we cancelled or we started a test run
        // update the progress display (if there is one)
        const cancelled = 'cancel' in data.data && data.data.cancel;

        if (!cancelled) this.checkForRunningTests();
      }
    });

    return await modal.present();
  }

}
