import { Component, OnInit, Input } from '@angular/core';
import { Site } from '../../classes/site';
import {
  ModalController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { SiteService } from '../../services/site.service';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { GatewaySubscriptionStatusTypeEnum } from 'src/app/enumerations/enums';
// import { ConfirmationModalPage } from 'src/app/common/modal/confirmation-modal/confirmation-modal.page';

@Component({
  selector: 'app-site-deactivate',
  templateUrl: './site-deactivate.component.html',
  styleUrls: ['./site-deactivate.component.scss'],
})
export class SiteDeactivateComponent implements OnInit {
  @Input() parent_selected_site: Site;
  @Input() parent_site_gateways: Gateway[] = [];
  i_btn_ok_cancel_color = 'reverse-primary';
  site_gateways: Gateway[] = [];
  site_has_active_gateways = false;
  min_number_of_gateway_display_lines = 3;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private siteService: SiteService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    if (this.parent_site_gateways && this.parent_site_gateways.length > 0) {
      this.site_has_active_gateways = true;
      this.site_gateways = [];
      this.site_gateways = this.prepareGateways(this.parent_site_gateways);
      this.site_gateways = this.display_min_number_of_gateways(
        this.site_gateways,
        this.min_number_of_gateway_display_lines
      );
    }
  }

  // Referenced from activeSubscription in getAccountOwnerGateways() within account-detail-main.component.ts
  getCurrentSubscriptionName(gateway) {
    let gwSubs = gateway?.gatewaysubscription;
    let latestUpdatedSubscription = gwSubs?.find(sub => sub.gateway_subscription_status_type_id === GatewaySubscriptionStatusTypeEnum.Active);
    let subscriptionThatHasntExpiredYet = gwSubs?.find(sub => sub.gateway_subscription_status_type_id === GatewaySubscriptionStatusTypeEnum.Expired && new Date(sub.expires_at + ` 23:59`) >= new Date());
    return (subscriptionThatHasntExpiredYet || latestUpdatedSubscription)?.subscriptions?.name;
  }

  display_min_number_of_gateways(
    list_of_site_gateways: Gateway[],
    min_number_of_gateway_display_lines: number
  ): Gateway[] {
    const lines_to_add: number =
      list_of_site_gateways.length < min_number_of_gateway_display_lines
        ? min_number_of_gateway_display_lines - list_of_site_gateways.length
        : 0;
    let index = 0;
    if (lines_to_add > 0) {
      const blank_gateway: Gateway = new Gateway();
      blank_gateway.name = '';
      blank_gateway.subscription_name = '';

      for (index = 0; index < lines_to_add; index++) {
        list_of_site_gateways.push(blank_gateway);
      }
    }
    return list_of_site_gateways;
  }

  prepareGateways(siteGateways: Gateway[]): Gateway[] {
    siteGateways = siteGateways.map((sg) => {
      sg.isExpanded = false;
      if (
        sg['gatewaysubscription'] &&
        sg['gatewaysubscription'][0] &&
        sg['gatewaysubscription'][0]['subscriptions']
      ) {
        sg.subscription_name =
          sg['gatewaysubscription'][0]['subscriptions']['name'];
      } else {
        sg.subscription_name = 'None';
      }
      return sg;
    });
    siteGateways = siteGateways.sort(compareValues('name', 'asc'));
    return siteGateways;
  }

  async confirm_deactivate_site() {
    if (!this.siteService.handleIsConnected()) return;

    const alert = await this.alertController.create({
      header: 'Confirm Deactivate Site',
      message:
        '<div class="me-redClass">Are you sure you want to deactivate this site?</div></br><div class="me-centerText">This action cannot be undone.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          role: 'DEACTIVATE',
          handler: () => {
            if (!this.siteService.handleIsConnected()) return false;
          },
        },
        {
          text: 'No',
          cssClass: 'back-button',
          role: 'CANCEL'
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role.toUpperCase() === 'DEACTIVATE') {
      this.deactivate_site();
    }
  }

  async deactivate_site() {
    const spinningLoader = await this.loadingController.create({
      spinner: 'lines',
      message: 'Deactivating site...',
    });

    await spinningLoader.present();

    this.siteService.deactivate_site(this.parent_selected_site).subscribe(
      (result) => {
        spinningLoader.dismiss();
        this.socketService.joinRooms();

        this.modalController.dismiss({
          site_deactivated: true,
        });
      },
      (err) => {
        spinningLoader.dismiss();
      }
    );
  }

  close() {
    this.modalController.dismiss({
      site_deactivated: false,
    });
  }
}
