<ion-content class="ion-no-padding" main-background *ngIf="mmd_show_acknowledged_at==true">
  <app-manage-member-detail [(show_acknowledged_at)]="mmd_show_acknowledged_at" [member_image_url]="mmd_image_url"
    [member_since]="mmd_member_since" [member_account_detail]="mmd_accountDetail">
  </app-manage-member-detail>

</ion-content>

<ion-content class="ion-no-padding" main-white-background *ngIf="mmd_show_acknowledged_at==false">
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>

  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="returnToSiteAlerts()" me-button-as-link id="btnSiteAlerts" name="btnSiteAlerts"
        *ngIf="showNavigationIssueMessage===false">
        &#8592;&nbsp;Back to Site Alerts</ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12">
      </ion-col>
      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12" *ngIf="showNavigationIssueMessage===false">
        <ion-card class="ion-no-margin" me-ion-card class="card">
          <ion-card-content>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col>
                  <div me-div-center-col-text>
                    <ion-label me-label-header>
                      {{siteNotification ? getNotificationHeader(siteNotification) : ''}}
                    </ion-label>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col>
                  <div me-div-center-col-text >
                    <img me-site-notification-image 
                      src="../../../assets/imgs/{{getNotificationImageName(siteNotification)}}">
                  </div>
                </ion-col>
              </ion-row>


              <ion-row>
                <ion-col>
                  <ion-grid class="ion-no-padding">
                    <ion-row white-background>
                      <ion-col>
                        <strong>Event Date:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification ? timeFormat(siteNotification.created_at) : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <strong>Event Detail:</strong>
                      </ion-col>
                      <ion-col></ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification ? siteNotification.notification : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.subdetail">
                      <ion-col>
                        <ion-label>
                          {{siteNotification ? siteNotification.context.subdetail : "" }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.is_sub_update">
                      <ion-col>
                        <strong>Site Name:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context?.site_name}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.is_sub_update">
                      <ion-col>
                        <strong>Gateway Name:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context?.gateway_name }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.is_sub_update">
                      <ion-col>
                        <strong>Serial Number:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label me-navigate-to (click)="navigateToGateway();">
                          {{siteNotification?.context?.serial_number }}
                        </ion-label>
                        <ion-label *ngIf="siteNotification?.context?.coupon_name">
                          <i> ({{siteNotification?.context?.coupon_name}})</i>
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.is_sub_update">
                      <ion-col>
                        <strong>From Subscription:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context?.from_sub }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="siteNotification?.context?.is_sub_update">
                      <ion-col>
                        <strong>To Subscription:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context?.to_sub }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <div
                      *ngFor="let item of siteNotification?.display_notification_labels | keyvalue : unsortedOrder">
                      <ion-row white-background>
                        <ion-col><strong>{{item.key}}:</strong></ion-col>
                        <ion-col>
                          <ion-label *ngIf="item.key.toLowerCase()==='gateway name'" me-navigate-to
                            (click)="navigateToGateway();">
                            {{item.value}}
                          </ion-label>
                          <ion-label *ngIf="item.key.toLowerCase()!=='gateway name'">
                            {{item.value}}
                          </ion-label>
                          <ion-label
                            *ngIf="(item.key.toLowerCase()==='serial number') && (siteNotification?.context?.coupon_name)">
                            <i> ({{siteNotification?.context?.coupon_name}})</i>
                          </ion-label>
                        </ion-col>
                      </ion-row>
                    </div>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showSuccessfulAcknowledgement">
                      <ion-col><strong>Acknowledged By:</strong></ion-col>
                      <ion-col class="textOverflow">
                        <ion-label *ngIf="acknowledgedOrResetByActiveMember" me-navigate-to (click)="navigateToAcknowledgeBy();">
                          {{siteNotificationAckDetails ? siteNotificationAckDetails.ackBy : ''}}
                        </ion-label>
                        <ion-label *ngIf="!acknowledgedOrResetByActiveMember">
                          {{siteNotificationAckDetails ? siteNotificationAckDetails.ackBy : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showSuccessfulAcknowledgement">
                      <ion-col><strong>Site Role:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{siteNotificationAckDetails ? siteNotificationAckDetails.role : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showSuccessfulAcknowledgement">
                      <ion-col><strong>Acknowledge Date:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{siteNotificationAckDetails ? timeFormat(siteNotificationAckDetails.ackDate) : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showFailedAcknowledgement" class="ion-text-center">
                      <ion-col>
                        <label style="color: red">Unable to acknowledge notification, please try again later.</label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col *ngIf="!showSuccessfulAcknowledgement && hasAckPermission(siteNotification)">
                        <ion-grid>
                          <ion-row class="ion-justify-content-center">
                            <ion-col size="4">
                              <div class="ion-text-center ion-text-nowrap ion-icon-wrapper-raw-button"
                                (click)="handleActionClick()" title="Acknowledge">
                                <ion-icon name="checkmark-circle" me-icon-notification-action></ion-icon>
                              </div>
                            </ion-col>

                          </ion-row>

                          <ion-row class="ion-justify-content-center">
                            <ion-col>
                              <div class="ion-text-center ion-text-nowrap">
                                <ion-label (click)="handleActionClick()" me-label-notification-action>
                                  Acknowledge
                                </ion-label>
                              </div>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-col>

                      <ion-col *ngIf="limitedSuspensionNotification && user.activeSiteUserLevel===LevelEnum.Owner">
                        <ion-grid>
                          <ion-row class="ion-justify-content-center">
                            <ion-col size="4">
                              <div class="ion-text-center ion-text-nowrap ion-icon-wrapper-raw-button"
                                title="Manage Gateway Subscription Plans"
                                [routerLink]="['/account', user?.id, 'details', 'subscriptions']">
                                <ion-icon name="list-circle" me-icon-notification-action></ion-icon>
                              </div>
                            </ion-col>

                          </ion-row>

                          <ion-row class="ion-justify-content-center">
                            <ion-col>
                              <div class="ion-text-center ion-text-nowrap">
                                <ion-label me-label-notification-action [routerLink]="['/account', user?.id, 'details', 'subscriptions']">
                                  Manage Gateway
                                </ion-label>
                              </div>
                            </ion-col>
                          </ion-row>
                          <ion-row class="ion-justify-content-center">
                            <ion-col>
                              <div class="ion-text-center ion-text-nowrap">
                                <ion-label me-label-notification-action [routerLink]="['/account', user?.id, 'details', 'subscriptions']">
                                  Subscription Plans
                                </ion-label>
                              </div>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>

            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12" *ngIf="showNavigationIssueMessage===true">
        <ion-grid me-grid-padding>

          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <div>
                {{navigationIssueMessage}}
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="5">
              <div>
                <ion-button (click)="backToNotification()"> Back</ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>

      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12">
      </ion-col>

    </ion-row>

  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>