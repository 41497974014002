import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SiteRoutingModule } from './site-routing.module';
import { SiteAdminPage } from '../../components/site-admin/site-admin.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EquipmentAlertAdminComponent } from '../../components/site-alerts/alert/equipment-alert-admin/equipment-alert-admin.component';
import { NotificationAdminComponent } from '../../components/site-alerts/notification/notification-admin/notification-admin.component';
import { SiteAlertsComponent } from '../../components/site-alerts/site-alerts.component';
import { SiteAnalyticsComponent } from '../../components/site-analytics/site-analytics.component';
import { SiteControlComponent } from '../../components/site-control/site-control.component';
// import { SiteGatewaysComponent } from '../../components/site-gateways/site-gateways.component';
import { SharedModule } from 'src/app/common/modules/shared/shared.module';
import { SiteMemberAdminPage } from '../../components/site-member-admin/site-member-admin.page';
import { SiteMemberDetailAdminPage } from '../../components/site-member-detail-admin/site-member-detail-admin.page';
import { SiteMemberRemovePage } from '../../components/site-member-remove/site-member-remove.page';
// import { SiteMembersComponent } from '../../components/site-members/site-members.component';
import { SiteMonitorComponent } from '../../components/site-monitor/site-monitor.component';
// import { SiteReportsComponent } from '../../components/site-reports/site-reports.component';
import { SelectGatewayPage } from '../../pages/select-gateway/select-gateway.page';
import { SelectGatewayUnitPage } from '../../pages/select-gateway-unit/select-gateway-unit.page';
import { SiteGatewayEditPage } from '../../pages/site-gateway-edit/site-gateway-edit.page';
import { SiteGatewayMovePage } from '../../pages/site-gateway-move/site-gateway-move.page';
import { SiteGatewayRegisterPage } from '../../pages/site-gateway-register/site-gateway-register.page';
import { SiteGatewayUnitEditPage } from '../../pages/site-gateway-unit-edit/site-gateway-unit-edit.page';
import { DashboardComponent } from 'src/app/features/dashboard/components/dashboard/dashboard.component';
// import { SiteGatewayDetailComponent } from '../../components/site-gateway-detail/site-gateway-detail.component';
import { SiteSelectPage } from '../../components/site-select/site-select.page';
import { SiteInformationComponent } from '../../components/site-information/site-information.component';
import { HighlightButtonDirective } from 'src/app/common/directives/highlightbutton/highlight-button.directive';
import { DashboardResolver } from '../../resolvers/dashboard/dashboard-resolver';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';
//import { OKModalPage } from 'src/app/common/modal/OKModal/okmodal/okmodal.page';
import { GroupAdminComponent } from '../../components/site-gateway-groups/groups/group-admin/group-admin.component';
import { GroupIndoorUnitAdminComponent } from '../../components/site-gateway-groups/group-indoor-units/group-indoor-unit-admin/group-indoor-unit-admin.component';
//import { SiteAnalyticsDownloadCSVComponent } from '../../components/site-analytics-download-csv/site-analytics-download-csv.component';
import { SiteAnalyticsGenerateGIFComponent } from '../../components/site-analytics-generate-gif/site-analytics-generate-gif.component';
import { SelectGatewayGroupUnitPage } from '../../pages/select-gateway-group-unit/select-gateway-group-unit.page';
import { SelectGatewayOutdoorUnitComponent } from '../../pages/select-gateway-outdoor-unit/select-gateway-outdoor-unit.component';
import { SiteDeactivateComponent } from '../../pages/site-deactivate/site-deactivate.component';
import { PlanSelectorModule } from 'src/app/common/components/plan-selector/plan-selector.module';
//import { ConfirmationModalPage } from 'src/app/common/modal/confirmation-modal/confirmation-modal.page';
import { SiteGatewayPlansComponent } from '../../pages/site-gateway-plans/site-gateway-plans.component';
import { SiteGatewayMovePaymentWarningPage } from '../../pages/site-gateway-move-payment-warning/site-gateway-move-payment-warning.page';
import { SiteGatewayMoveNoPaymentModal } from '../../pages/site-gateway-move-new-payment/site-gateway-move-new-payment.page';
import { SiteGatewayTypeCompareComponent } from '../../pages/site-gateway-type-compare/site-gateway-type-compare.component';
import { ErrorDetailComponent } from '../../components/site-alerts/alert/equipment-alert-admin/error-detail/error-detail.component';
import { TroubleshootingComponent } from '../../components/site-alerts/alert/troubleshooting/troubleshooting.component';
import { SiteAnalyticsGenerateMaintenanceDataComponent } from '../../components/site-analytics-generate-maintenance-data/site-analytics-generate-maintenance-data.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { SiteSchedulingCalendarComponent } from '../../components/site-scheduling-calendar/site-scheduling-calendar.component';
import { SiteSchedulingComponent } from '../../components/site-scheduling/site-scheduling.component';
import { SiteTransferModal } from '../../pages/site-transfer-modal/site-transfer-modal.page';
import { SiteTransferAcceptanceModal } from '../../pages/site-transfer-acceptance-modal/site-transfer-acceptance-modal.page';
import { SiteAlertsCardComponent } from 'src/app/features/dashboard/components/siteAlerts/siteAlerts.component';
import { SiteWeatherCardComponent } from 'src/app/features/dashboard/components/siteWeather/siteWeather.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TextMaskModule } from 'angular2-text-mask';
import * as moment from 'moment';
import { SiteAnalyticsTestRunComponent } from '../../components/site-analytics-test-run/site-analytics-test-run.component';
import { SiteAnalyticsTestRunOduSelectComponent } from '../../components/site-analytics-test-run-odu-select/site-analytics-test-run-odu-select.component';
import { SiteAnalyticsTestRunModeDurationComponent } from '../../components/site-analytics-test-run-mode-duration/site-analytics-test-run-mode-duration.component';
import { RefrigerantChecksCardComponent } from 'src/app/common/components/ui-cards/refrigerant-checks-card/refrigerant-checks-card.component';
import { SiteAnalyticsRefrigerantCheckComponent } from '../../components/site-analytics-refrigerant-check/site-analytics-refrigerant-check.component';
import { BranchPortChecksCardComponent } from 'src/app/common/components/ui-cards/branch-port-checks-card/branch-port-checks-card.component';
import { SiteAnalyticsBranchPortCheckComponent } from '../../components/site-analytics-branch-port-check/site-analytics-branch-port-check.component';
import { MaskitoDirective } from '@maskito/angular';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
    declarations: [
        DashboardComponent,
        SiteAlertsCardComponent,
        SiteWeatherCardComponent,
        SiteMonitorComponent,
        SiteControlComponent,
        // SiteGatewaysComponent,
        // SiteGatewayDetailComponent,
        SiteAlertsComponent,
        SiteAnalyticsComponent,
        SiteAdminPage,
        EquipmentAlertAdminComponent,
        TroubleshootingComponent,
        NotificationAdminComponent,
        SiteAlertsComponent,
        SiteControlComponent,
        // SiteGatewaysComponent,
        SiteMemberAdminPage,
        SiteMemberDetailAdminPage,
        SiteMemberRemovePage,
        SiteMonitorComponent,
        SelectGatewayPage,
        SiteSelectPage,
        SelectGatewayUnitPage,
        SiteGatewayEditPage,
        SiteGatewayMovePage,
        SiteGatewayMovePaymentWarningPage,
        SiteGatewayMoveNoPaymentModal,
        SiteGatewayRegisterPage,
        SiteGatewayUnitEditPage,
        SiteInformationComponent,
        HighlightButtonDirective,
        GroupAdminComponent,
        GroupIndoorUnitAdminComponent,
        //SiteAnalyticsDownloadCSVComponent,
        SiteAnalyticsGenerateGIFComponent,
        SiteAnalyticsGenerateMaintenanceDataComponent,
        SiteAnalyticsTestRunComponent,
        SiteAnalyticsTestRunOduSelectComponent,
        SiteAnalyticsTestRunModeDurationComponent,
        SelectGatewayGroupUnitPage,
        SelectGatewayOutdoorUnitComponent,
        SiteDeactivateComponent,
        SiteGatewayPlansComponent,
        SiteGatewayTypeCompareComponent,
        ErrorDetailComponent,
        SiteSchedulingCalendarComponent,
        SiteSchedulingComponent,
        SiteTransferModal,
        SiteTransferAcceptanceModal,
        RefrigerantChecksCardComponent,
        SiteAnalyticsRefrigerantCheckComponent,
        BranchPortChecksCardComponent,
        SiteAnalyticsBranchPortCheckComponent,
    ],
    imports: [
        CommonModule,
        SiteRoutingModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TextMaskModule,
        PlanSelectorModule,
        DragScrollModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        MaskitoDirective,
    ],
    providers: [
        DashboardResolver,
        AuthGuard,
        HasInternetConnectionGuardService,
        SiteGuard,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SiteModule { }
