import { LevelEnum } from "src/app/enumerations/enums";
import { Gateway } from "../../manage/components/classes/Gateway";

export class Site {
  name: string;
  id: string;
  phone: string;
  company: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  lat?: number;
  lng?: number;
  country: string;
  count_of_site_notifications?: number;
  site_photo_name?: string;
  sitestatustype_id: number;
  timezone?: string;
  transfer_locked? = false;
  transfer_site? = false;
  gateways?: Array<Gateway> = [];
  permission?:any;
  current_owner_id:string;
  locations: Array<string> = [];

  constructor(data: any) {
    this.name = data.name;
    this.id = data.id;
    this.phone = data.phone;
    this.company = data.company;
    this.timezone = data.timezone;
    if (data.site_address) {
      this.lat = data.lat;
      this.lng = data.lng;
      this.addressOne = data.site_address.street_line1;
      this.addressTwo = data.site_address.street_line2;
      this.city = data.site_address.city;
      this.state = data.site_address.state;
      this.zip = data.site_address.postal_code;
      this.country = data.site_address.country;
    } else {
      this.lat = data.lat;
      this.lng = data.lng;
      this.addressOne = data.addressOne;
      this.addressTwo = data.addressTwo;
      this.city = data.city;
      this.state = data.state;
      this.zip = data.zip;
      this.country = data.country;
    }
    this.count_of_site_notifications = 0;
    this.site_photo_name = data.site_photo_name;
    this.sitestatustype_id = data.sitestatustype_id;
    this.transfer_locked = data?.transfer_locked;
    this.transfer_site = data?.transfer_site;
    this.permission = data?.permission;
    if(data?.gateways) {
      data.gateways.forEach((ele: any) => {
        this.gateways.push(Gateway.fromObject(ele));
      });
    }
    // assign current_owner_id
    this.current_owner_id = '';
    this.permission?.forEach(perm => {
      if (perm?.level == LevelEnum.Owner && perm?.removed_at == null) this.current_owner_id = perm?.account_id;      
    });

    this.lat = data?.lat;
    this.lng = data?.lng;

    this.locations = data?.locations ? data.locations : [];
    this.sort_locations();
  }

  update_locations(new_locations: []) {
    // updated location list from server
    this.locations = new_locations;
    this.sort_locations();
  }

  sort_locations() {
    // sort the locations array alphabetically
    this.locations = this.locations.sort((s1, s2) => {
        if (s1 > s2) return 1;
        if (s1 < s2) return -1;
        return 0;
      }
    )
  }
}


export function makeRealSite(site): Site {
  // given the provided site object - make sure its a *real* site class and not just an object/dictionary

  // if its null - return as null
  if (!site) return site;

  // else if it is a real site - return as site
  if (`update_locations` in site) return site;

  // else make a site and return that inited with the passed site object
  console.log(`Need to create a site for active site`);
  
  return new Site(site);
}
