<ion-header>
  <ion-toolbar>
    <ion-title>Move Gateway</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-center">

  <ion-grid id="moveGatewayGrid" class="ion-no-padding md-0state">
    <br>
    <ion-row white-background>
      <ion-col col col-12 me-header-col>
        <ion-label color="danger">
          You are moving a gateway to a different site:
        </ion-label>
      </ion-col>
    </ion-row>

    <br>
    <div *ngIf="displayZeroState == true">
      <ion-row white-background style="padding-left: 17px; padding-right: 17px;">
        <ion-col col col-12>
          <ion-label>
            Note the following:
          </ion-label>
          <ul>
            <li class="li-padding">Indoor and outdoor system data will be moved to the new site.</li>
            <li class="li-padding">Any active equipment alarms on the old site will be moved into history.</li>
            <li class="li-padding">Any scheduled events already created for each group will be kept when the gateway is moved.</li>
          </ul>
        </ion-col>
      </ion-row>
    </div>

    <div *ngIf="displayZeroState == false">
      <ion-row white-background>
        <ion-col size="4">
          Gateway Name:
        </ion-col>
        <ion-col size="8">
          <ion-label id="lblGatewayName" name="lblGatewayName">{{ selectedGateway.name }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row white-background>
        <ion-col size="4">
          Serial Number:
        </ion-col>
        <ion-col size="8">
          <ion-label id="lblGatewaySerialNumber" name="lblGatewaySerialNumber">{{ selectedGateway.serial_number }} <i
              *ngIf="selectedGateway.has_coupon"> ({{selectedGateway.coupon_name}})</i>
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row white-background>
        <ion-col size="12">
          &nbsp;
        </ion-col>
      </ion-row>

      <ion-row white-background>
        <ion-col size="4">
          Move from:
        </ion-col>
        <ion-col size="8">
          <strong>
            <ion-label id="lblMoveFromSite" name="lblMoveFromSite">{{ user.active.name }}

            </ion-label>
          </strong>
        </ion-col>
      </ion-row>

      <ion-row white-background>
        <ion-col size="12">
          <ion-item lines="none" class="ion-text-center">
            <ion-label>Select the site you want to move the gateway to:</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row white-background>
        <ion-col size="4">
          Move to:
        </ion-col>
        <ion-col size="8">
          <select (change)="onOwnerSiteSelected($event)" style="width: 250px">
            <option *ngFor="let ownerSite of ownersSites" [value]="ownerSite.id">
              {{ ownerSite.name }}</option>
          </select>
        </ion-col>
      </ion-row>
    </div>

  </ion-grid>


</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col *ngIf="moveResult">
        <ion-item lines="none">
          <ion-label color="danger" id="moveResult" name="moveResult">{{moveResult}}</ion-label>
        </ion-item>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-label *ngIf="moveError" style="flex:auto" color="danger" class="moveError">{{moveError}}</ion-label>
        <ion-button style="margin: 0 0 0 1em;" color="primary" (click)="goBack()" [hidden]="backButtonIsHidden">
          Back
        </ion-button>
        <ion-button style="margin: 0 0 0 1em;" color="primary" (click)="goNext()" [hidden]="!displayZeroState">
          Next
        </ion-button>
        <ion-button style="margin: 0 0 0 1em;" color="primary" (click)="moveGateway()" [disabled]="!siteSelected || moveError!=''"
          [hidden]="displayZeroState">
          Move
        </ion-button>
        <ion-button id="btnCancelMove" name="btnCancelMove" (click)="cancelMove()" style="margin: 0 0 0 1em;"
          color="reverse-primary">
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>