import { DateParts } from "../components/datetime/datedayandrangepicker/date-day-and-range-picker/date-day-and-range-picker.component";

export class date_time_utilities {
  static get_date_parts_from_date(date_to_part: Date): DateParts {
    const date_parts_value = new DateParts();
    let month_part = "";
    let day_part = "";

    date_parts_value.dateYear = date_to_part.getFullYear().toString();
    month_part = (date_to_part.getMonth() + 1).toString();
    date_parts_value.dateMonth =
      month_part.length == 1 ? "0" + month_part : month_part;
    day_part = date_to_part.getDate().toString();
    date_parts_value.dateDay = day_part.length == 1 ? "0" + day_part : day_part;
    date_parts_value.date_string = `${date_parts_value.dateYear}-${date_parts_value.dateMonth}-${date_parts_value.dateDay}`;

    return date_parts_value;
  }

  static calculate_date_diff(start_date: Date, end_date: Date): number {
    const diff_in_time: number = start_date.getTime() - end_date.getTime();
    const diff_in_days: number = diff_in_time / (1000 * 3600 * 24);
    return diff_in_days;
  }

  static get_first_date_of_current_month(): Date {
    const current_date: Date = new Date();
    const first_day_of_current_month: Date = new Date(
      current_date.getFullYear(),
      current_date.getMonth(),
      1
    );
    return first_day_of_current_month;
  }

  static get_last_date_of_current_month(): Date {
    const current_date: Date = new Date();
    const last_day_of_current_month: Date = new Date(
      current_date.getFullYear(),
      current_date.getMonth() + 1,
      0
    );
    return last_day_of_current_month;
  }

  static getUtcDate(hour: number, minute: number){
    /**
     * Used for getting the current date in UTC. Currently used for some warning messages on monitor and analytics
     */
    const now = new Date();
    
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;
    const day = now.getUTCDate();

    const paddedMonth = month < 10 ? month.toString().padStart(2,'0') : month.toString()
    const paddedDay = day < 10 ? day.toString().padStart(2,'0') : day.toString()
    const paddedHour = hour < 10 ? hour.toString().padStart(2,'0') : hour.toString()
    const paddedMinute = minute < 10 ? minute.toString().padStart(2,'0') : minute.toString()

    return new Date(`${year}-${paddedMonth}-${paddedDay}T${paddedHour}:${paddedMinute}:00Z`);
  }

}
