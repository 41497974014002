import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_HTTP_403_FORBIDDEN_TITLE,
  TOAST_HTTP_403_FORBIDDEN,
} from 'src/app/constants/kenzaconstants';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpStatusCode, AppMessageTypeCode, AppMessageCode, ApplicationErrorsEnum } from 'src/app/enumerations/enums';
import { Router } from '@angular/router';
import { ErrorTypes } from 'src/app/constants/errors';
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastService: ToastrService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';

        switch (error.status) {
          case HttpStatusCode.MOVED_PERMANENTLY:
            return throwError(errorMsg);

          case HttpStatusCode.NOT_ACCEPTABLE:
            errorMsg = error.error
            return throwError(errorMsg);

          case HttpStatusCode.FORBIDDEN:
            // this.toastService.error(
            //   TOAST_HTTP_403_FORBIDDEN,
            //   TOAST_HTTP_403_FORBIDDEN_TITLE,
            //   TOAST_CONFIG_FULL_WIDTH
            // );
            errorMsg = error.error;
            this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);

            return throwError(errorMsg);

          default:
            //handle client-side error response
            if (error.error instanceof ErrorEvent) {
              this.toastService.error(
                TOAST_CONNECTIVITY_ISSUE,
                TOAST_CONNECTIVITY_ISSUE_TITLE,
                TOAST_CONFIG_FULL_WIDTH
              );
            } else if (error instanceof DOMException) {
              // do nothing with these
            } else {
              //handle server-side error response
              if (error.error['status'] === 'START_FAILED') { // firmware update failes to start
                const errorObject = ErrorTypes[ApplicationErrorsEnum.FIRMWARE_UPDATE_FAILED_TO_START];
                this.toastService.error(
                  errorObject['message'],
                  errorObject['title'] + ": " + errorObject['code'],
                  TOAST_CONFIG_FULL_WIDTH
                );
              } else if (error.error['status'] === 'ALREADY_INSTALLED') {
                errorMsg = error.error['status'];
                const errorObject = ErrorTypes[ApplicationErrorsEnum.FIRMWARE_NEWEST_VERSION_ALREADY_INSTALLED];
                this.toastService.error(
                  errorObject['message'],
                  errorObject['title'] + ": " + errorObject['code'],
                  TOAST_CONFIG_FULL_WIDTH
                );
              } else if(error.url.includes("transfer/check")) {
                // do nothing, handled on page
              } else if(error.url.includes(`/weather`)) {
                // do nothing, handled on page
              } else { // default message
                this.toastService.error(
                  TOAST_CONNECTIVITY_ISSUE,
                  TOAST_CONNECTIVITY_ISSUE_TITLE,
                  TOAST_CONFIG_FULL_WIDTH
                );
              }
            }
            return throwError(errorMsg);
        }
      })
    );
  }
}
