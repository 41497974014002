/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { AccountDetailProfileAdminPage } from '../account-detail-profile-admin/account-detail-profile-admin.page';
import { AccountDetailCompanyAdminPage } from '../account-detail-company-admin/account-detail-company-admin.page';
import { UserService } from 'src/app/common/services/user/user.service';
import { Log } from 'src/app/common/services/logging/log.service';
import { AccountDetail } from '../../classes/accountdetail';
import { compareValues, DateStringUtilities } from 'src/app/common/utilities/stringUtilities';
import { AccountService } from '../../services/accountService';
import { AccountGatewayDetail } from '../../classes/AccountGatewayDetail';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { PaymentMethod } from '../../classes/PaymentMethod';
import { AccountChangePasswordPage } from '../account-change-password/account-change-password.page';
import { ActivatedRoute, Router } from '@angular/router';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SiteMemberViewEnum, ToastMessageTypeEnum, PressurePreferenceEnum, GatewaySubscriptionStatusTypeEnum, MaintenanceJobTypeEnumTitle, MaintenanceJobTypeEnum } from 'src/app/enumerations/enums';
import { TOAST_SUCCESS_TITLE, CHANGED_PASSWORD_SUCCESS } from 'src/app/constants/kenzaconstants';
import { Location } from '@angular/common';
import { AccountPreferencesAdminComponent } from '../account-preferences-admin/account-preferences-admin.component';
import { AccountPreferences } from '../../classes/AccountPreferences';
import { TemperaturePreference } from '../../classes/TemperaturePreference';
import { PressurePreference } from '../../classes/PressurePreference';
import { AccountCancelComponent } from '../../modals/account-cancel/account-cancel.component';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { License } from "../../classes/License";
import { parseISO as dateFnsParseISO } from 'date-fns';
import { maintenanceJobStatusEnum } from 'src/app/common/classes/MaintenanceJob';

@Component({
  selector: 'app-account-detail-main',
  templateUrl: './account-detail-main.component.html',
  styleUrls: ['./account-detail-main.component.scss'],
})

export class AccountDetailMainComponent implements OnInit {
  @ViewChild('uploadPictureFile') uploadPictureFile: ElementRef;
  accountDetail: AccountDetail;
  imageUrl: string;
  memberSince: string;
  userId: string;
  devEnv = devEnv;
  currentView = SiteMemberViewEnum.SiteOwner;
  uploadPictureFileMessage: string;
  accountGatewayDetails: AccountGatewayDetail[] = null;
  accountPaymentMethods: PaymentMethod[] = [];
  accountLicenses: License[] = [];
  showBackToSiteMembersNavigation = false;
  includeImgInDOM = true;
  is_current_logged_in_user = false;
  account_preferences: AccountPreferences = null;
  temperature_preferences_list: TemperaturePreference[] = [];
  pressure_preferences_list: PressurePreference[] = [];

  accountGatewayDetailsLoading: boolean;
  accountPaymentMethodsLoading: boolean;

  hasLockedSite = false;

  lastSite = null;
  modalOpen = false;
  isModalOpening = false;

  GSSTE = GatewaySubscriptionStatusTypeEnum;

  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle; // For HTML Enum Access
  maintenanceJobStatusEnum = maintenanceJobStatusEnum; // For HTML Enum Access
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // For HTML Enum Access
  @Input() keepOriginalAspectRatio = true;
  iconMaintenanceType = `maintenance`;

  constructor(
    public location: Location,
    public modalController: ModalController,
    public toastController: ToastController,
    private user: UserService,
    private accountService: AccountService,
    private mainSiteUIService: MainSiteUIService,
    private activatedRoute: ActivatedRoute,
    private siteService: SiteService,
    private router: Router,
    private loadingController: LoadingController,
  ) {
    this.includeImgInDOM = true;

    this.imageUrl = "";

    if (this.user.backToSiteMembers) {
      this.showBackToSiteMembersNavigation = true;
      this.user.backToSiteMembers = false; // reset the flag
      this.lastSite = this.user.lastSite;
      this.user.lastSite = null;
    }
    
    this.userId = this.activatedRoute.snapshot.paramMap.get('accountId') || this.user.id;

    if (this.userId !== this.user.id) this.currentView = SiteMemberViewEnum.SiteMemberDetailView;

    this.is_current_logged_in_user = this.userId == this.user.id ? true : false;
  }

  ngOnInit() {
    this.displayAccountPicture();
    this.getAccountDetails();
    this.getAccountOwnerGateways();
    this.getAccountPayments();
    this.getTemperaturePreferences();
    this.getPressurePreferences();
    this.getAccountPreferences();
    this.uploadPictureFileMessage = '';
  }

  ionViewWillEnter() {
    // this happens when the page is created or comes from the router cache
    if (this.accountService.paymentDetailsUpdated) {
      this.getAccountPayments();
      this.accountService.paymentDetailsUpdated = false;
    }
    if (this.accountService.subscriptionDetailsUpdated) {
      this.getAccountOwnerGateways();
      this.accountService.subscriptionDetailsUpdated = false;
    }
  }

  displayAccountPicture() {
    this.user.getAccountPhotoUrl(this.userId)
      .then(accountPictureUrl => {
        this.includeImgInDOM = false;

        if (this.currentView === SiteMemberViewEnum.SiteOwner) {
          this.imageUrl = '';
          this.uploadPictureFile.nativeElement.value = "";
        }
        if (accountPictureUrl.length > 0) {
          this.includeImgInDOM = true;
          this.imageUrl = accountPictureUrl;
        }
      })
      .catch(err => Log.error(err));
  }

  getAccountDetails() {
    this.user.getAccountDetails(this.userId).subscribe((data: any) => {
      const acctDetail: AccountDetail = new AccountDetail();

      if (data.email) {
        acctDetail.id = data.id ? data.id : '';
        acctDetail.account_photo_name = '';
        acctDetail.country = '';
        acctDetail.last_login = null;
        acctDetail.removed_at = null;
        acctDetail.status = 0;
        acctDetail.fname = data.fname ? data.fname : '';
        acctDetail.lname = data.lname ? data.lname : '';
        acctDetail.title = data.title ? data.title : '';
        acctDetail.phone = data.phone ? data.phone : '';
        acctDetail.email = data.email ? data.email : '';
        acctDetail.created_at = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy');

        acctDetail.company = data.company ? data.company : '';
        acctDetail.company_phone = data.company_phone ? data.company_phone : '';
        acctDetail.street_line1 = data.street_line1 ? data.street_line1 : '';
        acctDetail.street_line2 = data.street_line2 ? data.street_line2 : '';

        acctDetail.city = data.city ? data.city : '';
        acctDetail.state = data.state ? data.state : '';
        acctDetail.postal_code = data.postal_code ? data.postal_code : '';
      } else {
        acctDetail.id = data.id ? data.id : '';
        acctDetail.account_photo_name = '';
        acctDetail.country = '';
        acctDetail.last_login = null;
        acctDetail.removed_at = null;
        acctDetail.status = 0;
        acctDetail.fname = '';
        acctDetail.lname = '';
        acctDetail.title = '';
        acctDetail.phone = '';
        acctDetail.email = data.email;
        acctDetail.created_at = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MM/dd/yyyy');

        acctDetail.company = '';
        acctDetail.company_phone = '';
        acctDetail.street_line1 = '';
        acctDetail.street_line2 = '';

        acctDetail.city = '';
        acctDetail.state = '';
        acctDetail.postal_code = '';
      }

      this.memberSince = DateStringUtilities.formatDateString(data.created_at, 'en-US', 'MMMM dd, yyyy');
      this.accountDetail = acctDetail;
    });
  }

  async getAccountOwnerGateways() {
    this.accountGatewayDetailsLoading = true;
    this.accountGatewayDetails =
      (await this.accountService.getOwnedGateways() || [])
        .reduce((acc, site) => {
          site.gateways.forEach((gw) => {
            acc.push(gw);

            // only get the active subscription
            const found_active_sub = gw.gatewaysubscription.find(sub =>
              // It's expiring but not expired
              sub.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Expired &&
              new Date(sub.expires_at + ' 23:59') >= new Date())
              ||
              // It's current
              gw.gatewaysubscription.find(sub =>
                (sub.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Active || 
                  sub.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Suspended) 
            );

            gw.activeSubscription = found_active_sub;
          });

          if (site.transfer_locked == true) {
            this.hasLockedSite = true;          
            let jobTitleIndex = MaintenanceJobTypeEnum.Site_Transfer;
            let maintType = MaintenanceJobTypeEnumTitle[jobTitleIndex];
            let maintTypeToUse = maintType ? maintType : this.iconMaintenanceType;
            this.iconMaintenanceType = maintTypeToUse.toLowerCase();
          }

          return acc;
        }, [])
        .sort(compareValues('name', 'asc'));
    this.accountGatewayDetailsLoading = false;
  }

  async getAccountPayments() {
    this.accountPaymentMethodsLoading = true;
    const result =  await this.accountService.getAccountPaymentMethods();
    this.accountPaymentMethods = (result.pmt_methods || []).sort(compareValues('card_type', 'asc'));
    this.accountLicenses = (result.license_array || []).sort(compareValues('site_name', 'asc'));
    this.accountLicenses.forEach(obj =>
          obj.licenseExpirationDate = dateFnsParseISO(obj.license_expiration + 'Z'));
    this.accountPaymentMethodsLoading = false;
  }

  on_open_upload_picture(event) {
    if (!this.siteService.handleIsConnected())
      return false;
  }

  async saveAccountPhoto(event) {

    if (!this.siteService.handleIsConnected())
      return;

    this.uploadPictureFileMessage = '';

    const allowedAccountPhotoTypes = ['gif', 'jpg', 'jpeg', 'png'];
    const accountDetail = new AccountDetail();

    accountDetail.id = this.user.id;
    accountDetail.account_photo_name = event.target.files[0].name;
    accountDetail.country = '';
    accountDetail.last_login = null;
    accountDetail.removed_at = null;
    accountDetail.status = 0;
    accountDetail.fname = '';
    accountDetail.lname = '';
    accountDetail.title = '';
    accountDetail.phone = '';
    accountDetail.email = ''
    accountDetail.created_at = '';

    accountDetail.company = '';
    accountDetail.company_phone = '';
    accountDetail.street_line1 = '';
    accountDetail.street_line2 = '';

    accountDetail.city = '';
    accountDetail.state = '';
    accountDetail.postal_code = '';

    const fileDetails = event.target.files[0];
    const fileExtension = fileDetails.name.substr(fileDetails.name.lastIndexOf('.') + 1);
    const found = allowedAccountPhotoTypes.filter(ext => ext.toLowerCase() === fileExtension.toLowerCase());

    if (found.length === 0) {
      this.uploadPictureFileMessage = 'Photo must be .png, .gif, or .jpg';
      return (true);
    }

    if (fileDetails.size > 1200000) {
      this.uploadPictureFileMessage = 'File size must not exceed 1MB';
      return (true);
    } else {
      this.uploadPictureFileMessage = '';
    }

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: 'Uploading picture...'
    });

    load.present();

    this.imageUrl = '';

    this.user.saveAccountPhoto(accountDetail, event.target.files[0])
      .then(res => {
        this.displayAccountPicture();
        load.dismiss();
      })
      .catch(err => {
        Log.error(err);
        load.dismiss();

      });
  }


  display_name(fname: string, lname: string, email: string): string {
    let displayName = '';

    if (fname || lname) {
      if (fname && lname) {
        displayName = fname + ' ' + lname;
      } else if (fname) {
        displayName = fname;
      } else if (lname) {
        displayName = lname;
      }
    } else {
      displayName = '';
    }

    return displayName;
  }

  removeAccountPhoto() {

    if (!this.siteService.handleIsConnected())
      return;

    this.uploadPictureFileMessage = '';

    this.user.removeAccountPhoto(this.userId)
      .then(res => {
        this.displayAccountPicture();
      })
      .catch(err => {
        Log.error(err);
      });
  }

  async onEditProfile(event) {

    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: AccountDetailProfileAdminPage,
      cssClass: 'me-sc-ion-modal-md-h-2',

      backdropDismiss: true,
      componentProps: {

      },
    });

    modal.onDidDismiss().then((data) => {
      if (((data.data !== undefined) && (data.data !== null) && (data.data.cancelled == false) && (data.data.accountDetail !== null))) {
        this.accountDetail = null;
        this.accountDetail = data.data.accountDetail;
      }
    });
    return await modal.present();
  }

  async onEditCompany(event) {

    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: AccountDetailCompanyAdminPage,
      cssClass: 'me-sc-ion-modal-md-h',
      backdropDismiss: true,
      componentProps: {

      },
    });

    modal.onDidDismiss().then((data) => {
      if (((data.data !== undefined) && (data.data !== null) && (data.data.cancelled == false) && (data.data.accountDetail !== null))) {
        this.accountDetail = data.data.accountDetail;
      }
    });
    return await modal.present();
  }

  viewAccountGatewaySubscriptionPlans() {
    if (!this.siteService.handleIsConnected())
      return;

    this.router.navigate(['/account/' + this.accountDetail.id + '/details/subscriptions']);
    this.mainSiteUIService.viewAccountGatewaySubscriptionPlans();
  }

  viewAccountPaymentHistory() {
    if (!this.siteService.handleIsConnected())
      return;
    this.router.navigate(['/account/' + this.accountDetail.id + '/details/payment-history']);
    this.mainSiteUIService.viewAccountPaymentHistory();
  }

  viewAccountPaymentMethods() {

    if (!this.siteService.handleIsConnected())
      return;
    this.router.navigate(['/account/' + this.accountDetail.id + '/details/payment-methods']);
    this.mainSiteUIService.viewAccountPaymentMethods();
  }

  backToSiteMembers() {
    if (!this.siteService.handleIsConnected())
      return;

    this.router.navigate(['/site', this.user.active.id, 'members'])
  }

  backToManageMembers() {
    if (!this.siteService.handleIsConnected())
      return;

    if(this.lastSite) {
      this.router.navigate(['/manage', this.lastSite, 'members'])
    } else {
      this.router.navigate(['/manage', this.user.active.id, 'members']);
    }
    
  }

  async onChangeAccountPassword() {

    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: AccountChangePasswordPage,
      cssClass: 'me-account-change-password-modal',
      backdropDismiss: true,
      componentProps: {
      },
    });

    modal.onDidDismiss().then((data) => {
      if (((data.data !== undefined) && (data.data !== null) && (data.data.cancelled == false))) {
        this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, CHANGED_PASSWORD_SUCCESS);
      }
    });
    return await modal.present();
  }

  getTemperaturePreferences() {
    this.accountService.getTemperaturePreferences().subscribe((temperaturePreferences: TemperaturePreference[]) => {
      this.temperature_preferences_list = temperaturePreferences;
    });
  }

  get_temperature_preference_name_abbreviation_from_id(temperature_preference_id: number) {
    const temperature_preference: TemperaturePreference = this.temperature_preferences_list.find(tp => tp.id == temperature_preference_id);
    if (temperature_preference == undefined) {
      return '';
    }
    return temperature_preference.name + " (" + temperature_preference.abbreviation + ")";
  }

  getPressurePreferences() {
    this.accountService.getPressurePreferences().subscribe((pressurereferences: PressurePreference[]) => {
      this.pressure_preferences_list = pressurereferences;
    });
  }

  get_pressure_preference_name_abbreviation_from_id(pressure_preference_id: number) {
    const pressure_preference: PressurePreference = this.pressure_preferences_list.find(pp => pp.id == pressure_preference_id);
    if (pressure_preference == undefined) {
      return '';
    }

    if (pressure_preference.id == PressurePreferenceEnum.PoundFourcePerSquareInch) {
      return `${pressure_preference.name} (${pressure_preference.abbreviation.toLowerCase()})`
    }

    return `${pressure_preference.name} (${pressure_preference.abbreviation})`

  }

  getAccountPreferences() {
    this.accountService.getAccountPreferences(this.userId).subscribe((accountPreferences: AccountPreferences) => {
      this.account_preferences = accountPreferences;
      this.user.accountPreferences = accountPreferences;
    });
  }


  async onAdminAccountPreferences(event) {

    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: AccountPreferencesAdminComponent,
      cssClass: 'me-sc-ion-modal-md-h-6',
      backdropDismiss: true,
      componentProps: {
        parent_account_id: this.account_preferences.account_id
      },
    });

    modal.onDidDismiss().then((data) => {

      if (((data.data !== undefined) && (data.data !== null) && (data.data.account_preference_saved == true))) {
        this.getAccountPreferences();
      }
    });
    return await modal.present();
  }

  cancel_account() {
    if (!this.siteService.handleIsConnected() || this.isModalOpening) return;

    this.isModalOpening = true;

    const get_account_site_permissions_promise = this.accountService.get_account_site_permissions(
      this.user.active.id
    );

    get_account_site_permissions_promise
      .then((account_site_permissions) => {
        this.show_cancel_account(account_site_permissions);
      }).catch((catch_error) => { 
        this.isModalOpening = false;
      })
    // .finally(() => { });
  }

  async show_cancel_account(account_site_permissions) {
    if (!this.siteService.handleIsConnected() || this.modalOpen == true) {
      this.isModalOpening = false;
      return;
    }

    const modal = await this.modalController.create({
      component: AccountCancelComponent,
      cssClass: 'me-sc-ion-modal-md-h-4',
      backdropDismiss: false,
      componentProps: {
        parent_account_id: this.user.id,
        parent_account_site_permissions: account_site_permissions,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.modalOpen = false;
      this.isModalOpening = false;
      if (data.data?.account_cancelled) {
        this.user.signOut();
        this.router.navigate(['/signin']);
      }
    });

    this.modalOpen = true;
    return await modal.present();
  }

}
