<ion-header>
  <ion-toolbar>
    <ion-title>Remove Member</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item lines="none" class="ion-text-center">
      <ion-label color="danger" class="ion-text-wrap">{{displayMessage}}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label color="black" class="labelPosition">Email</ion-label>
      <ion-label>{{parentAccountEMail}}</ion-label>
    </ion-item>
    <ion-item lines="none" class="ion-text-center">
      <ion-label color="danger">from</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label color="black" class="labelPosition">Site</ion-label>
      <ion-label>{{parentSiteName}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button button slot="end" (click)="onRemoveSiteMember()" [disabled]="parentAccountSiteLevel===4">Remove
    </ion-button>
    <ion-button button slot="end" color="reverse-primary" (click)="onCancel()" class="ion-padding-horizontal">Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>