<ion-content class="ion-no-padding" main-background>
  <ion-grid class="siteToolbarGrid">
    <ion-row>
      <ion-col class="sitePageNameCol" size="4">
        <ion-title color="dark">
          <h1>Site Alerts</h1>
        </ion-title>
      </ion-col>
      <ion-col class="siteWeatherInfoCol" size="4">
        <app-site-weather></app-site-weather>
      </ion-col>
      <ion-col class="siteRightCol" size="4">
        <!-- Empty For Now -->
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="padding-top:0px; padding-left:20px; padding-right:10px; padding-bottom:0px">
    <ion-row class="siteAlertsHeaderRow slim">
      <ion-col size="4" class="ion-no-padding">
        <!-- <h1>Site Alerts</h1> -->
        <ion-label color="medium" style="font-size:20px;">
          Showing
        </ion-label>
        <ion-badge id="filter_result_count" name="filter_result_count" color="primary">
          {{mainSiteUIService.siteAlertMetricsData.v2DisplayCount}}
        </ion-badge>
        <ion-label color="medium" style="font-size:20px;">
          with Filters
        </ion-label>
      </ion-col>
     
       <ion-col class="dismissNotificationsColumn" size="4" style="padding: 0 0 0 3px; display: flex; justify-content: flex-end; align-items: flex-end;">
         <ion-button class="notificationActionsButton alertsPageBtn slim {{ (user.active.transfer_locked || user.active.transfer_site) ? 'site-transfer-disable-modal' : '' }}" id="notificationActionsButton" color="primary" (click)="(user.active.transfer_locked || user.active.transfer_site) ? user.presentActionRestricted() : dismissNotifications($event)" *ngIf="getResettableNotifications().length > 0 && siteNotificationList.length > 0">
          {{ acknowledgeable.length > 0 ? (resettable.length > 0 ? 'Acknowledge /' : 'Acknowledge') : '' }}
          {{ resettable.length > 0 ? 'Reset' : '' }}
          <ion-badge color="black">{{
            getResettableNotifications().length }}
          </ion-badge>
         </ion-button>
       </ion-col>
      <ion-col size="4" style="padding: 0 0 0 3px; display: flex; justify-content: flex-end; align-items: flex-end;">
        <ion-searchbar #alertSearchBar (ionInput)="setSearch($event.target.value)" placeholder="Search..."
          class="ion-no-padding">
        </ion-searchbar>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row *ngIf="siteNotificationList.length == 0">
      <ion-col class="ion-no-padding">
        <ion-label><br></ion-label>
        <ion-label *ngIf="mainSiteUIService.getSiteAlertSearchText() != ''" class="ion-no-padding"
          color="var(--ion-color-warning)" style="font-size:20px; padding-left:15px">
          Your search for "{{mainSiteUIService.getSiteAlertSearchText()}}" returned no results. Please try again.
        </ion-label>
        <ion-label *ngIf="mainSiteUIService.getSiteAlertSearchText() == '' " class="ion-no-padding"
          color="var(--ion-color-warning)" style="font-size:20px; padding-left:15px">
          No alerts found. Please try again.
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12"
        *ngFor="let siteNotification of siteNotificationList; trackBy: notificationIdTracker">
        <ion-card me-ion-card id="cardNotification" style="height:100%">
          <ion-item class="ion-no-padding">
            <ion-label class="ion-text-center" id="notificationType">
              {{getNotificationHeader(siteNotification)}}
            </ion-label>
          </ion-item>

          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <div  class="image-wrapper">
                    <img me-site-notification-image
                      src="../../../assets/imgs/{{getNotificationImageName(siteNotification)}}">
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  {{siteNotification.created_at ? timeFormat(siteNotification.created_at) : ''}}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  {{siteNotification.notification ? siteNotification.notification : ''}}
                </ion-col>
              </ion-row>

              <ion-row>
                <!-- force some room for the footer bar we're going to move up into the card , yes its a HACK -->
                <ion-col style="height:62px">
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
        <!-- that negative margin is to force the footer up into the card but keep it at the bottom of the card-->
        <ion-footer class="ion-no-border" style="margin-top:-62px">
          <ion-row>
            <ion-col>
              <ion-toolbar *ngIf="siteNotification.sitenotificationtype_id==siteNotificationTypes.Gateway">
                <ion-buttons slot="secondary"
                  *ngIf="siteNotification.notification_labels && siteNotification.notification_labels['Operation'] === undefined
                  && sitePermissions['AckGatewayMove']">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['ViewGatewayDetails']"
                    (click)="dismissNotification(siteNotification)" id="buttonDismissNotification"
                    name="buttonDismissNotification" title="Acknowledge Notification">
                    <ion-icon slot="icon-only" color="primary" name="checkmark-circle"></ion-icon>
                  </ion-button>

                  <ion-button
                    *ngIf="sitePermissions['ViewGatewayDetails']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'notification', siteNotification.id]"
                    me-button-as-link id="btnSiteNotificationDetail" name="btnSiteNotificationDetail"
                    title="View Notification">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>

                <ion-buttons slot="secondary"
                  *ngIf="((siteNotification.notification_labels) && ((siteNotification?.notification_labels['Operation'] !== null) && (siteNotification?.notification_labels['Operation'] !== undefined)) && (siteNotification?.notification_labels['Operation'] === 'decommissioned_gateway'))">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['ViewGatewayDecommission']"
                    (click)="dismissNotification(siteNotification)" id="buttonDismissNotification"
                    name="buttonDismissNotification" title="Acknowledge Notification">
                    <ion-icon slot="icon-only" color="primary" name="checkmark-circle"></ion-icon>
                  </ion-button>

                  <ion-button
                    *ngIf="sitePermissions['ViewGatewayDecommission']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'notification', siteNotification.id]"
                    me-button-as-link id="btnSiteNotificationDetail" name="btnSiteNotificationDetail"
                    title="View Notification">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>

                </ion-buttons>

              </ion-toolbar>

              <ion-toolbar *ngIf=" siteNotification.sitenotificationtype_id==siteNotificationTypes.Membership">
                <ion-buttons slot="secondary">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['AckMemberNotification']"
                    (click)="dismissNotification(siteNotification)" id="buttonDismissNotification"
                    name="buttonDismissNotification" title="Acknowledge Notification">
                    <ion-icon slot="icon-only" color="primary" name="checkmark-circle"></ion-icon>
                  </ion-button>
                  <ion-button
                    *ngIf="sitePermissions['ViewMemberNotification']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'notification', siteNotification.id]"
                    me-button-as-link id="btnSiteNotificationDetail" name="btnSiteNotificationDetail"
                    title="View Notification">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
              <ion-toolbar *ngIf="siteNotification.sitenotificationtype_id===siteNotificationTypes.Equipment">
                <ion-buttons slot="secondary">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['ResetEquipmentAlert']"
                    id="buttonDismissNotification" name="buttonDismissNotification"
                    (click)="resetEquipmentAlert(siteNotification, true)" title="Reset Alarm">
                    <ion-icon slot="icon-only" color="primary" name="refresh-circle"></ion-icon>
                  </ion-button>
                  <ion-button
                    *ngIf="sitePermissions['ViewEquipmentAlert']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'equipment', siteNotification.id]"
                    me-button-as-link id="btnEquipmentAlertDetail" name="btnEquipmentAlertDetail" title="View Alarm">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
              <ion-toolbar *ngIf=" siteNotification.sitenotificationtype_id==siteNotificationTypes.Payments">
                <ion-buttons slot="secondary">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['AckPaymentNotification']"
                    (click)="dismissNotification(siteNotification)" id="buttonDismissNotification"
                    name="buttonDismissNotification" title="Acknowledge Notification">
                    <ion-icon slot="icon-only" color="primary" name="checkmark-circle"></ion-icon>
                  </ion-button>
                  <ion-button
                    *ngIf="sitePermissions['ViewPaymentNotification']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'notification', siteNotification.id]"
                    me-button-as-link id="btnSiteNotificationDetail" name="btnSiteNotificationDetail"
                    title="View Notification">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
              <!-- hide for now CB: Apr 2024 -->
              <!--               
              <ion-toolbar
                *ngIf=" siteNotification.sitenotificationtype_id==siteNotificationTypes.EquipmentNotification">
                <ion-buttons slot="secondary">
                  <ion-button
                    *ngIf="!siteNotification.acknowledged_at && sitePermissions['ResetEquipmentAlert']"
                    (click)="dismissNotification(siteNotification)" id="buttonDismissNotification"
                    name="buttonDismissNotification" title="Acknowledge Notification">
                    <ion-icon slot="icon-only" color="primary" name="checkmark-circle"></ion-icon>
                  </ion-button>
                  <ion-button
                    *ngIf="sitePermissions['ViewEquipmentAlert']"
                    [routerLink]="['/site', siteNotification.site_id, 'alerts', 'notification', siteNotification.id]"
                    me-button-as-link id="btnSiteNotificationDetail" name="btnSiteNotificationDetail"
                    title="View Notification">
                    <ion-icon slot="icon-only" color="primary" name="information-circle-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
              -->
            </ion-col>
          </ion-row>
        </ion-footer>
      </ion-col>
    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>