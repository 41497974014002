import { Directive, HostListener } from '@angular/core';
import { PasswordKeyCategories } from 'src/app/constants/passwordkeycategories';

@Directive({
  selector: '[appLimitPasswordKeys]'
})
export class LimitPasswordKeysDirective {

  //constructor() { }

  @HostListener('keydown', ['$event'])
  onInput(event: any) {

    // dont accept keys from outside our password groups
    const char: string = event.key;
    if (char) {
      const categoryMatch = PasswordKeyCategories(char);

      if (categoryMatch == 0) {
        // then deny
        event.preventDefault();
        return false;
      }

    }
    return true;
  }

  @HostListener('paste', ['$event'])
  onPasteInput(event: any) {
    const clipData: string = event.clipboardData.getData('text')

    let denyDrop = false;
    // walk the data looking for invalid password keys
    for (const k of clipData) {
      if (PasswordKeyCategories(k) == 0) {
        // then no.
        denyDrop = true;
        break;
      }
    }
    if (denyDrop) {
      // then we deny the paste.
      event.preventDefault();
      return false;
    }
    return true;
  }
}
