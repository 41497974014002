import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';

import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';
import { TestDriveListComponent } from '../../components/testdrive-list/testdrive-list.component';
import { TestDriveSettingsComponent } from '../../components/testdrive-settings/testdrive-settings.component';
import { TestDriveProgressComponent } from '../../components/testdrive-progress/testdrive-progress.component';
import { TestDriveHistoryComponent } from '../../components/testdrive-history/testdrive-history.component';

const siteGuards = [AuthGuard, HasInternetConnectionGuardService, SiteGuard];

const routes: Routes = [
  {
    path: 'testdrive/:siteId/list',
    component: TestDriveListComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.TestDrive }
  },
  {
    path: 'testdrive/:siteId/settings',
    component: TestDriveSettingsComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.TestDrive }
  },
  {
    path: 'testdrive/:siteId/progress',
    component: TestDriveProgressComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.TestDrive }
  },
  {
    path: 'testdrive/:siteId/history',
    component: TestDriveHistoryComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.TestDrive }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestDriveRoutingModule {}
