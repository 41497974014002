import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    connectionMonitor: Observable<boolean>;

    constructor() {
        this.connectionMonitor = new Observable((obs) => {
            window.addEventListener('offline', (evt) => {
                obs.next(false);
            });
            window.addEventListener('online', (evt) => {
                obs.next(true);
            });
        })
    }

    getMonitor() {
        return this.connectionMonitor;
    }

}
