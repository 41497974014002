<ion-header>
  <ion-toolbar>
    <ion-title id="title" name="title">{{currentStepUILayout.stepTitle}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form>
    <!--Header Section -->
    <ion-grid me-header-grid>
      <ion-row class="registration-step-counter ion-justify-content-around">
        <ion-col class="">
          <ion-row class="ion-justify-content-center">
            <ion-icon id="iconGateway" name="iconGateway"
              [ngClass]="(currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.GatewayStep) ? 'me-current-process-step' : 'me-process-step' "
              name="cloud-upload"></ion-icon>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.GatewayStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Gateway</ion-label>
            </ion-row>
          </ion-row>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
          <!-- <ion-icon name="remove-sharp"></ion-icon> -->
        </ion-col>
        <ion-col class="">
          <ion-row class="ion-justify-content-center">
            <ion-icon id="iconSubscription" name="iconSubscription"
              [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.SubscriptionStep ? 'me-current-process-step' : 'me-process-step' "
              name="apps"></ion-icon>
          </ion-row>
          <ion-row class="ion-margin-top ion-justify-content-center">
            <ion-label class="process-text-label"
              [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.SubscriptionStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
              Select Plan</ion-label>
          </ion-row>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
          <!-- <ion-icon name="remove-sharp"></ion-icon> -->
        </ion-col>
        <ion-col class="">
          <ion-row class="ion-justify-content-center">
            <ion-icon id="iconPayAndRegister" name="iconPayAndRegister"
              [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep ? 'me-current-process-step' : 'me-process-step' "
              name="card"></ion-icon>
          </ion-row>
          <ion-row class="ion-margin-top ion-justify-content-center">
            <ion-label
              [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
              Pay</ion-label>
          </ion-row>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
          <!-- <ion-icon name="remove-sharp"></ion-icon> -->
        </ion-col>
        <ion-col class="">
          <ion-row class="ion-justify-content-center">
            <ion-icon id="iconPrint" name="iconPrint"
              [ngClass]="(currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.ConfirmationStep) ? 'me-current-process-step' : 'me-process-step' "
              name="list"></ion-icon>
          </ion-row>
          <ion-row class="ion-margin-top ion-justify-content-center">
            <ion-label
              [ngClass]="currentGatewayChangeSubscriptionStep>=gatewayChangeSubscriptionStepEnum.ConfirmationStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
              Summary</ion-label>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Start Section-->
    <ion-grid *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.InformationStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger">
            You are changing the subscription<br>plan for your gateway:
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label>
            <!-- You will only be able to submit one Payment Method -->
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <ion-label class="label-padding">
            Note the Following:
          </ion-label>
          <ul>
            <li class="li-padding">Downgrading a gateway subscription from the Standard plan to the Limited plan, will
              retain access to
              Standard plan features until the end of the billing cycle.</li>
            <li class="li-padding">Upgrading a gateway subscription from the Limited plan to the Standard plan, will
              require the submission
              of a payment method.</li>
            <li class="li-padding">Only one payment method can be used for all gateway(s) on a site.
            </li>
            <li class="li-padding">If you enter a different payment method than is currently being used for this site,
              it will be applied to all gateways on the next billing cycle.</li>
          </ul>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Gateway Section-->
    <ion-grid
      *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.GatewayStep || currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger"
            *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.GatewayStep">
            You are changing the subscription plan<br>for the following gateway(s):
          </ion-label>
          <ion-label color="danger"
            *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">
            Select the subscription plan you<br>would like to change to:
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger">
            <ion-item lines="none">
              <ion-toolbar>
                <!-- <strong>
                  <ion-label style="padding-left: 10px">Gateways</ion-label>
                </strong> -->
                <ion-buttons slot="end"
                  *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">
                  <ion-button (click)="onComparePlans()" me-button-as-link id="btnClickHere" name="btnClickHere">
                    Click Here to Compare Plans
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-item>
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let sp of subscriptionsToChange">
        <ion-col col col-12>
          <ion-grid>
            <ion-row>
              <ion-col col col-6>
                <strong>Gateway / Device Type</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>Current Subscription Plan</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                {{sp.gateway_name}} / {{sp.model_name}}
              </ion-col>
              <ion-col col col-6>
                <ion-icon *ngIf="sp.has_coupon" name="pricetag" me-demo-pricetag-footer>
                </ion-icon>
                <ion-label *ngIf="!sp.is_expired"> {{sp.subscription_plan_name}}</ion-label>
                <ion-label *ngIf="sp.is_expired" class="me-expired-limited-plan"> Expired</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                (S/N: {{sp.serial_number}}) <ion-label *ngIf="sp.has_coupon"><i>({{sp.coupon_name}})</i></ion-label>
              </ion-col>
              <ion-col col col-6>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                <strong>Site Name</strong>
              </ion-col>
              <ion-col col col-6>
                <strong
                  *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">New
                  Subscription Plan</strong>
              </ion-col>
            </ion-row>
            <ion-row style="border-bottom-style: solid; border-bottom-width: thin; border-bottom-color: #efefef">
              <ion-col col col-6>
                {{sp.site_name}}
              </ion-col>
              <ion-col col col-6
                *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">
                <ion-icon *ngIf="sp.has_coupon" name="pricetag" me-demo-pricetag-footer>
                </ion-icon>
                <app-plan-selector [gw]="sp" [filterCurrent]="true"
                  (selectionChanged)="updateSiteGatewaySubscription()">
                </app-plan-selector>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
      <ion-row me-gateway-label
        *ngIf="currentGatewayChangeSubscriptionStep===gatewayChangeSubscriptionStepEnum.SubscriptionStep">
        <ion-col size="3">
        </ion-col>
        <ion-col size="5"><strong>Recurring Charge Total</strong><br>
          <div *ngIf="!noPaymentNeeded">(<sup validation-error>*</sup> plus applicable
            taxes)</div>
        </ion-col>
        <ion-col size="4" me-lineup-with-grid>
          <strong>
            <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
              {{ allNewSubscriptionsSelected ? ( calculatedNewSubscriptionTotal | currency ) : '$-.--'}}<sup
                *ngIf="!noPaymentNeeded" validation-error>*</sup>
            </ion-label>
          </strong>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="partialCoupons" me-gateway-label>
        <ion-col size="2">
        </ion-col>
        <ion-col col col-8 me-header-col me-gateway-label>
          <ion-label>
            <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
              have coupons applied and are not subject to a recurring monthly subscription cost.</i>
          </ion-label>
        </ion-col>
        <ion-col size="2">
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Pay Plan : Mixed Plans Section-->
    <ion-grid *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger" *ngIf="!noPaymentNeeded">
            Confirm the information below,<br>and enter a payment method before proceeding{{!noPaymentNeeded ? '.' :
            ':'}}<br>
            <br *ngIf="!noPaymentNeeded">
            {{ !noPaymentNeeded ? 'Your totals will be amended to include applicable taxes after selecting a payment
            method or entering billing information.' : ''}}
          </ion-label>
          <ion-label color="danger" *ngIf="noPaymentNeeded">
            Confirm the information below before proceeding:
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let sp of subscriptionsToChange">
        <ion-col col col-12>
          <ion-grid>
            <ion-row>
              <ion-col col col-6>
                <strong>Gateway / Device Type</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>New Subscription Plan</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                {{sp.gateway_name}} / {{sp.model_name}}
              </ion-col>
              <ion-col col col-6>
                <ion-icon *ngIf="sp?.has_coupon" name="pricetag" me-demo-pricetag-footer></ion-icon>
                <ion-label>{{ sp.new_subscription_plan_name}} </ion-label>
                <ion-label [style.text-decoration]="sp?.has_coupon ? 'line-through': 'none'">
                  {{ planService.plansKeyed[sp.new_subscription_plan_id]?.monthly_rate | currency }}/mo
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                (S/N: {{sp.serial_number}}) <i *ngIf="sp?.has_coupon">({{sp?.coupon_name}})</i>
              </ion-col>
              <ion-col col col-6>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                <strong>Site Name</strong>
              </ion-col>
              <ion-col col col-6>
                <strong
                  *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.SubscriptionStep">New
                  Subscription</strong>
              </ion-col>
            </ion-row>
            <ion-row style="border-bottom-style: solid; border-bottom-width: thin; border-bottom-color: #efefef">
              <ion-col col col-6>
                {{sp.site_name}}
              </ion-col>
              <ion-col col col-6>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>

      <!--Payment : Part 1-->
      <ion-row *ngIf="!noPaymentNeeded">
        <form>
          <ion-list>
            <ion-radio-group (ionChange)="updatePaymentMethodChoice($event)" [formControl]="useExistingPayment">
              <ion-row>
                <ion-col class="ion-no-padding">
                  <ion-item class="payment-method-choice" (click)="useExistingPayment.value='existing'"
                    [style.opacity]="paymentMethods.length && !noPaymentNeeded ? 1 : 0.5">
                    <ion-label>Use existing site payment method</ion-label>
                    <ion-radio class="ion-margin-end" slot="start" value="existing"
                      [disabled]="!paymentMethods.length || noPaymentNeeded">
                    </ion-radio>
                  </ion-item>
                </ion-col>
                <ion-col class="flex ion-justify-content-center ion-align-items-center"
                  *ngIf="useExistingPayment.value=='existing' && !noPaymentNeeded">
                  <select [value]="" id="selectPaymentMethod" name="selectPaymentMethod" style="font-size:small"
                    *ngIf="useExistingPayment.value=='existing'" (change)="updateExistingPaymentMethodChoice($event)">
                    <option value="unselected">Please select a payment method</option>
                    <option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.id">
                      {{ paymentMethod.card_type | titlecase }} ending in {{ paymentMethod.last_four }}
                      Exp.:{{ paymentMethod.expiration }}
                    </option>
                  </select>

                </ion-col>
              </ion-row>
              <ion-row>
                <ion-item class="payment-method-choice" [style.opacity]="noPaymentNeeded ? 0.5 : 1"
                  (click)="useExistingPayment.value='new'">
                  <ion-label>Enter a new payment method</ion-label>
                  <ion-radio class="ion-margin-end" slot="start" value="new" [disabled]="noPaymentNeeded">
                  </ion-radio>
                </ion-item>
              </ion-row>
            </ion-radio-group>
          </ion-list>
        </form>
      </ion-row>

      <!--Payment : Part 2-->
      <ion-row *ngIf="useExistingPayment.value === 'new' && !noPaymentNeeded">
        <ion-col>
          <ion-item lines="none">
            <ion-label [color]="noPaymentNeeded? 'light' : ''">Secure Payment Information<ion-icon name="lock-closed"
                size="large"></ion-icon>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>

      <!--Payment : Part 3-->
      <app-credit-card-form (formChange)="newPaymentMethodValidation($event)"
        *ngIf="useExistingPayment.value === 'new' && !noPaymentNeeded" #ccForm></app-credit-card-form>

      <ion-row *ngIf="!noPaymentNeeded">
        <ion-col class="ion-text-start ion-padding-top">
          <p style="padding-left: 10px;">
            Your method of payment will be charged the prorated amount for the first month immediately upon
            registration. You will be charged on the first of each month thereafter.
          </p>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="noPaymentNeeded">
        <ion-col col col-12>
          <div style="padding-left: 10px;">
            You have changed to a subscription plan that does not require a payment.
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12>
          <div style="padding-left: 10px;">
            If you are changing from a subscription that had a monthly payment, you will<br>
            retain access to those features until the end of the month.
          </div>

        </ion-col>
      </ion-row>

      <ion-row me-gateway-label>
        <ion-col size="3">
        </ion-col>
        <ion-col size="5"><strong>Prorated First Month</strong><br>
          <div *ngIf="!noPaymentNeeded">(<sup validation-error>*</sup> billed today, approximate value)</div>
        </ion-col>
        <ion-col size="4" me-lineup-with-grid>
          <strong>
            <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
              {{ newPaymentValid ? ( proratedTotal | currency ) : '$-.--'}}<sup *ngIf="!noPaymentNeeded"
                validation-error>*</sup><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
            </ion-label>
          </strong>
        </ion-col>
      </ion-row>
      <ion-row me-gateway-label>
        <ion-col size="3">
        </ion-col>
        <ion-col size="5"><strong>Recurring Charge Total</strong><br>

        </ion-col>
        <ion-col size="4" me-lineup-with-grid>
          <strong>
            <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
              {{ newPaymentValid ? ( monthlyTotal | currency ) : '$-.--'}}
            </ion-label><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
          </strong>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="partialCoupons" me-gateway-label>
        <ion-col size="2">
        </ion-col>
        <ion-col col col-8 me-header-col me-gateway-label>
          <ion-label>
            <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
              have coupons applied and are not subject to a recurring monthly subscription cost.</i>
          </ion-label>
        </ion-col>
        <ion-col size="2">
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Confirmation Section-->
    <ion-grid *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.ConfirmationStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-item-group>
            <ion-item>
              <ion-label>Gateway(s) Confirmed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item>
              <ion-label>Subscription Plan(s) Changed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item *ngIf="!noPaymentNeeded">
              <ion-label>Payment Method Accepted</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item>
              <ion-label>You will receive an email confirmation shortly.</ion-label>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>
    </ion-grid>


  </form>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-col class="flex ion-justify-content-end ion-align-items-center">
      <div *ngIf="AddressError" style="width:100%; text-align:center;">
        <ion-label validation-error>{{AddressError}}</ion-label>
      </div>
      <ion-button id="btnBack" color="primary" (click)="handleBack()" *ngIf="currentStepUILayout.showBackButton"
        style="padding-right: 10px">
        Back
      </ion-button>
      <ion-button id="btnNext" color="primary" [disabled]="!enableNextButton" *ngIf="currentStepUILayout.showNextButton"
        (click)=" handleNext()" style="padding-right: 10px">
        {{currentStepUILayout.nextButtonText}}
      </ion-button>
      <ion-button id="btnChangeSubscriptionPlan" color="primary" [disabled]="!enableNextButton"
        *ngIf="currentGatewayChangeSubscriptionStep==gatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep"
        (click)="changeSubscriptionPlan(1)" style="padding-right: 10px">
        Change
      </ion-button>
      <ion-button id="btnCancel" color="reverse-primary" (click)="cancelChangeSubscription()">
        {{currentStepUILayout.cancelButtonText}}
      </ion-button>
    </ion-col>
  </ion-grid>

</ion-footer>