/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway, GatewayWithGroups } from '../../../manage/components/classes/Gateway';
import { SiteService } from '../../services/site.service';
import { AlertController, ModalController } from '@ionic/angular';
import { SelectGatewayOutdoorUnitComponent } from '../../pages/select-gateway-outdoor-unit/select-gateway-outdoor-unit.component';
import { SiteGatewayPlansComponent } from '../../pages/site-gateway-plans/site-gateway-plans.component';
import { GatewayModelClass, SubscriptionFeatures } from 'src/app/enumerations/enums';
import { calculate_date_from_span, convert_date_to_date_string, DateDayAndRangePicker, SelectionTypeOptionEnum } from 'src/app/common/components/datetime/datedayandrangepicker/date-day-and-range-picker/date-day-and-range-picker.component';
import { SelectGatewayPage } from '../../pages/select-gateway/select-gateway.page';

@Component({
  selector: 'app-site-analytics-generate-maintenance-data',
  templateUrl: './site-analytics-generate-maintenance-data.component.html',
  styleUrls: ['./site-analytics-generate-maintenance-data.component.scss'],
})
export class SiteAnalyticsGenerateMaintenanceDataComponent implements OnInit {
  selected_gateway_name = 'Select a Gateway';
  site_gateways: Gateway[] = [];
  selected_gateway: Gateway;
  site_gateways_with_groups: any[];
  outdoor_units: any[];
  data_loading: boolean;
  filteredOutDoorUnits: any[];
  parent_date_day_and_range_picker: DateDayAndRangePicker = new DateDayAndRangePicker();

  constructor(
    public user: UserService,
    private siteService: SiteService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {
    this.selected_gateway = null;
    this.init_parent_date_day_and_range_picker();
  }

  ngOnInit() {
    this.refreshSiteGatewaysWithGroups(this.user.active.id);
  }


  async refreshSiteGatewaysWithGroups(site_id: string) {
    this.data_loading = true;
    this.outdoor_units = [];
    this.siteService.get_site_gateways_with_groups(site_id, true).subscribe((gateways: GatewayWithGroups) => {
      let gateways_with_groups: Gateway[] = [];

      if (gateways) {
        gateways_with_groups = gateways['gateways'] as Gateway[];
        this.site_gateways = gateways_with_groups;
        
        this.site_gateways_with_groups = [];
        this.outdoor_units = [];
        this.site_gateways_with_groups = gateways_with_groups.map(gwg => {
          gwg.outdoor_unit = gwg.outdoor_units[0];
          this.outdoor_units = this.outdoor_units.concat(gwg.outdoor_units);
          return gwg;
        });

        this.data_loading = false;
        this.outdoor_units = this.outdoor_units.sort(this.compareValues('name', 'asc '));
      }
    });
  }


  init_parent_date_day_and_range_picker() {
    this.parent_date_day_and_range_picker.selection_type_option_value = SelectionTypeOptionEnum.UNSELECTED;
    this.parent_date_day_and_range_picker.maximum_date_span_value = 4;
    const todays_date_minus_one_day = calculate_date_from_span(new Date(), (-1))
    const todays_date_minus_maximum_date_span_date_parts = calculate_date_from_span(new Date(), (-1 * this.parent_date_day_and_range_picker.maximum_date_span_value))

    this.parent_date_day_and_range_picker.min_date_value = todays_date_minus_maximum_date_span_date_parts.date_string;
    this.parent_date_day_and_range_picker.max_date_value = convert_date_to_date_string(new Date());
    this.parent_date_day_and_range_picker.single_date_value = convert_date_to_date_string(new Date());

    this.parent_date_day_and_range_picker.start_date_value = todays_date_minus_one_day.date_string;

    this.parent_date_day_and_range_picker.end_date_value = convert_date_to_date_string(new Date());
  }


  async onSelectGateway() {
    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: SelectGatewayPage,
      componentProps: {
        parentGatewayList: this.site_gateways,
        subscriptionCheckField: SubscriptionFeatures.GEN_SYSTEM_DIAGRAM,
        subscriptionCheckValue: SubscriptionFeatures.INCLUDED,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (!this.siteService.handleIsConnected()) return;

      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.gatewaySelected == true &&
        data.data.selectedGateway != null
      ) {
        // do we allow this request based on the gateway the ODU is part of?
        this.selected_gateway = data.data.selectedGateway;
        if (this.selected_gateway.subscription_features[SubscriptionFeatures.GEN_SYSTEM_DIAGRAM] != SubscriptionFeatures.INCLUDED) {

          this.presentSubscriptionOptions();

          this.selected_gateway_name = `Select a Gateway`;
          this.selected_gateway = null;
        } else {

          // do we allow this based on the RMD/MfK Lock State?
          if ( this.selected_gateway.model.class_name == GatewayModelClass.RMD ) {
            // ask the server whether this is ok.
            const gateway_to_try = this.selected_gateway;
            this.siteService.checkLock(this.selected_gateway.serial_number, this.user.active.id).subscribe( (result) => {
              if ( result == 0 ) {
                // then ok to use.
                this.selected_gateway = gateway_to_try
                this.selected_gateway_name = this.selected_gateway.name;
              } else {
                // then this gw is locked in MfK processing - please wait.
                const msg = `Gateway for ${this.selected_gateway.name} is busy gathering Model Details, please try again later.`
                this.displayInformation(`Unable to Download Maintenance Data at this time...`, msg);
              }
            })
            // for now - assume we don't have a gateway.  till the response above comes back.
            this.selected_gateway = null;
          } else {
            // MCC gw - proceed
            this.selected_gateway_name = this.selected_gateway.name;
          }
        }

      } else {
        this.selected_gateway_name = 'Select a Gateway';
      }

    });
    return await modal.present();
  }

  async checkForMfKLock(serial_no:string) {
    // check with the server - is this serial number = an RMD - and MfK is busy?
    await this.siteService.checkLock(serial_no, this.user.active.id)
  }

  async displayInformation(headerText:string, messageText:string) {
    // display an alert to the user
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
  }


  async presentSubscriptionOptions() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      backdropDismiss: true,
      componentProps: {
        title: "Upgrade your Subscription Plan",
        initialModelClassNameToDisplay: this.selected_gateway.model.class_name
      },
    });
    return await modal.present();

  }

  ready_for_submission() {
    return (
      (this.selected_gateway_name) &&
      (this.selected_gateway_name.length > 0) &&
      (this.selected_gateway_name !== 'Select a Gateway') &&
      (this.parent_date_day_and_range_picker.selection_type_option_value != SelectionTypeOptionEnum.UNSELECTED)
    )
  }

  close() {
    this.modalController.dismiss();
  }

  on_generate_maintenance_data() {
    if (!this.siteService.handleIsConnected())
      return;

    let start_date = this.parent_date_day_and_range_picker.single_date_value;
    let end_date = this.parent_date_day_and_range_picker.single_date_value;
    if (this.parent_date_day_and_range_picker.selection_type_option_value == SelectionTypeOptionEnum.RANGE_OF_DAYS) {
      start_date = this.parent_date_day_and_range_picker.start_date_value;
      end_date = this.parent_date_day_and_range_picker.end_date_value;
    }

    this.modalController.dismiss(
      {
        selected_gateway: this.selected_gateway,
        start_date_value: start_date,
        end_date_value: end_date

      })
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      //      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].toString().localeCompare(b[key]);
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

}
