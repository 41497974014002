<!-- Using Maskito -->
<div *ngIf="useMaskito" 
  [formGroup]="formGroup" class="text_mask_item maskitoInput {{ formControlName }} {{ fieldFocused ? 'item-has-value item-has-focus' : '' }}" 
  [ngClass]="{'isValid': control.valid, 'input-has-value': control.value, 'input-touched': control.touched}">
  <ion-input [id]="formControlName" type="text" [maskito]="maskitoMask" [maskitoElement]="maskPredicate" class="hasBottomBorderAlready" 
    [formControlName]="formControlName"  labelPlacement="floating" #{{formControlName}}>
    <ion-text slot="label" color="secondary">{{ extraLabel != '' ? extraLabel + ' ' : '' }}{{ MaskTypes[formControlName] }} Maskito
      <sup *ngIf="required" validation-error>*</sup>
    </ion-text>
  </ion-input>
</div>

<!-- Custom Text Masking w/o Maskito -->
<div *ngIf="!useMaskito" [formGroup]="formGroup" class="text_mask_item customTextMaskInput {{ formControlName }} {{ extraClassNames }} {{ fieldFocused ? 'item-has-value item-has-focus' : '' }}" [ngClass]="{'isValid': control.valid, 'input-has-value': control.value, 'input-touched': control.touched}">
  <label class="hydrated ion-color ion-color-secondary label-floating md sc-ion-label-md-h sc-ion-label-md-s ion-padding-start text-mask-label"  color="secondary">
    {{ extraLabel != '' ? extraLabel + ' ' : '' }}{{ MaskTypes[formControlName] }}
    <sup *ngIf="required" validation-error>*</sup> 
  </label>
  <input
    type="text"
    #{{formControlName}}
    (input)="set($event)"
    (blur)="blur($event)"
    [id]="formControlName"
    (focus)="focus($event)"
    [formControl]="control"
    [textMask]="{mask: mask}"
    [formControlName]="formControlName"
    [placeholder]="fieldFocused ? placeholder : ''"
    class="form-control textMaskedInput {{ fieldFocused ? 'focused' : '' }} {{ (capitalize || MaskTypes[formControlName] == MaskTypes.mac_address) ? 'capitalizedInput' : '' }}"
  />
  <ion-input
    hidden
    type="text"
    #{{formControlName}}
    label="Masked Input"
    [id]="formControlName"
    [formControl]="control"
    [labelPlacement]="labelPlacement"
    [formControlName]="formControlName"
    class="form-control text_mask_ion-input"
    [attr.data-recurly]="recurly ? recurly : null"
  >
    <ion-text slot="label" color="secondary" >{{ MaskTypes[formControlName] }}<sup *ngIf="required" validation-error>*</sup></ion-text>
  </ion-input>
  <!-- New Zip Code Ion Input with Text Mask -->
  <!-- <ion-input #{{formControlName}} type="text" [attr.data-recurly]="recurly ? recurly : null" [formControlName]="formControlName" class="form-control text_mask_ion-input textMaskedInput {{ (capitalize || MaskTypes[formControlName] == MaskTypes.mac_address) ? 'capitalizedInput' : '' }}" [id]="formControlName" [placeholder]="fieldFocused ? placeholder : ''" (ionInput)="setIonZipIon($event)" (ionBlur)="zipCodeBlurIon($event)" (ionFocus)="zipCodeFocusIon($event)"></ion-input> -->
</div>