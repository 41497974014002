<ion-content class="ion-no-padding" main-white-background>

  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar>
    <ion-buttons slot="end" *ngIf="showNavigationIssueMessage===false">
      <ion-button (click)="returnToSiteAlerts()" me-button-as-link id="btnSiteAlerts" name="btnSiteAlerts">
        &#8592;&nbsp;Back to Site Alerts</ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid>
    <ion-row>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">

      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12" *ngIf="showNavigationIssueMessage===false">
        <ion-card class="ion-no-margin" me-ion-card class="card">
          <ion-card-content>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col>
                  <div me-div-center-col-text>
                    <ion-label me-label-header>Equipment Alarm</ion-label>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col>
                  <div me-div-center-col-text>
                    <ion-icon name="alert-circle" me-icon-equipment-alert></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-grid class="ion-no-padding">
                    <ion-row white-background>
                      <ion-col>
                        <strong>Event Date:</strong>
                      </ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification !=null ? timeFormat(siteNotification?.created_at) : ''}}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <strong>Event Detail:</strong>
                      </ion-col>
                      <ion-col></ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.notification }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col><strong>Site Name:</strong></ion-col>
                      <ion-col>
                        <ion-label> {{ siteNotification?.context.site_name }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col><strong>Gateway Name:</strong></ion-col>
                      <ion-col>
                        <ion-label> {{ siteNotification?.context.gateway_name }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col><strong>Serial Number:</strong></ion-col>
                      <ion-col>
                        <ion-label me-navigate-to (click)="navigateToGateway();">
                          {{ siteNotification?.context.device_serial }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showOutdoorUnit">
                      <ion-col><strong>Outdoor Unit:</strong></ion-col>
                      <ion-col>
                        <ion-label> {{ siteNotification?.context.odu_name }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showOutdoorUnit">
                      <ion-col><strong>M-NET Address:</strong></ion-col>
                      <ion-col>
                        <ion-label> {{ siteNotification?.context.odu_address }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showGroupName">
                      <ion-col><strong>Group Name:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification ? ( siteNotification.context.group_name ?
                          siteNotification.context.group_name : '(No Group Assigned)' ) : '' }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showIndoorUnit">
                      <ion-col><strong>Indoor Coil:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context.idu_name }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background *ngIf="showIndoorUnit">
                      <ion-col><strong>M-NET Address:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context.idu_address }}
                        </ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row>
                      <ion-col><strong>Error Code:</strong></ion-col>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context.error_code }}
                        </ion-label>
                        <!-- <ion-label me-navigate-to (click)="openHandBook();">
                          {{ siteNotification?.context.error_code }}
                        </ion-label> -->
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col>
                        <strong>Error Description:</strong>
                      </ion-col>
                      <ion-col></ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col>
                        <ion-label>
                          {{ siteNotification?.context.error_message }}
                        </ion-label>
                      </ion-col>
                    </ion-row>
                    
                    <!-- <div class="equipmentAlarmAdminButtons" *ngIf="devEnv">
                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col>
                          <ion-card me-ion-card>
                            <ion-card-content >
                              <ion-row>
                                <ion-col>
                                  <ion-label>To try common solutions, press the button below.</ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row class="ion-justify-content-center">
                                <ion-col>
                                  <div class="ion-text-center ion-text-nowrap">
                                    <ion-button color="secondary" class="button-style" (click)="onClickDetail($event)">
                                      Troubleshooting
                                    </ion-button>
                                  </div>
                                </ion-col>
                              </ion-row>
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col>
                          <ion-card me-ion-card>
                            <ion-card-content >
                              <ion-row>
                                <ion-col>
                                  <ion-label>If you need more detailed operation data, please click the button below to download.</ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row class="ion-justify-content-center">
                                <ion-col>
                                  <div class="ion-text-center ion-text-nowrap">
                                    <ion-button class="button-style" (click)="onClickDownload($event)">
                                      Download driving data
                                    </ion-button>
                                  </div>
                                </ion-col>
                              </ion-row>
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col>
                          <ion-card me-ion-card>
                            <ion-card-content >
                              <ion-row>
                                <ion-col>
                                  <ion-label>If you need service parts, please order from the link below.</ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row>
                                <ion-col>
                                  <ion-label me-navigate-to (click)="openShopLink();">
                                    Open the purchase website.
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                            </ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>
                  
                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>
                    </div> -->

                    <ion-row white-background>
                      <ion-col>
                        <ion-label>&nbsp;</ion-label>
                      </ion-col>
                    </ion-row>
                    <div *ngIf="!hideErrorResetPart">
                      <ion-row white-background *ngIf="showSuccessfulReset">
                        <ion-col><strong>Alarm Reset By:</strong></ion-col>
                        <ion-col class="textOverflow">
                          <ion-label *ngIf="acknowledgedOrResetByActiveMember" me-navigate-to (click)="navigateToErrorResetBy();">
                            {{ siteNotification?.context.reset_by_name }}
                          </ion-label>
                          <ion-label *ngIf="!acknowledgedOrResetByActiveMember">
                            {{ siteNotification?.context.reset_by_name }}
                          </ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background *ngIf="showSuccessfulReset">
                        <ion-col><strong>Site Role:</strong></ion-col>
                        <ion-col>
                          <ion-label>
                            {{ siteNotification?.context.reset_by_role}}
                          </ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background *ngIf="showSuccessfulReset">
                        <ion-col><strong>Reset Date:</strong></ion-col>
                        <ion-col>
                          <ion-label>
                            {{ siteNotification?.acknowledged_at ?
                            timeFormat(siteNotification.acknowledged_at.toString()) : ''}}
                          </ion-label>
                        </ion-col>
                      </ion-row>
                    </div>
                    <div *ngIf="showAutoEquipmentReset">
                      <ion-row white-background>
                        <ion-col><strong>Additional Information:</strong></ion-col>
                      </ion-row>
                      <ion-row white-background>
                        <ion-col>
                          <ion-label>{{autoResetMessage}}</ion-label>
                        </ion-col>
                      </ion-row>
                    </div>

                    <div
                      *ngIf="appAuthService.doesLevelHavePermission(user.activeSiteUserLevel,appAuthService.permissionEnums.ResetEquipmentAlert)">
                      <ion-row white-background *ngIf="showFailedReset" class="ion-text-center">
                        <ion-col>
                          <label style="color: red">Unable to reset error, please try again later.</label>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row class="ion-justify-content-center">
                        <ion-col *ngIf="troubleshootingDetails?.length > 0">
                          <div class="ion-text-center ion-icon-wrapper-raw-button"
                            (click)="handleTroubleshootingClick()" title="Troubleshooting Tips">
                            <ion-icon name="construct" me-icon-equipment-alert-action></ion-icon>
                          </div>
                          <div class="ion-text-center">
                            <ion-label (click)="handleTroubleshootingClick()" me-label-equipment-alert-action>
                              Troubleshooting <br>Tips
                            </ion-label>
                          </div>
                        </ion-col>

                        <ion-col size="1" *ngIf="troubleshootingDetails?.length > 0"></ion-col> <!-- spacer -->

                        <ion-col *ngIf="showDddButton" [ngClass]="dddButtonEnabled ? 'enable-ddd' : 'disable-ddd'">
                          <div class="ion-text-center ion-icon-wrapper-raw-button" (click)="handleDownloadDriveDataClick()" title="{{dddButtonTooltip}}">
                            <ion-icon name="archive" me-icon-equipment-alert-action></ion-icon>
                          </div>
                          <div class="ion-text-center" title="{{dddButtonTooltip}}">
                            <ion-label (click)="handleDownloadDriveDataClick()" me-label-equipment-alert-action>
                              {{dddMainLabelText}}<br>
                            </ion-label>
                            <div class="dddRequestDownload">
                              <ion-spinner *ngIf="dddShowSpinner" class="spinner" color=primary name="lines"></ion-spinner>
                              <ion-label (click)="handleDownloadDriveDataClick()" me-label-equipment-alarm-download-msg>
                                <i>{{dddAltLabelText}}</i>
                              </ion-label>  
                            </div>
                          </div>
                        </ion-col>

                        <ion-col size="1" *ngIf="showDddButton"></ion-col> <!-- spacer -->

                        <ion-col>
                          <div class="ion-text-center ion-icon-wrapper-raw-button" (click)="handleMyLinkDriveClick()"
                            title="MyLinkDrive">
                            <ion-icon name="document-text" me-icon-equipment-alert-action></ion-icon>
                          </div>
                          <div class="ion-text-center">
                            <ion-label (click)="handleMyLinkDriveClick()" me-label-equipment-alert-action>
                              MyLinkDrive
                            </ion-label>
                          </div>
                        </ion-col>
                        <!-- <ion-col size="6">
                          <div class="ion-text-center ion-icon-wrapper-raw-button" (click)="downladAlertDetailClick()"
                            title="Alert Information">
                            <ion-icon name="download" me-icon-equipment-alert-action></ion-icon>
                          </div>
                          <div class="ion-text-center">
                            <ion-label (click)="downladAlertDetailClick()" me-label-equipment-alert-action>
                              Alert Information
                            </ion-label>
                          </div>
                        </ion-col> -->
                      </ion-row>

                      <ion-row white-background>
                        <ion-col>
                          <ion-label>&nbsp;</ion-label>
                        </ion-col>
                      </ion-row>

                      <ion-row white-background *ngIf="showSuccessfulReset==false">
                        <ion-col>
                          <ion-grid>
                            <ion-row class="ion-justify-content-center">
                              <ion-col size="4">
                                <div class="ion-text-center ion-text-nowrap ion-icon-wrapper-raw-button"
                                  (click)="handleActionClick()" title="Alarm Reset">
                                  <ion-icon name="refresh-circle" me-icon-equipment-alert-action></ion-icon>
                                </div>
                              </ion-col>

                            </ion-row>

                            <ion-row class="ion-justify-content-center">
                              <ion-col>
                                <div class="ion-text-center ion-text-nowrap">
                                  <ion-label (click)="handleActionClick()" me-label-equipment-alert-action>
                                    Alarm Reset
                                  </ion-label>
                                </div>
                              </ion-col>
                            </ion-row>
                            <ion-row class="ion-justify-content-center">
                              <ion-col>
                                <div class="ion-text-center">
                                  <ion-label color="danger" *ngIf="showIndoorUnit">
                                    By performing an Alarm Reset, you will clear all alarms on the unit group. Active
                                    alarms will persist until corrected.
                                  </ion-label>
                                  <ion-label color="danger" *ngIf="!showIndoorUnit">
                                    By performing an Alarm Reset, you will clear <i>all</i> alarms on the gateway.
                                    Active alarms will persist until corrected.
                                  </ion-label>
                                </div>
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </ion-col>
                      </ion-row>
                    </div>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12" *ngIf="showNavigationIssueMessage===true">
        <ion-grid me-grid-padding>

          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <div>
                {{navigationIssueMessage}}
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="5">
              <div>
                <ion-button (click)="backToNotification()"> Back</ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
      </ion-col>

    </ion-row>

  </ion-grid>
</ion-content>