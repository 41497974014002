/* eslint-disable @typescript-eslint/no-explicit-any */
import { GatewayUnit } from './GatewayUnit';
import { GatewayGroup } from './GatewayGroup';
import { GatewaySubscriptionStatusTypeEnum } from 'src/app/enumerations/enums';
import {
    parse as dateFnsParse ,
    addYears as dateFnsAddYears,
} from 'date-fns';

export class GatewayModel {
    public id = '';
    public class_name = '';
    public name = '';
    constructor(id = '0') {
        this.id = id;
    }
}

export class GatewayConnection {
    public gateway_id = '';
    public connected = '';
    public last_connect_time = '';
    public last_disconnect_time = '';
    public last_disconnect_reason = '';
    public gateway_ip = '';
    public last_push_update = '';
    public last_push_update_text = '';
}

export class GatewayWithGroups {
    gateways: Gateway[];
}

export class Gateway {

    subscription?
    activeSubscription?
    profile?
    public isExpanded = false;
    public isSelected = false;
    public connected = false;
    public has_coupon = false;
    public coupon_name = '';
    public coupon_description = '';
    public has_license = false;
    public license_name = '';
    public license_description = '';

    constructor(
        public id = '',
        public site_id = '',
        public name = '',
        public serial_number = '',
        public mac_address = '',
        public device_key = '',
        public gateway_status_type_id = 0,
        public subscription_id = '',
        public subscription_name = '',
        public created_at: Date = null,
        public created_account_id = '',
        public removed_at = '',
        public outdoor_units: GatewayUnit[] = [],
        public units: GatewayUnit[] = [],
        public outdoor_unit: GatewayUnit = null,
        public outdoor_unit_name = '',
        public model: GatewayModel = new GatewayModel(),
        public registration_code = '',
        public firmware_version = '',
        public firmware_update_available = false,
        public firmware_versions_available = '',
        public firmware_update_state = '',
        public firmware_update_active = false,

        public groups: GatewayGroup[] = [],

        // simplified subscription active on this gateway
        public subscription_features = {},

        // optional connection details about this gateway
        public connection: GatewayConnection = new GatewayConnection(),

        public coupons: any[] = [], // optional coupon properites about this gateway
        public licenses: any[] = [],  // optional license properites about this gateway

        public gatewaysubscription: any[] = [],
        
        public map_units_active = false,

        // optional, added to some gatway calls on backend. Has info on the active sub like state or coupon
        public active_sub = {},

        // optional, field was added to GatewayWithGroupsAndUnitsSchema on backend
        public subscription_expired = false,

        public maintenance_job = null,
        public mfk_status = null,

    ) {
        this.init();
    }

    private init() {
        // connected is based on connection
        this.connected = this.connection.connected.toLowerCase() == `true`;
        this.connection.last_push_update_text = `n/a`;
        const last_push_update_int = parseInt(this.connection.last_push_update);
        if (last_push_update_int > 0) {
            const d = new Date(last_push_update_int * 1000);
            this.connection.last_push_update_text = d.toLocaleString(`en-US`);
        }

        // coupon values are based on coupons
        this.has_coupon = this.coupons?.length > 0;
        if (this.has_coupon) {
            this.coupon_name = this.coupons[0]?.type?.name;
            this.coupon_description = this.coupons[0]?.type?.description;
        }
        // license values are based on coupons
        this.has_license = this.licenses?.length > 0;
        if (this.has_license) {
            this.license_name = this.licenses[0]?.type?.name;
            this.license_description = this.licenses[0]?.type?.description;
        }

        // Current Subscription
        if (this?.gatewaysubscription?.length > 0) {
            const currentSub = this.gatewaysubscription.find(sub => sub?.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Active || 
                sub?.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Suspended);
            if (currentSub) this.subscription = currentSub?.subscriptions;

            const hasGatewaySubscription = this.gatewaysubscription.find(sub =>
                sub.gateway_subscription_status_type_id == GatewaySubscriptionStatusTypeEnum.Expired &&
                new Date(sub.expires_at + ` 23:59`) >= new Date()) // It's expiring but not expired
            || currentSub; // Current, not expired
           
            if (hasGatewaySubscription) {
                this.activeSubscription = hasGatewaySubscription;
                this.activeSubscription.isPaid = this?.activeSubscription?.subscriptions?.monthly_rate > 0;
                if (this.activeSubscription.subscriptions?.is_licensed){
                  const parsedDate = dateFnsParse(this.activeSubscription.created_at, 'yyyy-MM-dd', new Date());
                  this.activeSubscription.licenseExpiration = dateFnsAddYears(parsedDate, this.licenses[0]?.type.duration_years); 
                }
            }

            this.subscription_id = this.activeSubscription?.id
            this.subscription_name = this.activeSubscription?.name;
        }
    }

    static fromObject(obj: any) {
        const gw = new Gateway();
        Object.keys(obj).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(gw, key)) return;
            gw[key] = obj[key];
        });
        gw.init();
        return gw;
    }

    static newFromGateway(gw: Gateway, site_id = '') {
        const g = new Gateway(
            gw.id,
            gw.site_id ? gw.site_id : site_id,
            gw.name,
            gw.serial_number,
            gw.mac_address,
            gw.device_key,
            gw.gateway_status_type_id,
            gw.subscription_id,
            gw.subscription_name,
            gw.created_at,
            gw.created_account_id,
            gw.removed_at,
            gw.outdoor_units,
            gw.units,
            gw.outdoor_unit,
            gw.outdoor_unit_name,
            gw.model,
            gw.registration_code ? gw.registration_code : gw.device_key,
            gw.firmware_version,
            gw.firmware_update_available,
            gw.firmware_versions_available,
            gw.firmware_update_state,
            gw.firmware_update_active,
            gw.groups,
            gw.subscription_features,
            gw.connection,
            gw.coupons,
            gw.licenses,
            gw.gatewaysubscription,
            gw.map_units_active,
            gw.active_sub,
            gw.maintenance_job,
            gw.mfk_status,
        );

        // Properly Track the Maintenance Job Object just in case
        if (!g.maintenance_job && gw.maintenance_job) {
            g.maintenance_job = gw.maintenance_job;
        }
      
        // Properly Track the MFK Status Object just in case
        if (!g.mfk_status && gw.mfk_status) {
            g.mfk_status = gw.mfk_status;
        }

        return g;
    }
}