<div class="sliderContainer swiper">
  <swiper-container class="swiper-container" speed="1000" autoplay autoplay-delay="4000" autoplay-disable-on-interaction="false" pagination pagination-clickable pagination-type="bullets" loop rewind grab-cursor>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/logo.svg" alt="Kenza Cloud Logo" />
      <div class="slideContent">
        <img class="slideTopContent tmLogo" src="assets/kenzaCloudTMLogo.png" alt="Kenza Cloud Logo" />
        <p>A multi-layered application offering real-time, visualized data of your air conditioning system.</p>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/equipmentalarm.png" alt="System Alerts & Notifications" />
      <div class="slideContent">
        <h1>Alerts & Notifications</h1>
        <p>Get equipment alarms to take immediate action and reduce downtime.</p>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/equipmentcontrol.png" alt="System Control" />
      <div class="slideContent">
        <h1>System Control</h1>
        <p>Control every aspect of your equipment from a single screen.</p>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/scheduling.png" alt="System Scheduling" />
      <div class="slideContent">
        <h1>System Scheduling</h1>
        <p>Set a schedule of events at a specific date and time or create recurring events which can span multiple days.</p>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/equipmentgraph.png" alt="System Monitor" />
      <div class="slideContent">
        <h1>System Monitor</h1>
        <p>Monitor multiple control points and track data in real time with powerful visualization tools.</p>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/equipmentgif.png" alt="System Analytics" />
      <div class="slideContent">
        <h1>System Analytics</h1>
        <p>Providing in depth analysis of your refrigeration system.</p>
      </div>
    </swiper-slide>
    <!-- <swiper-slide class="swiper-slide">
      <img class="slideTopContent" src="assets/imgs/plans/systemStatus.jpg" alt="System Status" />
      <div class="slideContent">
        <h1>System Status</h1>
        <p>Get the latest unit profile data at a glance.</p>
      </div>
    </swiper-slide> -->
  </swiper-container>
</div>