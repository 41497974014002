/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable, EventEmitter } from '@angular/core';
import { WebserviceURL, WEBSOCKET_URL } from '../../../constants/webservice';
import { io } from "socket.io-client";
import { CustomHeader } from '../websockets/classes/headers/CustomHeader';
import { MapGatewayRequest } from './classes/requests/MapGatewayRequest';
import {
  MapGatewayCompleteResponse,
  IssueResponse,
  ProfileUpdateResponse,
  ErrorResponse,
  MapGatewayProgressResponse,
  MapGatewayStartedResponse,
  MapGatewayErrorResponse,
} from './classes/responses/WebSocketResponses';
import { GatewayModelClass, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { Logger } from '../logging/log.service';
import { UserService } from '../user/user.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorTypes } from 'src/app/constants/errors'
import { ApplicationErrorsEnum } from 'src/app/enumerations/enums'
import { devEnv, TOAST_CONFIG_FULL_WIDTH } from 'src/app/constants/kenzaconstants';
import { SiteNotificationCount } from '../../classes/Metrics';
import { HttpClient } from '@angular/common/http';
import { SiteAnalyticsMtdzDownloadRequest } from 'src/app/features/sites/classes/site-analytics-request';
import { maintenanceNotification } from '../../../enumerations/enums';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: any;
  customHeader = new CustomHeader();
  extraHeader: any;
  siteGatewayUnitsMapped = new EventEmitter<any>();
  SocketServiceEmitter = new EventEmitter<any>();
  GraphEmitter = new EventEmitter<any>();
  AlertEmitter = new EventEmitter<any>();
  //  CsvEmitter = new EventEmitter<any>();
  ProfileUpdateEmitter = new EventEmitter<ProfileUpdateResponse>();
  maintenanceEmitter = new EventEmitter<any>();
  requests: any = {};
  private authenticated = false;
  private userService: UserService;
  private accessToken: string;

  constructor(private logger: Logger,
    private toasterService: ToastrService,
    private http: HttpClient) { }

  deleteRequest(request_id) {
    delete this.requests[request_id];
  }

  showSocketIssueToast(errorType: ApplicationErrorsEnum) {
    const errorObject = ErrorTypes[errorType];

    const errorTitle: string = errorObject['title'] + ": " + errorObject['code'];
    const errorMessage: string = errorObject['message'];
    const timeout: number = errorObject['timeout'] * 1000; // secs to millisecs

    let errorConfig = TOAST_CONFIG_FULL_WIDTH;
    errorConfig['timeOut'] = timeout

    this.toasterService.error(errorMessage, errorTitle, errorConfig);
  }

  start(userService: UserService) {
    this.userService = userService;
    this.socket = io(WEBSOCKET_URL, { transports: ['websocket', 'polling'] });

    this.socket.on('connect', () => {
      // this.logger.debug('socket.service=>connect');
      const issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type = WebSocketResponseTypeEnum.Connect;
      issueResponse.response_message = 'Socket connected';
      this.SocketServiceEmitter.emit(issueResponse);
    });

    this.socket.on('connect_error', (error) => {
      // this.logger.debug('socket.service=>connect_error=>error');
      this.logger.debug(error);
    });

    this.socket.on('connect_timeout', (timeout) => {
      // this.logger.debug('socket.service=>connect_timeout=>timeout : ');
      this.showSocketIssueToast(ApplicationErrorsEnum.WEBSOCKET_TIMEOUT);
      this.logger.debug(timeout);
    });

    this.socket.on('error', (error) => {
      // this.logger.debug('socket.service=>error=>error ');
      this.logger.debug(error);

      let issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type = WebSocketResponseTypeEnum.Error;
      issueResponse.response_message = error;
      issueResponse.request_id = error.request_id;
      this.SocketServiceEmitter.emit(issueResponse);
    });

    this.socket.on('disconnect', (reason) => {
      // this.logger.debug('socket.service=>disconnect=>reason : ');
      let url = window.location.href;
      // analytics page does downloads via embedded click which can cause a reset of the websocket.
      if (url.endsWith("control") || url.endsWith("monitor") /*|| url.endsWith("analytics")*/) {
        this.showSocketIssueToast(ApplicationErrorsEnum.WEBSOCKET_DISCONNECT);
      }

      if (url.includes("gateway") && !url.endsWith("gateways")) {
        let errorResponse: ErrorResponse = new ErrorResponse();
        errorResponse.response_type = ApplicationErrorsEnum.MAPUNITS_WEBSOCKET_DISCONNECT;
        errorResponse.response_message = "Websocket D/Ced during map units";
        this.SocketServiceEmitter.emit(errorResponse)
      }
      this.logger.debug(reason);
    });

    this.socket.on('notification_update', (msg) => {
      // update the count of errors on the sites
      this.accountSiteNotificationCounts().subscribe((accountSiteNotificationCounts: SiteNotificationCount[]) => {
        this.userService.updateSiteNotificationCounts(accountSiteNotificationCounts);
      },
        (error) => {
          console.log('accountSiteNotificationCounts =>error : ', error);
        }
      )
    });

    this.socket.on('reconnect', (attemptNumber) => {
      // this.logger.debug('socket.service=>reconnect=>attemptNumber ');
      this.logger.debug(attemptNumber);
    });

    this.socket.on('reconnect_attempt', (attemptNumber) => {
      // this.logger.debug('socket.service=>reconnect_attempt=>attemptNumber');
      this.logger.debug(attemptNumber);
      this.showSocketIssueToast(ApplicationErrorsEnum.WEBSOCKET_RECONNECT_ATTEMPT);
    });

    this.socket.on('reconnecting', (attemptNumber) => {
      // this.logger.debug('socket.service=>reconnecting=>attemptNumber');
      this.logger.debug(attemptNumber);
    });

    this.socket.on('reconnect_error', (error) => {
      // this.logger.debug('socket.service=>reconnect_error=>error');
      this.logger.debug(error);
    });

    this.socket.on('reconnect_failed', (error) => {
      // this.logger.debug('socket.service=>reconnect_failed=>error');
      this.logger.debug(error);
    });

    this.socket.on('validation_error', (msg) => {
      // this.logger.debug('socket.service=>validation_error>msg');
      this.logger.debug(msg);

      let issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type = WebSocketResponseTypeEnum.Validation_Error;
      issueResponse.response_message = msg;
      this.SocketServiceEmitter.emit(issueResponse);
    });

    this.socket.on('insufficient_privileges', (msg) => {
      // this.logger.debug('socket.service=>insufficient_privileges : ');
      this.logger.debug(msg);

      let issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type =
        WebSocketResponseTypeEnum.Insufficient_Privileges;
      issueResponse.response_message = msg;
      this.SocketServiceEmitter.emit(issueResponse);
    });

    this.socket.on('insufficient_subscription', (msg) => {
      this.logger.debug(msg);

      let issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type = WebSocketResponseTypeEnum.Insufficient_Subscription;
      issueResponse.response_message = msg.error_msg;
      issueResponse.request_event = msg.request_event;
      if (msg.request_event == 'graph') {
        // send down GraphEmitter
        this.GraphEmitter.emit(issueResponse);
      } else {
        // send down SocketServiceEmitter otherwise
        this.SocketServiceEmitter.emit(IssueResponse);
      }
    });

    this.socket.on('map_gateway_complete', (mapGatewayCompleteResponse: MapGatewayCompleteResponse) => {
      mapGatewayCompleteResponse.response_type = WebSocketResponseTypeEnum.Map_Gateway_Complete;
      this.SocketServiceEmitter.emit(mapGatewayCompleteResponse);
      if (mapGatewayCompleteResponse.response_type == 5) this.siteGatewayUnitsMapped.emit(mapGatewayCompleteResponse);
    });

    this.socket.on('map_gateway_started', (response: MapGatewayStartedResponse) => {
      response.response_type = WebSocketResponseTypeEnum.Map_Gateway_Started;
      this.SocketServiceEmitter.emit(response);
    });

    this.socket.on('map_gateway_error', (response: MapGatewayErrorResponse) => {
      response.response_type = WebSocketResponseTypeEnum.Map_Gateway_Error;
      this.SocketServiceEmitter.emit(response);
    });

    this.socket.on('map_gateway_progress', (response: MapGatewayProgressResponse) => {
      response.response_type = WebSocketResponseTypeEnum.Map_Gateway_Progress;
      this.SocketServiceEmitter.emit(response);
    });

    this.socket.on('graph_complete', (response) => {
      response.response_type = WebSocketResponseTypeEnum.GraphDataComplete;
      response.capability = this.requests[response.request_id];
      this.GraphEmitter.emit(response);
    });

    // this.socket.on('csv_complete', (response) => {
    //   this.logger.warn("Got a csv complete message!");
    //   this.logger.warn(response);
    //   this.SocketServiceEmitter.emit({
    //     "response_type": WebSocketResponseTypeEnum.CSV_Complete,
    //     "csv_file_name": response['csv_file']
    //   });
    // });

    this.socket.on('profile_update', (profileUpdateResponse) => {
      if (profileUpdateResponse) {
        this.ProfileUpdateEmitter.emit(profileUpdateResponse);
      } else {
        console.log("Received a bad profile update - no data?")
      }
    });

    this.socket.on('authenticated', () => {
      const issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type = WebSocketResponseTypeEnum.Authenticated;
      issueResponse.response_message = 'Socket authenticated';
      this.authenticated = true;
      this.SocketServiceEmitter.emit(issueResponse);
          });

    this.socket.on('auth_expired', () => {
      const issueResponse: IssueResponse = new IssueResponse();
      issueResponse.response_type =
        WebSocketResponseTypeEnum.Authentication_Expired;
      issueResponse.response_message = 'Socket authentication expired';
      this.SocketServiceEmitter.emit(issueResponse);
      this.authenticated = false;
      // You get 10 seconds to do this
      this.authenticate();
    });

    this.socket.on('gif_ready', (response) => {
      // lookup this request_id in session storage and update the stage active
      this.logger.warn("Got a gif_ready message!");
      this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.GIF_Ready,
        "request_id": response['request_id']
      });
    });

    this.socket.on('gif_complete', (response) => {
      // lookup this request_id in the session storage and
      // update to complete
      this.logger.warn("Got a gif_complete message!");
      this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.GIF_Complete,
        "request_id": response['request_id'],
        "image_url": response['image_url']
      });
    });

    this.socket.on('gif_error', (response) => {
      // error reported from back end re: gif image generation
      this.logger.warn("Got a gif_error message");
      this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.GIF_Error,
        "request_id": response['request_id'],
        "error_msg": response['error_msg']
      });
    });

    this.socket.on('test_run_complete', (response) => {
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Test_Run_Complete,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('test_run_error', (response) => {
      this.logger.warn('Got test run error result');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Test_Run_Error,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('test_run_progress', (response) => {
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Test_Run_Progress,
        request_id: '',
        response: response
      })
    })

    this.socket.on('maint_job_complete', (response) => {
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Job_Complete,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('maint_job_error', (response) => {
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Job_Error,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('maint_get_mtdz_complete', (response) => {
      this.logger.warn('Got MTDZ data');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Get_MTDZ_Complete,
        request_id: response['request_id'],
        s3_file_name_to_download: response["data"],
        response: response
      });
    });

    this.socket.on('maint_get_mtdz_error', (response) => {
      this.logger.warn('Error - Get MTDZ data');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Get_MTDZ_Error,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('maint_update_unit_complete', (response) => {
      this.logger.warn('Got maintenance update unit data');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Update_Unit_Complete,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('maint_update_unit_error', (response) => {
      this.logger.warn('Got maintenance update unit error result');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.Maint_Update_Unit_Error,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('mtdz_ready', (response) => {
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.MTDZ_Ready,
        "request_id": response['request_id']
      });
    })

    this.socket.on('mtdz_progress', (response) => {
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.MTDZ_Progress,
        "request_id": response['request_id'],
        "progress": response['progress']
      });
    })

    this.socket.on('mtdz_complete', (response) => {
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.MTDZ_Complete,
        "request_id": response['request_id'],
        "s3_file_name_to_download": response["s3_file_name"]
      });
    })

    this.socket.on('mtdz_error', (response) => {
      this.SocketServiceEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.MTDZ_Error,
        "request_id": response['request_id'],
        "error_msg": response["error_msg"]
      });
    })

    this.socket.on('bcport_progress', (response) => {
      this.maintenanceEmitter.emit({
        type: maintenanceNotification.BCPORT,
        responseData: response
      });
    });

    this.socket.on('refrigerant_progress', (response) => {
      this.maintenanceEmitter.emit({
        type: maintenanceNotification.REFRIGERANT,
        responseData: response
      });
    });

    this.socket.on('refrigerant_download_url', (response) => {
      this.maintenanceEmitter.emit({
        type: maintenanceNotification.REFRIGERANT_REPORT,
        responseData: response
      });
    });
    
    this.socket.on('maint_alert_information_result', (response) => {
        this.AlertEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.Receive_Create_Alert_Dump,
        "status": response['status'],
      });
    })

    this.socket.on('maint_alert_information_getfile_result', (response) => {
      this.AlertEmitter.emit({
        "response_type": WebSocketResponseTypeEnum.Receive_Result_Create_Alert_Dump,
        "status": response['status'],
        "url": response['url']
      });
    })

    this.socket.on('system_monitor_complete', (response) => {
      this.logger.warn('Got system monitor result');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.System_Monitor_Complete,
        request_id: response['request_id'],
        response: response
      });
    });

    this.socket.on('system_monitor_error', (response) => {
      this.logger.warn('Got system monitor error result');
      // this.logger.warn(response);
      this.SocketServiceEmitter.emit({
        response_type: WebSocketResponseTypeEnum.System_Monitor_Error,
        request_id: response['request_id'],
        response: response
      });
    });

    if (devEnv) {
      // socket cluster testing
      this.socket.on('test_message', (response) => {
        console.log('Test Socket Message Received:'+response.message)
      })
    }
  }

  async authenticate() {
    if (!this.socket?.connected) {
      return this.logger.debug(
        'socket.service=>authentication=>authentication_failure'
      );
    }

    this.accessToken = await this.userService.getToken();
    this.socket.emit('authenticate', { Authorization: this.accessToken });

    this.joinRooms();
  }

  is_connected(): boolean {
    if (!this.socket?.connected) {
      return this.socket.connected;
    }
    return true;
  }

  async joinRooms() {
    this.socket.emit('join', {})
  }

  mapGateway(mapGatewayRequest: MapGatewayRequest) {
    let result: boolean = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('map_gateway', {
          gateway_id: mapGatewayRequest.gateway_id,
          site_id: mapGatewayRequest.site_id,
          request_id: mapGatewayRequest.request_id,
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to map gateway');
      }
    }
    return result;
  }

  maintUpdateUnit(obj){
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('maint_update_unit', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id,
          rmd_serial_no: obj.rmd_serial_no
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to update unit');
      }
    }
    return result;
  }

  testRun(obj) {
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('test_run', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id,
          mode: obj.mode,
          loop_number: obj.loop_number,
          rmd_serial_no: obj.rmd_serial_no,
          ou_address_list: obj.ou_address_list,
          ou_model_name_list: obj.ou_model_name_list,
          ou_serial_no_list: obj.ou_serial_no_list,
          outside_temperature_list: obj.outside_temperature_list
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to request test run');
      }
    }
    return result;
  }

  cancelTestRun(obj){
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('cancel_test_run', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to cancel test run');
      }
    }
    return result;
  }

  getMaintData(obj) {
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('maint_job', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id,
          start_date: obj.start_date,
          end_date: obj.end_date,
          resolution: obj.resolution,
          monitor_mode: obj.monitor_mode,
          address: obj.address
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to req maint data');
      }
    }
    return result;
  }

  getMTDZ(obj) {
    // request RMD mtdz data/file - this is not the call for MCC gateways
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('maint_get_mtdz', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id,
          start_date: obj.start_date,
          end_date: obj.end_date,
          time_zone: obj.time_zone,
          monitor_mode: obj.monitor_mode,
          address: obj.address
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to request mtdz');
      }
    }
    return result;
  }

  requestGraphData(obj) {
    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.requests[obj.request_id] = obj.capability;
        this.socket.emit('graph', obj);
      } else {
        this.logger.debug(
          'socket not connected : Unable to request graph data'
        );
      }
    }
  }

  judge_authenticated() {
    // authenticatedの状態を判別し、他の画面からでも判別できるようにする
    // Determine the state of authenticated and be able to identify it from other screens.
    let result = false;
    if (this.authenticated) {
      result = true;
    }
    return result;
  }

  // requestCsvData(obj) {
  //   let result: boolean = false;
  //   if (this.socket) {
  //     if (this.socket.connected && this.authenticated) {
  //       this.socket.emit('csv', obj);
  //       result = true;
  //     } else {
  //       this.logger.debug(
  //         'socket not connected : Unable to request csv data'
  //       );
  //     }
  //   }
  //   return result;
  // }

  requestGifData(obj) {
    let result: boolean = false;
    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('gif_start', obj);
        result = true;
      } else {
        this.logger.debug(
          'socket not connected : Unable to request gif download'
        );
      }
    }
    return result;
  }

  requestMtdzData(obj: SiteAnalyticsMtdzDownloadRequest) {
    let result: boolean = false;
    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        if (obj.gateway_model == GatewayModelClass.MCC) {
          this.socket.emit('mtdz_start', obj);
          result = true;
        } else {
          result = this.getMTDZ(obj)
        }
      } else {
        this.logger.debug(
          'socket not connected : unable to request mainenance data'
        );
      }
    }
    return result;
  }

  sendControlCommand(
    param: string,
    value: string,
    gatewayId: string,
    busAddress: string
  ) {
    // Not connected or doesn't exist
    if (!this.socket?.connected) {
      this.showSocketIssueToast(ApplicationErrorsEnum.WEBSOCKET_NOT_CONNECTED);

      return this.logger.debug('socket.service=>command=>not_connected');
    }

    this.socket.emit('control', {
      value,
      param,
      gateway_id: gatewayId,
      bus_address: busAddress,
    });
    this.logger.debug(`socket.service=>command=>sending_${param}_${value}`);
  }

  accountSiteNotificationCounts() {
    return this.http.get(WebserviceURL + 'site/notifications/totals');
  }
  
  sendCommonRequest(requestModel: any, command: string) {
    let result = false;
    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit(command, requestModel);
        result = true;
      } else {
        this.logger.debug(
          'socket not connected : unable to send request' + command
        );
      }
    }
    return result;
  }

  getSystemMonitor(obj) {
    let result = false;

    if (this.socket) {
      if (this.socket.connected && this.authenticated) {
        this.socket.emit('system_monitor', {
          gateway_id: obj.gateway_id,
          site_id: obj.site_id,
          request_id: obj.request_id,
          date: obj.date
        });
        result = true;
      } else {
        this.logger.debug('socket not connected : Unable to map gateway');
      }
    }
    return result;
  }

}
