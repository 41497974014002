import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMessageTypeCode, AppMessageCode } from 'src/app/enumerations/enums';
import { HTTP_ERROR_MSG_DEFAULT, APP_UNAUTHORIZED_MSG } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-app-message',
  templateUrl: './app-message.component.html',
  styleUrls: ['./app-message.component.scss'],
})
export class AppMessageComponent implements OnInit {
  display_header: string;
  display_message: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.display_message = HTTP_ERROR_MSG_DEFAULT;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if ((this.activatedRoute.snapshot.paramMap.has('appmessagetype')) &&
        (this.activatedRoute.snapshot.paramMap.has('appmessagecode'))
      ) {
        const app_message_type_str: string = this.activatedRoute.snapshot.paramMap.get('appmessagetype');
        const app_message_code_str: string = this.activatedRoute.snapshot.paramMap.get('appmessagecode');
        const app_message_type: AppMessageTypeCode = Number(app_message_type_str);
        const app_message_code: AppMessageCode = Number(app_message_code_str);

        this.display_application_message(app_message_type, app_message_code);
      }
    });
  }

  navigateHome() {
    this.router.navigate(['/home']);
  }

  display_application_message(app_message_type: AppMessageTypeCode, app_message_code: AppMessageCode) {
    this.display_message = HTTP_ERROR_MSG_DEFAULT;

    switch (app_message_type) {
      case AppMessageTypeCode.HTTPRESPONSE:
      case AppMessageTypeCode.APP_PERMISSIONS:

        switch (app_message_code) {
          case AppMessageCode.FORBIDDEN:
          case AppMessageCode.UNAUTHORIZED:
            this.display_message = APP_UNAUTHORIZED_MSG;
            this.display_header = 'Unauthorized';
            break;
        }
        break;
    }
  }

}
