import { v4 as uuid } from 'uuid';
import { OutdoorUnit } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { OutdoorUnitDetails } from 'src/app/features/sites/components/site-analytics-test-run/site-analytics-test-run.component';

/**
 * Common class for Request
 */

export class WebSocketRequest {
    site_id: string;
    gateway_id: string;
    request_id: string;

    constructor() {
        this.site_id = ``;
        this.gateway_id = ``;
        this.request_id = ``;
    }

    setBaseIds(gateway_id, site_id, request_id = uuid()) {
        this.site_id = site_id;
        this.gateway_id = gateway_id;
        this.request_id = request_id;
    }

    getJSONData() {
        return JSON.stringify(this);
    }

    getRequestId() {
        return this.request_id;
    }
}

/**
 * communication class used in Maintenance Tub
 */

export class MaintenanceCommonRequest extends WebSocketRequest {
    modelName: string;
    unitSerialNo: string;
    address: number | any;
    gatewaySerialNo: string;
    managementTableId: string;

    setData(data: OutdoorUnit) {
        this.modelName = data.modelName;
        this.unitSerialNo = data.unitSerialNo;
        this.address = Number(data.unitAddress);
        this.gatewaySerialNo = data.gatewaySerialNo;
        this.managementTableId = data.managementTableId;
    }
}

export class BranchPortCheckRequest extends MaintenanceCommonRequest {
    // address: number; // Already inherited from the parent
    setData(data: OutdoorUnit, oduDetail?: OutdoorUnitDetails) {
        super.setData(data);
        if (oduDetail) {
            this.modelName = oduDetail.gwModel;
            this.address = Number(oduDetail.bus_address);
            this.gatewaySerialNo = oduDetail.gateway_serial_number;
            this.unitSerialNo = oduDetail.bus_address; // The unit serial number might not be needed in the future
        }
    }
}

/**
 * communication class used in BC Port
 */

export class MaintenanceCommonRequestMultiple extends MaintenanceCommonRequest {
    target: {
        address: number,
        unitSerialNo: string,
        modelName: string
    }[];
    data: Array<OutdoorUnit>
    setData(data) {
        this.target = [];
        for(const index in data){
            this.target.push({
                address: Number(data[index].unitAddress),
                unitSerialNo: data[index].unitSerialNo,
                modelName: data[index].modelName
            })
        }
        this.gatewaySerialNo = data[0].gatewaySerialNo;
        this.managementTableId = data[0].managementTableId;  
    }
}


/**
 * communication class used in Refrigerant progress
 */

export class RefrigerantCheckRequest extends MaintenanceCommonRequest {
    // address: number; // Already inherited from the parent
    loopNumber: number | any; // Could be the duration?
    setData(data: OutdoorUnit, loopNumber = 80, oduDetail?: OutdoorUnitDetails) {
        super.setData(data);
        this.loopNumber = loopNumber;
        if (oduDetail) {
            this.modelName = oduDetail.gwModel;
            this.address = Number(oduDetail.bus_address);
            this.gatewaySerialNo = oduDetail.gateway_serial_number;
            this.unitSerialNo = oduDetail.bus_address; // The unit serial number might not be needed in the future
        }
    }
}

/**
 * communication class used in Refrigerant download
 */

export class RefrigerantDownloadRequest extends WebSocketRequest {
    address: number;
    fileName: string;
    setData(address, fileName) {
        this.address = address;
        this.fileName = fileName;
    }
}

/**
 * communication class used in Alert dump creation start request
 */

export class requestAlertDumpStart extends WebSocketRequest {
    time_zone: string;
    sitenotification_id: string;
    setData(time_zone: string, sitenotification_id: string) {
        this.time_zone = time_zone;
        this.sitenotification_id = sitenotification_id;
    }
}

/**
 * communication class used in Alert dump creation result request
 */

export class requestAlertDumpGetfile extends WebSocketRequest {
    alert_information_request_id: string;
    setData(alert_information_request_id) {
        this.alert_information_request_id = alert_information_request_id;
    }
}