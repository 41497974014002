import { maintenanceNotification } from 'src/app/enumerations/enums';

// Data for one row of the table for displaying results
export class RefrigerantResultData {
    label: string;
    value: string;

    constructor(label: string, value: string) {
        this.label = label;
        this.value = value;
    }
}

// A class that manages data for displaying results
export class RefrigerantResultDataList {
    list: RefrigerantResultData[];

    constructor() {
        this.list = [];
    }
    createlist(data: RefrigerantRecvData, isCancel: boolean) {
        this.createALData(data.al, isCancel);
    }

    private createALData(al: number, isCancel: boolean) {
        if (isCancel === false) {
            this.list.push(new RefrigerantResultData('AL', String(al)));
        } else {
            this.list.push(new RefrigerantResultData('AL', 'Canceled'));
        }
    }
}


// Class for parsing incoming data
export class RefrigerantRecvData {
    private typeJudge = {
        'reception_start': notificationType.START,
        'reception_suspended': notificationType.SUSPEND,
        'notify': notificationType.NOTIFY,
        'finish': notificationType.FINISH,
        'err_start': notificationType.ERR_START,
        'err_mfk': notificationType.ERR_MFK,
        'err': notificationType.ERR,
    }
    type: notificationType;
    progress: number;
    ocAddress: number;
    al: number;
    fileName: string;
    url: string;
    status: string;
    managementTableId: string;
    target: {
        address: number,
        systemName: string,
        modelName: string
    }[];

    constructor(data) {
        this.type = notificationType.ERR;
        this.progress = 0;
        this.ocAddress = 0;
        this.al = 0;
        this.url = '';
        this.fileName = '';
        this.managementTableId = '-';
        this.status = '-';
        this.target = [{
            address: 0,
            systemName:'-',
            modelName:'-'
        }];
        this.updateData(data);
    }

    private updateData(data) {
        switch (data.type) {
            case maintenanceNotification.REFRIGERANT:
                this.type = this.typeJudge[data.responseData.type];
                this.progress = data.responseData.progress;
                this.ocAddress = data.responseData.result.ocAddress;
                this.al = data.responseData.result.al;
                this.fileName = data.responseData.result.fileName;
                this.status = data.responseData.status;
                this.managementTableId = data.responseData.managementTableId;
                this.target = data.responseData.target;
                break;

            case maintenanceNotification.REFRIGERANT_REPORT:
                this.type = notificationType.REPORT;
                this.url = data.responseData.url;
                break;

            default:
                break;

        }

    }
}

export enum notificationType {
    START,
    SUSPEND,
    NOTIFY,
    FINISH,
    ERR_START,
    ERR_MFK,
    ERR,
    REPORT,
    NOTIFICATION_NUM
}
