// track the state of the gif download through stages
export enum gifDownloadStage {
    noDownload = 0,
    startAnEEngine = 1,
    generateImage = 2,
    downloadReady = 3,
    errorState = 4
}

// object that tracks a site based gif download of an ODU
export class gifDownloadTracker {
    site_id = '';
    site_name = '';
    request_id = '';
    gateway_id = '';
    startTime = 0;
    bus_address = 0;
    image_url = '';
    error_msg = '';
    stage: gifDownloadStage = gifDownloadStage.startAnEEngine;
}