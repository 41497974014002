<ion-content main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light" class="ion-no-padding" me-gateway-toolbar>
    <ion-buttons slot="start" style="padding-left: 10px">
      <h1>
        <ion-label slot="start" color="dark" id="lblHeaderGatewayName" name="lblHeaderGatewayName">
          Gateway Details
        </ion-label>
      </h1>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="back()" me-button-as-link id="btnBackToGatewayList" name="btnBackToGatewayList">
        &#8592;&nbsp;Back to Site Gateways </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <!--Gateway Information-->
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card me-gateway-info-card-content>
          <ion-item lines="none" me-gateway-info-card-header>
            <ion-label class="ion-padding" id="lblGatewayInfo" name="lblGatewayInfo" style="padding-left:0;">
              <strong>Gateway Information</strong>
            </ion-label>
            <div
              *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditSiteGateway)"
              class="ion-icon-wrapper-card-header" (click)="onEditGateway(selectedGateway)"
              title="Edit Gateway Information">
              <ion-icon id="btnEdit" name="create" class="ion-padding-start" color="black"
                style="cursor:pointer;margin-top:2px"></ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content me-top-border>
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col><strong>Gateway Name:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblGatewayName" name="lblGatewayName">{{ selectedGateway.name }}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col><strong>Device Type:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblGatewayType" name="lblGatewayType">{{ selectedGateway.model.name ?
                    selectedGateway.model.name : '' }}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col><strong>Serial Number:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblSerialNumber" name="lblSerialNumber">{{
                    selectedGateway.serial_number }} <i *ngIf="selectedGateway.has_coupon">({{
                      selectedGateway.coupon_name }})</i></ion-label>
                </ion-col>
              </ion-row>
              <div *ngIf="selectedGateway.model.class_name == gatewayModelClass.RMD">
                <ion-row white-background>
                  <ion-col><strong>MAC Address:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblMACAddress" name="lblMACAddress">{{ selectedGateway.mac_address }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background>
                  <ion-col><strong>Device Key:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblDeviceKey" name="lblDeviceKey">{{ selectedGateway.device_key }}</ion-label>
                  </ion-col>
                </ion-row>
              </div>
              <div *ngIf="selectedGateway.model.class_name == gatewayModelClass.MCC">
                <ion-row white-background>
                  <ion-col><strong>Registration Code:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblDeviceKey" name="lblDeviceKey">{{ selectedGateway.device_key }}</ion-label>
                  </ion-col>
                </ion-row>
              </div>
              <div>
                <ion-row white-background>
                  <ion-col><strong>Firmware Version:</strong></ion-col>
                  <ion-col>
                    <ion-text id="lblDeviceKeyFirmwareVersion" name="lblDeviceKeyFirmwareVersion">
                      {{ selectedGateway.firmware_version }}</ion-text>
                  </ion-col>
                </ion-row>
              </div>
              <div style="height:10px"></div>
              <div>
                <ion-row white-background>
                  <ion-col><strong>Status:</strong></ion-col>
                  <!-- using firmware_version here just to keep disconnected from showing while we wait for the call back-->
                  <ion-col *ngIf="selectedGateway.firmware_version &&  selectedGateway.activeSubscription?.gateway_subscription_status_type_id != GSSTE.Suspended">
                    <ion-text id="gatewayStatus" *ngIf="selectedGateway?.connected" style="color:var(--ion-color-success)">Online</ion-text>
                    <ion-text id="gatewayStatus" *ngIf="!selectedGateway?.connected" style="color:red">Disconnected</ion-text>
                  </ion-col>
                  <ion-col *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended">
                    <ion-text id="gatewayStatus" *ngIf="selectedGateway?.connected">n/a</ion-text>
                  </ion-col>
                </ion-row>
              </div>
              <div>
                <ion-row while-background>
                  <!-- Coming from Redis // Gateay details connection status as last_push_update -->
                  <ion-col><strong>Last Update:</strong></ion-col>
                  <ion-col>
                    <ion-text id="lastPushUpdate">{{selectedGateway?.connection?.last_push_update_text}}</ion-text>
                  </ion-col>
                </ion-row>
              </div>
              <ion-row white-background>
                <ion-col>&nbsp;</ion-col>
                <ion-col>&nbsp;</ion-col>
              </ion-row>
              <div *ngIf="gatewayLoaded">
                <div id="firmwareUpdateHolder"
                  *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.PerformFirmwareUpgrade)">
                  <div id="firmwareUpdateIcon">
                    <ion-row white-background>
                      <ion-col me-center-grid-col-content *ngIf="selectedGateway.firmware_update_available">
                        <div class="ion-icon-wrapper-raw-button" (click)="startFirmwareUpgrade()" title="Firmware Update">
                          <ion-icon name="download" [ngClass]="{'me-firmware-dl-icon-clickable': !firmware_show_progress_bar,
                                                                'me-firmware-dl-icon': firmware_show_progress_bar  }"
                            color="{{firmware_show_progress_bar ? 'light' : 'dark' }}">
                          </ion-icon>
                        </div>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col me-center-grid-col-content *ngIf="selectedGateway.firmware_update_available">
                        <strong>
                          <ion-label [ngClass]="{'button_label': !firmware_show_progress_bar,
                                                'button_label_updating': firmware_show_progress_bar  }"
                            id="lblFWUpdateAvailTop" name="lblFWUpdateAvailTop">
                            Update MCC-50A
                          </ion-label>
                        </strong>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col me-center-grid-col-content *ngIf="selectedGateway.firmware_update_available">
                        <strong>
                          <ion-label [ngClass]="{'button_label': !firmware_show_progress_bar,
                          'button_label_updating': firmware_show_progress_bar  }" id="lblFWUpdateAvailbot"
                            name="lblFWUpdateAvailBot">
                            Firmware
                          </ion-label>
                        </strong>
                      </ion-col>
                    </ion-row>
                  </div>
                  <ion-row *ngIf="firmware_show_progress_bar">
                    <ion-col size="1.5">
                      <div>
                        <ion-spinner *ngIf="!firmware_update_finished" color=primary me-spinner-icon name="lines"></ion-spinner>
                        <ion-icon *ngIf="firmware_update_finished && !firmware_update_failed" me-finished-icon-success name="checkmark-circle"></ion-icon>
                        <ion-icon *ngIf="firmware_update_finished && firmware_update_failed" me-finished-icon-fail name="close-circle"></ion-icon>
                      </div>
                    </ion-col>
                    <ion-col class="ion-text-center">
                      <ion-label italic>{{firmware_progress_message}}</ion-label>
                      <ion-progress-bar me-progress-bar [color]="barColor" value="{{firmware_progress_bar_value}}"></ion-progress-bar>
                    </ion-col>
                  </ion-row>
                  <br>
                </div>
                <div id="moveGatewayHolder">
                  <ion-row white-background
                    *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.MoveGateway)">
                    <ion-col me-center-grid-col-content>
                      <app-large-icon-button iconTitle="Move Gateway" iconName="move-sharp" 
                        id="moveGatewayIconButton" class="moveGatewayIconButton automationTesting" 
                        [iconLocked]="isActionRestricted()" (iconAction)="openSiteGatewayMove()"
                        [iconMaintenanceType]="iconMaintenanceType"></app-large-icon-button>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background
                    *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.MoveGateway)">
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="isActionRestricted() ? 'site-transfer-lower-opacity' : ''" 
                          class="button_label" id="lblMove" name="lblMove">Move Gateway</ion-label>
                      </strong>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background
                    *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.MoveGateway)">
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="isActionRestricted() ? 'site-transfer-lower-opacity' : ''" class="button_label" id="lblMove" name="lblMove">
                          to a Different Site
                        </ion-label>
                      </strong>
                    </ion-col>
                  </ion-row>
                  <br>
                </div>
              </div>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <!-- This ODU / IDU Information-->
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card me-gateway-info-card-content>
          <ion-item lines="none" me-gateway-info-card-header>
            <ion-label class="ion-padding" id="lblOutdoorUnitInfo" name="lblOutdoorUnitInfo" style="padding-left:0;">
              <strong>HVAC Equipment</strong>
            </ion-label>
          </ion-item>
          <ion-card-content me-ion-card-content me-top-border>
            <ion-grid class="ion-no-padding">

              <!-- Start : ODU Units Section-->
              <ion-row id="gwDetailsODURow" class="gwDetailsODURow {{ oduShown ? 'itemIsExpanded' : 'itemIsCollapsed' }} automationTesting" white-background me-cursor-pointer (click)="toggleSection('odu')">
                <ion-col id="gwDetailsODUCol" class="gwDetailsODUCol automationTesting" size="auto">
                  <div id="gwDetailsODUIconContainer" class="gwDetailsODUIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" [title]="oduShown ? 'Collapse' : 'Expand'">
                    <ion-icon id="iIconGWDetailsToggleODU" class="iIconGWDetailsToggleODU {{ oduShown ? 'iconIsExpanded' : 'iconIsCollapsed' }} automationTesting" [name]="oduShown ? 'caret-down' : 'caret-forward'" me-color-black></ion-icon>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-label id="lblOutdoorUnitInfo" name="lblOutdoorUnitInfo" class="sub-header">
                    <strong>&nbsp;Outdoor Unit(s)</strong>
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-row white-background *ngIf="oduShown">
                <!-- <ion-col col col-12 class="ion-no-padding"> -->
                <ion-col>
                  <ion-col class="FixedHeightContainer">
                    <!--Start Outdoor Unit Card-->
                    <!-- <div div-units> -->
                    <div class="ODUContent" *ngIf="(outdoorUnits && outdoorUnits.length>0)">
                      <ion-card *ngFor="let outdoorUnit of outdoorUnits; last as isLast; let i = index"
                        id="outdoorUnitCard" name="outdoorUnitCard"
                        [ngClass]="isLast ? 'me-ion-unit-card-last' : 'me-ion-unit-card'">
                        <ion-item [color]="outdoorUnit.isSelected ? 'selected' : ''" class="ion-no-padding"
                          (click)="display_outdoor_unit_units(outdoorUnit)" style="cursor: pointer;">
                          <ion-label id="lblODUName" name="lblODUName" class="ion-padding-start">
                            {{ outdoorUnit.name ? outdoorUnit.name : outdoorUnit.bus_address }}
                          </ion-label>
                          <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                            (click)="showSelectedOutdoorUnit(outdoorUnit.id)"
                            [title]="outdoorUnit.isExpanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="outdoorUnit.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"
                              me-cursor-pointer>
                            </ion-icon>
                          </div>
                        </ion-item>
                        <ion-card-content *ngIf="outdoorUnit.isExpanded" lines="none" style="max-height: 300px">
                          <div style="float: left; width: 95%; margin-bottom:10px;">
                            <ion-grid class="ion-no-padding">
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Name:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblODUName" name="lblODUName" ion-no-padding class="ion-no-padding">
                                    {{ outdoorUnit.name ? outdoorUnit.name : outdoorUnit.bus_address }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Type:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblODUTypeName" name="lblODUTypeName" ion-no-padding
                                    class="ion-no-padding">
                                    {{ outdoorUnit.type_name ? outdoorUnit.type_name : outdoorUnit.type }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="outdoorUnit.type != 'LH' && outdoorUnit.type != 'MH'">
                                <ion-col size="6"><strong>Series: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOutdoorBusAddressOfName" name="lblOutdoorBusAddressOfName"
                                    ion-no-padding class="ion-no-padding">
                                    {{ outdoorUnit.series ? outdoorUnit.series : "Not Selected" }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="outdoorUnit.type != 'LH' && outdoorUnit.type != 'MH'">
                                <ion-col size="6"><strong>Model: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOutdoorBusAddressOfName" name="lblOutdoorBusAddressOfName"
                                    ion-no-padding class="ion-no-padding">
                                    {{ outdoorUnit.model ? outdoorUnit.model : "Not Selected" }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="outdoorUnit.type != 'LH' && outdoorUnit.type != 'MH'">
                                <ion-col size="6"><strong>M-NET Address: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOutdoorBusAddressOfName" name="lblOutdoorBusAddressOfName"
                                    ion-no-padding class="ion-no-padding">
                                    {{ outdoorUnit.bus_address ? outdoorUnit.bus_address : outdoorUnit.bus_address }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>

                          <div style="float:right; width:5%;">
                            <ion-col size="1" me-cursor-pointer>
                              <strong>
                                <div
                                  *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)"
                                  class="ion-icon-wrapper-raw-button" style="font-size: 22px"
                                  (click)="onEditUnit(outdoorUnit)" title="Edit Unit">
                                  <ion-icon name="create"></ion-icon>
                                </div>
                              </strong>
                            </ion-col>
                          </div>
                          <div style="float:right; width:5%;">
                            <ion-col size="1" me-cursor-pointer>
                              <strong>
                                <div id="configure_units_{{i}}"
                                  *ngIf='appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)'
                                  style="font-size: 22px" (click)="configureGroups(outdoorUnit)"
                                  title="Configure Units">
                                  <ion-icon name="play-circle"></ion-icon>
                                </div>
                              </strong>
                            </ion-col>
                          </div>

                        </ion-card-content>
                      </ion-card>
                    </div>
                    <!--End Outdoor Unit Card-->
                  </ion-col>
                </ion-col>
              </ion-row>
              <!-- End : ODU Units Section-->
              <!-- Start : IDU Units Section-->
              <ion-row id="gwDetailsICRow" class="gwDetailsICRow {{ iduShown ? 'itemIsExpanded' : 'itemIsCollapsed' }} automationTesting" white-background me-cursor-pointer me-margin-top-adjust (click)="toggleSection('idu')">
                <ion-col id="gwDetailsICCol" class="gwDetailsICCol automationTesting" size="auto">
                  <div id="gwDetailsICIconContainer" class="gwDetailsICIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" [title]="iduShown ? 'Collapse' : 'Expand'">
                    <ion-icon id="iIconGWDetailsToggleIC" class="iIconGWDetailsToggleIC {{ iduShown ? 'iconIsExpanded' : 'iconIsCollapsed' }} automationTesting" [name]="iduShown ? 'caret-down' : 'caret-forward'" me-color-black></ion-icon>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-label id="lblIndoorUnitInfo" name="lblIndoorUnitInfo" class="sub-header">
                    <strong>&nbsp;Groups / Indoor Coils</strong>
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-row white-background *ngIf="iduShown">
                <ion-col>
                  <ion-col class="FixedHeightContainer">
                    <div class="ion-text-center"
                      *ngIf="!ui_is_outdoor_unit_selected() && (outdoorUnits && outdoorUnits.length>0)">
                      <p style="font-size: 16px; min-height:250px; padding-top:100px;"><i>
                          Select an Outdoor Unit<br>to see Groups /<br>Indoor Coils.
                        </i></p>
                    </div>
                    <div class="IDUContent" *ngIf="ui_is_outdoor_unit_selected() == true">
                      <div *ngFor="let view_item of site_gateway_detail_view_data; last as isLast;">
                        <ion-card *ngIf="view_item.view_type!=='LC'" id="indoorUnitCard" name="indoorUnitCard"
                          [ngClass]="isLast ? 'me-ion-unit-card-last' : 'me-ion-unit-card'">
                          <ion-item class="ion-no-padding">
                            <ion-icon *ngIf="view_item.view_type==='GROUP'" name="copy" style="padding-left: 10px;">
                            </ion-icon>
                            <ion-icon *ngIf="view_item.view_type==='IU'" name="stop" style="padding-left: 10px;">
                            </ion-icon>

                            <ion-label id="lblIndoorBusAddressOfName" name="lblIndoorBusAddressOfName"
                              class="ion-padding-start">
                              {{view_item.name ? view_item.name : view_item.bus_address}}
                            </ion-label>
                            <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                              (click)="showSelectedIndoorUnit(view_item.id)"
                              [title]="view_item.isExpanded ? 'Collapse' : 'Expand'">
                              <ion-icon [name]="view_item.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"
                                me-cursor-pointer>
                              </ion-icon>
                            </div>
                          </ion-item>

                          <ion-card-content *ngIf="view_item.isExpanded" lines="none" style="max-height: 300px">
                            <div style="float:left; width:95%; margin-bottom:10px;">
                              <div *ngIf="view_item.view_item.autocreated == false">
                                <ion-grid class="ion-no-padding">
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Group Name:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDUGroup" name="lblIDUGroup" ion-no-padding
                                        class="ion-no-padding" *ngIf="view_item.view_item.autocreated == false">
                                        {{get_group_name_by_id(view_item.group_id) }}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Unit(s):</strong></ion-col>
                                    <ion-col size="6">
                                      <div id="lblIDUBusAddressByName" name="lblIDUBusAddressByName" ion-no-padding
                                        class="ion-no-padding" *ngFor="let unit of nameSort(view_item.view_item.units)">
                                        {{unit.name}}
                                      </div>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Location / Area:</strong></ion-col>
                                    <ion-col size="6">
                                      <div id="lblIGroupLocation" name="lblGroupLocation" ion-no-padding class="ion-no-padding">
                                        {{view_item.view_item.location ? view_item.view_item.location : ''}}
                                      </div>
                                    </ion-col>
                                  </ion-row>


                                </ion-grid>

                              </div>
                              <div *ngIf="view_item.view_item.autocreated == true">
                                <ion-grid class="ion-no-padding">
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Unit Name:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDUName" name="lblIDUName" ion-no-padding
                                        class="ion-no-padding">
                                        {{ view_item.name }}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row *ngIf="view_item.view_type != 'LC'" white-background>
                                    <ion-col size="6"><strong>Group Name:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDUGroup" name="lblIDUGroup" ion-no-padding
                                        class="ion-no-padding">
                                        {{get_group_name_by_id(view_item.group_id) }}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Unit Type:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDUTypeName" name="lblIDUTypeName" ion-no-padding
                                        class="ion-no-padding">
                                        {{view_item.view_type_name}}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>M-NET Address:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDUBusAddressByName" name="lblIDUBusAddressByName"
                                        ion-no-padding class="ion-no-padding">
                                        {{view_item.view_item.units[0].bus_address }}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row white-background>
                                    <ion-col size="6"><strong>Location / Area:</strong></ion-col>
                                    <ion-col size="6">
                                      <ion-label id="lblIDULocation" name="lblIDULocation"
                                        ion-no-padding class="ion-no-padding">
                                        {{view_item.view_item.units[0].location ? view_item.view_item.units[0].location : '' }}
                                      </ion-label>
                                    </ion-col>
                                  </ion-row>
                                </ion-grid>
                              </div>
                            </div>
                            <div style="float: right; width: 5%;">
                              <ion-col size="1">
                                <strong>
                                  <div
                                    *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)"
                                    class="ion-icon-wrapper-raw-button" style="font-size: 22px" title="{{ view_item.view_item.autocreated ? 'Edit Indoor Coil' : 'Edit Group' }}"
                                    (click)="onEditUniVI(view_item)">
                                    <ion-icon name="create" me-cursor-pointer>
                                    </ion-icon>
                                  </div>
                                </strong>
                              </ion-col>
                            </div>
                          </ion-card-content>
                        </ion-card>
                      </div>
                    </div>
                  </ion-col>

                </ion-col>
              </ion-row>
              <!-- END : IDU Units Section-->
              <!-- Start : Lossnay Units Section-->
              <ion-row id="gwDetailsLCRow" class="gwDetailsLCRow {{ lcShown ? 'itemIsExpanded' : 'itemIsCollapsed' }} automationTesting" white-background me-cursor-pointer me-margin-top-adjust *ngIf="(lossnays && lossnays.length > 0)" (click)="toggleSection('lc')">
                <ion-col id="gwDetailsLCCol" class="gwDetailsLCCol automationTesting" size="auto">
                  <div id="gwDetailsLCIconContainer" class="gwDetailsLCIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" [title]="lcShown ? 'Collapse' : 'Expand'">
                    <ion-icon id="iIconGWDetailsToggleLC" class="iIconGWDetailsToggleLC {{ lcShown ? 'iconIsExpanded' : 'iconIsCollapsed' }} automationTesting" [name]="lcShown ? 'caret-down' : 'caret-forward'" me-color-black></ion-icon>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-label id="lblLossnayInfo" name="lblLossnayInfo" class="sub-header">
                    <strong>&nbsp;Lossnays</strong>
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-row white-background *ngIf="lcShown">
                <ion-col>
                  <ion-col class="FixedHeightContainer">
                    <div class="ODUContent" *ngIf="(lossnays && lossnays.length>0)">
                      <ion-card *ngFor="let lossnay of lossnays; last as isLast;" id="lossnayCard" name="lossnayCard"
                        [ngClass]="isLast ? 'me-ion-unit-card-last' : 'me-ion-unit-card'">
                        <ion-item [color]="lossnay.isSelected ? 'selected' : ''" class="ion-no-padding">
                          <ion-label id="lblODUName" name="lblODUName" class="ion-padding-start">
                            {{ lossnay.name ? lossnay.name : lossnay.bus_address }}
                          </ion-label>
                          <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                            (click)="showSelectedLossnayUnit(lossnay.id)"
                            [title]="lossnay.isExpanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="lossnay.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"
                              me-cursor-pointer>
                            </ion-icon>
                          </div>
                        </ion-item>
                        <ion-card-content *ngIf="lossnay.isExpanded" lines="none" style="max-height: 300px">
                          <div style="float:left; width:95%; margin-bottom:10px;">
                            <ion-grid class="ion-no-padding">
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Name:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblODUName" name="lblODUName" ion-no-padding class="ion-no-padding">
                                    {{ lossnay.name ? lossnay.name : lossnay.bus_address }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Type:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblODUTypeName" name="lblODUTypeName" ion-no-padding
                                    class="ion-no-padding">
                                    {{ lossnay.type_name ? lossnay.type_name : lossnay.type}}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="lossnay.type != 'LH' && lossnay.type != 'MH'">
                                <ion-col size="6"><strong>M-NET Address: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOutdoorBusAddressOfName" name="lblOutdoorBusAddressOfName"
                                    ion-no-padding class="ion-no-padding">
                                    {{lossnay.bus_address ? lossnay.bus_address : lossnay.bus_address}}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="lossnay.type != 'LH' && lossnay.type != 'MH'">
                                <ion-col size="6"><strong>Location / Area: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblLossnayLocation" name="lblLossnayLocation"
                                    ion-no-padding class="ion-no-padding">
                                    {{lossnay.location ? lossnay.location : ''}}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>

                          <div style="float:right; width:5%;">
                            <ion-col size="1">
                              <strong>
                                <div
                                  *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)"
                                  class="ion-icon-wrapper-raw-button" style="font-size: 22px"
                                  (click)="onEditUnit(lossnay)" title="Edit Unit">
                                  <ion-icon name="create" me-cursor-pointer>
                                  </ion-icon>
                                </div>
                              </strong>
                            </ion-col>
                          </div>
                        </ion-card-content>
                      </ion-card>
                    </div>
                  </ion-col>
                </ion-col>
              </ion-row>
              <!-- End : Lossnay Units Section-->
              <!-- Start : Other Units Section-->
              <ion-row id="gwDetailsOtherRow" class="gwDetailsOtherRow {{ otherShown ? 'itemIsExpanded' : 'itemIsCollapsed' }} automationTesting" white-background me-cursor-pointer me-margin-top-adjust *ngIf="(otherUnits && otherUnits.length > 0)" (click)="toggleSection('other')">
                <ion-col id="gwDetailsOtherCol" class="gwDetailsOtherCol automationTesting" size="auto">
                  <div id="gwDetailsOtherIconContainer" class="gwDetailsOtherIconContainer ion-icon-wrapper-arrow-hide-show automationTesting" [title]="otherShown ? 'Collapse' : 'Expand'">
                    <ion-icon id="iIconGWDetailsToggleOther" class="iIconGWDetailsToggleOther {{ otherShown ? 'iconIsExpanded' : 'iconIsCollapsed' }} automationTesting" [name]="otherShown ? 'caret-down' : 'caret-forward'" me-color-black></ion-icon>
                  </div>
                </ion-col>
                <ion-col>
                  <ion-label id="lblOtherInfo" name="lblOtherInfo" class="sub-header">
                    <strong>&nbsp;Other Units</strong>
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-row white-background *ngIf="otherShown">
                <ion-col>
                  <ion-col class="FixedHeightContainer">
                    <div class="ODUContent" *ngIf="(otherUnits && otherUnits.length>0)">
                      <ion-card *ngFor="let o of otherUnits; last as isLast;" id="otherCard" name="otherCard"
                        [ngClass]="isLast ? 'me-ion-unit-card-last' : 'me-ion-unit-card'">
                        <ion-item [color]="o.isSelected ? 'selected' : ''" class="ion-no-padding">
                          <ion-label id="lblOtherName" name="lblOtherName" class="ion-padding-start">
                            {{ o.name ? o.name : o.bus_address }}
                          </ion-label>
                          <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                            (click)="showSelectedOtherUnit(o.id)"
                            [title]="o.isExpanded ? 'Collapse' : 'Expand'">
                            <ion-icon [name]="o.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"
                              me-cursor-pointer>
                            </ion-icon>
                          </div>
                        </ion-item>
                        <ion-card-content *ngIf="o.isExpanded" lines="none" style="max-height: 300px">
                          <div style="float:left; width:95%; margin-bottom:10px;">
                            <ion-grid class="ion-no-padding">
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Name:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOtherName" name="lblOtherName" ion-no-padding class="ion-no-padding">
                                    {{ o.name ? o.name : o.bus_address }}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background>
                                <ion-col size="6"><strong>Unit Type:</strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOtherTypeName" name="lblOtherTypeName" ion-no-padding
                                    class="ion-no-padding">
                                    {{ o.type_name ? o.type_name : o.type}}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                              <ion-row white-background *ngIf="o.type != 'LH' && o.type != 'MH'">
                                <ion-col size="6"><strong>M-NET Address: </strong></ion-col>
                                <ion-col size="6">
                                  <ion-label id="lblOutdoorBusAddressOfName" name="lblOutdoorBusAddressOfName"
                                    ion-no-padding class="ion-no-padding">
                                    {{o.bus_address ? o.bus_address : o.bus_address}}
                                  </ion-label>
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>

                          <div style="float:right; width:5%;">
                            <ion-col size="1">
                              <strong>
                                <div
                                  *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)"
                                  class="ion-icon-wrapper-raw-button" style="font-size: 22px"
                                  (click)="onEditUnit(o)" title="Edit Unit">
                                  <ion-icon name="create" me-cursor-pointer>
                                  </ion-icon>
                                </div>
                              </strong>
                            </ion-col>
                          </div>
                        </ion-card-content>
                      </ion-card>
                    </div>
                  </ion-col>
                </ion-col>
              </ion-row>

              <!-- End : Other Units Section-->

              <ion-row white-background>
                <ion-col>&nbsp;</ion-col>
                <ion-col>&nbsp;</ion-col>
              </ion-row>

              <!-- Start : New Map Units Section-->
              <div *ngIf="gatewayLoaded">
                <div id="mapUnitsButtonWithInformationContainer" class="buttonWithInformation buttonWithInformationFlipped">
                  <ion-row white-background
                    *ngIf="(appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.MapIndoorOutdoorUnits))">
                    <ion-col>
                      <div class="ion-text-center" *ngIf="(!outdoorUnits || outdoorUnits.length==0) && !mappingIsActive">
                        <p style="font-size: 16px;">
                          <i class="buttonInformationText" *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id != GSSTE.Suspended">
                            Click Map Units to discover<br>equipment connected to<br>your gateway.
                          </i>
                          <i class="buttonInformationText expiredLook" *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended">
                            Expired gateway(s) cannot Map Units
                          </i>
                        </p>
                      </div>
                    </ion-col>
                  </ion-row>
                  <div *ngIf="!mappingIsActive">
                    <ion-row white-background
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.MapIndoorOutdoorUnits)">
                      <ion-col me-center-grid-col-content>

                        <!-- Uses the transfer lock by default.  If disabled because expired gateway uses a custom message -->
                        <app-large-icon-button iconTitle="Map Units" iconName="compass" 
                          id="mapUnitsIconButton" class="mapUnitsIconButton automationTesting"
                          [iconLocked]="isActionRestricted() || selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended" 
                          [iconCustomHeader]="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended ? expiredAlertHeader : null"
                          [iconCustomMessage]="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended ? expiredAlertBody : null"
                          [iconMaintenanceType]="iconMaintenanceType"
                          (iconAction)="openMapUnits()"></app-large-icon-button>

                      </ion-col>
                    </ion-row>

                    <ion-row white-background
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.ConfigureUnitGroups)">
                      <ion-col me-center-grid-col-content>
                        <strong>
                          <ion-label [ngClass]="isActionRestricted() || selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended ? 'site-transfer-lower-opacity' : ''" id="lblConfigureGroupUnits" 
                          name="lblConfigureGroupUnits">Map Units</ion-label>
                        </strong>
                      </ion-col>
                    </ion-row>
                  </div>
                </div>

                <div *ngIf="mappingIsActive">
                  <ion-row>
                    <ion-col size="2">
                      <div>
                        <ion-spinner mapunits-spinner-icon name="lines"></ion-spinner>
                      </div>
                    </ion-col>
                    <ion-col class="ion-text-center">
                      <ion-label *ngIf="mappingStatus" italic>Mapping Units in Progress: {{mappingStatus}}</ion-label>
                      <ion-label *ngIf="!mappingStatus" italic>Mapping Units in Progress</ion-label>
                      <ion-progress-bar me-progress-bar [color]="barColor" value="{{mappingProgressBarValue}}"></ion-progress-bar>
                    </ion-col>
                  </ion-row>

                </div>
              </div>


              <!-- End : New Map Units Section-->


            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>

      <!-- Subscription Information-->
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card me-gateway-info-card-content>
          <ion-item lines="none" me-gateway-info-card-header>
            <ion-label class="ion-padding" id="lblSubscriptionInfo" name="lblSubscriptionInfo" style="padding-left:0;">
              <strong>Subscription Information</strong>
            </ion-label>
          </ion-item>
          <ion-card-content me-ion-card-content me-top-border>
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col><strong>Registration Date:</strong></ion-col>
                <ion-col>
                  <ion-label id="lblRegistrationDate" name="lblRegistrationDate">
                    {{ selectedGateway.created_at | date : 'MM/dd/yyyy' }}
                  </ion-label>
                </ion-col>
              </ion-row>
              <div *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id != GSSTE.Suspended">
                <ion-row>
                  <ion-col><strong>Subscription Plan:</strong></ion-col>
                  <ion-col>
                    <ion-label *ngIf="selectedGateway.activeSubscription?.subscriptions?.name" id="lblSubscriptionPlan" name="lblSubscriptionPlan">
                      {{ selectedGateway.activeSubscription?.subscriptions.name }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="selectedGateway.activeSubscription?.subscriptions.is_licensed && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.ViewGatewayDetailsSubData)">
                  <ion-col><strong>License Type:</strong></ion-col>
                  <ion-col>
                    <ion-label>{{selectedGateway.activeSubscription?.subscriptions?.coupontype.duration_years}}
                      Year(s)</ion-label>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.ViewGatewayDetailsSubData)">
                  <ion-col><strong>Billing Amount:</strong></ion-col>
                  <ion-col>
                    <div *ngIf="selectedGateway.activeSubscription?.subscriptions">
                        <ion-label id="lblSubscriptionCost">
                          <div *ngIf="!selectedGateway.activeSubscription?.subscriptions.is_licensed"
                              [style.text-decoration]="selectedGateway.has_coupon ? 'line-through': 'none'">
                              {{ selectedGateway.activeSubscription?.subscriptions.monthly_rate | currency }}/mo
                          </div>
                          <div *ngIf="selectedGateway.activeSubscription?.subscriptions.is_licensed">n/a</div>
                        </ion-label>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-grid *ngIf="selectedGateway.active_sub?.is_zero_rate" class="subscriptionPlanMessage limitedPlanMessage">
                  <ion-row *ngIf="selectedGateway.active_sub?.expiration_date">
                    <ion-col><strong>Limited Plan Exp.:</strong></ion-col>
                    <ion-col>{{ selectedGateway.active_sub?.expiration_date | date: 'MM/dd/yyyy' }}</ion-col>
                  </ion-row>
                  <ion-row class="planMessageRow">
                    <ion-col>
                      {{ LIMITED_GW_WARNING }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ion-row *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)
                         && selectedGateway.activeSubscription?.isPaid
                         && !selectedGateway.activeSubscription?.subscriptions.is_licensed">
                    <ion-col><strong>Billing Type:</strong></ion-col>
                    <ion-col *ngIf="selectedGateway.has_coupon">No Recurring Payment</ion-col>
                    <ion-col *ngIf="!selectedGateway.has_coupon">Recurring Payment</ion-col>
                </ion-row>
                <ion-row *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel) && selectedGateway.activeSubscription?.subscriptions.is_licensed">
                    <ion-col><strong>Billing Type:</strong></ion-col>
                    <ion-col>Prepaid</ion-col>
                </ion-row>
                <ion-row
                  *ngIf="selectedGateway.activeSubscription?.isPaid && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>Next Payment:</strong></ion-col>
                  <ion-col *ngIf="selectedGateway.has_coupon"><label>n/a</label></ion-col>
                  <ion-col *ngIf="!selectedGateway.has_coupon"><label
                      [style.text-decoration]="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired ? 'line-through': 'none'">{{
                      firstOfNextMonth() }}</label></ion-col>
                </ion-row>
                <ion-row *ngIf="selectedGateway.activeSubscription?.subscriptions.is_licensed
                      && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>Next Payment:</strong></ion-col>
                  <ion-col>See Detail Below*</ion-col>
                </ion-row>
                <ion-row
                  *ngIf="selectedGateway.activeSubscription?.isPaid && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>Payment Method:</strong></ion-col>
                  <ion-col *ngIf="!paymentMethod?.card_type || selectedGateway.has_coupon">n/a</ion-col>
                  <ion-col *ngIf="paymentMethod?.card_type && !selectedGateway.has_coupon">
                    {{ paymentMethod?.card_type | formatCardType}} ending in {{ paymentMethod?.last_four }}
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="selectedGateway.activeSubscription?.subscriptions.is_licensed
                      && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>Payment Method:</strong></ion-col>
                  <ion-col>{{selectedGateway.licenses[0]?.order_number}}</ion-col>
                </ion-row>
                <ion-row
                  *ngIf="selectedGateway.activeSubscription?.isPaid && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>Credit Card Exp.:</strong></ion-col>
                  <ion-col *ngIf="!paymentMethod?.expiration || selectedGateway.has_coupon">n/a</ion-col>
                  <ion-col *ngIf="paymentMethod?.expiration && !selectedGateway.has_coupon">
                    {{ paymentMethod?.expiration }}
                  </ion-col>
                </ion-row>
                <ion-row
                  *ngIf="selectedGateway.activeSubscription?.subscriptions.is_licensed && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col><strong>License Exp.:</strong></ion-col>
                  <ion-col>{{selectedGateway.activeSubscription?.licenseExpiration | date: 'MM/dd/yyyy'}}</ion-col>
                </ion-row>
                <ion-row class="subscriptionPlanMessage planMessageRow"
                  *ngIf="selectedGateway.activeSubscription?.licenseExpiration && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">
                  <ion-col class="limitedPlanMessage" >*{{LICENSED_GW_MESSAGE}}</ion-col>
                </ion-row>
                <div *ngIf="selectedGateway.has_coupon">
                  <ion-row>
                    <ion-col>&nbsp;</ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-label><i>Demo Gateway not subject to recurring monthly subscription cost.</i></ion-label>
                    </ion-col>
                  </ion-row>
                </div>

                <ion-row white-background *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired &&
                  permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)" class="color-red"
                  style="margin-top:1em;">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <strong>New Subscription Plan:</strong>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{ selectedGateway.subscription.name }}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background
                  *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <strong>New Billing Amount</strong>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{'0' | currency}}/mo
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background
                  *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <strong>Charged On:</strong>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{ selectedGateway.activeSubscription?.updated_at | date: 'MM/dd/yyyy'}}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background
                  *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <strong>Effective Date:</strong>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{ firstOfNextMonth() | date: 'MM/dd/yyyy' }}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background style="margin-top: 1em;"
                  *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Expired && permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                  class="color-red">
                  <ion-col class="me-grid-col">
                    <ion-label class="me-gw-label-text">
                      You will continue to have access to the Standard plan features until the beginning of the next
                      month.
                    </ion-label>
                  </ion-col>
                </ion-row>

                <br>
                <div *ngIf="gatewayLoaded">
                  <ion-row white-background>
                    <ion-col me-center-grid-col-content>
                      <app-large-icon-button *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                        id="manageGatewaySubscriptionPlansIconButtonFirst" class="manageGatewaySubscriptionPlansIconButton automationTesting"
                        iconTitle="Manage Gateway Subscription Plans" iconName="list-circle" (iconAction)="viewAccountGatewaySubscriptionPlans()"
                        [iconLocked]="user.active?.transfer_locked"
                        [routerLink]="gatewaySubPlansRouterLink" [queryParams]="{modify: selectedGateway.id}"></app-large-icon-button>
                    </ion-col>

                    <ion-col me-center-grid-col-content>
                      <app-large-icon-button *ngIf="permissionCheckCanEditPaymentMethod(user.activeSiteUserLevel)"
                        id="managePaymentMethodsIconButton" class="managePaymentMethodsIconButton automationTesting"
                        iconTitle="Manage Payment Methods" iconName="card" (iconAction)="viewAccountPaymentMethods()"
                        [iconLocked]="user.active?.transfer_locked"
                        [routerLink]="paymentMethodsRouterLink"></app-large-icon-button>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background>
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" id="lblManageGatewaySubscriptionPlan" name="lblManageGatewaySubscriptionPlan"
                          *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">Manage Gateway
                        </ion-label>
                      </strong>
                    </ion-col>
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" *ngIf="permissionCheckCanEditPaymentMethod(user.activeSiteUserLevel)"
                          id="lblManagePaymentMethods" name="lblManagePaymentMethods">Manage Payment</ion-label>
                      </strong>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background>
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                          id="lblManageGatewaySubscriptionPlan_Plans" name="lblManageGatewaySubscriptionPlan_Plans">
                          Subscription Plans
                        </ion-label>
                      </strong>
                    </ion-col>
                    <ion-col me-center-grid-col-content>
                      <strong>
                        <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" *ngIf="permissionCheckCanEditPaymentMethod(user.activeSiteUserLevel)"
                          id="lblManagePaymentMethods_Methods" name="lblManagePaymentMethods_Methods">
                          Methods
                        </ion-label>
                      </strong>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
              <div *ngIf="selectedGateway.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended">
                <ion-row>
                  <ion-col><strong>Subscription Plan:</strong></ion-col>
                  <ion-col>
                    <ion-label id="lblSubscriptionPlan" name="lblSubscriptionPlan" class="me-expired-limited-plan">Expired</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>&nbsp;</ion-row>
                <ion-row white-background>
                  <ion-col me-center-grid-col-content>
                    <app-large-icon-button *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                      id="manageGatewaySubscriptionPlansIconButtonSecond" class="manageGatewaySubscriptionPlansIconButton automationTesting"
                      iconTitle="Manage Gateway Subscription Plans" iconName="list-circle" (iconAction)="viewAccountGatewaySubscriptionPlans()"
                      [iconLocked]="user.active?.transfer_locked"
                      [routerLink]="gatewaySubPlansRouterLink" [queryParams]="{upgrade: selectedGateway.id}"></app-large-icon-button>
                  </ion-col>
                </ion-row>
                <ion-row white-background>
                  <ion-col me-center-grid-col-content>
                    <strong>
                      <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" id="lblManageGatewaySubscriptionPlan" name="lblManageGatewaySubscriptionPlan"
                        *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)">Manage Gateway
                      </ion-label>
                    </strong>
                  </ion-col>
                </ion-row>
                <ion-row white-background>
                  <ion-col me-center-grid-col-content>
                    <strong>
                      <ion-label [ngClass]="user.active?.transfer_locked ? 'site-transfer-lower-opacity' : ''" *ngIf="permissionCheckCanEditGatewaySubscription(user.activeSiteUserLevel)"
                        id="lblManageGatewaySubscriptionPlan_Plans" name="lblManageGatewaySubscriptionPlan_Plans">
                        Subscription Plans
                      </ion-label>
                    </strong>
                  </ion-col>
                </ion-row>
              </div>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>