import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SiteService } from 'src/app/features/sites/services/site.service';

@Component({
  selector: 'app-map-units',
  templateUrl: './map-units.component.html',
  styleUrls: ['./map-units.component.scss'],
})
export class MapUnitsComponent {

  constructor(private siteService: SiteService, private modalController: ModalController) { }

  // ngOnInit() { }

  map_units() {

    if (!this.siteService.handleIsConnected()) return;


    this.modalController.dismiss({
      map_units: true,
    })

  }

  close() {
    this.modalController.dismiss();

  }

}
