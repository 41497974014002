import { MaskitoOptions, maskitoUpdateElement } from '@maskito/core';
import { maskitoEventHandler, maskitoWithPlaceholder } from '@maskito/kit';
import { zipCodeExtensionMaskPlaceholder, zipCodeMaskExtended, zipCodeOrSerialNumberMaskShortened, zipOrSerialShortMaskPlaceholder } from 'src/app/constants/validators';

let useExtension = true;
let shortPlaceholder = zipOrSerialShortMaskPlaceholder;
let extension = zipCodeExtensionMaskPlaceholder;
let extendedPlaceholder = shortPlaceholder + extension;

let shortMask = zipCodeOrSerialNumberMaskShortened;
let extendedMask = zipCodeMaskExtended;

let placeholderToShow = useExtension ? extendedPlaceholder : shortPlaceholder;
let maskToUse = useExtension ? extendedMask : shortMask;

const {
  plugins,
  removePlaceholder,
  ...placeholderOptions
} = maskitoWithPlaceholder(placeholderToShow);

export const zipCodeMaskitoMask = {
  mask: maskToUse,
  preprocessors: placeholderOptions.preprocessors,
  postprocessors: placeholderOptions.postprocessors,
  plugins: [
    ...plugins,

    maskitoEventHandler(`focus`, element => {
      const initialValue = element?.value;
      maskitoUpdateElement(element, initialValue + placeholderToShow.slice(initialValue.length));
    }),

    maskitoEventHandler(`blur`, element => {
      const cleanValue = removePlaceholder(element?.value);
      maskitoUpdateElement(element, cleanValue);
    }),
  ],
} as MaskitoOptions;