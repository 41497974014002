<ion-content id="siteSchedule" class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1 id="header" name="header">Site Schedule</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <!-- Empty For Now -->
        </ion-col>
      </ion-row>  
      <ion-row>
        <ion-title class="stateMessage">
          <div class="loadingState" color="black" *ngIf="siteGatewaysLoading && !siteGatewaysLoadError">
            <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i></ion-label>
            <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
          </div>
          <div *ngIf="siteGatewaysLoadError">
            <ion-label><i>Unable to manage indoor group schedules at this time - please try again later.</i></ion-label>
          </div>
          <div *ngIf="!siteGatewaysLoading">
            <ion-label id="zeroStateMessage1" name="zeroStateMessage1" color="medium" *ngIf="!siteGatewaysExist"><i>There
                are no gateways currently registered to this site.</i></ion-label>
            <ion-label id="zeroStateMessage2" name="zeroStateMessage2" color="medium"
              *ngIf="siteGatewaysExist && !siteGatewayUnitsExist"><i>Configure your gateway(s) outdoor and indoor units to
                schedule events.</i></ion-label>
          </div>
        </ion-title>
      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <ion-grid id="ScheduleGrid">
    <ion-row id="groupSelector">
      <ion-col size="12" style="padding-bottom: 0px">

        <!-- Batch Scheduling Group Selector -->
        <app-group-selector [title]="batchScheduling ? 'Indoor Group(s) Schedule' : 'Indoor Group Schedule'"
          (newGroupsSelected)="batchScheduling ? scheduleForGroups($event) : null"
          (newGroupSelected)="batchScheduling ? null : scheduleForGroup($event)" 
          (stateMessage)="zeroStateMessage($event)" [multiSelect]="batchScheduling" 
          [checkBoxFilters]="true" #groupSelector>
        </app-group-selector>
      </ion-col>

      <!-- Custom Right Click Context Menu, Dev Only, Not being used yet -->
      <!-- <div *ngIf="batchScheduling" class="custom-context-menu {{ showContextMenu ? 'showContextMenu' : 'hideContextMenu' }}"
      [ngClass]="{'show': showContextMenu}" [style.left.px]="contextMenuX" [style.top.px]="contextMenuY">
        <ul>
          <li (click)="createNewGroup()" class="menu-item">
            <ion-icon name="add-circle"></ion-icon> Create New Group from <ion-badge class="contextMenuBadge"><span
                id="controlBadge" [class]="onMac ? 'macBadge' : 'winBadge'">{{ selectedGroups?.length }}</span></ion-badge>
            Selected Group(s)
          </li>
          <li (click)="addGroup()" class="menu-item">
            <ion-icon name="folder"></ion-icon> Add <ion-badge class="contextMenuBadge"><span id="controlBadge"
                [class]="onMac ? 'macBadge' : 'winBadge'">{{ selectedGroups?.length }}</span></ion-badge> Selected Group(s) to
            Group
          </li>
          <li (click)="scheduleGroup()" class="menu-item">
            <ion-icon name="time"></ion-icon> Schedule <ion-badge class="contextMenuBadge"><span id="controlBadge"
                [class]="onMac ? 'macBadge' : 'winBadge'">{{ selectedGroups?.length }}</span></ion-badge> Selected Group(s)
          </li>
          <li (click)="exitMenu()" class="menu-item">
            <ion-icon name="close"></ion-icon> Close
          </li>
        </ul>
      </div> -->

      <ion-col size="12" style="padding-top: 0px" *ngIf="(!selectedGatewayUnit || !selectedGatewayUnit?.id) && !displayDataNotAvailable">
        <ion-card id="cardNoSelectedUnit" name="cardNoSelectedUnit" *ngIf="(selectedGatewayUnit || !selectedGatewayUnit?.id) && displayDataNotAvailable" class="ion-no-margin" me-ion-card>
          <ion-card-content>
            <div class="center">
              <p me-warning-message>
                Unable to schedule at this time, please try again later.
              </p>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="haveAnyUnitsInMaintenance()">
      <ion-col class="unitsInTestRunMessage" title="{{ maintenanceJobMessage }} Progress">
        {{ maintenanceJobMessage }} <span title="To Maintenance History" class="useBlue linkLike" *ngIf="hasPermissionForSiteAnalytics()" (click)="activityHistory()">View progress</span><span *ngIf="hasPermissionForSiteAnalytics()">.</span>
      </ion-col>
    </ion-row>     
    <!-- Site Scheduling -->
    <ion-row id="siteCalendarRow" class="siteCalendarRow" *ngIf="selectedGatewayUnit && selectedGatewayUnit.id">
      <ion-col style="padding-top: 0px">
        <ion-card id="siteScheduling" name="siteScheduling" class="ion-no-margin" me-ion-card>
          <!-- <ion-toolbar class="controlSched siteSchedToolbar">
            <ion-row class="schedulingTitleRow">
              <ion-col id="formTitleCol" class="formTitleCol">
              </ion-col>
            </ion-row>
          </ion-toolbar> -->
          <ion-card-content>
            <div *ngIf="isExpiredGatewaySelected()" me-expired-form >
              <!-- this is a copy of the label logic from the standard schedule page-->
              <ion-label>
                <h1 *ngIf="bgd.singleGroup" id="schedulingLabel" name="schedulingLabel" class="schedulingLabel rowCol">
                  <ion-icon _ngcontent-kbd-c1="" me-group-unit-icon="" [name]="selectedSiteGatewayGroup &&
                    !selectedSiteGatewayGroup.autocreated ? 'copy' : 'stop'" ng-reflect-name="stop"
                    class="ng-star-inserted md hydrated" role="img" aria-label="stop"></ion-icon>
                  <span class="labelText">{{
                    selectedSiteGatewayGroup.group_name
                    ? selectedSiteGatewayGroup.group_name
                    : selectedGatewayUnit.bus_address
                    }}</span>
                </h1>
                <h1 *ngIf="!bgd.singleGroup" id="schedulingLabel" name="schedulingLabel" class="schedulingLabel rowCol">
                  <ion-icon name="stop" me-group-batch-icon></ion-icon>
                  <h1>.....</h1>
                  <ion-icon name="stop" me-group-unit-icon></ion-icon>
                  <h1 class="batchSchedulingLabel">Batch Schedule <i>({{ selectedGroups.length }})</i></h1>
                </h1>
              </ion-label>
              <!-- end copy of label logic from standard schedule page-->
              <div me-expired-spacer></div>
              <ion-label me-expired-label>One or more selected units have an expired gateway subscription. Select non expired units to view the schedule<ion-label *ngIf="isSiteOwner()">, or <ion-label (click)="upgradeExpiredSubscription()" me-expired-as-link>upgrade your subscription</ion-label></ion-label>.</ion-label>
            </div>
            <ion-grid *ngIf="!isExpiredGatewaySelected()" class="schedulingGridContainer">
              <ion-row>
                <ion-col class="me-controls-col" size-xs="11" size-sm="11" size-md="11" size-lg="12" size-xl="12">
                  <ion-label class="schedulingRow">

                    <h1 *ngIf="bgd.singleGroup" id="schedulingLabel" name="schedulingLabel" class="schedulingLabel innerLeftColumn rowCol">
                      <ion-icon _ngcontent-kbd-c1="" me-group-unit-icon="" [name]="selectedSiteGatewayGroup &&
                        !selectedSiteGatewayGroup.autocreated ? 'copy' : 'stop'" ng-reflect-name="stop"
                        class="ng-star-inserted md hydrated" role="img" aria-label="stop"></ion-icon>
                      <span class="labelText">{{
                        selectedSiteGatewayGroup.group_name
                        ? selectedSiteGatewayGroup.group_name
                        : selectedGatewayUnit.bus_address
                        }}</span>
                    </h1>

                    <h1 *ngIf="!bgd.singleGroup" id="schedulingLabel" name="schedulingLabel" class="schedulingLabel innerLeftColumn rowCol">
                      <ion-icon name="stop" me-group-batch-icon></ion-icon>
                      <h1>.....</h1>
                      <ion-icon name="stop" me-group-unit-icon></ion-icon>
                      <h1 class="batchSchedulingLabel">Batch Schedule <i>({{ selectedGroups.length }})</i></h1>
                    </h1>

                    <h1 id="schedulingText" class="rowCol innerMiddleColumn middleColumnLabel">{{ mainSiteUIService.schedulingCalendarWeeklyView == true ? 'Weekly' : 'Monthly' }} Schedule</h1>

                    <div class="mobileSchedulingDynamicTimer schedulingAdditionalInformation schedulingCol col-12">
                      <span class="colMsg innerMiddleColumn middleColMsg">
                        <h3 title="Current Site Time" class="currentSiteTimeContainer">
                          <span class="siteTime" [value]="siteTime">{{ siteTime }}</span>
                        </h3>
                      </span>
                    </div>

                    <div id="badgeLocation" class="innerRightColumn spacer rowCol">
                      <h3 *ngIf="!recurringAndSingleEvents" class="eventsBadge recurringSchedEventsNum">
                        <ion-badge><span id="controlBadge"
                            [class]="onMac ? 'macBadge' : 'winBadge'">{{ events.length }}</span></ion-badge>
                        Event(s){{ bgd.singleGroup || showAllEventsForSite == true ? '' : ' for Current Selection' }}
                      </h3>
                      <ion-row *ngIf="devEnv || (recurringAndSingleEvents && user?.activeSiteUserLevel === levelEnum.Owner)"
                        class="schedulingToggles" id="schedulingToggles">
                        <ion-col class="calToggle schedulingToggle">
                          <ion-label>365 Days / 7 Days</ion-label>
                          <ion-toggle id="is_recurring_toggle" [(ngModel)]="is_recurring"
                            (ionChange)="dayModeChanged($event)"></ion-toggle>
                        </ion-col>
                      </ion-row>
                    </div>
                  </ion-label>
                  <!-- Calendar Component -->
                  <app-site-scheduling-calendar [selectedSiteGatewayGroup]="selectedSiteGatewayGroup"
                    [presentSubscriptionOptions]="presentSubscriptionOptions"
                    [getGroupFanSpeedDescription]="getGroupFanSpeedDescription"
                    [selectedGatewayUnit]="selectedGatewayUnit" [selectedGateway]="selectedGateway"
                    [gatewayUnits]="gatewayUnits" [is_recurring]="is_recurring" [eventAdmin]="eventAdmin"
                    [recurringSched]="recurringSched" [onMac]="onMac" [events]="events"
                    (onAddEvent)="eventAdded($event)" (onDeleteEvent)="eventDeleted($event)"
                    (onChangeEvent)="eventChanged($event)" [recurringAndSingleEvents]="recurringAndSingleEvents" 
                    [colors]="colors" [getEventColor]="getEventColor" [actions]="actions"
                    [dataCity]="dataCity" [dataTime]="dataTime" [databaseEvents]="databaseEvents" [unfilteredEvents]="unfilteredEvents"
                    [selectedGroups]="selectedGroups" [batchScheduling]="batchScheduling" [allSiteGroups]="mainSiteUIService.allSiteGroups" 
                    [scheduleForGroups]="scheduleForGroups" [setWeekTime]="setWeekTime" [refreshGroupEvents]="refreshGroupEvents" 
                    [getEventsForSelection]="getEventsForSelection" [backEndToEvents]="backEndToEvents" 
                    [createFrontEndEventsFromBackEndEvents]="createFrontEndEventsFromBackEndEvents" 
                    [filteredSiteGroups]="mainSiteUIService.filteredSiteGroups" [lastSelectedByOrder]="groupSelector.LastSelectedByOrder" [hasAnyDisabledEvents]="hasAnyDisabledEvents" [groupsOfEvents]="groupsOfEvents" [groupSelector]="groupSelector" [consolidateBatchEvents]="consolidateBatchEvents" [mapEventsFromBackEndToFrontEnd]="mapEventsFromBackEndToFrontEnd" [hasAnyMultiDayEvents]="hasAnyMultiDayEvents" [allSiteDatabaseEvents]="allSiteDatabaseEvents" [allSiteEvents]="allSiteEvents">
                  </app-site-scheduling-calendar>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- End Site Scheduling -->

  </ion-grid>
</ion-content>