<ion-content main-background class="ion-text-center">
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>

  <div class="center" style="padding-top: 100px;">
    <ion-grid>
      <ion-row message-row *ngIf="app_message_type==appMessageTypeCode.INVALID_INVITE">
        <ion-col>
          <ion-grid class="ion-margin-top ion-text-center" me-login-banner-center-content white-background
            [fixed]="true">
            <span inline me-message-span>Your link to join this site is no longer valid.<br>Contact the site inviter in
              your email for more information.</span>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>