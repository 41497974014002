import { GatewaySubscription } from '../../manage/components/classes/GatewaySubscription';
import { GatewayUnit } from '../../manage/components/classes/GatewayUnit';
import { GatewayGroup } from '../../manage/components/classes/GatewayGroup';
import { Gateway } from '../../manage/components/classes/Gateway';

export class SiteGatewayDetailData {
    gateway_subscription: GatewaySubscription[] = [];
    payment_method: any = null;
    gateway: Gateway;
    units: GatewayUnit[] = [];
    groups: GatewayGroup[] = [];

}