import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProgressService } from 'src/app/features/testdrive/services/progress.service';


@Component({
  selector: 'testdrive-cancel-notification',
  templateUrl: './testdrive-cancel-notification.component.html',
  styleUrls: ['./testdrive-cancel-notification.component.scss'],
})
export class TestDriveCancelNotificationComponent implements OnInit {

  progressServiceSubscription: Subscription = null;

  public constructor(
    private modalController: ModalController,
    private progressService: ProgressService
    ) { 
      this.progressServiceSubscription  = this.progressService.progressObservable$.subscribe(
        (param: number) => {
          if (param == 7 || param == 8 || param == 9){
            this.close(false)
          }
        }
      );
  }
  ngOnInit() {
    this.doNothing()
  }

  close(isCheckStart: boolean) {
    // 状態通知のsubscribeを止める
    // Stop subscribing to status notifications
    if(this.progressServiceSubscription){
      console.log('notice modal unsubscribe');
      this.progressServiceSubscription.unsubscribe();
    }
    // モーダルを閉じる
    // Close modal
    this.modalController.dismiss(
      {
        "isCheckStart": isCheckStart
      }
    );
  }

  doNothing(){
    return;
  }
}
