import { Injectable } from '@angular/core';
import { Version as running_version } from 'src/app/constants/version';
import { DisplayVersionBox } from 'src/app/constants/webservice';
import { devEnv } from 'src/app/constants/kenzaconstants';

export enum Features {
  // list of features being switched
  MaintenanceDataDownload,
  ShowLoginVersionBox
}

@Injectable({
  providedIn: 'root'
})

export class FeatureService {

  //constructor() { }

  enabled(feature: Features): boolean {
    // is this feature enabled?

    // yes dev gets it all.
    if (devEnv) return true;

    if (feature === Features.MaintenanceDataDownload) {
      // not till keanza client version >= 1.4
      return versionGreaterThan('1.4');
    } else if ( feature === Features.ShowLoginVersionBox) {
      // if the webservice tells us to
      return DisplayVersionBox;
    }

    // anthing else is a no.
    return false;
  }
}

export function isProductionEnvironment(): boolean {
  // are we running in the production environment?
  
  // make use of the option to display the version box - that is not done in production
  return !DisplayVersionBox;
}

function versionGreaterThan(version: string): boolean {
  // assume given a x.y[.z] version string - parse it and only return
  //if the current version of the code is >= this version.

  const current_version = parseVersion(running_version);
  const required_version = parseVersion(version);

  // if higher major version - we're good.
  if (current_version.major > required_version.major) return true;
  // if lower major version - we're bad.
  if (current_version.major < required_version.major) return false;

  // so major version is the same, consider minor
  // if minor version is higher - then we're good.
  if (current_version.minor > required_version.minor) return true;
  // if minor version is lower - then we're bad
  if (current_version.minor < required_version.minor) return false;

  // minor is the same - consider patch.
  // if patch is higher - then we're good.
  if (current_version.patch > required_version.patch) return true;
  // if patch is lower - its a no.
  if (current_version.patch < required_version.patch) return false;

  // then its down to build.
  if (current_version.build > required_version.build) return true;

  // otherwise no.
  return false;
}

function parseVersion(version: string): any {
  // split this string on a . and expect a major.minor.patch.build
  const t = version.split('.');
  const cur_maj = parseInt(t[0]) || 0;
  const cur_min = parseInt(t[1]) || 0;
  const cur_pat = parseInt(t[2]) || 0;
  const cur_bld = parseInt(t[3]) || 0;

  return {
    major: cur_maj,
    minor: cur_min,
    patch: cur_pat,
    build: cur_bld
  }
}