<ion-content class="ion-no-padding" main-white-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="white-background">
    <ion-title color="dark">
      <h1>Analytics Report History<ion-badge *ngIf="!reportsLoading && !noReports" class="reportsBadge">{{filteredReports.length}}</ion-badge></h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="backToSiteAnalytics()" me-button-as-link
        id="btnBackToAccountDetail" name="btnBackToAccountDetail">
        &#8592;&nbsp;Back to Site Analytics </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div *ngIf="reportsLoading" class="reportsLoadingSpinner">
    <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i>
    </ion-label>
    <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
  </div>
  
  <ion-row *ngIf="!reportsLoading && noReports">
    <ion-col class="ion-no-padding">
      <ion-label id="noReportsMessage" color="medium" style="margin-left: 20px;"><i>No reports in the last 30 days</i></ion-label>
    </ion-col>
  </ion-row>
  
  <ion-row class="reportSearchBarRow">
    <ion-searchbar *ngIf="!reportsLoading && !noReports" #reportSearchBar
      (ionInput)="searchFilterReports($event)" placeholder="Search..." class="me-search-bar">
    </ion-searchbar>
  </ion-row>
  
  <ion-grid *ngIf="!reportsLoading && !noReports" class="customDataTable reportHistoryGrid reportHistoryTable me-grid-main" #reportHistoryGrid>

    <ion-row class="reportHistoryRow tableHeaderRow">

      <ion-col class="reportHistoryColumn reportTypeCol">
        <ion-icon name="document-text-outline"></ion-icon> Report Type
      </ion-col>

      <ion-col class="reportHistoryColumn siteNameCol">
        <ion-icon name="business-outline"></ion-icon> Site Name
      </ion-col>

      <ion-col class="reportHistoryColumn columnTitleOffCenter columnTitleSlightlyOffCenter">
        <ion-icon name="calendar-outline"></ion-icon> Start Time
      </ion-col>

      <ion-col class="reportHistoryColumn columnTitleOffCenter">
        <ion-icon name="cube"></ion-icon> ODU Name & <br> Model Name
      </ion-col>

      <!-- <ion-col class="reportHistoryColumn">
        <ion-icon name="git-network"></ion-icon> Model Name
      </ion-col> -->

      <ion-col class="reportHistoryColumn iconCol columnTitleOffCenter">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon> Status
      </ion-col>

      <ion-col class="reportHistoryColumn iconCol columnTitleOffCenter">
        <ion-icon name="construct-outline"></ion-icon> Actions
      </ion-col>

    </ion-row>
    
    <ion-grid class="customDataTableScrollContainer reportHistoryScrollContainer" #reportHistoryScrollContainer>
      <ion-row *ngFor="let report of filteredReports; let i = index; trackBy:trackByReportId" class="reportHistoryRow tableEntryRow">

        <ion-col *ngIf="report.job_id" class="reportHistoryColumn reportTypeColumn oflow" [title]="renderReportTypeString(report)">
          <span class="reportTypeName">
            {{ renderReportTypeString(report) }}
          </span>
        </ion-col>

        <ion-col *ngIf="report.linkEnabled" class="reportHistoryColumn linkLike siteNameCol oflow" tappable (click)="navigateToSite( report.siteId )" title="{{ report.title }}">
          <span class="siteName">
            {{ report.siteName }}
          </span>
        </ion-col>

        <ion-col *ngIf="!report.linkEnabled" class="reportHistoryColumn siteNameCol oflow" title="{{ report.title }}">
          <span class="siteName">
            {{ report.siteName }}
          </span>
        </ion-col>

        <ion-col [ngClass]="{ 'reportDisabledField': report.startDisabled }" title="{{ report.startTitle }}">
          {{ report.startString }}
        </ion-col>

        <ion-col class="reportHistoryColumn">
          <div class="oflow" title="{{ report.oduName }} & {{ report.oduModelTitle }}">
            {{ report.oduName }} <br> 
            <div class="{{ report.oduModelDisabled ? 'reportDisabledField' : '' }}">
              {{ report.oduModel }}
            </div>
          </div>
        </ion-col>

        <!-- <ion-col [ngClass]="{ 'reportDisabledField': report.oduModelDisabled }">
          <div class="oflow" title="{{ report.oduModelTitle }}">
            {{ report.oduModel }}
          </div>
        </ion-col> -->

        <ion-col *ngIf="report.status != reportStatusEnum.InProcess" class="reportHistoryColumn">
          <ion-button disabled="true" strong="true" class="reportStatus">
            {{ report.statusString }}
          </ion-button>
        </ion-col>

        <ion-col *ngIf="report.status == reportStatusEnum.InProcess">
          <button disabled="true" strong="true" title="{{ report.progressTitle }}" class="reportHistoryProgressColumn">
            {{ report.statusString }}<br>
            <ion-progress-bar color="success" class="reportHistoryProgresBar" value="{{ report.progress }}"></ion-progress-bar>
          </button>
        </ion-col>      

        <ion-col class="reportHistryColumn linkLike">
          <div *ngIf="report.viewable" Title="View Job Details" (click)="actionView(report)" tappable>
            <ion-icon style="color: var(--ion-color-dark)" name="eye"></ion-icon>
          </div>
          <div *ngIf="report.cancelable" Title="Cancel Job" (click)="actionCancel(report)" tappable>
            <ion-icon color="danger" style="color: var(--ion-color-dark)" name="close-circle"></ion-icon>
          </div>
          <div *ngIf="report.csvdownloadable" Title="Download CSV Report" (click)="actionCsvdownload(report)" tappable>
            <img class="csvDownload" alt="CSV Download" src="/assets/icon/file-csv-solid.svg">
          </div>
          <div *ngIf="report.mtdzdownloadable" Title="Download MTDZ file" (click)="actionMtdzDownload(report.id)" tappable>
            <ion-icon style="color: black" name="download"></ion-icon>
          </div>
          <div *ngIf="report.deleteable" Title="Delete Job" (click)="actionDelete(report.id)" tappable>
            <ion-icon color="danger" style="color: black" name="trash"></ion-icon>
          </div>                        
        </ion-col>

        <!-- <ion-col class="reportHistoryColumn inkIconSize iconCol" tappable (click)="viewInvoice(invoice.id, invoice.site_id)" title="View Invoice">
          <ion-icon  name="eye" class="tableButton"></ion-icon>
        </ion-col>

        <ion-col class="paymentHistoryColumn linkIconSize iconCol" tappable (click)="downloadInvoice(invoice.id, invoice.site_id)" title="Download Invoice">
          <ion-icon name="cloud-download" class="tableButton"></ion-icon>
        </ion-col> -->

      </ion-row>

      <ion-row *ngIf="noResultsFound" id="noResultsFound" class="reportHistoryRow tableEntryRow noResultsFound">
        <ion-col id="noResults" class="reportHistoryColumn">Your search with "{{ searchTerm }}" returned no results. Please try again.</ion-col>
      </ion-row>

    </ion-grid>

  </ion-grid>

  <ion-row *ngIf="!reportsLoading && !noReports" class="tableLabelRow">
    <ion-col>
      Reports will be available to view and download for 30 days after they are generated
    </ion-col>
  </ion-row>

  <ion-row *ngIf="moreReportsLoading">
    <ion-col class="reportsLoadingCol">
      <ion-spinner color="primary" me-spinner-icon name="lines"></ion-spinner>
      <ion-label class="reportsLoadingMessage">Loading more reports...</ion-label>
    </ion-col>
  </ion-row>

</ion-content>