import { forkJoin } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { PlanRowTypeEnum, GatewayModelClass } from 'src/app/enumerations/enums';
import { SubscriptionPlanDetail } from 'src/app/features/manage/components/classes/SubscriptionPlanDetail';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';

@Component({
  selector: 'app-site-gateway-plans',
  templateUrl: './site-gateway-plans.component.html',
  styleUrls: [
    './site-gateway-plans.component.scss',
    '../site-gateway-register/site-gateway-register.page.scss',
    '../../../account/components/account-gateway-change-subscription-plan/account-gateway-change-subscription-plan.page.scss'
  ],
})

export class SiteGatewayPlansComponent implements OnInit {
  isStandalone = false;
  planRowTypeEnum: any;
  // master details of all subscriptions
  fullDetails: any;
  // details filtered to the current model class to display
  details: any;
  // list of model class to display
  modelClassNames: any;
  // current model being displayed
  classNameToDisplay: string;
  // currently being displayed values
  priceHeaderRow: any;
  // plans
  plans: any = [];
  alertOpen = false;

  @Input() title: string;
  @Input() initialModelClassNameToDisplay: string;

  constructor(
    protected modalController: ModalController,
    protected planService: SubscriptionPlanService,
    protected alertController: AlertController
  ) {
    this.planRowTypeEnum = PlanRowTypeEnum;
  }

  ngOnInit(): void {

    forkJoin({
      gwModel: this.planService.getGatewayModelsAsObservable(),
      planDetails: this.planService.getPlanDetailsAsObservable(),
      plansArray: this.planService.getPlansAsObservable()
    }).subscribe(res => {
      let gatewayModelsResult = res.gwModel;
      this.fullDetails = res.planDetails;
      this.fullDetails = this.fullDetails.filter(obj => !obj.is_licensed)
      this.plans = res.plansArray;
      this.plans = this.plans.filter(obj => !obj.is_licensed);
      let gatewayModels = gatewayModelsResult.sort((n1, n2) => {
        if (n1.id > n2.id) return 1;
        if (n1.id < n2.id) return -1;
        return 0
      });
      this.modelClassNames = [... new Set(gatewayModels.map(model => model.class_name))];

      this.selectClassToDisplay(this.initialModelClassNameToDisplay ? this.initialModelClassNameToDisplay : GatewayModelClass.MCC);
    })
  }

  selectClassToDisplay(className: string) {
    // set the class_name to display the values for
    this.classNameToDisplay = className;

    this.priceHeaderRow = [];

    this.plans.forEach(element => {
      if (element.gateway_model_class_name == className) {
        this.priceHeaderRow.push({
          'subscription_name': element.name,
          'subscription_monthly_rate': element.monthly_rate,
          'subscription_content': element.name == `Limited` ? `No cost option allows for easy contractor setup of your system` : `Advanced control and monitoring with system scheduling, maintenance data, and more.`,
        })
      }

      // get list of plans for the selected class name
      let planList: SubscriptionPlanDetail[] = [];
      this.fullDetails.forEach((element: SubscriptionPlanDetail) => {
        if (element.subscription_gateway_model_class_name == className) {
          // then this is a plan we want to include
          planList.push(element);
        }
      });

      // now do the work based on planList - not fullDetails
      const planNameList = [...new Set(planList.map((plan: SubscriptionPlanDetail) => plan.subscription_name))];
      let planTable: SubscriptionPlanDetail[] = [];
      planNameList.forEach(function (planName: string, key) {
        let planRow: SubscriptionPlanDetail = planList.filter(spd => spd.subscription_name.toUpperCase() === planName.toUpperCase())[0];

        let hasPlans = planTable.length > 0 ? true : false;
        let planDetails: SubscriptionPlanDetail[] = [];
        //get plan rows
        let planRows: SubscriptionPlanDetail[] = [];
        planRows = planList.filter(spd => spd.subscription_name.toUpperCase() === planName.toUpperCase());
        planRows.forEach(function (spd, key) {

          if (planDetails.filter(item => item.feature_category_name === spd.feature_category_name).length == 0) {
            let spd_header: SubscriptionPlanDetail = { ...spd };
            spd_header.PlanRowType = PlanRowTypeEnum.FeatureCategory;
            spd_header.feature_category_name = spd.feature_category_name;
            spd_header.feature_detail_description = '';
            spd_header.feature_detail_description_plan_1 = '';
            planDetails.push(spd_header);
          }
          spd.PlanRowType = PlanRowTypeEnum.FeatureDetail;

          planDetails.push(spd);
        });

        if (hasPlans == false) {
          planTable = [...planDetails];
        } else {
          for (let rowIndex = 0; rowIndex < planDetails.length; ++rowIndex) {
            if (planTable[rowIndex]?.PlanRowType === PlanRowTypeEnum.FeatureCategory) {
              continue;
            }

            if (key === 1) {
              planTable[rowIndex].feature_detail_description_plan_1 = planDetails[rowIndex].feature_detail_description;              
            }
          }
        }
      });
      this.details = planTable;
    })
  }

  close() {
    this.modalController.dismiss({
    });
  }

  getPlanService() {
    return this.planService;
  }

  async displayInformation(headerText: string, infoBody: any) {
    if (this.alertOpen == true) return;
    const alert = await this.alertController.create({
      header: headerText,
      message: `<ion-label>${infoBody[0].description}</ion-label><img src="${infoBody[0].image}" class="me-info-image-padding">`,
      cssClass: `me-info-button-css`,
      buttons: [
        {
          text: `Ok`,
          cssClass: `ok-button`,

          handler: () => this.alertController.dismiss().then(data => {
            this.alertOpen = false;
          })
        }
      ]
    });
    this.alertOpen = true;
    await alert.present();
    alert.onDidDismiss().then(()=> this.alertOpen = false);
  }
  
  async displayPlanInformation(plan?: any) {
    if (this.alertOpen == true) return;
    const alert = await this.alertController.create({
      header: `Limited Plan`,
      backdropDismiss: false,
      cssClass: `me-info-button-css`,
      message: `The Limited subscription plan allows for a convenient way to set up and configure your HVAC system. <br><br> Note: If the gateway remains on this subscription plan for 3 consecutive months, services will be halted.`,
      buttons: [
        {
          text: `Ok`,
          cssClass: `ok-button`,

          handler: () => this.alertController.dismiss().then(data => {
            this.alertOpen = false;
          })
        }
      ]
    });
    this.alertOpen = true;
    await alert.present();
  }
}