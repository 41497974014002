// these mock units exist in the dev firmware server
// and should be suitable for registration in the dev env.
export const RMD_MOCK_UNITS = [
    ['7IB19701W00015','CC:CC:CC:00:00:53','2KVQE8TBU'],
    ['7IB19701W00015','CC:CC:CC:00:00:42','ML7CV4E71'],
    ['7IB19701W00015','CC:CC:CC:00:00:43','MVUCEBH2A'],
    ['7IB19701W00015','08:00:70:33:40:15','6TXDQNYPZ'],
    ['7IB19701W00015','CC:CC:CC:00:00:01','F2P3BT8V1'],
    ['7IB19701W00015','CC:CC:CC:00:00:02','AE5PD31DK'],
    ['7IB19701W00015','CC:CC:CC:00:00:03','QLFD976CR'],
    ['7IB19701W00015','CC:CC:CC:00:00:04','FLKHMNZNV'],
    ['7IB19701W00015','CC:CC:CC:00:00:05','KVQHM2WME'],
    ['7IB19701W00015','CC:CC:CC:00:00:06','EFBQQ5BFA'],
    ['7IB19701W00015','CC:CC:CC:00:00:07','BQHURCXGW'],
    ['7IB19701W00015','CC:CC:CC:00:00:08','1K56A7H23'],
    ['7IB19701W00015','CC:CC:CC:00:00:09','45QPXVM8R'],
    ['7IB19701W00015','CC:CC:CC:00:00:10','QQN123M8W'],
    ['7IB19701W00015','CC:CC:CC:00:00:11','G35GJRX44'],
    ['7IB19701W00015','CC:CC:CC:00:00:12','MF6BWVEVY'],
    ['7IB19701W00015','CC:CC:CC:00:00:13','5UV74NZ2K'],
    ['7IB19701W00015','CC:CC:CC:00:00:14','2F6BDFQDY'],
    ['7IB19701W00015','CC:CC:CC:00:00:15','Y676TK1X6'],
    ['7IB19701W00015','CC:CC:CC:00:00:16','QKTVQBZRJ'],
    ['7IB19701W00015','CC:CC:CC:00:00:17','X9LZLNC6K'],
    ['7IB19701W00015','CC:CC:CC:00:00:18','EU6M3G4WX'],
    ['7IB19701W00015','CC:CC:CC:00:00:19','WMGQVT3PG'],
    ['7IB19701W00015','CC:CC:CC:00:00:20','HMG3YMFPY'],
    ['7IB19701W00015','CC:CC:CC:00:00:21','QA6ETMKER'],
    ['7IB19701W00015','CC:CC:CC:00:00:22','6M6AB67MB'],
    ['7IB19701W00015','CC:CC:CC:00:00:23','FPABD26MY'],
    ['7IB19701W00015','CC:CC:CC:00:00:24','X5PQZGDUX'],
    ['7IB19701W00015','CC:CC:CC:00:00:25','BUX8LBNYB'],
    ['7IB19701W00015','CC:CC:CC:00:00:26','KL66WMVWQ'],
    ['7IB19701W00015','CC:CC:CC:00:00:27','U5PDT2B6N'],
    ['7IB19701W00015','CC:CC:CC:00:00:28','997B88MH4'],
    ['7IB19701W00015','CC:CC:CC:00:00:44','GY58FR391'],
    ['7IB19701W00015','CC:CC:CC:00:00:29','4CV73NP8R'],
    ['7IB19701W00015','CC:CC:CC:00:00:30','UTYA29MGH'],
    ['7IB19701W00015','CC:CC:CC:00:00:31','K3BQQEUDJ'],
    ['7IB19701W00015','CC:CC:CC:00:00:32','Q9EDP5ZZV'],
    ['7IB19701W00015','CC:CC:CC:00:00:33','6DRWDL2D5'],
    ['7IB19701W00015','CC:CC:CC:00:00:34','WDG7YXP51'],
    ['7IB19701W00015','CC:CC:CC:00:00:35','J28RQ96M6'],
    ['7IB19701W00015','CC:CC:CC:00:00:36','K9HFD5BT2'],
    ['7IB19701W00015','CC:CC:CC:00:00:37','DQ3J6W6YQ'],
    ['7IB19701W00015','CC:CC:CC:00:00:38','4Z2LH22RQ'],
    ['7IB19701W00015','CC:CC:CC:00:00:39','GGMERU1ZN'],
    ['7IB19701W00015','CC:CC:CC:00:00:40','NT7Z1YX7W'],
    ['7IB19701W00015','CC:CC:CC:00:00:41','ML7WG32G3'],
    ['7IB19701W00015','CC:CC:CC:00:00:45','RGN2UJQUB'],
    ['7IB19701W00015','CC:CC:CC:00:00:46','L68MWYC8M'],
    ['7IB19701W00015','CC:CC:CC:00:00:47','EDLAYD7LT'],
    ['7IB19701W00015','CC:CC:CC:00:00:48','P8T8CDP3N'],
    ['7IB19701W00015','CC:CC:CC:00:00:49','92FXQC8HD'],
    ['7IB19701W00015','CC:CC:CC:00:00:50','M57K7RHXH'],
    ['7IB19701W00015','CC:CC:CC:00:00:51','3GQDP6GDC'],
    ['7IB19701W00015','CC:CC:CC:00:00:52','N4D4BBDNA'],
    ['7IB19701W00015','CC:CC:CC:00:00:54','XCTCLN4AL'],
    ['7IB19701W00015','CC:CC:CC:00:00:55','RJ5GUANQB'],
    ['7IB19701W00015','CC:CC:CC:00:00:56','4LN8GB61R'],
    ['7IB19701W00015','CC:CC:CC:00:00:57','TX8CYDVVW'],
    ['7IB19701W00015','CC:CC:CC:00:00:58','M44XW8H55'],
    ['7IB19701W00015','CC:CC:CC:00:00:59','DP5BAB9N8'],
    ['7IB19701W00015','CC:CC:CC:00:00:60','Z5JZ6RTXF'],
    ['7IB19701W00015','CC:CC:CC:00:00:61','JHWXVUWKP'],
    ['7IB19701W00015','CC:CC:CC:00:00:62','RPAPXMRN9'],
    ['7IB19701W00015','CC:CC:CC:00:00:63','1AQ317MCU'],
    ['7IB19701W00015','CC:CC:CC:00:00:64','JT8E2V1UQ'],
    ['7IB19701W00015','CC:CC:CC:00:00:65','QFZNWU7UD'],
    ['7IB19701W00015','CC:CC:CC:00:00:66','W537XBJWJ'],
    ['7IB19701W00015','CC:CC:CC:00:00:67','B3QJ4PU8X'],
    ['7IB19701W00015','CC:CC:CC:00:00:68','HBZDUP6CK'],
    ['7IB19701W00015','CC:CC:CC:00:00:69','8HG2HNCNC'],
    ['7IB19701W00015','CC:CC:CC:00:00:70','1KEA34KCQ'],
    ['7IB19701W00015','CC:CC:CC:00:00:71','FQ9DY4F9F'],
    ['7IB19701W00015','CC:CC:CC:00:00:72','NQ5CGW363'],
    ['7IB19701W00015','CC:CC:CC:00:00:73','9ZG7Q9593'],
    ['7IB19701W00015','CC:CC:CC:00:00:74','YTMZNZ411'],
    ['7IB19701W00015','CC:CC:CC:00:00:75','Q74X8M82C'],
    ['7IB19701W00015','CC:CC:CC:00:00:76','WUUPLD4Y5'],
    ['7IB19701W00015','CC:CC:CC:00:00:77','1WJG1893E'],
    ['7IB19701W00015','CC:CC:CC:00:00:78','CMG46YNFK'],
    ['7IB19701W00015','CC:CC:CC:00:00:79','3WG352PH7'],
    ['7IB19701W00015','CC:CC:CC:00:00:80','W2Y8UF8UD'],
    ['7IB19701W00015','CC:CC:CC:00:00:81','1NLZHC8A5'],
    ['7IB19701W00015','CC:CC:CC:00:00:82','642WQA4Q9'],
    ['7IB19701W00015','CC:CC:CC:00:00:83','6J51BKQHJ'],
    ['7IB19701W00015','CC:CC:CC:00:00:84','B4NZA8CXX'],
    ['7IB19701W00015','CC:CC:CC:00:00:85','WV71YA23M'],
    ['7IB19701W00015','CC:CC:CC:00:00:86','XLETUR4RD'],
    ['7IB19701W00015','CC:CC:CC:00:00:87','LP83WEM8M'],
    ['7IB19701W00015','CC:CC:CC:00:00:88','ZR7H3Z1YR'],
    ['7IB19701W00015','CC:CC:CC:00:00:89','Z4PZUM93Z'],
    ['7IB19701W00015','CC:CC:CC:00:00:90','Z932JCQTB'],
    ['7IB19701W00015','CC:CC:CC:00:00:91','87UX8P7L4'],
    ['7IB19701W00015','CC:CC:CC:00:00:92','TN3Y1ZAHB'],
    ['7IB19701W00015','CC:CC:CC:00:00:93','2DEQN3PGD'],
    ['7IB19701W00015','CC:CC:CC:00:00:94','ZQWBRLW44'],
    ['7IB19701W00015','CC:CC:CC:00:00:95','892NDDDPT'],
    ['7IB19701W00015','CC:CC:CC:00:00:96','Y2RQVNZM7'],
    ['7IB19701W00015','CC:CC:CC:00:00:97','NUVN5HD77'],
    ['7IB19701W00015','CC:CC:CC:00:00:98','LB8D8BLA8'],
    ['7IB19701W00015','CC:CC:CC:00:00:99','Y9LEWBMCY'],
    ['7IB19701W00015','AB:CD:EF:12:34:56','K5E9FDL4M']
]

export const int2roman = (original: number): string => {
    if (original < 1 || original > 3999) {
      throw new Error('Error: Input integer limited to 1 through 3,999');
    }

    const numerals = [
      ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
      ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
      ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
      ['M', 'MM', 'MMM'], // 1000-3000
    ];

    // TODO: Could expand to support fractions, simply rounding for now
    const digits = Math.round(original).toString().split('');
    let position = (digits.length - 1);

    return digits.reduce((roman, digit) => {
      if (digit !== '0') {
        roman += numerals[position][parseInt(digit) - 1];
      }

      position -= 1;

      return roman;
    }, '');
  }