import { maintenanceJobStatusEnum } from "../common/classes/MaintenanceJob";

export const icons = {
    check: `checkmark`,
    spinner: `spinner`,
    error: `close-circle`,
}

// Accumulate all the items inside arrays within an array of objects with arrays
export const accumulateObjectArrays = (arraysWithArrays, key) => arraysWithArrays.reduce((accumulator, currentItem) => accumulator.concat(currentItem[key]), []);

export const statuses = {
    Ready: {
        icon: icons.check,
        id: maintenanceJobStatusEnum.ready,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.ready)[0],
    },
    New: {
        icon: icons.spinner,
        id: maintenanceJobStatusEnum.new,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.new)[0],
    },
    Booting: {
        icon: icons.spinner,
        id: maintenanceJobStatusEnum.booting,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.booting)[0],
    },
    Running: {
        icon: icons.spinner,
        id: maintenanceJobStatusEnum.running,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.running)[0],
    },
    Cancel: {
        icon: icons.error,
        id: maintenanceJobStatusEnum.cancel,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.cancel)[0],
    },
    Error: {
        icon: icons.error,
        id: maintenanceJobStatusEnum.error,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.error)[0],
    },
    Complete: {
        icon: icons.check,
        id: maintenanceJobStatusEnum.complete,
        label: Object.entries(maintenanceJobStatusEnum).find(([key, val]) => val == maintenanceJobStatusEnum.complete)[0],
    },
}

export const errorOrCancelStatuses = [statuses.Cancel, statuses.Error];
export const completedProgressStatuses = [statuses.Ready, statuses.Cancel, statuses.Error, statuses.Complete];