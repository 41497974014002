<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Maintenance</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/maintenance', user.active?.id]"me-button-as-link>
        &#8592;&nbsp;Back to Maintenance Top </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid>
      <ion-row>
        <ion-col size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Test History</h4>
            </ion-title>
          </ion-item>
          
          <ion-card-content >
            <ion-grid>
              <ion-row class="row-title">
                <ion-col>
                  Start Date and Time
                </ion-col>
                <ion-col>
                  System name
                </ion-col>
                <ion-col>
                  Model name
                </ion-col>
                <ion-col>
                  Serial No.
                </ion-col>
                <ion-col>
                  BC port check
                </ion-col>
                <ion-col>
                  Refrigerant check
                </ion-col>
                <ion-col>
                  Indoor unit LEV check
                </ion-col>
                <ion-col>
                  Test run
                </ion-col>
                <ion-col>
                  Detail
                </ion-col>
              </ion-row>
              <ion-row me-top-border>
                <ion-col>
                  4/27/2022 00:01:23
                </ion-col>
                <ion-col>
                  ACP1-1
                </ion-col>
                <ion-col>
                  PUHY-P200DMG6
                </ion-col>
                <ion-col>
                  1ZW00001
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style">Detail</ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  4/27/2022 00:01:23
                </ion-col>
                <ion-col>
                  ACP1-2
                </ion-col>
                <ion-col>
                  PUHY-P200DMG6
                </ion-col>
                <ion-col>
                  1ZW00002
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style">Detail</ion-button>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  3/3/2022 03:33:33
                </ion-col>
                <ion-col>
                  ACP1-3
                </ion-col>
                <ion-col>
                  PUHY-P200DMG6
                </ion-col>
                <ion-col>
                  1ZW00003
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="true" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="false" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="false" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col class = "col-checkbox">
                  <ion-checkbox color="primary" [checked]="false" [disabled]="true"></ion-checkbox>
                </ion-col>
                <ion-col>
                  <ion-button color="medium"  size="small" class="button-style">Detail</ion-button>
                </ion-col>
              </ion-row>            
            </ion-grid>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>