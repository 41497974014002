import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { UnauthGuard } from './common/guards/unauth.guard';
import { CanDeactivateGuard } from './common/guards/can-deactivate-guard.service';
import { HomePage } from './features/home/components/home.page';
import { HasInternetConnectionGuardService } from './common/guards/has-internet-connection-guard.service';
import { KenzaCloudMenuIds } from './enumerations/enums';
import { AppMessageComponent } from './common/views/app-message/app-message.component';
import { AppMessageUnauthComponent } from './common/views/app-message-unauth/app-message-unauth.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePage,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    canDeactivate: [CanDeactivateGuard],
    runGuardsAndResolvers: 'always',

    data: {
      parent: KenzaCloudMenuIds.Home,
      child: KenzaCloudMenuIds.NoSelection,
    },
  },
  {
    path: 'appmessage/:appmessagetype/messagecode/:appmessagecode',
    component: AppMessageComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'appmessageunauth/:appmessagetype',
    component: AppMessageUnauthComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'signin',
    loadChildren: () => import('./features/login/components/login.module').then(m => m.LoginPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./features/signup/components/signup.module').then(m => m.SignupPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'forgot',
    loadChildren: () => import('./features/forgot/components/forgot.module').then(m => m.ForgotPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'comparesubscriptionplans',
    loadChildren: () => import('./features/sites/pages/site-gateway-plans/standalone/standalone.module').then(m => m.GatewayPlanStandalonePageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'copyright',
    loadChildren: () => import('./features/copyright/components/copyright.module').then(m => m.CopyrightPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'subscriberagreement',
    loadChildren: () => import('./features/terms/components/terms.module').then(m => m.TermsPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./features/privacypolicy/components/privacy-policy/privacypolicy.module').then(m => m.PrivacyPolicyModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'transfer',
    loadChildren: () => import('./features/sites/pages/site-transfer/site-transfer.module').then(m => m.SiteTransferPageModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'contactus',
    loadChildren: () => import('./features/contact-us/contactus.module').then(m => m.ContactUsModule),
    canActivate: [UnauthGuard],
  },
  // { path: 'confirmation-modal', loadChildren: './common/modal/confirmation-modal/confirmation-modal.module#ConfirmationModalPageModule' },
  // Account Routing
  {
    path: 'account',
    loadChildren: () => import('./features/account/modules/account/account.module').then(m => m.AccountModule),
  },
  // Settings Routing
  {
    path: 'settings',
    loadChildren: () => import('src/app/features/settings/modules/settings/settings.module').then(m => m.SettingsModule),
  },
  // Site Routing
  {
    path: 'site',
    loadChildren: () => import('./features/sites/modules/site/site.module').then(m => m.SiteModule),
  },
  {
    path: 'manage',
    loadChildren: () => import('src/app/features/manage/modules/manage/manage.module').then(m => m.ManageModule),
  },
  // test Routing
  {
    path: 'testdrive',
    loadChildren: () => import('src/app/features/testdrive/modules/testdrive/testdrive.module').then(m => m.TestDriveModule),
   },
   {
    path: 'maintenance',
    loadChildren: () => import('src/app/features/maintenance/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
   }  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
