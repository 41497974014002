import { Animations } from '../../../constants/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-slider',
  animations: [Animations.enableDisable],
  templateUrl: './sidebar-slider.component.html',
  styleUrls: ['./sidebar-slider.component.scss'],
})

export class SidebarSlider {
  constructor(
    // Empty
  ) {
    // Empty
  }
}