import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteNotification } from 'src/app/features/sites/components/site-alerts/classes/sitenotification';

@Injectable()
export class DataService {

    private equipmentAlertNotificationID = new BehaviorSubject('No message');
    observeEquipmentAlertNotificationID = this.equipmentAlertNotificationID.asObservable();

    private siteNotificationID = new BehaviorSubject('No message');
    observeSiteNotificationID = this.siteNotificationID.asObservable();

    //constructor() { }

    updateEquipmentAlertNotificationID(sitenotification_id: string) {
        this.equipmentAlertNotificationID.next(sitenotification_id);
    }

    updateSiteNotificationID(sitenotification_id: string) {
        this.siteNotificationID.next(sitenotification_id);
    }

}