import { Component } from '@angular/core';
import { MaintenanceBCPortListComponent } from 'src/app/features/maintenance/components/maintenance-bcport-list/maintenance-bcport-list.component';
import { MaintRefrigerantNotificationComponent } from '../../modals/maint-refrigerant-notification/maint-refrigerant-notification.component';
import { TestType, OutdoorUnit } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: 'maintenance-refrigerant-list',
    templateUrl: './maintenance-refrigerant-list.component.html',
    styleUrls: ['./maintenance-refrigerant-list.component.scss']
})


export class MaintenanceRefrigerantListComponent extends MaintenanceBCPortListComponent {
    visible: boolean;

    ionViewWillEnter() {
        // The device configuration may have been updated, so recreate the list
        super.cleaningUp();

        // Reacquire the display data when the screen is displayed
        // Subscribing to unique events, since the screen side cannot detect changes in the attribute
        this.communicator = this.outdoorunitService.notificationEmitter.subscribe(msg => {
            // Redraw when you have finished retrieving data for display
            super.draw(msg);
        });
        // Communication is carried out with outdoorunitService
        this.outdoorunitService.getUnitList(TestType['refrigerant']);
    }

    async openNotification() {
        if (!this.siteService.handleIsConnected()) return;


        const modal = await this.modalController.create({
            component: MaintRefrigerantNotificationComponent,
            cssClass: 'me-modal-bcport',
            backdropDismiss: false,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data.isCheckStart === true) {
            let selectUnitList = []
            // Start is selected, so start checking
            selectUnitList.push(this.outdoorUnitList.unitList[this.selectUnit])
            this.outdoorunitService.setSelectUnit(selectUnitList);
            this.router.navigate(['/maintenance', this.user.active.id, "Refrigerant"], {});
        }

        return;
    }

    rmdNextClick() {
        this.displayRmd = this.displayHide;
        this.displayOc = this.displayShow;
        const filtered = this.outdoorUnitList.filteredSelectUnit(this.outdoorUnitList.unitList[this.selectUnitRmd])

        //Looping through the array after filtering
        for (const unit of filtered) {
            //If supported
            if (unit.supported === true) {
                //check radio button
                this.selectUnit = unit.unitIndex;
                break;
            }
        }
        this.dataSourceOc = new MatTableDataSource<OutdoorUnit>(filtered);
    }

    // Debug function invoked by clicking A in the center of the screen
    // Click while pressing the button below
    // ctr

    degug(event) {
        // if (event.ctrlKey === true) {
        //     const unit = new OutdoorUnit();
        //     const num = this.outdoorunitService.outdoorUnitList.unitList.length;
        //     unit.unitIndex = num;
        //     unit.unitAddress = '51';
        //     unit.systemName = "dummy_systemName_" + num;
        //     unit.modelName = "dummy_modelName_" + num;
        //     unit.supported = true;
        //     this.outdoorunitService.outdoorUnitList.unitList.push(unit);
        //     super.draw("degug : add unit");
        // }
    }


}
