import { utimes } from "fs";
import { GatewayUnitTwoDigitType } from "src/app/enumerations/enums";
import { GatewayUnit, VentMode } from "src/app/features/manage/components/classes/GatewayUnit";
import { ControlDisplay } from "./controlDisplay";
import { ControlSection } from "./controlSection"

export class VentModeControl extends ControlSection {
    // control options for the Lossnay unit re: ventilaion mode
 
    bypass: ControlDisplay           // how to render the Bypass button
    heat_recovery: ControlDisplay    // how to render the head_recovery button
    auto: ControlDisplay             // how to render the auto button

    firstUnitMode:VentMode           // mode of the first unit added to the group
                                     // used to calcuate singleMode
    singleMode:boolean               // are all units in this batch group in the same mode?

    lossnayIconSrc:string           // which lossnay icon to show in the main site control section 
                                    // assuming all batch groups are in the same mode (singleMode==true)

    constructor() {
        super();

        this.bypass = new ControlDisplay;
        this.heat_recovery = new ControlDisplay;
        this.auto = new ControlDisplay;

        this.firstUnitMode = null
        this.singleMode = true;
        this.lossnayIconSrc = ''
    }

    resetSectionCounters() {
        // set our couters to zero
        this.bypass.reset();
        this.heat_recovery.reset();
        this.auto.reset();
        this.firstUnitMode = null;
        this.singleMode = true;
        this.lossnayIconSrc = '';
    }

    addGroupToBatch(unit: GatewayUnit) {
        // add details of this unit re: mode

        // current ic modes
        if (unit.type == GatewayUnitTwoDigitType.Lossnay) {
            
            if (unit.isLCBypassDisabled()) this.bypass.addDisabled();
            if (unit.isLCAutoDisabled()) this.auto.addDisabled();

            if (unit.isInLCBypass()) this.bypass.addSelected();
            else if (unit.isInLCHeatRecovery()) this.heat_recovery.addSelected();
            else if (unit.isInLCAuto()) this.auto.addSelected();

            this.lossnayIconSrc = this.displayOptions.getLossnayImageAddress(unit.vent_mode);

            // set the single mode boolean
            if (this.firstUnitMode == null) this.firstUnitMode = unit.vent_mode;
            if (!unit.isInThisVentMode(this.firstUnitMode)) this.singleMode = false;
        }            
    }

    setDisplayOptions(groupCount:number, maintenanceJobActive: boolean) {
        // called when all groups are added and we should set our controllable/display states

        // set IC mode options - mode
        this.bypass.setDisplayOptions(groupCount, maintenanceJobActive)
        this.heat_recovery.setDisplayOptions(groupCount, maintenanceJobActive)
        this.auto.setDisplayOptions(groupCount, maintenanceJobActive)

        const labels = this.getLabelAndIcon()
        this.label = labels[0]
        this.label_icon = labels[1]     
    }
    
    getLabelAndIcon() {
        // determine and return the mode label and icon to display
        let lbl = 'Mixed'
        let icon = ''
        if (this.bypass.selected) {
            icon = this.displayOptions.getVentModeIcon(VentMode.bypass)
            lbl = this.displayOptions.getDisplayMode(VentMode.bypass)
        } else if (this.heat_recovery.selected) {
            icon = this.displayOptions.getVentModeIcon(VentMode.heat_recovery)
            lbl = this.displayOptions.getDisplayMode(VentMode.heat_recovery)
        } else if (this.auto.selected) {                
            icon = this.displayOptions.getVentModeIcon(VentMode.auto)
            lbl = this.displayOptions.getDisplayMode(VentMode.auto)
        } 

        return [lbl, icon]
    }

}
