import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-reports',
  templateUrl: './settings-reports.component.html',
  styleUrls: ['./settings-reports.component.scss'],
})
export class SettingsReportsComponent {

  // constructor() { }

  // ngOnInit() {}

}
