import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { AccountService } from '../../services/accountService';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { CognitoUserPoolCustomAttributeEnum, CognitoUserPoolCustomAttributeValueEnum, LevelEnum, PasswordErrorMessages } from 'src/app/enumerations/enums';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { UserService } from 'src/app/common/services/user/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-account-cancel',
  templateUrl: './account-cancel.component.html',
  styleUrls: ['./account-cancel.component.scss'],
})

export class AccountCancelComponent implements OnInit {
  @Input() parent_account_id: string;
  @Input() parent_account_site_permissions: any[] = [];
  i_btn_ok_cancel_color = `reverse-primary`;
  account_sites_have_active_gateways = false;
  min_number_of_gateway_display_lines = 3;
  active_account_sites_with_gateways: Gateway[] = [];

  error = ``;
  // allowedAttempts = 6;
  wrongPassword = false;
  showCurrent: boolean;
  showPassword: boolean;
  confirmPassword: UntypedFormGroup;

  constructor(
    private userService: UserService,
    private loading: LoadingController,
    private modalController: ModalController,
    private alertController: AlertController,
    public formBuilder: UntypedFormBuilder,
    private loadingController: LoadingController,
    private accountService: AccountService,
    private siteService: SiteService,
  ) { 
    this.confirmPassword = formBuilder.group({
      currentPassword: [``, Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])],
    });
  }

  toggleCurrent() {
    this.showCurrent = !this.showCurrent;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  ngOnInit() {
    if (this.parent_account_site_permissions && this.parent_account_site_permissions.length > 0) {
      this.active_account_sites_with_gateways = [];
      this.active_account_sites_with_gateways = this.prepare_account_site_gateways(this.parent_account_site_permissions);

      this.account_sites_have_active_gateways = (this.active_account_sites_with_gateways.length > 0);
      this.active_account_sites_with_gateways.sort(compareValues('name', 'asc'))

      this.active_account_sites_with_gateways = this.display_min_number_of_gateways(
        this.active_account_sites_with_gateways,
        this.min_number_of_gateway_display_lines
      );

    }
  }

  prepare_account_site_gateways(account_site_permissions: any[]): Gateway[] {
    const gateways: Gateway[] = [];
    const index: number = 0;

    if (account_site_permissions && account_site_permissions.length > 0) {

      account_site_permissions.forEach(account => {
        if ((account.level == LevelEnum.Owner) && (account.site) && (account.site.gateways && account.site.gateways.length > 0)) {
          account.site.gateways.forEach(gateway => {
            gateway.subscription_name = gateway.gatewaysubscription[0].subscriptions.name;
            gateways.push(gateway);
          });
        }
      })
    }
    return gateways;
  }

  display_min_number_of_gateways(
    list_of_site_gateways: Gateway[],
    min_number_of_gateway_display_lines: number
  ): Gateway[] {
    const lines_to_add: number =
      list_of_site_gateways.length < min_number_of_gateway_display_lines
        ? min_number_of_gateway_display_lines - list_of_site_gateways.length
        : 0;
    let index = 0;
    if (lines_to_add > 0) {
      const blank_gateway: Gateway = new Gateway();
      blank_gateway.name = '';
      blank_gateway.subscription_name = '';

      for (index = 0; index < lines_to_add; index++) {
        list_of_site_gateways.push(blank_gateway);
      }
    }
    return list_of_site_gateways;
  }

  checkPasswordFieldStatus(e) {
    if (this.confirmPassword.status == `VALID`) {
      this.error = ``;
      this.wrongPassword = false;
    };
  }

  async confirm_cancel_account() {
    if (!this.siteService.handleIsConnected()) return;

    let load;
    try {
      load = await this.loading.create({
        spinner: `lines`,
        message: `Checking password...`,
      });
      load.present();
    } catch (error) {
      return;
    }

    if (this.confirmPassword.status == `VALID`) {
      try {
        await Auth.signIn(this.userService.username.toLowerCase(), this.confirmPassword.controls.currentPassword.value);
        this.wrongPassword = false;
        // this.allowedAttempts = 6;
        this.error = ``;
      } catch (error) {
        if (error.message == PasswordErrorMessages.INCORRECT_CREDENTIALS) {
          this.error = `Incorrect password, multiple wrong attempts may cause this form to be temporarily locked.`;
          // this.allowedAttempts = this.allowedAttempts > 0 ? this.allowedAttempts - 1 : 0;
        } else if (error.message == PasswordErrorMessages.PASSWORD_ATTEMPTS_EXCEEDED) {
          this.error = `${error.message}, try again later.`;
        }
        this.wrongPassword = true;
      }
    }

    load.dismiss();

   if (this.wrongPassword == false) {
     const alert = await this.alertController.create({
       header: `Confirm Cancel Account`,
       message:
         `<div class="successfulAuthentication">Account Successfully Authenticated</div><br><div class="me-redClass">Are you sure you want to cancel your account?</div></br><div class="me-centerText">This action cannot be undone.</div>`,
       backdropDismiss: false,
       cssClass: `me-alert-registratin-buttons me-cancel-registration-alert`,

       buttons: [
         {
           text: `Yes`,
           cssClass: `exit-button`,
           role: `CANCEL_ACCOUNT`,
           handler: () => {
             if (!this.siteService.handleIsConnected()) return false;
           },
         },
         {
           text: `No`,
           cssClass: `back-button`,
           role: `CANCEL`
         },
       ],
     });

     await alert.present();

     const { role } = await alert.onDidDismiss();

     if (role.toUpperCase() === `CANCEL_ACCOUNT`) {
       this.cancel_account(``);
     }
   } else {
    return;
   }
  }

  async cancel_account(account_id: string) {
    const spinningLoader = await this.loadingController.create({
      spinner: `lines`,
      message: `Canceling Account...`,
    });

    await spinningLoader.present();

    this.accountService.cancel_account(account_id).then(
      (result) => {
        spinningLoader.dismiss();

        this.modalController.dismiss({
          account_cancelled: true,
        });
      },
      (err) => {
        spinningLoader.dismiss();
      }
    ) /*.catch((catch_error) => { })
    .finally(() => { });*/

  }

  close() {
    this.modalController.dismiss();
  }

}
