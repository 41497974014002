import { Injectable } from '@angular/core';
import { GatewayModelClass, PlanRowTypeEnum } from 'src/app/enumerations/enums';
import { Subscription } from 'src/app/features/manage/components/classes/Subscription';
import { SubscriptionPlanDetail } from 'src/app/features/manage/components/classes/SubscriptionPlanDetail';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { WebserviceURL } from 'src/app/constants/webservice';
import { HttpClient } from '@angular/common/http';
import { GatewayModel } from 'src/app/features/manage/components/classes/Gateway';
import { Mode } from 'src/app/features/manage/components/classes/GatewayUnit';
// import { memoize } from 'src/app/common/decorators/memoize.decorator';
import { keyBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {
  plans: Subscription[] = [{
    id: '',
    name: 'Select a Plan',
    // subscription_status_type_id: 1, 
    monthly_rate: 0,
    kenza_id: '',
    gateway_model_class_name: '',
    cost_string: '',
    plan_type: 'subscription'
  }];

  plansKeyed = {};

  gatewayModels: GatewayModel[] = [];

  details: SubscriptionPlanDetail[];

  private fetchedPlans = false;
  private fetchingPlans = false;

  private fetchedDetails = false;
  private fetchingDetails = false;

  private fetchingModels = false;
  private fetchedModels = false;

  priceHeaderRow;

  constructor(
    private siteService: SiteService,
    private http: HttpClient
  ) {
    this.getPlans();
    this.getPlanDetails();
    this.getGatewayModels();
  }
  isDefaultSub(subscription) {
    return subscription === 'unselected';
  }
  /**
   * Returns the plans (subscriptions) for our site.
   */
  async getPlans() {
    if (this.fetchedPlans || this.fetchingPlans) return;
    this.fetchingPlans = true;

    const plans: Subscription[] = await this.siteService.getPlans();
    this.plans.splice(1, 0, ...plans);
    this.plans.forEach(plan => plan.show = true);
    this.plansKeyed = keyBy(this.plans, 'kenza_id');

    this.fetchedPlans = true;
    this.fetchingPlans = false;
  }

  resetFilter() {
    this.plans.forEach(plan => plan.show = true);
  }

  filterDisplayByName(planName) {
    this.plans.forEach(plan => plan.show = plan.name !== planName)
  }

  filterDisplayByModelClass(model_class_name: string, filter_out_current: boolean, current_plan_name: string) {
    this.plans.forEach(plan => plan.show = (plan.gateway_model_class_name === '' || plan.gateway_model_class_name === model_class_name) &&
      (filter_out_current ? current_plan_name != plan.name : true))
  }

  // getKenzaPlanId(planId) {
  //   return this.plans.find(p => p.id === planId).kenza_id;
  // }

  // getPlanByName(planName) {
  //   return this.plans.find(p => p.name === planName);
  // }

  // getPlanById(planId) {
  //   return this.plans.find(p => p.id === planId);
  // }

  // getPriceForPlanId(planId) {
  //   if (!planId) return 0;
  //   return this.plans.find(p => p.id === planId)?.monthly_rate || 0;
  // }

  // getPriceForSubscriptionId(subscriptionId){
  //   return this.plans.find(p => p.kenza_id === subscriptionId)?.monthly_rate
  // }

  getPlanDetailsAsObservable() {
    return this.http
      .get<SubscriptionPlanDetail[]>(
        WebserviceURL + 'site/gateway/subscription/plans'
      )
  }

  getGatewayModelsAsObservable() {
    return this.http.get<GatewayModel[]>(
      WebserviceURL + 'gateway/models'
    )
  }

  getPlansAsObservable() {
    return this.http.get<Subscription[]>(
      WebserviceURL + 'site/gateway/subscriptions'
    )
  }

  async getPlanDetails() {
    if (this.fetchedDetails || this.fetchingDetails) return;
    this.fetchingDetails = true;

    const details = await this.siteService.getGatewaySubscriptionPlanDetails();

    this.fetchingDetails = false;
    this.fetchedDetails = true;

    let planTable: SubscriptionPlanDetail[] = [];
    const priceHeaderRow: SubscriptionPlanDetail = new SubscriptionPlanDetail();
    // get list of plans
    const planNameList = [...new Set(details.map(plan => plan.subscription_name))];

    planNameList.forEach(function (planName: string, key) {
      priceHeaderRow.PlanRowType = PlanRowTypeEnum.PriceHeader;
      const planRow: SubscriptionPlanDetail = details.filter(spd => spd.subscription_name.toUpperCase() === planName.toUpperCase())[0];

      switch (key) {
        case 0:
          priceHeaderRow.subscription_name = planName;
          priceHeaderRow.subscription_monthly_rate = planRow.subscription_monthly_rate;
          break;
        case 1:
          priceHeaderRow.subscription_name_plan_1 = planName;
          priceHeaderRow.subscription_monthly_rate_plan_1 = planRow.subscription_monthly_rate;
          break;

        default:
          break;
      }
      const hasPlans = planTable.length > 0 ? true : false;
      const planDetails: SubscriptionPlanDetail[] = [];
      //get plan rows
      let planRows: SubscriptionPlanDetail[] = [];
      planRows = details.filter(spd => spd.subscription_name.toUpperCase() === planName.toUpperCase());
      planRows.forEach(function (spd, key) {

        if (planDetails.filter(item => item.feature_category_name === spd.feature_category_name).length == 0) {
          let spd_header: SubscriptionPlanDetail = { ...spd };
          spd_header.PlanRowType = PlanRowTypeEnum.FeatureCategory;
          spd_header.feature_category_name = spd.feature_category_name;
          spd_header.feature_detail_description = '';
          spd_header.feature_detail_description_plan_1 = '';
          planDetails.push(spd_header);
        }
        spd.PlanRowType = PlanRowTypeEnum.FeatureDetail;

        planDetails.push(spd);
      });

      if (hasPlans == false) {
        planTable = [...planDetails];
      } else {
        for (let rowIndex = 0; rowIndex < planDetails.length; ++rowIndex) {
          if (planTable[rowIndex]?.PlanRowType === PlanRowTypeEnum.FeatureCategory) {
            continue;
          }

          if (key === 1) {
            if (planTable[rowIndex] && planTable[rowIndex]?.feature_detail_description_plan_1 && planDetails[rowIndex]?.feature_detail_description) {
              planTable[rowIndex].feature_detail_description_plan_1 = planDetails[rowIndex]?.feature_detail_description;
            }
          }
        }
      }
    });

    this.priceHeaderRow = priceHeaderRow;
    this.details = planTable;
  }

  async getGatewayModels() {
    if (this.fetchedModels || this.fetchingModels) return;
    this.fetchingModels = true;

    const gmodels: GatewayModel[] = await this.siteService.getGatewayModels();
    // sort models by id - they are created in the desired presented order
    let models: GatewayModel[] = [];
    models.splice(1, 0, ...gmodels)
    this.gatewayModels = models.sort((n1, n2) => {
      if (n1.id > n2.id) return 1;
      if (n1.id < n2.id) return -1;
      return 0
    })

    this.fetchedPlans = true;
    this.fetchingPlans = false;
  }

  gateway_class_of_model_id(model_id: string) {
    // what is the model class of this id?
    let mod = this.gatewayModels.find(p => p.id === model_id) || this.gatewayModels[0];
    return mod.class_name;
  }

  gateway_name_of_model_id(model_id: string) {
    // what is the model name of this id?
    let mod = this.gatewayModels.find(p => p.id === model_id) || this.gatewayModels[0];
    return mod.name;
  }

  gateway_class_id_of_class_name(class_name: string) {
    // what is the model id of this model class?
    let mod = this.gatewayModels.find(p => p.class_name == class_name) || this.gatewayModels[0];
    return mod.id;
  }

}
