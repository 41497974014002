<ion-header>
  <ion-toolbar>
    <ion-title slot="start">{{title_text}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="groupForm">
    <ion-grid *ngIf="group_model_state!==model_state.Delete">
      <ion-row class="validationRow">
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
          <ion-input type="text" id="groupName" maxlength="50"
            name="groupName" ngDefaultControl class="form-control" formControlName="group_name"
            (ionInput)="groupForm.controls.group_name.setValue($event.target.value);"
            (ionBlur)="setTrimmedValue($event)" labelPlacement="floating">
              <ion-text slot="label" color="secondary" for="groupName">Group Name<sup validation-error>*</sup></ion-text>
          </ion-input>

          <div *ngIf="groupForm.controls.group_name.errors">
            <div validation-error *ngIf="groupForm.controls.group_name.errors.required">
              Group Name is required
            </div>
            <div validation-error *ngIf="groupForm.controls.group_name.errors.minlength">
              Unit Name must be between 2 and 50 characters
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="validationRow">
        <ion-col size="12" style="padding-left: 10px">
          <ion-item lines="full">
             <mat-form-field style="width: 100%" appearance="none" color="primary">
              <mat-label>Location / Area</mat-label>
              <input type="text"
                placeholder=""
                aria-label=""
                matInput
                [formControl]="groupForm.controls.group_location"
                [matAutocomplete]="auto"
                maxlength="50">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let location of filteredLocationOptions" [value]="location">
                  {{location}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ion-item> 
        </ion-col>
      </ion-row>      

      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
          <ion-grid grid-group>

            <ion-row>
              <ion-col>
                <ion-item lines="none" class="ion-no-padding">
                  <ion-label class="ion-no-padding" color="secondary" style="padding-left: 10px;">
                    Assign Indoor Units to Group<sup validation-error>*</sup>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row class="FixedHeightContainer" *ngIf="group_model_state!==model_state.Delete">
              <ion-col class="FixedHeightContainer">
                <div class="Content" style="border-style: solid; ">
                  <ion-list *ngIf="group_model_state!==model_state.Delete" style="padding:0px;margin:0px">
                    <ion-item *ngFor="let idu of selected_odu_indoor_units" me-idu-item-style>
                      <ion-checkbox [checked]="idu.isSelected" (ionChange)="toggle_unit_selected_value(idu)"
                      label-placement="end" justify="start">
                        <ion-text style="padding-left: 5px">{{idu.name}}</ion-text>
                      </ion-checkbox>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-row>

            <ion-row class="FixedHeightContainer" *ngIf="group_model_state==model_state.Delete">
              <ion-col class="FixedHeightContainer">
                <div class="Content" style="border-style: solid; ">
                  <ion-list style="padding:0px;margin:0px">
                    <ion-item *ngFor="let idu of selected_odu_indoor_units" me-idu-item-style>
                      <ion-checkbox [checked]="idu.isSelected" (ionChange)="toggle_unit_selected_value(idu)"
                      label-placement="end" justify="start">
                        <ion-text style="padding-left: 5px">{{idu.name}}</ion-text>
                      </ion-checkbox>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-col>
            </ion-row>

          </ion-grid>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="group_model_state!==model_state.Delete">
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px;">
          <label me-ion-modal-message style="font-style: italic;">
            A group is a virtual collection of indoor units that are meant to be in the same state.
          </label>
          <label me-ion-modal-message style="font-style: italic;">
            You must assign at least 1 indoor unit to a group to create a group.
          </label>
          <label me-ion-modal-message style="font-style: italic;">
            When using other centralized controllers make sure units are grouped the same.
          </label>
          <label me-ion-modal-message style="font-style: italic;">
            Ensure proper grouping of units when using a multi unit M-NET Adaptor.
          </label>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="group_model_state===model_state.Delete">
      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
          <div class="ion-text-center" style="padding-top: 25px">
            <ion-label color="danger">
              Are you sure you want to delete this group?
            </ion-label>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
          <div class="ion-text-center" color="danger">
            <strong>
              {{group.group_name}}
            </strong>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
          <div class="ion-text-center" color="danger">
            Indoor unit group selection will default to No Group Assigned.
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button id="i_btn_save_group" name="i_btn_save_group" slot="end" (click)="on_save()"
      [disabled]="disable_save_button() || !saveEnabled" style="margin: 0 0 0 1em;" color="primary">
      {{save_button_text}}
    </ion-button>
    <ion-button id="i_btn_cancel" name="i_btn_cancel" slot="end" (click)="on_cancel()" style="margin: 0 1em 0 1em;"
      color="reverse-primary">
      {{cancel_button_text}}
    </ion-button>
  </ion-toolbar>
</ion-footer>