import { Injectable } from '@angular/core';
import { Logger } from '../logging/log.service';
import { CloudService } from '../cloud/cloud.service';
import { IdNamePair } from '../../classes/commonclasses';
import { LevelPermission } from '../../classes/LevelPermission';
import { AppStorageService } from '../storage/app-storage.service';
import { Account } from 'src/app/features/account/classes/account';
import { SiteMemberRoles } from '../../../constants/sitememberroles';
import { PermissionDetailEnum, LevelEnum } from '../../../enumerations/enums';
import { AccountService } from 'src/app/features/account/services/accountService';

@Injectable({
  providedIn: 'root'
})
export class AppAuthenticationService {
  public levelPermissions: LevelPermission[] = [];
  public levelEnums = LevelEnum;
  public permissionEnums = PermissionDetailEnum;

  constructor(
    private cloud: CloudService,
    private accountService: AccountService,
    private appStorageService: AppStorageService,
  ) {
  }

  async getSiteAccounts(siteId: string) {
    return await this.accountService.getSiteAccounts(siteId).toPromise();
  }

  getLevelPermissions() {
    return this.cloud.getLevelPermissions().then(results => {
      this.levelPermissions = results;
      this.appStorageService.localStorageSetItem('LevelPermissions', this.levelPermissions);
    });
  }

  refreshLevelPermissionFromLocalStorage() {
    const level_permissions_from_local_storage = this.appStorageService.localStorageGetItem('LevelPermissions');

    if (level_permissions_from_local_storage) {
      const level_permissions = JSON.parse(level_permissions_from_local_storage);
      this.levelPermissions = level_permissions;
    } else {
      this.levelPermissions = [];
    }
  }

  doesLevelHavePermission(level: number, permission: number): boolean {
    const local_storage_active_site_account = this.appStorageService.localStorageGetItem('ActiveSiteAccount');

    if (local_storage_active_site_account) {
      const active_site_account: Account = JSON.parse(local_storage_active_site_account);

      if ((this.levelPermissions == null) || (this.levelPermissions.length == 0)) {
        this.refreshLevelPermissionFromLocalStorage();
      }

      const result = this.levelPermissions.find(checkValue => checkValue.level_id == active_site_account.level && checkValue.permissiondetail_id == permission) !== undefined ? true : false;
      return result;
    }

    return this.levelPermissions.find(checkValue => checkValue.level_id == level && checkValue.permissiondetail_id == permission) !== undefined ? true : false;
  }

  doesLevelHavePermissionGlobal(level: number, permission: number): boolean {
    if (!this.levelPermissions) this.refreshLevelPermissionFromLocalStorage();
    return this.levelPermissions.find(checkValue => checkValue.level_id == level && checkValue.permissiondetail_id == permission) !== undefined ? true : false;
  }

  doesLevelHaveSiteMonitorPermission(level: number, permission: number): boolean {
    const local_storage_active_site_account = this.appStorageService.localStorageGetItem('ActiveSiteAccount');

    if (local_storage_active_site_account) {
      const active_site_account: Account = JSON.parse(local_storage_active_site_account);
      const result = this.levelPermissions.find(checkValue => checkValue.level_id == active_site_account.level && checkValue.permissiondetail_id == permission) !== undefined ? true : false;
      return result;
    }

    return this.levelPermissions.find(checkValue => checkValue.level_id == level && checkValue.permissiondetail_id == permission) !== undefined ? true : false;
  }

  doesLevelHavePermissions(level: number, permissions: number[]): boolean {
    return permissions.filter(perm => this.levelPermissions.some(({ level_id, permissiondetail_id }) => (perm == permissiondetail_id && level_id == level))).length > 0 ? true : false;
  }

  getChangeableRoleList(loggedInUserLevel: number, siteMemberLevel: number): IdNamePair[] {
    let roleList: IdNamePair[] = [];

    switch (loggedInUserLevel) {
      case this.levelEnums.Owner:

        if ((siteMemberLevel === this.levelEnums.Administrator) || (siteMemberLevel === this.levelEnums.User) || (siteMemberLevel === this.levelEnums.Viewer)) {
          roleList = SiteMemberRoles.list.filter(role => role.id !== this.levelEnums.Owner)
        }
        break;

      case this.levelEnums.Administrator:

        if ((siteMemberLevel === this.levelEnums.User)) {
          roleList = SiteMemberRoles.list.filter(role => (role.id !== this.levelEnums.Owner && (role.id !== this.levelEnums.Administrator)))
        }

        if ((siteMemberLevel === this.levelEnums.Viewer)) {
          roleList = SiteMemberRoles.list.filter(role => (role.id !== this.levelEnums.Owner && (role.id !== this.levelEnums.Administrator)))
        }
    }

    //return roles in descending level/id order
    return roleList.sort(function (roleA, roleB) {
      return roleA.id - roleB.id;
    }).reverse();
  }

}
