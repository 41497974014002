import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageRoutingModule } from './manage-routing.module';
import { ManageGatewaysComponent } from '../../components/manage-gateways/manage-gateways.component';
import { ManageMembersComponent } from '../../components/manage-members/manage-members.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/common/modules/shared/shared.module';
import { ManageGatewayDetailsComponent } from '../../components/manage-gateway-details/manage-gateway-details.component';
import { MapUnitsComponent } from '../../modals/map-units/map-units.component';
import { MapUnitsErrorComponent } from '../../modals/map-units-error/map-units-error.component';

@NgModule({
    declarations: [
        ManageGatewaysComponent,
        ManageGatewayDetailsComponent,
        ManageMembersComponent,
        MapUnitsComponent,
        MapUnitsErrorComponent,
    ],
    imports: [
        CommonModule,
        ManageRoutingModule,
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ]
})
export class ManageModule { }
