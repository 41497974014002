<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Troubleshooting<!--troubleshooting--></ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>

  <swiper-container *ngIf="didInit" pager="false" #mySlider>

    <swiper-slide>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-card me-ion-card style="height:100%">
              <ion-item class="ion-no-padding">
                <ion-label class="ion-text-center">
                  <h2>Error Factors(Top 1)<!--Error factors (Top 1)--></h2>
                </ion-label>
              </ion-item>
    
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        High pressure fault 1 (Outdoor Unit)<!--High pressure failure 1 (outdoor unit)-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        If the pressure of 3.78MPa [548psi] or higher is detected by the pressure sensor during operation (the first detection), 
                        the outdoor stops once, turns to antirestart mode for 3 minutes, and restarts after 3 minutes automatically.
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-card me-ion-card style="height:100%">
              <ion-item class="ion-no-padding">
                <ion-label class="ion-text-center">
                  <h2>solution<!--Solution--></h2>
                </ion-label>
              </ion-item>
    
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Replace service parts.<!--Please replace the service parts.-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      
      </ion-grid>
    </swiper-slide>

    <swiper-slide>
      <ion-grid>
        <ion-row>
            <ion-col size="12">
              <ion-card me-ion-card style="height:100%">
                <ion-item class="ion-no-padding">
                  <ion-label class="ion-text-center">
                    <h2>Error Factors(Top 2)<!--Error factors (Top 2)--></h2>
                  </ion-label>
                </ion-item>
      
                <ion-card-content>
                  <ion-grid>
                    <ion-row>
                      <ion-col>
                        <ion-label>
                          High pressure fault 1 (Outdoor Unit)<!--High pressure failure 1 (outdoor unit)-->
                        </ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col>
                        <ion-label>
                          If the pressure of 3.78MPa [548psi] or higher is detected by the pressure sensor again (the second detection) 
                          within 30 minutes after the first stop of the outdoor unit, 
                          the outdoor unit stops once, turns to anti-restart mode for 3 minutes, and restarts after 3 minutes automatically.
                        </ion-label>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-card me-ion-card style="height:100%">
              <ion-item class="ion-no-padding">
                <ion-label class="ion-text-center">
                  <h2>Solution<!--Solution--></h2>
                </ion-label>
              </ion-item>
    
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Thermistor failure.(TH3, TH7)<!--There is a possibility that the thermista (TH3, TH7) may fail.-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Check the Thermistor.<!--Please reconfirm the thermista.-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </swiper-slide>

    <swiper-slide>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-card me-ion-card style="height:100%">
              <ion-item class="ion-no-padding">
                <ion-label class="ion-text-center">
                  <h2>Error Factors(Top 3)<!--Error factor (TOP 3)--></h2>
                </ion-label>
              </ion-item>
    
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        High pressure fault 1 (Outdoor Unit)<!--High pressure failure 1 (outdoor unit)-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Open phases due to unstable supply voltage may cause the pressure switch to malfunction or the unit to fail.
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-card me-ion-card style="height:100%">
              <ion-item class="ion-no-padding">
                <ion-label class="ion-text-center">
                  <h2>Solution<!--Solution--></h2>
                </ion-label>
              </ion-item>
    
              <ion-card-content>
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Voltage drop due to unstable power supply may be occurring.
                      </ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-label>
                        Recheck the power supply.
                        <!--Please reconfirm the power supply.-->
                      </ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </swiper-slide>
 
  </swiper-container>

  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col>
        <div class="ion-text-center ion-text-nowrap">
          <ion-label *ngIf="activePage != 0" me-navigate-to (click)="swipePrev();">Prev solution</ion-label>
          <ion-button color="success" (click)="close()" style="margin: 20px">Resolved.</ion-button>
          <ion-label *ngIf="activePage != 2" me-navigate-to (click)="swipeNext();">Next solution</ion-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>