import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { OutdoorUnitDetails } from '../site-analytics-test-run/site-analytics-test-run.component';
import { SiteAnalyticsTestRunOduSelectComponent } from '../site-analytics-test-run-odu-select/site-analytics-test-run-odu-select.component';
import { GatewayModelClass, LevelEnum, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumLabels, MaintenanceJobTypeEnumTitle, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';

@Component({
  selector: 'app-site-analytics-branch-port-check',
  templateUrl: './site-analytics-branch-port-check.component.html',
  styleUrls: ['./site-analytics-branch-port-check.component.scss'],
})

export class SiteAnalyticsBranchPortCheckComponent implements OnInit {
  @Input() site_id;
  @Input() multiSelect;

  devEnv = devEnv;
  contentFit = true;
  outdoor_units = [];
  selected_odus = [];
  error_message: string;
  data_loading: boolean;
  devTogglesEnabled = false;
  creatingBranchPortCheck: boolean;
  selected_odus_label = `Select Outdoor Unit(s)`;
  TemperatureConversions = TemperatureConversions;
  TemperaturePreferenceEnum = TemperaturePreferenceEnum;
  shortTitle = MaintenanceJobTypeEnumLabels.Incorrect_Port;
  title = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Incorrect_Port];
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // For HTML Enum Access
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle; // For HTML Enum Access
  MaintenanceJobTypeEnumLabels = MaintenanceJobTypeEnumLabels; // For HTML Enum Access

  constructor(
    public user: UserService,
    private siteService: SiteService,
    public modalController: ModalController,
  ) {
    this.error_message = ``;
    this.creatingBranchPortCheck = false;
  }

  ngOnInit() {
    this.refreshOutdoorUnits(this.user.active.id);
  }

  renderSelectionLabel() {
    this.selected_odus_label = `Select Outdoor Unit${this.multiSelect ? `(s)` : ``}`;
    if (this.selected_odus && this.selected_odus.length > 0) {
      this.selected_odus_label = this.selected_odus[0].name;
      if (this.selected_odus.length > 1) this.selected_odus_label = `Multi. units selected`;
    }
    return this.selected_odus_label;
  }

  refreshOutdoorUnits(site_id: string) {
    // get a fresh list of outdoor units
    this.outdoor_units = [];
    this.data_loading = true;
    this.siteService.get_site_gateways_with_groups(site_id, true).subscribe(gateways => {
      let gateways_with_groups: Gateway[] = [];

      if (gateways) {
        gateways_with_groups = gateways[`gateways`] as Gateway[];
        gateways_with_groups.forEach(gwg => {
          // in this env we only want RMD gateways - if were here there must be at least 1
          if (gwg.model.class_name == GatewayModelClass.RMD) {
            gwg.outdoor_units.forEach(odu => {
              this.outdoor_units.push(new OutdoorUnitDetails(odu, gwg, this.user.activeSiteUserLevel === LevelEnum.Owner));
            })
          }
        })

        this.outdoor_units = this.outdoor_units.sort(this.compareValues(`name`, `asc`));
        this.data_loading = false;
      }
    })
  }

  readyForSubmission() {
    // are we good to press start?
    let selected = false;
    this.outdoor_units.forEach(odu => {
      if (odu.checked) selected = true;
    });
    return selected;
  }

  async onSelectOutdoorUnit() {
    // display options to select an ODU
    if (!this.siteService.handleIsConnected()) return;
  
    const modal = await this.modalController.create({
      component: SiteAnalyticsTestRunOduSelectComponent,
      cssClass: `me-custom-modal-standard`,
      backdropDismiss: true,
      componentProps: {
        multiSelect: this.multiSelect,
        outdoor_units: this.outdoor_units,
        selected_units: this.selected_odus,
        job_id: MaintenanceJobTypeEnum.Incorrect_Port,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (!this.siteService.handleIsConnected()) return;

      if (data && data.data && `upgrade` in data.data) {
        // close form with a redirect request.
        this.modalController.dismiss(data.data);
      } else if (data && data.data && `success` in data.data) {
        // successful return. Update display.
        this.selected_odus = data.data.success;
        this.creatingBranchPortCheck = true;
      }
    });

    return await modal.present();
  }

  compareValues(key, order = `asc`) {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      //      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].toString().localeCompare(b[key]);
      return order === `desc` ? comparison * -1 : comparison;
    };
  }

  close() {
    this.modalController.dismiss({ 'cancel': true });
  }
}