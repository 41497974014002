import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMouseOverOpacity]'
})
export class MouseOverOpacityDirective {

  constructor(private el: ElementRef) {
  }
  
  @HostListener('mouseenter') onMouseEnter() {
    this.el.nativeElement.style.opacity = '.92';
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.el.nativeElement.style.opacity = '1';
  }
  
}
