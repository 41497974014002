import { Component, OnInit, Input } from '@angular/core';
import { Account } from 'src/app/features/account/classes/account';
import { ModalController, LoadingController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { KenzaPermission } from 'src/app/common/classes/KenzaPermission';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { AccountDetail } from 'src/app/features/account/classes/accountdetail';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';

@Component({
  selector: 'app-site-member-detail-admin',
  templateUrl: './site-member-detail-admin.page.html',
  styleUrls: ['./site-member-detail-admin.page.scss'],
})
export class SiteMemberDetailAdminPage implements OnInit {
  @Input() parentSiteId: string;
  @Input() parentSiteName: string;
  @Input() parentMember: Account;

  siteMemberDetailAdminForm: UntypedFormGroup;
  siteMemberRoles = [];
  disableForm = true;

  constructor(
    private user: UserService,
    private siteService: SiteService,
    private loading: LoadingController,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private authService: AppAuthenticationService,
  ) { 
    
  }

  ngOnInit() {
    this.createSiteMemberDetailAdminForm(this.parentMember.id);
    this.initializeSiteMemberDetailAdminForm(this.parentSiteId, this.parentMember.id, this.parentSiteName, this.parentMember);
    this.siteMemberRoles = this.authService.getChangeableRoleList(this.user.activeSiteUserLevel, this.parentMember.level);
  }

  createSiteMemberDetailAdminForm(account_id: string) {

    this.siteMemberDetailAdminForm = this.formBuilder.group({
      site_id: [''],
      account_id: [''],
      site_name: [''],
      memberNameOrEMail: [''],
      currentMemberSiteLevel: [''],
      currentMemberSiteLevelText: [''],
      newMemberSiteLevel: [''],
    });
  }

  initializeSiteMemberDetailAdminForm(site_id: string, account_id: string, site_name: string, memberAccount: Account) {

    this.user.getAccountDetailsById(account_id).subscribe((memberAccountDetail: AccountDetail) => {
      this.siteMemberDetailAdminForm.setValue({
        site_id,
        account_id,
        site_name,
        memberNameOrEMail: memberAccountDetail.fname && memberAccountDetail.lname ? memberAccountDetail.fname + ' ' + memberAccountDetail.lname : memberAccountDetail.email,
        currentMemberSiteLevel: memberAccount.level,
        currentMemberSiteLevelText: memberAccount.levelDisplayText,
        newMemberSiteLevel: memberAccount.level,
      });

    });
  }

  onSelectRole(event) {
    const selectedLevel = event.target.value;
    const currentMemberSiteLevel = this.siteMemberDetailAdminForm.get('currentMemberSiteLevel').value;
    this.disableForm = (selectedLevel == currentMemberSiteLevel);
  }

  mapFormToKenzaPermission(): KenzaPermission {
    const mapped: KenzaPermission = {
      id: '',
      level: this.siteMemberDetailAdminForm.get('newMemberSiteLevel').value,
      account_id: this.siteMemberDetailAdminForm.get('account_id').value,
      site_id: this.siteMemberDetailAdminForm.get('site_id').value,
      created_at: null,
      removed_at: null,
    };

    return mapped;
  }

  onCancel() {
    this.modalController.dismiss(
      {
        modalResponseData: null,
      }
    );
  }

  async onSubmit() {

    if (!this.siteService.handleIsConnected())
      return;

    const load = await this.loading.create({
      spinner: 'lines',
      message: 'Changing user role...'
    });

    load.present();

    const kenzaPermission = this.mapFormToKenzaPermission();

    this.siteService.setSiteAccountPermission(kenzaPermission).subscribe({
      next: () => {
        this.modalController.dismiss(
          {
            modalResponseData: kenzaPermission,
          }
        );
      },
      error: (e) => console.error(e),
      complete: () => {
        if(load != null || load != undefined) {
          load.dismiss();
        }
      }
  })

  }

}
