import { Component, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from '../../services/site.service';
import { Site } from '../../classes/site';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { AccountService } from 'src/app/features/account/services/accountService';

@Component({
  selector: 'app-site-gateway-move-payment-warning-payment-warning',
  templateUrl: './site-gateway-move-payment-warning.page.html',
  styleUrls: ['./site-gateway-move-payment-warning.page.scss'],
})
export class SiteGatewayMovePaymentWarningPage /*implements OnInit*/ {
  @Input() selectedGateway: Gateway;
  @Input() currentSiteId: string;
  @Input() movedToSite: Site;
  @Input() cardType: string;
  @Input() cardLastFour: string;

  moveError = '';

  constructor(
    public modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    public user: UserService,
    public siteService: SiteService,
    public loadingController: LoadingController,
    public accountService: AccountService
  ) { }

  // ngOnInit() {
  // }

  cancelMove() {
    this.modalController.dismiss({
    });
  }

  async moveGateway() {
    if (!this.siteService.handleIsConnected()) return;

    const loading = await this.loadingController.create({
      message: 'Moving gateway...',
      spinner: 'lines',
    });

    await loading.present();

    const params = {
      last_modified_account_id: this.user.id,
      move_from_site_id: this.user.active.id,
      move_to_site_id: this.movedToSite.id,
      gateway_id: this.selectedGateway.id,
      skip_payment_check: true,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    await this.siteService
      .moveGateway(
        params
      )
      .toPromise()
      .then((result) => { // Gateway Move Success

        if ('error' in result) {
          // then we failed for some reason.
          this.moveError = <string>result['error']
          //this.disableMoveButton = true;
        } else { // Gateway Move Success

          this.selectedGateway.site_id = this.movedToSite.id;
          // update locations in the  user site list
          this.user.updateSiteLocations(this.user.active.id, result['from_site_locations']);
          this.user.updateSiteLocations(this.movedToSite.id, result['to_site_locations']);
          // update locations in this components object
          this.movedToSite.update_locations(result['to_site_locations']);
          this.siteService.siteEdited.emit({ type: `Refresh Notifications` });
          this.accountService.subscriptionDetailsUpdated = true;              

          this.modalController.dismiss({
            moved: true,
            movedGateway: this.selectedGateway,
            movedFromSite: this.user.active,
            movedToSite: this.movedToSite,
          });
        }
      })
      .catch((error) => {
        this.moveError = `Error: ${error.message}`;
      })
      .finally(() => {
        loading.dismiss();
      });
  }

}
