import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-troubleshooting',
  templateUrl: './troubleshooting.component.html',
  styleUrls: ['./troubleshooting.component.scss'],
})

export class TroubleshootingComponent implements OnInit {
  @Input() troubleshootingDetails: any[] = [];

  // models and detail codes from the provided details
  models: string[] = [];
  global_detail_error_codes: { [key: string]: string[] } = {};

  // detail error codes based on the selected model
  detail_error_codes: string[] = [];

  // tied to the component selected
  selected_model: string;
  selected_detail_error_code: string;
  key_of_selected_detail_error_code: string;

  // tip currently being displayed
  displayOptions: any = null;

  devEnv = devEnv;
  splitText = false;
  dynamicIndex = false;

  constructor(
    public modalController: ModalController,
  ) { 
    // Empty
  }

  ngOnInit() {

    // populate models and detail_error_codes
    this.troubleshootingDetails.forEach((n: any) => {
      let model: string = n.related_models[0];
      let detail_error_code: string[] = Object.values(n.error_detail_code);

      if (!this.models.includes(model)) this.models.push(model);
      if (!(model in this.global_detail_error_codes)) this.global_detail_error_codes[model] = [];

      detail_error_code.forEach((errCode) => {
        if (errCode == ``) errCode = `default`;
        if (!this.global_detail_error_codes[model].includes(errCode)) {
          this.global_detail_error_codes[model].push(errCode);
          // sort.
          this.global_detail_error_codes[model].sort();
        }
      })
    })

    // now default to first model, first detail code
    this.selected_model = this.models[0];
    // set the detail error codes
    this.modelSelected();
    this.selected_detail_error_code = this.detail_error_codes[0];
    // set the displayOptions
    this.detailErrorCodeSelected();
  }

  modelSelected() {
    // set the displayed options to the selected item.
    let current_selected_detail_error_code = this.selected_detail_error_code;
    this.detail_error_codes = this.global_detail_error_codes[this.selected_model];
    if (!(this.detail_error_codes.includes(current_selected_detail_error_code))) {
      // reset the selected detail_error_code
      this.selected_detail_error_code = this.detail_error_codes[0];
    }
    // update the displayed tip
    this.detailErrorCodeSelected();
  }

  detailErrorCodeSelected() {
    let code_to_lookup = this.selected_detail_error_code == `default` ? `` : this.selected_detail_error_code;
    this.displayOptions = this.troubleshootingDetails.find(i => (i.related_models[0] === this.selected_model && Object.values(i.error_detail_code).includes(code_to_lookup)));
    this.key_of_selected_detail_error_code = Object.entries(this.displayOptions.error_detail_code).find(ent => ent[1] == this.selected_detail_error_code)[0];
  }

  // ensurePeriodAtEnd(stringToValidate) {
    // if (stringToValidate.endsWith(`.`) || stringToValidate.endsWith(`,`)) {
    //   return stringToValidate.substring(0, stringToValidate.length - 1) + `.`;
    // }
    // return stringToValidate;
  // }

  splitTroubleshootingText(text) {
    let splitPatterns = {
      on_Every_Space: ` `,
      on_Every_Opening_Paranthesee: `(`,
      on_Every_Capital_Letter_Followed_By_Lowercase_Letter: /(?=[A-Z][a-z])/,
      on_Every_Number_Or_Number_Followed_By_Close_Paranthesee: /\d+\)|\(\d+\)/,
      Keep_Every_Number_Or_Number_Followed_By_Close_Paranthesee: /(?=\(\d+\))|(?=\d+\))/,
      on_Every_String_Followed_By_Close_Paranthesee: /(\([\d]\))|([A-Z])|\.\s|[0-9]\)\s/g,
    }

    // const pattern = splitPatterns.on_Every_Capital_Letter_Followed_By_Lowercase_Letter;
    const pattern = this.dynamicIndex == true ? splitPatterns.on_Every_Number_Or_Number_Followed_By_Close_Paranthesee : splitPatterns.Keep_Every_Number_Or_Number_Followed_By_Close_Paranthesee;
    let splitTextArray = text.split(pattern);

    let minLength = this.dynamicIndex == true ? 3 : 5;

    if (pattern == splitPatterns.on_Every_Number_Or_Number_Followed_By_Close_Paranthesee || pattern == splitPatterns.Keep_Every_Number_Or_Number_Followed_By_Close_Paranthesee) splitTextArray = splitTextArray.filter(part => part.trim() != ``);
    splitTextArray = splitTextArray.filter(part => part.length > minLength);

    return splitTextArray;
  }

  close() {
    this.modalController.dismiss();
  }
}