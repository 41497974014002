import { utimes } from "fs"
import { GatewayUnitTwoDigitType } from "src/app/enumerations/enums"
import { GatewayUnit, Mode } from "src/app/features/manage/components/classes/GatewayUnit"
import { ControlDisplay } from "./controlDisplay"
import { ControlSection } from "./controlSection"

export class ModeControl extends ControlSection {
    // the control block for IC mode options
    label: string                   // the text to display beside the control
   
    
    heat: ControlDisplay            // how to render the Heat button
    cool: ControlDisplay            // how to render the Cool button
    dry: ControlDisplay             // how to render the Dry button
    fan: ControlDisplay             // how to render the Fan button
    setback: ControlDisplay         // how to render the Setback button
    auto: ControlDisplay            // how to render the Auto button


    firstUnitMode:Mode              // mode of the first unit added to the group
                                    // used to calcuate singleMode
    singleMode:boolean              // are all units in this batch group in the same mode?
    
    constructor() {
        super()

        this.heat = new ControlDisplay;
        this.cool = new ControlDisplay;
        this.dry = new ControlDisplay;
        this.fan = new ControlDisplay;
        this.setback = new ControlDisplay;
        this.auto = new ControlDisplay;

        this.firstUnitMode = null;
        this.singleMode = true;
    }

    resetSectionCounters() {
        // set our couters to zero
        this.heat.reset();
        this.cool.reset();
        this.dry.reset();
        this.fan.reset();
        this.setback.reset();
        this.auto.reset();

        this.firstUnitMode = null;
        this.singleMode = true;
    }

    addGroupToBatch(unit: GatewayUnit) {
        // add details of this unit re: mode

        // current ic modes
        if (unit.isCoil()) {
            
            // supported ic modes
            if (unit.isHeatModeDisabled()) this.heat.addDisabled();
            if (unit.isDryModeDisabled()) this.dry.addDisabled();
            if (unit.isSetbackModeDisabled()) this.setback.addDisabled();
            if (unit.isFanModeDisabled()) this.fan.addDisabled();
            if (unit.isAutoModeDisabled()) this.auto.addDisabled();

            // current mode
            if (unit.isInHeat()) this.heat.addSelected();
            else if (unit.isInCool()) this.cool.addSelected();
            else if (unit.isInDry()) this.dry.addSelected();
            else if (unit.isInFan()) this.fan.addSelected();
            else if (unit.isInSetback()) this.setback.addSelected();
            else if (unit.isInAuto()) this.auto.addSelected();

            // set the single mode boolean
            if (this.firstUnitMode == null) this.firstUnitMode = unit.baseMode();
            if (!unit.isInThisMode(this.firstUnitMode)) this.singleMode = false;
        } 
    }

    setDisplayOptions(groupCount:number, maintenanceJobActive: boolean) {
        // called when all groups are added and we should set our controllable/display states

        // set IC mode options - mode
        this.heat.setDisplayOptions(groupCount, maintenanceJobActive)
        this.cool.setDisplayOptions(groupCount, maintenanceJobActive)
        this.dry.setDisplayOptions(groupCount, maintenanceJobActive)
        this.fan.setDisplayOptions(groupCount, maintenanceJobActive)
        this.setback.setDisplayOptions(groupCount, maintenanceJobActive)
        this.auto.setDisplayOptions(groupCount, maintenanceJobActive)

        const labels = this.getLabelAndIcon()
        this.label = labels[0]
        this.label_icon = labels[1]        
    }

    
    getLabelAndIcon() {
        // determine and return the mode label and icon to display
        let lbl = 'Mixed'
        let icon = ''
        if (this.heat.selected) {
            icon = this.displayOptions.getModeIcon(Mode.heat)
            lbl = Mode.heat;
        } else if (this.cool.selected) {
            icon = this.displayOptions.getModeIcon(Mode.cool)
            lbl = Mode.cool;
        } else if (this.dry.selected) {
            icon = this.displayOptions.getModeIcon(Mode.dry)
            lbl = Mode.dry;
        } else if (this.fan.selected) {
            icon = this.displayOptions.getModeIcon(Mode.fan)
            lbl = Mode.fan;
        } else if (this.setback.selected) {
            icon = this.displayOptions.getModeIcon(Mode.setback)
            lbl = Mode.setback;
        } else if (this.auto.selected) {
            icon = this.displayOptions.getModeIcon(Mode.auto)
            lbl = Mode.auto;
        }

        return [lbl, icon]
    }


}
