import { filter } from 'rxjs/operators';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { RecurlyService } from '../../services/recurly/recurly.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
})

export class CreditCardFormComponent implements OnInit, OnDestroy {

  detectFormChanges: (any) = globalFunctions.detectFormChanges;
  @Input() useMaskito?: boolean = false;
  billingDisabled = false;
  devEnv = devEnv;
  cardElement;
  cardChange;
  elements;

  ccForm = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [
      Validators.required, Validators.minLength(1), Validators.maxLength(50),
        Validators.pattern(/\S+/) // at least 1 non-whitespace char
    ]),
    last_name: new UntypedFormControl('', [
      Validators.required, Validators.minLength(1), Validators.maxLength(50),
        Validators.pattern( /\S+/) // at least 1 non-whitespace char
    ]),
    address1: new UntypedFormControl('', [
      Validators.required, Validators.minLength(3), Validators.maxLength(100),
        // at least 3 non-whitespace, possibly seperated by whitespace
        Validators.pattern(/(\S+\s*){3,}/)
    ]),
    address2: new UntypedFormControl('', []),
    city: new UntypedFormControl('', [
      Validators.required, Validators.minLength(3), Validators.maxLength(50),
        // at least 3 non-whitespace, possibly seperated by whitespace
        Validators.pattern(/(\S+\s*){3,}/)
    ]),
    state: new UntypedFormControl(''),
    state_select: new UntypedFormControl('', [Validators.required]),
    postal_code: new UntypedFormControl('', [
      Validators.compose([
        Validators.required,
        Validators.pattern(/^\d{5}$|^\d{5}-$|^\d{5}-[_]{4}$|^\d{5}-\d{4}$/),
        Validators.minLength(5),
        Validators.maxLength(10),
      ]),
    ])
  });

  tokenIsDirty = true; // controls whether we should request a token again at end prior to use.
                      // i.e. the form has changed _after_ we requested a token
  formIsValid = false;
  formDidSubmit = false;
  formIsSubmitting = false;
  ccFormCurrentState = 'INVALID';
  recurlyFormCurrentState = false;
  // tracks errors back from recurly on card number, expiry and cvv
  ccFormFieldError = ''
  ccFormFieldStates = {
    numberHadFocus: false,
    numberTouched: false,
    numberDirty: false,
    expiryHadFocus: false,
    expiryTouched: false,
    expiryDirty: false,
    cvvHadFocus: false,
    cvvTouched: false,
    cvvDirty: false
  }

  @Output() formChange = new EventEmitter<any>();

  constructor(
    private recurly: RecurlyService,
  ) {
    this.billingDisabled = this.recurly.isMocked();
    if (this.billingDisabled) this.ccFormCurrentState = 'VALID';
    this.elements = this.recurly.getElements();
    this.cardElement = this.elements.CardElement({
      style: {
        border: 'none'
      }
    });
    this.cardElement.on('change', (state) => {
      this.cardDidChange(state);
    })
    this.ccForm.statusChanges
      .pipe(filter(s => {
        return s !== this.ccFormCurrentState
      }))
      .subscribe(val => this.formStatusDidChange());

    this.ccForm.valueChanges.subscribe(val => this.formValueDidChange());
  }

  formStatusDidChange() {
    this.ccFormCurrentState = this.ccForm.status;
    this.submitTokenRequest();
  }

  formValueDidChange() {
    // something on the form changes.
    // set the token 'dirty'.
    this.tokenIsDirty = true;
  }

  async submitTokenRequest(forceUpdate:boolean = false) {
    const formRecheck = this.ccFormCurrentState === 'VALID' && this.recurlyFormCurrentState;

    if ((formRecheck !== this.formIsValid) && !formRecheck) {
      this.formDidSubmit = false;
      this.formChange.emit({ valid: false });
    }

    this.formIsValid = formRecheck;

    if (this.formDidSubmit && !forceUpdate) return;
    if (!formRecheck) return;

    this.formDidSubmit = true;

    setTimeout(() => this.recurly.createToken(
        this.elements,
        document.getElementById('recurly-form'),
        this.recurlyTokenResponse.bind(this)));
  }

  recurlyTokenResponse(e, val: { id: string }) {
    // There are occasional cvv issues when the cvv is technically valid
    // Amex does this. 378734493671000

    if (e) {
      this.recurlyFormCurrentState = false;
      this.formDidSubmit = false;

      // parse the error.
      if (e.code == 'invalid-parameter') {
        for (const field of e.fields) {
          if (field == 'postal_code') {
            // set it invalid.
            this.ccForm.controls.postal_code.setErrors({ 'pattern': true });
          } else if (field == 'first_name') {
            // set it invalid.
            this.ccForm.controls.first_name.setErrors( { 'recurly': true, 'message': e.message })
          } else if (field == 'last_name') {
            // set it invalid
            this.ccForm.controls.last_name.setErrors( { 'recurly': true, 'message': e.message})
          }
          else {
            // set it invalid
            this.ccFormFieldError = e.message;
          }
        }
      }

      return;
    }

    if (!val || !val.id) return;

    this.tokenIsDirty = false;

    this.formChange.emit({
      valid: true,
      token: val.id,
      customerForm: this.ccForm.getRawValue()
    });
  }

  async cardDidChange(state) {
    this.recurlyFormCurrentState = state.valid;
    // errors here?

    let ccNumberError = false;
    let ccExpiryError = false;
    let ccCvvError = false;
    let ccNumberReq = false;
    let ccExpiryReq = false;
    let ccCvvReq = false;

    // update ccFormFieldStates

    // check number field
    if (state.number.focus) {
      this.ccFormFieldStates.numberHadFocus = true;
    } else {
      // if we HAD focus - and now don't then we're touched.
      if (this.ccFormFieldStates.numberHadFocus) {
        this.ccFormFieldStates.numberTouched = true;
      }
    }
    if (!state.number.empty) this.ccFormFieldStates.numberDirty = true;

    // set number error state booleans.
    if (state.number.empty && this.ccFormFieldStates.numberTouched) {
      ccNumberReq = true;
    } else if (!state.number.valid && this.ccFormFieldStates.numberDirty) {
      ccNumberError = true;
    }

    // check expiry field
    if (state.expiry.focus) {
      // then update state
      this.ccFormFieldStates.expiryHadFocus = true;
    } else {
      // if we HAD focus - and now don't then we're touched.
      if (this.ccFormFieldStates.expiryHadFocus) {
        this.ccFormFieldStates.expiryTouched = true;
      }
    }
    if (!state.expiry.empty) this.ccFormFieldStates.expiryDirty = true;

    // set expiry error state booleans.
    if (state.expiry.empty && this.ccFormFieldStates.expiryTouched) {
      ccExpiryReq = true;
    } else if (!state.expiry.valid && this.ccFormFieldStates.expiryDirty) {
      ccExpiryError = true;
    }

    // check cvv field
    if (state.cvv.focus) {
      // then update state
      this.ccFormFieldStates.cvvHadFocus = true;
    } else {
      // if we HAD focus - and now don't then we're touched.
      if (this.ccFormFieldStates.cvvHadFocus) {
        this.ccFormFieldStates.cvvTouched = true;
      }
    }
    if (!state.cvv.empty) this.ccFormFieldStates.cvvDirty = true;

    // set cvv error state booleans.
    if (state.cvv.empty && this.ccFormFieldStates.cvvTouched) {
      ccCvvReq = true;
    } else if (!state.cvv.valid && this.ccFormFieldStates.cvvDirty) {
      ccCvvError = true;
    }

    if (ccNumberReq) this.ccFormFieldError = "Credit Card Number is required"
    if (ccNumberError) this.ccFormFieldError = "Please enter a valid Credit Card Number"
    if (ccNumberError || ccNumberReq || ccExpiryError || ccExpiryReq || ccCvvError || ccCvvReq) {
      // then we have an error.
      this.ccFormFieldError = '';
      if (ccNumberReq) this.ccFormFieldError += "Credit Card Number is required"
      if (ccNumberError) this.ccFormFieldError += "Enter valid Credit Card Number"

      if (this.ccFormFieldError.length > 0 && (ccExpiryReq || ccExpiryError)) this.ccFormFieldError += ", "

      if (ccExpiryReq) this.ccFormFieldError += "Expiry is required"
      if (ccExpiryError) this.ccFormFieldError += "Enter a valid Expiry"

      if (this.ccFormFieldError.length > 0 && (ccCvvReq || ccCvvError)) this.ccFormFieldError += ", "

      if (ccCvvReq) this.ccFormFieldError += "CVV is required"
      if (ccCvvError) this.ccFormFieldError += "Enter a valid CVV"

    } else {
      // we're clear.
      this.ccFormFieldError = '';
    }

    this.submitTokenRequest();
  }

  ngOnInit() {
    this.cardElement.attach('#recurly-elements');
    this.detectFormChanges(this.ccForm);
  }

  ngOnDestroy() {
    this.cardElement.off('change');
  }
}
