import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { Site } from 'src/app/features/sites/classes/site';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { UserService } from 'src/app/common/services/user/user.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SiteTransferAcceptanceModal } from '../../pages/site-transfer-acceptance-modal/site-transfer-acceptance-modal.page';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.page.html',
  styleUrls: ['./site-select.page.scss'],
})

export class SiteSelectPage implements OnInit {
  @Input() parentIgnoreSameSiteSelected: any;
  @Input() parentSelectedSiteInfo: any;
  @Input() parentSiteList: Site[];
  originalSiteList: any[] = [];
  siteList: any[] = [];
  selectedSite: Site = null;
  parentSelectedSite: Site = null;
  ignoreSameSiteSelected = false;
  devEnv = devEnv;

  filter: SiteAlertFilter = null;

  constructor(
    private router: Router,
    public user: UserService,
    private location: Location,
    public siteService: SiteService,
    private modalController: ModalController,
    public mainSiteUIService: MainSiteUIService,
  ) {
    // Empty
  }

  ngOnInit() {
    this.ignoreSameSiteSelected = this.parentIgnoreSameSiteSelected;
    this.parentSelectedSite = this.parentSelectedSiteInfo;
    if (this.parentSelectedSite.id !== 'default-site') {
      this.selectedSite = this.parentSelectedSite;
    }

    this.originalSiteList = this.parentSiteList.filter((x, y, z) => z.findIndex(t => t.id == x.id) == y).sort(this.compareValues('name', 'asc'));

    this.siteList = this.originalSiteList.map(site => {
      const isChecked: boolean = (this.selectedSite == null) || (site.id !== this.selectedSite.id) ? false : true;
      return Object.assign(site, { isChecked });
    });

    this.mainSiteUIService.siteSelectionOpen = true;
  }

  async acceptSite(siteId) {
    if (!this.siteService.handleIsConnected()) return;
    if (this.mainSiteUIService.siteTransferAcceptanceOpen == true) return;

    const modal = await this.modalController.create({
      component: SiteTransferAcceptanceModal,
      cssClass: 'me-sc-ion-modal-md-h-5',
      backdropDismiss: false,
      componentProps: {
        siteId: siteId,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.mainSiteUIService.siteTransferAcceptanceOpen = false;
      if (data.data?.transferSuccessful) {
        this.router.navigate(['/home']);
        if (this.mainSiteUIService.siteSelectionOpen == true) this.close();
      } else {
        if (data.data?.wasAuthError) { // doing this because having issue with siteList refreshing after redirecting from the 403 page
          let fixedSiteList = [];
          this.siteList.forEach(s => {
            if (s.id != siteId) {
              fixedSiteList.push(s);
            }
          });
          this.siteList = fixedSiteList;
        }

        this.router.navigate(['/home']);
      }
    });

    if (this.mainSiteUIService.siteTransferAcceptanceOpen == false) {
      this.mainSiteUIService.siteTransferAcceptanceOpen = true;
      return await modal.present();
    }
  }

  setClickedSelection(site: Site) {
    // if a transfer site
    if (site.transfer_site) {
      let onHomePage = window.location.href.includes(`home`);
      if (!onHomePage) {
        this.user.showTransferModal = true;
        this.user.transferSiteId = site.id;
        this.router.navigate(['/home']);
      } else {
        this.acceptSite(site.id);
      }
      return;
    }

    if ((site !== this.selectedSite) || (this.parentIgnoreSameSiteSelected == true)) {
      this.selectedSite = site;
    } else {
      this.selectedSite = null;
    }

    // saving the alerts filter state when switching sites on the alerts page (KEN-2211)
    if (this.router.url.includes("alerts")) {
      this.filter = this.mainSiteUIService.getSiteAlertFilter();
    }
    this.modalController.dismiss({
      itemSelected: this.selectedSite === null ? false : true,
      selectedSite: this.selectedSite,
      alertFilter: this.filter,
    });

    if (!this.location.path().includes('site')) {
      if (!this.location.path().includes('manage')) {
        this.router.navigate(['/site', site.id, 'dashboard']);
      } else if (this.location.path().includes('members')) {
        this.router.navigate(['/manage', site.id, 'members']);
      } else if (this.location.path().includes('gateway')) {
        this.router.navigate(['/manage', site.id, 'gateways']);
      }
    } else {
      if (this.location.path().includes('alerts/notification')) {
        this.router.navigate(['/site', site.id, 'alerts']);
      }
    }
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      const comparison = a[key].localeCompare(b[key]);

      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  setSelection(site) {
    if (site !== this.selectedSite) {
      this.selectedSite = site;
    } else {
      this.selectedSite = null;
    }
  }

  dismissModal(action) {
    this.mainSiteUIService.siteSelectionOpen = false;
    this.modalController.dismiss({
      itemSelected: this.selectedSite === null ? false : true,
      selectedSite: action === 'ok' ? this.selectedSite : this.parentSelectedSite,
    });
  }

  close() {
    this.mainSiteUIService.siteSelectionOpen = false;
    this.modalController.dismiss({
      itemSelected: false,
      selectedSite: null,
    });
  }

}