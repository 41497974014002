import { SiteNotificationTypeEnum } from "src/app/enumerations/enums";
import { DateStringUtilities } from 'src/app/common/utilities/stringUtilities';

export class SiteNotification {

  id: string;
  site_id: string;
  site_name: string;
  sitenotificationtype_id: number;
  notification: string;
  created_at: Date;
  created_account_id: string;
  acknowledged_at: Date;
  acknowledged_account_id: string;
  acknowledged_by: string;
  acknowledged_by_role: string;
  context: any;
  notification_labels: any;
  display_notification_labels: any;
  isNotificationAcknowledgeable: any;
  isNotificationViewable: any;

  constructor(
    id: string,
    site_id: string,
    sitenotificationtype_id: number,
    created_at: Date,
    created_account_id: string,
    acknowledged_at: Date,
    acknowledged_account_id: string,
    context: any,
    notification_labels: any,
  ) {
    this.id = id;
    this.site_id = site_id;
    this.sitenotificationtype_id = sitenotificationtype_id;
    this.created_at = created_at;
    this.created_account_id = created_account_id;
    this.acknowledged_at = acknowledged_at;
    this.acknowledged_account_id = acknowledged_account_id;
    this.context = context;
    this.notification_labels = notification_labels;
  }
}

export function siteNotification_textSearch(sn: SiteNotification, searchText: string): boolean {
  // consider all the string fields of the notification - looking for this searchText
  // return wither we can find it anywhere.

  if (!searchText) {
    // nothing to do
    return;
  }
  
  let lcSearchText = searchText.toLowerCase();
  if (sn.notification.toLowerCase().includes(lcSearchText)) return true;
  // consider all fields in the context
  for (const nameOfProperty in sn.context) {
    if (typeof sn.context[nameOfProperty] == 'string') {
      let nonContextFields = [`id`, `unit_id`];
      if (!nonContextFields.includes(nameOfProperty) && sn.context[nameOfProperty].toLowerCase().includes(lcSearchText)) {
        return true;
      };
    } //else {
    //console.log('value of ' + k + ' not a string: ' + sn.context[k]);
    //}
  }
  // consider all fields in the additionalLabels that are referenced in the field order
  // they are the display fields.
  if (sn.notification_labels) {
    if ('order' in sn.notification_labels) {
      let fields = sn.notification_labels['order'].split(',');
      fields.forEach((field: string) => {
        if (typeof sn.notification_labels[field] == 'string') {
          if (sn.notification_labels[field].toLowerCase().includes(lcSearchText)) return true;
        } //else {
        //console.log(' value of ' + field + ' not a string ' + sn.notification_labels[field])
        //}
      })
    }
  }

  // consider the date fields - convert to a string and compare
  if (sn.created_at != null) {
    let created_at_str = DateStringUtilities.timeFormat(sn.created_at);
    if (created_at_str.toLowerCase().includes(lcSearchText)) return true;
  }
  if (sn.acknowledged_at != null) {
    let acknowledged_at_str = DateStringUtilities.timeFormat(sn.acknowledged_at)
    if (acknowledged_at_str.toLowerCase().includes(lcSearchText)) return true;
  }

  // otherwise - its not there.
  return false;
}
