import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SiteService } from 'src/app/features/sites/services/site.service';

@Component({
  selector: 'map-units-error',
  templateUrl: './map-units-error.component.html',
  styleUrls: ['./map-units-error.component.scss'],
})
export class MapUnitsErrorComponent /*implements OnInit*/ {
  @Input() errorMessage: string;

  constructor(private modalController: ModalController) { }

  // ngOnInit() { }

  close() {
    this.modalController.dismiss();
  }

}
