// Details about an analytics report from the server
import { MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle } from "src/app/enumerations/enums";
import { maintenanceJobStatusEnum } from "src/app/common/classes/MaintenanceJob";
import { globalFunctions } from "src/app/constants/globalFunctions";
import { formatDate } from '@angular/common';

export const convertToMinutesSeconds = (timeInMinutes) => {
    let numericTime = parseFloat(timeInMinutes);
    let minutes = Math.floor(numericTime);    
    let seconds = Math.round((numericTime - minutes) * 60);

    if (seconds === 60) {
        minutes += 1;
        seconds = 0;
    }

    let formattedSeconds = seconds.toString().padStart(2, '0');
    let formattedTime = `${minutes}m ${formattedSeconds}s`;

    return formattedTime;
}

export const calculateMinutesPassed = (start, end) => {
    let endDate: any = new Date(end);
    let startDate: any = new Date(start);
    let millisecondsPassed = endDate - startDate;
    let minutesPassed = millisecondsPassed / (1000 * 60);

    let formattedMinutesPassed = globalFunctions?.removeTrailingZeroDecimal(minutesPassed, 2);
    // let formattedMinutesPassed = convertToMinutesSeconds(globalFunctions?.removeTrailingZeroDecimal(minutesPassed, 2));

    return formattedMinutesPassed;
}

export enum ReportStatusEnum {
    Starting,
    InProcess,
    Complete,
    Canceled, 
    Error
}

export enum ReportStatusString {
    // these must stay in order as the ReprotStatusEnum
    "Starting",
    "In Progress",
    "Complete",
    "Canceled",
    "Error"
}

export const setRefrigValues = (obj, parameters) => {
    obj.mode = `Cooling`;
    obj.al = parameters?.al;
    obj.parameters = parameters;
    obj.loop_number = parameters?.loop_number;
    obj.filling_volume = parameters?.filling_volume;
    return obj;
}

export class ReportDetail {
    id: string;          // unique report id
    siteId: string;     // site identifier - UUID
    siteName: string;   // site name - text
    siteTimezone: string; // the timezone value for the site
    siteTimezoneOffset: string; // the +/-HH:MM offset of the site timezone
    linkEnabled: boolean; // should there be a link to the site in kenza?
    title: string;       // report title - text

    startDate: Date;    // start time/date as data object
    startString: string;   // start time as a HH:MM:SS am/pm
    startTitle: string;  // start time as a full ISO string
    startDisabled: boolean; // should the start date be in a disabled format? 

    endDate: Date;     // ending time/date as date object

    oduName: string;     // name in kenza of the odu unit
    oduNameDisabled: boolean; // should the odu name be in a disabled format?

    oduModel: string;
    oduModelTitle: string;
    oduModelDisabled: boolean; // should the odu model be in a disabled format?

    status: ReportStatusEnum;
    statusString: string;

    progress: number; // 0-1 done of this job
    progressTitle: string;  // this is % complete
    progressMessage: string; // this is Time remaining

    mode: string; // the test run mode selected
    duration: string; // the test run duration in minutes
    trueDuration?: any; // If the job is completed, lets track the true duration in minutes

    parameters?: any; // The Specific Maintenance Job Parameters

    // RLD
    al?: number = 0; // If the Record is an RLD, it should return an AL Percentage, default is 0
    loop_number?: number = 80; // If the Record is an RLD, it should return a Loop Number, default is 80
    filling_volume?: number = 0; // If the Record is an RLD, it should return a Filling Volume, default is 0

    maintenancejob_id: string; // id of the active maintenance_job if there is one

    gateway_id: string; // gateway id this job is for
    ou_address: string; // odu bus address on gateway for this job

    // controls for what option to show for this job.
    viewable: boolean; // should the view details of report option be available?
    cancelable: boolean;  // should the cancel job option be available?
    deleteable: boolean;  // should the delete job option be available?
    csvdownloadable: boolean; // should the download csv option be available?
    mtdzdownloadable: boolean;    // should the download mtdz option be available?

    unitHistoryId: number;

    job_id: number; // The ID of the report or type of maintenance job
    type: string; // The string equivalent of what the job id is
    jobType: string; // The type string of the job

    notApplicableString = `n/a`;

    constructor(record: any = null, locale = null, isSiteMember: boolean = false) {
        if (record) {
            // Maintenance ODU & Report Data
            // console.log(`Record`, record);
            const active_job_started_at = record.active_job ? record.active_job.started_at : 0;
            const active_job_progress = record.active_job ? record.active_job.progress / 100 : 1;
            
            this.id = record.id;
            this.siteId = record.site_id;
            this.siteName = record.site_name;
            this.siteTimezone = record.site_timezone;

            if (record.job_id) {
                let jobType = MaintenanceJobTypeEnum[record.job_id];
                if (jobType && jobType.includes(`_`)) jobType = jobType.replace(/_/g, ` `);
                this.job_id = record.job_id;
                this.type = jobType;
                this.jobType = MaintenanceJobTypeEnumTitle[record.job_id];
            }

            const date = new Date();
            const offsetDate = new Intl.DateTimeFormat(locale, { timeZone: this.siteTimezone , timeZoneName: `longOffset` }).format(date);
            this.siteTimezoneOffset = offsetDate.substring(offsetDate.length - 6);

            this.linkEnabled = isSiteMember;
            this.title = record.site_name;
            this.endDate = null;
            
            this.unitHistoryId = record.unit_history_id;

            if (record.started_at || active_job_started_at) {
                // times from the server should always be interpreted as UTC/Zulu
                this.startDate = new Date( (active_job_started_at ? active_job_started_at : record.started_at) + `Z`);
                this.startString = formatDate(this.startDate, `MM/dd/yyyy hh:mm a`, locale, this.siteTimezoneOffset);
                this.startTitle = formatDate(this.startDate, `long`, locale, this.siteTimezoneOffset);
                this.startDisabled = false;

                if (record.ended_at) { 
                    this.endDate = new Date(record.ended_at + `Z`);
                } else if (record.active_job && record.active_job.ended_at != `1970-01-01T00:00:00`) {
                    // then take the active job end
                    this.endDate = new Date(record.active_job.ended_at + `Z`);
                } // else it will stay as null
            } else {
                // not yet started?
                this.startDate = new Date(active_job_started_at);
                this.startString = `not yet started`;
                this.startTitle = `This job has not yet entered a running state`;
                this.startDisabled = true;
            }

            this.oduName = record.ou_name ? record.ou_name : this.notApplicableString;
            this.oduModel = record.ou_model_name ? record.ou_model_name 
                            : record.kenza_ou_model_name ? record.kenza_ou_model_name
                            : this.notApplicableString;

            this.oduModelDisabled = this.oduModel == this.notApplicableString;
            this.oduModelTitle = this.oduModelDisabled ? `The model of this ODU is not available` : this.oduModel;
            this.status = this.maintenanceJobTaskStatusToReportStatus(record.status);
            this.duration = record.test_run_params ? `${record.test_run_params.testruntime.toString()} minutes` 
                            : record.refrigerant_params ? `Approx. ${record.refrigerant_params.loop_number.toString()} minutes`
                            : record.incorrect_port_params ? `Approx. 5 - 10 minutes per Group` : this.notApplicableString;
            this.trueDuration = this.startDate && this.endDate 
                                ? `Approx. ${calculateMinutesPassed(this.startDate, this.endDate)} minutes` 
                                : this.duration;

            if (record.test_run_params) {
                this.parameters = record.test_run_params;
                this.mode = record.test_run_params.ope_mode;
            } else if (record.refrigerant_params) {
                setRefrigValues(this, record.refrigerant_params);
            } else if (record.incorrect_port_params) {
                this.mode = this.notApplicableString;
                this.parameters = record.incorrect_port_params;
            } else {
                this.mode = this.notApplicableString;
            }

            if (record.active_job) {
                // update state based on active job
                this.status = this.maintenanceJobTaskStatusToReportStatus(record.active_job.status);
            }

            this.statusString = ReportStatusString[this.status];    
            
            this.progress = active_job_progress;
            this.progressTitle = record.active_job ? `${record.active_job.progress}% Complete` : ``;
            this.progressMessage = ``;
            if (record.active_job) this.setProgressMessage(record.active_job.progress);
            
            this.maintenancejob_id = record.active_job ? record.active_job.id : record.maintenancejob_id ? record.maintenancejob_id : null;

            this.gateway_id = record.gateway_id ? record.gateway_id : null;
            this.ou_address = record.ou_address ? record.ou_address : null;

            this.setOptions();
        }
    }

    isActive() {
        // is this job `active` ?
        return this.status == ReportStatusEnum.InProcess;
    }

    isNotEnded() {
        // is this job not over?
        return this.status == ReportStatusEnum.Starting || this.status == ReportStatusEnum.InProcess;
    }

    static initFromReport(reportDetail: any) {
        // make a report from the members of another report
        const rep = new ReportDetail();

        rep.id = reportDetail.id;
        rep.siteId = reportDetail.siteId;
        rep.siteName = reportDetail.siteName;
        rep.siteTimezone = reportDetail.siteTimezone;
        rep.siteTimezoneOffset = reportDetail.siteTimezoneOffset;
        rep.linkEnabled = reportDetail.linkEnabled;
        rep.title = reportDetail.title;

        if (reportDetail.job_id) {
            let jobType = MaintenanceJobTypeEnum[reportDetail.job_id];
            if (jobType && jobType.includes(`_`)) jobType = jobType.replace(/_/g, ` `);
            rep.job_id = reportDetail.job_id;
            rep.type = jobType;
        }

        if (reportDetail?.parameters) rep.parameters = reportDetail?.parameters;
        if (reportDetail?.trueDuration) rep.trueDuration = reportDetail?.trueDuration;
    
        rep.startDate = reportDetail.startDate;
        rep.startString = reportDetail.startString;
        rep.startTitle = reportDetail.startTitle;
        rep.startDisabled = reportDetail.startDisabled;
    
        rep.oduName = reportDetail.oduName;
        rep.oduNameDisabled = reportDetail.oduNameDisabled;
    
        rep.oduModel = reportDetail.oduModel;
        rep.oduModelTitle = reportDetail.oduModelTitle;
        rep.oduModelDisabled = reportDetail.oduModelDisabled;
    
        rep.status = reportDetail.status;
        rep.statusString = reportDetail.statusString;
    
        rep.progress = reportDetail.progress;
        rep.progressTitle = reportDetail.progressTitle;
        rep.progressMessage = reportDetail.progressMessage;
    
        rep.mode = reportDetail.mode;
        rep.duration = reportDetail.duration;
    
        rep.maintenancejob_id = reportDetail.maintenancejob_id;
        
        rep.gateway_id = reportDetail.gateway_id;
        rep.ou_address = reportDetail.ou_address;

        rep.endDate = reportDetail.endDate;

        if (reportDetail?.job_id == MaintenanceJobTypeEnum.Refrigerant) {
            setRefrigValues(rep, reportDetail?.parameters);
        }

        rep.setOptions();

        return rep;
    }

    maintenanceJobTaskStatusToReportStatus(maintenanceJobStatus): ReportStatusEnum {
        // map from server MaintenanceJobStatus to ReportJobStatusEnum
        if (maintenanceJobStatus == maintenanceJobStatusEnum.new || maintenanceJobStatus == maintenanceJobStatusEnum.booting) {
            return ReportStatusEnum.Starting;
        } else if (maintenanceJobStatus == maintenanceJobStatusEnum.running) {
            return ReportStatusEnum.InProcess;
        } else if (maintenanceJobStatus == maintenanceJobStatusEnum.complete) {
            return ReportStatusEnum.Complete;
        } else if (maintenanceJobStatus == maintenanceJobStatusEnum.cancel) {
            return ReportStatusEnum.Canceled;
        }
        
        return ReportStatusEnum.Error;
    }        

    // test run updates
    update(update, locale) {
        // update this report detail with new details
        this.progress = parseFloat(update.progress) / 100;
        this.progressTitle = `${update.progress}% Complete`;
        this.status = this.maintenanceJobTaskStatusToReportStatus(update.status);
        this.statusString = ReportStatusString[this.status];

        this.setProgressMessage(parseFloat(update.progress));

        if (update.started_at) {
            this.startDate = update.started_at;
            this.startString = formatDate(this.startDate, `MM/dd/yyyy hh:MM a`, locale, this.siteTimezoneOffset);
            this.startTitle = formatDate(this.startDate, `long`, locale, this.siteTimezoneOffset);
            this.startDisabled = false;
            
        }
        this.setOptions();
    }

    // maint job updates
    updateMaintJobProgress(response, locale) {
        // translate to the update form
        this.update({
            'progress': response.progress,
            'status': response.maintJobStatus,
            'result': response.maintJobResult,
            'started_at': response.started_at
        }, locale)
    }

    setProgressMessage(percentComplete: number) {
        // set the progress message based on % complete and duration values.
        // like '~35 minutes left'
        const minutesRan = Number(((percentComplete * parseInt(this.duration)) / 100).toFixed(0));
        const minutesLeft = Math.max(parseInt(this.duration) - minutesRan , 0);
        this.progressMessage = `~${minutesLeft} min(s) left`;
    }

    setOptions() {
        // based on the current status - set booleans for options.
        switch (this.status) {
            case ReportStatusEnum.Starting:
                this.viewable = false;
                this.cancelable = false;
                this.deleteable = false;
                this.csvdownloadable = false;
                this.mtdzdownloadable = false;
                break;
            case ReportStatusEnum.InProcess:
                this.viewable = true;
                this.cancelable = true;
                this.deleteable = false;
                this.csvdownloadable = false;
                this.mtdzdownloadable = false;
                break;
            case ReportStatusEnum.Complete:
                this.viewable = true;
                this.cancelable = false;
                this.deleteable = true;
                this.csvdownloadable = true;
                this.mtdzdownloadable = false; // disable MTDZ download for now.
                break
            case ReportStatusEnum.Canceled:
                this.viewable = this.startDate != null;
                this.cancelable = false;
                this.deleteable = true;
                this.csvdownloadable = true;
                this.mtdzdownloadable = false;
                break
            case ReportStatusEnum.Error:
                this.viewable = this.startDate != null;
                this.cancelable = false;
                this.deleteable = true;
                this.csvdownloadable = true;
                this.mtdzdownloadable = false;
                break
        }

        if (this.job_id != MaintenanceJobTypeEnum.Test_Run) {
            this.viewable = this.viewable && this.progress >= 0;
        }

        if (this.job_id == MaintenanceJobTypeEnum.Incorrect_Port) {
            this.csvdownloadable = false;
        } else if (this.job_id == MaintenanceJobTypeEnum.Refrigerant) {
            if (this.status == ReportStatusEnum.Canceled || this.status == ReportStatusEnum.Error) {
                this.csvdownloadable = false;
            }
        }
    }

}