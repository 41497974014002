<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Change BC port</ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>
  <img src="/assets/imgs/switch_image.png">
</ion-content>