<ion-content main-background>
  <ion-toolbar class="maintenanceToolbar" color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Maintenance</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>

    <ion-row>
      <ion-col style="text-align:left">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Select test type</h4>
            </ion-title>
          </ion-item>

          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ng-container *ngFor="let b of testTypes; index as i">
                  <ion-col *ngIf="b.isShow">
                    <input type="radio" name="maintenance_test_Type" id="maintenance_test_type{{i}}"
                      [(ngModel)]="selectType" [value]="b.typeId" [checked]="selectType == b.typeId" (change)="show(i)">
                    <label style="padding-left: 5px;">{{b.title}}</label>
                  </ion-col>
                </ng-container>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showMTDZ">
      <ion-col size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Select Equipment</h4>
            </ion-title>

            <div class="ion-icon-wrapper-card-header">
              <ion-button class="button-style" (click)="PageTransition('MTDZ')">
                MTDZ
              </ion-button>
            </div>

            <div class="ion-icon-wrapper-card-header">
              <ion-button class="button-style" (click)="PageTransition('spreadsheet')">
                Spreadsheet
              </ion-button>
            </div>
          </ion-item>
          <ion-card-content>
            <div>
              <table mat-table [dataSource]="dataSourceMverTB7" class="mat-elevation-z8">
                <ng-container matColumnDef="RadioButton">
                  <th mat-header-cell *matHeaderCellDef class="col-select"> Select </th>
                  <td mat-cell *matCellDef="let element" class="col-select">
                    <input type="radio" name="radiobutton_mt" [checked]="element.Checked">
                  </td>
                </ng-container>

                <ng-container matColumnDef="GatewayName">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Gateway Name </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.GatewayName}} </td>
                </ng-container>

                <ng-container matColumnDef="DeviceType">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Device Type </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.DeviceType}} </td>
                </ng-container>

                <ng-container matColumnDef="SerialNumber">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Serial Number </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.SerialNumber}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col size="12">
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-card-content>
            <ion-grid class="calendarline">
              <ion-row>
                <ion-item lines="none" class="cw135">
                  <p>History Time:</p>
                </ion-item>
                <ion-item lines="none" class="cw200">
                  <p>
                    <ng2-flatpickr
                      #flatpickr
                      [config]="options"
                    ></ng2-flatpickr>
                  </p>
                </ion-item>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <maintenance-bcport-list></maintenance-bcport-list>
    <maintenance-refrigerant-list></maintenance-refrigerant-list>
    <maintenance-testrun></maintenance-testrun>
  </ion-grid>
</ion-content>