import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE, TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE, TOAST_CONFIG_FULL_WIDTH } from 'src/app/constants/kenzaconstants';


/** Pass untouched request through to the next request handler. */
@Injectable()
export class CheckConnectivityInterceptor implements HttpInterceptor {

    constructor(public toastService: ToastrService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        if (!window.navigator.onLine) {
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            const error = {
                status: 0,
                error: {
                    description: 'Check Connectivity!'
                },
                statusText: 'Check Connectivity!'
            };
            this.toastService.error(TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE, TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE, TOAST_CONFIG_FULL_WIDTH);


            return throwError(new HttpErrorResponse(error));
        } else {
            // else return the normal request
            return next.handle(req);
        }
    }
}