import { v4 as uuidv4 } from 'uuid';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { Location, formatDate } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { ReportDetail, ReportStatusEnum } from 'src/app/features/manage/components/classes/AnalyticsReportDetail';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { Subscription } from 'rxjs';
import { MaintenanceJobTypeEnum, MaintenanceJobTypeEnumLabels, MaintenanceJobTypeEnumTitle, MaintenanceNotificationType, PressurePreferenceEnum, TemperaturePreferenceEnum, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { UserService } from 'src/app/common/services/user/user.service';
import { MatTable } from '@angular/material/table';
import { PressureConversions, TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { devEnv, WAIT_TIME } from 'src/app/constants/kenzaconstants';
import { globalFunctions } from 'src/app/constants/globalFunctions';

// limits for metrics considered in error
  // 以下、閾値設定
const ts63HS1Limit = 30;
const tsTeLimit = -1;
const tsTH4Limit = 93;
const tsTH2aLimit = 1.6;
const tsTH2bLimit = 8.9;
const tsSHLimit = 18;

enum UnitDataType {
  Numeric = 0,  // numeric, numbers
  TempNonSet = 1, // Temp C - not set point
  TempSet = 2,  // Temp C - set point
  TempDiff = 3,   // Temp C diff
  PresureKcm2 = 4,  // Presure Kilograms/cm2
  PresureMPa  = 5,  // Presure MPa - megapascal
  String = 6  // String data formated into data2
}
@Component({
  selector: 'app-account-analytics-test-run-view',
  templateUrl: './account-analytics-test-run-view.component.html',
  styleUrls: ['./account-analytics-test-run-view.component.scss'],
})

export class AccountAnalyticsTestRunViewComponent implements OnInit {
  @ViewChild(MatTable) metricsTable!: MatTable<any>;   
  @ViewChild(MatTable) testTable!: MatTable<any>;
  
  devEnv = devEnv;
  globalFunctions = globalFunctions;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;
  MaintenanceJobTypeEnumLabels = MaintenanceJobTypeEnumLabels;
  
  progress = 0;
  maintUpdates = 0;
  alPercentage = 0;
  testRunMaintenance = true;
  shortTitle: string = MaintenanceJobTypeEnumLabels.Test_Run;
  reportTitle: string = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Test_Run];

  loadingSpinner;
  csvReport: ReportDetail = null;
  csvRequestId: string; // the request id used when we are doing a CSV download.

  private timeoutId: any;

  reportDetail: ReportDetail;
  from_site_id: string;
  account_id: string;

  reportStatusEnum = ReportStatusEnum;
  MaintenanceNotificationType = MaintenanceNotificationType;

  socketEventSubscription: Subscription;
  maintenanceSocketEventSubscription: Subscription = null;

  cancelRequested: boolean;

  // data to display
  timeSlots;
  displayedColumns;
  metrics;

  dataLoading: boolean;
  noData: boolean;

  requestId: string;

  returnToSiteId: string; // the site we were on when we navigated to history

  // used during refresh to cache the requested start/end time
  // then used when socket connect/authentication is notififed.
  retryStartedAt;
  retryEndedAt;

  pageDetailReport;
  notApplicableString = `n/a`;
  
  // Refrigerant
  recommendation = {};
  referenceRecommendation = `reference the manual and observe all local, state and federal laws`;
  improperChargeRecommendation = `Run ${MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Refrigerant]} again to make sure you have properly adjusted your refrigerant level, then pass ${MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Test_Run]} before returning to normal operation`;
  
  // Recommendations Object
  recommendations = {
    undercharged: {
      range: `0 - 14.9%`,
      resultTitle: `Undercharged`,
      messageTitle: `Your system might be undercharged with refrigerant.`,
      recommendationMessage: `Properly charge your system, ${this.referenceRecommendation}. ${this.improperChargeRecommendation}.`,
    },
    charged: {
      range: `15 - 17.5%`,
      resultTitle: `Properly Charged`,
      messageTitle: `Your system is properly charged with refrigerant.`,
      recommendationMessage: ``,
    },
    overcharged: {
      range: `17.6 - 100%`,
      resultTitle: `Overcharged`,
      messageTitle: `Your system might be overcharged with refrigerant.`,
      recommendationMessage: `Properly remove refrigerant, ${this.referenceRecommendation}. ${this.improperChargeRecommendation}.`,
    }
  };

  // Branch Port
  gateway;
  units = [];
  groups = [];
  judges = [];
  outdoor_unit;
  bpcInfoLoading = true;
  gatewayInfoLoading = true;
  bpcZeroStateMessage = `No Data from ${MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Incorrect_Port]}`;

  errorMessages = [
    `The liquid pipe temperature (TH2) of the IDU is higher compared to the room temperature (TH1) of the IDU.`,
    `The temperature difference between the gas pipe temperature (TH3) and the room temperature (TH1) of the IDU is lower than expected.`,
    `The gas pipe temperature (TH3) of the IDU is lower compared to the room temperature (TH1) of the IDU.`,
    `The outdoor unit failed to start up properly.`,
    `Failed to get value.`,
  ];

  errors = {
    1: {
      id: 1,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: true,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `No incorrect settings were found. For the final assessment, please review the maintenance data and proceed with the necessary actions on your own.`,
    },
    2: {
      id: 2,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `bit0=1`,
      cancelJudgeError: `-`,
      message: this.errorMessages[0],
    },
    3: {
      id: 3,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `bit1=1`,
      cancelJudgeError: `-`,
      message: this.errorMessages[1],
    },
    4: {
      id: 4,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `bit2=1`,
      cancelJudgeError: `-`,
      message: this.errorMessages[2],
    },
    5: {
      id: 5,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `bit3=1`,
      cancelJudgeError: `-`,
      message: this.errorMessages[3],
    },
    6: {
      id: 6,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `Bit4=1`,
      cancelJudgeError: `-`,
      message: this.errorMessages[4],
    },
    7: {
      id: 7,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: true,
        cancel: false,
      },
      errorType: `Others`,
      cancelJudgeError: `-`,
      message: `An error has occurred.`,
    },
    8: {
      id: 8,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: true,
      },
      errorType: `-`,
      cancelJudgeError: 2,
      message: `BC port check could not be performed as the IDU did not change the thermos ON state.`,
    },
    9: {
      id: 9,
      status: `normal`,
      judgeStatus: `ok`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: true,
      },
      errorType: `-`,
      cancelJudgeError: `Others`,
      message: `BC port check could not be performed. (**)`,
    },
    10: {
      id: 10,
      status: `normal`,
      judgeStatus: `cancel`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `BC port check has failed. (**)`,
    },
    11: {
      id: 11,
      status: `normal`,
      judgeStatus: `disable`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `An error has occurred.`,
    },
    12: {
      id: 12,
      status: `normal`,
      judgeStatus: `Others`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `An error has occurred.`,
    },
    13: {
      id: 13,
      status: `canceled`,
      judgeStatus: `-`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `Canceled BC port check.`,
    },
    14: {
      id: 14,
      status: `Others`,
      judgeStatus: `-`,
      dataExists: {
        ok: false,
        ng: false,
        cancel: false,
      },
      errorType: `-`,
      cancelJudgeError: `-`,
      message: `An error has occurred.`,
    },
  }

  judgeStatuses = {
    ok: {
      status: `ok`,
      label: `Ok`,
      icon: {
        name: `checkmark-circle`,
        altName: `checkmark`,
        color: `success`,
      },
    },
    ng: {
      status: `ng`,
      label: `Error`,
      icon: {
        name: `close-circle`,
        altName: `close`,
        color: `danger`,
      },
    },
    cancel: {
      status: `cancel`,
      label: `Cancel`,
      icon: {
        name: `remove-circle`,
        altName: `remove`,
        color: `gray`,
      },
    }
  }

  tableData?: any = {
    width: 96,
    id: `branchPortCheckDataTable`,
    class: `branchPortCheckDataTable`,
    headerRow: {
      columns: [
        {
          id: 1,
          icon: `cube`,
          label: `Group`,
          class: `groupNameCol`,
        },
        {
          id: 2,
          icon: `git-branch`,
          label: `BC Port Valve`,
          class: `bcPortValveCol`,
        },
        {
          id: 3,
          label: `IDU`,
          icon: `tablet-landscape`,
          class: `iduMnetAddressCol`,
        },
        {
          id: 4,
          label: `Status`,
          class: `statusCol`,
          icon: `ellipsis-horizontal-outline`,
        },
        {
          id: 5,
          label: `Additional Info`,
          class: `additionalInfoCol`,
          icon: `document-text-outline`,
        },
      ]
    },
    tableRows: {
      rows: [],
      columns: [],
    }
  }

  constructor(
    private router: Router,
    private siteService: SiteService,
    private alertController: AlertController,
    private location: Location,
    private socketService: SocketService,
    private userService: UserService,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) public locale: string,
    private loadingController?: LoadingController,
  ) {
    // this.test_data_display = false;
    this.metrics = [];
  }

  ngOnInit() {
    // Empty
  }

  getEntries(obj) {
    return Object.entries(obj);
  }

  // Can be any number between 0 and 31
  getBitMessages(number) {
    if (number > 31) number = 31; // Make sure its not higher than 31
    let errors = [];
    for (let i = 0; i < this.errorMessages.length; i++) {
      if (number & (1 << i)) {
        errors.push(this.errorMessages[i]);
      }
    }
    return errors;
  }

  devLogParameters(optionalExtraParameters?) {
    devEnv && console.log(`${this.shortTitle} Info`, {
      reportDetail: this.reportDetail,
      pageDetailReport: this.pageDetailReport,

      ...(this.isBPC() && {
        judges: this.judges,
        rows: this.tableData.tableRows.rows,
      }),

      ...(optionalExtraParameters && {
        ...optionalExtraParameters
      })
    });
  }

  ionViewWillEnter() {
    // coming into view
    // get ReportDetail from router.
    // the calls to this page should provide two additional parameters
    // the ReportDetail object and the site to return to
    this.cancelRequested = false;
    // Maintenance ODU & Report Data
    let pageDetail = this.location.getState() as any;
    this.pageDetailReport = pageDetail?.report;
    this.reportDetail = ReportDetail.initFromReport(pageDetail?.report);
    this.from_site_id = pageDetail?.site_id;
    this.account_id = pageDetail?.account_id;
    this.dataLoading = false;

    if (this.reportDetail.job_id == MaintenanceJobTypeEnum.Incorrect_Port) {
      this.testRunMaintenance = false;
      this.shortTitle = MaintenanceJobTypeEnumLabels.Incorrect_Port;
      this.reportTitle = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Incorrect_Port];
    } else if (this.reportDetail.job_id == MaintenanceJobTypeEnum.Refrigerant) {
      this.testRunMaintenance = false;
      this.shortTitle = MaintenanceJobTypeEnumLabels.Refrigerant;
      this.reportTitle = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Refrigerant];
    }

    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      this.receiveData(socketResult);
    });    

    if (this.maintenanceSocketEventSubscription == null) {
      this.maintenanceSocketEventSubscription = this.socketService.maintenanceEmitter.subscribe((maintenanceSocketResult: any) => {
        this.onMaintenanceSocketUpdates(maintenanceSocketResult);
      });    
    }

    this.route.queryParams.subscribe(params => {
      this.returnToSiteId = `fromSiteId` in params ? params.fromSiteId : ``;
    });    

    if (this.reportDetail.viewable) {
      // get data to display - if this job is active more data will arrive on the site socket room
      // as it progresses.

      // if we have a start time - then there is data to display

      if (this.reportDetail.startDate) {
        this.dataLoading = true;
        this.noData = false;
        // what is end time?  based on status of job.
        let endDate = this.reportDetail.endDate;
        if (!this.reportDetail.endDate) {
          // then we need to consider other options.
          switch (this.reportDetail.status) {
            case ReportStatusEnum.InProcess:
              // then we use the value of now
              endDate = new Date();
              break;
            case ReportStatusEnum.Canceled:
            case ReportStatusEnum.Error:
              {
                // in this case since there IS a startDate - we'll just go to the run time.
                const testRunDurationInSeconds = parseInt(this.reportDetail.duration) * 60;
                if (this.reportDetail.startDate.getTime) endDate = new Date(this.reportDetail.startDate.getTime() + testRunDurationInSeconds * 1000);
              }
              break;
            case ReportStatusEnum.Complete:
              {
                // humm its complete with no endTime? must be funky simn job.  Let run for 1 minute
                if (this.reportDetail.startDate.getTime) endDate = new Date(this.reportDetail.startDate.getTime() + 60 * 1000);
              }
              break;
            
          
            default:
              break;
          }
        }
        
        // request from job start time to now - both in local timezone
        // determine an end date - based on report status
        this.getMaintData(this.reportDetail.startDate, endDate);
      } else {
        // then although viewable - there is nothing to view.
        this.dataLoading = false;
        this.noData = true;
      }        
    } else {
      // there if no data to display
      this.dataLoading = false;
      this.noData = true;
    }

    if (this.reportDetail?.status == ReportStatusEnum.Canceled || this.reportDetail?.status == ReportStatusEnum.Error) {
      this.progress = -1;
    } else {
      this.progress = this.reportDetail?.progress;
    }

    // Simulate Loading for Non Test Run Maintenance Jobs for now
    if (this.testRunMaintenance === false) {
      this.timeoutId = setTimeout(() => {
        this.dataLoading = false;

        if (this.completedChart() && !this.errorOrCanceled()) {
          if (this.isRC()) {
            // Start AL Percentage at 0 and Animate to Actual AL % from Report
            this.alPercentage = 0;
            this.setRecommendation(this.alPercentage);
            this.timeoutId = setTimeout(() => {
              let durationInMS = 100;
              let endPercentage = this.reportDetail?.al;
              this.simplifiedAnimateALPercentage(endPercentage, durationInMS);
            }, 250);
          } else if (this.isBPC()) {
            this.getBPCJudges();
            this.devLogParameters();
            // this.getGatewayInfoFromReport(this.reportDetail);
          }
        }

      }, 1500);
    }
  }

  getErrorMessagesByCode(errorCode: number) {
    const errorMessages = [];
    for (const key in this.errors) {
      const error = this.errors[key];
      if (error.errorType && error.errorType.includes(`bit`)) {
        const bitPosition = parseInt(error.errorType.match(/\d+/)[0]);
        const bitValue = 1 << bitPosition;  // Shift 1 to the left by bitPosition places
        if ((errorCode & bitValue) !== 0) {  // Use bitwise AND to check if the error bit is set in errorCode
          errorMessages.push(error);
        }
      }
    }
    return errorMessages;
  }

  getBPCJudges(okJudges?, ngJudges?, cancelJudges?) {
    if (this.reportDetail && this.reportDetail.parameters) {
      if (!okJudges) okJudges = JSON.parse(this.reportDetail?.parameters?.ok_judge) || [];
      if (!ngJudges) ngJudges = JSON.parse(this.reportDetail?.parameters?.ng_judge) || [];
      if (!cancelJudges) cancelJudges = JSON.parse(this.reportDetail?.parameters?.cancel_judge) || [];
    }
    
    okJudges = Array.isArray(okJudges) && okJudges?.length > 0 ? okJudges.map(judge => ({ ...judge, status: this.judgeStatuses.ok.status })) : [];
    ngJudges = Array.isArray(ngJudges) && ngJudges?.length > 0 ? ngJudges.map(judge => ({ ...judge, status: this.judgeStatuses.ng.status })) : [];
    cancelJudges = Array.isArray(cancelJudges) && cancelJudges?.length > 0 ? cancelJudges.map(judge => ({ ...judge, status: this.judgeStatuses.cancel.status })) : [];
    
    let allJudges = [];
    if (Array.isArray(okJudges) && Array.isArray(ngJudges) && Array.isArray(cancelJudges)) {
      this.judges = [ ...okJudges, ...ngJudges, ...cancelJudges ];
      allJudges = this.judges.map(jdg => {
        if (jdg?.icAddress && jdg?.icAddress?.length > 0) jdg?.icAddress.sort();

        let defaultErrorType = `-`;
        let row = {
          ...jdg,
          messages: [],
          rowUnits: [],
          rowAddresses: [],
          error: jdg?.error || 0,
          units: jdg?.units || [],
          groups: jdg?.groups || [],
          errors: jdg?.errors || [],
          addresses: jdg?.addresses || [],
          icAddress: jdg?.icAddress || [],
          unit: jdg?.unit || [
            {
              errorType: defaultErrorType,
              address: this.reportDetail?.ou_address,
              branchPair: this.reportDetail?.ou_address,
              branchAddress: this.reportDetail?.ou_address,
            }
          ],
        };

        let errorIndex = row?.error;
        let defaultLabel = this.judgeStatuses[errorIndex] ? this.judgeStatuses[errorIndex]?.label : `Ok`;
        let message = `${defaultLabel} Status`;

        if (!row?.error || row?.error == 0) {
          if (row?.unit && Array.isArray(row?.unit) && row?.unit?.length > 0) {
            if (row?.unit[0]?.errorType && row?.unit[0]?.errorType != defaultErrorType) {
              errorIndex = row?.unit[0]?.errorType;
            }
          }
        }

        if (errorIndex > 0 && this.errors[errorIndex]) message = this.errors[errorIndex]?.message;

        let errorFromIDCode = this.errors[errorIndex];
        let errMsgsBinaryBitwise = this.getErrorMessagesByCode(errorIndex);

        row.error = errorIndex;
        row.errors = errMsgsBinaryBitwise;
        if (errorFromIDCode == undefined) row.errors = [this.errors[1]];

        if (row.status == this.judgeStatuses.cancel.status) {
          if (isNaN(row.error) && row.error != `Others`) row.error = `Others`;
          let cancelErrors = Object.values(this.errors).filter(val => val?.dataExists?.cancel == true);
          let errorsToInclude = cancelErrors && cancelErrors.length > 0 ? cancelErrors.filter(err => err.cancelJudgeError == row.error) : [];
          if (errorsToInclude.length == 0) errorsToInclude = [this.errors[9]];
          row.errors = errorsToInclude;
        }

        row.message = message;
        row.messages = this.getBitMessages(errorIndex);

        // Sort Units by Address or Name so its "Unit 1", "Unit 2", etc.
        row.unit.sort((u1, u2) => {
          if (u1.address !== undefined && u2.address !== undefined) {
            return u1.address - u2.address;
          } else if (u1.address === undefined && u2.address !== undefined) {
            return 1;
          } else if (u1.address !== undefined && u2.address === undefined) {
            return -1;
          } else {
            return u1.name.localeCompare(u2.name);
          }
        });

        return row;
      });
    }

    this.bpcInfoLoading = false;
    if (allJudges && Array.isArray(allJudges) && allJudges?.length > 0) {
      this.tableData.tableRows.rows = allJudges;
    }
  }

  simplifiedAnimateALPercentage(endPercentage, durationInMS = 100) {
    let updateProgressBar = setInterval(() => {
      this.alPercentage += 0.01;
      this.setRecommendation(this.alPercentage);

      if (this.alPercentage >= endPercentage) {
        clearInterval(updateProgressBar);
        this.alPercentage = endPercentage;
        this.setRecommendation(this.alPercentage);
      }
    }, (durationInMS / 100));

    setTimeout(() => {
      clearInterval(updateProgressBar);
      this.alPercentage = endPercentage;
      this.setRecommendation(this.alPercentage);
      this.devLogParameters();
    }, durationInMS);
  } 

  elementError(element, slot) {
    // Is this element in the table view considered in Error?
    
    // limits on OC 63HSI
    if (element.itemKey == 'OC_CONV1_145_63hs1' && 
        parseFloat(element[slot.def]['raw']) > ts63HS1Limit) return true;
    // limits on OC TE
    if (element.itemKey == 'OC_CONV1_37_te' && 
        parseFloat(element[slot.def]['raw']) < tsTeLimit) return true;
    // limits on OC TH4
    if (element.itemKey == 'OC_CONV1_4_th4' && 
        parseFloat(element[slot.def]['raw']) > tsTH4Limit) return true;
    // limits on IC TH2
    if (element.itemKey == 'IC_CONV1_3_th2' && 
        (parseFloat(element[slot.def]['raw']) < tsTH2aLimit ||
        parseFloat(element[slot.def]['raw']) > tsTH2bLimit)) return true;
    // limits on IC SH
    if (element.itemKey == 'IC_CONV1_6_sh' && 
        parseFloat(element[slot.def]['raw']) > tsSHLimit) return true;

    // then not in error
    return false;
  }

  toDate12(date: Date): string {
    // desired format is YYYYMMDDHHmm in UTC
    const year = date.getUTCFullYear().toString();
    const month = (date.getUTCMonth()+1).toString().padStart(2,'0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hour = date.getUTCHours().toString().padStart(2,'0');
    const minute = date.getUTCMinutes().toString().padStart(2, '0');

    return `${year}${month}${day}${hour}${minute}`;
  }

  getMaintData(startedAt, endedAt, socketResult?) {
    if (startedAt != undefined && startedAt?.getTime && endedAt != undefined && endedAt?.getTime) {
      // メンテデータ取得API呼出
      // Maintenance data acquisition API call - it wants times in UTC
  
      const point = Math.floor((endedAt.getTime() - startedAt.getTime()) / (60*1000)) + 1;
  
      this.requestId = uuidv4();
      // convert the start/end values into desired format in UTC
      const start_utc = this.toDate12(startedAt);
      const end_utc = this.toDate12(endedAt);
  
      const obj = {
        gateway_id: this.reportDetail.gateway_id,
        site_id: this.reportDetail.siteId,
        request_id: this.requestId,
        start_date: start_utc,
        end_date: end_utc,
        resolution: point,
        monitor_mode: 1,
        address: [this.reportDetail.ou_address]
      }
  
      if (!this.socketService.getMaintData(obj)) {
        // this will return false if the socket is not connected yet...
        // in that case we'll retry when we're told we're authenticated.
        this.retryStartedAt = startedAt;
        this.retryEndedAt = endedAt;
        console.log(`Socket not connected - will retry when connected.`);
      }
    }
  }

  async showLoadingSpinner() {
    this.loadingSpinner = await this.loadingController.create({
      message: 'Generating CSV file...',
      spinner: 'lines',
      duration: WAIT_TIME,
    });

    await this.loadingSpinner.present();
  }

  actionCsvdownload() {
    this.showLoadingSpinner();
    this.csvReport = this.reportDetail;
    this.getMaintData(this.reportDetail.startDate, this.reportDetail.endDate);
    this.csvRequestId = this.requestId;
  }

  ionViewDidLeave() {
    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
      this.socketEventSubscription = null;
    }
    if (this.maintenanceSocketEventSubscription) {
      this.maintenanceSocketEventSubscription.unsubscribe();
      this.maintenanceSocketEventSubscription = null;
    }
  }

  onMaintenanceSocketUpdates(maintenanceSocketResult) {
    if (this.reportDetail && maintenanceSocketResult.responseData) {
      if (this.reportDetail != undefined && maintenanceSocketResult.responseData != undefined) {
        let active = this.reportDetail.isActive();
        let { progress } = maintenanceSocketResult.responseData;
        let decimalProgress = (progress / 100);
        this.maintUpdates = this.maintUpdates + 1;
        let activeJobUpdate = maintenanceSocketResult.responseData.managementTableId == this.reportDetail.maintenancejob_id;
        if (active && activeJobUpdate) {
          this.reportDetail.updateMaintJobProgress(maintenanceSocketResult.responseData, this.locale);
          this.progress = this.cleanedProgress(3, decimalProgress);
          devEnv && console.log(`Maintenance Socket Updates`, maintenanceSocketResult.responseData);
          if (maintenanceSocketResult.responseData.type == MaintenanceNotificationType.FINISH) {
            if (this.isBPC()) {
              let { okJudge, ngJudge, cancelJudge } = maintenanceSocketResult.responseData.result[0];
              this.getBPCJudges(okJudge, ngJudge, cancelJudge);
            } else if (this.isRC()) {
              let durationInMS = 100;
              this.alPercentage = maintenanceSocketResult.responseData.result.al;
              this.simplifiedAnimateALPercentage(this.alPercentage, durationInMS);
            }
          }
        }
      }
    }
  }

  receiveData(socketResult) {
    // several options here - we could be getting as response from a request for 
    // inital data to display, or details to display for a non active job
    // or we could be getting details here for a job that is active and pushing
    // results to the site channel.
    const responseType: WebSocketResponseTypeEnum = socketResult.response_type;

    if (responseType == WebSocketResponseTypeEnum.Authenticated) {
      // if we think we're loading data - then we need to request again...
      if (this.dataLoading) {
        this.getMaintData(this.retryStartedAt, this.retryEndedAt, socketResult);
      }
    }

    // did we request this directly?
    if (this.requestId == socketResult.request_id) {
      // then yes - we ASKED for this so process it.
      if (responseType == WebSocketResponseTypeEnum.Maint_Job_Complete)
        this.importMaintData(socketResult.response.data);
      else if (responseType == WebSocketResponseTypeEnum.Maint_Job_Error) {
        this.dataLoading = false;
        this.noData = true;        
      }

    } else {
      // ok - then its a site wide update.  Do we want it?
      
      // what is the status of the run we're looking at?
      if (this.reportDetail.isNotEnded()) {
        // then consider this
        const response = socketResult.response;
        if ( responseType == WebSocketResponseTypeEnum.Test_Run_Progress) {
          const maintenancejob_id = response.maintenancejob_id;  
          if (maintenancejob_id == this.reportDetail.maintenancejob_id) {
            // then yes this is for this job update with details.
            this.reportDetail.update(response, this.locale);
          } 
        } else if (responseType == WebSocketResponseTypeEnum.Maint_Job_Complete) {
          // then is this for the job we're viewing?
          // is this case the requets_id might be our maintenancejob_id
          const request_id = socketResult.request_id;
          if (request_id == this.reportDetail.maintenancejob_id) {
            // then yes - it might be for us
            if (this.reportDetail.gateway_id == socketResult.response.gateway_id) {
              // then it *might* be for us - but it could be for a different ODU
              this.importMaintData(socketResult.response.data);  
            }            
          }
        }
      }
    }
  }

  importMaintData(string_data) {
    // udpate the row and column objects with data to display in the table.

    let newTimeSlots = [];
    let newMetrics = [];

    const data = JSON.parse(string_data);

    // if there is no data
    if (Object.keys(data).length === 0) {
      this.noData = true;
      this.dataLoading = false;
      return
    }

    const myOduAddress = this.reportDetail.ou_address;
    const myOduUnit = data.operationMonitor.cycles[0].units.find(unit => unit.attribute == `OC` && unit.address == myOduAddress);
    if (!myOduUnit) {
      // then this isn't for us.
      return;
    }

    // make time slots by looking at the first cycle data items for each unit
    data.operationMonitor.cycles.forEach( cycle => {
      newTimeSlots.push( {
        'label': this.getColLabel(cycle),
        'def': this.getColDef(cycle)
      });
    })
    this.noData = newTimeSlots.length == 0;
    this.dataLoading = false;
    // setup new metrics data
    data.operationMonitor.cycles[0].units.forEach(unit => {
      unit.items.forEach(item => {
        let newMetric = {
          'label': this.getItemLabel(unit, item),
          'itemKey': this.getItemKey(item),
          'unitKey': this.getUnitKey(unit)
        }
        newMetrics.push(newMetric);     
      })
    })

    // now populate the data in newMetrics from data.operationMonitor for each of the keys
    data.operationMonitor.cycles.forEach(cycle => {
      const colDef = this.getColDef(cycle);
      cycle.units.forEach(unit => {
        unit.items.forEach(item => {
          // find this entry in newMetrics and an entry for this time cycle / colDef
          const itemKey = this.getItemKey(item);
          const unitKey = this.getUnitKey(unit);
          let newMetric = newMetrics.find(metric => metric.itemKey == itemKey && metric.unitKey == unitKey);
          if (newMetric) {
            // inject this colDef.
            newMetric[colDef] = {
              'raw': this.getRawData(item),
              'cooked': this.getCookedData(item)
            }
          } else {
            // error?
            console.log('Did not find a metric to set in new Metrics data being ingested');
          }
        })
      })
    })

    this.timeSlots = newTimeSlots;
    this.displayedColumns = [ `label` ];
    this.timeSlots.forEach( (slot) => {
      this.displayedColumns.push(slot.def);
    })    
    this.metrics = newMetrics;
    this.metricsTable.renderRows();
  }

  getColLabel(cycle): string {
    const date = new Date(cycle.date);
    return formatDate(date, `shortTime`, this.locale, this.reportDetail.siteTimezoneOffset);
  }

  getColDef(cycle): string { 
    return this.getColLabel(cycle).replace(`:`,``).replace(` `,``);
  }

  getItemLabel(unit, item) {
    // return the unit display label name for this unit.
    const address = unit.address.toString().padStart(3,0);
    const itemUnits = this.getItemLabelPostfix(item);
    return `${unit.attribute}(${address}) ${item.name} ${itemUnits}`;
  }

  getItemLabelPostfix(item) {
    // does this unit definition get a F/C or psi like postfix?
    const unitType = item.unitId
    switch (unitType) {
      case UnitDataType.PresureKcm2:
      case UnitDataType.PresureMPa:
        if (this.userService.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
          return `(Kpa)`;
        }
        // else psi
        return `(psi)`;

      case UnitDataType.TempDiff:
      case UnitDataType.TempNonSet:
      case UnitDataType.TempSet:
        if (this.userService.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) {
          return `(°C)`;
        }
        // else F
        return `(°F)`;
    }

    return ``;
  }

  getRawData(item) {
    // return the proper string display value for this item.
    if (!item.data1 || item.data1 == `-`) return `-`;
    if (item.unitId == UnitDataType.String) return item.data2;
    return item.data1;
  }

  getCookedData(item) {
    // return the proper display string based on user pref for this item
    if (!item.data1 || item.data1 == `-`) return `-`;
    if (item.unitId == UnitDataType.TempNonSet ||
      item.unitId == UnitDataType.TempSet ||
      item.unitId == UnitDataType.TempDiff) {
      // value in celcius
      if (this.userService.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
        return TemperatureConversions.convertCelsiusToFahrenheit(parseFloat(item.data1)).toFixed(1);
      }
      return item.data1;
    } else if (item.unitId == UnitDataType.PresureKcm2) {
      // convert presure from Kcm2
      if (this.userService.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
        return PressureConversions.convert_from_kg_per_cm2_to_kilopascal(parseFloat(item.data1)).toFixed(1);
      } 
      return PressureConversions.convert_from_kg_per_cm2_to_psi(parseFloat(item.data1)).toFixed(1);
    } else if (item.unitId == UnitDataType.PresureMPa) {
      // convert presure from Megapascal
      if (this.userService.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal) {
        return PressureConversions.convert_from_megapascal_to_kilopascal(parseFloat(item.data1)).toFixed(1);
      }
      return PressureConversions.convert_from_megapascal_to_psi(parseFloat(item.data1)).toFixed(1);
    } else if (item.unitId == UnitDataType.Numeric) {
      return item.data1;
    }
    // only left is UnitDataType.String
    return item.data2;
  }

  getItemKey(item) {
    // return a unit id for this item without spaces!
    return `${item.nameId1}_${item.nameId2}_${item.name.replace(' ','').toLowerCase()}`;
  }

  getUnitKey(unit) {
    // return a unit key to this unit
    return `${unit.attribute}_${unit.address}`;
  }

  reportInProcess() {
    return this.reportDetail ? this.reportDetail.status == ReportStatusEnum.InProcess : false;
  }

  showReportTable() {
    // should we show the report table?
    return this.reportDetail?.viewable && !this.dataLoading && !this.noData;
  }
  
  navigateToSite() {
    // link to site dashboard
    this.router.navigate([`/site`, this.reportDetail.siteId, `dashboard`]);
  }

  async cancelTestRun() {

    const maintenancejob_id = this.reportDetail.maintenancejob_id;

    const alert = await this.alertController.create({
      header: 'Cancel Test Run',
      message: '<div class="me-redClass">Are you sure you want to cancel this Test Run?</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.siteService.cancelMaintenanceJob(maintenancejob_id).subscribe();
            this.cancelRequested = true;
          }
        }, {
          text: 'No',
          cssClass: 'back-button'
        }
      ]
    });

    await alert.present();

  }

  tableTitle() {
    let msg = `<ion-row>`;
    msg += `<ion-col class="col-3"><strong><u>Value</u></strong></ion-col>`;
    msg += `<ion-col class="col-5"><strong><u>Error Condition</u></strong></ion-col>`;
    msg += `<ion-col class="col-4"><strong><u>Description</u></strong></ion-col>`;
    msg += `</ion-row>`;
    return msg;
  }

  tableRow(name, error, description) {
    // add this as a row to the ion-grid
    return `<ion-row> <ion-col class="col-3">${name}</ion-col> <ion-col class="col-5">${error}</ion-col> <ion-col class="col-4">${description}</ion-col> </ion-row>`;
  }

  async valueDetails() {

    let header: string = `${this.reportTitle} Details`;
    let message: string = `${header} Message`;

    if (this.reportDetail.job_id == MaintenanceJobTypeEnum.Test_Run) {

      header = `Value Details`;

      const OC_63HS1 = this.getCookedData({ data1: ts63HS1Limit, unitId: UnitDataType.PresureKcm2 }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.PresureKcm2 });
      const OC_TE = this.getCookedData({ data1: tsTeLimit, unitId: UnitDataType.TempSet }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.TempSet });
      const OC_TH4 = this.getCookedData({ data1: tsTH4Limit, unitId: UnitDataType.TempSet }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.TempSet });
      const IC_TH2_low = this.getCookedData({ data1: tsTH2aLimit, unitId: UnitDataType.TempSet }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.TempSet });
      const IC_TH2_high = this.getCookedData({ data1: tsTH2bLimit, unitId: UnitDataType.TempSet }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.TempSet });
      const IC_SH = this.getCookedData({ data1: tsSHLimit, unitId: UnitDataType.TempSet }) + ` ` + this.getItemLabelPostfix({ unitId: UnitDataType.TempSet });
  
      message = `<ion-text>${this.reportTitle} values outside these ranges are considered in error and highlighted with a red background.</ion-text>`;
  
      message += `<ion-grid>`;
      
      message += this.tableTitle();
      message += this.tableRow(`OC 63HSI`, `OC 63HSI > ${OC_63HS1}`, `High Presure Sensor`);
      message += this.tableRow(`OC Te`, `OC Te < ${OC_TE}`, `Evaporating Temp`);
      message += this.tableRow(`OC TH4`, `OC TH4 > ${OC_TH4}`, `Discharge Temp`);
      message += this.tableRow(`IC TH2`, `${IC_TH2_low} > IC TH2 > ${IC_TH2_high}`, `Liquid Pipe Temp`);
      message += this.tableRow(`IC SH`, `IC SH > ${IC_SH}`, `Superheat Temp`);
  
      message += `</ion-grid>`;
    } else if (this.reportDetail.job_id == MaintenanceJobTypeEnum.Refrigerant) {
      message = `${this.reportTitle} analyzes temperature, pressure, and compressor values to determine if the refrigerant amount in the system is undercharged, properly charged, or overcharged.`;
      message += `<br />`;
      message += `<br />`;
      message += `If the refrigerant amount is determined to be out of an acceptable range, consult with the manual for the specific model and follow all applicable laws when adjusting refrigerant amounts.`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
    } else if (this.reportDetail.job_id == MaintenanceJobTypeEnum.Incorrect_Port) {
      message = `${this.reportTitle} evaluates the temperature gap between TH1 and TH3, and TH1 and TH2 to determine if the port setting is correct or incorrect.`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
      message += `<br />`;
    }

    const alert = await this.alertController.create({
      header: header,
      message: message,
      backdropDismiss: true,
      cssClass: `me-info-button-css me-value-dialog`,

      buttons: [
        {
          text: `Ok`,
          cssClass: `ok-button`
        }
      ]
    });

    await alert.present();

  }

  completedChart() {
    let active = this.reportDetail ? this.reportDetail?.isActive() : false;
    return !this.errorOrCanceled() && !active;
  }

  isRC() {
    let isRC = this.reportDetail ? this.reportDetail?.job_id == MaintenanceJobTypeEnum.Refrigerant : false;
    return isRC;
  }

  isBPC() {
    let isBPC = this.reportDetail ? this.reportDetail?.job_id == MaintenanceJobTypeEnum.Incorrect_Port : false;
    return isBPC;
  }
  
  cleanedProgress(decimalPlaces = 1, percentage = this.progress * 100) {
    let cleanProgress = globalFunctions?.removeTrailingZeroDecimal(percentage, decimalPlaces);
    return cleanProgress;
  }

  cleanedALPercentage(decimalPlaces = 1, percentage = this.alPercentage) {
    let cleanedALPercentage = globalFunctions.removeTrailingZeroDecimal(percentage, decimalPlaces, false);
    return cleanedALPercentage;
  }

  renderProgressMessage(progressMessage = this?.reportDetail?.progressMessage) {
    if (progressMessage.includes(`NaN`)) {
      if (this.isRC()) {
        // Calculate Minutes Left for RC Based on (Approximate Duration or Loop Number) - (Approximate Duration or Loop Number * Job Progress)
        let reportProgress = this?.reportDetail?.progress;
        let approxDuration = this?.reportDetail?.loop_number;
        let remainingDuration = approxDuration - (approxDuration * reportProgress);
        let cleanedRemainingDuration = this.cleanedProgress(2, remainingDuration);
        progressMessage = progressMessage.replaceAll(`NaN`, cleanedRemainingDuration);
      }
    }
    return progressMessage;
  }

  changePercentage(percentageFieldEvent) {
    let percentageValue = percentageFieldEvent?.detail?.value;
    this.alPercentage = percentageValue;
    this.setRecommendation(this.alPercentage);
  }

  errorOrCanceled() {
    let error = this.reportDetail ? this.reportDetail?.status == ReportStatusEnum.Error : false;
    let canceled = this.reportDetail ? this.reportDetail?.status == ReportStatusEnum.Canceled : false;
    return error || canceled;
  }

  setRecommendation(percentage = this?.alPercentage) {
    if (percentage >= 0 && percentage < 15) {
      this.recommendation = this.recommendations.undercharged;
    } else if (percentage >= 15 && percentage < 17.6) {
      this.recommendation = this.recommendations.charged;
    } else {
      this.recommendation = this.recommendations.overcharged;
    }
  }

  ionViewWillLeave() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

}