import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SiteService } from 'src/app/features/sites/services/site.service';

@Component({
  selector: 'maint-map-units-error',
  templateUrl: './maint-map-units-error.component.html',
  styleUrls: ['./maint-map-units-error.component.scss'],
})
export class MaintMapUnitsErrorComponent /*implements OnInit*/ {
  @Input() errorMessage: string;

  constructor(private modalController: ModalController) { }

  // ngOnInit() { }

  close() {
    this.modalController.dismiss();
  }

}
