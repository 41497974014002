<ion-header>
  <ion-toolbar>
    <ion-title>Change Member Role</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="siteMemberDetailAdminForm" (ngSubmit)="onSubmit()">
    <ion-list>
      <ion-item lines="none" class="ion-text-center">
        <ion-label color="danger">Change member role for</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label fixed class="labelPosition">Name</ion-label>
        <ion-label>{{siteMemberDetailAdminForm.get('memberNameOrEMail').value}}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label fixed class="labelPosition">Current Role</ion-label>
        <ion-label>{{siteMemberDetailAdminForm.get('currentMemberSiteLevelText').value}}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label fixed class="labelPosition">New Role</ion-label>
        <select formControlName="newMemberSiteLevel" id="newMemberSiteLevel" (change)="onSelectRole($event)">
          <option *ngFor="let role of siteMemberRoles" [value]="role.id" id="{{ role.name }}_Dropwdown_Option" class="{{ role.name }}_Option automationTesting">
            {{ role.name }}
          </option>
        </select>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button button [disabled]="disableForm" id="btnChange" slot="end" (click)="onSubmit()">
      Change
    </ion-button>
    <ion-button button slot="end" color="reverse-primary" id="btnCancel" (click)="onCancel()"
      class="ion-padding-horizontal">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>