<ion-content class="ion-no-padding" main-background *ngIf="currentView == 0">

  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1>Account Details</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="showBackToSiteMembersNavigation" (click)="backToManageMembers()" me-button-as-link
        id="btnBackToSiteMembers" name="btnBackToSiteMembers">
        &#8592;&nbsp;Back to Site Members </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-grid class="accountDetailMainGrid {{ keepOriginalAspectRatio ? 'keepOriginalAspectRatio' : '' }}">
    <ion-row>
      <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card id="i_card_picture" name="i_card_picture">
          <ion-card-content color="white" me-top-border class="ion-no-padding" class="ion-justify-content-center">
            <!--start picture/member since grid-->
            <ion-grid>
              <ion-row>
                <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size="12">
                  <div
                    class="ion-float-xl-right ion-float-lg-right ion-float-md-left ion-float-sm-left ion-float-xs-left">
                    <ion-item-group>
                      <ion-item *ngIf="imageUrl.length > 0" lines="none">
                        <img 
                          color="primary"
                          src="{{imageUrl}}"  
                          me-img-account-detail 
                          id="uploadAccountPicture" 
                          name="uploadAccountPicture" 
                          class="uploadAccountPicture" 
                          *ngIf="includeImgInDOM && imageUrl.length > 0" 
                        />
                      </ion-item>

                      <ion-item *ngIf="imageUrl.length==0" lines="none">
                        <img me-img-default-account-detail src="../../../../../assets/icon/no-picture.svg">
                      </ion-item>

                      <ion-item *ngIf="imageUrl.length==0" lines="none">
                        <div me-picture-aspect-label>
                          (Picture shown in 3:4 aspect ratio)
                        </div>
                      </ion-item>

                      <ion-item lines="none">
                        <input type="file" #uploadPictureFile name="uploadPictureFile" id="uploadPictureFile"
                          class="fileInput" (click)="on_open_upload_picture($event)" (change)="saveAccountPhoto($event)"
                          accept="image/x-png,image/gif,image/jpeg"   />
                        <label for="uploadPictureFile" appMouseOverOpacity class="fileInputLabel">
                          Upload Picture
                        </label>
                      </ion-item>

                      <ion-item lines="none">
                        <ion-grid>
                          <ion-row>
                            <ion-col>
                              <ion-button class="removeAccountPhotoButton" fill="clear" me-remove-picture-button *ngIf="imageUrl.length !== 0"
                                (click)="removeAccountPhoto()">
                                Remove Picture
                              </ion-button>
                            </ion-col>
                          </ion-row>
                          <ion-row>
                            <ion-col>
                              <ion-label me-validation-error><i>
                                  {{uploadPictureFileMessage}}
                                </i></ion-label>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </ion-item>
                    </ion-item-group>
                  </div>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size="12">
                  <div me-member-since me-member-since-div>
                    <strong>Member Since</strong><br>{{memberSince}}
                  </div>

                </ion-col>
              </ion-row>
            </ion-grid>
            <!-- end picture/member since grid-->
          </ion-card-content>
          <div id="i_card_picture_cardBottomFooter" class="cardBottomFooter {{ includeImgInDOM && imageUrl.length > 0 ? 'cardDataLoaded' : 'cardDataLoading' }} automationTesting"></div>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row class="accountDetailsBottomRow">
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_profile_information" name="i_card_profile_information">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Profile Information</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="onEditProfile($event)" title="Edit Profile Information">
              <ion-icon id="btnProfileEdit" name="create" class="ion-padding-start" color="black" me-icon-type-1>
              </ion-icon>
            </div>
          </ion-item>

          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col size="4"><strong>Name:</strong></ion-col>
                <ion-col>
                  <ion-label>
                    {{accountDetail && display_name(accountDetail.fname,accountDetail.lname,accountDetail.email)}}
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Email:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.email : ''}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Phone:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.phone : ''}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Title:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.title : ''}}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <div id="i_card_profile_information_cardBottomFooter" class="cardBottomFooter {{ accountDetail ? 'cardDataLoaded' : 'cardDataLoading' }} automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_company_information" name="i_card_company_information">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Company Information</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="onEditCompany($event)" title="Edit Company Information">
              <ion-icon id="btnCompEdit" name="create" class="ion-padding-start" color="black" me-icon-type-1>
              </ion-icon>
            </div>
          </ion-item>

          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col>
                  <ion-grid class="ion-no-padding">
                    <ion-row white-background>
                      <ion-col size="4"><strong>Company:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.company : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"><strong>Address:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.street_line1 : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.street_line2 : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"></ion-col>
                      <ion-col>
                        <ion-label>
                          {{accountDetail ? accountDetail.city : ''}}{{accountDetail && accountDetail.city &&
                          accountDetail.state? ',' : ''}}
                          {{accountDetail ? ' ' + accountDetail.state : ' '}}
                          {{accountDetail ? accountDetail.postal_code : ''}}</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col size="4"><strong>Phone:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.company_phone : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <div id="i_card_company_information_cardBottomFooter" class="cardBottomFooter {{ accountDetail ? 'cardDataLoaded' : 'cardDataLoading' }} automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12" *ngIf="is_current_logged_in_user==true">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_account_preferences" name="i_card_account_preferences">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Account Preferences</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="onAdminAccountPreferences()"
              title="Edit Account Preferences">
              <ion-icon id="i_btn_edit_account_preferences" name="create" class="ion-padding-start" color="black"
                me-icon-type-1></ion-icon>
            </div>
          </ion-item>

          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col>
                  <ion-grid class="ion-no-padding">
                    <ion-row white-background>
                      <ion-col size="4"><strong>Temperature:</strong></ion-col>
                      <ion-col>
                        <ion-label
                          *ngIf="account_preferences && temperature_preferences_list && temperature_preferences_list.length > 0">
                          {{get_temperature_preference_name_abbreviation_from_id(account_preferences.temperaturepreference_id)}}
                        </ion-label>

                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"><strong>Pressure:</strong></ion-col>
                      <ion-col>
                        <ion-label
                          *ngIf="account_preferences && pressure_preferences_list && pressure_preferences_list.length > 0">
                          {{get_pressure_preference_name_abbreviation_from_id(account_preferences.pressurepreference_id)}}
                        </ion-label>
                      </ion-col>
                    </ion-row>


                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <div id="i_card_account_preferences_cardBottomFooter" class="cardBottomFooter {{ account_preferences && pressure_preferences_list && pressure_preferences_list.length > 0 ? 'cardDataLoaded' : 'cardDataLoading' }} automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_account_access" name="i_card_account_access">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Account Access</strong>
            </ion-label>
          </ion-item>
          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col size="4"><strong>Password:</strong></ion-col>
                <ion-col>
                  <ion-label class="ion-no-padding">********</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center">
                  &nbsp;
                </ion-col>
              </ion-row>
              <ion-row white-background class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center">
                  <ion-button id="btnGoToChangePassword" (click)="onChangeAccountPassword()" class="automationTesting ">
                    <span>Change Password</span>
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row white-background class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center">
                  &nbsp;
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <ion-grid class="cardFooterIcon">
            <ion-row>
              <ion-col me-center-grid-col-content>
                <app-large-icon-button iconTitle="Cancel Account" iconName="remove-circle-sharp" 
                 [iconLocked]="hasLockedSite" (iconAction)="cancel_account()" [iconMaintenanceType]="iconMaintenanceType"></app-large-icon-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col me-center-grid-col-content>
                <strong>
                  <ion-label [ngClass]="hasLockedSite ? 'site-transfer-lower-opacity' : ''" id="i_label_cancel_account" name="i_label_cancel_account">
                    Cancel Account
                  </ion-label>
                </strong>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div id="i_card_account_access_cardBottomFooter" class="cardBottomFooter automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_gateway_information" name="i_card_gateway_information">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Gateway Subscriptions</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="viewAccountGatewaySubscriptionPlans()"
              title="To Gateway Subscriptions">

              <ion-icon *ngIf="accountGatewayDetails?.length" name="arrow-forward-outline" class="ion-padding-start"
                color="black" me-icon-type-2>
              </ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <div *ngIf="accountGatewayDetailsLoading" class="accountLoadingMessage">
              <ion-label>Loading </ion-label>
              <ion-spinner name="dots"></ion-spinner>
            </div>
            <div scrolly="true" class="gatewaySubsScrollList" me-scroll-div *ngIf="accountGatewayDetails?.length && !accountGatewayDetailsLoading">
              <ion-list>
                <ion-item lines="none" me-ion-border *ngFor="let ad of accountGatewayDetails">
                  <strong><ion-label me-ion-border position="stacked">{{ad.name}}</ion-label></strong>
                  <ion-label *ngIf="ad?.activeSubscription?.gateway_subscription_status_type_id != GSSTE.Suspended" me-ion-border position="stacked">
                    {{ad?.activeSubscription?.subscriptions.name}}
                  </ion-label>
                  <ion-label *ngIf="ad?.activeSubscription?.gateway_subscription_status_type_id == GSSTE.Suspended" me-ion-border class='me-expired-limited-plan' position="stacked">
                    Expired
                  </ion-label>
                </ion-item>
              </ion-list>
            </div>
            <div *ngIf="accountGatewayDetails?.length == 0 && !accountGatewayDetailsLoading">
              <span class="italicize">There are no gateways currently registered to your account.</span>
            </div>
          </ion-card-content>
          <div id="i_card_gateway_information_cardBottomFooter" class="cardBottomFooter {{ accountGatewayDetailsLoading ? 'cardDataLoading' : 'cardDataLoaded' }} automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card id="i_card_payment_methods" name="i_card_payment_methods">
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Payment Methods</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="viewAccountPaymentMethods()" title="To Payment Methods">
              <ion-icon *ngIf="accountPaymentMethods?.length > 0" name="arrow-forward-outline" class="ion-padding-start"
                color="black" me-icon-type-2></ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content me-card-min-height me-top-border>
            <div *ngIf="accountPaymentMethodsLoading" class="accountLoadingMessage">
              <ion-label>Loading </ion-label>
              <ion-spinner name="dots"></ion-spinner>
            </div>
            <div scrolly="true" me-scroll-div>
              <div *ngIf="accountPaymentMethods?.length > 0 && !accountPaymentMethodsLoading">
                <ion-list style="padding-bottom: 0px;">
                  <ion-item lines="none" me-ion-border *ngFor="let paymentMethod of accountPaymentMethods">
                    <strong><ion-label position="stacked" me-ion-border me-payment-label>
                      {{ paymentMethod.card_type | formatCardType }} ending
                      in {{ paymentMethod.last_four }}</ion-label></strong>
                    <ion-label position="stacked" me-ion-border me-payment-label>
                      Credit Card Exp.: {{ paymentMethod.expiration }}</ion-label>
                    <ion-icon class="failedPaymentIcon" color="danger" size="large" name="alert-circle"
                      *ngIf="paymentMethod.failed_payment_message">
                    </ion-icon>
                  </ion-item>
                </ion-list>
              </div>
              <div *ngIf="accountLicenses?.length > 0 && !accountPaymentMethodsLoading">
                <ion-list>
                  <ion-item lines="none" me-ion-border *ngFor="let accountLicense of accountLicenses">
                    <strong><ion-label position="stacked" me-ion-border me-payment-label>
                      {{ accountLicense.order_number }}</ion-label></strong>
                    <ion-label position="stacked" me-ion-border me-payment-label>
                      License Exp.: {{ accountLicense?.licenseExpirationDate | date : 'MM/dd/yyyy'}}</ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <div *ngIf="!accountLicenses?.length && !accountPaymentMethods?.length && !accountPaymentMethodsLoading"
                   class="no-licenses-message-row">
                <span class="italicize">There are no payment methods currently on file with your account</span>
              </div>
            </div>
          </ion-card-content>
          <ion-grid class="cardFooterIcon paymentHistoryIconCont" *ngIf="!accountPaymentMethodsLoading">
            <ion-row>
              <ion-col me-center-grid-col-content>
                <app-large-icon-button iconTitle="Payment History" iconName="reader" (iconAction)="viewAccountPaymentHistory()"></app-large-icon-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col me-center-grid-col-content>
                <strong>
                  <ion-label id="i_label_payment_history" name="i_label_payment_history">
                    Payment History
                  </ion-label>
                </strong>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div id="i_card_payment_methods_cardBottomFooter" class="cardBottomFooter {{ accountPaymentMethodsLoading ? 'cardDataLoading' : 'cardDataLoaded' }} automationTesting"></div>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>


<ion-content class="ion-no-padding" main-background *ngIf="currentView != 0">
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1>Member Details</h1>
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="backToManageMembers()" me-button-as-link id="btnBackToSiteMembers" name="btnBackToSiteMembers">
        &#8592;&nbsp;Back to Site Members </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-grid>
    <ion-row>
      <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card>
          <ion-card-content color="white" me-top-border class="ion-no-padding">
            <div>
              <ion-grid class="ion-no-padding">
                <ion-row class="ion-justify-content-center">
                  <ion-col size-xl="12" size-lg="12" size-md="6" size-sm="12" size="12">
                    <div>
                      <ion-grid me-account-detail-main-grid>
                        <ion-row>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                            <div>
                              <ion-list *ngIf="imageUrl.length==0">
                                <ion-item lines="none">
                                  <img me-img-account-detail src="../../../assets/imgs/edit-md-imagesaspect.svg">
                                </ion-item>
                              </ion-list>

                              <img  *ngIf="imageUrl.length>0" me-img-account-detail src="{{imageUrl}}"  
                                id="uploadAccountPicture" name="uploadAccountPicture" color="primary">
                            </div>
                          </ion-col>
                          <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                            <div me-member-since me-member-since-div>
                              <strong>Member Since</strong><br>{{memberSince}}
                            </div>
                          </ion-col>
                        </ion-row>

                        <ion-row>
                          <ion-col col col-6 me-center-ion-col-content>
                            <ion-label me-validation-error><i>
                                {{uploadPictureFileMessage}}
                              </i></ion-label>
                          </ion-col>

                          <ion-col col col-6 me-center-ion-col-content>
                          </ion-col>
                        </ion-row>

                      </ion-grid>
                    </div>
                  </ion-col>

                </ion-row>
              </ion-grid>
            </div>
          </ion-card-content>
          <div id="i_card_image_container_cardBottomFooter" class="cardBottomFooter automationTesting"></div>
        </ion-card>

      </ion-col>
    </ion-row>

    <ion-row>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Profile Information</strong>
            </ion-label>
          </ion-item>

          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col size="4"><strong>Name:</strong></ion-col>
                <ion-col>
                  <ion-label>
                    {{accountDetail && display_name(accountDetail.fname,accountDetail.lname,accountDetail.email)}}
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Email:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.email : ''}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Phone:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.phone : ''}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"><strong>Title:</strong></ion-col>
                <ion-col>
                  <ion-label>{{accountDetail ? accountDetail.title : ''}}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <div id="i_card_profile_information_cardBottomFooter" class="cardBottomFooter automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none" me-account-detail-card-header>
            <ion-label class="ion-padding" me-label-0-padding>
              <strong>Company Information</strong>
            </ion-label>
          </ion-item>

          <ion-card-content me-ion-card-content me-top-border me-card-min-height>
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col>
                  <ion-grid class="ion-no-padding">
                    <ion-row white-background>
                      <ion-col size="4"><strong>Company:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.company : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"><strong>Address:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.street_line1 : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.street_line2 : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-row white-background>
                      <ion-col size="4"></ion-col>
                      <ion-col>
                        <ion-label>
                          {{accountDetail ? accountDetail.city : ''}}{{accountDetail && accountDetail.city &&
                          accountDetail.state? ',' : ''}}
                          {{accountDetail ? ' ' + accountDetail.state : ' '}}
                          {{accountDetail ? accountDetail.postal_code : ''}}</ion-label>
                      </ion-col>
                    </ion-row>

                    <ion-row white-background>
                      <ion-col size="4"><strong>Phone:</strong></ion-col>
                      <ion-col>
                        <ion-label>{{accountDetail ? accountDetail.company_phone : ''}}</ion-label>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <div id="i_card_company_information_cardBottomFooter" class="cardBottomFooter automationTesting"></div>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
      </ion-col>
    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>