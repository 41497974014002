// import { AccountSubscriptionChangePaymentMethodComponent } from '../account-subscription-change-payment-method/account-subscription-change-payment-method.component';
//import { devEnv, TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE } from 'src/app/constants/kenzaconstants';
import { GetPaymentHistoryResponse, InvoiceDetail } from 'src/app/features/manage/components/classes/InvoiceDetail';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { UserService } from 'src/app/common/services/user/user.service';
//import { AccountService } from '../../services/accountService';
import { AlertController, IonGrid } from '@ionic/angular';
import { Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-payment-history',
  templateUrl: './account-payment-history.component.html',
  styleUrls: ['./account-payment-history.component.scss'],
})

export class AccountPaymentHistoryComponent /*implements OnInit*/ {
  @ViewChild(`paymentHistoryScrollContainer`, { static: false }) paymentHistoryScrollContainer: IonGrid;
  @ViewChildren(IonGrid) paymentHistoryTableRows: QueryList<IonGrid>;
  @ViewChild(IonGrid) paymentHistoryTable: IonGrid;
  paymentHistorySubscription: Subscription = null;
  //devEnv = devEnv;
  invoices;
  filteredInvoices = [];
  transferLocked = false;
  siteGateways: Gateway[] = null;
  invoicesLoading: boolean;
  loading: boolean;
  noInvoices: boolean;
  paginationKey:string; // kenza pagination key to get more invoice data 
                        // used by infinite loader to get more invoices to show
  searchTerm: string;
  noMoreInvoices:boolean;
  noResultsFound: boolean;

  constructor(
    public user: UserService,
    private siteService: SiteService,
    //private accountService: AccountService,
    private alertController: AlertController,
    //private modalController: ModalController,
    private mainSiteUIService: MainSiteUIService,
    // private loadingController: LoadingController,
    private router: Router,
    @Inject(LOCALE_ID) public locale:string
  ) { }

  // ngOnInit() {
  // }

  ionViewWillEnter() {
    // loading new or from cache
    this.paginationKey = '';
    // refresh the payment/invoice history
    this.loading = true;
    this.noInvoices = false;
    this.invoices = [];
    this.noMoreInvoices = false;

    this.getPaymentHistory();
    if (this.paymentHistorySubscription == null) {
      this.paymentHistorySubscription = this.paymentHistoryTableRows.changes.subscribe((fetchedInvoices: QueryList<IonGrid>) => {
        if (fetchedInvoices.length > 0) {
          (<any>this.paymentHistoryScrollContainer).el.addEventListener(`scroll`, scrollEvent => {
            let { scrollTop, scrollHeight, offsetHeight } = scrollEvent.target;
            let scrollMinusOffset = scrollHeight - offsetHeight;
            let scrolledToBottom = scrollTop >= (scrollMinusOffset - 3);
            if (scrolledToBottom && !this.searchTerm) {
              if (!this.noMoreInvoices && !this.invoicesLoading) {
                this.invoicesLoading = true;
                this.siteService.getPaymentHistory(this.paginationKey).subscribe((res: GetPaymentHistoryResponse) => {
                  this.processGetPaymentHistoryResponse(res);
                });
              }
            }
          })
        }
      })
    }
  }

  currencyFormatter = new Intl.NumberFormat(`en-US`, {
    style: `currency`,
    currency: `USD`,
  });

  searchFilterInvoices(ionChangeEvent) {
    this.searchTerm = ionChangeEvent.detail.value.toLowerCase();
    if (this.searchTerm) {
      this.filteredInvoices = this.invoices.filter(invoice => {
        if (this.currencyFormatter.format(invoice.total).includes(this.searchTerm) 
        || invoice.site_name.toLowerCase().includes(this.searchTerm)  
        || invoice.status.toLowerCase().includes(this.searchTerm) 
        || invoice.date.toLowerCase().includes(this.searchTerm)
        || `#${invoice.number}`.includes(this.searchTerm)) {
          return invoice;
        }
      });

      if (this.filteredInvoices.length == 0) {
        this.noResultsFound = true;
      } else {
        this.noResultsFound = false;
      }
    } else {
      this.filteredInvoices = this.invoices;
      if (this.filteredInvoices.length == 0) {
        this.noResultsFound = true;
      } else {
        this.noResultsFound = false;
      }
    }
  }

  ionViewWillLeave() {
    if (this.paymentHistorySubscription) {
      this.paymentHistorySubscription.unsubscribe();
      this.paymentHistorySubscription = null;
    }
  }

  processGetPaymentHistoryResponse(res: GetPaymentHistoryResponse) {
    // response from call to get payment history - process it.
    if (res.first) this.invoices = [];
    res.invoices.forEach((invoice: InvoiceDetail) => {
      invoice[`date`] = formatDate(invoice.created_at, `MM/dd/yyyy`, this.locale);
      invoice[`full_date`] = formatDate(invoice.created_at, `long`, this.locale);
      invoice[`status`] = invoice[`status`].replace(`_`,` `);
      let title = `Go To Site ` + invoice[`site_name`];
      if (!invoice[`owner_active`]) title = `No longer the owner of this site`;
      if (!invoice[`site_active`]) title = `This site has been deactivated`;
      invoice[`title`] = title;
      invoice[`link_enabled`] =  invoice[`owner_active`] && invoice[`site_active`];
      this.invoices.push(invoice);
     });
    this.filteredInvoices = this.invoices;

    if (this.invoices.length == 0) this.noInvoices = true;
    this.loading = false;
    this.invoicesLoading = false;
    this.paginationKey = res.pagination_key;
    if (res.last) {
      this.paginationKey = ``;
      this.noMoreInvoices = true;
    }
  }

  getPaymentHistory() {
    // make that call 
    this.siteService.getPaymentHistory(this.paginationKey).subscribe((res: GetPaymentHistoryResponse) => this.processGetPaymentHistoryResponse(res));
  }

  // onIonInfinite(ev) {
  //   // get more data
  //   if (!this.noMoreInvoices) {
  //     this.siteService.getPaymentHistory(this.paginationKey).subscribe((res: GetPaymentHistoryResponse) => {
  //       this.processGetPaymentHistoryResponse(res);
  //         ev.target.complete();
  //     })
  //   } else {
  //     ev.target.complete();
  //   }
  // }

  trackByInvoiceId(index, item) {
    // the invoice.id is unique
    return item.id;
  }


  returnToAccountDetail() {
    // link to account details
    this.mainSiteUIService.viewAccountDetail();
  }

  navigateToSite(site_id: string) {
    // link to site dashboard
    this.router.navigate([`/site`, site_id, `dashboard`])
  }

  async viewInvoice(invoice_id: string, site_id: string) {
    this.siteService.downloadInvoice(invoice_id, site_id).subscribe((r: Blob) => {
      const fileURL = URL.createObjectURL(r);
      window.open(fileURL, `_blank`, `width=1000, height=800`);
    })
  }

  async downloadInvoice(invoice_id: string, site_id: string) {
    this.siteService.downloadInvoice(invoice_id, site_id).subscribe((r: Blob) => {
      const fileURL = URL.createObjectURL(r);
      const a = document?.createElement(`a`);
      if (document && document?.body) document?.body?.appendChild(a);
      a.href = fileURL;
      a.download = `invoice_${invoice_id}.pdf`;
      a.click();
      a.remove();
    })  
  }

}