import { DisplayOptions } from "src/app/common/classes/displayOptions"
import { SelectedGroup } from "src/app/common/components/group-selector/group-selector.component"
import { AppAuthenticationService } from "src/app/common/services/authentication/app-authentication.service"
import { UserService } from "src/app/common/services/user/user.service"
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { GatewayUnitTwoDigitType, GatewayUnitTwoDigitTypeToEnum } from "src/app/enumerations/enums"
import { AirDirectionControl } from "./airDirectionControl"
import { FanSpeedControl } from "./fanSpeedControl"
import { InletTemp } from "./inletTemp"
import { ModeControl } from "./modeControl"
import { PowerControl } from "./powerControl"
import { SetTempControl } from "./setTempControl"
import { VentModeControl } from "./ventModeControl"

export enum GatewaysConnectionStatusEnum {
    online,     // all gateways online
    warning,    // some gateways online
    offline     // no gateways online
}

export enum GatewaysControlFeatureStatusEnum {
    none,   // no gateways are allowed control ferature
    some,   // some gateways are allowed control feature
    all     // all gateways are allowed controlfeature
}

export class BatchGroupDisplay {
    // object used to track how the site-control page should display the properties
    // of the selected groups from the group selector
    type: GatewayUnitTwoDigitType                                           // the type of gateway units in the batch
    control_permission: boolean                                             // does the current user have permission to control the batch
    label: string                                                           // label to display on control page
    icon: string                                                            // name of icon to show on control page
    lastUpdate: Date                                                        // datetime of the last received update to batch groups
    unitLastUpdated: Date                                                   // datetime of the last received update to specific unit
    singleGroup: boolean                                                    // indicates if we are in single or multi select mode
    zeroGroup: boolean                                                      // indicates we are in loading state - with nothing yet selected
 
    gatewaysConnectionStatus: GatewaysConnectionStatusEnum;                 // status of the gateways connection
    gatewaysControlFeatureAllowedStatus: GatewaysControlFeatureStatusEnum;  // status of the gateways subscription feature

    // control options for the batch
    power: PowerControl
    mode: ModeControl
    vent_mode: VentModeControl
    fan_speed: FanSpeedControl
    air_direction: AirDirectionControl
    inlet_temp: InletTemp
    setTemp: SetTempControl

    do: DisplayOptions

    maintenance_active:boolean // is there a maintenanceJob active on anyone in the current batch?

    constructor(
        public user: UserService,
        public appAuth: AppAuthenticationService,
        public mainUIService: MainSiteUIService) {
        this.type = GatewayUnitTwoDigitType.IndoorUnit;
        this.control_permission = false;
        this.label = '';
        this.icon = '';
        this.zeroGroup = true; // assume loading state
        this.singleGroup = true;
        this.lastUpdate = null;
        this.gatewaysConnectionStatus = GatewaysConnectionStatusEnum.online;
        this.gatewaysControlFeatureAllowedStatus = GatewaysControlFeatureStatusEnum.all;
        this.power = new PowerControl;
        this.mode = new ModeControl;
        this.vent_mode = new VentModeControl;
        this.fan_speed = new FanSpeedControl;
        this.air_direction = new AirDirectionControl;
        this.inlet_temp = new InletTemp;
        this.setTemp = new SetTempControl;
        this.do = new DisplayOptions;
        this.maintenance_active = false;
    }

    setType(unit_type: string) {
        // set the type based on the provided string - expected like IC or LC
        this.type = GatewayUnitTwoDigitTypeToEnum[unit_type];
    }

    isLossnay():boolean {
        // are we a lossnay batch?
        return this.type == GatewayUnitTwoDigitType.Lossnay;
    }

    isIndoorCoil():boolean {
        //are we an indoor coil batch?
        return this.type == GatewayUnitTwoDigitType.IndoorUnit;
    }

    gatewaysStatusOnline():boolean {
        // are the gateways of the batch all online?
        return this.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.online;
    }

    gatewaysStatusWarning():boolean {
        // are the gateways of the batch partly! online?
        return this.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.warning;
    }

    gatewaysStatusOffline():boolean {
        // are the gateways of the batch all offline?
        return this.gatewaysConnectionStatus == GatewaysConnectionStatusEnum.offline;
    }

    isMaintenanceActive():boolean {
        // are any of the groups running a maintenance job?
        return this.maintenance_active;
    }

    update(selectedGroups: SelectedGroup[], hardReset = true, maintenanceJobActive = false) {
        // update the object used to render the UI with suitable details based on selectedGroups[]
        // hardReset implies reset ignorables and set all controls to be 'what they are'
        // non-hardReset implies dont touch ignorables and only update badge counts on controls

        // maintenanceJobActive indicates if any group in the batch is currently active in a maintenance job
        // in this case all operations should be disabled.

        this.zeroGroup = selectedGroups.length == 0;
        this.singleGroup = selectedGroups.length == 1;

        this.lastUpdate = new Date();
        this.unitLastUpdated = selectedGroups[0].gatewayUnits[0].last_updated;

        this.maintenance_active = maintenanceJobActive;

        if (selectedGroups.length > 0) {
            // ok - update.
            const groupCount = selectedGroups.length;

            if (this.singleGroup) {
                if (selectedGroups[0].gatewayUnits.length > 1) this.icon = `copy`;
                else this.icon = `stop`;
                this.label = selectedGroups[0].gatewayGroup.group_name;
            } else {
                this.icon = ``;
                this.label = ``;
            }

            // they all have to be the same type - so just take the first unit from the first group.
            this.setType(selectedGroups[0].gatewayUnits[0].type);

            // is this user allowed to make control changes?
            this.control_permission = this.appAuth.doesLevelHavePermission(this.user.activeSiteUserLevel, this.appAuth.permissionEnums.ControlUnit);

            // reset control sections
            this.power.reset(hardReset);
            this.mode.reset(hardReset);
            this.vent_mode.reset(hardReset);
            this.fan_speed.reset(hardReset);
            this.air_direction.reset(hardReset);
            this.inlet_temp.reset(this?.user?.accountPreferences?.temperaturepreference_id);
            this.setTemp.reset(hardReset, this?.user?.accountPreferences?.temperaturepreference_id, this?.mainUIService?.isDevTestAltSite());
           
            // add selected groups to the batch
            selectedGroups.forEach((group) => {
                const unit = group.gatewayUnits[0];
                this.power.addGroupToBatch(unit);
                this.mode.addGroupToBatch(unit);
                this.vent_mode.addGroupToBatch(unit);
                this.fan_speed.addGroupToBatch(unit);
                this.air_direction.addGroupToBatch(unit);
                this.inlet_temp.addGroupToBatch(unit);
                this.setTemp.addGroupToBatch(unit);
            })

            // finalize
            this.power.setDisplayOptions(groupCount, maintenanceJobActive);
            this.mode.setDisplayOptions(groupCount, maintenanceJobActive);
            this.vent_mode.setDisplayOptions(groupCount, maintenanceJobActive);
            this.fan_speed.setDisplayOptions(groupCount, maintenanceJobActive);
            this.air_direction.setDisplayOptions(groupCount, maintenanceJobActive);
            this.inlet_temp.setDisplayOptions(this.singleGroup);
            this.setTemp.setDisplayOptions(groupCount, !this.mode.singleMode, maintenanceJobActive);

        } else {
            console.log(`updateBatchGroupDisplay: no groups?`);
        }

    }
}