export class Account {
    id: string;
    account_photo_name: string;
    account_photo_url: string;
    city: string;
    company: string;
    country: string;
    createdAt: string;
    email: string;
    fname: string;
    lastLogin: string;
    lname: string;
    phone: string;
    postalCode: string;
    removedAt: string;
    state: string;
    status: string;
    streetLine1: string;
    streetLine2: string;
    level: number;
    levelDisplayText: string;

    constructor(data: any) {
        this.id = data.id,
            this.account_photo_name = data.account_photo_name,
            this.account_photo_url = '',
            this.city = data.city,
            this.company = data.company,
            this.country = data.country,
            this.createdAt = data.created_at,
            this.email = data.email,
            this.fname = data.fname,

            this.lastLogin = data.last_login,
            this.lname = data.lname,
            this.phone = data.phone,
            this.postalCode = data.postal_code,
            this.removedAt = data.removed_at,
            this.state = data.state,
            this.status = data.status,
            this.streetLine1 = data.street_line1,
            this.streetLine2 = data.street_line2,
            this.level = data.level,
            this.levelDisplayText = data.levelDisplayText;
    }
}
