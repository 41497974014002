/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { ModalController } from '@ionic/angular';
import { SiteService } from '../../services/site.service';
import { SiteGatewayPlansComponent } from '../site-gateway-plans/site-gateway-plans.component';

@Component({
  selector: 'app-select-gateway',
  templateUrl: './select-gateway.page.html',
  styleUrls: ['./select-gateway.page.scss'],
})
export class SelectGatewayPage implements OnInit {
  @Input() parentGatewayList: Gateway[];
  @Input() subscriptionCheckField = "";
  @Input() subscriptionCheckValue = "";

  gatewayList: Gateway[] = [];

  constructor(private modalController: ModalController, private siteService: SiteService) { }

  ngOnInit() {

    this.gatewayList = this.parentGatewayList.map(gateway => {
      const isChecked = false;
      return Object.assign(gateway, { isChecked });
    });
    this.gatewayList.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  setClickedSelection(gateway: any) {

    if (!this.siteService.handleIsConnected())
      return;

    // check subscription?
    if (this.subscriptionCheckField) {
      if (gateway.subscription_features[this.subscriptionCheckField] != this.subscriptionCheckValue) {
        // then this is denied.
        this.presentSubscriptionOptions(gateway.gateway_model_class_name);
        return;
      }
    }

    this.modalController.dismiss({
      gatewaySelected: gateway === null ? false : true,
      selectedGateway: gateway,
    });

  }

  close() {
    this.modalController.dismiss({
      gatewaySelected: false,
      selectedGateway: null,
    });
  }

  async presentSubscriptionOptions(gateway_model_class_name: string) {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      backdropDismiss: true,
      componentProps: {
        title: "Upgrade your Subscription Plan",
        initialModelClassNameToDisplay: gateway_model_class_name
      },
    });
    return await modal.present();

  }

}
