import { Component, OnInit, Input, ɵConsole, OnDestroy } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { GatewayUnit } from '../../../../../manage/components/classes/GatewayUnit';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { GroupAdminComponent } from '../../groups/group-admin/group-admin.component';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { ModelState, GatewayUnitTypeEnum, ToastMessageTypeEnum, GatewayUnitTwoDigitType, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { MapGatewayRequest } from 'src/app/common/services/websockets/classes/requests/MapGatewayRequest';
import { Gateway } from '../../../../../manage/components/classes/Gateway';
import { MAPUNITS_WAIT_TIME, TOAST_UNABLE_TO_MAP_GATEWAY_MESSAGE, TOAST_SUCCESS_TITLE, TOAST_SUCCESSFULLY_MAPPED_DEVICES, TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH, TOAST_GENERAL_ERROR_TITLE } from 'src/app/constants/kenzaconstants';
import { v4 as uuid } from 'uuid';
import { GatewayGroup } from '../../../../../manage/components/classes/GatewayGroup';
import { SiteGatewayUnitEditPage } from 'src/app/features/sites/pages/site-gateway-unit-edit/site-gateway-unit-edit.page';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group-indoor-unit-admin',
  templateUrl: './group-indoor-unit-admin.component.html',
  styleUrls: ['./group-indoor-unit-admin.component.scss'],
})
export class GroupIndoorUnitAdminComponent implements OnInit, OnDestroy {
  @Input() selected_odu_groups: GatewayGroup[];
  @Input() selected_odu_indoor_units: GatewayUnit[];
  @Input() selected_gateway: Gateway;
  @Input() selected_outdoor_unit: GatewayUnit;
  group_model_state = ModelState;
  socketEventSubscription:Subscription = null;
  modalOpen = false;
  loadMain: any;

  constructor(
    public user: UserService,
    public appAuth: AppAuthenticationService,
    private siteService: SiteService,
    private modalController: ModalController,
    private toastController: ToastController,
    private socketService: SocketService,
    private loadingController: LoadingController,
    private toastService: ToastrService,
  ) {
  }

  ionViewWillEnter() {
    if (this.socketEventSubscription == null) {
      this.socketEventSubscription = this.socketService.SocketServiceEmitter
        .subscribe((socketResult: any) => {
          if (socketResult && socketResult.response_type) {
            switch (socketResult.response_type) {
              case WebSocketResponseTypeEnum.Map_Gateway_Complete:
                this.showGatewayDetails(this.selected_gateway.id, this.selected_gateway.site_id);
                this.siteService.presentToastMessage(
                  ToastMessageTypeEnum.Success,
                  TOAST_SUCCESS_TITLE,
                  TOAST_SUCCESSFULLY_MAPPED_DEVICES);
                break;
            }
          }
        });
    }

    if (this.loadMain && this.loadMain.dismiss)
        this.loadMain.dismiss();
  }

  ionViewWillLeave() {
    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
      this.socketEventSubscription = null;
    }
  }

  ngOnInit() {
    if ((this.selected_odu_groups) && (this.selected_odu_groups.length > 0)) {
      this.selected_odu_groups = this.selected_odu_groups.sort(this.compareValues('group_name', 'asc'));

      this.selected_odu_groups = this.selected_odu_groups.map(odu_group => {
        if (odu_group.units && (odu_group.units.length > 0)) {
          odu_group.units = odu_group.units.sort(this.compareValues('name', 'asc'))
        }
        return odu_group;
      })

    }
    this.sortUnits();
  }

  ngOnDestroy(): void {
    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
    }   
  }

  get_selected_odu_idus(gateway_indoor_units: GatewayUnit[], selected_outdoor_unit: GatewayUnit) {
    let selected_odu_idus: GatewayUnit[] = [];
    selected_odu_idus = gateway_indoor_units.filter(idu => (idu.odu) && (idu.odu.id === selected_outdoor_unit.id));
    return selected_odu_idus;
  }

  showGatewayDetails(gatewayId, siteId?) {
    if (!this.siteService.handleIsConnected())
      return;

    if (siteId == null) {
      siteId = this.user.active.id;
    }

    this.siteService
      .getGatewayDetails(siteId, gatewayId)
      .subscribe(gatewayDetails => {
        this.displayUnits(gatewayDetails['units']);
      }, err => {
        // Creating a toast right now but should probably be a redirect to 403/404 or generic error page in the future.
        // Issue came up because user could reach this page by just typing in the URL without having permissions.
        this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, "Error Displaying Page", "There was an error in rendering the page.");
      });
  }

  close() {
    this.modalController.dismiss();
  }

  displayUnits(units: GatewayUnit[]) {
    this.selected_odu_indoor_units = [];
    // this.gateway_outdoor_units = [];

    // check for units and outdoor units
    if ((units == null) ||
      (units.filter(ou => ou.type.toLowerCase() === GatewayUnitTwoDigitType.OutdoorUnit.toLowerCase()).length === 0)) {
      return;
    }

    if ((units) && (units.length > 0)) {
      units = units.map(u => {
        u.site_id = this.user.active.id;
        if (!u.bus_address) {
          u.bus_address = '';
        }

        if (!u.name) {
          u.name = '';
        }

        if (u.type === GatewayUnitTwoDigitType.OutdoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.OutdoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.IndoorUnit) {
          u.unit_type = GatewayUnitTypeEnum.IndoorUnit;
        } else if (u.type === GatewayUnitTwoDigitType.Lossnay) {
          u.unit_type = GatewayUnitTypeEnum.Lossnay;
        }

        u.isExpanded = false;

        return u;
      });

      this.selected_odu_indoor_units = units.filter(ou => [GatewayUnitTwoDigitType.IndoorUnit.toLowerCase(), GatewayUnitTwoDigitType.Lossnay.toLowerCase()].includes(ou.type.toLowerCase()));
      // this.gateway_outdoor_units = units.filter(ou => ou.type.toLowerCase() === GatewayUnitTwoDigitType.OutdoorUnit.toLowerCase());
    }

    this.sortUnits();
  }

  showSelectedIndoorUnit(gatewayId: string) {
    this.selected_odu_indoor_units.forEach(io => {
      if (io.id === gatewayId) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  showSelectedGatewayGroup(group_id: string) {
    this.selected_odu_groups.forEach(io => {
      if (io.group_id === group_id) {
        io.isExpanded = !io.isExpanded;
      } else {
        io.isExpanded = false;
      }
    });
  }

  get_next_group_name(): string {
    let next_group_name = 'New Group 1';
    let list_of_new_group: GatewayGroup[];

    if ((this.selected_odu_groups) && (this.selected_odu_groups.length > 0)) {
      list_of_new_group = this.selected_odu_groups.filter(grp => grp.group_name.toUpperCase().includes('NEW GROUP'));
      if (list_of_new_group.length > 0) {
        list_of_new_group = list_of_new_group.sort(this.compareValues('group_name', 'desc'));
        const last_group: string = list_of_new_group[0].group_name;
        const number: any = last_group.substring('NEW GROUP'.length, last_group.length).trim();

        if (!isNaN(number)) {
          next_group_name = 'New Group ' + (Number(number) + 1);
        }
      }
    }

    return next_group_name;
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      //if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].localeCompare(b[key]);

      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  get_group_name_by_id(indoor_unit: GatewayUnit, group_id: string): string {
    const group = this.get_group_by_id(group_id);

    if (indoor_unit && indoor_unit.type == "LC") {
      return "";
    }

    if ((!group) || (group.autocreated == true)) {
      return 'No Group Assigned';
    }
    return group.group_name;
  }

  get_group_by_id(group_id: string): GatewayGroup {
    return this.selected_odu_groups.find(gg => gg.group_id === group_id);
  }

  async on_open_group_admin(odu_group: GatewayGroup, selected_outdoor_unit: GatewayUnit, group_model_state: ModelState, skip_dismiss = false) {
    let modal_css_name = 'me-group-admin-modal';
    if (!this.siteService.handleIsConnected() || this.modalOpen) return;

    if (!skip_dismiss) {
      this.modalController.dismiss();
    }

    if ((group_model_state == ModelState.Create) && (odu_group == null)) {
      odu_group = new GatewayGroup();
      odu_group.group_name = this.get_next_group_name();
    }

    switch (group_model_state) {
      case ModelState.Create:
      case ModelState.Update:
      case ModelState.Quick_Update:
        modal_css_name = 'me-group-admin-modal';
        break;

      case ModelState.Delete:
        modal_css_name = 'me-group-small-admin-modal';
        break;
    }

    const modal = await this.modalController.create({
      component: GroupAdminComponent,
      cssClass: modal_css_name,
      backdropDismiss: false,
      componentProps: {
        group_model_state: group_model_state,
        selectedGateway: this.selected_gateway,
        selected_outdoor_unit: this.selected_outdoor_unit,
        group: odu_group,
        selected_odu_groups: this.selected_odu_groups,
        selected_odu_indoor_units: this.selected_odu_indoor_units.filter(iu => (!iu.odu) || ((iu.type === GatewayUnitTwoDigitType.IndoorUnit) && (iu.odu.id == selected_outdoor_unit.id))),
      },
    });

    modal.onDidDismiss().then((data) => {
      this.modalOpen = false;
    });
    this.modalOpen = true;
    return await modal.present();
  }

  async onEditUnit(gatewayUnit: GatewayUnit) {
    if (!this.siteService.handleIsConnected() || this.modalOpen) return;

    this.modalController.dismiss();

    const modal = await this.modalController.create({
      component: SiteGatewayUnitEditPage,
      cssClass: 'me-modal-gw-unit',
      backdropDismiss: false,
      componentProps: {
        parentGateway: this.selected_gateway,
        parentGatewayUnit: gatewayUnit,
        parentGatewayGroups: this.selected_odu_groups,
        showConfigureUnits: true,
      },
    });

    modal.onDidDismiss().then(data => {
      this.modalOpen = false;
      if (((data.data !== undefined) && (data.data !== null)) && ((data.data.edited) && (data.data.editedUnit))) {
        // if (data.data.edited) {
        //   this.siteService.emit_siteGatewayUnitChanged(data.data.editedUnit);
        //   this.siteService.emit_siteGatewayDetailRefresh(this.selected_gateway);
        //   const editedGatewayUnit: GatewayUnit = data.data.editedUnit;

        //   switch (editedGatewayUnit.unit_type) {
        //     case GatewayUnitTypeEnum.IndoorUnit:
        //       this.selected_odu_indoor_units = this.selected_odu_indoor_units.map(iu => {
        //         if (iu.id === editedGatewayUnit.id) {
        //           iu.name = editedGatewayUnit.name;
        //           iu.group_id = editedGatewayUnit.group_id;
        //         }
        //         return iu;
        //       });
        //       this.sortUnits();
        //       break;
        //   }
        //   this.configureGroups();
        // }


      } else {
        // this.configureGroups();
      }
    });

    this.modalOpen = true;
    return await modal.present();
  }

  sortUnits() {
    if ((this.selected_odu_indoor_units) && (this.selected_odu_indoor_units.length > 0)) {
      this.selected_odu_indoor_units.sort((a, b) => (a.bus_address > b.bus_address) ? 1 : -1);
    }
  }

  async configureGroups() {
    if (!this.siteService.handleIsConnected() || this.modalOpen) return;
    const modal = await this.modalController.create({
      component: GroupIndoorUnitAdminComponent,
      cssClass: '',
      backdropDismiss: true,
      componentProps: {
        selected_gateway: this.selected_gateway,
        selected_outdoor_unit: this.selected_outdoor_unit,
        selected_odu_groups: this.selected_odu_groups,
        selected_odu_indoor_units: this.selected_odu_indoor_units,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.modalOpen = false;
    });
    this.modalOpen = true;
    return await modal.present();
  }

  async presentToast(toastColor: string, displayMessage: string) {
    const toast = await this.toastController.create({
      message: displayMessage,
      duration: 5000,
      color: toastColor,
      position: 'middle',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-outline',
        },
      ]
    });
    toast.present();
  }

  async present_loadMain() {
    this.loadMain = await this.loadingController.create({
      message: 'Mapping units...',
      spinner: 'lines',
      duration: MAPUNITS_WAIT_TIME,
    });

    await this.loadMain.present();
  }

  async on_map_units() {

    if (!this.siteService.handleIsConnected()) return;

    if (!this.socketService.is_connected()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      console.log('The socket service is not connected');
      return;
    }

    const mapGatewayRequest: MapGatewayRequest = new MapGatewayRequest();
    mapGatewayRequest.request_id = uuid();
    mapGatewayRequest.gateway_id = this.selected_gateway.id;
    mapGatewayRequest.site_id = this.selected_gateway.site_id;

    if (this.socketService.mapGateway(mapGatewayRequest)) {
      this.present_loadMain();
    } else {
      this.toastService.error(TOAST_UNABLE_TO_MAP_GATEWAY_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
    }
  }

}
