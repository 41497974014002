import { Subscription, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
// import { ItemReorderEventDetail } from '@ionic/angular';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { UserService } from "src/app/common/services/user/user.service";
import { SiteService } from "src/app/features/sites/services/site.service";
import { MainSiteUIService } from "src/app/common/services/ui/main-site-ui.service";
import { IonItem, IonList, LoadingController, PopoverController } from "@ionic/angular";
import { SiteNotification } from 'src/app/features/sites/components/site-alerts/classes/sitenotification';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { Component, ElementRef, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { KenzaCloudMenuIds, SiteNotificationTypeEnum, ToastMessageTypeEnum, SiteNotificationTypeActionEnum } from "src/app/enumerations/enums";
import {  ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS, ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS, ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS, ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS, ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS, devEnv, EQUIPMENT_ALERT_RESET_WAIT_TIME, ERROR_RESET_NO_GATEWAY, ERROR_RESET_SUCCESS, TOAST_SUCCESS_TITLE } from 'src/app/constants/kenzaconstants';

@Pipe({ name: 'safeHtml' })
 export class SafeHtmlPipe implements PipeTransform {
   constructor(private sanitizer: DomSanitizer) {}
   transform(value: any) {
     return this.sanitizer.bypassSecurityTrustHtml(value);
   }
 }

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})

export class NotificationsComponent {

    // kenza pagination key to get more notification data 
    // used by infinite loader to get more notifications to show
    notificationListItems = [];
    noMoreNotifications: boolean;
    noNotificationsFound: boolean;
    notificationsSubscription: Subscription = null;
    @ViewChild('notificationsList', { static: false }) notificationsList: IonList;
    @ViewChildren(IonItem, { read: ElementRef }) notificationItems: QueryList<ElementRef>;

    siteNames;
    isScrolling;
    filterBadge;
    siteNameFilters;
    uniqueSiteNames;
    devEnv = devEnv;
    copyOfFiltered = [];
    scrolledBottom = false;
    nextSetOfNotifications = [];
    notificationTypes: any = [];
    notificationsLoaded = false;
    firstSetOfNotifications = [];
    resetAlertLoader: any = null;
    notificationsLoading = false;
    is_connected: boolean = true;
    notificationsExpanded = true;
    notificationsPaginateSets = true;
    windowHeight = window.innerHeight;
    notifDateFormat = `MM/DD/YYYY, hh:mm A`;
    notificationsLoadingSubject = new Subject<boolean>();
    siteNameFiltersOn = false; // Set to True for Site Name Filters on Global Notifications
    filteredNotificationTypes = this.mainSiteUIService.notifTypesFromEnum.filter(type => type != `EquipmentNotification`);
    siteNotificationTypeAction = SiteNotificationTypeActionEnum;

    constructor(
        public user: UserService,
        private siteService: SiteService,
        private popoverCtrl: PopoverController,
        public appAuth: AppAuthenticationService,
        public mainSiteUIService: MainSiteUIService,
        public loadingController: LoadingController,
    ) {
        this.is_connected = this.siteService.handleIsConnected();
        this.notificationsLoading = true;
        if (this.notificationsPaginateSets) {
            this.firstSet();
        }
        this.notificationsLoading = false;
        this.notificationsLoaded = true;
    }

    log(notification) {
        devEnv && console.log(`Notification`, notification);
    }

    // handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    //     // The `from` and `to` properties contain the index of the item
    //     // when the drag started and ended, respectively
    //     devEnv && console.log(`Dragged from index`, ev.detail.from, `to`, ev.detail.to);

    //     // Finish the reorder and position the item in the DOM based on
    //     // where the gesture ended. This method can also be called directly
    //     // by the reorder group
    //     ev.detail.complete();
    // }

    firstSet() {
        this.noMoreNotifications = false;
        this.mainSiteUIService.noMoreNotifications = false;
        if (this.mainSiteUIService.filteredNotifications.length > 0) {
            this.firstSetOfNotifications = [...this.mainSiteUIService.filteredNotifications].slice(0, this.mainSiteUIService.notificationPageCount);
            this.nextSetOfNotifications = [...this.mainSiteUIService.filteredNotifications].slice(this.mainSiteUIService.notificationPageCount);
            this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications;
            this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
        } else {
            this.noMoreNotifications = false;
            this.mainSiteUIService.noMoreNotifications = false;
            this.firstSetOfNotifications = this.mainSiteUIService.notifications.slice(0, this.mainSiteUIService.notificationPageCount);
            this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications;
            this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
        }
    }

    ionViewWillEnter() {

        this.mainSiteUIService.activeSites = [];
        this.mainSiteUIService.activeFilters = [];

        this.noMoreNotifications = false;
        this.firstSetOfNotifications = this.mainSiteUIService.notifications.slice(0, this.mainSiteUIService.notificationPageCount);
        this.nextSetOfNotifications = this.mainSiteUIService.notifications.slice(this.mainSiteUIService.notificationPageCount);
        this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications;
        this.copyOfFiltered = [...this.mainSiteUIService.filteredNotifications];
        this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);

        this.notificationPagination();
    }

    getNotificationsInRange(notifications, start, end) {
        if (start < 0 || end >= notifications.length) {
            this.noMoreNotifications = true;
        }

        const notificationsInRange = [];
        for (let i = start; i <= end; i++) {
            notificationsInRange.push(notifications[i]);
        }
        return notificationsInRange;
    }

    notificationsScrollEvent = () => {
        clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(() => {
            // User has finished scrolling
            this.mainSiteUIService.notificationsScrolling = false;
        }, 500); // Adjust the timeout duration as needed
    };

    notificationPagination() {
        // Notification Pagination Functions
        this.notificationListItems = this.notificationItems.filter(item => (<any>this.notificationsList).el.contains(item.nativeElement));
        (<any>this.notificationsList).el.addEventListener(`scroll`, notificationsScrollEvent => {

            this.mainSiteUIService.notificationsScrolling = true;
            this.notificationsScrollEvent();

            if (notificationsScrollEvent.target.scrollTop >= (notificationsScrollEvent.target.scrollHeight - notificationsScrollEvent.target.offsetHeight)) {

                if (!this.noMoreNotifications) {
                    if (this.mainSiteUIService.clientSidePagination) {

                        this.getNotificationsInRange(this.mainSiteUIService.totalNotifications, this.mainSiteUIService.startIndex + this.mainSiteUIService.notificationPageCount, this.mainSiteUIService.startIndex + (this.mainSiteUIService.notificationPageCount * 2)).forEach((notif, index) => {
                            if (notif && !this.mainSiteUIService.filteredNotifications.map(not => not.id).includes(notif?.id)) {
                                this.mainSiteUIService.notifications.push(notif);
                            }
                        });

                        this.mainSiteUIService.startIndex = this.mainSiteUIService.startIndex + this.mainSiteUIService.notificationPageCount;
                        this.mainSiteUIService.endIndex = this.mainSiteUIService.endIndex + this.mainSiteUIService.notificationPageCount;

                        if (this.mainSiteUIService.endIndex >= this.mainSiteUIService.notificationsResponse.notifications.length) {
                            this.mainSiteUIService.endIndex = this.mainSiteUIService.notificationsResponse.notifications.length;
                            this.mainSiteUIService.startIndex = this.mainSiteUIService.endIndex - this.mainSiteUIService.notificationPageCount;
                            this.noMoreNotifications = true;
                        }

                        devEnv && console.log(`Getting Notifications`, this.mainSiteUIService.startIndex, `Through`, this.mainSiteUIService.endIndex);

                        this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
                    } else {
                        if (this.user.serverSidePagination) {
                            devEnv && console.log(`Scrolled to the Bottom of Notifications!`);
                            this.user.getAccountNotifications(this.user.notificationPageCount, this.mainSiteUIService.notificationPaginationKey).subscribe((accountNotifications: any) => {

                                // Server Pagination
                                devEnv && console.log(`Pagination Key`, this.mainSiteUIService.notificationPaginationKey);
                                let nextPageOfNotifications = this.mainSiteUIService.mapNotifications(accountNotifications.notifications);
                                if (nextPageOfNotifications.length > 0) {
                                    nextPageOfNotifications.forEach(notif => this.mainSiteUIService.notifications.push(notif));
                                    this.mainSiteUIService.notificationPaginationKey = accountNotifications.pagination_key;
                                    this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
                                }

                            });
                        } else {
                            if (this.notificationsPaginateSets && this.mainSiteUIService.activeFilters.length == 0 && this.mainSiteUIService.activeSites.length == 0 && this.mainSiteUIService.filteredNotifications.length < this.mainSiteUIService.totalNotifications.length) {
                                this.loadSet(true);
                            } else {
                                devEnv && console.log(`No more Notifications to Load!`);
                            }
                        }
                    }
                } else {
                    if (this.notificationsPaginateSets && this.mainSiteUIService.activeFilters.length == 0 && this.mainSiteUIService.activeSites.length == 0 && this.mainSiteUIService.filteredNotifications.length < this.mainSiteUIService.totalNotifications.length) {
                        this.loadSet();
                    } else {
                        devEnv && console.log(`No more Notifications to Load!`);
                    }
                }
            }
        })
    }

    loadSet(nextSet?) {
        this.scrolledBottom = true;
        if (nextSet) {
            this.nextSetOfNotifications.forEach(notif => {
                this.mainSiteUIService.filteredNotifications.push(notif);
            });
        } else {
            this.mainSiteUIService.notifications.slice(this.mainSiteUIService.notificationPageCount).forEach(notif => {
                this.mainSiteUIService.filteredNotifications.push(notif);
            });
        }
        this.mainSiteUIService.noMoreNotifications = true;
        this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
        this.copyOfFiltered = [...this.mainSiteUIService.filteredNotifications];
        this.noMoreNotifications = true;
    }

    notificationID(index, notification) {
        return notification.id;
    }

    badgeNum(type) {
        if (this.mainSiteUIService.activeSites.length > 0) {
            if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                return this.mainSiteUIService.filteredNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
            } else {
                if (this.mainSiteUIService.filteredNotifications.length == this.mainSiteUIService.totalNotifications.length) {
                    return this.mainSiteUIService.totalNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                } else {
                    return this.mainSiteUIService.filteredNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                }
            }
        } else {
            if (this.mainSiteUIService.activeFilters.length > 0) {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    return this.copyOfFiltered.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                } else {
                    return this.mainSiteUIService.totalNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                }
            } else {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    return this.mainSiteUIService.filteredNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                } else {
                    return this.mainSiteUIService.totalNotifications.filter(notif => type == this.mainSiteUIService.notifTypesFromEnumFull[notif?.sitenotificationtype_id]).length;
                }
            }
        }
    }

    notificationFocus(e) {
        e.preventDefault();
        this.windowHeight = window.innerHeight;
        this.notificationsExpanded = !this.notificationsExpanded;
    }

    resizeNotificationsContainer(e) {
        // let globalNotificationList: any = document.querySelector(`#appNotifs`);
        // globalNotificationList?.style?.left = window.innerWidth;
        // this.distanceFromRight = window.innerWidth;

        // this.popoverCtrl.getTop().then((popover) => {
        //     // console.log(window.innerWidth);
        //     // console.log(popover.style.left);
        //     popover.style.left = `${((window.innerWidth / 10) * -1)}px`;
        //     // popover.style.left = `${(window.innerWidth - (window.innerWidth * 0.9))}px`;
        // });

        this.windowHeight = window.innerHeight;
    }

    getSiteName(user, siteID) {
        let selectedSite = user.sites.filter(sit => sit.id == siteID);
        return selectedSite[0]?.name;
    }

    isRecent(notification) {
        if (notification.created_at) {
            let now = new Date();
            let minutesAgo = new Date(now.getTime() - 1 * 60 * 1000);
            let d = new Date(this.mainSiteUIService.timeFormat(notification.created_at));
            return minutesAgo <= d && d <= now ? `newNotification` : `appNotification`;
        } else {
            return `appNotification`;
        }
    }

    renderNotificationMessage(notificationMessage?: any) {
        if (notificationMessage?.includes(`[kenzalogo]`)) {
            notificationMessage = notificationMessage.replace(/\[kenzalogo\]/g, `
                <img alt="Kenza" src="https://www.kenzacloud.com/assets/imgs/logo.svg" height="20" />
            `);
        }
        return notificationMessage;
    }

    getNotificationLabels(notification) {
        switch (notification.type) {
            default:
                return `kenza cloud Notification`;
            case `Membership`:
                return notification?.context?.type == `SITE_TRANSFER` ? `Site Ownership Transfer Notification` : `Membership Notification`;
            case `Subscription`:
                return `Subscription Notification`;
            case `Gateway`:
            case `Gateways`:
                return `Gateway Notification`;
            case `Payments`:
                return `Payment Notification`;
            case `Equipment`:
                return `Equipment Alarm`;
            case `EquipmentNotification`:
                return `Equipment Notification`;
            }
    }

    getTypeIcon(type, notification?) {
        switch (type) {
            default:
                return notification && notification.servicePortalData ? `../../../assets/imgs/logo.svg` : `../../../assets/imgs/logo-black.svg`;
            case `Membership`:
                return notification?.context?.type == `SITE_TRANSFER` ? `../../../assets/imgs/business-sharp.svg` : `../../../assets/imgs/edit-md-person-notification.svg`;
            case `Subscription`:
                return `../../../assets/imgs/business-sharp.svg`;
            case `Gateway`:
            case `Gateways`:
                return `../../../assets/imgs/custom-gateway.svg`;
            case `Payments`:
                return notification?.context?.actionType == this.siteNotificationTypeAction.License_Activated ?
                     `../../../assets/imgs/receipt-outline.svg`
                    : `../../../assets/imgs/edit-md-card-notification.svg`;
            case `Equipment`:
                return `../../../assets/imgs/alert-circle.svg`;
            case `EquipmentNotification`:
                return `../../../assets/imgs/alert-circle.svg`;
        }
    }

    setSiteNameFilter(event) {
        if (!this.siteService.handleIsConnected()) return;
        let filter = event.target.id;
        let filterButton = event.target;
        if (this.mainSiteUIService.activeSites.includes(filter)) {
            this.mainSiteUIService.activeSites = this.mainSiteUIService.activeSites.filter(filt => filt != filter);
            filterButton.classList.remove(`active`);
        } else {
            this.mainSiteUIService.activeSites.push(filter);
            filterButton.classList.add(`active`);
        }

        if (this.mainSiteUIService.activeFilters.length == 0) {
            if (this.mainSiteUIService.activeSites.length > 0) {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                }
            } else {
                if (this.mainSiteUIService.filteredNotifications.length != this.mainSiteUIService.totalNotifications.length) this.noMoreNotifications = false;
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.copyOfFiltered;
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications;
                }
            }
        } else {
            if (this.mainSiteUIService.activeSites.length > 0) {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name)).filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name)).filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                }
            } else {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                }
            }
        }
        // this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
        // devEnv && console.log(`Filtered Notifications`, this.mainSiteUIService.filteredNotifications);
        this.mainSiteUIService.checkResettable(true);
    }

    setNotificationFilter(event) {
        if (!this.siteService.handleIsConnected()) return;
        let filter = (event.target.id == `Gateway` ? `Gateways` : event.target.id);
        let filterButton = event.target;
        if (this.mainSiteUIService.activeFilters.includes(filter)) {
            this.mainSiteUIService.activeFilters = this.mainSiteUIService.activeFilters.filter(filt => filt != filter);
            filterButton.classList.remove(`active`);
        } else {
            this.mainSiteUIService.activeFilters.push(filter);
            filterButton.classList.add(`active`);
        }

        if (this.mainSiteUIService.activeSites.length == 0) {
            if (this.mainSiteUIService.activeFilters.length > 0) {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.firstSetOfNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type));
                }
            } else {
                if (this.mainSiteUIService.filteredNotifications.length != this.mainSiteUIService.totalNotifications.length) this.noMoreNotifications = false;
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.copyOfFiltered;
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications;
                }
            }
        } else {
            if (this.mainSiteUIService.activeFilters.length > 0) {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.filteredNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type)).filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeFilters.includes(notif?.type)).filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                }
            } else {
                if (this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications) {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.filteredNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                } else {
                    this.mainSiteUIService.filteredNotifications = this.mainSiteUIService.totalNotifications.filter(notif => this.mainSiteUIService.activeSites.includes(notif?.site_name));
                }
            }
        }
        // devEnv && console.log(`Filtered Notifications`, this.mainSiteUIService.filteredNotifications);
        this.mainSiteUIService.setNotificationsUI(this.user, true);
    }

    async navigateToSiteAlert(e?, notification?) {
        if (!this.siteService.handleIsConnected()) return;
        this.dismiss();
    }

    async navigateFromLinkInsideNotification(e) {
        if (!this.siteService.handleIsConnected()) return;
        if (e.target[`href`]) this.dismiss();
    }

    async navigateToSiteDashboard(user, siteID) {
        if (!this.siteService.handleIsConnected()) return;
        let selectedSite = user.sites.filter(sit => sit.id == siteID);
        await this.user.setActiveSite(selectedSite, true);

        let siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
        siteAlertFilter.site_id = siteID;
        siteAlertFilter.UserLevelEnum = this.user?.activeSiteUserLevel;

        await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);

        this.user.setActiveSiteAndNavigate(
            selectedSite[0],
            KenzaCloudMenuIds.Dashboard,
            KenzaCloudMenuIds.Dashboard
        );

        this.dismiss();
    }

    dismissNotification(notification: SiteNotification) {
        if (!this.siteService.handleIsConnected()) return;
        if (notification.sitenotificationtype_id == SiteNotificationTypeEnum.Global) {
            this.siteService.acknowledgeGlobalNotification(notification.id).subscribe(data => {
                this.mainSiteUIService.dismissNotification(notification, this.user);
                this.presentToastWithOptions(notification);
            }, (error) => {
                console.log(`Error Acknowledging Global Notification(s)`, error);
            });
        } else {
            this.siteService.dismissSiteNotification(notification.id, this.user.id).subscribe(data => {
                if (this.user.active && this.user.active.id == notification?.site_id) {
                    const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
                    siteAlertFilter.site_id = notification.site_id;
                    siteAlertFilter.UserLevelEnum = this.user?.activeSiteUserLevel;
                    this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
                }
                this.mainSiteUIService.dismissNotification(notification, this.user);
                this.presentToastWithOptions(notification);
            }, (error) => {
                console.log(`Error Dismissing Notification(s)`, error);
            });
        }
        this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
    }

    async dismissNotifications(event) {
        if (!this.siteService.handleIsConnected()) return;
        if (this.mainSiteUIService.resettableNotifications.length > 0) {

            if (this.mainSiteUIService.resettableNotifications.filter(notif => notif?.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length >= 1) {
                this.resetAlertLoader = await this.loadingController.create({
                    message: 'Resetting error...',
                    spinner: 'lines',
                    duration: EQUIPMENT_ALERT_RESET_WAIT_TIME
                });

                await this.resetAlertLoader.present();
            }

            this.mainSiteUIService.resettableNotifications.forEach(notification => {
                if (notification.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) {
                    this.resetEquipmentAlert(notification);
                } else {
                    this.dismissNotification(notification);
                }

                if (this.mainSiteUIService.resettableNotifications.filter(notif => notif?.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length >= 1) this.showSuccess();

                this.mainSiteUIService.totalNotifications = this.mainSiteUIService.totalNotifications.filter(notif => notif?.id != notification?.id);
                this.mainSiteUIService.dismissNotification(notification, this.user);
            });

        }
        this.mainSiteUIService.setNotificationsUI(this.user, this.mainSiteUIService.filterWhatsOnScreen && !this.noMoreNotifications);
    }

    async showSuccess() {
        if ((this.resetAlertLoader !== undefined) && (this.resetAlertLoader !== null)) {
            this.resetAlertLoader.dismiss('ErrorReset_Success');
        }

        const { data } = await this.resetAlertLoader.onDidDismiss();

        if ((data !== undefined) && (data == 'ErrorReset_Success')) {
            this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_SUCCESS);
        } else if ((data !== undefined) && (data == 'ErrorReset_GWNotFound')) {
            this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_NO_GATEWAY);
        }

        if (data !== undefined) {
            this.user.updateSites();
        }
    }

    async resetEquipmentAlert(notification: SiteNotification, singleReset?) {
        if (!this.siteService.handleIsConnected()) return;

        if (singleReset == true) {
            this.resetAlertLoader = await this.loadingController.create({
                message: 'Resetting error...',
                spinner: 'lines',
                duration: EQUIPMENT_ALERT_RESET_WAIT_TIME
            });

            await this.resetAlertLoader.present();
        }

        this.siteService.resetEquipmentAlert(notification.id).subscribe(result => {

            if (this.user.active && this.user.active.id == notification?.site_id) {
                const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
                siteAlertFilter.site_id = notification.site_id;
                siteAlertFilter.UserLevelEnum = this.user?.activeSiteUserLevel;
                this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
            }

            if ((this.resetAlertLoader !== undefined) && (this.resetAlertLoader !== null)) {
                if ('message' in result && (<string>result['message']).includes('Gateway not found')) {
                    if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_GWNotFound');
                } else {
                    if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_Success');
                }
            }
        }, error => {
            if (singleReset == true) this.resetAlertLoader.dismiss('ErrorReset_Failure');
        });

        if (singleReset == true) {
            const { data } = await this.resetAlertLoader.onDidDismiss();

            if ((data !== undefined) && (data == 'ErrorReset_Success')) {
                this.mainSiteUIService.dismissNotification(notification, this.user);
                this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_SUCCESS);
            } else if ((data !== undefined) && (data == 'ErrorReset_GWNotFound')) {
                this.mainSiteUIService.dismissNotification(notification, this.user);
                this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, ERROR_RESET_NO_GATEWAY);
            }

            if (data !== undefined) {
                this.user.updateSites();
            }
        }
    }

    async presentToastWithOptions(siteNotification: SiteNotification) {
        let toastMessage = ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS;

        if (siteNotification) {
            switch (siteNotification.sitenotificationtype_id) {
                case SiteNotificationTypeEnum.Equipment:
                    toastMessage = ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS;
                    break;

                case SiteNotificationTypeEnum.Gateway:
                    toastMessage = ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS;
                    break;

                case SiteNotificationTypeEnum.Membership:
                    toastMessage = ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS;
                    break;

                case SiteNotificationTypeEnum.Payments:
                    toastMessage = ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS;
                    break;

                case SiteNotificationTypeEnum.Subscription:
                    toastMessage = ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS;
                    break;
                case SiteNotificationTypeEnum.EquipmentNotification:
                    toastMessage = ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS;
                    break;
            }
        }

        this.siteService.presentToastMessage(ToastMessageTypeEnum.Success, TOAST_SUCCESS_TITLE, toastMessage)
    }

    dismiss() {
        this.popoverCtrl.dismiss();
    }

    ionViewWillLeave() {
        this.user.clearUserSubscriptions();
    }

    ngOnDestroy() {
        this.user.clearUserSubscriptions();
    }
}