import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from '../../features/sites/services/site.service';
import { TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE, TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE } from 'src/app/constants/kenzaconstants';

@Injectable({
  providedIn: 'root'
})
export class HasInternetConnectionGuardService implements CanActivate {
  constructor(
    public siteService: SiteService, private toastr: ToastrService) {}

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): boolean 
  {
    if (!this.siteService.isConnectedToInternet) {
      console.debug('Disallow Route');
      this.toastr.error(
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE, 
        TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE, 
        { positionClass: 'toast-top-full-width' }
      );
    }

    return this.siteService.isConnectedToInternet;
  }

}
