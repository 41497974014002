import { Subscription } from 'rxjs';
import moment from 'moment-timezone';
import { Site } from '../../classes/site';
import { SiteService } from '../../services/site.service';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { UserService } from 'src/app/common/services/user/user.service';
import { GatewayGroup } from '../../../manage/components/classes/GatewayGroup';
import { BatchGroupDisplay } from '../site-control/batchControl/batchGroupDisplay';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { Component, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SiteGatewayPlansComponent } from '../../pages/site-gateway-plans/site-gateway-plans.component';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { GatewayUnit, IndoorFanSpeed, LossnayFanSpeed, Temp } from '../../../manage/components/classes/GatewayUnit';
import { GroupSelectorComponent, SelectedGroup } from 'src/app/common/components/group-selector/group-selector.component';
import { GatewayUnitTwoDigitType, TemperaturePreferenceEnum, SiteControlGroupStatusEnum, LevelEnum, GatewaySubscriptionStatusTypeEnum, SubscriptionFeatures, ModesSpeedsDirections, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle } from 'src/app/enumerations/enums';
import { Router } from '@angular/router';

export enum ScheduleMaintenanceJobStatusEnum {
    // how to handle maintenance job selected in group selector
    NoneInMaintenance,
    AllInMaintenance,
    SomeInMaintenance
  }

// message to banner on screen based on maintenance job active on group selector
export const ScheduleMaintenanceJobStatusMessage: { [type:number]: string} = {};
ScheduleMaintenanceJobStatusMessage[ScheduleMaintenanceJobStatusEnum.NoneInMaintenance] = ``;
ScheduleMaintenanceJobStatusMessage[ScheduleMaintenanceJobStatusEnum.AllInMaintenance] = 
  `Indoor Coil/Group(s) in #JobName# mode. Scheduled events will not execute at this time.`;
ScheduleMaintenanceJobStatusMessage[ScheduleMaintenanceJobStatusEnum.SomeInMaintenance] = 
  `Some Indoor Coil/Group(s) in #JobName# mode. Unselect those in #JobName# mode to schedule units.`;

@Component({
    selector: 'app-site-scheduling',
    templateUrl: './site-scheduling.component.html',
    styleUrls: ['./site-scheduling.component.scss'],
})

export class SiteSchedulingComponent {

    @ViewChild(GroupSelectorComponent) groupSelector: GroupSelectorComponent;
    public bgd: BatchGroupDisplay = new BatchGroupDisplay(this.user, this.appAuth, this.mainSiteUIService);

    // Scheduling State
    devEnv = devEnv;
    allSiteEvents = [];
    spinningLoader = null;
    allSiteDatabaseEvents = [];
    batchSelectedEvents = [];
    batchScheduling = true;
    showContextMenu = false;
    contextMenuX = 0;
    contextMenuY = 0;
    showAllEventsForSite = false;
    showAllEventsForUnitType = false;
    GatewaySubscriptionStatusTypeEnum = GatewaySubscriptionStatusTypeEnum;
    onMac: boolean = navigator.platform.includes(`Mac`);
    recurringSched = true;
    is_recurring: boolean = devEnv && localStorage.getItem(`lastDayMode`) ? JSON.parse(localStorage.getItem(`lastDayMode`)) : true;
    eventAdmin: boolean = this.recurringSched;
    recurringAndSingleEvents: boolean = devEnv;
    keepShowingSpinner = true;
    levelEnum = LevelEnum;
    selectedGroup;
    subEnds: any;
    lastGroupID;
    dataCity;
    dataTime;

    // maintenance job display control
    scheduleMaintenanceJobStatusEnum = ScheduleMaintenanceJobStatusEnum;
    maintenanceJobStatus: ScheduleMaintenanceJobStatusEnum;
    maintenanceJobMessage: string;

    // expired gateway selected id, if so
    expiredGatewayIdSelected:string;    

    initialGroupIdToSelect: string;

    // Logic for Angular Calendar & Database Events
    week: any;
    browserName;
    weekEnd: any;
    weekBegin: any;
    updateIndex = 0;
    databaseEvents: any;
    siteGateways: any = [];
    groupsOfEvents: any = [];
    degreesTypeAbbreviation: any = `°F`;
    onNewGroupsSelected: Subscription = null;
    ModesSpeedsDirections = ModesSpeedsDirections;
    calendarTimerFormat = `dddd, MMMM Do, h:mm:ss A`;
    databaseTimeFormat: any = `YYYY-MM-DD HH:mm:ss.SSS`;
    formattedEvents: CalendarEvent<EventControlPoints>[];
    defaultEvents: CalendarEvent<EventControlPoints>[] = [];
    events: CalendarEvent<EventControlPoints>[] = this.defaultEvents;
    uniqueEvents: CalendarEvent<EventControlPoints>[] = this.events;
    @Output() newGroupsSelected = new EventEmitter<SelectedGroup[]>();
    unfilteredEvents: CalendarEvent<EventControlPoints>[] = this.events;
    siteTime: string = moment.tz(this.user?.active?.timezone).format(this.calendarTimerFormat);

    actions: CalendarEventAction[] = [
        {
            label: `<i class="fas fa-fw fa-pencil-alt"></i>`,
            a11yLabel: `Edit`,
            onClick: ({ event }: { event: CalendarEvent<EventControlPoints> }): void => {
                console.log(`Edited`, event);
            },
        },
        {
            label: `<i class="fas fa-fw fa-trash-alt"></i>`,
            a11yLabel: `Delete`,
            onClick: ({ event }: { event: CalendarEvent<EventControlPoints> }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event).sort((event1: any, event2: any) => event1.start - event2.start);
                console.log(`Deleted`, event);
            },
        },
    ];

    // cal event colors // cal-event
    colors: any = { // Primary = Border, Secondary = Background
        heat: { primary: `#ff0000`, secondary: `#fae3e3` },
        cool: { primary: `#1e90ff`, secondary: `#d1e8ff` },
        dry: { primary: `#d08800`, secondary: `#fdf1ba` },
        fan: { primary: `#00b900`, secondary: `#d4ffd4` },
        setback: { primary: `#028c8d`, secondary: `#a7feff` },
        auto: { primary: `#ff00ff`, secondary: `#ffeeff` },
        bypass: { primary: `#9966ff`, secondary: `#e1d6f7` },
        energy: { primary: `#04acac`, secondary: `#cbf8f8` },
        off: { primary: `#000000`, secondary: `#ffffff` },
        disabled: { primary: `#e1e1e1`, secondary: `#f3f3f3` },
    };

    // zero state control
    siteGatewaysLoading = true;
    siteGatewaysExist = false;
    siteGatewayUnitsExist = false;
    siteGatewaysLoadError = false;

    // currently controlling group detail
    selectedGateway: Gateway = null;
    selectedSiteGatewayGroup: GatewayGroup = null;
    selectedGatewayUnit: GatewayUnit = null;

    selectedGroups: SelectedGroup[];
    gatewayUnits: GatewayUnit[] = [];

    displayDataNotAvailable = false;
    debounceTimeout: NodeJS.Timeout;

    temperaturePreference = TemperaturePreferenceEnum;
    siteControlGroupStatusEnum = SiteControlGroupStatusEnum;
    gatewayUnitTwoDigitTypeEnum = GatewayUnitTwoDigitType;
    eventsLoaded = false;

    constructor(
        private router:Router,
        public user: UserService,
        public siteService: SiteService,
        public modalController: ModalController,
        public toastController: ToastController,
        public appAuth: AppAuthenticationService,
        public loadingController: LoadingController,
        private mainSiteUIService: MainSiteUIService,
    ) {
        // Init schedule maintenance msg
        this.maintenanceJobStatus = ScheduleMaintenanceJobStatusEnum.NoneInMaintenance;
        this.maintenanceJobMessage = ``;
    }

    ngOnInit() {
        if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
            this.browserName = `chrome`;
        } else if (navigator.userAgent.match(/firefox|fxios/i)) {
            this.browserName = `firefox`;
        } else if (navigator.userAgent.match(/safari/i)) {
            this.browserName = `safari`;
        } else if (navigator.userAgent.match(/opr\//i)) {
            this.browserName = `opera`;
        } else if (navigator.userAgent.match(/edg/i)) {
            this.browserName = `edge`;
        } else {
            this.browserName = `chrome`;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // Empty
    }

    ionViewWillEnter() {
        // pre select an item in the group_selector?
        if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) this.degreesTypeAbbreviation = `°C`;
        if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) this.degreesTypeAbbreviation = `°F`;
        this.groupSelector.ionViewWillEnter();
        this.init_ui();
        this.dataCity = this?.user?.active?.city;
        this.dataTime = moment.tz(this?.user?.active?.timezone).format(`h:mm:ss A`);
        if (this.user && this?.user?.active?.id == `default-site`) {
            this.rememberLastActiveSite();
        }
    }

    rememberLastActiveSite() {
        this.user.setActiveSite((<Site>JSON.parse(localStorage.getItem(`ActiveSite`))) ?? this?.user?.sites[JSON.parse(localStorage.getItem(`IndexOfActiveSite`))], true);
        localStorage.setItem(`IndexOfActiveSite`, JSON.stringify(this?.user?.sites.map(site => site.id).indexOf(this?.user?.active.id)));
        localStorage.setItem(`ActiveSite`, JSON.stringify(this.user.active));
    }

    async closeSpinner() {
        let top = await this.loadingController.getTop();
        if (top != undefined) top.dismiss();
        this.keepShowingSpinner = false;
        this.spinningLoader = null;
    }

    async ionViewWillLeave() {
        // await this.closeSpinner();
        this.groupSelector.ionViewWillLeave();
    }

    haveAnyUnitsInMaintenance() {
        return this.maintenanceJobStatus != ScheduleMaintenanceJobStatusEnum.NoneInMaintenance
    }

    // Single Selection
    // Fires on New Group / Tile Selection
    scheduleForGroup(group: any) {
        this.expiredGatewayIdSelected = group.gateway.subscription_expired ? group.gateway.id : '';        
        this.setWeekTime();
        if (group) {
            this.selectedGateway = group.gateway;
            this.selectedSiteGatewayGroup = group.gatewayGroup;
            this.gatewayUnits = group.gatewayUnits;
            this.selectedGatewayUnit = this.gatewayUnits[0];
            this.selectedGroups = [group];
            this.bgd.update(this.selectedGroups);
            this.refreshGroupEvents();
        }

        // track how many gateways have the schedule feature (subscription limit)
        if (group.maintenacejob_id) {
            this.maintenanceJobStatus = ScheduleMaintenanceJobStatusEnum.AllInMaintenance;
            this.maintenanceJobMessage = ScheduleMaintenanceJobStatusMessage[this.maintenanceJobStatus];
        }
    }

    // Bacth Selection
    // Fires on New Group / Tile Selection
    scheduleForGroups(groups: SelectedGroup[], newSet = false) {
        this.setWeekTime();
        if (groups) {
            this.bgd.update(groups);
            this.selectedGroups = groups;
            if (newSet == true) {
                let selectedGroupIDs = this.selectedGroups.map(grp => grp.id);
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.map(grp => {
                    if (selectedGroupIDs.includes(grp.id)) {
                        return {
                            ...grp,
                            selected: true,
                        }
                    } else {
                        return {
                            ...grp,
                            selected: false,
                        }
                    }
                });
            }
            let newestGroupSelected: any = groups.find(gr => gr.id == this.groupSelector.LastSelectedByOrder[0]?.id) || groups[0];
            this.selectedGateway = newestGroupSelected?.gateway;
            this.selectedSiteGatewayGroup = newestGroupSelected?.gatewayGroup;
            this.gatewayUnits = newestGroupSelected?.gatewayUnits;
            this.selectedGatewayUnit = this.gatewayUnits[0];
            this.refreshGroupEvents(true);
        }

        let expiredGatewayIdSelected = ``;    
        // track how many gateways have the schedule feature (subscription limit)
        let groupsInMaintenance = 0;
        groups.forEach((group) => {
            if (group.maintenanceJobId != MaintenanceJobTypeEnum.None) groupsInMaintenance += 1;
            if (group.subscriptionExpired) expiredGatewayIdSelected = group.gateway.id;
        })

        this.expiredGatewayIdSelected = expiredGatewayIdSelected;

        if (groupsInMaintenance == 0) {
            this.maintenanceJobStatus = ScheduleMaintenanceJobStatusEnum.NoneInMaintenance;
        } else if (groupsInMaintenance == groups.length) {
            this.maintenanceJobStatus = ScheduleMaintenanceJobStatusEnum.AllInMaintenance;
        } else {
            this.maintenanceJobStatus = ScheduleMaintenanceJobStatusEnum.SomeInMaintenance;
        }

        let maintJobIDs = [...new Set(this.selectedGroups.map(grp => grp.maintenanceJobId))].filter(jobID => jobID > 0);
        // devEnv && console.log(`Maint Jobs`, maintJobIDs);
        if (maintJobIDs && maintJobIDs.length > 0) {
            if (maintJobIDs.length > 1) {
                let maintenanceJobName = `Multiple Indoor Coil/Group(s) are running maintenance jobs.`;
                this.maintenanceJobMessage = `${maintenanceJobName} Scheduled events will not execute at this time.`;
            } else {
                let maintenanceJobName = MaintenanceJobTypeEnumTitle[maintJobIDs[0]];
                this.maintenanceJobMessage = ScheduleMaintenanceJobStatusMessage[this.maintenanceJobStatus].replaceAll(`#JobName#`, maintenanceJobName);
            }
        }
    }

    isExpiredGatewaySelected() {
        return this.expiredGatewayIdSelected != '';
    }

    customContextMenu(event: MouseEvent) {
        event.preventDefault();
        this.showContextMenu = true;
        this.contextMenuX = event.clientX - 271;
        this.contextMenuY = event.clientY - 180 + 125;
    }

    createNewGroup() {
        this.showContextMenu = false;
    }

    addGroup() {
        this.showContextMenu = false;
    }

    scheduleGroup() {
        this.showContextMenu = false;
    }

    exitMenu() {
        this.showContextMenu = false;
    }
 
    upgradeExpiredSubscription() {
        // we have an expired gateway group selected in the sushi bar - and the owner
        // has selected the option to upgrade it.  Navigate to the gateway subscription page
        this.router.navigate(['/account/' + this.user.id + '/details/subscriptions'], { queryParams: { upgrade: this.expiredGatewayIdSelected}});    
    }

    isSiteOwner() {
        // is this user the owner of the site?
        return this.user.activeSiteUserLevel == LevelEnum.Owner;
    }

    setWeekTime() {
        if (this.user.active.id != `default-site`) {
            if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) this.degreesTypeAbbreviation = `°C`;
            if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) this.degreesTypeAbbreviation = `°F`;

            let tz = (<any>moment().tz(this.user.active.timezone));
            const today = new Date(tz);
            const first = today.getDate() - today.getDay() + 1;
            const last = first + 6;
            const nextSunday = new Date(today.setDate(last)).setHours(0, 0, 0, 0);
            const beginningOfWeek = (<any>moment(nextSunday)).subtract(7, `days`)._d;

            this.weekBegin = beginningOfWeek;
            this.weekEnd = (<any>moment(nextSunday).subtract(1, `minute`))._d;

            this.week = {
                Sunday: moment(this.weekBegin).format(`YYYY-MM-DD`),
                Monday: moment(this.weekBegin).add(`1`, `days`).format(`YYYY-MM-DD`),
                Tuesday: moment(this.weekBegin).add(`2`, `days`).format(`YYYY-MM-DD`),
                Wednesday: moment(this.weekBegin).add(`3`, `days`).format(`YYYY-MM-DD`),
                Thursday: moment(this.weekBegin).add(`4`, `days`).format(`YYYY-MM-DD`),
                Friday: moment(this.weekBegin).add(`5`, `days`).format(`YYYY-MM-DD`),
                Saturday: moment(this.weekEnd).format(`YYYY-MM-DD`),
            };
        }
    }

    getEventsForSelection(multiGatewayIDs, multiGroupIDs, spinningLoader, type?: any) {
        this.siteService.getSiteEvents(this.user.active.id).subscribe((dbEvents: any) => {
            // devEnv && console.log(`All Database Events for Site`, dbEvents);
            let eventsFromDB = dbEvents.events;
            this.allSiteDatabaseEvents = dbEvents.events;
            if (this.showAllEventsForSite == false) {
                eventsFromDB = dbEvents.events.filter(evt => multiGroupIDs.includes(evt.group_id) && multiGatewayIDs.includes(evt.gateway_id));
            } 
            if (this.showAllEventsForUnitType == true) {
                let group = this.mainSiteUIService.allSiteGroups.find(grp => multiGroupIDs.includes(grp.id));
                eventsFromDB = dbEvents.events.filter(evt => this.mainSiteUIService.allSiteGroups.find(grp => grp.id == evt.group_id).type == group.type);
            }

            let weeklyRecurringEvents = eventsFromDB.filter(evt => evt.is_recurring);
            let nonRecurringEvents = eventsFromDB.filter(evt => !evt.is_recurring);
            weeklyRecurringEvents && weeklyRecurringEvents.length > 0 && devEnv && console.log(`Database Events for Selection`, weeklyRecurringEvents);
            this.is_recurring ? this.backEndToEvents(weeklyRecurringEvents, dbEvents, spinningLoader, type) : this.backEndToEvents(nonRecurringEvents, dbEvents, spinningLoader, type);
        });
    }

    async refreshGroupEvents(showLoadingSpinner = false, type?: any) {

        // let spinner = await this.loadingController.getTop();
        // if (spinner != undefined) spinner.dismiss();
        if (showLoadingSpinner == true && this.keepShowingSpinner) {
            this.spinningLoader = await this.loadingController.create({
                spinner: `lines`,
                message: `${this.eventsLoaded == true ? `Refreshing` : `Loading`} Event(s)...`,
            })
        }
    
        if (this.spinningLoader != null) await this.spinningLoader.present();

        if (this.batchScheduling == true && this.selectedGroups.length > 1) {
            let multiGroupIDs = this.selectedGroups.map(grp => grp?.gatewayUnits[0].group_id);
            let multiGatewayIDs = this.selectedGroups.map(grp => grp?.gatewayUnits[0].gateway_id);
            this.getEventsForSelection(multiGatewayIDs, multiGroupIDs, this.spinningLoader, type);
        } else {
            this.getEventsForSelection([this.selectedGatewayUnit?.gateway_id], [this.selectedGatewayUnit?.group_id], this.spinningLoader, type);
        }
    }

    dayModeChanged(e) {
        if (this.user.activeSiteUserLevel == this.levelEnum.Owner) localStorage.setItem(`lastDayMode`, JSON.stringify(this.is_recurring));
        this.refreshGroupEvents();
    }

    eventAdded(eventAddedData: any) {
        // new event added. for now just refresh
        // save the current group as selected
        let showSpinner = (eventAddedData.spinner != undefined && eventAddedData.spinner != null) ? eventAddedData.spinner == true : true;
        this.refreshGroupEvents(showSpinner, `Add`);
    }

    eventDeleted(eventDeletedData: any) {
        // existing event removed.  for now just refresh
        // save the current group as selected
        let showSpinner = (eventDeletedData.spinner != undefined && eventDeletedData.spinner != null) ? eventDeletedData.spinner == true : true;
        this.refreshGroupEvents(showSpinner, `Delete`);
    }

    eventChanged(eventChangedData: any) {
        // event updated - for now just refresh
        // save the current group as selected
        let showSpinner = (eventChangedData.spinner != undefined && eventChangedData.spinner != null) ? eventChangedData.spinner == true : true;
        this.refreshGroupEvents(showSpinner, `Change`);
    }

    zeroStateMessage(state: string) {
        // startup messages from group_selector
        this.siteGatewaysLoadError = false;
        if (state === 'Loading') {
            this.siteGatewaysLoading = true;
            this.selectedGateway = null;
            this.selectedGatewayUnit = null;
            this.selectedSiteGatewayGroup = null;
        }
        if (state === 'LoadError') this.siteGatewaysLoadError = true;
        if (state === 'NoGateways') this.siteGatewaysExist = false;
        if (state === 'Gateways') this.siteGatewaysExist = true;
        if (state === 'NoUnits') this.siteGatewayUnitsExist = false;
        if (state === 'Units') this.siteGatewayUnitsExist = true;
        if (state == 'Ready') {
            this.siteGatewaysLoading = false;
        }
    }

    async init_ui() {
        // set the UI options initially
        this.siteGatewaysExist = false;
        this.siteGatewayUnitsExist = false;
        this.gatewayUnits = [];
        this.selectedGatewayUnit = null;
    }

    perform_unit_temperature_conversion(convertToUnit: TemperaturePreferenceEnum, units: GatewayUnit[]): GatewayUnit[] {
        // convert temps to the logged in account requested type (F/C)
        let converted_units: GatewayUnit[] = [];

        switch (convertToUnit) {
            case TemperaturePreferenceEnum.Celsius:
                return units;

            case TemperaturePreferenceEnum.Fahrenheit:
                converted_units = units.map(unit => {

                    unit.AutoMin = unit.AutoMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMin) : Temp.zero;
                    unit.AutoMax = unit.AutoMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMax) : Temp.hundred;
                    unit.CoolMin = unit.CoolMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMin) : Temp.zero;
                    unit.CoolMax = unit.CoolMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMax) : Temp.hundred;
                    unit.HeatMin = unit.HeatMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMin) : Temp.zero;
                    unit.HeatMax = unit.HeatMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMax) : Temp.hundred;
                    unit.inlet_temp = unit.inlet_temp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.inlet_temp) : Temp.unk;

                    unit.SetTemp = unit.SetTemp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp) : Temp.unk;
                    unit.SetTemp1 = unit.SetTemp1 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp1) : Temp.unk;
                    unit.SetTemp2 = unit.SetTemp2 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp2) : Temp.unk;
                    unit.SetTemp3 = unit.SetTemp3 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp3) : Temp.unk;
                    unit.SetTemp4 = unit.SetTemp4 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp4) : Temp.unk;
                    unit.SetTemp5 = unit.SetTemp5 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp5) : Temp.unk;

                    // deadband is a simple double to go to F
                    unit.deadband = unit.deadband ? unit.deadband * 2.0 : 0;
                    return unit;
                });
                break;
        }

        return converted_units;
    }

    convertTemperatureIfNeeded(value: string) {
        if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
            value = TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(value);
        }
        return value;
    }

    mapFanSpeedNumber: any = (unitType, eventFanSpeed, mappedFanSpeed?) => {
        switch (unitType) {
            case GatewayUnitTwoDigitType.IndoorUnit:
                switch (eventFanSpeed) {
                    case IndoorFanSpeed.disabled:
                        return mappedFanSpeed = `disabled`;
                    case IndoorFanSpeed.very_low:
                        return mappedFanSpeed = `very low`;
                    case IndoorFanSpeed.low:
                        return mappedFanSpeed = `low`;
                    case IndoorFanSpeed.medium:
                        return mappedFanSpeed = `medium`;
                    case IndoorFanSpeed.high:
                        return mappedFanSpeed = `high`;
                    case IndoorFanSpeed.auto:
                        return mappedFanSpeed = `auto`;
                }
                return mappedFanSpeed;
            case GatewayUnitTwoDigitType.Lossnay:
                switch (eventFanSpeed) {
                    case LossnayFanSpeed.disabled:
                        return mappedFanSpeed = `disabled`;
                    case LossnayFanSpeed.very_low:
                        return mappedFanSpeed = `very low`;
                    case LossnayFanSpeed.low:
                        return mappedFanSpeed = `low`;
                    case LossnayFanSpeed.medium:
                        return mappedFanSpeed = `medium`;
                    case LossnayFanSpeed.high:
                        return mappedFanSpeed = `high`;
                    case LossnayFanSpeed.auto:
                        return mappedFanSpeed = `auto`;
                }
                return mappedFanSpeed;
        }
        return mappedFanSpeed;
    };

    getEventColor(mode: string) {
        let color;
        switch (mode) {
            default:
            case `fan`:
                color = this.colors.fan;
                break;
            case `heat`:
                color = this.colors.heat;
                break;
            case `dry`:
                color = this.colors.dry;
                break;
            case `cool`:
                color = this.colors.cool;
                break;
            case `auto`:
                color = this.colors.auto;
                break;
            case `setback`:
                color = this.colors.setback;
                break;
            case `bypass`:
                color = this.colors.bypass;
                break;
            case `energy recovery`:
                color = this.colors.energy;
                break;
        }
        return color
    }

    async presentSubscriptionOptions() {
        // display subscription options dialog
        if (!this.siteService.handleIsConnected()) return;

        const modal = await this.modalController.create({
            component: SiteGatewayPlansComponent,
            backdropDismiss: true,
            componentProps: {
                title: "Upgrade your Subscription Plan",
                initialModelClassNameToDisplay: this.selectedGateway.model.class_name
            },
        });
        return await modal.present();
    }

    to24HourTime(time: any) {
        let hours = Number(time.match(/^(\d+)/)[1]);
        let minutes = Number(time.match(/:(\d+)/)[1]);
        let AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM == `PM` && hours < 12) hours = hours + 12;
        if (AMPM == `AM` && hours == 12) hours = hours - 12;
        let sHours = hours.toString();
        let sMinutes = minutes.toString();
        if (hours < 10) sHours = `0` + sHours;
        if (minutes < 10) sMinutes = `0` + sMinutes;
        return sHours + `:` + sMinutes;
    }

    hasAnyDisabledEvents(event) {
        if (event.meta.groupedEvents && event.meta.groupedEvents.length > 0) {
            return event.meta.groupedEvents.some(ev => ev.meta.active == false) || event.meta.groupedEvents.filter(ev => ev.meta.active == false).length > 0;
        } else if (event.meta.batchEvents && event.meta.batchEvents.length > 0) {
            return event.meta.batchEvents.some(ev => ev.meta.active == false) || event.meta.batchEvents.filter(ev => ev.meta.active == false).length > 0;
        } else {
            return event.meta.active == false;
        }
    }
    
    hasAnyMultiDayEvents(event) {
        return event.meta.multiDayEvent == true;
    }

    withinXMinutes = (x, startTime, endTime) => (startTime - endTime) >= 0 && (startTime - endTime) <= (x * (60 * 1000));

    // Map Events From Back End to Front End
    backEndToEvents(eventsFromDatabase, siteEvents, spinningLoader, type) {
        if (this.batchScheduling == true) {
            this.batchSelectedEvents = eventsFromDatabase;
            if (this.showAllEventsForSite == false) {
                this.batchSelectedEvents = this.batchSelectedEvents.filter(ev => this.selectedGroups.map(grp => grp?.group_id).includes(ev?.group_id));
            }
            this.createFrontEndEventsFromBackEndEvents(this.batchSelectedEvents, siteEvents, spinningLoader, type);
            return this.batchSelectedEvents;
        } else {
            this.createFrontEndEventsFromBackEndEvents(eventsFromDatabase, siteEvents, spinningLoader, type);
        }
    }

    consolidateBatchEvents(events: CalendarEvent<EventControlPoints>[]) {
        let consolidated = {};
        events.forEach(event => {
            let batchID = event.meta.batchID;
            if (!consolidated[`${batchID}-${event.meta.startD}`]) {
                consolidated[`${batchID}-${event.meta.startD}`] = {
                    ...event,
                    title: event.title,
                    meta: {
                        batchID,
                        ...event.meta,
                        groupedEvents: []
                    }
                };
            }
            consolidated[`${batchID}-${event.meta.startD}`].meta.groupedEvents.push(event);
        });
        let consolidatedEvents: CalendarEvent<EventControlPoints>[] = Object.values(consolidated);
        return consolidatedEvents;
    }

    mapEventsFromBackEndToFrontEnd = (arrayOfDatabaseEvents) => {
        const mappedEvents: CalendarEvent<EventControlPoints>[] = arrayOfDatabaseEvents.map((databaseEvent: any, index: any) => {

            this.determineGatewaySubscriptionFeatures(databaseEvent);

            let tempVal;
            let upperVal: number, lowerVal: number = null;
            let dbTimeFix = this.browserName == `safari` ? (<any>moment(databaseEvent?.time))._d as Date : new Date(databaseEvent?.time) as Date;
            let getEventValue = (param) => databaseEvent?.commands?.commands?.filter(cmd => cmd?.param == param)[0]?.value ?? `disabled`;
            let groupOfEvent = this.mainSiteUIService.allSiteGroups.filter(grp => grp.group_id == databaseEvent?.group_id)[0];
            let gatewayUnitOfEvent: any = groupOfEvent.gatewayUnits[0];

            let eventTempValues = databaseEvent.commands.commands.filter(com => !isNaN(com.value)).map(com => parseFloat(com.value));
            let uniqueEventTempValues = [...new Set(eventTempValues)];
            let hasOnlyOneTempValue: boolean = uniqueEventTempValues?.length == 1;

            let modeValue = getEventValue(`Mode`);
            let inSupportedDualMode = modeValue == ModesSpeedsDirections.auto || modeValue == ModesSpeedsDirections.setback || modeValue == ModesSpeedsDirections.dual_auto;

            // console.log(`Pre Convert Event from Back End to Front End`, {modeValue, inSupportedDualMode, databaseEvent, groupOfEvent, gatewayUnitOfEvent, eventTempValues, singleTempSet: hasOnlyOneTempValue});

            // Check if we are in Batch Scheduling or Single Scheduling
            // if (this.selectedGroups.length > 1) {
                // console.log(`Batch Selection Logic will go here`);
            // } else {
                // Check the Type of Group
                if (groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit) {
                    try {
                        upperVal = this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? ((parseFloat(getEventValue(`MultiSetTemp1`)) || parseFloat(getEventValue(`MultiSetTemp4`))) || parseFloat(getEventValue(`SetTemp`))) : ((parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`MultiSetTemp1`)))
                        || parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`MultiSetTemp4`)))) || parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`SetTemp`))));
                    } catch (except) {
                        upperVal = null;
                    }

                    try {
                        lowerVal = this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? ((parseFloat(getEventValue(`MultiSetTemp2`)) || parseFloat(getEventValue(`MultiSetTemp5`))) || parseFloat(getEventValue(`SetTemp`))) : ((parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`MultiSetTemp2`)))
                        || parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`MultiSetTemp5`)))) || parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`SetTemp`))));
                    } catch (except) {
                        lowerVal = null;
                    }
                    
                    // Check if Dual Set or Single Set is Enabled & if the unit is in a mode that supports dual set
                    if (gatewayUnitOfEvent.GroupType == `enabled` && inSupportedDualMode && !hasOnlyOneTempValue) {
                        tempVal = {
                            upper: upperVal,
                            lower: lowerVal,
                        }
                    } else {
                        // console.log(`Single Set Temp`, lowerVal, upperVal);
                        tempVal = upperVal;
                    }
                }
            // }

            // // Single Set Temp
            // if (databaseEvent.commands.commands.length < 6) {
            //     if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) {
            //         tempVal = parseFloat(getEventValue(`SetTemp`));
            //     } else {
            //         tempVal = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`SetTemp`)));
            //     }
            // } else {
            //     if (!databaseEvent.commands.commands.find(com => com.param == `MultiSetTemp2`)) {
            //         if (this?.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius) {
            //             if (getEventValue(`Mode`) == `heat`) {
            //                 tempVal = parseFloat(databaseEvent?.commands.commands.filter(cmd => cmd.param == `MultiSetTemp2`)[0].value);
            //             } else if (getEventValue(`Mode`) == `fan`) {
            //                 tempVal = 20;
            //             } else {
            //                 tempVal = parseFloat(getEventValue(`MultiSetTemp1`));
            //             }
            //         } else {
            //             if (getEventValue(`Mode`) == `heat`) {
            //                 tempVal = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(databaseEvent?.commands.commands.filter(cmd => cmd.param == `MultiSetTemp2`)[0].value));
            //             } else if (getEventValue(`Mode`) == `fan`) {
            //                 tempVal = 70;
            //             } else {
            //                 tempVal = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(getEventValue(`MultiSetTemp1`)));
            //             }
            //         }
            //     }
            // }

            // let maintenanceJob = groupOfEvent?.gateway?.maintenance_job;
            // let hasMaintenance = maintenanceJob != null && maintenanceJob != undefined && maintenanceJob?.job_id > 0;

            let startX = moment(databaseEvent?.time).format(`A`);
            let startH = moment(databaseEvent?.time).format(`h`);
            let startMM = moment(databaseEvent?.time).format(`mm`);
            let eventIsInOverflowingPosition = startX == `AM` && startH == `12` && (parseFloat(startMM) >= 0 && parseFloat(startMM) <= 9);

            return {
                start: dbTimeFix,
                title: databaseEvent?.name,
                allDay: databaseEvent?.allDay || false,
                resizable: { beforeStart: false, afterEnd: false },
                draggable: this.user.activeSiteUserLevel > this.levelEnum.Viewer,
                color: groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit ? this.getEventColor(getEventValue(`Mode`)) : this.getEventColor(getEventValue(`VentMode`).replace(/[_]/g, ` `).replace(`heat`, `energy`)),
                cssClass: `eventBlock ${databaseEvent?.is_active == true ? 'active' : 'disabled'} ${getEventValue(`Drive`)} ${groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit ? getEventValue(`Mode`) : getEventValue(`VentMode`).replace(/[_]/g, ` `).replace(`heat`, `energy`)} ${eventIsInOverflowingPosition == true ? `overflowingTimeEvent` : ``}`,

                meta: {
                    startH,
                    startX,
                    startMM,
                    databaseEvent,
                    id: databaseEvent?.id,
                    onGroup: groupOfEvent?.name,
                    power: getEventValue(`Drive`),
                    group_id: databaseEvent?.group_id,
                    gatewayUnit_id: gatewayUnitOfEvent.id,
                    gateway_id: databaseEvent?.gateway_id,
                    is_recurring: databaseEvent?.is_recurring,
                    startDate: moment(dbTimeFix).format(`M/D`),
                    startD: moment(databaseEvent?.time).format(`dddd`),
                    startT: moment(databaseEvent?.time).format(`h:mm A`),
                    updatedBy: databaseEvent?.updatedBy || this.user.name,
                    startTime: moment(databaseEvent?.time).format(`h:mm`),
                    days: databaseEvent?.days || [moment().format(`dddd`)],
                    eventAuthor: databaseEvent?.eventAuthor || this.user.name,
                    eventAuthorRole: databaseEvent?.eventAuthorRole || this.user.activeSiteUserLevel,
                    updatedDate: databaseEvent?.updatedDate || moment().format(this.databaseTimeFormat),
                    createdDate: databaseEvent?.createdDate || moment().tz(this.user.active.timezone).format(this.databaseTimeFormat),
                    mode: groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit ? getEventValue(`Mode`) : getEventValue(`VentMode`).replace(/[_]/g, ` `).replace(`heat`, `energy`),

                    active: moment(dbTimeFix).tz(this.user.active.timezone).isBefore(moment(new Date(this.subEnds) as Date).tz(this.user.active.timezone)) ? databaseEvent?.is_active : false,

                    unitType: groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit ? databaseEvent?.unitType || GatewayUnitTwoDigitType.IndoorUnit || this.selectedGatewayUnit?.type : databaseEvent?.unitType || GatewayUnitTwoDigitType.Lossnay || this.selectedGatewayUnit?.type,
                    fanSpeedEnabled: groupOfEvent.gatewayUnits[0].type == GatewayUnitTwoDigitType.Lossnay ? groupOfEvent.gatewayUnits[0].lc_fan_speed_sw.toLowerCase() != ModesSpeedsDirections.disabled : (groupOfEvent.gatewayUnits[0].fan_mode_sw != ModesSpeedsDirections.disabled && groupOfEvent.gatewayUnits[0].fan_speed_sw != `none`),

                    ...(databaseEvent?.batch?.id && databaseEvent?.batch?.id != `` && {
                        batchID: databaseEvent?.batch?.id,
                        batchName: databaseEvent?.batch?.name,
                        batchScheduleID: databaseEvent?.batch?.schedule_id,
                        batchScheduleName: databaseEvent?.batch?.schedule_name,
                        // batchGroupIDs: [...new Set(databaseEvent?.events?.map(evt => evt.group_id))],
                    }),

                    // If Event is Recurring
                    ...(this.is_recurring && databaseEvent?.is_recurring && {
                        daysArray: Object.entries(databaseEvent?.days).filter(day => day[1]).map(dy => globalFunctions.capitalizeAllWords(dy[0])),
                        seriesOrIcon: Object.entries(databaseEvent?.days).filter(day => day[1]).map(dy => globalFunctions.capitalizeAllWords(dy[0])).length > 1 ? `∞` : `√`,
                        multiDayEvent: Object.entries(databaseEvent?.days).filter(day => day[1]).map(dy => globalFunctions.capitalizeAllWords(dy[0])).length > 1 ? true : false,
                        seriesID: `${databaseEvent?.id}` + `${Object.entries(databaseEvent?.days).filter(day => day[1]).map(day => `-` + globalFunctions.capitalizeAllWords(day[0])).join().replace(/[,]/g, ``)}`, 
                    }),

                    // If Event is for IC Indoor Coil
                    ...(groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit && {
                        temperature: tempVal,
                        airDirection: getEventValue(`AirDirection`),
                        singleAndDualSetEvent: this.mainSiteUIService.multipleRangeSliders,
                        dualSetEvent: databaseEvent.commands.commands.length > 5 && (getEventValue(`Mode`) == ModesSpeedsDirections.auto || getEventValue(`Mode`) == ModesSpeedsDirections.setback || getEventValue(`Mode`) == ModesSpeedsDirections.dual_auto) ? true : false,
                        tempString: (databaseEvent?.tempString || typeof tempVal == `number`) ? tempVal + ` ` + this.degreesTypeAbbreviation : `${tempVal.lower + ` ` + this.degreesTypeAbbreviation} - ${tempVal.upper + ` ` + this.degreesTypeAbbreviation}`,
                    }),

                    // If Event is Fan Speed Enabled, Include Fan Speed Value
                    ...((groupOfEvent.gatewayUnits[0].type == GatewayUnitTwoDigitType.Lossnay ? groupOfEvent.gatewayUnits[0].lc_fan_speed_sw.toLowerCase() != ModesSpeedsDirections.disabled : (groupOfEvent.gatewayUnits[0].fan_mode_sw != ModesSpeedsDirections.disabled && groupOfEvent.gatewayUnits[0].fan_speed_sw != `none`)) && {
                        fanSpeed: groupOfEvent.type == GatewayUnitTwoDigitType.IndoorUnit ? this.mapFanSpeedNumber(GatewayUnitTwoDigitType.IndoorUnit, getEventValue(`FanSpeed`)) ?? ModesSpeedsDirections.disabled : this.mapFanSpeedNumber(GatewayUnitTwoDigitType.Lossnay, getEventValue(`FanSpeed`)) ?? ModesSpeedsDirections.disabled,
                    }),
                },
            } as CalendarEvent<EventControlPoints>;
        }).sort((event1: any, event2: any) => event1.start - event2.start);

        const singleEvents = mappedEvents.filter(evt => !evt.meta.is_recurring);
        const recurringEvents = mappedEvents.filter(evt => evt.meta.is_recurring);
        const recurringDBEvents = arrayOfDatabaseEvents.filter(evt => evt.is_recurring);
        const multiDayEvents = [];

        // For Each Recurring Event With Multiple Days
        recurringDBEvents.forEach(evt => Object.entries(evt.days).map(ifDay => ifDay[1] ? ifDay[0] : null).filter(day => typeof day == `string`).forEach((day, index) => {

            // For Each Day Instance of the Recurring Event
            recurringEvents.map((recurringEventInstance: CalendarEvent<EventControlPoints>) => {

                // We need to determine the Subscription Expiration Date for Events Planned In the Future
                this.determineGatewaySubscriptionFeatures(recurringEventInstance);

                let defaultTimeString;
                if (this.browserName == `firefox`) {
                    defaultTimeString = new Date(this.week[globalFunctions.capitalizeAllWords(day)] + ` ` + this.to24HourTime(recurringEventInstance?.meta?.startT)) as Date;
                } else if (this.browserName == `safari`) {
                    defaultTimeString = (<any>moment(this.week[globalFunctions.capitalizeAllWords(day)] + ` ` + this.to24HourTime(recurringEventInstance?.meta?.startT)))._d as Date;
                } else {
                    defaultTimeString = new Date(this.week[globalFunctions.capitalizeAllWords(day)] + ` ` + recurringEventInstance.meta.startT) as Date;
                }
                
                return {
                    ...recurringEventInstance,
                    start: defaultTimeString,
                    color: moment(defaultTimeString).tz(this.user.active.timezone).isBefore(moment(new Date(this.subEnds) as Date).tz(this.user.active.timezone)) && recurringEventInstance.color,
                    cssClass: moment(defaultTimeString).tz(this.user.active.timezone).isBefore(moment(new Date(this.subEnds) as Date).tz(this.user.active.timezone)) && recurringEventInstance.cssClass,
                    meta: {
                        ...recurringEventInstance.meta,
                        startD: globalFunctions.capitalizeAllWords(day),
                        active: moment(defaultTimeString).tz(this.user.active.timezone).isBefore(moment(new Date(this.subEnds) as Date).tz(this.user.active.timezone)) && (<any>this.selectedGateway?.subscription_features)[SubscriptionFeatures.SYSTEM_SCHEDULING] == SubscriptionFeatures.INCLUDED ? recurringEventInstance.meta.active : false,
                    }
                }
            }).forEach((multiEvt: CalendarEvent<EventControlPoints>) => multiEvt.meta.id == evt.id ? multiDayEvents.push({ ...multiEvt, meta: { ...multiEvt.meta, ...(this.is_recurring && multiEvt?.meta?.is_recurring && { seriesID: `${multiEvt?.meta?.id}` + `-${index + 1}` }), } }) : null);
        }));

        let allMultiDayEvents = singleEvents.concat(globalFunctions.removeDuplicateObjectFromArray(multiDayEvents));
        let sortedEvents = allMultiDayEvents.sort((a: any, b: any) => (new Date(a.start) as any) - (new Date(b.start) as any));
        return sortedEvents;
    }

    getEventsGateway = (evt, gws) => (gws || this.siteGateways).find(gw => gw.id == (evt.gateway_id || evt.meta.gateway_id));

    determineGatewaySubscriptionFeatures = (evt) => {
        this.selectedGateway = this.getEventsGateway(evt, this.siteGateways);
        let subFeatures = (<any>this.selectedGateway?.subscription_features);
        this.subEnds = subFeatures.EXPIRES_AT ? subFeatures.EXPIRES_AT : new Date(`2099-12-31`);
        this.subEnds = moment(this.subEnds).add(`1`, `day`).format(`YYYY-MM-DD`);
    }

    async createFrontEndEventsFromBackEndEvents(eventsFromDatabase, siteEvents, spinningLoader, type) {

        this.databaseEvents = eventsFromDatabase;
        let siteID = this.user.active.id || this.selectedGroups[0].gateway.site_id;
        this.siteGateways = await this.siteService.getSiteGatewaysWithUnits(siteID, true).toPromise();

        let sortedEvents = this.mapEventsFromBackEndToFrontEnd(eventsFromDatabase);
        let sortedSiteEvents = this.mapEventsFromBackEndToFrontEnd(siteEvents.events);
        this.allSiteEvents = sortedSiteEvents;

        let batchEvents = sortedEvents.map((evt, evtIndex) => {
            let eventsToBatch = sortedSiteEvents.filter(evet => `${evet.meta.batchID}-${evet.meta.startD}` == `${evt.meta.batchID}-${evt.meta.startD}`);
            let eventTemps = eventsToBatch.map(evt => evt.meta.temperature);
            let temperatureRange: any = eventTemps.find(temp => typeof temp != `number`);
            let setTempParam = evt.meta.databaseEvent.commands.commands.find(com => com.param == `SetTemp`);
            let temperatureNumber = setTempParam ? parseFloat(setTempParam.value) : eventTemps.find(temp => typeof temp == `number`);
            let singleAndDualSetEvent = eventTemps.some(temp => typeof temp == `number`) && eventTemps.some(temp => typeof temp != `number`);
            if (this.user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit) {
                temperatureNumber = parseFloat(TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(temperatureNumber));
            }

            return {
                ...evt,
                meta: {
                    ...evt.meta,
                    temperatureRange,
                    singleAndDualSetEvent,
                    batchEvents: eventsToBatch,
                    temperatureNumber: evt.meta.temperatureNumber || temperatureNumber,
                    temperatureRangeString: temperatureRange && temperatureRange.lower && temperatureRange.upper ? `${temperatureRange.lower} ${this.degreesTypeAbbreviation} - ${temperatureRange.upper} ${this.degreesTypeAbbreviation}` : evt.meta.tempString,
                }
            } as CalendarEvent<EventControlPoints>
        })

        let sameTimeEvents = batchEvents.map((evt, evtIndex) => {
            if (evt.meta.batchEvents && evt.meta.batchEvents.length > 1) {
                return {
                    ...evt,
                    meta: {
                        ...evt.meta,
                        sameTimeEvents: [],
                        active: this.hasAnyDisabledEvents(evt) == false,
                    }
                }
            } else {
                return {
                    ...evt,
                    meta: {
                        ...evt.meta,
                        sameTimeEvents: batchEvents.filter(evet => this.withinXMinutes(29, evet.start, evt.start))
                    }
                }
            }
        });

        let eventsAndConsolidatedBatchEvents = this.consolidateBatchEvents(sameTimeEvents);

        this.events = eventsAndConsolidatedBatchEvents;
        this.unfilteredEvents = this.events;
        this.groupsOfEvents = [...new Set(this.unfilteredEvents.map(evt => evt.meta.group_id))];

        this.eventsLoaded = true;
        
        // localStorage.setItem(`Events`, JSON.stringify(this.events));
        devEnv && this.events.length > 0 && console.log(`Events${type != undefined ? ` Refreshed After Event ${type}` : ``}`, this.events);
        if (spinningLoader != null) spinningLoader.dismiss();
    }

    ngOnDestroy() {
        this.eventsLoaded = false;
        if (this.user.activeSiteUserLevel == this.levelEnum.Owner) {
            localStorage.setItem(`lastDayMode`, JSON.stringify(this.is_recurring));
        }
    }

    activityHistory() {
        // link to MaintenanceJob Activity History page - with option to return to Site Control.
        this.router.navigate(['/account/' + this.user.id + '/details/analytics-reports'], { queryParams: { fromSiteId: this.user.active.id} } );
      }
    
      hasPermissionForSiteAnalytics() {
        return this.appAuth.doesLevelHavePermission(
          this.user.activeSiteUserLevel,
          this.appAuth.permissionEnums.ViewSiteAnalytics
        );
      }      

}