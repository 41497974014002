import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebserviceURL, LogToServer } from 'src/app/constants/webservice';


@Injectable({
  providedIn: 'root'
})
export class ServerLoggerServiceService {

  logToServer: boolean;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.logToServer = LogToServer;
  }

  PrintToServerLog(messageToPrint: string) {
    if (this.logToServer == true) {
      return this.httpClient.post(WebserviceURL + 'servertask/printtoserverlog/', { messageToPrint: messageToPrint });
    }
  }
}
