import {Component, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import {IonInput, LoadingController, ModalController} from '@ionic/angular';
import { CustomValidators } from 'src/app/constants/validators';
import { Animations } from 'src/app/constants/animations';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-account-change-password',
  templateUrl: './account-change-password.page.html',
  styleUrls: ['./account-change-password.page.scss'],
  animations: [Animations.enableDisable]
})

export class AccountChangePasswordPage {
  changeForm: UntypedFormGroup;
  submitAttempt = false;
  error = '';
  success = false;
  showCurrent: boolean;
  showPassword: boolean;
  devEnv = devEnv;

  constructor(
    private user: UserService,
    private loading: LoadingController,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private siteService: SiteService,
  ) {

    this.changeForm = formBuilder.group({
      old: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        CustomValidators.newIsCurrent,
        CustomValidators.matchThreeOfFourCategories,
        CustomValidators.matchEmailSubstring
      ])],
      confirm: ['', Validators.compose([
        Validators.required,
        CustomValidators.matchPassword
      ])],
      email: ['', null]
    }, {
      validators: CustomValidators.groupMatchPassword
    });

    this.showCurrent = false;
    this.showPassword = false;
    // email form control is not displayed and only there to confirm the user 
    // isn't changing their password to something that is a substring of the email/userid
    this.changeForm.controls.email.setValue(this.user.username);
  }

  async change() {
    this.error = '';
    if (!this.siteService.handleIsConnected())
      return;

    this.submitAttempt = true;

    if (this.changeForm.valid) {
      const load = await this.loading.create({
        spinner: 'lines',
        message: 'Changing password...',
      });

      load.present();

      this.user
        .changePassword(this.changeForm.value.old, this.changeForm.value.password)
        .then(data => {
          return this.user
            .sendChangePasswordEmail(this.user.username)
            .subscribe(sendResult => {
              this.success = true;
              if (this.success) {
                // devEnv && console.log(`data`, data);
                // devEnv && console.log(`sendResult`, sendResult);
                // devEnv && console.log(`Account Password Changed Successfully`);
                this.modalController.dismiss({
                  cancelled: false
                });
              }
            });
        })
        .catch(err => {
          switch (err.code) {
            case 'NotAuthorizedException':
              this.error = 'Incorrect password entered';
              break;
            case 'InvalidParameterException':
              this.error = 'Incorrect password entered';
              break;
            case 'LimitExceededException':
              this.error = 'Attempt limit exceeded, please try after some time';
              break;
            default:
              this.error = 'Unknown login error';
              break;
          }
        })
        .finally(() => {
          load.dismiss();
        });
    }
  }

  // ngOnInit() {
  // }

  toggleCurrent() {
    this.showCurrent = !this.showCurrent;
  }
  togglePassword() {
    this.showPassword = !this.showPassword;
  }

}
