<ion-list lines="full" class="ion-no-padding">
  <ion-item id="accountDetailsUserAction" class="accountDetailsUserAction automationTesting" *ngIf="is_connected" button user-action [routerLink]="['/account', user?.id,  'details']" (click)="dismiss()">
    <strong>Account Details</strong>
  </ion-item>
  <!-- <ion-item button user-action [routerLink]="'/change'" (click)="dismiss()"><strong>Change Password</strong></ion-item> 
  <ion-item button user-action [routerLink]="['/account', 'details', 'payment-methods']" (click)="dismiss()">
    <strong>Payment Details</strong>
  </ion-item> -->
  <ion-item id="signOutUserAction" class="signOutUserAction automationTesting" *ngIf="is_connected" button user-action (click)="signOut()" lines="none"><strong>Sign Out</strong>
  </ion-item>
  <ion-item id="notConnectedSignOut" class="notConnectedSignOut automationTesting" *ngIf="!is_connected" button user-action (click)="signOutNotConnected()" lines="none"><strong>Sign Out</strong>
  </ion-item>
</ion-list>