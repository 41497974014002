import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebserviceURL } from 'src/app/constants/webservice';
import { LevelPermission } from '../../classes/LevelPermission';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: ''
  })
};

@Injectable({
  providedIn: 'root'
})
export class CloudService {

  constructor(
    private http: HttpClient,
  ) {

  }

  // ***************** APPLICATION ENDPOINT REQUESTS  ************************/
  version() {
    return this.http.get(WebserviceURL + 'version', { responseType: 'text' });
  }

  async getLevelPermissions(): Promise<LevelPermission[]> {
    return this.http.get<LevelPermission[]>(WebserviceURL + 'account/permissions').toPromise();
  }

}