import { trigger, state, style, animate, transition } from '@angular/animations';

const fadeInTime = '.5s';
const fadeOutTime = '1s';
const slideTime = '.25s';

export const Animations = {
  enableDisable:
    trigger('enableDisable', [
      state('enabled', style({ opacity: 1 })),
      state('disabled', style({ opacity: .5, 'pointer-events': 'none' })),
      transition('enabled => disabled', [ animate(fadeOutTime) ]),
      transition('disabled => enabled', [ animate(fadeInTime) ])
    ]),
  slideUpDown:
    trigger('slideUpDown', [
      state('up', style({ opacity: 0, transform: 'translateY(-100%)', position: 'absolute' })),
      state('down', style({ opacity: 1, transform: 'translateY(0)', position: 'static' })),
      transition('up => down', [ animate(slideTime + ' ease-out') ]),
      transition('down => up', [ animate(slideTime + ' ease-in') ])
    ])
};