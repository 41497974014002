<ion-content class="ion-no-padding" main-white-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="white-background">
    <ion-title color="dark">
      <h1>Manage Gateway Subscription Plans</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/account', user?.id, 'details']" (click)="returnToAccountDetail()" me-button-as-link
        id="btnBackToAccountDetail" name="btnBackToAccountDetail">
        &#8592;&nbsp;Back to Account Details </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-list class="gwSubscriptionPlansButtons me-button-list" lines="none">
    <ion-item>

      <div id="i_change_sub_div" title="Change Subscription" class="ion-icon-wrapper-raw-button {{ isActionRestricted() ? 'site-transfer-disable-modal' : '' }}" 
        (click)="isActionRestricted() ? user.presentActionRestricted(null, null, iconMaintenanceType) : changeSubscriptionPlan()"
      >
        <ion-icon id="btnApps" class="me-icon-cursor" name="apps" me-icon></ion-icon>
      </div>

      <div id="i_decommission_div" title="Decommission Gateway" class="ion-icon-wrapper-raw-button {{ isActionRestricted() ? 'site-transfer-disable-modal' : '' }}" 
        (click)="isActionRestricted() ? user.presentActionRestricted(null, null, iconMaintenanceType) : decommissionGateway()"
      >
        <ion-icon id="btnDecommissionGateway" class="me-icon-cursor" name="remove-circle-sharp" me-icon></ion-icon>
      </div>

    </ion-item>
  </ion-list>

  <div *ngIf="siteGatewaysLoading && ( siteGateways.length == 0 )">
    <ion-label id="zeroStateLoading" name="zeroStateLoading" style="font-size: 20px; margin-left: 20px;"><i>Loading</i>
    </ion-label>
    <ion-spinner name="dots" style="position: absolute; height: 40px"></ion-spinner>
  </div>

  <ion-grid class="me-grid-main">
    <ion-row *ngFor="let site of siteGateways; index as i;" #siteRow id="{{site.site_id}}"
      [ngClass]="{'not-last-row': i !== siteGateways.length - 1, 'last-row': i === siteGateways.length - 1 }">
      <ion-col [ngClass]="(site && site.isDisabled) ? 'me-disabled-site-name' : 'me-site-name' " size-xl="12"
        size-lg="12" size-md="12" size-sm="12">
        <strong>{{site.site_name}}</strong>
      </ion-col>
      <ion-row *ngFor="let sgw of site.gateways; index as j" style="width:100%;border-left:none;border-right:none;"
        [ngClass]="{'not-last-row': j !== site.gateways.length - 1, 'last-row': j === site.gateways.length - 1, 'no-bot-border': j === site.gateways.length - 1 && i !== siteGateways.length - 1}">
        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" class="me-no-tb-padding">
          <ion-card class="me-card-no-shadow me-no-tb-margin" [disabled]="sgw.isDisabled">
            <ion-checkbox  *ngIf="!sgw.gatewaysubscription[0]?.subscriptions?.is_licensed"
              [checked]="sgw.isSelected" 
              [(ngModel)]="sgw.isSelected"
              (ionChange)="on_gateway_checked(sgw)"
              id="chkSelectGateway" 
              name="chkSelectGateway"
              class="me-gateway-name"
              label-placement="end"
              justify="start"><b class="me-gateway-label">{{sgw.name}}</b>
            </ion-checkbox>
            <div class="me-gateway-name ion-no-padding no-hover" *ngIf="sgw.gatewaysubscription[0]?.subscriptions?.is_licensed">
              <label class="me-gateway-label gw-name-without-checkbox">{{sgw.name}}</label>
            </div>
          </ion-card>

          <ion-card-content me-ion-card-content *ngIf="sgw.isExpanded">
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                  <ion-label class="me-gw-label">Gateway Name:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="ion-text-wrap">
                  <ion-label class="me-gw-label-text">{{sgw.name}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                  <ion-label class="me-gw-label">Device Type:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="ion-text-wrap">
                  <ion-label class="me-gw-label-text">{{sgw.model.name ? sgw.model.name : ''}}</ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                  <ion-label class="me-gw-label">Serial Number:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label-text">{{sgw.serial_number}}
                    <i *ngIf="sgw.has_coupon">({{sgw.coupon_name}})</i>
                  </ion-label>
                </ion-col>
              </ion-row>
              <div *ngIf="sgw.model.class_name == gatewayModelClass.RMD">
                <ion-row white-background>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                    <ion-label class="me-gw-label">MAC Address:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">{{sgw.mac_address}}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                    <ion-label class="me-gw-label">Device Key:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">{{sgw.device_key}}</ion-label>
                  </ion-col>
                </ion-row>
              </div>
              <div *ngIf="sgw.model.class_name == gatewayModelClass.MCC">
                <ion-row white-background>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6" class="me-table-label">
                    <ion-label class="me-gw-label">Registration Code:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">{{sgw.device_key}}</ion-label>
                  </ion-col>
                </ion-row>
              </div>

            </ion-grid>
          </ion-card-content>

        </ion-col>

        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" class="me-no-tb-padding">
          <ion-card class="me-card-no-shadow me-no-tb-margin" [disabled]="sgw.isDisabled" [disabled]="sgw.isDisabled">
            <ion-item lines="none" class="ion-no-padding no-hover">

              <ion-label id="lblSubscriptionPlanName" name="lblSubscriptionPlanName" class="me-icon-cursor" (click)="toggleExpandGateway(sgw)">
                <strong *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id != GSSTE.Suspended">
                  {{sgw.activeSubscription?.subscriptions.name}}
                </strong>
                <strong class="me-expired-limited-plan" *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Suspended">
                  Expired
                </strong>
              </ion-label>

              <div class="ion-icon-wrapper-arrow-hide-show" (click)="toggleExpandGateway(sgw)"
                [title]="sgw.isExpanded ? 'Collapse' : 'Expand'">
                <ion-icon id="btnToggleIsGatewayExpanded" slot="end"
                  [name]="sgw.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"></ion-icon>
              </div>
            </ion-item>
          </ion-card>

          <ion-card-content me-ion-card-content *ngIf="sgw.isExpanded">
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label">Registration Date:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label-text">
                    {{sgw.created_at | date : 'MM/dd/yyyy'}}
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label">Subscription Plan:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id != GSSTE.Suspended" class="me-gw-label-text">
                    {{ sgw?.activeSubscription?.subscriptions?.name }}
                  </ion-label>
                  <ion-label *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Suspended" class="me-gw-label-text me-expired-limited-plan">
                    Expired
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row white-background *ngIf="sgw.activeSubscription?.subscriptions?.is_licensed">
                <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label">License Type:</ion-label>
                </ion-col>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                  <ion-label class="me-gw-label-text">{{sgw.activeSubscription?.subscriptions?.coupontype.duration_years}}
                    Year(s)</ion-label>
                </ion-col>
              </ion-row>
              <div *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id != GSSTE.Suspended">
                <ion-row white-background>
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Billing Amount:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text" [style.text-decoration]="sgw.has_coupon ? 'line-through': 'none'">
                      <div *ngIf="!sgw.activeSubscription?.subscriptions.is_licensed">
                      {{sgw?.activeSubscription?.subscriptions?.monthly_rate | currency}}/mo</div>
                      <div *ngIf="sgw.activeSubscription?.subscriptions.is_licensed">n/a</div>
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-grid *ngIf="sgw?.active_sub?.is_zero_rate" class="subscriptionPlanMessage limitedPlanMessage">
                  <ion-row *ngIf="sgw?.active_sub?.expiration_date">
                    <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                      <strong>Limited Plan Exp.:</strong>
                    </ion-col>
                    <ion-col>
                      {{ sgw.active_sub?.expiration_date | date: 'MM/dd/yyyy' }}
                    </ion-col>
                  </ion-row>
                  <ion-row class="planMessageRow">
                    <ion-col class="me-grid-col">
                      {{ LIMITED_GW_WARNING }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <ion-row white-background *ngIf="sgw?.activeSubscription?.subscriptions?.monthly_rate > 0.0">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Billing Type:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-col *ngIf="sgw.has_coupon" class="me-gw-label-text">No Recurring Payment</ion-col>
                    <ion-col *ngIf="!sgw.has_coupon" class="me-gw-label-text">Recurring Payment</ion-col>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="sgw?.activeSubscription?.subscriptions.is_licensed">
                    <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                      <ion-label class="me-gw-label">Billing Type:</ion-label>
                    </ion-col>
                    <ion-col class="me-gw-label-text">Prepaid</ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw?.activeSubscription?.subscriptions?.monthly_rate > 0.0">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Next Payment:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label *ngIf="!sgw.has_coupon" class="me-gw-label-text"
                      [style.text-decoration]="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired ? 'line-through': 'none'">
                      {{site.nextPayment}}
                    </ion-label>
                    <ion-label *ngIf="sgw.has_coupon" class="me-gw-label-text">n/a</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="sgw?.activeSubscription?.subscriptions.is_licensed">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Next Payment:</ion-label>
                  </ion-col>
                  <ion-col class="me-gw-label-text">See Detail Below*</ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw?.activeSubscription?.subscriptions?.monthly_rate > 0.0">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Payment Method:</ion-label>
                  </ion-col>
                  <ion-col size-xl="3" size-lg="3" size-md="3" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text" *ngIf="site.paymentMethod?.card_type && (!sgw.has_coupon)">
                      {{site.paymentMethod?.card_type | titlecase }}
                      ending in {{site.paymentMethod?.last_four}}
                    </ion-label>
                    <ion-label class="me-gw-label-text" *ngIf="(!site.paymentMethod?.card_type) || sgw.has_coupon">
                      n/a
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="sgw?.activeSubscription?.subscriptions.is_licensed">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Payment Method:</ion-label>
                  </ion-col>
                  <ion-col class="me-gw-label-text">{{sgw.licenses[0]?.order_number}}</ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw?.activeSubscription?.subscriptions?.monthly_rate > 0.0">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Credit Card Exp.:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text" *ngIf="site.paymentMethod?.expiration && !sgw.has_coupon">
                      {{site.paymentMethod?.expiration}}
                    </ion-label>
                    <ion-label class="me-gw-label-text" *ngIf="!site.paymentMethod?.expiration || sgw.has_coupon">
                      n/a
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="sgw?.activeSubscription?.subscriptions.is_licensed">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">License Exp.:</ion-label>
                  </ion-col>
                  <ion-col class="me-gw-label-text">
                    {{sgw?.activeSubscription?.licenseExpiration | date: 'MM/dd/yyyy'}}
                  </ion-col>
                </ion-row>
                <ion-row class=" planMessageRow subscriptionPlanMessage"
                  *ngIf="sgw.activeSubscription?.licenseExpiration">
                  <ion-col class="me-grid-col me-gw-label-text" size-xl="10" size-lg="10" size-md="10" size-sm="10" size="10">
                    *{{LICENSED_GW_MESSAGE}}
                  </ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired"
                  class="color-red" style="margin-top:1em;">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">New Subscripion Plan:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{ sgw.subscription.name }}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">New Billing Amount:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text" [style.text-decoration]="sgw.has_coupon ? 'line-through': 'none'">
                      {{'0' | currency}}/mo
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Changed On:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{sgw.activeSubscription.updated_at | date: 'MM/dd/yyyy'}}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired"
                  class="color-red">
                  <ion-col class="me-grid-col" size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label">Effective Date:</ion-label>
                  </ion-col>
                  <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size="6">
                    <ion-label class="me-gw-label-text">
                      {{ firstOfNextMonth() | date: 'MM/dd/yyyy' }}
                    </ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background style="margin-top: 1em;"
                  *ngIf="sgw.activeSubscription.gateway_subscription_status_type_id == GSSTE.Expired" class="color-red">
                  <ion-col class="me-grid-col">
                    <ion-label class="me-gw-label-text">
                      You will continue to have access to the paid plan features until the beginning of the next
                      month.
                    </ion-label>
                  </ion-col>
                </ion-row>
                <div *ngIf="sgw.has_coupon">
                  <ion-row>
                    <ion-col>&nbsp;</ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col class="me-grid-col">
                      <ion-label class="me-gw-label-text"><i>Demo Gateway not subject to recurring monthly subscription
                          cost.</i></ion-label>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </ion-grid>
<!--            <pre style="margin-top: 40px;">sgw: {{sgw | json}}</pre>-->
          </ion-card-content>

        </ion-col>
      </ion-row>

    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
  <br>


</ion-content>