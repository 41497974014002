
export class ControlDisplay {
    // object used to track how a single control should be presented on the site-control ui

    // is this control active on all groups (implies showing)
    public selected: boolean
    // is this item control disable on at least 1 group
    public disabled: boolean
    // how many of the groups have this control selected
    public count: number
    // is this control valid for all groups (and shouldn't be hidden)
    public showing: boolean

    // counters
    selectedCount:number    // how many times in the batch is this control selected
    disabledCount:number    // how many times in the batch is this control disabled

    constructor() {
        this.reset();
    }

    addSelected() {
        this.selectedCount += 1;
    }

    addDisabled() {
        this.disabledCount += 1;
    }

    reset() {
        // reset counters
        this.count = 0;
        this.selected = false;
        this.showing = true;
        this.disabled = false;
        this.selectedCount = 0;
        this.disabledCount = 0;
    }

    public setDisplayOptions(totalCount: number, maintenanceJobActive: boolean) {
        // set the control values.
        // totalCount - number of groups selected currently
        this.selected = totalCount == this.selectedCount;
        this.disabled = (this.disabledCount > 0) || maintenanceJobActive;
        this.showing = this.disabledCount != totalCount;
        this.count = totalCount == 1 ? 0 : this.selectedCount;
    }
}
