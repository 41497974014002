<ion-header>
  <ion-toolbar>
    <ion-title>{{ pageTitle }}</ion-title>
    <ion-buttons slot="end">
      <ion-button id="ionBtnAddAnotherMember" class="ionBtnAddAnotherMember ionBtnAddInvitee automationTesting" (click)="addInviteeRow()">
        <ion-icon padding color="primary" name="add-circle"></ion-icon>
        <ion-label>&nbsp;Add Another Member</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="siteMemberAdminForm">
    <ion-grid class="ion-align-items-baseline">
      <ion-row *ngFor="let newSiteMember of newSiteMemberInviteelist; let first = first; let last = last">
        <ion-col size="6.5">
          <ion-input type="email" maxlength="50" 
            (ionInput)="updateNewSiteMemberList(newSiteMember.ui_rowid, 'siteMemberEmail', $event)"
            [value]="newSiteMember.siteMemberEmail" class="form-control"
            formControlName="email{{ newSiteMember.ui_rowid }}" id="{{ newSiteMember.ui_rowid }}"
            (keydown.space)="$event.preventDefault()" labelPlacement="floating">
              <ion-text slot="label" color="secondary">Email<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div *ngIf="getEmailControl(newSiteMember.ui_rowid).errors">
            <div validation-error
              *ngIf="getEmailControl(newSiteMember.ui_rowid).errors.required && getEmailControl(newSiteMember.ui_rowid).touched">
              Email address is required
            </div>
            <div validation-error *ngIf="getEmailControl(newSiteMember.ui_rowid).errors.invalidEmail">
              Enter a valid email address
            </div>
          </div>
          <div validation-error
            *ngIf="getEmailControl(newSiteMember.ui_rowid) && getEmailControl(newSiteMember.ui_rowid).errors && getEmailControl(newSiteMember.ui_rowid).errors.exists">
            Account already exists for this site
          </div>
          <div validation-error
            *ngIf="getEmailControl(newSiteMember.ui_rowid) && getEmailControl(newSiteMember.ui_rowid).errors && getEmailControl(newSiteMember.ui_rowid).errors.invited">
            Account has already been invited
          </div>
        </ion-col>
        <ion-col size="4.5">
          <ion-item lines="none">
            <ion-label position="stacked" style="margin-bottom: 15px" color="secondary">Role<sup validation-error>*</sup>
            </ion-label>
            <select class="inviteMemberSelectRoleDropdown automationTesting" [value]="newSiteMember.level"
              (change)="updateNewSiteMemberList(newSiteMember.ui_rowid,'siteMemberRole',$event)">
              <option class="inviteMemberSelectRoleDropdownOption automationTesting" *ngFor="let siteMemberRole of siteMemberRoles" [value]="siteMemberRole.id">
                {{ siteMemberRole.name }}</option>
            </select>
          </ion-item>
        </ion-col>
        <ion-col size="1">
          <ion-button id="ionBtnRemoveInvitee" class="ionBtnRemoveInvitee {{ first ? 'buttonIsHidden' : 'buttonIsVisible' }} automationTesting" [hidden]="first" (click)="removeInvitee(newSiteMember.ui_rowid)" fill="clear" relative-down button-adjust>
            <ion-icon color="danger" name="close-circle-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-label slot="start" *ngIf="!success" color="danger" class="ion-padding-horizontal">
      {{ displayErrorMessage }}
    </ion-label>
    <ion-button [appToggleButtonClickability]="toggleButtonClickabilityDetails" slot="end" id="btnInvite"
      [disabled]="!isReadyForSubmission || formHasErrors" (click)="onInviteSiteMembers($event)">
      {{ adminButtonText }}
    </ion-button>
    <ion-button slot="end" id="btnCancel" color="reverse-primary" (click)="onCancel()" class="ion-padding-horizontal">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>