<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Select an Outdoor Unit</ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-item *ngIf="parent_gateway_outdoor_units.length == 0" lines="none"><i>No suitable outdoor units exist in this
      site</i></ion-item>
  <ion-list>
    <ion-item id="{{outdoor_unit.id}}" *ngFor="let outdoor_unit of parent_gateway_outdoor_units"
      (click)="select_outdoor_unit(outdoor_unit)" lines="none" button>
      <ion-label>{{ outdoor_unit.name ? outdoor_unit.name : outdoor_unit.bus_address}}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>