<ion-header>
  <ion-toolbar>
    <ion-title slot="start" id="i_title_title" name="i_title_title">Select Scenario to Start {{ title }}
    </ion-title>
    <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
      <ion-icon slot="end" name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">

    <ion-row>

      <ion-col *ngIf="devTogglesEnabled" class="devToggles devOnlyToggles ion-padding-start" me-option-label size-xl="4" size-lg="4" size-md="4" size-sm="12" size="12">
        <ion-toggle [(ngModel)]="multiSelect" [enableOnOffLabels]="true">Multi Select</ion-toggle>
      </ion-col>

      <ion-col class="ion-padding-start" me-option-label [size-xl]="devTogglesEnabled ? '4' : '6'" [size-lg]="devTogglesEnabled ? '4' : '6'" [size-md]="devTogglesEnabled ? '4' : '6'" [size-sm]="devTogglesEnabled ? '4' : '6'" [size]="devTogglesEnabled ? '4' : '6'">
        <ion-label me-label id="i_label_outdoor_unit" name="i_label_outdoor_unit">Outdoor Unit{{ multiSelect ? '(s)' : '' }}:</ion-label>
      </ion-col>

      <ion-col me-option-col [size-xl]="devTogglesEnabled ? '4' : '6'" [size-lg]="devTogglesEnabled ? '4' : '6'" [size-md]="devTogglesEnabled ? '4' : '6'" [size-sm]="devTogglesEnabled ? '4' : '6'" [size]="devTogglesEnabled ? '4' : '6'">
        <div class="me-float-div-content-right">
          <ion-buttons>
            <ion-button id="i_btn_select_outdoor_unit" name="i_btn_select_outdoor_unit" slot="end"
              (click)="onSelectOutdoorUnit()" [ngClass]="data_loading ? 'outdoor-unit-selection-loading' : 'outdoor-unit-selection'" [disabled]="data_loading">
              <div class="oflow oduLabel" title="{{ renderSelectionLabel() }}">
                {{ data_loading ? 'Loading Outdoor Unit List...' : renderSelectionLabel() }}
              </div>
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </ion-col>
      
    </ion-row>

    <ion-row>
      <ion-col size="12" me-warning-label>
        <ul class="bulletListContent {{ contentFit == true ? 'contentFit' : '' }} refrigerantCheckContent">
          <li>
            {{ title }} requires approximately 80 minutes to complete.
          </li>
          <li>
            This function operates the indoor unit in “cooling” mode and fan speed at “high”.  After using this function, return the setting of the indoor unit(s) as desired.
          </li>
          <li>
            DO NOT use this function when the ambient temperature is less than {{ user && user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? TemperatureConversions.convert_from_ME_fahrenheit_to_ME_celsius(59) + ' °C' : '59 °F' }} or while charging refrigerant.
          </li>
          <li>
            DO NOT change control points from a central or remote controller during the process and TURN OFF scheduling functions or this will output incorrect results.
          </li>
          <li>
            The function supports both single and twinned units of the following models:
            <br>
            PUHY-P72T (Y) NU*
            <br>
            PUHY-P96T (Y) NU*
            <br>
            PUHY-P120T (Y) NU*
            <br>
            PUHY-P144T (Y) NU*
            <br>
            PUHY-P168T (Y) NU*
          </li>
        </ul>
      </ion-col>
    </ion-row>

  </ion-grid> 
</ion-content>

<ion-label class="silverWarning {{ contentFit == true ? 'contentFit' : '' }}" me-warning-label>
  When operating in {{ title }} mode, units cannot be controlled, and scheduled events will not execute.
</ion-label>

<!-- <ion-label class="silverWarning {{ contentFit == true ? 'contentFit' : '' }}" me-warning-label>
  Do not close the browser or sign out or you will need to start this process over.
</ion-label> -->

<ion-footer>
  <ion-toolbar>
    <ion-button me-start-btn id="i_btn_ready_for_submission" name="i_btn_ready_for_submission"
      (click)="modalController.dismiss({ run: true, outdoor_units })" [disabled]="!readyForSubmission()" color="primary">
      Start {{ title }}
    </ion-button>
  </ion-toolbar>
</ion-footer>