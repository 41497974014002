import { group } from "console"
import { GatewayUnitTwoDigitType } from "src/app/enumerations/enums"
import { AirDirection, GatewayUnit } from "src/app/features/manage/components/classes/GatewayUnit"
import { ControlDisplay } from "src/app/features/sites/components/site-control/batchControl/controlDisplay"
import { ControlSection } from "./controlSection"

export class AirDirectionControl extends ControlSection {
    // options to present for Air Direction control of indoor coils
    stationary: ControlDisplay      // how to render the air direction stationary button
    ceiling: ControlDisplay         // how to render the air direction ceiling button
    high: ControlDisplay            // how to render the air direction high button
    middle: ControlDisplay          // how to render the air direction middle button
    low: ControlDisplay             // how to render the air direction low button
    floor: ControlDisplay           // how to render the air direction floor button
    swing: ControlDisplay           // how to render the air direction swing button
    auto: ControlDisplay            // how to render the air direction auto button

    firstUnit: GatewayUnit           // the first unit added to the batch - used at end
                                    // if all are the same to get a description and icon

    constructor() {
        super();
        this.stationary = new ControlDisplay;
        this.ceiling = new ControlDisplay;
        this.high = new ControlDisplay;
        this.middle = new ControlDisplay;
        this.low = new ControlDisplay;
        this.floor = new ControlDisplay;
        this.swing = new ControlDisplay;
        this.auto = new ControlDisplay;
        this.firstUnit = null;
    }

   
    resetSectionCounters() {
        // set our couters to zero
        this.stationary.reset();
        this.ceiling.reset();
        this.high.reset();
        this.middle.reset();
        this.low.reset();
        this.floor.reset();
        this.swing.reset();
        this.auto.reset();
        this.firstUnit = null;
    }

    addGroupToBatch(unit: GatewayUnit) {
        // add details of this unit re: mode

        // current ic modes
        if (unit.type == GatewayUnitTwoDigitType.IndoorUnit) {
            // air direction disables

            // stationary is the special case that takes out all the others.
            if (unit.isAirDirectionStationaryDisabled()) this.stationary.addDisabled();
            if (unit.isAirDirectionCeilingDisabled()) this.ceiling.addDisabled();
            if (unit.isAirDirectionHighDisabled()) this.high.addDisabled();
            if (unit.isAirDirectionMiddleDisabled()) this.middle.addDisabled();
            if (unit.isAirDirectionLowDisabled()) this.low.addDisabled();
            if (unit.isAirDirectionFloorDisabled()) this.floor.addDisabled();
            if (unit.isAirDirectionSwingDisabled()) this.swing.addDisabled();
            if (unit.isAirDirectionAutoDisabled()) this.auto.addDisabled();

            // air direction current mode
            if (unit.isInAirDirectionStationary()) this.stationary.addSelected();
            else if (unit.isInAirDirectionCeiling()) this.ceiling.addSelected();
            else if (unit.isInAirDirectionHigh()) this.high.addSelected();
            else if (unit.isInAirDirectionMiddle()) this.middle.addSelected();
            else if (unit.isInAirDirectionLow()) this.low.addSelected();
            else if (unit.isInAirDirectionFloor()) this.floor.addSelected();
            else if (unit.isInAirDirectionSwing()) this.swing.addSelected();
            else if (unit.isInAirDirectionAuto()) this.auto.addSelected();

            if (this.firstUnit == null) this.firstUnit = unit;
        } 
    }

    setDisplayOptions(groupCount:number, maintenanceJobActive: boolean) {
        // called when all groups are added and we should set our controllable/display states

        // set IC mode options - mode
        this.stationary.setDisplayOptions(groupCount, maintenanceJobActive)
        this.ceiling.setDisplayOptions(groupCount, maintenanceJobActive)
        this.high.setDisplayOptions(groupCount, maintenanceJobActive)
        this.middle.setDisplayOptions(groupCount, maintenanceJobActive)
        this.low.setDisplayOptions(groupCount, maintenanceJobActive)
        this.floor.setDisplayOptions(groupCount, maintenanceJobActive)
        this.swing.setDisplayOptions(groupCount, maintenanceJobActive)
        this.auto.setDisplayOptions(groupCount, maintenanceJobActive)

        const labels = this.getLabelAndIcon()
        this.label = labels[0]
        this.label_icon = labels[1]        

    }

    getLabelAndIcon() {
        // determine and return the air-direction label and iconto display on the label line - if any
        let label = 'Mixed'
        let icon = ''
        if (this.stationary.selected
            || this.ceiling.selected
            || this.high.selected
            || this.middle.selected
            || this.low.selected 
            || this.floor.selected
            || this.swing.selected
            || this.auto.selected) {
                // then everyone is the same
                label = this.displayOptions.getAirDirectionDisplay(this.firstUnit)
                icon = this.displayOptions.getAirDirectionIcon(this.firstUnit.air_direction, this.firstUnit.air_direction_sw)
            }

        return [label, icon]
    }
 
}
