import { GroupSelectorFilterTypes, SearchFilterActionTypes, SearchFilterObjectTypes, SubscriptionFeatures } from "src/app/enumerations/enums";
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, ViewChildren, QueryList } from "@angular/core";
import { SiteService } from "src/app/features/sites/services/site.service";
import { MainSiteUIService } from "../../services/ui/main-site-ui.service";
import { WindowService } from "../../services/ui/window.service";
import { devEnv } from "src/app/constants/kenzaconstants";
import { IonSearchbar } from "@ionic/angular";

@Component({
    selector: 'app-search-and-filter',
    templateUrl: './search-and-filter.component.html',
})

export class SearchAndFilterComponent {
    
    width: number;
    height: number;
    devEnv = devEnv;
    checkedBoxes = [];
    activeGateways = [];
    activeLocations = [];
    activeGatewayIDs = [];
    @Input() sizeXL = true;
    @Input() selectedGroups;
    @Input() itemClass = ``;
    @Input() searchClass = ``;
    // docClicksListener = null;
    filterSelectionOpen = false;
    @Input() searchElementTag = ``;
    @Input() useInternalList = false;
    windowHeight = window.innerHeight;
    SubscriptionFeatures = SubscriptionFeatures;
    SearchFilterActionTypes = SearchFilterActionTypes;
    SearchFilterObjectTypes = SearchFilterObjectTypes;
    GroupSelectorFilterTypes = GroupSelectorFilterTypes;
    @Output() searchFilterDataSignal = new EventEmitter();
    @ViewChildren(`itemWrapper`) itemWrappers: QueryList<ElementRef>;
    @ViewChild(`searchElementTag`, { static: false }) searchbar: IonSearchbar;
    @ViewChildren(`gsFilterCheckBox`) gsFilterCheckBoxes: QueryList<ElementRef>;

    constructor(
        private element: ElementRef,
        // private renderer: Renderer2,
        public siteService: SiteService,
        private windowSizeService: WindowService,
        private mainSiteUIService: MainSiteUIService,
    ) {
        // Empty
    }

    ngOnInit() {
        this.setWindowSizeVariables();
    }

    getCurrentSearchBar() {
        return this.searchbar;
    }

    resizeFiltersContainer(e) {
        this.windowHeight = window.innerHeight;
    }

    getCurrentSearchTerm() {
        return this.mainSiteUIService.searchTerm.toLowerCase();
    }

    ngAfterViewInit() {
        this.searchbar = this.element.nativeElement.querySelector(`.${this.searchElementTag}`);
    }

    isActiveFilter(filter) {
        return this.mainSiteUIService.isActiveFilter(filter, this.checkedBoxes, this.activeLocations, this.activeGatewayIDs);
    }

    clearCurrentSearch() {
        this.mainSiteUIService.searchTerm = ``;
        this.searchbar.value = this.mainSiteUIService.searchTerm;
    }

    setWindowSizeVariables() {
        this.windowSizeService.width$.subscribe(width => this.width = width);
        this.windowSizeService.height$.subscribe(height => this.height = height);
    }

    setCheckedBoxes() {
        let updatedCheckedBoxes = this.gsFilterCheckBoxes.length > 0 ? this.gsFilterCheckBoxes.map((checkbox: any) => {
            if (checkbox.checked == true || checkbox.el.checked == true || checkbox.checked === true || checkbox.el.checked === true) {
              let categoryOfCheckBoxGroup = checkbox.el.parentElement.parentElement.firstChild.innerText;
              let labelNextToCheckBox = this.mainSiteUIService.reverseFilterName(checkbox.el.innerText);
              if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.LOCATION_AREA) {
                this.activeLocations.push(labelNextToCheckBox);
                this.mainSiteUIService.activeLocations.push(labelNextToCheckBox);
              } else if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.GATEWAY) {
                let id = checkbox.el.value;
                this.activeGateways.push(labelNextToCheckBox);
                this.activeGatewayIDs.push(id);
                this.mainSiteUIService.activeGateways.push(labelNextToCheckBox);
                this.mainSiteUIService.activeGatewayIDs.push(id);
              }
              this.activeGateways = [...new Set(this.activeGateways)];
              this.activeLocations = [...new Set(this.activeLocations)];
              this.activeGatewayIDs = [...new Set(this.activeGatewayIDs)];
              return labelNextToCheckBox;
            } else {
                let categoryOfCheckBoxGroup = checkbox.el.parentElement.parentElement.firstChild.innerText;
                let labelNextToCheckBox = this.mainSiteUIService.reverseFilterName(checkbox.el.innerText);
                if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.LOCATION_AREA) {
                    this.activeLocations = this.activeLocations.filter(fil => fil != labelNextToCheckBox);
                } else if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.GATEWAY) {
                    let id = checkbox.el.value;
                    this.activeGateways = this.activeGateways.filter(fil => fil != labelNextToCheckBox);
                    this.activeGatewayIDs = this.activeGatewayIDs.filter(fil => fil != id);
                }
            }
        }).filter(val => val != `` && val != undefined && val != null) : [];

        // let setCheckedBoxesParams = {
        //     updatedCheckedBoxes,
        //     checkedBoxes: this.checkedBoxes,
        //     checkBoxes: this.gsFilterCheckBoxes,
        //     activeGateways: this.activeGateways,
        //     activeLocations: this.activeLocations,
        //     activeGatewayIDs: this.activeGatewayIDs,
        //     gsFilters: this.mainSiteUIService.gsFilters,
        //     mainSiteUIServiceActiveGateways: this.mainSiteUIService.activeGateways,
        //     mainSiteUIServiceActiveLocations: this.mainSiteUIService.activeLocations,
        //     mainSiteUIServiceActiveGatewayIDs: this.mainSiteUIService.activeGatewayIDs,
        // };

        // devEnv && console.log(`setCheckedBoxes`, setCheckedBoxesParams);

        return updatedCheckedBoxes;
    }

    setActiveFilters(filter, e?) {
        this.checkedBoxes = this.setCheckedBoxes();

        this.mainSiteUIService.gsFilters.map(gsFil => {
            if (this.checkedBoxes.some(fil => gsFil.filters.includes(fil))) {
              if (filter.name == gsFil.name) {
                gsFil.active = true;
              } else {
                gsFil.active = false;
              }
              return gsFil;
            } else {
              gsFil.active = false;
              return gsFil;
            }
        });

        // let unitsWithLocationNoneActive = this.mainSiteUIService.activeLocations.includes(GroupSelectorFilterTypes.NONE);
        // let locationFiltersActive = this.mainSiteUIService.activeLocations.length > 0;
        let unitsWithLocationNoneActive = this.activeLocations.includes(GroupSelectorFilterTypes.NONE);
        let locationFiltersActive = this.activeLocations.length > 0;
        let gatewayFiltersActive = this.activeGatewayIDs.length > 0;
        // let gatewayFiltersActive = this.mainSiteUIService.activeGatewayIDs.length > 0;

        let activeFiltersParams = {
            filter,
            gatewayFiltersActive,
            locationFiltersActive, 
            unitsWithLocationNoneActive,
            checkedBoxes: this.checkedBoxes,
            checkBoxes: this.gsFilterCheckBoxes,
            activeGateways: this.activeGateways,
            activeLocations: this.activeLocations,
            activeGatewayIDs: this.activeGatewayIDs,
            gsFilters: this.mainSiteUIService.gsFilters,
            mainSiteUIServiceActiveGateways: this.mainSiteUIService.activeGateways,
            mainSiteUIServiceActiveLocations: this.mainSiteUIService.activeLocations,
            mainSiteUIServiceActiveGatewayIDs: this.mainSiteUIService.activeGatewayIDs,
        }

        // devEnv && console.log(`setActiveFilters`, activeFiltersParams);

        this.searchFilterDataSignal.emit({
            activeFiltersParams,
            type: SearchFilterActionTypes.Filter,
            objectType: SearchFilterObjectTypes.Checkbox,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
        });
    }

    onSearchInput(e) {
        this.mainSiteUIService.searchTerm = e.detail.value.toLowerCase();
        this.searchFilterDataSignal.emit({
            checkedBoxes: this.checkedBoxes,
            type: SearchFilterActionTypes.Search,
            objectType: SearchFilterObjectTypes.Gateways,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
        });
    }

    filteredGatewayFilters(gsFilter) {
        let filteredFilters = gsFilter.filters.filter(fil => fil.expired == false);
        return filteredFilters;
    }

    groupsInSearchAndFilter(groups) {
        return groups.filter(grp => grp.units[0].type == this.selectedGroups[0].type);
    }

    toggleFilterSelection(e) {
        if (this.useInternalList == true) {
            this.filterSelectionOpen = !this.filterSelectionOpen;
            // this.mainSiteUIService.gsFilters = this.mainSiteUIService.gsFilters.filter(fil => fil.name != GroupSelectorFilterTypes.UNIT_TYPE);
            this.mainSiteUIService.getLocationFiltersFromGateways(this.mainSiteUIService.siteGatewaysObject);
            this.mainSiteUIService.setGatewayFilters();

            setTimeout(() => {
                // Need to get rid of default padding on inside of ion item shadow roots 
                this.itemWrappers.forEach((itemWrapper: any) => {
                  let shadowRoot = itemWrapper?.el?.shadowRoot;
                  let itemInner = shadowRoot?.querySelector(`.item-inner`);
                  if (itemInner) itemInner.style.padding = `0`;
                });
            }, 10);
        }

        this.searchFilterDataSignal.emit({
            type: SearchFilterActionTypes.Toggle,
            objectType: SearchFilterObjectTypes.List,
            gsFilters: this.mainSiteUIService.gsFilters,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
        });
    }

    toggleSelectAll(e) {
        this.searchFilterDataSignal.emit({
            type: SearchFilterActionTypes.Toggle,
            objectType: SearchFilterObjectTypes.Select,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
        });
    }

    toggleCheckBoxSelectAll(e) {
        this.searchFilterDataSignal.emit({
            type: SearchFilterActionTypes.Toggle,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
            objectType: SearchFilterObjectTypes.SelectAllChecks,
        });
    }

    checkAllCheckboxes(e?) {
        if (!this.siteService.handleIsConnected()) return;
        this.gsFilterCheckBoxes.forEach((checkbox: any) => {
          checkbox.el.checked = true;
        });
        this.mainSiteUIService.gsFilters = this.mainSiteUIService.gsFilters.map(gsFil => {
          gsFil.active = true;
          return gsFil;
        });
        this.checkedBoxes = this.setCheckedBoxes();
        this.mainSiteUIService.totalFilters = this.gsFilterCheckBoxes.length;
        // this.mainSiteUIService.checkedBoxes = this.setCheckedBoxes();
        this.mainSiteUIService.refreshBadgeCounts();
    }

    clearAllCheckBoxFilters(e?) {
        this.checkedBoxes = [];
        this.activeGateways = [];
        this.activeLocations = [];
        this.activeGatewayIDs = [];
        this.mainSiteUIService.clearAllCheckBoxFilters();
        this.gsFilterCheckBoxes.forEach((checkbox: any) => {
            checkbox.el.checked = false;
        });
        this.searchFilterDataSignal.emit({
            type: SearchFilterActionTypes.Clear,
            objectType: SearchFilterObjectTypes.Checkbox,
            searchTerm: this.mainSiteUIService.searchTerm,
            filterSelectionOpen: this.filterSelectionOpen,
        });
    }
}