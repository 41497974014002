import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { Logger } from 'src/app/common/services/logging/log.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SiteAlertFilter } from 'src/app/common/classes/filters';

@Component({
  selector: 'app-site-member-remove',
  templateUrl: './site-member-remove.page.html',
  styleUrls: ['./site-member-remove.page.scss'],
})
export class SiteMemberRemovePage implements OnInit {
  @Input() parentSiteId: string;
  @Input() parentSiteName: string;
  @Input() parentAccountId: string;
  @Input() parentAccountEMail: string;
  @Input() parentAccountSiteLevel: number;

  displayMessage = '';
  isReadyForSubmission: boolean = false;
  logger: Logger;

  constructor(
    private modalController: ModalController,
    private loading: LoadingController,
    private siteService: SiteService,
    private user: UserService,
    private mainSiteUIService: MainSiteUIService,
  ) { }

  ngOnInit() {
    this.logger = new Logger();
    this.setDisplayMessage(this.parentAccountSiteLevel);
  }

  isMemberLoggedInUser(loggedIn_user_account_id, remove_member_account_id) {
    return loggedIn_user_account_id === remove_member_account_id ? true : false;
  }

  setDisplayMessage(accountSiteLevel: number) {
    switch (accountSiteLevel) {
      case 4:
        this.displayMessage = 'Site Owners are not allowed to be removed.';
        break;

      default:
        if (this.isMemberLoggedInUser(this.user.id, this.parentAccountId) === true) {
          this.displayMessage = 'Are you sure you want to remove YOURSELF from this site?';
        } else {
          this.displayMessage = 'Are you sure you want to remove?';
        }
        break;
    }
  }

  async onRemoveSiteMember() {
    const load = await this.loading.create({
      spinner: 'lines',
      message: 'Removing site member...'
    });

    load.present();

    this.siteService.removeSiteMember(this.parentSiteId, this.parentAccountId).subscribe(data => {
      this.user.activeSiteAccounts = this.user.activeSiteAccounts.filter(siteAccount => siteAccount.id !== this.parentAccountId);
      const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
      siteAlertFilter.site_id = this.user.active.id;
      siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

      this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
      load.dismiss();
      this.modalController.dismiss(
        {
          removedCurrentUser: this.isMemberLoggedInUser(this.user.id, this.parentAccountId)
        }
      );
    }, error => {
      // TODO : Add error logging
      load.dismiss();

    }, () => {
      this.modalController.dismiss(
        {
          removedCurrentUser: this.isMemberLoggedInUser(this.user.id, this.parentAccountId)
        }
      );
    });

    load.dismiss();
  }

  onCancel() {
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss(
      {
        removedCurrentUser: false
      }
    );
  }

}
