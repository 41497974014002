<ion-header>
  <ion-toolbar>
    <ion-title slot="start" id="i_title_title" name="i_title_title">Select {{title}}
  </ion-title>
  <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
    <ion-icon slot="end" name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
  </div>  
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let value of values, index as i" (click)="setClickedSelection(i+1)" lines="none" button>
      <ion-label>{{value}}</ion-label>
    </ion-item>
  </ion-list>  
</ion-content>
