import { SiteNotificationTypeEnum, SiteNotificationTypeActionEnum } from "src/app/enumerations/enums";

export function getNotificationHeader(siteNotification) {
    let sitenotificationtype_id = siteNotification.sitenotificationtype_id;
    let header = "";

    switch (sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Membership:
        if(siteNotification.context != null && 'type' in siteNotification.context && siteNotification.context['type'] == 'SITE_TRANSFER') {
          header = "Site Ownership Transfer Notification";
        } else {
          header = "Membership Notification";
        }
        
        break;

      case SiteNotificationTypeEnum.Gateway:
        header = "Gateway Notification";
        break;

      case SiteNotificationTypeEnum.Payments:
        header = "Payment Notification";
        break;

      case SiteNotificationTypeEnum.Subscription:
        header = "Subscription Notification";
        break;

      case SiteNotificationTypeEnum.Equipment:
        header = "Equipment Alarm";
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        header = "Equipment Notification";
        break;
    }
    return header;
  }

  export function getNotificationImageName(siteNotification) {
    if (!siteNotification){
      console.error('siteNotification not found in getNotificationImageName()');
      return '';
    }
    const siteNotificationTypeAction = SiteNotificationTypeActionEnum;
    let sitenotificationtype_id = siteNotification.sitenotificationtype_id;
    let imageName = "";

    switch (sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Membership:
        if(siteNotification.context != null && 'type' in siteNotification.context && siteNotification.context['type'] == 'SITE_TRANSFER') {
            imageName = "business-sharp.svg";
        } else {
            imageName = "edit-md-person-notification.svg";
        }
        
        break;

      case SiteNotificationTypeEnum.Gateway:
        imageName = "custom-gateway.svg";
        break;

      case SiteNotificationTypeEnum.Payments:
        imageName = siteNotification?.context?.actionType == siteNotificationTypeAction.License_Activated ?
                 `../../../assets/imgs/receipt-outline.svg`
                : `../../../assets/imgs/edit-md-card-notification.svg`;
        break;

      case SiteNotificationTypeEnum.Subscription:
        imageName = "edit-md-person-notification.svg";
        break;

      case SiteNotificationTypeEnum.Equipment:
        imageName = "alert-circle.svg";
        break;
      case SiteNotificationTypeEnum.EquipmentNotification:
        imageName = "alert-circle.svg";
        break;
    }
    return imageName;
  }