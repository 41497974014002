import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'maintenance-testrun',
    templateUrl: './maintenance-testrun.component.html',
    styleUrls: ['./maintenance-testrun.component.scss']
})


export class MaintenanceTestRunComponent implements OnInit {
    visible: boolean;

    constructor() {
        this.visible = false;
    }

    ngOnInit() {
        // empty
    }

    ionViewWillEnter() { 
        // empty
    }
    ionViewWillLeave() { 
        // empty
    }

    hide() {
        this.visible = false;
    }
    show() {
        this.visible = true;
    }


}
