import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { FormatMonthPipe } from '../../pipes/format-month.pipe';
import { FormatCardTypePipe } from '../../pipes/format-card-type.pipe';
import { IonicModule } from '@ionic/angular';
//import { OKModalPage } from '../../modal/OKModal/okmodal/okmodal.page';
//import { CustomModalSelectPage } from '../../modal/custom-modal-select/custom-modal-select.page';
import { DateDayAndRangePickerComponent } from '../../components/datetime/datedayandrangepicker/date-day-and-range-picker/date-day-and-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ConfirmationModalPage } from '../../modal/confirmation-modal/confirmation-modal.page';
import { MouseOverOpacityDirective } from '../../directives/mouseoveropacity/mouse-over-opacity.directive';
import { LimitPasswordKeysDirective } from '../../directives/limitpasswordkeys/limit-password-keys.directive';
import { ManageMemberDetailComponent } from 'src/app/features/manage/components/manage-member-detail/manage-member-detail.component';
import { GroupSelectorComponent } from '../../components/group-selector/group-selector.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { LargeIconButtonComponent } from '../../components/large-icon-button/large-icon-button.component';
import { SidebarSlider } from '../../components/sidebar-slider/sidebar-slider.component';
import { SiteWeatherComponent } from 'src/app/features/sites/components/site-weather/site-weather.component';
import { TextMaskComponent } from '../../components/text-mask/text-mask.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SearchAndFilterComponent } from '../../components/search-and-filter/search-and-filter.component';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  declarations: [
    FormatCardTypePipe,
    FormatMonthPipe,
    //    OKModalPage,
    //    CustomModalSelectPage,
    DateDayAndRangePickerComponent,
    //    ConfirmationModalPage,
    MouseOverOpacityDirective,
    LimitPasswordKeysDirective,
    ManageMemberDetailComponent,
    GroupSelectorComponent,
    SearchAndFilterComponent,
    LargeIconButtonComponent,
    SidebarSlider,
    SiteWeatherComponent,
    TextMaskComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DragScrollModule,
    TextMaskModule,
    MaskitoDirective
  ],
  exports: [
    FormatCardTypePipe,
    FormatMonthPipe,
    //    OKModalPage,
    //    CustomModalSelectPage,
    DateDayAndRangePickerComponent,
    MouseOverOpacityDirective,
    LimitPasswordKeysDirective,
    ManageMemberDetailComponent,
    GroupSelectorComponent,
    SearchAndFilterComponent,
    LargeIconButtonComponent,
    SidebarSlider,
    SiteWeatherComponent,
    TextMaskComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] // for swiper
})
export class SharedModule { }
