<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Configure Indoor Coil Groups</ion-title>
    <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" style="padding-left: 10px">
        <label me-ion-modal-message style="font-style: italic;">
          Changes to indoor unit group assignments can take up to 5 mins to process.
        </label>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
        <ion-grid grid-group>
          <ion-row>
            <ion-col>
              <ion-item lines="none" class="ion-no-padding">
                <ion-label class="ion-no-padding" style="max-width: 70px;">
                  <strong>Groups</strong>
                </ion-label>
                <span title="" style="cursor: pointer;"
                  (click)="on_open_group_admin(null,selected_outdoor_unit, group_model_state.Create)">
                  <ion-icon name="add-circle" color="primary" me-ion-icon></ion-icon>
                </span>
                <ion-label class="ion-no-padding"
                  (click)="on_open_group_admin(null, selected_outdoor_unit ,group_model_state.Create)"
                  style="cursor: pointer;">
                  Add Another Group
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="FixedHeightContainer">
            <ion-col class="FixedHeightContainer">
              <div class="Content">
                <ion-card me-ion-unit-card *ngFor="let odu_group of selected_odu_groups" id="odu_groupCard"
                  name="odu_groupCard">
                  <ion-item class="ion-no-padding" *ngIf="!odu_group.autocreated">
                    <ion-label id="lblGatewayGroup" name="lblGatewayGroup" class="ion-padding-start">
                      {{odu_group.group_name ? odu_group.group_name : ''}}
                    </ion-label>
                    <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                      (click)="showSelectedGatewayGroup(odu_group.group_id)"
                      [title]="odu_group.isExpanded ? 'Collapse' : 'Expand'">
                      <ion-icon [name]="odu_group.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'" me-cursor-pointer>
                      </ion-icon>
                    </div>
                  </ion-item>

                  <ion-card-content *ngIf="odu_group.isExpanded" lines="none" style="max-height: 300px">
                    <div style="float:left; width:90%; margin-bottom:10px;">
                      <ion-grid class="ion-no-padding">
                        <ion-row white-background>
                          <ion-col size="6"><strong>Group Name:</strong></ion-col>
                          <ion-col size="6" class="textOverflow locationAreaTextOverlow">
                            <ion-label id="lblGroupName" name="lblGroupName" ion-no-padding class="ion-no-padding">
                              {{ odu_group.group_name }}
                            </ion-label>
                          </ion-col>
                        </ion-row>
                        <ion-row white-background>
                          <ion-col size="6"><strong>Unit(s):</strong></ion-col>
                          <ion-col size="6" class="textOverflow locationAreaTextOverlow">
                            <div id="lblIDUBusAddressByName" name="lblIDUBusAddressByName" ion-no-padding
                              class="ion-no-padding" *ngFor="let unit of odu_group.units">
                              {{unit.name}}
                            </div>
                          </ion-col>
                        </ion-row>
                        <ion-row white-background>
                          <ion-col size="6"><strong>Location / Area:</strong></ion-col>
                          <ion-col size="6">
                            <div id="lblIDULocation" name="lblIDULocation" ion-no-padding class="ion-no-padding" >
                              {{odu_group.location}}
                            </div>
                          </ion-col>
                        </ion-row>

                      </ion-grid>
                    </div>
                    <div style="float:right; width:10%;">
                      <div class="ion-icon-wrapper-raw-button" style="font-size: 22px" title="Edit Group"
                        (click)="on_open_group_admin(odu_group, selected_outdoor_unit,group_model_state.Update)">
                        <ion-icon name="create" me-cursor-pointer></ion-icon>
                      </div>
                      <div class="ion-icon-wrapper-raw-button" style="font-size: 22px" title="Delete Group"
                        (click)="on_open_group_admin(odu_group,selected_outdoor_unit, group_model_state.Delete)">
                        <ion-icon name="close-circle-outline" color="danger" me-cursor-pointer></ion-icon>
                      </div>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>

      <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
        <ion-grid grid-group>
          <ion-row>
            <ion-col>
              <ion-item lines="none" class="ion-no-padding">
                <ion-label class="ion-no-padding">
                  <strong>Indoor Coils</strong>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="FixedHeightContainer">
            <ion-col class="FixedHeightContainer">
              <div class="Content">
                <ion-card me-ion-unit-card *ngFor="let indoorUnit of selected_odu_indoor_units" id="indoorUnitCard"
                  name="indoorUnitCard">

                  <ion-item class="ion-no-padding">
                    <ion-label id="lblIndoorBusAddressOfName" name="lblIndoorBusAddressOfName"
                      class="ion-padding-start">
                      {{indoorUnit.name ? indoorUnit.name : indoorUnit.bus_address}}
                      <br>
                      <div me-ion-card-sub-title style="font-size:small">
                        {{get_group_name_by_id(indoorUnit, indoorUnit.group_id) }}
                      </div>
                    </ion-label>
                    <div class="ion-icon-wrapper-arrow-hide-show" slot="end"
                      (click)="showSelectedIndoorUnit(indoorUnit.id)"
                      [title]="indoorUnit.isExpanded ? 'Collapse' : 'Expand'">
                      <ion-icon [name]="indoorUnit.isExpanded ? 'caret-up-sharp' : 'caret-down-sharp'"
                        me-cursor-pointer></ion-icon>
                    </div>
                  </ion-item>

                  <ion-card-content *ngIf="indoorUnit.isExpanded" lines="none" style="max-height: 300px">
                    <div class="idu-outer">
                      <div class="idu-left" style="margin-bottom:10px;">
                        <ion-grid class="ion-no-padding">
                          <ion-row white-background>
                            <ion-col size="6"><strong>Unit Name:</strong></ion-col>
                            <ion-col class="textOverflow locationAreaTextOverlow" size="6">
                              <ion-label id="lblIDUName" name="lblIDUName" ion-no-padding class="ion-no-padding">
                                {{ indoorUnit.name }}
                              </ion-label>
                            </ion-col>
                          </ion-row>
                          <ion-row white-background *ngIf="indoorUnit && indoorUnit.type !== 'LC'">
                            <ion-col size="6"><strong>Group:</strong></ion-col>
                            <ion-col size="6" class="textOverflow locationAreaTextOverlow">
                              <ion-label id="lblIDUGroup" name="lblIDUGroup" ion-no-padding class="ion-no-padding">
                                {{get_group_name_by_id(indoorUnit,indoorUnit.group_id) }}
                              </ion-label>
                            </ion-col>
                          </ion-row>
                          <ion-row white-background>
                            <ion-col size="6"><strong>Unit Type:</strong></ion-col>
                            <ion-col size="6">
                              <ion-label id="lblIDUTypeName" name="lblIDUTypeName" ion-no-padding
                                class="ion-no-padding">
                                {{indoorUnit.type_name ? indoorUnit.type_name : indoorUnit.type}}
                              </ion-label>
                            </ion-col>
                          </ion-row>
                          <ion-row white-background>
                            <ion-col size="6"><strong>M-NET Address:</strong></ion-col>
                            <ion-col size="6">
                              <ion-label id="lblIDUBusAddressByName" name="lblIDUBusAddressByName" ion-no-padding
                                class="ion-no-padding">
                                {{indoorUnit.bus_address }}
                              </ion-label>
                            </ion-col>
                          </ion-row>
                          <ion-row white-background>
                            <ion-col size="6"><strong>Location / Area:</strong></ion-col>
                            <ion-col class="textOverflow locationAreaTextOverlow" size="6">
                              <ion-label id="lblIDULocation" name="lblIDULocation" ion-no-padding
                                class="ion-no-padding">
                                {{indoorUnit.location }}
                              </ion-label>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </div>
                      <div class="idu-right">
                        <ion-col size="1" me-cursor-pointer>
                          <strong>
                            <div
                              *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditUnit)"
                              class="ion-icon-wrapper-raw-button" style="font-size: 22px" title="Edit Indoor Coil"
                              (click)="onEditUnit(indoorUnit)">
                              <ion-icon name="create"></ion-icon>
                            </div>
                          </strong>
                        </ion-col>
                      </div>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>