import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-site-analytics-test-run-mode-duration',
  templateUrl: './site-analytics-test-run-mode-duration.component.html',
  styleUrls: ['./site-analytics-test-run-mode-duration.component.scss'],
})
export class SiteAnalyticsTestRunModeDurationComponent  implements OnInit {
  @Input() title   // the title string
  @Input() labels   // array of labels 

  values=[]

  constructor(private modalController:ModalController) { }

  ngOnInit() {
    // filter the first element in the array - its the zero state
    this.values = this.labels.slice(1,this.labels.length)    
  }

  close() {
    this.modalController.dismiss()
  }

  setClickedSelection(value) {
    this.modalController.dismiss( {'selection': +value})
  }

}
