import { Component } from '@angular/core';
import { UserService } from '../../../common/services/user/user.service';
import { LoadingController, PopoverController, ModalController } from '@ionic/angular';
import { Log } from '../../../common/services/logging/log.service';
import { AccountComponent } from '../../account/components/account/account.page';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { Router } from '@angular/router';
import { SiteService } from '../../sites/services/site.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent {

  is_connected: boolean = true;

  constructor(
    public user: UserService, 
    public mainSiteUIService: MainSiteUIService,
    private loading: LoadingController, 
    private popoverCtrl: PopoverController,
    private router: Router,
    private siteService: SiteService
  ) { 
    this.is_connected = this.siteService.handleIsConnected();
  }
  
  async accountDetails() {
    this.popoverCtrl.dismiss();
    const popover = await this.popoverCtrl.create({
      component: AccountComponent,
      cssClass: 'large-popover'
    });
    return await popover.present();
  }

  async signOut() {
    this.mainSiteUIService.showLeftNav = true;
    this.popoverCtrl.dismiss();
    const load = await this.loading.create({
      spinner: 'lines'
    });
    load.present();
    await this.user.signOut().catch(err =>{
      this.user = null;
      Log.error('Logout error:', err)
    });
    load.dismiss();

    if(this.user)
      this.user = null;

    // clear the analytics messages
    sessionStorage.removeItem('gifDownloads')

    this.router.navigate(['/signin'])
  }

  async signOutNotConnected(){

    this.popoverCtrl.dismiss();
    this.user.set_signed_out_while_disconnected(true);
    this.user.reset();
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }
}
