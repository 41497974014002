import { Component } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { DataService } from 'src/app/common/services/data/DataService';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { CONNECTION_UPDATE_TIMER_INTERVAL } from 'src/app/constants/kenzaconstants';
import { LevelEnum, SiteNotificationTypeEnum, TemperaturePreferenceEnum } from 'src/app/enumerations/enums';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';

@Component({
    selector: 'app-site-weather',
    templateUrl: './site-weather.component.html',
    styleUrls: ['./site-weather.component.scss'],
})

export class SiteWeatherComponent {
    TemperaturePreferenceEnum = TemperaturePreferenceEnum;
    siteNotificationTypeEnum = SiteNotificationTypeEnum;
    connectionUpdateTimer: Observable<number> = null;
    showWeatherDash = false;
    levelEnum = LevelEnum;
    modalOpen = false;
    devEnv = devEnv;

    constructor(
        public user: UserService,
        public dataService: DataService,
        public siteService: SiteService,
        public appAuth: AppAuthenticationService,
        public mainSiteUIService: MainSiteUIService,
    ) {
        this.connectionUpdateTimer = interval(CONNECTION_UPDATE_TIMER_INTERVAL);
    }
    
    ionViewWillEnter() {
        // Empty
    }

    isWeatherObject(ob) {
        return typeof ob == `object`;
    }

    // Just in Dev
    weatherClick = (weatherClickEvent) => {
        if (!this.siteService.handleIsConnected()) return;
        // devEnv && console.log(`Dev Only weatherClickEvent`, this.siteService?.weatherData);
        this.showWeatherDash = !this.showWeatherDash;
    }

    ionViewWillLeave() {
        if (this.user.updateSiteWeatherInterval != null) {
            clearInterval(this.user.updateSiteWeatherInterval);
            this.user.clearUserSubscriptions();
        }
    }

    ngOnDestroy() {
        if (this.user.updateSiteWeatherInterval != null) {
            clearInterval(this.user.updateSiteWeatherInterval);
            this.user.clearUserSubscriptions();
        }
    }
}
