<ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
</ion-toolbar>
<ion-toolbar color="light">
  <ion-title color="dark">
    <h1>Member Details</h1>
  </ion-title>

  <ion-buttons slot="end">
    <ion-button (click)="backToManageMembers()" me-button-as-link id="btnBackToSiteMembers" name="btnBackToSiteMembers">
      &#8592;&nbsp;Back to Site Members </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-grid>
  <ion-row>
    <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
      <ion-card me-ion-card>
        <ion-card-content color="white" me-top-border class="ion-no-padding">
          <div>
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-justify-content-center">
                <ion-col size-xl="12" size-lg="12" size-md="6" size-sm="12" size="12">
                  <div>
                    <ion-grid style="width:550px">
                      <ion-row>
                        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                          <div>
                            <ion-list *ngIf="member_image_url.length==0">
                              <ion-item lines="none">
                                <img me-img-account-detail src="../../../assets/imgs/edit-md-imagesaspect.svg">
                              </ion-item>
                            </ion-list>

                            <img  *ngIf="member_image_url.length>0" me-img-account-detail src="{{member_image_url}}"  
                              id="uploadAccountPicture" name="uploadAccountPicture" color="primary">
                          </div>
                        </ion-col>
                        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                          <div me-member-since style="max-width: 52%">
                            <strong>Member Since</strong><br>{{member_since}}
                          </div>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col col col-6 me-center-ion-col-content>
                          <ion-label me-validation-error><i>
                              <!-- {{uploadPictureFileMessage}} -->
                            </i></ion-label>
                        </ion-col>

                        <ion-col col col-6 me-center-ion-col-content>
                        </ion-col>
                      </ion-row>

                    </ion-grid>
                  </div>
                </ion-col>

              </ion-row>
            </ion-grid>
          </div>
        </ion-card-content>
      </ion-card>

    </ion-col>
  </ion-row>

  <ion-row>

    <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12">
      <ion-card class="ion-no-margin" me-ion-card>
        <ion-item lines="none" me-account-detail-card-header>
          <ion-label class="ion-padding" style="padding-left:0;">
            <strong>Profile Information</strong>
          </ion-label>
        </ion-item>

        <ion-card-content me-ion-card-content me-top-border me-card-min-height>
          <ion-grid class="ion-no-padding">
            <ion-row white-background>
              <ion-col size="4"><strong>Name:</strong></ion-col>
              <ion-col>
                <ion-label>
                  {{member_account_detail &&
                  display_name(member_account_detail.fname,member_account_detail.lname,member_account_detail.email)}}
                </ion-label>
              </ion-col>
            </ion-row>
            <ion-row white-background>
              <ion-col size="4"><strong>Email:</strong></ion-col>
              <ion-col>
                <ion-label>{{member_account_detail ? member_account_detail.email : ''}}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row white-background>
              <ion-col size="4"><strong>Phone:</strong></ion-col>
              <ion-col>
                <ion-label>{{member_account_detail ? member_account_detail.phone : ''}}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row white-background>
              <ion-col size="4"><strong>Title:</strong></ion-col>
              <ion-col>
                <ion-label>{{member_account_detail ? member_account_detail.title : ''}}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="12" size="12">
      <ion-card class="ion-no-margin" me-ion-card>
        <ion-item lines="none" me-account-detail-card-header>
          <ion-label class="ion-padding" style="padding-left:0;">
            <strong>Company Information</strong>
          </ion-label>
        </ion-item>

        <ion-card-content me-ion-card-content me-top-border me-card-min-height>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col>
                <ion-grid class="ion-no-padding">
                  <ion-row white-background>
                    <ion-col size="4"><strong>Company:</strong></ion-col>
                    <ion-col>
                      <ion-label>{{member_account_detail ? member_account_detail.company : ''}}</ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background>
                    <ion-col size="4"><strong>Address:</strong></ion-col>
                    <ion-col>
                      <ion-label>{{member_account_detail ? member_account_detail.street_line1 : ''}}</ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background>
                    <ion-col size="4"></ion-col>
                    <ion-col>
                      <ion-label>{{member_account_detail ? member_account_detail.street_line2 : ''}}</ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row white-background>
                    <ion-col size="4"></ion-col>
                    <ion-col>
                      <ion-label>
                        {{member_account_detail ? member_account_detail.city : ''}}{{member_account_detail &&
                        member_account_detail.city
                        &&
                        member_account_detail.state? ',' : ''}}
                        {{member_account_detail ? ' ' + member_account_detail.state : ' '}}
                        {{member_account_detail ? member_account_detail.postal_code : ''}}</ion-label>
                    </ion-col>
                  </ion-row>

                  <ion-row white-background>
                    <ion-col size="4"><strong>Phone:</strong></ion-col>
                    <ion-col>
                      <ion-label>{{member_account_detail ? member_account_detail.company_phone : ''}}</ion-label>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-col>

  </ion-row>

</ion-grid>