import { Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { OutdoorUnitList, OutdoorUnit, TestType, OutdoorRmd } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { MaintBCPortNotificationComponent }
    from 'src/app/features/maintenance/modals/maint-bcport-notification/maint-bcport-notification.component';
import { OutdoorunitService } from '../../service/outdoorunit.service';

@Component({
    selector: 'maintenance-bcport-list',
    templateUrl: './maintenance-bcport-list.component.html',
    styleUrls: ['./maintenance-bcport-list.component.scss']
})

// MaintenanceRefrigerantListComponent inherits from MaintenanceBCPortListComponent
//    Confirm impact when changing

export class MaintenanceBCPortListComponent implements OnDestroy {
    outdoorUnitList: OutdoorUnitList = null;
    visible: boolean;
    selectUnit: number;
    selectUnitChecked: boolean[];
    selectUnitRmd: number;
    nextDisabledOc: boolean;
    nextDisabledRmd: boolean;
    displayOc: string;
    displayRmd: string;
    displayShow = 'block';
    displayHide = 'none';

    protected communicator: Subscription = null;

    public dataSourceOc: MatTableDataSource<OutdoorUnit> =
        new MatTableDataSource<OutdoorUnit>();
    public rowColumns: string[] = ["unitIndex", "systemName", "modelName", "Support"];

    public dataSourceRmd: MatTableDataSource<OutdoorRmd> =
        new MatTableDataSource<OutdoorRmd>();
    public displayedColumns: string[] = ['RadioButton', 'GatewayName', 'DeviceType', 'SerialNumber'];

    constructor(
        protected user: UserService,
        protected siteService: SiteService,
        protected router: Router,
        protected modalController: ModalController,
        protected outdoorunitService: OutdoorunitService
    ) {
        this.visible = false;
        this.selectUnit = 0;
        this.selectUnitRmd = 0;
        this.nextDisabledOc = true;
        this.nextDisabledRmd = true;
        this.displayOc = this.displayHide;
        this.displayRmd = this.displayShow;
    }

    ngOnDestroy() {
        this.cleaningUp();
    }

    ionViewWillEnter() {
        // The device configuration may have been updated, so recreate the list
        this.cleaningUp();

        // Reacquire the display data when the screen is displayed
        // Subscribing to unique events, since the screen side cannot detect changes in the attribute
        this.communicator = this.outdoorunitService.notificationEmitter.subscribe(msg => {
            // Redraw when you have finished retrieving data for display
            this.draw(msg);
        });

        // Communication is carried out with outdoorunitService
        this.outdoorunitService.getUnitList(TestType['bcPort']);
    }

    ionViewWillLeave() {
        console.info("ionViewWillLeave");
        this.cleaningUp();
    }

    hide() {
        if (this.communicator !== null) {
            this.communicator.unsubscribe();
            this.communicator = null;
        }
        // Another menu radio button is selected
        this.visible = false;
    }
    show() {
        this.visible = true;
    }

    draw(msg: string) {
        console.log("Component : " + msg);
        if (this.communicator !== null) {
            this.communicator.unsubscribe();
            this.communicator = null;
        }
        this.outdoorUnitList = this.outdoorunitService.outdoorUnitList;
        this.outdoorUnitList.createRmdList();
        if (this.outdoorUnitList.rmdList.length > 0) {
            // Activate the Next button of the target device
            this.nextDisabledOc = false;
            this.nextDisabledRmd = false;
            this.selectUnitRmd = this.outdoorUnitList.rmdList[0].unitIndex;
        } else {
            // Deactivate the Next button because there is nothing to check
            this.nextDisabledOc = true;
            this.nextDisabledRmd = true;
        }
        this.dataSourceRmd = new MatTableDataSource<OutdoorRmd>(this.outdoorUnitList.rmdList);
    }

    cleaningUp() {
        if (this.communicator !== null) {
            this.communicator.unsubscribe();
            this.communicator = null;
        }
        this.nextDisabledOc = true;
        this.outdoorUnitList = null;
        this.outdoorunitService.cleaningUp();
        this.selectUnit = 0;
        this.selectUnitChecked = [];
        this.selectUnitRmd = 0;
        this.displayOc = this.displayHide;
        this.displayRmd = this.displayShow;
        this.dataSourceOc = new MatTableDataSource<OutdoorUnit>();
        this.dataSourceRmd = new MatTableDataSource<OutdoorRmd>();
    }

    // Called only if  'this.outdoorUnitList.unitList > 0 '  is ture
    checkStart() {
        this.openNotification();
    }

    async openNotification() {
        if (!this.siteService.handleIsConnected()) return;

        const modal = await this.modalController.create({
            component: MaintBCPortNotificationComponent,
            cssClass: 'me-modal-bcport',
            backdropDismiss: false,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data.isCheckStart === true) {
            const selectUnitList = []
            // Start is selected, so start checking
            for (const data of this.dataSourceOc.data) {
                const selectUnitIndex = data.unitIndex;
                if (this.selectUnitChecked[selectUnitIndex] === true) {
                    selectUnitList.push(data);
                }
            }
            this.outdoorunitService.setSelectUnit(selectUnitList);
            this.router.navigate(['/maintenance', this.user.active.id, "BCPort"], {});
        }

        return;
    }

    rmdNextClick() {
        this.displayRmd = this.displayHide;
        this.displayOc = this.displayShow;

        const filtered = this.outdoorUnitList.filteredSelectUnit(this.outdoorUnitList.unitList[this.selectUnitRmd])
        //Looping through the array after filtering
        for (const unit of filtered) {
            //If supported
            if (unit.supported === true) {
                //check radio button
                this.selectUnitChecked[unit.unitIndex] = true;
                this.nextDisabledOc = false;
            } else {
                this.selectUnitChecked[unit.unitIndex] = false;
            }
        }
        this.dataSourceOc = new MatTableDataSource<OutdoorUnit>(filtered);
    }

    ocBackClick() {
        this.displayRmd = this.displayShow;
        this.displayOc = this.displayHide;
    }

    choose() {
        // Activate the Next button of the target device
        for (const data of this.dataSourceOc.data) {
            const selectUnitIndex = data.unitIndex;
            if (this.selectUnitChecked[selectUnitIndex] === true) {
                this.nextDisabledOc = !data.supported;
                return;
            }
        }
        this.nextDisabledOc = true;
    }

    // Debug function invoked by clicking A in the center of the screen
    // Click while pressing the button below
    // ctr
    /*
	    degug(event) {
	        if (event.ctrlKey === true) {
	            const unit = new OutdoorUnit();
	            const num = this.outdoorunitService.outdoorUnitList.unitList.length;
	            unit.unitIndex = num;
	            unit.unitAddress = '51';
	            unit.systemName = "dummy_systemName_" + num;
	            unit.modelName = "dummy_modelName_" + num;
	            unit.supported = true;
	            this.outdoorunitService.outdoorUnitList.unitList.push(unit);
	            this.draw("degug : add unit");
	        }
	    }
    */

}
