<form [formGroup]='ccForm' id="recurly-form">
  <ion-row *ngIf="!billingDisabled" class="row-name validationRow">
    <ion-col>
      <ion-input type="text" data-recurly="first_name" formControlName="first_name" required maxlength="50" labelPlacement="floating">
        <ion-text slot="label" color="secondary">First Name<sup validation-error>*</sup></ion-text>
      </ion-input>
      <div validation-error
        *ngIf="ccForm.controls.first_name.hasError('required') && ccForm.controls.first_name.touched">
        First Name is required
      </div>
      <div validation-error
        *ngIf="ccForm.controls.first_name.hasError('minlength') && ccForm.controls.first_name.dirty">
        First Name must be between 1 and 50 characters</div>
      <div validation-error
        *ngIf="ccForm.controls.first_name.hasError('pattern') && ccForm.controls.first_name.dirty">
        First Name must have at least 1 character</div>
      <div validation-error *ngIf="ccForm.controls.first_name.hasError('recurly') && ccForm.controls.first_name.dirty">
        {{ccForm.controls.first_name.errors.message}}
      </div>        
    </ion-col>
    <ion-col>
      <ion-input type="text" data-recurly="last_name" formControlName="last_name" required maxlength="50" labelPlacement="floating">
        <ion-text slot="label" color="secondary">Last Name<sup validation-error>*</sup></ion-text>
      </ion-input>
      <div validation-error *ngIf="ccForm.controls.last_name.hasError('required') && ccForm.controls.last_name.touched">
        Last Name is required
      </div>
      <div validation-error *ngIf="ccForm.controls.last_name.hasError('minlength') && ccForm.controls.last_name.dirty">
        Last Name must be between 1 and 50 characters</div>
      <div validation-error *ngIf="ccForm.controls.last_name.hasError('pattern') && ccForm.controls.last_name.dirty">
        Last Name must have at least 1 character</div>
      <div validation-error *ngIf="ccForm.controls.last_name.hasError('recurly') && ccForm.controls.last_name.dirty">
        {{ccForm.controls.last_name.errors.message}}
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!billingDisabled"  class="row-address validationRow">
    <ion-col>
      <ion-input type="text" data-recurly="address1" formControlName="address1" required maxlength="100" labelPlacement="floating">
        <ion-text slot="label" color="secondary">Address Line 1<sup validation-error>*</sup></ion-text>
      </ion-input>
      <div validation-error *ngIf="ccForm.controls.address1.hasError('required') && ccForm.controls.address1.touched">
        Address is required
      </div>
      <div validation-error *ngIf="(ccForm.controls.address1.hasError('minlength') ||
       ccForm.controls.address1.hasError('pattern')) && ccForm.controls.address1.dirty">
        Address must be between 3 and 100 characters</div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!billingDisabled"  class="validationRow">
    <ion-col>
      <ion-input type="text" data-recurly="address2" formControlName="address2" labelPlacement="floating">
        <ion-text slot="label" color="secondary">Address Line 2</ion-text>
      </ion-input>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!billingDisabled" class="row-locale validationRow">
    <ion-col>
      <ion-input type="text" data-recurly="city" formControlName="city" required maxlength="50" labelPlacement="floating">
        <ion-text slot="label" color="secondary">City<sup validation-error>*</sup></ion-text>
      </ion-input>
      <div validation-error *ngIf="ccForm.controls.city.hasError('required') && ccForm.controls.city.touched">
        City is required
      </div>
      <div validation-error *ngIf="(ccForm.controls.city.hasError('minlength') ||
       ccForm.controls.city.hasError('pattern')) && ccForm.controls.city.dirty">
        City must be between 3 and 50 characters</div>
    </ion-col>
    <ion-col>
      <ion-item>
        <ion-label me-state-class position="stacked" color="secondary">State<sup validation-error>*</sup>
        </ion-label>
        <select style="border: none;" formControlName="state_select" required>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
        </select>
      </ion-item>
      <div *ngIf="ccForm.controls.state_select.hasError('required') && ccForm.controls.state_select.touched"
        validation-error>
        State is required
      </div>
    </ion-col>
    <ion-col>
      <app-text-mask formControlName="postal_code" [control]="ccForm.controls.postal_code" [formGroup]="ccForm" recurly="postal_code" [moveTop]="false" [useMaskito]="useMaskito" />
      <div validation-error
        *ngIf="ccForm.controls.postal_code.hasError('required') && ccForm.controls.postal_code.touched">
        Zip Code is required
      </div>
      <div validation-error
        *ngIf="ccForm.controls.postal_code.hasError('pattern') && ccForm.controls.postal_code.dirty">
        Please enter a valid Zip Code<br>Example #####-####</div>
        <!-- <div validation-error>{{ ccForm.controls.postal_code?.errors | json }}</div> -->
    </ion-col>
  </ion-row>
  <ion-row class="row-cc-info validationRow" [hidden]="billingDisabled">
    <ion-col>
      <ion-item class="recurlyIonItemInput">
        <ion-label position="stacked" color="secondary">Credit Card Details<sup validation-error>*</sup></ion-label>
        <ion-input id="recurly-elements" tabindex="-100"></ion-input>
      </ion-item>
      <div *ngIf="ccFormFieldError" validation-error>{{ccFormFieldError}}</div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="billingDisabled">
    <ion-col style="text-align: center">
      <ion-label style="font-size: xx-large; color: red">Billing is Disabled.</ion-label>
    </ion-col>
  </ion-row>
  <input type="hidden" data-recurly="state" value="{{ccForm.controls.state_select.value}}" id="recurly-state">
  <input type="hidden" data-recurly="country" value="US">
  <input type="hidden" name="recurly-token" data-recurly="token">
</form>