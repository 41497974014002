<div me-ion-card class="groupSelectorCard ion-no-padding" *ngIf="!loading && siteGatewayUnitsExist">
 <div class="search_and_filter_container">
  <ion-toolbar class="siteControlToolbar">
    <ion-grid class="groupSelectorToolbarGrid">
      <ion-row class="groupSelectorTopRow groupSelectorTitleContainer">
        <ion-title class="bringLayerUp title">{{ title }}</ion-title>
        <div class="ion-icon-wrapper-card-header bringLayerUp" (click)="displayInformation()" title="Information">
          <ion-icon name="information-circle" color="black" me-icon-nav>
          </ion-icon>
        </div>
      </ion-row>
      <ion-row class="groupSelectorTopRow toolbarRight {{ checkBoxFilters ? 'checkBox' : 'button' }}">
        <ion-searchbar [hidden]="useSearchFilterComponent == true" #groupSearchBar (ionInput)="setFilteredList()" placeholder="Search..." class="me-search-bar">
        </ion-searchbar>
        <ion-grid id="buttonFilters" *ngIf="!checkBoxFilters" class="filterGridInToolbar customGrid filterGrid buttonFilters" #buttonFilters>
          <ion-row>
            <ion-col>
              <ion-button id="LossnayFilter" name="LossnayFilter" (click)="toggleLossnayFilter()"
                [attr.color]="mainSiteUIService.filterIncludeLossnay ? 'medium' : 'light-medium'" class="ion-no-padding me-filter-button"
                title="Show Only Lossnay Units">
                <ion-icon class="me-filter-ln-icon" src="/assets/imgs/lossnay-modes/Lossnay Control 3 NOFILL.svg">
                </ion-icon>
              </ion-button>
              <ion-button id="IDUFilter" name="IDUFilter" title="Show Only Indoor Coils"
                [attr.color]="mainSiteUIService.filterIncludeIDU ? 'medium' : 'light-medium'" (click)="toggleIDUFilter()"
                class="ion-no-padding me-filter-button">
                <ion-icon class="me-filter-idu-icon" src="/assets/imgs/Wall_Unit_filter.svg"></ion-icon>
              </ion-button>
              <ion-text color="medium" class="me-filter-count">
                <i>(showing {{ mainSiteUIService.filteredSiteGroups.length }} of {{ mainSiteUIService.allSiteGroups.length }} groups)</i>
              </ion-text>
              <ion-button *ngIf="showClearAll()" class="me-clear-filter" fill="clear" (click)="clearFilter()">Clear all</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid *ngIf="checkBoxFilters" class="filterGridInToolbar customGrid filterGrid checkBoxFilters">
          <ion-row class="{{ useSearchFilterComponent == true ? 'search_and_filter_outer_row' : '' }}">
            <ion-col class="{{ useSearchFilterComponent == true ? 'search_and_filter_outer_col' : '' }}">
              <ion-button *ngIf="useSearchFilterComponent == false" id="checkBoxFiltersButton" title="Filters" color="black" class="checkBoxFiltersButton checkBoxButton me-clear-filter" fill="clear"
                (click)="toggleFilterSelection($event)" #checkBoxFiltersButton>
                <ion-text class="filterButtonLabel filterTypeLabel">Filter <ion-badge *ngIf="setCheckedBoxes().length > 0" color="secondary">{{ setCheckedBoxes().length }}</ion-badge>
                </ion-text>
                <ion-icon class="checkBoxFilterIconStyle" name="filter"></ion-icon>
              </ion-button>
              <div class="innerFilterGridButtons">
                <ion-button *ngIf="user.activeSiteUserLevel > 1 && multiSelect != false && useSearchFilterComponent == false" id="checkBoxSelectAllButton" title="Select All" color="black" class="checkBoxSelectAllButton checkBoxButton me-clear-filter"
                  fill="clear" (click)="togglecheckBoxSelectAll($event)" #checkBoxSelectAllButton>
                  <ion-icon color="black" name="layers"></ion-icon>
                </ion-button>
                <ion-text *ngIf="width > 1050" color="medium" class="me-filter-count">
                  <i>(showing {{ mainSiteUIService.filteredSiteGroups.length }} of {{ mainSiteUIService.allSiteGroups.length }} groups)</i>
                </ion-text>
                <ion-button *ngIf="(groupSearchBar && groupSearchBar?.value != '') || setCheckedBoxes()?.length > 0" class="me-clear-filter" fill="clear" (click)="clearFilter()">Clear all</ion-button>
              </div>
              <app-search-and-filter *ngIf="useSearchFilterComponent == true" (searchFilterDataSignal)="onSearchFilterDataSignal($event)" itemClass="gsSearchAndFilter" searchClass="gsSearchBar" searchElementTag="groupSearchBar"></app-search-and-filter>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <div id="filterSelectionList" class="filterSelectionList {{ filterSelectionOpen ? 'open' : 'closed' }}" (window:resize)="resizeFiltersContainer($event)" [style.maxHeight.px]="windowHeight < 690 ? (windowHeight - 250 < 170 ? 170 : windowHeight - 250) : 450" #filterSelectionList>
    <ion-list class="checkBoxFilterContainer" [style.maxHeight.px]="windowHeight < 690 ? ((windowHeight - 250 < 170 ? 170 : windowHeight - 250) - 49) : 401">
      <ion-item class="itemWrapper" [lines]="last ? 'none' : 'full'"
        *ngFor="let gsFilter of mainSiteUIService.gsFilters; index as i; let first = first; let last = last;" title="{{ gsFilter.name }}" #itemWrapper>
        <div class="itemContent">
          <ion-text class="filterTypeLabel">{{ gsFilter?.name }}</ion-text>
          <ng-container *ngIf="mainSiteUIService.isArrayOfStrings(gsFilter?.filters)" title="Unit / Group Type / Location Filters || Filters are from Strings">
            <ion-item class="checkBoxContent {{ isActiveFilter({gsFilter, itemFilter}) ? 'active' : 'inactive' }} {{ itemFilter == '( None )' ? 'italics' : '' }}" [title]="itemFilter" lines="none" (click)="setActiveFilters({name: gsFilter.name, itemFilter}, $event)"
            *ngFor="let itemFilter of gsFilter.filters; index as idx; let first = firstItem; let last = lastItem;" #checkBoxContent>
              <ion-checkbox class="gsFilterCheckBox textOverflow" [value]="itemFilter" label-placement="end" justify="start" #gsFilterCheckBox>
                {{ mainSiteUIService.renderFilterName(itemFilter) }}
              </ion-checkbox>
            </ion-item>
          </ng-container>
          <ng-container *ngIf="!mainSiteUIService.isArrayOfStrings(gsFilter?.filters)" title="Gateway Filters || Filters are from Objects">
            <ion-item class="checkBoxContent {{ isActiveFilter(itemFilter?.id) ? 'active' : 'inactive' }}" [title]="itemFilter?.filter" *ngFor="let itemFilter of gsFilter.filters; index as idx; let first = firstItem; let last = lastItem;" lines="none" (click)="setActiveFilters(itemFilter, $event)" #checkBoxContent>
              <ion-checkbox class="gsFilterCheckBox gwCheckbox textOverflow" [value]="itemFilter?.id" label-placement="end" justify="start" #gsFilterCheckBox>
                {{ itemFilter.filter }}
              </ion-checkbox>
              <div *ngIf="devEnv && itemFilter?.category == GroupSelectorFilterTypes.GATEWAY" class="additionalFilterDetails">
                <span *ngIf="itemFilter?.connected == true" class="connectedGW">✔</span>
                {{ itemFilter?.subscription_features[SubscriptionFeatures.SYSTEM_SCHEDULING] == SubscriptionFeatures.INCLUDED ? 'Standard' : 'Limited' }}
                <ion-badge class="gatewayGroupsBadge">{{ itemFilter?.groups?.length }}</ion-badge> Groups
              </div>
            </ion-item>
          </ng-container>
        </div>
      </ion-item>
    </ion-list>
    <ion-list class="filterListFooter">
      <ion-item title="Check all" *ngIf="devEnv" [hidden]="mainSiteUIService.checkedBoxes.length == mainSiteUIService.totalFilters" (click)="checkAllCheckboxes($event)" lines="full" button="true">
        <ion-text class="filterTypeLabel" color="primary">Check all</ion-text>
      </ion-item>
      <ion-item title="Clear filters" (click)="clearAllCheckBoxFilters($event)" lines="full" button="true">
        <ion-text class="filterTypeLabel" color="primary">Clear filters</ion-text>
      </ion-item>
    </ion-list>
  </div>
 </div>
  <ion-button *ngIf="showLeftScrollArrow" class="me-scroll-bar-left" color="secondary" (click)="scrollBarMove(-1)"
      [class.me-scroll-bar-collapse]="collapseGroupSelector"  >
    <ion-icon name="play-back"></ion-icon>
  </ion-button>
  <ion-button *ngIf="showLeftScrollArrow" class="me-scroll-bar-left-end" color="secondary"
    (click)="scrollBarMoveEnd(-1)" [class.me-scroll-bar-collapse]="collapseGroupSelector">
    <ion-icon name="play-skip-back"></ion-icon>
  </ion-button>
  <ion-button *ngIf="showRightScrollArrow && siteGatewayUnitsExist" class="me-scroll-bar-right" color="secondary"
    (click)="scrollBarMove(+1)" [class.me-scroll-bar-collapse]="collapseGroupSelector">
    <ion-icon name="play-forward"></ion-icon>
  </ion-button>
  <ion-button *ngIf="showRightScrollArrow && siteGatewayUnitsExist" class="me-scroll-bar-right-end" color="secondary"
    (click)="scrollBarMoveEnd(+1)" [class.me-scroll-bar-collapse]="collapseGroupSelector">
    <ion-icon name="play-skip-forward"></ion-icon>
  </ion-button>

  <!-- #ScrollBar -->
  <div *ngIf="!loading && siteGatewayUnitsExist && mainSiteUIService.filteredSiteGroups.length==0" class="me-scroll-bar-zero">
    <ion-label color="medium" class="me-scroll-bar-zero-msg"><i>{{getZeroStateMessage()}}</i></ion-label>
  </div>
  <drag-scroll *ngIf="!loading && siteGatewayUnitsExist && mainSiteUIService.filteredSiteGroups.length > 0" scrollbar-hidden="true"
    class="ion-no-margin">
    <div drag-scroll-item
      *ngFor="let group of mainSiteUIService.filteredSiteGroups; trackBy: trackByGroupId; index as i; let first = first; let last = last;"
      (click)="selectGroup(group,i,$event)" [id]="group.group_id" class="ion-no-padding; ion-no-margin">
      <ion-card [id]="group.group_id" class="me-scroll-bar-card ion-no-padding" [ngClass]="{
          'collapsedCard': collapseGroupSelector,
          'me-scroll-bar-selected-card': group.selected && !group.errorSelected,
          'me-scroll-bar-unselected-card': !group.selected && !group.errorSelected,
          'me-scroll-bar-errorSelected-card': group.errorSelected
        }">
        <!-- card if group gateway is expired -->
        <ion-card-content [id]="group.group_id" class="ion-no-padding me-scroll-bar-subscription-expired me-scroll-bar-engine-off" 
          *ngIf="group.isGatewaySubscriptionExpired()">
          <ion-row>
            <ion-col class="me-scroll-bar-title expired-scroll-bar-title">
              <ion-label class="oflow" title="Group Name: {{ group.name }}" #searchParam>
                {{ group.name }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="me-scroll-bar-sub-title-row" [class.hide-row]="collapseGroupSelector">
            <ion-col class="me-scroll-bar-sub-title">
              <ion-label class="oflow" title="Gateway Name: {{ group.gateway.name }}" #searchParam>
                {{ group.gateway.name }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row [class.hide-row]="collapseGroupSelector">
            <ion-col *ngIf="group.isIndoorCoil()" class="me-scroll-bar-temp">
              <ion-text class="me-scroll-bar-mode" title="Gateway Subscription is expired">
                -- {{ tempSymbol }}
              </ion-text>
            </ion-col>
            <ion-col *ngIf="group.isLossnay()" class="me-scroll-bar-temp-lossnay-expired" title="Gateway Subscription is expired">
            </ion-col>
          </ion-row>
          <ion-row [class.hide-row]="collapseGroupSelector">
            <ion-col class="me-scroll-bar-status-expired">
              <ion-label title="Gateway Subscription is expired">
                Status: Expired
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-card-content>
        <!-- card if group gateway is not expired -->
        <ion-card-content [id]="group.group_id" class="ion-no-padding" *ngIf="!group.isGatewaySubscriptionExpired()"
          [ngClass]="{
            'me-scroll-bar-engine-on': group.state.drive_on == 'on',
            'me-scroll-bar-engine-off': group.state.drive_on != 'on'}">
          <ion-row>
            <ion-col class="me-scroll-bar-title">
              <ion-label class="oflow" title="Group Name: {{ group.name }}" #searchParam>
                {{ group.name }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="me-scroll-bar-sub-title-row" [class.hide-row]="collapseGroupSelector">
            <ion-col class="me-scroll-bar-sub-title">
              <ion-label class="oflow" title="Gateway Name: {{ group.gateway.name }}" #searchParam>
                {{ group.gateway.name }}
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row [class.hide-row]="collapseGroupSelector">
            <ion-col *ngIf="group.isIndoorCoil()" class="me-scroll-bar-temp">
              <ion-text class="me-scroll-bar-mode" title="{{ group.state.drive_on == 'on' ? 'Power: On' : 'Power: Off'}}">
                {{ group.state.inlet_temp }}{{ tempSymbol }}
              </ion-text>
              <div title="Mode: {{do.getDisplayMode(group.state.mode) | titlecase}}">
                <ion-icon class="me-scroll-bar-mode-2" name="{{ do.getModeIcon(group.state.mode) }}"
                  color="{{ do.getModeColor(group.state.mode) }}">
                </ion-icon>
              </div>
            </ion-col>
            <ion-col *ngIf="group.isLossnay()" class="me-scroll-bar-temp">
              <img class="me-scroll-bar-width-48" src="{{ do.getLossnayImageAddress(group.state.vent_mode) }}"
                title="Vent Mode: {{do.getDisplayMode(group.state.vent_mode) | titlecase}}" />
            </ion-col>
          </ion-row>
          <ion-row [class.hide-row]="collapseGroupSelector">
            <ion-col class="me-scroll-bar-fan">
              <div title="Fan Speed: {{ do.getFanSpeedDescription(group.gatewayUnits[0], null, group.state.fan_speed) | titlecase}}">
                <ion-icon class="ion-text-center me-scroll-bar-font-size-24 me-scroll-bar-icon-dark" icon-only
                  [src]="do.getFanSpeedPreviewIcon(group.gatewayUnits[0], group.state.fan_speed)">
                </ion-icon>
              </div>
              <div *ngIf="group.isIndoorCoil()">
                &nbsp;|&nbsp;
              </div>
              <div *ngIf="group.isIndoorCoil()"
                title="Air Direction: {{do.getAirDirectionDisplay(group.gatewayUnits[0], group.state.air_direction) | titlecase}}">
                <ion-icon class="me-scroll-bar-air-icon" [src]="do.getAirDirectionIcon(group.state.air_direction,group.gatewayUnits[0].air_direction_sw)"></ion-icon>
              </div>
            </ion-col>
          </ion-row>
          <ion-row [class.hide-row]="collapseGroupSelector">
            <ion-col class="me-scroll-bar-status">
              <ion-label *ngIf="group.state.statusIndex == siteControlGroupStatusEnum.OK"
                title="{{ group.state.statusTitle }}">
                Status: Ok</ion-label>
              <ion-label *ngIf="group.state.statusIndex != siteControlGroupStatusEnum.OK"
                title="{{ group.state.statusTitle }}">
                Status:&nbsp;
              </ion-label>
              <div *ngIf="group.state.statusIndex == siteControlGroupStatusEnum.Alert"
                title="{{ group.state.statusTitle }}">
                <ion-icon name="alert-circle-sharp" color="danger" class="me-scroll-bar-status-icon">
                </ion-icon>
              </div>
              <ion-label *ngIf="group.state.statusIndex == siteControlGroupStatusEnum.Maintenance_Job_Active"
              title="{{ group.state.statusTitle }}" class="me-maint-job-status">
                &nbsp;{{group.state.maintenance_job_type_label}}&nbsp;
              </ion-label>
              <!-- <div *ngIf="group.state.statusIndex==siteControlGroupStatusEnum.Warning" title="{{group.state.statusTitle}}">
                <ion-icon name="alert-circle-sharp" color="warning" class="me-scroll-bar-status-icon">
                </ion-icon>
              </div>-->
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </div>
  </drag-scroll>
  <div class="expandCollapseButton" (click)="expandCollapse($event)"><ion-icon name="{{ collapseGroupSelector == true ? 'chevron-down-outline' : 'chevron-up-outline' }}"></ion-icon></div>
</div>