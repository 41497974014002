import { Injectable } from '@angular/core';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { LevelEnum, PermissionDetailEnum } from 'src/app/enumerations/enums';

@Injectable({
  providedIn: 'root'
})
export class SiteAuthorizationService {

  constructor(
    private appAuth: AppAuthenticationService,

  ) { }


  permissionCheckCanRemoveSiteMember(activeSiteUserLevel: LevelEnum, siteMemberLevel: LevelEnum): boolean {
    let result: boolean = false;

    if (this.appAuth.doesLevelHavePermission(activeSiteUserLevel, this.appAuth.permissionEnums.AddRemoveMembers) == false) {
      return false;
    }

    if (activeSiteUserLevel > siteMemberLevel) {
      return true;
    }

    return result;
  }
}
