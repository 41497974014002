<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Select test run time</ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-item *ngFor="let TestRunTime of TimeList" (click)="setClickedSelection(TestRunTime)" lines="none" button>
      <ion-label>{{TestRunTime}}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>