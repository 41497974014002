// classes to track maintenance job results for all types.

export enum maintenanceJobStatusEnum {
    // as pulled from Server Code in maintenancejob.py
    new = 0,
    booting = 1,
    running = 2,
    complete = 10,
    cancel = 11,
    error = 12,
    ready = 100,
}

export const maintenanceJobStatusEnumMessage: { [type: number]: string } = {};
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.new] = 'Test Run(s) initalizing...';
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.booting] = 'Test Run(s) starting...';
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.running] = 'Test Run(s) in progress...';
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.complete] = 'Test Run(s) complete.';
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.cancel] = 'Test Run(s) canceled.';
maintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.error] = 'Unexpected error, try again later.';

export const genericMaintenanceJobStatusEnumMessage: { [type: number]: string } = {};
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.new] = `initalizing...`;
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.booting] = `starting...`;
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.running] = `in progress...`;
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.complete] = `ready.`;
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.cancel] = `canceled.`;
genericMaintenanceJobStatusEnumMessage[maintenanceJobStatusEnum.error] = `Unexpected error, try again later.`;

export class GenericMaintenanceJob {
    // details about a generic maintenance job from the server 
    id = '';
    job_id = '';
    gateway_id = '';
    site_id = '';
    result = 0;
    result_str = '';
    progress = 0;
    status = 0 ;
    status_str = '';
    created_at:Date = null;
    updated_at:Date = null;
    started_at:Date = null;
    ended_at:Date = null;

    constructor( jobDetails ) {
        // Deserializer incoming object
        Object.keys(jobDetails).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(this, key)) return;
            this[key] = jobDetails[key];
        });        
    }

    // status helpers
    isNewJobStatus():boolean { 
        return (this.status == maintenanceJobStatusEnum.new);
    }
    isBootingJobStatus():boolean {
        return (this.status == maintenanceJobStatusEnum.booting);
    }    
    isRunningJobStatus():boolean { 
        return (this.status == maintenanceJobStatusEnum.running);
    }
    isCompleteJobStatus():boolean {
        return (this.status == maintenanceJobStatusEnum.complete);
    }    
    isCancelJobStatus():boolean {
        return (this.status == maintenanceJobStatusEnum.cancel);
    }        
    isErrrorJobStatus():boolean {
        return (this.status == maintenanceJobStatusEnum.error);
    }

}

export class TestRunMaintenanceJob extends GenericMaintenanceJob {
    // include testrun parameters
    
    ope_mode = '';           // heating, cooling  (not auto)
    outside_temperature = 0; // OATemp at ODU
    testruntime = 0;         // number of minutes in job

    constructor( jobDetails) {
        // create parent
        super(jobDetails)

        // now try to merge the test_run_params
        Object.keys(jobDetails.test_run_params).forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(this, key)) return;
            this[key] = jobDetails.test_run_params[key];
        });        
    }

}