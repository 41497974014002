/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import { Logger } from 'src/app/common/services/logging/log.service'
import { UntypedFormControl } from '@angular/forms';
import { GatewayChangeSubscriptionStepEnum } from 'src/app/enumerations/enums';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { AccountService } from '../../services/accountService';
import { PaymentMethod } from '../../classes/PaymentMethod';
import { LoadingController } from '@ionic/angular';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { SiteGatewayPlansComponent } from 'src/app/features/sites/pages/site-gateway-plans/site-gateway-plans.component';
import { CreditCardFormComponent } from 'src/app/common/components/credit-card-form/credit-card-form.component';
import { GetAccountPaymentResult } from '../../classes/HttpResult';

@Component({
  selector: 'app-account-gateway-change-subscription-plan',
  templateUrl: './account-gateway-change-subscription-plan.page.html',
  styleUrls: [
    '../../../sites/pages/site-gateway-register/site-gateway-register.page.scss',
    './account-gateway-change-subscription-plan.page.scss'
  ],
})
export class AccountGatewayChangeSubscriptionPlanPage implements OnInit {
  @ViewChild(CreditCardFormComponent) ccForm: CreditCardFormComponent;
  gatewayChangeSubscriptionStepEnum: any;
  currentGatewayChangeSubscriptionStep = 0;
  currentStepUILayout: any = null;
  calculatedNewSubscriptionTotal: any = 0;
  allNewSubscriptionsSelected = false;
  enableNextButton = true;
  disablePayAndChangeButton = true;
  subscriptionsToChange;
  noPaymentNeeded = false;
  existingMethodChoice = 'unselected';
  useExistingPayment = new UntypedFormControl('');
  newPaymentValid = false;
  paymentMethods: PaymentMethod[] = [];
  monthlyTotal = 0;
  proratedTotal = 0;
  AddressError = "";

  // do all gateways have coupons?
  fullCoupons = false;
  // do some gateways on coupons?
  // if fullCoupon is true - partialCoupon will also be true
  partialCoupons = false;
  // price if coupons are not considered.
  unReducedCalcualtedNewSubscriptionTotal = 0;

  // prorate calculating?
  prorateCalc = false;

  // auto process the purchase after get get a new token
  autoProcessAfterToken = false;


  stepUILayouts = [
    {
      stepIndex: GatewayChangeSubscriptionStepEnum.InformationStep,
      stepTitle: 'Change Subscription Plan',
      showBackButton: false,
      showNextButton: true,
      nextButtonText: 'Next',
      cancelButtonText: 'Cancel',
    },
    {
      stepIndex: GatewayChangeSubscriptionStepEnum.GatewayStep,
      stepTitle: 'Change Subscription Plan (Step 1 of 4)',
      showBackButton: true,
      showNextButton: true,
      nextButtonText: 'Next',
      cancelButtonText: 'Cancel',
    },
    {
      stepIndex: GatewayChangeSubscriptionStepEnum.SubscriptionStep,
      stepTitle: 'Change Subscription Plan (Step 2 of 4)',
      showBackButton: true,
      showNextButton: true,
      nextButtonText: 'Next',
      cancelButtonText: 'Cancel',
    },
    {
      stepIndex: GatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep,
      stepTitle: 'Change Subscription Plan (Step 3 of 4)',
      showBackButton: true,
      showNextButton: false,
      nextButtonText: 'Change',
      cancelButtonText: 'Cancel',
    },
    {
      stepIndex: GatewayChangeSubscriptionStepEnum.ConfirmationStep,
      stepTitle: 'Change Subscription Plan (Step 4 of 4)',
      showBackButton: false,
      showNextButton: false,
      nextButtonText: 'Print',
      cancelButtonText: 'Close',
    },

  ]

  constructor(
    public loadingController: LoadingController,
    public navParams: NavParams,
    private accountService: AccountService,
    private alertController: AlertController,
    private logger: Logger,
    private modalController: ModalController,
    private siteService: SiteService,
    private planService: SubscriptionPlanService,
  ) {
    this.gatewayChangeSubscriptionStepEnum = GatewayChangeSubscriptionStepEnum;
  }

  async ngOnInit() {
    this.setSubscriptionsToUpdate();

    this.accountService.getAccountPaymentMethodsSub().subscribe((result: GetAccountPaymentResult) => {
      this.paymentMethods = (result.pmt_methods as PaymentMethod[] || []) 
        .sort((a, b) => (a.card_type + a.last_four).localeCompare(b.card_type + b.last_four))
        .filter(pm => pm.site_id === this.subscriptionsToChange[0].site_id);
      this.useExistingPayment.setValue(this.paymentMethods.length ? 'existing' : 'new');
    })

    this.handleNavigationUISetup(this.currentGatewayChangeSubscriptionStep);
  }

  setSubscriptionsToUpdate() {
    const devicesToUpdate = this.navParams.get('subscriptionPlans');
    this.fullCoupons = true;
    this.subscriptionsToChange = devicesToUpdate.map((device) => {
      if (device.coupons.length > 0) {
        this.partialCoupons = true;
      } else {
        this.fullCoupons = false;
      }
      return {
        model_id: device.model.id,
        model_class_name: device.model.class_name,
        model_name: device.model.name,
        serial_number: device.serial_number,
        mac_address: device.mac_address,
        device_key: device.device_key,
        registration_code: device.registration_code,
        gateway_id: device.id,
        gateway_name: device.name,
        has_coupon: (device.coupons.length > 0),
        coupon_name: (device.coupons[0]?.type?.name),
        subscription_plan_name: device?.activeSubscription?.subscriptions?.name,
        subscription_plan_id: device?.activeSubscription?.subscriptions?.id,
        new_subscription_plan_name: '',
        new_subscription_plan_id: '',
        site_name: device.site_name,
        site_id: device.site_id,
        // Set by plan picker
        kenza_plan_id: '',
        subscription_id: '',
        is_expired: device?.active_sub.is_expired,
      }
    });
  }

  async updateExistingPaymentMethodChoice(event) {
    this.monthlyTotal = this.proratedTotal = 0;
    this.newPaymentValid = false;

    this.existingMethodChoice = event.target.value;
    this.AddressError = ''

    if (this.existingMethodChoice != 'unselected') {
      await this.getChargePreview()
    }

    this.setEnableNextButton();
  }

  async updatePaymentMethodChoice(event) {
    this.monthlyTotal = this.proratedTotal = 0;
    this.newPaymentValid = false;

    if (this.useExistingPayment.value === 'new') {
      this.existingMethodChoice = 'unselected';

      //setTimeout(() => this.preparePaymentForm(), 50);
    }

    this.setEnableNextButton();
  }

  //  preparePaymentForm() { }

  updateSiteGatewaySubscription() {
    this.allNewSubscriptionsSelected = this.doAllGatewaysHavePlans();
    if (this.allNewSubscriptionsSelected) {
      this.calculateSubscriptionTotal();
      if (this.calculatedNewSubscriptionTotal == 0) {
        this.noPaymentNeeded = true;
      }
    } else {
      this.noPaymentNeeded = false;
    }
    this.setEnableNextButton();
  }

  calculateSubscriptionTotal() {
    this.calculatedNewSubscriptionTotal = this.unReducedCalcualtedNewSubscriptionTotal = 0
    this.subscriptionsToChange.forEach((device: any) => {
      const planCost = this.planService.plansKeyed[device.new_subscription_plan_id]?.monthly_rate || 0;
      this.unReducedCalcualtedNewSubscriptionTotal += planCost;
      if (!device.has_coupon) this.calculatedNewSubscriptionTotal += planCost;
    })
  }

  async setEnableNextButton() {
    switch (this.currentGatewayChangeSubscriptionStep) {
      case GatewayChangeSubscriptionStepEnum.InformationStep:
      case GatewayChangeSubscriptionStepEnum.GatewayStep:
        this.enableNextButton = true;
        break;
      case GatewayChangeSubscriptionStepEnum.SubscriptionStep:
        this.enableNextButton = this.allNewSubscriptionsSelected;
        break;
      case GatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep:
        if (this.noPaymentNeeded) {
          this.enableNextButton = true;
          this.disablePayAndChangeButton = true;
        } else if (this.useExistingPayment.value === 'existing') {
          if (this.existingMethodChoice !== 'unselected' && this.AddressError === '') {
            this.enableNextButton = true;
            this.disablePayAndChangeButton = false;
          } else if (this.existingMethodChoice === 'unselected') {
            this.enableNextButton = false;
            this.disablePayAndChangeButton = true;
          }
        } else if (this.useExistingPayment.value === 'new') {
          this.enableNextButton = this.newPaymentValid;
          this.disablePayAndChangeButton = true;
        }
        break;
      case GatewayChangeSubscriptionStepEnum.ConfirmationStep:
        break;
    }
  }

  async getChargePreview() {
    const params = {
      //token: token,
      site_id: this.subscriptionsToChange[0].site_id,
      devices: this.subscriptionsToChange,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    this.prorateCalc = true
    const preview = await this.siteService.getChargePreview(params);
    if ('error' in preview) {
      // then something went wrong.
      this.newPaymentValid = false;
      this.monthlyTotal = 0;
      this.proratedTotal = 0;
      //this.AddressError = "Please enter a valid Address/City/State/Zip Code"
      this.AddressError = preview.error
      if (preview.error.includes('..')) {
        this.AddressError = preview.error.substring(0, preview.error.search('\\.\\.')) + '.'
      }
    } else {
      this.newPaymentValid = true;
      this.monthlyTotal = preview.monthly;
      this.proratedTotal = preview.prorate;
      this.AddressError = ""
    }
    this.getParams = function () { return params; };
    this.prorateCalc = false;

  }
  /**
   * DO NOT REMOVE
   * @returns
   */
  getParams() {
    return {
      site_id: this.subscriptionsToChange[0].site_id,
      devices: this.subscriptionsToChange,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  }

  async newPaymentMethodValidation(event) {
    const params = {
      token: event.token,
      site_id: this.subscriptionsToChange[0].site_id,
      devices: this.subscriptionsToChange,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      customer_info: event.customerForm
    };

    this.getParams = function () { return params; };

    if (this.autoProcessAfterToken) {
      // proceed with payment.
      this.changeSubscriptionPlan(1)
      return
    }

    if (event.valid) {
      await this.getChargePreview();
    } else {
      this.newPaymentValid = false;
      this.monthlyTotal = 0;
      this.proratedTotal = 0;
      this.AddressError = ""
    }
    this.setEnableNextButton();

    this.getParams = function () { return params; };
  }

  clearAllGatewaySubscriptionPlans() {
    this.subscriptionsToChange.forEach(sp => {
      sp.new_subscription_plan_id = "";
      sp.new_subscription_plan_name = "";
      sp.subscription_id = "";
    });
  }

  doAllGatewaysHavePlans(): boolean {
    return !this.subscriptionsToChange.some(sp => sp.new_subscription_plan_id === "");
  }

  async cancelChangeSubscription() {
    if (this.currentGatewayChangeSubscriptionStep == GatewayChangeSubscriptionStepEnum.ConfirmationStep) {
      this.modalController.dismiss({ requiresUpdate: true });
      return;
    }

    const alert = await this.alertController.create({
      header: 'Cancel Subscription Plan Change',
      message: '<div class="me-redClass">Are you sure you want to cancel?</div></br><div class="me-centerText">If you cancel, your information will not be saved.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.clearAllGatewaySubscriptionPlans();
            this.logger.debug('Cancellation confirmed.');
            this.modalController.dismiss();
          }
        }, {
          text: 'No',
          cssClass: 'back-button',
          handler: () => {
            this.logger.debug('Cancellation cancelled.');
          }
        }
      ]
    });

    await alert.present();
  }

  async onComparePlans() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',
      backdropDismiss: false,
      componentProps: {
        title: "Change Subscription Plan",
        initialModelClassNameToDisplay: this.subscriptionsToChange[0].model_class_name
      }
    });
    return await modal.present();
  }

  onCloseSubscriptionPlans() {
    this.currentGatewayChangeSubscriptionStep = GatewayChangeSubscriptionStepEnum.SubscriptionStep;
    this.handleNavigationUISetup(this.currentGatewayChangeSubscriptionStep);
  }

  handleNavigationUISetup(stepIndex: number) {
    this.currentStepUILayout = this.stepUILayouts[stepIndex];

    switch (this.currentGatewayChangeSubscriptionStep) {
      case GatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep:
        this.monthlyTotal = this.proratedTotal = 0;
        this.newPaymentValid = this.noPaymentNeeded;
        this.getParams = function () {
          return {
                  site_id: this.subscriptionsToChange[0].site_id,
                  devices: this.subscriptionsToChange,
                  tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone};
        };

        if (!this.noPaymentNeeded) {
          this.currentStepUILayout.showNextButton = false;
        }
        break;
    }

    this.setEnableNextButton();
  }

  handleNext() {
    if (!this.siteService.handleIsConnected())
      return;

    if (this.currentGatewayChangeSubscriptionStep < this.stepUILayouts.length - 1) {
      this.currentGatewayChangeSubscriptionStep += 1;

      switch (this.currentGatewayChangeSubscriptionStep) {
        case GatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep:
          this.existingMethodChoice = 'unselected';
          this.useExistingPayment.setValue(this.paymentMethods.length ? 'existing' : 'new')

          if (this.useExistingPayment.value === 'new') {
            setTimeout(() => { /* do nothing */ }, 50); // there has to be a better way, but we have to wait for the DOM
          }
          break;
        case GatewayChangeSubscriptionStepEnum.SubscriptionStep:
          this.allNewSubscriptionsSelected = false;
          this.noPaymentNeeded = false;
          break;
      }
    }

    this.setEnableNextButton();
    this.handleNavigationUISetup(this.currentGatewayChangeSubscriptionStep);
  }

  handleBack() {
    if (!this.siteService.handleIsConnected())
      return;

      this.AddressError = '';

    if (this.currentGatewayChangeSubscriptionStep > 0) {
      this.currentGatewayChangeSubscriptionStep -= 1;
      this.handleNavigationUISetup(this.currentGatewayChangeSubscriptionStep);
    }

    if (this.currentGatewayChangeSubscriptionStep < this.gatewayChangeSubscriptionStepEnum.PaymentAndRegisterStep) {
      this.disablePayAndChangeButton = true;
    }

    switch (this.currentGatewayChangeSubscriptionStep) {
      case GatewayChangeSubscriptionStepEnum.GatewayStep:
      case GatewayChangeSubscriptionStepEnum.InformationStep:
        this.clearAllGatewaySubscriptionPlans();
        break;
    }
  }

  async changeSubscriptionPlan(stepsToMove: number) {
    if (!this.siteService.handleIsConnected())
      return;

    if (this.ccForm && this.ccForm.tokenIsDirty) {
      // then we need to request a new token.
      this.autoProcessAfterToken = true
      this.ccForm.submitTokenRequest(true)
      return
    }

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: `Updating gateway subscription${this.subscriptionsToChange.length === 1 ? '...' : 's...'}`
    });

    load.present();
    this.disablePayAndChangeButton = true;

    try {
      const response: any = await this.processSubscriptionUpdate();

      if (response.error) {
        this.AddressError = response.error;
        this.enableNextButton = false;
      } else {
        this.successfulNavigation(1);
         // this might have changed a credit card - request an update to the account page payment methods.
         this.accountService.paymentDetailsUpdated = true;        
      }
    } catch (err) {
      console.log('unsuccessful payment processing', err);
    }

    this.disablePayAndChangeButton = false;
    load.dismiss();
  }

  successfulNavigation(stepsToMove: number) {
    this.currentGatewayChangeSubscriptionStep = this.currentGatewayChangeSubscriptionStep + stepsToMove;
    this.logger.debug(this.currentGatewayChangeSubscriptionStep);
    this.handleNext();
  }

  async processSubscriptionUpdate() {
    return await this.siteService.updateDeviceSubscription(this.getParams());
  }
}
