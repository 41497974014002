import { Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/common/services/user/user.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TOAST_GENERAL_ERROR_TITLE, TOAST_MAINT_BCPORT_CHECK_ERR } from 'src/app/constants/kenzaconstants';

import { OutdoorUnit } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { OutdoorunitService } from '../../service/outdoorunit.service';
import { BCPortResultDataList, BCPortResultData, BCPortRecvData, notificationType } from './maintenance-bcport-result-data';
import { MaintenanceCommonRequestMultiple } from 'src/app/common/services/websockets/classes/requests/WebSocketRequest';


const CANCEL_PROGRESS = 'Canceled';
const ERROR_PROGRESS = 'Error';
const STATUS_CANCEL = 'Canceled BC port check.';
const STATUS_ERROR = 'An error has occurred.';
const TOAST_INFO = {
    positionClass: 'toast-top-full-width',
    disableTimeOut: true,
    timeOut: 0,
    tapToDismiss: true,
};

@Component({
    selector: 'maintenance-bcport-result',
    templateUrl: './maintenance-bcport-result.component.html',
    styleUrls: ['./maintenance-bcport-result.component.scss']
})

export class MaintenanceBCPortResultComponent implements OnDestroy {
    target: OutdoorUnit[];
    result: BCPortResultDataList;
    progress: string;
    statusStr: string;
    statusMassage: string;
    cancelDisabled: boolean;
    isShowResult: boolean;
    socketEmitterSub: Subscription;
    public dataSourceResult: MatTableDataSource<BCPortResultData> = new MatTableDataSource<BCPortResultData>();
    public rowColumns: string[] = ["ocAddress", "branchAddress", "group", "address", "branchPair", "note"];
    public dataSourceOc: MatTableDataSource<OutdoorUnit> = new MatTableDataSource<OutdoorUnit>();
    public displayedColumns: string[] = ["SystemName", "ModelName"];

    private intervalTimer;
    private timerSubscription: Subscription;
    readonly interval = 60000; // ms
    readonly updateInterval = 10000; // ms

    debugCount: number;

    constructor(
        private user: UserService,
        private socketService: SocketService,
        private router: Router,
        private toastService: ToastrService,
        private outdoorunitService: OutdoorunitService
    ) {
        this.target = null;
        this.result = new BCPortResultDataList(user);
        this.cancelDisabled = true;
        this.isShowResult = false;
        this.socketEmitterSub = null;
        this.timerSubscription = null;
        this.debugCount = 0;
        this.statusStr = 'Progress';
        this.statusMassage = null;
        this.dataSourceResult = new MatTableDataSource<BCPortResultData>([]);
        this.dataSourceOc = new MatTableDataSource<OutdoorUnit>([]);

    }

    ngOnDestroy() {
        this.cleaningUp();
    }

    ionViewWillEnter() {
        this.target = [];
        if (this.outdoorunitService.selectUnit === null || this.outdoorunitService.selectUnit.length === 0) {
            // Simultaneous execution on multiple pages is prohibited,
            // so Return to previous screen if reloaded or tab - copied
            this.toastService.error(TOAST_MAINT_BCPORT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
            this.backPage();
        } else {
            this.debugCount = 0;
            this.dataSourceResult = new MatTableDataSource<BCPortResultData>([]);
            this.target = this.outdoorunitService.selectUnit;
            this.dataSourceOc = new MatTableDataSource<OutdoorUnit>(this.target)
            this.progress = 'Before Start';

            if (this.socketEmitterSub === null) {
                this.socketEmitterSub = this.socketService.maintenanceEmitter.subscribe(payload => {
                    this.recvProgress(payload);
                });
            }
            this.sendRequest('bcport_start');
            this.intervalTimer = timer(this.interval);
            this.timerSubscription = this.intervalTimer.subscribe(() => {
                this.recvTimeOut();
            });
        }
    }

    // Notify the start/stop of BCPort check to Server
    sendRequest(command: string) {
            const request = new MaintenanceCommonRequestMultiple();
            request.setBaseIds(this.target[0].gatewayId, this.user.active.id);
            request.setData(this.target);
            console.info("send : " + command);
            this.socketService.sendCommonRequest(request, command);
    }

    ionViewWillLeave() {
        this.cleaningUp();
    }

    cleaningUp() {
        if (this.socketEmitterSub !== null) {
            this.socketEmitterSub.unsubscribe();
            this.socketEmitterSub = null;
        }
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.target = [];
        this.result = new BCPortResultDataList(this.user);
        this.cancelDisabled = true;
        this.isShowResult = false;
        this.dataSourceResult = new MatTableDataSource<BCPortResultData>([]);
        this.dataSourceOc = new MatTableDataSource<OutdoorUnit>([]);
    }

    backPage() {
        this.router.navigate(['/maintenance', this.user.active.id], {});
        this.cleaningUp();
    }

    checkCancel() {
        this.progress = CANCEL_PROGRESS;
        this.cancelDisabled = true;

        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }

        this.sendRequest('bcport_cancel');
        this.intervalTimer = timer(this.interval);
        this.timerSubscription = this.intervalTimer.subscribe(() => {
            this.recvTimeOut();
        });

    }

    // Receive check results (progress) from Server
    recvProgress(payload) {
        const bCPortRecvData = new BCPortRecvData(payload);

        if (!this.isShowResult) {
            if (bCPortRecvData["target"] !== undefined) {
                const _gatewaySerialNo = this.target[0].gatewaySerialNo;
                const _gateway_id = this.target[0].gatewayId;
                this.target = [];
                for (const index in bCPortRecvData.target) {
                    this.target.push(new OutdoorUnit());
                    this.target[index].gatewaySerialNo = _gatewaySerialNo;
                    this.target[index].gatewayId = _gateway_id;
                    this.target[index].setDBIds(bCPortRecvData.managementTableId);
                    this.target[index].unitAddress = String(bCPortRecvData.target[index].address);
                    this.target[index].systemName = bCPortRecvData.target[index].systemName;
                    this.target[index].modelName = bCPortRecvData.target[index].modelName;
                }
            }
            this.dataSourceOc = new MatTableDataSource<OutdoorUnit>(this.target)
        }

        if (bCPortRecvData.status == 'canceled') {
            // Even if the user selects cancel on another screen, switch to the canceling display
            this.progress = CANCEL_PROGRESS;
            this.cancelDisabled = true;
        } else if (bCPortRecvData.status === 'normal') {
            this.statusMassage = null;
        } else {
            this.statusMassage = STATUS_ERROR;
        }

        switch (bCPortRecvData.type) {
            case notificationType.FINISH:
                if (this.timerSubscription !== null) {
                    this.timerSubscription.unsubscribe();
                    this.timerSubscription = null;
                }

                this.redorwProgress(bCPortRecvData.progress);
                if (!this.cancelDisabled) {
                    this.statusStr = 'Complete';
                    this.showResult(bCPortRecvData);
                } else {
                    // I canceled the check halfway through
                    this.statusStr = 'Canceled';
                    this.statusMassage = STATUS_CANCEL;
                    if (this.socketEmitterSub !== null) {
                        this.socketEmitterSub.unsubscribe();
                        this.socketEmitterSub = null;
                    }
                }
                break;
            case notificationType.START:
                this.progressUpdate();
                break;
            case notificationType.SUSPEND:
                this.progressUpdate();
                break;
            case notificationType.NOTIFY:
                if (this.progress === 'Before Start') {
                    this.cancelDisabled = false;
                }
                this.redorwProgress(bCPortRecvData.progress);
                break;
            case notificationType.ERR_MFK:
            case notificationType.ERR:
                this.timerSubscription.unsubscribe();
                this.timerSubscription = null;
                this.statusStr = 'Error';
                this.progress = ERROR_PROGRESS;
                this.showResult(bCPortRecvData);
                this.toastService.error(TOAST_MAINT_BCPORT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
                break;
            case notificationType.ERR_START:
                // Returns to the previous screen when there is a communication error
                this.toastService.error(TOAST_MAINT_BCPORT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
                this.backPage();
                break;
            default:
                break;
        }

    }

    private redorwProgress(progress) {
        // The progress received during Cancel processing is not displayed
        if (!this.cancelDisabled) {
            this.progress = progress + '%';
        }
    }

    private progressUpdate() {
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.intervalTimer = timer(this.updateInterval, this.updateInterval);
        this.timerSubscription = this.intervalTimer.subscribe(() => {
            this.sendRequest('bcport_update');
        });
    }

    recvTimeOut() {
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.statusStr = 'Error';
        this.progress = ERROR_PROGRESS;
        this.toastService.error(TOAST_MAINT_BCPORT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
    }

    showResult(bCPortRecvData: BCPortRecvData) {
        if (this.socketEmitterSub !== null) {
            this.socketEmitterSub.unsubscribe();
            this.socketEmitterSub = null;
        }
        this.cancelDisabled = true;
        this.isShowResult = true;
        this.result.createlist(bCPortRecvData);
        this.dataSourceResult = new MatTableDataSource<BCPortResultData>(this.result.list);
    }

    // Debug function invoked by clicking A in the center of the screen
    // Click while pressing the button below
    // ctr + shift + alt
    // ctr + shift
    // ctr
    // alt
    /*
	    degug(event) {
	
	        if (event.ctrlKey === true && event.shiftKey === true && event.altKey === true) {
	            this.backPage();
	        } else if (event.ctrlKey === true && event.shiftKey === true) {
	            const testData = {
	                'type': 'reception_start',
	                'progress': 50,
	                'status': 'normal',
	                'target': [{
	                    'address': 51,
	                    'systemName': 'hogehoge',
	                    'modelName': 'umauma',
	                }, {
	                    'address': 52,
	                    'systemName': 'homehome',
	                    'modelName': 'usiusi',
	                }],
	                'result': {
	                    'ocAddress': 51,
	                    'okJudge': [
	                        { 'group': 1, 'icAddress': 1 },
	                        { 'group': 2, 'icAddress': 2 },
	                        { 'group': 3, 'icAddress': 3 },
	                        { 'group': 4, 'icAddress': 4 },
	                        { 'group': 5, 'icAddress': 5 },
	                        { 'group': 6, 'icAddress': 6 },
	                        { 'group': 7, 'icAddress': 7 },
	                        { 'group': 8, 'icAddress': 8 },
	                        { 'group': 9, 'icAddress': 9 },
	                        { 'group': 10, 'icAddress': 10 }
	
	                    ],
	                    'ngJudge': [
	                        { 'group': 11, 'icAddress': 11 },
	                        { 'group': 12, 'icAddress': 12 },
	                        { 'group': 13, 'icAddress': 13 },
	                        { 'group': 14, 'icAddress': 14 },
	                        { 'group': 15, 'icAddress': 15 },
	                        { 'group': 16, 'icAddress': 16 },
	                        { 'group': 17, 'icAddress': 17 },
	                        { 'group': 18, 'icAddress': 18 },
	                        { 'group': 19, 'icAddress': 19 },
	                        { 'group': 20, 'icAddress': 20 },
	                        { 'group': 21, 'icAddress': 21 },
	                        { 'group': 22, 'icAddress': 22 },
	                        { 'group': 23, 'icAddress': 23 },
	                        { 'group': 24, 'icAddress': 24 },
	                        { 'group': 25, 'icAddress': 25 },
	                        { 'group': 26, 'icAddress': 26 },
	                        { 'group': 27, 'icAddress': 27 },
	                        { 'group': 28, 'icAddress': 28 },
	                        { 'group': 29, 'icAddress': 29 }
	                    ],
	                    'cancelJudge': [
	                        { 'group': 30, 'icAddress': 30 },
	                        { 'group': 31, 'icAddress': 31 },
	                        { 'group': 32, 'icAddress': 32 },
	                        { 'group': 33, 'icAddress': 33 },
	                        { 'group': 34, 'icAddress': 34 },
	                        { 'group': 35, 'icAddress': 35 },
	                        { 'group': 36, 'icAddress': 36 },
	                        { 'group': 37, 'icAddress': 37 },
	                        { 'group': 38, 'icAddress': 38 },
	                        { 'group': 39, 'icAddress': 39 },
	                        { 'group': 40, 'icAddress': 40 },
	                        { 'group': 41, 'icAddress': 41 },
	                        { 'group': 42, 'icAddress': 42 },
	                        { 'group': 43, 'icAddress': 43 },
	                        { 'group': 44, 'icAddress': 44 },
	                        { 'group': 45, 'icAddress': 45 },
	                        { 'group': 46, 'icAddress': 46 },
	                        { 'group': 47, 'icAddress': 47 },
	                        { 'group': 48, 'icAddress': 48 },
	                        { 'group': 49, 'icAddress': 49 },
	                        { 'group': 50, 'icAddress': 50 }
	                    ]
                }
            };
            if (this.debugCount >= 100) {
                testData.type = 'finish';
            }
            testData.progress = this.debugCount;
            this.recvProgress({
                type: 0,
                responseData: testData
            });
            this.debugCount = this.debugCount + 10;
        } else if (event.ctrlKey === true) {
            console.log(this);
        } else if (event.altKey === true) {
            const testData = {
                'type': 'reception_suspended',
                'progress': 50,
                'status': 'canceled',
                'result': {
                    'ocAddress': 51,
                    'okJudge': [],
                    'ngJudge': [],
                    'cancelJudge': []
                }
            };
            if (this.debugCount > 10) {
                testData.type = 'notify';
            }
            if (this.debugCount >= 100) {
                testData.type = 'finish';
            }
            testData.progress = this.debugCount;
            this.recvProgress({
                type: 0,
                responseData: testData
            });
            this.debugCount = this.debugCount + 10;
        }
    }
    */

}
