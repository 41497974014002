
export const vertical_line_plugin = {
    id: 'vertical_line_plugin',
    afterEvent: function (chart, args, options) {
        let e = args.event;
        if ((e.type === 'mousemove') && (e.x >= e.chart.chartArea.left) && (e.x <= e.chart.chartArea.right)) {
            // store the x value of the event so it can be used to draw later
            options.x = e.x;
        }
    },
    afterDraw: function (chart, args, options) {
        let ctx = chart.ctx;
        let chartArea = chart.chartArea;
        let x = options.x;

        if (!isNaN(x)) {
            ctx.save();
            ctx.strokeStyle = options.line_color;
            ctx.lineWidth = options.line_width;
            ctx.beginPath();
            ctx.moveTo(x, chartArea.bottom);
            ctx.lineTo(x, chartArea.top);
            ctx.stroke();
            ctx.restore();
        }
    }
}