/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Logger } from 'src/app/common/services/logging/log.service'
import { SiteService } from '../../services/site.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { GatewayModelClass } from 'src/app/enumerations/enums';
import { globalFunctions } from 'src/app/constants/globalFunctions';

@Component({
  selector: 'app-site-gateway-edit',
  templateUrl: './site-gateway-edit.page.html',
  styleUrls: ['./site-gateway-edit.page.scss'],
})

export class SiteGatewayEditPage implements OnInit {
  @Input() parentSelectedGateway: Gateway;
  gatewayEditForm: UntypedFormGroup;
  selectedGateway: Gateway = null;
  updateResult = "";
  gatewayModelClass = GatewayModelClass;
  saveEnabled = false;
  detectFormChanges: (any) = globalFunctions.detectFormChanges;

  constructor(
    public modalController: ModalController,
    private logger: Logger,
    public formBuilder: UntypedFormBuilder,
    public user: UserService,
    public siteService: SiteService,
    public loadingController: LoadingController,
  ) {
    // Empty
  }

  ngOnInit() {
    this.selectedGateway = this.parentSelectedGateway;
    this.createGatewayEditForm();
    this.populateGatewayEditForm(this.selectedGateway);
  }

  createGatewayEditForm() {
    this.gatewayEditForm = this.formBuilder.group({
      gateway_name: ['', Validators.compose([
        Validators.required,
        Validators.required, Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
    });
  }

  populateGatewayEditForm(selectedGateway: Gateway) {
    const gatewayData = {
      gateway_name: selectedGateway.name,
    }
    this.gatewayEditForm.setValue(gatewayData);
    this.detectFormChanges(this.gatewayEditForm);
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  async onSave() {

    if (!this.siteService.handleIsConnected())
      return;

    const loading = await this.loadingController.create({
      message: 'Saving gateway information...',
      spinner: 'lines',
    });

    await loading.present();

    await this.siteService.updateGatewayName(this.user.id, this.selectedGateway.id, this.gatewayEditForm.value.gateway_name).toPromise().then(result => {
      this.selectedGateway.name = this.gatewayEditForm.value.gateway_name;

      this.modalController.dismiss({
        saved: true,
        savedGateway: this.selectedGateway,
      });
    }).catch(error => {
      // do nothing
    }).finally(() => {
      loading.dismiss();
    });
  }

  onClose() {
    this.modalController.dismiss({
      saved: false,
      savedGateway: null,
    });
  }

}
