import { Injectable } from '@angular/core';
import { RECURLY_API_KEY } from 'src/app/constants/webservice';
import { mockRecurlyJSClient } from './mockRecurlyClient.service';

declare const recurly;
@Injectable({
  providedIn: 'root'
})
export class RecurlyService {
  __mockRecurly = false;

  constructor() {
    if (RECURLY_API_KEY) {
      recurly.configure(RECURLY_API_KEY);
    } else {
      // using the mock service.
      this.__mockRecurly = true;
    }
  }

  isMocked() {
    // are we mocking responses?
    return this.__mockRecurly;
  }

  /**
   * Form elements for recurly. See documentation:
   * https://developers.recurly.com/reference/recurly-js/#getting-started
   */
  getElements() {
    if (this.isMocked()) {
      return mockRecurlyJSClient.mockElements();
    } else {
      return recurly.Elements();
    }
  }

  createToken(elements, doc, callback) {
    if (this.isMocked()) {
      return mockRecurlyJSClient.mockToken(elements, doc, callback)
    } else {
      return recurly.token(elements, doc, callback);
    }
  }
}
