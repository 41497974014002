<div *ngIf="visible">
    <ion-grid>
    	<!-- table area -->
            <ion-item lines="none">
                <ion-title class="ion-no-padding">
                    <h4 (click)="degug($event)">Select Equipment</h4>
                </ion-title>

                <div class="ion-icon-wrapper-card-header">
                    <ion-button class="button-style" (click)="rmdNextClick()" [disabled]="nextDisabledRmd" [style.display]="displayRmd">
                        Next
                    </ion-button>
                    <ion-button class="button-style" (click)="checkStart()" [disabled]="nextDisabledOc" [style.display]="displayOc">
                        Next
                    </ion-button>
                </div>

                <div class="ion-icon-wrapper-card-header">
                    <!--always inactive-->
                    <ion-button class="button-style" disabled [style.display]="displayRmd">
                        Back
                    </ion-button>
                    <ion-button class="button-style" (click)="ocBackClick()" [style.display]="displayOc">
                        Back
                    </ion-button>
                </div>

                <div class="ion-icon-wrapper-card-header" style="display: none;">
                    <ion-button class="button-style">
                        History
                    </ion-button>
                </div>
            </ion-item>
        <!--RMD List-->
        <ion-card class="ion-no-margin" [style.display]="displayRmd">
            <ion-card-content>
                <div>
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceRmd" class="mat-elevation-z8">
                            <ng-container matColumnDef="RadioButton">
                                <th mat-header-cell *matHeaderCellDef class="col-select">Select</th>
                                <td mat-cell *matCellDef="let element" class="col-select">
                                    <input type="radio" name="radiobutton_rmd" id="select_unit{{element.unitIndex}}"
                                        [(ngModel)]="selectUnitRmd" [value]="element.unitIndex"
                                        [checked]="selectUnitRmd == element.unitIndex">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="GatewayName">
                                <th mat-header-cell *matHeaderCellDef class="col-width">Gateway Name</th>
                                <td mat-cell *matCellDef="let element" class="col-width">{{ element.gatewayGatewayName
                                    }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="DeviceType">
                                <th mat-header-cell *matHeaderCellDef class="col-width">Device Type</th>
                                <td mat-cell *matCellDef="let element" class="col-width">{{ element.gatewayDeviceType }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="SerialNumber">
                                <th mat-header-cell *matHeaderCellDef class="col-width">Serial Number</th>
                                <td mat-cell *matCellDef="let element" class="col-width">{{ element.gatewaySerialNo }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </div>
                </div>
            </ion-card-content>
        </ion-card>
        <!--OC List-->
        <ion-card class="ion-no-margin" [style.display]="displayOc">
            <!-- table area -->
            <ion-card-content>
                <div>
                    <div class="table-container">
                        <table mat-table [dataSource]="dataSourceOc" class="mat-elevation-z8">
                            <ng-container matColumnDef="unitIndex">
                                <th mat-header-cell *matHeaderCellDef class="col-select">Select</th>
                                <td mat-cell *matCellDef="let element" class="col-select">
                                    <input type="radio" name="radiobutton_unit" id="select_unit{{element.unitIndex}}"
                                        [(ngModel)]="selectUnit" [value]="element.unitIndex"
                                        [checked]="selectUnit == element.unitIndex" [disabled]="!element.supported">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="systemName">
                                <th mat-header-cell *matHeaderCellDef class="col-width">SystemName</th>
                                <td mat-cell *matCellDef="let element" class="col-width">{{ element.systemName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="modelName">
                                <th mat-header-cell *matHeaderCellDef class="col-width">ModelName</th>
                                <td mat-cell *matCellDef="let element" class="col-width">{{ element.modelName }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Support">
                                <th mat-header-cell *matHeaderCellDef class="col-width">Support</th>
                                <td mat-cell *matCellDef="let element" class="col-width">
                                    <div *ngIf="element.supported" class="col-support">
                                        &#10004;<!-- Support -->
                                    </div>
                                    <div *ngIf="!element.supported" class="col-support">
                                        &#x2716;<!-- Not Support -->
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="rowColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: rowColumns"></tr>
                        </table>
                    </div>
                </div>
            </ion-card-content>
        </ion-card>
    </ion-grid>
</div>