<ion-list id="appNotifications" lines="full" class="ion-no-padding {{ notificationsLoading ? 'notificationsLoading' : 'notificationsLoaded' }}">
    <ion-item user-action>
        <strong class="blackText notifTitle" title="({{ mainSiteUIService?.getGlobalNotifications()?.length }}) Global Notification(s) + ({{ mainSiteUIService?.getSiteNotifications()?.length }}) Alert(s)">
            <ion-badge color="primary">{{ (mainSiteUIService.activeFilters.length > 0 || mainSiteUIService.activeSites.length > 0) ? mainSiteUIService.filteredNotifications.length : mainSiteUIService.totalNotifications.length }}</ion-badge> {{ (mainSiteUIService.activeFilters.length > 0 || mainSiteUIService.activeSites.length > 0) ? 'Filtered' : 'Total' }} Alert(s)
        </strong>
        <ion-button color="primary" *ngIf="mainSiteUIService.resettableNotifications.length > 0 && user.signedIn" (click)="dismissNotifications($event)" class="notificationActionsButton" id="notificationActionsButton" title="{{ mainSiteUIService.acknowledgeable.length }} Acknowledgeable + {{ mainSiteUIService.resettable.length }} Resettable {{ devEnv ? '+ ' + mainSiteUIService.viewable.length + ' Viewable' : '' }}" slot="end">
            {{ mainSiteUIService.acknowledgeable.length > 0 ? (mainSiteUIService.resettable.length > 0 ? 'Acknowledge /' : 'Acknowledge') : mainSiteUIService.acknowledgeable.length == 0 && mainSiteUIService.resettable.length == 0 ? 'Acknowledge' : '' }}
            {{ mainSiteUIService.resettable.length > 0 ? 'Reset' : '' }} 
            <!-- {{ mainSiteUIService.resettableNotifications.length == mainSiteUIService.totalResettableNotifications.length ? 'All' : 'Selected' }}  -->
            <ion-badge color="black">{{ mainSiteUIService.resettableNotifications.length }}</ion-badge>
        </ion-button>
    </ion-item>
    <ion-list *ngIf="devEnv && user.signedIn && siteNameFiltersOn && mainSiteUIService.totalNotifications.length > 0 && mainSiteUIService.siteNameFilters.length > 0" id="siteNameFilters" class="notificationFilters" user-action>
        <ion-item [id]="siteName?.site" class="notificationFilterItem"
            *ngFor="let siteName of mainSiteUIService.siteNameFilters; index as i; let first = first; let last = last;" lines="none"
            title="{{ siteName?.site }} ({{ siteName?.alerts }}) Alert(s)" button
            (click)="setSiteNameFilter($event)">
            <ion-label>{{ siteName?.site }}</ion-label>
            <ion-badge class="filterBadge" color="secondary">{{ siteName?.alerts }}</ion-badge>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="user.signedIn && mainSiteUIService.totalNotifications.length > 0" id="notificationFilters" class="notificationFilters {{ notificationsLoading ? 'notificationFiltersLoading' : 'notificationFiltersLoaded' }} {{ filteredNotificationTypes.includes('Payments') ? 'hasPaymentsFilter' : 'noPaymentsFilter' }}" user-action>
        <ion-item [id]="type" class="notificationFilterItem" *ngFor="let type of filteredNotificationTypes; index as i; let first = first; let last = last;" lines="none" [title]="type != 'Global' ? (type == 'Payments' ? 'Payment' : type) + ' ' + (type == 'Equipment' ? 'Alarm(s)' : 'Notification(s)') : 'kenza cloud Notification(s)'" button (click)="setNotificationFilter($event)">
            <img me-site-notification-image [class]="type" [src]="getTypeIcon(type)" />
            <ion-badge class="filterBadge siteNotifFilterBadge" color="secondary">{{ badgeNum(type) }}</ion-badge>
        </ion-item>
    </ion-list>
    <!-- <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)"> -->
        <!-- <ion-infinite-scroll-content> -->
            <ion-list #notificationsList class="notificationsList notifsContainer {{ mainSiteUIService.filteredNotifications.length == 0 ? 'noNotifs' : 'notifs' }} {{ (notificationsExpanded || ((windowHeight - 275) < 391)) ? 'expanded' : '' }}"
                (window:resize)="resizeNotificationsContainer($event)" [style.maxHeight.px]="windowHeight - 275">
                <!-- <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))"> -->
                <!-- <ion-item-sliding>
                    <ion-item-options side="start">
                        <ion-item-option color="success">Acknowledge</ion-item-option>
                    </ion-item-options> -->
                    <ion-item
                        *ngFor="let notification of mainSiteUIService.filteredNotifications; index as i; let first = first; let last = last; trackBy: notificationID;"
                        [button]="notification?.expandable" (click)="notification?.expandable ? log(notification) : null" lines="none"
                        user-action [class]="isRecent(notification)">
                        <div class="notificationContent">
                            <div class="notificationMessage">
                                <span class="notificationTypeIcon notifRow" [title]="getNotificationLabels(notification)">
                                    <img [class]="notification?.type" me-site-notification-image
                                        [src]="getTypeIcon((notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]), notification)" />
                                </span>
                                <div class="notificationText">
                                    <strong class="blackText typeLabel">{{ getNotificationLabels(notification) }}</strong>
                                    <span (click)="navigateFromLinkInsideNotification($event)" class="notificationContentMessage" [innerHTML]="renderNotificationMessage(notification?.notification) | safeHtml"></span>
                                    <ion-row class="notifMeta">
                                        <ion-col class="leftCol textOverflow">
                                            <!-- <div *ngIf="!notification?.site_id">(Beta) 5m ago</div> -->
                                            <a class="useBlue"
                                                *ngIf="!notification?.site_id && (notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]) != 'Global'"
                                                href="#">Read More</a>
                                            <a class="useBlue" *ngIf="notification?.site_id"
                                                [title]="notification?.site_name || getSiteName(user, notification?.site_id)"
                                                [routerLink]="['/site', notification?.site_id, 'dashboard']"
                                                (click)="navigateToSiteDashboard(user, notification?.site_id)">{{
                                                (notification?.site_name || getSiteName(user, notification?.site_id)) }}</a>
                                        </ion-col>
                                        <ion-col class="rightCol">{{ mainSiteUIService.timeFormat(notification?.created_at) }}</ion-col>
                                    </ion-row>
                                </div>
                                <div class="notifEnd">
                                    <ion-badge title="Notification Index" color="secondary" class="notificationIndex">{{ i + 1 }}</ion-badge>
                                    <!-- <ion-badge title="Notification Badge" *ngIf="!devEnv" color="secondary" class="notificationIndex adjustedBadge">
                                        <img [class]="notification?.type" me-site-notification-image
                                        src="../../../assets/imgs/logo-black.svg" />
                                    </ion-badge> -->
                                    <ion-button *ngIf="notification?.acknowledgeable"
                                        (click)="dismissNotification(notification, true)"
                                        [title]="(notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]) == 'Equipment' ? 'Acknowledge Alarm' : 'Acknowledge Notification'" color="white" shape="round">
                                        <ion-icon color="primary" name="checkmark-circle"></ion-icon>
                                    </ion-button>
                                    <ion-button *ngIf="notification?.resettable"
                                        (click)="resetEquipmentAlert(notification, true)"
                                        [title]="(notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]) == 'Equipment' ? 'Reset Alarm' : 'Reset Notification'" color="white" shape="round">
                                        <ion-icon color="primary" name="refresh-circle"></ion-icon>
                                    </ion-button>
                                    <ion-button *ngIf="notification?.viewable" 
                                        name="btnSiteNotificationDetail"
                                        (click)="navigateToSiteAlert($event, notification)"
                                        [title]="(notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]) == 'Equipment' ? 'View Alarm' : 'View Notification'" 
                                        [routerLink]="(notification?.type || mainSiteUIService.notifTypesFromEnumFull[notification?.sitenotificationtype_id]) == 'Equipment' ? ['/site', notification.site_id, 'alerts', 'equipment', notification.id] : ['/site', notification.site_id, 'alerts', 'notification', notification.id]" color="white" shape="round">
                                        <ion-icon color="primary" name="information-circle-outline"></ion-icon>
                                    </ion-button>
                                </div>
                            </div>
                        </div>
                        <!-- <ion-reorder slot="end"></ion-reorder> -->
                    </ion-item>
                    <!-- <ion-item-options side="end">
                        <ion-item-option>Favorite</ion-item-option>
                        <ion-item-option color="danger">Delete</ion-item-option>
                    </ion-item-options>
                </ion-item-sliding> -->
                <!-- </ion-reorder-group> -->
                <ion-item class="notificationsLoadMore" *ngIf="mainSiteUIService.filteredNotifications.length > 6 && mainSiteUIService.filteredNotifications.length != mainSiteUIService.totalNotifications.length && mainSiteUIService.activeFilters.length == 0 && mainSiteUIService.activeSites.length == 0">
                    <span (click)="loadSet(true)" class="useBlue mockLink">{{ scrolledBottom ? 'Loading more alerts...' : 'Get More Alerts...' }}</span>
                    <!-- {{ (notificationsLoadingSubject | async) === true ? 'Loading more notifications...' : 'Scroll to the bottom to load more notifications...' }} -->
                </ion-item>
            </ion-list>
        <!-- </ion-infinite-scroll-content> -->
    <!-- </ion-infinite-scroll> -->
    <!-- <ion-item class="showMoreNotifications" *ngIf="(notificationsLoadingSubject | async) === true">
        Loading More Notifications
    </ion-item> -->
    <ion-item class="showMoreNotifications" *ngIf="false && mainSiteUIService.filteredNotifications.length > 3 && windowHeight > 835">
        <a href="#" (click)="notificationFocus($event)" class="showMoreLink">
            <!-- <ion-icon [class]="notificationsExpanded ? 'up' : 'down'" name="chevron-down-outline"></ion-icon> -->
            {{ notificationsExpanded ? 'Show less ...' : 'Show more ...' }}
        </a>
    </ion-item>
    <!-- <ion-item *ngIf="mainSiteUIService.notifications.length == 0 && mainSiteUIService.filteredNotifications.length == 0" user-action lines="none">
        <strong><i>You have no alerts.</i></strong>
    </ion-item>
    <ion-item *ngIf="mainSiteUIService.notifications.length != 0 && mainSiteUIService.filteredNotifications.length == 0" user-action lines="none">
        <strong><i>You have no alerts for these filters.</i></strong>
    </ion-item> -->
    <ion-item *ngIf="mainSiteUIService.notifications.length == 0 && mainSiteUIService.filteredNotifications.length == 0" user-action lines="none">
        <strong><i>No active alerts.</i></strong>
    </ion-item>
    <ion-item *ngIf="mainSiteUIService.notifications.length != 0 && mainSiteUIService.filteredNotifications.length == 0" user-action lines="none">
        <strong><i>No alerts for these filters.</i></strong>
    </ion-item>
</ion-list>
<ng-template #notificationsLoadingState>Alerts Loading...</ng-template>