import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'select-time-modal',
  templateUrl: './select-time.page.html',
  styleUrls: ['./select-time.page.scss'],
})
export class SelectTestRunTime /*implements OnInit*/ {
  @Input() parentSelectedName: string;

  TimeList = TEST_RUN_TIME;

  constructor(private modalController: ModalController) { }

  // ngOnInit() {
  // }
  
  setClickedSelection(selectTime: any) {

    this.modalController.dismiss({
      parentSelectedName: selectTime,
    });
  }

  close() {
    this.modalController.dismiss({
      parentSelectedName: this.parentSelectedName,
    });
  }

}

const TEST_RUN_TIME = [
  '30 min',
  '60 min',
  '90 min',
  '120 min',
];
