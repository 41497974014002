import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';


@Component({
  selector: 'error-detail',
  templateUrl: './error-detail.component.html',
  styleUrls: ['./error-detail.component.scss'],
})
export class ErrorDetailComponent /*implements OnInit*/ {
  // @ViewChild('mySlider')  slides: IonSlides;
  
  activePage = 0
  didInit = false;

  constructor(private modalController: ModalController) {

  }

  // ngOnInit() {
  // }
  
  ngAfterViewInit() {
    this.didInit = true;
  }
  
  select_outdoor_unit() {

    this.modalController.dismiss({
    });

  }

  close() {
    this.modalController.dismiss({
      selected_outdoor_unit: null,
    });

  }

  // swipePrev(){
  //   this.slides.slidePrev();
  //   const aa = this.slides.getActiveIndex()
  //   .then(res => {
  //     this.activePage = res;
  //   });

  // }

  // swipeNext(){
  //   this.slides.slideNext();
  //   const aa = this.slides.getActiveIndex()
  //   .then(res => {
  //     this.activePage = res;
  //   });
  // }

}
