/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Logger } from 'src/app/common/services/logging/log.service';
import { Gateway } from '../classes/Gateway';
import { UserService } from 'src/app/common/services/user/user.service';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { interval, Observable, Subscription } from 'rxjs';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { SiteGatewayViewEnum, GatewayUnitTypeEnum, GatewayModelClass, GatewaySubscriptionStatusTypeEnum, LevelEnum } from 'src/app/enumerations/enums';
import { NavigationEnd, Router } from '@angular/router';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { GatewaySubscription } from 'src/app/features/manage/components/classes/GatewaySubscription';
import { GatewayUnit } from 'src/app/features/manage/components/classes/GatewayUnit';
import { SiteAuthorizationService } from 'src/app/features/sites/services/site-authorization.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SiteGatewayRegisterPage } from 'src/app/features/sites/pages/site-gateway-register/site-gateway-register.page';
import { SiteGatewayEditPage } from 'src/app/features/sites/pages/site-gateway-edit/site-gateway-edit.page';
import { filter, map } from 'rxjs/operators';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { UnregisteredDevice } from '../classes/UnregisteredDevice';
import { AccountService } from 'src/app/features/account/services/accountService';
import { CONNECTION_UPDATE_TIMER_INTERVAL, devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-site-gateways',
  templateUrl: './manage-gateways.component.html',
  styleUrls: ['./manage-gateways.component.scss'],
})

export class ManageGatewaysComponent implements OnInit, OnDestroy {
  currentSiteGatewayView: SiteGatewayViewEnum;
  onActiveSiteChangedSubscription: Subscription = null;
  siteGateways: Gateway[] = [];
  siteGatewayViewEnum = SiteGatewayViewEnum;
  selectedGateway: Gateway;
  navigationSubscription: Subscription = null;
  showMovedGateway = false;
  gateway_subscription: GatewaySubscription = null;
  paymentMethod: any = null;
  indoor_units: GatewayUnit[] = [];
  outdoor_units: GatewayUnit[] = [];
  gatewayUnitTypeEnum: GatewayUnitTypeEnum;
  siteGatewayChangedSubscription: Subscription = null;
  siteGatewayCreatedSubscription: Subscription = null;
  loadMain: any = null;
  onRouterEventSubscription: Subscription;
  connectionUpdateTimer: Observable<number>;
  timerSubscription: Subscription = null;
  gatewayModelClass = GatewayModelClass;

  dataLoading: boolean;
  devEnv = devEnv;

  gsste = GatewaySubscriptionStatusTypeEnum;

  LevelEnum = LevelEnum;

  constructor(
    public appAuth: AppAuthenticationService,
    public user: UserService,
    public modalController: ModalController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public socketService: SocketService,
    public siteAuthorizationSrvice: SiteAuthorizationService,
    private logger: Logger,
    private siteService: SiteService,
    private router: Router,
    private mainSiteUIService: MainSiteUIService,
    private accountService: AccountService,
  ) {
    this.mainSiteUIService.viewSiteAlert("fromManageGatewayDetail");
    this.siteGatewayViewEnum = SiteGatewayViewEnum;
  }

  ionViewWillEnter() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentSiteGatewayView = SiteGatewayViewEnum.GatewayListView;
      }
    });

    this.siteGatewayChangedSubscription = this.siteService.siteGatewayChanged.subscribe((changed_gateway: Gateway) => {
      if (changed_gateway) {
        this.siteGateways = this.siteGateways.map(gw => {
          if (gw.id == changed_gateway.id)
            gw = changed_gateway;
          return gw;
        })
      }
    });

    this.siteGatewayCreatedSubscription = this.siteService.siteGatewayCreated.subscribe((new_gateways: UnregisteredDevice[]) => {
      // process new gateways defined.

      // for now we just refresh everything.  This is overkill but the rest call to create the gateway does not
      // tell us the id of the created gateway - so we dont have a good way to obtain the Gateway Object for the new gateway
      // if we could - we could just append the new gateway(s) onto this.siteGatways... 
      this.refreshGatewayListByActiveSite(this.user.active.id);
    })

    // perform refresh post initialization
    this.onActiveSiteChangedSubscription = this.user.activeSiteChanged.subscribe(
      (activeSite: any) => {
        if (activeSite && activeSite.id) this.refreshGatewayListByActiveSite(activeSite.id);
        if (this.showMovedGateway == false) {
          this.currentSiteGatewayView = SiteGatewayViewEnum.GatewayListView;
        } else {
          this.currentSiteGatewayView = SiteGatewayViewEnum.GatewayDetailView;
          this.showMovedGateway = false;
        }
      }
    );

    this.onRouterEventSubscription = this.router.events.
      pipe(
        filter(event => event instanceof NavigationEnd)).
      subscribe((event: NavigationEnd) => {

        if ((event.url.indexOf('manage') > 0) && (event.url.indexOf('gateways') > 0)) {
          this.refreshGatewayListByActiveSite(this.user.active.id);
        }
      });
    
    if (this.timerSubscription == null) {
      this.timerSubscription = this.connectionUpdateTimer.subscribe((tick) => this.updateConnectionStatus())
    }

  }

  ionViewWillLeave() {
    // moving away - turn off the subscriptions
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
      this.navigationSubscription = null;
    }    

    if (this.siteGatewayChangedSubscription) {
      this.siteGatewayChangedSubscription.unsubscribe();
      this.siteGatewayChangedSubscription = null;
    }

    if (this.siteGatewayCreatedSubscription) {
      this.siteGatewayCreatedSubscription.unsubscribe();
      this.siteGatewayCreatedSubscription = null;
    }  
    
    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
      this.onActiveSiteChangedSubscription = null;
    }    
  }

  ngOnInit() {
    this.connectionUpdateTimer = interval(CONNECTION_UPDATE_TIMER_INTERVAL);    
    // initial load 
    this.refreshGatewayListByActiveSite(this.user.active.id);    
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.onRouterEventSubscription) {
      this.onRouterEventSubscription.unsubscribe();
    }

    if (this.siteGatewayChangedSubscription) {
      this.siteGatewayChangedSubscription.unsubscribe();
    }

    if (this.siteGatewayCreatedSubscription) {
      this.siteGatewayCreatedSubscription.unsubscribe();
    }

    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
    }

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  updateConnectionStatus(): void {
    // make a call to get the connection status of all gateways on this site - and then update 'em.
    if (this.siteService.isConnectedToInternet) {

      this.siteService.getSiteGatewayConnectionStatus(this.user.active.id).subscribe((res) => {
        if (res.length > 0) {
          res.forEach(gw => {
            // find this gw serial in our list of gateways
            this.siteGateways.forEach(swg => {
              if (swg.id == gw.gateway_id) {
                swg.connected = gw.connected.toLowerCase() == 'true';
              }
            })
          })
        }
      }, (error) => {
        // stop trying untill the next refresh
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
          this.timerSubscription = null;
        }
      });
    }
  }

  firstOfNextMonth() {
    const now = new Date();
    const next = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return next.toLocaleDateString('en', { month: '2-digit', day: '2-digit', year: 'numeric' });
  }

  async refreshGatewayListByActiveSite(activeSiteId: string) {
    this.dataLoading = true;
    this.siteService
      .getSiteGateways(activeSiteId, true)
      .pipe(
        map(siteGateways =>
          siteGateways.map((gateway: Gateway) => Gateway.newFromGateway(gateway))
        )
      )
      .subscribe((siteGateways: Gateway[]) => {
        this.prepareGateways(siteGateways);
        this.dataLoading = false;
      });
  }

  trackByGatewayId(index) {
    return this.siteGateways && this.siteGateways[index]?.id;
  }

  prepareGateways(siteGateways: Gateway[]) {
    const gwIds = new Set;
    const gwIdMap = {};

    if (this.siteGateways?.length) {
      this.siteGateways.forEach(gw => {
        gwIds.add(gw.id);
        gwIdMap[gw.id] = gw;
      });
    }

    this.siteGateways = siteGateways.map(gw => {
      if (gwIds.has(gw.id)) {
        gw.isExpanded = gwIdMap[gw.id].isExpanded;
      }
      gw.isSelected = false;

      return gw;
    });

    this.siteGateways.sort(compareValues('name'));

    devEnv && console.log(`Site Gateways`, this.siteGateways);
  }

  getIsGatewayExpanded(gateway: Gateway) {
    if ((gateway) && (gateway.isExpanded == true)) {
      return true;
    }
    return false;
  }

  toggleIsGatewayExpanded(gateway: Gateway) {
    gateway.isExpanded = !gateway.isExpanded;
  }

  async registerGateway() {

    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: SiteGatewayRegisterPage,
      backdropDismiss: false,
      cssClass: `siteGatewayRegisterFormModal`,
      id: `registerGatewayModal`,
    });

    modal.onDidDismiss().then((result) => {
      if (result?.data?.cancelled === false) {
        this.refreshGatewayListByActiveSite(this.user.active.id);
        this.user.setActiveSite(this.user.active, true);
        this.siteService.emit_siteGateChanged(null);
        this.accountService.paymentDetailsUpdated = true;
      }
    });

    return await modal.present();
  }

  getDefaultSubscription() {
    const defaultSubscription = {
      id: '',
      gateway_id: 0,
      subscription_id: 0,
      gateway_subscription_status_type_id: 0,
      created_at: null,
      created_acccount_id: '',
    };

    return defaultSubscription;
  }

  hasPermissionForGatewayDetails() {
    return this.appAuth.doesLevelHavePermission(
      this.user.activeSiteUserLevel,
      this.appAuth.permissionEnums.ViewGatewayDetails
    )
  }

  async onEditGateway(selectedGateway: Gateway) {

    if (!this.siteService.handleIsConnected()) return;

    this.logger.debug('onEditGateway=>selectedGateway:', selectedGateway);

    const modal = await this.modalController.create({
      component: SiteGatewayEditPage,
      cssClass: 'me-modal-gw-select',
      backdropDismiss: false,
      componentProps: {
        parentSelectedGateway: this.selectedGateway,
      },
    });

    modal.onDidDismiss().then(data => {
      if (((data.data !== undefined) && (data.data !== null)) && (data.data.saved)) {
        this.siteService.emit_siteGateChanged(null);

        if (data.data.moved) this.indoor_units.sort(compareValues('bus_address'));
      }
    });

    return await modal.present();
  }

  getStatusClass(connectionStatus: string) {
    let classValue = '';

    switch (connectionStatus.toLowerCase()) {
      case 'connected':
        classValue = 'me-connection-status-connected';
        break;

      case 'disconnected':
        classValue = 'me-connection-status-disconnected';
        break;
    }

    return classValue;
  }
}
