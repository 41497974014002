import { LoadingController } from '@ionic/angular';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import {
  TOAST_GENERAL_ERROR_TITLE,
  TOAST_SUCCESS_TITLE,
  MAPUNITS_WAIT_TIME,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_UNABLE_TO_TEST_RUN_MESSAGE,
  TOAST_UNABLE_TO_CANCEL_TEST_RUN_MESSAGE,
  TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE,
  MODAL_MAINT_UPDATE_UNIT_ERROR,
  MODAL_MAINT_UPDATE_UNIT_BLOCK,
  TOAST_FAILED_TO_DOWNLOAD_CSV
} from 'src/app/constants/kenzaconstants';
import {
  ToastMessageTypeEnum,
  WebSocketResponseTypeEnum,
  TemperaturePreferenceEnum,
  PressurePreferenceEnum
} from 'src/app/enumerations/enums';
import { Logger } from 'src/app/common/services/logging/log.service';
import moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';
import { Subscription, forkJoin } from 'rxjs';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { PressureConversions } from 'src/app/common/utilities/conversionUtilities';
import { TestDriveCancelNotificationComponent } from 'src/app/features/testdrive/modals/testdrive-cancel-notification/testdrive-cancel-notification.component';
import { ModalController } from '@ionic/angular';
import { ProgressService} from 'src/app/features/testdrive/services/progress.service'

@Component({
  selector: 'testdrive-progress',
  templateUrl: './testdrive-progress.component.html',
  styleUrls: ['./testdrive-progress.component.scss'],
})
export class TestDriveProgressComponent /*implements OnInit*/ {
  displayedColumns_2: string[] = ['RadioButton', 'SystemName', 'ModelName', 'SerialNo', 'OutsideTemperature']; // list of table items
  outdoorUnitIdList = []; //outdoorUnitId list
  dataSource_2 = []; //Outdoor unit (OC) data
  ouAddressList = []; //ouAdress List
  ouModelNameList = []; //ouModelName List
  ouSerialNoList = []; //ouSerialNumber List
  ouOutsideTempNumList = []; //OutsideTempNum List
  address: string; //ouAdress

  reqMaintenanceCount = 0;
  resMaintenanceCount = 0;
  
  testRunMode: string;   // Commissioning mode
  testRunTime: number;   // Commissioning time

  timeDif = new Date().getTimezoneOffset();                        // Time difference between Coordinated Universal Time and system time
  utcOffset = moment().tz(this.user.active.timezone).utcOffset();  // Time difference between UTC and property time
  nowTime = new Date();                                            // Current time @ Coordinated Universal Time

  gatewayId: string;    // ID of gateway (RMD)
  gatewaySerialNo: string; // Gateway (RMD) serial number
  maintenancejobId = '';  // For trial run polling (ID in maintenancejob table)

  displayedColumns: string[] = ['Key1'];   // Only the key names of the dataSource are extracted ('Key1', TimeData_0 to TimeData_XXX are included).
  timeHeader =  []; // Key names and item names (display names) for the time part (other than column 1)
  dataSource = [];      // The structure of the operating data (for screen display) is [{Key1: attribute (address) item name,Key2: item name for threshold judgmentTimeData_0:[value after conversion, unconverted value],TimeData_1:[value after conversion, unconverted value],...} ...]
  progress = 0;         // Trial run progress.0=not executed,1=MfK starting up,2=MfK starting up completed - trial run being executed,7=Trial run completed (normal),8=Trial run completed (cancelled),9=Trial run completed (error)
  progressPoint = 0;    // Trial run progress (% display)
  loadMain;             // For display of effects during loading
  socketEventSubscription: Subscription;    // For web socket communication
  socketRequestIdList = [];            // Request ID for socket communication
  timeouttime: number;   // Timer set time (time limit from start of trial run until response is returned). If this is exceeded, an error occurs.
  timeoutFlg = true;      // For timeout (error if 3 minutes elapses while true).
  intervalTime = 20000;   // Polling time
  interval;               // For polling
  subscribe_stop = false; // Flag to stop subscribe (true on completion)

  target: number;
  maintenanceDataList = [];

  isCancelButtonDisabled: boolean;

  // 以下、閾値設定
  // Threshold settings below
  ts63HS1 = 30;
  tsTe = -1;
  tsTH4 = 93;
  tsTH2a = 1.6;
  tsTH2b = 8.9;
  tsSH = 18;

  constructor(
    public _ref: ChangeDetectorRef,
    public _router: Router,
    private route: ActivatedRoute,
    private user: UserService,
    private siteService: SiteService,
    private socketService: SocketService,
    private loadingController: LoadingController,
    private toastService: ToastrService,
    private logger: Logger,
    private progressService: ProgressService,
    protected modalController: ModalController
  ) { 
    this.isCancelButtonDisabled = true;
  }
  
  // ngOnInit() {

  // }

  ionViewWillEnter() {
    // 画面初期表示時処理
    // Processing at initial screen display
    console.log('enter testrun page.');
    this._router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.timeHeader = [];
        this.displayedColumns = ['Key1'];
        this.displayedColumns_2 = ['RadioButton', 'SystemName', 'ModelName', 'SerialNo', 'OutsideTemperature'];
        this.dataSource = [];
        this.dataSource_2 = [];
        this.progress = 0;
        this.progressPoint = 0;
        this.timeoutFlg = true;
        this.subscribe_stop = false;
        this.outdoorUnitIdList = [];
        this.ouAddressList = [];
        this.ouModelNameList = [];
        this.ouSerialNoList = [];
        this.ouOutsideTempNumList = [];
        this.reqMaintenanceCount = 0;
        this.resMaintenanceCount = 0;
        this.isCancelButtonDisabled = true;
      }
    });
    // ソケットデータ受信
    // Socket data received
    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      for (let i = 0; i < this.socketRequestIdList.length; i++){
        if(socketResult.request_id === this.socketRequestIdList[i]){
          this.socketRequestIdList.splice(i,1);
          switch(socketResult.response_type){
            case WebSocketResponseTypeEnum.Maint_Job_Complete:
              this.receiveMaintData(socketResult);
              break;
            case WebSocketResponseTypeEnum.Test_Run_Complete:
              this.maintenancejobId = socketResult.response.id;
              this.timeoutFlg = false;
              break;
            case WebSocketResponseTypeEnum.Test_Run_Error:
            case WebSocketResponseTypeEnum.Maint_Job_Error:
              // 異常完了状態とする（メッセージ有）
              // Abnormal completion status (with message)
              this.siteService.presentToastMessage(
                ToastMessageTypeEnum.Error,
                TOAST_GENERAL_ERROR_TITLE,
                socketResult.response.error
              );
              this.errorCleaningUp();
              break;
            default:
              // 異常完了状態とする（不明なエラー）
              // Abnormal completion status (unknown error)
              this.siteService.presentToastMessage(
                ToastMessageTypeEnum.Error,
                TOAST_GENERAL_ERROR_TITLE,
                'Unknown Error.'
              );
              this.errorCleaningUp();
              break;
          }
        }
      }
    });

    // ローディング表示後、初期処理開始 Initial processing starts after loading display
    this.present_loadMain().then(() => {
      console.log('loading start.');
      this.ouOutsideTempNumList = [];   // 外気温度リセット Reset of outside air temperature

      // 現在時刻設定（UTC）
      // Current time setting (UTC)
      this.nowTime = new Date();
      this.nowTime.setMinutes(new Date().getMinutes() + this.timeDif);

      // OutdoorUnit情報、試運転設定値取得
      // DoorUnit information, acquisition of trial run set values
      this.route.queryParams.subscribe(params => {
        this.gatewayId = params.target;
        this.testRunMode = params.mode;
        this.testRunTime = Number(params.time);

        // タブ複製、リロード対応
        // Tab duplication and reload support
        if (Array.isArray(params.outdoorUnitIdList)){
          this.outdoorUnitIdList = params.outdoorUnitIdList;
        }
        else{
          this.outdoorUnitIdList.push(params.outdoorUnitIdList);
        }
        this.siteService.get_site_gateways_with_groups(this.user.active.id, true).subscribe(
          (gateways: GatewayWithGroups) => {
            this.setGateways(gateways);
          });
      });
    });
  }

  ionViewWillLeave() {
    // 別画面への遷移時処理
    // Processing at transition to another screen
    console.log('leave testrun page.');
    clearInterval(this.interval);   // タイマー処理停止 Stopping timer processing
    if(this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();   // ローディング非表示 Loading is not displayed.
    // subscribe停止
    // Stop subscribe
    if(this.socketEventSubscription){
      console.log('unsubscribe');
      this.socketEventSubscription.unsubscribe();
    }
  }

  setGateways(gateways: GatewayWithGroups) {
    // OutdoorUnitデータ取得
    // Acquire OutdoorUnit data.
    console.log('get outdoor unit data.');
    let noRMDflg = true;
    let gatewaysWithGroups: Gateway[] = [];

    const newDataSource = [];

    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True' && gwg.outdoor_units.length > 0){

          // RMDが一致しているか確認
          // Check if RMD matches
          if(gwg.id === this.gatewayId){
            for (let i = 0; i < gwg.outdoor_units.length; i++) {
              // 選択された室外機（OC）がRMDと紐づいているか確認
              // Check if the selected outdoor unit (OC) is associated with RMD
              const outdoorUnitId = this.outdoorUnitIdList.find((outdoorUnitId) => outdoorUnitId === gwg.outdoor_units[i].id)
              if(outdoorUnitId){
                const addData = {
                  Checked: false,
                  SystemName: gwg.outdoor_units[i].name,
                  ModelName: '',
                  SerialNo: '',
                  GatewayId: gwg.id,
                  Address: gwg.outdoor_units[i].bus_address,
                  OutdoorUnitId: gwg.outdoor_units[i].id,
                  OutsideTemperature: '',
                  OutsideTempNum:'',
                };
                newDataSource.push(addData);
              }
            }
            this.gatewaySerialNo = gwg.serial_number;
            noRMDflg =  false;
          }
        }
      });

      // 対象のRMDが存在しない場合エラー表示
      // Display error if target RMD does not exist
      if(noRMDflg){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          TOAST_CONNECTIVITY_ISSUE
        );
        this.errorCleaningUp();
        return;
      }

      if(newDataSource.length > 0){
        newDataSource[0].Checked = true;  // The first line of the radio button is checked by default.
      }

      // 排他確認に進む
      // Proceed to exclusion check.
      this.getRedisInfo();

      // restAPI経由でModelNameとSerialNo.を取得
      // Acquire ModelName and SerialNo. via restAPI.
      const modelSerialNoList = [];
      newDataSource.forEach((value) => {
        const response = this.siteService.getMachineDetail(this.gatewayId, value.Address)
        modelSerialNoList.push(response)
        response.subscribe(
          (resData: {modelName: string, serialNo:string}) => {
            // データが存在しない場合、ハイフン表示とする。
            // If data does not exist, hyphen is displayed.
            if(!resData.modelName){
              value.ModelName = '-';
            }else{
              value.ModelName = resData.modelName;
            }
            if(!resData.serialNo){
              value.SerialNo = '-';
            }else{
              value.SerialNo = resData.serialNo;
            }
          },
          () => {
            value.ModelName = '-';
            value.SerialNo = '-';
            this.errorCleaningUp();
          }
        )
      })
      
      // 外気温度取得を実施
      // Proceed to outside air temperature acquisition
      const oatList = [];
      newDataSource.forEach((value) => {
        const response = this.siteService.getOATemp(this.gatewaySerialNo, value.Address, this.user.active.id)
        oatList.push(response)
        response.subscribe(
          (resData: number) => {
            value.OutsideTempNum = Number(resData);
            // 摂氏華氏判定（華氏の場合、変換関数を使用して変換）
            // Celsius Fahrenheit judgement (in case of Fahrenheit, conversion using conversion function)
            if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Celsius) {
              value.OutsideTemperature = Number(resData).toFixed(1) + '°C';
            }else{
              value.OutsideTemperature = (Number(resData) * (9 / 5) + 32).toFixed(1) + '°F';
            }
            this._ref.detectChanges();
            return value;
          },
          () => {
            if(this.testRunMode !== 'Auto'){
              // 取得に失敗した場合でも、運転モード=Auto以外ならそのまま試運転開始
              // Even if acquisition fails, if the operation mode is other than Auto, the trial run starts as it is.
              value.OutsideTempNum = null;
              value.OutsideTemperature = '-';
              return value;
            }else{
              // 運転モード=Autoなら処理中断、エラートースト表示
              // If the operation mode = Auto, the process is interrupted and an error toast is displayed.
              this.siteService.presentToastMessage(
                ToastMessageTypeEnum.Error,
                TOAST_GENERAL_ERROR_TITLE,
                'Test run in AUTO mode has failed because the outside temperature cannot be retrieved. Please manually select cooling mode or heating mode.'
              );
              this.errorCleaningUp();
            }
          },
        );
      })

      // 処理が同期するように対応
      // Processing is now synchronous
      forkJoin(modelSerialNoList).subscribe(() => {
        this.dataSource_2 = newDataSource;
        forkJoin(oatList).subscribe(() => {
            this.dataSource_2 = newDataSource;
            this._ref.detectChanges();
            // 試運転実行
            // start of trial run
            this.testAPIcall();
        },
        () => {
            // 外気温取得に失敗した場合でも、運転モード=Auto以外ならそのまま試運転開始
            // Even if acquisition fails, if the operation mode is other than Auto, the trial run starts as it is.
            if(this.testRunMode !== 'Auto'){
              this.dataSource_2 = newDataSource;
              this._ref.detectChanges();
              // 試運転実行
              // start of trial run
              this.testAPIcall();
            }
        });
      });
    }
  }

  getRedisInfo(){
    // 試運転データ取得restAPI呼出
    // Call restAPI to acquire trial run data
    console.log('get redis info.');
    this.siteService.checkLock(this.gatewaySerialNo, this.user.active.id).subscribe(
      (resData: any) => {
        if (resData !== 0 && resData !== 2600){
          this.progress = 9;
          // 進捗状態を通知する
          // Notify me of progress
          this.progressService.noticeProgress(this.progress);

          if(this.socketEventSubscription){
            console.log('unsubscribe');
            this.socketEventSubscription.unsubscribe();
          }
          if(this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
          this._ref.detectChanges();
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            MODAL_MAINT_UPDATE_UNIT_ERROR
          );
        }else{
          // 試運転履歴データ取得
          // Acquisition of test run history data
          this.siteService.getUnitHistoryTop(this.gatewayId).subscribe(
            (resData2: any) => {
              if (!resData2){
                this.progress = 9;

                // 進捗状態を通知する
                // Notify me of progress
                this.progressService.noticeProgress(this.progress);

                if(this.socketEventSubscription){
                  console.log('unsubscribe');
                  this.socketEventSubscription.unsubscribe();
                }
                if(this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
                this._ref.detectChanges();
                this.siteService.presentToastMessage(
                  ToastMessageTypeEnum.Error,
                  TOAST_GENERAL_ERROR_TITLE,
                  MODAL_MAINT_UPDATE_UNIT_BLOCK
                );
              }
            },
            (err) => {
              console.log('getUnitHistoryTop=>err', err);
              this.errorCleaningUp();
            }
          );
        }
      },
      (err) => {
        this.errorCleaningUp();
        console.log('getRedisInfo=>err', err);
      }
    );
  }

  startTimer() {
    console.log('start interval.');
    this.interval = setInterval(() => {

      if(this.timeoutFlg){
        // タイムアウト（試運転開始後3分以上応答が無い場合、異常終了とする）
        // Timeout (If no response is received for 3 minutes or more after the start of trial run, it is assumed to be an abnormal end).
        this.timeouttime = this.timeouttime - this.intervalTime;
        if(this.timeouttime <= 0){
          // アラート表示・試運転終了
          // Alert display, end of trial run
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            'No response from the device. Abort processing.'
          );

          // エラー後処理
          // Error post-processing
          this.errorCleaningUp();
        }

      }else{
        // ポーリング処理
        // Polling processing
        this.siteService.getMaintenancejobProgress(this.maintenancejobId, this.user.active.id).subscribe(
          (resData: any) => {

            // cancel ボタンを活性にする
            // Activate the cancel button
            this.isCancelButtonDisabled = false;
            
            // ローディング解除
            // Loading release
            if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
            this._ref.detectChanges();

            // 正常応答(result=0)の場合、statusで処理分岐
            // In case of normal response (result=0), process branching by status
            if(Number(resData.result) === 0){

              switch (resData.status) {
                // 試運転開始直後
                // Immediately after start of trial run
                case 'new':
                  // 何もしない
                  // Nothing is done.
                  break;
                // MfK起動開始要求受付完了
                // MfK start-up request acceptance complete
                case 'booting':
                  if(this.progress !== 1){
                    this.progress = 1;
                    this.siteService.presentToastMessage(
                      ToastMessageTypeEnum.Success,
                      TOAST_SUCCESS_TITLE,
                      'Successfully requested to start TestRun.'
                    );
                  }
                  break;
                // 試運転実行中（データ取得REST API呼び出し）
                // Commissioning in progress (data acquisition RESTAPI call)
                case 'running':
                  this.progress = 2;
                  if(this.progressPoint !== resData.progress){
                    this.progressPoint = resData.progress;
                    if(Number(resData.progress) > 0){
                      this.getMaintData(resData.started_at, resData.ended_at);
                    }
                  }
                  break;
                // 試運転完了（progress = 7）
                // Commissioning complete (progress=7)
                case 'complete':
                  this.progress = 7;

                  // 進捗状態を通知する
                  // Notify me of progress
                  this.progressService.noticeProgress(this.progress);
                  
                  this.getMaintData(resData.started_at, resData.ended_at);
                  this.siteService.presentToastMessage(
                    ToastMessageTypeEnum.Success,
                    TOAST_SUCCESS_TITLE,
                    'TestRun completed.'
                  );
                  this.subscribe_stop = true;
                  clearInterval(this.interval);
                  break;
                // その他（progress = 9）※エラー
                // Other (progress=9) *Error
                default:
                  this.siteService.presentToastMessage(
                    ToastMessageTypeEnum.Error,
                    TOAST_GENERAL_ERROR_TITLE,
                    'Unknown Error.'
                  );
                  this.errorCleaningUp();
                  break;
              }
            }

            // キャンセル応答(result = 11)の場合、キャンセル処理
            // In case of a cancellation response (result=11), cancellation processing
            else if(Number(resData.result) === 11){
              this.progress = 8;

              // 進捗状態を通知する
              // Notify me of progress
              this.progressService.noticeProgress(this.progress);

              if(this.progressPoint > 0){
                this.subscribe_stop = true;
                this.getMaintData(resData.started_at, resData.ended_at);
              }else{
                if(this.socketEventSubscription){
                  console.log('unsubscribe');
                  this.socketEventSubscription.unsubscribe();
                }
              }
              this.siteService.presentToastMessage(
                ToastMessageTypeEnum.Success,
                TOAST_SUCCESS_TITLE,
                'TestRun canceled.'
              );
              clearInterval(this.interval);
            }

            // その他応答（エラー）の場合、result値ごとにメッセージを設定し終了
            // In case of other response (error), a message is set for each result value and the process ends.
            else{
              let toastMessage = '';
              switch(resData.result){
                case 1:
                  toastMessage = 'Task NG.';
                  break;
                case 3:
                  toastMessage = 'Invalid parameters.';
                  break;
                case 10:
                  toastMessage = 'Command Not Found.';
                  break;
                case 13:
                  toastMessage = 'MfK is Not Started.';
                  break;
                default:
                  toastMessage = 'Unknown Error.';
                  break;
              }
              this.siteService.presentToastMessage(
                ToastMessageTypeEnum.Error,
                TOAST_GENERAL_ERROR_TITLE,
                toastMessage
              );
              this.errorCleaningUp();
            }
          },
          (err) => {
            this.errorCleaningUp();
            console.log('getRedisInfo=>err', err);            
          }
        );
      }

    },this.intervalTime);
  }

  cancelTestRun() {
    // cancelボタン押下処理（試運転キャンセル）
    // Processing by pressing the CANCEL button (cancellation of trial run)
    this.openNotification();
  }

  async openNotification() {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
        component: TestDriveCancelNotificationComponent,
        cssClass: 'me-modal-bcport',
        backdropDismiss: false,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();

    if (data.isCheckStart === true) {
      // 試運転開始前の中断は不可
      // Interruption before the start of the trial run is not possible.
      if(this.progress === 0){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          'Interruption before starting a test run is not allowed.'
        );
        return;
      }

      if (!this.siteService.handleIsConnected()) return;

      if (!this.socketService.is_connected()) {
        this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
        console.log('The socket service is not connected');
        return;
      }

      const socketReqId = uuid()
      this.socketRequestIdList.push(socketReqId);

      const obj = {
        gateway_id: this.gatewayId,
        site_id: this.user.active.id,
        request_id: socketReqId
      }
      if (this.socketService.cancelTestRun(obj)) {
        this.present_loadMain();
      }else{
        this.toastService.error(TOAST_UNABLE_TO_CANCEL_TEST_RUN_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
      }
    }

    return;
}

  backToTestRunTop() {
    // Backボタン押下処理（試運転機器一覧画面に遷移する）
    // Back button is pressed (to the list of commissioning devices screen).
    if (!this.siteService.handleIsConnected()){
      return;
    }
    this._router.navigate(['/testdrive', this.user.active.id, 'list']);
  }

  async present_loadMain() {
    // ローディング処理
    // Loading process
    this.loadMain = await this.loadingController.create({
      message: 'loading...',
      spinner: 'lines',
      duration: MAPUNITS_WAIT_TIME,
    });

    await this.loadMain.present();
  }

  testAPIcall() {
    console.log('call test run API.');
    // 試運転開始要求処理
    // Processing of trial run start request

    if (!this.siteService.handleIsConnected()){
      this.errorCleaningUp();
      console.log('not handleIsConnected in testAPIcall');
      return;
    }

    if (!this.socketService.is_connected() || !this.socketService.judge_authenticated()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      this.errorCleaningUp();
      console.log('The socket service is not connected');
      return;
    }

    // 試運転開始時に送信するパラメータを作成する
    // Create parameters to be sent at the start of trial run

    // リクエストする変数を初期化する
    // Initialize the requested variables
    this.ouAddressList = [];
    this.ouModelNameList = [];
    this.ouSerialNoList = [];
    this.ouOutsideTempNumList = [];

    for (let i = 0; i < this.dataSource_2.length; i++){
      this.ouAddressList.push(this.dataSource_2[i].Address)
      this.ouModelNameList.push(this.dataSource_2[i].ModelName)
      this.ouSerialNoList.push(this.dataSource_2[i].SerialNo)
      this.ouOutsideTempNumList.push(this.dataSource_2[i].OutsideTempNum)
    }

    // 運転モードがAutoの場合、室外機外気温度から冷房or暖房を判定
    // When the operation mode is Auto, cooling or heating is determined from the outdoor air temperature of the outdoor unit.
    if(this.testRunMode === 'Auto'){
      // 運転モードをheatingに設定
      // Set driving mode to heating
      this.testRunMode = 'heating';

      // 外気温度が15度以上の場合、運転モードをcoolingに設定を変更する
      // If the outside temperature is 15 degrees or higher, change the operating mode to cooling.
      for(let i = 0; i <= this.ouOutsideTempNumList.length; i++) {
        if(this.ouOutsideTempNumList[i] >= 15) {
          this.testRunMode = 'cooling';
          break;
        }
      }
    // 先頭大文字→小文字へ変換（引数の規定に合わせる）
    // Conversion from first capital letter to lower case (to conform to the argument rules).
    }else{
      this.testRunMode = this.testRunMode.toLowerCase();
    }

    const socketReqId = uuid()
    this.socketRequestIdList.push(socketReqId);

    const obj = {
      gateway_id: this.gatewayId,
      site_id: this.user.active.id,
      request_id: socketReqId,
      mode: this.testRunMode,
      loop_number: this.testRunTime,
      rmd_serial_no: this.gatewaySerialNo,
      ou_address_list: this.ouAddressList,
      ou_model_name_list: this.ouModelNameList,
      ou_serial_no_list: this.ouSerialNoList,
      outside_temperature_list: this.ouOutsideTempNumList
    }
    if (this.socketService.testRun(obj)) {
      // ポーリング処理開始
      // Polling processing starts.
      this.timeouttime = 180000;
      clearInterval(this.interval);
      this.startTimer();
    }else{
      this.toastService.error(TOAST_UNABLE_TO_TEST_RUN_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
      this.errorCleaningUp();
    }
  }

  getMaintData(startedAt, endedAt){
    // メンテデータ取得API呼出
    // Maintenance data acquisition API call

    // 認証時にメンテナンスデータ取得要求を実行する
    // Execute maintenance data acquisition request upon authentication
    if(!this.socketService.judge_authenticated()){
      console.log('auth expired');
      return;
    }

    // タイムゾーン変更確認・現在時刻更新
    // Confirmation of time zone change and update of current time
    this.timeDif = new Date().getTimezoneOffset();
    this.nowTime = new Date();
    this.nowTime.setMinutes(new Date().getMinutes() + this.timeDif);

    // 開始時刻・終了時刻を設定する
    // Set start time and end time
    let startTime = new Date(startedAt);
    if(!endedAt){
      endedAt = this.nowTime;
    }
    const endTime = new Date(endedAt);
    endTime.setMinutes(new Date(endedAt).getMinutes() - 3);

    // 開始時刻＞終了時刻の場合、開始時刻を終了時刻に合わせる
    // If start time > end time, set start time to end time.
    if(startTime.getTime() - endTime.getTime() > 0){
      startTime = endTime;
    }

    // 要素数設定：時間差(分)+1とする ※分未満切り捨て
    // Set the number of elements: Time difference (minutes) + 1 *Round down to the nearest minute.
    startedAt = this.dateToString(new Date(startedAt));
    endedAt = this.dateToString(new Date(endedAt));
    const point = Math.floor((endTime.getTime() - startTime.getTime()) / (60*1000)) + 1;


    // 系統毎に実行する★
    for (let i = 0 ; i < this.ouAddressList.length; i++){
      const socketReqId = uuid()
      this.socketRequestIdList.push(socketReqId);
  
      const obj = {
        gateway_id: this.gatewayId,
        site_id: this.user.active.id,
        request_id: socketReqId,
        start_date: this.date12(this.dateToString(startTime)),   // UTC
        end_date: this.date12(this.dateToString(endTime)),   // UTC
        resolution: point,
        monitor_mode: 1,
        address: [this.ouAddressList[i]]
      }
      // メンテナンスデータ送信要求数のカウントを更新する
      // Update the count of maintenance data transmission requests
      this.reqMaintenanceCount = this.reqMaintenanceCount + 1;

      if (!this.socketService.getMaintData(obj)) {
        this.toastService.error(TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
      }
    }
  }

  receiveMaintData(socketResult){
    try {

      // メンテナンスデータ受信数のカウントを更新する
      // Update the count of maintenance data received
      this.resMaintenanceCount = this.resMaintenanceCount + 1;

      // メンテデータ受信処理
      // Maintenance data receiving process
      if(!this.importRunData(socketResult.response.data)){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          'Maintenance data is not available.'
        );
        // 異常完了状態にする(progress=9)
        // Abnormal completion status (progress=9)
        this.progress = 9;
        
        // 進捗状態を通知する
        // Notify me of progress
        this.progressService.noticeProgress(this.progress);

        if(this.socketEventSubscription){
          console.log('unsubscribe');
          this.socketEventSubscription.unsubscribe();
        }
      }

      // 正常完了時subscribe停止
      // Stop subscribe at normal completion
      if(this.subscribe_stop && this.reqMaintenanceCount === this.resMaintenanceCount){
        if(this.socketEventSubscription){
          console.log('unsubscribe');
          this.socketEventSubscription.unsubscribe();
        }
      }
      // ローディング解除
      // Release loading
      if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
      this._ref.detectChanges();
    } catch(exception) {
      console.log('receiveMaintData=>exception');
    }
  }

  importRunData(json) {
    try {
      // dataSourceにデータ追加(丸ごと入れ替え)
      // Add data to dataSource (replace the entire data source).
      const data = JSON.parse(json);

      for (let i=0; i < data['operationMonitor']['cycles'][0]['units'].length; i++){
         const address = data['operationMonitor']['cycles'][0]['units'][i]['address']
         //  アドレスがアドレスリストにある場合、メンテンナンスデータとする
         if (this.ouAddressList.includes(address)){
          const maintData = {
            'address': address,
            'data': data
          }
          for (let j=0; j < this.maintenanceDataList.length; j++){
            // 既にメンテンナンスデータがある場合、削除する
            if(this.maintenanceDataList[j].address === address){
              this.maintenanceDataList.splice(j,1)
              break;
            }
          }
          this.maintenanceDataList.push(maintData);
          break;
         }
      }

      // メンテンナンスデータ表示
      this.displayMaintData();

      this._ref.detectChanges();
      return true;

    } catch (error) {
      return false;

    }
  }

  displayMaintData(){
    // 表示用配列初期化
    // Display array initialisation
    this.timeHeader = [];
    this.dataSource = [];
    this.displayedColumns = ['Key1'];

    this._ref.detectChanges();

    // 選択されている系統の情報を表示する
    let k = 0;
    this.target = -1;
    const allRadioButtons = document.querySelectorAll('input[name=\'radiobutton_tr\']') as NodeListOf<HTMLInputElement>;
    allRadioButtons.forEach(radio => {
      if(radio && radio.checked){
        this.target = k;
      }
      k++;
    });
    const address = this.dataSource_2[this.target].Address;
    let data;

    for (let j = 0; j < this.maintenanceDataList.length; j++){
      if (this.maintenanceDataList[j].address === address){
        data = this.maintenanceDataList[j].data;

        let machineName: string;

        /* jsonファイルから項目リスト(表の1列目)の作成 */
        // Creating a list of items (column 1 of the table) from a json file.
    
        // 機器台数を取得
        // Acquire number of devices
        const machineCount = Object.keys(data['operationMonitor']['cycles'][0]['units']).length;
    
        // 各機器の項目一覧を取得
        // Acquire list of items for each device.
        for(let i=0; i<machineCount; i++){
          // アドレス取得（桁数を3桁に揃える）
          // Acquire addresses (align digits to 3 digits).
          machineName = data['operationMonitor']['cycles'][0]['units'][i]['address'];
          machineName = ('00' + machineName).slice(-3);
          // 属性設定
          // Attribute setting
          machineName = data['operationMonitor']['cycles'][0]['units'][i]['attribute'] + '(' + machineName + ')';
    
          // 項目数カウント
          // Item count
          const itemCount = Object.keys(data['operationMonitor']['cycles'][0]['units'][i]['items']).length;
          // 項目名設定（Key1には属性・アドレス・項目名を、Key2には閾値判定用にnameId1_nameId2を、それぞれ格納）
          // Item name setting (Key1 stores attributes, addresses and item names, Key2 stores nameId1_nameId2 for threshold judgment, respectively)
          for(let k=0; k<itemCount; k++){
            const addData = {
              Key1: machineName + ' ' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['name'],
              Key2: data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['nameId1'] + '_' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['nameId2']
            }
            this.dataSource.push(addData);
          }
        }
    
        /* 運転データを表示用配列に格納 */
        // Stores operational data in display arrays
    
        // 受信データの時間の数（列数）カウント
        // Number of hours (number of columns) count for received data
        const timeCount = Object.keys(data['operationMonitor']['cycles']).length;
        let columnName = '';  // dataSourceの項目名(TimeData_X)が入る Contains the item name (TimeData_X) of the dataSource
    
        // 順に配列に格納
        // Stored in an array in order
        for(let i=0; i<timeCount; i++){
    
          // html用カラム名設定
          // Column name setting for html
          columnName = 'TimeData_' + i;
          this.displayedColumns.push(columnName);
          const timeDataIdx = data['operationMonitor']['cycles'][i]['date']; // timeHeaderのname(YYYY/MM/DD HH:MM)が入る Contains the name (YYYYY/MM/DDHH:MM) of the timeHeader
          this.timeHeaderPlus(timeDataIdx, columnName); // 日付時刻をヘッダへ格納 Date and time are stored in the header
    
          // dataSourceにデータセット
          // 一番早い時刻のデータをTimeData_0に、そこから順にTimeData_1, TimeData_2, ...と格納していく。
          // データは単位変換後(画面表示用)と未変換(閾値判定用)を2つペアにし、配列形式で格納する。
          // ex) 摂氏15、華氏59の場合、TimeData_n: [15, 59] となる
          // Data set in dataSource
          // The data of the earliest time is stored in TimeData_0, from there in order TimeData_1, TimeData_2, ... ... and so on in that order.
          // Data is stored in an array format, with two pairs of data, one after unit conversion (for screen display) and one unconverted (for threshold determination).
          // (ex) In the case of 15 degrees Celsius and 59 degrees Fahrenheit, TimeData_n:[15,59].
          let rowIdx = 0;
          const unitCount = Object.keys(data['operationMonitor']['cycles'][i]['units']).length;
    
          for(let k=0; k<unitCount; k++){
    
            const itemCount = Object.keys(data['operationMonitor']['cycles'][i]['units'][k]['items']).length;
            for(let m=0; m<itemCount; m++){
              this.dataSource[rowIdx][columnName] =
                [this.dataConv(data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['data2'],
                  data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['unitId']),
                data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['data2']];
              rowIdx++;
            }
          }
        }
        break;
      }
    }
  }

  timeHeaderPlus(timeDataIdx, columnName){
    // 受信時刻(UTC)を物件時刻に変換
    // Convert received time (UTC) to property time
    const td = new Date(timeDataIdx);
    td.setMinutes(td.getMinutes() + this.timeDif + this.utcOffset);
    timeDataIdx = this.dateToString(td);

    // timeHeaderに項目追加
    // Add item to timeHeader
    this.timeHeader.push({def: columnName, name: timeDataIdx});
  }

  dataConv(data2, unitId){
    // 単位変換
    // Unit conversion
    let resp: string;

    // 数値以外の場合、空欄・nullの場合は変換しない
    // Non-numeric: no conversion if blank or null
    if(isNaN(data2) || !data2){
      return data2;
    }

    switch(unitId){
      case 0:
        // 数値：何もしない
        // Numeric value: do nothing
        resp = data2;
        break;
      case 1:
        // 温度(設定温度以外)：通常変換
        // Temperature (other than set temperature): normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5) + 32).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 2:
        // 温度(設定温度)：変換ロジック使用
        // Temperature (set temperature): use conversion logic
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(data2);
        }else{
          resp = data2;
        }
        break;
      case 3:
        // 温度差：通常変換
        // Temperature difference: normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 4:
        // 圧力(kg/cm2)：変換ロジック使用
        // Pressure (kg/cm2): use conversion logic
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_kilopascal(Number(data2)).toFixed(1);
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 5:
        // 圧力(MPa)：kPaは通常変換、psiは変換ロジック使用
        // Pressure (MPa): kPa: normal conversion, psi: use conversion logic
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = (Number(data2) * 1000).toString();
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_megapascal_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 6:
        // 文字列表示：何もしない
        // String display: do nothing
        resp = data2;
        break;
      default:
        resp = data2;
    }
    return resp;
  }

  downloadCSV(){
    // 試運転データCSV生成
    // Generation of commissioning data CSV
    const testruninfo = [];
    const headerdata = [''];
    const record = [];
    let updateTime = '';

    // 試運転情報取得
    // Acquisition of commissioning information
    this.siteService.getTestrunHistory(this.gatewayId).subscribe(
      (resData: any) => {
        // 試運転情報整形
        // Trial run information shaping

        // 選択中の系統情報取得
        let k = 0;
        this.target = -1;
        const allRadioButtons = document.querySelectorAll('input[name=\'radiobutton_tr\']') as NodeListOf<HTMLInputElement>;
        allRadioButtons.forEach(radio => {
          if(radio && radio.checked){
            this.target = k;
          }
          k++;
        });
        const address = this.dataSource_2[this.target].Address;

        // job_id = 2600(試運転)のレコードのみdataSourceHistoryに格納
        // Only records with job_id=2600 (trial run) are stored in dataSourceHistory.
        const dataSourceHistory = resData.filter(item => item.job_id === 2600 && item.ou_address === address);

        // 開始日時・終了日時をタイムゾーン補正
        // Time zone correction for start and end dates and times
        if(dataSourceHistory[0].started_at !== 'None'){
          const startDt = new Date(dataSourceHistory[0].started_at);
          startDt.setMinutes(startDt.getMinutes() + this.utcOffset);
          dataSourceHistory[0].started_at_sitetime = this.dateToString(startDt);
        }
        if(dataSourceHistory[0].ended_at !== 'None'){
          const endDt = new Date(dataSourceHistory[0].ended_at);
          endDt.setMinutes(endDt.getMinutes() + this.utcOffset);
          dataSourceHistory[0].ended_at_sitetime = this.dateToString(endDt);
        }

        // 試運転情報csv出力
        // Output trial run information csv
        record.push(['test_run_id','status','testrun_time','start_date_time','end_date_time',
          'ope_mode','outside_temperature','rmd_serial_no','gateway_id']);

        testruninfo.push(dataSourceHistory[0].id);
        testruninfo.push(dataSourceHistory[0].status);
        testruninfo.push(dataSourceHistory[0].testruntime);
        testruninfo.push(dataSourceHistory[0].started_at_sitetime);
        testruninfo.push(dataSourceHistory[0].ended_at_sitetime);
        testruninfo.push(dataSourceHistory[0].ope_mode);
        testruninfo.push(dataSourceHistory[0].outside_temperature);
        testruninfo.push(dataSourceHistory[0].rmd_serial_no);
        testruninfo.push(this.gatewayId);

        record.push(testruninfo);
        record.push(['']);

        // 機種情報取得
        // Acquire model information
        this.siteService.getUnitHistory(this.gatewayId, dataSourceHistory[0].unit_history_id).subscribe(
          (resData2: any) => {

            // 機種情報csv出力
            // Output model information csv
            record.push(['address',
              'model_name',
              'serial_number',
              'attribute',
              'attribute_code',
              'group',
              'branch_pair',
              'rom_main',
              'rom_sub',
              'sub_no',
              'qj_threshold',
              'ic_kind',
              'auto_mode_sw',
              'dry_mode_sw',
              'fan_mode_sw',
              'setback_mode_sw',
              'fan_auto_sw',
              'fan_low_sw',
              'fan_mid2_sw',
              'fan_mid1_sw',
              'fan_high_sw',
              'fan_ex_low_sw',
              'fan_ex_high_sw',
              'ic_lev_sw',
              'bc_svm1_sw',
              'bc_svm1b_sw',
              'bc_svm2_sw',
              'bc_svm2b_sw',
              'bc_lev1_sw',
              'bc_lev2_sw',
              'bc_lev3_sw',
              'bc_lev4_sw',
              'hbc_mv1_sw',
              'hbc_lev1_sw',
              'hbc_lev2_sw',
              'hbc_lev3_sw'
            ]);

            resData2.forEach((value) => {
              const unithistorydata = [];
              unithistorydata.push(value.address);
              unithistorydata.push(value.model_name);
              unithistorydata.push(value.serial_number);
              unithistorydata.push(value.attribute);
              unithistorydata.push(value.attribute_code);
              unithistorydata.push(value.group);
              unithistorydata.push(value.branch_pair);
              unithistorydata.push(value.rom_main);
              unithistorydata.push(value.rom_sub);
              unithistorydata.push(value.sub_no);
              unithistorydata.push(value.qj_threshold);
              unithistorydata.push(value.ic_kind);
              unithistorydata.push(value.auto_mode_sw);
              unithistorydata.push(value.dry_mode_sw);
              unithistorydata.push(value.fan_mode_sw);
              unithistorydata.push(value.setback_mode_sw);
              unithistorydata.push(value.fan_auto_sw);
              unithistorydata.push(value.fan_low_sw);
              unithistorydata.push(value.fan_mid2_sw);
              unithistorydata.push(value.fan_mid1_sw);
              unithistorydata.push(value.fan_high_sw);
              unithistorydata.push(value.fan_ex_low_sw);
              unithistorydata.push(value.fan_ex_high_sw);
              unithistorydata.push(value.ic_lev_sw);
              unithistorydata.push(value.bc_svm1_sw);
              unithistorydata.push(value.bc_svm1b_sw);
              unithistorydata.push(value.bc_svm2_sw);
              unithistorydata.push(value.bc_svm2b_sw);
              unithistorydata.push(value.bc_lev1_sw);
              unithistorydata.push(value.bc_lev2_sw);
              unithistorydata.push(value.bc_lev3_sw);
              unithistorydata.push(value.bc_lev4_sw);
              unithistorydata.push(value.hbc_mv1_sw);
              unithistorydata.push(value.hbc_lev1_sw);
              unithistorydata.push(value.hbc_lev2_sw);
              unithistorydata.push(value.hbc_lev3_sw);

              record.push(unithistorydata);
            });
            record.push(['']);

            // ヘッダー作成
            // Header creation
            this.dataSource.forEach((value) => {
              headerdata.push(value['Key1']);
            });
            record.push(headerdata);

            // 中身作成
            // Create contents
            this.timeHeader.forEach((value) => {
              // 時刻セット
              // Time set
              const csvdata = [];
              csvdata.push(value['name']);
              updateTime = value['name'];  // Time used for file names
              const keyName = value['def'];      // Time header for search (TimeData_X)

              // データセット
              // Data set
              this.dataSource.forEach((value2) => {
                csvdata.push(value2[keyName][1]);
              });

              record.push(csvdata);

            });

            const data = record.map((record2) => record2.join(',')).join('\r\n');
            const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            const blob = new Blob([bom, data], {type: 'text/csv'});
            const url = (window.URL || window.webkitURL).createObjectURL(blob);
            const link = document.createElement('a');
            link.download = dataSourceHistory[0].id + '_' + this.date12(updateTime) + '.csv';
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.siteService.presentToastMessage(
              ToastMessageTypeEnum.Success,
              TOAST_SUCCESS_TITLE,
              'Downloading csv file.'
            );
          },
          (err) => {
            // 機種情報取得に失敗した場合、エラートースト表示
            // Error toast displayed if model information acquisition fails
            this.siteService.presentToastMessage(
              ToastMessageTypeEnum.Error,
              TOAST_GENERAL_ERROR_TITLE,
              TOAST_FAILED_TO_DOWNLOAD_CSV
              );
          }
        );
      },
      (err) => {
        // 試運転情報取得に失敗した場合、エラートースト表示
        // Error toast displayed if trial run information acquisition fails.
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          TOAST_FAILED_TO_DOWNLOAD_CSV
        );
      }
    );
  }

  dateToString(date){
    // 日付型のデータを受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Receives date type data and returns a string in the format "YYYYY/MM/DDhh:mm".
    const yearStr = date.getFullYear();
    let monthStr = 1 + date.getMonth();
    let dayStr = date.getDate();
    let hourStr = date.getHours();
    let minuteStr = date.getMinutes();

    monthStr = ('0' + monthStr).slice(-2);
    dayStr = ('0' + dayStr).slice(-2);
    hourStr = ('0' + hourStr).slice(-2);
    minuteStr = ('0' + minuteStr).slice(-2);

    let formatStr = 'YYYY/MM/DD hh:mm'
    formatStr = formatStr.replace(/YYYY/g, yearStr);
    formatStr = formatStr.replace(/MM/g, monthStr);
    formatStr = formatStr.replace(/DD/g, dayStr);
    formatStr = formatStr.replace(/hh/g, hourStr);
    formatStr = formatStr.replace(/mm/g, minuteStr);

    return formatStr;
  }

  date12(formatStr){
    // "YYYY/MM/DD hh:mm"形式の文字列を受け取り、"YYYYMMDDhhmm"形式の文字列を返す
    // Receives a string in the format "YYYYY/MM/DDhh:mm" and returns a string in the format "YYYYYMMDDhhmm".
    formatStr = formatStr.replace('/', '');
    formatStr = formatStr.replace('/', '');
    formatStr = formatStr.replace(' ', '');
    formatStr = formatStr.replace(':', '');
    return formatStr;
  }

  errorCleaningUp(){
    this.progress = 9;

    // 進捗状態を通知する
    // Notify me of progress
    this.progressService.noticeProgress(this.progress);

    if(this.socketEventSubscription){
      console.log('unsubscribe');
      this.socketEventSubscription.unsubscribe();
    }
    clearInterval(this.interval);

    if(this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
    this._ref.detectChanges();
  }
}