import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageMembersComponent } from '../../components/manage-members/manage-members.component';
import { ManageGatewaysComponent } from '../../components/manage-gateways/manage-gateways.component';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';
import { GatewayDetailGuard } from 'src/app/common/guards/gateway-detail.guard';
import { ManageGatewayDetailsComponent } from '../../components/manage-gateway-details/manage-gateway-details.component';

const siteGuards = [AuthGuard, HasInternetConnectionGuardService, SiteGuard];
const siteGatewayGuards = [AuthGuard, HasInternetConnectionGuardService, GatewayDetailGuard];

const routes: Routes = [
  {
    path: '',
    component: ManageMembersComponent,
  },

  {
    path: 'manage/:siteId/members',
    component: ManageMembersComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'fromManageMemberDetail' }
  }, {
    path: 'manage/:siteId/members/:accountId/showAs/:showAs',
    component: ManageMembersComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'fromManageMemberDetail' }
  },

  {
    path: 'manage/:siteId/gateways',
    component: ManageGatewaysComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'fromManageGatewayDetail' }
  },
  {
    path: 'manage/:siteId/gateway/:gatewayId',
    component: ManageGatewayDetailsComponent,
    canActivate: siteGatewayGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'toManageGatewayDetail' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }
