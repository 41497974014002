<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Notice</ion-title>
    <ion-item lines="none" slot="end">
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(true)"> Start </ion-button>
      </div>
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(false)"> Cancel </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-label>
        ・This function requires about 80 minutes.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・This function operates the indoor unit in the operation mode of "cooling" and the air volume to "high".
        <br> &nbsp;&nbsp;&nbsp;
        After using this function, return the setting of the indoor unit as necessary.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・If the user operates the remote control during the process,
        <br> &nbsp;&nbsp;&nbsp;
        this function will output incorrect results.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・When using the schedule function, please turn off the setting.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・Do not use this function while charging refrigerant.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・The function supports the following models.
        <br> &nbsp;&nbsp;&nbsp;UHY-P72T(Y)NU*
        <br> &nbsp;&nbsp;&nbsp;PUHY-P96T (Y) NU*
        <br> &nbsp;&nbsp;&nbsp;PUHY-P120T (Y) NU*
        <br> &nbsp;&nbsp;&nbsp;PUHY-P144T (Y) NU*
        <br> &nbsp;&nbsp;&nbsp;PUHY-P168T (Y) NU*
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・If another user has already started an inspection, a screen indicating that the device is already being inspected, which is different from the selected device, will be displayed.
      </ion-label>
    </ion-row>
  </ion-grid>
</ion-content>