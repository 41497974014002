import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { MaintenanceJobTypeEnum } from 'src/app/enumerations/enums';
import { OutdoorUnitDetails } from '../site-analytics-test-run/site-analytics-test-run.component';

@Component({
  selector: 'app-site-analytics-test-run-odu-select',
  templateUrl: './site-analytics-test-run-odu-select.component.html',
  styleUrls: ['./site-analytics-test-run-odu-select.component.scss'],
})

export class SiteAnalyticsTestRunOduSelectComponent {
  
  devEnv = devEnv;
  @Input() outdoor_units;
  @Input() selected_units;
  showGatewayName = false;
  @Input() multiSelect = true;
  @Input() job_id?: MaintenanceJobTypeEnum;
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // For HTML Enum Access

  constructor(private modalController: ModalController) {
    // Empty
  }

  ionViewWillEnter() {
    // Preselect the outdoor units based on values in selected units
    devEnv && console.log(`Outdoor Units`, this.outdoor_units);
    this.outdoor_units.forEach((odu: OutdoorUnitDetails) => {
      odu.checked = this.selected_units.find((selected_unit) => {
        return selected_unit.id === odu.id;
      });
    })
  }

  functionSupported(odu: OutdoorUnitDetails) {
    let functionSupported = odu.checkable;
    let nonTestRunJob = this.job_id == MaintenanceJobTypeEnum.Refrigerant || this.job_id == MaintenanceJobTypeEnum.Incorrect_Port;
    if (nonTestRunJob == true) {
      if (this.job_id == MaintenanceJobTypeEnum.Refrigerant) functionSupported = odu?.refrigerantEnabled;
      else if (this.job_id == MaintenanceJobTypeEnum.Incorrect_Port) functionSupported = odu?.branchPortEnabled;
      return functionSupported != null && functionSupported != false;
    } else { // Test Run
      let oduHasBusAddressInRange = odu.analyticsBusAddress == true;
      return oduHasBusAddressInRange;
    }
  }

  shouldODUBeDisabled(odu: OutdoorUnitDetails) {
    let shouldBeDisabled = this.multiSelect == true ? !odu.checkable : !odu.checkable || !odu.checked && this.readyForSubmission();
    return !this.functionSupported(odu) || shouldBeDisabled;
  }

  readyForSubmission() {
    // Something selected?
    let selected = false;
    this.outdoor_units.forEach((odu: OutdoorUnitDetails) => {
      if (odu.checked) selected = true;
    });
    return selected;
  }

  upgradeSubscription(event) {
    // Clicked the upgrade subscirption option, return with details to go to
    // Manage gateway subscription
    this.modalController.dismiss({"upgrade": event});
  }

  close() {
    this.modalController.dismiss();
  }  

  selectAll() {
    // Walk outdoor units - select any that can be.
    this.outdoor_units.forEach((odu: OutdoorUnitDetails) => {
      odu.checked = odu.checkable;
    })
  }

  unselectAll() {
    // Walk outdoor units - deselect all
    this.outdoor_units.forEach((odu: OutdoorUnitDetails) => {
      odu.checked = false;
    })
  }

  checkbox(odu: OutdoorUnitDetails) {
    // Toggle if able
    if (odu.checkable) odu.checked = !odu.checked;
  }

  onSelect() {
    // Submit the response.
    // Gather up the id's of the selected odu
    let selected_odus = [];
    this.outdoor_units.forEach((odu: OutdoorUnitDetails) => {
      if (odu.checked) selected_odus.push(odu);
    })
    this.modalController.dismiss({ 'success': selected_odus });
  }
}