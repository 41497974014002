import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';
import { SettingsAlertsComponent } from '../../components/settings-alerts/settings-alerts.component';
import { KenzaCloudMenuIds } from 'src/app/enumerations/enums';
import { SettingsReportsComponent } from '../../components/settings-reports/settings-reports.component';
import { SettingsSubscriptionComponent } from '../../components/settings-subscription/settings-subscription.component';

const siteGuards = [AuthGuard, HasInternetConnectionGuardService, SiteGuard];

const routes: Routes = [
  {
    path: 'settings/:siteId/settings/alerts',
    component: SettingsAlertsComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Settings, child: KenzaCloudMenuIds.SettingsAlerts }
  },

  {
    path: 'settings/:siteId/settings/reports',
    component: SettingsReportsComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Settings, child: KenzaCloudMenuIds.SettingsReports }
  }, {
    path: 'settings/:siteId/settings/subscription',
    component: SettingsSubscriptionComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Settings, child: KenzaCloudMenuIds.SettingsSubscription }
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
