<div class="batchSchedulingForm {{ sidebarExpanded == true ? 'expandedBatchSchedulingForm' : 'collapsedBatchSchedulingForm' }}">
  <div class="formElements">
    <ion-header>
      <ion-toolbar class="modalTitlebar ion-align-items-center">
        <ion-title *ngIf="!evnt" slot="start" class="eventFormTitle">Create {{ selectedGroups.length > 1 ? 'Batch ' : '' }}{{ eventOrSeriesText }}</ion-title>
        <ion-title *ngIf="evnt" slot="start" class="eventFormTitle">Edit {{ evnt && selectedGroupsForEvent()?.length > 1 ? 'Batch ' : '' }}{{ eventOrSeriesText }}</ion-title>
        <ion-row id="formHeaderEndRow" class="ion-justify-content-between ion-align-items-center">
          <ion-col class="flex ion-align-items-center" id="eventFormCloseButtonCol">
            <div class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
              <ion-icon name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    
    <div class="batchSchedulingFormContainer {{ showIcForm == true ? 'ICFormContainer' : 'LCFormContainer' }}" (window:resize)="resizeBatchSchedulingFormContainer($event)" [style]="getBatchSchedulingFormContainerHeight()">
      <form id="siteSchedulingEventForm" [formGroup]="siteSchedulingEventForm" #eventAdminModal="ngForm">
        <ion-grid class="siteSchedulingEventFormGrid">
          <ion-row class="eventFormRow ion-align-items-center">
            <ion-col size="12" class="formEventTitleCol validationItemCol ion-align-items-center">
              <div id="eventTitleItem validationItem">
                <!-- <ion-label position="floating" color="secondary" class="validationItemLabel">Event Title<sup validation-error>*</sup></ion-label> -->
                <ion-input type="text" id="eventTitleInput" class="validationInput eventTitleInput" formControlName="title"
                  [readonly]="user.activeSiteUserLevel < 1" maxLength="50" required title="Click to Set Title" aria-labelledby="Event Title" labelPlacement="floating">
                  <ion-text slot="label" color="secondary" class="customFloatingLabel">Event Title<sup validation-error>*</sup></ion-text>
                </ion-input>
              </div>
              <div validation-error *ngIf="siteSchedulingEventForm.controls.title.status == 'INVALID'">
                {{ titleLabel }}
              </div>
            </ion-col>
          </ion-row>
          <!-- <ion-row *ngIf="devEnv == true ? siteSchedulingEventForm.controls.lastSelectedUnits && sidebarExpanded == false : true" class="lastSelectedUnitsRow eventFormRow ion-align-items-center"> -->
          <ion-row class="lastSelectedUnitsRow eventFormRow ion-align-items-center">
            <ion-col size="12" class="validationItemCol">
              <ion-item class="lastSelectedUnitsItem validationItem" title="{{ siteSchedulingEventForm.value.lastSelectedUnits }}">
                <div class="inputContainer">
                  <ion-label position="floating" color="secondary" class="validationItemLabel normalScale selectFromListLabel">{{ evnt && evnt?.meta?.groupedEvents && evnt?.meta?.groupedEvents?.length > 0 ? 'Event Groups/Indoor Coils' : 'Selected Groups/Indoor Coils' }}<sup validation-error>*</sup></ion-label>
                  <div class="inputControlsContainer">
                    <ion-input type="text" class="lastSelectedUnitsField textOverflow tflowShowDots hasBottomBorderAlready noPaddingAtStart validationInput" formControlName="lastSelectedUnits" aria-labelledby="Selected Groups/Indoor Coils" readonly required></ion-input>
                    <ion-button color="secondary" class="dayButton" title="{{ sidebarExpanded == true ? 'Collapse' : 'Expand' }} Group Selection List" class="expandCollapseSidebarIconButton expandCollapseSidebarIconButtonSelectFromList" (click)="expandFormSidebar($event)">
                      Select from List <ion-badge class="groupNumBadge">{{ selectedGroupsForEvent()?.length }}</ion-badge> 
                      <ion-icon class="expandCollapseListButtonIcon expandCollapseSidebarIcon expandCollapseList {{ sidebarExpanded == true ? 'collapseIcon' : 'expandIcon' }}" name="chevron-forward-outline"></ion-icon>
                    </ion-button>
                  </div>
                </div>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="startControls eventFormRow ion-align-items-center">
            <div [class]="timeColLeft ? 'timeDate' : 'timeDate timeColLeft'">
              <ion-col class="eventDaysCol" *ngIf="is_recurring">
                <ion-item id="eventDaysItem" class="item-interactive">
                  <ion-label position="stacked" color="secondary" class="validationItemLabel normalScale timeDateLabel eventDaysLabel">Event Day(s)<sup validation-error>*</sup>
                  </ion-label>
                  <div *ngIf="buttonMode" class="dayButtons elemBelowLabel" id="dayButtons" title="Click to Select Days">
                    <ion-button *ngFor="let day of eventDays; let i = index" [id]="day" [title]="day" [value]="day"
                      (click)="changeEventDays($event, true)" [active]="days.includes(day)" class="dayButton"
                      [class.active]="days.includes(day)" [color]="
                        days.includes(day) ? 'secondary' : 'reverse-secondary'
                      ">
                      {{ day.substring(0, 3) }}
                    </ion-button>
                  </div>
                </ion-item>
                <div validation-error *ngIf="is_recurring && days.length == 0">
                  Event Day is required
                </div>
              </ion-col>
              <ion-col *ngIf="!is_recurring" class="smallCol ion-align-items-center errCol eventDateCol">
                <ion-item id="eventStartDateItem">
                  <ion-label position="stacked" color="secondary" class="validationItemLabel normalScale adjustedMargin">
                    Event Date<sup validation-error>*</sup>
                  </ion-label>
                  <ion-input id="eventStartDateInput" title="Click to Select Date" name="eventStartDateInput" type="date"
                    class="hasBottomBorderAlready ion-no-padding me-date-picker" formControlName="startDay"
                    [readonly]="user.activeSiteUserLevel < 1" required ngDefaultControl min="2000-01-01" max="2099-12-31" 
                    aria-labelledby="Event Date" [attr.data-day]="dayOfDate()">
                  </ion-input>
                </ion-item>
                <div validation-error *ngIf="
                    siteSchedulingEventForm.controls.startDay.hasError(
                      'required'
                    ) && !siteSchedulingEventForm.controls.startDay.touched
                  ">
                  Event Date Invalid
                </div>
                <div validation-error *ngIf="
                     siteSchedulingEventForm.controls.startDay.hasError(
                       'required'
                     ) && siteSchedulingEventForm.controls.startDay.touched
                   ">
                   Event Date Invalid
                 </div>
                 <div validation-error *ngIf="eventFuture">
                   Event Date cannot be after 2099
                 </div>
                 <div validation-error *ngIf="eventPast">
                   Event Date cannot be before 2000
                 </div>
               </ion-col>
               <ion-col id="timeCol" [size]="alldayEnabled ? 4 : is_recurring ? 3 : 6"
                 class="timeCol smallCol ion-align-items-center">
                 <div id="eventStartTimeItem" class="eventStartTimeItem validationItem">
                   <ion-label position="stacked" color="secondary" class="validationItemLabel timeDateLabel">Event Time<sup validation-error>*</sup></ion-label>
                   <ion-input type="time" name="startTime" id="eventStartTimeInput" class="capitalizeInput noPaddingAtStart validationInput elemBelowLabel"
                     formControlName="startTime" [attr.disabled]="alldayEvent" [readonly]="user.activeSiteUserLevel < 1"
                     required value="{{ startTime }}" title="Click to Select Time" aria-labelledby="Event Time">
                   </ion-input>
                 </div>
                 <div validation-error *ngIf="
                     siteSchedulingEventForm.controls.startTime.hasError(
                       'required'
                     ) && !siteSchedulingEventForm.controls.startTime.touched
                   ">
                   Event Time Invalid
                 </div>
                 <div validation-error *ngIf="
                     siteSchedulingEventForm.controls.startTime.hasError(
                       'required'
                     ) && siteSchedulingEventForm.controls.startTime.touched
                   ">
                   Event Time Invalid
                 </div>
               </ion-col>
             </div>
            <ion-row class="tempRangeSlidersRow {{ temp1DualKnobs() ? 'dualSetMain' : 'singleSetMain' }} {{ multipleRangeSliders() == true ? 'multipleTempControls' : '' }}">
              <ion-col [size]="multipleRangeSliders() == true ? 6 : 12" *ngIf="selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && (dualSetPointInitialized == false || transitionDualToTriple == false)" class="rangeCol firstRangeCol validationItemCol ion-align-items-center">
                <ion-item id="eventStartTempItem" class="item-has-value range-knob-pressed item-interactive validationItem"
                  [attr.disabled]="user.activeSiteUserLevel < 1 || activeMode == ModesSpeedsDirections.fan || siteSchedulingEventForm.controls.mode.value == ''"
                  title="{{ temp1ControllerTitle() }}">
                  <ion-label class="controlsLabel validationItemLabel normalScale" color="secondary" position="stacked">
                    {{ temp1DualKnobs() ? 'Temperature' + (devEnv ? ' 1' : '') : multipleRangeSliders() == true ? 'Single Set Temp' : 'Temperature' }}
                    <sup validation-error>*</sup>
                  </ion-label>
                  <div class="tempLabels">
                    <div class="setpoint-input-holder" position="stacked">
                      <div
                        [hidden]="!(temp1DualKnobs() || activeMode == ModesSpeedsDirections.heat || activeMode == ModesSpeedsDirections.fan || activeMode == ModesSpeedsDirections.auto || activeMode == ModesSpeedsDirections.setback)"
                        class="input-holder">
                        <ion-input id="lowerTemp" [formControlName]="temp1DualKnobs() ? 'temperature2Lower' : 'lowerSetTemp'" [value]="temp1DualKnobs() ? temperature2Lower : heatSetTemp"
                          (ionInput)="temperatureChangeInputLower($event, '', temp1DualKnobs())" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="lowerMin()"
                          [max]="temp1DualKnobs() ? lowerMax() : getMaxTemp()"
                          [placeholder]="placeHolderNum" [step]="customStep" (keydown)="detectTempInput($event)" (click)="determineLowerDirection($event)" aria-labelledby="Event Lower Temp"></ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                      <span *ngIf="temp1DualKnobs()" class="spacerDash"> <span class="arrow">to</span> </span>
                      <div [hidden]="!(temp1DualKnobs() || activeMode == ModesSpeedsDirections.cool || activeMode == ModesSpeedsDirections.dry)"
                        class="input-holder">
                        <ion-input id="upperTemp" [formControlName]="temp1DualKnobs() ? 'temperature2Upper' : 'upperSetTemp'" [value]="temp1DualKnobs() ? temperature2Upper : coolSetTemp"
                          (ionInput)="temperatureChangeInputUpper($event, '', temp1DualKnobs())" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="upperMin()"
                          [max]="upperMax()" [placeholder]="placeHolderNum" [step]="customStep"
                          (keydown)="detectTempInput($event)" (click)="determineUpperDirection($event)" aria-labelledby="Event Upper Temp">
                        </ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                    </div>
                  </div>
                  <ion-range class="eventTempRange eventTempRangeFirst" [attr.data-dualSet]="temp1DualKnobs() ? 'Dual' : 'Single'"
                    (ionInput)="temperatureChangeRange($event)" id="eventStartTempRange" [formControlName]="temp1DualKnobs() ? 'temperatureBatchDual' : 'temperatureRange'"
                    [dualKnobs]="temp1DualKnobs()" [min]="lowerMin()" [max]="upperMax()" [value]="temperature" 
                    title="Click to Set Temperature" [step]="customStep" [debounce]="debounceTime" 
                    pin [pinFormatter]="pinFormatter" aria-label="Event Temp Range" aria-labelledby="Event Temp Range" color="secondary" required>
                    <ion-label slot="start" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline"> </ion-icon><br />{{ lowerMin() }}
                      {{ degreesTypeAbbreviation }} <br />MIN
                    </ion-label>
                    <ion-label slot="end" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline" size="small"> </ion-icon><br />{{ multipleRangeSliders() == true && temp1DualKnobs() == false ? getMaxTemp() : temp1DualKnobs() == true ? getMaxTemp() : maxTemperature }}
                      {{ degreesTypeAbbreviation }} <br />MAX
                    </ion-label>
                  </ion-range>
                </ion-item>
                <div *ngIf="!eventEnabled || !saveEnabled" class="temperatureErrorMsg">
                  {{ temperatureErrorMsg }}
                </div>
              </ion-col>
              <ion-col id="rangeColSingleCol" [size]="multipleRangeSliders() == true ? 6 : 12" *ngIf="selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit && (dualSetPointInitialized == true && transitionDualToTriple == true)" class="rangeCol rangeColSingle firstRangeCol validationItemCol ion-align-items-center">
                <ion-item id="eventStartTempItem" class="item-has-value range-knob-pressed item-interactive validationItem"
                  [attr.disabled]="user.activeSiteUserLevel < 1 || activeMode == ModesSpeedsDirections.fan || siteSchedulingEventForm.controls.mode.value == ''"
                  title="{{ temp1ControllerTitle() }}">
                  <ion-label class="controlsLabel validationItemLabel normalScale" color="secondary" position="stacked">
                    {{ temp1DualKnobs() ? 'Temperature' + (devEnv ? ' 1' : '') : multipleRangeSliders() == true ? 'Single Set Temp' : 'Temperature' }}
                    <sup validation-error>*</sup>
                  </ion-label>
                  <div class="tempLabels">
                    <div class="setpoint-input-holder" position="stacked">
                      <div
                        [hidden]="!(temp1DualKnobs() || activeMode == ModesSpeedsDirections.heat || activeMode == ModesSpeedsDirections.fan || activeMode == ModesSpeedsDirections.auto || activeMode == ModesSpeedsDirections.setback)"
                        class="input-holder">
                        <ion-input id="lowerTempSingleCol" formControlName="lowerSetTemp" [value]="heatSetTemp"
                          (ionInput)="temperatureChangeInputLower($event, '', temp1DualKnobs())" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="getMinTemp()"
                          [max]="getMaxTemp()"
                          [placeholder]="placeHolderNum" [step]="customStep" (keydown)="detectTempInput($event)" (click)="determineLowerDirection($event)" aria-labelledby="Event Lower Temp"></ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                      <span *ngIf="temp1DualKnobs()" class="spacerDash"> <span class="arrow">to</span> </span>
                      <div [hidden]="!(temp1DualKnobs() || activeMode == ModesSpeedsDirections.cool || activeMode == ModesSpeedsDirections.dry)"
                        class="input-holder">
                        <ion-input id="upperTemp" formControlName="upperSetTemp" [value]="coolSetTemp"
                          (ionInput)="temperatureChangeInputUpper($event, '', temp1DualKnobs())" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="upperMin()"
                          [max]="upperMax()" [placeholder]="placeHolderNum" [step]="customStep"
                          (keydown)="detectTempInput($event)" (click)="determineUpperDirection($event)" aria-labelledby="Event Upper Temp">
                        </ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                    </div>
                  </div>
                  <ion-range class="eventTempRange eventTempRangeFirst eventTempRangeFirstTransitionFromDual" [attr.data-dualSet]="Single"
                    (ionInput)="temperatureChangeRange($event)" id="eventStartTempRange" formControlName="temperatureRange"
                    dualKnobs="false" [min]="getMinTemp()" [max]="getMaxTemp()" [value]="temperature" 
                    title="Click to Set Temperature" [step]="customStep" [debounce]="debounceTime" 
                    pin [pinFormatter]="pinFormatter" aria-label="Event Temp Range" aria-labelledby="Event Temp Range" color="secondary" required>
                    <ion-label slot="start" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline"> </ion-icon><br />{{ getMinTemp() }}
                      {{ degreesTypeAbbreviation }} <br />MIN
                    </ion-label>
                    <ion-label slot="end" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline" size="small"> </ion-icon><br />{{ getMaxTemp() }}
                      {{ degreesTypeAbbreviation }} <br />MAX
                    </ion-label>
                  </ion-range>
                </ion-item>
                <div *ngIf="!eventEnabled || !saveEnabled" class="temperatureErrorMsg">
                  {{ temperatureErrorMsg }}
                </div>
              </ion-col>
              <ion-col size="6" *ngIf="multipleRangeSliders() == true" class="rangeCol secondRangeCol validationItemCol ion-align-items-center">
                <ion-item id="eventStartTempItem2" class="item-has-value range-knob-pressed item-interactive validationItem"
                  [attr.disabled]="user.activeSiteUserLevel < 1 || activeMode == ModesSpeedsDirections.fan" title="Lower Min {{ lowerMin2() }}, Lower Max {{ lowerMax2() }}, Upper Min {{ upperMin2() }}, Upper Max {{ upperMax2() }}">
                  <ion-label class="controlsLabel validationItemLabel normalScale" color="secondary" position="stacked">
                    {{ temp2DualKnobs() == false ? 'Temperature' + (devEnv ? ' 2' : '') : 'Dual Set Temp' }}
                    <sup validation-error>*</sup>
                  </ion-label>
                  <div class="tempLabels">
                    <div class="setpoint-input-holder" position="stacked">
                      <div class="input-holder">
                        <ion-input id="lowerTemp2" [formControlName]="temp2DualKnobs() == true ? 'temperature2Lower' : multipleRangeSliders() == true && temp2DualKnobs() == false ? 'lowerSetTemp2' : 'lowerSetTemp'" [value]="temp2DualKnobs() == true ? temperature2Lower : evnt && evnt != null ? evnt.meta.temperatureNumber : heatSetTemp < lowerMin2() ? lowerMin2() : heatSetTemp > lowerMax2() ? lowerMax2() : heatSetTemp" (ionInput)="temperatureChangeInputLower($event, temp2DualKnobs() == true ? 'temperature2Lower' : multipleRangeSliders() == true && temp2DualKnobs() == false ? 'lowerSetTemp2' : 'lowerSetTemp')" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="lowerMin2()"
                          [max]="lowerMax2()"
                          [placeholder]="placeHolderNum" [step]="customStep" (keydown)="detectTempInput($event)" (click)="determineLowerDirection2($event)" aria-labelledby="Event Lower Temp"></ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                      <span *ngIf="temp2DualKnobs() == true" class="spacerDash"> <span class="arrow">to</span> </span>
                      <div [hidden]="temp2DualKnobs() == false" class="input-holder">
                        <ion-input id="upperTemp2" [formControlName]="temp2DualKnobs() == true ? 'temperature2Upper' : 'upperSetTemp'" [value]="temp2DualKnobs() == true ? temperature2Upper : coolSetTemp"
                          (ionInput)="temperatureChangeInputUpper($event, temp2DualKnobs() == true ? 'temperature2Upper' : 'upperSetTemp')" class="temperatureNumberInput hasBottomBorderAlready noPaddingAtStart" type="number"
                          [min]="upperMin2()"
                          [max]="upperMax2()" [placeholder]="placeHolderNum" [step]="customStep"
                          (keydown)="detectTempInput($event)" (click)="determineUpperDirection2($event)" aria-labelledby="Event Upper Temp">
                        </ion-input>
                        <span class="degreeFormLetter">{{ degreesTypeAbbreviation }}</span>
                      </div>
                    </div>
                  </div>
                  <ion-range class="eventTempRange eventTempRangeSecond" [attr.data-dualSet]="!temp2DualKnobs() ? 'Single' : 'Dual'"
                    (ionInput)="temperatureChangeRange($event, true)" id="eventStartTempRange2" [formControlName]="temp2DualKnobs() == true ? 'temperatureBatchDual' : multipleRangeSliders() == true && temp2DualKnobs() == false ? 'temperatureRange2' : 'temperatureRange'"
                    [dualKnobs]="!temp2DualKnobs() ? false : true" [min]="getMinTemp2()" [max]="getMaxTemp2()" [value]="evnt && evnt != null && temp2DualKnobs() == false ? evnt.meta.temperatureNumber : temp2DualKnobs() == true ? multipleRangeSliders() == true ? siteSchedulingEventForm.value.temperatureBatchDual : temperature : lowerMin2()" 
                    title="Click to Set Temperature" [step]="customStep" [debounce]="debounceTime" 
                    pin [pinFormatter]="pinFormatter" aria-label="Event Temp Range" aria-labelledby="Event Temp Range" color="secondary" required>
                    <ion-label slot="start" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline"> </ion-icon><br />{{ getMinTemp2() }}
                      {{ degreesTypeAbbreviation }} <br />MIN
                    </ion-label>
                    <ion-label slot="end" class="rangeIconLabel">
                      <ion-icon name="thermometer-outline" size="small"> </ion-icon><br />{{ getMaxTemp2() }}
                      {{ degreesTypeAbbreviation }} <br />MAX
                    </ion-label>
                  </ion-range>
                </ion-item>
                <div *ngIf="!eventEnabled || !saveEnabled" class="temperatureErrorMsg">
                  {{ temperatureErrorMsg }}
                </div>
              </ion-col>
            </ion-row>
          </ion-row>
          <ion-row class="powerRow eventFormRow ion-align-items-center segRow">
            <ion-col class="segmentCol ion-align-items-center">
              <ion-label position="stacked" color="secondary" class="segLabel validationItemLabel">Power<sup validation-error>*</sup></ion-label>
              <ion-segment color="secondary" formControlName="power" class="segmentNoLabels" scrollable title="Click to Select Power">
                <ion-segment-button [value]="activePower" title="Power: {{ capWords(activePower) }}" [name]="activePower"
                  *ngFor="let activePower of activePowers" class="batchSegmentButton powerBatchButton" [disabled]="bgd.power[activePower]?.disabled">
                  <ion-label>{{ activePower }}</ion-label>
                  <ion-badge *ngIf="showBatchBadges == true && bgd.power[activePower]?.count > 0" color="secondary" me-control-badge>
                    {{ bgd.power[activePower]?.count }}
                  </ion-badge>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit" class="modeRow eventFormRow ion-align-items-center segRow">
            <ion-col class="segmentCol ion-align-items-center">
              <ion-label position="stacked" color="secondary" class="segLabel validationItemLabel">Mode<sup validation-error>*</sup></ion-label>
              <ion-segment color="secondary" (ionChange)="modeChange()" formControlName="mode"
                [attr.readonly]="unitModes.length == 1 || user.activeSiteUserLevel < 1" [ngClass]="
                  unitModes.length == 1
                    ? 'stretchSeg segmentWithIcons'
                    : 'segmentWithIcons'
                " scrollable title="Click to Select Mode">
                <ion-segment-button [value]="activeMode" title="Mode: {{ capWords(activeMode) }}" [name]="activeMode"
                  *ngFor="let activeMode of unitModes" class="batchSegmentButton {{ evnt == null && bgd.mode[activeMode]?.count > 0 && selectedGroups.length == 1 ? 'segment-button-checked' : '' }}" [disabled]="bgd.mode[activeMode]?.disabled">
                  <ion-icon [name]="getModeIcon(activeMode)" class="full"></ion-icon>
                  <ion-label class="buttonLabel">{{ activeMode }}</ion-label>
                  <ion-badge *ngIf="showBatchBadges == true && bgd.mode[activeMode]?.count > 0" class="iconBadge" color="secondary" me-control-badge>
                    {{ bgd.mode[activeMode]?.count }}
                  </ion-badge>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedGatewayUnit.type == 'LC'" class="ventModeRow eventFormRow ion-align-items-center segRow">
            <ion-col class="segmentCol ion-align-items-center">
              <ion-label position="stacked" color="secondary" class="segLabel validationItemLabel">Vent Mode<sup validation-error>*</sup></ion-label>
              <ion-segment color="secondary" formControlName="ventMode"
                [attr.readonly]="ventModes.length == 1 || user.activeSiteUserLevel < 1" [ngClass]="
                  ventModes.length == 1
                    ? 'stretchSeg segmentWithIcons'
                    : 'segmentWithIcons'
                " scrollable title="Click to Select Mode">
                <ion-segment-button [value]="activeVentMode" title="Vent Mode: {{ capWords(activeVentMode) }}" [name]="activeVentMode"
                  *ngFor="let activeVentMode of ventModes" class="batchSegmentButton" [disabled]="bgd.vent_mode[renderVentModeFanSpeed(activeVentMode)]?.disabled">
                  <ion-icon [ngClass]="activeVentMode != ModesSpeedsDirections.auto && 'lcIcon'" [src]="getLCModeIcon(activeVentMode)"
                    class="full">
                  </ion-icon>
                  <ion-label class="buttonLabel">{{ activeVentMode }}</ion-label>
                  <ion-badge *ngIf="showBatchBadges == true && bgd.vent_mode[renderVentModeFanSpeed(activeVentMode)]?.count > 0" class="iconBadge" color="secondary" me-control-badge>
                    {{ bgd.vent_mode[renderVentModeFanSpeed(activeVentMode)]?.count }}
                  </ion-badge>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row
            class="fanSpeedRow eventFormRow ion-align-items-center longLabel segRow">
            <ion-col class="segmentCol ion-align-items-center">
              <ion-label position="stacked" color="secondary" class="segLabel validationItemLabel">Fan Speed<sup validation-error>*</sup></ion-label>
              <ion-segment color="secondary" formControlName="fanSpeed"
                [attr.readonly]="fanSpeeds.length == 1 || user.activeSiteUserLevel < 1" [ngClass]="
                  fanSpeeds.length == 1
                    ? 'stretchSeg segmentWithIcons'
                    : 'segmentWithIcons'
                " scrollable title="Click to Select Fan Speed">
                <ion-segment-button [value]="speed" title="Fan Speed: {{ speed == 'disabled' ? 'No Change' : capWords(speed) }}" [name]="speed"
                  *ngFor="let speed of fanSpeeds; let index = index" class="batchSegmentButton" [disabled]="renderSpeed(speed) != ModesSpeedsDirections.none && bgd.fan_speed[renderSpeed(speed)]?.disabled">
                  <ion-icon [src]="getFanSpeedIcon(speed)" class="full"></ion-icon>
                  <ion-label class="buttonLabel">{{ speed == 'disabled' ? 'no change' : speed }}</ion-label>
                  <ion-badge *ngIf="showBatchBadges == true && bgd.fan_speed[renderSpeed(speed)]?.count > 0" class="iconBadge" color="secondary" me-control-badge>
                    {{ bgd.fan_speed[renderSpeed(speed)]?.count }}
                  </ion-badge>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedGatewayUnit.type == GatewayUnitTwoDigitType.IndoorUnit"
            class="airDirectionRow eventFormRow ion-align-items-center longLabel segRow">
            <ion-col class="segmentCol ion-align-items-center">
              <ion-label position="stacked" color="secondary" class="segLabel validationItemLabel">Air Direction<sup validation-error>*</sup></ion-label>
              <ion-segment color="secondary" formControlName="airDirection" class="selectButtons"
                [attr.readonly]="airDirects.length == 1 || user.activeSiteUserLevel < 1" [ngClass]="
                  airDirects.length == 1
                    ? 'stretchSeg segmentWithIcons'
                    : 'segmentWithIcons'
                " scrollable title="Click to Select Air Direction">
                <ion-segment-button [value]="direction" title="Air Direction: {{ direction == 'disabled' ? 'No Change' : direction == ModesSpeedsDirections.stationary ? 'No Change' : capWords(direction) }}" [name]="direction"
                  *ngFor="let direction of airDirects; let index = index" class="batchSegmentButton" [disabled]="direction != ModesSpeedsDirections.stationary && bgd.air_direction[direction]?.disabled">
                  <ion-icon [src]="getAirDirectionIcon(direction)" class="full"></ion-icon>
                  <ion-label class="buttonLabel">{{ direction == 'disabled' ? 'no change' : direction == ModesSpeedsDirections.stationary ? 'no change' : direction }}</ion-label>
                  <ion-badge *ngIf="showBatchBadges == true && bgd.air_direction[direction]?.count > 0" class="iconBadge" color="secondary" me-control-badge>
                    {{ bgd.air_direction[direction]?.count }}
                  </ion-badge>
                </ion-segment-button>
              </ion-segment>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </div>
    
    <ion-footer class="schedFormFooter">
      <ion-grid style="padding: 0.5em">
        <ion-row class="formFooterRow ion-justify-content-between ion-align-items-center">
          <ion-col class="timeContent flex ion-align-items-center" size="8">
            <ion-label class="siteTime siteTimeFormFooter" [value]="siteTime">Current Time: <br />
              {{ siteTime }}
            </ion-label>
          </ion-col>
          <ion-col *ngIf="user.activeSiteUserLevel > levelEnum.Viewer"
            class="eventActionButtons endContent flex ion-align-items-center">
            <ion-button *ngIf="action == 'Clicked'" id="deleteEventButton"
              (click)="deleteEvent(evnt, true, eventAdminModal, daysChanged)" style="margin: 0 0 0 0 1em">
              Delete
            </ion-button>
            <ion-button *ngIf="action == 'Clicked'" (click)="updateEvent(eventAdminModal, evnt, getCheckboxes())" [disabled]="eventSaveNotAllowed()"
              color="primary">
              Save
            </ion-button>
            <ion-button *ngIf="action !== 'Clicked'" (click)="createNewEvent(eventAdminModal)" color="primary"
              [disabled]="eventCreationNotAllowed()">
              Create
            </ion-button>
            <ion-button id="closeEventFormModal" (click)="close()" color="reverse-primary">
              Cancel
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
  <div class="sidebarElements textOverflow tflow35 {{ sidebarExpanded == true ? 'expandedSidebarElements' : 'collapsedSidebarElements' }}">
    <form *ngIf="batchScheduling" class="selectedGroupsCheckBoxesForm batchSchedulingFormSideBar {{ groupByGateways == true ? 'sideBarWithIndentation' : '' }} {{ sidebarExpanded == true ? 'expandedSidebar' : 'collapsedSidebar' }}">
      <ion-item class="checkBoxFormLabelItem" lines="full">
        <!-- Selected Groups/Indoor Coils -->
        <ion-label class="checkBoxFormLabel">
          {{ evnt && evnt?.meta?.groupedEvents && evnt?.meta?.groupedEvents?.length > 0 ? 'Event Groups/Indoor Coils' : 'Selected Groups/Indoor Coils' }}
          <!-- <div class="checkBoxListBadges">
            <div class="checkBoxListBadgeText"><ion-badge class="groupNumBadge" color="secondary">{{ selectedGroups?.length }}</ion-badge> Selected Group(s)</div>
            <hr />
            <div class="checkBoxListBadgeText"><ion-badge class="groupNumBadge">{{ mainSiteUIService.allSiteGroups?.length }}</ion-badge> Total Group(s)</div>
          </div>
          <div class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
            <ion-icon name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
          </div> -->
        </ion-label>
      </ion-item>
      <app-search-and-filter (searchFilterDataSignal)="onSearchFilterDataSignal($event)" searchClass="eventFormSearch" [selectedGroups]="selectedGroups" [useInternalList]="true" searchElementTag="groupSearchBar" [sizeXL]="false"></app-search-and-filter>
      <div id="checkboxGridListContainer" class="checkboxGridListContainer {{ isElementHeightOverflowing(checkboxGridListContainer, 815) ? 'overflowing' : 'contained' }}" #checkboxGridListContainer>
        <div class="{{ groupByGateways == true ? 'groupByGateways' : 'groupByGroups' }}">
          <ion-grid [hidden]="filteredUniqueGateways.length > 0" class="selectedGroupsCheckList">
            <ion-item lines="full" class="checkboxItem checkboxGWItem itemExpandComponent noResultsFound"><i>Your search for "{{ mainSiteUIService.searchTerm }}" returned no results. Please try again.</i></ion-item>
          </ion-grid>
          <ion-grid [hidden]="filteredUniqueGateways.length == 0 && mainSiteUIService.searchTerm != ''" *ngFor="let gw of filteredUniqueGateways; idx as i; let frst = first; let lst = last;" class="selectedGroupsCheckList {{ gw?.expanded == true || groupByGateways == false ? 'expandedCheckList' : 'collapsedCheckList' }}" (click)="expandCollapseCheckList(gw, $event)">
            <ion-item *ngIf="groupByGateways == true" lines="full" class="checkboxItem checkboxGWItem itemExpandComponent" id="checkboxItemFor-{{ gw?.id }}" title="{{ gw?.name }}">
              <ion-checkbox [checked]="getFilteredCheckedGroupsForGW(gw, mainSiteUIService.allSiteGroups)?.length > 0" id="checkboxFor-{{ gw?.id }}" name="{{ gw?.name }}" me-side-menu-checkbox
                class="ion-no-margin batchSchedulingCheckbox gwItemCheckbox itemExpandComponent textOverflow tflow95" labelPlacement="end" justify="start">
                <ion-text class="textOverflow tFlowBlock">{{ gw?.name }}</ion-text>
              </ion-checkbox>
              <ion-badge class="grpGWCheckBadge itemExpandComponent" *ngIf="getFilteredCheckedGroupsForGW(gw, mainSiteUIService.allSiteGroups)?.length > 0">{{ getFilteredCheckedGroupsForGW(gw, mainSiteUIService.allSiteGroups)?.length }}</ion-badge>
              <button class="expandCollapseCheckListButton itemExpandComponent"><ion-icon name="chevron-up-outline"></ion-icon></button>
            </ion-item>
            <div class="checkListContainer {{ onMac ? 'macSchedulingCheckListContainer' : 'windowsSchedulingCheckListContainer' }}">
              <ion-row *ngFor="let grp of getFilteredGroupsForGW(gw, mainSiteUIService.allSiteGroups); index as i; let first = first; let last = last;">
                <ion-col *ngIf="includesGroupInSearchAndFilter(grp)" col-2>
                  <ion-item lines="full" class="checkboxItem" id="checkboxItemFor-{{ grp?.gatewayUnits[0]?.id }}" title="{{ grp?.gatewayUnits[0]?.group_name }}">
                    <ion-checkbox color="{{ evnt && grp?.id == evnt?.meta?.group_id && selectedGroups.length == 1 ? 'primary' : 'primary' }}" id="checkboxFor-{{ grp?.gatewayUnits[0]?.id }}" name="{{ grp?.gatewayUnits[0]?.group_name }}" me-side-menu-checkbox
                    class="ion-no-margin batchSchedulingCheckbox unitItemCheckbox textOverflow tflow95" [checked]="isGrpSelected(grp)" [disabled]="isGrpDisabled(grp)" (ionChange)="checkUnCheckGroup($event, grp)" labelPlacement="end" justify="start" #unitItemCheckbox>
                      <ion-text>{{ grp?.gatewayUnits[0]?.group_name }}</ion-text>
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
              </ion-row>
            </div>
          </ion-grid>
        </div>
      </div>
    </form>
    <ion-item title="{{ sidebarExpanded == true ? 'Collapse' : 'Expand' }} Group Selection List" class="expandCollapseSidebarIconButton" [button]="true" lines="full" (click)="expandFormSidebar($event)">
      <ion-icon class="expandCollapseSidebarIcon expandCollapseListSideArrow {{ sidebarExpanded == true ? 'collapseIcon' : 'expandIcon' }}" name="chevron-forward-outline"></ion-icon>
    </ion-item>
  </div>
</div>