import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AppAuthenticationService } from '../services/authentication/app-authentication.service';
import { AppMessageTypeCode, AppMessageCode } from 'src/app/enumerations/enums';
import { Site } from 'src/app/features/sites/classes/site';
import { Permission } from 'src/app/features/account/classes/Permission';

@Injectable({
    providedIn: 'root'
})
export class SiteMonitorGuard implements CanActivate {
    constructor(
        private router: Router,
        public user: UserService,
        private appAuth: AppAuthenticationService
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let hasPerm = this.hasPermissionForSiteMonitor();
        if (hasPerm == false) {
            this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
            return false;
        }

        return true;
    }

    hasPermissionForSiteMonitor() {
        return this.appAuth.doesLevelHavePermission(
            this.user.activeSiteUserLevel,
            this.appAuth.permissionEnums.ViewSiteMonitoring
        );
    }
}