import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppAuthenticationService } from '../services/authentication/app-authentication.service';
import { MainSiteUIService } from '../services/ui/main-site-ui.service';
import { UserService } from '../services/user/user.service';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Hub } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private ui: MainSiteUIService,
    private user: UserService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    switch (state.url) {
      case "/contactus":
      case "/privacypolicy":
      case "/subscriberagreement":
      case "/copyright":
      case "/comparesubscriptionplans":
        this.ui.showLeftNav = false;
        return true;

      default:
        this.ui.showLeftNav = true;
        break;
    }

    if (this.user.signedIn) {
      // then yes we're signed in.
      this.ui.viewSiteAlertEmitter.emit(next.data.transition);
      this.ui.generalNavChangeEmitter.emit(next.data);
      return true;
    }

    return this.user.auth.currentAuthenticatedUser().then((item) => {
        // have a user.
        const signInUserSession = this.user.auth.user.getSignInUserSession();
        // if (signInUserSession) {
        //   let accessTokenExpiry = signInUserSession.getAccessToken().getExpiration() * 1000;     
        //   const expiryTime = new Date(accessTokenExpiry);
        //   console.log(`AuthGuard: Got existing token - expires at ${expiryTime.getHours()}:${String(expiryTime.getMinutes()).padStart(2,'0')}:${String(expiryTime.getSeconds()).padStart(2,'0')}`);
        // }
        this.ui.viewSiteAlertEmitter.emit(next.data.transition);
        this.ui.generalNavChangeEmitter.emit(next.data);
        return true; 
      }).catch((error) => {
        // do not have a user.
        this.ui.showLeftNav = false;
        this.router.navigate(['/signin']);
        return false;
      })
  }
}
