import { FormGroup } from "@angular/forms";
import { phoneMaskitoMask } from "./maskito/phone-mask";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { zipCodeMaskitoMask } from "./maskito/zip-code-mask";
import { MaskTypes } from "src/app/enumerations/enums";
import { MaskitoElementPredicate } from "@maskito/core";
import { devEnv } from "src/app/constants/kenzaconstants";
import { serialNumberMaskitoMask } from "./maskito/serial-number-mask";
import { Component, forwardRef, HostListener, Input } from "@angular/core";
import { 
  numbersOnly,
  macAddressMask,
  setCaretPosition,
  getCaretPosition,
  phoneMaskExtended,
  phoneMaskShortened,
  zipCodeMaskExtended,
  zipCodeMaskPlaceholder,
  serialNumberMaskExtended,
  rmdMacAddressMaskPlaceholder,
  mccSerialNumberMaskPlaceholder,
  zipCodeOrSerialNumberMaskShortened,
  phoneNumberWithExtensionMaskPlaceholder,
} from 'src/app/constants/validators';
import { macAddressMaskitoMask } from "./maskito/mac-address-mask";

@Component({
  selector: 'app-text-mask',
  templateUrl: './text-mask.component.html',
  styleUrls: ['./text-mask.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextMaskComponent),
    }
  ]
})

export class TextMaskComponent {
  type: any;
  mask: any;
  devEnv = devEnv;
  placeholder = ``;
  fieldFocused = false;
  MaskTypes = MaskTypes;

  @Input() control: any;
  @Input() recurly?: any;
  @Input() formGroup: FormGroup;
  @Input() maskPlaceholder: any;
  @Input() formControlName: any;
  @Input() form?: string = `Form`;
  @Input() extraLabel?: string = ``;
  @Input() moveTop?: boolean = true;
  @Input() capitalize?: any = false;
  @Input() required?: boolean = false;
  @Input() labelPlacement?: any = `floating`;

  // Maskito
  maskitoMask;
  @Input() useMaskito = false;
  public phoneMask = phoneMaskitoMask;
  public zipCodeMask = zipCodeMaskitoMask;
  public serialNumberMask = serialNumberMaskitoMask;
  public macAddressMaskitoMask = macAddressMaskitoMask;
  // Finds input inside ion-input
  maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

  extensionLimit = 5;
  phoneExtensionLimit = 11;
  zipOrSerialExtensionLimit = 5;
  macAddressMask = macAddressMask;
  extraClassNames = `extraClassNames`;
  phoneMaskExtended = phoneMaskExtended;
  phoneMaskShortened = phoneMaskShortened;
  zipCodeMaskExtended = zipCodeMaskExtended;
  zipCodePlaceholder = zipCodeMaskPlaceholder;
  serialNumberMaskExtended = serialNumberMaskExtended;
  macAddressPlaceholder = rmdMacAddressMaskPlaceholder;
  phoneTypes = [MaskTypes.phone, MaskTypes.company_phone];
  serialNumberPlaceholder = mccSerialNumberMaskPlaceholder;
  phonePlaceholder = phoneNumberWithExtensionMaskPlaceholder;
  zipOrSerialTypes = [MaskTypes.zip, MaskTypes.serial_number];
  zipCodeOrSerialNumberMaskShortened = zipCodeOrSerialNumberMaskShortened;
  extenderTypes = this.phoneTypes.concat(this.zipOrSerialTypes) || [MaskTypes.phone, MaskTypes.company_phone, MaskTypes.zip, MaskTypes.serial_number];

  constructor() {
    // Empty
  }

  ngOnInit() {
    this.type = MaskTypes[this.formControlName];
    this.control = this.control || this.formGroup.controls[this.formControlName];
    this.required = this.control.validator('')?.required;
    this.capitalize = this.capitalize == true || this.type == MaskTypes.mac_address;
    if (this.phoneTypes.includes(this.type)) {
      this.maskitoMask = this.phoneMask;
      this.extensionLimit = this.phoneExtensionLimit;
      this.extraClassNames = `phoneMaskField ${this.moveTop == true ? `moveTop` : ``}`;
    } else if (this.zipOrSerialTypes.includes(this.type)) {
      if (this.type == MaskTypes.zip) {
        this.maskitoMask = this.zipCodeMask;
        this.extensionLimit = this.zipOrSerialExtensionLimit;
        this.extraClassNames = `zipMaskField ${this.moveTop == true ? `moveTop` : ``}`;
      } else {
        this.maskitoMask = this.serialNumberMask;
        this.extensionLimit = this.zipOrSerialExtensionLimit;
        this.extraClassNames = `serialNumberMaskField ${this.moveTop == true ? `moveTop` : ``}`;
      }
    } else if (this.type == MaskTypes.mac_address) {
      this.maskitoMask = this.macAddressMaskitoMask;
      this.extensionLimit = this.macAddressMask.length;
      this.extraClassNames = `macAddressMaskField ${this.moveTop == true ? `moveTop` : ``}`;
    };
  }

  ngAfterViewInit() {
    // Empty
  }

  // On Keydown
  @HostListener(`keydown`, [`$event`]) onKeyDown(onKeyDownEvent: KeyboardEvent) {
    let value = (<any>onKeyDownEvent.target).value;
    if (this.phoneTypes.includes(this.type) && `Tab` === onKeyDownEvent.key) return;
    let validValue = value == `` || this.phoneTypes.includes(this.type) && (value == this.phonePlaceholder);
    let isPasteAction = (onKeyDownEvent.ctrlKey || onKeyDownEvent.metaKey) && onKeyDownEvent.key?.toLowerCase() == `v`;
    let notANumber = this.phoneTypes.includes(this.type) && (onKeyDownEvent.shiftKey || (onKeyDownEvent.keyCode < 48 || onKeyDownEvent.keyCode > 57)) &&
    (onKeyDownEvent.keyCode < 96 || onKeyDownEvent.keyCode > 105);

    let numsInValue = value?.length;
    if (this.phoneTypes.includes(this.type)) numsInValue = numbersOnly(value)?.length;
    if (validValue && notANumber || (onKeyDownEvent.key === `Backspace` || onKeyDownEvent.key === `Delete`) && (validValue || numsInValue <= 1)) {
      if (!isPasteAction) {
        onKeyDownEvent.preventDefault();
      }
    }
  }
  
  // On Paste
  @HostListener(`paste`, [`$event`]) onPaste(onPasteEvent: ClipboardEvent) {
    const pastedText = onPasteEvent.clipboardData.getData(`text`);
    const firstBlankPosition = pastedText.indexOf(`_`);
    if (this.zipOrSerialTypes.includes(this.type)) this.mask = this.type == MaskTypes.zip ? this.zipCodeMaskExtended : this.serialNumberMaskExtended;
    if (this.phoneTypes.includes(this.type)) {
      this.mask = this.phoneMaskExtended;
      let numValue = (onPasteEvent.target as any).value.replace(/\D/g, ``);
      if (numValue == `1`) {
        (onPasteEvent.target as any).value = ``;
        setCaretPosition(onPasteEvent.target, firstBlankPosition);
      }
    };
  }

  // On Custom Text Mask Update
  writeValue(value: any) {
    if (this.type == MaskTypes.mac_address) {
      this.placeholder = this.macAddressPlaceholder;
      this.mask = this.macAddressMask;
    } else if (this.phoneTypes.includes(this.type)) {
      this.placeholder = this.phonePlaceholder;
      this.mask = this.phoneMaskExtended;
      if (value && value != ``) this.mask = value.length > this.extensionLimit ? this.phoneMaskExtended : this.phoneMaskShortened;
      if (value != `` && !value.includes(`Ext`)) this.mask = this.phoneMaskShortened;
      if (value == ``) this.mask = this.phoneMaskExtended;
    } else if (this.zipOrSerialTypes.includes(this.type)) {
      this.placeholder = this.type == MaskTypes.zip ? this.zipCodePlaceholder : this.serialNumberPlaceholder;
      this.mask = this.type == MaskTypes.zip ? this.zipCodeMaskExtended : this.serialNumberMaskExtended;
      if (value && value != ``) this.mask = value.length > this.extensionLimit ? (this.type == MaskTypes.zip ? this.zipCodeMaskExtended : this.serialNumberMaskExtended) : this.zipCodeOrSerialNumberMaskShortened;
    }
  }
  
  set(e) {
    e.target.nextElementSibling.value = e.target.value;
    this.control.setValue(e.target.value);
    this.check(e);
  }
  
  focus(e) {
    this.fieldFocused = true;
    let numValue = e.target.value.replace(/\D/g, ``);
    if (this.phoneTypes.includes(this.type) && numValue == ``) {
      e.target.value = this.phonePlaceholder;
      setCaretPosition(e.target, 4);
    }
    this.check(e);
  }
  
  check(e, blur?) {
    let value: any;

    if (this.type == MaskTypes.mac_address) {
      value = e.target.value.replace(/[^A-Fa-f0-9]/g, ``);
    } else if (this.zipOrSerialTypes.includes(this.type) || this.phoneTypes.includes(this.type)) {
      value = e.target.value.replace(/\D/g, ``);
    };

    if (value.length == 0) {
      if (!blur || this.fieldFocused) {
        e.target.parentElement.classList.add(`item-has-focus`);
        e.target.parentElement.classList.add(`item-has-value`);
      } else {
        e.target.parentElement.classList.remove(`item-has-focus`);
        e.target.parentElement.classList.remove(`item-has-value`);
      }
    };

    if (this.zipOrSerialTypes.includes(this.type) || this.phoneTypes.includes(this.type)) {
      let firstBlankPosition = e.target.value.indexOf(`_`);
      
      if (this.zipOrSerialTypes.includes(this.type)) {
        if (blur && value.length <= this.extensionLimit || !blur && value.length < this.extensionLimit) {
          this.mask = this.zipCodeOrSerialNumberMaskShortened;
        } else {
          this.mask = this.type == MaskTypes.zip ? this.zipCodeMaskExtended : this.serialNumberMaskExtended;
        };
        if (value.length == this.extensionLimit) {
          e.target.value = value;
          e.target.nextElementSibling.value = value;
        };
      } else if (this.phoneTypes.includes(this.type)) {
        let caretPos = getCaretPosition(e.target);
        if (caretPos == 0 && value == ``) {
          e.target.value = this.phonePlaceholder;
          setCaretPosition(e.target, 4);
        };
        if (blur && value.length <= this.extensionLimit || !blur && value.length < this.extensionLimit || (caretPos == 17 && e.target.value.includes(` Ext ______`))) {
          this.mask = e.target.value.includes(` Ext ______`) ? this.phoneMaskExtended : this.phoneMaskShortened;
          if (caretPos == 17) {
            this.control.setValue(e.target.value.replace(` Ext ______`, ``));
            e.target.value = this.control.value;
            e.target.nextElementSibling.value = this.control.value;
          }
        } else {
          this.mask = this.phoneMaskExtended;
        };
      }

      if (e.target.selectionStart === e.target.value.length && e.data && /\d/.test(e.data) && firstBlankPosition !== -1) {
        let newValue;
        if (this.zipOrSerialTypes.includes(this.type) && /^\d{5}$/.test(e.target.value)) {
          newValue = e.target.value.slice(0, firstBlankPosition) + `-` + e.data + e.target.value.slice(firstBlankPosition + 1);
        } else {
          newValue = e.target.value.slice(0, firstBlankPosition) + e.data + e.target.value.slice(firstBlankPosition + 1);
        }
        e.target.value = newValue;
        e.target.nextElementSibling.value = newValue;
        e.target.setSelectionRange(firstBlankPosition + 1, firstBlankPosition + 1);
      };
    }
  }
  
  blur(e) {
    this.fieldFocused = false;
    let value: any;

    if (this.zipOrSerialTypes.includes(this.type)) {
      value = e.target.value.replace(/\D/g, ``);
      if (value.length == this.extensionLimit) {
        e.target.value = value;
        e.target.nextElementSibling.value = value;
        this.control.setValue(e.target.value);
        e.target.value = e.target.value.replace(/\D/g, ``);
      } else {
        e.target.nextElementSibling.value = e.target.value;
        this.control.setValue(e.target.value);
      }
      if (value.length >= this.extensionLimit) {
        if (this.type == MaskTypes.zip) {
          this.mask = this.zipCodeMaskExtended;
        } else {
          this.mask = this.serialNumberMaskExtended;
        }
      } else {
        this.mask = this.zipCodeOrSerialNumberMaskShortened;
      }
    } else if (this.type == MaskTypes.mac_address) {
      value = e.target.value.replace(/[^A-Fa-f0-9]/g, ``);
      let capitalized = e.target.value.replace(/[a-z]/g, (match: any) => match.toUpperCase());
      e.target.value = capitalized;
    } else if (this.phoneTypes.includes(this.type)) {
      let numValue = e.target.value.replace(/\D/g, ``);
      if (e.target.value.includes(` Ext ______`)) {
        this.mask = this.phoneMaskShortened;
        value = e.target.value.replace(` Ext ______`, ``);
        e.target.value = value;
        e.target.nextElementSibling.value = value;
        this.control.setValue(value);
      } else {
        this.phoneMaskExtended;
      }
      if (numValue == `1`) {
        e.target.value = ``;
        e.target.nextElementSibling.value = e.target.value;
        this.control.setValue(e.target.value);
      };
    } else {
      e.target.nextElementSibling.value = e.target.value;
      this.control.setValue(e.target.value);
    };

    if (this.capitalize && this.capitalize == true) {
      let capitalized = e.target.value.replace(/[a-z]/g, (match: any) => match.toUpperCase());
      e.target.value = capitalized;
    }
  }

  registerOnChange(fn: any) {
    // Store the provided function
  }

  registerOnTouched(fn: any) {
    // Store the provided function
  }

  setDisabledState?(isDisabled: boolean) {
    // Update the disabled status
  }

  // Zip Code Ion Input
  // @ViewChild('postal_code')
  // public set postal_code(value: IonInput) {
  //     if (!value) {
  //       return;
  //     }

  //     value.getInputElement().then(input => this.registerTextMask(input));
  // }

  // private registerTextMask(inputElement: HTMLInputElement) {
  //   const maskedInput = createTextMaskInputElement({
  //     inputElement,
  //     mask: this.zipCodeMask,
  //   });
  //   this.ccForm.controls.postal_code.valueChanges.subscribe(value => {
  //     maskedInput.update(value.replace(/\D/g, ''));
  //   });
  // }

  // checkZipMaskIon(e, blur?) {
  //   let value = e.target.value.replace(/\D/g, '');
  //   if (blur && value.length <= this.extensionLimit || !blur && value.length < this.extensionLimit) {
  //     this.zipCodeMask = this.zipCodeMaskShortened;
  //   } else {
  //     this.zipCodeMask = this.zipCodeMaskExtended;
  //   }
  //   devEnv && console.log(e);
  //   this.registerTextMask(e.target.firstChild);
  // }

  // zipCodeFocusIon(e) {
  //   this.zipCodeFocused = true;
  //   this.checkZipMaskIon(e);
  // }

  // zipCodeBlurIon(e) {
  //   this.zipCodeFocused = false;
  //   this.checkZipMaskIon(e, true);
  // }

  // setIonZipIon(e) {
  //   this.checkZipMaskIon(e);
  // }
}