/* eslint-disable @typescript-eslint/no-explicit-any */
export const WAIT_TIME = 90000;
export const MAPUNITS_WAIT_TIME: number = 20 * 60 * 1000;
export const EQUIPMENT_ALERT_RESET_WAIT_TIME = 60000;
export const CONNECTION_UPDATE_TIMER_INTERVAL = 5000;
export const TOAST_DEFAULT_DURATION = 5000;

export const TOAST_CHECK_YOUR_INTERNET_CONNECTION_MESSAGE =
  'Check your internet connection';
export const TOAST_CHECK_YOUR_INTERNET_CONNECTION_TITLE =
  'Internet Connection Lost';
export const TOAST_CONNECTIVITY_ISSUE =
  'Unable to process your request, please try again later';
export const TOAST_CONNECTIVITY_ISSUE_TITLE = 'Connectivity Issue: 5-0001';
export const TOAST_SUCCESS_TITLE = 'Success';
export const TOAST_GENERAL_ERROR_TITLE = 'Failure';
export const TOAST_VALIDATION_ERROR_TITLE = 'Failed Validation';
export const TOAST_UNABLE_TO_MAP_GATEWAY_MESSAGE =
  'Unable to map units at this time';
export const TOAST_UNABLE_TO_TEST_RUN_MESSAGE =
  'Unable to test run at this time';
export const TOAST_UNABLE_TO_CANCEL_TEST_RUN_MESSAGE =
  'Unable to cancel test run at this time'
export const TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE =
  'Unable to get maintenance data at this time';
export const TOAST_UNABLE_TO_GET_SYSTEM_MONITOR_MESSAGE =
  'Unable to get system monitor data at this time';
export const TOAST_FAILED_TO_DOWNLOAD_CSV =
  'Failed to download csv file.';
export const MODAL_MAINT_UPDATE_UNIT_ERROR =
  'Failed. Please try again after a while.';
export const MODAL_MAINT_UPDATE_UNIT_BLOCK =
  'Press the mapunit button.';
export const TOAST_SUCCESSFULLY_MAPPED_DEVICES =
  'You have successfully mapped all units to this gateway';
export const TOAST_HTTP_403_FORBIDDEN =
  'Access to this resource is not allowed';
export const TOAST_HTTP_403_FORBIDDEN_TITLE = 'Forbidden Access';
export const TOAST_MAINT_BCPORT_CHECK_ERR = 'Check failed.';
export const TOAST_MAINT_REFRIGERANT_CHECK_ERR = 'Check failed.';
export const TOAST_MAINT_REFRIGERANT_DOWNLOAD_ERR = 'Result file acquisition failure.';

export const DEFAULT_SITE = 'default-site';
export const TOAST_CONFIG_FULL_WIDTH: any = {
  positionClass: 'toast-top-full-width',
  disableTimeOut: false,
  timeOut: TOAST_DEFAULT_DURATION,
  tapToDismiss: true,
};
export const TOAST_CONFIG_FULL_WIDTH_SUCCESS: any = {
  positionClass: 'toast-top-full-width-success',
  disableTimeOut: false,
  timeOut: TOAST_DEFAULT_DURATION,
  tapToDismiss: true,
};

export const ACKNOWLEDGE_NOTIFICATION_WAIT_TIME = 60000;
export const ACKNOWLEDGED_NOTIFICATION_DEFAULT_SUCCESS =
  'You have successfully acknowledged a notification';
export const ACKNOWLEDGED_NOTIFICATION_DEFAULT_ERROR =
  'Unable to acknowledge notification, please try again later';
export const ACKNOWLEDGED_GATEWAY_NOTIFICATION_SUCCESS =
  'You have successfully acknowledged this gateway notification';
export const ACKNOWLEDGED_MEMBERSHIP_NOTIFICATION_SUCCESS =
  'You have successfully acknowledged this membership notification';
export const ACKNOWLEDGED_EQUIPMENT_ALERT_NOTIFICATION_SUCCESS =
  'You have successfully acknowledged this membership notification';
export const ACKNOWLEDGED_PAYMENT_ALERT_NOTIFICATION_SUCCESS =
  'You have successfully acknowledged this payment method notification';
export const ACKNOWLEDGED_SUBSCRIPTION_ALERT_NOTIFICATION_SUCCESS =
  'You have successfully acknowledged this membership notification';
export const ACKNOWLEDGED_EQUIPMENT_NOTIFICATION_SUCCESS: string  =
  'You have successfully acknowledged this equipment notification';

export const ERROR_RESET_SUCCESS =
  'You have successfully performed an alarm reset';
export const ERROR_RESET_NO_GATEWAY =
  'Gateway not found; error can not be reset however has been acknowledged.'
export const ERROR_RESET_ERROR =
  'Unable to reset error, please try again later.';
export const JOINED_SITE_SUCCESS =
  'You have successfully joined the site';
export const JOINED_SITE_ALREADY = 'You have already joined this site';
export const CHANGED_PASSWORD_SUCCESS =
  'You have successfully changed your password';

export const MEMBER_NOT_ASSOICATED_WITH_SITE =
  'This member is no longer associated with this site.';
export const GATEWAY_NOT_ASSOCIATED_WITH_SITE =
  'This gateway is no longer associated with this site.';
export const GATEWAY_MOVED_SUCCESS =
  'You have successfully moved a gateway to a different site.';

export const HTTP_ERROR_MSG_DEFAULT =
  'HTTP DEFAULT ERROR MESSAGE GOES HERE';
// export const APP_UNAUTHORIZED_MSG: string = `You do not have the permission to view this page.\nPlease contact the site owner for more information.`;
export const APP_UNAUTHORIZED_MSG = `You do not have the permission to view this information.\nIf you believe you have reached this page in error,\n please click the home button, or contact the site owner for more information.`;

export const OK_MODAL_MAINTENANCE_DATA_TITLE = 'Maintenance Data';
// export const OK_MODAL_MAINTENANCE_DATA_CONTENT: string =
//   'Maintenance data allows a user to download up to 10 days of raw data in a .csv file format.';
export const OK_MODAL_MAINTENANCE_DATA_CONTENT =
  `To analyze maintenance data, you must have the latest version of the software installed on your computer.  It can be downloaded by going to: <a href="https://mylinkdrive.com/" id="myLinkDriveLink">MyLinkDrive.com</a>, search Maintenance Tool, and download the latest software.<br><br>For MCC devices, downloading of .mtdz data for the day may be limited between %% and ^^.`;
export const OK_MODAL_SYSTEM_DATA_TITLE = 'System Diagram';
export const OK_MODAL_SYSTEM_DATA_CONTENT =
  'System diagram generates an animated .gif file.';

export const GATEWAY_GROUP_UNABLE_TO_CREATE =
  'Unable to create the gateway group.';
export const GATEWAY_GROUP_UNABLE_TO_DELETE =
  'Unable to delete the gateway group.';
export const GATEWAY_GROUP_UNABLE_TO_UPDATE =
  'Unable to update the gateway group.';
export const GATEWAY_DETAIL_UNABLE_TO_RETRIEVE =
  'Unable to retrieve site gateway details.';

export const TEST_MODE_AUTO_WARNING_TITLE = 
  'Auto Mode Warning';
export const TEST_MODE_AUTO_WARNING_DETAIL = 
  'When selecting Auto Mode, units are operated in the cooling mode when the outside temperature is #TEMP# or above and in the heating mode when the outside temperature is below #TEMP#. If the Outdoor Unit does not support temperature detection the test will be performed in heating mode.';
export const TEST_MODE_NO_SUITABLE_ODU_TITLE = 
  'No Suitable Outdoor Units Mapped';
export const TEST_MODE_NO_SUITABLE_ODU_DETAIL = 
  'Map units in an RMD class gateway to perform a test run.';

export const INVITE_INVALID_MSG = `Your site invitation link is invalid, \n please contact the Site Owner.`;
export const devEnv: boolean = window.location.host.includes(`dev`) || window.location.host.includes(`local`) || window.location.host.includes(`:8100`) ? true : false;

export const LIMITED_GW_WARNING = "Access to the Limited subscription plan will continue until the expiration date.  If the plan is not upgraded, services to the gateway will be halted.";
export const LICENSED_GW_MESSAGE = "This gateway was paid through a Purchase Order.  To renew your subscription at the time of expiration, convert to a credit card payment, submit a new Purchase Order, or contact your distributor for more information or other options.";

import * as moment from 'moment-timezone';
export const siteTimezones: any[] = [];
import { EXTRA_COUNTRY_TIMEZONES } from '../constants/webservice';
export const defaultTimeZoneName = `America/New_York`;
[ `US`, ...EXTRA_COUNTRY_TIMEZONES ].forEach((country) => {
  const countryTimezones:any[] = moment.tz.zonesForCountry(country).map(timezone => {
    return {
      timezone,
      moment: moment.tz(timezone),
      tz: moment.tz(timezone).format(`z`),
      hour: moment.tz(timezone).format(`h A`),
      utcVal: moment.tz(timezone).format(`Z`),
      time: moment.tz(timezone).format(`h:mm A`),
      zone: timezone.split(`/`)[timezone.split(`/`).length - 1].replace(`_`, ` `),
      value: timezone.split(`/`)[timezone.split(`/`).length - 1].replace(`_`, ` `) + ` (UTC ${moment.tz(timezone).format(`Z`)})`,
      option: timezone.split(`/`)[timezone.split(`/`).length - 1].replace(`_`, ` `) + ` - ` + moment.tz(timezone).format(`z`) + ` - ` + `(UTC ${moment.tz(timezone).format(`Z`)})` + ` - ` + moment.tz(timezone).format(`h:mm A`)
    }
  })
  // append into the siteTimezone list 
  siteTimezones.push(...countryTimezones);
})
// finally sort the list.
siteTimezones.sort((timezoneA, timezoneB) => timezoneA.zone.localeCompare(timezoneB.zone));
export const defaultTimeZone = siteTimezones.filter(timezone => timezone.timezone == defaultTimeZoneName)[0].timezone;