<ion-content main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol me-flex" size="4">
          <ion-title class="me-title-adjustments" color="dark"><h1>Site Dashboard</h1></ion-title>
          <div *ngIf="hasSuspendedGateways" class="me-suspend-warning me-title-adjustments" title="Expired Gateways" (click)="presentSuspendedGatewaysAlert()">
            <ion-icon name="warning"></ion-icon>
          </div>
        </ion-col>
        <ion-col class="dashboardSiteWeatherInfoCol siteWeatherInfoCol" size="4">
          <app-site-weather id="topWeather"></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <!-- Empty For Now -->
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <ion-grid id="dashboardCardsGrid" class="dashboardCardsGrid">
    <ion-row id="dashboardCardsRow" class="dashboardCardsRow automationTesting">

      <ion-col class="indoorGroupStatusCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card id="indoorGroupStatusCard" class="indoorGroupStatusCard" me-dashboard-card>
      
          <ion-item justify-content-center align-items-center style="height: 100%" lines="none" no-padding>
            <ion-label class="me-header me-flex">
              <strong>Indoor Group Status</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" title="To Control" [routerLink]="['/site', site.id, 'control' ]">
              <ion-icon name="arrow-forward-outline" slot="end" me-icon-nav color="black"></ion-icon>
            </div>
          </ion-item>
      
          <ion-card-content me-top-border>
            <ion-grid [hidden]="groupStatusZeroState" class="groupStatusStateContent ion-no-padding align-text">
      
              <ion-segment *ngIf="tempsLoaded" class="selectGroupType ionSegment" color="secondary" name="selectGroupType"
                scrollable title="Click to Select Group Type" [value]="GroupType.IU" #selectGroupType
                (ionChange)="groupTypeChanged($event.detail.value)">
                <ion-segment-button *ngFor="let groupType of groupTypes; let i = index" [value]="groupType"
                  [title]="groupType" [name]="groupType" class="typeSegmentButton" [id]="groupType">
                  <ion-label>{{ groupType }}</ion-label>
                </ion-segment-button>
              </ion-segment>
      
              <ion-row *ngIf="tempsLoaded" class="totalGroupsAndUnits">
                <ion-col style="margin-top: 15px; display: flex; justify-content: center;">
                  <ion-label class="groupNumbers">
                    <strong *ngIf="selectedType == GroupType.IU">
                      {{groupNum}} Groups ({{coilNum}} Total {{ selectedType }})
                    </strong>
                    <strong *ngIf="selectedType == GroupType.LC">
                      {{coilNum}} Total {{ selectedType }}
                    </strong>
                  </ion-label>
                </ion-col>
              </ion-row>
      
              <ion-row *ngIf="tempsLoaded && selectedType == GroupType.IU">
                <ion-col style="margin-top: 10px; display: flex; justify-content: center;">
                  <ion-label class="systemStatus"><strong>Room Temperature</strong></ion-label>
                </ion-col>
              </ion-row>

              <ion-grid *ngIf="tempsLoaded && selectedType == GroupType.IU" class="tempPoints" style="margin-top: 0px;">
                <ion-row class="tempRow">
                  <ion-col class="tempPointLabel"><strong>High Temp:</strong></ion-col>
                  <ion-col><strong class="highTemp">{{user.accountPreferences.temperaturepreference_id ==
                      TemperaturePreferenceEnum.Celsius ? cHigh : fHigh }} <span
                        class="degreeFormLetter">{{user.accountPreferences.temperaturepreference_id ==
                        TemperaturePreferenceEnum.Celsius ? '°C' : '°F' }}</span></strong></ion-col>
                </ion-row>
                <ion-row class="tempRow">
                  <ion-col class="tempPointLabel"><strong>Low Temp:</strong></ion-col>
                  <ion-col><strong class="lowTemp">{{user.accountPreferences.temperaturepreference_id ==
                      TemperaturePreferenceEnum.Celsius ? cLow : fLow }} <span
                        class="degreeFormLetter">{{user.accountPreferences.temperaturepreference_id ==
                        TemperaturePreferenceEnum.Celsius ? '°C' : '°F' }}</span></strong></ion-col>
                </ion-row>
                <ion-row class="tempRow">
                  <ion-col class="tempPointLabel"><strong>Average Temp:</strong></ion-col>
                  <ion-col class="avgTemp"><strong class="avgTemp">{{user.accountPreferences.temperaturepreference_id ==
                      TemperaturePreferenceEnum.Celsius ? cAvg : fAvg }} <span
                        class="degreeFormLetter">{{user.accountPreferences.temperaturepreference_id ==
                        TemperaturePreferenceEnum.Celsius ? '°C' : '°F' }}</span></strong>
                  </ion-col>
                </ion-row>
              </ion-grid>
      
              <ion-grid [hidden]="modeProfileError" class="barChartGrid">
      
                <ion-grid class="powerGrid">
                  <ion-row class="powerTextRow" *ngIf="createdBarGraph && tempsLoaded && gwAvail">
                    <ion-col style="margin-top: 10px; display: flex; justify-content: center;">
                      <ion-label class="systemStatus"><strong>Power</strong>
                      </ion-label>
                    </ion-col>
                  </ion-row>
      
                  <ion-row class="powerBtnRow" *ngIf="createdBarGraph && tempsLoaded && gwAvail">
                    <ion-col class="powerBtnCol" style="margin-top: 5px; display: flex; justify-content: center;">
                      <span class="buttonBadge powerOn"><span>On</span>
                        <ion-badge class="powerOnBadge">{{powerOns}}</ion-badge>
                      </span>
                      <span class="buttonBadge powerOff"><span>Off</span>
                        <ion-badge class="powerOffBadge">{{powerOffs}}</ion-badge>
                      </span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
      
                <ion-row class="currentActiveModes" *ngIf="createdBarGraph && tempsLoaded && gwAvail">
                  <ion-col style="margin-top: 10px; display: flex; justify-content: center;">
                    <ion-label class="systemStatus"><strong>{{ selectedType }} Current Active Mode(s)<span></span></strong>
                    </ion-label>
                  </ion-col>
                </ion-row>
      
                <ion-row [hidden]="!tempsLoaded" id="statusChartRow">
                  <ion-col id="statusChartCol">
                    <div id="statusBarChartContainer" [class]="!createdBarGraph ? 'hideChart' : 'showChart'">
                      <canvas id="statusBarChart" class="chartJS" #statusBarChart></canvas>
                    </div>
                  </ion-col>
                </ion-row>
                <!-- End Bar Chart Grid -->
              </ion-grid>
              <!-- End Group Status Content -->
            </ion-grid>
      
            <ion-row class="groupStatusZeroStateMessage" *ngIf="groupStatusZeroState || modeProfileError">
              <ion-col style="margin-top: 20px; display: flex; justify-content: center;">
                <ion-label class="italicize">
                  <strong class="groupStatusZeroStateMessageInnerContainer">
      
                    <ion-row *ngIf="recentlyMapped || mapLoading; else mapNotLoading">
                      <ion-col style="margin-top: 20px; display: flex; justify-content: center; grid-gap: 10px; align-items: center;">
                        <ion-spinner color="primary" me-spinner-icon name="lines"></ion-spinner>
                        <ion-label style="font-style: italic;">Loading map data...</ion-label>
                      </ion-col>
                    </ion-row>
      
                    <ng-template #mapNotLoading>
                      <ion-row *ngIf="groupStatusLoading">
                        <ion-col style="margin-top: 20px; display: flex; justify-content: center; grid-gap: 10px; align-items: center;">
                          <ion-spinner color="primary" me-spinner-icon name="lines"></ion-spinner>
                          <ion-label style="font-style: italic; font-weight: 400;">Loading data...</ion-label>
                        </ion-col>
                      </ion-row>
                      <strong *ngIf="!groupStatusLoading" class="groupStatusZeroStateInnerMessage" [innerHTML]="groupStatusZeroStateMessage"></strong>
                    </ng-template>
      
                  </strong>
                </ion-label>
              </ion-col>
            </ion-row>
      
            <ion-row *ngIf="(!recentlyMapped || !mapLoading) && (!tempsLoaded || (!createdBarGraph && !modeProfileError))">
              <ion-col style="margin-top: 20px; display: flex; justify-content: center; grid-gap: 10px; align-items: center;">
                <ion-spinner color="primary" me-spinner-icon name="lines"></ion-spinner>
                <ion-label style="font-style: italic;">Loading data...</ion-label>
              </ion-col>
            </ion-row>
      
          </ion-card-content>
      
          <ion-grid id="indoorGroupStatusCardBottom" class="indoorGroupStatusCardBottom cardBottomFooter noIonPadding automationTesting {{ tempsLoaded ? 'cardDataLoaded' : 'cardDataLoading' }}">
            <ion-row class="groupStatusCardFooterText" *ngIf="tempsLoaded && !recentlyMapped && !mapLoading && unitsMapped">
              <ion-col [hidden]="!devEnv || !totalGroups || allProfileUnits == 0 || gwAvail == false" title="Dev Only"
                class="groupTotalCol">
                Total Groups: {{totalGroups}}
              </ion-col>
              <ion-col class="lastUpdatedCol">
                {{lastUpdatedMessage}}: {{groupStatusLastUpdated}}
              </ion-col>
            </ion-row>
          </ion-grid>

        </ion-card>
      </ion-col>

      <ion-col class="siteAlertsCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <app-siteAlerts-card [events]="eventsSubject.asObservable()" me-dashboard-card></app-siteAlerts-card>
      </ion-col>

      <ion-col class="siteWeatherCol dashboardWeatherCard" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <app-siteWeather-card me-dashboard-card></app-siteWeather-card>
      </ion-col>

      <ion-col class="indoorGroupSchedCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card id="indoorGroupSchedCard" class="indoorGroupSchedCard" me-dashboard-card>
      
          <ion-item justify-content-center align-items-center style="height: 100%" lines="none" no-padding>
            <ion-label class="me-header">
              <strong>Indoor Group Schedule</strong>
            </ion-label>
      
            <div class="ion-icon-wrapper-card-header" title="To Schedule" [routerLink]="['/site', site.id, 'schedule']">
              <ion-icon name="arrow-forward-outline" slot="end" me-icon-nav color="black"></ion-icon>
            </div>
          </ion-item>
      
          <ion-card-content me-top-border>
            <ion-grid class="ion-no-padding align-text">
              <ion-row *ngIf="events_loading">
                <ion-col style="margin-top: 20px; display: flex; justify-content: center;">
                  <ion-label>Loading </ion-label>
                  <ion-spinner name="dots"></ion-spinner>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="!events_loading && events_noEventsDefined">
                <ion-col style="margin-top: 20px; display: flex; justify-content: center;">
                  <ion-label class="italicize">
                    <strong>There are no scheduled events for today.</strong>
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="!events_loading && !events_noEventsDefined">
                <ion-col syle="margin-top: 20px; display: flex; justify-content: center;">
                  <ion-label>
                    <strong>You have {{ events_events.length }} scheduled event{{ events_events.length > 1 ? 's' : '' }} today.</strong>
                  </ion-label>
                </ion-col>
              </ion-row>

              <ion-card *ngIf="!events_loading && !events_noEventsDefined" class="schedule-card ion-no-padding ion-no-margin" #scheduleEventItemsContainer>
                <ion-card-content class="ion-no-padding ion-no-margin" me-ion-card-content me-card-min-height me-top-border>
                  <ng-container *ngFor="let ev of events_events; index as idx;">
                    <ng-container *ngIf="{ past: eventInPast(ev.time) } as event">
                      <div class="indoorGroupScheduleEventItem {{ event.past ? 'light' : '' }}" (mouseleave)="onCustomToolTipItemMouseLeaveLogic($event)" #scheduleEventItem>
                        <div class="indoorGroupScheduleEvent" title="{{ ev.group_name }}">
                          <ion-label class="textOverflow tflow95" [ngClass]="{
                            'alert-label-hdr-past': event.past, 
                            'alert-label-hdr': !event.past
                          }">
                            {{ ev.group_name }}
                          </ion-label>
                          <ion-label class="textOverflow tflow95" [ngClass]="{
                            'alert-label-past': event.past, 
                            'alert-label': !event.past
                          }" capitalize>
                            {{ ev.event_description }}
                          </ion-label>
                        </div>
                        <div *ngIf="ev.batchEvents" id="hoverTrigger_for_event_{{ ev.id }}" class="customToolTip customHover" (mouseenter)="onCustomToolTipMouseEnterLogic($event)">
                          <ion-icon class="customToolTipTrigger hoverEventGroupListTrigger" name="list-circle"></ion-icon>
                          <ion-grid class="customToolTipContent hoverListGroups {{ event.past ? 'light' : '' }} groups-{{ ev?.batchEvents?.length }} {{ events_events.length - (idx + 1) < 4 ? 'closeToBottom' : '' }}">
                            <ion-row class="popoverTitle">{{ ev?.batchEvents?.length }} Group(s) in this Event</ion-row>
                            <ion-row class="hoverListGroup textOverflow tflow95" *ngFor="let group of ev?.batchEvents; index as index;">
                              {{ index + 1 }}) {{ group.group_name }}
                            </ion-row>
                          </ion-grid>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ion-card-content>
              </ion-card>

            </ion-grid>
          </ion-card-content>

          <ion-grid id="indoorGroupSchedCardBottom" class="indoorGroupSchedCardBottom cardBottomFooter {{ events_loading ? 'cardDataLoading' : 'cardDataLoaded' }} noIonPadding automationTesting">
            <!-- Empty Container for Automation Locators // Identifiers -->
          </ion-grid>

        </ion-card>
      </ion-col>

      <ion-col class="gatewayConnectionCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card id="gatewayConnectionCard" class="gateway_connection_card" me-dashboard-card>

          <ion-item justify-content-center align-items-center style="height: 100%" lines="none" no-padding>
            <ion-label class="me-header">
              <strong>Gateway Connections</strong>
            </ion-label>

            <div class="ion-icon-wrapper-card-header" title="To Gateways"
              [routerLink]="['/manage', site.id, 'gateways']">
              <ion-icon name="arrow-forward-outline" slot="end" me-icon-nav color="black"></ion-icon>
            </div>
          </ion-item>

          <ion-card-content class="gateway_connection_card_content" me-top-border>
            <ion-grid class="ion-no-padding align-text">
              <ion-row class="gatewaysMappedRow" *ngIf="!gettingConnectionInfo && !groupStatusZeroState && !modeProfileError">
                <ion-col style="margin: 20px 0px; display: flex; justify-content: center;">
                  <ion-label class="mappedGWText" style="font-weight: 600;">{{ totalMappedGateways }} gateway(s) mapped / {{ totalGateways }} gateway(s) registered</ion-label>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="noGatewaysDefined">
                <ion-col style="margin-top: 20px; display: flex; justify-content: center; font-weight: bold;">
                  <ion-label class="italicize">There are no gateways currently registered to this site.</ion-label>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="!noGatewaysDefined && allGatewaysExpiredFlag">
                <ion-col style="margin-top: 20px; display: flex; justify-content: center; font-weight: bold;">
                  <ion-label class="italicize">You have Expired gateway(s) on your site. To view connection information, upgrade your subscription plan.</ion-label>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="gettingConnectionInfo">
                <ion-col style="margin-top: 20px; display: flex; justify-content: center;">
                  <ion-label><i>Checking Connection Status...</i></ion-label>
                </ion-col>
              </ion-row>
              <div class="gateway_connection_icon_container" *ngIf="gatewayConnectionStatus.length != 0 && !allGatewaysExpiredFlag">
                <ion-row>
                  <ion-col>
                    <img style="margin-top: 20px" src="assets/imgs/custom-gateway.svg">
                  </ion-col>
                  <ion-col>
                    <img class="me-conn-dash"
                      *ngIf="gatewayDisconnectedCount==0 && this.siteService.isConnectedToInternet && !this.kenzaLinkFailed"
                      src="assets/imgs/dash-check.svg">
                    <img class="me-conn-dash"
                      *ngIf="gatewayDisconnectedCount!=0 && this.siteService.isConnectedToInternet && !this.kenzaLinkFailed"
                      src=" assets/imgs/dash-error.svg">
                    <!-- Preload Image in DOM so Image is loaded, then hide it in CSS -->
                    <img class="me-conn-dash" id="dashErrorVector" src="assets/imgs/dash-error.svg">
                    <img class="me-conn-dash" id="dashErrVector" src="assets/imgs/dash-err.svg">
                    <!-- Actual Vector Error Icon which will show up when the internet cuts out -->
                    <img class="me-conn-dash hiddenDef"
                      *ngIf="!this.siteService.isConnectedToInternet || this.kenzaLinkFailed" id="dashErrVector"
                      src="assets/imgs/dash-err.svg">
                    <!-- <img class="me-conn-dash" *ngIf="!this.siteService.isConnectedToInternet && this.kenzaLinkFailed"
                      src=" assets/imgs/dash-err.svg"> -->
                  </ion-col>
                  <ion-col>
                    <img src="assets/imgs/cloud-sharp.svg">
                  </ion-col>
                  <ion-col id="dashErrorVectorCol" class="showDef">
                    <img class="me-conn-dash" *ngIf="siteService.isConnectedToInternet && !kenzaLinkFailed"
                      src="assets/imgs/dash-check.svg">
                    <!-- Preload Image in DOM so Image is loaded, then hide it in CSS -->
                    <img class="me-conn-dash" id="dashErrVector" src="assets/imgs/dash-err.svg">
                    <!-- Actual Vector Error Icon which will show up when the internet cuts out -->
                    <img class="me-conn-dash hiddenDef"
                      *ngIf="!this.siteService.isConnectedToInternet || this.kenzaLinkFailed" id="dashErrorVector"
                      src="assets/imgs/dash-error.svg">
                  </ion-col>
                  <ion-col>
                    <img src="assets/imgs/logo-black.svg">
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-label>Gateway</ion-label>
                  </ion-col>
                  <ion-col></ion-col>
                  <ion-col>
                    <ion-label>Gateway<br>Cloud</ion-label>
                  </ion-col>
                  <ion-col></ion-col>
                  <ion-col>
                    <ion-label><span translate='no'>kenza</span><br>cloud</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col style="height: 10px"></ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-label *ngIf="siteService.isConnectedToInternet && gatewayDisconnectedCount==0">
                      <strong>{{gatewayConnectionStatus.length}}/{{totalGateways}} gateway(s) are connected.</strong>
                    </ion-label>
                    <ion-label *ngIf="!siteService.isConnectedToInternet">
                      <stong>Unable to determine gateway connection status.</stong>
                    </ion-label>
                    <div *ngIf="siteService.isConnectedToInternet && gatewayDisconnectedCount > 0">
                      <ion-label [routerLink]="['/manage', site.id, 'gateways']" pointer appHighlightButton
                        me-button-as-link>
                        <strong>{{gatewayTotal}} gateway(s)</strong>
                      </ion-label>
                      <ion-label><strong> {{gatewayDisconnectedCount > 1 ? 'are' : 'are'}} not
                          connected.</strong>
                      </ion-label>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </ion-grid>
          </ion-card-content>

          <ion-grid id="gatewayConnectionsCardBottom" class="gatewayConnectionsCardBottom cardBottomFooter {{ gettingConnectionInfo ? 'cardDataLoading' : 'cardDataLoaded' }} noIonPadding automationTesting">
            <!-- Empty Container for Automation Locators // Identifiers -->
          </ion-grid>

        </ion-card>
      </ion-col>

      <ion-col class="siteInformationCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card id="siteInformationCard" class="siteInformationCard" no-padding me-dashboard-card>
          <ion-item lines="none">
            <ion-label class="me-header">
              <strong>Site Information</strong>
            </ion-label>
            <div (click)="isActionRestricted() ? user.presentActionRestricted(null, null, iconMaintenanceType) : openSiteAdmin()" title="Edit Site"
              [ngClass]="isActionRestricted() ? 'ion-icon-wrapper-card-header site-transfer-disable-modal': 'ion-icon-wrapper-card-header'">
              <ion-icon me-icon-nav
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditSiteDetails)"
                id="create" name="create" class="ion-padding-start" color="black">
              </ion-icon>
            </div>
          </ion-item>
      
          <ion-card-content color="white" me-top-border>
            <ion-grid class="ion-no-padding siteInformationPanel">
              <ion-row>
                <ion-col size="4">
                  <strong>Site Name:</strong>
                </ion-col>
                <ion-col class="textOverflow">
                  {{ user.active.name ? user.active.name : "" }}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="4">
                  <strong>Company:</strong>
                </ion-col>
                <ion-col class="textOverflow">
                  {{ user.active.company ? user.active.company : "" }}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="4">
                  <strong>Address:</strong>
                </ion-col>
                <ion-col>
                  {{ user.active.addressOne ? user.active.addressOne : "" }}<br>
                  {{ user.active.addressTwo ? user.active.addressTwo : "" }}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="4">
                  <strong></strong>
                </ion-col>
                <ion-col>
                  {{user.active.city ? user.active.city : ''}}{{addressComma()}}
                  {{user.active.state ? user.active.state : ''}}
                  {{user.active.zip ? user.active.zip : ''}}
                </ion-col>
              </ion-row>
              <ion-row class="timezoneRow">
                <ion-col size="4">
                  <strong>{{width > 1355 ? 'Site Timezone:' : 'Timezone:'}}</strong>
                </ion-col>
                <ion-col>
                  {{ user.active.timezone ? width > 1460 ? formatTimezoneLong(user.active.timezone) :
                  formatTimezone(user.active.timezone) : "" }}
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="4">
                  <strong>Phone:</strong>
                </ion-col>
                <ion-col>
                  {{ user.active.phone ? user.active.phone : "" }}
                </ion-col>
              </ion-row>
      
              <ion-row>
                <ion-col>
                  <div class="dashboardEmptySpacer {{ user && user?.active && user?.active?.transfer_locked ? 'siteTransferring' : '' }}">
                    <!-- Empty -->
                  </div>
                </ion-col>
              </ion-row>
              
              <ion-row
                *ngIf="gatewayGroups && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.DeactivateSite)">
                <ion-col me-center-grid-col-content>
                  <app-large-icon-button iconTitle="Deactivate Site" iconName="remove-circle-sharp"
                    [iconLocked]="isActionRestricted()" [iconMaintenanceType]="iconMaintenanceType" (iconAction)="deactivate_site()"></app-large-icon-button>
                </ion-col>
              </ion-row>
              <ion-row
                *ngIf="gatewayGroups && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.DeactivateSite)">
                <ion-col me-center-grid-col-content>
                  <strong>
                    <ion-label [ngClass]="isActionRestricted() ? 'site-transfer-lower-opacity' : ''"
                      id="i_label_deactivate_site" name="i_label_deactivate_site">
                      Deactivate Site
                    </ion-label>
                  </strong>
                </ion-col>
              </ion-row>

              <br />

              <ion-row
                *ngIf="gatewayGroups && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.DeactivateSite)">
                <ion-col me-center-grid-col-content class="initSiteTransferButton automationTesting">
                  <app-large-icon-button iconId="i_site_transfer_ownership_button" iconTitle="Transfer Site Ownership"
                    iconName="swap-horizontal" [iconLocked]="isActionRestricted()" [iconMaintenanceType]="iconMaintenanceType" (iconAction)="transfer_site()">
                  </app-large-icon-button>
                </ion-col>
              </ion-row>
              <ion-row
                *ngIf="gatewayGroups && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.DeactivateSite)">
                <ion-col me-center-grid-col-content>
                  <strong>
                    <ion-label [ngClass]="isActionRestricted() ? 'site-transfer-lower-opacity' : ''"
                      id="i_label_transfer_site" name="i_label_transfer_site">
                      Transfer Site Ownership
                    </ion-label>
                  </strong>
                </ion-col>
              </ion-row>

              <ion-row center-objects
                *ngIf="user.active?.transfer_locked && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.DeactivateSite)">
                <ion-button id="cancelTransferSiteButtonDashboard" class="cancelTransferSiteButtonDashboard iBtnCancelTransferSiteDashboard automationTesting" (click)="cancelSiteTransfer()">Cancel Transfer Site Ownership</ion-button>
              </ion-row>
              
            </ion-grid>
          </ion-card-content>

          <ion-grid id="siteInformationCardBottom" class="siteInformationCardBottom cardBottomFooter {{ gatewayGroups ? 'cardDataLoading' : 'cardDataLoaded' }} noIonPadding automationTesting">
            <!-- Empty Container for Automation Locators // Identifiers -->
          </ion-grid>

        </ion-card>
      </ion-col>

      <ion-col class="sitePictureCol" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card id="sitePictureCard" class="sitePictureCard" no-padding me-dashboard-card>
          <ion-item lines="none">
            <ion-label class="me-header">
              <strong>Site Picture</strong>
            </ion-label>
          </ion-item>
      
          <ion-card-content color="white" me-top-border class="align-text">
      
            <ion-grid>
              <ion-row>
                <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
                  <div>
      
                    <img *ngIf="imageUrl.length==0" me-img-account-detail src="../../../../../assets/icon/no-picture.svg">
                    <img *ngIf="includeImgInDOM && imageUrl.length>0" src="{{imageUrl}}"
                    #uploadAccountPicture id="uploadAccountPicture" name="uploadAccountPicture" color="primary">
                  </div>
                </ion-col>
              </ion-row>
      
              <ion-row
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditSiteDetails) ">
                <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
                  <input type="file" #uploadDashboardPictureFile name="uploadDashboardPictureFile"
                    id="uploadDashboardPictureFile" class="fileInput" (click)="on_open_upload_picture($event)"
                    (change)="saveSitePhoto($event)" accept="image/x-png,image/gif,image/jpeg"   />
                  <label for="uploadDashboardPictureFile" appMouseOverOpacity class="fileInputLabel">
                    Upload Picture
                  </label>
                </ion-col>
              </ion-row>
      
              <ion-row
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.EditSiteDetails) && imageUrl.length!==0">
                <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
                  <ion-button class="align-text" fill="clear" (click)="removeSitePhoto()">Remove
                    Picture
                  </ion-button>
                </ion-col>
              </ion-row>
      
              <ion-row>
                <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
                  <ion-label me-validation-error><i>
                      {{uploadDashboardPictureFileMessage}}
                    </i></ion-label>
                </ion-col>
              </ion-row>
      
            </ion-grid>
      
          </ion-card-content>

          <ion-grid id="sitePictureCardBottom" class="sitePictureCardBottom cardBottomFooter {{ user ? 'cardDataLoaded' : 'cardDataLoading' }} noIonPadding automationTesting">
            <!-- Empty Container for Automation Locators // Identifiers -->
          </ion-grid>

        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>