import { Component, Input } from '@angular/core';
import { GatewayUnit } from '../../../manage/components/classes/GatewayUnit';
import { ModalController } from '@ionic/angular';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'app-select-gateway-outdoor-unit',
  templateUrl: './select-gateway-outdoor-unit.component.html',
  styleUrls: ['./select-gateway-outdoor-unit.component.scss'],
})
export class SelectGatewayOutdoorUnitComponent /*implements OnInit*/ {
  @Input() parent_gateway_outdoor_units: GatewayUnit[];

  constructor(private modalController: ModalController, private siteService: SiteService) { }

  // ngOnInit() {
  // }

  select_outdoor_unit(selected_outdoor_unit: GatewayUnit) {

    if (!this.siteService.handleIsConnected())
      return;

    this.modalController.dismiss({
      selected_outdoor_unit: selected_outdoor_unit,
    });

  }

  close() {
    this.modalController.dismiss({
      selected_outdoor_unit: null,
    });

  }

}
