import { PasswordKeyCategories } from "./passwordkeycategories";
import {AbstractControl, ValidationErrors} from "@angular/forms";

export let minDelay = 24;
export let maxDelay = 75;
export let allowedDelays = [];
export let activeField = false;
for (let i = minDelay; i < maxDelay; i++) allowedDelays.push(i);
export let randomDelay = allowedDelays[Math.floor(Math.random() * allowedDelays.length)];

export const numbersOnly = (stringWithNumbers: string) => stringWithNumbers?.replace(/[^\d]/g, ``);
export const lettersAndNumbersOnly = (stringWithLettersAndNumbers: string) => stringWithLettersAndNumbers?.replace(/[^a-zA-Z0-9]/g, ``);

export const zipOrSerialShortMaskPlaceholder = `_____`;
export const zipCodeExtensionMaskPlaceholder = `-____`;
export const phoneNumberMaskPlaceholder = `+1 (___)-___-____`;
export const mccSerialNumberExtensionMaskPlaceholder = `-___`;
export const rmdMacAddressMaskPlaceholder = `__:__:__:__:__:__`;
export const phoneNumberExtensionMaskPlaceholder = ` Ext ______`;
export const zipCodeMaskPlaceholder = zipOrSerialShortMaskPlaceholder + zipCodeExtensionMaskPlaceholder;
export const mccSerialNumberMaskPlaceholder = zipOrSerialShortMaskPlaceholder + mccSerialNumberExtensionMaskPlaceholder;
export const phoneNumberWithExtensionMaskPlaceholder = phoneNumberMaskPlaceholder + phoneNumberExtensionMaskPlaceholder;

export const zipCodeOrSerialNumberMaskShortened = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const serialNumberMaskExtended = [/\d/, /\d/, /\d/, /\d/, /\d/, `-`, /\d/, /\d/, /\d/];
export const zipCodeMaskExtended = [/\d/, /\d/, /\d/, /\d/, /\d/, `-`, /\d/, /\d/, /\d/, /\d/];
export const phoneMaskShortened = [`+`, `1`, ` `, `(`, /\d/, /\d/, /\d/, `)`, ` `, /\d/, /\d/, /\d/, `-`, /\d/, /\d/, /\d/, /\d/];
export const phoneMaskExtended = [`+`, `1`, ` `, `(`, /\d/, /\d/, /\d/, `)`, ` `, /\d/, /\d/, /\d/, `-`, /\d/, /\d/, /\d/, /\d/, ` `, `E`, `x`, `t`, ` `, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const macAddressMask = [/[A-Fa-f0-9]/, /[A-Fa-f0-9]/, `:`, /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, `:`, /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, `:`, /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, `:`, /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, `:`, /[A-Fa-f0-9]/, /[A-Fa-f0-9]/];
export const emailRegex =  /^[a-z0-9_][a-z0-9_+\-.]*@([a-z0-9_-]+\.)+[a-z0-9]{2,}$/i; // from style guide

export const getCaretPosition = (input_elem) => {
  let caret_position = 0;
  if (input_elem.selectionStart || input_elem.selectionStart == 0) { // Standard.
    caret_position = input_elem.selectionStart;
  }
  return (caret_position);
}

// Set Caret Position after a Slight Delay
export const setCaretPosition = (input_elem, caretPosition) => {
  setTimeout(() => {
    if (input_elem.setSelectionRange) {
      input_elem.focus();
      input_elem.setSelectionRange(caretPosition, caretPosition);
    } else if (input_elem.createTextRange) {
      let range = input_elem.createTextRange();
      range.collapse(true);
      range.moveEnd('character', caretPosition);
      range.moveStart('character', caretPosition);
      range.select();
    }
  }, maxDelay);
}

export const CustomValidators = {

  // Phone Set Up Function
  setPhoneField: e => {
    activeField = false;
    let nums = e.target.value.replace(/\D/g, ``).substr(0, 15);
    const hasOne = nums[0] == `1`;
    if (hasOne) nums = nums.substr(1);
    if (nums.length < 1) {
      e.target.value = phoneNumberWithExtensionMaskPlaceholder;
      setCaretPosition(document.activeElement, 4);
    } // Delete Fix
    e.target.addEventListener(`keydown`, event => {
      if (event.keyCode === 8 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement) - 1);
        activeField = true;
      } else if (event.keyCode === 46 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        activeField = true;
        return
      }
    })
  },

  // Phone Mask Function
  maskPhoneField: e => {

    // The Parts of a US Phone Number, with an Extension that Accepts up to 6 Digits
    let nums = e.target.value.replace(/\D/g, '');
    let first3 = nums.substring(1, 4);
    let middle3 = nums.substring(4, 7);
    let final4 = nums.substring(7, 11);
    let ext6 = nums.substring(11, 17);

    // Phone Masking
    if (e.target.classList.contains(`has-focus`)) {
      if (first3[0] && !middle3 && !final4 && !ext6) {
        if (first3[1] && !first3[2]) {
          e.target.value = `+1 ` + '(' + first3[0] + first3[1] + '_' + ') ' + '___' + '-' + '____' + ' Ext ' + '______';
        } else if (first3[1] && first3[2]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + '___' + '-' + '____' + ' Ext ' + '______';
        } else {
          e.target.value = `+1 ` + '(' + first3[0] + '__' + ') ' + '___' + '-' + '____' + ' Ext ' + '______';
        }
      } else if (middle3 && !final4 && !ext6) {
        if (middle3[1] && !middle3[2]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3[0] + middle3[1] + '_' + '-' + '____' + ' Ext ' + '______';
        } else if (middle3[1] && middle3[2]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + '____' + ' Ext ' + '______';
        } else {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3[0] + '__' + '-' + '____' + ' Ext ' + '______';
        }
      } else if (final4 && !ext6) {
        if (final4[1] && !final4[2] && !final4[3]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4[0] + final4[1] + '__' + ' Ext ' + '______';
        } else if (final4[1] && final4[2] && !final4[3]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4[0] + final4[1] + final4[2] + '_' + ' Ext ' + '______';
        } else if (final4[1] && final4[2] && final4[3]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + '______';
        } else {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4[0] + '___' + ' Ext ' + '______';
        }
      } else if (ext6) {
        if (ext6[1] && !ext6[2] && !ext6[3] && !ext6[4] && !ext6[5]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6[0] + ext6[1] + '____';
        } else if (ext6[1] && ext6[2] && !ext6[3] && !ext6[4] && !ext6[5]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6[0] + ext6[1] + ext6[2] + '___';
        } else if (ext6[1] && ext6[2] && ext6[3] && !ext6[4] && !ext6[5]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6[0] + ext6[1] + ext6[2] + ext6[3] + '__';
        } else if (ext6[1] && ext6[2] && ext6[3] && ext6[4] && !ext6[5]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6[0] + ext6[1] + ext6[2] + ext6[3] + ext6[4] + '_';
        } else if (ext6[1] && ext6[2] && ext6[3] && ext6[4] && ext6[5]) {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6;
        } else {
          e.target.value = `+1 ` + '(' + first3 + ') ' + middle3 + '-' + final4 + ' Ext ' + ext6[0] + '_____';
        }
      } else {
        e.target.value = phoneNumberWithExtensionMaskPlaceholder;
        setCaretPosition(document.activeElement, 4);
      }
    } else {
      if (nums.length >= 11) {
        e.target.value = e.target.value.replace(` Ext ______`, ``).replace(`_`, ``);
      }
    }

    // Resetting Cursor
    e.target.addEventListener(`keydown`, event => {
      let currentPos: any = getCaretPosition(event.target);
      if (event.keyCode === 16) { // Shift Key Pressed
        return
      } else if (event.keyCode === 9) { // Tab Key Pressed
        return
      } else if (event.keyCode === 38) { // Up Arrow Key Pressed
        return
      } else if (event.keyCode === 40) { // Down Arrow Key Pressed
        return
      } else if (event.keyCode === 39) { // Right Arrow Key Pressed
        return
      } else if (event.keyCode === 37) { // Left Arrow Key Pressed
        return
      } else if (event.keyCode === 13) { // Enter Key Pressed
        return
      } else if (event.keyCode === 91 || event.keyCode === 93) { // Command Key Pressed
        return
      } else if (event.keyCode === 18) { // Alt Key Pressed
        return
      } else if (event.keyCode === 17) { // Ctrl Key Pressed
        return
      } else if (event.keyCode === 36) { // Home Key Pressed
        return
      } else if (event.keyCode === 35) { // End Key Pressed
        return
      } else if (event.keyCode === 46) { // Delete Key Pressed
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        return
      } else if (event.keyCode === 8) { // Backspace Key Pressed
        if (currentPos === 10 && nums.length === 5 || currentPos === 9 && nums.length === 4) {
          setCaretPosition(event.target, 7);
        } else if (currentPos === 14 && nums.length === 8 || currentPos === 13 && nums.length === 7) {
          setCaretPosition(event.target, 12);
        } else if (currentPos === 23 && nums.length === 12 || currentPos === 22 && nums.length === 11) {
          setCaretPosition(event.target, 17);
        } else {
          setCaretPosition(event.target, currentPos - 1);
        }
      } else if (isNaN(event.key)) { // Non Number Key Pressed
        return
      } else if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) { // A + Ctrl Key Pressed
        return
      } else { // Or The User Is Typing
        if (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 88) { // Copy + Paste + Selection
          if (event.ctrlKey || event.metaKey) {
            event.target.select();
            setTimeout(() => {
              let lastCharOfPhoneNumber = event.target.value.replace(/[0-9]/g, `#`).lastIndexOf(`#`) + 1;
              setCaretPosition(document.activeElement, lastCharOfPhoneNumber);
            }, maxDelay);
            return true;
          }
        }
        if (event.target.value.includes(`_`)) {
          if (currentPos === 0) {
            setCaretPosition(event.target, 7);
          } else if (e.target.value === phoneNumberWithExtensionMaskPlaceholder) {
            setCaretPosition(event.target, 5);
          } else if (currentPos === 6 && nums.length === 3) {
            setCaretPosition(event.target, 9);
          } else if (currentPos === 11 && nums.length === 6) {
            setCaretPosition(event.target, 13);
          } else if (currentPos === 16 && nums.length === 10) {
            setCaretPosition(event.target, 22);
          } else {
            setCaretPosition(event.target, currentPos + 1);
          }
        } else {
          setCaretPosition(event.target, currentPos + 1);
        }
      }
    })

  },

  clearPhoneField: e => {
    setTimeout(() => {
      let nums = e.target.value.replace(/\D/g, '');
      if (e.target.value === phoneNumberWithExtensionMaskPlaceholder) {
        e.target.value = ``;
      } else {
        if (nums.length >= 11) {
          e.target.value = e.target.value.replace(` Ext ______`, ``).replace(`_`, ``);
        }
      }
    }, maxDelay);
  },

  // ZIP Code Set Up Function
  setZipField: e => {
    activeField = false;
    // The Parts of a ZIP Code
    let zipChars = e.target.value.replace(/[^0-9]/g, ``).substring(0, 10);
    if (zipChars.length === 0) {
      e.target.value = zipCodeMaskPlaceholder;
      setCaretPosition(document.activeElement, 0);
    } else {
      if (zipChars.length === 5) {
        e.target.value = e.target.value.replace(/[^0-9]/g, ``);
      }
    } // Delete Fix
    e.target.addEventListener(`keydown`, event => {
      if (event.keyCode === 8 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement) - 1);
        activeField = true;
      } else if (event.keyCode === 46 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        activeField = true;
        return
      }
    })
  },

  // ZIP Code Mask Function
  maskZipField: e => {

    let zipChars = e.target.value.replace(/[^0-9]/g, ``).substring(0, 10);
    let first5 = zipChars.substring(0, 5);
    let ext4 = zipChars.substring(5, 9);

    // Resetting Cursor
    e.target.addEventListener(`keydown`, event => {
      let currentPos: any = getCaretPosition(event.target);
      let lastChar: any = event.target.value.replace(/[0-9]/g, `#`).lastIndexOf(`#`) + 1;
      if (event.keyCode === 8) { // Backspace Key Pressed
        if (zipChars.length === 10 && currentPos === 11) {
          setCaretPosition(event.target, 11);
        } else if (zipChars.length === 6 && currentPos === 7) {
          setCaretPosition(event.target, 5);
        } else {
          setCaretPosition(event.target, currentPos - 1);
        }
      } else if (event.keyCode === 46) { // Delete Key Pressed
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        return
      } else if (event.keyCode === 16) { // Shift Key Pressed
        return
      } else if (event.keyCode === 9) { // Tab Key Pressed
        return
      } else if (event.keyCode === 38) { // Up Arrow Key Pressed
        return
      } else if (event.keyCode === 40) { // Down Arrow Key Pressed
        return
      } else if (event.keyCode === 39) { // Right Arrow Key Pressed
        return
      } else if (event.keyCode === 37) { // Left Arrow Key Pressed
        return
      } else if (event.keyCode === 13) { // Enter Key Pressed
        return
      } else if (event.keyCode === 91 || event.keyCode === 93) { // Command Key Pressed
        return
      } else if (event.keyCode === 18) { // Alt Key Pressed
        return
      } else if (event.keyCode === 17) { // Ctrl Key Pressed
        return
      } else if (event.keyCode === 36) { // Home Key Pressed
        return
      } else if (event.keyCode === 35) { // End Key Pressed
        return
      } else if (isNaN(event.key)) {
        return
      } else if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) { // A + Ctrl Key Pressed
        return
      } else { // Or The User Is Typing
        if (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 88) { // Copy + Paste + Selection
          if (event.ctrlKey || event.metaKey) {
            event.target.select();
            setTimeout(() => {
              let lastCharOfZipCode = event.target.value.replace(/[0-9]/g, `#`).lastIndexOf(`#`) + 1;
              setCaretPosition(document.activeElement, lastCharOfZipCode);
            }, maxDelay);
            return true;
          }
        }
        if (event.target.value.includes(`_`)) {
          if (zipChars.length === 9 && currentPos === 9) {
            setCaretPosition(event.target, 10);
          } else if (zipChars.length === 4 && currentPos === 4) {
            setCaretPosition(event.target, 6);
          } else if (currentPos === 10) {
            setCaretPosition(event.target, lastChar + 1);
          } else {
            setCaretPosition(event.target, currentPos + 1);
          }
        } else {
          setCaretPosition(event.target, currentPos + 1);
        }
      }
    })

    // Masking ZIP Code
    if (e.target.classList.contains(`has-focus`)) {
      if (first5[0] && !ext4) {
        if (first5[1] && !first5[2] && !first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1]}___-____`;
        } else if (first5[1] && first5[2] && !first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1] + first5[2]}__-____`;
        } else if (first5[1] && first5[2] && first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1] + first5[2] + first5[3]}_-____`;
        } else if (first5[1] && first5[2] && first5[3] && first5[4]) {
          e.target.value = `${first5}-____`;
        } else {
          e.target.value = `${first5[0]}____-____`;
        }
      } else if (first5 && ext4[0]) {
        if (ext4[1] && !ext4[2] && !ext4[3]) {
          e.target.value = `${first5}-${ext4[0] + ext4[1]}__`;
        } else if (ext4[1] && ext4[2] && !ext4[3]) {
          e.target.value = `${first5}-${ext4[0] + ext4[1] + ext4[2]}_`;
        } else if (ext4[1] && ext4[2] && ext4[3]) {
          e.target.value = `${first5}-${ext4}`;
        } else {
          e.target.value = `${first5}-${ext4[0]}___`;
        }
      } else {
        e.target.value = zipCodeMaskPlaceholder;
        setCaretPosition(document.activeElement, 0);
      }
    } else {
      if (zipChars.length === 5) {
        e.target.value = e.target.value.replace(/[^0-9]/g, ``);
      }
    }
  },

  // ZIP Code Reset Function
  clearZipField: e => {
    let zipChars = e.target.value.replace(/[^0-9]/g, ``).substring(0, 10);
    if (e.target.value === zipCodeMaskPlaceholder) {
      e.target.value = ``;
    } else {
      if (zipChars.length <= 5) {
        e.target.value = e.target.value.replace(/[^0-9]/g, ``);
      } else {
        e.target.value = e.target.value.replace(/[^0-9-]/g, ``);
      }
    }
  },

  // Serial Number Set Up Function
  setSerialNumberField: e => {
    activeField = false;
    // The Parts of an MCC Serial Number
    let serialNums = e.target.value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 9);
    if (serialNums.length === 0) {
      e.target.value = mccSerialNumberMaskPlaceholder;
      setCaretPosition(document.activeElement, 0);
    } // Delete Fix
    e.target.addEventListener(`keydown`, event => {
      if (event.keyCode === 8 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement) - 1);
        activeField = true;
      } else if (event.keyCode === 46 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        activeField = true;
        return
      }
    })
  },

  // Serial Number Mask Function
  maskSerialNumberField: e => {
    let serialNums = e.target.value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 10);
    let first5 = serialNums.substring(0, 5);
    let ext3 = serialNums.substring(5, 8);
    let ctrl = false;

    // Resetting Cursor
    e.target.addEventListener(`keydown`, event => {
      let currentPos: any = getCaretPosition(event.target);
      let lastChar: any = event.target.value.replace(/[a-zA-Z0-9]/g, `X`).lastIndexOf(`X`) + 1;
      if (event.keyCode === 8) { // Backspace Key Pressed
        if (serialNums.length === 8 && currentPos === 9) {
          setCaretPosition(event.target, 8);
        } else if (serialNums.length === 6 && currentPos === 7) {
          setCaretPosition(event.target, 5);
        } else {
          setCaretPosition(event.target, currentPos - 1);
        }
      } else if (event.keyCode === 46) { // Delete Key Pressed
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        return
      } else if (event.keyCode === 16) { // Shift Key Pressed
        return
      } else if (event.keyCode === 9) { // Tab Key Pressed
        return
      } else if (event.keyCode === 38) { // Up Arrow Key Pressed
        return
      } else if (event.keyCode === 40) { // Down Arrow Key Pressed
        return
      } else if (event.keyCode === 39) { // Right Arrow Key Pressed
        return
      } else if (event.keyCode === 37) { // Left Arrow Key Pressed
        return
      } else if (event.keyCode === 13) { // Enter Key Pressed
        return
      } else if (event.keyCode === 91 || event.keyCode === 93) { // Command Key Pressed
        return
      } else if (event.keyCode === 18) { // Alt Key Pressed
        return
      } else if (event.keyCode === 17) { // Ctrl Key Pressed
        return
      } else if (event.keyCode === 36) { // Home Key Pressed
        return
      } else if (event.keyCode === 35) { // End Key Pressed
        return
      } else if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) { // A + Ctrl Key Pressed
        return
      } else { // Or The User Is Typing
        if (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 88) { // Copy + Paste + Selection
          if (event.ctrlKey || event.metaKey) {
            event.target.select();
            setTimeout(() => {
              let lastCharOfSerial = event.target.value.replace(/[a-zA-Z0-9]/g, `X`).lastIndexOf(`X`) + 1;
              setCaretPosition(document.activeElement, lastCharOfSerial);
            }, maxDelay);
            return true;
          }
        }
        if (event.target.value.includes(`_`)) {
          if (serialNums.length === 4) {
            setCaretPosition(event.target, 6);
          } else if (currentPos === 9) {
            setCaretPosition(event.target, lastChar + 1);
          } else {
            getCaretPosition(event.target) == 5 ? setCaretPosition(event.target, currentPos + 2) : setCaretPosition(event.target, currentPos + 1);
          }
        } else {
          setCaretPosition(event.target, currentPos + 1);
        }
      }
    })

    // Masking Serial Number
    if (e.target.classList.contains(`has-focus`)) {
      if (first5[0] && !ext3) {
        if (first5[1] && !first5[2] && !first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1]}___-___`;
        } else if (first5[1] && first5[2] && !first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1] + first5[2]}__-___`;
        } else if (first5[1] && first5[2] && first5[3] && !first5[4]) {
          e.target.value = `${first5[0] + first5[1] + first5[2] + first5[3]}_-___`;
        } else if (first5[1] && first5[2] && first5[3] && first5[4]) {
          e.target.value = `${first5}-___`;
        } else {
          e.target.value = `${first5[0]}____-___`;
        }
      } else if (first5 && ext3[0]) {
        if (ext3[1] && !ext3[2]) {
          e.target.value = `${first5}-${ext3[0] + ext3[1]}_`;
        } else if (ext3[1] && ext3[2]) {
          e.target.value = `${first5}-${ext3}`;
        } else {
          e.target.value = `${first5}-${ext3[0]}__`;
        }
      } else {
        e.target.value = mccSerialNumberMaskPlaceholder;
        setCaretPosition(document.activeElement, 0);
      }
    }
  },

  // Serial Number Reset Function
  clearSerialNumberField: e => {
    let serialNums = e.target.value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 10);
    if (e.target.value === mccSerialNumberMaskPlaceholder) {
      e.target.value = ``;
    } else {
      if (serialNums.length <= 5) {
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, ``);
      } else {
        e.target.value = e.target.value.replace(/[^0-9a-zA-Z-]/g, ``);
      }
    }
  },

  // MAC Address Set Up Function
  setMacAddressField: e => {
    activeField = false;
    // The Parts of a MAC Address, which can take 17 characters max
    // 12 / 17 of those characters are single digit numbers
    let addressChars = e.target.value.replace(/[^a-fA-F0-9]/g, '').substring(0, 17);
    if (addressChars.length === 0) {
      e.target.value = rmdMacAddressMaskPlaceholder;
      setCaretPosition(document.activeElement, 0);
    } // Delete Fix
    e.target.addEventListener(`keydown`, event => {
      if (event.keyCode === 8 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement) - 1);
        activeField = true;
      } else if (event.keyCode === 46 && !activeField) {
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        activeField = true;
        return
      }
    })
  },

  // MAC Address Mask Function
  maskMacAddressField: e => {
    let addressChars = e.target.value.replace(/[^a-fA-F0-9]/g, '').substring(0, 17);
    let first2 = addressChars.substring(0, 2);
    let next2 = addressChars.substring(2, 4);
    let midLeft2 = addressChars.substring(4, 6);
    let midRight2 = addressChars.substring(6, 8);
    let nextToLast2 = addressChars.substring(8, 10);
    let last2 = addressChars.substring(10, 12);

    // Resetting Cursor
    e.target.addEventListener(`keydown`, event => {
      let currentPos: any = getCaretPosition(event.target);
      let lastChar: any = event.target.value.replace(/[a-fA-F0-9]/g, `X`).lastIndexOf(`X`) + 1;
      if (event.keyCode === 8) { // Backspace Key Pressed
        if (addressChars.length === 11 && currentPos === 16) {
          setCaretPosition(event.target, 14);
        } else if (addressChars.length === 9 && currentPos === 13) {
          setCaretPosition(event.target, 11);
        } else if (addressChars.length === 7 && currentPos === 10) {
          setCaretPosition(event.target, 8);
        } else if (addressChars.length === 5 && currentPos === 7) {
          setCaretPosition(event.target, 5);
        } else if (addressChars.length === 3 && currentPos === 4) {
          setCaretPosition(event.target, 2);
        } else {
          setCaretPosition(event.target, currentPos - 1);
        }
      } else if (event.keyCode === 46) { // Delete Key Pressed
        setCaretPosition(document.activeElement, getCaretPosition(document.activeElement));
        return
      } else if (event.keyCode === 16) { // Shift Key Pressed
        return
      } else if (event.keyCode === 9) { // Tab Key Pressed
        return
      } else if (event.keyCode === 38) { // Up Arrow Key Pressed
        return
      } else if (event.keyCode === 40) { // Down Arrow Key Pressed
        return
      } else if (event.keyCode === 39) { // Right Arrow Key Pressed
        return
      } else if (event.keyCode === 37) { // Left Arrow Key Pressed
        return
      } else if (event.keyCode === 13) { // Enter Key Pressed
        return
      } else if (event.keyCode === 91 || event.keyCode === 93) { // Command Key Pressed
        return
      } else if (event.keyCode === 18) { // Alt Key Pressed
        return
      } else if (event.keyCode === 17) { // Ctrl Key Pressed
        return
      } else if (event.keyCode === 36) { // Home Key Pressed
        return
      } else if (event.keyCode === 35) { // End Key Pressed
        return
      } else if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) { // A + Ctrl Key Pressed
        return
      } else { // Or The User Is Typing
        if (event.keyCode == 67 || event.keyCode == 86 || event.keyCode == 88) { // Copy + Paste + Selection
          if (event.ctrlKey || event.metaKey) {
            event.target.select();
            setTimeout(() => {
              let lastCharOfMacAddress = event.target.value.replace(/[a-fA-F0-9]/g, `X`).lastIndexOf(`X`) + 1;
              setCaretPosition(document.activeElement, lastCharOfMacAddress);
            }, maxDelay);
            return true;
          }
        }
        if (event.target.value.includes(`_`)) {
          if (addressChars.length === 1) {
            setCaretPosition(event.target, 3);
          } else if (addressChars.length === 3) {
            setCaretPosition(event.target, 6);
          } else if (addressChars.length === 5) {
            setCaretPosition(event.target, 9);
          } else if (addressChars.length === 7) {
            setCaretPosition(event.target, 12);
          } else if (addressChars.length === 9) {
            setCaretPosition(event.target, 15);
          } else if (currentPos === 17) {
            setCaretPosition(event.target, lastChar + 1);
          } else {
            setCaretPosition(event.target, currentPos + 1);
          }
        } else {
          setCaretPosition(event.target, currentPos + 1);
        }
      }
    });

    // Masking MAC Address
    if (e.target.classList.contains(`has-focus`)) {
      if (first2[0] && !next2 && !midLeft2 && !midRight2 && !nextToLast2 && !last2) {
        if (first2[1]) {
          e.target.value = `${first2}:__:__:__:__:__`;
        } else {
          e.target.value = `${first2[0]}_:__:__:__:__:__`;
        }
      } else if (first2 && next2[0] && !midLeft2 && !midRight2 && !nextToLast2 && !last2) {
        if (next2[1]) {
          e.target.value = `${first2}:${next2}:__:__:__:__`;
        } else {
          e.target.value = `${first2}:${next2[0]}_:__:__:__:__`;
        }
      } else if (first2 && next2 && midLeft2[0] && !midRight2 && !nextToLast2 && !last2) {
        if (midLeft2[1]) {
          e.target.value = `${first2}:${next2}:${midLeft2}:__:__:__`;
        } else {
          e.target.value = `${first2}:${next2}:${midLeft2[0]}_:__:__:__`;
        }
      } else if (first2 && next2 && midLeft2 && midRight2[0] && !nextToLast2 && !last2) {
        if (midRight2[1]) {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2}:__:__`;
        } else {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2[0]}_:__:__`;
        }
      } else if (first2 && next2 && midLeft2 && midRight2 && nextToLast2[0] && !last2) {
        if (nextToLast2[1]) {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2}:${nextToLast2}:__`;
        } else {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2}:${nextToLast2[0]}_:__`;
        }
      } else if (first2 && next2 && midLeft2 && midRight2 && nextToLast2 && last2[0]) {
        if (last2[1]) {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2}:${nextToLast2}:${last2}`;
        } else {
          e.target.value = `${first2}:${next2}:${midLeft2}:${midRight2}:${nextToLast2}:${last2[0]}_`;
        }
      } else {
        e.target.value = rmdMacAddressMaskPlaceholder;
        setCaretPosition(document.activeElement, 0);
      }
    }
  },

  // MAC Address Reset Function
  clearMacAddressField: e => {
    if (e.target.value === rmdMacAddressMaskPlaceholder) {
      e.target.value = ``;
    }
  },

  // MAC Address Validation
  validateMACAddress: e => {
    const MACAddressRegEx = /^([a-fA-F0-9][a-fA-F0-9]:){5}([a-fA-F0-9][a-fA-F0-9])$/;
    if (e.value) {
      if (MACAddressRegEx.test(e.value)) return null;
      else return { invalidMACAddress: true }
    }
    else return null;
  },

  onMACAddressChange: e => {
    e.target.value = e.target.value.toUpperCase().replace(' ', '').trim();
  },

  trim: (e) => {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  },

  phoneMinLength: form => {
    if (form && form.parent && form.value) {
      const num = form.value.replace(/\D/g, '');
      const min = (num.length && num[0] == '1') ? 11 : 10;
      if (form.dirty && num.length < min && form.value != phoneNumberWithExtensionMaskPlaceholder) return { 'too short': true };
    }
    return null;
  },

  // Phone Format Function
  formatPhone: e => {
    let nums = e.target.value.replace(/\D/g, '').substr(0, 15);
    const hasOne = nums[0] == '1';
    if (hasOne) nums = nums.substr(1);
    const filteredOutput = nums.replace(/^(\d{1,2})?(\d{1})?(\d{1,3})?(\d{1,4})?(\d{1,5})?/,
      function (txt, a, b, c, d, e) {
        let output = ``;
        if (a) output = hasOne ? ` (${a}` : `(${a}`;
        if (b) output = hasOne ? ` (${a}${b}` : `(${a}${b}`;
        if (c) output = hasOne ? ` (${a}${b}) ${c}` : `(${a}${b}) ${c}`;
        if (d) output = hasOne ? ` (${a}${b}) ${c}-${d}` : `(${a}${b}) ${c}-${d}`;
        if (e) output = hasOne ? ` (${a}${b}) ${c}-${d} Ext ${e}` : `(${a}${b}) ${c}-${d} Ext ${e}`;
        return output.replace(/\D@.#&+-]*$/g, '');
      }
    );
    e.target.value = (hasOne ? '1' : '') + filteredOutput;
  },

  matchThreeOfFourCategories: form => {
    // confirm the password supplied matches three of the four required categories.
    if (form) {
      if (form.dirty) {
        const password = form.parent.get('password').value;

        if (password.length == 0) {
          // then its empty, and there is no error.
          return null
        }

        let categories = PasswordKeyCategories(password);

        if (categories < 3) {
          // then we have an error.
          return { 'NotEnoughCategories': true }
        } else {
          // confirm we dont have any bogus characters in here.
          let invalid = false;
          // walk the data looking for invalid password keys
          for (let k of password) {
            if (PasswordKeyCategories(k) == 0) {
              // then no.
              invalid = true;
              break;
            }
          }
          if (invalid) {
            return { 'NotEnoughCategories': true }
          }
        }
      }
    }
    return null;
  },

  matchEmailSubstring: form => {
    // confirm the password field is not a substring of the email 
    if (form && form.parent) {
      const password = form.parent.get('password').value;
      const email = form.parent.get('email').value;
      if (email && password && email.indexOf(password) != -1) {
        return { 'PasswordInEmail': true };
      }
    }
    return null;
  },

  matchPassword: form => {
    // does the password match the confirm?
    // called from 'confirm' validation.

    if (form && form.parent) {
      // get the confirm and the password
      let newPass = form.parent.get('password').value
      let conPass = form.value
      if (newPass != conPass && conPass != '') {
        return { 'PasswordMismatch': true }
      }
    }
    return null
  },

  groupMatchPassword: formGroup => {
    // this validator works with the following options
    //  - new signup dialog
    //  - change password dialog
    //  - forgot password dialog
    //
    //  it confirms the following cases
    //  - does password equal the confirm
    //  - is password a substring of the email/username
    //  - does password equal old (in change form)

    // this is a chance to catch errors between components.

    if (formGroup) {
      const email = formGroup.get('email')
      const newPass = formGroup.get('password')
      const conPass = formGroup.get('confirm')
      const oldPass = formGroup.get('old')

      // check for password mismatch between newPass and conPass
      let updateConPassState = false;
      if (conPass.hasError('PasswordMismatch')) {
        // should it still?
        if (conPass.value == newPass.value) {
          // then it should be cleared.
          updateConPassState = true;
        }
      } else {
        // SHOULD it be on?
        if (conPass.value != newPass.value && conPass.value != '') {
          // then yes poke it.
          updateConPassState = true;
        }
      }

      if (updateConPassState) {
        // have conPass check itself.
        conPass.updateValueAndValidity({ emitEvent: false });
        // trick to update the ion-item color
        setTimeout(() => conPass.setValue(conPass.value, { emitEvent: false }), 0);
      }

      let updateNewPassState = false;
      if (email) {
        // check for password in email substring
        if (newPass.hasError('PasswordInEmail')) {
          // should it still?
          if (newPass.value && email.value && email.value.indexOf(newPass.value) == -1) {
            updateNewPassState = true;
          }
        } else {
          // SHOULD it be on?
          if (newPass.value && email.value && email.value.indexOf(newPass.value) != -1) {
            updateNewPassState = true;
          }
        }
      }

      if (oldPass) {
        // check for newPass equals oldPass - this is during password change.
        if (newPass.hasError('NewIsCurrent')) {
          // should it still?
          if (oldPass.value && newPass.value && oldPass.value != newPass.value) {
            updateNewPassState = true;
          }
        } else {
          // should it now?
          if (oldPass.value && newPass.value && oldPass.value == newPass.value) {
            updateNewPassState = true;
          }
        }
      }

      if (updateNewPassState) {
        // have newPass check itself.
        newPass.updateValueAndValidity({ emitEvent: false });
        // trick to update the ion-item color
        setTimeout(() => newPass.setValue(newPass.value, { emitEvent: false }), 0);
      }

    }
    return null;
  },

  newIsCurrent: form => {
    // is the new password the same as the current password?
    if (form && form.parent) {
      const newPass = form.parent.get('password').value;
      const curPass = form.parent.get('old').value;

      if (newPass && curPass && newPass === curPass) {
        return { 'NewIsCurrent': true }
      }
    }
    return null;
  },

  formatExpiration: e => {
    let [month, year] = e.target.value.split('/');
    let output = '';
    if (year && year.length) {
      month = month.replace(/\D/g, '');
      year = year.replace(/\D/g, '');
      if (year.length == 3 && month.length == 1) {
        month += year.substr(0, 1);
        year = year.substr(1, 2);
      } else if (year.length == 1 && month.length == 2) {
        year = month.substr(1, 1) + year;
        month = month.substr(0, 1);
      }
      output = `${month.substr(0, 2)}/${year.substr(0, 2)}`;
    } else {
      let nums = e.target.value.replace(/\D/g, '');
      let hasSlash = e.target.value.match(/\//g);
      if (nums.length == 3) {
        output = `${nums.substr(0, 1)}/${nums.substr(1, 2)}`;
      } else output = nums;
      if (hasSlash && nums.length) output += '/';
    }
    e.target.value = output;
  },

  verifyExpiry: form => {
    if (form && form.parent && form.value) {
      if (form.value.length != 4 && form.value.length != 5) return { 'too short': true };
      else {
        const [month, year] = form.value.split('/');
        if (parseInt(month) > 12 || parseInt(month) == 0) return { 'invalid month': true };
        else {
          const currentYear = parseInt((new Date).getFullYear().toString().substr(2, 2));
          if (parseInt(year) < currentYear) return { 'invalid year': true };
          else if (parseInt(year) == currentYear && parseInt(month) - 1 < (new Date).getMonth()) return { 'invalid date': true }
        }
        return null;
      }
    }
    return null;
  },

  formatZip: e => {
    const nums = e.target.value.replace(/[^0123456789-]/g, '');
    e.target.value = nums.replace(/(\d{1,5})?(\d{1,4})?/,
      function (txt, a, b) {
        let output = '';
        if (a) output = `${a}`;
        if (b) output += `-${b}`;
        else {
          if (a && a.length === 5 && e.target.value.slice(e.target.value.legnth - 1) === '-') {
            output += '-'
          }
        }
        return output;
      }
    );
  },

  // Validator to make sure the mcc serial follows this format: 27C89-428
  validateMccSerial: e => {
    if (e.value) {
      let mcc_serial = e.value.replace(/[^a-zA-Z0-9-]/g, ``);
      let split = mcc_serial.split("-");
      if (split.length == 1) {
        return { parsingError: "missingDash" };
      } else if (split.length > 2) {
        return { parsingError: "toManyDashs" };
      } else {
        if (split[0].length != 5) {
          return { parsingError: "group1Issue" };
        }
        if (split[1].length != 3) {
          if (split[0].length === 5 && split[1].length === 0) {
            return null
          } else {
            return { parsingError: "group2Issue" };
          }
        }
      }
      return null;
    }
  },

  emailsDontMatch: form => {
    // confirm the password field is not a substring of the email 
    if (form && form.parent) {
      const email1 = !form.parent.get('email1').value ? form.parent.get('email1').value : form.parent.get('email1').value.toLowerCase();
      const email2 = !form.parent.get('email2').value ? form.parent.get('email2').value : form.parent.get('email2').value.toLowerCase();
      if (email2 && email1) {
        if (email1 != email2) {
          return { 'emailsDontMatch': true };
        } 
      } else if (email2) { // email1 empty
        return { 'emailsDontMatch': true };
      }
    }
    return null;
  },

  validateEmail: (e: AbstractControl) : ValidationErrors | null => {
    if (e.value && 'string' === typeof e.value) {
      if (!emailRegex.test(e.value)) return {invalidEmail: true};
    }
    return null;
  },
  
};
