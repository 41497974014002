<ion-header>
  <ion-toolbar>
    <ion-title slot="start" id="i_title_title" name="i_title_title">Select Equipment & Date to Download Data
    </ion-title>
    <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
      <ion-icon slot="end" name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="ion-padding-start" size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12"
        style="margin-top: 20px">
        <ion-label me-ou-label id="i_label_outdoor_unit" name="i_label_outdoor_unit">Gateway:</ion-label>
      </ion-col>

      <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12" style="margin-top: 20px">

        <div id="iBtnSelectODUContainerMaintenanceData" class="iBtnSelectODUContainerMaintenanceData me-float-div-content-right ion-hide-md-down automationTesting">
          <ion-buttons id="iBtnsSelectODUButtonsContainerMaintenanceData" class="iBtnsSelectODUButtonsContainerMaintenanceData automationTesting">
            <ion-button id="i_btn_select_outdoor_unit" class="iBtnSelectODUMaintenanceData automationTesting {{ data_loading ? 'functionDisabled' : 'functionEnabled' }}" name="i_btn_select_outdoor_unit" slot="end" (click)="onSelectGateway()" [ngClass]="data_loading ? 'outdoor-unit-selection-loading' : 'outdoor-unit-selection'" [disabled]="data_loading">
              {{ data_loading ? 'Loading Outdoor Unit List...' : selected_gateway_name }}
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>

        <div id="iBtnSelectODUContainerMaintenanceDataSecond" class="iBtnSelectODUContainerMaintenanceDataSecond me-float-div-content-left ion-hide-md-up ion-padding-bottom automationTesting">
          <ion-buttons id="iBtnsSelectODUButtonsContainerMaintenanceDataSecond" class="iBtnsSelectODUButtonsContainerMaintenanceDataSecond automationTesting">
            <ion-button id="i_btn_select_outdoor_unit" class="iBtnSelectODUMaintenanceDataSecond outdoor-unit-selection automationTesting" name="i_btn_select_outdoor_unit" slot="start" (click)="onSelectGateway()">
              {{ selected_gateway_name }}
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>&nbsp;</ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-date-day-and-range-picker id="date_day_and_range_picker" name="date_day_and_range_picker"
          [(date_day_and_range_picker)]="parent_date_day_and_range_picker">
        </app-date-day-and-range-picker>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-label me-warning-label>
  Generating Maintenance Data can take up to 10 min. Do not close the browser or sign out or you will need to start this
  process over.
</ion-label>

<ion-footer>
  <ion-toolbar>
    <ion-button id="i_btn_ready_for_submission" class="iBtnDownloadMTDZInModal {{ !ready_for_submission() ? 'functionDisabled' : 'functionEnabled' }} automationTesting" name="i_btn_ready_for_submission" (click)="on_generate_maintenance_data()" [disabled]="!ready_for_submission()" style="margin: 0 0 0 1em; width: 100%; padding-right: 25px;" color="primary">
      Download .mtdz
    </ion-button>
  </ion-toolbar>
</ion-footer>