import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { PlanRowTypeEnum } from 'src/app/enumerations/enums';
import { SubscriptionPlanDetail } from 'src/app/features/manage/components/classes/SubscriptionPlanDetail';

@Component({
  selector: 'app-site-gateway-type-compare',
  templateUrl: './site-gateway-type-compare.component.html',
  styleUrls: [
    './site-gateway-type-compare.component.scss',
    '../site-gateway-register/site-gateway-register.page.scss',
  ],
})
export class SiteGatewayTypeCompareComponent implements OnInit {
  planRowTypeEnum: any;

  constructor(
    protected modalController: ModalController,
    protected planService: SubscriptionPlanService,
    protected alertController: AlertController
  ) {
    this.planRowTypeEnum = PlanRowTypeEnum;
  }

  ngOnInit(): void {
    this.planService.getPlans();
  }

  close() {
    this.modalController.dismiss({
    });

  }

  getPlanService() {
    return this.planService;
  }

  async displayInformation(headerText: string, messageText: string) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      cssClass: 'me-info-cp-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
  }

}
