<ion-header>
  <ion-toolbar>
    <ion-title>Edit Company Information</ion-title>
    <!-- <ion-toggle *ngIf="devEnv" class="devToggles devOnlyToggles" [(ngModel)]="useMaskito" [enableOnOffLabels]="true" title="Dev Only Temporary Toggle, Will Remove Later" slot="end" style="padding-right: 20px;">
      Dev Only: Use Maskito
    </ion-toggle> -->
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="accountDetailForm" (ngSubmit)="onSubmit()" id="accountDetailForm">
    <ion-grid>
      <ion-row class="phoneRow">
        <ion-col col col-6>
          <ion-input minlength="2" maxlength="50" type="text" class="form-control" id="company"
              formControlName="company" (ionBlur)="trim($event)" labelPlacement="floating">
              <ion-text slot="label" for="company" color="secondary">Company</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.company.errors">
            Company must be between 2 and 50 characters
          </div>
        </ion-col>
        <ion-col col col-6>
          <app-text-mask formControlName="company_phone" [control]="accountDetailForm.controls.company_phone" [formGroup]="accountDetailForm" [useMaskito]="useMaskito" />
<!--            <div validation-error-->
<!--              *ngIf="accountDetailForm.controls.company_phone.hasError('minlength')  && accountDetailForm.controls.company_phone.dirty">-->
<!--              Minimum length of 10 characters.-->
<!--            </div>-->
          <div validation-error
            *ngIf="!accountDetailForm.controls.company_phone.valid && accountDetailForm.controls.company_phone.dirty">
            Please enter a valid phone number
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
              <ion-input type="text" class="form-control" id="street_line1" formControlName="street_line1"
                 minlength="3" maxlength="50" (ionBlur)="trim($event)" labelPlacement="floating">
                <ion-text slot="label" for="street_line1" color="secondary">Company Address Line 1</ion-text>
              </ion-input>
            <div validation-error *ngIf="accountDetailForm.controls.street_line1.errors">
              Company Address must be between 3 and 50 characters
            </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <ion-input type="text" class="form-control" id="street_line2" formControlName="street_line2"
             minlength="3" maxlength="50" (ionBlur)="trim($event)" labelPlacement="floating">
            <ion-text slot="label" for="street_line2" color="secondary">Company Address Line 2</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.street_line2.errors">
            Company Address must be between 3 and 50 characters
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-4>
          <ion-input type="text" class="form-control" id="city" formControlName="city"
             minlength="2" maxlength="50" (ionBlur)="trim($event)" labelPlacement="floating">
             <ion-text slot="label" for="city" color="secondary">City</ion-text>
          </ion-input>
          <div validation-error *ngIf="accountDetailForm.controls.city.errors">
            City must be between 3 and 50 characters
          </div>
        </ion-col>
        <ion-col col col-4>
          <div lines="none">
            <ion-label position="stacked" me-ion-label color="secondary">State</ion-label>
            <select formControlName="state">
              <option *ngFor="let state of stateList" [value]="state.abbreviation">{{ state.abbreviation }} -
                {{ state.name }}</option>
            </select>
            <div *ngIf="accountDetailForm.controls.state.errors">
              <div validation-error *ngIf="accountDetailForm.controls.state.errors.minlength">
                Please select a state
              </div>
            </div>
          </div>
        </ion-col>
        <ion-col col col-4>
          <app-text-mask formControlName="postal_code" [control]="accountDetailForm.controls.postal_code" [formGroup]="accountDetailForm" [useMaskito]="useMaskito" />
          <div validation-error *ngIf="accountDetailForm.controls.postal_code.errors">
            Zip Code must be 5 or 9 numbers<br>Example:XXXXX-XXXX
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!--Company Information Grid-->
  </form>
</ion-content>

<ion-footer class="ion-margin-top">
  <ion-toolbar>
    <ion-button slot="end" [disabled]="!accountDetailForm.valid || !saveEnabled" class="editCompanyInfoSave automationTesting" (click)="onSubmit()">
      {{ accountDetailText }}
    </ion-button>
    <ion-button slot="end" color="reverse-primary" class="editCompanyInfoCancel ion-padding-horizontal automationTesting" (click)="onCancel()">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>