import { group } from "console"
import { FanSpeed, FanSpeedStages, GatewayUnit } from "src/app/features/manage/components/classes/GatewayUnit"
import { ControlDisplay } from "./controlDisplay"
import { ControlSection } from "./controlSection"

enum addType {
    enabled = 1,
    disabled = 2
}

export class FanSpeedControl extends ControlSection {
    // options to display/control fanspeed for both IC and LC
    none: ControlDisplay            // how to render when fan speed is disabled
    zero: ControlDisplay            // how to render the fan speed 0 button/very low
    one: ControlDisplay             // how to render the fan speed 1 button/low
    two: ControlDisplay             // how to render the fan speed 2 button/medium
    three: ControlDisplay           // how to render the fan speed 3 button/high
    four: ControlDisplay            // how to render the fan speed 4 button/auto

    firstUnit: GatewayUnit           // the first unit added to the batch - used at end
                                    // if all are the same to get a description and icon

    constructor() {
        super();

        this.none = new ControlDisplay
        this.zero = new ControlDisplay;
        this.one = new ControlDisplay;
        this.two = new ControlDisplay;
        this.three = new ControlDisplay;
        this.four = new ControlDisplay;

        this.firstUnit = null;
    }

    
    resetSectionCounters() {
        // set our couters to zero
        this.none.reset()
        this.zero.reset()
        this.one.reset()
        this.two.reset()
        this.three.reset()
        this.four.reset()

        this.firstUnit = null;
    }

    addGroupToBatch(unit: GatewayUnit) {
        // add details of this unit re: mode

        // fan speeds 
        // what is current fanspeed name
        const selectedFanSpeed = this.displayOptions.getFanSpeedDescription(unit).toLowerCase();
        // what fan speeds does this unit support? consider positions 0->4
        const fanSpeedSW = this.displayOptions.getUnitFanSpeedSw(unit)
        const supportedFanSpeeds = [];
        if (fanSpeedSW == FanSpeedStages.none) {
            // special case handler where all modes are disabled.
            this.none.addSelected();
            this.zero.addDisabled();
            this.one.addDisabled();
            this.two.addDisabled();
            this.three.addDisabled();
            this.four.addDisabled();
        } else {
            // if a mode is supprted - non is disabled
            this.none.addDisabled();
            // populate the list of 'names' for supported fan speeds
            [0, 1, 2, 3, 4].forEach((position) => {
                if (this.displayOptions.shouldShowFanSpeed(unit, position)) {
                    if (position == 4) supportedFanSpeeds.push(FanSpeed.auto)
                    else supportedFanSpeeds.push(this.displayOptions.getFanSpeedDescription(unit, position).toLowerCase());
                }
            });
            
            // now determine which of the 5 modes are not supported
            if (!supportedFanSpeeds.includes(selectedFanSpeed)) console.log('Unit is running an unsupported fan Speed!?! ' + unit.id);
            [FanSpeed.very_low, FanSpeed.low, FanSpeed.medium, FanSpeed.high, FanSpeed.auto].forEach((fanSpeed, position) => {
                if (!supportedFanSpeeds.includes(fanSpeed)) {
                    // this one is not supported on this unit
                    this.addCount(position, addType.disabled)
                }
                // and consider if this position is the one currently selected on the unit
                if (fanSpeed == selectedFanSpeed) {
                    // then this is selected
                    this.addCount(position, addType.enabled)
                }
            });
        }

        if (this.firstUnit == null) this.firstUnit = unit;
    }

    addCount(position:number, type:addType) {
        // add an enabled/disabled count to the control at this position
        if (position == 0) type == addType.disabled ? this.zero.addDisabled() : this.zero.addSelected()
        else if (position == 1) type == addType.disabled ? this.one.addDisabled() : this.one.addSelected()
        else if (position == 2) type == addType.disabled ? this.two.addDisabled() : this.two.addSelected()
        else if (position == 3) type == addType.disabled ? this.three.addDisabled() : this.three.addSelected()
        else if (position == 4) type == addType.disabled ? this.four.addDisabled() : this.four.addSelected()
    }

    setDisplayOptions(groupCount:number, maintenanceJobActive: boolean) {
        // called when all groups are added and we should set our controllable/display states

        // set IC mode options - mode
        this.none.setDisplayOptions(groupCount, maintenanceJobActive)
        this.zero.setDisplayOptions(groupCount, maintenanceJobActive)
        this.one.setDisplayOptions(groupCount, maintenanceJobActive)
        this.two.setDisplayOptions(groupCount, maintenanceJobActive)
        this.three.setDisplayOptions(groupCount, maintenanceJobActive)
        this.four.setDisplayOptions(groupCount, maintenanceJobActive)

        const labels = this.getLabelAndIcon(groupCount)
        this.label = labels[0]
        this.label_icon = labels[1]     
    }

    getLabelAndIcon(groupCount:number) {
        // dtermine and return the fanspeed label and icon ti display on the label line
        let label = 'Mixed'
        let icon = ''
        if (this.none.selected 
            || this.zero.selected 
            || this.one.selected 
            || this.two.selected 
            || this.three.selected 
            || this.four.selected) {
                // then we are all the same in the batch
            label = this.displayOptions.getFanSpeedDescription(this.firstUnit);
            icon = this.displayOptions.getFanSpeedPreviewIcon(this.firstUnit);
        }

        return [label,icon]
    }

}
