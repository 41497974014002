import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { MaskitoDirective } from "@maskito/angular";
import { TextMaskModule } from "angular2-text-mask";
import { SharedModule } from "../../modules/shared/shared.module";
import { CreditCardFormComponent } from "../credit-card-form/credit-card-form.component";
import { PlanSelectorComponent } from "./plan-selector.component";

@NgModule({
    declarations: [
        PlanSelectorComponent,
        CreditCardFormComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TextMaskModule,
        SharedModule,
        ReactiveFormsModule,
        MaskitoDirective
    ],
    exports: [
        PlanSelectorComponent,
        CreditCardFormComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
  })
  export class PlanSelectorModule { }