<ion-header>
  <ion-toolbar>
    <ion-title slot="start" id="i_title_title" name="i_title_title">Select Scenario to Start Test Run
    </ion-title>
    <div slot="end" class="ion-icon-wrapper-card-header" (click)="close()" title="Close">
      <ion-icon slot="end" name="close-circle" id="i_icon_close" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="ion-padding-start" me-option-label size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <ion-label me-label id="i_label_outdoor_unit" name="i_label_outdoor_unit">Outdoor Unit(s):</ion-label>
      </ion-col>

      <ion-col me-option-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <div class="me-float-div-content-right">
          <ion-buttons>
            <ion-button id="i_btn_select_outdoor_unit" name="i_btn_select_outdoor_unit" slot="end"
              (click)="onSelectOutdoorUnit()" [ngClass]="data_loading ? 'outdoor-unit-selection-loading' : 'outdoor-unit-selection'" [disabled]="data_loading">
              <div class="oflow oduLabel" title="{{selected_odus_label}}">{{data_loading ? 'Loading Outdoor Unit List...' : selected_odus_label}}</div>
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-padding-start" me-option-label size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
          <ion-label me-label id="i_label_mode" name="i_label_mode">Mode: </ion-label>
          <div class="info-icon" (click)="modeInfo()" title="Information" pointer><ion-icon name="information-circle"></ion-icon></div>
      </ion-col>

      <ion-col me-option-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <div class="me-float-div-content-right">
          <ion-buttons>
            <ion-button id="i_btn_select_mode" name="i_btn_select_mode" slot="end"
              (click)="onSelectMode()" class="outdoor-unit-selection">
              {{selected_mode_label}}
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-padding-start" me-option-label size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <ion-label me-label id="i_label_duration" name="i_label_duration">Duration:</ion-label>
      </ion-col>

      <ion-col me-option-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
        <div class="me-float-div-content-right">
          <ion-buttons>
            <ion-button id="i_btn_select_duration" name="i_btn_select_duration" slot="end"
              (click)="onSelectDuration()" class="outdoor-unit-selection">
              {{selected_duration_label}}
              <ion-icon name="caret-down-outline" me-ou-icon color="dark"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col validation-error>&nbsp;{{error_message}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" me-warning-label>
        <ion-label>When operating in Test Run, units cannot be controlled, and scheduled events will not execute.</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid> 
</ion-content>
<ion-label me-warning-label>
  Do not close the browser or sign out or you will need to start this process over.
</ion-label>

<ion-footer>
  <ion-toolbar>
    <ion-button me-start-btn id="i_btn_ready_for_submission" name="i_btn_ready_for_submission"
      (click)="onPerformTestRun()" [disabled]="!readyForSubmission()" color="primary">
      Start Test Run
    </ion-button>
  </ion-toolbar>
</ion-footer>