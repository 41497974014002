export const Cognito = {
  Auth: {
    identityPoolId: 'us-west-2:15f3916d-51d3-4b10-916b-d732fa542682',
    region: 'us-west-2',
    userPoolId: 'us-west-2_rOIMkKyAR',
    userPoolWebClientId: '1kpbhamrplb3tqe28i1294pvg5',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  Storage: {
    AWSS3: {
      bucket: 'prod1-s3-kcloud-userdata-kzcf',
      region: 'us-west-2'
    }
  },
  Analytics: {
    disabled: true
  }
};
