<ion-header>
  <ion-toolbar>
    <ion-title id="title" name="title">{{steps[currentStep].title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- HEADER -->
  <ion-grid me-header-grid *ngIf="!transferWindowClosed">
    <ion-row class="registration-step-counter ion-justify-content-around">
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= TransferAcceptancePage.MEMBERS ? 'me-current-process-step' : 'me-process-step'" name="person"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= TransferAcceptancePage.MEMBERS ? 'me-current-process-step-text' : 'me-process-step-text'">Manage Members</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">-</ion-col>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= TransferAcceptancePage.SUBSCRIPTION ? 'me-current-process-step' : 'me-process-step'" name="apps"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= TransferAcceptancePage.SUBSCRIPTION ? 'me-current-process-step-text' : 'me-process-step-text'">Select Plan</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">-</ion-col>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= TransferAcceptancePage.PAY ? 'me-current-process-step' : 'me-process-step'" name="card"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= TransferAcceptancePage.PAY ? 'me-current-process-step-text' : 'me-process-step-text'">Pay and Transfer</ion-label>
        </ion-row>
      </ion-col>
      <ion-col class="flex ion-justify-content-center hyphen">-</ion-col>
      <ion-col>
        <ion-row class="ion-justify-content-center">
          <ion-icon [ngClass]="currentStep >= TransferAcceptancePage.SUMMARY ? 'me-current-process-step' : 'me-process-step'" name="list"></ion-icon>
        </ion-row>
        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-label class="process-text-label"
            [ngClass]="currentStep >= TransferAcceptancePage.SUMMARY ? 'me-current-process-step-text' : 'me-process-step-text'">Summary</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid transfer-body>
    <!-- STEP 0 -->
    <div *ngIf="currentStep == TransferAcceptancePage.START">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="secondary">You are starting the process of taking ownership of this site.</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12>
          <p>You will need to select a gateway subscription, enter your payment information for a paid plan,
            and confirm the members and their role on this site.  You will have 24 hours to complete the transfer
            ownership process from the time it was submitted.</p>
          <p>If you do not wish to take ownership, ignore this request and it will be removed in 24 hours.</p>
        </ion-col>
      </ion-row>
    </div>

    <!-- STEP 1 -->
    <div *ngIf="currentStep == TransferAcceptancePage.MEMBERS">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="secondary" *ngIf="accounts.length > 0">Update Site Membership</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12 *ngIf="accounts.length > 0">
          <p>Update current site membership roles before taking ownership. This is for existing members on the site. You can add new site members after you have taken ownership.</p>
        </ion-col>
        <ion-col col col-12 modal-warning-message-container *ngIf="accounts.length == 0">
          <div modal-warning-message-content>
            <p>There are no current members on the site to manage permissions of.</p>
            <p>You can add new site members after taking ownership of the site.</p>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col zero-padding>
          <ion-item class="ion-no-padding" *ngFor="let mv of accounts">
            <ion-card class='member-card-enabled'>
              <ion-grid zero-side-padding id="members-grid">
                <ion-row>
                  <ion-col [ngClass]="mv.deleted ? 'member-card-disabled' : ''" size="12" size-sm="7" zero-padding>
                    <ion-row><ion-col zero-topbot-padding><ion-label><strong>Member</strong></ion-label></ion-col></ion-row>
                    <ion-row *ngIf="mv.name">
                      <ion-col zero-topbot-padding><ion-label>{{mv.name}}</ion-label></ion-col>
                    </ion-row>
                    <ion-row *ngIf="mv.email">
                      <ion-col zero-topbot-padding><ion-label>{{mv.email}}</ion-label></ion-col>
                    </ion-row>
                    <ion-row *ngIf="mv.phoneNum">
                      <ion-col zero-topbot-padding><ion-label>{{mv.phoneNum}}</ion-label></ion-col>
                    </ion-row>
                  </ion-col>
                  <ion-col [ngClass]="mv.deleted ? 'member-card-disabled' : ''" size="12" size-sm="4" zero-padding>
                    <ion-row><ion-col zero-topbot-padding><ion-label><strong>Role</strong></ion-label></ion-col></ion-row>
                    <ion-row>
                      <ion-col zero-topbot-padding>
                        <select (change)="roleChange($event, mv)" level-selector [value]="mv.currRole" [disabled]="mv.deleted">
                          <option value="1">{{levelDisplayText(1)}}</option>
                          <option value="2">{{levelDisplayText(2)}}</option>
                          <option value="3">{{levelDisplayText(3)}}</option>
                        </select>
                      </ion-col>
                    </ion-row>
                    <ion-row *ngIf="mv.isOwner" member-type>
                      <ion-label>*Current Site Owner</ion-label>
                    </ion-row>
                    <ion-row *ngIf="mv.isInvitee" member-type>
                      <ion-label>*Pending Member</ion-label>
                    </ion-row>
                  </ion-col>
                  <ion-col size="12" size-sm="1" zero-padding>
                    <div (click)="toggleMember(mv)" pointer [title]="memberToggleTitle" member-delete-div>
                      <ion-icon *ngIf="!mv.deleted" name="person-remove-sharp" color="primary"></ion-icon>
                      <ion-icon *ngIf="mv.deleted" name="person-add-sharp" color="secondary"></ion-icon>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>

    <!-- Step 2 -->
    <div *ngIf="currentStep == TransferAcceptancePage.SUBSCRIPTION">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label *ngIf="gateways.length > 0" color="secondary">Select a <span translate="no">kenza</span> cloud plan for your gateway(s):</ion-label>
        </ion-col>
      </ion-row>
      <div *ngIf="gateways.length > 0">
        <ion-row>
          <ion-col zero-padding>
            <ion-item class="ion-no-padding">
              <ion-toolbar>
                <ion-buttons slot="end">
                  <ion-button (click)="onComparePlans()" me-button-as-link id="btnClickHere" name="btnClickHere">
                    Click Here to Compare Plans
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col zero-padding>
            <ion-item class="ion-no-padding">
              <ion-grid zero-side-padding center-the-grid>
                <ion-row>
                  <ion-col small-padding size="4"><strong>Gateway Name</strong></ion-col>
                  <ion-col small-padding size="4"><strong>Serial Number</strong></ion-col>
                  <ion-col small-padding-not-right size="4"><strong><span translate="no">kenza</span> cloud Plan</strong></ion-col>
                </ion-row>
                <ion-row *ngFor="let gwv of gateways, index as i">
                  <ion-col small-padding size="3.5">{{gwv.gateway.name}}</ion-col>
                  <ion-col small-padding size="3.5">{{gwv.gateway.serial_number}}</ion-col>
                  <ion-col zero-side-padding size=".5">
                    <ion-icon *ngIf="gwv.gateway.has_coupon" name="pricetag" me-demo-pricetag></ion-icon>
                    <i *ngIf="gwv.gateway.has_license" class="fa-solid fa-ticket-simple"></i>
                  </ion-col>
                  <ion-col small-padding-not-right size="4.5">
                    <app-plan-selector *ngIf="!gwv.gateway.has_license" class="plan-selector" id="plan_selector_{{i}}" [gw]="gwv.device" (selectionChanged)="planChange($event, gwv)"></app-plan-selector>
                    <span *ngIf="gwv.gateway.has_license">{{gwv.gateway.licenses[0]?.type?.name}}</span>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row me-gateway-label>
          <ion-col size="4">
            <strong></strong>
          </ion-col>
          <ion-col size="4">
            <strong>Recurring Charge Total</strong>
            <div *ngIf="!noPaymentNeeded">(<sup validation-error>*</sup> plus applicable taxes)</div>
          </ion-col>
          <ion-col size="4" me-lineup-with-grid>
            <strong>
              {{allPlansSelected ? ( recuringMonthlyTotal | currency ) + '/mo' : '$-.--'}}<sup *ngIf="!noPaymentNeeded"
                validation-error>*</sup>
            </strong>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="partialCoupons" me-gateway-label>
          <ion-col size="1">
          </ion-col>
          <ion-col size="10" col me-header-col me-gateway-label>
            <ion-label>
              <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above have coupons applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="1">
          </ion-col>
        </ion-row>
        <ion-row *ngIf="partialLicenses" me-gateway-label>
          <ion-col size="1">
          </ion-col>
          <ion-col size="10" col me-header-col me-gateway-label>
            <ion-label>
                <i class="fa-solid fa-ticket-simple margin-right "></i><i>Gateway(s) above have licenses applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="1">
          </ion-col>
        </ion-row>
      </div>
      <ion-row *ngIf="gateways.length == 0">
        <ion-col col col-12 modal-warning-message-container>
          <div modal-warning-message-content>
            <p>This site has no gateway to be transferred.</p>
            <p>You can register a gateway after accepting the site transfer.</p>
          </div>
        </ion-col>
      </ion-row>
    </div>

    <!-- Step 3 -->
    <div *ngIf="currentStep == TransferAcceptancePage.PAY">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label *ngIf="gateways.length > 0" color="secondary">
            Confirm the information below{{ !noPaymentNeeded ? ', and' : '' }} <br>
            {{ !noPaymentNeeded ? 'enter a payment method' : ''}} before proceeding.<br>
            <br *ngIf="!noPaymentNeeded">
            {{ !noPaymentNeeded ? 'Your totals will be amended to include applicable taxes after' : ''}}
            <br *ngIf="!noPaymentNeeded">
            {{ !noPaymentNeeded ? ' selecting a payment method or entering billing information.' : ''}}
          </ion-label>
        </ion-col>
      </ion-row>
      <div *ngIf="gateways.length > 0">
        <ion-item me-margin-left-m5>
          <ion-grid class="ion-no-padding" me-margin-left-5>
            <ion-row me-gateway-label>
              <ion-col size="4"><strong>Gateway Name</strong></ion-col>
              <ion-col size="4"><strong>Serial Number</strong></ion-col>
              <ion-col size="4"><strong><span translate="no">kenza</span> cloud Plan</strong></ion-col>
            </ion-row>
            <ion-row *ngFor="let gwv of gateways" id="gatewaySubscriptionRow" me-gateway-label>
              <ion-col size="3.5" me-padding-right-15px>{{ gwv.device.name }}</ion-col>
              <ion-col size="3" class="padding-right-5px">{{ gwv.device.serial_number }}</ion-col>
              <ion-col size="0.5" class="padding-right-5px">
                <ion-icon *ngIf="gwv.gateway.has_coupon" name="pricetag" me-demo-pricetag></ion-icon>
                <i *ngIf="gwv.gateway.has_license" class="fa-solid fa-ticket-simple"></i>
              </ion-col>
              <ion-col  [style.text-decoration]="gwv.gateway.has_coupon ? 'line-through': 'none'" size="5" class="padding-right-5px">
                 <span *ngIf="!gwv.gateway.has_license">{{(planService.plansKeyed[gwv.device.kenza_plan_id]?.monthly_rate | currency) + '/mo' }}</span>
                 <span *ngIf="gwv.gateway.has_license" >{{gwv.gateway.licenses[0]?.type?.name}}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-row *ngIf="!noPaymentNeeded">
          <ion-col>
            <ion-item lines="none">
              <ion-label>Secure Payment Information<ion-icon name="lock-closed">
                </ion-icon>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <app-credit-card-form (formChange)="ccFormValidationChanged($event)"
          *ngIf="!noPaymentNeeded" #cForm></app-credit-card-form>
        <ion-row *ngIf="!noPaymentNeeded">
          <ion-col class="ion-text-start ion-padding-top">
            <p me-label-margin>
              Your method of payment will be charged the prorated amount for the first billing cycle immediately upon
              transfer. You will be charged on the first of each month thereafter.
            </p>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="noPaymentNeeded">
          <ion-col *ngIf="noPaymentNeeded" me-label-margin class="ion-text-start ion-padding-top">You have selected
              <span translate="no">kenza</span> cloud plan(s) that do not require a monthly payment.</ion-col>
        </ion-row>
        <ion-row me-gateway-label>
          <ion-col size="3">
            <string></string>
          </ion-col>
          <ion-col size="5"><strong>Prorated First Month</strong><br>
            <div *ngIf="!noPaymentNeeded">(<sup>*</sup> billed today, approximate value)</div>
          </ion-col>
          <ion-col size="4" me-lineup-with-grid>
            <strong>
              <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
                {{ retrievedPreview ? ( subscriptionProrateTotal | currency ) : '$-.--'}}<sup
                  *ngIf="!noPaymentNeeded">*</sup><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
              </ion-label>
            </strong>
          </ion-col>
        </ion-row>
        <ion-row me-gateway-label>
          <ion-col size="3">
            <string></string>
          </ion-col>
          <ion-col size="5"><strong>Recurring Charge Total</strong><br>

          </ion-col>
          <ion-col size="4" me-lineup-with-grid>
            <strong>
              <ion-label id="lblTotalPerMonth" name="lblTotalPerMonth" id="totalPerMonth" name="total">
                {{ retrievedPreview ? ( subscriptionTotal | currency ) : '$-.--'}}
              </ion-label><ion-spinner *ngIf="prorateCalc" name="lines"></ion-spinner>
            </strong>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="partialCoupons" me-gateway-label>
          <ion-col size="2">
          </ion-col>
          <ion-col col col-8 me-header-col me-gateway-label>
            <ion-label>
              <ion-icon name="pricetag" me-demo-pricetag-footer></ion-icon><i>Gateway(s) above
                have coupons applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="2">
          </ion-col>
        </ion-row>
        <ion-row *ngIf="partialLicenses" me-gateway-label>
          <ion-col size="2">
          </ion-col>
          <ion-col col col-8 me-header-col me-gateway-label>
            <ion-label>
                <i class="fa-solid fa-ticket-simple margin-right "></i><i>Gateway(s) above have licenses applied and are not subject to a recurring monthly subscription cost.</i>
            </ion-label>
          </ion-col>
          <ion-col size="2">
          </ion-col>
        </ion-row>
      </div>
      <ion-row *ngIf="gateways.length == 0">
        <ion-col col col-12 modal-warning-message-container>
          <div modal-warning-message-content>
            <p>This site has no gateway to be transferred.</p>
            <p>You can register a gateway after accepting the site transfer.</p>
          </div>
        </ion-col>
      </ion-row>

    </div>

    <!-- Step 4 -->
    <div *ngIf="currentStep == TransferAcceptancePage.SUMMARY && !transferWindowClosed">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="secondary">You have successfully completed the transfer of site ownership.</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-item-group modal-center-item-list>
            <ion-item>
              <ion-label>Site Successfully Transferred</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item *ngIf="gateways.length > 0">
              <ion-label><span translate="no">kenza</span> cloud Plans Confirmed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item>
              <ion-label>Membership Updated</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="currentStep == TransferAcceptancePage.SUMMARY && transferWindowClosed">
      <ion-row>
        <ion-col col col-12>
          <p>The Transfer Site Ownership process was canceled because it has been revoked by the current site owner or the transfer window has timed out.</p>
        </ion-col>
      </ion-row>
    </div>

  </ion-grid>
</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-label color="danger" *ngIf="errorMessage">{{errorMessage}}</ion-label>
        <div class="transferAcceptanceFooterButtons" *ngIf="currentStep != TransferAcceptancePage.SUMMARY">
          <ion-button id="btnPrevStep" name="btnPrevStep" style="margin: 0 0 0 1em;" color="primary"
            [hidden]="!steps[currentStep].prevBtnShown" (click)="prevStep()">
            {{steps[currentStep].prevBtnText}}</ion-button>
          <ion-button id="btnNextStep" name="btnNextStep" style="margin: 0 0 0 1em;" color="primary"
            [disabled]="!steps[currentStep].nextBtnEnabled" (click)="nextStep()">
            {{steps[currentStep].nextBtnText}}</ion-button>
          <ion-button id="btnCancel" name="btnCancel" (click)="onCancel()" style="margin: 0 0 0 1em;"
            color="reverse-primary">Cancel</ion-button>
        </div>
        <div class="transferAcceptanceFooterButtons" *ngIf="currentStep == TransferAcceptancePage.SUMMARY">
          <ion-button id="btnClose" name="btnClose" (click)="onFinished()" style="margin: 0 0 0 1em;"
            color="reverse-primary">Close</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>