/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/common/services/user/user.service';
import { UntypedFormBuilder } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { AccountService } from 'src/app/features/account/services/accountService';
import { Site } from '../../classes/site';
import { Account } from 'src/app/features/account/classes/account';
import { GatewayModelClass, LevelEnum } from 'src/app/enumerations/enums';
import { SiteGatewayPlansComponent } from '../site-gateway-plans/site-gateway-plans.component';
import { UnregisteredDevice } from 'src/app/features/manage/components/classes/UnregisteredDevice';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { CreditCardFormComponent } from 'src/app/common/components/credit-card-form/credit-card-form.component';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-site-transfer-acceptance',
  templateUrl: './site-transfer-acceptance-modal.page.html',
  styleUrls: ['./site-transfer-acceptance-modal.page.scss']
})

export class SiteTransferAcceptanceModal implements OnInit {
  @ViewChild(CreditCardFormComponent) ccForm: CreditCardFormComponent;
  @Input() siteId: string;
  currentStep: number;
  steps: Array<TransferStep> = [];

  accounts: Array<MemberView> = [];
  gateways: Array<GatewayView> = [];

  LevelEnum = LevelEnum;
  TransferAcceptancePage = TransferAcceptancePage;

  alertOpen = false;
  partialCoupons = false;
  partialLicenses = false;

  memberToggleTitle = "Remove Member";

  transferWindowClosed = false;

  // step 2 display control
  allPlansSelected = false;
  recuringMonthlyTotal = 0;

  // payment page items
  noPaymentNeeded: boolean;  // defines whether a check for a payment method is required.
  retrievedPreview = false; // have we retrieved a preview value from the server yet?
  subscriptionProrateTotal: number;  // prorate preview charge
  subscriptionTotal: number;     // preview total charge
  errorMessage: string;        // any error message from backend to display

  wasAuthError = false;

  // auto process the purchase after get get a new token
  autoProcessAfterToken = false;

  // are we calculating prorate?
  prorateCalc = false;
  devEnv = devEnv;

  constructor(
    public loadingController: LoadingController,
    public formBuilder: UntypedFormBuilder,
    private user: UserService,
    private modalController: ModalController,
    public mainSiteUIService: MainSiteUIService,
    private siteService: SiteService,
    private accountService: AccountService,
    private alertController: AlertController,
    public planService: SubscriptionPlanService, // init to cache plans from backend and to assign license plans
  ) {
    this.makeSteps();
  }

  ngOnInit() {
    this.currentStep = 0;

    this.accountService.getSiteAccounts(this.siteId).toPromise().then(resp =>{
      if (resp['users']) {
        for (let i = 0; i < resp['users'].length; i++) {
          const acc = new Account(resp['users'][i]);
          if (acc.email.toLowerCase() != this.user.username.toLowerCase()) {
            const name = this.getName(acc);
            let mv = null;
            if (acc.level != LevelEnum.Owner) {
              mv = new MemberView(acc.id, acc.email, name, acc.phone, acc.level);
            } else { // current owner
              mv = new MemberView(acc.id, acc.email, name, acc.phone, LevelEnum.Administrator, true);
            }
            this.accounts.push(mv);
          }
        }
      }

      if (resp['invitees']) {
        for (let i = 0; i < resp['invitees'].length; i++) {
          const invite =resp['invitees'][i];
          if (invite.email.toLowerCase() != this.user.username.toLowerCase()) {
            const mv = new MemberView(invite['invite'], invite['email'], null, null, invite['level'], false, true);
            this.accounts.push(mv);
          }
        }
      }
    }).catch(err => {
      console.error(err);
      this.showErrorPage();
      return;
    });

    this.siteService.getSiteDetail(this.siteId).then((siteInfo: any) => {
      const site = new Site(siteInfo);
      const siteGateways = site.gateways;
      siteGateways.forEach((gateway: Gateway) => {
        if (gateway.has_coupon) {
          this.partialCoupons = true;
        }
        if (gateway.licenses.length) {
          this.partialLicenses = true;
        }
        this.gateways.push(new GatewayView(gateway));
      });
    }).catch(err => {
      console.error(err);
      this.showErrorPage();
      return;
    });
  }

  getName(acc: Account) {
    let name = null;
    if (acc.fname && acc.lname) {
      name = `${acc.fname} ${acc.lname}`;
    } else if (acc.fname) {
      name = acc.fname;
    } else if (acc.lname) {
      name = acc.lname;
    }

    return name;
  }

  ionViewWillEnter() {
    this.currentStep = 0; // safty step to reset this on enter, incase of caching
  }

  makeSteps() {
    let title: string, nextBtnEnabled: boolean = null;

    let nextBtnText = "Next";
    const prevBtnText = "Back";

    // Step 0 state message
    title = "Transfer Site Ownership";
    nextBtnEnabled = true;
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, false, prevBtnText))

    // Step 1
    title = "Transfer Site Ownership (Step 1 of 4)";
    nextBtnEnabled = true;
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, true, prevBtnText));

    // Step 2
    title = "Transfer Site Ownership (Step 2 of 4)";
    nextBtnEnabled = false;
    nextBtnText = "Next";
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, true, prevBtnText));

    // Step 3
    title = "Transfer Site Ownership (Step 3 of 4)";
    nextBtnEnabled = false;
    nextBtnText = "Pay and Transfer";
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, true, prevBtnText));

    // Step 4
    title = "Transfer Site Ownership (Step 4 of 4)";
    this.steps.push(new TransferStep(title, null, null, false, null));
  }

  onCancel() {
    this.presentConfirmCancel();
  }

  async dismissAllModals() {
    await this.modalController.dismiss();
    this.mainSiteUIService.siteSelectionOpen = false;
    this.mainSiteUIService.siteTransferAcceptanceOpen = false;
  }

  onFinished() {
    if (this.wasAuthError) {
      this.modalController.dismiss({
        transferSuccessful: false,
        wasAuthError: this.wasAuthError,
      });
    } else {
      this.modalController.dismiss({
        transferSuccessful: true,
        wasAuthError: this.wasAuthError,
      });
      if (this.mainSiteUIService.siteSelectionOpen == true) {
        setTimeout(() => {
          this.dismissAllModals();
        }, 250);
      }
    }
  }

  async nextStep() {
    if (this.currentStep == TransferAcceptancePage.MEMBERS) {
      if (this.gateways.filter(gwv => gwv.gateway.has_license).length == 0) {
        this.steps[this.currentStep+1].nextBtnEnabled = true;
      }
      this.gateways.forEach(gwv => {
        if (gwv.gateway.has_license) { // assign plan for licensed gateways
          const kenzaPlanId = gwv.gateway.gatewaysubscription[0]?.subscriptions?.id;
          const planObj = this.planService.plans.find(obj => obj.kenza_id == kenzaPlanId);
          gwv.device.kenza_plan_id = kenzaPlanId;
          gwv.device.new_subscription_plan_id = kenzaPlanId;
          gwv.device.new_subscription_plan_name = planObj.name;
          gwv.device.subscription_id = kenzaPlanId;
          gwv.plan = planObj.id;
        }
      });
      this.evalPlansSelected(true);
    }

    if (this.currentStep == TransferAcceptancePage.SUBSCRIPTION) {
      if (this.gateways.length == 0) { // nothing to pay
        this.noPaymentNeeded = true;
        this.steps[TransferAcceptancePage.PAY].setNextButtonEnabled(true);
        this.steps[TransferAcceptancePage.PAY].setNextButtonText("Transfer");
      }
      // reset this
      this.getParams = function () { return {}; };
    }

    if (this.currentStep == TransferAcceptancePage.PAY) {
      // then do the job of actually transfer the site on the backend.
      this.transferSite();
      return;
    }

    this.currentStep += 1;
  }

  prevStep() {
    this.errorMessage = '';
    if (this.currentStep == TransferAcceptancePage.MEMBERS) { //reset the membership fields
      this.accounts.forEach(mv => {
        mv.currRole = mv.originalRole;
        mv.deleted = false;
      });
    } else if (this.currentStep == TransferAcceptancePage.SUBSCRIPTION) { // reset plan fields
      for (let i = 0; i < this.gateways.length; i++) {
        const gwv = this.gateways[i];
        gwv.plan = null;
        // reset the value
        gwv.device = gwv.makeDevice();
      }

      this.steps[this.currentStep].nextBtnEnabled = false;
    } else if (this.currentStep == TransferAcceptancePage.PAY) { // reset payment fields
      this.subscriptionProrateTotal = 0;
      this.subscriptionTotal = 0;
      this.steps[this.currentStep].nextBtnEnabled = false;
    }

    if (this.currentStep != 0) this.currentStep -= 1;
  }

  async transferSite() {
    // send message to server reqeusting site transfer
    this.errorMessage = ""; // reset this
    const message_template = '<div>Transferring site...</div><div>(This may take a few minutes)</div>';

    if (this.ccForm && this.ccForm.tokenIsDirty) {
      // then we need a new token.
      this.autoProcessAfterToken = true;
      this.ccForm.submitTokenRequest(true);
      return;
    }

    const load = await this.loadingController.create({
      spinner: `lines`,
      message: message_template,
    });
    load.present();

    const params = {
      site_id: this.siteId,
      accounts:this.accounts,
      gateways: this.gateways,
      ...this.getParams(),
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    this.siteService.acceptTransfer(params).subscribe(result => {
      // did we suceede?
      if (`error` in result) {
        // then no.
        if (result[`error`] == `TRANSFER_WINDOW`) {
          this.transferWindowClosed = true;
          this.currentStep += 1;
        } else {
          this.errorMessage = String(result[`error`]);
        }
      } else {
        // success
        this.currentStep += 1;
      }
      load.dismiss();
    }, error => {
      // error
      this.errorMessage = `Unable to Transfer Site at this time - please try again later.`;
      load.dismiss();
    })
  }

  transferConfirmed(event) {
    if (event.detail.checked) {
      this.steps[this.currentStep].nextBtnEnabled = true;
    } else {
      this.steps[this.currentStep].nextBtnEnabled = false;
    }
  }

  roleChange(event, mv: MemberView) {
    mv.currRole = parseInt(event.target.value);
  }

  /* Set gw plan using selected plan if passed. */
  planChange(plan, gwv: GatewayView) {
    if (plan && gwv){
      if (plan.id == '') {
        gwv.plan = null;
      } else {
        gwv.plan = plan.id;
      }
    }
    this.evalPlansSelected(false);
  }

  /* Evaluate if all plans are set. */
  evalPlansSelected(nextStep: boolean) {
    // see if every GW has a plan now, if so enable the next button
    let nextEnabled = true;
    for (let i = 0; i < this.gateways.length; i++) {
      const gw = this.gateways[i];
      if (!gw.plan) {
        nextEnabled = false;
        break;
      }
    }
    this.steps[this.currentStep + (nextStep ? 1 : 0)].nextBtnEnabled = nextEnabled;

    this.allPlansSelected = nextEnabled;
    this.recuringMonthlyTotal = 0;

    if (nextEnabled) {
      // determine if a payment is required and what the total monthly will be
      let totalCost = 0;
      this.gateways.forEach((gwv: GatewayView) => {
        // dont consider coupon subscriptions - they are free
        if (!gwv.device.has_coupon) {
          totalCost += this.planService.plansKeyed[gwv.device.kenza_plan_id]?.monthly_rate || 0;
        }
      });
      this.noPaymentNeeded = totalCost == 0;
      this.recuringMonthlyTotal = totalCost;
      this.allPlansSelected = true;
      this.steps[TransferAcceptancePage.PAY].setNextButtonText(this.noPaymentNeeded ? `Transfer` : `Pay and Transfer`);
      this.steps[TransferAcceptancePage.PAY].setNextButtonEnabled(this.noPaymentNeeded);

      this.subscriptionProrateTotal = 0;
      this.subscriptionTotal = 0;
      this.retrievedPreview = this.noPaymentNeeded;
    }
  }

  toggleMember(mv: MemberView) {
    if (mv.deleted) {
      mv.deleted = false;
      this.memberToggleTitle = `Remove Member`;
    } else {
      mv.deleted = true;
      this.memberToggleTitle = `Restore Member`;
    }
  }

  levelDisplayText(level) {
    const obj = {
      1: 'Viewer',
      2: 'User',
      3: 'Administrator',
    };
    return obj[level];
  }

  async presentConfirmCancel() {
    if (this.alertOpen == true) return;
    const confirmCancel = await this.alertController.create({
      id: `confirmCancel`,
      backdropDismiss: false,
      cssClass: `alertMsg me-info-button-css`,
      header: `Cancel Transfer Site Ownership`,
      subHeader: `Are you sure you want to cancel?`,
      message: `If you cancel, your information will not be saved.`,
      buttons: [{
        text: `Yes`, role: `ok`, cssClass: `ok-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
          this.modalController.dismiss({
            cancelled: true
          });
        })
      }, {
        text: `No`, role: `cancel`, cssClass: `ok-button cancel-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
        })
      }]
    });
    this.alertOpen = true;
    return await confirmCancel.present();
  }

  async onComparePlans() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',
      backdropDismiss: false,
      componentProps: {
        title: "Compare Subscription Plans",
      }
    });
    return await modal.present();
  }

  showErrorPage() {
    this.transferWindowClosed = true;
    this.currentStep = TransferAcceptancePage.SUMMARY;
    this.wasAuthError = true;
  }

    /**
   * Placeholder function used when requesting previews and charges.
   *
   * DO NOT REMOVE
   * @returns
   */
  getParams() { return {} }

  async ccFormValidationChanged(event) {
    // A change on the credit card form

    const params = {
      token: event.token,
      customer_info: event.customerForm,
      site_id: this.siteId,
      devices: this.gateways.map(({device}) => ({...device})),
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    this.getParams = function () {
      return params;
    }


    if (event.valid) {

      if (this.autoProcessAfterToken) {
        // submit the transfer now that we have a new token.
        this.transferSite();
        return;
      }

      this.prorateCalc = true;

      let preview = null;
      try {
        preview = await this.siteService.getChargePreview(params);
      } catch (error) {
        // we get here if the user isn't admin and the transfer was canceled
        // going to send the user to the warning page bit
        console.error(error);
        this.prorateCalc = false;
        this.showErrorPage();
        return
      }

      if ('error' in preview) {
        // then we failed.
        this.retrievedPreview = false;
        this.subscriptionProrateTotal = 0;
        this.subscriptionTotal = 0;
        // if there is a .. in the error then use before that as the message. Else use stock.
        this.errorMessage = "Please enter a valid Site Address/City/State/Zip Code";
        if (preview.error.includes('..')) {
          this.errorMessage = preview.error.substring(0, preview.error.search('\\.\\.')) + '.';
        }
      } else {
        this.subscriptionProrateTotal = preview.prorate;
        this.subscriptionTotal = preview.monthly;
        this.retrievedPreview = true;
        this.errorMessage = '';
        this.steps[TransferAcceptancePage.PAY].setNextButtonEnabled(true);
      }

      this.prorateCalc = false;
    } else {
      this.retrievedPreview = false;
      this.subscriptionProrateTotal = 0;
      this.subscriptionTotal = 0;
      this.errorMessage = '';
    }

    this.getParams = function () {
      return params;
    }
  }

}

class TransferStep {
  title: string;
  nextBtnEnabled: boolean;
  nextBtnText: string;
  prevBtnShown: boolean;
  prevBtnText: string;

  constructor(title, nextBtnEnabled, nextBtnText, prevBtnShown, prevBtnText) {
    this.title = title;
    this.nextBtnEnabled = nextBtnEnabled;
    this.nextBtnText = nextBtnText;
    this.prevBtnShown = prevBtnShown;
    this.prevBtnText = prevBtnText;
  }

  public setNextButtonText(text:string) {
    this.nextBtnText = text;
  }

  public setNextButtonEnabled(enabled:boolean) {
    this.nextBtnEnabled = enabled;
  }
}

class MemberView {
  id: string;
  email: string;
  name: string;
  phoneNum: string;
  originalRole: LevelEnum;
  currRole: LevelEnum;
  deleted: boolean;

  isOwner: boolean;
  isInvitee: boolean;

  constructor(id, email, name, phoneNum, orginialRole, isOwner = false, isInvitee = false) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.phoneNum = phoneNum;
    this.originalRole = orginialRole
    this.currRole = this.originalRole
    this.deleted = false

    this.isOwner = isOwner;
    this.isInvitee = isInvitee;
  }
}

class GatewayView {
  plan: string | null;
  gateway: Gateway;
  device: UnregisteredDevice;

  constructor(gateway: Gateway) {
    this.plan = null;
    this.gateway = gateway;

    this.device = this.makeDevice();// doing this so the plan selector component will work
  }

  makeDevice() {
    const d = new UnregisteredDevice({
      model_id: this.gateway.model.id,
      model_class_name: this.gateway.model.class_name,
      name: this.gateway.name,
      serial_number: this.gateway.serial_number,
      model_name: null,
      device_key: this.gateway.device_key,
      mac_address: this.gateway.mac_address,
      registration_code : this.gateway.registration_code
    });
    d.has_coupon = this.gateway.has_coupon;
    d.has_license = this.gateway.has_license;
    if (this.gateway.model.class_name == GatewayModelClass.MCC) {
      // then we need to push the device key into the registration_code
      d.registration_code = this.gateway.device_key
    }

    return d;
  }

}

enum TransferAcceptancePage {
  START = 0,
  MEMBERS,
  SUBSCRIPTION,
  PAY,
  SUMMARY
}