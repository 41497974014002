import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountSubscriptionChangePaymentMethodComponent } from '../../components/account-subscription-change-payment-method/account-subscription-change-payment-method.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountGatewayDecommissionComponent } from '../../components/account-gateway-decommission/account-gateway-decommission.component';
import { AccountChangePasswordPage } from '../../components/account-change-password/account-change-password.page';
import { AccountDetailPage } from '../../components/account-detail/account-detail.page';
import { AccountDetailCompanyAdminPage } from '../../components/account-detail-company-admin/account-detail-company-admin.page';
import { AccountComponent } from '../../components/account/account.page';
import { AccountDetailMainComponent } from '../../components/account-detail-main/account-detail-main.component';
import { SharedModule } from 'src/app/common/modules/shared/shared.module';
import { AccountDetailProfileAdminPage } from '../../components/account-detail-profile-admin/account-detail-profile-admin.page';
import { AccountGatewayChangeSubscriptionPlanPage } from '../../components/account-gateway-change-subscription-plan/account-gateway-change-subscription-plan.page';
import { AccountGatewaySubscriptionPlansComponent } from '../../components/account-gateway-subscription-plans/account-gateway-subscription-plans.component';
import { AccountPaymentHistoryComponent } from '../../components/account-payment-history/account-payment-history.component';
import { AccountPaymentMethodsComponent } from '../../components/account-payment-methods/account-payment-methods.component';
import { PlanSelectorModule } from 'src/app/common/components/plan-selector/plan-selector.module';
import { AccountPreferencesAdminComponent } from '../../components/account-preferences-admin/account-preferences-admin.component';
import { AccountCancelComponent } from '../../modals/account-cancel/account-cancel.component';
import { AccountAnalyticsTestRunHistoryComponent } from '../../components/account-analytics-test-run-history/account-analytics-test-run-history.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AccountAnalyticsTestRunViewComponent } from '../../components/account-analytics-test-run-view/account-analytics-test-run-view.component';
import { MatTableModule } from '@angular/material/table';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
    declarations: [
        // Components
        AccountComponent,
        AccountSubscriptionChangePaymentMethodComponent,
        AccountGatewayDecommissionComponent,
        AccountChangePasswordPage,
        AccountDetailPage,
        AccountDetailCompanyAdminPage,
        AccountDetailMainComponent,
        AccountDetailProfileAdminPage,
        AccountGatewayChangeSubscriptionPlanPage,
        AccountGatewaySubscriptionPlansComponent,
        AccountPaymentHistoryComponent,
        AccountPaymentMethodsComponent,
        AccountPreferencesAdminComponent,
        AccountCancelComponent,
        AccountAnalyticsTestRunHistoryComponent,
        AccountAnalyticsTestRunViewComponent
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        PlanSelectorModule,
        TextMaskModule,
        MatTableModule,
        MaskitoDirective
    ]
})
export class AccountModule { }
