import { ChangeDetectorRef, Component,  ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2FlatpickrComponent } from 'ng2-flatpickr';
import { FlatpickrOptions } from 'ng2-flatpickr';
import flatpickr from 'flatpickr/dist/flatpickr.min.js';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { ToastrService } from 'ngx-toastr';
import {
  TOAST_GENERAL_ERROR_TITLE,
  MAPUNITS_WAIT_TIME,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_CONNECTIVITY_ISSUE_TITLE,
  TOAST_CONFIG_FULL_WIDTH,
  TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE,
  TOAST_UNABLE_TO_GET_SYSTEM_MONITOR_MESSAGE,
} from 'src/app/constants/kenzaconstants';
import {
  ToastMessageTypeEnum,
  WebSocketResponseTypeEnum
 } from 'src/app/enumerations/enums';
import { Logger } from 'src/app/common/services/logging/log.service';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';


@Component({
  selector: 'maintenance-MTDZ',
  templateUrl: './maintenance-MTDZ.component.html',
  styleUrls: ['./maintenance-MTDZ.component.scss'],
})
export class MaintenanceMTDZComponent /*implements OnInit*/ {
  @ViewChild('flatpickr') flatpickr?: Ng2FlatpickrComponent;
  @ViewChild('flatpickr2') flatpickr2? :Ng2FlatpickrComponent;

  timeDif = new Date().getTimezoneOffset();                        // Time difference between Coordinated Universal Time and system time
  utcOffset = moment().tz(this.user.active.timezone).utcOffset();  // Time difference between Coordinated Universal Time and property time
  endDatetime = new Date();   // end date and time (For flatpickr setting)
  startDatetime = new Date(); // start date and time（For flatpickr setting）
  paramEndDatetime: string;   // end date and time (param)
  paramStartDatetime: string; // start date and time (param)

  options: FlatpickrOptions = {
    enableTime: true,      // Enable Time Selection
    time_24hr: true,       // Enable 24-hour notation
    dateFormat: 'Y/m/d(D)H:i'
  };
  options02: FlatpickrOptions = {
    enableTime: true,      // Enable Time Selection
    time_24hr: true,       // Enable 24-hour notation
    dateFormat: 'Y/m/d(D)H:i'
  };

  interval;

  socketEventSubscription: Subscription;    // for web socket communications
  maintRequestId: uuid = null;    // Test Run Data Starting Parameters
  gatewayId: string;    // OutDoorUnit's gatewayID
  loadMain;     // For Effects during loading display
  dlMessage: string;   // String for display of DL Result
  dlMessage2: string;   // String for display of DL Result
  dlUrl: string; // String for display of URL
  progress: number;  // indicator(For screen display = integer)
  progressManage;   // indicator(For progress management = floating point number)

  displayedColumns: string[] = ['RadioButton', 'SystemName', 'ModelName', 'SerialNo']; // list of table items
  dataSource = [];
  historyDate: string;
  systemMonitorRequestId: uuid = null;
  timeouttime: number;   // Timer set time (time limit from start of trial run until response is returned). If this is exceeded, an error occurs.
  outdoorUnitList = [];
  groupsList = [];
  systemMonitorInfoList = [];
  target: number;
  s3FileName: string;

  constructor(
    public _ref: ChangeDetectorRef,
    public _router: Router,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    private user: UserService,
    private siteService: SiteService,
    private socketService: SocketService,
    private toastService: ToastrService,
    private logger: Logger
    ) { }

  // ngOnInit(){

  // }

  ionViewWillEnter() {
    // 画面初期表示時処理
    // Processing at initial screen display
    console.log('enter testrun page.');
    this.displayedColumns = ['RadioButton', 'SystemName', 'ModelName', 'SerialNo'];
    this.dataSource = [];
    this.progress = 0;

    // time setting ボタンを非活性にする
    // Deactivate the time setting button
    const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
    setTime[0].disabled = true;

    // OutdoorUnit情報、履歴時刻設定値取得
    // DoorUnit information, acquisition of trial run set values
    this.route.queryParams.subscribe(params => {
      this.gatewayId = params.target;

      // 履歴日時が指定されている場合、指定値に更新
      // If history date and time is specified, update to the specified value
      let historyDate = new Date();
      if ("historyDate" in params){
        historyDate = new Date(params.historyDate);
      }
       
      // タイムゾーン変更
      // Time zone change confirmation
      this.timeDif = new Date().getTimezoneOffset();

      // 取得する履歴時刻 = (指定した履歴時刻 + タイムゾーンの時刻 + １分)
      // History time to obtain = (specified history time + time zone time + 1 minute)
      historyDate.setMinutes(new Date().getMinutes() + this.timeDif + 1);
      this.historyDate = this.date12(this.dateToString(historyDate));
      
      this.siteService.get_site_gateways_with_groups(this.user.active.id, true).subscribe(
        (gateways: GatewayWithGroups) => {
          this.setGateways(gateways);
      });
      this.getSystemMonitor();
    });
  }

  ionViewDidEnter() {
    // MTDZ-URL受信
    // MTDZ-URL reception
    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      this.receiveData(socketResult);
    });
    // クエリパラメータ取得
    // Get query parameter
    this.route.queryParams.subscribe(params => {
      this.gatewayId = params.target;
      this.paramEndDatetime = params.end;
      this.paramStartDatetime = params.start;
    });
    // パラメータ初期化
    // Parameter initialization
    this.dlMessage = '';
    this.dlMessage2 = '';
    this.dlUrl = '';
    this.progress = 0;
    this.progressManage = 0;
    this.s3FileName = '';

    // fpに時刻反映
    // Reflect time on fp
    const fp = this.flatpickr.flatpickr as flatpickr;
    const fp2 = this.flatpickr2.flatpickr as flatpickr;

    if(this.paramEndDatetime && this.paramStartDatetime){
      // spreadsheet画面から遷移した場合はクエリパラメータから開始/終了時刻をセット
      // If transitioned from screen spreadsheet, set start/end time from query parameter
      fp.setDate(new Date(this.date12toDate(this.paramEndDatetime)));
      fp2.setDate(new Date(this.date12toDate(this.paramStartDatetime)));
    }else{
      // メンテナンストップ画面から遷移した場合は現在時刻-63分～現在時刻-3分をセット
      // If transitioned from screen maintenance top, set From Current Time minus 63 minutes To Current Time minus 3 minutes
      this.endDatetime = new Date();
      this.startDatetime = new Date();
      this.endDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3); // initial value of end date and time is Current Time minus 3 minutes
      this.startDatetime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 63);  // initial value of start date and time is Current Time minus 63 minutes
      // 一度文字列変換を通すことで秒以下の情報を切り捨てる
      // ※秒情報が残っていると時刻計算に影響を及ぼすため
      // Truncate sub-second information by passing through string conversion once
      // *Seconds information left over will affect the time calculation.
      const strEndDatetime = this.dateToString(this.endDatetime);
      const strStartDatetime = this.dateToString(this.startDatetime);
      fp.setDate(new Date(strEndDatetime));
      fp2.setDate(new Date(strStartDatetime));
    }
  }

  ionViewWillLeave() {
    clearInterval(this.interval);
    if(this.socketEventSubscription){
      this.socketEventSubscription.unsubscribe();
    }
    if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
  }


  setGateways(gateways: GatewayWithGroups) {
    // OutdoorUnitデータ取得
    // Acquisition of OutdoorUnit data
    let noRMDflg = true;
    let gatewaysWithGroups: Gateway[] = [];

    // リスト初期化
    // List initialization
    this.outdoorUnitList = [];
    this.groupsList = [];

    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True'){
          
          if(gwg.id === this.gatewayId){
            // 室外機の情報を取得する
            // Obtain outdoor unit information
            for (let i = 0; i < gwg.outdoor_units.length; i++) {
              const addOuData = {
                SystemName: gwg.outdoor_units[i].name,
                GatewayId: gwg.id,
                Address: gwg.outdoor_units[i].bus_address,
              };
              this.outdoorUnitList.push(addOuData);
            }
            // 室外機以外の機器の情報を取得する
            // Obtaining information on devices other than the outdoor unit
            for (let i = 0; i < gwg.groups.length; i++){
              const unitList = gwg.groups[i].units;
              for (let j = 0; j < unitList.length; j++){
                const addData = {
                  SystemName: unitList[j].name,
                  GatewayId: gwg.id,
                  Address: unitList[j].bus_address,
                };
                this.groupsList.push(addData);
              }
            }
            noRMDflg = false
          }
        }
      })

      // 対象のRMDが存在しない場合エラー表示
      // Error display if the target RMD does not exist
      if(noRMDflg){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          TOAST_CONNECTIVITY_ISSUE
        );
        return;
      }
    }
  }

  getSystemMonitor(){

    if (!this.siteService.handleIsConnected()) return;

    if (!this.socketService.is_connected()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      console.log('The socket service is not connected');
      return;
    }

    this.systemMonitorRequestId = uuid();

    const obj = {
      gateway_id: this.gatewayId,
      site_id: this.user.active.id,
      request_id: this.systemMonitorRequestId,
      date: this.historyDate,
    }

    // 系統情報データ取得要求
    // System information data acquisition request
    if (!this.socketService.getSystemMonitor(obj)) {
      this.toastService.error(TOAST_UNABLE_TO_GET_SYSTEM_MONITOR_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
    }
  }

  setTime() {
    /* TimeSettingボタン押下時処理 */
    /* Processing when TimeSetting button is pressed */

    this.dlMessage = '';
    this.dlMessage2 = '';
    this.dlUrl = '';
    this.progress = 0;
    this.progressManage = 0;
    this.s3FileName = '';

    const fp = this.flatpickr.flatpickr as flatpickr;
    const fp2 = this.flatpickr2.flatpickr as flatpickr;

    // タイムゾーン変更確認
    // Time zone change confirmation
    this.timeDif = new Date().getTimezoneOffset();
    this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();

    const nowTime = new Date();
    nowTime.setMinutes(new Date().getMinutes() + this.timeDif + this.utcOffset - 3);

    // 選択日時が未来の場合エラー
    // Error if the selected date/time is in the future
    if(nowTime < fp.selectedDates[0]){
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'Future date and time are selected.'
      );
      return;
    }

    // 時刻未選択の場合エラー
    // Error when time is not selected
    if(!fp.selectedDates[0] || !fp2.selectedDates[0]){
      // alert('時刻を選択してください');
      // alert('Please select a time');
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'Please select a date and time.'
      );
      return;
    }

    // 下限時刻＞上限時刻の場合エラー
    // Error if lower time limit > upper limit time
    if(fp.selectedDates[0] - fp2.selectedDates[0] < 0){
      // alert('終了日時は開始日時よりあとの日時を指定してください');
      // alert('End date and time must be after the start date and time');
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'The end date and time must be after the start date and time.'
      );
      return;
    }

    // 指定期間＞5日間(120時間)の場合エラー
    // Error if Specified period > 5 days(120 hours)
    if((fp.selectedDates[0] - fp2.selectedDates[0]) / 1000 / 60 / 60 > 120){
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        'Please select a period of up to 5 days.'
      );
      return;
    }

    // 進捗インジケータ増分算出
    // トータルの処理時間：(終了日時- 開始日時 [秒]) / 1200 (小数点切り上げ) ※期間が3600秒(60分)なら3秒、60秒(1分)なら0.05秒→切り上げで1秒、Min 1秒～MAX 360秒
    // 1秒あたりの増分：100 / 処理時間[秒] (浮動小数点数);
    // Progress Indicator Incremental Calculation
    // Total processing time: (End date and time - Start date and time [second]) / 1200 (rounding up decimal points) *Duration is 3600 seconds(60 minutes) then 3 seconds、60 seconds(1 minutes) then 0.05 seconds -> 1 seconds by rounding up, Min 1 seconds ~ MAX 360 seconds
    // Increment per second: 100 / processing time[second] (floating-point number);
    let totalTime = Math.ceil((fp.selectedDates[0] - fp2.selectedDates[0]) / 1000 / 1200);
    if(totalTime === 0) totalTime = 1;
    const increment = 100 / totalTime;

    // time setting ボタンを非活性にする
    // Deactivate the time setting button
    const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
    setTime[0].disabled = true;

    this.present_loadMain();
    this.progressTimer(increment);

    // データ取得API呼出
    // Data acquisition API call
    this.callAPI(new Date(fp2.selectedDates[0]), new Date(fp.selectedDates[0]));
  }

  progressTimer(increment) {
    this.interval = setInterval(() => {

      // 99を超えていたらローディング表示終了
      // Loading display ends if 99 is exceeded
      if(this.progress > 99){
        this.loadMain.dismiss();

        // time setting ボタンを活性にする
        // Activate the time setting button
        const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
        setTime[0].disabled = false;
        
        this._ref.detectChanges();
        clearInterval(this.interval);
      }else{
        // 99になるまでパーセンテージ加算・表示更新
        // Percentage addition and display update until 99
        this.progressManage = this.progressManage + increment;
        this.progress = Math.round(this.progressManage);
        if(this.progress > 99){
          this.progress = 99;
        }
      }

      this.loadMain.message = 'Now Loading... ' + this.progress + '%';
    }, 1000);
  }

  callAPI(startDate, endDate) {
    // MTDZファイル生成API呼出
    // MTDZ File generation API call

    if (!this.siteService.handleIsConnected()) return;

    if (!this.socketService.is_connected()) {
      this.toastService.error(TOAST_CONNECTIVITY_ISSUE, TOAST_CONNECTIVITY_ISSUE_TITLE, TOAST_CONFIG_FULL_WIDTH);
      console.log('The socket service is not connected');
      return;
    }

    // 物件時刻をUTCに変換
    // Convert property time to UTC
    startDate.setMinutes(startDate.getMinutes() - this.utcOffset);
    endDate.setMinutes(endDate.getMinutes() - this.utcOffset);

    // 物件タイムゾーンとUTCの時差を"XX:XX形式"の文字列に変換
    // Convert the time difference between Property Time Zone and UTC into a "XX:XX" format string
    let plusminus: string;
    if(this.utcOffset < 0){
      plusminus = '-';
    }else{
      plusminus = '+';
    }
    const timeZone: string = plusminus + ('0' + String(Math.floor(Math.abs(this.utcOffset) / 60))).slice(-2) + ':' +
      ('0' + String(Math.abs(this.utcOffset) % 60)).slice(-2);

    this.maintRequestId = uuid();

    let i = 0;
    this.target = -1;
    const allRadioButtons = document.querySelectorAll('input[name=\'radiobutton_tr\'][class=\'radio_button\']') as NodeListOf<HTMLInputElement>;
    allRadioButtons.forEach(radio => {
      if(radio && radio.checked){
        this.target = i;
      }
      i++;
    });

    const obj = {
      gateway_id: this.gatewayId,
      site_id: this.user.active.id,
      request_id: this.maintRequestId,
      start_date: this.date12(this.dateToString(startDate)),
      end_date: this.date12(this.dateToString(endDate)),
      time_zone: timeZone,
      monitor_mode: 1,
      address: [this.dataSource[this.target].Address]
    }
    if (!this.socketService.getMTDZ(obj)) {
      this.toastService.error(TOAST_UNABLE_TO_GET_MAINT_DATA_MESSAGE, TOAST_GENERAL_ERROR_TITLE, TOAST_CONFIG_FULL_WIDTH);
    }
  }

  receiveData(socketResult){
    try {
      if (socketResult && socketResult.response_type){
        if(socketResult.request_id === this.maintRequestId){
          this.progress = 100;
          if(socketResult.response_type === WebSocketResponseTypeEnum.Maint_Get_MTDZ_Complete){
            this.dlMessage = 'Generate MTDZ Success'
            this.dlMessage2 = '';
            this.s3FileName = socketResult.response.data;

            this.siteService.downloadRmdMtdzFile(this.user.active.id, this.s3FileName).subscribe(result => {
              const file = new Blob([result], { type: 'application/zip' });
              this.dlUrl = window.URL.createObjectURL(file);
      
              const a = document?.createElement("a");
              if (document && document?.body) document?.body?.appendChild(a);
              a.href = this.dlUrl;
              a.download = this.s3FileName;
              a.click();
              a.remove();
            })

          }else{
            this.dlMessage = 'Generate MTDZ Failure.'
            this.dlMessage2 = 'An unexpected error occurred or no Maintenance data exists.'
            this.dlUrl = '';
          }
          if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
          // time setting ボタンを活性にする
          // Activate the time setting button
          const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
          setTime[0].disabled = false;

          this._ref.detectChanges();
        }

        if(socketResult.request_id === this.systemMonitorRequestId){
          // パラメータ初期化
          // Parameter initialization
          this.systemMonitorInfoList = []

          if(socketResult.response_type === WebSocketResponseTypeEnum.System_Monitor_Complete){
            // dataをparseする
            // parse data
            const data = JSON.parse(socketResult.response.data);

            // 系統情報を取得
            // Get system monitor information data
            for (let i = 0; i < data["systemMonitor"]["systems"].length; i++){
              const unitsSource = []

              // systemMonitorのunit情報を取得
              // Get systemMonitor unit information
              const units = data["systemMonitor"]["systems"][i]["units"]
              let monitorCheck = false
              for (let j = 0; j < units.length; j++){
                const addData = {
                  "Check": false,
                  "Address":  units[j]["address"],
                  "ModelName": units[j]["modelName"],
                  "SerialNo": units[j]["serialNumber"],
                  "OperationMonitoringAvailable": units[j]["supportFunction"]["operationMonitoringAvailable"],
                  "SystemName": ""
                }
                unitsSource.push(addData)
              }
              
              // OperationMonitoringAvailableがtrueの場合、monitorCheckをtrueにする
              // If OperationMonitoringAvailable is true, set monitorCheck to true
              for (let j = 0; j < unitsSource.length; j++){
                if (unitsSource[j].OperationMonitoringAvailable){
                  monitorCheck = true
                  break;
                }
              }
              // monitorCheckがfalseの場合、系統情報を表示しないため後続処理は実施しない
              if (!monitorCheck){
                continue;
              }
              
              // 系統代表のアドレスを取得
              const representativeAddress = data["systemMonitor"]["systems"][i]["representativeAddress"]
              for (let j = 0; j < unitsSource.length; j++){
                // 系統代表のアドレスと一致する機器情報を、系統情報リストに追加する
                if (unitsSource[j].Address === representativeAddress){
                  this.systemMonitorInfoList.push(unitsSource[j])
                  break;
                }
              }
            }

            if(this.systemMonitorInfoList.length > 0){
              this.systemMonitorInfoList[0].Checked = true;  // The first line of the radio button is checked by default.

              // 代表系統のSystemNameを取得する
              // Get the SystemName of the representative system
              for (let i = 0; i < this.systemMonitorInfoList.length; i++){

                // Gateway情報のoutdoorUnitのAddressと代表系統のアドレスが一致する場合、
                // outdoorUnitのSystemNameを代表系統のSystemNameに設定する
                // If the address of outdoorUnit in Gateway information and the address of representative system match,
                //Set the SystemName of outdoorUnit to the SystemName of the representative system
                for (let j = 0; j < this.outdoorUnitList.length; j++){
                  if (this.systemMonitorInfoList[i].Address === this.outdoorUnitList[j].Address ){
                    this.systemMonitorInfoList[i].SystemName = this.outdoorUnitList[j].SystemName;
                    break;
                  }
                }
                // Gateway情報のoutdoorUnitのAddressと代表系統のアドレスが一致しない場合、
                // Gateway情報のgroupsのAddressと代表系統のアドレスと比較し、一致する場合
                // groupsのSystemNameを代表系統のSystemNameに設定する
                // If the address of outdoorUnit in Gateway information and the address of representative system do not match,
                // Compare the Address of groups in the Gateway information and the address of the representative system, and if they match
                // Set the SystemName of groups to the SystemName of the representative system
                for (let j = 0; j < this.groupsList.length; j++){
                  if (this.systemMonitorInfoList[i].Address === this.groupsList[j].Address ){
                    this.systemMonitorInfoList[i].SystemName = this.groupsList[j].SystemName;
                    break;
                  }
                }
              }
              // time setting ボタンを活性にする
              // Activate the time setting button
              const setTime = document.getElementsByClassName('time-button-style') as HTMLCollectionOf<HTMLInputElement>;
              setTime[0].disabled = false;
              this.dataSource = this.systemMonitorInfoList;
            }
            this._ref.detectChanges();

          }else{
            // 失敗処理を記載（ダイアログ出す）
            // Describe failure handling (display dialog)
            this.siteService.presentToastMessage(
              ToastMessageTypeEnum.Error,
              TOAST_UNABLE_TO_GET_SYSTEM_MONITOR_MESSAGE,
              TOAST_CONNECTIVITY_ISSUE
            );
            return;

          }
          if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
          this._ref.detectChanges();
        }
      }
    } catch(exception) {
      console.log('receiveData=>exception');
    }
  }

  openURL() {
    if(this.dlUrl){
      const a = document?.createElement("a");
      if (document && document?.body) document?.body?.appendChild(a);
      a.href = this.dlUrl;
      a.download = this.s3FileName;
      a.click();
      a.remove();
    }
  }

  async present_loadMain() {
    this.loadMain = await this.loadingController.create({
      // message: 'loading...',
      message: 'Now Loading... ' + this.progress + '%',
      spinner: 'lines',
      duration: MAPUNITS_WAIT_TIME,
    });

    await this.loadMain.present();
  }

  dateToString(date){
    // 日付型のデータを受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Takes data from Date type data and returns a string in format "YYYY/MM/DD hh:mm"
    const strYear = date.getFullYear();
    let strMonth = 1 + date.getMonth();
    let strDay = date.getDate();
    let strHour = date.getHours();
    let strMinute = date.getMinutes();

    strMonth = ('0' + strMonth).slice(-2);
    strDay = ('0' + strDay).slice(-2);
    strHour = ('0' + strHour).slice(-2);
    strMinute = ('0' + strMinute).slice(-2);

    let strFormat = 'YYYY/MM/DD hh:mm'
    strFormat = strFormat.replace(/YYYY/g, strYear);
    strFormat = strFormat.replace(/MM/g, strMonth);
    strFormat = strFormat.replace(/DD/g, strDay);
    strFormat = strFormat.replace(/hh/g, strHour);
    strFormat = strFormat.replace(/mm/g, strMinute);

    return strFormat;
  }

  date12(strFormat){
    // "YYYY/MM/DD hh:mm"形式の文字列を受け取り、"YYYYMMDDhhmm"形式の文字列を返す
    // Takes a string in format "YYYY/MM/DD hh:mm" and returns a string in format "YYYYMMDDhhmm"
    strFormat = strFormat.replace('/', '');
    strFormat = strFormat.replace('/', '');
    strFormat = strFormat.replace(' ', '');
    strFormat = strFormat.replace(':', '');
    return strFormat;
  }

  date12toDate(date12){
    // "YYYYMMDDhhmm"形式の文字列を受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Takes a string in format "YYYYMMDDhhmm" and returns a string in format "YYYY/MM/DD hh:mm"
    const YYYY = date12.substring(0,4);
    const MM = date12.substring(4,6);
    const DD = date12.substring(6,8);
    const hh = date12.substring(8,10);
    const mm = date12.substring(10,12);
    const strFormat = YYYY + '/' + MM + '/' + DD + ' ' + hh + ':' + mm;

    return strFormat;
  }
}