<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="!pending">Remove Member</ion-title>
    <ion-title *ngIf="pending">Remove Invitee</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item lines="none" class="ion-text-center">
      <ion-label color="danger" class="ion-text-wrap" *ngIf="memberIsMe">
        Are you sure you want to remove yourself?
      </ion-label>
      <ion-label color="danger" class="ion-text-wrap" *ngIf="!memberIsMe">
        Are you sure you want to remove this {{ pending ? 'invitee' : 'member' }}?
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label class="labelPosition">Email</ion-label>
      <ion-label>{{ invite.email }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button id="btnRemove" name="btnRemove" slot="end" (click)="uninvite()">Remove</ion-button>
    <ion-button id="btnCancel" name="btnCancel" slot="end" color="reverse-primary" (click)="cancel()"
      class="ion-padding-horizontal">Cancel</ion-button>
  </ion-toolbar>
</ion-footer>

<!--
  padding on bottom buttens
  no all caps anywhere
  remove unnecessary white space
  left side padding
  bottom buttons in footer
  no blue/green text for read-only fields
  center call to action text centered and red
  name: value fields should have spacing like the change member role popup currently
-->