<ion-card id="dashboardSiteWeatherCard" class="dashboardSiteWeatherCard" me-dashboard-card>

  <ion-item lines="none" no-padding>
    <ion-label class="me-header">
      <strong>Site Weather</strong>
    </ion-label>
    <!-- <div class="siteTime textOverflow" *ngIf="siteService?.isConnectedToInternet" title="Site Weather">
      <span class="textOverflow weatherCityFlow">{{ user?.active?.city }}, {{ user?.active?.state }}</span>
      <span *ngIf="siteService?.weatherData?.siteTime">as of {{ siteService?.weatherData?.siteLastUpdated }}</span>
    </div> -->
    <div *ngIf="!siteService?.weatherDataError" (click)="presentAlert()" class="siteWeatherCardHeaderRight ion-icon-wrapper-card-header" title="Additional Info (Site Weather)">
      <ion-icon name="information-circle" slot="end" me-icon-nav color="secondary"></ion-icon>
      <!-- <ion-icon name="arrow-forward-outline" slot="end" me-icon-nav color="black"></ion-icon> -->
    </div>
  </ion-item>

  <ion-card-content me-top-border>
    <ion-grid id="siteWeatherCardGrid" class="siteWeatherCardGrid ion-no-padding">
      <ion-row>
        <ion-col>
          <app-site-weather id="cardWeather"></app-site-weather>
        </ion-col>
      </ion-row>
      <div *ngIf="!siteService?.weatherDataError && siteService?.isConnectedToInternet && siteService?.weatherData?.humidity" [hidden]="siteService?.weatherDataError || siteService?.weatherDataLoading" class="weatherStats">
        <ion-row id="humidityRow" title="Humidity: {{ siteService?.weatherData?.humidity }}%" class="weatherStatsRow {{ siteService?.weatherData?.humidity ? 'humidityLoaded' : 'humidityLoading' }}">
          <ion-col id="humidityRowLabel" class="statLabel">
            <ion-label>Humidity:</ion-label>
          </ion-col>
          <ion-col id="humidityRowValue" class="statValue">
            <ion-label>{{ siteService?.weatherData?.humidity }}%</ion-label>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!usePressurePreference && siteService?.weatherData?.pressure" id="pressureRow" title="Pressure: {{ siteService?.weatherData?.pressure }}hPa" class="weatherStatsRow {{ siteService?.weatherData?.pressure ? 'pressureLoaded' : 'pressureLoading' }}">
          <ion-col id="pressureRowLabel" class="statLabel">
            <ion-label>Pressure:</ion-label>
          </ion-col>
          <ion-col id="pressureRowValue" class="statValue">
            <ion-label>{{ siteService?.weatherData?.pressure }}hPa</ion-label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="usePressurePreference && siteService?.weatherData?.kPa && user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal" id="pressureRow" title="Pressure: {{ siteService?.weatherData?.kPa }}kPa" class="weatherStatsRow {{ siteService?.weatherData?.pressure ? 'pressureLoaded' : 'pressureLoading' }}">
          <ion-col id="pressureRowLabel" class="statLabel">
            <ion-label>Pressure:</ion-label>
          </ion-col>
          <ion-col id="pressureRowValue" class="statValue">
            <ion-label>{{ siteService?.weatherData?.kPa }}kPa</ion-label>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="usePressurePreference && siteService?.weatherData?.kPa && user.accountPreferences.pressurepreference_id == PressurePreferenceEnum.Kilopascal" id="pressureRow" title="Pressure: {{ siteService?.weatherData?.psi }} psi" class="weatherStatsRow {{ siteService?.weatherData?.pressure ? 'pressureLoaded' : 'pressureLoading' }}">
          <ion-col id="pressureRowLabel" class="statLabel">
            <ion-label>Pressure:</ion-label>
          </ion-col>
          <ion-col id="pressureRowValue" class="statValue">
            <ion-label>{{ siteService?.weatherData?.psi }} psi</ion-label>
          </ion-col>
        </ion-row>
        
        <!-- <ion-row id="dryBulbTempRow" title="Dry Bulb Temp: {{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDryBulb + ' °F' : siteService?.weatherData?.celsiusDryBulb + ' °C' }}" class="weatherStatsRow {{ (siteService?.weatherData?.fahrenheitDryBulb || siteService?.weatherData?.celsiusDryBulb) ? 'dryBulbLoaded' : 'dryBulbLoading' }}">
          <ion-col id="dryBulbTempRowLabel" class="statLabel">
            <ion-label>Dry Bulb Temp:</ion-label>
          </ion-col>
          <ion-col id="dryBulbTempRowValue" class="statValue">
            <ion-label>{{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDryBulb + ' °F' : siteService?.weatherData?.celsiusDryBulb + ' °C' }}</ion-label>
          </ion-col>
        </ion-row> -->
        <ion-row id="dewPointRow" title="Dew Point: {{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDewPoint + ' °F' : siteService?.weatherData?.celsiusDewPoint + ' °C' }}" class="weatherStatsRow {{ (siteService?.weatherData?.fahrenheitDewPoint || siteService?.weatherData?.celsiusDewPoint) ? 'dewPointLoaded' : 'dewPointLoading' }}">
          <ion-col id="dewPointRowLabel" class="statLabel">
            <ion-label>Dew Point:</ion-label>
          </ion-col>
          <ion-col id="dewPointRowValue" class="statValue">
            <ion-label>{{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? siteService?.weatherData?.fahrenheitDewPoint + ' °F' : siteService?.weatherData?.celsiusDewPoint + ' °C' }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row id="wetBulbTempRow" title="Wet Bulb Temp: {{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ?
            siteService?.weatherData?.fahrenheitWetBulb + ' °F' : siteService?.weatherData?.celsiusWetBulb + ' °C' }}" class="weatherStatsRow {{ (siteService?.weatherData?.fahrenheitWetBulb || siteService?.weatherData?.celsiusWetBulb) ? 'wetBulbLoaded' : 'wetBulbLoading' }}">
          <ion-col id="wetBulbTempRowLabel" class="statLabel">
            <ion-label>Wet Bulb Temp:</ion-label>
          </ion-col>
          <ion-col id="wetBulbTempRowValue" class="statValue">
            <ion-label>{{ user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ?
            siteService?.weatherData?.fahrenheitWetBulb + ' °F' : siteService?.weatherData?.celsiusWetBulb + ' °C' }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row id="rainChanceRow" title="Rain % Chance: {{ siteService?.weatherData?.pop }}%" class="weatherStatsRow {{ siteService?.weatherData?.pop ? 'rainChanceLoaded' : 'rainChanceLoading' }}">
          <ion-col id="rainChanceRowLabel" class="statLabel">
            <ion-label>Rain % Chance:</ion-label>
          </ion-col>
          <ion-col id="rainChanceRowValue" class="statValue">
            <ion-label>{{ siteService?.weatherData?.pop }}% Currently</ion-label>
          </ion-col>
        </ion-row>
      </div>
      <ion-row class="weatherChartRow" [hidden]="!siteService.isConnectedToInternet || siteService?.weatherDataError || siteService?.weatherDataLoading">
        <ion-col>
          <ion-segment class="selectTypeToggle selectWeatherType ionSegment" color="secondary" name="selectWeatherType" title="Click to Select Weather Type" [value]="displayedChart" #selectWeatherType (ionChange)="weatherTypeChanged($event.detail.value)">
            <ion-segment-button *ngFor="let type of displayedChartTypes; let i = index" [value]="type" [title]="type" [name]="type" class="typeSegmentButton" [id]="type">
              <ion-label>{{ type }}</ion-label>
            </ion-segment-button>
          </ion-segment>
          <div id="weatherChart" class="weatherChart {{ displayedChart }}"></div>
        </ion-col>
      </ion-row>
      <ion-row title="Weather Forecast" class="multiDayForecast fourCast" *ngIf="!siteService?.weatherDataError && siteService?.isConnectedToInternet && siteService?.weatherData?.fourDay" [hidden]="siteService?.weatherDataError || siteService?.weatherDataLoading">
        <ion-col title="{{ day?.dayTitle }} - {{ capWords(day?.weather[0]?.description) }}" class="dayOfForecast" *ngFor="let day of siteService?.weatherData?.fourDay; index as i; let first = first; let last = last;">
          <ion-grid title="{{ day?.dayTitle }} - {{ capWords(day?.weather[0]?.description) }}" class="forecastDayGrid">
            <!-- <ion-row class="forecastHour">{{ day?.dayOfMonth }}<sup style="left: 1px; top: 4px; font-size: 0.85em; vertical-align: super;">{{ day?.dayOfMonthOrdinal }}</sup></ion-row> -->
            <ion-row class="forecastDay">{{ day?.dayCastWeekDay }} <span class="dayOrdinal">({{ day?.dayCastMonthDay }}<sup style="top: 4px; padding-right: 1px; vertical-align: super;">{{
              day?.dayOfMonthOrdinal }}</sup>)</span>
            </ion-row>
            <div class="weatherIconImage">
              <img class="weatherConditionIcon" title="{{ day?.dayTitle }} - {{ capWords(day?.weather[0]?.description) }}" alt="{{ capWords(day?.weather[0]?.description) }}" [src]="siteService?.getConditionIcon(false, day?.weather[0]?.description)" />
            </div>
            <div class="weatherMinMax" title="{{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? day?.maxF : day?.maxC }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }} | {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? day?.minF : day?.minC }} {{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}">
              {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? day?.maxF : day?.maxC }} <sup style="top: -1px; left: -1px; font-size: .75em;">{{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}</sup> <span class="seperator">|</span> {{ user && user.accountPreferences && user.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? day?.minF : day?.minC }} <sup style="top: -1px; left: -1px; font-size: .75em;">{{ user && user.accountPreferences && user?.accountPreferences.temperaturepreference_id == TemperaturePreferenceEnum.Fahrenheit ? '°F' : '°C' }}</sup>
          </div>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
  
  <ion-grid id="dashboardSiteWeatherCardBottom" class="dashboardSiteWeatherCardBottom cardBottomFooter noIonPadding automationTesting {{ siteService?.weatherDataLoading ? 'cardDataLoading' : 'cardDataLoaded' }}">
    <ion-row class="weatherCardFooterText groupStatusCardFooterText" *ngIf="!siteService.weatherDataError && siteService?.isConnectedToInternet && siteService?.weatherData?.lastUpdated">
      <ion-col class="lastUpdatedCol weatherUpdatedStamp">
        <span title="Last Updated: {{ siteService?.weatherData?.onPageLoad }}">Last Updated: {{ siteService?.weatherData?.onPageLoad }}</span>
        <span *ngIf="siteService?.weatherData?.siteTimezone" title="{{ user?.active?.city }}, {{ user?.active?.state }} {{ dynamicSiteTime }}" class="textOverflow weatherStampFlow">
          <span class="textOverflow weatherCityFlow">({{ user?.active?.city }}</span>, {{ user?.active?.state }} {{ dynamicSiteTime }})
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-card>