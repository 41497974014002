import { Component, OnDestroy } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, timer } from 'rxjs';
import { UserService } from 'src/app/common/services/user/user.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TOAST_GENERAL_ERROR_TITLE, TOAST_MAINT_REFRIGERANT_CHECK_ERR, TOAST_MAINT_REFRIGERANT_DOWNLOAD_ERR } from 'src/app/constants/kenzaconstants';

import { OutdoorUnit, TestType } from 'src/app/features/maintenance/common/maint-outdoor-unit-list';
import { OutdoorunitService } from '../../service/outdoorunit.service';
import { RefrigerantCheckRequest, RefrigerantDownloadRequest }
    from 'src/app/common/services/websockets/classes/requests/WebSocketRequest';
import { RefrigerantRecvData, notificationType, RefrigerantResultDataList, RefrigerantResultData } from './maintenance-refrigerant-result-data';


const CANCEL_PROGRESS = 'Canceled';
const ERROR_PROGRESS = 'Error';
const TOAST_INFO = {
    positionClass: 'toast-top-full-width',
    disableTimeOut: true,
    timeOut: 0,
    tapToDismiss: true,
};

@Component({
    selector: 'maintenance-refrigerant-result',
    templateUrl: './maintenance-refrigerant-result.component.html',
    styleUrls: ['./maintenance-refrigerant-result.component.scss']
})

export class MaintenanceRefrigerantResultComponent implements OnDestroy {
    target: OutdoorUnit = null;
    result: RefrigerantResultDataList = null;
    progress: string;
    statusStr: string;
    cancelDisabled: boolean;
    isShowResult: boolean;
    socketEmitterSub: Subscription = null;
    public dataSource: MatTableDataSource<RefrigerantResultData> = new MatTableDataSource<RefrigerantResultData>();
    public rowColumns: string[] = ["label", "value"];

    private refrigerantRecvData: RefrigerantRecvData = null;

    private intervalTimer;
    private timerSubscription: Subscription = null;
    readonly interval = 60000; // ms
    readonly updateInterval = 10000; // ms
    readonly downloadTimeUP = 300000; // ms
    debugCount: number;

    constructor(
        private user: UserService,
        private socketService: SocketService,
        private router: Router,
        private toastService: ToastrService,
        private outdoorunitService: OutdoorunitService
    ) {
        this.target = new OutdoorUnit();
        this.result = new RefrigerantResultDataList();
        this.cancelDisabled = true;
        this.isShowResult = false;
        this.socketEmitterSub = null;
        this.timerSubscription = null;
        this.debugCount = 0;
        this.statusStr = 'Progress';
        this.refrigerantRecvData = null;
        this.dataSource = new MatTableDataSource<RefrigerantResultData>([]);

    }

    ngOnDestroy() {
        this.cleaningUp();
    }

    ionViewWillEnter() {
        this.target = new OutdoorUnit();
        if (this.outdoorunitService.selectUnit === null) {
            // Simultaneous execution on multiple pages is prohibited,
            // so Return to previous screen if reloaded or tab - copied
            this.toastService.error(TOAST_MAINT_REFRIGERANT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
            this.backPage();
        } else {
            this.debugCount = 0;
            this.target.copyData(this.outdoorunitService.selectUnit[0]);
            this.progress = 'Before Start';

            if (this.socketEmitterSub === null) {
                this.socketEmitterSub = this.socketService.maintenanceEmitter.subscribe(payload => {
                    this.recvWebSocketData(payload);
                });
            }
            this.sendRequest('refrigerant_start');
            this.intervalTimer = timer(this.interval);
            this.timerSubscription = this.intervalTimer.subscribe(() => {
                this.recvTimeOut();
            });
        }
    }

    // Notify the start/stop of Refrigerant check to Server
    sendRequest(command: string) {
        const request = new RefrigerantCheckRequest();
        request.setBaseIds(this.target.gatewayId, this.user.active.id);
        request.setData(this.target);
        console.info("send : " + command);
        this.socketService.sendCommonRequest(request, command);

    }

    ionViewWillLeave() {
        this.cleaningUp();
    }

    cleaningUp() {
        if (this.socketEmitterSub !== null) {
            this.socketEmitterSub.unsubscribe();
            this.socketEmitterSub = null;
        }
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.target = new OutdoorUnit();
        this.cancelDisabled = true;
        this.isShowResult = false;
        this.result = new RefrigerantResultDataList();
        this.dataSource = new MatTableDataSource<RefrigerantResultData>([]);
    }

    backPage() {
        this.outdoorunitService.testType = TestType['refrigerant'];
        this.router.navigate(['/maintenance', this.user.active.id], {});
        this.cleaningUp();
    }

    checkCancel() {
        this.progress = CANCEL_PROGRESS;
        this.cancelDisabled = true;

        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }

        this.sendRequest('refrigerant_cancel');
        this.intervalTimer = timer(this.interval);
        this.timerSubscription = this.intervalTimer.subscribe(() => {
            this.recvTimeOut();
        });

    }

    // Receive check results (progress) from Server
    recvWebSocketData(payload) {
        this.refrigerantRecvData = new RefrigerantRecvData(payload);
        this.target.setDBIds(this.refrigerantRecvData.managementTableId);
        if (this.refrigerantRecvData.type === notificationType.REPORT) {
            if (this.timerSubscription !== null) {
                this.timerSubscription.unsubscribe();
                this.timerSubscription = null;
            }
            if (this.refrigerantRecvData.url !== '') {
                window.open(this.refrigerantRecvData.url, '_blank');
            } else {
                this.toastService.error(TOAST_MAINT_REFRIGERANT_DOWNLOAD_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
            }
        } else if (!this.isShowResult) {
            //Update information received from the server
            if (this.refrigerantRecvData["target"] !== undefined) {
                this.target.unitAddress = String(this.refrigerantRecvData.target[0].address);
                this.target.systemName = this.refrigerantRecvData.target[0].systemName;
                this.target.modelName = this.refrigerantRecvData.target[0].modelName;
            }
            // Update the display only when the notification of inspection completion has never been received
            this.recvProgress();
        }
    }

    private recvProgress() {
        if (this.refrigerantRecvData.status == 'canceled') {
            // Even if the user selects cancel on another screen, switch to the canceling display
            this.progress = CANCEL_PROGRESS;
            this.cancelDisabled = true;
        }
        switch (this.refrigerantRecvData.type) {
            case notificationType.FINISH:
                if (this.timerSubscription !== null) {
                    this.timerSubscription.unsubscribe();
                    this.timerSubscription = null;
                }
                if (!this.cancelDisabled) {
                    this.statusStr = 'Complete';
                } else {
                    // I canceled the check halfway through
                    this.statusStr = 'Canceled';
                }
                this.redorwProgress();
                this.showResult();
                break;
            case notificationType.START:
                this.progressUpdate();
                break;
            case notificationType.SUSPEND:
                this.progressUpdate();
                break;
            case notificationType.NOTIFY:
                if (this.progress === 'Before Start') {
                    this.cancelDisabled = false;
                }
                this.redorwProgress();
                break;
            case notificationType.ERR_MFK:
            case notificationType.ERR:
                this.timerSubscription.unsubscribe();
                this.timerSubscription = null;
                this.statusStr = 'Error';
                this.progress = ERROR_PROGRESS;
                this.showResult();
                this.toastService.error(TOAST_MAINT_REFRIGERANT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
                break;
            case notificationType.ERR_START:
                // Returns to the previous screen when there is a communication error
                this.toastService.error(TOAST_MAINT_REFRIGERANT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
                this.backPage();
                break;
            default:
                break;
        }
    }

    private redorwProgress() {
        // The progress received during Cancel processing is not displayed
        if (!this.cancelDisabled) {
            this.progress = this.refrigerantRecvData.progress + '%';
        }
    }

    private progressUpdate() {
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.intervalTimer = timer(this.updateInterval, this.updateInterval);
        this.timerSubscription = this.intervalTimer.subscribe(() => {
            this.sendRequest('refrigerant_update');
        });
    }

    recvTimeOut() {
        if (this.timerSubscription !== null) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        this.statusStr = 'Error';
        this.progress = ERROR_PROGRESS;
        this.cancelDisabled = true;
        this.refrigerantRecvData = new RefrigerantRecvData('');
        this.showResult();
        this.toastService.error(TOAST_MAINT_REFRIGERANT_CHECK_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
    }

    showResult() {
        this.result.createlist(this.refrigerantRecvData, this.cancelDisabled);

        this.cancelDisabled = true;
        this.isShowResult = true;
        this.dataSource = new MatTableDataSource<RefrigerantResultData>(this.result.list);

    }

    checkDownload() {
        const command = 'refrigerant_download';
        const request = new RefrigerantDownloadRequest();
        request.setBaseIds(this.target.gatewayId, this.user.active.id);
        request.setData(this.target.unitAddress, this.refrigerantRecvData.fileName);
        console.info("send : " + command);
        this.socketService.sendCommonRequest(request, command);
        this.intervalTimer = timer(this.downloadTimeUP);
        this.timerSubscription = this.intervalTimer.subscribe(() => {
            this.downloadTimeOut();
        });
    }

    downloadTimeOut() {
        this.toastService.error(TOAST_MAINT_REFRIGERANT_DOWNLOAD_ERR, TOAST_GENERAL_ERROR_TITLE, TOAST_INFO);
    }


    // Debug function invoked by clicking A in the center of the screen
    // Left click while holding the bottom button
    // ctr + shift + alt
    // ctr + shift
    // ctr
    // alt
    
    degug(event) {
        // if (event.ctrlKey === true && event.shiftKey === true && event.altKey === true) {
        //     this.backPage();
        // } else if (event.ctrlKey === true && event.shiftKey === true) {
        //     const testData = {
        //         'type': 'reception_start',
        //         'progress': 50,
        //         'status': 'nomal',
        //         'result': {
        //             'ocAddress': 51,
        //             'al': 25
        //         }
        //     };
        //     if (this.debugCount > 0) {
        //         testData.type = 'notify';
        //     }
        //     if (this.debugCount >= 100) {
        //         testData.type = 'finish';
        //     }
        //     testData.progress = this.debugCount;
        //     this.recvWebSocketData({
        //         type: 1,
        //         responseData: testData
        //     });
        //     this.debugCount = this.debugCount + 10;
        // } else if (event.ctrlKey === true) {
        //     console.log(this);
        // } else if (event.altKey === true) {
        //     const testData = {
        //         'type': 'reception_suspended',
        //         'progress': 50,
        //         'status': 'canceled',
        //         'result': {
        //             'ocAddress': 51,
        //             'al': 100
        //         }
        //     };
        //     if (this.debugCount > 10) {
        //         testData.type = 'notify';
        //     }
        //     if (this.debugCount >= 100) {
        //         testData.type = 'finish';
        //     }
        //     testData.progress = this.debugCount;
        //     this.recvWebSocketData({
        //         type: 1,
        //         responseData: testData
        //     });
        //     this.debugCount = this.debugCount + 10;
        // }
    }


    // Debug function invoked by clicking A in the center of the screen
    // Right click while pressing the button below
    // shift
    // ctr
    // alt

    degug2(event) {
        // if (event.shiftKey === true) {
        //     const testData = {
        //         'url': '',
        //     };
        //     this.recvWebSocketData({
        //         type: 2,
        //         responseData: testData
        //     });
        // } else if (event.ctrlKey === true) {
        //     const testData = {
        //         'url': 'https://dev-s3-lcs-report-kzcf.s3.ap-northeast-1.amazonaws.com/84fae372-2e62-11ed-bc78-06ddd60227cd_2022-10-28+05%3A44%3A40.098370.csv',
        //     };
        //     this.recvWebSocketData({
        //         type: 2,
        //         responseData: testData
        //     });
        // }
    }
}
