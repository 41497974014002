<ion-header>
  <ion-toolbar>
    <ion-title>
      {{currentStepUILayout.stepTitle}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [ngSwitch]="currentStepUILayout.stepIndex" class="changePaymentMethodForm">
    <!--Header Section -->
    <ion-grid me-header-grid
      *ngIf="currentStepUILayout.stepIndex!==gatewayChangePaymentMethodStepEnum.ComparePlansStep">
      <ion-row class="registration-step-counter ion-justify-content-around">
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconGateway" name="iconGateway"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.GatewayStep) ? 'me-current-process-step' : 'me-process-step' "
                name="cloud-upload"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.GatewayStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Gateway</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
        </ion-col>
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconPay" name="iconPay"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.ChangeStep) ? 'me-current-process-step' : 'me-process-step' "
                name="card"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.ChangeStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Change Payment</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col class="flex ion-justify-content-center hyphen">
          -
        </ion-col>
        <ion-col>
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon id="iconPay" name="iconPay"
                [ngClass]="(currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.SummaryStep) ? 'me-current-process-step' : 'me-process-step' "
                name="list"></ion-icon>
            </ion-row>
            <ion-row class="ion-margin-top ion-justify-content-center">
              <ion-label
                [ngClass]="currentStepUILayout.stepIndex>=gatewayChangePaymentMethodStepEnum.SummaryStep ? 'me-current-process-step-text' : 'me-process-step-text' ">
                Summary</ion-label>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>

    </ion-grid>

    <!--Information Step-->
    <ion-grid *ngSwitchCase="gatewayChangePaymentMethodStepEnum.InformationStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger">
            You are changing the payment method for your<br>gateway subscription, on the
            site:<br>
          </ion-label>
          <ion-label>
            <strong>{{paymentMethodToChange?.site_name}}</strong>
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label>
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <ion-label class="label-padding">
            Note the Following:
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ul>
            <li class="li-padding">Changing your payment method will be applied to all gateways on this site.</li>
            <li class="li-padding">Your new payment method will be charged at the first of the next month.</li>
          </ul>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <!-- <ion-label>
              <ul>
                <li *ngFor="let sp of subscriptionPlans">
                  {{sp.gateway_name}}
                </li>
              </ul>
            </ion-label> -->
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Gateway Step-->
    <ion-grid
      *ngSwitchCase="[gatewayChangePaymentMethodStepEnum.GatewayStep,gatewayChangePaymentMethodStepEnum.ChangeStep].includes(currentStepUILayout.stepIndex) ? currentStepUILayout.stepIndex : -1  ">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-label color="danger"
            *ngIf="currentStepUILayout.stepIndex==gatewayChangePaymentMethodStepEnum.GatewayStep">
            You are changing the payment method for the<br>following gateway(s):
          </ion-label>
          <ion-label *ngIf="affectedDevices.length == 0">
            <br><br><i>There are no gateways with paid subscriptions currently registered to this site.</i><br><br>
          </ion-label>
          <ion-label color="danger"
            *ngIf="currentStepUILayout.stepIndex==gatewayChangePaymentMethodStepEnum.ChangeStep">
            Please confirm the information below,<br>and enter a payment method before proceeding:
          </ion-label>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let sp of affectedDevices">
        <ion-col col col-12>
          <ion-grid>
            <ion-row>
              <ion-col col col-6>
                <strong>Gateway</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>Current Subscription Plan</strong>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                {{sp.name}}
              </ion-col>
              <ion-col col col-6>
                {{sp.gatewaysubscription.subscriptions.plan_name}} {{sp.gatewaysubscription.subscriptions.monthly_rate |
                currency }}{{'/mo'}}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                (S/N: {{sp.serial_number}})
              </ion-col>
              <ion-col col col-6>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col col col-6>
                <strong>Site Name</strong>
              </ion-col>
              <ion-col col col-6>
                <strong>Current Payment Method</strong>
              </ion-col>
            </ion-row>
            <ion-row style="border-bottom-style: solid; border-bottom-width: thin; border-bottom-color: #efefef">
              <ion-col col col-6>
                {{paymentMethodToChange?.site_name}}
              </ion-col>
              <ion-col col col-6>
                {{paymentMethodToChange?.card_type | titlecase }} ending in {{paymentMethodToChange?.last_four}} Exp.
                {{paymentMethodToChange?.expiration}}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Change Step-->
    <ion-grid *ngSwitchCase="gatewayChangePaymentMethodStepEnum.ChangeStep">
      <!-- <ion-row style="padding-bottom: 15px;">
        <ion-col col col-6>
          <strong>
            <div style="padding-left: 10px;">
              Total per Month
            </div>
          </strong>
        </ion-col>
        <ion-col col col-6>
          <strong>
            {{calculatedSubscriptionTotal | currency}}
          </strong>
        </ion-col>
      </ion-row> -->

      <!--Payment : Part 2-->
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-label>Secure Payment Information<ion-icon name="lock-closed" size="large"></ion-icon>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>

      <app-credit-card-form (formChange)="creditCardStatusDidChange($event)" #ccForm></app-credit-card-form>

      <ion-row>
        <ion-col class="ion-text-start ion-padding-top">
          <p *ngIf="paymentMethodCollision" style="padding-left: 10px;">
            <ion-text color="danger">This payment method is already being used for a selected
              subscription. Please use a different payment method to proceed.</ion-text>
          </p>
          <p style="padding-left: 10px;">
            Your new payment method will be charged at the beginning of the next billing cycle.
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!--Summary Step-->
    <ion-grid *ngSwitchCase="gatewayChangePaymentMethodStepEnum.SummaryStep">
      <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-item-group>
            <ion-item>
              <ion-label>Gateway(s) Confirmed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item *ngIf="!noPaymentNeeded">
              <ion-label>Payment Method Changed</ion-label>
              <ion-icon color="success" name="checkmark-circle"></ion-icon>
            </ion-item>
            <ion-item>
              <ion-label>You will receive an email confirmation shortly.</ion-label>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>

      <!-- <ion-row>
        <ion-col col col-12 me-header-col>
          <ion-item-group>

          </ion-item-group>
        </ion-col>
      </ion-row> -->
    </ion-grid>



    <!--Compare Plan Step-->
    <!-- <ion-grid *ngSwitchCase="gatewayChangePaymentMethodStepEnum.ComparePlansStep">
      <ion-row>
        <ion-col>
          Compare Plan Step
        </ion-col>
      </ion-row>
    </ion-grid> -->

  </form>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-col class="flex ion-justify-content-end ion-align-items-center">
      <div *ngIf="paymentError" style="width:100%; text-align:center;">
        <ion-label validation-error>{{paymentErrorString}}</ion-label>
      </div>
      <ion-button id="btnBack" color="primary" (click)="handleBackButton()" *ngIf="currentStepUILayout.showBackButton"
        class="me-button-change-payment">
        Back
      </ion-button>
      <ion-button id="btnNext" color="primary" (click)="handleNextButton()" [disabled]="disableChangeButton"
        *ngIf="currentStepUILayout.showNextButton" class="me-button-change-payment">
        {{currentStepUILayout.nextButtonText}}
      </ion-button>
      <ion-button id="btnCancel" color="reverse-primary" (click)="handleCancelButton()"
        *ngIf="currentStepUILayout.showCancelButton">
        Cancel
      </ion-button>
      <ion-button id="btnClose" color="reverse-primary" (click)="handleCloseButton()"
        *ngIf="currentStepUILayout.showCloseButton">
        Close
      </ion-button>
    </ion-col>
  </ion-grid>
</ion-footer>