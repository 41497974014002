<ion-header>
  <ion-grid me-ion-grid-tab>
    <ion-row me-ion-col-tab>
      <ion-col size="6">
        <ion-item [color]="tabDetails.profileSelected==true ? 'white': 'light'" lines="none" me-ion-item-tab-left
          (click)="onTabClick('Profile')">
          <ion-label color="dark">
            Profile
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="6">
        <ion-item lines="none" [color]="tabDetails.companyInformationSelected==true ? 'white': 'light'" me-ion-item-tab
          (click)="onTabClick('CompanyInformation')">
          <ion-label color="dark">
            Company Information
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>

<ion-content>
  <form [formGroup]="accountDetailForm" (ngSubmit)="onSubmit()">
    <!--Profile Grid-->
    <div class="form-group">
      <ion-grid>
        <ion-row>
          <ion-col col col-6 me-center-ion-col-content>

            <ion-list *ngIf="imageUrl.length==0">
              <ion-item lines="none">
                <img me-img-account-detail src="../../../assets/imgs/edit-md-imagesaspect.svg">
              </ion-item>
            </ion-list>

            <img  *ngIf="imageUrl.length>0" me-img-account-detail src="{{imageUrl}}"   id="uploadAccountPicture"
              name="uploadAccountPicture" color="primary">
          </ion-col>

          <ion-col col col-6 me-center-ion-col-content>
            <div me-member-since>
              <strong>Member Since</strong><br>{{memberSince}}
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col col col-6 me-center-ion-col-content>
            <input type="file" #uploadPictureFile name="uploadPictureFile" id="uploadPictureFile" class="fileInput"
              (change)="saveAccountPhoto($event)" accept="image/x-png,image/gif,image/jpeg"   />
            <label for="uploadPictureFile" appMouseOverOpacity class="fileInputLabel">
              Upload Picture
            </label>
          </ion-col>

          <ion-col col col-6 me-center-ion-col-content>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="imageUrl.length!==0">
          <ion-col me-center-ion-col-content>
            <ion-button fill="clear" (click)="removeAccountPhoto()">Remove Picture
            </ion-button>
          </ion-col>

          <ion-col col col-6 me-center-ion-col-content>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col col col-6 me-center-ion-col-content>
            <ion-label me-validation-error><i>
                {{uploadPictureFileMessage}}
              </i></ion-label>
          </ion-col>

          <ion-col col col-6 me-center-ion-col-content>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col col col-12>
            <ion-list *ngIf="tabDetails.profileSelected">
              <ion-item>
                <ion-label position="floating" for="email" color="dark">Email</ion-label>
                <ion-input type="text" class="form-control" id="email" [disabled]="true"  formControlName="email"
                   (ionBlur)="trim($event)"></ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.email.errors">
                <div validation-error *ngIf="accountDetailForm.controls.email.errors.minlength">
                  Minimum length of 10 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.email.errors">
                <div validation-error *ngIf="accountDetailForm.controls.email.errors.maxlength">
                  Maximum length of 25 characters.
                </div>
              </div>
            </ion-list>

          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col col col-6>
            <ion-list *ngIf="tabDetails.profileSelected">
              <ion-item>
                <ion-label position="floating" for="firstName"  color="primary"  >First Name </ion-label>
                <ion-input type="text" class="form-control" id="firstName"  formControlName="firstName"
                   (ionBlur)="trim($event)"></ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.firstName.errors">
                <div validation-error *ngIf="accountDetailForm.controls.firstName.errors.minlength">
                  Minimum length of 2 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.firstName.errors">
                <div validation-error *ngIf="accountDetailForm.controls.firstName.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>

            <ion-list *ngIf="tabDetails.companyInformationSelected">
              <ion-item>
                <ion-label position="floating" for="company"  color="primary"  >Company Name</ion-label>
                <ion-input type="text" class="form-control" id="company" formControlName="company" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>

              <div *ngIf="accountDetailForm.controls.company.errors">
                <div validation-error *ngIf="accountDetailForm.controls.company.errors.minlength">
                  Minimum length of 2 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.company.errors">
                <div validation-error *ngIf="accountDetailForm.controls.company.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>

          </ion-col>
          <ion-col col col-6>
            <ion-list *ngIf="tabDetails.profileSelected">
              <ion-item>
                <ion-label position="floating" for="lastName"  color="primary"  >Last Name</ion-label>
                <ion-input type="text" class="form-control" id="lastName" formControlName="lastName" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.lastName.errors">
                <div validation-error *ngIf="accountDetailForm.controls.lastName.errors.minlength">
                  Minimum length of 2 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.lastName.errors">
                <div validation-error *ngIf="accountDetailForm.controls.lastName.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>

            <ion-list *ngIf="tabDetails.companyInformationSelected">
              <ion-item>
                <ion-label position="floating" for="companyPhone"  color="primary"  >Company Phone</ion-label>
                <ion-input type="text" class="form-control tel_input" id="companyPhone" formControlName="companyPhone"
                  (ionFocus)="setPhoneField($event)" (ionInput)="maskPhoneField($event)"
                  (ionBlur)="clearPhoneField($event)">
                </ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.companyPhone.errors">
                <div validation-error
                  *ngIf="accountDetailForm.controls.companyPhone.errors.minlength  && accountDetailForm.controls.companyPhone.dirty">
                  Minimum length of 10 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.companyPhone.errors">
                <div validation-error
                  *ngIf="!accountDetailForm.controls.companyPhone.valid && accountDetailForm.controls.companyPhone.dirty">
                  Please enter a valid phone number
                </div>
              </div>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="tabDetails.profileSelected" class="phoneRow">
          <ion-col col col-6>
            <ion-list *ngIf="tabDetails.profileSelected">
              <ion-item>
                <ion-label position="floating" for="title"  color="primary"  >Title</ion-label>
                <ion-input type="text" class="form-control" id="title" formControlName="title" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.title.errors">
                <div validation-error *ngIf="accountDetailForm.controls.title.errors.minlength">
                  Minimum length of 2 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.title.errors">
                <div validation-error *ngIf="accountDetailForm.controls.title.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>
          </ion-col>

          <ion-col col col-6>
            <ion-list>
              <ion-item>
                <ion-label position="floating" for="phoneNumber" color="primary">Personal Phone</ion-label>
                <ion-input type="text" id="phoneNumber" class="form-control tel_input" formControlName="phoneNumber"
                  (ionFocus)="setPhoneField($event)" (ionInput)="maskPhoneField($event)"
                  (ionBlur)="clearPhoneField($event)">
                </ion-input>
              </ion-item>
              <div validation-error
                *ngIf="!accountDetailForm.controls.phoneNumber.valid && accountDetailForm.controls.phoneNumber.dirty">
                Please enter a valid phone number
              </div>
            </ion-list>
          </ion-col>
        </ion-row>


        <ion-row>
          <ion-col col col-12>


            <ion-list *ngIf="tabDetails.companyInformationSelected">
              <ion-item>
                <ion-label position="floating" for="addressLine1"  color="primary"  >Company Address Line 1</ion-label>
                <ion-input type="text" class="form-control" id="addressLine1" formControlName="addressLine1" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.addressLine1.errors">
                <div validation-error *ngIf="accountDetailForm.controls.addressLine1.errors.minlength">
                  Minimum length of 5 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.addressLine1.errors">
                <div validation-error *ngIf="accountDetailForm.controls.addressLine1.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col col col-12>

            <ion-list *ngIf="tabDetails.companyInformationSelected">
              <ion-item>
                <ion-label position="floating" for="addressLine2"  color="primary"  >Company Address Line 2</ion-label>
                <ion-input type="text" class="form-control" id="addressLine2" formControlName="addressLine2" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>

              <div *ngIf="accountDetailForm.controls.addressLine2.errors">
                <div validation-error *ngIf="accountDetailForm.controls.addressLine2.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="tabDetails.companyInformationSelected">
          <ion-col col col-4>
            <ion-list>
              <ion-item *ngIf="tabDetails.companyInformationSelected">
                <ion-label position="floating" for="city"  color="primary"  >City</ion-label>
                <ion-input type="text" class="form-control" id="city" formControlName="city" (ionBlur)="trim($event)">
                </ion-input>
              </ion-item>
              <div *ngIf="accountDetailForm.controls.city.errors">
                <div validation-error *ngIf="accountDetailForm.controls.city.errors.minlength">
                  Minimum length of 2 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.city.errors">
                <div validation-error *ngIf="accountDetailForm.controls.city.errors.maxlength">
                  Maximum length of 50 characters.
                </div>
              </div>
            </ion-list>
          </ion-col>
          <ion-col col col-4>
            <ion-item lines="none">
              <ion-list>
                <ion-label position="stacked" label-format color="primary"  >State</ion-label>
                <select formControlName="state">
                  <option *ngFor="let state of stateList" [value]="state.abbreviation">{{ state.abbreviation }} -
                    {{ state.name }}</option>
                </select>
                <div *ngIf="accountDetailForm.controls.state.errors">
                  <div validation-error *ngIf="accountDetailForm.controls.state.errors.minlength">
                    Please select a state
                  </div>
                </div>
              </ion-list>
            </ion-item>
          </ion-col>
          <ion-col col col-4>
            <ion-list>
              <ion-item *ngIf="tabDetails.companyInformationSelected">
                <ion-label position="floating" for="zip"  color="primary">Zip Code</ion-label>
                <ion-input type="text" class="form-control" id="zip" formControlName="zip"
                  (ionBlur)="clearZipField($event)" (ionFocus)="setZipField($event)" (ionInput)="maskZipField($event)">
                </ion-input>
                <!-- <ion-input type="text" class="form-control" id="zip" formControlName="zip" (onChange)="formatZip($event)"
                  (ionBlur)="trim($event)">
                </ion-input> -->
              </ion-item>
              <div *ngIf="accountDetailForm.controls.zip.errors">
                <div validation-error *ngIf="accountDetailForm.controls.zip.errors.minlength">
                  Minimum length of 5 characters.
                </div>
              </div>

              <div *ngIf="accountDetailForm.controls.zip.errors">
                <div validation-error *ngIf="accountDetailForm.controls.zip.errors.maxlength">
                  Maximum length of 10 characters.
                </div>
              </div>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
      <!--Company Information Grid-->
    </div>
  </form>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-toolbar>
      <ion-buttons slot="secondary" buttons-layout>
        <ion-button me-button [disabled]="!accountDetailForm.valid" (click)="onSubmit()">{{accountDetailText}}
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="secondary" buttons-layout>
        <ion-button me-button-cancel (click)="onCancel()"> Cancel</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-toolbar>
</ion-footer>