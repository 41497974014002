<form class="dateSelectionForm" [formGroup]="date_picker_form_group">
  <ion-grid class="dateSelectionFGrid ion-no-padding">
    <ion-row class="dateSelectionTitleRow">
      <ion-col class="ion-padding-start" size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">
        <ion-label id="i_label_date" name="i_label_date" style="padding-left: 7px">
          Date Selection:
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-grid class="dateSelectionFormFieldsGrid">
      <ion-row class="dateSelectionRow">
        <ion-col class="dateSelectionCol">
          <ion-radio-group id="i_radio_grp_selection_type_option_value" name="i_radio_grp_selection_type_option_value"
            formControlName="selection_type_option_value" ngDefaultControl
            (ionChange)="copy_date_picker_form_group_to_class()">

            <ion-row class="dateSelectionSingleDayRow">
              <ion-col class="ion-padding-start" size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">

                <ion-item lines="none" id="i_item_previous_number_of_days" class="iItemSingleDay automationTesting" name="i_item_previous_number_of_days">
                  <ion-label id="i_label_previous_number_of_days" style="max-width: 80px;"
                    name="i_label_previous_number_of_days"
                    (click)="date_picker_form_group.controls.selection_type_option_value.setValue(selectionTypeOptionEnum.SINGLE_DATE);copy_date_picker_form_group_to_class()">
                    Single day
                  </ion-label>
                  <ion-radio id="i_radio_previous_number_of_days" class="iRadioSingleDay automationTesting ion-margin-end"
                    name="i_radio_previous_number_of_days" slot="start" [value]="selectionTypeOptionEnum.SINGLE_DATE">
                  </ion-radio>
                  <div id="divSingleDayInfo" class="divSingleDayInfo automationTesting" title="Information" pointer style="z-index: 2;" (click)="onSingleDayInfo()">
                    <ion-icon id="iIconSingleDayInfo" class="iIconSingleDayInfo automationTesting" color="primary" style="font-size: 20px; margin-top: 4px;" name="information-circle-outline">
                    </ion-icon>
                  </div>
                </ion-item>

              </ion-col>

              <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12"
                style="margin-top: 15px; padding-left: 37px;"
                *ngIf="date_picker_form_group.controls.selection_type_option_value.value==selectionTypeOptionEnum.SINGLE_DATE">
                <ion-item id="i_item_single_date" name="i_item_single_date" lines="none" x-style="padding-left: 60px;">
                  <!-- to make room for the single date picker -->
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row class="dateSelectionMultipleDayRow">
              <ion-col class="ion-padding-start" size-xl="12" size-lg="12" size-md="12" size-sm="12" size="12">

                <ion-item lines="none" id="i_item_date_range" class="iItemMultipleDay automationTesting" name="i_item_date_range">
                  <ion-label id="i_label_select_date_range" style="max-width: 195px;" name="i_label_select_date_range"
                    (click)="date_picker_form_group.controls.selection_type_option_value.setValue(selectionTypeOptionEnum.RANGE_OF_DAYS);copy_date_picker_form_group_to_class()">
                    Multiple day (up to {{date_day_and_range_picker.maximum_date_span_value + 1}} days)
                  </ion-label>
                  <!-- +1 days above to reflect the days into the past plus the current day -->
                  <ion-radio id="i_radio_date_range" class="iRadioMultipleDay automationTesting ion-margin-end" name="i_radio_date_range" slot="start"
                    [value]="selectionTypeOptionEnum.RANGE_OF_DAYS">
                  </ion-radio>
                  <div id="divMultipleDayInfo" class="divMultipleDayInfo automationTesting" style="font-size: 20px; margin-top: 4px; z-index: 2; margin-left: 5px;" pointer title="Information" (click)="onMultipleDayInfo()">
                    <ion-icon id="iIconMultipleDayInfo" class="iIconMultipleDayInfo automationTesting" color="primary" name="information-circle-outline">
                    </ion-icon>
                  </div>
                </ion-item>

              </ion-col>
            </ion-row>

          </ion-radio-group>
        </ion-col>

        <ion-col>
          <!-- the single date picker is in a different col cause the calendar pop fails if its in the ion-radio-group -->
          <div
            *ngIf="date_picker_form_group.controls.selection_type_option_value.value==selectionTypeOptionEnum.SINGLE_DATE"
            style="margin-top: 50px; margin-left: -245px;max-width: 295px;">
            <!-- this negative margin moves the control under the Single Day radio button -->
            <ion-item id="i_item_single_date" name="i_item_single_date" lines="none">
              <ion-label id="i_label_single_date" name="i_label_single_date" color="primary"
                style="padding-right: 15px;font-size: 20px;margin-top:-15px;" position="stacked">
                Date<sup validation-error>*</sup>
              </ion-label>
              <ion-input id="i_input_date_single_date" name="i_input_date_single_date" style="max-width: 150px;"
                type="date" class="ion-no-padding me-date-picker" formControlName="single_date" ngDefaultControl
                [min]="min_date_value" [max]="max_date_value" (ionBlur)="singleDayBlur($event, 'single_date')">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="date_picker_form_group.controls.selection_type_option_value.value==selectionTypeOptionEnum.RANGE_OF_DAYS">
        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
          <ion-item id="i_item_previous_date" name="i_item_previous_date" lines="none" style="padding-left:60px;">
            <ion-label id="i_label_previous_date" name="i_label_previous_date" color="primary"
              style="padding-right: 15px;font-size: 20px" position="stacked">
              Beginning Date<sup validation-error>*</sup>
            </ion-label>
            <ion-input id="i_input_date_start_date2" name="i_input_date_start_date2" style="max-width: 150px;"
              type="date" class="ion-no-padding me-date-picker" formControlName="start_date" ngDefaultControl
              [min]="min_date_value" [max]="max_date_value" (ionBlur)="startDayBlur($event)">
            </ion-input>
          </ion-item>
        </ion-col>

        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
          <div>
            <ion-item id="i_item_end_date" name="i_item_end_date" lines="none" style="padding-left:60px;">
              <ion-label id="i_label_end_date" name="i_label_end_date" color="primary"
                style="padding-right: 15px;font-size: 20px" position="stacked">
                End Date<sup validation-error>*</sup>
              </ion-label>
              <ion-input id="i_input_date_end_date" name="i_input_date_end_date" style="max-width: 150px;" type="date"
                class="ion-no-padding me-date-picker" formControlName="end_date" ngDefaultControl [min]="min_date_value"
                [max]="max_date_value" (ionBlur)="endDayBlur($event)">
              </ion-input>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>
</form>