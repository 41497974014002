<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Cancel Account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="account_sites_have_active_gateways===true" id="i_grid_account_sites_with_gateways"
    name="i_grid_account_sites_with_gateways">
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-top: 10px">
          <ion-label color="secondary" id="i_label_gateway_message_1" name="i_label_gateway_message_1">
            All gateways must be decommissioned before you can cancel your account.
          </ion-label>
        </div>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_gateway_message_2" name="i_label_gateway_message_2">
            The following gateways are active on your account:
          </ion-label>
        </div>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col style="padding-left: 10px;">
        <div scrolly="true" me-scroll-div>
          <ion-list id="i_list_of_site_gateways" name="i_list_of_site_gateways">
            <ion-item lines="none" me-ion-border *ngFor="let site_gateway of active_account_sites_with_gateways">
              <ion-label me-ion-border>{{site_gateway.name}}</ion-label>
              <ion-label me-ion-border slot="end">{{site_gateway.subscription_name}}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="account_sites_have_active_gateways===false" id="i_grid_site_without_gateways"
    name="i_grid_site_without_gateways">

    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-top: 10px">
          <ion-label color="danger" id="i_label_gateway_message" name="i_label_gateway_message">
            You are canceling your account:
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-left: 10px">
          <ion-label>
            <strong>
              <!-- {{parent_selected_site.name}} -->
            </strong>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>

        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_deactivate_message" name="i_label_deactivate_message">
            When canceling your account, note the following:
          </ion-label>
          <ul>
            <li>
              Your account will be canceled, this action cannot be undone.
            </li>
            <li>
              You will be removed from all sites.
            </li>
            <li>
              If you are a site owner, your sites will be deactivated, and all members will lose access to each site.
            </li>
            <li>
              You will no longer be able to access historical information.
            </li>
            <li>
              You will be immediately signed out.
            </li>
          </ul>
        </div>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_deactivate_message" name="i_label_deactivate_message">
            Confirm your password to cancel your account.
          </ion-label>
        </div>
      </ion-col>
    </ion-row>

    <form class="confirmPasswordForm" [formGroup]="confirmPassword">
      <ion-row>
        <ion-col>
          <div class="eyeBallWrapper ion-icon-wrapper-raw-button" (click)="toggleCurrent()"
          title="{{showCurrent ? 'Hide Current Password' : 'Show Current Password'}}">
            <ion-icon size="small" color="secondary" eye-icon-margin name="{{ showCurrent ? 'eye' : 'eye-off' }}"></ion-icon>
          </div>

          <ion-input (ionInput)="checkPasswordFieldStatus($event)" type="{{showCurrent ? 'text' : 'password'}}" formControlName="currentPassword" clearOnEdit="false" maxlength="50" id="currentPassword" (copy)="false" (paste)="false" labelPlacement="floating">
            <ion-text slot="label" color="secondary">Confirm Password<sup validation-error>*</sup></ion-text>
          </ion-input>

          <div validation-error *ngIf="confirmPassword.controls.currentPassword.hasError('minlength') && confirmPassword.controls.currentPassword.dirty">
            Password must be between 8 and 50 characters
          </div>

          <div validation-error *ngIf="confirmPassword.controls.currentPassword.hasError('required') && confirmPassword.controls.currentPassword.touched">
            Current Password is required
          </div>

          <div validation-error *ngIf="error != ''">
            {{ error != '' ? error : '' }}
          </div>
        </ion-col>
      </ion-row>
    </form>

  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" [disabled]="confirmPassword.controls.currentPassword.status == 'INVALID' || wrongPassword" *ngIf="account_sites_have_active_gateways===false" id="i_btn_cancel_account"
      name="i_btn_cancel_account" (click)="confirm_cancel_account()" color="primary" style="margin: 0 0 0 1em;">
      Cancel Account
    </ion-button>
    <ion-button slot="end" id="i_btn_ok_cancel" name="i_btn_ok_cancel" (click)="close()"
      [color]="account_sites_have_active_gateways ? 'primary' : 'reverse-primary'" style="margin: 0 1em 0 1em;">
      {{account_sites_have_active_gateways==true ? 'Ok' : 'Close'}}
    </ion-button>
  </ion-toolbar>
</ion-footer>