/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { SiteGatewaySubscriptionPlan } from '../../classes/SiteGatewaySubscriptionPlan';
import { GatewayDecommissionStepEnum, GatewaySubscriptionStatusTypeEnum } from 'src/app/enumerations/enums';
import { ModalController, AlertController, NavParams, LoadingController } from '@ionic/angular';
import { Logger } from 'src/app/common/services/logging/log.service';
import { Subscription } from 'src/app/features/manage/components/classes/Subscription';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { isNumber } from 'util';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { compareValues } from 'src/app/common/utilities/stringUtilities';
import { AccountService } from '../../services/accountService';
import { UserService } from 'src/app/common/services/user/user.service';
import { GetAccountPaymentResult } from '../../classes/HttpResult';
import {PaymentMethod} from "../../classes/PaymentMethod";

@Component({
  selector: 'app-account-gateway-decommission',
  templateUrl: './account-gateway-decommission.component.html',
  styleUrls: ['../../../sites/pages/site-gateway-register/site-gateway-register.page.scss', './account-gateway-decommission.component.scss'],
})

export class AccountGatewayDecommissionComponent implements OnInit {
  //form
  decommissionForm: UntypedFormGroup;

  //navigation
  stepUILayouts = [
    {
      stepIndex: GatewayDecommissionStepEnum.InformationStep,
      stepTitle: 'Decommission Gateway',
      showBackButton: false,
      showNextButton: true,
      showCancelButton: true,
      showCloseButton: false,
      nextButtonText: 'Next',
    },
    {
      stepIndex: GatewayDecommissionStepEnum.GatewayStep,
      stepTitle: 'Decommission Gateway (Step 1 of 3)',
      showBackButton: true,
      showNextButton: true,
      showCancelButton: true,
      showCloseButton: false,
      nextButtonText: 'Next',
    },
    {
      stepIndex: GatewayDecommissionStepEnum.DecommissionStep,
      stepTitle: 'Decommission Gateway (Step 2 of 3)',
      showBackButton: true,
      showNextButton: true,
      showCancelButton: true,
      showCloseButton: false,
      nextButtonText: 'Decommission',
    },
    {
      stepIndex: GatewayDecommissionStepEnum.SummaryStep,
      stepTitle: 'Decommission Gateway (Step 3 of 3)',
      showBackButton: false,
      showNextButton: false,
      showCancelButton: false,
      showCloseButton: true,
      nextButtonText: 'Next',
    },

  ]
  currentStepUILayout: any = null;
  subscriptionPlans: SiteGatewaySubscriptionPlan[] = [];

  //general
  gatewayDecommissionStepEnum: any;
  unsortedSubscriptionPlans: SiteGatewaySubscriptionPlan[] = [];
  subscriptionPlanList: Subscription[] = [];
  readyToDecommission = false;

  //payment
  calculatedSubscriptionTotal: any = 0;
  decomissionError = '';

  // coupons
  atLeastOneCoupon = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private alertController: AlertController,
    private logger: Logger,
    public navParams: NavParams,
    private siteService: SiteService,
    private loadingController: LoadingController,
    private planService: SubscriptionPlanService,
    private accountService: AccountService,
    public user: UserService
  ) {
    this.gatewayDecommissionStepEnum = GatewayDecommissionStepEnum;
    this.decommissionForm_create();
    this.subscriptionPlans = navParams.get('subscriptionPlans').sort(compareValues('name', 'asc'));
    this.accountService.getAccountPaymentMethodsSub().subscribe((result: GetAccountPaymentResult) => {
      this.atLeastOneCoupon = false;
      this.subscriptionPlans?.forEach(gw => {
        gw.isSelected = false;
        if (gw?.coupons?.length > 0) this.atLeastOneCoupon = true;
        // Current Subscription
        gw.subscription = (
          gw?.gatewaysubscription.find(sub =>
            sub?.gateway_subscription_status_type_id === GatewaySubscriptionStatusTypeEnum.Active)
        )?.subscriptions;
        gw.activeSubscription = (
          // It's expiring but not expired
          gw?.gatewaysubscription?.find(sub =>
            sub?.gateway_subscription_status_type_id === GatewaySubscriptionStatusTypeEnum.Expired &&
            new Date(sub?.expires_at + ' 23:59') >= new Date())
          ||
          // It's current
          gw?.gatewaysubscription?.find(sub =>
            sub?.gateway_subscription_status_type_id === GatewaySubscriptionStatusTypeEnum.Active)
        );

        if (gw?.subscription?.monthly_rate > 0) gw.paymentMethod = (result.pmt_methods as PaymentMethod[] || [])
            .find(pm => pm?.site_id === gw?.site_id);
      });
    })
    this.planService.getPlans();
  }

  // Referenced from activeSubscription in getAccountOwnerGateways() within account-detail-main.component.ts
  getCurrentSubscription(sp) {
    const gwSubs = sp?.gatewaysubscription;
    const latestUpdatedSubscription = gwSubs?.find(sub => parseFloat(sub.gateway_subscription_status_type_id) === GatewaySubscriptionStatusTypeEnum.Active);
    const subscriptionThatHasntExpiredYet = gwSubs?.find(sub => parseFloat(sub.gateway_subscription_status_type_id) === GatewaySubscriptionStatusTypeEnum.Expired && new Date(sub.expires_at + ` 23:59`) >= new Date());
    const currentSubscription = subscriptionThatHasntExpiredYet || latestUpdatedSubscription;
    const currentSubscriptionName = currentSubscription?.subscriptions?.name;
    const monthly_rate = currentSubscription?.subscriptions?.monthly_rate;
    const subscriptionParameters = {
      gwSubs,
      monthly_rate,
      currentSubscription,
      currentSubscriptionName,
      latestUpdatedSubscription,
      subscriptionThatHasntExpiredYet,
    };
    return subscriptionParameters;
  }

  ngOnInit() {
    this.setInitialFocus();
    this.handleNavigationUISetup(GatewayDecommissionStepEnum.InformationStep);
  }

  setInitialFocus() {
    document.getElementById("iconGateway").focus();
  }

  calculateSubscriptionTotal(siteGatewaySubscriptionPlans: SiteGatewaySubscriptionPlan[]): number {
    this.calculatedSubscriptionTotal = siteGatewaySubscriptionPlans.reduce(function (subTotal, siteGatewaySubscriptionPlan) {
      return subTotal + (isNumber(siteGatewaySubscriptionPlan.subscription_plan_monthly_rate) ? siteGatewaySubscriptionPlan.subscription_plan_monthly_rate : 0);
    }, 0);

    return this.calculatedSubscriptionTotal;
  }

  //form
  decommissionForm_create() {
    this.decommissionForm = this.formBuilder.group({
      decommission_gateways: [false, Validators.required]
    });

  }

  setReadyToDecommission() {
    this.readyToDecommission = this.decommissionForm.controls.decommission_gateways.value;
  }

  //navigation
  async handleNavigationUISetup(gatewayDecommissionStepEnum: GatewayDecommissionStepEnum) {
    if ((gatewayDecommissionStepEnum >= GatewayDecommissionStepEnum.InformationStep) && (gatewayDecommissionStepEnum <= GatewayDecommissionStepEnum.SummaryStep)) {
      this.currentStepUILayout = this.stepUILayouts[gatewayDecommissionStepEnum];

      switch (this.currentStepUILayout.stepIndex) {
        case GatewayDecommissionStepEnum.InformationStep:
        case GatewayDecommissionStepEnum.GatewayStep:
          this.decommissionForm.controls.decommission_gateways.setValue(false);
          this.readyToDecommission = false;
          break;
        case GatewayDecommissionStepEnum.DecommissionStep:
        case GatewayDecommissionStepEnum.SummaryStep:
          break;
      }
    }
  }

  handleBackButton() {
    this.decomissionError = ``;
    const nextStep = this.currentStepUILayout.stepIndex - 1;
    this.handleNavigationUISetup(nextStep);
  }

  async handleNextButton() {
    let nextStep = 0;
    nextStep = this.currentStepUILayout.stepIndex + 1;
    this.decomissionError = ``;

    switch (nextStep) {
      case GatewayDecommissionStepEnum.SummaryStep:
        {
          // before we continue with the decomission - make sure there isn't a pending mfk task
          // on this gateway active.
          const gateway_serial_numbers = this.subscriptionPlans.map(sub => sub.serial_number)
          let gateways_in_lock = false;
          let gateways_checked = 0;
          gateway_serial_numbers.forEach( (gateway_serial) => {
            this.siteService.checkLock(gateway_serial, this.user.active.id).subscribe( async result => {
              gateways_checked += 1;
              if (result != 0) gateways_in_lock = true;
              if (gateways_checked == gateway_serial_numbers.length) {
                // then we are the last one - are any in lock?
                if (gateways_in_lock) {
                  // then yes - 1 or more gateways in the list are mfk locked.
                  this.displayGatewaysMfkLockedAlert();
                } else {
                  // its ok to continue with the decomission
                  const load = await this.loadingController.create({
                    spinner: `lines`,
                    message: `Decommissioning gateway(s)...`
                  });
        
                  load.present();
        
                  const sendable = this.subscriptionPlans.map(sub => sub.id);
                  const response: any = await this.siteService.decommissionGatewayPromise(this.subscriptionPlans[0].site_id, sendable);
        
                  load.dismiss();
        
                  if (response?.error) {
                    // Decomission error
                    this.decomissionError = response?.error;
                  } else {
                    this.siteService.emit_siteGatewayDecomissioned(sendable);
                    this.accountService.subscriptionDetailsUpdated = true;
                    this.handleNavigationUISetup(nextStep);
                  }
                }
              }
            },
            (error) => {
              console.log('Failed to check mfk lock:'+error)
              gateways_checked += 1;
              if (gateways_checked == gateway_serial_numbers.length) {
                // then we are the last one - and we threw an error.
                // display a msg that can't continue.
                this.displayGatewaysMfkLockedAlert();
              }
            });
          })
        }
        break;
      default:
        this.handleNavigationUISetup(nextStep);
        break;
    }
  }


  async displayGatewaysMfkLockedAlert() {
    // display the alert that there are gateways active doing maintenance actions and can't be decomissioned at this time.
    const alert = await this.alertController.create({
      header: 'Gateway Busy',
      message: '<div class="me-redClass">One or more gateways are currently performing maintenance activities and can not be decomissioned at this time.</div></br><div class="me-centerText">Please try again later.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Ok',
          cssClass: 'exit-button',
         }
      ]
    });

    await alert.present();    
  }


  async handleCancelButton() {

    const alert = await this.alertController.create({
      header: 'Cancel Decommission Gateway',
      message: '<div class="me-redClass">Are you sure you want to cancel?</div></br><div class="me-centerText">If you cancel, this process will not be complete.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.logger.debug('Cancellation confirmed.');
            this.modalController.dismiss();
          }
        }, {
          text: 'No',
          cssClass: 'back-button',
          handler: () => {
            this.logger.debug('Cancellation cancelled.');
          }
        }
      ]
    });

    await alert.present();
  }

  handleCloseButton() {
    if (this.currentStepUILayout.stepIndex == GatewayDecommissionStepEnum.SummaryStep) {
      this.modalController.dismiss({ decommissioned: true });
    }
  }

}
