<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Notice</ion-title>
    <ion-item lines="none" slot="end">
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(true)"> Start </ion-button>
      </div>
      <div class="ion-icon-wrapper-card-header">
        <ion-button class="button-style" (click)="close(false)"> Cancel </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-label>
        ・This function is effective only for CityMulti cooling/heating simultaneous system (R2).
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・This function operates start/stop, operation mode, temperature setting,
        <br> &nbsp;&nbsp;&nbsp;
        and air flow rate of indoor units.Diagnosis is performed for each group of indoor units.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・The estimated time for diagnosis is approximately 5 to 10 minutes per group.
        <br> &nbsp;&nbsp;&nbsp;
        If the outdoor unit is in defrost control during diagnosis, the diagnosis cannot be performed correctly.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・If the user operates the remote control during the diagnosis,
        the diagnosis cannot be performed correctly.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・If timer or schedule function is used, please turn off the setting.
        <br>&nbsp;&nbsp;&nbsp;
        Operation data during diagnosis can be checked on the offline analysis screen.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・After diagnosis, be sure to check the operation data in the offline analysis and
        make a final judgment by the user.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・Since operation is performed on a group basis,
        it is not possible to detect a branch outlet error within the same group.
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label>
        ・If another user has already started an inspection,
        a screen indicating that the device is already being inspected,
        which is different from the selected device, will be displayed.
      </ion-label>
    </ion-row>

    <ion-row bcport-example class="flex ion-padding-vertical ion-justify-content-center me-ring-col"></ion-row>

  </ion-grid>
</ion-content>