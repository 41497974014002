import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCardType'
})
export class FormatCardTypePipe implements PipeTransform {

  transform(value: string): string {
    return (value && value[0] && value[0].length > 0) ? value[0].toUpperCase() + value.slice(1) : "";
  }

}
