import { Component, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { SiteService } from '../../services/site.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';

@Component({
  selector: 'app-uninvite',
  templateUrl: './uninvite.component.html',
  styleUrls: ['./uninvite.component.scss']
})
export class UninviteComponent /* implements OnInit  */ {
  @Input() invite: any;
  @Input() pending: boolean;
  @Input() memberIsMe: boolean;

  constructor(
    private modalController: ModalController,
    private siteService: SiteService,
    private loading: LoadingController,
    private user: UserService,
    private socketSerivce: SocketService,
    private mainSiteUIService: MainSiteUIService) { }

  //ngOnInit() { }

  async uninvite() {

    if (!this.siteService.handleIsConnected())
      return;


    const load = await this.loading.create({
      spinner: 'lines',
      message: 'Removing site member...',
      translucent: true
    });

    load.present();
    if (this.pending) {
      this.siteService.uninviteMember(this.invite.invite, this.user.active.id).subscribe(
        data => {
          this.user.activeSiteInvites = this.user.activeSiteInvites.filter(siteInvite => siteInvite.invite !== this.invite.invite);
          load.dismiss();
          this.modalController.dismiss({ success: true });
        },
        err => {
          load.dismiss();
          this.modalController.dismiss({ success: false });
        }
      );
    } else {
      this.siteService.removeSiteMember(this.user.active.id, this.invite.id).subscribe(data => {
        this.user.activeSiteAccounts = this.user.activeSiteAccounts.filter(siteAccount => siteAccount.id !== this.invite.id);
        if (!this.memberIsMe) {
          // then do some cleanup so the site looks good.
          const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
          siteAlertFilter.site_id = this.user.active.id;
          siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

          this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
          this.socketSerivce.joinRooms();
        }
        load.dismiss();
        this.modalController.dismiss({ success: true });
      }, error => {
        load.dismiss();
      });
    }
  }

  cancel() {
    this.modalController.dismiss({ success: false });
  }
}
