import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import moment from 'moment';

export enum SelectionTypeOptionEnum {
  UNSELECTED = 0,
  SINGLE_DATE = 1,
  RANGE_OF_DAYS = 2,
}

export class DateParts {
  dateYear: string;
  dateMonth: string;
  dateDay: string;
  date_string: string;

  constructor() {
    this.dateYear = '';
    this.dateMonth = '';
    this.dateDay = '';
    this.date_string = '';
  }
}

export class DateDayAndRangePicker {
  selection_type_option_value: number;

  maximum_date_span_value: number;
  //maximum_selected_date_range_value: number;

  min_date_value: string;
  //  min_date:Date;
  max_date_value: string;
  //  max_date:Date;

  //min_single_date: string;
  //max_single_date: string;
  single_date_value: string;

  //min_start_date: string;
  //max_start_date: string;
  start_date_value: string;

  //min_end_date: string;
  //max_end_date: string;
  end_date_value: string;

  constructor() {
    this.selection_type_option_value = SelectionTypeOptionEnum.UNSELECTED;

    this.maximum_date_span_value = 5;

    this.min_date_value = '';
    //    this.min_date = new Date();
    this.max_date_value = '';
    //    this.max_date = new Date();


    //this.min_single_date = '';
    //this.max_single_date = '';
    this.single_date_value = ''

    //this.min_start_date = '';
    //this.max_start_date = '';
    this.start_date_value = '';

    //this.min_end_date = '';
    //this.max_end_date = '';
    this.end_date_value = '';

  }
}

export function convert_date_to_date_string(date_to_convert: Date): string {
  let date_string: string = '';
  
  const dateYear = date_to_convert.getFullYear().toString();
  const dateMonth = (date_to_convert.getMonth() + 1).toString().padStart(2,'0')
  const dateDay = date_to_convert.getDate().toString().padStart(2,'0')

  date_string = `${dateYear}-${dateMonth}-${dateDay}`;

  return date_string;
}

export function get_date_parts_from_date(date_to_part: Date): DateParts {
  let date_parts_value: DateParts = new DateParts();
  let month_part: string = '';
  let day_part: string = '';

  date_parts_value.dateYear = date_to_part.getFullYear().toString();
  month_part = (date_to_part.getMonth() + 1).toString();
  date_parts_value.dateMonth = month_part.length == 1 ? '0' + month_part : month_part;
  day_part = date_to_part.getDate().toString();
  date_parts_value.dateDay = day_part.length == 1 ? '0' + day_part : day_part;
  date_parts_value.date_string = `${date_parts_value.dateYear}-${date_parts_value.dateMonth}-${date_parts_value.dateDay}`

  return date_parts_value;
}

export function calculate_date_from_span(start_date: Date, allowed_day_span: number): DateParts {
  let date_parts: DateParts = new DateParts();

  start_date.setDate(start_date.getDate() + allowed_day_span);
  date_parts = get_date_parts_from_date(start_date);

  return date_parts;
}

export function calculate_date_diff_for_today(start_date: string): number {
  const start_date_check: Date = new Date(start_date + 'T00:00:00');
  const date_parts: DateParts = get_date_parts_from_date(new Date());
  const todays_date: Date = new Date(date_parts.date_string + 'T00:00:00');
  const diff_in_days = calculate_date_diff(todays_date, start_date_check);
  return diff_in_days;
}

export function calculate_date_diff(start_date: Date, end_date: Date): number {
  const diff_in_time: number = start_date.getTime() - end_date.getTime();
  const diff_in_days: number = diff_in_time / (1000 * 3600 * 24);
  return diff_in_days;
}
@Component({
  selector: 'app-date-day-and-range-picker',
  templateUrl: './date-day-and-range-picker.component.html',
  styleUrls: ['./date-day-and-range-picker.component.scss'],
})
export class DateDayAndRangePickerComponent implements OnInit {
  @Input() date_day_and_range_picker: DateDayAndRangePicker;

  date_picker_form_group: UntypedFormGroup;
  public selectionTypeOptionEnum = SelectionTypeOptionEnum;
  //single_date: string;
  min_date_value: string;
  min_date: Date;
  max_date_value: string;
  max_date: Date;
  //min_start_date: string;
  //max_start_date: string;
  //min_end_date: string;
  //max_end_date: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private alertController: AlertController) {

  }

  ngOnInit() {
    this.date_picker_form_group_create();
  }

  date_picker_form_group_create() {
    const date_validation_pattern = "((?:19|20)\\d\\d)-(0?[1-9]|1[012])-([12][0-9]|3[01]|0?[1-9])";

    this.min_date_value = this.date_day_and_range_picker.min_date_value;
    this.min_date = moment(this.min_date_value, "YYYY-MM-DD").toDate();
    this.max_date_value = this.date_day_and_range_picker.max_date_value;
    this.max_date = moment(this.max_date_value, "YYYY-MM-DD").toDate();
    //this.min_start_date = this.date_day_and_range_picker.min_start_date;
    //this.max_start_date = this.date_day_and_range_picker.max_start_date;
    //this.min_end_date = this.date_day_and_range_picker.min_end_date;
    //this.max_end_date = this.date_day_and_range_picker.max_end_date;

    this.date_picker_form_group = this.formBuilder.group({
      selection_type_option_value: [this.date_day_and_range_picker.selection_type_option_value, Validators.required],
      single_date: [this.date_day_and_range_picker.single_date_value, Validators.pattern(date_validation_pattern)],
      start_date: [this.date_day_and_range_picker.start_date_value, Validators.pattern(date_validation_pattern)],
      end_date: [this.date_day_and_range_picker.end_date_value, Validators.pattern(date_validation_pattern)],
    });
  }

  copy_date_picker_form_group_to_class(): DateDayAndRangePicker {
    this.date_day_and_range_picker.selection_type_option_value = parseInt(this.date_picker_form_group.value.selection_type_option_value);
    this.date_day_and_range_picker.single_date_value = this.date_picker_form_group.value.single_date;
    this.date_day_and_range_picker.start_date_value = this.date_picker_form_group.value.start_date;
    this.date_day_and_range_picker.end_date_value = this.date_picker_form_group.value.end_date;

    return this.date_day_and_range_picker;
  }

  singleDayBlur(event, form_group_control_name: string) {
    let updatedValue = event.target.value;
    // check if the value is outside valid
    let fixedValue = this.valid_new_date_range(updatedValue)
    if (updatedValue != fixedValue) {
      // udpate value
      let patchValues = { single_date: fixedValue };
      this.date_picker_form_group.patchValue(patchValues);
    }
    this.copy_date_picker_form_group_to_class();

    return fixedValue;
  }

  startDayBlur(event) {
    // validate start day change
    let updatedValue = event.target.value;
    let fixedValue = this.valid_new_date_range(updatedValue);
    if (updatedValue != fixedValue) {
      // then we need to update the start_day value
      let patchValues = { start_date: fixedValue }
      this.date_picker_form_group.patchValue(patchValues);
    }
    // check for end day overlaps
    let endDateValue = this.date_picker_form_group.controls.end_date.value;
    let fixedDate = moment(fixedValue, "YYYY-MM-DD").toDate();
    let endDate = moment(endDateValue, "YYYY-MM-DD").toDate();
    if (fixedDate > endDate) {
      // then we need to push a change to end-date to be fixedDate
      let patchValues = { end_date: fixedValue }
      this.date_picker_form_group.patchValue(patchValues)
    }
    this.copy_date_picker_form_group_to_class();
  }

  endDayBlur(event) {
    // end day change
    let updatedValue = event.target.value;
    let fixedValue = this.valid_new_date_range(updatedValue);
    if (updatedValue != fixedValue) {
      // then we need to update the end_day value
      let patchValues = { end_date: fixedValue }
      this.date_picker_form_group.patchValue(patchValues);
    }
    let startDateValue = this.date_picker_form_group.controls.start_date.value;
    let fixedDate = moment(fixedValue, "YYYY-MM-DD").toDate();
    let startDate = moment(startDateValue, "YYYY-MM-DD").toDate();
    if (fixedDate < startDate) {
      // then we need to push a change to start-date to be fixedDate
      let patchValues = { start_date: fixedValue }
      this.date_picker_form_group.patchValue(patchValues)
    }
    this.copy_date_picker_form_group_to_class();
  }

  valid_new_date_range(updatedValue: string) {
    // check whether this new date value is too new or too old.
    let selected_date = moment(updatedValue, "YYYY-MM-DD").toDate();

    if (selected_date > this.max_date) return this.max_date_value;
    if (selected_date < this.min_date) return this.min_date_value;
    return updatedValue;
  }

  async displayInformation(msg) {

    const alert = await this.alertController.create({
      header: 'Information',
      message: msg,
      backdropDismiss: true,
      cssClass: 'me-info-button-css',

      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',
          handler: () => {
            this.alertController.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  onMultipleDayInfo() {
    this.displayInformation(`Download up to ${ this.date_day_and_range_picker.maximum_date_span_value + 1} days of data.  Data selected for the current day is available within an hour and a half of the current time.`);
  }

  async onSingleDayInfo() {
    this.displayInformation('Download a day of data.  Data is available within an hour and a half of the current time.');
  }

}
