import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import {
  MAPUNITS_WAIT_TIME,
  TOAST_GENERAL_ERROR_TITLE,
  TOAST_SUCCESS_TITLE,
  TOAST_CONNECTIVITY_ISSUE,
  TOAST_FAILED_TO_DOWNLOAD_CSV,
  MODAL_MAINT_UPDATE_UNIT_ERROR,
  MODAL_MAINT_UPDATE_UNIT_BLOCK
} from 'src/app/constants/kenzaconstants';
import {
  ToastMessageTypeEnum,
  TemperaturePreferenceEnum,
  PressurePreferenceEnum
} from 'src/app/enumerations/enums';
import { Logger } from 'src/app/common/services/logging/log.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import { TemperatureConversions, PressureConversions } from 'src/app/common/utilities/conversionUtilities';
import moment from 'moment-timezone';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { MaintMapUnitsErrorComponent } from 'src/app/features/maintenance/modals/maint-map-units-error/maint-map-units-error.component';

@Component({
  selector: 'testdrive-history',
  templateUrl: './testdrive-history.component.html',
  styleUrls: ['./testdrive-history.component.scss'],
})
export class TestDriveHistoryComponent /*implements OnInit*/ {
  timeDif = new Date().getTimezoneOffset();                        // Time difference between Coordinated Universal Time and system time
  utcOffset = null;  // Time difference between Coordinated Universal Time and property time
  nowTime = new Date();                                            // current time @ Coordinated Universal Time

  displayedColumns: string[] = ['ended_at_sitetime', 'system_name', 'ou_model_name', 'ou_serial_no', 'ou_address', 'testruntime', 'ope_mode', 'outside_temperature', 'status', 'report']; // List of columns for tables
  gatewayId: string;    // gateway ID
  timeHeader =  [];     // Array for CSV output, time part(line1)
  dataSource = [];      // Array for CSV output, data part(line2 and following)
  dataSourceHistory = [];      // commissioning history List
  systemName: string;   // Device name of OutdoorUnit for history display
  modelName: string;    // Model name of OutdoorUnit for history display
  serialNo: string;     // Serial number of OutdoorUnit for history display
  ouAddress: number;      // OutdoorUnit Address
  gatewaySerialNo: string; // Serial number of RMDs for history display
  unitHistory = [];     // List of equipment information for historical data
  csvTestRunId: string;     // Commissioning ID of data for csv output

  gatewayName: string;
  deviceType: string;
  serialNumber: string;
  gwData = [];

  socketEventSubscription: Subscription;    // for web socket communication
  apicalling = false;              // for Exclusion Check

  loadMain;             // for display of effects during loading
  siteGatewaySubscription;  // for web socket communication
  maintRequestId: uuid = null;              // Parameters for data acquisition

  csvFileName = null;

  constructor(
    public _ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    public user: UserService,
    private siteService: SiteService,
    private socketService: SocketService,
    private loadingController: LoadingController,
    public modalController: ModalController,
    private logger: Logger,
  ) { }

  // ngOnInit() {

  // }

  ionViewWillEnter() {
    // Processing at initial screen display
    this.timeHeader = [];
    this.dataSource = [];
    this.dataSourceHistory = [];
    this.unitHistory = [];
    this.systemName = '-';
    this.modelName = '-';
    this.serialNo = '-';
    this.apicalling = false;

    this.utcOffset = moment().tz(this.user.active.timezone).utcOffset();  // Time difference between Coordinated Universal Time and property time

    // OutdoorUnit情報取得（画面上部の試運転対象機器エリアに表示する内容）
    // Get OutdoorUnit information(displayed in the equipment area for commissioning at the top of the screen)
    this.route.queryParams.subscribe(params => {
      this.gatewayId = params.target;
      this.siteGatewaySubscription = this.siteService.get_site_gateways_with_groups(this.user.active.id, true)
        .subscribe((gateways: GatewayWithGroups) => {
          this.setGateways(gateways);
        });
    });
    // 試運転データ受信
    // Receiving trial run data
    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      this.receiveMaintData(socketResult);
    });
  }

  ionViewWillLeave() {
    // 別画面への遷移時処理
    // Processing when transitioning to other screen
    if(this.siteGatewaySubscription){
      this.siteGatewaySubscription.unsubscribe();
    }
    if(this.socketEventSubscription){
      this.socketEventSubscription.unsubscribe();
    }
    if (this.loadMain && this.loadMain.dismiss){
      this.loadMain.dismiss();
    }
  }

  backToTestRunTop() {
    // 試運転機器一覧画面へ戻る
    // Back to List of Commissioning Equipment
    if (!this.siteService.handleIsConnected())
      return;

    this.router.navigate(['/testdrive', this.user.active.id, 'list']);
  }

  async present_loadMain() {
    // ローディング処理
    // loading
    this.loadMain = await this.loadingController.create({
      message: 'loading...',
      spinner: 'lines',
      duration: MAPUNITS_WAIT_TIME,
    });

    await this.loadMain.present();
  }

  setGateways(gateways: GatewayWithGroups) {
    // OutdoorUnitデータ取得
    // OutdoorUnit data acquisition

    let noRMDflg = true;
    let gatewaysWithGroups: Gateway[] = [];
    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True' && gwg.outdoor_units.length > 0){
          if(gwg.id === this.gatewayId){
            
            this.gatewayName = '-';
            this.deviceType = '-';
            this.serialNumber = '-';
            
            if (gwg.name){
              this.gatewayName = gwg.name
            }
            if (gwg.model.name){
              this.deviceType = gwg.model.name
            }
            if (gwg.serial_number){
              this.serialNumber = gwg.serial_number
            }

            // GWに登録されているOUアドレスとシステム名を格納する
            // Store the OU address and system name registered in GW
            for (let i=0; i < gwg.outdoor_units.length; i++){
              let systemName = '-'
              let address = null
              if (gwg.outdoor_units[i].name){
                systemName = gwg.outdoor_units[i].name
              }
              if(gwg.outdoor_units[i].bus_address){
                address = Number(gwg.outdoor_units[i].bus_address)
              }
              const addData = {
                SystemName: systemName,
                Address: address,
              };
              this.gwData.push(addData);
            }
            noRMDflg =  false;
          }
        }
      });

      // 対象のRMDが存在しない場合エラー
      // Error if target RMD does not exist
      if(noRMDflg){
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          TOAST_CONNECTIVITY_ISSUE
        );
        return;
      }

      // 履歴取得を行う
      //  history acquisition is performed.
      this.getTestrunHistory();
    }
  }

  getTestrunHistory(){
    // 履歴一覧データ取得
    // Acquisition of history list data
    this.siteService.getTestrunHistory(this.gatewayId).subscribe(
      (resData: any) => {
        if(resData.length > 0){
          // job_id = 2600(試運転)のレコードのみdataSourceHistoryに格納
          // Only records with job_id = 2600 (trial run) are stored in dataSourceHistory
          this.dataSourceHistory = resData.filter(item => item.job_id === 2600);

          if(this.dataSourceHistory.length > 0){
            this.formatDataSourceHistory();

            this._ref.detectChanges();
            return;
          }
        }
        // データが0件の場合、エラートースト表示
        // Error toast displayed if 0 data
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          'No data available.'
        );
        this._ref.detectChanges();
      },
      (err) => {
        // 取得に失敗した場合、エラートースト表示
        // Error toast displayed if acquisition fails
        this.siteService.presentToastMessage(
          ToastMessageTypeEnum.Error,
          TOAST_GENERAL_ERROR_TITLE,
          'Failed to acquire historical data.'
        );
        console.log('getTestrunHistory=>err', err);
      }
    );
  }

  formatDataSourceHistory() {
    this.dataSourceHistory.map(value => {
      // 外気温度単位変換（華氏の場合、変換関数を使用して変換）
      // Outside air temperature unit conversion (in Fahrenheit, use conversion function to convert)
      value.outside_temperature_c = value.outside_temperature; // Leave Celsius for CSV output
      if(value.outside_temperature){
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          value.outside_temperature = 
            (Number(value.outside_temperature) * (9 / 5) + 32).toFixed(1) + '°F';
        }else{
          value.outside_temperature = Number(value.outside_temperature).toFixed(1) + '°C';
        }
      }else{
        value.outside_temperature = '-'
      }
      // 開始時刻='None'の場合、値消去
      // If start time = 'None', value is deleted
      if(value.started_at === 'None'){
        value.started_at = ''
      }
      else{
        // 「開始時刻」のタイムゾーン補正版を別途作成
        // Create a separate time zone-corrected version of "Start Time
        const startDt = new Date(value.started_at);
        startDt.setMinutes(startDt.getMinutes() + this.utcOffset);
        value.started_at_sitetime = this.dateToString(startDt);
      }
      // 終了日時='None'の場合、値消去
      // If end date/time = 'None', value is deleted
      if(value.ended_at === 'None'){
        value.ended_at = ''
      }
      else{
        // 「終了時刻」のタイムゾーン補正版を別途作成
        // Create a separate time zone-corrected version of End Time
        const endDt = new Date(value.ended_at);
        endDt.setMinutes(endDt.getMinutes() + this.utcOffset);
        value.ended_at_sitetime = this.dateToString(endDt);
      }

      // 履歴のOUアドレスがRMDに紐づいているOUアドレスと一致する場合、
      // RMDに紐づいているSystemNameを表示する
      // If the OU address in the history matches the OU address associated with the RMD
      // Display SystemName associated with RMD
      value.system_name = '-'
      for (let i=0; i<this.gwData.length; i++){
        if (this.gwData[i].Address === value.ou_address){
          value.system_name = this.gwData[i].SystemName;
          break;
        }
      }
    });
  }

  async open_map_units_error(errMsg) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: MaintMapUnitsErrorComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
      componentProps: {
        errorMessage: errMsg
      },
    });
    return await modal.present();
  }

  getMaintData(startedAt, endedAt, testrunId, unitHistoryId, ouAddress){
    // CSVダウンロード処理
    // CSV download process

    // 機種情報更新が一度も成功していない場合
    // If the model information update has never been successful
    if(!unitHistoryId){
      this.open_map_units_error(MODAL_MAINT_UPDATE_UNIT_BLOCK);
      return;
    }

    // 排他確認API実行中は処理止める(連打防止)
    // Stop processing while executing the exclusive check API (to prevent consecutive hits)
    if(this.apicalling){
      return;
    }

    // 要素数設定：時間差(分)+1とする ※分未満切り捨て
    // Number of elements setting: Time difference (minutes) + 1 *Rounded down to the nearest minute
    startedAt = this.dateToString(new Date(startedAt));
    endedAt = this.dateToString(new Date(endedAt));
    const point = Math.floor((new Date(endedAt).getTime() - new Date(startedAt).getTime()) / (60*1000)) + 1;

    this.maintRequestId = uuid();

    const obj = {
      gateway_id: this.gatewayId,
      site_id: this.user.active.id,
      request_id: this.maintRequestId,
      start_date: this.date12(this.dateToString(new Date(startedAt))),   // UTC
      end_date: this.date12(this.dateToString(new Date(endedAt))),   // UTC
      resolution: point,
      monitor_mode: 1,
      address: [ouAddress]
    }

    // 排他確認処理
    // exclusion check process
    this.apicalling = true;
    this.siteService.checkLock(this.gatewaySerialNo, this.user.active.id).subscribe(
      (resData: any) => {
        if (resData === 2100){
          this.apicalling = false;
          this.open_map_units_error(MODAL_MAINT_UPDATE_UNIT_ERROR);
        }else{
          this.apicalling = false;
          this.csvTestRunId = testrunId;

          // ダウンロードボタンを非活性にする
          // Deactivate download button
          const download = document.getElementsByClassName('download-button-style') as HTMLCollectionOf<HTMLInputElement>;
          for (let i = 0; i < download.length; i++) {
            download[i].disabled = true;
          }

          this.present_loadMain().then(() => {
            // 機器情報取得
            // Equipment information acquisition
            this.siteService.getUnitHistory(this.gatewayId, unitHistoryId).subscribe(
              (resData2: any) => {
                this.unitHistory = resData2;
                // メンテデータ取得
                // Maintenance data acquisition
                if (!this.socketService.getMaintData(obj)) {
                  if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
                  // ダウンロードボタンを活性にする
                  // Activate download button
                  const download = document.getElementsByClassName('download-button-style') as HTMLCollectionOf<HTMLInputElement>;
                  for (let i = 0; i < download.length; i++) {
                    download[i].disabled = false;
                  }
                  this.siteService.presentToastMessage(
                    ToastMessageTypeEnum.Error,
                    TOAST_GENERAL_ERROR_TITLE,
                    TOAST_FAILED_TO_DOWNLOAD_CSV
                    );                  
                  this._ref.detectChanges();
                }
              },
              (err) => {
                if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();
                // ダウンロードボタンを活性にする
                // Activate download button
                const download = document.getElementsByClassName('download-button-style') as HTMLCollectionOf<HTMLInputElement>;
                for (let i = 0; i < download.length; i++) {
                  download[i].disabled = false;
                }
                this._ref.detectChanges();
                console.log('getUnitHistory=>err', err);
              }
            );
          });
        }
      },
      (err) => {
        this.apicalling = false;
        console.log('getRedisInfo=>err', err);
        this.open_map_units_error(MODAL_MAINT_UPDATE_UNIT_ERROR);
      }
    );
  }

  receiveMaintData(socketResult){
    try {
      // メンテデータ受信処理
      // Maintenance data receiving process
      if(socketResult.response.request_id === this.maintRequestId){
        if(!this.importRunData(socketResult.response.data)){
          this.siteService.presentToastMessage(
            ToastMessageTypeEnum.Error,
            TOAST_GENERAL_ERROR_TITLE,
            TOAST_FAILED_TO_DOWNLOAD_CSV
          );
        }
        // ローディング解除
        // Loading canceled
        if (this.loadMain && this.loadMain.dismiss) this.loadMain.dismiss();

        // ダウンロードボタンを活性にする
        // Activate download button
        const download = document.getElementsByClassName('download-button-style') as HTMLCollectionOf<HTMLInputElement>;
        for (let i = 0; i < download.length; i++) {
          download[i].disabled = false;
        }
        this._ref.detectChanges();
      }
    } catch(exception) {
      console.log('receiveMaintData=>exception');
    }
  }

  importRunData(json) {
    try {
      // dataSourceにデータ追加(丸ごと入れ替え)
      // Add data to dataSource (replace the entire data source)
      const data = JSON.parse(json);

      // 表示用配列初期化
      // Initialization of display array
      this.timeHeader = [];
      this.dataSource = [];

      this._ref.detectChanges();

      let machineName: string;

      /* jsonファイルから項目リスト(表の1列目)の作成 */
      /* Creating a list of items (first column of a table) from a json file */

      // 機器台数を取得
      // Get the number of devices
      const machineCount = Object.keys(data['operationMonitor']['cycles'][0]['units']).length;

      // 各機器の項目一覧を取得
      // Get a list of items for each device
      for(let i=0; i<machineCount; i++){
        // アドレス取得（桁数を3桁に揃える）
        // Get address (align digits to 3 digits)
        machineName = data['operationMonitor']['cycles'][0]['units'][i]['address'];
        machineName = ('00' + machineName).slice(-3);
        // 属性設定
        // Attribute Setting
        machineName = data['operationMonitor']['cycles'][0]['units'][i]['attribute'] + '(' + machineName + ')';

        // 項目数カウント
        // Item Count
        const itemCount = Object.keys(data['operationMonitor']['cycles'][0]['units'][i]['items']).length;
        // 項目名設定（Key1には属性・アドレス・項目名を、Key2には閾値判定用にnameId1_nameId2を、それぞれ格納）
        // Item name setting (Key1 stores attribute, address, and item name, Key2 stores nameId1_nameId2 for threshold judgment, respectively)
        for(let k=0; k<itemCount; k++){
          const addData = {
            Key1: machineName + ' ' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['name'],
            Key2: data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['nameId1'] + '_' + data['operationMonitor']['cycles'][0]['units'][i]['items'][k]['nameId2']
          }
          this.dataSource.push(addData);
        }
      }

      /* 運転データを表示用配列に格納 */
      /* Stores operation data in an array for display */

      // 受信データの時間の数（列数）カウント
      // Number of hours (number of columns) count of received data
      const timeCount = Object.keys(data['operationMonitor']['cycles']).length;
      let columnName = '';  // The item name (TimeData_X) of the dataSource

      // 順に配列に格納
      // stored in array in order
      for(let i=0; i<timeCount; i++){

        // html用カラム名設定
        // set column name for html
        columnName = 'TimeData_' + i;
        const timeDataIdx = data['operationMonitor']['cycles'][i]['date']; // The name (YYYY/MM/DD HH:MM) of the timeHeader
        this.timeHeaderPlus(timeDataIdx, columnName); // Store date and time in header

        // dataSourceにデータセット
        // DataSet in dataSource
        let rowIdx = 0;
        const unitCount = Object.keys(data['operationMonitor']['cycles'][i]['units']).length;

        for(let k=0; k<unitCount; k++){

          const itemCount = Object.keys(data['operationMonitor']['cycles'][i]['units'][k]['items']).length;
          for(let m=0; m<itemCount; m++){
            this.dataSource[rowIdx][columnName] =
              [this.dataConv(data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['data2'],
                data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['unitId']),
              data['operationMonitor']['cycles'][i]['units'][k]['items'][m]['data2']];
            rowIdx++;
          }
        }
      }

      this.downloadCSV();
      return true;

    } catch (error) {
      return false;

    }
  }

  timeHeaderPlus(timeDataIdx, columnName){
    // 受信時刻(UTC)を物件時刻に変換
    // Converts received time (UTC) to property time
    const td = new Date(timeDataIdx);
    td.setMinutes(td.getMinutes() + this.timeDif + this.utcOffset);
    timeDataIdx = this.dateToString(td);

    // timeHeaderに項目追加
    // Add item to timeHeader
    this.timeHeader.push({def: columnName, name: timeDataIdx});
  }

  dataConv(data2, unitId){
    // 単位変換
    // Unit Conversion
    let resp: string;

    // 数値以外の場合、空欄・nullの場合は変換しない
    // If non-numeric, blank, or null, do not convert
    if(isNaN(data2) || !data2){
      return data2;
    }

    switch(unitId){
      case 0:
        // 数値：何もしない
        // Value: Do nothing
        resp = data2;
        break;
      case 1:
        // 温度(設定温度以外)：通常変換
        // Temperature (other than setpoint): Normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5) + 32).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 2:
        // 温度(設定温度)：変換ロジック使用
        // Temperature (setpoint): conversion logic used
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(data2);
        }else{
          resp = data2;
        }
        break;
      case 3:
        // 温度差：通常変換
        // Temperature difference: Normal conversion
        if(this.user.accountPreferences.temperaturepreference_id === TemperaturePreferenceEnum.Fahrenheit) {
          resp = (Number(data2) * (9 / 5)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 4:
        // 圧力(kg/cm2)：変換ロジック使用
        // Pressure (kg/cm2): conversion logic used
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_kilopascal(Number(data2)).toFixed(1);
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_kg_per_cm2_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 5:
        // 圧力(MPa)：kPaは通常変換、psiは変換ロジック使用
        // Pressure (MPa): kPa is normal conversion, psi uses conversion logic
        if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.Kilopascal) {
          resp = (Number(data2) * 1000).toString();
        }else if(this.user.accountPreferences.pressurepreference_id === PressurePreferenceEnum.PoundFourcePerSquareInch) {
          resp = PressureConversions.convert_from_megapascal_to_psi(Number(data2)).toFixed(1);
        }else{
          resp = data2;
        }
        break;
      case 6:
        // 文字列表示：何もしない
        // String display: do nothing
        resp = data2;
        break;
      default:
        resp = data2;
    }
    return resp;
  }

  downloadCSV(){
    // 試運転データCSV生成
    // Generate CSV of commissioning data
      const testruninfo = [];
      const headerdata = [''];
      const record = [];
      let updateTime = '';

      // 試運転情報部分作成
      // Create trial run information section
      record.push(['test_run_id','status','testrun_time','start_date_time','end_date_time',
        'ope_mode','outside_temperature','rmd_serial_no','gateway_id']);
      this.dataSourceHistory.forEach((value) => {
        if(value.id === this.csvTestRunId){
          testruninfo.push(value.id);
          testruninfo.push(value.status);
          testruninfo.push(value.testruntime);
          testruninfo.push(value.started_at_sitetime);
          testruninfo.push(value.ended_at_sitetime);
          testruninfo.push(value.ope_mode);
          testruninfo.push(value.outside_temperature_c);
          testruninfo.push(value.rmd_serial_no);
          testruninfo.push(this.gatewayId);
        }
      });
      record.push(testruninfo);
      record.push(['']);

      // 機器情報部分作成
      // Create equipment information section
      record.push(['address',
        'model_name',
        'serial_number',
        'attribute',
        'attribute_code',
        'group',
        'branch_pair',
        'rom_main',
        'rom_sub',
        'sub_no',
        'qj_threshold',
        'ic_kind',
        'auto_mode_sw',
        'dry_mode_sw',
        'fan_mode_sw',
        'setback_mode_sw',
        'fan_auto_sw',
        'fan_low_sw',
        'fan_mid2_sw',
        'fan_mid1_sw',
        'fan_high_sw',
        'fan_ex_low_sw',
        'fan_ex_high_sw',
        'ic_lev_sw',
        'bc_svm1_sw',
        'bc_svm1b_sw',
        'bc_svm2_sw',
        'bc_svm2b_sw',
        'bc_lev1_sw',
        'bc_lev2_sw',
        'bc_lev3_sw',
        'bc_lev4_sw',
        'hbc_mv1_sw',
        'hbc_lev1_sw',
        'hbc_lev2_sw',
        'hbc_lev3_sw'
      ]);

      this.unitHistory.forEach((value) => {
        const unithistorydata = [];
        unithistorydata.push(value.address);
        unithistorydata.push(value.model_name);
        unithistorydata.push(value.serial_number);
        unithistorydata.push(value.attribute);
        unithistorydata.push(value.attribute_code);
        unithistorydata.push(value.group);
        unithistorydata.push(value.branch_pair);
        unithistorydata.push(value.rom_main);
        unithistorydata.push(value.rom_sub);
        unithistorydata.push(value.sub_no);
        unithistorydata.push(value.qj_threshold);
        unithistorydata.push(value.ic_kind);
        unithistorydata.push(value.auto_mode_sw);
        unithistorydata.push(value.dry_mode_sw);
        unithistorydata.push(value.fan_mode_sw);
        unithistorydata.push(value.setback_mode_sw);
        unithistorydata.push(value.fan_auto_sw);
        unithistorydata.push(value.fan_low_sw);
        unithistorydata.push(value.fan_mid2_sw);
        unithistorydata.push(value.fan_mid1_sw);
        unithistorydata.push(value.fan_high_sw);
        unithistorydata.push(value.fan_ex_low_sw);
        unithistorydata.push(value.fan_ex_high_sw);
        unithistorydata.push(value.ic_lev_sw);
        unithistorydata.push(value.bc_svm1_sw);
        unithistorydata.push(value.bc_svm1b_sw);
        unithistorydata.push(value.bc_svm2_sw);
        unithistorydata.push(value.bc_svm2b_sw);
        unithistorydata.push(value.bc_lev1_sw);
        unithistorydata.push(value.bc_lev2_sw);
        unithistorydata.push(value.bc_lev3_sw);
        unithistorydata.push(value.bc_lev4_sw);
        unithistorydata.push(value.hbc_mv1_sw);
        unithistorydata.push(value.hbc_lev1_sw);
        unithistorydata.push(value.hbc_lev2_sw);
        unithistorydata.push(value.hbc_lev3_sw);

        record.push(unithistorydata);
      });
      record.push(['']);

      // ヘッダー作成
      // Create Header
      this.dataSource.forEach((value) => {
        headerdata.push(value['Key1']);
      });
      record.push(headerdata);

      // 中身作成
      // Create content
      this.timeHeader.forEach((value) => {
        // 時刻セット
        // Time set
        const csvdata = [];
        csvdata.push(value['name']);
        updateTime = value['name'];  // Time to use for file name
        const key_name = value['def'];      // Time header for search (TimeData_X)

        // データセット
        // Data set
        this.dataSource.forEach((value2) => {
          csvdata.push(value2[key_name][1]);
        });

        record.push(csvdata);

      });

      const data = record.map((records) => records.join(',')).join('\r\n');
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, data], {type: 'text/csv'});
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const link = document.createElement('a');

      let fname = "";
      if(this.csvFileName != null) {
        fname = this.csvFileName;
      } else {
        fname = this.csvTestRunId;
      }

      link.download = fname + '_' + this.date12(updateTime) + '.csv';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Success,
        TOAST_SUCCESS_TITLE,
        'Downloading csv file.'
      );
  }

  dateToString(date){
    // 日付型のデータを受け取り、"YYYY/MM/DD hh:mm"形式の文字列を返す
    // Accepts date type data and returns a string in the format "YYYY/MM/DD hh:mm
    const yearStr = date.getFullYear();
    let monthStr = 1 + date.getMonth();
    let dayStr = date.getDate();
    let hourStr = date.getHours();
    let minuteStr = date.getMinutes();

    monthStr = ('0' + monthStr).slice(-2);
    dayStr = ('0' + dayStr).slice(-2);
    hourStr = ('0' + hourStr).slice(-2);
    minuteStr = ('0' + minuteStr).slice(-2);

    let formatStr = 'YYYY/MM/DD hh:mm'
    formatStr = formatStr.replace(/YYYY/g, yearStr);
    formatStr = formatStr.replace(/MM/g, monthStr);
    formatStr = formatStr.replace(/DD/g, dayStr);
    formatStr = formatStr.replace(/hh/g, hourStr);
    formatStr = formatStr.replace(/mm/g, minuteStr);

    return formatStr;
  }

  date12(formatStr){
    // "YYYY/MM/DD hh:mm"形式の文字列を受け取り、"YYYYMMDDhhmm"形式の文字列を返す
    // Accepts a string in the format "YYYYY/MM/DD hh:mm" and returns a string in the format "YYYYYMMDDhhmm
    formatStr = formatStr.replace('/', '');
    formatStr = formatStr.replace('/', '');
    formatStr = formatStr.replace(' ', '');
    formatStr = formatStr.replace(':', '');
    return formatStr;
  }
}