export class SubscriptionPlanDetail {
    subscription_id: string;
    subscription_name: string;
    subscription_name_plan_1: string;
    subscription_monthly_rate: number;
    subscription_monthly_rate_plan_1: number;
    subscription_gateway_model_class_name: string;
    feature_category_name: string;
    feature_category_code: string;
    feature_detail_description: string;
    feature_category_information: JSON;
    feature_detail_description_plan_1: string;
    PlanRowType: number;
}