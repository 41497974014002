/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { Auth, Storage, StorageClass, Hub } from 'aws-amplify';
import { Logger } from '../logging/log.service';
import { CloudService } from '../cloud/cloud.service';
import { Location } from '@angular/common';
import { Site, makeRealSite } from '../../../features/sites/classes/site';
import { EventEmitter } from '@angular/core';
import {
  CognitoAuthEventNameEnum,
  CognitoKenzaCustomAttributeStatus,
  CognitoUserPoolCustomAttributeEnum,
  CognitoUserPoolCustomAttributeValueEnum,
  KenzaCloudMenuIds,
  LevelEnum,
  MaintenanceJobTypeEnum,
  MaintenanceJobTypeEnumTitle,
  ToastMessageTypeEnum,
} from '../../../enumerations/enums';
import { FileStringUtilities } from '../../utilities/stringUtilities';
import { AppStorageService } from '../storage/app-storage.service';
import { AppAuthenticationService } from '../authentication/app-authentication.service';
import { Account } from '../../../features/account/classes/account';
import { AccountDetail } from '../../../features/account/classes/accountdetail';
import { MainSiteUIService } from '../ui/main-site-ui.service';
import { AccountService } from 'src/app/features/account/services/accountService';
import { SiteService } from 'src/app/features/sites/services/site.service';
import {
  s3AccountDetailPicturePath,
  s3SitePicturePath,
  WebserviceURL,
} from 'src/app/constants/webservice';
import { ServerLoggerServiceService } from 'src/app/common/services/logging/server-logger-service.service';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../websockets/socket.service';
import { SiteAlertFilter } from '../../classes/filters';
import { SiteNotificationCount } from '../../classes/Metrics';
import {
  JOINED_SITE_SUCCESS,
  TOAST_SUCCESS_TITLE,
  JOINED_SITE_ALREADY,
  defaultTimeZone,
  devEnv,
} from 'src/app/constants/kenzaconstants';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { concat, of } from 'rxjs';
import { concatMap, map, tap, delay, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Permission } from 'src/app/features/account/classes/Permission';
import { AccountPreferences } from 'src/app/features/account/classes/AccountPreferences';
import { AppComponent } from 'src/app/app.component';
import { StorageAccessLevel } from '@aws-amplify/storage';
import { catchError, takeUntil } from 'rxjs/operators';
import moment from 'moment-timezone';

// this value represents how long before the conginto token is set to expire should we 
// refresh it.  It is meant to cover the potential time difference between the client time
// and the server time, so we on the client dont try to use a token that the server will
// claim is expired.

// this value is 5 minutes in milliseconds
const TOKEN_REFRESH_BUFFER_TIME_MS: number = 300_000;
// should we log token refresh events
const TOKEN_LOGGING: boolean = false;

@Injectable({
  providedIn: 'root',
})

export class UserService {
  auth: any;
  userAttributes: object;
  storage: StorageClass;
  signedIn: boolean;
  id: string;
  user: any;
  username: string;
  name: string;
  // email: string;
  externalPaths: Array<string>;
  sites: Array<Site>;
  site_permissions: Permission[];
  active: Site;
  accountPreferences: AccountPreferences;
  s3AccountDetailPicturePath: string;
  s3SitePicturePath: string;
  activeSiteAccounts: Account[] = [];
  activeSiteInvites: Array<any> = [];
  activeSiteUserLevel: number;
  retrievedSites = new EventEmitter<Site[]>();
  activeSiteChanged = new EventEmitter<Site>();
  photoStorageLevel: StorageAccessLevel = 'public';
  activeSiteAndNavigationChanged = new EventEmitter<{
    activeSite: Site;
    mainMenuId: KenzaCloudMenuIds;
    subMenuId: KenzaCloudMenuIds;
  }>();
  signed_out_while_disconnected: boolean;

  mockedSite: Site = new Site({
    id: 'Mock-Site',
    name: 'Mock Site',
    phone: '404-000-0001',
    company: '1 Mocks R Us',
    addressOne: '1 Mock Lane',
    addressTwo: 'Suite M',
    city: 'Mockville',
    state: 'GA',
    zip: '39029',
    country: 'USA',
    site_photo_name: '',
    sitestatustype_id: 1,
    current_owner_id:'',
    timezone: defaultTimeZone,
    transfer_locked: false,
    locations: []
  });

  observableSiteNotificationMetrics: Observable<SiteNotificationCount[]>;
  load$ = new BehaviorSubject('');
  lastTimeValue = '';
  notificationCounts: any;
  subscriptionSiteNotification: Subscription;

  showTransferModal = false;
  transferSiteId = "";

  app: AppComponent = null;

  lastSite = null;
  devEnv = devEnv;
  hubCancel = null;
  alertOpen = false;
  backToSiteMembers = false;
  serverSidePagination = false;
  siteEdited: Subscription = null;
  updateSiteWeatherInterval = null;
  updateWeatherMinuteInterval = 60;
  notificationPageCount = this.serverSidePagination ? 20 : -1;

  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;

   constructor(
    private router: Router,
    private logger: Logger,
    private http: HttpClient,
    private location: Location,
    private nav: NavController,
    private cloud: CloudService,
    private siteService: SiteService,
    private socketService: SocketService,
    private accountService: AccountService,
    private alertController: AlertController,
    private toastController: ToastController,
    private appStorageService: AppStorageService,
    private mainSiteUIService: MainSiteUIService,
    public appAuthService: AppAuthenticationService,
    private serverLoggerService: ServerLoggerServiceService,
  ) {
    this.auth = Auth;
    this.storage = Storage;
    this.reset();
    this.active = null;
    this.externalPaths = ['/signin', '/forgot', '/signup', '/terms', '/', '/version', '/site/transfer/check', '/gateway/models', '/billing/plans', '/site/gateway/subscriptions', '/site/gateway/subscription/plans'];
    this.s3AccountDetailPicturePath = s3AccountDetailPicturePath;
    this.s3SitePicturePath = s3SitePicturePath;
    this.signed_out_while_disconnected = false;
    this.activeSiteUserLevel = LevelEnum.Unknown;

    // check whether we're already authenticated.
    this.auth.currentAuthenticatedUser()
      .then((user) => { 
        // then yes - set to logged in.
        this.successfulSignIn(user);
      })
      .catch((err) => { // do nothing - no user is logged in.  The Promise MUST have a catch though,
      // and eslint will bark if it doesn't at least have a comment :)
      });

    // catch any change to the cognito auth state
    this.hubCancel = Hub.listen('auth', (data) => {
      const { payload } = data;
      this.authStateChange(payload);
    })

    this.notificationsInitialize();
    this.setWeatherUpdateTimer();
    this.mainSiteUIService.sites = this.sites;
  }

  updateAndRefreshNotifications(initializeOrUpdate, includeAccount = true) {
    forkJoin({
      globalNotifications: this.http.get(WebserviceURL + `account/global-notifications`),
      ...(includeAccount == true && {
        accountNotifications: this.getAccountNotifications(this.notificationPageCount),
      }),
    })
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(results => {

      const globalNotifications: any = results.globalNotifications;
      let globalNotificationsExist = globalNotifications && globalNotifications.length > 0;

      if (globalNotificationsExist) {
        this.mainSiteUIService.globalNotifications = globalNotifications;
        localStorage.setItem(`globalNotifications`, JSON.stringify(globalNotifications));
      }

      const accountNotifications: any = results.accountNotifications;
      let accountNotificationsExist = accountNotifications && accountNotifications.notifications && accountNotifications.notifications.length > 0;

      if (includeAccount == true && accountNotifications && accountNotificationsExist) {
        this.mainSiteUIService.notificationPaginationKey = accountNotifications.pagination_key;
      }

      if (globalNotificationsExist || accountNotificationsExist) {
        this.mainSiteUIService.checkForNotificationsUpdate(this, initializeOrUpdate, {accountNotifications, globalNotifications});
      }

    }, error => {
      console.log(`Error Fetching Global Notification(s)`, error);
    });
  }

  // When a site is transferring
  async presentActionRestricted(customSubheader?, customMessage?, actionType = `maintenance`) {
    if (this.alertOpen == true) return;
    if (!this.siteService.handleIsConnected()) return;

    if (this.active && this.active.transfer_locked) {
      actionType = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer].toLowerCase();
    }

    let ddd = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Download_Drive_Data].toLowerCase();
    let mapUnits = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Mapping_Units].toLowerCase();
    let message = `you need to cancel the ${actionType} request.`;
    if (actionType == ddd || actionType == mapUnits) {
      message = `you need to wait for the ${actionType} request to finish.`;
    }

    const actionRestricted = await this.alertController.create({
      id: `actionRestricted`,
      cssClass: `alertMsg actionRestrMsg me-info-button-css`,
      header: `Action Restricted`,
      subHeader: customSubheader ?? `This action cannot be taken inside the ${actionType} time period.`,
      message: customMessage ?? `To proceed with this action, ${message}`,
      buttons: [{
        text: `Ok`, role: `cancel`, cssClass: `ok-button slimButton`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
        })
      }]
    });
    this.alertOpen = true;
    return await actionRestricted.present();
  }

  notificationsInitialize() {
    this.mainSiteUIService.initializeNotifications(this);
    if (this.siteEdited == null) {
      this.siteEdited = this.siteService.siteEdited.subscribe((siteEditedResponse) => {
        if (siteEditedResponse.type == `Site Deactivated`) {
          this.mainSiteUIService.setNotificationsUI(this, this.mainSiteUIService.filterWhatsOnScreen && !this.mainSiteUIService.noMoreNotifications);
        } else if (siteEditedResponse.type == `Refresh Notifications`) {
          this.updateAndRefreshNotifications(`Update`);
        }
      })
    }
  }

  authStateChange(data: any) {
    // message from cognito about a change in the authorization state of the current user.
    if (data.event == CognitoAuthEventNameEnum.signIn) {
      this.userAttributes = data.data.attributes;
      if (Object.prototype.hasOwnProperty.call(this.userAttributes,CognitoKenzaCustomAttributeStatus) &&
          (this.userAttributes[CognitoKenzaCustomAttributeStatus] == CognitoUserPoolCustomAttributeValueEnum.ACCOUNT_CANCELLED)) {
            // no - account is cancelled in kenza
            this.signOut();
            this.router.navigate([`/signin`]);
      } else {
        // success
        this.successfulSignIn(data.data);
        if (this.externalPaths.indexOf(this.location.path()) >= 0) {
          this.router.navigate([`/home`]);          
        }
      }
    }
  }

  setApp(app: AppComponent) {
    this.app = app;
  }

  reset() {
    this.signedIn = false;
    this.user = null;
    this.id = null;
    this.username = null;
    this.name = null;
    this.sites = [];
    this.active = null;
    this.subscriptionSiteNotification?.unsubscribe();
    //this.authState = null;
    
    this.resetApp();
  }

  resetApp() {
    let defaultSite: Site = new Site({
      id: "default-site",
      name: "Select Site",
      phone: "",
      company: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      site_photo_name: "",
      sitestatustype_id: 1,
      current_owner_id:'',
      locations: []
    });

    if(this.app) {
      this.app.selectedSiteInfo = defaultSite;
    }
  }

  set_signed_out_while_disconnected(signed_out_while_disconnected_value: boolean) {
    this.signed_out_while_disconnected = signed_out_while_disconnected_value;
  }

  successfulSignIn(data) {
    this.signedIn = true;
    this.user = null;
    this.user = data;
    this.id = data.attributes.sub;
    this.username = data.attributes.email;
    // this.email = data.attributes.email;
    this.name = this.username.split('@')[0];
    this.set_signed_out_while_disconnected(false);
    this.sites = [];
    this.updateSites();
    this.appAuthService.getLevelPermissions();
    this.setAccountPreferences();
    //const authToken: string = data.signInUserSession.accessToken.jwtToken;
    //this.appStorageService.setLocalStorage('AuthToken', authToken);
    this.siteService.checkSitePopups(this.id).subscribe((data) => {
      if (data) setTimeout(() => this.presentToastWithOptions(data), 250);
    });
    this.socketService.start(this);
    this.mainSiteUIService.initializeNotifications(this);
    this.init_accountSiteNotificationCounts();
  }

  getAccountNotifications(page_size:number, pagination_key: string = ``) {
    let params = { params: {page_size, ...((this.mainSiteUIService.clientSidePagination || this.serverSidePagination) && { pagination_key })} };
    let accountNotifications = this.http.get(WebserviceURL + `account/notifications`, params);
    return accountNotifications;
  }

  async presentToastWithOptions(data) {
    let toastMessage = '';

    switch (data) {
      case 1:
        toastMessage = JOINED_SITE_SUCCESS;
        break;

      case 2:
        toastMessage = JOINED_SITE_ALREADY;
        break;
    }

    this.siteService.presentToastMessage(
      ToastMessageTypeEnum.Success,
      TOAST_SUCCESS_TITLE,
      toastMessage
    );
  }

  getToken_no_refresh_token() {
    if (this.auth.user) {
      return this.auth.user.signInUserSession.accessToken.jwtToken;
    }
  }

  getToken(): Promise<any> {
    if (!this.auth || !this.auth.user) {
      TOKEN_LOGGING && console.log(`GetToken: not  logged in.... no token to give!`);
      return null;
    }

    const signInUserSession = this.auth.user.getSignInUserSession();
    const accessToken = signInUserSession
      ? signInUserSession.getAccessToken()
      : null;

    // determine if existing token is ok to use.
    const now = Date.now();
    // default to now for case we have no accessToken - so we will renew.
    let earlyExpiryTime = now;
    let expiryTime = now;
    if (accessToken) {
      expiryTime = accessToken.getExpiration() * 1000;
      earlyExpiryTime = expiryTime - TOKEN_REFRESH_BUFFER_TIME_MS;
    }
    let expiresIn = expiryTime - now;

    if ( earlyExpiryTime <= now) {
      // then we will refresh
      if (TOKEN_LOGGING) {
        if (expiresIn <= 0) console.log(`GetToken: Time to refresh. expired ${expiresIn.toLocaleString()} ms ago`)
        else console.log(`GetToken: Time to refresh. expires in ${expiresIn.toLocaleString()} ms`)
      }
      const refreshToken = signInUserSession.getRefreshToken();
      return new Promise((resolve) => {
        this.auth.user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            TOKEN_LOGGING && console.log(`GetToken: Err refreshing session ${err}`)
            resolve(this.signOut());
          }
          if (TOKEN_LOGGING) {
            let accessTokenExpiry = session.getAccessToken().getExpiration() * 1000;
            let earlyTokenExpify = accessTokenExpiry - TOKEN_REFRESH_BUFFER_TIME_MS;
            const expiryTime = new Date(accessTokenExpiry);
            const earlyExpireTime = new Date(earlyTokenExpify);
            console.log(`GetToken: Got new token - expires at ${String(expiryTime.getHours()%12).padStart(2,'0')}:${String(expiryTime.getMinutes()).padStart(2,'0')}:${String(expiryTime.getSeconds()).padStart(2,'0')}, Early refresh at ${String(earlyExpireTime.getHours()%12).padStart(2,'0')}:${String(earlyExpireTime.getMinutes()).padStart(2,'0')}:${String(earlyExpireTime.getSeconds()).padStart(2,'0')}`)            
          }
          this.auth.user.setSignInUserSession(session);
          resolve(session.getAccessToken().getJwtToken());
        });
      });
    } else {
      // token is good to use.
      if (TOKEN_LOGGING) {
        let earlyExpireIn = earlyExpiryTime - now;
        console.log(`GetToken: Token is good, expires in ${expiresIn.toLocaleString()} ms, refresh in ${earlyExpireIn.toLocaleString()} ms.`)
      }
      return Promise.resolve(accessToken.getJwtToken());      
    }
  }

  testToken_refresh(): Promise<any> {
    if (!this.auth || !this.auth.user) {
      return null;
    }

    const signInUserSession = this.auth.user.getSignInUserSession();
    const idToken = signInUserSession
      ? signInUserSession.getAccessToken()
      : null;
    if (
      !idToken ||
      idToken.getExpiration() * 1000 <= idToken.getExpiration() * 1000
    ) {
      const refreshToken = signInUserSession.getRefreshToken();
      return new Promise((resolve) => {
        this.auth.user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            resolve(this.signOut());
          }
          this.auth.user.setSignInUserSession(session);
          resolve(session.getIdToken().getJwtToken());
        });
      });
      return Promise.resolve(idToken.getJwtToken());
    } else {
      // this.logger.debug("Token has not expired.");
    }
    return Promise.resolve(idToken.getJwtToken());
  }

  clearSites() {
    this.sites = [];
  }

  updateSites() {
    const obs_getSitesForAccount = this.siteService.getSitesForAccount(this.id);
    const obs_accountSiteNotificationCounts = this.siteService.accountSiteNotificationCounts();

    const obs_fork_joined = forkJoin({
      obs_getSitesForAccount,
      obs_accountSiteNotificationCounts,
    });

    obs_fork_joined.subscribe({
      next: (update_sites_result) => {
        this.clearSites();
        // map the objects to real Site classes
        const site_list: Array<Site> = update_sites_result.obs_getSitesForAccount[`sites`].map((s) => new Site(s));
        const siteNotificationCounts: SiteNotificationCount[] = update_sites_result.obs_accountSiteNotificationCounts as SiteNotificationCount[];

        // appears to be getting all the gateways a user has access to and then figuring out how many sites they have access to
        site_list.filter(
          (x, y, z) => {
            z.findIndex((t) => t.id == x.id) == y
          }
        );

        site_list.forEach((s) => this.sites.push(s));
        if (this.active === null) {
          this.active = this.sites[0] || null;
        } else {
          const site_id = this.active.id;
          const active_site: Site = this.sites.find(s => s.id === site_id);
          if (active_site) this.active = active_site;
        }

        this.updateSiteNotificationCounts(siteNotificationCounts);
        this.retrievedSites.emit(this.sites);
      },
      error: (e) => {
        // this.siteService.presentToastMessage(ToastMessageTypeEnum.Error, TOAST_GENERAL_ERROR_TITLE, GATEWAY_DETAIL_UNABLE_TO_RETRIEVE);
      },
    });

    this.siteService
      .accountSiteNotificationCounts()
      .subscribe((siteNotificationCounts: SiteNotificationCount[]) => {
        this.updateSiteNotificationCounts(siteNotificationCounts);
      });

    if (this.site_permissions && this.site_permissions.length == 0) {
      this.refresh_account_site_permissions();
    }
  }

  async refresh_account_site_permissions() {
    const account_site_permissions: Permission[] = (await this.accountService.get_account_site_permissions(
      this.id
    )) as Permission[];
    this.site_permissions = account_site_permissions;
  }

  isMemberOfCachedSites(site_id:string):boolean {
    // does this site_id appear in the local list of *known* sites?
    // this is a non-authoritative view of what sites the user is a member of.
    return this.sites.find(site => site.id == site_id) != null;
  }

  signUp(creds) {
    return this.auth.signUp(creds);
  }

  signOut() {
    this.reset();
    //this.appStorageService.clearLocalStorageByKey('AuthToken');
    this.appStorageService.localStorageClear();
    // devEnv && console.log(`Global Notifications`, this.mainSiteUIService.globalNotifications);
     this.mainSiteUIService.setSystemStatusFromNotifications(this.mainSiteUIService.globalNotifications)
    if (this.siteEdited != null) {
      this.siteEdited.unsubscribe();
      this.siteEdited = null;
    }
    clearInterval(this.updateSiteWeatherInterval);
    this.mainSiteUIService.clearNotifications();
    this.updateIndex = 0;
    return this.auth.signOut();
  }

  signIn(email, password) {
    return this.auth.signIn(email, password);
  }

  forgot(email) {
    return this.auth.forgotPassword(email);
  }

  forgotSubmit(email, code, password) {
    return this.auth.forgotPasswordSubmit(email, code, password);
  }

  changePassword(oldPassword, newPassword) {
    return this.auth.currentAuthenticatedUser().then((user) => {
      return this.auth.changePassword(user, oldPassword, newPassword);
    });
  }

  sendChangePasswordEmail(toEmail: string) {
    return this.http.post(WebserviceURL + 'site/account/password-reset', {
      toEmail: toEmail,
    });
  }

  setAccountDetails(obj) {
    obj.id = this.id;
    return this.accountService.setAccountDetails(obj);
  }

  getAccountDetails(userId = this.id) {
    return this.accountService.getAccountDetails(userId);
  }

  getAccountDetailsById(account_id: string) {
    return this.accountService.getAccountDetails(account_id);
  }

  getSiteAccountDetails(site_id: string, account_id: string) {
    return this.accountService.getSiteAccountDetails(site_id, account_id);
  }

  createSite(obj) {
    obj.accountId = this.id;
    return this.siteService.createSite(obj);
  }

  updateSite(obj) {
    obj.accountId = this.id;
    return this.siteService.updateSite(obj);
  }

  async refreshSiteAccounts(siteId: string) {
    const accountList = (await this.appAuthService.getSiteAccounts(
      siteId
    )) as any;

    this.activeSiteAccounts = [];
    this.activeSiteInvites = [];

    accountList.users.forEach(
      (account) => {
        this.activeSiteAccounts.push(new Account(account));
      },
      (err) => {
        this.logger.error(err);
        const messageToPrint: string =
          'Kenza Client Message==>refreshSiteAccounts==>error==>' + err;
        this.serverLoggerService
          .PrintToServerLog(messageToPrint)
          .subscribe((result) => {
            this.logger.debug('Print to Server Log Result : ', result);
          });
      }
    );
    this.activeSiteInvites =
      accountList['invitees'] == null ? [] : accountList['invitees'];
  }

  setActiveSiteUserLevel() {
    const activeSiteAccount = this.activeSiteAccounts.find(
      (activeAccount) => activeAccount.id === this.id
    );

    if (activeSiteAccount !== undefined) {
      this.appStorageService.localStorageSetItem('ActiveSiteAccount', activeSiteAccount);
      this.activeSiteUserLevel = activeSiteAccount?.level;
    } else {
      this.activeSiteUserLevel = LevelEnum.Unknown;
    }
    return this.activeSiteUserLevel;
  }

  updateSiteLocations(site_id: string, new_locations: []) {
    // update the site in the site list with new locations.

    // update in the list of sites.
    let siteToUpdate: Site = this.sites.find((s) => s.id == site_id);
    if (siteToUpdate) siteToUpdate.update_locations(new_locations);

    // update active site is site_id is active site
    if (this.active && this.active?.id == site_id) this.active.update_locations(new_locations);
  }

  async setActiveSite(activeSite: Site, emitChange: boolean) {
    if (!this.siteService.handleIsConnected()) return;

    if (activeSite === undefined) {
      // do not switch to an undefined site.
      console.log(`Request to switch to an undefined site denied.`);
      return
    }

    //make sure active site is a REAL Site class - not just an object.
    this.active = makeRealSite(activeSite); 
    if (activeSite && activeSite.id && activeSite.id !== `default-site`) { 
      const accountList = (await this.appAuthService.getSiteAccounts(activeSite.id)) as any;
      if (accountList) {
        this.activeSiteAccounts = [];
        accountList['users'].forEach((account) => {this.activeSiteAccounts.push(new Account(account))});
        this.activeSiteInvites = accountList['invitees'] == null ? [] : accountList['invitees'];
      }

      this.setActiveSiteUserLevel();
      this.appStorageService.localStorageSetItem('ActiveSite', this.active);
      if (emitChange) {
        this.activeSiteChanged.emit(activeSite);
      }
    } else {
      this.mainSiteUIService.clearSiteAlertData();
    }

    this.getSiteWeather();
  }

  async setActiveSiteAndNavigate(
    activeSite: Site,
    mainMenuId: KenzaCloudMenuIds,
    subMenuId: KenzaCloudMenuIds
  ) {
    if (!this.siteService.handleIsConnected()) return;
    
    if (activeSite === undefined) {
      // do not switch to an undefined site.
      console.log(`Request to switch to an undefined site denied.`);
      return
    }

    this.active = activeSite;
    const accountList = (await this.appAuthService.getSiteAccounts(
      activeSite.id
    )) as any;
    this.activeSiteAccounts = [];

    if (
      accountList &&
      accountList['users'] &&
      accountList['users'].length > 0
    ) {
      accountList['users'].forEach((account) => {
        this.activeSiteAccounts.push(new Account(account));
      });
    }

    this.activeSiteInvites =
      accountList['invitees'] == null ? [] : accountList['invitees'];

    this.setActiveSiteUserLevel();
    this.appStorageService.localStorageSetItem('ActiveSite', this.active);

    this.activeSiteAndNavigationChanged.emit({
      activeSite,
      mainMenuId,
      subMenuId,
    });

    this.router.navigate(['/site', this.active.id, 'dashboard']);
   
    this.getSiteWeather();
  }

  public getSiteList() {
    return this.sites;
  }

  setAvatar(img) {
    return this.storage.vault.put('avatar.jpg', img, {
      contentType: 'image/jpg',
    });
  }

  async getAccountDetail(account_id: string): Promise<AccountDetail> {
    const accountPictureUrl = '';
    return this.accountService.getAccountDetail(account_id);
  }

  async getSiteDetail(site_id: string): Promise<Site> {
    return this.siteService.getSiteDetail(site_id);
  }

  removeS3AccountPhoto(photoFileName: string) {
    return this.storage.remove(
      this.s3AccountDetailPicturePath + photoFileName,
      { level: this.photoStorageLevel }
    );
  }

  removeS3SitePhoto(photoFileName: string) {
    return this.storage.remove(this.s3SitePicturePath + photoFileName, {
      level: this.photoStorageLevel,
    });
  }

  removeDBAccountPhoto(account_id: string): Promise<any> {
    return this.accountService.removeDBAccountPhoto(account_id);
  }

  removeDBSitePhoto(site_id: string): Promise<any> {
    return this.siteService.removeDBSitePhoto(site_id);
  }

  async saveDBAccountPhoto(
    accountDetail: AccountDetail,
    imageData: any
  ): Promise<any> {
    const fileUtility: FileStringUtilities = new FileStringUtilities();
    const contentType = fileUtility.getContentTypeFromFileName(imageData.name);
    const saveDBFileName = fileUtility.swapFileName(
      imageData.name,
      accountDetail.id
    );

    const saveS3FileName = this.s3AccountDetailPicturePath + saveDBFileName;

    accountDetail.account_photo_name = saveDBFileName;

    await this.accountService.saveDBAccountPhoto(accountDetail);
    if (contentType && saveS3FileName) {
      return this.storage.put(saveS3FileName, imageData, {
        level: this.photoStorageLevel,
        contentType,
      });
    }
  }

  async saveDBSitePhoto(site: Site, imageData: any): Promise<any> {
    const fileUtility: FileStringUtilities = new FileStringUtilities();
    const contentType = fileUtility.getContentTypeFromFileName(imageData.name);
    const saveDBFileName = fileUtility.swapFileName(imageData.name, site.id);
    const saveS3FileName = this.s3SitePicturePath + saveDBFileName;
    // fileUtility.swapFileName(imageData.name, site.id);

    site.site_photo_name = saveDBFileName;

    await this.siteService.saveDBSitePhoto(site);
    if (contentType && saveS3FileName) {
      return await this.storage.put(saveS3FileName, imageData, {
        level: this.photoStorageLevel,
        contentType,
      });
    }
  }

  async getAccountPhotoUrl(account_id: string): Promise<string> {
    let accountPhotoUrl = '';
    const accountDetail = await this.getAccountDetail(account_id);

    if (
      accountDetail !== undefined &&
      accountDetail !== null &&
      accountDetail.account_photo_name
    ) {
      accountPhotoUrl = await this.storage.get(
        this.s3AccountDetailPicturePath + accountDetail.account_photo_name,
        { level: this.photoStorageLevel }
      );
    }

    return accountPhotoUrl;
  }

  async getSitePhotoUrl(site_id: string): Promise<string> {
    let sitePhotoUrl = '';
    const siteDetail: Site = await this.getSiteDetail(site_id);

    if (
      siteDetail !== undefined &&
      siteDetail !== null &&
      siteDetail.site_photo_name
    ) {
      sitePhotoUrl = await this.storage.get(
        this.s3SitePicturePath + siteDetail.site_photo_name,
        { level: this.photoStorageLevel }
      );
    }

    return sitePhotoUrl;
  }

  async saveAccountPhoto(accountDetail: AccountDetail, imageData: any) {
    const accountDetails = await this.getAccountDetail(accountDetail.id);

    if (
      accountDetails !== undefined &&
      accountDetails !== null &&
      accountDetails.account_photo_name &&
      accountDetails.account_photo_name.length > 0
    ) {
      await this.removeS3AccountPhoto(accountDetails.account_photo_name);
      await this.removeDBAccountPhoto(accountDetail.id);
    }

    await this.saveDBAccountPhoto(accountDetail, imageData);
  }

  async saveSitePhoto(site: Site, imageData: any) {
    const siteDetail = await this.getSiteDetail(site.id);

    if (
      siteDetail !== undefined &&
      siteDetail !== null &&
      siteDetail.site_photo_name &&
      siteDetail.site_photo_name.length > 0
    ) {
      await this.removeS3SitePhoto(siteDetail.site_photo_name);
    }
    await this.saveDBSitePhoto(siteDetail, imageData);
  }

  async removeAccountPhoto(account_id: string) {
    const accountDetails = await this.getAccountDetail(account_id);

    if (
      accountDetails !== undefined &&
      accountDetails !== null &&
      accountDetails.account_photo_name &&
      accountDetails.account_photo_name.length > 0
    ) {
      await this.removeS3AccountPhoto(accountDetails.account_photo_name);
      await this.removeDBAccountPhoto(accountDetails.id);
    }
  }

  async removeSitePhoto(site_id: string) {
    const siteDetail: Site = await this.getSiteDetail(site_id);

    if (
      siteDetail !== undefined &&
      siteDetail !== null &&
      siteDetail.site_photo_name &&
      siteDetail.site_photo_name.length > 0
    ) {
      await this.removeS3SitePhoto(siteDetail.site_photo_name);
      await this.removeDBSitePhoto(siteDetail.id);
    }
  }

  getCustomer() {
    return this.http.get(WebserviceURL + 'pay/customer/');
  }

  setAccountPreferences() {
    this.accountService.getAccountPreferences(this.id).subscribe((accountPreferences: AccountPreferences) => {
      this.accountPreferences = accountPreferences;
    });
  }

  init_accountSiteNotificationCounts() {
    this.notificationCounts = this.siteService.accountSiteNotificationCounts();

    // Observable whenToRefresh that emits after a delay
    // And then triggers the load$ Subject to emit
    const whenToRefresh = of('').pipe(
      delay(this.mainSiteUIService.notificationUpdateDelay * 1000), // Check if we need to Update Notifications
      tap((_) => this.load$.next('')),
      skip(1)
    );

    // Observable poll$ that first emits the initial notificationCounts
    // And then emits the values from the whenToRefresh Observable.
    const poll$ = concat(this.notificationCounts, whenToRefresh);

    // Observable this.observableSiteNotificationMetrics that listens to the load$ Subjects
    // And maps the response from poll$ to an array of SiteNotificationCount objects.
    this.observableSiteNotificationMetrics = this.load$.pipe(
      concatMap((_) => poll$),
      map((response: SiteNotificationCount[]) => response)
    );

    // Periodically refreshes the data by subscribing to the observableSiteNotificationMetrics Observable
    this.subscriptionSiteNotification = this.observableSiteNotificationMetrics.subscribe(
      (accountSiteNotificationCounts: SiteNotificationCount[]) => {
        this.updateSiteNotificationCounts(accountSiteNotificationCounts);
      },
      (error) => {
        console.log(`this.subscriptionSiteNotification=>error : `, error);
      }
    );
  }
  
  updateIndex = 0;
  private onDestroy$ = new Subject<void>();
  updateSiteNotificationCounts(siteNotificationCounts: SiteNotificationCount[]) {
    this.mainSiteUIService.sitesWithNotifications = [];
    if (siteNotificationCounts && siteNotificationCounts.length > 0) {
      this.sites = this.sites.map((site) => {
        const siteNotificationCount = siteNotificationCounts.find(
          (snc) => snc.site_id == site.id
        );

        if (siteNotificationCount !== undefined && this.active) {
          if (this.mainSiteUIService.siteAlertMetricsData.v2ActiveCount != siteNotificationCount.count_of_site_notifications
            && this.active.id == site.id) {
            const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
            siteAlertFilter.site_id = this.active.id
            siteAlertFilter.UserLevelEnum = this.activeSiteUserLevel;
            this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
          }
          site.count_of_site_notifications = siteNotificationCount.count_of_site_notifications;
          if (Math.floor(site.count_of_site_notifications) > 0 && !this.mainSiteUIService.sitesWithNotifications.map(sit => sit.id).includes(site.id)) {
            this.mainSiteUIService.sitesWithNotifications.push({name: site.name, id: site.id, count_of_site_notifications: Math.floor(site.count_of_site_notifications), permission: site.permission});
          }
        } else {
          site.count_of_site_notifications = 0;
        }
        return site;
      });

      if (this.mainSiteUIService.sitesWithNotifications.length > 0) {
        this.mainSiteUIService.startingNotificationTotal = this.mainSiteUIService.sitesWithNotifications.reduce((total, notification) => total + notification.count_of_site_notifications, 0);
      }
    }

    if (this.updateIndex == 0) {
      this.updateAndRefreshNotifications(`Initialize`);
    } else if (this.updateIndex > 4 && !this.mainSiteUIService.notificationsScrolling && this.signedIn) {
      this.updateAndRefreshNotifications(`Update`);
    }
    this.updateIndex++;
  }

  lastUpdated = ``;
  setWeatherUpdateTimer() {
    if (this.signedIn && this.updateSiteWeatherInterval || this.updateSiteWeatherInterval != null) {
      clearInterval(this.updateSiteWeatherInterval);
      this.updateSiteWeatherInterval = null;
    }

    if (this.signedIn && !this.updateSiteWeatherInterval || this.updateSiteWeatherInterval == null) {
      this.updateSiteWeatherInterval = setInterval(() => {
        if (this.active.id != `default-site`) {
          this.lastUpdated = this.formatDate(new Date());
          this.getSiteWeather();
        }
      }, (60_000 * this.updateWeatherMinuteInterval));
    }
  }

  clearUserSubscriptions() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  formatDate = (date, short?) => {
    const days = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
    const months = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
  
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, `0`);
    const seconds = String(date.getSeconds()).padStart(2, `0`);
  
    const suffix = (day % 10 === 1 && day !== 11) ? `st` : (day % 10 === 2 && day !== 12) ? `nd` : (day % 10 === 3 && day !== 13) ? `rd` : `th`;
    const amPm = hours >= 12 ? `PM` : `AM`;
  
    const formattedHours = ((hours + 11) % 12) + 1;
    if (short) return `${formattedHours}:${minutes} ${amPm}`;
    return `${dayOfWeek}, ${month} ${day}${suffix}, ${formattedHours}:${minutes}:${seconds} ${amPm}`;
  }

  async getSiteWeather(active?, clearCache?) {
    if (!this.siteService.handleIsConnected()) return;
    if (!active) active = this.active;
    if (active && active?.id != `default-site`) {
      this.siteService.weatherDataLoading = true;

      this.lastUpdated = this.formatDate(new Date());
      let getWeatherFromServer = () => this.siteService.fetchWeatherData(active?.id)
      .pipe(catchError(error => {
          this.siteService.weatherDataLoading = false;
          this.siteService.weatherDataLoaded = true;
          this.siteService._weatherDataLoaded.next(true);
          this.siteService.weatherDataError = true;
          devEnv && console.log(`Error Fetching Weather Data`, error);
          return error;
        }), takeUntil(this.onDestroy$))
        .subscribe((weather: any) => {
        if (typeof weather == `object`) {
          this.siteService.weatherData = this.siteService.generateWeatherData(weather, this, active);
          this.siteService.weatherDataLoading = false;
          this.siteService.weatherDataLoaded = true;
          this.siteService._weatherDataLoaded.next(true);
          this.siteService.weatherDataError = false;
          let zipWeatherCache = JSON.parse(localStorage.getItem(`ZipWeatherCache`)) || [];
          let updatedZipWeatherCache = [];
          if (zipWeatherCache.length > 0) {
            if (zipWeatherCache.filter(site => site?.zip == active?.zip).length > 0) {
              updatedZipWeatherCache = zipWeatherCache.map(cachedZipWeatherData => {
                if (cachedZipWeatherData?.zip == active?.zip) {
                  return this.siteService.weatherData;
                } else {
                  return cachedZipWeatherData;
                }
              });
            } else {
              updatedZipWeatherCache = zipWeatherCache.concat([this.siteService.weatherData]);
            }
          } else {
            updatedZipWeatherCache.push(this.siteService.weatherData);
          }
          // devEnv && console.log(`Server Weather`, this.siteService.weatherData);
          localStorage.setItem(`ZipWeatherCache`, JSON.stringify(updatedZipWeatherCache));
          return this.siteService.weatherData;
        }
      });

      let zipWeatherCache = JSON.parse(localStorage.getItem(`ZipWeatherCache`)) || [];
      if (!zipWeatherCache.map(site => site?.zip?.slice(0,5)).includes(active?.zip?.slice(0,5)) || clearCache) {
        await getWeatherFromServer();
      } else {
        let cachedWeather = zipWeatherCache.find(site => site?.zip?.slice(0,5) == active?.zip?.slice(0,5));
        let targetTime = new Date(new Date().toDateString() + ` ` + cachedWeather?.weLastUpdatedShort);
        // Store in localStorage and reference that for 55 minutes or slightly less than 1 hour.
        let xMinutesAgo = new Date((new Date() as any) - 55 * 60 * 1000);

        if (targetTime >= xMinutesAgo && targetTime <= new Date()) {
          this.siteService.weatherData = { 
            ...cachedWeather,
            lastUpdated: this.lastUpdated,
            weLastUpdated: this.lastUpdated,
            onPageLoad: this.formatDate(new Date(), true),
            weLastUpdatedSite: moment().tz(cachedWeather?.siteTimezone).format(`h:mm A`),
          };
          this.siteService.weatherDataLoading = false;
          this.siteService.weatherDataLoaded = true;
          this.siteService._weatherDataLoaded.next(true);
          this.siteService.weatherDataError = false;
          // devEnv && console.log(`Cached Weather`, this.siteService.weatherData);
        } else {
          await getWeatherFromServer();
        }
      }
    } else {
      this.siteService.weatherData = null;
      this.siteService.weatherDataLoading = false;
      this.siteService.weatherDataLoaded = true;
    }
  }
}