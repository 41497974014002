import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Gateway, GatewayWithGroups } from 'src/app/features/manage/components/classes/Gateway';
import { MaintMapUnitsErrorComponent } from 'src/app/features/maintenance/modals/maint-map-units-error/maint-map-units-error.component';
import { MODAL_MAINT_UPDATE_UNIT_BLOCK } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'testdrive-list',
  templateUrl: './testdrive-list.component.html',
  styleUrls: ['./testdrive-list.component.scss'],
})
export class TestDriveListComponent /*implements OnInit*/ {
  displayedColumns: string[] = ['RadioButton', 'GatewayName', 'DeviceType', 'SerialNumber']; // list of table items
  dataSource = []; // data of RMD
  target: number;
  siteId: string;
  apicalling = false;

  constructor(
    public _ref: ChangeDetectorRef,
    private router: Router,
    public user: UserService,
    private siteService: SiteService,
    public modalController: ModalController,
    private toastService: ToastrService,
  ) { }

  // ngOnInit() {
  // }

  ionViewWillEnter() {
    this.dataSource = [];
    this.siteService.get_site_gateways_with_groups(this.user.active.id, true).subscribe(
      (gateways: GatewayWithGroups) => {
        this.setGatewaysRmd(gateways);
      });
  }

  ionViewWillLeave() {
    const newDataSource = [];
    this.dataSource = newDataSource;
    this._ref.detectChanges();
  }

  setGatewaysRmd(gateways: GatewayWithGroups) {
    // RMDデータ取得
    // RMD acquisition
    this.apicalling = false;
    let gatewaysWithGroups: Gateway[] = [];
    if(gateways){
      gatewaysWithGroups = gateways['gateways'] as Gateway[];
      const newDataSource = [];
      gatewaysWithGroups.map(gwg => {
        if(gwg.connection.connected === 'True' && gwg.outdoor_units.length > 0){
          let gatewayName = '-';
          let deviceType = '-';
          let serialNumber = '-';
          if (gwg.name){
            gatewayName = gwg.name
          }
          if (gwg.model.name){
            deviceType = gwg.model.name
          }
          if (gwg.serial_number){
            serialNumber = gwg.serial_number
          }
          const addData = {
            Checked: false,
            GatewayName: gatewayName,
            DeviceType: deviceType,
            SerialNumber: serialNumber,
            GatewayId: gwg.id
          };
          newDataSource.push(addData);
        }
      });

      if(newDataSource.length > 0){
        newDataSource[0].Checked = true;  // The first line of the radio button is checked by default.
      }

      this.dataSource = newDataSource;
      this._ref.detectChanges();
    }
  }

  PageTransition(pageName) {
    if (!this.siteService.handleIsConnected()){
      return;
    }

    if (this.apicalling){
      return;
    }

    let i = 0;
    this.target = -1;
    const allRadioButtons = document.querySelectorAll('input[name=\'radiobutton_tr\'][class=\'radio_button\']') as NodeListOf<HTMLInputElement>;
    allRadioButtons.forEach(radio => {
      if(radio && radio.checked){
        this.target = i;
      }
      i++;
    });
    if(this.target >= 0){
      this.apicalling = true;
      this.getRedisInfo(this.target, pageName);
    }
  }

  getRedisInfo(targetNo, pageName){
    const gatewayId = this.dataSource[targetNo].GatewayId;
    this.siteService.getUnitHistoryTop(gatewayId).subscribe(
      (resData: {[key:string]: number | boolean}) => {
        this.apicalling = false;
        if (resData){
          this.router.navigate(['/testdrive', this.user.active.id, pageName], { queryParams: { target: gatewayId}});
        }else{
          this.openMapUnitsError(MODAL_MAINT_UPDATE_UNIT_BLOCK);
        }
      },
      (err) => {
        this.apicalling = false;
        console.log('getUnitHistoryTop=>err', err);
      }
    );
  }

  async openMapUnitsError(err_msg) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: MaintMapUnitsErrorComponent,
      cssClass: 'me-modal-gw-move',
      backdropDismiss: true,
      componentProps: {
        errorMessage: err_msg
      },
    });
    return await modal.present();
  }
}
