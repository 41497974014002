import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor(/*public storage: Storage*/) {
    //this.init_storage();
   }

  // async init_storage() {
  //   // init the storage component
  //   await this.storage.create();
  // }

  // async setLocalStorage<T>(storageKey: string, object2Store: T) {
  //   let result = true;

  //   try {
  //    await this.storage.set(storageKey, object2Store);
  //   } catch (exception) {
  //     result = false;
  //   }
  //   return result;
  // }

  // async getLocalStorage(storageKey: string):Promise<string> {
  //   return await this.storage.get(storageKey);
  // }

  // async clearLocalStorage() {
  //   return await this.storage.clear();
  // }

  // async clearLocalStorageByKey(storageKey: string) {
  //   return await this.storage.remove(storageKey);
  // }

  localStorageSetItem(storage_key: string, object2Store: any) {
    localStorage.setItem(storage_key, JSON.stringify(object2Store));
  }

  localStorageGetItem(storage_key: string) {
    return localStorage.getItem(storage_key);
  }

  localStorageClear() {
    localStorage.clear();
  }

}
