<ion-header>
  <ion-toolbar>
    <ion-label me-label-header slot="start">Troubleshooting Tips</ion-label>
    <ion-item lines="none" slot="end">
      <!-- <ion-label>Select Model</ion-label> -->
      <ion-select label="Select Model" aria-label="Select Model" [(ngModel)]="selected_model" (ionChange)="modelSelected()" class="selectField selectModelField alert-wrapper">
        <!-- <ion-label>Model</ion-label> -->
        <ion-select-option *ngFor="let model of models" value="{{ model }}">
          {{ model }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item lines="none" slot="end">
      <!-- <ion-label class="ignoreLabel">Select Detail Code</ion-label> -->
      <ion-select label="Select Detail Code" aria-label="Select Detail Code" class="selectField selectDetailCodeField selectDetailCodeSelect" [(ngModel)]="selected_detail_error_code" (ionChange)="detailErrorCodeSelected()">
        <!-- <ion-label>Detail Code</ion-label> -->
        <ion-select-option class="selectDetailCodeSelectOption" *ngFor="let errorCode of detail_error_codes" value="{{ errorCode }}">
          {{ errorCode }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row me-troubleshooting-header>
      <ion-col>
        <ion-label class="definitionContainer selectable" me-larger-bold>
          {{ displayOptions?.definition }}
        </ion-label>
      </ion-col>
      <ion-col *ngIf="devEnv" class="devToggles devOnlyToggles">
        <ion-row>
          <ion-col>
            <ion-toggle [(ngModel)]="splitText" [enableOnOffLabels]="true">Clean Up Data</ion-toggle>
          </ion-col>
          <!-- <ion-col>
            <ion-toggle [(ngModel)]="dynamicIndex" [enableOnOffLabels]="true">Dynamic Index</ion-toggle>
          </ion-col> -->
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-header>
      <ion-col>
        <ion-label class="selectable" me-bold>Detection</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-line *ngFor="let r of displayOptions?.detection">
      <ion-col>
        <ion-label class="selectable">{{r}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-header>
      <ion-col>
        <ion-label class="selectable" me-bold>Cause</ion-label>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!splitText" me-troubleshooting-line>
      <ion-col>
        <ion-label class="selectable">{{ displayOptions?.cause[key_of_selected_detail_error_code] }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-grid *ngIf="splitText" class="innerGrid">
      <ion-row me-troubleshooting-line *ngFor="let cause of splitTroubleshootingText(displayOptions?.cause[key_of_selected_detail_error_code]); index as i; let first = first; let last = last;">
        <ion-col>
          <ion-label class="selectable"><span *ngIf="dynamicIndex">({{ i + 1 }}) - </span>{{ cause }}</ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row me-troubleshooting-header>
      <ion-col>
        <ion-label class="selectable" me-bold>Remedy</ion-label>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!splitText" me-troubleshooting-line>
      <ion-col>
        <ion-label class="selectable">{{ displayOptions?.remedy[key_of_selected_detail_error_code] }}</ion-label>
      </ion-col>
    </ion-row>
    <ion-grid *ngIf="splitText" class="innerGrid">
      <ion-row me-troubleshooting-line *ngFor="let remedy of splitTroubleshootingText(displayOptions?.remedy[key_of_selected_detail_error_code]); index as i; let first = first; let last = last;">
        <ion-col>
          <ion-label class="selectable"><span *ngIf="dynamicIndex">({{ i + 1 }}) - </span>{{ remedy }}</ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row me-troubleshooting-header *ngIf="displayOptions?.series[0] != ''">
      <ion-col>
        <ion-label class="selectable" me-bold>Series</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-line *ngIf="displayOptions?.series[0] != ''">
      <ion-col>
        <ion-label class="selectable">{{displayOptions?.series[0]}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-header *ngIf="displayOptions?.note[0] != ''">
      <ion-col>
        <ion-label class="selectable" me-bold>Note</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-line *ngIf="displayOptions?.note[0] != ''">
      <ion-col>
        <ion-label class="selectable">{{displayOptions?.note[0]}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-header *ngIf="displayOptions?.units">
      <ion-col>
        <ion-label class="selectable" me-bold>Unit(s)</ion-label>
      </ion-col>
    </ion-row>
    <ion-row me-troubleshooting-line *ngFor="let r of displayOptions?.units">
      <ion-col>
        <ion-label class="selectable">{{r}}</ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" id="i_btn_ok_cancel" name="i_btn_ok_cancel" (click)="close()" color="primary"
      style="margin: 0 1em 0 1em;">
      Close
    </ion-button>
  </ion-toolbar>
</ion-footer>