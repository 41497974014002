<ion-content class="ion-no-padding" main-white-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="white-background">
    <ion-title color="dark">
      <h1>Manage Payment Methods</h1>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['/account', user?.id, 'details']" (click)="returnToAccountDetail()" me-button-as-link
        id="btnBackToAccountDetail" name="btnBackToAccountDetail">
        &#8592;&nbsp;Back to Account Details </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-list class="me-button-list" lines="none">
    <ion-item>
      <div class="ion-icon-wrapper-raw-button" id="i_change_payment" (click)="isActionRestricted() ? user.presentActionRestricted(null, null, iconMaintenanceType) : changePaymentMethod()" title="Change Payment Method"
        [ngClass]="isActionRestricted() ? 'site-transfer-disable-modal' : ''">
        <ion-icon id="btnCreditCard" class="me-icon-cursor" name="card" me-icon></ion-icon>
      </div>
      <div class="ion-icon-wrapper-raw-button" id="i_remove_payment" (click)="isActionRestricted() ? user.presentActionRestricted(null, null, iconMaintenanceType) : removePaymentMethod()" title="Remove Payment Method"
        [ngClass]="isActionRestricted() ? 'site-transfer-disable-modal' : ''">
        <ion-icon id="btnRemoveCreditCard" class="me-icon-cursor" name="remove-circle-sharp" me-icon></ion-icon>
      </div>
    </ion-item>
  </ion-list>

  <div *ngIf="accountPaymentMethodsLoading">
    <ion-label id="zeroStateLoading" name="zeroStateLoading" style="font-size: 20px; margin-left: 20px;">
      <i>Loading</i>
    </ion-label>
    <ion-spinner name="dots" style="position: absolute; height: 40px"></ion-spinner>
  </div>
  <ion-grid class="me-grid-main">
    <ion-row *ngIf="!accountPaymentMethodsLoading && accountPaymentMethods.length == 0 && accountLicenses.length == 0">
      <ion-col>
        <ion-label color="medium" style="font-size: 20px">
          <i>There are no payment methods currently on file with your account.</i>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-radio-group [formControl]="selectedPaymentMethod" [value]="selectedPaymentMethod.value" style="width:100%;">
    <ion-grid *ngFor="let site of siteArray; index as siteIndex" class="site-container">
      <ion-row class="default-row-border">
        <ion-col class="me-site-name" size-xl="12" size-lg="12" size-md="12" size-sm="12">
          <strong>{{ site.siteName }}</strong>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let paymentMethod of site.pmtMethodArray; index as pmtMethIndex" class="default-row-border"
        [ngClass]="{'last-row': site.pmtMethodArray.length - 1 === pmtMethIndex && site.licenseArray.length == 0 }"
        (click)="onSelectPaymentMethod(paymentMethod)">
        <ion-col size="1">
          <ion-radio [value]="paymentMethod?.pmtMethodLookupId" class="me-radio"></ion-radio>
        </ion-col>
        <ion-col size="5" class="ion-no-padding" tappable>
          <ion-item lines="none" class="ion-no-padding no-hover me-item-height">
            <ion-label>
              <strong>Credit Card: </strong>{{ paymentMethod?.card_type | formatCardType }} ending in {{ paymentMethod?.last_four }}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="6" class="ion-no-padding" tappable>
          <ion-item lines="none" class="ion-no-padding no-hover me-item-height">
            <ion-label>
              <strong>Credit Card Exp.: </strong>{{ paymentMethod?.expiration }}
            </ion-label>
            <ion-label color="danger" class="ion-text-center" *ngIf="paymentMethod?.failed_payment_message">
              <strong><i>Failed Payment Method </i></strong>
            </ion-label>
            <ion-icon color="danger" size="large" name="alert-circle" *ngIf="paymentMethod?.failed_payment_message">
            </ion-icon>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row *ngFor="let license of site.licenseArray; index as licenseIndex" class="default-row-border"
        [ngClass]="{'last-row': site.licenseArray.length-1 === licenseIndex && siteIndex ==  siteArray.length -1}">
        <ion-col size="1"></ion-col>
        <ion-col size="5" class="ion-no-padding" >
          <ion-item lines="none" class="ion-no-padding no-hover me-item-height">
            <ion-label>
              <strong>Purchase Order:</strong> {{ license?.order_number }}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="6" class="ion-no-padding" >
          <ion-item lines="none" class="ion-no-padding no-hover me-item-height">
            <ion-label>
              <strong>License Exp.:</strong> {{ license?.licenseExpirationDate | date : 'MM/dd/yyyy'}}
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-radio-group>
  </ion-grid>

<!--<pre>selectedPaymentMethod.value: {{selectedPaymentMethod.value | json}}</pre>-->
<!--<pre>siteArray: {{siteArray | json}}</pre>-->
<!--<pre>accountPaymentMethods: {{accountPaymentMethods | json}}</pre>-->
<!--<pre>accountLicenses: {{accountLicenses | json}}</pre>-->
</ion-content>