import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { SiteGuard } from 'src/app/common/guards/site.guard';
import { KenzaCloudMenuIds, PermissionDetailEnum } from 'src/app/enumerations/enums';
import { DashboardComponent } from 'src/app/features/dashboard/components/dashboard/dashboard.component';
import { SiteAdminPage } from '../../components/site-admin/site-admin.page';
import { EquipmentAlertAdminComponent } from '../../components/site-alerts/alert/equipment-alert-admin/equipment-alert-admin.component';
import { NotificationAdminComponent } from '../../components/site-alerts/notification/notification-admin/notification-admin.component';
import { SiteAlertsComponent } from '../../components/site-alerts/site-alerts.component';
import { SiteAnalyticsComponent } from '../../components/site-analytics/site-analytics.component';
import { SiteControlComponent } from '../../components/site-control/site-control.component';
import { SiteMonitorComponent } from '../../components/site-monitor/site-monitor.component';
import { SiteGatewayMovePage } from '../../pages/site-gateway-move/site-gateway-move.page';
import { DashboardResolver } from '../../resolvers/dashboard/dashboard-resolver';
import { SiteMonitorGuard } from 'src/app/common/guards/site-monitor.guard';
import { SiteSchedulingComponent } from '../../components/site-scheduling/site-scheduling.component';

const siteGuards = [AuthGuard, HasInternetConnectionGuardService, SiteGuard];
const siteMonitorGuards = [AuthGuard, HasInternetConnectionGuardService, SiteMonitorGuard];

const routes: Routes = [
  {
    path: 'site/:siteId/dashboard',
    component: DashboardComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    resolve: {
      Dashboard: DashboardResolver
    },
    data: { parent: KenzaCloudMenuIds.Dashboard, child: KenzaCloudMenuIds.Dashboard }
  }, {
    path: 'site/:siteId/monitor',
    component: SiteMonitorComponent,
    canActivate: siteMonitorGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteMonitor }
  }, {
    path: 'site/:siteId/control',
    component: SiteControlComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteControl }
  }, {
    path: 'site/:siteId/analytics',
    component: SiteAnalyticsComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'toSiteAnalytics', parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteAnalytics, requiredPermission: PermissionDetailEnum.ViewSiteAnalytics }
  }, {
    path: 'site/:siteId/schedule',
    component: SiteSchedulingComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Site, child: KenzaCloudMenuIds.SiteSchedule }
  }, {
    path: 'sitegatewaymovepage',
    component: SiteGatewayMovePage,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    // data: { transition: 'toGatewayDetail' }
  }, {
    path: 'site/:siteId/admin',
    component: SiteAdminPage,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { parent: KenzaCloudMenuIds.Alerts, child: KenzaCloudMenuIds.Alerts }
  }, {
    path: 'site/:siteId/alerts',
    component: SiteAlertsComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: 'always',
    data: { transition: 'from' }
  }, {
    path: 'site/:siteId/alerts/equipment/:alertId',
    component: EquipmentAlertAdminComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'toEquipmentAlerts' }
  }, {
    path: 'site/:siteId/alerts/notification/:alertId',
    component: NotificationAdminComponent,
    canActivate: siteGuards,
    runGuardsAndResolvers: "always",
    data: { transition: 'toSiteNotifications' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteRoutingModule { }
