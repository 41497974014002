<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Map Units</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid id="i_grid_map_units" name="i_grid_map_units">
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-top: 10px">
          <ion-label color="danger" id="i_label_map_unit_message" name="i_label_map_unit_message">
            Error:
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-left: 10px">
          <ion-label>
            <strong>
            </strong>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_deactivate_message" name="i_label_deactivate_message">
            {{errorMessage}}
          </ion-label>
        </div>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" id="ok_map_units_error" name="ok_map_units_error" (click)="close()" color="primary"
      style="margin: 0 0 0 1em;">
      OK
    </ion-button>
  </ion-toolbar>
</ion-footer>