<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">MTDZ</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-card-content>
            <ion-grid class="calendarline">
              <ion-row>
                <ion-item lines="none" class="cw135">
                  <p>Display range:</p>
                </ion-item>
                <ion-item lines="none" class="cw200">
                  <p>
                    <ng2-flatpickr
                      #flatpickr2
                      [config]="options02"
                    ></ng2-flatpickr>
                  </p>
                </ion-item>
                <ion-item lines="none" class="cw30"> ~ </ion-item>
                <ion-item lines="none" class="cw200">
                  <p>
                    <ng2-flatpickr
                      #flatpickr
                      [config]="options"
                    ></ng2-flatpickr>
                  </p>
                </ion-item>
                <ion-item lines="none">
                  <ion-button id="setTime" class="time-button-style" (click)="setTime()">
                    Time Setting
                  </ion-button>
                </ion-item>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Target Equipment</h4>
            </ion-title>
          </ion-item>
          <div class="ou-table">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="RadioButton">
                <th mat-header-cell *matHeaderCellDef class="col-select"> Select </th>
                <td mat-cell *matCellDef="let element" class="col-select">
                  <input class="radio_button" type="radio" name="radiobutton_tr" [checked]="element.Checked">
                </td>
              </ng-container>

              <ng-container matColumnDef="SystemName">
                <th mat-header-cell *matHeaderCellDef class="col-width"> System Name </th>
                <td mat-cell *matCellDef="let element" class="col-width"> {{element.SystemName}} </td>
              </ng-container>

              <ng-container matColumnDef="ModelName">
                <th mat-header-cell *matHeaderCellDef class="col-width"> Model Name </th>
                <td mat-cell *matCellDef="let element" class="col-width"> {{element.ModelName}} </td>
              </ng-container>

              <ng-container matColumnDef="SerialNo">
                <th mat-header-cell *matHeaderCellDef class="col-width"> Serial No. </th>
                <td mat-cell *matCellDef="let element" class="col-width"> {{element.SerialNo}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-card
          id="cardNoSelectedUnit"
          name="cardNoSelectedUnit"
          class="URLarea"
          me-ion-card
        >
          <ion-card-header>
            <div class="resulttitle">
              {{ dlMessage }}
              <br>
              {{ dlMessage2 }}
            </div>
          </ion-card-header>
          <ion-card-content>
            <div>
              <ion-label *ngIf="dlUrl" me-navigate-to (click)="openURL()">
                Please click here if you cannot download.
              </ion-label>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
