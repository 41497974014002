import { Component, Input, ViewChild } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from '../../services/site.service';
import { Site } from '../../classes/site';
import { Gateway } from '../../../manage/components/classes/Gateway';
import { CreditCardFormComponent } from 'src/app/common/components/credit-card-form/credit-card-form.component';
import { AccountService } from 'src/app/features/account/services/accountService';

@Component({
  selector: 'app-site-gateway-move-new-payment',
  templateUrl: './site-gateway-move-new-payment.page.html',
  styleUrls: ['./site-gateway-move-new-payment.page.scss'],
})
export class SiteGatewayMoveNoPaymentModal /*implements OnInit*/ {
  @ViewChild(CreditCardFormComponent) ccForm: CreditCardFormComponent;

  @Input() selectedGateway: Gateway;
  @Input() currentSiteId: string;
  @Input() movedToSite: Site;

  moveError = '';

  disableMoveButton: boolean;

  // auto process the purchase after get get a new token
  autoProcessAfterToken = false;

  constructor(
    public modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    public user: UserService,
    public siteService: SiteService,
    public loadingController: LoadingController,
    public accountService: AccountService
  ) {
    this.disableMoveButton = true;
  }

  // ngOnInit() {
  // }

  cancelMove() {
    this.modalController.dismiss({
    });
  }

  async moveGateway() {
    if (!this.siteService.handleIsConnected()) return;

    if (this.ccForm && this.ccForm.tokenIsDirty) {
      // need to refres the token
      this.autoProcessAfterToken = true;
      this.ccForm.submitTokenRequest(true)
      return
    }

    const loading = await this.loadingController.create({
      message: 'Moving gateway...',
      spinner: 'lines',
    });

    await loading.present();

    const params = {
      last_modified_account_id: this.user.id,
      move_from_site_id: this.user.active.id,
      move_to_site_id: this.movedToSite.id,
      gateway_id: this.selectedGateway.id,
      skip_payment_check: true,
      ...this.getParams(),
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    this.moveError = '';

    await this.siteService
      .moveGateway(params)
      .toPromise()
      .then((result) => {
        if ('error' in result) {
          // then we failed for some reason.
          this.moveError = <string>result['error']
          this.disableMoveButton = true;
        } else { // Gateway Move Success
          this.selectedGateway.site_id = this.movedToSite.id;
          // update locations in the user site list
          this.user.updateSiteLocations(this.user.active.id, result['from_site_locations']);
          this.user.updateSiteLocations(this.movedToSite.id, result['to_site_locations']);
          // update locations in this components copy
          this.movedToSite.update_locations(result['to_site_locations']);
          this.siteService.siteEdited.emit({ type: `Refresh Notifications` });
          this.accountService.subscriptionDetailsUpdated = true;              

          this.modalController.dismiss({
            moved: true,
            movedGateway: this.selectedGateway,
            movedFromSite: this.user.active,
            movedToSite: this.movedToSite,
          });
        }
      })
      .catch((error) => {
        this.moveError = `Error: ${error.message}`;
        this.disableMoveButton = true;
      })
      .finally(() => {
        loading.dismiss();
      });
  }

  getParams() {
    return {};
  }

  creditCardStatusDidChange(event) {
    this.disableMoveButton = !event.valid;
    this.moveError = ''

    if (event.valid) {
      this.getParams = function () {
        return {
          token_id: event.token,
          customer_info: event.customerForm,
          tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
         }
      };
    } else {
      this.getParams = function () { return {} };
    }

    if (this.autoProcessAfterToken) {
      // continue with the gateway move
      this.moveGateway()
    }
  }
}
