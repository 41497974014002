<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Progress</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Target Equipment</h4>
            </ion-title>
          </ion-item>
          <div class="ou-table">
            <table mat-table [dataSource]="dataSource_2" class="mat-elevation-z8">
              <ng-container matColumnDef="RadioButton">
                <th mat-header-cell *matHeaderCellDef class="col-select"> Select </th>
                <td mat-cell *matCellDef="let element" class="col-select">
                  <input type="radio" name="radiobutton_tr" [checked]="element.Checked" (click)="displayMaintData()">
                </td>
              </ng-container>

              <ng-container matColumnDef="SystemName">
                <th mat-header-cell *matHeaderCellDef class="col-width-ou"> System Name </th>
                <td mat-cell *matCellDef="let element" class="col-width-ou"> {{element.SystemName}} </td>
              </ng-container>

              <ng-container matColumnDef="ModelName">
                <th mat-header-cell *matHeaderCellDef class="col-width-ou"> Model Name </th>
                <td mat-cell *matCellDef="let element" class="col-width"> {{element.ModelName}} </td>
              </ng-container>

              <ng-container matColumnDef="SerialNo">
                <th mat-header-cell *matHeaderCellDef class="col-width-ou"> Serial No. </th>
                <td mat-cell *matCellDef="let element" class="col-width-ou"> {{element.SerialNo}} </td>
              </ng-container>

              <ng-container matColumnDef="OutsideTemperature">
                <th mat-header-cell *matHeaderCellDef class="col-width-ou"> Outside Temperature </th>
                <td mat-cell *matCellDef="let element" class="col-width-ou"> {{element.OutsideTemperature}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns_2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns_2;"></tr>
            </table>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-card me-ion-card>
          <ion-item lines="none">
            <ion-title>
              <h4 *ngIf="(progress==0)">Progress: Before start</h4>
              <h4 *ngIf="(progress==1)">Progress: TestRun starting up</h4>
              <h4 *ngIf="(progress==2)">Progress: {{progressPoint}}%</h4>
              <h4 *ngIf="(progress==7)">Progress: Completed</h4>
              <h4 *ngIf="(progress==8)">Progress: Canceled</h4>
              <h4 *ngIf="(progress==9)">Progress: Error</h4>
            </ion-title>
            <div>
              <ion-button id="download" *ngIf="(progress>6 && progressPoint>0)" class="button-style" (click)="downloadCSV()">
                Download
              </ion-button>
            </div>
            <div>
              <ion-button id="cancel" *ngIf="(progress<7)" class="button-style" (click)="cancelTestRun()" [disabled]="isCancelButtonDisabled">
                Cancel
              </ion-button>
              <ion-button id="back" *ngIf="(progress>6)" class="button-style" (click)="backToTestRunTop()">
                Back
              </ion-button>
            </div>
          </ion-item>
          
          <ion-card-content id="testrunData" color="white" me-top-border class="progress-row">
            <div class="table-area">
              <table mat-table [dataSource]="dataSource"  class="mat-elevation-z8">
                
                <ng-container matColumnDef="Key1" sticky>
                  <th class="col-width-left" mat-header-cell *matHeaderCellDef> Time </th>
                  <td mat-cell *matCellDef="let element" > {{element.Key1}} </td>
                </ng-container>

                <ng-container *ngFor="let header of timeHeader" [matColumnDef]="header.def">
                  <th class="col-width" mat-header-cell *matHeaderCellDef> {{header.name}} </th>
                  <td mat-cell *matCellDef="let element"
                  [ngStyle]="{'background-color': 
                    (element['Key2'] == 'OC_CONV1_145' && element[header.def][1] > ts63HS1) 
                    || (element['Key2'] == 'OC_CONV1_37' && element[header.def][1] < tsTe) 
                    || (element['Key2'] == 'OC_CONV1_4' && element[header.def][1] > tsTH4) 
                    || (element['Key2'] == 'IC_CONV1_3' && (element[header.def][1] < tsTH2a || element[header.def][1] > tsTH2b)) 
                    || (element['Key2'] == 'IC_CONV1_6' && element[header.def][1] > tsSH) 
                    ? 'pink' : 'none'}">  {{element[header.def][0]}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>