import { ApplicationErrorsEnum, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { GatewayUnit } from 'src/app/features/manage/components/classes/GatewayUnit';

export class MapGatewayCompleteResponse {
    response_type: WebSocketResponseTypeEnum;
    request_id: number;
    message: string;
    gateway_id: string;
    site_locations: string;

    constructor() {
        this.response_type = WebSocketResponseTypeEnum.Map_Gateway_Complete;
    }
}

export class MapGatewayProgressResponse {
    response_type: WebSocketResponseTypeEnum;
    gateway_id: string;
    status: string

    constructor() {
        this.response_type = WebSocketResponseTypeEnum.Map_Gateway_Progress;
    }
}

export class MapGatewayStartedResponse {
    response_type: WebSocketResponseTypeEnum;
    gateway_id: string;
    status: string

    constructor() {
        this.response_type = WebSocketResponseTypeEnum.Map_Gateway_Started;
    }
}

export class MapGatewayErrorResponse {
    response_type: WebSocketResponseTypeEnum;
    gateway_id: string;
    status: string

    constructor() {
        this.response_type = WebSocketResponseTypeEnum.Map_Gateway_Error;
    }
}

export class IssueResponse {
    response_type: WebSocketResponseTypeEnum;
    response_message: string;
    request_event: string;
    request_id: string;
}

export class ErrorResponse {
    response_type: ApplicationErrorsEnum;
    response_message: string;
}

export class ProfileUpdateResponse {
    device_serial: string;
    units: GatewayUnit[];
}