import { GatewayModelClass } from "src/app/enumerations/enums";

export class SiteAnalyticsRequest {
    start_date: string;
    end_date: string;
    fields: string;
    gateway_id: string;
    odu_bus_address: string;
    request_id: string;
    site_id: string;
    local_timezone: string;

    constructor() {
        this.start_date = '';
        this.end_date = '';
        this.gateway_id = '';
        this.odu_bus_address = '';
        this.request_id = '';
        this.site_id = '';
        this.local_timezone = '';
    }
}

export class SiteAnalyticsGIFDownloadRequest {
    gateway_id: string;
    odu_bus_address: string;
    request_id: string;
    site_id: string;

    constructor() {
        this.request_id = '';
        this.gateway_id = '';
        this.odu_bus_address = '';
        this.site_id = '';
    }
}

export class SiteAnalyticsMtdzDownloadRequest {
    gateway_id: string;
    gateway_model: GatewayModelClass;
    odu_bus_address: string;
    request_id: string;
    site_id: string;
    start_date: string;
    end_date: string;
    time_zone: string;
    monitor_mode: number;
    address: Array<number>;

    constructor() {
        this.request_id = '';
        this.gateway_id = '';
        this.gateway_model = GatewayModelClass.MCC;
        this.odu_bus_address = '';
        this.site_id = '';
        this.start_date = '';
        this.end_date = '';
        this.time_zone = '';
        this.monitor_mode = 99;
        this.address = []
    }
}