<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="unitType == 'Indoor'">Edit {{unitType}} Coil Information</ion-title>
    <ion-title *ngIf="unitType != 'Indoor'">Edit {{unitType}} Unit Information</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="outdoorUnitEditForm">
    <ion-grid>
      <ion-row class="requiredRow">
        <ion-col size="12">
          <ion-input minlength="3" maxlength="50" type="text" class="form-control" id="outdoorUnitName" name="outdoorUnitName"
            formControlName="name" (ionBlur)="trim($event)" labelPlacement="floating">
              <ion-text slot="label" for="outdoorUnitName" color="secondary">Unit Name<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div *ngIf="outdoorUnitEditForm.controls.name.errors">
            <div validation-error *ngIf="outdoorUnitEditForm.controls.name.errors.required">
              Unit Name is required
            </div>
            <div validation-error *ngIf="outdoorUnitEditForm.controls.name.errors.minlength || outdoorUnitEditForm.controls.name.errors.pattern">
              Unit Name must be between 3 and 50 characters
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="(selectedGatewayUnit.type == gatewayUnitTwoDigitType.IndoorUnit)">
        <ion-col size="12">
          <ion-item-group>
            <ion-item lines="full" style="padding-right: 0px;">
              <ion-label position="stacked" id="lblGroupID" for="group_id" name="lblGroup" color="secondary" select-label-fix>Group</ion-label>
              <select formControlName="group_id" me-select>
                <option *ngFor="let gateway_group of gateway_groups" [value]="gateway_group.group_id">{{gateway_group.group_name}}</option>
              </select>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item-group>
            <ion-item lines="full">
              <ion-input type="text" class="forxm-control" id="type_name" name="type_name" formControlName="type_name" labelPlacement="floating">
                <ion-text slot="label" id="lblTypeName" for="type_name" name="lblTypeName" color="medium">Unit Type</ion-text>
              </ion-input>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>

      <ion-row [ngClass]="selectedGatewayUnit.type != gatewayUnitTwoDigitType.OutdoorUnit ? 'hide': ''">
        <ion-col size="12">
          <ion-item-group>
            <ion-item lines="full" style="padding-right: 0px;">
              <ion-label position="stacked" id="lblUnitSeries" for="unit_series" name="lblUnitSeries" color="secondary" select-label-fix>
                Outdoor Series
              </ion-label>
              <select formControlName="unit_series" me-select (change)="seriesSelected($event)">
                <option value="">Select Series</option>
                <option *ngFor="let series of seriesList" [value]="series">{{series}}</option>
              </select>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>

      <ion-row [ngClass]="selectedGatewayUnit.type != gatewayUnitTwoDigitType.OutdoorUnit ? 'hide': ''">
        <ion-col size="12">
          <ion-item-group>
            <ion-item lines="full" style="padding-right: 0px;">
              <ion-label position="stacked" id="lblUnitModel" for="unit_model" name="lblUnitModel" color="secondary" select-label-fix>
                Outdoor Model
              </ion-label>
              <select formControlName="unit_model" me-select (change)="modelSelected($event)">
                <option value="">Select Model</option>
                <option *ngFor="let model of modelsList" [value]="model">{{model}}</option>
              </select>
            </ion-item>
          </ion-item-group>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item-group>
            <ion-item lines="full">
              <ion-input type="text" class="forxm-control" id="bus_address" name="bus_address"
                formControlName="bus_address" labelPlacement="floating">
                  <ion-text slot="label" id="lblBusAddress" for="bus_address" name="lblBusAddress" color="medium">M-NET Address</ion-text>
              </ion-input>
            </ion-item>

          </ion-item-group>
        </ion-col>
      </ion-row>
      <ion-row [ngClass]="(selectedGatewayUnit.type == gatewayUnitTwoDigitType.OutdoorUnit || selectedGatewayUnit.unit_type == gatewayUnitTypeEnum.Other || selectedGatewayUnit.type == gatewayUnitTwoDigitType.MelshiHolder) ? 'hide': ''">
        <ion-col size="12">
          <ion-item lines="full">
            <mat-form-field style="width: 100%" appearance="none">
              <mat-label class="{{ outdoorUnitEditForm.controls.location.disabled ? 'disabledLabel' : '' }}" for="location">Location / Area</mat-label>
              <input id="location"
                type="text"
                placeholder=""
                aria-label=""
                matInput
                [formControl]="outdoorUnitEditForm.controls.location"
                [matAutocomplete]="auto"
                maxlength="50">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let location of filteredLocationOptions" [value]="location">
                  {{location}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ion-item> 
        </ion-col>
      </ion-row>

    </ion-grid>
  </form>
</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-left ion-align-items-left" *ngIf="editResult">
        <ion-label color="danger" id="editResult" name="editResult">{{editResult}}</ion-label>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-button [disabled]="!outdoorUnitEditForm.valid || !saveEnabled" id="btnEditUnit" name="btnEditUnit"
          (click)="editUnit()" style="margin: 0 0 0 1em;" color="primary">
          Save
        </ion-button>
        <ion-button id="btnCancelEdit" name="btnCancelEdit" color="reverse-primary" (click)="cancelEdit()"
          style="margin: 0 0 0 1em;">
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>