<ion-header>
  <ion-toolbar>
    <ion-title>Change Account Password</ion-title>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid white-background>
    <form [formGroup]="changeForm" *ngIf="!success">
      <ion-row class="requiredRow">
        <ion-col>
          <div class="ion-icon-wrapper-raw-button" 
               title="{{showCurrent ? 'Hide Current Password' : 'Show Current Password'}}" (click)="toggleCurrent()">
            <ion-icon size="small" color="secondary" eye-icon-margin name="{{showCurrent ? 'eye' : 'eye-off'}}">
            </ion-icon>
          </div>
          <ion-input type="{{showCurrent ? 'text' : 'password'}}" formControlName="old"
             (ionInput)="submitAttempt=false" (keyup.enter)="change()" clearOnEdit="false" maxlength="50" 
             labelPlacement="floating" >
              <ion-text slot="label"  color="secondary" >Current Password<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div validation-error
            *ngIf="changeForm.controls.old.hasError('minlength') && changeForm.controls.old.dirty">
            Password must be between 8 and 50 characters
          </div>
          <div validation-error
            *ngIf="changeForm.controls.old.hasError('required') && changeForm.controls.old.touched">
            Current Password is required
          </div>
          <div validation-error *ngIf="error && submitAttempt">
            {{ error }}
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="requiredRow">
        <ion-col>
            <div [class.ng-invalid]="!changeForm.controls.password.valid"
              [class.ng-valid]="changeForm.controls.password.valid">
              <div class="ion-icon-wrapper-raw-button" slot="end"
               title="{{showPassword ? 'Hide New Password' : 'Show New Password'}}" (click)="togglePassword()">
                <ion-icon size="small" color="secondary" eye-icon-margin name="{{showPassword ? 'eye' : 'eye-off'}}">
                </ion-icon>
              </div>
              <ion-input appLimitPasswordKeys type="{{showPassword ? 'text' : 'password'}}" formControlName="password" 
                (keyup.enter)="change()" clearOnEdit="false" maxlength="50" labelPlacement="floating">
                  <ion-text slot="label" color="secondary">New Password<sup validation-error>*</sup></ion-text>  
              </ion-input>
            </div>
            <div validation-error
              *ngIf="changeForm.controls.password.dirty && ( changeForm.controls.password.hasError('minlength') || changeForm.controls.password.hasError('NotEnoughCategories'))">
              Password must be 8 to 50 characters, and contain three of the following four categories: uppercase
              letter, lowercase letter, number: 0-9, special character: !@#$%^&*()-_=|+]{{ '}' }}[{{ '{' }}
            </div>
            <div validation-error
              *ngIf="changeForm.controls.password.dirty && changeForm.controls.password.hasError('PasswordInEmail') && !changeForm.controls.password.hasError('minlength') && !changeForm.controls.password.hasError('NotEnoughCategories')">
              Password cannot be an exact substring of the email address
            </div>
            <div validation-error
              *ngIf="changeForm.controls.password.dirty && changeForm.controls.password.hasError('NewIsCurrent')">
              New Password cannot be the same as Current Password
            </div>
            <div validation-error
              *ngIf="changeForm.controls.password.touched && changeForm.controls.password.hasError('required') && !(changeForm.controls.password.hasError('minlength') || changeForm.controls.password.hasError('NotEnoughCategories'))">
              New Password is required
            </div>
        </ion-col>
      </ion-row>
      <ion-row class="requiredRow">
        <ion-col>
              <ion-input appLimitPasswordKeys type="{{showPassword ? 'text' : 'password'}}" formControlName="confirm" 
                (keyup.enter)="change()" clearOnEdit="false" maxlength="50" labelPlacement="floating">
                  <ion-text slot="label" color="secondary" >Confirm Password<sup validation-error>*</sup></ion-text>
              </ion-input>
            <div validation-error
              *ngIf="changeForm.controls.confirm.touched && changeForm.controls.confirm.hasError('required') && !changeForm.controls.confirm.hasError('PasswordMismatch')">
              Confirm Password is required
            </div>
            <div validation-error
              *ngIf="changeForm.controls.confirm.dirty && changeForm.controls.confirm.hasError('PasswordMismatch')">
              Password mismatch
            </div>
            <!-- <div validation-error>{{ changeForm.controls.confirm?.errors|json}}</div> -->
        </ion-col>
      </ion-row>
      <ion-row padding-top>
        <ion-col>
          <ion-button id="btnChangePasswordLoggedIn" expand="block" [@enableDisable]="changeForm.valid? 'enabled' : 'disabled'"
            (click)="change()" class="automationTesting ">
            Change Password</ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</div>