import { DatePipe } from '@angular/common';

export class FileStringUtilities {
    public getContentTypeFromFileName(fileName: string): string {
        let contentType = '';

        const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
        switch (fileExtension.toLocaleLowerCase()) {
            case 'gif':
                contentType = 'image/gif';
                break;

            case 'jpeg':
                contentType = 'image/jpeg';
                break;

            case 'jpg':
                contentType = 'image/jpeg';
                break;

            case 'png':
                contentType = 'image/png';
                break;
        }

        return contentType;
    }

    public swapFileName(originalFileNameAndExtension: string, newFileName: string): string {
        const fileExtension = originalFileNameAndExtension.substr(originalFileNameAndExtension.lastIndexOf('.') + 1);
        const randomNumber = this.get5DigitRandomNumber();
        // const swappedFileName = newFileName + '.' + fileExtension;
        const swappedFileName = newFileName + '-' + randomNumber + '.' + fileExtension;
        return swappedFileName;
    }

    private get5DigitRandomNumber(): string {
        return Math.floor((Math.random() + Math.floor(Math.random() * 5) + 1) * Math.pow(10, 6)).toString();
    }


}

export class DateStringUtilities {
    static formatDateString(dateStringToFormat: string, locale: string, format: string): string {
        const datePipe = new DatePipe(locale);
        const formattedDate = datePipe.transform(dateStringToFormat, format);
        return formattedDate;
    }

    static timeFormat(t) {
        const d = new Date(t + 'Z');
        return d.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    }

    static formatTimeZoneString(utcOffset) {
        let plusminus: string;
        if (utcOffset < 0) {
            plusminus = '-';
        } else {
            plusminus = '+';
        }
        const timeZone: string = plusminus + ('0' + String(Math.floor(Math.abs(utcOffset) / 60))).slice(-2) + ':' +
            ('0' + String(Math.abs(utcOffset) % 60)).slice(-2);

        return timeZone
    }
}

export function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
        if (!Object.prototype.hasOwnProperty.call(a, key) ||
            !Object.prototype.hasOwnProperty.call(b, key)) return 0;
        //if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
        const comparison = a[key].toString().localeCompare(b[key]);
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}