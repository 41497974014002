import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  // 試運転状態監視用
  progressSubject: Subject<number> = new Subject<number>();
  public progressObservable$: Observable<number> = this.progressSubject.asObservable();

  // 状態通知
  noticeProgress(progress) {
    this.progressSubject.next(progress);
  }
}
