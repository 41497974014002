<ion-content class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <div class="ion-text-start">
        <h1>Site List</h1>
        <div *ngIf="dataLoading">
          <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i></ion-label>
          <ion-spinner name="dots" style="position: absolute; height: 40px"></ion-spinner>
        </div>
        <ion-label *ngIf="!dataLoading && siteList.length == 0" id="zeroStateMessage" name="zeroStateMessage" color="medium">
          <i>You are not a member of any sites.</i>
        </ion-label>
      </div>
    </ion-title>
    <ion-button id="ionBtnCreateNewSite" (click)="createSite()" slot="end" class="ionBtnCreateNewSite automationTesting ion-padding-horizontal">
      Create a New Site
    </ion-button>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12" *ngFor="let site of siteList; trackBy: trackBySiteId; index as i; let first = first; let last = last;">
        <ion-card me-ion-card>
          <ion-item lines="none" class="ion-no-padding" me-site-header>

            <ion-note id="btnBookmark" name="btnBookmark" (click)="navigateToSiteAlerts(site)"
              [routerLink]="['/site', site.id, 'alerts']"
              class="ion-no-margin ion-no-padding alert-notification-bookmark"
              *ngIf="site.count_of_site_notifications && site.count_of_site_notifications>0 && !site.transfer_site" color="white">
              {{site.count_of_site_notifications}}
            </ion-note>

            <ion-note id="btnNoBookmark" name="btnNoBookmark" (click)="navigateToSiteAlerts(site)"
              [routerLink]="['/site', site.id, 'alerts']"
              class="ion-no-margin ion-no-padding alert-notification-no-bookmark" style="padding-right: 0px"
              *ngIf="!site.count_of_site_notifications || site.count_of_site_notifications==0 || site.transfer_site" color="white">
              &nbsp;
            </ion-note>

            <ion-label (click)="navigateToSiteDashboard(site)" id="lblSiteName" name="lblSiteName"
              class="ion-no-text-wrap"
              [ngStyle]="{
                'padding-right' : (site.transfer_locked && !site.transfer_site) || site.transfer_site ? '28px' : '0px',
                'padding-left' : (!site.count_of_site_notifications || site.count_of_site_notifications=== 0) || site.transfer_site ? '20px' : '0px',
              }"
            >
              <strong title="{{ site.name }}">{{ site.name }}</strong>
              <div title="{{ site.city ? site.city + ', ' : ''}} {{ site.state }} {{ site.zip }}" me-ion-card-sub-title class="oflow">
                {{ site.city ? site.city + ', ' : ''}} {{ site.state }} {{ site.zip }}
              </div>
            </ion-label>

            <div *ngIf="site.transfer_locked && !site.transfer_site" class="transfer-emblem" (click)="navigateToSiteDashboard(site)" title="Site is being transferred">
              <ion-icon name="time"></ion-icon>
            </div>
            <div *ngIf="site.transfer_site" class="transfer-emblem" (click)="navigateToSiteDashboard(site)" title="Site is being transferred">
              <ion-icon name="ribbon"></ion-icon>
            </div>

            <div class="ion-icon-wrapper-arrow-hide-show" slot="end" (click)="toggleSiteInfo(site)"
              [title]="getIsSiteExpanded(site) ? 'Collapse' : 'Expand'">
              <ion-icon [name]="getIsSiteExpanded(site)? 'caret-up-sharp' : 'caret-down-sharp'"></ion-icon>
            </div>
          </ion-item>
          <ion-card-content me-ion-card-content *ngIf="getIsSiteExpanded(site)" me-top-border>
            <ion-grid class="ion-no-padding">
              <ion-row white-background>
                <ion-col size="4" class="me-site-detail-label"><strong>Company:</strong></ion-col>
                <ion-col>{{ site.company }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4" class="me-site-detail-label"><strong>Address:</strong></ion-col>
                <ion-col>{{ site.addressOne }}</ion-col>
              </ion-row>
              <ion-row *ngIf="site.addressTwo" white-background>
                <ion-col size="4"></ion-col>
                <ion-col>{{ site.addressTwo }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4"></ion-col>
                <ion-col>{{site.city ? site.city + ', ' : ''}} {{ site.state }} {{ site.zip }}</ion-col>
              </ion-row>
              <ion-row white-background>
                <ion-col size="4" class="me-site-detail-label"><strong>Phone:</strong></ion-col>
                <ion-col>{{ site.phone }}</ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>