export enum KenzaCloudMenuIds {
  NoSelection = -1,
  test = 0,
  Home = 1,
  Site,
  SiteMonitor,
  SiteMonitorODU,
  SiteMonitorIDUs,
  SiteControl,
  SiteAnalytics,
  SiteMembers,
  SiteInformation,
  SiteReports,
  Alerts,
  Settings,
  SettingsSubscription,
  SettingsAlerts,
  SettingsReports,
  Dashboard,
  SiteGateways,
  AccountDetail,
  MemberAccountDetail,
  EquipmentAlert,
  SiteNotification,
  AccountGatewaySubscriptionPlans,
  AccountPaymentMethods,
  GatewayDetail,
  Manage,
  ManageGateways,
  ManageMembers,
  MemberDetail,
  SiteSchedule,
  TestDrive,
  SiteMaintenance
}

export enum ModelState {
  None,
  Create,
  Read,
  Update,
  Delete,
  Created,
  Updated,
  Deleted,
  Quick_Update,
  Quick_Updated,
}

export enum PermissionDetailEnum {
  NonPermission = 0,
  ReassignSiteOwner = 1,
  InviteUsers = 2,
  AddRemoveMembers = 3,
  AssignChangeRole = 4,
  AckMemberNotification = 5,
  EditSiteDetails = 6,
  AddRemoveSiteGateway = 7,
  MoveGateway = 8,
  MapIndoorOutdoorUnits = 9,
  EditSiteGateway = 10,
  EditUnit = 11,
  AckEquipmentAlert = 12,
  ResetEquipmentAlert = 13,
  ControlUnit = 14,
  EditGatewaySubscription = 15,
  EditGatewayPaymentMethod = 16,
  AckGatewayMove = 17,
  ViewGatewayDetails = 18,
  //  SADownloadMaintDataCSV = 19,
  SAPerformAnalytics = 20,
  ViewGatewayNotification = 21,
  ViewEquipmentAlert = 22,
  ViewMemberNotification = 23,
  ConfigureUnitGroups = 24,
  DeactivateSite = 25,
  AckPaymentNotification = 26,
  ViewPaymentNotification = 27,
  ViewGatewayDecommission = 28,
  AckGatewayDecommission = 29,
  ViewSiteMonitoring = 30,
  ViewSiteAnalytics = 31,
  PerformFirmwareUpgrade = 32,
  ViewGatewayDetailsSubData = 33,
}

export enum PasswordErrorMessages {
  INCORRECT_CREDENTIALS = `Incorrect username or password.`,
  PASSWORD_ATTEMPTS_EXCEEDED = `Password attempts exceeded`
}

export enum SearchFilterActionTypes {
  Search = `Search`,
  Filter = `Filter`,
  Toggle = `Toggle`,
  Clear = `Clear`,
}

export enum SearchFilterObjectTypes {
  List = `List`,
  Select = `Select`,
  Gateways = `Gateways`,
  Checkbox = `Checkbox`,
  SelectAllChecks = `Select All Checks`,
}

export enum GroupSelectorFilterTypes {
  NONE = `( None )`,
  GATEWAY = `Gateway`,
  UNIT_TYPE = `Unit Type`,
  LOCATION_AREA = `Location / Area`,
}

export enum PageState {
  Enter = 1,
  Leave
}

export enum LevelEnum {
  Unknown = -1,
  NotAMember = 0,
  Viewer = 1,
  User = 2,
  Administrator = 3,
  Owner = 4,
}

export enum SiteNotificationTypeEnum {
  Global = 0,
  Membership = 1,
  Subscription = 2,
  Payments = 3,
  Gateway = 4,
  Equipment = 5,
  EquipmentNotification = 6,
}

export enum SiteNotificationTypeStringsEnum {
  Global = `Global`,
  Membership = `Membership`,
  Subscription = `Subscription`,
  Payments = `Payments`,
  Gateway = `Gateway`,
  Equipment = `Equipment`,
}

export enum GatewayRegistrationStepEnum {
  InformationStep = 1,
  GatewayStep = 2,
  SubscriptionStep = 3,
  PaymentAndRegisterStep = 4,
  ConfirmationStep = 5,
}

export enum GatewayChangeSubscriptionStepEnum {
  InformationStep = 0,
  GatewayStep = 1,
  SubscriptionStep = 2,
  PaymentAndRegisterStep = 3,
  ConfirmationStep = 4,
}

export enum GatewayChangePaymentMethodStepEnum {
  InformationStep = 0,
  GatewayStep = 1,
  ChangeStep = 2,
  SummaryStep = 3,
}

export enum GatewayDecommissionStepEnum {
  InformationStep = 0,
  GatewayStep = 1,
  DecommissionStep = 2,
  SummaryStep = 3,
}

export enum GatewayStatusTypeEnum {
  Active = 1,
  InActive = 2,
  Released = 3,
}

export enum NavigationDirectionEnum {
  Previous = -1,
  Next = 1,
}

export enum PlanRowTypeEnum {
  PriceHeader = 1,
  FeatureCategory = 2,
  FeatureDetail = 3,
}

export enum SiteGatewayViewEnum {
  GatewayListView = 1,
  GatewayDetailView = 2,
  GatewayDetailView2 = 3,
}

export enum SiteMemberViewEnum {
  SiteOwner = 0,
  SiteMemberListView = 1,
  SiteMemberDetailView = 2
}

export enum GatewayUnitTypeEnum {
  OutdoorUnit = 1,
  IndoorUnit = 2,
  Lossnay = 3,
  Other = 4
}

export enum GatewayUnitTwoDigitType {
  OutdoorUnit = 'OU',
  IndoorUnit = 'IU',
  Lossnay = 'LC',
  MelshiHolder = 'MH',
  LossnayHolder = 'LH',
  OutdoorUnitSub = 'OS',
  BranchController = 'BC',
  BranchControllerSub = 'BS',
  RemoteController = 'RC',
  SystemController = 'TR',
  Gateway = 'GW',
  Hex = 'HEX',
  WaterBranchController = 'HB',
  WaterBranchControllerSub = 'HS',
  HydroUnit = 'HU',
}

export enum GroupType {
  IU = `Indoor Coils`,
  LC = `Lossnays`,
}

export enum GatewayModels {
  MCC = `1`,
  RMD = `2`
}

export enum ModesSpeedsDirections {
  on = `on`,
  off = `off`,
  fan = `fan`,
  dry = `dry`,
  low = `low`, 
  one = `one`, 
  two = `two`, 
  four = `four`, 
  cool = `cool`,
  zero = `zero`,
  heat = `heat`,
  none = `none`,
  auto = `auto`,
  high = `high`,
  unknown = `--`,
  three = `three`,
  floor = `floor`,
  swing = `swing`,
  energy = `energy`,
  medium = `medium`, 
  middle = `middle`,
  bypass = `bypass`,
  setback = `setback`,
  ceiling = `ceiling`,
  very_low = `very low`,
  disabled = `disabled`,
  nochange = `no change`,
  very_high = `very high`,
  dual_auto = `dual_auto`,
  auto_cool = `auto_cool`,
  auto_heat = `auto_heat`,
  stationary = `stationary`,
  undetected = `undetected`,
  setback_cool = `setback_cool`,
  setback_heat = `setback_heat`,
  dual_setpoint = `dual_setpoint`,
  heat_recovery = `heat_recovery`,
  heating_cooling = `heating_cooling`,
  energy_recovery = `energy_recovery`,
  heat_recovery_with_spaces = `heat recovery`,
  energy_recovery_with_spaces = `energy recovery`,
}

export enum MaskTypes {
  phone = `Phone`,
  company_phone = `Company Phone`,
  zip = `Zip Code`,
  zipCode = `Zip Code`,
  zip_code = `Zip Code`,
  [`zip-code`] = `Zip Code`,
  postal = `Zip Code`,
  postalCode = `Zip Code`,
  postal_code = `Zip Code`,
  [`postal-code`] = `Zip Code`,
  serial_number = `Serial Number`,
  mcc_serial_number = `Serial Number`,
  rmd_serial_number = `Serial Number`,
  [`serial-number`] = `Serial Number`,
  mac_address = `MAC Address`,
  rmd_mac_address = `MAC Address`,
}

export const GatewayUnitTwoDigitTypeToEnum: { [type: string]: GatewayUnitTwoDigitType } = {}
GatewayUnitTwoDigitTypeToEnum.OU = GatewayUnitTwoDigitType.OutdoorUnit;
GatewayUnitTwoDigitTypeToEnum.IU = GatewayUnitTwoDigitType.IndoorUnit;
GatewayUnitTwoDigitTypeToEnum.LC = GatewayUnitTwoDigitType.Lossnay;
GatewayUnitTwoDigitTypeToEnum.MH = GatewayUnitTwoDigitType.MelshiHolder;
GatewayUnitTwoDigitTypeToEnum.LH = GatewayUnitTwoDigitType.LossnayHolder;

export const GatewayUnitTwoDigitTypeToName: { [type: string]: string } = {}
GatewayUnitTwoDigitTypeToName.LC = "lossnay unit";
GatewayUnitTwoDigitTypeToName.IU = "indoor unit";
GatewayUnitTwoDigitTypeToName.OU = "outdoor unit";
GatewayUnitTwoDigitTypeToName.MH = "outdoor unit";
GatewayUnitTwoDigitTypeToName.LH = "lossnay unit"

export enum WebSocketResponseTypeEnum {
  Connect = 1,
  Validation_Error = 2,
  Connection_Dropped = 3,
  Insufficient_Privileges = 4,
  Map_Gateway_Complete = 5,
  GraphDataComplete = 6,
  Profile_Update = 7,
  Error = 8,
  Authenticated = 9,
  Authentication_Expired = 10,
  //  CSV_Complete = 11,
  GIF_Ready = 12,
  GIF_Complete = 13,
  GIF_Error = 14,
  Insufficient_Subscription = 15,
  Test_Run_Complete = 16,
  MTDZ_Start = 17,
  MTDZ_Ready = 18,
  MTDZ_Progress = 19,
  MTDZ_Complete = 20,
  MTDZ_Error = 21,
  Test_Run_Error = 22,
  Test_Run_Status = 23,
  Test_Run_Cancel = 24,
  Maint_Job_Complete = 25,
  Maint_Job_Error = 26,
  Maint_Update_Unit_Complete = 27,
  Maint_Update_Unit_Error = 28,
  Maint_Get_MTDZ_Complete = 29,
  Maint_Get_MTDZ_Error = 30,
  Map_Gateway_Progress = 31,
  Map_Gateway_Started = 32,
  Map_Gateway_Error = 33,
  Receive_Create_Alert_Dump = 34,
  Receive_Result_Create_Alert_Dump = 35,
  System_Monitor_Complete = 36,
  System_Monitor_Error = 37,
  Test_Run_Progress = 38,
}

export enum EnableDisableEnum {
  Disable = 0,
  Enable = 1,
}

export enum ToastMessageTypeEnum {
  Error = 1,
  Info = 2,
  Success = 3,
  Warning = 4,
}

export enum MccRegistrationStatusEnum {
  INPROGRESS = 1,
  FAILED = 2,
  READY = 3,
}

export class GatewayModelClass {
  static readonly RMD = "RMD";
  static readonly MCC = "MCC";
}

export enum SelectionTypeEnum {
  Selected = 1,
  UnSelected = 2,
}

export enum CognitoUserPoolCustomAttributeEnum {
  KENZA_STATUS = "kenza_status",
}

export enum OduGraphDataTypes {
  HS63 = 1,
  LS63 = 2,
  TH4 = 3,
  F = 4,
  FAN = 5,
  OATemp = 6,
}

export enum CognitoUserPoolCustomAttributeValueEnum {
  ACCOUNT_CANCELLED = "account_cancelled",
}

export const CognitoKenzaCustomAttributeStatus= `custom:${CognitoUserPoolCustomAttributeEnum.KENZA_STATUS}`

export enum CognitoAuthEventNameEnum {
  // possible events names from the aws-amplify Hub listen event handler
  signIn = 'signIn',
  configured = 'configured',
  signin_failure = 'signIn_failure',
  signUp = 'signUp',
  signUp_failure = 'signUp_failure',
  confirmSignUp = 'confirmSignUp',
  completeNewPassword_failure = 'completeNewPassword_failure',
  autoSignIn = 'autoSignIn',
  autoSignIn_failure = 'autoSignIn_failure',
  forgotPassword = 'forgotPassword',
  forgotPassword_failure = 'forgotPassword_failure',
  forgotPasswordSubmit = 'forgotPasswordSubmit',
  forgotPasswordSubmit_failure = 'forgotPasswordSubmit_failure',
  tokenRefresh = 'tokenRefresh',
  tokenRefresh_failure = 'tokenRefresh_failure',
  cognitoHostedUI = 'cognitoHostedUI',
  cognitoHostedUI_failure = 'cognitoHostedUI_failure',
  customOAuthState = 'customOAuthState',
  customState_failure = 'customState_failure',
  parsingCallbackUrl = 'parsingCallbackUrl',
  userDeleted = 'userDeleted',
  signOut = 'signOut'
}

export enum DriveDataDownloadStateEnum {
  // possible states of the equipment alert drive download feature
  ReadyToGenerate = 1,
  RequestedGeneration = 2,
  Generating = 3,
  ReadyToDownload = 4,
  RequestedDownload = 5,
  Failed = 6
}

export enum ServerDriveDataDownloadStatusEnum {
  // these values must match the server value for DriveDataStatus
  Error = -1,
  Generating = 0,
  Starting = 1,
  Active = 2,
  Ready = 3
}

export enum HttpStatusCode {

  /**
   * The server has received the request headers and the client should proceed to send the request body
   * (in the case of a request for which a body needs to be sent; for example, a POST request).
   * Sending a large request body to a server after a request has been rejected for inappropriate headers would be inefficient.
   * To have a server check the request's headers, a client must send Expect: 100-continue as a header in its initial request
   * and receive a 100 Continue status code in response before sending the body. The response 417 Expectation Failed indicates the request should not be continued.
   */
  CONTINUE = 100,

  /**
   * The requester has asked the server to switch protocols and the server has agreed to do so.
   */
  SWITCHING_PROTOCOLS = 101,

  /**
   * A WebDAV request may contain many sub-requests involving file operations, requiring a long time to complete the request.
   * This code indicates that the server has received and is processing the request, but no response is available yet.
   * This prevents the client from timing out and assuming the request was lost.
   */
  PROCESSING = 102,

  /**
   * Standard response for successful HTTP requests.
   * The actual response will depend on the request method used.
   * In a GET request, the response will contain an entity corresponding to the requested resource.
   * In a POST request, the response will contain an entity describing or containing the result of the action.
   */
  OK = 200,

  /**
   * The request has been fulfilled, resulting in the creation of a new resource.
   */
  CREATED = 201,

  /**
   * The request has been accepted for processing, but the processing has not been completed.
   * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
   */
  ACCEPTED = 202,

  /**
   * SINCE HTTP/1.1
   * The server is a transforming proxy that received a 200 OK from its origin,
   * but is returning a modified version of the origin's response.
   */
  NON_AUTHORITATIVE_INFORMATION = 203,

  /**
   * The server successfully processed the request and is not returning any content.
   */
  NO_CONTENT = 204,

  /**
   * The server successfully processed the request, but is not returning any content.
   * Unlike a 204 response, this response requires that the requester reset the document view.
   */
  RESET_CONTENT = 205,

  /**
   * The server is delivering only part of the resource (byte serving) due to a range header sent by the client.
   * The range header is used by HTTP clients to enable resuming of interrupted downloads,
   * or split a download into multiple simultaneous streams.
   */
  PARTIAL_CONTENT = 206,

  /**
   * The message body that follows is an XML message and can contain a number of separate response codes,
   * depending on how many sub-requests were made.
   */
  MULTI_STATUS = 207,

  /**
   * The members of a DAV binding have already been enumerated in a preceding part of the (multistatus) response,
   * and are not being included again.
   */
  ALREADY_REPORTED = 208,

  /**
   * The server has fulfilled a request for the resource,
   * and the response is a representation of the result of one or more instance-manipulations applied to the current instance.
   */
  IM_USED = 226,

  /**
   * Indicates multiple options for the resource from which the client may choose (via agent-driven content negotiation).
   * For example, this code could be used to present multiple video format options,
   * to list files with different filename extensions, or to suggest word-sense disambiguation.
   */
  MULTIPLE_CHOICES = 300,

  /**
   * This and all future requests should be directed to the given URI.
   */
  MOVED_PERMANENTLY = 301,

  /**
   * This is an example of industry practice contradicting the standard.
   * The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect
   * (the original describing phrase was "Moved Temporarily"), but popular browsers implemented 302
   * with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307
   * to distinguish between the two behaviours. However, some Web applications and frameworks
   * use the 302 status code as if it were the 303.
   */
  FOUND = 302,

  /**
   * SINCE HTTP/1.1
   * The response to the request can be found under another URI using a GET method.
   * When received in response to a POST (or PUT/DELETE), the client should presume that
   * the server has received the data and should issue a redirect with a separate GET message.
   */
  SEE_OTHER = 303,

  /**
   * Indicates that the resource has not been modified since the version specified by the request headers If-Modified-Since or If-None-Match.
   * In such case, there is no need to retransmit the resource since the client still has a previously-downloaded copy.
   */
  NOT_MODIFIED = 304,

  /**
   * SINCE HTTP/1.1
   * The requested resource is available only through a proxy, the address for which is provided in the response.
   * Many HTTP clients (such as Mozilla and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.
   */
  USE_PROXY = 305,

  /**
   * No longer used. Originally meant "Subsequent requests should use the specified proxy."
   */
  SWITCH_PROXY = 306,

  /**
   * SINCE HTTP/1.1
   * In this case, the request should be repeated with another URI; however, future requests should still use the original URI.
   * In contrast to how 302 was historically implemented, the request method is not allowed to be changed when reissuing the original request.
   * For example, a POST request should be repeated using another POST request.
   */
  TEMPORARY_REDIRECT = 307,

  /**
   * The request and all future requests should be repeated using another URI.
   * 307 and 308 parallel the behaviors of 302 and 301, but do not allow the HTTP method to change.
   * So, for example, submitting a form to a permanently redirected resource may continue smoothly.
   */
  PERMANENT_REDIRECT = 308,

  /**
   * The server cannot or will not process the request due to an apparent client error
   * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
   */
  BAD_REQUEST = 400,

  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
   * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
   * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
   * "unauthenticated",i.e. the user does not have the necessary credentials.
   */
  UNAUTHORIZED = 401,

  /**
   * Reserved for future use. The original intention was that this code might be used as part of some form of digital
   * cash or micro payment scheme, but that has not happened, and this code is not usually used.
   * Google Developers API uses this status if a particular developer has exceeded the daily limit on requests.
   */
  PAYMENT_REQUIRED = 402,

  /**
   * The request was valid, but the server is refusing action.
   * The user might not have the necessary permissions for a resource.
   */
  FORBIDDEN = 403,

  /**
   * The requested resource could not be found but may be available in the future.
   * Subsequent requests by the client are permissible.
   */
  NOT_FOUND = 404,

  /**
   * A request method is not supported for the requested resource;
   * for example, a GET request on a form that requires data to be presented via POST, or a PUT request on a read-only resource.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * The requested resource is capable of generating only content not acceptable according to the Accept headers sent in the request.
   */
  NOT_ACCEPTABLE = 406,

  /**
   * The client must first authenticate itself with the proxy.
   */
  PROXY_AUTHENTICATION_REQUIRED = 407,

  /**
   * The server timed out waiting for the request.
   * According to HTTP specifications:
   * "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
   */
  REQUEST_TIMEOUT = 408,

  /**
   * Indicates that the request could not be processed because of conflict in the request,
   * such as an edit conflict between multiple simultaneous updates.
   */
  CONFLICT = 409,

  /**
   * Indicates that the resource requested is no longer available and will not be available again.
   * This should be used when a resource has been intentionally removed and the resource should be purged.
   * Upon receiving a 410 status code, the client should not request the resource in the future.
   * Clients such as search engines should remove the resource from their indices.
   * Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
   */
  GONE = 410,

  /**
   * The request did not specify the length of its content, which is required by the requested resource.
   */
  LENGTH_REQUIRED = 411,

  /**
   * The server does not meet one of the preconditions that the requester put on the request.
   */
  PRECONDITION_FAILED = 412,

  /**
   * The request is larger than the server is willing or able to process. Previously called "Request Entity Too Large".
   */
  PAYLOAD_TOO_LARGE = 413,

  /**
   * The URI provided was too long for the server to process. Often the result of too much data being encoded as a query-string of a GET request,
   * in which case it should be converted to a POST request.
   * Called "Request-URI Too Long" previously.
   */
  URI_TOO_LONG = 414,

  /**
   * The request entity has a media type which the server or resource does not support.
   * For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
   */
  UNSUPPORTED_MEDIA_TYPE = 415,

  /**
   * The client has asked for a portion of the file (byte serving), but the server cannot supply that portion.
   * For example, if the client asked for a part of the file that lies beyond the end of the file.
   * Called "Requested Range Not Satisfiable" previously.
   */
  RANGE_NOT_SATISFIABLE = 416,

  /**
   * The server cannot meet the requirements of the Expect request-header field.
   */
  EXPECTATION_FAILED = 417,

  /**
   * This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol,
   * and is not expected to be implemented by actual HTTP servers. The RFC specifies this code should be returned by
   * teapots requested to brew coffee. This HTTP status is used as an Easter egg in some websites, including Google.com.
   */
  I_AM_A_TEAPOT = 418,

  /**
   * The request was directed at a server that is not able to produce a response (for example because a connection reuse).
   */
  MISDIRECTED_REQUEST = 421,

  /**
   * The request was well-formed but was unable to be followed due to semantic errors.
   */
  UNPROCESSABLE_ENTITY = 422,

  /**
   * The resource that is being accessed is locked.
   */
  LOCKED = 423,

  /**
   * The request failed due to failure of a previous request (e.g., a PROPPATCH).
   */
  FAILED_DEPENDENCY = 424,

  /**
   * The client should switch to a different protocol such as TLS/1.0, given in the Upgrade header field.
   */
  UPGRADE_REQUIRED = 426,

  /**
   * The origin server requires the request to be conditional.
   * Intended to prevent "the 'lost update' problem, where a client
   * GETs a resource's state, modifies it, and PUTs it back to the server,
   * when meanwhile a third party has modified the state on the server, leading to a conflict."
   */
  PRECONDITION_REQUIRED = 428,

  /**
   * The user has sent too many requests in a given amount of time. Intended for use with rate-limiting schemes.
   */
  TOO_MANY_REQUESTS = 429,

  /**
   * The server is unwilling to process the request because either an individual header field,
   * or all the header fields collectively, are too large.
   */
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,

  /**
   * A server operator has received a legal demand to deny access to a resource or to a set of resources
   * that includes the requested resource. The code 451 was chosen as a reference to the novel Fahrenheit 451.
   */
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  /**
   * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
   * Usually this implies future availability (e.g., a new feature of a web-service API).
   */
  NOT_IMPLEMENTED = 501,

  /**
   * The server was acting as a gateway or proxy and received an invalid response from the upstream server.
   */
  BAD_GATEWAY = 502,

  /**
   * The server is currently unavailable (because it is overloaded or down for maintenance).
   * Generally, this is a temporary state.
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.
   */
  GATEWAY_TIMEOUT = 504,

  /**
   * The server does not support the HTTP protocol version used in the request
   */
  HTTP_VERSION_NOT_SUPPORTED = 505,

  /**
   * Transparent content negotiation for the request results in a circular reference.
   */
  VARIANT_ALSO_NEGOTIATES = 506,

  /**
   * The server is unable to store the representation needed to complete the request.
   */
  INSUFFICIENT_STORAGE = 507,

  /**
   * The server detected an infinite loop while processing the request.
   */
  LOOP_DETECTED = 508,

  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  NOT_EXTENDED = 510,

  /**
   * The client needs to authenticate to gain network access.
   * Intended for use by intercepting proxies used to control access to the network (e.g., "captive portals" used
   * to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).
   */
  NETWORK_AUTHENTICATION_REQUIRED = 511
}

export enum AppMessageTypeCode {
  APP_PERMISSIONS = 1,
  HTTPRESPONSE = 2,
  INVALID_INVITE = 3,
}

export enum AppMessageCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export enum PressurePreferenceEnum {
  Kilopascal = 1,
  PoundFourcePerSquareInch = 2,
}

export enum TemperaturePreferenceEnum {
  Celsius = 1,
  Fahrenheit = 2
}

export enum SubscriptionFeatures {
  ALERTS_AND_NOTIFICATIONS = "ALERTS_AND_NOTIFICATIONS",
  DATA_REPORTING = "DATA_REPORTING",
  GEN_SYSTEM_DIAGRAM = "GEN_SYSTEM_DIAGRAM",
  SYSTEM_MONITORING = "IDU_SYSTEM_MONITORING",
  SYSTEM_CONTROL = "IDU_SYSTEM_CONTROL",
  SYSTEM_GRAPHING = "SYSTEM_GRAPHING",
  SYSTEM_SCHEDULING = "SYSTEM_SCHEDULING",

  INCLUDED = "INCLUDED",
  NOT_INCLUDED = "NOT_INCLUDED"
}

export enum ApplicationErrorsEnum {
  WEBSOCKET_TIMEOUT = 1,
  WEBSOCKET_DISCONNECT = 2,
  WEBSOCKET_RECONNECT_ATTEMPT = 3,
  WEBSOCKET_NOT_CONNECTED = 4,
  MAPUNITS_ERROR = 5,
  MAPUNITS_WEBSOCKET_DISCONNECT = 6,
  FIRMWARE_UPDATE_FAILED_TO_START = 7,
  FIRMWARE_NEWEST_VERSION_ALREADY_INSTALLED = 8,
  COGNITO_ACCESS_ISSUES = 9,
}

export enum SiteStatusTypeEnum {
  Active = 1,
  Deactivated = 2,
}

export enum SiteControlGroupStatusEnum {
  OK,
  Warning, // WARNING is not currently used.
  Alert,
  Maintenance_Job_Active
}

export enum MaintenanceJobTypeEnum {
  // these relate to Server ReqeustType values
  None = 0,
  Default = 1,
  Test_Run = 2600,
  Incorrect_Port = 2610, // Branch Port Check
  Refrigerant = 2620, // Refrigeration Check
  Alert_Dump = 2630,
  Mapping_Units = 2100,
  // From enums.py in the Back End
  Monitoring = 2000,
  MTDZ_Unit = 2010,
  Cancel_Maintenance = 2700,
  // Get_Maintenance_Data = 2631,
  Download_Drive_Data = 2631,
  Rmd_Transmission_Mode = 2200,
  Start_Connect_Mode = 2201,
  Rmd_System_Monitor = 2020,
  Site_Transfer = 9999,
}

export enum MaintenanceJobTypeEnumLabels {
  None = ``,
  Default = `M`,
  Test_Run = ` TR `,
  Incorrect_Port = `BPC`, // Branch Port Check
  Refrigerant = `RC`, // Refrigeration Check
  Alert_Dump = ` AD `,
  Mapping_Units = `MU`,
  // Get_Maintenance_Data = `GMD`,
  Download_Drive_Data = `DDD`,
  Site_Transfer = `ST`,
}

export enum MaintenanceJobTypeEnumDescriptions {
  None = ``,
  Default = `This group is currently performing Maintenance.`,
  Test_Run = `This group is currently performing a Test Run.`,
  Incorrect_Port = `This group is currently is performing a Branch Port Check.`, // Branch Port Check
  Refrigerant = `This group is currently performing a Refrigerant Check.`, // Refrigeration Check
  Alert_Dump = `This group is curently performing an Alert Dump.`,
  Mapping_Units = `This group is curently performing a Map Units`,
  // Get_Maintenance_Data = `This group is curently performing a Get Maintenance Data`,
  Download_Drive_Data = `This group is curently performing a Download Drive Data`,
  Site_Transfer = `This group is curently performing a Site Transfer`,
}

export const MaintenanceJobTypeEnumTitle: { [type: number]: string } = {};
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.None] = ``;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Default] = `Maintenance`;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Test_Run] = `Test Run`;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Incorrect_Port] = `Branch Port Check`; // Branch Port Check
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Refrigerant] = `Refrigerant Check`; // Refrigeration Check
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Alert_Dump] = `Alert Dump`;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Mapping_Units] = `Mapping Units`;
// MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Get_Maintenance_Data] = `Getting Maintenance Data`;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Download_Drive_Data] = `Download Drive Data`;
MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Site_Transfer] = `Site Transfer`;

export enum GatewaySubscriptionStatusTypeEnum {
  Active = 1,
  Suspended = 2,
  Paused = 3,
  Cancelled = 4,
  Expired = 5
}

export enum maintenanceNotification {
  BCPORT,
  REFRIGERANT,
  REFRIGERANT_REPORT,
  NOTIFICATION_NUM
}

export enum MaintenanceNotificationType {
  START = `start`,
  SUSPEND = `suspend`,
  NOTIFY = `notify`,
  FINISH = `finish`,
  ERR_START = `err_start`,
  ERR_MFK = `err_mfk`,
  ERR = `err`,
  NOTIFICATION_NUM = `notification_num`
}

export enum SiteNotificationTypeActionEnum {
  Membership_Add = 1,
  Membership_Remove = 2,
  Membership_Reactivate = 3,
  Member_Level_Changed = 4,
  Member_Reactivated = 5,
  Gateway_Moved = 6,
  Gateway_Registered = 7,
  Equipment_Error = 8,
  Subscription_Changed = 9,
  Member_Left_Site = 10,
  Subscription_Forced_Change = 11,
  Payment_Method_Updated = 12,
  Gateway_Decommissioned = 13,
  Failed_Payment_Processing = 14,
  Payment_Method_Added = 15,
  Site_Transfered = 16,
  Limited_Subscription_Suspended = 17,
  Equipment_Life_Span_Diagnosis = 18,
  License_Activated = 19,
}