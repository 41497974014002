import { SiteNotificationTypeEnum, EnableDisableEnum, LevelEnum, SiteNotificationTypeStringsEnum, GroupSelectorFilterTypes, GroupType } from 'src/app/enumerations/enums';
import { SiteNotification, siteNotification_textSearch } from '../../../features/sites/components/site-alerts/classes/sitenotification';
import { SelectedGroup, TrackedGroup } from '../../components/group-selector/group-selector.component';
import { PopoverSize, PositionAlign, PositionReference, PositionSide } from '@ionic/core';
import { AppAuthenticationService } from '../authentication/app-authentication.service';
import { defaultTimeZoneName, devEnv } from 'src/app/constants/kenzaconstants';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { AppStorageService } from '../storage/app-storage.service';
import { Account } from 'src/app/features/account/classes/account';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { SiteAlertMetricsData } from '../../classes/Metrics';
import { WebserviceURL } from 'src/app/constants/webservice';
import { SiteAlertFilter } from '../../classes/filters';
import { HttpClient } from '@angular/common/http';
import { AnimationBuilder } from '@ionic/angular';
import { Subscription } from 'rxjs';

declare global {
  // interface Notification {
  //   sitenotificationtype_id: number;
  //   acknowledgeable: boolean;
  //   acknowledged_at: any;
  //   expandable?: boolean;
  //   resettable: boolean;
  //   notification?: any;
  //   viewable: boolean;
  //   created_at: any;
  //   type: string;
  //   date?: any;
  //   id: any;
  // }
  interface PopoverOptions {
    component: any;
    componentProps?: { [key: string]: any };
    showBackdrop?: boolean;
    backdropDismiss?: boolean;
    translucent?: boolean;
    cssClass?: string | string[];
    event?: Event;
    animated?: boolean;

    mode?: 'ios' | 'md';
    keyboardClose?: boolean;
    id?: string;
    htmlAttributes?: { [key: string]: any };

    enterAnimation?: AnimationBuilder;
    leaveAnimation?: AnimationBuilder;

    size?: PopoverSize;
    dismissOnSelect?: boolean;
    reference?: PositionReference;
    side?: PositionSide;
    alignment?: PositionAlign;
    arrow?: boolean;
  }
}

@Injectable({
  providedIn: 'root'
})

export class MainSiteUIService {
  searchText: string;
  applyCustomFilter = false;
  notificationPaginationKey: string;
  siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
  customSiteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
  onNewGroupsSelected: Subscription = null;
  @Output() newGroupsSelected = new EventEmitter<SelectedGroup[]>();
  siteAlertMetricsData: SiteAlertMetricsData = new SiteAlertMetricsData();
  refreshSiteAlertDataEmitter = new EventEmitter<SiteAlertMetricsData>();
  viewAccountDetailEmitter = new EventEmitter();
  viewMemberAccountDetailEmitter = new EventEmitter();
  viewSiteAlertEmitter = new EventEmitter<string>();
  viewGatewaySubscriptionPlansEmitter = new EventEmitter();
  viewAccountPaymentHistoryEmitter = new EventEmitter();
  viewAccountPaymentMethodsEmitter = new EventEmitter();
  disableLeftNavigationEmitter = new EventEmitter<EnableDisableEnum>();
  generalNavChangeEmitter = new EventEmitter();
  applyCustomSiteAlertFilterEmitter = new EventEmitter<SiteAlertFilter>();
  viewMemberDetailsEmitter = new EventEmitter<string>();
  showLeftNav = true;
  schedulingCalendarWeeklyView = true;
  
  devEnv = devEnv;
  customFilter = null;
  notificationsLoaded = false;
  notificationsLoading = false;
  notifDateFormat = `MM/DD/YYYY, hh:mm A`;
  notificationTypes: any = [];
  notifTypesFromEnumFull = this.notificationTypes.concat(Object.values(SiteNotificationTypeEnum).filter(val => typeof val == `string`));
  notifTypesFromEnum = this.notifTypesFromEnumFull;
  globalNotificationsEmitter = new EventEmitter();
  startingNotificationTotal = 0;
  globalNotifications: any[] = [];
  sitesWithNotifications = [];
  alertFilters = [];
  notifications = [];
  serverNotifications = [];
  filteredNotifications = [];
  systemStatusMessage = ``;
  siteNames;
  siteNameFilters;
  uniqueSiteNames;
  userID = ``;
  sites = [];
  viewable = [];
  resettable = [];
  activeSites = [];
  activeFilters = [];
  acknowledgeable = [];
  totalNotifications = [];
  pageOfNotifications = [];
  resettableNotifications = [];
  public updatedNotifications = [];
  totalResettableNotifications = [];

  startIndex = 0;
  paginatedSets = [];
  notificationsResponse;
  notificationPageCount = 10;
  noMoreNotifications = false;
  filterWhatsOnScreen = false;
  clientSidePagination = false;
  notificationUpdateDelay = 30;
  notificationsScrolling = false;
  notificationsPaginateSets = true;
  defaultTimeZoneString: any = defaultTimeZoneName;
  databaseTimeFormat: any = `YYYY-MM-DD HH:mm:ss.SSS`;
  simpleTimeFormat: any = `hh:mm:ss A dddd MM/DD/YYYY`;
  endIndex = this.startIndex + this.notificationPageCount;

  // Placeholder Notifications
  defaultNotifications = [
    // {
    //   id: 1,
    //   notification: `<img src="../../../assets/imgs/logo.svg" /> <span translate="no"><span translate="no">kenza cloud</span></span> version 1.5 is releasing May 17th! New features include:<br><br><img src="../../../assets/imgs/notifications.svg" /> Global Notifications, <img src="../../../assets/icon/weatherIcons/colored/Rainy.svg" /> Local Weather for each Site, <img src="https://img.icons8.com/graph" /> Improved Graphing Capabilities, and more.`,
    //   created_at: new Date(new Date(Date.now() - 20 * 60 * 1000).toLocaleString(`en-US`, { year: `numeric`, month: `2-digit`, day: `2-digit`, hour: `2-digit`, minute: `2-digit`, hour12: true })).toISOString().replace(`Z`, ``).replace(`T`, ` `),
    //   type: this.notifTypesFromEnum[0],
    //   sitenotificationtype_id: 0,
    //   acknowledgeable: false,
    //   acknowledged_at: null,
    //   expandable: devEnv,
    //   resettable: false,
    //   viewable: false,
    // },
  ];

  // Search & Filter
  // List of groups
  public allSiteGroups: any[] = [];
  // List of groups filtererd by search bar
  public filteredSiteGroups: TrackedGroup[] = [];
  gwNames = [];
  searchTerm = ``;
  checkedBoxes = [];
  uniqueGwNames = [];
  activeGateways = [];
  gatewayFilters = [];
  uniqueGateways = [];
  activeLocations = [];
  totalFilters: number;
  GroupType = GroupType;
  activeGatewayIDs = [];
  siteGatewaysObject: any;
  filterIncludeIDU: boolean;
  temp1DualKnobs = false;
  temp2DualKnobs = false;
  multipleRangeSliders = false;
  filterIncludeLossnay: boolean;
  GroupSelectorFilterTypes = GroupSelectorFilterTypes;
  gsFilters = [
    {
      active: false,
      name: GroupSelectorFilterTypes.UNIT_TYPE,
      filters: [
        `Indoor Coils`,
        `Lossnays`
      ],
    },
  ];

  siteTransferAcceptanceOpen = false; // So it cannot be opened more than once when clicking fast
  siteSelectionOpen = false;

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    public appAuth: AppAuthenticationService,
    private appStorageService: AppStorageService,
  ) { 
    // Empty
  }

  isArrayOfStrings(array) {
    return array.every(item => typeof item === `string`);
  }

  isActiveFilter(filter, checkedBoxes, locations?: any, activeGatewayIDs?: any) {
    if (typeof filter == `string`) {
      if (!activeGatewayIDs) activeGatewayIDs = this.activeGatewayIDs;
      return activeGatewayIDs.length > 0 && activeGatewayIDs.includes(filter);
    } else if (filter?.gsFilter?.name == GroupSelectorFilterTypes.LOCATION_AREA) {
      if (!locations) locations = this.activeLocations || checkedBoxes;
      return locations.length > 0 && locations.includes(filter.itemFilter);
    } else {
      return checkedBoxes.length > 0 && checkedBoxes.includes(filter.itemFilter);
    }
  }

  renderFilterName(filter) {
    if (filter == GroupType.IU) {
      return `Indoor Coil / Group`;
    } else if (filter == GroupType.LC) {
      return `Lossnay`;
    } else {
      return filter;
    }
  }

  reverseFilterName(label) {
    if (label == `Indoor Coil / Group`) {
      return GroupType.IU;
    } else if (label == `Lossnay`) {
      return GroupType.LC;
    } else {
      return label;
    }
  }

  clearAllCheckBoxFilters(e?) {
    this.checkedBoxes = [];
    this.activeGateways = [];
    this.activeLocations = [];
    this.activeGatewayIDs = [];

    this.gsFilters = this.gsFilters.map(gsFil => {
      gsFil.active = false;
      return gsFil;
    });

    this.filterIncludeIDU = false;
    this.filterIncludeLossnay = false;
  }

  setCheckedBoxes(gsFilterCheckBoxes) {
    this.activeGateways = [];
    this.activeLocations = [];
    this.activeGatewayIDs = [];
    let updatedCheckedBoxes = gsFilterCheckBoxes.length > 0 ? gsFilterCheckBoxes.map((checkbox: any) => {
      if (checkbox.checked == true || checkbox.el.checked == true || checkbox.checked === true || checkbox.el.checked === true) {
        let categoryOfCheckBoxGroup = checkbox.el.parentElement.parentElement.firstChild.innerText;
        let labelNextToCheckBox = this.reverseFilterName(checkbox.el.innerText);
        if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.LOCATION_AREA) {
          this.activeLocations.push(labelNextToCheckBox);
        } else if (categoryOfCheckBoxGroup == GroupSelectorFilterTypes.GATEWAY) {
          let id = checkbox.el.value;
          this.activeGateways.push(labelNextToCheckBox);
          this.activeGatewayIDs.push(id);
        }
        return labelNextToCheckBox;
      }
    }).filter(val => val != `` && val != undefined && val != null) : [];
    return updatedCheckedBoxes;
  }

  getUniqueGateways(gateways, expand = false) {
    let uniqueGateways = gateways.reduce((acc, current) => {
      if (!acc.some(item => item.id === current.id)) acc.push(current);
      return acc;
    }, []);
    if (expand == true) {
      uniqueGateways = uniqueGateways.map((gw, gwIndex) => {
        if (gwIndex + 1 == uniqueGateways.length) { // Expand Last One By Default
          gw.expanded = true;
          return gw;
        } else {
          return gw;
        }
      })
    }
    return uniqueGateways;
  }

  refreshBadgeCounts(expand = false) {
    let gwCount = {};
    let gwNames = [];
    let gws = [];

    // console.log(`All Site Groups`, this.allSiteGroups);

    gws = this.allSiteGroups.map(grp => {
      return {
        active: false,
        expanded: false,
        id: grp?.gateway?.id,
        name: grp?.gateway?.name, 
        filter: grp?.gateway?.name,
        groups: grp?.gateway?.groups,
        itemFilter: grp?.gateway?.name, 
        model: grp?.gateway?.model?.class_name,
        category: GroupSelectorFilterTypes.GATEWAY,
        expired: grp?.isGatewaySubscriptionExpired(),
        connected: grp?.gateway?.connection?.connected == `true`,
        subscription_features: grp?.gateway?.subscription_features,
        // connected: JSON.parse(grp?.gateway?.connection?.connected),
        location: grp?.gatewayGroup?.units[0].location == `` ? GroupSelectorFilterTypes.NONE : grp?.gatewayGroup?.units[0].location,
      };
    }).sort((gwA, gwB) => gwB?.groups?.length - gwA?.groups?.length);

    let gatewaysAreRendered = this.uniqueGateways && this.uniqueGateways.length > 0 && this.uniqueGateways.some(gw => gw.expanded);
    if (gatewaysAreRendered == false) {
      this.uniqueGateways = this.getUniqueGateways(gws, expand);
    }

    gwNames = this.allSiteGroups.map(grp => grp?.gateway?.name);
    gwNames.forEach(function (i) { gwCount[i] = (gwCount[i] || 0) + 1; });

    this.gwNames = gwNames.sort(function (a, b) {
      return gwCount[b] - gwCount[a];
    });

    this.uniqueGwNames = [...new Set(this.gwNames)].filter(gwName => gwName != `` && gwName != null && gwName != undefined);
    this.gatewayFilters = this.uniqueGwNames.map(gwName => {
      return { gateway: gwName, length: this.gwNames.filter(name => name == gwName).length }
    });
  }
  
  setGatewayFilters() {
    this.refreshBadgeCounts();
    this.getGatewayFiltersFromGateways();
  }

  getGatewayFiltersFromGateways() {
    if (this.gsFilters.filter(fil => fil?.name == GroupSelectorFilterTypes.GATEWAY).length == 0) {
      this.gsFilters.push({
        name: GroupSelectorFilterTypes.GATEWAY,
        filters: this.uniqueGateways,
        active: false,
      });
    }
  }

  getLocationFiltersFromGateways(gateways) {
    let cleanLocations = [GroupSelectorFilterTypes.NONE];

    let locs: any = [];
    gateways.gateways.forEach(gw => {
      let { groups } = gw;
      let unitLocs = [...new Set((groups.map(grop => grop.units.map(unt => unt.location).filter(val => val != undefined && val != `` && val != null)).filter(arr => arr.length != 0) as any).flat())];
      locs.push(unitLocs);
    });

    let locations: any = [...new Set(locs.flat())];
    if (locations.length > 0) cleanLocations = cleanLocations.concat(locations);

    if (this.gsFilters.find(fil => fil?.name == GroupSelectorFilterTypes.LOCATION_AREA)) {
      if (cleanLocations.length > 0) {
        this.gsFilters = this.gsFilters.map(gsFil => {
          if (gsFil.name == GroupSelectorFilterTypes.LOCATION_AREA) {
            return {
              ...gsFil,
              filters: cleanLocations,
            }
          } else {
            return gsFil;
          }
        })
      }
    } else {
      if (cleanLocations.length > 0) {
        this.gsFilters.push({
          name: GroupSelectorFilterTypes.LOCATION_AREA,
          filters: cleanLocations,
          active: false,
        })
      }
    }
  }

  setSystemStatusFromNotifications(arrayOfNotifications) {
    arrayOfNotifications.slice(0,3).forEach(notification => {
      let messageToShow = notification?.notification.includes(`<br>`) ? notification?.notification.replace(/<br>/g, ``) : notification?.notification;
      this.systemStatusMessage += `<span class="statusMessage textOverflow"><img src="../../../assets/imgs/logo.svg" /> ${messageToShow}</span>`;
    });
  }

  initializeNotifications(user) {
    let defaultNotifications = this.defaultNotifications;

    if (user && user.signedIn) {
      defaultNotifications = this.defaultNotifications.map(notification => {
        if (notification.sitenotificationtype_id != SiteNotificationTypeEnum.Global && notification.sitenotificationtype_id != SiteNotificationTypeEnum.Equipment) {
          return {
            ...notification,
            acknowledgeable: user && user.signedIn,
          };
        } else {
          return notification;
        }
      });

      user.retrievedSites.subscribe(sites => {
        if (sites.length > 0) {
          this.sites = sites;
        }
      });

      this.userID = user.id;
    };

    this.totalNotifications = this.serverNotifications && user ? defaultNotifications.concat(this.serverNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)))) : defaultNotifications;
    this.notifications = this.totalNotifications;
    this.filteredNotifications = user ? this.notifications : this.notifications.filter(notif => notif?.type == `Global`);

    this.setNotificationsUI(user, this.filterWhatsOnScreen && !this.noMoreNotifications);
  }

  setNotificationsUI(user?, filterWhatsOnScreen?, retrievedSites?, retrievedNotifications?) {
    this.notifications = this.notifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)));
    if (retrievedNotifications) {
      this.filteredNotifications = retrievedNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)));
    } else {
      this.filteredNotifications = this.filteredNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)));
    }

    let globals = this.getGlobalNotifications().length > 0 ? this.getGlobalNotifications() : (JSON.parse(localStorage.getItem(`globalNotifications`)) || []);
    this.setSystemStatusFromNotifications(globals);
    
    if (user && user.signedIn) {
      user.retrievedSites.subscribe(sites => {
        if (sites.length > 0) {
          let sitePerms = sites.map(sit => sit.permission.filter(perm => perm?.account_id == user.id && perm?.removed_at == null)[0]);
          if (sitePerms.some(perm => perm?.level >= LevelEnum.Owner)) {
            this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription);
          } else {
            this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription && filt != SiteNotificationTypeStringsEnum.Payments);
          }
        } else {
          this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription && filt != SiteNotificationTypeStringsEnum.Payments);
        }
      })
    } else {
      if (retrievedSites && retrievedSites.length > 0) {
        let sitePerms = retrievedSites.map(sit => sit.permission.filter(perm => perm?.account_id == this.userID && perm?.removed_at == null)[0]);
        if (sitePerms.some(perm => perm?.level >= LevelEnum.Owner)) {
          this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription);
        } else {
          this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription && filt != SiteNotificationTypeStringsEnum.Payments);
        }
      } else {
        this.notifTypesFromEnum = this.notifTypesFromEnumFull.filter(filt => filt != SiteNotificationTypeStringsEnum.Subscription && filt != SiteNotificationTypeStringsEnum.Payments);
      }
    };

    this.filteredNotifications = this.activeFilters.length > 0 ? this.filteredNotifications.filter(notif => this.activeFilters.includes(notif?.type)) : this.filteredNotifications;

    this.filteredNotifications = this.activeSites.length > 0 ? this.filteredNotifications.filter(notif => this.activeSites.includes(notif?.site_name)) : this.filteredNotifications;

    // if (filterWhatsOnScreen && this.notificationsPaginateSets && !this.noMoreNotifications) {
    //   this.filteredNotifications = this.filteredNotifications.slice(0, this.notificationPageCount);
    // }

    this.getSiteNameFilters();
    this.checkResettable(filterWhatsOnScreen || this.activeFilters.length > 0);
  }

  getNotifications() { return this.filteredNotifications; };

  getNotification(id) { return this.filteredNotifications.filter(notif => notif?.id == id)[0]; };
  
  getPaymentNotifications() { return this.notifications.filter(notif => notif?.sitenotificationtype_id == SiteNotificationTypeEnum.Payments); };

  setFilteredAlerts(siteAlertFilter?: any) {
    this.customFilter = siteAlertFilter;
  }

  withinXUnits(X, smallerArray, largerArray) {
    return Math.abs(smallerArray.length - largerArray.length) <= X;
  }

  getGlobalNotifications() {
    return this.notifications.filter(notif => notif?.type == `Global`);
  }

  getSiteNotifications() {
    return this.totalNotifications.filter(notif => notif?.type != `Global`);
  }

  emit_globalNotifications(globalNotifications?: any) {
    this.filteredNotifications = globalNotifications;
    this.globalNotificationsEmitter.emit(globalNotifications);
  }

  dismissNotification(notification?, user?) {
    this.totalNotifications = this.totalNotifications.filter(notif => notif?.id != notification?.id);
    this.notifications = this.totalNotifications;
    this.filteredNotifications = this.activeFilters.length > 0 ? this.notifications.filter(notif => this.activeFilters.includes(notif?.type)) : this.notifications;
    this.setNotificationsUI(user);
  }

  clearNotifications() {
    this.serverNotifications = [];
    this.globalNotifications = [];
    this.totalNotifications = [];
    this.notifications = [];
    this.filteredNotifications = [];
    this.acknowledgeable = [];
    this.resettable = [];
    this.viewable = [];
    this.resettableNotifications = [];
    this.totalResettableNotifications = [];
    let defaultNotifications = this.defaultNotifications;
    this.totalNotifications = defaultNotifications.map(notif => {
      return {
        ...notif,
        acknowledgeable: false,
      }
    });
    this.notifications = this.totalNotifications;
    this.filteredNotifications = this.notifications;
    this.setNotificationsUI();
  }

  getSiteNameFilters() {
    let siteCount = {};
    let siteNames = [];
    if (this.filterWhatsOnScreen) {
      siteNames = this.filteredNotifications.filter(notif => notif?.type != `Global`).map(notif => notif?.site_name);
    } else {
      siteNames = this.totalNotifications.filter(notif => notif?.type != `Global`).map(notif => notif?.site_name);
    }
    siteNames.forEach(function (i) { siteCount[i] = (siteCount[i] || 0) + 1; });
    this.siteNames = siteNames.sort(function (a, b) {
      return siteCount[b] - siteCount[a];
    });
    this.uniqueSiteNames = [...new Set(this.siteNames)];
    this.siteNameFilters = this.uniqueSiteNames.map(siteFilter => {
      return { site: siteFilter, alerts: this.siteNames.filter(name => name == siteFilter).length }
    });
  }

  getResetAuth = (notification) => {
    switch (notification.sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Membership:
        return this.appAuth.permissionEnums.AckMemberNotification;
      case SiteNotificationTypeEnum.Payments:
        return this.appAuth.permissionEnums.AckPaymentNotification;
      case SiteNotificationTypeEnum.Gateway:
        return this.appAuth.permissionEnums.AckGatewayMove;
      case SiteNotificationTypeEnum.Equipment:
        return this.appAuth.permissionEnums.ResetEquipmentAlert;
      case SiteNotificationTypeEnum.EquipmentNotification:
        return this.appAuth.permissionEnums.AckEquipmentAlert;        
    }
  }

  getViewAuth = (notification) => {
    switch (notification.sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Membership:
        return this.appAuth.permissionEnums.ViewMemberNotification;
      case SiteNotificationTypeEnum.Payments:
        return this.appAuth.permissionEnums.ViewPaymentNotification;
      case SiteNotificationTypeEnum.Gateway:
        return this.appAuth.permissionEnums.ViewGatewayNotification;
      case SiteNotificationTypeEnum.Equipment:
        return this.appAuth.permissionEnums.ViewEquipmentAlert;
      case SiteNotificationTypeEnum.EquipmentNotification:
        return this.appAuth.permissionEnums.ViewEquipmentAlert;  
    }
  }

  timeFormat(date) {
    let dateObject = new Date(date + `Z`);
    let normalTimeFormat = dateObject.toLocaleString([], { year: `numeric`, month: `2-digit`, day: `2-digit`, hour: `2-digit`, minute: `2-digit` });
    return normalTimeFormat;
  }
  
  timeStampFormat(date) {
    let dateObject = new Date(date);
    let normalTimeFormat = dateObject.toLocaleString([], { hour: `2-digit`, minute: `2-digit`, second: `2-digit` });
    return normalTimeFormat;
  }

  notificationIsAcknowledgeable(notification) {
    let notificationCanBeAcknowledged = false;
    let hasPermissionToResetOrAcknowledge = this.appAuth.doesLevelHavePermissionGlobal(notification?.permission_level, this.getResetAuth(notification));

    if (notification.sitenotificationtype_id == SiteNotificationTypeEnum.Global) {
      notificationCanBeAcknowledged = notification.acknowledgeable;
    } else {
      notificationCanBeAcknowledged = 
        (notification.sitenotificationtype_id != SiteNotificationTypeEnum.Global 
        && notification.sitenotificationtype_id != SiteNotificationTypeEnum.Equipment) 
        && hasPermissionToResetOrAcknowledge;
    }

    return notificationCanBeAcknowledged;
  }

  notificationIsResettable(notification) {
    let hasPermissionToResetOrAcknowledge = this.appAuth.doesLevelHavePermissionGlobal(notification?.permission_level, this.getResetAuth(notification));

    let notificationCanBeReset = 
      (notification?.sitenotificationtype_id != SiteNotificationTypeEnum.Global 
      && notification?.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) 
      && hasPermissionToResetOrAcknowledge;

    return notificationCanBeReset;
  }

  notificationIsViewable(notification) {
    let hasPermissionToView = this.appAuth.doesLevelHavePermissionGlobal(notification?.permission_level, this.getViewAuth(notification));
    let notificationCanBeViewed = notification?.sitenotificationtype_id != SiteNotificationTypeEnum.Global && hasPermissionToView;

    return notificationCanBeViewed;
  }

  mapNotifications(notifications, siteID?) {
    return notifications.map(notification => {
      return {
        ...notification,
        expandable: devEnv,
        date: this.timeFormat(notification.created_at),
        acknowledgeable: this.notificationIsAcknowledgeable(notification),
        resettable: this.notificationIsResettable(notification),
        viewable: this.notificationIsViewable(notification),
      }
    }).sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)));
  }

  checkForNotificationsUpdate(user, state, latestNotifications) {
    this.notificationsResponse = latestNotifications;
    // devEnv && console.log(`${state} Notifications at ${this.timeStampFormat(new Date())}`, this.notificationsResponse);

    const globalNotificationsMapped: any = latestNotifications.globalNotifications.map(gNotif => ({
      acknowledgeable: user && user.signedIn,
      notification: gNotif.notification,
      type: this.notifTypesFromEnum[0],
      created_at: gNotif.created_at,
      sitenotificationtype_id: 0,
      acknowledged_at: null,
      expandable: devEnv,
      resettable: false,
      viewable: false,
      id: gNotif.id,
      servicePortalData: {
        ...gNotif
      }
    }));

    let defaultNotifications = [...globalNotificationsMapped, ...this.defaultNotifications].map(notification => {
      if (notification.sitenotificationtype_id != SiteNotificationTypeEnum.Global && notification.sitenotificationtype_id != SiteNotificationTypeEnum.Equipment) {
        return {
          ...notification,
          acknowledgeable: user && user.signedIn,
        };
      } else {
        return notification;
      }
    });

    let latestAccountNotifications = latestNotifications ? latestNotifications?.accountNotifications?.notifications : [];

    if (latestNotifications && (latestAccountNotifications && (latestAccountNotifications?.length != this.getSiteNotifications().length)) || (latestNotifications?.globalNotifications?.length != this.getGlobalNotifications())) {
      
      this.notificationsLoading = true;
      
      this.notifications = [];
      this.paginatedSets = [];
      this.totalNotifications = [];
      this.serverNotifications = [];
      this.pageOfNotifications = [];
      this.filteredNotifications = [];

      defaultNotifications = this.mapNotifications(defaultNotifications);
      this.globalNotifications = defaultNotifications;
      this.setSystemStatusFromNotifications(defaultNotifications);
      if (latestAccountNotifications) this.serverNotifications = this.mapNotifications(latestAccountNotifications);

      this.totalNotifications = this.serverNotifications && user ? defaultNotifications.concat(this.serverNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)))) : defaultNotifications;

      this.notifications = this.serverNotifications && user ? defaultNotifications.concat(this.serverNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at))))  : defaultNotifications;

      this.pageOfNotifications = this.serverNotifications && user ? (this.clientSidePagination ? defaultNotifications.concat(this.serverNotifications.slice(this.startIndex, this.endIndex).sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at)))) : defaultNotifications.concat(this.serverNotifications.sort((a, b) => (<any>new Date(b.created_at)) - (<any>new Date(a.created_at))))) : defaultNotifications;

      if (this.clientSidePagination) {
        this.notifications = this.pageOfNotifications;
        this.filteredNotifications = user ? this.pageOfNotifications : this.pageOfNotifications.filter(notif => notif?.type == `Global`);
      } else {
        this.filteredNotifications = user ? this.notifications : this.notifications.filter(notif => notif?.type == `Global`);
      }

      this.setNotificationsUI(user, this.filterWhatsOnScreen && !this.noMoreNotifications);

      this.notificationsLoading = false;
      this.notificationsLoaded = true;
      return this.filteredNotifications;

    } else {
      // devEnv && console.log(`No Notification Update Needed`);
    };
  }

  checkResettable(filterWhatsOnScreen?) {
    this.viewable = [];
    this.resettable = [];
    this.acknowledgeable = [];
    // if (this.filterWhatsOnScreen || filterWhatsOnScreen || !this.noMoreNotifications) {
    if (this.filterWhatsOnScreen || filterWhatsOnScreen) {
      this.viewable = this.filteredNotifications.filter(notif => notif?.viewable);
      this.resettable = this.filteredNotifications.filter(notif => notif?.resettable);
      this.acknowledgeable = this.filteredNotifications.filter(notif => notif?.acknowledgeable);
      this.acknowledgeable = this.filteredNotifications.filter(notif => notif?.type == `Global` && !this.filteredNotifications.map(not => not.id).includes(notif?.id)).concat(this.acknowledgeable);
    } else {
      this.viewable = this.totalNotifications.filter(notif => notif?.viewable);
      this.resettable = this.totalNotifications.filter(notif => notif?.resettable);
      this.acknowledgeable = this.totalNotifications.filter(notif => notif?.acknowledgeable);
      this.acknowledgeable = this.totalNotifications.filter(notif => notif?.type == `Global` && !this.totalNotifications.map(not => not.id).includes(notif?.id)).concat(this.acknowledgeable);
    }
    this.resettableNotifications = this.resettable.concat(this.acknowledgeable);
    this.totalResettableNotifications = this.notifications.filter(notif => notif?.acknowledgeable).concat(this.notifications.filter(notif => notif?.type == `Global`)).concat(this.notifications.filter(notif => notif?.resettable));
  }

  clearSiteAlertData() {
    this.siteAlertMetricsData.clear();
    this.searchText = ``;
  }

  getSiteAlertFilter(): SiteAlertFilter {
    return this.siteAlertFilter;
  }

  async setSiteAlertFilter(siteAlertFilter: SiteAlertFilter) {
    this.siteAlertFilter = siteAlertFilter;
  }

  getCustomSiteAlertFilter(): SiteAlertFilter {
    return this.customSiteAlertFilter;
  }

  setCustomSiteAlertFilter(siteAlertFilter: SiteAlertFilter) {
    this.customSiteAlertFilter = siteAlertFilter;
  }

  async applyCustomSiteAlertFilter(siteAlertFilter: SiteAlertFilter) {
    this.applyCustomSiteAlertFilterEmitter.emit(siteAlertFilter);
  }

  getActiveSiteAccount(): Account {
    const local_storage_active_site_account = this.appStorageService.localStorageGetItem('ActiveSiteAccount');
    if (local_storage_active_site_account) {
      const active_site_account: Account = JSON.parse(local_storage_active_site_account);
      return active_site_account;
    }
    return null;
  }

  async refreshSiteAlertData(siteAlertFilter: SiteAlertFilter, e?) {
    // get site id from url as sometimes on refresh there is a race condition and things aren't set
    let siteId = siteAlertFilter.site_id;
    if (siteId == null || siteId == "" || siteId == undefined) {
      const hashSplit = window.location.hash.split("/");
      if (hashSplit.length > 3) {
        const hash = hashSplit[2];
        if (hash.split("-").length == 5) { //looks like a uuid
          siteId = hash;
        }
      }
    }

    // get notification/alert data from server
    this.getSiteAlertData(siteId).subscribe((siteNotifications: SiteNotification[]) => {

      // set the new site alert filter
      this.siteAlertFilter = siteAlertFilter;

      // populate the siteAlertMetricsData with updated values from the server.
      const siteAlertMetricsData: SiteAlertMetricsData = new SiteAlertMetricsData();

      // make sure we dont show any payment notifications to non-owners.
      if (siteAlertFilter.UserLevelEnum == LevelEnum.Unknown) {
        const active_site_account: Account = this.getActiveSiteAccount();

        if (active_site_account) {
          this.siteAlertFilter.UserLevelEnum = active_site_account.level;
        }
      }
      if (this.siteAlertFilter.UserLevelEnum !== LevelEnum.Owner) {
        siteNotifications = siteNotifications.filter(sn => sn.sitenotificationtype_id !== SiteNotificationTypeEnum.Payments);
      }

      // if there is anything left?      
      const v2RawTotalCount = siteNotifications.length;
      if (v2RawTotalCount > 0) {

        // calculate the filter booleans
        let showActive: boolean = this.siteAlertFilter.StatusActive;
        let showHistorical: boolean = this.siteAlertFilter.StatusHistorical;

        // special case where both are off - means both are on.
        if (!showActive && !showHistorical) {
          // then they are actually on.
          showActive = true;
          showHistorical = true;
        }

        // filter on type
        let alertEquipment: boolean = this.siteAlertFilter.AlertEquipment;
        let notificationMembership: boolean = this.siteAlertFilter.NotificationMembership;
        let notificationPayments: boolean = this.siteAlertFilter.UserLevelEnum === LevelEnum.Owner ? this.siteAlertFilter.NotificationPayments : false;
        let notificationGateway: boolean = this.siteAlertFilter.NotificationGateway;
        let notificationEquipment: boolean = this.siteAlertFilter.NotificationEquipment;

        // special case where all are off - means all are on.
        if (!alertEquipment && !notificationMembership && !notificationPayments && !notificationGateway && !notificationEquipment) {
          alertEquipment = true;
          notificationMembership = true;
          notificationPayments = true;
          notificationGateway = true;
          notificationEquipment = true;
        }

        // raw/full non-search reduced list & values
        const v2RawSiteNotifications = siteNotifications;
        // total raw counts - not reduced by text search
        let v2RawActSiteNotifications = v2RawSiteNotifications.filter(sn => !sn.acknowledged_at);
        v2RawActSiteNotifications = v2RawActSiteNotifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

        const v2RawTotalActCount = v2RawActSiteNotifications.length;
        const v2RawTotalAckCount = v2RawTotalCount - v2RawTotalActCount;

        const v2RawEquipmentActCount = v2RawActSiteNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length
        const v2RawMembershipActCount = v2RawActSiteNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Membership).length
        const v2RawGatewayActCount = v2RawActSiteNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway).length
        const v2RawPaymentActCount = v2RawActSiteNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Payments).length
        const v2RawEquipmentNotificationActCount = v2RawActSiteNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.EquipmentNotification).length

        // text search reduced list
        const v2SearchedSiteNotifications = this.searchSiteAlertData(siteAlertFilter, v2RawSiteNotifications);

        // of searched list -how many are active vs acked
        const v2TotalCount = v2SearchedSiteNotifications.length;
        const actNotifications = v2SearchedSiteNotifications.filter(sn => !sn.acknowledged_at);
        const ackNotifications = v2SearchedSiteNotifications.filter(sn => sn.acknowledged_at);

        const v2EquipmentActCount = actNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length
        const v2EquipmentAckCount = ackNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment).length
        const v2MembershipActCount = actNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Membership).length
        const v2MembershipAckCount = ackNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Membership).length
        const v2GatewayActCount = actNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway).length
        const v2GatewayAckCount = ackNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway).length
        const v2PaymentActCount = actNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Payments).length
        const v2PaymentAckCount = ackNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.Payments).length
        const v2EquipmentNotificationActCount = actNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.EquipmentNotification).length
        const v2EquipmentNotificationAckCount = ackNotifications.filter(sn => sn.sitenotificationtype_id == SiteNotificationTypeEnum.EquipmentNotification).length


        let v2ActiveCount = 0;
        if (alertEquipment) v2ActiveCount += v2EquipmentActCount;
        if (notificationGateway) v2ActiveCount += v2GatewayActCount;
        if (notificationMembership) v2ActiveCount += v2MembershipActCount;
        if (notificationPayments) v2ActiveCount += v2PaymentActCount;
        if (notificationEquipment) v2ActiveCount += v2EquipmentNotificationActCount;        

        let v2AckedCount = 0;
        if (alertEquipment) v2AckedCount += v2EquipmentAckCount;
        if (notificationGateway) v2AckedCount += v2GatewayAckCount;
        if (notificationMembership) v2AckedCount += v2MembershipAckCount;
        if (notificationPayments) v2AckedCount += v2PaymentAckCount;
        if (notificationEquipment) v2AckedCount += v2EquipmentNotificationAckCount;        

        let v2EquipmentCount = 0;
        if (showActive) v2EquipmentCount += v2EquipmentActCount;
        if (showHistorical) v2EquipmentCount += v2EquipmentAckCount;

        let v2MembershipCount = 0;
        if (showActive) v2MembershipCount += v2MembershipActCount;
        if (showHistorical) v2MembershipCount += v2MembershipAckCount;

        let v2GatewayCount = 0;
        if (showActive) v2GatewayCount += v2GatewayActCount;
        if (showHistorical) v2GatewayCount += v2GatewayAckCount;

        let v2PaymentCount = 0;
        if (showActive) v2PaymentCount += v2PaymentActCount;
        if (showHistorical) v2PaymentCount += v2PaymentAckCount;
        
        let v2EquipmentNotificationCount = 0;
        if (showActive) v2EquipmentNotificationCount += v2EquipmentNotificationActCount;
        if (showHistorical) v2EquipmentNotificationCount += v2EquipmentNotificationAckCount;

        let v2DisplaySiteNotifications = [];
        if (showActive) {
          for (const sn of actNotifications) {
            if (alertEquipment && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) v2DisplaySiteNotifications.push(sn)
            if (notificationGateway && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway) v2DisplaySiteNotifications.push(sn)
            if (notificationMembership && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Membership) v2DisplaySiteNotifications.push(sn)
            if (notificationPayments && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Payments) v2DisplaySiteNotifications.push(sn)
            if (notificationEquipment && sn.sitenotificationtype_id == SiteNotificationTypeEnum.EquipmentNotification) v2DisplaySiteNotifications.push(sn)            
          }
        }
        if (showHistorical) {
          for (const sn of ackNotifications) {
            if (alertEquipment && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) v2DisplaySiteNotifications.push(sn)
            if (notificationGateway && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway) v2DisplaySiteNotifications.push(sn)
            if (notificationMembership && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Membership) v2DisplaySiteNotifications.push(sn)
            if (notificationPayments && sn.sitenotificationtype_id == SiteNotificationTypeEnum.Payments) v2DisplaySiteNotifications.push(sn)
            if (notificationEquipment && sn.sitenotificationtype_id == SiteNotificationTypeEnum.EquipmentNotification) v2DisplaySiteNotifications.push(sn)            
          }
        }
        v2DisplaySiteNotifications = v2DisplaySiteNotifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        const v2DisplaySiteNotificationCount = v2DisplaySiteNotifications.length;

        // ByUnit/Gateway details
        const v2ActiveAlertsByUnits = new Map<string, number>();
        const v2ActiveNotificationsByGateways = new Map<string, number>();
        v2RawActSiteNotifications.forEach((sn) => {
          // only care about equipment and gateway here...
          if (sn.sitenotificationtype_id == SiteNotificationTypeEnum.Equipment) {
            // find the group and add it.
            let unit_id = '';
            if ('unit_id' in sn.context) unit_id = sn.context.unit_id;
            if (unit_id) {
              let val = 0;
              if (v2ActiveAlertsByUnits.has(unit_id)) {
                val = v2ActiveAlertsByUnits.get(unit_id);
              }
              val += 1;
              v2ActiveAlertsByUnits.set(unit_id, val);
            }
          } //else if (sn.sitenotificationtype_id == SiteNotificationTypeEnum.Gateway) {
          //   // find the gateway and add it.
          //   let serial_number = '';
          //   if ('serial_number' in sn.context) serial_number = sn.context.serial_number;
          //   if ('Serial Number' in sn.notification_labels) serial_number = sn.notification_labels['Serial Number'];
          //   if (serial_number != '') {
          //     let val = 0;
          //     if (v2ActiveNotificationsByGateways.has(serial_number)) {
          //       val = v2ActiveNotificationsByGateways.get(serial_number);
          //     }
          //     val += 1;
          //     v2ActiveNotificationsByGateways.set(serial_number, val);
          //   }
          // }
        })

        // now assign to the metrics data
        const params = {
          // full non-search reduced list
          v2RawSiteNotifications: v2RawSiteNotifications,
          v2RawActSiteNotifications: v2RawActSiteNotifications,
          // text search reduced list
          v2SiteNotifications: v2SearchedSiteNotifications,

          // total raw counts - not reduced by text search
          v2RawTotalCount: v2RawTotalCount,
          v2RawTotalActCount: v2RawTotalActCount,
          v2RawTotalAckCount: v2RawTotalAckCount,
          v2RawEquipmentActCount: v2RawEquipmentActCount,
          v2RawMembershipActCount: v2RawMembershipActCount,
          v2RawGatewayActCount: v2RawGatewayActCount,
          v2RawPaymentActCount: v2RawPaymentActCount,
          v2RawEquipmentNotificationActCount: v2RawEquipmentNotificationActCount,


          // v2ActiveCount is based on checked status
          v2TotalCount: v2TotalCount,
          v2ActiveCount: v2ActiveCount,
          v2AckedCount: v2AckedCount,

          // equipment type notifications split by status
          v2EquipmentActCount: v2EquipmentActCount,
          v2EquipmentAckCount: v2EquipmentAckCount,
          v2MembershipActCount: v2MembershipActCount,
          v2MembershipAckCount: v2MembershipAckCount,
          v2GatewayActCount: v2GatewayActCount,
          v2GatewayAckCount: v2GatewayAckCount,
          v2PaymentActCount: v2PaymentActCount,
          v2PaymentAckCount: v2PaymentAckCount,
          v2EquipmentNotificationActCount: v2EquipmentNotificationActCount,
          v2EquipmentNotificationAckCount: v2EquipmentNotificationAckCount,


          // type counters
          v2EquipmentCount: v2EquipmentCount,
          v2MembershipCount: v2MembershipCount,
          v2GatewayCount: v2GatewayCount,
          v2PaymentCount: v2PaymentCount,
          //v2EquipmentNotificationCount: v2EquipmentNotificationCount,

          //displaying values
          v2DisplayedSiteNotifications: v2DisplaySiteNotifications,
          v2DisplayCount: v2DisplaySiteNotificationCount,

          // by group data
          v2ActiveNotificationsByGateways: v2ActiveNotificationsByGateways,
          v2ActiveAlertsByUnits: v2ActiveAlertsByUnits
        };
        // push it in
        siteAlertMetricsData.assign(params);
      }

      this.siteAlertMetricsData = siteAlertMetricsData;
      this.refreshSiteAlertDataEmitter.emit(this.siteAlertMetricsData);
      if (e == undefined) this.siteService.siteEdited.emit({ type: `Refresh Notifications` });
    });
  }

  searchSiteAlertData(siteAlertFilter: SiteAlertFilter, siteNotifications: SiteNotification[]): SiteNotification[] {
    this.siteAlertFilter = siteAlertFilter;
    const searchSiteNotifications: SiteNotification[] = [];

    // filter on search filter
    for (const sn of siteNotifications) {
      let addIt = true;

      //now consider the text search - its the most expensive
      if (this.searchText !== '') {
        if (!siteNotification_textSearch(sn, this.searchText)) addIt = false;
      }
      // add to filtered list?
      if (addIt) searchSiteNotifications.push(sn)
    }
    return searchSiteNotifications;
  }

  getSiteMetricsData(): SiteAlertMetricsData {
    return this.siteAlertMetricsData;
  }

  public getSiteAlertData(siteId: string) {
    return this.http.get<SiteNotification[]>(WebserviceURL + 'site/alerts?site_id=' + siteId);
  }

  async viewAccountDetail() {
    this.viewAccountDetailEmitter.emit();
  }

  async viewMemberAccountDetail() {
    this.viewMemberAccountDetailEmitter.emit();
  }

  async viewAccountGatewaySubscriptionPlans() {
    this.viewGatewaySubscriptionPlansEmitter.emit();
  }

  async viewAccountPaymentHistory() {
    this.viewAccountPaymentHistoryEmitter.emit();
  }

  async viewAccountPaymentMethods() {
    this.viewAccountPaymentMethodsEmitter.emit();
  }

  async viewSiteAlert(navDirection: string) {
    this.viewSiteAlertEmitter.emit(navDirection);
  }

  async disableLeftNavigation(enableDisable: EnableDisableEnum) {
    this.disableLeftNavigationEmitter.emit(enableDisable);
  }

  async viewMemberDetails(account_id: string) {
    this.viewMemberDetailsEmitter.emit(account_id);
  }

  getSiteNotificationFromGlobal(notification_id: string): any {
    return this.totalNotifications.find(x => x.id === notification_id);
  }

  getSiteNotification(notification_id: string): SiteNotification {
    // return this notification from the active list.
    return this.siteAlertMetricsData.v2DisplayedSiteNotifications.find(x => x.id === notification_id);
  }

  setSiteAlertSearchText(text: string) {
    // set the text to search for in notifications
    this.searchText = text;
  }

  getSiteAlertSearchText() {
    return this.searchText;
  }

  isDevSite(): boolean {
    // are we on the development version of the site?
    let dev_site: boolean = this.isSite('dev');
    if (!dev_site) {
      // are we a local sever?
      dev_site = WebserviceURL.includes('8000') || WebserviceURL.includes('localhost') || WebserviceURL.includes('127.0.0.1');
    }

    return dev_site;
  }

  isDevTestAltSite(): boolean {
    // are we the development or test or alt version of the site?
    let dta_site: boolean = this.isSite('dev') || this.isSite('test') || this.isSite('alt');
    if (!dta_site) {
      // are we a local sever?
      dta_site = WebserviceURL.includes('8000');
    }

    return dta_site;
  }

  private isSite(siteString: string): boolean {
    return WebserviceURL.includes(siteString);
  }

}