import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-alerts',
  templateUrl: './settings-alerts.component.html',
  styleUrls: ['./settings-alerts.component.scss'],
})
export class SettingsAlertsComponent /*implements OnInit*/ {

  // constructor() { }

  // ngOnInit() {}

}
