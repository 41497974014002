import { Site } from '../../features/sites/classes/site';
import { Account } from '../../features/account/classes/account';
import { SiteNotification } from '../../features/sites/components/site-alerts/classes/sitenotification';

export class GridRow {
    sites: Site[];
    accounts: Account[];
    siteNotifications: SiteNotification[];
}
export class GridDynamicConfig {
    columnSize: number;
    columnsPerRow: number;
    RowCount: number;
    RowData: GridRow[];
}
