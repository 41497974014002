<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Spreadsheet</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card me-ion-card id="cardSelectUnit">
          <ion-card-content>
            <ion-grid class="calendarline">
              <ion-row>
                <ion-item lines="none" class="cw135">
                  <p>Display range:</p>
                </ion-item>
                <ion-item lines="none" class="cw200">
                  <p *ngIf="autoUpdate">{{ strStartDatetime }}</p>
                  <p *ngIf="!autoUpdate">
                    <ng2-flatpickr
                      #flatpickr2
                      [config]="options2"
                    ></ng2-flatpickr>
                  </p>
                </ion-item>
                <ion-item lines="none" class="cw30"> ~ </ion-item>
                <ion-item lines="none" class="cw200">
                  <p *ngIf="autoUpdate">{{ strEndDatetime }}</p>
                  <p *ngIf="!autoUpdate">
                    <ng2-flatpickr
                      #flatpickr
                      [config]="options"
                    ></ng2-flatpickr>
                  </p>
                </ion-item>
                <ion-item lines="none">
                  <ion-button
                    *ngIf="!autoUpdate"
                    id="setTime"
                    class="time-button-style"
                    (click)="setTime()"
                  >
                    Time Setting
                  </ion-button>
                </ion-item>
              </ion-row>
            </ion-grid>
            <ion-item lines="none">
              <div *ngIf="autoUpdate">
                <p>Fixed Start Time: &nbsp;</p>
                <input id="fixCheck" type="checkbox" name="checkbox" />
              </div>
              <div slot="end">
                <p>
                  <ion-button
                    id="MTDZ-button"
                    class="button-style"
                    (click)="toMTDZpage()"
                  >
                    MTDZ
                  </ion-button>
                </p>
                <p>
                  <ion-button
                    *ngIf="graphMode"
                    id="graph-button"
                    class="button-style"
                    (click)="toSheetMode()"
                  >
                    spreadsheet
                  </ion-button>
                </p>
              </div>
            </ion-item>
            <ion-item lines="none">
              <div>
                <p>Mode: &nbsp;</p>
                <p *ngIf="autoUpdate">&nbsp; Auto Updating</p>
                <p *ngIf="!autoUpdate">&nbsp; Fixed</p>
                <p>&nbsp;</p>
                <p *ngIf="autoUpdate">
                  <ion-button
                    id="testrun"
                    class="button-style"
                    (click)="stopTimer()"
                  >
                    Stop
                  </ion-button>
                </p>
                <p *ngIf="!autoUpdate">
                  <ion-button
                    id="testrun"
                    class="button-style"
                    (click)="setNowTime()"
                  >
                    Auto Update
                  </ion-button>
                </p>
              </div>
              <div slot="end">
                <p>
                  <select id="filter-list" required #target>
                    <option
                      *ngFor="let Target of filterTarget"
                      [value]="Target"
                    >
                      {{ Target }}
                    </option>
                  </select>
                </p>
                <p>
                  <ion-button
                    id="graph-button"
                    class="button-style"
                    (click)="toGraphMode(target.value)"
                  >
                    graph
                  </ion-button>
                </p>
              </div>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="graphMode">
      <ion-col size="12">
        <ion-card
          id="cardNoSelectedUnit"
          name="cardNoSelectedUnit"
          class="ion-no-margin"
          me-ion-card
        >
          <ion-card-content>
            <div class="center-chart">
              <canvas #test_line></canvas>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!graphMode">
      <ion-col>
        <ion-card me-ion-card>
          <ion-card-content
            id="testrunData"
            color="white"
            me-top-border
            class="spreadsheet-row"
          >
            <div class="table-area">
              <table
                mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="Key1" sticky>
                  <th class="col-width-left" mat-header-cell *matHeaderCellDef>
                    Time
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.Key1 }}</td>
                </ng-container>

                <ng-container
                  *ngFor="let header of timeHeader"
                  [matColumnDef]="header.def"
                >
                  <th class="col-width" mat-header-cell *matHeaderCellDef>
                    {{ header.name }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element[header.def] }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
