import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebserviceURL } from 'src/app/constants/webservice';

@Injectable()
export class GraphService {

    constructor(
        private http: HttpClient,
      ) {}

    getUnitData(gatewayId: string, unitId: string) {
        return this.http
          .get(`${WebserviceURL}graph/data/${gatewayId}/${unitId}`)
          .toPromise();
      }

}