import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountSubscriptionChangePaymentMethodComponent } from '../../components/account-subscription-change-payment-method/account-subscription-change-payment-method.component';
import { AuthGuard } from 'src/app/common/guards/auth.guard';
import { HasInternetConnectionGuardService } from 'src/app/common/guards/has-internet-connection-guard.service';
import { AccountGatewayDecommissionComponent } from '../../components/account-gateway-decommission/account-gateway-decommission.component';
import { AccountChangePasswordPage } from '../../components/account-change-password/account-change-password.page';
import { AccountDetailPage } from '../../components/account-detail/account-detail.page';
import { AccountDetailCompanyAdminPage } from '../../components/account-detail-company-admin/account-detail-company-admin.page';
import { AccountDetailMainComponent } from '../../components/account-detail-main/account-detail-main.component';
import { AccountDetailProfileAdminPage } from '../../components/account-detail-profile-admin/account-detail-profile-admin.page';
import { AccountGatewayChangeSubscriptionPlanPage } from '../../components/account-gateway-change-subscription-plan/account-gateway-change-subscription-plan.page';
import { AccountGatewaySubscriptionPlansComponent } from '../../components/account-gateway-subscription-plans/account-gateway-subscription-plans.component';
import { AccountPaymentMethodsComponent } from '../../components/account-payment-methods/account-payment-methods.component';
import { AccountPaymentHistoryComponent } from '../../components/account-payment-history/account-payment-history.component';
import { SiteGuard } from 'src/app/common/guards/site.guard';
import { AccountPreferencesAdminComponent } from '../../components/account-preferences-admin/account-preferences-admin.component';
import { AccountAnalyticsTestRunHistoryComponent } from '../../components/account-analytics-test-run-history/account-analytics-test-run-history.component';
import { AccountAnalyticsTestRunViewComponent } from '../../components/account-analytics-test-run-view/account-analytics-test-run-view.component';

const routes: Routes = [
  {
    path: 'manage/:siteId/members/:accountId',
    component: AccountDetailMainComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService, SiteGuard],
    runGuardsAndResolvers: 'always',
    data: { transition: 'toSiteMemberDetail' }
  }, {
    path: 'account/:accountId/details',
    component: AccountDetailMainComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always",
    data: { transition: 'fromMemberDetail' }
  }, {
    path: 'account/:accountId/details/subscriptions',
    component: AccountGatewaySubscriptionPlansComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always",
    data: { transition: 'fromMemberDetail' }
  }, {
    path: 'account/:accountId/details/payment-methods',
    component: AccountPaymentMethodsComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always",
    data: { transition: 'fromMemberDetail' }
  }, {
    path: 'account/:accountId/details/payment-history',
    component: AccountPaymentHistoryComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always"
  }, {
    path: 'account/:accountId/details/analytics-reports',
    component: AccountAnalyticsTestRunHistoryComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always",
    data: { transition: 'toTestRunHistory' }
  }, {
    path: 'account/:accountId/details/analytics-reports/:reportId', 
    component: AccountAnalyticsTestRunViewComponent,
    canActivate: [AuthGuard, HasInternetConnectionGuardService],
    runGuardsAndResolvers: "always"
  }, {
    path: 'accountsubscriptionchangepaymentmethod',
    component: AccountSubscriptionChangePaymentMethodComponent, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountgatewaydecommission',
    component: AccountGatewayDecommissionComponent, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountchangepasswordpage',
    component: AccountChangePasswordPage, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountdetailpage',
    component: AccountDetailPage, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountdetailcompanyddminpage',
    component: AccountDetailCompanyAdminPage, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountdetailprofileadminpage',
    component: AccountDetailProfileAdminPage, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountgatewaychangesubscriptionplanpage',
    component: AccountGatewayChangeSubscriptionPlanPage, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
  {
    path: 'accountpreferencesadmincomponent',
    component: AccountPreferencesAdminComponent, canActivate: [AuthGuard, HasInternetConnectionGuardService], runGuardsAndResolvers: "always"
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
