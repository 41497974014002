const PasswordKeysLowerCase = /[a-z]/g;
const PasswordKeysUpperCase = /[A-Z]/g;
const PasswordKeysDigits = /[0-9]/g;
const PasswordKeysSpecial = /[!@#$%^&*()\-_=+[\]{}|]/g;

export function PasswordKeyCategories(field: string) {
  // how many of the password categories is this field in?

  let categories = 0;
  if (field.match(PasswordKeysLowerCase)) {
    categories += 1;
  }
  if (field.match(PasswordKeysUpperCase)) {
    categories += 1;
  }
  if (field.match(PasswordKeysDigits)) {
    categories += 1;
  }
  if (field.match(PasswordKeysSpecial)) {
    categories += 1;
  }

  return categories;
}