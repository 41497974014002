import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/common/services/logging/log.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { MaintenanceJobTypeEnum, MaintenanceJobTypeEnumTitle, ToastMessageTypeEnum, WebSocketResponseTypeEnum } from 'src/app/enumerations/enums';
import { ReportDetail, ReportStatusEnum } from 'src/app/features/manage/components/classes/AnalyticsReportDetail';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { TestDriveHistoryComponent } from 'src/app/features/testdrive/components/testdrive-history/testdrive-history.component';
import { AccountAnalyticsTestRunViewComponent } from '../account-analytics-test-run-view/account-analytics-test-run-view.component';
import { devEnv, TOAST_GENERAL_ERROR_TITLE, WAIT_TIME } from 'src/app/constants/kenzaconstants';
import moment from 'moment';

@Component({
  selector: 'app-account-analytics-test-run-history',
  templateUrl: './account-analytics-test-run-history.component.html',
  styleUrls: ['./account-analytics-test-run-history.component.scss'],
})

export class AccountAnalyticsTestRunHistoryComponent  implements OnInit {
  reports = [];
  devEnv = devEnv;
  filteredReports = [];
  transferLocked = false;
  reportsLoading: boolean;
  noReports: boolean;
  moreReportsLoading: boolean;
  paginationKey: string; // kenza pagination key to get more invoice data 
                        // used by infinite loader to get more invoices to show
  searchTerm: string;
  noMoreReports: boolean;
  noResultsFound: boolean;

  reportStatusEnum = ReportStatusEnum; // for html enum access
  ToastMessageTypeEnum = ToastMessageTypeEnum; // for html enum access
  MaintenanceJobTypeEnum = MaintenanceJobTypeEnum; // for html enum access
  WebSocketResponseTypeEnum = WebSocketResponseTypeEnum; // for html enum access
  MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle; // for html enum access

  // socket notifications for test run progress
  socketEventSubscription: Subscription;
  // socket notifications of non test run maint jobs
  maintJobEventSubscription: Subscription;

  returnToSiteId; // site ID we came from

  csvReport: ReportDetail = null;
  csvRequestId: string; // the request id used when we are doing a CSV download.

  loadingSpinner;

  csvRecievedTime = 0; // hack to fix a double recieve of CSV file data.  Think it is an local/dev enviro issue so just going to ignore if recieved within seconds of each other

  constructor(
    private router: Router,
    private logger: Logger,
    private user: UserService,
    private location: Location,
    private route: ActivatedRoute,
    private _ref: ChangeDetectorRef,
    private userService: UserService,
    private siteService: SiteService,
    private socketService: SocketService,
    private alertController:AlertController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    @Inject(LOCALE_ID) public locale: string,
  ) { 
    this.socketEventSubscription = null;
  }

  ngOnInit() {
    // Empty
  }

  ionViewWillEnter() {

    this.route.queryParams.subscribe(params => {
      this.returnToSiteId = `fromSiteId` in params ? params.fromSiteId : ``;
    });

    // loading new or from cache
    this.paginationKey = '';
    // refresh the payment/invoice history
    this.searchTerm = '';
    this.reports = [];
    this.filteredReports = [];  
    this.refreshJobHistory();
    this.csvRequestId = 'no-active-request';

    this.socketEventSubscription = this.socketService.SocketServiceEmitter.subscribe((socketResult: any) => {
      this.receiveData(socketResult);
    });
    this.maintJobEventSubscription = this.socketService.maintenanceEmitter.subscribe((socketResult: any) => {
      this.receiveMaintJobData(socketResult);
    });
  }

  ionViewDidLeave() {
    if (this.socketEventSubscription) {
      this.socketEventSubscription.unsubscribe();
      this.socketEventSubscription = null;
    }
    if (this.maintJobEventSubscription) {
      this.maintJobEventSubscription.unsubscribe();
      this.maintJobEventSubscription = null;
    }

    this.dismissSpinner();
  }

  backToSiteAnalytics() {
    this.router.navigate(['/site', this.returnToSiteId, 'analytics']);
  }

  receiveData(socketResult) {
    if (socketResult.response_type == WebSocketResponseTypeEnum.Test_Run_Progress) {
      // then we have an update to the progress of a job
      const response = socketResult.response;
      const maintenancejob_id = response.maintenancejob_id;      
      const reports = this.reports.filter((rec) => (rec.maintenancejob_id == maintenancejob_id));
      if (reports.length > 0) {

        reports.forEach((report) => { 
          // update with details.
          report.update(response, this.locale);
        })
      } else {
        console.log('Test Run update for job(s) I dont have');
        // get a fresh list.
        this.refreshJobHistory();     
      }
      this.searchFilterReports();
    } else if (socketResult.response_type == WebSocketResponseTypeEnum.Maint_Job_Complete) {
      // are you sure??? its for us?
      if (socketResult.request_id == this.csvRequestId) {
        if (this.csvReport.gateway_id == socketResult.response.gateway_id) {
          const gotMessage = new Date().getTime() / 1000;  // getTime is epoch + milliseconds
          if (gotMessage - this.csvRecievedTime < 1) {
            return;  //ignoring if received within 1 second
          }
          this.csvRecievedTime = gotMessage;

          const tdhc = new TestDriveHistoryComponent(
            this._ref,
            this.router,
            this.route,
            this.user,
            this.siteService,
            this.socketService,
            this.loadingController,
            this.modalController,
            this.logger
          );
          tdhc.maintRequestId = socketResult.request_id;
          tdhc.csvTestRunId = this.csvReport.id;
          tdhc.csvFileName = this.csvReport.siteName.replace(" ", "_").trim();
          tdhc.gatewayId = this.csvReport.gateway_id;
          const site_id = this.csvReport.siteId;
          let selectedSite = null;
          this.user.sites.forEach((site) => {
            if (site.id == site_id) {
              selectedSite = site;
              return;
            }
          });

          if (selectedSite) {
            tdhc.utcOffset = moment().tz(selectedSite.timezone).utcOffset();
          }
          
          this.siteService.getTestrunHistory(this.csvReport.gateway_id).subscribe({
            next: (resData: any) => {
              if (resData.length > 0) {
                // Only records with job_id = 2600 (trial run) are stored in dataSourceHistory
                tdhc.dataSourceHistory = resData.filter(item => item.job_id === MaintenanceJobTypeEnum.Test_Run);
                tdhc.formatDataSourceHistory();

                this.siteService.getUnitHistory(this.csvReport.gateway_id, this.csvReport.unitHistoryId).subscribe({
                  next: (data: any) => {
                    tdhc.unitHistory = data;
                    tdhc.receiveMaintData(socketResult);
                    this.dismissSpinner();
                  },
                  error: () => {
                    this.dismissSpinner();
                    this.displayCsvErrorToast();
                  }
                })
              }
            },
            error: () => {
              this.dismissSpinner();
              this.displayCsvErrorToast();
            }
          });
        }
      }
    } else if (socketResult.response_type == WebSocketResponseTypeEnum.Maint_Job_Error) {
      this.dismissSpinner();
      this.displayCsvErrorToast();
    }
  }

  receiveMaintJobData(socketResult) {
    // process update of a non test run maintenance job
    // this should cover both Port Check and Refrig Maint Jobs
    const response = socketResult.responseData;
    const maintenancejob_id = response.managementTableId;      
    const reports = this.reports.filter((rec) => (rec.maintenancejob_id == maintenancejob_id));
    if (reports.length > 0) {

      reports.forEach( (report) => { 
        // update with details.
        report.updateMaintJobProgress(response, this.locale);
      })
    } else {
      console.log('Maint Job update for job(s) I dont have');
      // get a fresh list.
      this.refreshJobHistory();  
    }
    this.searchFilterReports();    
  }


  trackByReportId(index, item) {
    // the invoice.id is unique
    return item.id;
  }

  refreshJobHistory() {
    // get a fresh list of job history
    this.paginationKey = '';
    // refresh the payment/invoice history
    this.moreReportsLoading = false;
    this.reportsLoading = true;
    this.noReports = false;

    this.noMoreReports = false;    
    this.siteService.getTestrunHistory2().subscribe((testRuns: []) => {
      // map testRuns to reports.    
      this.reports = [];
      testRuns.forEach((testRun: any) => {
        let report = new ReportDetail(testRun, this.locale, this.user.isMemberOfCachedSites(testRun.site_id));
        this.reports.push(report);
      })
      this.reportsLoading = false;
      this.noReports = this.reports.length == 0;
      this.noMoreReports = true;
      this.filteredReports = this.reports;
      // devEnv && console.log(`Reports`, this.reports);
    })
  }

  navigateToSite(site_id: string) {
    // link to site dashboard
    this.router.navigate([`/site`, site_id, `dashboard`]);
  }

  actionView(report: ReportDetail) {
    // perform an action on view for this report
    this.router.navigate([ '/account/' + this.user.id + '/details/analytics-reports/' + report.id], { state: { report: report , site_id: this.user.lastSite , account_id: this.user.id}, queryParams: { fromSiteId: this.returnToSiteId} });
  }

  async actionCancel(report: ReportDetail) {

    // dont do this twice.
    if (!report.cancelable) return;

    const alert = await this.alertController.create({
      header: 'Cancel Job',
      message: '<div class="me-redClass">Are you sure you want to cancel this Job?</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.siteService.cancelMaintenanceJob(report.maintenancejob_id).subscribe();
            report.cancelable = false;
          }
        }, {
          text: 'No',
          cssClass: 'back-button'
        }
      ]
    });

    await alert.present();

  }

  async downloadRefrig(report) {
    let maintenancejob_history_id = report?.id;
    this.siteService.downloadRefrigerantCheck(maintenancejob_history_id).subscribe((r: Blob) => {
      const fileURL = URL.createObjectURL(r);
      const a = document?.createElement(`a`);
      if (document && document?.body) document?.body?.appendChild(a);
      a.href = fileURL;
      a.download = `refrig_maintenancejob_${maintenancejob_history_id}.csv`;
      a.click();
      a.remove();
      this.dismissSpinner();
    }, (error) => {
      console.log(`Refrigerant CSV Download Error`, error);
      this.dismissSpinner();
    })  
  }

  actionCsvdownload(report: ReportDetail) {
    // Test Run CSV's are good, do what you were already doing
    // Doing the same thing as Test Run for Refrig for now until the logic for Refrig CSV Download is done
    if (report.job_id == MaintenanceJobTypeEnum.Test_Run) {
      // There is a simular function in testdrive-history.component.ts but looking at it I don't think the TZ offsets are correct. Using this call Chris
      // wrote to trigger gathering of the maint data.  CSV is generated in the socket interceptor.
      const aatrvc = new AccountAnalyticsTestRunViewComponent(
        this.router,
        this.siteService,
        this.alertController,
        this.location,
        this.socketService,
        this.userService, 
        this.route,
        this.locale,
      )

      this.showLoadingSpinner();

      this.csvReport = report;
      aatrvc.reportDetail = report;
      aatrvc.getMaintData(report.startDate, report.endDate);
      this.csvRequestId = aatrvc.requestId;
    } else if (report.job_id == MaintenanceJobTypeEnum.Refrigerant) {
      this.showLoadingSpinner();
      this.downloadRefrig(report);
    } else { // Non Test Run CSV's are in development, show placeholder for now
      let toastTitle = `In Development`;
      let toastMessage = `This CSV Report data is in development.`;
      let toastType = (report?.status == ReportStatusEnum.Canceled || report?.status == ReportStatusEnum.Error) ? ToastMessageTypeEnum.Error : ToastMessageTypeEnum.Success;
      this.showLoadingSpinner();
      setTimeout(() => { 
        this.dismissSpinner();
        this.siteService.presentToastMessage(
          toastType,
          toastTitle,
          toastMessage,
        );
      }, 3_000);
    }
  }

  actionMtdzDownload(maintenancejob_id: string) {
    alert(`mtdz download job ${maintenancejob_id}`);
  }

  renderReportTypeString(report) {
    let type = MaintenanceJobTypeEnumTitle[report.job_id];
    return type;
  }

  async actionDelete(maintenancejob_id: string) {

    const alert = await this.alertController.create({
      header: 'Delete Job Data',
      message: '<div class="me-redClass">Are you sure you want to delete this Job?</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.siteService.deleteMaintenanceHistoryJob(maintenancejob_id).subscribe();
            const index = this.reports.findIndex( (rep) => rep.id == maintenancejob_id );
            if (index > -1)
              this.reports.splice(index,1);
            this.searchFilterReports();
          }
        }, {
          text: 'No',
          cssClass: 'back-button'
        }
      ]
    });

    await alert.present();
  }

  searchFilterReports(event=null)  {
    // filter reports down to filteredReports based on value in search
    if (event) {
      this.searchTerm = event.detail.value;
    }
    let searchTermLc = this.searchTerm.toLowerCase();
      
    // add entries from reports to filteredReports
    this.filteredReports = [];
    this.reports.forEach((report: ReportDetail) => {
      let add_it = false;
      if (report.siteName && report.siteName.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.startString && report.startString.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.statusString && report.statusString.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.oduName && report.oduName.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.oduModel && report.oduModel.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.id.toLocaleLowerCase() == searchTermLc) add_it = true;
      else if (report.type && report.type.toLowerCase().includes(searchTermLc)) add_it = true;
      else if (report.jobType && report.jobType.toLowerCase().includes(searchTermLc)) add_it = true;

      if (add_it) this.filteredReports.push(report);

    })
    this.noResultsFound = this.filteredReports.length == 0;
  }

  async showLoadingSpinner() {
    this.loadingSpinner = await this.loadingController.create({
      message: 'Generating CSV file...',
      spinner: 'lines',
      duration: WAIT_TIME,
    });

    await this.loadingSpinner.present();
  }

  dismissSpinner() {
    if (this.loadingSpinner && this.loadingSpinner.dismiss) {
      this.loadingSpinner.dismiss();
    }
  }

  displayCsvErrorToast() {
    if (this.csvReport != null) {
      this.siteService.presentToastMessage(
        ToastMessageTypeEnum.Error,
        TOAST_GENERAL_ERROR_TITLE,
        `Issue downloading Report CSV data.`
      );
    }
  }
}