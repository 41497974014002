<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Edit Account Preferences</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="accoutPreferencesForm">
    <ion-grid id="i_grid_site_with_gateways" name="i_grid_site_with_gateways">
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-label>Temperature:</ion-label>
            <select formControlName="temperature_preference" style="width: 275px">
              <option *ngFor="let temp_pref of temperature_preferences_list" [value]="temp_pref.id">
                {{ temp_pref.name + " (" + temp_pref.abbreviation + ") " }} </option>
            </select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-label>Pressure:</ion-label>
            <select formControlName="pressure_preference" style="width: 275px">
              <option *ngFor="let pressure_pref of pressure_preferences_list" [value]="pressure_pref.id">
                {{ get_pressure_preference_name_abbreviation_from_id(pressure_pref.id) }}</option>
            </select>
          </ion-item>
        </ion-col>
      </ion-row>

    </ion-grid>

  </form>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" id="i_btn_save" name="i_btn_save" (click)="save_account_preferences()" color="primary"
      style="margin: 0 0 0 1em;" [disabled]="!saveEnabled">
      Save
    </ion-button>
    <ion-button slot="end" id="i_btn_ok_cancel" name="i_btn_ok_cancel" (click)="close()" color="reverse-primary"
      style="margin: 0 1em 0 1em;">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>