<ion-header>
  <ion-toolbar>
    <ion-title>{{ siteAdminPageTitle }}</ion-title>
    <!-- <ion-toggle *ngIf="devEnv" class="devToggles devOnlyToggles" [(ngModel)]="useMaskito" [enableOnOffLabels]="true" title="Dev Only Temporary Toggle, Will Remove Later" slot="end" style="padding-right: 20px;">
      Dev Only: Use Maskito
    </ion-toggle> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="siteAdminForm" (ngSubmit)="onSubmit(submitBtn)">
    <ion-grid>
      <ion-row class="textRow">
        <ion-col col-12 me-zero-msg>
          <ion-title class="title2">Enter your site information</ion-title>
          <ion-label>
            This will be used for calculating tax when registering a gateway with a paid subscription.
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row class="requiredRow">
        <ion-col col col-12 class="siteNameCol">
          <ion-input
            id="name"
            type="text"
            minlength="3"
            maxlength="50"
            class="form-control"
            formControlName="name"
            (ionBlur)="trim($event)"
            labelPlacement="floating"
          >
            <ion-text slot="label" color="secondary">Site Name<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div validation-error *ngIf="siteAdminForm.controls.name.hasError('required') && siteAdminForm.controls.name.touched">
            Site Name is required
          </div>
          <!-- <div validation-error class="defaultMsg"
            *ngIf="!siteAdminForm.controls.name.hasError('required') && !siteAdminForm.controls.name.touched">
            Required
          </div> -->
          <div validation-error *ngIf="(siteAdminForm.controls.name.hasError('minlength') ||
             siteAdminForm.controls.name.hasError('pattern')) &&
             siteAdminForm.controls.name.dirty">
            Site Name must be between 3 and 50 characters
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="phoneRow">
        <ion-col col col-6>
          <app-text-mask formControlName="phone" [control]="siteAdminForm.controls.phone" [formGroup]="siteAdminForm" [useMaskito]="useMaskito" />
          <div validation-error *ngIf="!siteAdminForm.controls.phone.valid && siteAdminForm.controls.phone.dirty">
            Please enter a valid phone number
          </div>
        </ion-col>

        <ion-col col col-6>
          <ion-input
            type="text"
            minlength="2"
            maxlength="50"
            class="form-control"
            (ionBlur)="trim($event)"
            labelPlacement="floating"
            formControlName="company"
          >
            <ion-text slot="label" color="secondary">Company</ion-text>
          </ion-input>
          <div validation-error *ngIf="siteAdminForm.controls.company.errors">
            Company must be between 2 and 50 characters
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="requiredRow">
        <ion-col col col-12>
          <ion-input
            id="name"
            type="text"
            minlength="3"
            maxlength="50"
            class="form-control"
            (ionBlur)="trim($event)"
            labelPlacement="floating"
            formControlName="addressOne"
            (keypress)="clearErrorMessage($event)"
          >
            <ion-text slot="label" color="secondary">Address Line 1<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div validation-error *ngIf="siteAdminForm.controls.addressOne.hasError('required') && siteAdminForm.controls.addressOne.touched">
            Address is required
          </div>
          <div validation-error *ngIf="(siteAdminForm.controls.addressOne.hasError('minlength') ||
           siteAdminForm.controls.addressOne.hasError('pattern')) &&
           siteAdminForm.controls.addressOne.dirty">
            Address must be between 3 and 50 characters
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col col col-12>
          <ion-input
            id="name"
            type="text"
            maxLength="50"
            class="form-control"
            (ionBlur)="trim($event)"
            labelPlacement="floating"
            formControlName="addressTwo"
            (keypress)="clearErrorMessage($event)"
          >
            <ion-text slot="label" color="secondary">Address Line 2</ion-text>
          </ion-input>
          <div validation-error *ngIf="siteAdminForm.controls.addressTwo.errors">
            Address must be between 5 and 50 characters
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="requiredRow">
        <ion-col col col-4>
          <ion-input
            type="text"
            minlength="2"
            maxlength="50"
            class="form-control"
            formControlName="city"
            (ionBlur)="trim($event)"
            labelPlacement="floating"
            (keypress)="clearErrorMessage($event)"
          >
            <ion-text slot="label" color="secondary">City<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div validation-error *ngIf="siteAdminForm.controls.city.hasError('required') && siteAdminForm.controls.city.touched">
            City is required
          </div>
          <div validation-error *ngIf="(siteAdminForm.controls.city.hasError('minlength') ||
           siteAdminForm.controls.city.hasError('pattern') )
           && siteAdminForm.controls.city.dirty">
            City must be between 2 and 50 characters
          </div>
        </ion-col>

        <ion-col col col-4>
            <ion-label
              color="secondary"
              position="stacked"
              class="select-label small-text-label">
              State<sup validation-error>*</sup>
            </ion-label>
            <select formControlName="state" (change)="clearErrorMessage($event)">
              <option value="" selected>Select a State</option>
              <option *ngFor="let state of stateList" [value]="state.abbreviation">
                {{ state.abbreviation }} - {{ state.name }}
              </option>
            </select>
        </ion-col>

        <ion-col col col-4>
          <app-text-mask formControlName="zip" [control]="siteAdminForm.controls.zip" [formGroup]="siteAdminForm" [required]="true" [useMaskito]="useMaskito" />
          <div validation-error *ngIf="siteAdminForm.controls.zip.hasError('required') && siteAdminForm.controls.zip.touched">
            Zip Code is required
          </div>
          <div validation-error *ngIf="siteAdminForm.controls.zip.errors && siteAdminForm.controls.zip.dirty && !siteAdminForm.controls.zip.hasError('required') && siteAdminForm.controls.zip.touched">
            Zip Code must be 5 or 9 numbers<br />Example: #####-####
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="siteTimezoneRow requiredRow">
        <ion-col>
          <ion-item class="siteTimezoneItem">
            <ion-label
              color="secondary"
              position="stacked"
              class="select-label">
              Site Timezone<sup validation-error>*</sup>
            </ion-label>
            <select formControlName="timezone" (change)="clearErrorMessage($event)">
              <option *ngFor="let timezone of siteTimezones; let i = index" [value]="timezone.timezone">
                {{ timezone.option }}
              </option>
            </select>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-label me-error-label *ngIf="!success" color="danger">{{ displayErrorMessage }}</ion-label>
    <ion-button
      id="btnSave"
      #submitBtn
      [disabled]="!siteAdminForm.valid || !saveEnabled"
      slot="end"
      (click)="onSubmit(submitBtn)"
    >
      {{adminButtonText}}
    </ion-button>
    <ion-button
      slot="end"
      id="btnCancel"
      color="reverse-primary"
      (click)="onCancel()"
      class="ion-padding-horizontal"
    >
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>