export class UnregisteredDevice {
  public model_id;
  public model_class_name;
  public model_name;
  public name;
  public serial_number;
  public mac_address;
  public device_key;
  public registration_code;
  public kenza_plan_id;
  public subscription_id;
  public isExpanded = false;
  public has_coupon: boolean = false;
  public coupon_name: string = '';
  public hasTrialTimeLeft = true;
  public has_license = false;
  public licenseTypeDescription = '';
  public licenseName = '';
  public new_subscription_plan_name = '';
  public new_subscription_plan_id;

  constructor({
    model_id = '',
    model_class_name = '',
    model_name = '',
    name = '',
    serial_number = '',
    mac_address = '',
    device_key = '',
    registration_code = '',
    kenza_plan_id = '',
    subscription_id = '',
    coupon_name = '',
    hasTrialTimeLeft = true,
    has_license = false,
    licenseTypeDescription = '',
    licenseName = '',
    new_subscription_plan_name = ''
  }: IUnregisteredDeviceParameters) {
    this.model_id = model_id;
    this.model_class_name = model_class_name;
    this.model_name = model_name;
    this.name = name;
    this.serial_number = serial_number;
    this.mac_address = mac_address;
    this.device_key = device_key;
    this.registration_code = registration_code;
    this.subscription_id = subscription_id;
    this.kenza_plan_id = kenza_plan_id;
    this.coupon_name = coupon_name;
    this.has_coupon = coupon_name != '';
    this.hasTrialTimeLeft = hasTrialTimeLeft;
    this.has_license = has_license;
    this.licenseTypeDescription = licenseTypeDescription;
    this.licenseName = licenseName;
    this.new_subscription_plan_name = new_subscription_plan_name;
  }
}

interface IUnregisteredDeviceParameters {
  model_id: string;
  model_class_name: string;
  model_name: string;
  name: string;
  serial_number: string;
  mac_address?: string;
  device_key?: string;
  registration_code?: string;
  kenza_plan_id?: string;
  subscription_id?: string;
  coupon_name?: string;
  hasTrialTimeLeft?: boolean;
  has_license?: boolean;
  licenseTypeDescription?: string;
  licenseName?: string;
  new_subscription_plan_name?: string;
}