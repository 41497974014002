<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Test detail</ion-title>
    <div class="ion-icon-wrapper-raw-button" slot="end" (click)="close()" title="Close">
      <ion-icon name="close-circle" class="close-icon"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen>
  <ion-grid>

      <ion-row>
        <ion-col>
          <ion-card me-ion-card>
            <ion-card-content >
              <ion-grid>
                <ion-row class="row-title">
                  <ion-col size="3">
                    <strong>Test name</strong>
                  </ion-col>
                  <ion-col size="2">
                    <strong>Result</strong>
                  </ion-col>
                  <ion-col>
                    <strong>Detail</strong>
                  </ion-col>
                </ion-row>
                <ion-row me-top-border>
                  <ion-col size="3">
                    BC port check
                  </ion-col>
                  <ion-col size="2">
                    <ion-label color="danger">
                      <strong>Failed</strong>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    The port settings may not be correctly set.
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-card me-ion-card>
                      <ion-card-content >
                        <div class="table-area">
                          <table mat-table [dataSource]="checkResult">
                            <ng-container matColumnDef="Col_1">
                              <th mat-header-cell *matHeaderCellDef> <strong>BC Controller</strong> </th>
                              <td mat-cell *matCellDef="let element"> {{element.Col_1}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Col_2">
                              <th mat-header-cell *matHeaderCellDef> <strong>Refrigerant piping</strong> </th>
                              <td mat-cell *matCellDef="let element"> {{element.Col_2}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Col_3">
                              <th mat-header-cell *matHeaderCellDef> <strong>Logical connection</strong> </th>
                              <td mat-cell *matCellDef="let element"> {{element.Col_3}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Col_4">
                              <th mat-header-cell *matHeaderCellDef> <strong>Result</strong> </th>
                              <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.Col_4 == 'Failed'? '#eb445a' : '#2dd36f'}"> <strong>{{element.Col_4}}</strong> </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                        </div>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none">
                        <ion-button slot="end" color="secondary" size="small" (click)="onClickBranch()">
                          automatic correction
                        </ion-button>
                        <ion-button slot="end" color="warning" size="small" (click)="onClickBranch()">
                          manual correction
                        </ion-button>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-card me-ion-card>
            <ion-card-content >
              <ion-grid>
                <ion-row class="row-title">
                  <ion-col size="3">
                    <strong>Test name</strong>
                  </ion-col>
                  <ion-col size="2">
                    <strong>Result</strong>
                  </ion-col>
                  <ion-col>
                    <strong>Detail</strong>
                  </ion-col>
                </ion-row>
                <ion-row me-top-border>
                  <ion-col size="3">
                    Refrigerant check
                  </ion-col>
                  <ion-col size="2">
                    <ion-label color="success">
                      <strong>Passed</strong>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    Amount of refrigerant is correct.
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
  
          </ion-card>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-card me-ion-card>
            <ion-card-content >
              <ion-grid>
                <ion-row class="row-title">
                  <ion-col size="3">
                    <strong>Test name</strong>
                  </ion-col>
                  <ion-col size="2">
                    <strong>Result</strong>
                  </ion-col>
                  <ion-col>
                    <strong>Detail</strong>
                  </ion-col>
                </ion-row>
                <ion-row me-top-border>
                  <ion-col size="3">
                    Indoor unit LEV check
                  </ion-col>
                  <ion-col size="2">
                    <ion-label color="success">
                      <strong>Passed</strong>
                    </ion-label>
                  </ion-col>
                  <ion-col>
                    Locked-close “OK”: Locked-open “OK”.
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
  
          </ion-card>
        </ion-col>
      </ion-row>

    </ion-grid>
  <ion-list>
    <!--
    <ion-item id="{{outdoor_unit.id}}" *ngFor="let outdoor_unit of parent_gateway_outdoor_units"
      (click)="select_outdoor_unit(outdoor_unit)" lines="none" button>
      <ion-label>{{ outdoor_unit.name ? outdoor_unit.name : outdoor_unit.bus_address}}
      </ion-label>
    </ion-item>
    -->
  </ion-list>
</ion-content>