<ion-content class="ion-no-padding" main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1>Site Analytics</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <!-- Empty For Now -->
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-title class="stateMessage">
          <div class="loadingState" *ngIf="dataLoading">
            <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i></ion-label>
            <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
          </div>
          <ion-label id="zeroStateMessage1" name="zeroStateMessage1" color="medium"
            *ngIf="siteGatewayState == SiteGatewayState.NoGateways && !dataLoading">
            <i>Register a gateway to view analytics data.</i>
          </ion-label>
          <ion-label id="zeroStateMessage3" name="zeroStateMessage3" color="medium"
            *ngIf="siteGatewayState == SiteGatewayState.Unmapped && !dataLoading">
            <i>Map and configure your gateway units to view analytics data.</i>
          </ion-label>
        </ion-title>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <ion-grid *ngIf="siteGatewayState == SiteGatewayState.Mapped && !dataLoading">
    <ion-row>
      <!-- <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card no-padding me-ion-card-site-analytics>
          <ion-item lines="none">
            <ion-title me-card-header style="padding-left: 0px;">
              Maintenance Data
            </ion-title>

            <ion-icon id="maintenanceInfo"
              *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SADownloadMaintDataCSV)"
              (click)="showMaintenanceInfo()" name="information-circle" class="ion-padding-start" color="black"
              me-icon-nav>
            </ion-icon>
          </ion-item>

          <ion-card-content color="white" me-top-border>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <div>
                    <ion-icon name="construct" style="font-size: 100px; color:black;"></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>&nbsp;</ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto" class="ion-no-padding">
                  <div>
                    <ion-button
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SADownloadMaintDataCSV)"
                      (click)="onDownloadCSV()">
                      Download .csv
                    </ion-button>
                  </div>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col> -->

      <!-- Begin New Maintenance Data Column -->

      <ion-col *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics) && featureService.enabled(features.MaintenanceDataDownload)" id="maintenanceDataColumn" class="siteMaintenanceColumn maintenanceDataColumn" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card-site-analytics>
          <ion-item lines="none">
            <ion-label me-card-header>
              <strong>Maintenance Data</strong>
            </ion-label>

            <div class="ion-icon-wrapper-card-header maintenanceData" (click)="showMaintenanceInfo()"
              title="Information">
              <ion-icon id="maintenanceDataInfo"
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                name="information-circle" class="ion-padding-start" color="black" me-icon-nav>
              </ion-icon>
            </div>
          </ion-item>

          <ion-card-content color="white" me-top-border>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <div class>
                    <ion-icon name="document" style="font-size: 100px; color:black"></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>&nbsp;</ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto" class="ion-no-padding">
                  <div id="iBtnDownloadMTDZContainer" class="iBtnDownloadMTDZContainer downloadMTDZButtonContainer automationTesting">
                    <ion-button
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                      id="iBtnDownloadMTDZ" class="iBtnDownloadMTDZ downloadMTDZButton automationTesting"
                      (click)="onGenerateMaintenanceData()" [disabled]="isMtdzDownloadActive()">
                      Download .mtdz
                    </ion-button>
                  </div>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-card-content>

          <ion-row class="cardfooter" class="ion-no-padding" *ngIf="isMtdzDownloadActive()">
            <ion-col size="1.5">
              <div *ngIf="mtdzDownloadInAnEStage() || mtdzDownloadInCreateState()">
                <ion-spinner color=primary me-spinner-icon name="lines"></ion-spinner>
              </div>
              <div *ngIf="mtdzDownloadInReadyState()" pointer (click)='mtdzDownload()'>
                <ion-icon me-download-icon name="cloud-download-outline"></ion-icon>
              </div>
              <div *ngIf="mtdzDownloadInErrorState()" pointer (click)='mtdzDownloadClear()'>
                <ion-icon me-error-icon color=danger name="close-circle"></ion-icon>
              </div>
            </ion-col>

            <ion-col class="ion-text-center">
              <ion-label italic *ngIf="mtdzDownloadInAnEStage() || mtdzDownloadInCreateState()">
                Generating Maintenance Data...
              </ion-label>
              <ion-label italic *ngIf="mtdzDownloadInReadyState()" pointer (click)="mtdzDownload()">
                Complete, click to download
              </ion-label>
              <ion-label italic *ngIf="mtdzDownloadInErrorState()" pointer (click)='mtdzDownloadClear()'>
                {{ mtdzDownloadErrorMsg }}, try again later, click to clear
              </ion-label>
              <ion-progress-bar me-progress-bar color="{{ mtdzDownloadInErrorState() ? 'danger' : 'success' }}"
                value="{{ mtdzDownloadProgress }}">
              </ion-progress-bar>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>

      <!-- End New Maintenance Data Column -->

      <!-- Test Run Card -->

      <ion-col *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics) && rmdClassGatewaysExist" id="testRunColumn" class="siteMaintenanceColumn testRunColumn" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card-site-analytics>
          <ion-item lines="none">
            <ion-label me-card-header>
              <strong>Test Run</strong>
            </ion-label>

            <div class="ion-icon-wrapper-card-header maintenanceData" (click)="showAnalyticsHistory()"
              title="View Analytics Report History">
              <ion-icon id="viewAnalyticsReportHistory"
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                name="arrow-forward" class="ion-padding-start" color="black" me-icon-nav>
              </ion-icon>
            </div>
          </ion-item>

          <ion-card-content color="white" me-top-border>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <div class>
                    <ion-icon name="construct" style="font-size: 100px; color: black"></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>&nbsp;</ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto" class="ion-no-padding">
                  <div>
                    <ion-button
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                      (click)="user.active.transfer_locked ? user.presentActionRestricted() : onPerformTestRun()" 
                      [class]="user.active.transfer_locked ? 'site-transfer-disable-modal' : ''">
                      Start Test Run
                    </ion-button>
                  </div>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-card-content>
          <ion-row class="cardfooter" class="ion-no-padding" *ngIf="isTestRunActive()">
            <ion-col size="1.5">
              <div *ngIf="isTestRunStatusActive()" title="{{testRunProgressTitle}}">
                <ion-spinner color=primary me-spinner-icon name="lines"></ion-spinner>
              </div>
              <div *ngIf="isTestRunStatusComplete()" title="{{testRunProgressTitle}}">
                <ion-icon me-download-icon name="checkmark"></ion-icon>
              </div>
              <div *ngIf="isTestRunStatusError()" title="{{testRunProgressTitle}}">
                <ion-icon me-error-icon color=danger name="close-circle"></ion-icon>
              </div>
            </ion-col>

            <ion-col class="ion-text-center">
              <ion-label italic *ngIf="isTestRunStatusActive()" title="{{testRunProgressTitle}}">
                {{testRunStateMessage}}
              </ion-label>
              <ion-label italic *ngIf="!isTestRunStatusActive()">
                {{testRunStateMessage}}
              </ion-label>

              <ion-progress-bar me-progress-bar color="{{isTestRunStatusError() ? 'danger' : 'success' }}"
                value="{{testRunProgress}}" title="{{testRunProgressTitle}}">
              </ion-progress-bar>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>

      <!-- End New Maintenance Data Column -->

      <!-- end test run card -->

      <!-- System Diagram Card -->


      <ion-col *ngIf="mccClassGatewaysExist" id="systemDiagramColumn" class="siteMaintenanceColumn systemDiagramColumn" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <ion-card me-ion-card-site-analytics>
          <ion-item lines="none">
            <ion-label me-card-header>
              <strong>System Diagram</strong>
            </ion-label>
            <div class="ion-icon-wrapper-card-header" (click)="showSystemDataInfo()" title="Information">
              <ion-icon id="systemDataInfo"
                *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                name="information-circle" class="ion-padding-start" color="black" me-icon-nav>
              </ion-icon>
            </div>
          </ion-item>

          <ion-card-content color="white" me-top-border>
            <ion-grid>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto">
                  <div class>
                    <ion-icon name="git-branch" style="font-size: 100px; color: black;"></ion-icon>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>&nbsp;</ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="auto" class="ion-no-padding">
                  <div id="iBtnGenerateGIFContainer" class="iBtnGenerateGIFContainer generateGIFButtonContainer automationTesting">
                    <ion-button
                      *ngIf="appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)"
                      id="iBtnGenerateGIF" class="iBtnGenerateGIF generateGIFButton automationTesting"
                      (click)="onGenerateGIF()" [disabled]="isGifDownloadActive()">
                      Generate .gif
                    </ion-button>
                  </div>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-card-content>
          <ion-row class="cardfooter" class="ion-no-padding" *ngIf="isGifDownloadActive()">
            <ion-col size="1.5">
              <div *ngIf="gifDownloadInAnEStage() || gifDownloadInCreateState()">
                <ion-spinner color=primary me-spinner-icon name="lines"></ion-spinner>
              </div>
              <div *ngIf="gifDownloadInReadyState()" pointer (click)='gifDownload()'>
                <ion-icon me-download-icon name="cloud-download-outline"></ion-icon>
              </div>
              <div *ngIf="gifDownloadInErrorState()" pointer (click)='gifDownloadClear()'>
                <ion-icon me-error-icon color=danger name="close-circle"></ion-icon>
              </div>
            </ion-col>

            <ion-col class="ion-text-center">
              <ion-label italic *ngIf="gifDownloadInAnEStage() || gifDownloadInCreateState()">
                Generating system diagram...
              </ion-label>
              <ion-label italic *ngIf="gifDownloadInReadyState()" pointer (click)="gifDownload()">
                Complete, click to download
              </ion-label>
              <ion-label italic *ngIf="gifDownloadInErrorState()" pointer (click)='gifDownloadClear()'>
                {{gifDownloadErrorMsg}}, try again later, click to clear
              </ion-label>
              <ion-progress-bar me-progress-bar color="{{gifDownloadInErrorState() ? 'danger' : 'success' }}"
                value="{{gifDownloadProgress}}">
              </ion-progress-bar>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>

      <ion-col *ngIf="rmdClassGatewaysExist && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)" id="refrigerantCheckColumn" class="siteMaintenanceColumn refrigerantCheckColumn" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <app-refrigerant-checks-card [sortMaintJobByMostRecent]="sortMaintJobByMostRecent" [getCardDisplayData]="getCardDisplayData" [rcReports]="rcReports" [rmdClassGatewaysMapped]="rmdClassGatewaysMapped" [socketResult]="socketResult" />
      </ion-col>
 
      <ion-col *ngIf="rmdClassGatewaysExist && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.SAPerformAnalytics)" id="branchPortCheckColumn" class="siteMaintenanceColumn branchPortCheckColumn" size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <app-branch-port-checks-card [sortMaintJobByMostRecent]="sortMaintJobByMostRecent" [getCardDisplayData]="getCardDisplayData" [bpcReports]="bpcReports" [rmdClassGatewaysMapped]="rmdClassGatewaysMapped" [socketResult]="socketResult" />
      </ion-col>

      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12">
        <!-- <ion-card no-padding me-dashboard-card>
          <ion-item lines="none" no-padding>
            <ion-title class="me-header">

            </ion-title>

            <ion-icon name="create" class="ion-padding-start" color="black" me-icon-nav>
            </ion-icon>
          </ion-item>

          <ion-card-content color="white" me-top-border>
            <ion-grid class="ion-no-padding">
              <ion-row>
                <ion-col size="3">
                </ion-col>
                <ion-col>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card> -->
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>