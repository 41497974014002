import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/common/services/user/user.service';
import { Gateway, GatewayWithGroups } from '../../../manage/components/classes/Gateway';
import { SiteService } from '../../services/site.service';
import { ModalController } from '@ionic/angular';
import { SelectGatewayOutdoorUnitComponent } from '../../pages/select-gateway-outdoor-unit/select-gateway-outdoor-unit.component';
import { SiteGatewayPlansComponent } from '../../pages/site-gateway-plans/site-gateway-plans.component';
import { GatewayModelClass, SubscriptionFeatures } from 'src/app/enumerations/enums';

@Component({
  selector: 'app-site-analytics-generate-gif',
  templateUrl: './site-analytics-generate-gif.component.html',
  styleUrls: ['./site-analytics-generate-gif.component.scss'],
})
export class SiteAnalyticsGenerateGIFComponent implements OnInit {
  selected_gateway_name = 'Select an Outdoor Unit';
  site_gateways: Gateway[] = [];
  selected_gateway: Gateway;
  site_gateways_with_groups: any[];
  data_loading: boolean;
  outdoor_units: any[];
  selected_outdoor_unit: any;


  constructor(
    public user: UserService,
    private siteService: SiteService,
    private modalController: ModalController,
  ) {
    this.selected_gateway = null;
  }

  ngOnInit() {
    this.refreshSiteGatewaysWithGroups(this.user.active.id);
  }

  async refreshSiteGatewaysWithGroups(site_id: string) {
    this.data_loading = true;
    this.outdoor_units = [];
    this.siteService.get_site_gateways_with_groups(site_id).subscribe((gateways: GatewayWithGroups) => {
      let gateways_with_groups: Gateway[] = [];

      if (gateways) {
        gateways_with_groups = gateways['gateways'] as Gateway[];
        this.site_gateways_with_groups = [];
        this.outdoor_units = [];
        this.site_gateways_with_groups = gateways_with_groups.map(gwg => {
          gwg.outdoor_unit = gwg.outdoor_units[0];
          if (gwg.model.class_name == GatewayModelClass.MCC) {
            this.outdoor_units = this.outdoor_units.concat(gwg.outdoor_units);
          }
          return gwg;
        });
        // now go through each gateway and pull its subscription
        this.site_gateways_with_groups.forEach((gateway) => {
          this.siteService.getGatewayDetails(site_id, gateway.id, true).subscribe(gateway_details => {
            gateway['subscription'] = gateway_details;
          })
        })

        this.data_loading = false;
        this.outdoor_units = this.outdoor_units.sort(this.compareValues('name', 'asc '));
      }
    });
  }

  async on_select_outdoor_unit() {
    if (!this.siteService.handleIsConnected())
      return;

    const modal = await this.modalController.create({
      component: SelectGatewayOutdoorUnitComponent,
      backdropDismiss: true,
      componentProps: {
        parent_gateway_outdoor_units: this.outdoor_units,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (!this.siteService.handleIsConnected()) return;

      if (
        data.data !== undefined &&
        data.data !== null &&
        data.data.selected_outdoor_unit !== undefined &&
        data.data.selected_outdoor_unit !== null
      ) {
        // do we allow this request based on the gateway the ODU is part of?
        this.selected_gateway = this.site_gateways_with_groups.find(gateway => gateway.outdoor_units.find(ou => ou.id == data.data.selected_outdoor_unit.id) !== undefined);
        if (this.selected_gateway.subscription?.subscription_features[SubscriptionFeatures.GEN_SYSTEM_DIAGRAM] != SubscriptionFeatures.INCLUDED) {

          this.presentSubscriptionOptions();

          this.selected_gateway_name = 'Select an Outdoor Unit';
          this.selected_gateway = null;
        } else {
          this.selected_outdoor_unit = data.data.selected_outdoor_unit;
          this.selected_gateway_name = data.data.selected_outdoor_unit.name;
        }

      } else {
        this.selected_gateway_name = 'Select an Outdoor Unit';
      }

    });
    return await modal.present();
  }

  async presentSubscriptionOptions() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      backdropDismiss: true,
      componentProps: {
        title: "Upgrade your Subscription Plan",
        initialModelClassNameToDisplay: this.selected_gateway.model.class_name
      },
    });
    return await modal.present();

  }

  ready_for_submission() {
    return (
      (this.selected_gateway_name) &&
      (this.selected_gateway_name.length > 0) &&
      (this.selected_gateway_name !== 'Select an Outdoor Unit')
    )
  }

  close() {
    this.modalController.dismiss();
  }

  on_generate_gif() {
    if (!this.siteService.handleIsConnected())
      return;

    // resolve the dialog with the selected_outdoor_unit
    console.log("Generate the GIF file for outdoor unit ", this.selected_outdoor_unit.id, " at bus_address ", this.selected_outdoor_unit.bus_address)
    this.modalController.dismiss({ selected_outdoor_unit: this.selected_outdoor_unit, selected_gateway: this.selected_gateway })
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)) return 0;
      //if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
      const comparison = a[key].toString().localeCompare(b[key]);
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

}
