import {
  Input,
  OnInit,
  OnDestroy,
  Component,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Chart } from 'chart.js';
import { UserService } from 'src/app/common/services/user/user.service';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import {
  LevelEnum,
  PageState,
  SiteNotificationTypeEnum,
} from 'src/app/enumerations/enums';
import { Router } from '@angular/router';
import { ECharts, EChartsOption, init } from 'echarts';
import { Site } from 'src/app/features/sites/classes/site';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { CustomObservers } from 'src/app/common/classes/observers';
import { DataService } from 'src/app/common/services/data/DataService';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { getNotificationHeader } from 'src/app/features/sites/components/site-alerts/classes/shared';

@Component({
  selector: 'app-siteAlerts-card',
  templateUrl: './siteAlerts.component.html',
  styleUrls: ['./siteAlerts.component.scss', '../dashboard.shared.scss'],
})

export class SiteAlertsCardComponent implements OnInit, OnDestroy {
  @Input() events: Observable<PageState>;

  site: Site;
  alertChart: Chart = null;

  donughnutChart: ECharts = null;

  levelEnum = LevelEnum;
  siteNotificationTypeEnum = SiteNotificationTypeEnum;
  getNotificationHeader = getNotificationHeader;

  onRefreshSiteAlertData: Subscription = null;

  leavePage = new Subscriber();

  eventsSubscription: Subscription;

  chartElmSelector = "#chartContainer";

  colors = {
    'red': '#f8310a',
    'blue': '#3880ff',
    'purple': 'purple',
    'orange': 'orange',
    'yellow': 'yellow',
    'green': 'green'
  }

  constructor(
    protected router: Router,
    protected elm: ElementRef,
    protected user: UserService,
    protected dataService: DataService,
    protected siteService: SiteService,
    protected appAuth: AppAuthenticationService,
    protected mainSiteUIService: MainSiteUIService,
  ) {
    this.site = this.user.active;
  }

  ngOnInit() {
    if (this.onRefreshSiteAlertData == null) {
      this.onRefreshSiteAlertData = this.mainSiteUIService.refreshSiteAlertDataEmitter.subscribe(
        () => {
          CustomObservers.waitForElementToRenderObserver(this.chartElmSelector).then(() => {
            this.makePieChart();
          });
        }
      );

    }

    this.eventsSubscription = this.events.subscribe((evt) => this.handlePageStateChange(evt));
  }

  handlePageStateChange(evt: PageState) {
    if(evt == PageState.Enter) {
      CustomObservers.waitForElementToRenderObserver(this.chartElmSelector).then(() => {
        this.makePieChart();
      })
    } else if(evt == PageState.Leave) {
      if(this.donughnutChart) {
        this.donughnutChart.dispose();
      }
    }
  }

  makePieChart() {
    const root = this.elm.nativeElement.querySelector(this.chartElmSelector);
    this.donughnutChart = init(root);

    // colors and data objects match each other, so dataObj[0] matches colors[0]
    let colorPalette = [this.colors['red'], this.colors['yellow'], this.colors['blue'], this.colors['purple'], this.colors['orange']];
    let dataObj = [
      { value: this.mainSiteUIService.siteAlertMetricsData.v2RawEquipmentActCount, name: 'Equipment' },
      { value: this.mainSiteUIService.siteAlertMetricsData.v2RawEquipmentNotificationActCount, name: 'EquipmentNotification' },
      { value: this.mainSiteUIService.siteAlertMetricsData.v2RawMembershipActCount, name: 'Membership' },
      { value: this.mainSiteUIService.siteAlertMetricsData.v2RawGatewayActCount, name: 'Gateway' },
      { value: this.mainSiteUIService.siteAlertMetricsData.v2RawPaymentActCount, name: 'Payment' },
    ];

    let sum = 0;
    dataObj.forEach((obj) => {
      sum += obj['value'];
    });

    let showTooltip = true;

    if (sum == 0){ // No alerts to show
      dataObj = [
        { value: 1, name: '' }, // new a value still to make the circle green
      ];
      colorPalette = [this.colors['green']];
      showTooltip = false;
    }

    const option: EChartsOption = {
      tooltip: {
        trigger: 'item',
        padding: 10,
        show: showTooltip
      },
      legend: {
        show: false
      },
      series: [
        {
          name: 'Alerts',
          type: 'pie',
          radius: ['70%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            color: '#000',
            position: 'center',
            formatter: [
              `{a|${sum}}`,
              '{b|Active Alert(s)}',
              ].join('\n'),
            rich: {
              a: {
                fontSize: 40
              },
              b: {
                fontSize: 12
              },
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: dataObj
        }
      ],
      color: colorPalette,
    };

    this.donughnutChart.setOption(option);

    this.donughnutChart.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async viewAlerts(siteNotificationType: SiteNotificationTypeEnum) {
    if (!this.siteService.handleIsConnected()) return;

    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.StatusActive = true;
    siteAlertFilter.AlertEquipment = false;
    siteAlertFilter.StatusHistorical = false;
    siteAlertFilter.NotificationMembership = false;
    siteAlertFilter.NotificationGateway = false;
    siteAlertFilter.NotificationPayments = false;
    siteAlertFilter.NotificationEquipment = false;

    switch (siteNotificationType) {
      case SiteNotificationTypeEnum.Equipment:
        siteAlertFilter.AlertEquipment = true;
        break;

      case SiteNotificationTypeEnum.Membership:
        siteAlertFilter.NotificationMembership = true;
        break;

      case SiteNotificationTypeEnum.Gateway:
        siteAlertFilter.NotificationGateway = true;
        break;

      case SiteNotificationTypeEnum.Payments:
        siteAlertFilter.NotificationPayments = true;
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        siteAlertFilter.NotificationEquipment = true;
        break;
    }

    await this.user.setActiveSite(this.user.active, true);
    await this.mainSiteUIService.setCustomSiteAlertFilter(siteAlertFilter);
    await this.mainSiteUIService.applyCustomSiteAlertFilter(siteAlertFilter);
    await this.mainSiteUIService.setFilteredAlerts(siteAlertFilter);
    this.router.navigate(['/site', this.user.active.id, 'alerts']);
  }

  async navigateToSiteNotificationByType(sitenotificationtype_id: number, sitenotification_id: string) {
    if (!this.siteService.handleIsConnected()) return;

    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
    this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);

    switch (sitenotificationtype_id) {
      case SiteNotificationTypeEnum.Equipment:
        this.dataService.updateEquipmentAlertNotificationID(
          sitenotification_id
        );
        break;

      case SiteNotificationTypeEnum.Membership:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.Gateway:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.Payments:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;

      case SiteNotificationTypeEnum.EquipmentNotification:
        this.dataService.updateSiteNotificationID(sitenotification_id);
        break;
    }
  }

  ngOnDestroy(): void {
    if(this.donughnutChart) {
      this.donughnutChart.dispose();
    }

    this.eventsSubscription.unsubscribe();
  }

  async navigateToSiteAlerts() {
    if (!this.siteService.handleIsConnected()) return;

    const siteAlertFilter: SiteAlertFilter = new SiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

    await this.user.setActiveSite(this.user.active, true);
    await this.mainSiteUIService.setCustomSiteAlertFilter(siteAlertFilter);
    await this.mainSiteUIService.applyCustomSiteAlertFilter(siteAlertFilter);
    await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
    await this.mainSiteUIService.viewSiteAlert('siteAlerts');
    this.router.navigate(['/site', this.user.active.id, 'alerts']);
  }
}
