import { TemperaturePreferenceEnum } from "src/app/enumerations/enums";
import { GatewayUnit, Temp } from "src/app/features/manage/components/classes/GatewayUnit";

const MaxInletTemp = "300.0", MinInletTemp = "-300.0";

export class InletTemp {
    // object to control how the dial is displayed for coil units.

    dashTemp: boolean        // whether we are displaying a -- for the temp
    maxInletTemp: string     // working max room temp discovered for batch
    minInletTemp: string     // working min room temp discovered for the batch

    label: string            // the string to display in the room temp dial
    tempSymbol: string       // degrees symbol to postfix to temp displays

    constructor() {
        this.reset()
    }

    reset(tempPref:TemperaturePreferenceEnum = TemperaturePreferenceEnum.Celsius) {
        this.dashTemp = false;
        this.maxInletTemp = MinInletTemp;
        this.minInletTemp = MaxInletTemp;
        this.label = '';
        this.tempSymbol = tempPref == TemperaturePreferenceEnum.Celsius ? ' °C' : ' °F'
    }

    addGroupToBatch(unit: GatewayUnit) {
        // inlet/room temps to consider
        if (unit.inlet_temp == Temp.unk || unit.inlet_temp == Temp.mforty) {
            this.dashTemp = true;
        } else {
            // is it higher/lower?
            if (parseFloat(unit.inlet_temp) < parseFloat(this.minInletTemp)) this.minInletTemp = unit.inlet_temp;
            if (parseFloat(unit.inlet_temp) > parseFloat(this.maxInletTemp)) this.maxInletTemp = unit.inlet_temp;
        }
    }

    setDisplayOptions(singleGroup: boolean) {
        // set the values the UI will us to display on the dialog
        // inlet temp - for dial
        // singleGroup - if we dont' have multiple groups in the batch - or all are --
        if (singleGroup) {
            if (this.dashTemp) this.label = Temp.unk;
            else this.label = this.minInletTemp;
        } else {
            // make a range dialog
            if (this.dashTemp && this.maxInletTemp == MinInletTemp && this.minInletTemp == MaxInletTemp) this.label = Temp.unk;
            else if (this.dashTemp) this.label = `${Temp.unk}...${this.maxInletTemp}`
            else this.label = `${this.minInletTemp}...${this.maxInletTemp}`
        }
        this.label += this.tempSymbol; 
    }
    
}