import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AppAuthenticationService } from '../services/authentication/app-authentication.service';
import { AppMessageTypeCode, AppMessageCode } from 'src/app/enumerations/enums';
import { Site } from 'src/app/features/sites/classes/site';
import { Permission } from 'src/app/features/account/classes/Permission';
import { MainSiteUIService } from '../services/ui/main-site-ui.service';

@Injectable({
  providedIn: 'root'
})
export class GatewayDetailGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private appAuth: AppAuthenticationService,
    private mainUIService: MainSiteUIService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!next.paramMap.has('siteId')) {
      this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
      return false;
    }

    const gateway_site_id = next.paramMap.get('siteId');

    await this.userService.refresh_account_site_permissions();

    if ((!this.userService) || (!this.userService.site_permissions)) {
      this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
      return false;
    }

    const site_permission: Permission = this.userService.site_permissions.find(sp => sp.site_id == gateway_site_id);

    if ((site_permission == undefined)) {
      this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
      return false;
    }

    const found = this.appAuth.levelPermissions.find(checkValue => (checkValue.level_id == site_permission.level) && (checkValue.permissiondetail_id == this.appAuth.permissionEnums.ViewGatewayDetails));

    if (found === undefined) {
      this.router.navigate(['/appmessage', AppMessageTypeCode.HTTPRESPONSE, 'messagecode', AppMessageCode.UNAUTHORIZED]);
      return false;
    } else {
      if (this.userService.active?.id !== gateway_site_id) {
        // then we want to try and switch to this site - if we can.
        // do we have sites yet in the user Servivce (this can be called during refresh before sites are loaded)
        if (this.userService.sites.length == 0) {
          // then the userService sites are not yet loaded - tell me when they are.
          let retrievedSitesSubscription = this.userService.retrievedSites.subscribe(() => {
            // ok sites are loaded try again
            const activeSite: Site = this.userService.sites.find(s => s.id == gateway_site_id);
            this.userService.setActiveSite(activeSite, true);
            // release the subscription
            retrievedSitesSubscription.unsubscribe()
            // gateway details does not enable the left nav
            this.mainUIService.viewSiteAlertEmitter.emit(next.data.transition);            
          })
        } else {
          // then sites are loaded go ahead and try to find the site.
          const activeSite: Site = this.userService.sites.find(s => s.id == gateway_site_id);
          this.userService.setActiveSite(activeSite, true);       
        }
      }
      // gateway details does not enable the left nav
      this.mainUIService.viewSiteAlertEmitter.emit(next.data.transition);
      return true;
    }
  }
}
