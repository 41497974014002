<ion-grid>
  <form [formGroup]="accountForm">
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">First Name</ion-label>
            <ion-input type="text" formControlName="first" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)"></ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.first.valid && (accountForm.controls.first.dirty || submitAttempt)">
            Please enter your first name
          </div>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Last Name</ion-label>
            <ion-input type="text" formControlName="last" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)"></ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.last.valid && (accountForm.controls.last.dirty || submitAttempt)">
            Please enter your last name
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Phone</ion-label>
            <ion-input type="text" class="form-control tel_input" formControlName="phone"
              (ionFocus)="setPhoneField($event)" (ionInput)="maskPhoneField($event)"
              (ionBlur)="clearPhoneField($event)">
            </ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.phone.valid && (accountForm.controls.phone.dirty || submitAttempt)">
            Please enter a valid phone number
          </div>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Company</ion-label>
            <ion-input type="text" formControlName="company" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)">
            </ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.company.valid && (accountForm.controls.company.dirty || submitAttempt)">
            Please enter your company name
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Address Line 1</ion-label>
            <ion-input type="text" formControlName="addressOne" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)">
            </ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.addressOne.valid && (accountForm.controls.addressOne.dirty || submitAttempt)">
            Please enter your address
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Address Line 2</ion-label>
            <ion-input type="text" formControlName="addressTwo" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)">
            </ion-input>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">City</ion-label>
            <ion-input type="text" formControlName="city" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="trim($event)"></ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.city.valid && (accountForm.controls.city.dirty || submitAttempt)">
            Please enter your city
          </div>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list lines="none">
          <ion-item>
            <ion-label position="stacked" [color]="edit? 'primary' : 'dark'">State</ion-label>
            <select formControlName="state" [attr.disabled]="edit? null : 'true'">
              <option *ngFor="let state of stateList" [value]="state.abbreviation">{{ state.abbreviation }} -
                {{ state.name }}</option>
            </select>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label position="floating" [color]="edit? 'primary' : 'dark'">Zip Code</ion-label>
            <!-- <ion-input type="text" formControlName="zip" [attr.readonly]="!edit" (keyup.enter)="submit()"
              (ionBlur)="formatZip($event)"></ion-input> -->
            <ion-input type="text" formControlName="zip" [attr.readonly]="!edit" (ionBlur)="clearZipField($event)"
              (ionFocus)="setZipField($event)" (ionInput)="maskZipField($event)"></ion-input>
          </ion-item>
          <div validation-error
            *ngIf="!accountForm.controls.zip.valid && (accountForm.controls.zip.dirty || submitAttempt)">
            Please enter your Zip Code
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list lines="none">
          <ion-label>Photo</ion-label>
          <ion-input type="file" (change)="uploadAvatar($event)"></ion-input>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row padding-top>
      <ion-col>
        <ion-button expand="block" *ngIf="!edit" (click)="editDetails()">Edit</ion-button>
        <ion-button expand="block" *ngIf="edit" [@enableDisable]="accountForm.valid? 'enabled' : 'disabled'"
          (click)="submit()">Save</ion-button>
      </ion-col>
    </ion-row>
  </form>
</ion-grid>