<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Deactivate Site</ion-title>
    <!-- <ion-icon id="i_icon_close" slot="end" name="close-circle" class="close-icon" (click)="close()"></ion-icon> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="site_has_active_gateways===true" id="i_grid_site_with_gateways" name="i_grid_site_with_gateways">
    <ion-row>
        <ion-col class="modalTitleCol">
            <div class="has-gw-header1">Manage gateway(s) before proceeding</div>
            <div class="ion-text-center"> 
              <ion-label color="danger" id="i_label_gateway_message_1" name="i_label_gateway_message_1">
                All gateways on this site must be moved or decommissioned<br>before the site can be deactivated.
              </ion-label>
            </div>
        </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px; padding-left: 10px;">
          <ion-label id="i_label_gateway_message_2" name="i_label_gateway_message_2">
            The following gateways are on this site:
          </ion-label>
        </div>

      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col style="padding-left: 10px;">
        <div scrolly="true" me-scroll-div>
          <ion-list id="i_list_of_site_gateways" name="i_list_of_site_gateways">
            <ion-item lines="none" me-ion-border *ngFor="let site_gateway of site_gateways" [hidden]="!site_gateway?.created_at">
              <ion-label me-ion-border>{{site_gateway.name}}</ion-label>
              <ion-label me-ion-border slot="end">{{ getCurrentSubscriptionName(site_gateway) }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="site_has_active_gateways===false" id="i_grid_site_without_gateways"
    name="i_grid_site_without_gateways">
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-top: 10px">
          <ion-label color="danger" id="i_label_gateway_message" name="i_label_gateway_message">
            You are deactivating the site:
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-left: 10px">
          <ion-label>
            <strong>
              {{parent_selected_site.name}}
            </strong>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_deactivate_message" name="i_label_deactivate_message">
            When deactivating a site, please note the following:
          </ion-label>
          <ul>
            <li>
              The site will be removed and this action cannot be undone.
            </li>
            <li>
              You will no longer be able to access historical information.
            </li>
            <li>
              Site payment method information will be removed.
            </li>
            <li>
              All members will lose access to this site.
            </li>
          </ul>
        </div>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" *ngIf="site_has_active_gateways===false" id="i_btn_deactivate" name="i_btn_deactivate"
      (click)="confirm_deactivate_site()" color="primary" style="margin: 0 0 0 1em;">
      Deactivate
    </ion-button>
    <ion-button slot="end" id="i_btn_ok_cancel" name="i_btn_ok_cancel" (click)="close()"
      [color]="site_has_active_gateways ? 'primary' : 'reverse-primary'" style="margin: 0 1em 0 1em;">
      {{site_has_active_gateways ? 'Ok' : 'Cancel'}}
    </ion-button>
  </ion-toolbar>
</ion-footer>