/* eslint-disable prefer-const */
const kg_per_cm2_to_kilopascal_conversion_factor = 98.0665;
const kg_per_cm2_to_psi_conversion_factor = 14.2233;

export class TemperatureConversions {
    static convert_from_ME_celsius_to_ME_fahrenheit(celsius_temp_string): string {
        if (celsius_temp_string == null) {
            return null;
        }

        if (isNaN(celsius_temp_string) == false) {
            let celsius_temp_number = Number(celsius_temp_string);
            const decimal_portion = Number((celsius_temp_number - Math.floor(celsius_temp_number)).toFixed(2));
            let celsius_value_to_convert = 0;

            const no_round_values: number[] = [0.0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.50, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59];
            const ceil_values: number[] = [0.30, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.40, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.80, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.90, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99];
            const floor_values: number[] = [0.10, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.20, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.60, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.70, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79];

            if (no_round_values.includes(decimal_portion)) {
                celsius_value_to_convert = celsius_temp_number;
            } else if (ceil_values.includes(decimal_portion)) {
                celsius_value_to_convert = Math.ceil(celsius_temp_number);
            } else if (floor_values.includes(decimal_portion)) {
                celsius_value_to_convert = Math.floor(celsius_temp_number);
            }

            let fahrenheit_conversion = 0;
            for (let i = 0; i < this.conversions.length; i++) {
                let temperatureParing = this.conversions[i];
                if (celsius_value_to_convert == temperatureParing['celsius']) {
                    fahrenheit_conversion = temperatureParing['fahrenheit'];
                    break;
                }
            }

            if (fahrenheit_conversion) {
                return String(fahrenheit_conversion);
            }
        }
        return celsius_temp_string;
    }

    static convert_from_ME_fahrenheit_to_ME_celsius(fahrenheit_temp_string): string {
        if (fahrenheit_temp_string == null) {
            return null;
        }

        let fahrenheit_temp_number = Number(fahrenheit_temp_string);
        let celsius_conversion = 0;
        for (let i = 0; i < this.conversions.length; i++) {
            let temperatureParing = this.conversions[i];
            if (fahrenheit_temp_number == temperatureParing['fahrenheit']) {
                celsius_conversion = temperatureParing['celsius'];
                break;
            }
        }

        if (celsius_conversion) {
            return String(celsius_conversion.toFixed(1));
        } else {
            return null;
        }
    }


    static convertCelsiusToFahrenheit(celsius): number {
        if (celsius == null) {
            return null;
        }
        let f = celsius * 9/5 + 32;
        f = Number(f.toFixed(1));
        return f;       
    }

    static conversions = [
        {
            "celsius": -99.0,
            "fahrenheit": -146.0
        },
        {
            "celsius": -98.5,
            "fahrenheit": -145.0
        },
        {
            "celsius": -98.0,
            "fahrenheit": -144.0
        },
        {
            "celsius": -97.5,
            "fahrenheit": -144.0
        },
        {
            "celsius": -97.0,
            "fahrenheit": -143.0
        },
        {
            "celsius": -96.5,
            "fahrenheit": -142.0
        },
        {
            "celsius": -96.0,
            "fahrenheit": -141.0
        },
        {
            "celsius": -95.5,
            "fahrenheit": -140.0
        },
        {
            "celsius": -95.0,
            "fahrenheit": -139.0
        },
        {
            "celsius": -94.5,
            "fahrenheit": -138.0
        },
        {
            "celsius": -94.0,
            "fahrenheit": -137.0
        },
        {
            "celsius": -93.5,
            "fahrenheit": -136.0
        },
        {
            "celsius": -93.0,
            "fahrenheit": -135.0
        },
        {
            "celsius": -92.5,
            "fahrenheit": -135.0
        },
        {
            "celsius": -92.0,
            "fahrenheit": -134.0
        },
        {
            "celsius": -91.5,
            "fahrenheit": -133.0
        },
        {
            "celsius": -91.0,
            "fahrenheit": -132.0
        },
        {
            "celsius": -90.5,
            "fahrenheit": -131.0
        },
        {
            "celsius": -90.0,
            "fahrenheit": -130.0
        },
        {
            "celsius": -89.5,
            "fahrenheit": -129.0
        },
        {
            "celsius": -89.0,
            "fahrenheit": -128.0
        },
        {
            "celsius": -88.5,
            "fahrenheit": -127.0
        },
        {
            "celsius": -88.0,
            "fahrenheit": -126.0
        },
        {
            "celsius": -87.5,
            "fahrenheit": -126.0
        },
        {
            "celsius": -87.0,
            "fahrenheit": -125.0
        },
        {
            "celsius": -86.5,
            "fahrenheit": -124.0
        },
        {
            "celsius": -86.0,
            "fahrenheit": -123.0
        },
        {
            "celsius": -85.5,
            "fahrenheit": -122.0
        },
        {
            "celsius": -85.0,
            "fahrenheit": -121.0
        },
        {
            "celsius": -84.5,
            "fahrenheit": -120.0
        },
        {
            "celsius": -84.0,
            "fahrenheit": -119.0
        },
        {
            "celsius": -83.5,
            "fahrenheit": -118.0
        },
        {
            "celsius": -83.0,
            "fahrenheit": -117.0
        },
        {
            "celsius": -82.5,
            "fahrenheit": -117.0
        },
        {
            "celsius": -82.0,
            "fahrenheit": -116.0
        },
        {
            "celsius": -81.5,
            "fahrenheit": -115.0
        },
        {
            "celsius": -81.0,
            "fahrenheit": -114.0
        },
        {
            "celsius": -80.5,
            "fahrenheit": -113.0
        },
        {
            "celsius": -80.0,
            "fahrenheit": -112.0
        },
        {
            "celsius": -79.5,
            "fahrenheit": -111.0
        },
        {
            "celsius": -79.0,
            "fahrenheit": -110.0
        },
        {
            "celsius": -78.5,
            "fahrenheit": -109.0
        },
        {
            "celsius": -78.0,
            "fahrenheit": -108.0
        },
        {
            "celsius": -77.5,
            "fahrenheit": -108.0
        },
        {
            "celsius": -77.0,
            "fahrenheit": -107.0
        },
        {
            "celsius": -76.5,
            "fahrenheit": -106.0
        },
        {
            "celsius": -76.0,
            "fahrenheit": -105.0
        },
        {
            "celsius": -75.5,
            "fahrenheit": -104.0
        },
        {
            "celsius": -75.0,
            "fahrenheit": -103.0
        },
        {
            "celsius": -74.5,
            "fahrenheit": -102.0
        },
        {
            "celsius": -74.0,
            "fahrenheit": -101.0
        },
        {
            "celsius": -73.5,
            "fahrenheit": -100.0
        },
        {
            "celsius": -73.0,
            "fahrenheit": -99.0
        },
        {
            "celsius": -72.5,
            "fahrenheit": -99.0
        },
        {
            "celsius": -72.0,
            "fahrenheit": -98.0
        },
        {
            "celsius": -71.5,
            "fahrenheit": -97.0
        },
        {
            "celsius": -71.0,
            "fahrenheit": -96.0
        },
        {
            "celsius": -70.5,
            "fahrenheit": -95.0
        },
        {
            "celsius": -70.0,
            "fahrenheit": -94.0
        },
        {
            "celsius": -69.5,
            "fahrenheit": -93.0
        },
        {
            "celsius": -69.0,
            "fahrenheit": -92.0
        },
        {
            "celsius": -68.5,
            "fahrenheit": -91.0
        },
        {
            "celsius": -68.0,
            "fahrenheit": -90.0
        },
        {
            "celsius": -67.5,
            "fahrenheit": -90.0
        },
        {
            "celsius": -67.0,
            "fahrenheit": -89.0
        },
        {
            "celsius": -66.5,
            "fahrenheit": -88.0
        },
        {
            "celsius": -66.0,
            "fahrenheit": -87.0
        },
        {
            "celsius": -65.5,
            "fahrenheit": -86.0
        },
        {
            "celsius": -65.0,
            "fahrenheit": -85.0
        },
        {
            "celsius": -64.5,
            "fahrenheit": -84.0
        },
        {
            "celsius": -64.0,
            "fahrenheit": -83.0
        },
        {
            "celsius": -63.5,
            "fahrenheit": -82.0
        },
        {
            "celsius": -63.0,
            "fahrenheit": -81.0
        },
        {
            "celsius": -62.5,
            "fahrenheit": -81.0
        },
        {
            "celsius": -62.0,
            "fahrenheit": -80.0
        },
        {
            "celsius": -61.5,
            "fahrenheit": -79.0
        },
        {
            "celsius": -61.0,
            "fahrenheit": -78.0
        },
        {
            "celsius": -60.5,
            "fahrenheit": -77.0
        },
        {
            "celsius": -60.0,
            "fahrenheit": -76.0
        },
        {
            "celsius": -59.5,
            "fahrenheit": -75.0
        },
        {
            "celsius": -59.0,
            "fahrenheit": -74.0
        },
        {
            "celsius": -58.5,
            "fahrenheit": -73.0
        },
        {
            "celsius": -58.0,
            "fahrenheit": -72.0
        },
        {
            "celsius": -57.5,
            "fahrenheit": -72.0
        },
        {
            "celsius": -57.0,
            "fahrenheit": -71.0
        },
        {
            "celsius": -56.5,
            "fahrenheit": -70.0
        },
        {
            "celsius": -56.0,
            "fahrenheit": -69.0
        },
        {
            "celsius": -55.5,
            "fahrenheit": -68.0
        },
        {
            "celsius": -55.0,
            "fahrenheit": -67.0
        },
        {
            "celsius": -54.5,
            "fahrenheit": -66.0
        },
        {
            "celsius": -54.0,
            "fahrenheit": -65.0
        },
        {
            "celsius": -53.5,
            "fahrenheit": -64.0
        },
        {
            "celsius": -53.0,
            "fahrenheit": -63.0
        },
        {
            "celsius": -52.5,
            "fahrenheit": -63.0
        },
        {
            "celsius": -52.0,
            "fahrenheit": -62.0
        },
        {
            "celsius": -51.5,
            "fahrenheit": -61.0
        },
        {
            "celsius": -51.0,
            "fahrenheit": -60.0
        },
        {
            "celsius": -50.5,
            "fahrenheit": -59.0
        },
        {
            "celsius": -50.0,
            "fahrenheit": -58.0
        },
        {
            "celsius": -49.5,
            "fahrenheit": -57.0
        },
        {
            "celsius": -49.0,
            "fahrenheit": -56.0
        },
        {
            "celsius": -48.5,
            "fahrenheit": -55.0
        },
        {
            "celsius": -48.0,
            "fahrenheit": -54.0
        },
        {
            "celsius": -47.5,
            "fahrenheit": -54.0
        },
        {
            "celsius": -47.0,
            "fahrenheit": -53.0
        },
        {
            "celsius": -46.5,
            "fahrenheit": -52.0
        },
        {
            "celsius": -46.0,
            "fahrenheit": -51.0
        },
        {
            "celsius": -45.5,
            "fahrenheit": -50.0
        },
        {
            "celsius": -45.0,
            "fahrenheit": -49.0
        },
        {
            "celsius": -44.5,
            "fahrenheit": -48.0
        },
        {
            "celsius": -44.0,
            "fahrenheit": -47.0
        },
        {
            "celsius": -43.5,
            "fahrenheit": -46.0
        },
        {
            "celsius": -43.0,
            "fahrenheit": -45.0
        },
        {
            "celsius": -42.5,
            "fahrenheit": -45.0
        },
        {
            "celsius": -42.0,
            "fahrenheit": -44.0
        },
        {
            "celsius": -41.5,
            "fahrenheit": -43.0
        },
        {
            "celsius": -41.0,
            "fahrenheit": -42.0
        },
        {
            "celsius": -40.5,
            "fahrenheit": -41.0
        },
        {
            "celsius": -40.0,
            "fahrenheit": -40.0
        },
        {
            "celsius": -39.5,
            "fahrenheit": -39.0
        },
        {
            "celsius": -39.0,
            "fahrenheit": -38.0
        },
        {
            "celsius": -38.5,
            "fahrenheit": -37.0
        },
        {
            "celsius": -38.0,
            "fahrenheit": -36.0
        },
        {
            "celsius": -37.5,
            "fahrenheit": -36.0
        },
        {
            "celsius": -37.0,
            "fahrenheit": -35.0
        },
        {
            "celsius": -36.5,
            "fahrenheit": -34.0
        },
        {
            "celsius": -36.0,
            "fahrenheit": -33.0
        },
        {
            "celsius": -35.5,
            "fahrenheit": -32.0
        },
        {
            "celsius": -35.0,
            "fahrenheit": -31.0
        },
        {
            "celsius": -34.5,
            "fahrenheit": -30.0
        },
        {
            "celsius": -34.0,
            "fahrenheit": -29.0
        },
        {
            "celsius": -33.5,
            "fahrenheit": -28.0
        },
        {
            "celsius": -33.0,
            "fahrenheit": -27.0
        },
        {
            "celsius": -32.5,
            "fahrenheit": -27.0
        },
        {
            "celsius": -32.0,
            "fahrenheit": -26.0
        },
        {
            "celsius": -31.5,
            "fahrenheit": -25.0
        },
        {
            "celsius": -31.0,
            "fahrenheit": -24.0
        },
        {
            "celsius": -30.5,
            "fahrenheit": -23.0
        },
        {
            "celsius": -30.0,
            "fahrenheit": -22.0
        },
        {
            "celsius": -29.5,
            "fahrenheit": -21.0
        },
        {
            "celsius": -29.0,
            "fahrenheit": -20.0
        },
        {
            "celsius": -28.5,
            "fahrenheit": -19.0
        },
        {
            "celsius": -28.0,
            "fahrenheit": -18.0
        },
        {
            "celsius": -27.5,
            "fahrenheit": -18.0
        },
        {
            "celsius": -27.0,
            "fahrenheit": -17.0
        },
        {
            "celsius": -26.5,
            "fahrenheit": -16.0
        },
        {
            "celsius": -26.0,
            "fahrenheit": -15.0
        },
        {
            "celsius": -25.5,
            "fahrenheit": -14.0
        },
        {
            "celsius": -25.0,
            "fahrenheit": -13.0
        },
        {
            "celsius": -24.5,
            "fahrenheit": -12.0
        },
        {
            "celsius": -24.0,
            "fahrenheit": -11.0
        },
        {
            "celsius": -23.5,
            "fahrenheit": -10.0
        },
        {
            "celsius": -23.0,
            "fahrenheit": -9.0
        },
        {
            "celsius": -22.5,
            "fahrenheit": -9.0
        },
        {
            "celsius": -22.0,
            "fahrenheit": -8.0
        },
        {
            "celsius": -21.5,
            "fahrenheit": -7.0
        },
        {
            "celsius": -21.0,
            "fahrenheit": -6.0
        },
        {
            "celsius": -20.5,
            "fahrenheit": -5.0
        },
        {
            "celsius": -20.0,
            "fahrenheit": -4.0
        },
        {
            "celsius": -19.5,
            "fahrenheit": -3.0
        },
        {
            "celsius": -19.0,
            "fahrenheit": -2.0
        },
        {
            "celsius": -18.5,
            "fahrenheit": -1.0
        },
        {
            "celsius": -18.0,
            "fahrenheit": 0.0
        },
        {
            "celsius": -17.5,
            "fahrenheit": 1.0
        },
        {
            "celsius": -17.0,
            "fahrenheit": 1.0
        },
        {
            "celsius": -16.5,
            "fahrenheit": 2.0
        },
        {
            "celsius": -16.0,
            "fahrenheit": 3.0
        },
        {
            "celsius": -15.5,
            "fahrenheit": 4.0
        },
        {
            "celsius": -15.0,
            "fahrenheit": 5.0
        },
        {
            "celsius": -14.5,
            "fahrenheit": 6.0
        },
        {
            "celsius": -14.0,
            "fahrenheit": 7.0
        },
        {
            "celsius": -13.5,
            "fahrenheit": 8.0
        },
        {
            "celsius": -13.0,
            "fahrenheit": 9.0
        },
        {
            "celsius": -12.5,
            "fahrenheit": 10.0
        },
        {
            "celsius": -12.0,
            "fahrenheit": 10.0
        },
        {
            "celsius": -11.5,
            "fahrenheit": 11.0
        },
        {
            "celsius": -11.0,
            "fahrenheit": 12.0
        },
        {
            "celsius": -10.5,
            "fahrenheit": 13.0
        },
        {
            "celsius": -10.0,
            "fahrenheit": 14.0
        },
        {
            "celsius": -9.5,
            "fahrenheit": 15.0
        },
        {
            "celsius": -9.0,
            "fahrenheit": 16.0
        },
        {
            "celsius": -8.5,
            "fahrenheit": 17.0
        },
        {
            "celsius": -8.0,
            "fahrenheit": 18.0
        },
        {
            "celsius": -7.5,
            "fahrenheit": 19.0
        },
        {
            "celsius": -7.0,
            "fahrenheit": 19.0
        },
        {
            "celsius": -6.5,
            "fahrenheit": 20.0
        },
        {
            "celsius": -6.0,
            "fahrenheit": 21.0
        },
        {
            "celsius": -5.5,
            "fahrenheit": 22.0
        },
        {
            "celsius": -5.0,
            "fahrenheit": 23.0
        },
        {
            "celsius": -4.5,
            "fahrenheit": 24.0
        },
        {
            "celsius": -4.0,
            "fahrenheit": 25.0
        },
        {
            "celsius": -3.5,
            "fahrenheit": 26.0
        },
        {
            "celsius": -3.0,
            "fahrenheit": 27.0
        },
        {
            "celsius": -2.5,
            "fahrenheit": 28.0
        },
        {
            "celsius": -2.0,
            "fahrenheit": 28.0
        },
        {
            "celsius": -1.5,
            "fahrenheit": 29.0
        },
        {
            "celsius": -1.0,
            "fahrenheit": 30.0
        },
        {
            "celsius": -0.5,
            "fahrenheit": 31.0
        },
        {
            "celsius": 0.0,
            "fahrenheit": 32.0
        },
        {
            "celsius": 0.5,
            "fahrenheit": 33.0
        },
        {
            "celsius": 1.0,
            "fahrenheit": 34.0
        },
        {
            "celsius": 1.5,
            "fahrenheit": 35.0
        },
        {
            "celsius": 2.0,
            "fahrenheit": 36.0
        },
        {
            "celsius": 2.5,
            "fahrenheit": 37.0
        },
        {
            "celsius": 3.0,
            "fahrenheit": 37.0
        },
        {
            "celsius": 3.5,
            "fahrenheit": 38.0
        },
        {
            "celsius": 4.0,
            "fahrenheit": 39.0
        },
        {
            "celsius": 4.5,
            "fahrenheit": 40.0
        },
        {
            "celsius": 5.0,
            "fahrenheit": 41.0
        },
        {
            "celsius": 5.5,
            "fahrenheit": 42.0
        },
        {
            "celsius": 6.0,
            "fahrenheit": 43.0
        },
        {
            "celsius": 6.5,
            "fahrenheit": 44.0
        },
        {
            "celsius": 7.0,
            "fahrenheit": 45.0
        },
        {
            "celsius": 7.5,
            "fahrenheit": 46.0
        },
        {
            "celsius": 8.0,
            "fahrenheit": 46.0
        },
        {
            "celsius": 8.5,
            "fahrenheit": 47.0
        },
        {
            "celsius": 9.0,
            "fahrenheit": 48.0
        },
        {
            "celsius": 9.5,
            "fahrenheit": 49.0
        },
        {
            "celsius": 10.0,
            "fahrenheit": 50.0
        },
        {
            "celsius": 10.5,
            "fahrenheit": 51.0
        },
        {
            "celsius": 11.0,
            "fahrenheit": 52.0
        },
        {
            "celsius": 11.5,
            "fahrenheit": 53.0
        },
        {
            "celsius": 12.0,
            "fahrenheit": 53.0
        },
        {
            "celsius": 12.5,
            "fahrenheit": 54.0
        },
        {
            "celsius": 13.0,
            "fahrenheit": 55.0
        },
        {
            "celsius": 13.5,
            "fahrenheit": 56.0
        },
        {
            "celsius": 14.0,
            "fahrenheit": 57.0
        },
        {
            "celsius": 14.5,
            "fahrenheit": 58.0
        },
        {
            "celsius": 15.0,
            "fahrenheit": 59.0
        },
        {
            "celsius": 15.5,
            "fahrenheit": 60.0
        },
        {
            "celsius": 16.0,
            "fahrenheit": 61.0
        },
        {
            "celsius": 16.5,
            "fahrenheit": 62.0
        },
        {
            "celsius": 17.0,
            "fahrenheit": 63.0
        },
        {
            "celsius": 17.5,
            "fahrenheit": 64.0
        },
        {
            "celsius": 18.0,
            "fahrenheit": 65.0
        },
        {
            "celsius": 18.5,
            "fahrenheit": 66.0
        },
        {
            "celsius": 19.0,
            "fahrenheit": 67.0
        },
        {
            "celsius": 19.5,
            "fahrenheit": 67.0
        },
        {
            "celsius": 20.0,
            "fahrenheit": 68.0
        },
        {
            "celsius": 20.5,
            "fahrenheit": 68.0
        },
        {
            "celsius": 21.0,
            "fahrenheit": 69.0
        },
        {
            "celsius": 21.5,
            "fahrenheit": 70.0
        },
        {
            "celsius": 22.0,
            "fahrenheit": 71.0
        },
        {
            "celsius": 22.5,
            "fahrenheit": 72.0
        },
        {
            "celsius": 23.0,
            "fahrenheit": 73.0
        },
        {
            "celsius": 23.5,
            "fahrenheit": 74.0
        },
        {
            "celsius": 24.0,
            "fahrenheit": 75.0
        },
        {
            "celsius": 24.5,
            "fahrenheit": 76.0
        },
        {
            "celsius": 25.0,
            "fahrenheit": 77.0
        },
        {
            "celsius": 25.5,
            "fahrenheit": 78.0
        },
        {
            "celsius": 26.0,
            "fahrenheit": 79.0
        },
        {
            "celsius": 26.5,
            "fahrenheit": 80.0
        },
        {
            "celsius": 27.0,
            "fahrenheit": 81.0
        },
        {
            "celsius": 27.5,
            "fahrenheit": 82.0
        },
        {
            "celsius": 28.0,
            "fahrenheit": 83.0
        },
        {
            "celsius": 28.5,
            "fahrenheit": 84.0
        },
        {
            "celsius": 29.0,
            "fahrenheit": 85.0
        },
        {
            "celsius": 29.5,
            "fahrenheit": 86.0
        },
        {
            "celsius": 30.0,
            "fahrenheit": 87.0
        },
        {
            "celsius": 30.5,
            "fahrenheit": 88.0
        },
        {
            "celsius": 31.0,
            "fahrenheit": 88.0
        },
        {
            "celsius": 31.5,
            "fahrenheit": 89.0
        },
        {
            "celsius": 32.0,
            "fahrenheit": 89.0
        },
        {
            "celsius": 32.5,
            "fahrenheit": 90.0
        },
        {
            "celsius": 33.0,
            "fahrenheit": 91.0
        },
        {
            "celsius": 33.5,
            "fahrenheit": 92.0
        },
        {
            "celsius": 34.0,
            "fahrenheit": 93.0
        },
        {
            "celsius": 34.5,
            "fahrenheit": 94.0
        },
        {
            "celsius": 35.0,
            "fahrenheit": 95.0
        },
        {
            "celsius": 35.5,
            "fahrenheit": 96.0
        },
        {
            "celsius": 36.0,
            "fahrenheit": 97.0
        },
        {
            "celsius": 36.5,
            "fahrenheit": 98.0
        },
        {
            "celsius": 37.0,
            "fahrenheit": 99.0
        },
        {
            "celsius": 37.5,
            "fahrenheit": 100.0
        },
        {
            "celsius": 38.0,
            "fahrenheit": 100.0
        },
        {
            "celsius": 38.5,
            "fahrenheit": 101.0
        },
        {
            "celsius": 39.0,
            "fahrenheit": 102.0
        },
        {
            "celsius": 39.5,
            "fahrenheit": 103.0
        },
        {
            "celsius": 40.0,
            "fahrenheit": 104.0
        },
        {
            "celsius": 40.5,
            "fahrenheit": 105.0
        },
        {
            "celsius": 41.0,
            "fahrenheit": 106.0
        },
        {
            "celsius": 41.5,
            "fahrenheit": 107.0
        },
        {
            "celsius": 42.0,
            "fahrenheit": 108.0
        },
        {
            "celsius": 42.5,
            "fahrenheit": 109.0
        },
        {
            "celsius": 43.0,
            "fahrenheit": 109.0
        },
        {
            "celsius": 43.5,
            "fahrenheit": 110.0
        },
        {
            "celsius": 44.0,
            "fahrenheit": 111.0
        },
        {
            "celsius": 44.5,
            "fahrenheit": 112.0
        },
        {
            "celsius": 45.0,
            "fahrenheit": 113.0
        },
        {
            "celsius": 45.5,
            "fahrenheit": 114.0
        },
        {
            "celsius": 46.0,
            "fahrenheit": 115.0
        },
        {
            "celsius": 46.5,
            "fahrenheit": 116.0
        },
        {
            "celsius": 47.0,
            "fahrenheit": 117.0
        },
        {
            "celsius": 47.5,
            "fahrenheit": 118.0
        },
        {
            "celsius": 48.0,
            "fahrenheit": 118.0
        },
        {
            "celsius": 48.5,
            "fahrenheit": 119.0
        },
        {
            "celsius": 49.0,
            "fahrenheit": 120.0
        },
        {
            "celsius": 49.5,
            "fahrenheit": 121.0
        },
        {
            "celsius": 50.0,
            "fahrenheit": 122.0
        },
        {
            "celsius": 50.5,
            "fahrenheit": 123.0
        },
        {
            "celsius": 51.0,
            "fahrenheit": 124.0
        },
        {
            "celsius": 51.5,
            "fahrenheit": 125.0
        },
        {
            "celsius": 52.0,
            "fahrenheit": 126.0
        },
        {
            "celsius": 52.5,
            "fahrenheit": 127.0
        },
        {
            "celsius": 53.0,
            "fahrenheit": 127.0
        },
        {
            "celsius": 53.5,
            "fahrenheit": 128.0
        },
        {
            "celsius": 54.0,
            "fahrenheit": 129.0
        },
        {
            "celsius": 54.5,
            "fahrenheit": 130.0
        },
        {
            "celsius": 55.0,
            "fahrenheit": 131.0
        },
        {
            "celsius": 55.5,
            "fahrenheit": 132.0
        },
        {
            "celsius": 56.0,
            "fahrenheit": 133.0
        },
        {
            "celsius": 56.5,
            "fahrenheit": 134.0
        },
        {
            "celsius": 57.0,
            "fahrenheit": 135.0
        },
        {
            "celsius": 57.5,
            "fahrenheit": 136.0
        },
        {
            "celsius": 58.0,
            "fahrenheit": 136.0
        },
        {
            "celsius": 58.5,
            "fahrenheit": 137.0
        },
        {
            "celsius": 59.0,
            "fahrenheit": 138.0
        },
        {
            "celsius": 59.5,
            "fahrenheit": 139.0
        },
        {
            "celsius": 60.0,
            "fahrenheit": 140.0
        },
        {
            "celsius": 60.5,
            "fahrenheit": 141.0
        },
        {
            "celsius": 61.0,
            "fahrenheit": 142.0
        },
        {
            "celsius": 61.5,
            "fahrenheit": 143.0
        },
        {
            "celsius": 62.0,
            "fahrenheit": 144.0
        },
        {
            "celsius": 62.5,
            "fahrenheit": 145.0
        },
        {
            "celsius": 63.0,
            "fahrenheit": 145.0
        },
        {
            "celsius": 63.5,
            "fahrenheit": 146.0
        },
        {
            "celsius": 64.0,
            "fahrenheit": 147.0
        },
        {
            "celsius": 64.5,
            "fahrenheit": 148.0
        },
        {
            "celsius": 65.0,
            "fahrenheit": 149.0
        },
        {
            "celsius": 65.5,
            "fahrenheit": 150.0
        },
        {
            "celsius": 66.0,
            "fahrenheit": 151.0
        },
        {
            "celsius": 66.5,
            "fahrenheit": 152.0
        },
        {
            "celsius": 67.0,
            "fahrenheit": 153.0
        },
        {
            "celsius": 67.5,
            "fahrenheit": 154.0
        },
        {
            "celsius": 68.0,
            "fahrenheit": 154.0
        },
        {
            "celsius": 68.5,
            "fahrenheit": 155.0
        },
        {
            "celsius": 69.0,
            "fahrenheit": 156.0
        },
        {
            "celsius": 69.5,
            "fahrenheit": 157.0
        },
        {
            "celsius": 70.0,
            "fahrenheit": 158.0
        },
        {
            "celsius": 70.5,
            "fahrenheit": 159.0
        },
        {
            "celsius": 71.0,
            "fahrenheit": 160.0
        },
        {
            "celsius": 71.5,
            "fahrenheit": 161.0
        },
        {
            "celsius": 72.0,
            "fahrenheit": 162.0
        },
        {
            "celsius": 72.5,
            "fahrenheit": 163.0
        },
        {
            "celsius": 73.0,
            "fahrenheit": 163.0
        },
        {
            "celsius": 73.5,
            "fahrenheit": 164.0
        },
        {
            "celsius": 74.0,
            "fahrenheit": 165.0
        },
        {
            "celsius": 74.5,
            "fahrenheit": 166.0
        },
        {
            "celsius": 75.0,
            "fahrenheit": 167.0
        },
        {
            "celsius": 75.5,
            "fahrenheit": 168.0
        },
        {
            "celsius": 76.0,
            "fahrenheit": 169.0
        },
        {
            "celsius": 76.5,
            "fahrenheit": 170.0
        },
        {
            "celsius": 77.0,
            "fahrenheit": 171.0
        },
        {
            "celsius": 77.5,
            "fahrenheit": 172.0
        },
        {
            "celsius": 78.0,
            "fahrenheit": 172.0
        },
        {
            "celsius": 78.5,
            "fahrenheit": 173.0
        },
        {
            "celsius": 79.0,
            "fahrenheit": 174.0
        },
        {
            "celsius": 79.5,
            "fahrenheit": 175.0
        },
        {
            "celsius": 80.0,
            "fahrenheit": 176.0
        },
        {
            "celsius": 80.5,
            "fahrenheit": 177.0
        },
        {
            "celsius": 81.0,
            "fahrenheit": 178.0
        },
        {
            "celsius": 81.5,
            "fahrenheit": 179.0
        },
        {
            "celsius": 82.0,
            "fahrenheit": 180.0
        },
        {
            "celsius": 82.5,
            "fahrenheit": 181.0
        },
        {
            "celsius": 83.0,
            "fahrenheit": 181.0
        },
        {
            "celsius": 83.5,
            "fahrenheit": 182.0
        },
        {
            "celsius": 84.0,
            "fahrenheit": 183.0
        },
        {
            "celsius": 84.5,
            "fahrenheit": 184.0
        },
        {
            "celsius": 85.0,
            "fahrenheit": 185.0
        },
        {
            "celsius": 85.5,
            "fahrenheit": 186.0
        },
        {
            "celsius": 86.0,
            "fahrenheit": 187.0
        },
        {
            "celsius": 86.5,
            "fahrenheit": 188.0
        },
        {
            "celsius": 87.0,
            "fahrenheit": 189.0
        },
        {
            "celsius": 87.5,
            "fahrenheit": 190.0
        },
        {
            "celsius": 88.0,
            "fahrenheit": 190.0
        },
        {
            "celsius": 88.5,
            "fahrenheit": 191.0
        },
        {
            "celsius": 89.0,
            "fahrenheit": 192.0
        },
        {
            "celsius": 89.5,
            "fahrenheit": 193.0
        },
        {
            "celsius": 90.0,
            "fahrenheit": 194.0
        },
        {
            "celsius": 90.5,
            "fahrenheit": 195.0
        },
        {
            "celsius": 91.0,
            "fahrenheit": 196.0
        },
        {
            "celsius": 91.5,
            "fahrenheit": 197.0
        },
        {
            "celsius": 92.0,
            "fahrenheit": 198.0
        },
        {
            "celsius": 92.5,
            "fahrenheit": 199.0
        }
    ];

}

export class PressureConversions {
    static convert_from_kg_per_cm2_to_kilopascal(kg_per_cm2: number) {
        const kilopascals: number = kg_per_cm2 * kg_per_cm2_to_kilopascal_conversion_factor;
        return kilopascals;
    }

    static convert_from_kg_per_cm2_to_psi(kg_per_cm2: number) {
        const psi: number = kg_per_cm2 * kg_per_cm2_to_psi_conversion_factor;
        return psi;
    }

    static convert_from_megapascal_to_psi(megapaskal: number) {
        const psi: number = megapaskal * 1000 * kg_per_cm2_to_psi_conversion_factor / kg_per_cm2_to_kilopascal_conversion_factor;
        return psi;
    }    

    static convert_from_megapascal_to_kilopascal(megapascal:number) {
        return megapascal * 1000;
    }
}