<ion-header>
  <ion-toolbar>
    <ion-title id="title" name="title">Edit Gateway Information</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="gatewayEditForm" (ngSubmit)="onSave()">
    <ion-grid>
      <ion-row class="requiredRow">
        <ion-col size="12">
          <ion-input  minlength="3" maxlength="50" type="text" class="form-control" id="gatewayName" name="gatewayName"
            formControlName="gateway_name" (ionBlur)="trim($event)" labelPlacement="floating">
              <ion-text slot="label" for="gatewayName" color="secondary">Gateway Name<sup validation-error>*</sup></ion-text>
          </ion-input>
          <div *ngIf="gatewayEditForm.controls.gateway_name.errors">
            <div validation-error *ngIf="gatewayEditForm.controls.gateway_name.errors.required">
              Gateway Name is required
            </div>
            <div validation-error *ngIf="!gatewayEditForm.controls.gateway_name.errors.required">
              Gateway Name must be between 3 and 50 characters
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
            <ion-input [disabled]="true" type="text" class="form-control" id="serial_number" name="serial_number"
              value={{selectedGateway.serial_number}} labelPlacement="floating">
                <ion-text slot="label" id="serialNumber" for="serial_number" name="serialNumber" color="medium">Serial Number</ion-text>
            </ion-input>
            <div *ngIf="selectedGateway.model.class_name == gatewayModelClass.RMD">
              <ion-input [disabled]="true" type="text" class="form-control" id="mac_address" name="mac_address"
                value={{selectedGateway.mac_address}} labelPlacement="floating">
                  <ion-text slot="label" id="macAddress" for="mac_address" name="macAddress" color="medium" class="labelForInput">MAC Address</ion-text>
              </ion-input>
              <div lines="none">
                <ion-input [disabled]="true" type="text" class="form-control" id="device_key" name="device_key"
                  value={{selectedGateway.device_key}} labelPlacement="floating">
                    <ion-text slot="label" id="deviceKey" for="device_key" name="deviceKey" color="medium">Device Key</ion-text>
                </ion-input>
              </div>
            </div>
            <div *ngIf="selectedGateway.model.class_name == gatewayModelClass.MCC">
              <ion-input [disabled]="true" type="text" class="form-control" id="device_key" name="device_key"
                value={{selectedGateway.device_key}} labelPlacement="floating">
                  <ion-text slot="label" id="deviceKey" for="device_key" name="deviceKey" color="medium">Registration Code</ion-text>
              </ion-input>
            </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-left ion-align-items-left" *ngIf="updateResult">
        <ion-label color="danger" id="updateResult" name="updateResult">{{updateResult}}</ion-label>
      </ion-col>
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-button
          [disabled]="!gatewayEditForm.controls.gateway_name.dirty ||  !gatewayEditForm.controls.gateway_name.valid || !saveEnabled"
          id="btnEditUnit" name="btnEditUnit" (click)="onSave()" style="margin: 0 0 0 1em;" color="primary">
          Save
        </ion-button>
        <ion-button id="btnCancel" name="btnCancel" color="reverse-primary" (click)="onClose()"
          style="margin: 0 0 0 1em;">
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>