import { GatewayUnitTwoDigitType, TemperaturePreferenceEnum } from "src/app/enumerations/enums"
import { AutoMode, GatewayUnit, Mode, Presence, SetTempModeEnum, Temp } from "src/app/features/manage/components/classes/GatewayUnit"
import { ControlIgnorable } from "./controlIgnorable";

const MaxInletTemp = "300.0", MinInletTemp = "-300.0";

export class SetTempControl {
    // how to render the Set Temp values

    mode:SetTempModeEnum                // mode of set temp, disable, single, dual, singleAndDual (only for auto)
    showing: boolean                    // is the setTemp hidden

    autoSingleSetPointCount:number      // number of units in auto with single set point
    autoDualSetPointCount:number        // number of units in auto with dual set point

    singleSetPointLabel: string      // temp label for single set temp
    dualSetPointLowLabel: string     // temp label for low dual set temp
    dualSetPointHighLabel: string    // temp label for high dual set temp

    ignorable: ControlIgnorable     // are we ignoring incoming temp changes

    minMaxPoints = {                // min max points for the whole batch
        cool: { min: 0, max: 100 },
        dry: { min: 0, max: 100 },
        heat: { min: 0, max: 100 },
        auto: { min: 0, max: 100 },
        setback: { min: 0, max: 100 }
    };
    deadband:number                 // working deadband for the batch as a float
    tempSymbol:string               // temperature symbol to add to text displays, °C or °F

    // counters as we merge in units of the batch
    minDualLowSetTemp:string
    maxDualLowSetTemp:string
    minDualHighSetTemp:string
    maxDualHighSetTemp:string
    minSingleSetTemp:string
    maxSingleSetTemp:string
    
    // counts of expectations for the units
    disabledCount:number
    singleCount:number
    dualCount:number

    // debug - do we show the deadband value as a label?
    deadbandLabel:string
    deadbandDebug:boolean

    constructor() {
        this.ignorable = new ControlIgnorable;
        this.deadbandDebug = false;
        this.reset();
    }

    reset(hardReset:boolean = true, tempPref:TemperaturePreferenceEnum = TemperaturePreferenceEnum.Celsius, isDevTestAltSite:boolean = false) {
        if (hardReset) this.ignorable.reset();
        // reset counters and min/max
        this.minDualLowSetTemp = MaxInletTemp;
        this.maxDualLowSetTemp = MinInletTemp;
        this.minDualHighSetTemp = MaxInletTemp;
        this.maxDualHighSetTemp = MinInletTemp;
        this.minSingleSetTemp = MaxInletTemp;
        this.maxSingleSetTemp = MinInletTemp;
        this.disabledCount = 0;
        this.singleCount = 0;
        this.dualCount = 0;    
        this.autoSingleSetPointCount = 0;
        this.autoDualSetPointCount = 0;
        this.showing = true;
        this.singleSetPointLabel = Temp.unk;
        this.dualSetPointLowLabel = Temp.unk;
        this.dualSetPointHighLabel = Temp.unk;
        this.deadband = 0; 
        this.minMaxPoints = {                // min max points for the whole batch
            cool: { min: 0, max: 100 },
            dry: { min: 0, max: 100 },
            heat: { min: 0, max: 100 },
            auto: { min: 0, max: 100 },
            setback: { min: 0, max: 100 }
        };
        this.tempSymbol = tempPref == TemperaturePreferenceEnum.Celsius ? ' °C' : ' °F';
        this.deadbandDebug = isDevTestAltSite;
    }

    addGroupToBatch(unit: GatewayUnit) {
        // merge in settemp and settemp limits

        // merge in the min|ran range values from this unit.
        this.merge_limits(unit.minMaxPoints, unit.isInAuto(),  unit.auto_mode);

        // track how many disable, single and dual temp options there are
        // current ic modes
        if (unit.isCoil()) {

            if (unit.isInHeat()) this.singleCount +=1;
            else if (unit.isInCool()) this.singleCount +=1;
            else if (unit.isInDry()) this.singleCount +=1; 
            else if (unit.isInFan()) this.disabledCount +=1; 
            else if (unit.isInSetback()) this.dualCount +=1;
            else if (unit.isInAuto()) { 
                if (unit.isSingleAuto()) this.singleCount +=1;
                else if (unit.isDualAuto()) this.dualCount += 1;
                else this.disabledCount += 1;
            }
        } else {
            // lossnay mode
            // set temp is disabled
            this.disabledCount += 1;
        }        
        
        // current set temp values based on mode and capabilities
        if (unit.isInSetback()) {
            // consider both upper and lower

            // lower
            if (parseFloat(unit.lowerSetTemp) < parseFloat(this.minDualLowSetTemp)) this.minDualLowSetTemp = unit.lowerSetTemp;
            if (parseFloat(unit.lowerSetTemp) > parseFloat(this.maxDualLowSetTemp)) this.maxDualLowSetTemp = unit.lowerSetTemp;
            // upper
            if (parseFloat(unit.upperSetTemp) > parseFloat(this.maxDualHighSetTemp)) this.maxDualHighSetTemp = unit.upperSetTemp;
            if (parseFloat(unit.upperSetTemp) < parseFloat(this.minDualHighSetTemp)) this.minDualHighSetTemp = unit.upperSetTemp;
        
        } else if (unit.isNewGroupType()) {
            if (unit.isInAuto()) {
                if (unit.isSingleAuto()) {
                    // lower only
                    if (parseFloat(unit.lowerSetTemp) < parseFloat(this.minSingleSetTemp)) this.minSingleSetTemp = unit.lowerSetTemp;
                    if (parseFloat(unit.lowerSetTemp) > parseFloat(this.maxSingleSetTemp)) this.maxSingleSetTemp = unit.lowerSetTemp;
                } else if (unit.isDualAuto()) {
                    // consider both upper and lower
                    // lower
                    if (parseFloat(unit.lowerSetTemp) < parseFloat(this.minDualLowSetTemp)) this.minDualLowSetTemp = unit.lowerSetTemp;
                    if (parseFloat(unit.lowerSetTemp) > parseFloat(this.maxDualLowSetTemp)) this.maxDualLowSetTemp = unit.lowerSetTemp;
                    // upper
                    if (parseFloat(unit.upperSetTemp) > parseFloat(this.maxDualHighSetTemp)) this.maxDualHighSetTemp = unit.upperSetTemp;
                    if (parseFloat(unit.upperSetTemp) < parseFloat(this.minDualHighSetTemp)) this.minDualHighSetTemp = unit.upperSetTemp;
                }
            } else if ([Mode.cool, Mode.dry].includes(unit.mode)) {
                // upper only
                if (parseFloat(unit.upperSetTemp) > parseFloat(this.maxSingleSetTemp)) this.maxSingleSetTemp = unit.upperSetTemp;
                if (parseFloat(unit.upperSetTemp) < parseFloat(this.minSingleSetTemp)) this.minSingleSetTemp = unit.upperSetTemp;                        
            } else if (unit.isInHeat()) {
                // lower
                if (parseFloat(unit.lowerSetTemp) < parseFloat(this.minSingleSetTemp)) this.minSingleSetTemp = unit.lowerSetTemp;
                if (parseFloat(unit.lowerSetTemp) > parseFloat(this.maxSingleSetTemp)) this.maxSingleSetTemp = unit.lowerSetTemp;
            }
        } else {
            // old GT - everything is SetTemp
            if (unit.isInHeat() || unit.isInAuto()) {
                // then its lower set temp
                if (parseFloat(unit.lowerSetTemp) < parseFloat(this.minSingleSetTemp)) this.minSingleSetTemp = unit.lowerSetTemp;
                if (parseFloat(unit.lowerSetTemp) > parseFloat(this.maxSingleSetTemp)) this.maxSingleSetTemp = unit.lowerSetTemp;
            } else {
                // then its cool/dry
                // then its upper set temp
                if (parseFloat(unit.upperSetTemp) > parseFloat(this.maxSingleSetTemp)) this.maxSingleSetTemp = unit.upperSetTemp;
                if (parseFloat(unit.upperSetTemp) < parseFloat(this.minSingleSetTemp)) this.minSingleSetTemp = unit.upperSetTemp;
            }
        }

        // merge deadband - we want the biggest there is in the batch
        if (unit.deadband > this.deadband) this.deadband = unit.deadband;
    }


    setDisplayOptions(groupCount, mixedModes: boolean, maintenanceJobActive: boolean) {
        // set display options for the setTemp section   

        this.singleSetPointLabel = Temp.unk;
        this.dualSetPointLowLabel = Temp.unk;
        this.dualSetPointHighLabel = Temp.unk;

        this.showing = this.disabledCount != groupCount;
        if (mixedModes || maintenanceJobActive) this.mode = SetTempModeEnum.disabled;
        else if (this.singleCount == groupCount) this.mode = SetTempModeEnum.single;
        else if (this.dualCount == groupCount) this.mode = SetTempModeEnum.dual;
        else if (this.disabledCount == 0 && this.singleCount > 0 && this.dualCount > 0) this.mode = SetTempModeEnum.singleAndDual;
        
        // badges only show up for auto mode - between single|dual
        this.autoSingleSetPointCount = this.singleCount;
        this.autoDualSetPointCount = this.dualCount;

        // deadband label
        this.deadbandLabel = ''
        if (this.deadbandDebug) this.deadbandLabel = `Deadband value is ${this.deadband}${this.tempSymbol}`;

        // does the deadband imply a limit on the dual mode min/max values?
        if (this.deadband > 0 && this.mode != SetTempModeEnum.single) {
            // then we need to consider the effect of the deadband on the upper heat/lower set point and 
            // the lower upper/cool set point values.
            if (this.minMaxPoints[Mode.heat].max + this.deadband > this.minMaxPoints[Mode.cool].max) {
                // then we need to lower the heat max such that we dont push the
                // upper set temp over the max
                this.minMaxPoints[Mode.heat].max = this.minMaxPoints[Mode.cool].max - this.deadband;
            }

            // like wise on the lower upper
            if (this.minMaxPoints[Mode.cool].min - this.deadband < this.minMaxPoints[Mode.heat].min) {
                // then we need to raise the cool min such that we dont push the heat set point
                // below its min
                this.minMaxPoints[Mode.cool].min = this.minMaxPoints[Mode.heat].min + this.deadband
            }
        }
  
        // labels
        if (this.mode != SetTempModeEnum.disabled) {
            // handle getting NO updates.
            // singleSetPointLabel
            if (this.minSingleSetTemp == MaxInletTemp && this.maxSingleSetTemp == MinInletTemp) this.singleSetPointLabel = Temp.unk;
            else if (this.minSingleSetTemp == this.maxSingleSetTemp) this.singleSetPointLabel = this.minSingleSetTemp
            else this.singleSetPointLabel = `${this.minSingleSetTemp}...${this.maxSingleSetTemp}`
            //else this.singleSetPointLabel = Temp.unk;

            if (this.minDualLowSetTemp == this.maxDualLowSetTemp) this.dualSetPointLowLabel = this.minDualLowSetTemp
            else this.dualSetPointLowLabel = `${this.minDualLowSetTemp}...${this.maxDualLowSetTemp}`
            //else this.dualSetPointLowLabel = Temp.unk;

            if (this.minDualHighSetTemp == this.maxDualHighSetTemp) this.dualSetPointHighLabel = this.minDualHighSetTemp
            else this.dualSetPointHighLabel = `${this.minDualHighSetTemp}...${this.maxDualHighSetTemp}`
            //else this.dualSetPointHighLabel = Temp.unk;

            this.singleSetPointLabel += this.tempSymbol;
            this.dualSetPointHighLabel += this.tempSymbol;
            this.dualSetPointLowLabel += this.tempSymbol;
        }
    }

    merge_limits(unitLimits, isInAutoMode:boolean, autoMode:AutoMode) {
        // merge limits from this unit into the batch
        // unitLimits is the minMaxPoints object/dict of a GatewayUnit
        if (isInAutoMode) {
            // then merge fields based on auto mode...
            if (autoMode == AutoMode.dual_setpoint) {
                // only merge cool|dry
                [`cool`, `heat`].forEach((mode) => {
                    //if incoming mode min is higher than our min, change our min to match
                    if (unitLimits[mode].min > this.minMaxPoints[mode].min) this.minMaxPoints[mode].min = unitLimits[mode].min   
                    // if incoming mode max is lower than our max, change our max to match
                    if (unitLimits[mode].max < this.minMaxPoints[mode].max) this.minMaxPoints[mode].max = unitLimits[mode].max
                })
            } else {
                // single set point - only merge auto
                [`auto`].forEach((mode) => {
                    //if incoming mode min is higher than our min, change our min to match
                    if (unitLimits[mode].min > this.minMaxPoints[mode].min) this.minMaxPoints[mode].min = unitLimits[mode].min   
                    // if incoming mode max is lower than our max, change our max to match
                    if (unitLimits[mode].max < this.minMaxPoints[mode].max) this.minMaxPoints[mode].max = unitLimits[mode].max
                })
            }
        } else {
            // merge it all
            [`cool`, `dry`, `heat`, `auto`, `setback`].forEach((mode) => {
                //if incoming mode min is higher than our min, change our min to match
                if (unitLimits[mode].min > this.minMaxPoints[mode].min) this.minMaxPoints[mode].min = unitLimits[mode].min   
                // if incoming mode max is lower than our max, change our max to match
                if (unitLimits[mode].max < this.minMaxPoints[mode].max) this.minMaxPoints[mode].max = unitLimits[mode].max
            })
        }
    }

    isModeDisabled():boolean {
        // are we in disabled mode?
        return this.mode == SetTempModeEnum.disabled
    }

    isModeSingle():boolean {
        // are we in single mode?
        return this.mode == SetTempModeEnum.single
    }

    isModeDual():boolean {
        // are we in dual mode?
        return this.mode == SetTempModeEnum.dual
    }

    isModeSingleDual():boolean {
        // are we in single and dual mode?
        return this.mode == SetTempModeEnum.singleAndDual;
    }

    
}
