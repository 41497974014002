/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { round } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, timer } from 'rxjs';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { CreditCardFormComponent } from 'src/app/common/components/credit-card-form/credit-card-form.component';
import { SubscriptionPlanService } from 'src/app/common/services/subscription-plan/subscription-plan.service';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { CustomValidators } from 'src/app/constants/validators';
import { GatewayModelClass, GatewayModels, GatewayRegistrationStepEnum, MccRegistrationStatusEnum, NavigationDirectionEnum, PlanRowTypeEnum } from 'src/app/enumerations/enums';
import { PaymentMethod } from 'src/app/features/account/classes/PaymentMethod';
import { AccountService } from 'src/app/features/account/services/accountService';
import { GatewayModel } from 'src/app/features/manage/components/classes/Gateway';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { UnregisteredDevice } from '../../../manage/components/classes/UnregisteredDevice';
import { SiteGatewayPlansComponent } from '../site-gateway-plans/site-gateway-plans.component';
import { SiteGatewayTypeCompareComponent } from '../site-gateway-type-compare/site-gateway-type-compare.component';
import { RMD_MOCK_UNITS, int2roman } from './classes/rmd_mock_units';
import { ValidateGatewayRequestData } from './classes/validateGatewayRequestData';
import { devEnv } from 'src/app/constants/kenzaconstants';
import {GetAccountPaymentResult} from "../../../account/classes/HttpResult";
@Component({
  selector: 'app-site-gateway-register',
  templateUrl: './site-gateway-register.page.html',
  styleUrls: ['./site-gateway-register.page.scss']
})

export class SiteGatewayRegisterPage implements OnInit {
  @ViewChild(CreditCardFormComponent) ccForm: CreditCardFormComponent;
  rmd_gatewayRegistrationForm: UntypedFormGroup;
  mcc_gatewayRegistrationForm: UntypedFormGroup;
  currentGatewayProcessHeader: string;
  nextButtonText: string;
  gatewayRegistrationStep: any;
  currentGatewayRegistrationStep: number;
  navigationDirection: any;
  subscriptionProrateTotal = 0;
  selectedSubscriptionPlan: string;
  subscriptionTotal = 0;
  validationResultMessage: string;
  payButtonEnabled = false;
  cardExpiration = '';
  noPaymentNeeded = false;
  formatExpiry: any = CustomValidators.formatExpiration;
  submitAttempt = false;
  paymentError = false;
  paymentErrorMessage = '';
  registrationError = false;
  calculatedSubscriptionTotal: any = 0;
  existingMethodChoice = '';
  useExistingPayment = new UntypedFormControl('');
  paymentMethods: PaymentMethod[] = [];
  paymentMethodCollision = false;
  model: GatewayModel = new GatewayModel;
  unregisteredDevices: UnregisteredDevice[] = [];
  retrievedPreview = false;
  currentToken = '';
  currentBillingInfoId = '';
  planRowTypeEnum: any;
  errorMessage = '';
  // timeSubscription: Subscription = null;
  // timer: Observable<number> = null;
  gatewayModelClass = GatewayModelClass;

  // auto process the purchase after get get a new token
  autoProcessAfterToken = false;

  // are we calculating prorate?
  prorateCalc = false;

  allLicenses = false; // all are licenses
  someCoupons = false; // some are coupons
  someLicenses = false; // some are licenses
  someLimitedStandard = false; // some are either limited or standard
  // price if coupons are not considered.
  unReducedSubscriptionTotal = 0;
  gwName = ``;

  eventListenersAdded = false;

  // how many times have we pressed the easy button
  easy_index = 0;

  // Max gateways to register at once
  GATEWAY_LIMIT = 5;

  GatewayModels = GatewayModels;
  addGatewayDisabled = false;

  devEnv = devEnv;
  useMaskito = false;

  constructor(
    public loadingController: LoadingController,
    public formBuilder: UntypedFormBuilder,
    private user: UserService,
    private siteService: SiteService,
    private modalController: ModalController,
    private alertController: AlertController,
    private accountService: AccountService,
    public planService: SubscriptionPlanService,
    private toastService: ToastrService,
    private socketService: SocketService,
    public mainSiteUIService: MainSiteUIService,
  ) {
    this.gatewayRegistrationStep = GatewayRegistrationStepEnum;
    this.navigationDirection = NavigationDirectionEnum;
    this.planRowTypeEnum = PlanRowTypeEnum;
    this.selectedSubscriptionPlan = "";
    this.validationResultMessage = "";
    this.accountService
      .getAccountPaymentMethodsSub(this.user.active.id)
      .subscribe({next: (result: GetAccountPaymentResult) => {
        this.paymentMethods = result.pmt_methods || [];
        this.useExistingPayment.setValue(this.paymentMethods.length ? 'existing' : 'new');
        this.paymentMethods.sort((a, b) => (a.card_type + a.last_four).localeCompare(b.card_type + b.last_four));
        this.paymentMethods.sort((a, b) => (a.card_type + a.last_four).localeCompare(b.card_type + b.last_four));
      }, error: () => {
        this.paymentMethods = [];
        this.useExistingPayment.setValue('new');
        // There was an error retrieving payment methods :(
      }});

    this.existingMethodChoice = 'unselected';
    this.autoProcessAfterToken = false;

    this.createRmdRegistrationForm();
    this.createMccRegistrationForm();
  }

  ngOnInit() {
    // default to the first gateway model selected
    this.handleNavigationViewSetup(GatewayRegistrationStepEnum.InformationStep);
  }

  trackGWNameValue(e) {
    this.gwName = e.target.value;
  }

  onGatewayTypeChange(event) {
    this.model.id = event.target.value;
    this.model.class_name = this.planService.gateway_class_of_model_id(this.model.id);
    this.model.name = this.planService.gateway_name_of_model_id(this.model.id);
    this.reset_form_layout_by_model_class(this.model.class_name);
  }

  reset_form_layout_by_model_class(gatewayModelClassName: string) {
    const mccName = this.mcc_gatewayRegistrationForm.get("mcc_gateway_name").value;
    const rmdName = this.rmd_gatewayRegistrationForm.get("gateway_name").value;
    this.mcc_gatewayRegistrationForm.reset();
    this.rmd_gatewayRegistrationForm.reset();
    if (gatewayModelClassName === GatewayModelClass.RMD) {
      this.rmd_gatewayRegistrationForm.get("gateway_name").setValue(mccName);
    } else if (gatewayModelClassName === GatewayModelClass.MCC) {
      this.mcc_gatewayRegistrationForm.get("mcc_gateway_name").setValue(rmdName);
    }
    this.validationResultMessage = ``;
  }

  async onNavigateRegistrationProcess(stepsToMove: number) {
    if (!this.siteService.handleIsConnected())
      return;

    this.allLicenses = !this.unregisteredDevices.some(device => !device.has_license);
    this.someCoupons = this.unregisteredDevices.some(device => device.has_coupon);
    this.someLicenses = this.unregisteredDevices.some(device => device.has_license);
    this.someLimitedStandard = this.unregisteredDevices.some(device => !device.has_license && !device.has_coupon);
    this.paymentError = false;
    this.paymentErrorMessage = ''
    this.registrationError = false;
    this.errorMessage = '';

    if (this.currentGatewayRegistrationStep == 4 && stepsToMove > 0) {
      this.submitAttempt = true;

      await this.processPayment();
    } else {
      this.successfulNavigation(stepsToMove);
    }
  }

  successfulNavigation(stepsToMove: number) {
    this.currentGatewayRegistrationStep = this.currentGatewayRegistrationStep + stepsToMove;
    this.handleNavigationViewSetup(this.currentGatewayRegistrationStep);
    if (this.currentGatewayRegistrationStep === this.gatewayRegistrationStep.SubscriptionStep) this.calculateSubscriptionTotal();
  }

  createRmdRegistrationForm() {
    this.rmd_gatewayRegistrationForm = this.formBuilder.group({
      gateway_name: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
      serial_number: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]{14}$/) // lowercase allowed to pass validation after toUpperCase()
      ])
      ],
      mac_address: ['', Validators.compose([
        Validators.required,
        CustomValidators.validateMACAddress,
        Validators.minLength(17),
      ])
      ],
      device_key: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z]{9}$/)
      ])
      ]
    })
  }

  createMccRegistrationForm() {
    this.mcc_gatewayRegistrationForm = this.formBuilder.group({
      mcc_gateway_name: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/(\S+\s*){3,}/)
      ])
      ],
      mcc_serial_number: ['', Validators.compose([
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        CustomValidators.validateMccSerial,
      ])
      ],
      mcc_registration_code: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/\S/)
      ])
      ]
    })
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  formatInput(e) {
    e.target.value = e.target.value.replace(' ', '');
  }

  uppercaseInput(e) {
    e.target.value = e.target.value.toUpperCase();
    this.mcc_gatewayRegistrationForm.controls.mcc_registration_code.setValue(e.target.value.toUpperCase());
    this.formatInput(e);
  }

  onMACAddressChange(e) {
    if (e) {
      e.target.value = e.target.value.toUpperCase().replace(' ', '').trim();
    }
  }

  canAddGateway() {
    if(this.unregisteredDevices.length >= this.GATEWAY_LIMIT){
      this.validationResultMessage = `You can register a maximum of ${this.GATEWAY_LIMIT} gateways at one time.`;
      return false;
    }

    if (this.model.class_name == GatewayModelClass.RMD) {
      if (
        (this.rmd_gatewayRegistrationForm.get('gateway_name').value !== "") &&
        (this.rmd_gatewayRegistrationForm.get('serial_number').value !== "") &&
        (this.rmd_gatewayRegistrationForm.get('mac_address').value !== "") &&
        (this.rmd_gatewayRegistrationForm.get('device_key').value !== "") &&
        (this.rmd_gatewayRegistrationForm.valid == true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.model.class_name == GatewayModelClass.MCC) {
      if (
        (this.mcc_gatewayRegistrationForm.get('mcc_gateway_name').value !== "") &&
        (this.mcc_gatewayRegistrationForm.get('mcc_serial_number').value !== "") &&
        (this.mcc_gatewayRegistrationForm.get('mcc_registration_code').value !== "") &&
        (this.mcc_gatewayRegistrationForm.valid == true)
      ) {
        return true;
      } else {
        return false;
      }
    }

  }

  disableNextButton(): boolean {
    let result = true;

    switch (this.currentGatewayRegistrationStep) {
      case GatewayRegistrationStepEnum.InformationStep:
      case GatewayRegistrationStepEnum.ConfirmationStep:
        result = false;
        break;
      case GatewayRegistrationStepEnum.GatewayStep:
        if (this.unregisteredDevices.length > 0) result = false;
        break;
      case GatewayRegistrationStepEnum.SubscriptionStep:
        result = !this.doAllGatewaysHaveAPlan();
        break;
      case GatewayRegistrationStepEnum.PaymentAndRegisterStep:
        if (this.noPaymentNeeded) result = false;
        if (this.retrievedPreview) result = false;
        if (this.paymentError) result = true;
        break;
    }

    return result;
  }

  doesRmdExistInList(newDevice): boolean {
    return this.unregisteredDevices.some(d => {
      return (d.serial_number.toLowerCase() == newDevice.serial_number.toLowerCase()) &&
        (d.mac_address.toLowerCase() == newDevice.mac_address.toLowerCase()) &&
        (d.device_key.toLowerCase() == newDevice.device_key.toLowerCase()) &&
        (d.model_class_name == GatewayModelClass.RMD)
    });
  }

  doesMccExistInList(newDevice): boolean {
    return this.unregisteredDevices.some(d => {
      return ((d.serial_number === newDevice.serial_number) &&
        (d.registration_code.toLowerCase() === newDevice.registration_code.toLowerCase()) &&
        (d.model_class_name === GatewayModelClass.MCC));
    });
  }

  async addVerifiedGateway() {
    this.addGatewayDisabled = true;
    if (!this.siteService.handleIsConnected()) return;

    this.validationResultMessage = "";

    const validateGatewayRequestData: ValidateGatewayRequestData = new ValidateGatewayRequestData();

    if (this.model.class_name == GatewayModelClass.RMD) {
      validateGatewayRequestData.site_id = this.user.active.id;
      validateGatewayRequestData.serial_number = this.rmd_gatewayRegistrationForm.get('serial_number').value;
      validateGatewayRequestData.mac_address = this.rmd_gatewayRegistrationForm.get('mac_address').value.toUpperCase();
      validateGatewayRequestData.device_key = this.rmd_gatewayRegistrationForm.get('device_key').value;
      validateGatewayRequestData.model_id = this.model.id;

    } else if (this.model.class_name == GatewayModelClass.MCC) {
      validateGatewayRequestData.site_id = this.user.active.id;
      validateGatewayRequestData.serial_number = this.mcc_gatewayRegistrationForm.get('mcc_serial_number').value;
      validateGatewayRequestData.registration_code = this.mcc_gatewayRegistrationForm.get('mcc_registration_code').value;
      validateGatewayRequestData.model_id = this.model.id;
    }

    const loading = await this.loadingController.create({
      message: 'Adding gateway...',
      spinner: 'lines'
    });
    await loading.present();

    if (this.model.class_name == GatewayModelClass.RMD) {
      if (this.doesRmdExistInList(validateGatewayRequestData)) {
        this.validationResultMessage = "You have already entered this Gateway Serial Number, MAC Address and Device Key.";
        await loading.dismiss();
        return;
      }
    } else if (this.model.class_name == GatewayModelClass.MCC) {
      if (this.doesMccExistInList(validateGatewayRequestData)) {
        this.validationResultMessage = "You have already entered this Gateway Serial Number and Registration Code.";
        await loading.dismiss();
        return;
      }
    }

    if (this.model.class_name == GatewayModelClass.RMD) {

      this.siteService.validateSiteGateway(validateGatewayRequestData).subscribe({next:(result)=>{
          const isValidRequest: boolean = result['isValidRequest'];
          if (!isValidRequest){
            if (result['gatewayExists']){
              this.validationResultMessage = 'Gateway has already been registered';
            } else if (result['responseMessage']){
              this.validationResultMessage = result['responseMessage'];
            } else {
              this.validationResultMessage = 'An unhandled error occurred';
            }
        } else {
          const unregisteredDevice = new UnregisteredDevice({
            model_id: this.model.id,
            model_class_name: GatewayModelClass.RMD,
            name: this.rmd_gatewayRegistrationForm.get('gateway_name').value,
            serial_number: this.rmd_gatewayRegistrationForm.get('serial_number').value,
            mac_address: this.rmd_gatewayRegistrationForm.get('mac_address').value.toUpperCase(),
            device_key: this.rmd_gatewayRegistrationForm.get('device_key').value,
            model_name: this.model.name,
            coupon_name: result['couponName'],
            hasTrialTimeLeft: result['hasTrialTimeLeft'],
            has_license: result['hasLicense'],
          });
          if (result['hasLicense']){
            unregisteredDevice.licenseTypeDescription = result['licenseTypeDescription'];
            unregisteredDevice.licenseName = result['licenseName'];
            unregisteredDevice.new_subscription_plan_name = result['licenseTypeDescription'];
            unregisteredDevice.subscription_id = result['licenseSubscriptionId'];
            unregisteredDevice.kenza_plan_id = result['licenseSubscriptionId'];
          }
          this.unregisteredDevices.push(unregisteredDevice);
          this.createRmdRegistrationForm();
        }
      }, error: (err) => {
        this.validationResultMessage = "Unable to register Gateway at this time";
      }, complete: () =>{
        loading.dismiss();
        this.addGatewayDisabled = false;
      }});

    } else if (this.model.class_name == GatewayModelClass.MCC) {

      this.siteService.validateDCCSiteGateway(validateGatewayRequestData).subscribe({next:(result) => {
          const isValidRequest: boolean = result['isValidRequest'];
          if (!isValidRequest){
            if (result['gatewayExists']){
              this.validationResultMessage = 'Gateway has already been registered';
            } else if (result['responseMessage']){
              this.validationResultMessage = result['responseMessage'];
            } else {
              this.validationResultMessage = 'An unhandled error occurred';
            }
        } else {
          const unregisteredDevice = new UnregisteredDevice({
            model_id: this.model.id,
            model_class_name: GatewayModelClass.MCC,
            name: this.mcc_gatewayRegistrationForm.get('mcc_gateway_name').value,
            serial_number: this.mcc_gatewayRegistrationForm.get('mcc_serial_number').value,
            registration_code: this.mcc_gatewayRegistrationForm.get('mcc_registration_code').value,
            model_name: this.model.name,
            coupon_name: result['couponName'],
            hasTrialTimeLeft: result['hasTrialTimeLeft'],
            has_license: result['hasLicense'],
          });
          if (result['hasLicense']){
            unregisteredDevice.licenseTypeDescription = result['licenseTypeDescription'];
            unregisteredDevice.licenseName = result['licenseName'];
            unregisteredDevice.new_subscription_plan_name = result['licenseTypeDescription'];
            unregisteredDevice.subscription_id = result['licenseSubscriptionId'];
            unregisteredDevice.kenza_plan_id = result['licenseSubscriptionId'];
          }
          this.unregisteredDevices.push(unregisteredDevice);
          this.createMccRegistrationForm();
        }
      },error: (err) => {
        this.validationResultMessage = "Unable to register Gateway at this time";
        // catch rule violations and abort.
        if (err.message.includes("kenza rule")) this.modalController.dismiss()
      }, complete: () =>{
        loading.dismiss();
        this.addGatewayDisabled = false;
      }});
    }
  }

  calculateSubscriptionTotal(): void {
    let total = 0;
    let unReducedTotal = 0;
    let allSelected = true;

    this.unregisteredDevices.forEach(d => {
      if (d.subscription_id === '') allSelected = false;
      unReducedTotal += this.planService.plansKeyed[d.subscription_id]?.monthly_rate || 0;
      if (!d.has_coupon) total += this.planService.plansKeyed[d.subscription_id]?.monthly_rate || 0;
    });

    this.subscriptionTotal = total;
    this.unReducedSubscriptionTotal = unReducedTotal;

    if (!allSelected) {
      this.noPaymentNeeded = false;
      this.retrievedPreview = false;
    } else {
      this.retrievedPreview = true;
      if (!total) {
        this.noPaymentNeeded = true;
      } else {
        this.noPaymentNeeded = false;
      }
    }
  }

  doAllGatewaysHaveAPlan(): boolean {
    return !this.unregisteredDevices.some(sg => sg.subscription_id === '');
  }

  async onComapareGateways() {
    // display GW type dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayTypeCompareComponent,
      cssClass: 'me-custom-modal-account-gateway-view-gateway-types',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async onComparePlans() {
    // display subscription options dialog
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteGatewayPlansComponent,
      cssClass: 'me-custom-modal-account-gateway-change-sub-plan',
      backdropDismiss: false,
      componentProps: {
        title: "Compare Subscription Plans",
        initialModelClassNameToDisplay: this.model.class_name
      }
    });
    return await modal.present();
  }

  onCloseSubscriptionPlans() {
    this.currentGatewayRegistrationStep = GatewayRegistrationStepEnum.SubscriptionStep;
  }

  showDevice(serial_number: string, registration_code: string, device_key: string) {
    this.unregisteredDevices.forEach(sg => {
      if (sg.serial_number === serial_number && sg.registration_code == registration_code && sg.device_key == device_key) {
        sg.isExpanded = !sg.isExpanded;
      } else {
        sg.isExpanded = false;
      }
    });
  }

  removeUnregisteredDevice(serial_number: string, registration_code: string, device_key: string) {
    this.unregisteredDevices = this.unregisteredDevices
      .filter(gw => !(gw.serial_number === serial_number && gw.registration_code === registration_code && gw.device_key === device_key));

    if(this.unregisteredDevices.length < this.GATEWAY_LIMIT) {
      this.validationResultMessage = '';
    }
    
    this.calculateSubscriptionTotal();
  }

  handleNavigationViewSetup(currentGatewayRegistrationStep: number) {
    switch (currentGatewayRegistrationStep) {
      case GatewayRegistrationStepEnum.InformationStep:
        this.currentGatewayProcessHeader = "Register a Gateway";
        this.nextButtonText = "Next"
        break;

      case GatewayRegistrationStepEnum.GatewayStep:
        this.model.id = '1';
        this.currentGatewayProcessHeader = "Register a Gateway (Step 1 of 4)";
        this.nextButtonText = "Next"
        // set the dialog to display based on the current gateway model.id
        this.model.class_name = this.planService.gateway_class_of_model_id(this.model.id);
        this.model.name = this.planService.gateway_name_of_model_id(this.model.id);
        this.reset_form_layout_by_model_class(this.model.class_name);
        break;

      case GatewayRegistrationStepEnum.SubscriptionStep:
        this.currentGatewayProcessHeader = "Register a Gateway (Step 2 of 4)";
        this.nextButtonText = "Next"
        break;

      case GatewayRegistrationStepEnum.PaymentAndRegisterStep:
        this.currentToken = '';
        this.currentBillingInfoId = '';
        this.paymentMethodCollision = false;
        this.subscriptionProrateTotal = 0;
        this.existingMethodChoice = 'unselected';
        this.currentGatewayProcessHeader = 'Register a Gateway (Step 3 of 4)';
        this.useExistingPayment.setValue(this.paymentMethods.length ? 'existing' : 'new');
        this.nextButtonText = this.subscriptionTotal ? 'Pay and Register' : 'Register';
        this.noPaymentNeeded = this.subscriptionTotal === 0;
        this.retrievedPreview = this.noPaymentNeeded;
        this.getParams = function () { return {}; };
        break;

      case GatewayRegistrationStepEnum.ConfirmationStep:
        this.currentGatewayProcessHeader = 'Register a Gateway (Step 4 of 4)';
        this.nextButtonText = 'Print';
        break;
    }
    this.currentGatewayRegistrationStep = currentGatewayRegistrationStep;
  }

  updatePaymentMethodChoice(event) {
    if (this.useExistingPayment.value === 'new') {
      this.existingMethodChoice = 'unselected';
      this.retrievedPreview = false;
    } else if (this.useExistingPayment.value === 'existing') {
      if (this.existingMethodChoice === 'unselected') {
        this.retrievedPreview = false;
      }
    }
  }
  /**
   * Placeholder function used when requesting previews and charges.
   *
   * DO NOT REMOVE
   * @returns
   */
  getParams() { return {} }

  async ccFormValidationChanged(event) {
    this.currentToken = event.token;
    this.currentBillingInfoId = '';
    this.paymentError = false;
    this.paymentErrorMessage = '';
    this.registrationError = false;

    const params = {
      token: event.token,
      site_id: this.user.active.id,
      devices: this.unregisteredDevices,
      customer_info: event.customerForm,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    this.getParams = function () {
      return params
    }

    if (event.valid) {

      // are we in a last second get a new token cycle?
      if (this.autoProcessAfterToken) {

        // then we go to retry the purchase.
        this.processPayment()
        return
      }

      this.prorateCalc = true;

      const preview = await this.siteService.getChargePreview(params);

      if ('error' in preview) {
        // then we failed.
        console.error(preview.error);
        this.retrievedPreview = false;
        this.subscriptionProrateTotal = 0;
        this.subscriptionTotal = 0;
        // if there is a .. in the error then use before that as the message. Else use stock.
        this.errorMessage = "Please enter a valid Site Address/City/State/Zip Code";
        if (preview.error.includes('..')) {
          this.errorMessage = preview.error.substring(0, preview.error.search('\\.\\.')) + '.'
        }
      } else {
        if (this.ccForm.formIsValid) {
          this.subscriptionProrateTotal = preview.prorate;
          this.subscriptionTotal = preview.monthly;
          this.retrievedPreview = true;
          this.errorMessage = '';
        } else {
          // form changed state while we were away
          this.retrievedPreview = false;
          this.subscriptionProrateTotal = 0;
          this.subscriptionTotal = 0;
          this.errorMessage = '';
        }
      }
      this.prorateCalc = false;
    } else {
      this.retrievedPreview = false;
      this.subscriptionProrateTotal = 0;
      this.subscriptionTotal = 0;
      this.errorMessage = '';
    }

    this.getParams = function () {
      return params
    }
  }

  async updateExistingPaymentMethodChoice(event) {
    this.existingMethodChoice = event.target.value;
    this.currentToken = '';

    // this.unregisteredDevices.forEach(ud => {
    //   ud.billing_info_id = event.target.value;
    // });

    if (this.planService.isDefaultSub(event.target.value)) {
      this.retrievedPreview = false;
      return;
    }

    const params = {
      billing_info_id: this.existingMethodChoice,
      site_id: this.user.active.id,
      devices: this.unregisteredDevices,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    this.getParams = function () {
      return params
    }

     this.prorateCalc = true;

    const preview = await this.siteService.getChargePreview(params)
    if ('error' in preview) {
      // then we failed.
      console.error(preview.error)
      this.retrievedPreview = false;
      this.subscriptionProrateTotal = 0;
      this.subscriptionTotal = 0;
      // if there is a .. in the error then use before that as the message. Else use stock.
      this.errorMessage = "Please enter a valid Site Address/City/State/Zip Code";
      if (preview.error.includes('..')) {
        this.errorMessage = preview.error.substring(0, preview.error.search('\\.\\.')) + '.'
      }
    } else {
      if (event.target.value != 'unselected') {
        this.subscriptionProrateTotal = preview.prorate;
        this.subscriptionTotal = preview.monthly;
        this.retrievedPreview = true;
        this.errorMessage = '';
      } else {
        // mr fast fingers...
        this.retrievedPreview = false;
        this.subscriptionProrateTotal = 0;
        this.subscriptionTotal = 0;
      }
    }
    //load.dismiss();
    this.prorateCalc = false;
  }

  async processPayment() {


    if (this.ccForm && this.ccForm.tokenIsDirty) {
      // then we need to refresh the token
      this.autoProcessAfterToken = true;
      this.ccForm.submitTokenRequest(true);
      return
    }

    this.errorMessage = ""; // reset this
    const message_template = '<div>Registering gateway(s)...</div><div>(This may take a few minutes)</div>';

    const load = await this.loadingController.create({
      spinner: 'lines',
      message: message_template,
    });
    load.present();

    const params = {
      site_id: this.user.active.id,
      devices: this.unregisteredDevices,
      ...this.getParams(),
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    const waitTimeSecs = 5;
    const totalWaitTimeSecs: number = 10 * 60;
    // const totalWaitTimeSecs: number = 10;
    const totalTicks: number = round(totalWaitTimeSecs / waitTimeSecs);
    const devicesReadyTracker = new Map();

    // init our tracking map object - and the allRMD boolean
    let allRMDDevices = true;
    for (let i = 0; i < this.unregisteredDevices.length; i++) {
      const device = this.unregisteredDevices[i];
      if (device.model_class_name === GatewayModelClass.MCC) {
        devicesReadyTracker.set(device.serial_number + device.registration_code, MccRegistrationStatusEnum.INPROGRESS);
        allRMDDevices = false;
      } else {
        // fake out the RMD ones to be ready now.
        devicesReadyTracker.set(device.serial_number + device.device_key, MccRegistrationStatusEnum.READY);
      }
    }

    if (allRMDDevices) {
      //then go ahead - no special MCC handing needed
      this.registerDevices(params, load);
      this.accountService.subscriptionDetailsUpdated = true;
    } else {
      // wait for some MCC's to be ready
      const myTimer: Observable<number> = timer(0, waitTimeSecs * 1000);

      const timeSubscription: Subscription = myTimer.subscribe(tick => {
        // walk all the unregistered devices and check whether they are now ready
        this.unregisteredDevices.forEach(device => {

          // only check MCC devices, RMDs are ready right away
          if (device.model_class_name == GatewayModelClass.MCC) {
            this.siteService.getMccStatus(device.serial_number, device.registration_code).subscribe({next: result => {
              if (result['message'] === "READY") {
                devicesReadyTracker.set(device.serial_number + device.registration_code, MccRegistrationStatusEnum.READY);
              }
              if (tick >= totalTicks && result['message'] !== "READY") { // reached the max time allowed and still not ready
                devicesReadyTracker.set(device.serial_number + device.registration_code, MccRegistrationStatusEnum.FAILED);
              }

              //all devices are being checked at once going to need to check if the others are finished with their checks
              let allDevicesHaveFinished = true;
              const keys = Array.from(devicesReadyTracker.keys());
              for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                if (devicesReadyTracker.get(key) == MccRegistrationStatusEnum.INPROGRESS) {
                  allDevicesHaveFinished = false;
                  break;
                }
              }
              if (allDevicesHaveFinished) { //all devices are either Ready or have timeout
                let allDevicesReady = true;
                for (let j = 0; j < keys.length; j++) {
                  const key = keys[j];
                  if (devicesReadyTracker.get(key) == MccRegistrationStatusEnum.FAILED) {
                    allDevicesReady = false;
                  }
                }

                if (allDevicesReady) {
                  this.registerDevices(params, load);
                  this.accountService.subscriptionDetailsUpdated = true;
                  timeSubscription.unsubscribe();
                } else {
                  this.errorMessage = "Unable to register gateway at this time, please try again later.";
                  load.dismiss();
                  timeSubscription.unsubscribe();
                }
              }
            }, error: 
            err => {
              // something went wrong.
              this.errorMessage = "Unable to register gateway at this time, please try again later.";
              load.dismiss();
              timeSubscription.unsubscribe();
            }});
          }
        });
      });
    }
  }

  async registerDevices(params, spinner) {
    // need to validate that the gateway(s) registered with the DCC before starting to process the payment
    const resp = await this.siteService.registerDevice(params).then(result => {
      if (result == 204) {
        this.paymentError = true;
        this.paymentErrorMessage = "There was a problem during payment processing, please check your details and try again"
      } else {
        // is there an error attribute in the response?
        if (result.error) {
          //this.AddressError = result.error
          this.paymentError = true;
          this.paymentErrorMessage = result.error;
          this.registrationError = true;
        }
      }
      spinner.dismiss();

      if (!this.paymentError) {
        this.siteService.emit_siteGatewayCreated(this.unregisteredDevices);
        // tell account service it needs to refresh
        this.accountService.subscriptionDetailsUpdated = true;
        this.accountService.paymentDetailsUpdated = true;
        this.successfulNavigation(1);
        this.customRefreshSites();
      }

    }).catch((err) => {
      spinner.dismiss();
      this.errorMessage = "Unable to register gateway at this time, please try again later.";
      // dismiss model if critical site error
      if (err?.message && err?.message.includes("kenza rule")) this.modalController.dismiss()
    });
  }

  async customRefreshSites() {
    const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
    siteAlertFilter.site_id = this.user.active.id;
    siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;
    await this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
  }

  async displayInformation(headerText: string, messageText: string) {
    const alert = await this.alertController.create({
      header: headerText,
      message: messageText,
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
  }


  async onCancel() {

    if (!this.siteService.handleIsConnected()) {
      this.onClose();
      return;
    }


    const alert = await this.alertController.create({
      header: 'Cancel Gateway Registration',
      message: '<div class="me-redClass">Are you sure you want to cancel?</div></br><div class="me-centerText">If you cancel, your information will not be saved.</div>',
      backdropDismiss: false,
      cssClass: 'me-alert-registratin-buttons me-cancel-registration-alert',

      buttons: [
        {
          text: 'Yes',
          cssClass: 'exit-button',
          handler: () => {
            this.modalController.dismiss({
              cancelled: true,
            });
          }
        }, {
          text: 'No',
          cssClass: 'back-button',
          // handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  onClose() {
    this.modalController.dismiss({
      cancelled: false,
    });
  }


  //temporary method to allow quick input during development
  rmdEasyButton() {
    this.model.class_name = GatewayModelClass.RMD;

    const mock_unit = RMD_MOCK_UNITS[this.easy_index]
    const mock_name = "Engineering Center "+int2roman(this.easy_index+1);
    const mock_serial_number = mock_unit[0]
    const mock_mac_address = mock_unit[1]
    const mock_device_key = mock_unit[2]

    // then take this one.
    this.rmd_gatewayRegistrationForm.controls['gateway_name'].setValue(mock_name);
    this.rmd_gatewayRegistrationForm.controls['serial_number'].setValue(mock_serial_number);
    this.rmd_gatewayRegistrationForm.controls['mac_address'].setValue(mock_mac_address);
    this.rmd_gatewayRegistrationForm.controls['device_key'].setValue(mock_device_key);

    this.easy_index += 1;
    if ( this.easy_index > RMD_MOCK_UNITS.length ) this.easy_index = 0;
  }

  mccEasyButton() {
    this.model.class_name = GatewayModelClass.MCC;

    let len = 14;
    const chars = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let reg_code = '';
    while (len--) reg_code += chars[Math.floor(Math.random() * chars.length)];

    this.mcc_gatewayRegistrationForm.controls['mcc_gateway_name'].setValue('Fake MCC '+int2roman(this.easy_index+1));
    this.mcc_gatewayRegistrationForm.controls['mcc_serial_number'].setValue('12345-123');
    this.mcc_gatewayRegistrationForm.controls['mcc_registration_code'].setValue(reg_code);

    this.easy_index += 1;
    if (this.easy_index > RMD_MOCK_UNITS.length) this.easy_index = 0;

  }
}
