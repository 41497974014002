import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ModelState,
  KenzaCloudMenuIds,
  LevelEnum,
  PermissionDetailEnum,
} from 'src/app/enumerations/enums';
import { Account } from 'src/app/features/account/classes/account';
import { SiteUI } from 'src/app/common/classes/ui';
import {
  GridDynamicConfig,
  GridRow,
} from 'src/app/common/classes/griddynamicconfig';
import { UserService } from 'src/app/common/services/user/user.service';
import { Logger } from 'src/app/common/services/logging/log.service';
import { Subscription } from 'rxjs';
import { AccountFiller } from 'src/app/constants/fillers';
import { ModalController } from '@ionic/angular';
import { Site } from 'src/app/features/sites/classes/site';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { SiteMemberRoles } from 'src/app/constants/sitememberroles';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SiteAlertFilter } from 'src/app/common/classes/filters';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Log } from 'src/app/common/services/logging/log.service';
import { AccountDetail } from 'src/app/features/account/classes/accountdetail';
import { DateStringUtilities } from 'src/app/common/utilities/stringUtilities';
import { SiteAuthorizationService } from 'src/app/features/sites/services/site-authorization.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { SiteMemberAdminPage } from 'src/app/features/sites/components/site-member-admin/site-member-admin.page';
import { SiteMemberDetailAdminPage } from 'src/app/features/sites/components/site-member-detail-admin/site-member-detail-admin.page';
import { UninviteComponent } from 'src/app/features/sites/components/uninvite/uninvite.component';
import { SiteMemberRemovePage } from 'src/app/features/sites/components/site-member-remove/site-member-remove.page';
import { devEnv } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-site-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss'],
})

export class ManageMembersComponent implements OnInit, OnDestroy {
  imageUrl: string;
  memberSince: string;
  @ViewChild('uploadPictureFile') uploadPictureFile: ElementRef;
  accountDetail: AccountDetail;

  uploadPictureFileMessage: string;

  onActiveSiteChangedSubscription: Subscription = null;
  memberModelState = ModelState;
  memberList: Account[] = [];
  inviteList: Array<any> = [];
  siteUIList: SiteUI[] = [];
  defaultSiteExpanded = false;
  gridDynamicConfig: GridDynamicConfig = {
    columnSize: 0,
    columnsPerRow: 0,
    RowCount: 0,
    RowData: [],
  };

  dataLoading: boolean;
  modalOpen = false;
  devEnv = devEnv;

  constructor(
    private logger: Logger,
    public user: UserService,
    private modalController: ModalController,
    public appAuth: AppAuthenticationService,
    public siteAuthorizationSrvice: SiteAuthorizationService,
    public mainSiteUIService: MainSiteUIService,
    private router: Router,
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute
  ) {
    this.imageUrl = '';

    // perform refresh on initialization
    this.refreshMemberListByActiveSite(this.user.active.id, true);
  }

  ionViewWillEnter() {
    // perform refresh post initialization
    if (this.onActiveSiteChangedSubscription == null) {
      this.onActiveSiteChangedSubscription = this.user.activeSiteChanged.subscribe(
        (activeSite: any) => {
          this.refreshMemberListByActiveSite(activeSite.id, true);
        }
      );
    }
  }

  ionViewWillLeave() {
    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe()
      this.onActiveSiteChangedSubscription = null;
    }
  }

  ngOnDestroy(): void {
    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    if (
      this.activatedRoute.snapshot.paramMap.has('accountId') &&
      this.activatedRoute.snapshot.paramMap.has('showAs') &&
      this.activatedRoute.snapshot.paramMap.get('showAs') === 'memberdetail'
    ) {
      const site_member_account_id: string = this.activatedRoute.snapshot.paramMap.get(
        'accountId'
      );
      this.displayMemberDetails(site_member_account_id);
    }
  }

  handleSiteMemberClick(siteMemberAccount: Account) {
    if (!this.siteService.handleIsConnected()) return;

    if (siteMemberAccount.id !== this.user.id) {
      this.mainSiteUIService.viewSiteAlert('toManageMemberDetail');
      this.router.navigate([
        '/manage',
        this.user.active.id,
        'members',
        siteMemberAccount.id,
      ]);
    } else {
      this.user.backToSiteMembers = true;
      this.user.lastSite = this.user.active.id;
      this.router.navigate([
        '/account',
        this.user.id,
        'details',
      ]);
    }
  }

  backToManageMembers() {
    this.router.navigate(['/manage', this.user.active.id, 'members']);
  }

  displayMemberDetails(site_member_account_id: string) {
    this.displayAccountPicture(site_member_account_id);
    this.getAccountDetails(site_member_account_id);
  }

  displayAccountPicture(account_id) {
    this.user
      .getAccountPhotoUrl(account_id)
      .then((accountPictureUrl) => {
        this.imageUrl = '';
        if (accountPictureUrl.length > 0) {
          this.imageUrl = accountPictureUrl;
        }
      })
      .catch((err) => Log.error(err));
  }

  getAccountDetails(account_id: string) {
    this.user.getSiteAccountDetails(this.user.active.id, account_id).subscribe(
      (data: any) => {
        const acctDetail: AccountDetail = new AccountDetail();

        if (data.email) {
          acctDetail.id = data.id ? data.id : '';
          acctDetail.account_photo_name = '';
          acctDetail.country = '';
          acctDetail.last_login = null;
          acctDetail.removed_at = null;
          acctDetail.status = 0;
          acctDetail.fname = data.fname ? data.fname : '';
          acctDetail.lname = data.lname ? data.lname : '';
          acctDetail.title = data.title ? data.title : '';
          acctDetail.phone = data.phone ? data.phone : '';
          acctDetail.email = data.email ? data.email : '';
          acctDetail.created_at = DateStringUtilities.formatDateString(
            data.created_at,
            'en-US',
            'MM/dd/yyyy'
          );

          acctDetail.company = data.company ? data.company : '';
          acctDetail.company_phone = data.company_phone
            ? data.company_phone
            : '';
          acctDetail.street_line1 = data.street_line1 ? data.street_line1 : '';
          acctDetail.street_line2 = data.street_line2 ? data.street_line2 : '';

          acctDetail.city = data.city ? data.city : '';
          acctDetail.state = data.state ? data.state : '';
          acctDetail.postal_code = data.postal_code ? data.postal_code : '';
        } else {
          acctDetail.id = data.id ? data.id : '';
          acctDetail.account_photo_name = '';
          acctDetail.country = '';
          acctDetail.last_login = null;
          acctDetail.removed_at = null;
          acctDetail.status = 0;
          acctDetail.fname = '';
          acctDetail.lname = '';
          acctDetail.title = '';
          acctDetail.phone = '';
          acctDetail.email = data.email;
          acctDetail.created_at = DateStringUtilities.formatDateString(
            data.created_at,
            'en-US',
            'MM/dd/yyyy'
          );

          acctDetail.company = '';
          acctDetail.company_phone = '';
          acctDetail.street_line1 = '';
          acctDetail.street_line2 = '';

          acctDetail.city = '';
          acctDetail.state = '';
          acctDetail.postal_code = '';
        }

        this.memberSince = DateStringUtilities.formatDateString(
          data.created_at,
          'en-US',
          'MMMM dd, yyyy'
        );
        this.accountDetail = acctDetail;
      },
      (error) => {
        console.log('error:', error);
      }
    );
  }

  display_name(fname: string, lname: string, email: string): string {
    let displayName = '';

    if (fname || lname) {
      if (fname && lname) {
        displayName = fname + ' ' + lname;
      } else if (fname) {
        displayName = fname;
      } else if (lname) {
        displayName = lname;
      }
    } else {
      displayName = email;
    }

    return displayName;
  }

  // UI Events
  adminSiteMember(modelState: ModelState) {
    if (!this.siteService.handleIsConnected()) return;

    switch (modelState) {
      case ModelState.Create:
        this.openSiteMemberAdminPage(ModelState.Create);
        break;
    }
  }

  trim(e) {
    if (e && e.target) e.target.value = e?.target?.value?.trim();
  }

  async openSiteMemberAdminPage(inviteState: ModelState) {
    if (this.modalOpen == false) {
      const modal = await this.modalController.create({
        component: SiteMemberAdminPage,
        cssClass: 'me-custom-site-member-detail-admin-modal-css',
        componentProps: {
          parentModelState: inviteState,
          parentSelectedMember: null,
        },
      });
  
      modal.onDidDismiss().then((data) => {
        this.modalOpen = false;
        if (data.data?.cancelled === false) {
          this.refreshMemberListByActiveSite(this.user.active.id, true);
        }
      });
  
      this.modalOpen = true;
      return await modal.present();
    } else {
      return;
    }
  }

  async openSiteMemberDetailAdminPage(
    siteId: string,
    siteName: string,
    memberDetail: Account
  ) {
    if (!this.siteService.handleIsConnected()) return;

    const modal = await this.modalController.create({
      component: SiteMemberDetailAdminPage,
      cssClass: 'me-custom-site-member-detail-admin-modal-css',
      componentProps: {
        parentSiteId: siteId,
        parentSiteName: siteName,
        parentMember: memberDetail,
      },
    });

    modal.onDidDismiss().then((data) => {
      const responseKenzaPermission = data.data.modalResponseData;

      if (responseKenzaPermission) {
        this.updateSiteMemberRole(
          responseKenzaPermission.account_id,
          responseKenzaPermission.level
        );
        this.refreshMemberListByActiveSite(
          responseKenzaPermission.site_id,
          false
        );
        const siteAlertFilter: SiteAlertFilter = this.mainSiteUIService.getSiteAlertFilter();
        siteAlertFilter.site_id = this.user.active.id;
        siteAlertFilter.UserLevelEnum = this.user.activeSiteUserLevel;

        this.mainSiteUIService.refreshSiteAlertData(siteAlertFilter);
      }
    });

    return await modal.present();
  }

  async uninviteMember(inviteObj, pending) {
    if (!this.siteService.handleIsConnected()) return;

    const memberIsMeBool: boolean = this.memberIsMe(inviteObj);

    const modal = await this.modalController.create({
      component: UninviteComponent,
      cssClass: 'me-custom-modal-site-member-uninvite',
      componentProps: {
        invite: inviteObj,
        pending: pending,
        memberIsMe: memberIsMeBool,
      },
    });

    modal.onDidDismiss().then((data) => {
      // if you just removed yourself, then you need to select a new site.
      if (data && data.data && data.data.success) {
        if (memberIsMeBool) {
          this.user.updateSites();
          this.router.navigate(['/home']);
        } else {
          this.refreshMemberListByActiveSite(this.user.active.id, true);
        }
      }
    });

    return await modal.present();
  }

  async openSiteMemberRemovePage(
    siteId: string,
    siteName: string,
    account_id: string,
    accountEMail: string,
    siteLevel: number
  ) {
    const modal = await this.modalController.create({
      component: SiteMemberRemovePage,
      cssClass: 'me-custom-modal-site-member-remove',
      componentProps: {
        parentSiteId: siteId,
        parentSiteName: siteName,
        parentAccountId: account_id,
        parentAccountEMail: accountEMail,
        parentAccountSiteLevel: siteLevel
      },
    });

    modal.onDidDismiss().then((data) => {
      this.refreshMemberListByActiveSite(this.user.active.id, true);
      if (
        data.data !== undefined &&
        data.data.removedCurrentUser !== undefined &&
        data.data.removedCurrentUser !== null
      ) {
        if (data.data.removedCurrentUser === true) {
          const selectedSiteInfo: Site = new Site({
            id: '',
            name: 'Select Site',
            phone: '',
            company: '',
            addressOne: '',
            addressTwo: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            site_photo_name: '',
            sitestatustype_id: 1,
            current_owner_id: '',
            locations: []
          });
          this.user.clearSites();
          this.user.updateSites();
          this.user.setActiveSiteAndNavigate(
            selectedSiteInfo,
            KenzaCloudMenuIds.Home,
            KenzaCloudMenuIds.Home
          );
        }
      }
    });
    return await modal.present();
  }

  updateSiteMemberRole(account_id: string, level: number) {
    this.user.activeSiteAccounts = this.user.activeSiteAccounts.map(
      (account) => {
        if (account.id !== account_id) {
          return account;
        } else {
          account.level = Number(level);
          account.levelDisplayText = SiteMemberRoles.list[level - 1].name;
          return account;
        }
      }
    );
  }

  async refreshMemberListByActiveSite(
    activeSiteId: string,
    refreshAccountPhoto: boolean
  ) {
    this.dataLoading = true;
    await this.user.refreshSiteAccounts(this.user.active.id);
    this.dataLoading = false;

    this.memberList = this.user.activeSiteAccounts;
    this.inviteList = this.user.activeSiteInvites.map((x) => {
      x['id'] = x['invite'];
      return x;
    });
    this.siteUIList = this.updateUIList(
      this.memberList.concat(this.inviteList)
    );
    this.gridDynamicConfig = this.getGridDynamicConfig(this.memberList);
    let mIndex = 0;

    if (refreshAccountPhoto) {
      for (mIndex = 0; mIndex < this.memberList.length; mIndex++) {
        const accountPhotoUrl = await this.user.getAccountPhotoUrl(
          this.memberList[mIndex].id
        );
        this.memberList[mIndex].account_photo_url = accountPhotoUrl;
      }
    }
  }

  // SUPPORT Methods
  updateUIList(members: Array<any>) {
    const newUIList: SiteUI[] = [];
    let updatedUIList: SiteUI[] = [];

    if (members && members.length > 0) {
      let index = 0;

      for (index = 0; index < members.length; index++) {
        const newUI = this.siteUIList.find(
          (siteUI) => siteUI.id == members[index].id
        );
        if (newUI == undefined) {
          newUIList.push({
            id: members[index].id,
            isExpanded: this.defaultSiteExpanded,
            status: '',
          });
        }
      }
    }
    updatedUIList = this.siteUIList.concat(newUIList);
    const siteUIIndex = 0;

    updatedUIList = updatedUIList.map((siteUI) => {
      siteUI.status = '';

      if (siteUIIndex % 2) {
        siteUI.status = 'alertRed';
      }

      if (siteUIIndex % 3) {
        siteUI.status = 'alertYellow';
      }
      return siteUI;
    });

    return updatedUIList;
  }

  levelDisplayText(level) {
    const obj = {
      1: 'Viewer',
      2: 'User',
      3: 'Administrator',
      4: 'Owner',
    };
    return obj[level];
  }

  getGridDynamicConfig(accounts: Account[]): GridDynamicConfig {
    const gridDynamicConfig: GridDynamicConfig = new GridDynamicConfig();
    gridDynamicConfig.columnSize = 3;
    gridDynamicConfig.columnsPerRow = 3;
    gridDynamicConfig.RowData = [];

    const val = accounts.length / gridDynamicConfig.columnsPerRow;
    let rowCount = Math.floor(val);
    if (val - rowCount > 0) {
      rowCount++;
    }

    gridDynamicConfig.RowCount = rowCount;

    if (gridDynamicConfig.RowCount > 0) {
      let rowIndex = 0;
      let index = 0;

      for (rowIndex = 0; rowIndex < gridDynamicConfig.RowCount; rowIndex++) {
        const gridRow: GridRow = new GridRow();
        gridRow.accounts = [];
        let columnIndex = 0;

        while (
          columnIndex < gridDynamicConfig.columnsPerRow &&
          index < accounts.length
        ) {
          gridRow.accounts.push(accounts[index]);
          index++;
          columnIndex++;
        }

        // check for the need to add filler columns
        if (
          rowIndex == gridDynamicConfig.RowCount - 1 &&
          gridRow.accounts.length < gridDynamicConfig.columnsPerRow
        ) {
          const fillerColumnCount =
            gridDynamicConfig.columnsPerRow - gridRow.accounts.length;

          for (
            let fillerIndex = 0;
            fillerIndex < fillerColumnCount;
            fillerIndex++
          ) {
            const accountFiller = AccountFiller;
            gridRow.accounts.push(accountFiller);
          } // end : for(let fillerIndex=0; fillerIndex<fillerColumnCount; fillerIndex++){
        } // end : if((rowIndex== gridDynamicConfig.RowCount-1) && (gridRow.members.length<gridDynamicConfig.columnsPerRow)){

        gridDynamicConfig.RowData.push(gridRow);
      } // end :     if(gridDynamicConfig.RowCount > 0){
    } // end :       for(rowIndex=0; rowIndex< gridDynamicConfig.RowCount; rowIndex++){

    return gridDynamicConfig;
  }

  getIsMemberExpanded(account: Account) {
    if (account && this.siteUIList && this.siteUIList.length > 0) {
      const siteUI: SiteUI = this.siteUIList.find(
        (siteUI) => siteUI.id === account.id
      );
      if (siteUI) {
        return siteUI.isExpanded;
      } else {
        return this.defaultSiteExpanded;
      }
    } else {
      return this.defaultSiteExpanded;
    }
  }

  toggleMemberInfo(account: Account) {
    if (account) {
      this.siteUIList.find(
        (siteUI) => siteUI.id === account.id
      ).isExpanded = !this.siteUIList.find((siteUI) => siteUI.id === account.id)
        .isExpanded;
    }
  }

  permissionCheckCanRemoveSiteMember(
    activeSiteUserLevel: LevelEnum,
    siteMemberLevel: LevelEnum,
    permissionEnum: PermissionDetailEnum
  ): boolean {
    const result: boolean = true;

    if (
      this.appAuth.doesLevelHavePermission(
        activeSiteUserLevel,
        this.appAuth.permissionEnums.AddRemoveMembers
      ) == false
    ) {
      return false;
    }

    return result;
  }

  memberIsMe(siteMember: Account) {
    return this.user.id == siteMember.id;
  }

  memberIsMeAndNotOwner(siteMember: Account) {
    // is this account me?
    return this.memberIsMe(siteMember) && siteMember.level != LevelEnum.Owner;
  }
}
