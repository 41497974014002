import { ApplicationErrorsEnum } from 'src/app/enumerations/enums';

export const ErrorTypes = {
    [ApplicationErrorsEnum.WEBSOCKET_DISCONNECT]: {
        'title': 'Connectivity Issue',
        'code': '6-1111',
        'message': 'Unable to retrieve gateway data, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.WEBSOCKET_TIMEOUT]: {
        'title': 'Connectivity Issue',
        'code': '6-1112',
        'message': 'Unable to retrieve gateway data, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.WEBSOCKET_RECONNECT_ATTEMPT]: {
        'title': 'Connectivity Issue',
        'code': '6-1113',
        'message': 'Unable to retrieve gateway data, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.WEBSOCKET_NOT_CONNECTED]: {
        'title': 'Connectivity Issue',
        'code': '6-1114',
        'message': 'Unable to retrieve gateway data, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.MAPUNITS_ERROR]: {
        'title': 'Map Units Error',
        'code': '6-1211',
        'message': 'Issue with mapping units, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.MAPUNITS_WEBSOCKET_DISCONNECT]: {
        'title': 'Map Units Error',
        'code': '6-1212',
        'message': 'Issue with mapping units, try again later.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.FIRMWARE_UPDATE_FAILED_TO_START]: {
        'title': 'Firmware Update Failed to Start',
        'code': '6-2111',
        'message': 'Issue starting firmware update, try again later.  If problem persists restart the MCC device.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.FIRMWARE_NEWEST_VERSION_ALREADY_INSTALLED]: {
        'title': 'Newest Firmware Version Already Installed',
        'code': '6-2112',
        'message': 'Newest firmware is already installed, no further action needed.',
        'timeout': 5
    },
    [ApplicationErrorsEnum.COGNITO_ACCESS_ISSUES]: {
        'title': 'Authentication Error ',
        'code': '5-0010',
        'message': 'Unable to sign in at this time, please try again later.',
        'timeout': 5
    }
}