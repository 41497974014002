<ion-header>
  <ion-toolbar>
    <ion-title>Move Gateway to a Different Site</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-center">
  <ion-grid class="ion-no-padding">
    <br>
    <ion-row white-background>
      <ion-col col col-12 me-header-col>
        <ion-label color="danger">
          You are moving this gateway to a site that has a<br>
          different payment method associated with it
        </ion-label>
      </ion-col>
    </ion-row>
    <br>
    <ion-row white-background>
      <ion-col col col-12 me-header-col>
        <ion-label>
          New Site Payment Method: {{cardType}} ending in {{cardLastFour}}
        </ion-label>
      </ion-col>
    </ion-row>
    <br>
    <ion-row white-background style="padding-left: 20px; padding-right: 20px;">
      <ion-col col col-12>
        <ion-label>
          Note the following:
        </ion-label>
        <ul>
          <li class="li-padding">This gateway’s payment method will be updated to match the new site.</li>
          <li class="li-padding">This new payment method will be used at the beginning of the next billing cycle.</li>
        </ul>
      </ion-col>
    </ion-row>
  </ion-grid>


</ion-content>

<!--Footer-->
<ion-footer>
  <ion-grid style="padding: .5em">
    <ion-row class="ion-justify-content-between">
      <ion-col class="flex ion-justify-content-end ion-align-items-center">
        <ion-label *ngIf="moveError" style="flex:auto" color="danger" class="moveError">{{moveError}}</ion-label>
        <ion-button style="margin: 0 0 0 1em;" color="primary" (click)="moveGateway()" [disabled]="moveError!=''">
          Move
        </ion-button>
        <ion-button id="btnCancelMove" name="btnCancelMove" (click)="cancelMove()" style="margin: 0 0 0 1em;"
          color="reverse-primary">
          Cancel
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>