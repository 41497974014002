import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appHighlightButton]'
})
export class HighlightButtonDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.highlight();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.highlight();
        this.el.nativeElement.style.textDecoration = '';
    }

    private highlight() {
        this.el.nativeElement.style.textDecoration = 'underline';
    }
}
