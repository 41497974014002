import { GatewayUnitTwoDigitType, TemperaturePreferenceEnum, SiteControlGroupStatusEnum, GatewayUnitTwoDigitTypeToEnum, LevelEnum, SubscriptionFeatures, GroupType, GroupSelectorFilterTypes, MaintenanceJobTypeEnum, MaintenanceJobTypeEnumLabels, MaintenanceJobTypeEnumDescriptions } from 'src/app/enumerations/enums';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { GatewayUnit, AirDirection, FanSpeed, Power, Mode, VentMode, Temp } from '../../../features/manage/components/classes/GatewayUnit';
import { ProfileUpdateResponse } from 'src/app/common/services/websockets/classes/responses/WebSocketResponses';
import { Gateway, GatewayConnection, GatewayModel } from '../../../features/manage/components/classes/Gateway';
import { SearchAndFilterComponent } from '../search-and-filter/search-and-filter.component';
import { GatewayGroup } from '../../../features/manage/components/classes/GatewayGroup';
import { TemperatureConversions } from 'src/app/common/utilities/conversionUtilities';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { SocketService } from 'src/app/common/services/websockets/socket.service';
import { SiteService } from '../../../features/sites/services/site.service';
import { UserService } from 'src/app/common/services/user/user.service';
import { maintenanceJobStatusEnum } from '../../classes/MaintenanceJob';
import { SiteAlertMetricsData } from 'src/app/common/classes/Metrics';
import { WindowService } from '../../services/ui/window.service';
import { AlertController, IonSearchbar } from '@ionic/angular';
import { DisplayOptions } from '../../classes/displayOptions';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subscription } from 'rxjs';

export class TrackedGroupState {
  // state of the group/unit to show on the scroll bar
  drive_on: string;
  mode: Mode;
  statusIndex: SiteControlGroupStatusEnum;
  statusTitle: string;
  inlet_temp: string;
  vent_mode: VentMode;
  fan_speed: string;
  air_direction: AirDirection;
  maintenance_job_type: MaintenanceJobTypeEnum;
  maintenance_job_type_label: string;

  constructor(unit: GatewayUnit, gatewayMaintenanceJobTypeId: MaintenanceJobTypeEnum = MaintenanceJobTypeEnum.None) {
    // populate based on provided unit
    this.vent_mode = unit ? unit.vent_mode : VentMode.default;
    this.inlet_temp = unit ? unit.inlet_temp : Temp.unk;
    this.mode = unit ? unit.mode : Mode.cool;
    this.drive_on = unit ? unit.power : Power.default;
    this.fan_speed = unit ? unit.fan_speed : FanSpeed.medium;
    this.air_direction = unit ? unit.air_direction : AirDirection.default;
    this.maintenance_job_type = MaintenanceJobTypeEnum.None;
    // set the status, title and maintenance job type/label
    this.setToOk();
    if (gatewayMaintenanceJobTypeId != MaintenanceJobTypeEnum.None) this.setToMaintenanceJob(gatewayMaintenanceJobTypeId);
  }

  setToAlert(numberOfAlerts: number) {
    // set the state of this group to be in alert
    this.statusIndex = SiteControlGroupStatusEnum.Alert;
    this.statusTitle = numberOfAlerts + ' active alert(s) for this group';    
  }

  setToOk() {
    this.statusIndex = SiteControlGroupStatusEnum.OK;
    this.statusTitle = "No active alerts for this group";
  }

  setToMaintenanceJob(jobType: MaintenanceJobTypeEnum) {
    this.statusIndex = SiteControlGroupStatusEnum.Maintenance_Job_Active;
    this.maintenance_job_type = jobType;
    if (jobType == MaintenanceJobTypeEnum.Test_Run) {
      this.statusTitle = MaintenanceJobTypeEnumDescriptions.Test_Run;
      this.maintenance_job_type_label = MaintenanceJobTypeEnumLabels.Test_Run;
    } else if (jobType == MaintenanceJobTypeEnum.Incorrect_Port) {
      this.statusTitle = MaintenanceJobTypeEnumDescriptions.Incorrect_Port;
      this.maintenance_job_type_label = MaintenanceJobTypeEnumLabels.Incorrect_Port;
    } else if (jobType == MaintenanceJobTypeEnum.Refrigerant) {
      this.statusTitle = MaintenanceJobTypeEnumDescriptions.Refrigerant;
      this.maintenance_job_type_label = MaintenanceJobTypeEnumLabels.Refrigerant;
    } else if (jobType == MaintenanceJobTypeEnum.Alert_Dump) {
      this.statusTitle = MaintenanceJobTypeEnumDescriptions.Alert_Dump;
      this.maintenance_job_type_label = MaintenanceJobTypeEnumLabels.Alert_Dump;
    }
  }
}

export class SelectedGroupGateway {
  // select details about the gateway of the group
  id: string;
  serial_number: string;
  connection: GatewayConnection;
  subscription_features = {}; // its a dictionary object
  model: GatewayModel;
  site_id: string;
  name: string;
  maintenanceJobType: MaintenanceJobTypeEnum.None;
  gatewaySubscriptionIsExpired:boolean;

  _supportsFeature(subscriptionFeature: string, value: string = SubscriptionFeatures.INCLUDED): boolean {
    // does this gateways have a feature called subscriptionFeature with a value value?

    if (Object.prototype.hasOwnProperty.call(this.subscription_features, subscriptionFeature)) {
      const sub_value = this.subscription_features[subscriptionFeature];
      return sub_value == value;
    }
    // then it does not.
    console.log('Unknown feature requested:' + subscriptionFeature);
    return false;
  }

  supportsControlFeature(): boolean {
    // does this gateway support the control subscription feature?
    return this._supportsFeature(SubscriptionFeatures.SYSTEM_CONTROL);
  }

  supportsScheduleFeature(): boolean {
    // does this gateway support the schedule subscription feature?
    return this._supportsFeature(SubscriptionFeatures.SYSTEM_SCHEDULING);
  }

  isConnected(): boolean {
    // is this gateway connected to the kenza cloud?
    return this.connection.connected.toLowerCase() == 'true';
  }

  isGatewaySubscriptionExpired(): boolean {
    // is this gateway on a limited expired subscription?
    return this.gatewaySubscriptionIsExpired;
  }

  init(gateway: Gateway) {
    // init the object from the gateway
    this.id = gateway.id;
    this.serial_number = gateway.serial_number;
    this.connection = gateway.connection;
    this.subscription_features = gateway.subscription_features;
    this.model = gateway.model;
    this.site_id = gateway.site_id;
    this.name = gateway.name;
    this.maintenanceJobType = gateway.maintenance_job ? gateway.maintenance_job.job_id : MaintenanceJobTypeEnum.None;
    this.gatewaySubscriptionIsExpired = gateway.subscription_expired;
  }
}

export class SelectedGroupGroup {
  autocreated: boolean;
  group_name: string;
  group_id: string;
  bus_address;

  init(group: GatewayGroup) {
    this.autocreated = group.autocreated;
    this.group_name = group.group_name;
    this.group_id = group.group_id;
    this.bus_address = group.bus_address;
  }
}

export class SelectedGroup {
  id: string;
  group_id: string;
  gateway_serial: string;
  type: GatewayUnitTwoDigitType;
  // state: SelectedGroupState
  gateway: SelectedGroupGateway;
  gatewayGroup: SelectedGroupGroup;
  gatewayUnits: GatewayUnit[];
  maintenanceJobId: MaintenanceJobTypeEnum;
  subscriptionExpired: boolean;

  init(group: TrackedGroup) {
    // initialize the group from the provided rest api group obejct.
    this.id = group.group_id;
    this.group_id = group.group_id;
    this.gateway_serial = group.gateway.serial_number;
    this.type = group.type;
    // this.state.init(group);
    this.gateway = new SelectedGroupGateway();
    this.gateway.init(group.gateway);
    this.gatewayGroup = new SelectedGroupGroup();
    this.gatewayGroup.init(group.gatewayGroup);
    this.gatewayUnits = group.gatewayUnits;
    this.maintenanceJobId = group.state.maintenance_job_type;
    this.subscriptionExpired = group.gateway.subscription_expired;
  }
}

export class TrackedGroup {

  id: string;
  group_id: string; // same as id
  gateway: Gateway;
  gateway_serial: string;
  gatewayGroup: GatewayGroup;
  gatewayUnits: GatewayUnit[];
  name: string;

  state: TrackedGroupState;
  errorSelected: boolean;
  selected: boolean;
  type: GatewayUnitTwoDigitType;

  isLossnay(): boolean { return this.type === GatewayUnitTwoDigitType.Lossnay }
  isIndoorCoil(): boolean { return this.type === GatewayUnitTwoDigitType.IndoorUnit }
  isGatewaySubscriptionExpired():boolean { return this.gateway.subscription_expired }
}

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})

export class GroupSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(DragScrollComponent) childrenComponent: QueryList<DragScrollComponent>;
  @Input() title = "Select a Group";
  @Input() multiSelect = false;
  @Input() checkBoxFilters: boolean;
  @Output() newGroupSelected = new EventEmitter<SelectedGroup>();
  @Output() newGroupsSelected = new EventEmitter<SelectedGroup[]>();
  internalNewGroupsSelected = new EventEmitter<SelectedGroup[]>();
  @Output() stateMessage = new EventEmitter<string>();

  // Bar related
  previousScrollBar: DragScrollComponent = null;
  onRightSideScrollEnd: Subscription = null;
  onLeftSideScrollEnd: Subscription = null;
  onNewGroupsSelected: Subscription = null;
  showLeftScrollArrow = false;
  showRightScrollArrow = true;
  scrollBar: DragScrollComponent;
  
  // Search bar
  searchTerm = ``;
  useSearchFilterComponent = false;
  @ViewChild('groupSearchBar', { static: false }) groupSearchBar: IonSearchbar;
  @ViewChild(SearchAndFilterComponent) searchAndFilter: SearchAndFilterComponent;
  
  // Should the next change event on the scroll bar also perform a groupunit selection?
  // This is used during startup to select a unit after the scroll bar is created
  private performUnitSelect: boolean;

  // Loading spinner control
  loading = true;

  // Active group
  // selectedGroup: any;

  // Events that can trigger a reload of what we show present as gateways/groups to display
  onActiveSiteChangedSubscription: Subscription;
  profileUpdateSubscription: Subscription;
  siteGatewayChanged: Subscription;
  siteGatewayUnitChanged: Subscription;
  siteGatewayGroupCRUDEvent: Subscription;
  navigationSubscription: Subscription;
  childComponentSubscription: Subscription;
  siteAlertMetricsSubscription: Subscription;

  // Zero state control
  siteGatewaysExist = false;
  siteGatewayUnitsExist = false;
  displayDataNotAvailable = false;

  // Enums for HTML usage
  SubscriptionFeatures = SubscriptionFeatures;
  temperaturePreference = TemperaturePreferenceEnum;
  GroupSelectorFilterTypes = GroupSelectorFilterTypes;
  gatewayUnitTwoDigitTypeEnum = GatewayUnitTwoDigitType;
  siteControlGroupStatusEnum = SiteControlGroupStatusEnum;

  // Cached unit details to display based on gateway-serial-number
  cachedProfileUpdateMap = new Object();

  // Display lookups for html 
  do: DisplayOptions = new DisplayOptions;

  debug = false;

  tempSymbol: string; // Symbol to postfix into temperature display for degrees and C/F
  keyupFunction = null;
  keydownFunction = null;

  @ViewChildren('searchParam') searchParams: QueryList<ElementRef>;
  @ViewChildren('itemWrapper') itemWrappers: QueryList<ElementRef>;
  @ViewChildren('gsFilterCheckBox') gsFilterCheckBoxes: QueryList<ElementRef>;
  @ViewChild('filterSelectionList', { static: false }) filterSelectionList: ElementRef;
  @ViewChild('checkBoxFiltersButton', { static: false }) checkBoxFiltersButton: ElementRef;
  devEnv = devEnv;
  filterSelectionOpen = false;
  checkBoxSelectAllOpen = false;
  checkedFilterGroups: TrackedGroup[] = [];
  LastSelectedByOrder: any = [];
  allDefaultFilters = false;
  lastSelectedOrder = 1;
  collapseGroupSelector = false;
  docClicksListener = null;
  width: number;
  height: number;
  windowHeight = window.innerHeight;

  constructor(
    private mainSiteUIService: MainSiteUIService,
    private windowSizeService: WindowService,
    public alertController: AlertController,
    public siteService: SiteService,
    private socket: SocketService,
    private renderer: Renderer2,
    public user: UserService,
  ) {
    // Empty
  }

  ngOnInit() {
    this.init_ui();
    this.listenForShiftOnDragScroll();
    this.setWindowSizeVariables();
    this.collapseGroupSelector = localStorage.getItem(`CollapseGroupSelector`)
      ? JSON.parse(localStorage.getItem(`CollapseGroupSelector`)) : false;
  }

  expandCollapse() {
    this.collapseGroupSelector = !this.collapseGroupSelector;
    localStorage.setItem(`CollapseGroupSelector`, JSON.stringify(this.collapseGroupSelector));
  }

  setWindowSizeVariables() {
    this.windowSizeService.width$.subscribe(width => this.width = width);
    this.windowSizeService.height$.subscribe(height => this.height = height);
  }

  listenForShiftOnDragScroll() {
    // Not the most ideal or angular solution but it works for now
    // Will implement an optimized solution in the future.
    if (this.keydownFunction == null) this.keydownFunction = document.addEventListener(`keydown`, (e: KeyboardEvent) => {
      if (window.location.href.includes(`control`)) {
        if (e.code.includes(`Shift`)) {
          (<any>document.querySelector(`drag-scroll`)).onselectstart = function () {
            return false;
          }
        }
      }
    });

    if (this.keyupFunction == null) this.keyupFunction = document.addEventListener(`keyup`, (e: KeyboardEvent) => {
      if (window.location.href.includes(`control`)) {
        if (e.code.includes(`Shift`)) {
          (<any>document.querySelector(`drag-scroll`)).onselectstart = function () {
            return true;
          }
        }
      }
    });
  }

  ionViewWillEnter() {

    this.mainSiteUIService.allSiteGroups = [];
    this.mainSiteUIService.filteredSiteGroups = [];

    if (this.onNewGroupsSelected == null) {
      this.onNewGroupsSelected = this.mainSiteUIService.newGroupsSelected.subscribe((grps) => {
        this.mainSiteUIService.allSiteGroups = this.mainSiteUIService.allSiteGroups.map((grp, index) => {
          grp.selected = false;
          return grp as TrackedGroup;
        });
        // Select All Groups that match Type from Filtered
        this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.filteredSiteGroups.map((grp, index) => {
          if (grps?.map(gr => gr?.id).includes(grp?.id)) {
            grp.selected = true;
            return grp as TrackedGroup;
          } else {
            return grp as TrackedGroup;
          }
        });
        this.setLastSelectedFromFiltered();
        this.notifySelection();
      });
    }

    // Subscribe to profiles updates
    this.profileUpdateSubscription = this.socket.ProfileUpdateEmitter.subscribe(
      // A data update has arrived for a gateway
      (profileUpdateResponse) => {

        const profileUpdate = this.mapProfileUpdateResponse(profileUpdateResponse);

        const device_serial = profileUpdate.device_serial;
        if (device_serial in this.cachedProfileUpdateMap) {

          profileUpdate.units.forEach((unit) => {
            const unit_group_id = unit.group_id;
            if (unit_group_id) {
              // try to find maching row in this.cachedProfileUpdateMap[profileUpdate.device_serial].units
              const result = this.cachedProfileUpdateMap[device_serial].units.find((o, i) => {
                if (o.group_id == unit_group_id) {
                  this.cachedProfileUpdateMap[device_serial].units[i] = unit;
                  return true; // stop looking
                }
              })
              if (!result) {
                // then it wasn't there.  Add
                this.cachedProfileUpdateMap[device_serial].units.push(unit);
              }
            }
          })
        } else {
          // add new
          this.cachedProfileUpdateMap[profileUpdate.device_serial] = profileUpdate;
        }

        this.refreshSelectedGateway(profileUpdate);
      }
    );

    // subscribe to site change notifications
    this.onActiveSiteChangedSubscription = this.user.activeSiteChanged.subscribe(
      //the selected site has changed.
      activeSite => {
        if ((activeSite) && (activeSite.id) && (activeSite.id.toLowerCase() !== "default-site")) {
          this.mainSiteUIService.allSiteGroups = [];
          this.mainSiteUIService.filteredSiteGroups = [];
          this.dlog('rsgwg - activeSiteChanged');
          this.clearFilter();
          this.refreshSiteGatewaysWithGroups(this.user.active.id);
          this.init_ui();
        }
      }
    );

    // subscribe to gateway change notifications
    this.siteGatewayChanged = this.siteService.siteGatewayChanged.subscribe(
      // a gateway on the site has changed
      () => {
        this.dlog('rsgwg - sitechanged');
        this.refreshSiteGatewaysWithGroups(this.user.active.id);
      }
    );

    // subscribe to gateway group notifications
    this.siteGatewayGroupCRUDEvent = this.siteService.siteGatewayGroupCRUDEvent.subscribe(( /*crudDetails*/) => {
      // a group on the gateway has changed
      this.dlog('rsgwg - gatewayunitcrud');
      this.refreshSiteGatewaysWithGroups(this.user.active.id);
    });

    // subscirbe to gateway unit change (mapping) notifications
    this.siteGatewayUnitChanged = this.siteService.siteGatewayUnitChanged.subscribe((/*gateway_unit*/) => {
      // a unit on the gateway has changed
      this.dlog('rsgwg - gatewayunitchanged');
      this.refreshSiteGatewaysWithGroups(this.user.active.id);
    });

    // // subscribe to router events - to detect a refresh
    // this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //   // browser refresh occurred
    //   if (e instanceof NavigationEnd) {
    //     if ((this.user) && (this.user.active) && (this.user.active.id != 'default-site')) {
    //       this.dlog('rsgwg - router refresh');
    //       //this.refreshSiteGatewaysWithGroups(this.user.active.id);
    //     }
    //     //this.init_ui();

    //   }
    // });

    // subscribe to site level alarm notifications
    this.siteAlertMetricsSubscription = this.mainSiteUIService.refreshSiteAlertDataEmitter.subscribe((siteAlertMetricsData: SiteAlertMetricsData) => {
      // refresh status message of all the groups
      if (this.mainSiteUIService.allSiteGroups) {
        this.updateWithAlertData(siteAlertMetricsData);
      }
    })

    // catch events from the ScrollBar to know when to show/hide the scroll arrow buttons

    this.childComponentSubscription = this.childrenComponent.changes.subscribe((comps: QueryList<DragScrollComponent>) => {
      if (comps.first !== undefined) {
        // somekind of event on the scroll bar - check for changes or set selection
        this.scrollBar = comps.first;
        if (this.previousScrollBar == null) this.previousScrollBar = this.scrollBar;

        if (this.scrollBar != this.previousScrollBar) {
          // then it changed and we need to rebook up the events
          if (this.onRightSideScrollEnd) {
            this.onRightSideScrollEnd.unsubscribe();
            this.onRightSideScrollEnd = null;
          }
          if (this.onLeftSideScrollEnd) {
            this.onLeftSideScrollEnd.unsubscribe();
            this.onLeftSideScrollEnd = null;
          }
          this.previousScrollBar = this.scrollBar;
        }
        if (this.onRightSideScrollEnd == null) {
          this.onRightSideScrollEnd = this.scrollBar.reachesRightBound.subscribe((hitBoundary) => {
            this.showRightScrollArrow = !hitBoundary;
          });
        }
        if (this.onLeftSideScrollEnd == null) {
          this.onLeftSideScrollEnd = this.scrollBar.reachesLeftBound.subscribe((hitBoundary) => {
            this.showLeftScrollArrow = !hitBoundary;
          });
        }
        // now that we have a scroll bar - move it to the last selected.
        if ((this.mainSiteUIService.allSiteGroups.length > 0) && this.performUnitSelect) {
          setTimeout(() => { this.moveToLastSelected() }, 0);
          this.performUnitSelect = false;
        }
      }
    });

    if (this.user.active.id) {
      this.dlog('rsgwg - cache load');
      this.refreshSiteGatewaysWithGroups(this.user.active.id);
    }
  }

  ionViewWillLeave() {
    // drop eventually
    if (this.profileUpdateSubscription) {
      this.profileUpdateSubscription.unsubscribe();
    }

    if (this.onActiveSiteChangedSubscription) {
      this.onActiveSiteChangedSubscription.unsubscribe();
    }

    if (this.siteGatewayChanged) {
      this.siteGatewayChanged.unsubscribe();
    }

    if (this.siteGatewayUnitChanged) {
      this.siteGatewayUnitChanged.unsubscribe();
    }

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    if (this.siteGatewayGroupCRUDEvent) {
      this.siteGatewayGroupCRUDEvent.unsubscribe();
    }

    if (this.childComponentSubscription) {
      this.childComponentSubscription.unsubscribe();
      this.childComponentSubscription = null;
    }

    if (this.onRightSideScrollEnd) {
      this.onRightSideScrollEnd.unsubscribe();
      this.onRightSideScrollEnd = null;
    }
    if (this.onLeftSideScrollEnd) {
      this.onLeftSideScrollEnd.unsubscribe();
      this.onLeftSideScrollEnd = null;
    }

    if (this.siteAlertMetricsSubscription) {
      this.siteAlertMetricsSubscription.unsubscribe();
      this.siteAlertMetricsSubscription = null;
    }

    if (this.docClicksListener != null) {
      this.docClicksListener();
      this.docClicksListener = null;
    }

    this.scrollBar = null;

  }

  private dlog(msg: string) {
    // bit of debug logging
    if (this.debug) {
      console.log(`GS: ${this.title}: ${msg}`)
    }
  }

  private moveToLastSelected() {
    // try to move the selection to the last unit we were using.
    const lastSelectedGroupIds = this.getLastSelected();

    this.dlog(`lastSelected is ${lastSelectedGroupIds}`);

    // mode switch
    let move_to_index = this.mainSiteUIService.allSiteGroups.length;
    if (this.multiSelect) {
      // select the number there were before(if any), set move_to_index to first

      this.mainSiteUIService.allSiteGroups.forEach((grp, index) => {
        grp.selected = lastSelectedGroupIds.includes(grp.id);
        if (grp.selected) {
          if (index < move_to_index) {
            // then this is the one to consider for a move_to
            move_to_index = index;
          }
        }
      })
      if (move_to_index == this.mainSiteUIService.allSiteGroups.length) {
        // then there was nothing selected, select zero
        this.mainSiteUIService.allSiteGroups[0].selected = true;
        move_to_index = 0;
      }

      // notify
      this.notifySelection();

    } else {

      // only consider the 'lowest' index value - select and move to
      this.mainSiteUIService.allSiteGroups.forEach((grp, index) => {
        if (lastSelectedGroupIds.includes(grp.id)) {
          if (index < move_to_index) {
            // then this is the one to consider for a move_to, and the select
            move_to_index = index;
          }
        }
      })
      if (move_to_index < this.mainSiteUIService.allSiteGroups.length) {
        // then select it
        this.mainSiteUIService.allSiteGroups[move_to_index].selected = true;
      } else {
        // then select zero.
        this.mainSiteUIService.allSiteGroups[0].selected = true;
        move_to_index = 0;
      }

      // notify
      this.notifySelection();
    }

    if (move_to_index > 2) {
      // then move the bar along to this location. less 2, in a quarter second.
      setTimeout(() => {
        if (window.location.href.includes(`schedule`) || window.location.href.includes(`control`)) {
          this.scrollBar.moveTo(move_to_index - 2);
        }
      }, 250);
    }

  }

  getZeroStateMessage() {
    // get the zero state message based on the current filter options
    let type_filter = '';
    if (this.mainSiteUIService.filterIncludeIDU && !this.mainSiteUIService.filterIncludeLossnay) type_filter = 'for Indoor Coils ';
    if (!this.mainSiteUIService.filterIncludeIDU && this.mainSiteUIService.filterIncludeLossnay) type_filter = 'for Lossnay Units ';

    let text_filter = '';
    if (this.groupSearchBar?.value) text_filter = `with "${this.groupSearchBar?.value}" `;

    return `Your search ${type_filter} ${text_filter} returned no results. Please try again.`;
  }

  private setLastSelected(): void {
    // Set the groupIds to pre-select next time we load - if its available.
    const selectedGroups = [];
    this.mainSiteUIService.allSiteGroups.forEach((grp, grpIndex) => { 
      if (grp.selected) {
        selectedGroups.push(grp.id);
      }
      this.setLastSelectedByOrder(grp);
    });
    localStorage.setItem(`LastGroupIdsSelected`, JSON.stringify(selectedGroups));
  }

  private getLastSelected() {
    // Is there a group we should pre-select?
    return JSON.parse(localStorage.getItem(`LastGroupIdsSelected`) || '[]');
  }

  ngAfterViewInit() {
    // Empty
  }

  async init_ui() {
    // set the UI options initially
    this.siteGatewaysExist = false;
    this.siteGatewayUnitsExist = false;
    if (this.groupSearchBar && this.groupSearchBar?.value) this.groupSearchBar.value = ``;
    this.mainSiteUIService.filterIncludeIDU = false;
    this.mainSiteUIService.filterIncludeLossnay = false;
    this.tempSymbol = this.user && this.user?.accountPreferences?.temperaturepreference_id == TemperaturePreferenceEnum.Celsius ? ' °C' : ' °F';
  }

  resizeFiltersContainer(e) {
    this.windowHeight = window.innerHeight;
  }

  mapProfileUpdateResponse(profileUpdateResponse): ProfileUpdateResponse {
    // translate a raw profile update to one the client can understand

    const profileUpdate: ProfileUpdateResponse = new ProfileUpdateResponse();

    if (!profileUpdateResponse) return null;
    if (!(profileUpdateResponse.device_serial || profileUpdateResponse.units)) return null;

    profileUpdate.device_serial = profileUpdateResponse.device_serial;
    profileUpdate.units = [];

    const temp_units: GatewayUnit[] = [];

    const units = profileUpdateResponse.units;
    if (this.mainSiteUIService.isDevTestAltSite) {
      // dev/local only special cases
      if (profileUpdate.device_serial == `12345-123`) {
        // this unit will be identified by KenzaServer with the group_id that it belongs to
        // based on which site this gateway serial number is registered to.
        // this doesn't work quite right for our fake 12345-123 gateway that is reused on 
        // many many sites.  So special case handler to update the group id's to values
        // that are relavent to THIS instance of 12345-123
        this.mainSiteUIService.allSiteGroups.forEach((group) => {
          if (group.gateway_serial == `12345-123`) {
            const group_bus_address = group.gatewayGroup.bus_address;
            // find THIS bus address is the profile update and set the group.
            const found = units.find(punit => punit.bus_address == group_bus_address);
            if (found) found.group_id = group.group_id;
          }
        })
      }
    }

    if (!units.length) return profileUpdate;

    for (const unit of units) {
      const gatewayUnit = new GatewayUnit({'group_id': unit.group_id, 'type': unit.type});
      gatewayUnit.update(unit, true);
      temp_units.push(gatewayUnit);
    }

    const converted_units: GatewayUnit[] = this?.perform_unit_temperature_conversion(this?.user?.accountPreferences?.temperaturepreference_id, temp_units);
    profileUpdate.units = converted_units;

    return profileUpdate;
  }

  refreshSelectedGateway(profileUpdate: ProfileUpdateResponse) {
    // profile update arrived for a gateway - do we care?
    if (profileUpdate) {

      const profileSerialNumber = profileUpdate.device_serial;

      // if we are tracking this gateway in our ScrolBar - update its values
      this.mainSiteUIService.allSiteGroups.forEach((sg) => {
        if (sg.gateway_serial == profileSerialNumber) {

          if (this.mainSiteUIService.isDevTestAltSite) {
            // dev/local only special cases
            if (profileSerialNumber == '12345-123') {
              // this unit will be identified by KenzaServer with the group_id that it belongs to
              // based on the first site this gateway serial number is registered to.
              // this doesn't work quite right for our fake 12345-123 gateway that is reused on 
              // many many sites.  So special case handler to update the group id's to values
              // that are relavent to THIS instance of 12345-123

              // set the group_id in the profileUpdate to match the group_ids of this gateway - based on bus_address matching
              sg.gatewayUnits.forEach((unit) => {
                const profileUnit: GatewayUnit = profileUpdate.units.find((profileUnit) => profileUnit.bus_address == unit.bus_address);
                // if we found something - set its group_id
                if (profileUnit) profileUnit.group_id = unit.group_id;
              })
            }
          }

          // find this ones group id in the profileUpdate
          const profile = profileUpdate.units.find(u => u.group_id == sg.group_id);
          if (profile) {
            sg.state.inlet_temp = profile.inlet_temp;
            sg.state.mode = profile.mode;
            sg.state.drive_on = profile.power;
            sg.state.vent_mode = profile.vent_mode;
            sg.state.fan_speed = profile.fan_speed;
            sg.state.air_direction = profile.air_direction;
          }
        }
      });

    }
  }

  perform_unit_temperature_conversion(convertToUnit: TemperaturePreferenceEnum, units: GatewayUnit[]): GatewayUnit[] {
    // convert temps to the logged in account requested type (F/C)
    let converted_units: GatewayUnit[] = [];

    switch (convertToUnit) {
      case TemperaturePreferenceEnum.Celsius:
        return units;

      case TemperaturePreferenceEnum.Fahrenheit:
        converted_units = units.map(unit => {

          unit.AutoMin = unit.AutoMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMin) : Temp.zero;
          unit.AutoMax = unit.AutoMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.AutoMax) : Temp.hundred;
          unit.CoolMin = unit.CoolMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMin) : Temp.zero;
          unit.CoolMax = unit.CoolMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.CoolMax) : Temp.hundred;
          unit.HeatMin = unit.HeatMin ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMin) : Temp.zero;
          unit.HeatMax = unit.HeatMax ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.HeatMax) : Temp.hundred;
          unit.inlet_temp = unit.inlet_temp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.inlet_temp) : Temp.unk;

          unit.SetTemp = unit.SetTemp ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp) : Temp.unk;
          unit.SetTemp1 = unit.SetTemp1 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp1) : Temp.unk;
          unit.SetTemp2 = unit.SetTemp2 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp2) : Temp.unk;
          unit.SetTemp3 = unit.SetTemp3 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp3) : Temp.unk;
          unit.SetTemp4 = unit.SetTemp4 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp4) : Temp.unk;
          unit.SetTemp5 = unit.SetTemp5 ? TemperatureConversions.convert_from_ME_celsius_to_ME_fahrenheit(unit.SetTemp5) : Temp.unk;

          unit.updateMinMaxPoints(unit)

          // deadband is a simple double to go to F
          unit.deadband = unit.deadband ? unit.deadband * 2.0 : 0;
          return unit;
        });
        break;
    }

    return converted_units;
  }

  async refreshSiteGatewaysWithGroups(site_id: string) {
    // this is the method to initialize the whole page.

    if (site_id != 'default-site') {

      this.loading = true;
      this.dlog('Emit Loading');
      this.stateMessage.emit('Loading');

      this.siteService.get_site_gateways_with_groups(site_id, true, true).subscribe((gateways) => {

        this.dlog(`GS: ${this.title}: set_site_gateways_with_groups_returned`);

        let gateways_with_groups = [];

        if (gateways) {
          this.siteGatewaysExist = gateways['gateways'].length > 0;
          gateways_with_groups = gateways['gateways'] as [];
          this.siteGatewayUnitsExist = false;

          this.mainSiteUIService.siteGatewaysObject = gateways;
          this.mainSiteUIService.getLocationFiltersFromGateways(gateways);

          // are there gateways here?
          if (this.siteGatewaysExist) {
            this.dlog('Emit Gateways');
            this.stateMessage.emit('Gateways');
            // for each gateway
            gateways_with_groups.forEach((gwg) => {
              // are there groups here?
              const maintenanceJob = gwg.maintenance_job;
              const maintenanceJobTypeId = maintenanceJob ? maintenanceJob.job_id : MaintenanceJobTypeEnum.None;
              const maintenanceJobOdus = maintenanceJob ? maintenanceJob.ou_addresses.split(',').map( (e:string) => parseInt(e)) : [];
              if (gwg.groups.length > 0) {
                this.siteGatewayUnitsExist = true;
                // update cache
                const gateway_profile = gwg.profile;
                const mappedProfile = this.mapProfileUpdateResponse(gateway_profile);
                const serial = gateway_profile['device_serial'];
                if (serial in this.cachedProfileUpdateMap) {
                  // merge in any updates group_id
                  mappedProfile.units.forEach((unit) => {
                    const unit_group_id = unit.group_id;
                    if (unit_group_id) {
                      // try to find maching row in this.cachedProfileUpdateMap[profileUpdate.device_serial].units
                      const result = this.cachedProfileUpdateMap[serial].units.find((o, i) => {
                        if (o.group_id == unit_group_id) {
                          this.cachedProfileUpdateMap[serial].units[i] = unit;
                          return true; // stop looking
                        }
                      })
                      if (!result) {
                        // then it wasn't there.  add.
                        this.cachedProfileUpdateMap[serial].units.push(unit)
                      }
                    }
                  })
                } else {
                  // add
                  this.cachedProfileUpdateMap[serial] = mappedProfile;
                }

                if (this.mainSiteUIService.isDevTestAltSite) {
                  // dev/local only special cases
                  if (serial == `12345-123`) {
                    // this unit will be identified by KenzaServer with the group_id that it belongs to
                    // based on the first site this gateway serial number is registered to.
                    // this doesn't work quite right for our fake 12345-123 gateway that is reused on 
                    // many many sites.  So special case handler to update the group id's to values
                    // that are relavent to THIS instance of 12345-123

                    // set the group_id in the mappedProfile to match the group_ids of this gateway - based on bus_address matching
                    gwg.groups.forEach((group) => {
                      // there is a bug where goups can show up withOUT?!? a unit. This should no happen.
                      if (group.units.length > 0) {
                        const profileUnit: GatewayUnit = mappedProfile.units.find((profileUnit) => profileUnit.bus_address == group.units[0].bus_address);
                        if (profileUnit) profileUnit.group_id = group.id;
                      }
                    })
                  }
                }

                // for each group
                gwg.groups.forEach((group) => {
                  // again - should not be possible to have a group with zero units.. but...
                  if (group.units.length > 0) {
                    let group_name = group['name'];
                    if (group.autocreated) group_name = group.units[0].name;
                    const group_profile = mappedProfile.units.find(({ group_id }) => group_id == group['id']);

                    const unit_group: GatewayGroup = new GatewayGroup();
                    unit_group.gateway_id = gwg.id;
                    unit_group.autocreated = group.autocreated;
                    unit_group.group_id = group['id'];
                    unit_group.group_name = group_name;
                    unit_group.bus_address = group.units && group.units.length > 0 ? group.units[0].bus_address : '';
                    unit_group.units = group.units;
                    unit_group.number = group.number;

                    const units: GatewayUnit[] = [];
                    group.units.forEach((unit) => {
                      const temp_unit: GatewayUnit = new GatewayUnit(group_profile);
                      temp_unit.group_id = group['id'];
                      temp_unit.bus_address = unit.bus_address;
                      temp_unit.id = unit.id;
                      temp_unit.name = unit.name;
                      temp_unit.type = unit.type;
                      temp_unit.gateway_id = gwg.id;
                      temp_unit.group_name = group_name;
                      temp_unit.outdoor_unit = unit.outdoor_unit;
                      if (group['number']) temp_unit.group_number = group['number'].toString();
                      units.push(temp_unit);
                    });

                    if (units.length > 0) {
                      const sg = new TrackedGroup();
                      sg.group_id = group['id'];
                      sg.id = group['id'];
                      sg.name = group_name;
                      sg.gateway = gwg;
                      sg.gateway_serial = gwg.serial_number;
                      sg.gatewayGroup = unit_group;
                      sg.gatewayUnits = units;
                      sg.type = GatewayUnitTwoDigitTypeToEnum[units[0].type];
                      const oduBusAddress = units[0].outdoor_unit ? units[0].outdoor_unit.bus_address : -1;
                      let groupMaintJobTypeId = maintenanceJobOdus.includes(oduBusAddress) ?
                        maintenanceJobTypeId : 
                        MaintenanceJobTypeEnum.None;

                      // Simulate Maintenance Jobs
                      // groupMaintJobTypeId = MaintenanceJobTypeEnum.Refrigerant;
                      // groupMaintJobTypeId = MaintenanceJobTypeEnum.Incorrect_Port;

                      sg.state = new TrackedGroupState(group_profile, groupMaintJobTypeId);
                      sg.selected = false;
                      sg.errorSelected = false;

                      this.mainSiteUIService.allSiteGroups.push(sg);
                    }
                  }
                })
              }
            })

            if (this.siteGatewayUnitsExist) {
              this.dlog('Emit Units');
              this.stateMessage.emit('Units');
            } else {
              this.dlog('Emit NoUnits');
              this.stateMessage.emit('NoUnits');
            }
            // update with site alert details
            if (this.mainSiteUIService.allSiteGroups.length > 0) {

              // //sort by gatway name then by group name
              this.mainSiteUIService.allSiteGroups.sort(
                function (a, b) {
                  if (a.gateway.name === b.gateway.name) {
                    return a.name.localeCompare(b.name);
                  }
                  return a.gateway.name.localeCompare(b.gateway.name);
                });


              const siteAlertMetricsData: SiteAlertMetricsData = this.mainSiteUIService.getSiteMetricsData();

              // update site_groups with alert details
              this.updateWithAlertData(siteAlertMetricsData);
            }

            // update the list to display based on search filter
            this.setFilteredList();

            // Remove the Gateway Filter so it reinitializes with updated data
            this.mainSiteUIService.gsFilters = this.mainSiteUIService.gsFilters.filter(gsFil => gsFil.name != GroupSelectorFilterTypes.GATEWAY);

            // on the next change event for the scroll bar - select a unit.
            this.performUnitSelect = true;

          } else {
            this.dlog('Emit NoGateways');
            this.stateMessage.emit('NoGateways');
          }
        }
        // clear the loading zero state
        this.loading = false;
        this.dlog('Emit Ready');
        this.stateMessage.emit('Ready');
      }, (error) => {
        // something went wrong.
        this.loading = false;
        // do not emit ready
        this.dlog(`Error:${error}`);
        this.stateMessage.emit('LoadError');
      });
    }
  }

  toggleIDUFilter() {
    this.mainSiteUIService.filterIncludeIDU = !this.mainSiteUIService.filterIncludeIDU;
    this.setFilteredList();
  }

  toggleLossnayFilter() {
    this.mainSiteUIService.filterIncludeLossnay = !this.mainSiteUIService.filterIncludeLossnay;
    this.setFilteredList();
  }

  showClearAll() {
    if (this.checkBoxFilters == false) {
      return this.mainSiteUIService.filterIncludeIDU || this.mainSiteUIService.filterIncludeLossnay || (this.groupSearchBar?.value != ``);
    } else {
      return this.groupSearchBar?.value != `` || this.mainSiteUIService.checkedBoxes?.length > 0;
    }
  }

  clearFilter() {
    if (!this.siteService.handleIsConnected()) return;
    this.mainSiteUIService.filterIncludeIDU = false;
    this.mainSiteUIService.filterIncludeLossnay = false;
    this.clearAllCheckBoxFilters();
    if (this.groupSearchBar && this.groupSearchBar?.value) this.groupSearchBar.value = ``;
    if (this.searchAndFilter) this.searchAndFilter.clearCurrentSearch();
    this.setFilteredList();
  }

  onSearchFilterDataSignal(searchFilterDataSignal) {
    let signalType = searchFilterDataSignal.type;
    let objectType = searchFilterDataSignal.objectType;
    let searchTerm = searchFilterDataSignal.searchTerm;

    if (signalType == `Search`) {
      if (objectType == `Gateways`) {
        this.groupSearchBar.value = searchTerm.toLowerCase();
        this.setFilteredList();
      }
    } else if (signalType == `Toggle`) {
      if (objectType == `Select`) {
        this.togglecheckBoxSelectAll();
      } else if (objectType == `List`) {
        this.toggleFilterSelection();
      }
    }
  }

  toggleFilterSelection(ionClickEvent?: any) {
    if (!this.siteService.handleIsConnected()) return;
    this.filterSelectionOpen = !this.filterSelectionOpen;

    if (this.filterSelectionOpen == true) {

      if (this.mainSiteUIService.allSiteGroups.filter(grp => grp?.type == GatewayUnitTwoDigitType.IndoorUnit)?.length == 0) {
        this.mainSiteUIService.gsFilters[0].filters = this.mainSiteUIService.gsFilters[0].filters.filter(fil => fil != GroupType.IU);
      } else if (this.mainSiteUIService.allSiteGroups.filter(grp => grp?.type == GatewayUnitTwoDigitType.Lossnay)?.length == 0) {
        this.mainSiteUIService.gsFilters[0].filters = this.mainSiteUIService.gsFilters[0].filters.filter(fil => fil != GroupType.LC);
      };

      this.mainSiteUIService.setGatewayFilters();

      setTimeout(() => {
        // Need to get rid of default padding on inside of ion item shadow roots 
        this.itemWrappers.forEach((itemWrapper: any) => {
          let shadowRoot = itemWrapper?.el?.shadowRoot;
          let itemInner = shadowRoot?.querySelector(`.item-inner`);
          if (itemInner) itemInner.style.padding = `0`;
        });
      }, 10);

      if (this.docClicksListener == null) {
        this.docClicksListener = this.renderer.listen(`document`, `click`, (event) => {
          if (this.mainSiteUIService.uniqueGateways.length > 0) this.mainSiteUIService.uniqueGateways = [];
          let isOpen = this.filterSelectionOpen;
          let keepOpen = this.filterSelectionList.nativeElement.contains(event.target) || event.target == (<any>this.checkBoxFiltersButton)?.el;
          devEnv && this.useSearchFilterComponent == true && console.log(`useSearchFilterComponent`, { event, isOpen, keepOpen, target: event.target });
          if (keepOpen == true) {
            return;
          } else {
            if (this.useSearchFilterComponent == false) {              
              this.filterSelectionOpen = keepOpen;
              this.docClicksListener();
              this.docClicksListener = null;
            }
          }
        });
      }
    } else {
      if (this.docClicksListener != null) {
        this.docClicksListener();
        this.docClicksListener = null;
      }
    }
  }

  getFilterBadgeDetails(filter) {
    if (filter == GroupType.IU) {
      return `${this.mainSiteUIService.filteredSiteGroups.filter(grp => grp?.type == GatewayUnitTwoDigitType.IndoorUnit)?.length}`;
    } else if (filter == GroupType.LC) {
      return `${this.mainSiteUIService.filteredSiteGroups.filter(grp => grp?.type == GatewayUnitTwoDigitType.Lossnay)?.length}`;
    } else if (this.mainSiteUIService.uniqueGwNames?.length > 0 && this.mainSiteUIService.uniqueGwNames.includes(filter)) {
      return this.mainSiteUIService.gatewayFilters.find(gw => gw?.gateway == filter)?.length;
    } else {
      return 0;
    }
  }

  setCheckedBoxes() {
    let updatedCheckedBoxes = this.mainSiteUIService.setCheckedBoxes(this.gsFilterCheckBoxes);
    return updatedCheckedBoxes;
  }

  doArraysContainMatches(arrayOfStrings1: string[], arrayOfStrings2: string[]): boolean {
    for (let value1 of arrayOfStrings1) {
      for (let value2 of arrayOfStrings2) {
        if (value1 === value2) {
          return true;
        }
      }
    }
    return false;
  }

  setActiveFilters(filter, e?) {
    if (!this.siteService.handleIsConnected()) return;
    this.mainSiteUIService.checkedBoxes = this.setCheckedBoxes();

    this.mainSiteUIService.gsFilters.map(gsFil => {
      if (this.mainSiteUIService.checkedBoxes.some(fil => gsFil.filters.includes(fil))) {
        if (filter.name == gsFil.name) {
          gsFil.active = true;
        } else {
          gsFil.active = false;
        }
        return gsFil;
      } else {
        gsFil.active = false;
        return gsFil;
      }
    });
    
    if (filter?.itemFilter == GroupType.IU) this.mainSiteUIService.filterIncludeIDU = !this.mainSiteUIService.filterIncludeIDU;
    if (filter?.itemFilter == GroupType.LC) this.mainSiteUIService.filterIncludeLossnay = !this.mainSiteUIService.filterIncludeLossnay;

    let unitTypeFiltersActive = this.mainSiteUIService.checkedBoxes.includes(GroupType.IU) || this.mainSiteUIService.checkedBoxes.includes(GroupType.LC);
    let unitsWithLocationNoneActive = this.mainSiteUIService.activeLocations.includes(GroupSelectorFilterTypes.NONE);
    let locationFiltersActive = this.mainSiteUIService.activeLocations.length > 0;
    let gatewayFiltersActive = this.mainSiteUIService.activeGatewayIDs.length > 0;

    this.mainSiteUIService.totalFilters = this.gsFilterCheckBoxes.length;
    this.allDefaultFilters = this.mainSiteUIService.checkedBoxes.includes(GroupType.IU) && this.mainSiteUIService.checkedBoxes.includes(GroupType.LC);

    if (this.mainSiteUIService.checkedBoxes.length > 0) {
      if (this.mainSiteUIService.checkedBoxes.length == 1) {
        if (unitTypeFiltersActive) {
          this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]));
        } else {
          if (unitsWithLocationNoneActive) {
            this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => grp?.gatewayGroup?.units[0].location == ``);
          } else {
            if (gatewayFiltersActive) {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
            } else {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location));
            }
          }
        }
      } else {

        if (this.mainSiteUIService.checkedBoxes.length == this.mainSiteUIService.totalFilters || (this.allDefaultFilters && this.mainSiteUIService.checkedBoxes.length == 2)) {
          this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups;
        } else {

          // let multipleActiveFilters = () => ({
          //   unitTypeFiltersActive, 
          //   gatewayFiltersActive, 
          //   locationFiltersActive, 
          //   all: this.mainSiteUIService.allSiteGroups, 
          //   unitsWithLocationNoneActive, 
          //   filtered: this.mainSiteUIService.filteredSiteGroups, 
          //   activeLocations: this.mainSiteUIService.activeLocations,
          //   activeGatewayIDs: this.mainSiteUIService.activeGatewayIDs,
          // });
          
          // console.log(`Multiple Active Filters`, multipleActiveFilters());

          if (unitTypeFiltersActive && locationFiltersActive && !gatewayFiltersActive) {
            if (unitsWithLocationNoneActive) {
              if (this.mainSiteUIService.activeLocations.length < 2) {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && grp?.gatewayGroup?.units[0].location == ``);
              } else {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && (grp?.gatewayGroup?.units[0].location == `` || this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location)));
              }
            } else {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location));
            }
          } else if (unitTypeFiltersActive && gatewayFiltersActive && !locationFiltersActive) {
            this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
          } else if (!unitTypeFiltersActive && gatewayFiltersActive && !locationFiltersActive) {
            this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
          } else if (!unitTypeFiltersActive && !gatewayFiltersActive && locationFiltersActive) {
            if (unitsWithLocationNoneActive) {
              if (this.mainSiteUIService.activeLocations.length < 2) {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => grp?.gatewayGroup?.units[0].location == ``);
              } else {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => (grp?.gatewayGroup?.units[0].location == `` || this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location)));
              }
            } else {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location));
            }
          } else if (!unitTypeFiltersActive && gatewayFiltersActive && locationFiltersActive) {
            if (unitsWithLocationNoneActive) {
              if (this.mainSiteUIService.activeLocations.length < 2) {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => grp?.gatewayGroup?.units[0].location == `` && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
              } else {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => (grp?.gatewayGroup?.units[0].location == `` || this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location)) && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
              }
            } else {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id) && this.doArraysContainMatches(this.mainSiteUIService.activeLocations, grp?.gatewayGroup?.units.map(uni => uni.location)));
            }
          } else if (unitTypeFiltersActive && gatewayFiltersActive && locationFiltersActive) {
            if (unitsWithLocationNoneActive) {
              if (this.mainSiteUIService.activeLocations.length < 2) {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && grp?.gatewayGroup?.units[0].location == `` && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
              } else {
                this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && (grp?.gatewayGroup?.units[0].location == `` || this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location)) && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
              }
            } else {
              this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]) && this.mainSiteUIService.activeLocations.includes(grp?.gatewayGroup?.units[0].location) && this.mainSiteUIService.activeGatewayIDs.includes(grp?.gateway?.id));
            }
          } else {
            this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups.filter(grp => this.mainSiteUIService.checkedBoxes.includes(GroupType[grp?.type]));
          }
        }
      }
    } else {
      this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups;
    }

    this.checkedFilterGroups = this.mainSiteUIService.filteredSiteGroups;

    const searchText = (this.groupSearchBar?.value ?? '').toLowerCase();
    if (searchText != ``) this.setFilteredList();
    this.mainSiteUIService.refreshBadgeCounts();
  }

  async togglecheckBoxSelectAll(e?: any) {
    if (!this.siteService.handleIsConnected() || this.checkBoxSelectAllOpen == true) return;

    let hasIndoorUnits = this.mainSiteUIService.filteredSiteGroups.some(grp => grp.type == GatewayUnitTwoDigitType.IndoorUnit);
    let hasLossnays = this.mainSiteUIService.filteredSiteGroups.some(grp => grp.type == GatewayUnitTwoDigitType.Lossnay);

    if (hasIndoorUnits && hasLossnays) {
      let buttons = [
        {
          text: `Indoor Coils / Groups`,
          cssClass: `ok-button`,

          handler: () => {
            this.selectUnitType(GatewayUnitTwoDigitType.IndoorUnit);
          }
        },
        {
          text: `Lossnays`,
          cssClass: `ok-button`,

          handler: () => {
            this.selectUnitType(GatewayUnitTwoDigitType.Lossnay);
          }
        }
      ];

      // if (devEnv) {
      //   buttons.push({
      //     text: `Indoor Coils / Groups w/ Valid Values`,
      //     cssClass: `ok-button devBlueButton`,

      //     handler: () => {
      //       devEnv && console.log(`Select All Indoor Coils / Groups w/ Valid Values`);
      //     }
      //   }, {
      //     text: `Indoor Coils / Groups w/ Events`,
      //     cssClass: `ok-button devBlueButton`,

      //     handler: () => {
      //       devEnv && console.log(`Select All Indoor Coils / Groups w/ Events`);
      //     }
      //   }, {
      //     text: `Lossnays w/ Events`,
      //     cssClass: `ok-button devBlueButton`,

      //     handler: () => {
      //       devEnv && console.log(`Select All Lossnays w/ Events`);
      //     }
      //   }, {
      //     text: `Not Expired Indoor Coils / Groups`,
      //     cssClass: `ok-button devBlueButton`,

      //     handler: () => {
      //       devEnv && console.log(`Select All Not Expired Indoor Coils / Groups`);
      //     }
      //   }, {
      //     text: `Not Expired Lossnays`,
      //     cssClass: `ok-button devBlueButton`,

      //     handler: () => {
      //       devEnv && console.log(`Select All Not Expired Lossnays`);
      //     }
      //   })
      // }

      const alert = await this.alertController.create({
        cssClass: `checkBoxSelectAllAlertMenu ${buttons.length > 2 ? `expanded` : ``}`,
        backdropDismiss: true,
        header: `Select all:`,
        buttons,
      });
    
      alert.onDidDismiss().then((data) => { 
        this.checkBoxSelectAllOpen = false;
      });
  
      this.checkBoxSelectAllOpen = true;
      await alert.present();
    } else {
      if (hasIndoorUnits) {
        this.selectUnitType(GatewayUnitTwoDigitType.IndoorUnit);
      } else {
        this.selectUnitType(GatewayUnitTwoDigitType.Lossnay);
      }
    }
  }

  selectUnitType(type) {
    if (!this.siteService.handleIsConnected()) return;
    // Deselect All Groups first
    this.mainSiteUIService.allSiteGroups = this.mainSiteUIService.allSiteGroups.map((grp, index) => {
      grp.selected = false;
      return grp as TrackedGroup;
    });
    // Select All Groups that match Type from Filtered
    this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.filteredSiteGroups.map((grp, index) => {
      if (grp?.type == type) {
        grp.selected = true;
        return grp as TrackedGroup;
      } else {
        return grp as TrackedGroup;
      }
    });
    this.setLastSelectedFromFiltered();
    this.notifySelection();
  }

  setLastSelectedFromFiltered() {
    let groupIDsToSelect = this.mainSiteUIService.filteredSiteGroups.filter(grp => grp.selected).map(grp => grp?.id);
    localStorage.setItem(`LastGroupIdsSelected`, JSON.stringify(groupIDsToSelect));
  }

  isActiveFilter(filter) {
    this.mainSiteUIService.checkedBoxes = this.setCheckedBoxes();
    return this.mainSiteUIService.isActiveFilter(filter, this.mainSiteUIService.checkedBoxes);
  }

  checkAllCheckboxes(e?) {
    if (!this.siteService.handleIsConnected()) return;
    this.gsFilterCheckBoxes.forEach((checkbox: any) => {
      checkbox.el.checked = true;
    });
    this.mainSiteUIService.gsFilters = this.mainSiteUIService.gsFilters.map(gsFil => {
      gsFil.active = true;
      return gsFil;
    });
    this.mainSiteUIService.checkedBoxes = this.setCheckedBoxes();
    this.mainSiteUIService.totalFilters = this.gsFilterCheckBoxes.length;
    this.mainSiteUIService.refreshBadgeCounts();
  }

  clearAllCheckBoxFilters(e?) {
    if (!this.siteService.handleIsConnected()) return;
    this.checkedFilterGroups = [];
    this.mainSiteUIService.filteredSiteGroups = this.mainSiteUIService.allSiteGroups;

    this.mainSiteUIService.clearAllCheckBoxFilters();

    this.gsFilterCheckBoxes.forEach((checkbox: any) => {
      checkbox.el.checked = false;
    });

    this.setFilteredList();
  }

  setFilteredList() {
    // filter the list to dipslay based on this string and the filterIDU/filterLossnay

    this.mainSiteUIService.checkedBoxes = this.setCheckedBoxes();
    let locationsNoneActive = this.mainSiteUIService.activeLocations.length > 0 && this.mainSiteUIService.activeLocations.includes(GroupSelectorFilterTypes.NONE);
    let locationsActive = this.mainSiteUIService.activeLocations.length > 0;
    let gatewaysActive = this.mainSiteUIService.activeGateways.length > 0;
    // this guy handles cases where value may be undefined or null
    const searchText = (this.groupSearchBar?.value ?? '').toLowerCase();
    this.mainSiteUIService.filteredSiteGroups = (this.mainSiteUIService.checkedBoxes.length > 0 ? this.checkedFilterGroups : this.mainSiteUIService.allSiteGroups).filter(group =>
      group.name.toLowerCase().includes(searchText)
      && (this.mainSiteUIService.filterIncludeIDU && !this.mainSiteUIService.filterIncludeLossnay ? group.type == GatewayUnitTwoDigitType.IndoorUnit : true)
      && (this.mainSiteUIService.filterIncludeLossnay && !this.mainSiteUIService.filterIncludeIDU ? group.type == GatewayUnitTwoDigitType.Lossnay : true) 
      && (locationsNoneActive ? group.gatewayGroup.units[0].location == `` : locationsActive ? this.mainSiteUIService.checkedBoxes.includes(group.gatewayGroup.units[0].location) : true) 
      && (gatewaysActive ? this.mainSiteUIService.checkedBoxes.includes(group.gateway.name) : true) ||
      group.gateway.name.toLowerCase().includes(searchText)
      && (this.mainSiteUIService.filterIncludeIDU && !this.mainSiteUIService.filterIncludeLossnay ? group.type == GatewayUnitTwoDigitType.IndoorUnit : true)
      && (this.mainSiteUIService.filterIncludeLossnay && !this.mainSiteUIService.filterIncludeIDU ? group.type == GatewayUnitTwoDigitType.Lossnay : true) 
      && (locationsNoneActive ? group.gatewayGroup.units[0].location == `` : locationsActive ? this.mainSiteUIService.checkedBoxes.includes(group.gatewayGroup.units[0].location) : true) 
      && (gatewaysActive ? this.mainSiteUIService.checkedBoxes.includes(group.gateway.name) : true)
    );

    if (this.mainSiteUIService.filteredSiteGroups.length == 0) {
      // took it all out.
      this.showLeftScrollArrow = false;
      this.showRightScrollArrow = false;
    }

    devEnv && this.highlightSearchResults(searchText);
  }

  async highlightSearchResults(searchText) {
    if (searchText != ``) {
      this.searchParams.forEach((param: any) => {
        let filteredCharacters = searchText.replace(/[.*+?^${}()|[\]\\]/g,"\\$&");
        let filter = new RegExp(`${filteredCharacters}`,`gi`);
        param.el.innerHTML = param.el.textContent.replace(filter, match => `<mark>${match}</mark>`);
      })
    } else {
      this.searchParams.forEach((param: any) => {
        param.el.innerHTML = param.el.textContent;
      })
    }
  }

  trackByGroupId(index, item) {
    // the group_id is unique
    return item.group_id;
  }

  updateWithAlertData(siteAlertMetricsData: SiteAlertMetricsData) {
    // scan the site_groups and update the status indicators with alert/notification details

    this.mainSiteUIService.allSiteGroups.forEach((grp:TrackedGroup) => {
      // first look for alerts on any unit in this group.
      let unit_errors = 0;
      grp.gatewayUnits.forEach((unit) => {
        // if in a maintenace job - it doesn't matter if errors show up.
        if (grp.state.maintenance_job_type == MaintenanceJobTypeEnum.None) {
          if (siteAlertMetricsData.v2ActiveAlertsByUnits.has(unit.id)) {
            // then this group is in active alert.
            unit_errors += siteAlertMetricsData.v2ActiveAlertsByUnits.get(unit.id);
            grp.state.setToAlert(unit_errors)
          } else {
            // then this group has no active alert.
            grp.state.setToOk()
          }
        }
      })
      // Update: Gateway Alerts are disabled for this time.  Do not show.
      // if (grp.statusIndex == SiteControlGroupStatusEnum.OK) {
      //   // then consder gateway notifications.
      //   var gateway_notifications = 0;
      //   if (siteAlertMetricsData.v2ActiveNotificationsByGateways.has(grp.gateway_serial)) {
      //     var notifications = siteAlertMetricsData.v2ActiveNotificationsByGateways.get(grp.gateway_serial);
      //     grp.state.statusIndex = SiteControlGroupStatusEnum.Warning;
      //     grp.state.statusTitle = notifications + ' active gateway notifications';
      //   }
      // }
    })
  }

  ngOnDestroy() {
    document.removeEventListener(`keydown`, this.keydownFunction);
    document.removeEventListener(`keyup`, this.keyupFunction);
    this.keydownFunction = null;
    this.keyupFunction = null;

    if (this.docClicksListener != null) {
      this.docClicksListener();
      this.docClicksListener = null;
    }
  }

  async displayInformation() {
    const alert = await this.alertController.create({
      header: "Information",
      message: "Duplicate Lossnays may appear if multiple RMDs are connected on the same communication line.",
      cssClass: 'me-info-button-css',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'ok-button',

          handler: () => {
            //do something here
          }
        }
      ]
    });

    await alert.present();
  }

  selectGroup(group: TrackedGroup, index: number, clickEvent: PointerEvent) {
    // something was selected on the bar, decide how to handle

    if (group) {

      // devEnv && console.log(`Selected Group`, group);

      if (this.multiSelect) {

        // multiselect processing
        const userIsViewer = this.user.activeSiteUserLevel == LevelEnum.Viewer;
        // if we're a viewer on this site - its no on multiselect.
        const shiftPressed = clickEvent ? (userIsViewer ? false : clickEvent.shiftKey) : false;
        const ctrlPressed = clickEvent ? (userIsViewer ? false : clickEvent.ctrlKey || clickEvent.metaKey) : false;
        let alreadySelectedType: GatewayUnitTwoDigitType;
        let alreadySelectedCount = 0;
        this.mainSiteUIService.allSiteGroups.forEach((grp) => {
          if (grp.selected) {
            alreadySelectedType = grp.type;
            alreadySelectedCount += 1;
          }
        })

        if (!shiftPressed && !ctrlPressed) {
          // in this case - the clicking of any tile should simply select that time, and deselect all others
          // there are no limits on what you can/cant click in this case.
          this.mainSiteUIService.allSiteGroups.forEach((grp) => {
            if (grp.id == group.id) grp.selected = true
            else grp.selected = false
          })

        } else if (ctrlPressed) {
          // in this case - select or deselect the tile - if it is part of the already selected group.
          // can't mix idu and lc

          if (group.type != alreadySelectedType) {
            // then deny - can't click with ctrl cross types
            group.errorSelected = true;
            // clear in quater second.
            setTimeout(() => { group.errorSelected = false; }, 250);
          } else {
            // then as long as its not the last one - select or de-select it.
            if (alreadySelectedCount > 1 || !group.selected) {
              // then we can invert
              group.selected = !group.selected
            }

          }
        } else if (shiftPressed) {
          // is this case - select all of the same type items forward or backwards from selected to 'nearest' existing selected.
          // selecting only the 'type' already selected , and delselect everything else...

          // need to know the 'distance' to the left and rigth of the nearest selected group.
          let selectedLeft = -1;
          let selectedRight = this.mainSiteUIService.filteredSiteGroups.length;

          // start where we are to the left
          let selected = index - 1;
          while (selected >= 0) {
            if (this.mainSiteUIService.filteredSiteGroups[selected].selected) {
              // then this is the first.
              selectedLeft = selected;
              break;
            }
            selected -= 1;
          }

          // start where we are to the right
          selected = index + 1;
          while (selected < this.mainSiteUIService.filteredSiteGroups.length) {
            if (this.mainSiteUIService.filteredSiteGroups[selected].selected) {
              // this this is the first
              selectedRight = selected;
              break;
            }
            selected += 1;
          }

          // handle the edge case where we have filtered out all the selected groups..
          if (selectedRight == this.mainSiteUIService.filteredSiteGroups.length && selectedLeft == -1) {
            // then we treat this as a non-shift click.
            return this.selectGroup(group, index, null);
          }

          // now - which is smaller
          let selectLeft: boolean;
          if (selectedLeft == -1) {
            // select right.
            selectLeft = false;
          } else if (selectedRight == this.mainSiteUIService.filteredSiteGroups.length) {
            // select left
            selectLeft = true;
          } else {
            // its based on the distance
            const leftDist = index - selectedLeft;
            const rightDist = selectedRight - index;

            selectLeft = (leftDist < rightDist);
          }

          const groupIdsToSelect = [];
          const groupIdsToError = [];
          if (selectLeft) {
            for (let i = index; i >= selectedLeft; i--) {
              if (this.mainSiteUIService.filteredSiteGroups[i].type == alreadySelectedType) {
                // include in the selected list 
                groupIdsToSelect.push(this.mainSiteUIService.filteredSiteGroups[i].id);
              } else {
                // include in the error list
                groupIdsToError.push(this.mainSiteUIService.filteredSiteGroups[i].id);
              }
            }
          } else {
            for (let i = index; i <= selectedRight; i++) {
              if (this.mainSiteUIService.filteredSiteGroups[i].type == alreadySelectedType) {
                // add to selected list
                groupIdsToSelect.push(this.mainSiteUIService.filteredSiteGroups[i].id);
              } else {
                // to error list
                groupIdsToError.push(this.mainSiteUIService.filteredSiteGroups[i].id);
              }
            }
          }
          this.mainSiteUIService.allSiteGroups.forEach((grp) => {
            grp.selected = groupIdsToSelect.includes(grp.id);
            grp.errorSelected = groupIdsToError.includes(grp.id);
            if (grp.errorSelected) {
              // clear in quarter second.
              setTimeout(() => { grp.errorSelected = false; }, 250);
            }
          })
        }

        // save the new selection to localstorage
        this.setLastSelected();

        // notify the client
        this.notifySelection();

      } else {
        // single select processing, clear everyone else.
        this.mainSiteUIService.allSiteGroups.forEach((grp) => {
          grp.selected = (grp.id === group.id);
        })

        // check whether we need to update the details of this item based on whats in the cacheProfileUpdateMap
        const selected_serial = group.gateway_serial;
        if (selected_serial in this.cachedProfileUpdateMap) {
          this.refreshSelectedGateway(this.cachedProfileUpdateMap[selected_serial]);
        } else {
          this.displayDataNotAvailable = true;
        }

        // set this group as the last selected
        this.setLastSelected();

        // notify client.
        this.notifySelection();

      }
    }
  }

  setLastSelectedByOrder(group: any) {

    let getUnixTimeStamp = (milliseconds: boolean = false) => milliseconds == true ? Date.now() % 1000 : Math.floor(Date.now() / 1000);
    let LastGroupsSelectedByOrder = localStorage.getItem(`LastGroupsSelectedByOrder`) ? JSON.parse(localStorage.getItem(`LastGroupsSelectedByOrder`)) : [];
    let timeSelected = getUnixTimeStamp(true);
    let selectionTimes = this.LastSelectedByOrder.length > 0 ? this.LastSelectedByOrder.map(grp => grp?.momentSelected) : [timeSelected];

    if (selectionTimes.includes(timeSelected)) {
      timeSelected = timeSelected + this.lastSelectedOrder;
      this.lastSelectedOrder = this.lastSelectedOrder + 1;
    };

    let selectedGroupObjectWithOrder: any = {
      timeSelected, 
      id: group?.id,  
      order: this.lastSelectedOrder,
      name: group?.gatewayGroup?.group_name, 
      group_name: group?.gatewayUnits[0]?.name, 
    };

    if (this.LastSelectedByOrder.length == 0 && LastGroupsSelectedByOrder.length > 0) {
      this.LastSelectedByOrder = LastGroupsSelectedByOrder;
    } else {
      let orders = this.LastSelectedByOrder.length > 0 ? this.LastSelectedByOrder.map(grp => grp?.order) : [this.lastSelectedOrder];
      let newOrder = !isNaN(Math.max(orders)) ? Math.max(orders) : this.lastSelectedOrder;
      if (orders.includes(newOrder)) newOrder = newOrder + 1;
      this.lastSelectedOrder = newOrder;
      selectedGroupObjectWithOrder.order = this.lastSelectedOrder;
      let groupIsNewlySelected = this.LastSelectedByOrder.length > 0 && !this.LastSelectedByOrder.map(grp => grp.id).includes(group?.id);
      if (groupIsNewlySelected || this.LastSelectedByOrder.length == 0) this.LastSelectedByOrder.push(selectedGroupObjectWithOrder);
      if (groupIsNewlySelected) this.lastSelectedOrder = this.lastSelectedOrder + 1;
    };

    if (group.selected == false) this.LastSelectedByOrder = this.LastSelectedByOrder.filter(gr => gr.id != group?.id);
    // this.LastSelectedByOrder = this.LastSelectedByOrder.sort((a: any, b: any) => b.timeSelected - a.timeSelected);
    this.LastSelectedByOrder = this.LastSelectedByOrder.sort((a: any, b: any) => b.order - a.order);

    localStorage.setItem(`LastGroupsSelectedByOrder`, JSON.stringify(this.LastSelectedByOrder));
  }

  notifySelection() {
    // notify our parent of a change in the selection.

    if (this.multiSelect) {
      // then consider that multiple groups can be selected
      const selectedGroups: SelectedGroup[] = [];
      this.mainSiteUIService.allSiteGroups.forEach((group, groupIndex) => {
        if (group.selected) {
          const selectedGroup = new SelectedGroup();
          selectedGroup.init(group);
          selectedGroups.push(selectedGroup);
        }
        this.setLastSelectedByOrder(group);
      });

      if (selectedGroups.length > 0) this.newGroupsSelected.emit(selectedGroups);

    } else {
      // single selection
      let selectedGroup: SelectedGroup = null;
      this.mainSiteUIService.allSiteGroups.forEach((group) => {
        if (group.selected) {
          // this is it.
          selectedGroup = new SelectedGroup();
          selectedGroup.init(group);
          return;
        }
      })
      // send it up.
      if (selectedGroup) this.newGroupSelected.emit(selectedGroup);
    }
  }

  scrollBarMoveEnd(delta: number) {
    // move the scroll bar to the end of the list
    if (delta > 0) this.scrollBar.moveTo(this.scrollBar._children.length);
    if (delta < 0) this.scrollBar.moveTo(0);
  }

  scrollBarMove(delta: number) {
    // move the scroll bar forward/backwards by the number of tiles on the screen
    const tile_width = 170; // this is the width of the tile (150) + 10/10 margins. If the tile changes update this too!
    const window_width = this.scrollBar.parentNode.clientWidth;
    const tiles_on_screen = Math.floor(window_width / tile_width);

    let new_start = this.scrollBar.currIndex + (tiles_on_screen * delta);
    if (new_start < 0) new_start = 0;
    if (new_start + tiles_on_screen > this.mainSiteUIService.filteredSiteGroups.length) new_start = this.mainSiteUIService.filteredSiteGroups.length - tiles_on_screen;

    this.scrollBar.moveTo(new_start);
  }

}