import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { AlertController, ModalController } from '@ionic/angular';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { UserService } from 'src/app/common/services/user/user.service';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Component, Inject, Input, LOCALE_ID, SimpleChanges } from '@angular/core';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { devEnv, TEST_MODE_NO_SUITABLE_ODU_TITLE } from 'src/app/constants/kenzaconstants';
import { miniMaintJob } from 'src/app/features/sites/components/site-analytics/site-analytics.component';
import { AppAuthenticationService } from 'src/app/common/services/authentication/app-authentication.service';
import { RefrigerantCheckRequest } from 'src/app/common/services/websockets/classes/requests/WebSocketRequest';
import { completedProgressStatuses, errorOrCancelStatuses, icons, statuses } from 'src/app/enumerations/globals';
import { genericMaintenanceJobStatusEnumMessage, maintenanceJobStatusEnum } from 'src/app/common/classes/MaintenanceJob';
import { OutdoorUnitDetails } from 'src/app/features/sites/components/site-analytics-test-run/site-analytics-test-run.component';
import { MaintenanceJobTypeEnum, MaintenanceJobTypeEnumLabels, MaintenanceJobTypeEnumTitle } from 'src/app/enumerations/enums';
import { SiteAnalyticsRefrigerantCheckComponent } from 'src/app/features/sites/components/site-analytics-refrigerant-check/site-analytics-refrigerant-check.component';

@Component({
    selector: 'app-refrigerant-checks-card',
    templateUrl: './refrigerant-checks-card.component.html',
    styleUrls: ['./refrigerant-checks-card.component.scss'],
})

export class RefrigerantChecksCardComponent {

    @Input() rcReports;
    @Input() socketResult;
    @Input() getCardDisplayData;
    @Input() rmdClassGatewaysMapped;
    @Input() sortMaintJobByMostRecent;

    devEnv = devEnv;
    simulate = false;
    dataloaded = false;
    refrigerantsProgress = 0;
    disableWhenActive = false;
    recentRefrigerantChecks = [];
    progressReadyMessage = `Ready`;
    progressLoadingMessage = `loading...`;
    hasPermissionToCheckRefrigerant = true;
    multiSelect = false; // Only supporting selecting 1 ODU for now
    progressUpdateMessage = this.progressLoadingMessage || this.progressReadyMessage;

    globalFunctions = globalFunctions;
    MaintenanceJobTypeEnum = MaintenanceJobTypeEnum;
    maintenanceJobStatusEnum = maintenanceJobStatusEnum;
    MaintenanceJobTypeEnumTitle = MaintenanceJobTypeEnumTitle;
    MaintenanceJobTypeEnumLabels = MaintenanceJobTypeEnumLabels;
    genericMaintenanceJobStatusEnumMessage = genericMaintenanceJobStatusEnumMessage;

    shortTitle = MaintenanceJobTypeEnumLabels.Refrigerant;
    title = MaintenanceJobTypeEnumTitle[MaintenanceJobTypeEnum.Refrigerant];

    icons = icons;
    statuses = statuses;
    status = statuses.Ready;
    errorOrCancelStatuses = errorOrCancelStatuses;
    completedProgressStatuses = completedProgressStatuses;

    constructor(
        private router: Router,
        public user: UserService,
        private siteService: SiteService,
        public modalController: ModalController,
        private alertController: AlertController,
        public appAuth: AppAuthenticationService,
        @Inject(LOCALE_ID) public locale: string,
        private mainSiteUIService: MainSiteUIService,
    ) {
        // Empty
    }

    ngOnInit() {
        // Empty
    }

    ionViewWillEnter() {
        // Empty
    }
    
    ngAfterViewInit() {
        this.getRecentRefrigerantChecks();
    }

    initializeOrUpdateCardData() {
        this.ifHasPermissionToCheckRefrigerant();
        this.updateProgress();
        this.dataloaded = true;
    }

    startRefrigerantCheck() {
        if (!this.siteService.handleIsConnected()) return;
        this.openRefrigerationCheckModal();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            let recievedSocketUpdate = changes.socketResult.firstChange == false;
            if (recievedSocketUpdate) this.updateProgress();
        }
    }

    navigateToAnalyticsHistory() {
        this.router.navigate(
            [`/account/` + this.user.id + `/details/analytics-reports`], 
            { queryParams: { fromSiteId: this.user.active.id } } 
        );
    }

    ifHasPermissionToCheckRefrigerant() {
        this.hasPermissionToCheckRefrigerant = this.appAuth.doesLevelHavePermission(
            this.user.activeSiteUserLevel, 
            this.appAuth.permissionEnums.SAPerformAnalytics,
        );
        return this.hasPermissionToCheckRefrigerant;
    }

    setCardStatus() {
        let jobs = this.recentRefrigerantChecks;
        if (jobs.length > 0) {
            let mostRecentJob = jobs[0];
            let stepOfMostRecentJob = Object.entries(statuses).find(([key, val]) => val.id == mostRecentJob.status)[1];
            if (stepOfMostRecentJob) {
                this.status = stepOfMostRecentJob;
            }
        }
    }

    getRecentRefrigerantChecks() {
        this.siteService.getRecentRefrigerantChecks(this.user.active.id, true).subscribe((recentRefrigerantChecks: any) => {
            this.recentRefrigerantChecks = this.sortMaintJobByMostRecent(recentRefrigerantChecks);
            this.initializeOrUpdateCardData();
        }, (error) => {
            console.log(`Error on Get ${this.title}'s`, error);
        })

        return this.recentRefrigerantChecks;
    }

    // For Dev Only // Not used in Test / Alt / Prod
    simulateMaintJob() {
        let simulationDurationInMS = 15_000;
        let timeBeforeNextStepInMS = simulationDurationInMS / 5;

        setTimeout(() => {
            this.status = statuses.Booting;
            this.updateProgress(this.refrigerantsProgress, [{status: statuses.Booting.id, progress: this.refrigerantsProgress}]);
            setTimeout(() => {
                let updateProgressBar = setInterval(() => {
                    this.refrigerantsProgress += 0.01;
                    this.updateProgress(this.refrigerantsProgress, [{status: statuses.Running.id, progress: this.refrigerantsProgress}]);
                    if (this.refrigerantsProgress >= 100) {
                        clearInterval(updateProgressBar);
                    }
                }, (simulationDurationInMS / 100));
    
                setTimeout(() => {
                    this.refrigerantsProgress = 100;
                    this.status = statuses.Complete;
                    this.updateProgress(this.refrigerantsProgress, [{status: statuses.Complete.id, progress: this.refrigerantsProgress}]);
                    clearInterval(updateProgressBar);
                    setTimeout(() => {
                        this.status = statuses.Ready;
                    }, timeBeforeNextStepInMS);
                }, simulationDurationInMS);
            }, timeBeforeNextStepInMS);
        }, timeBeforeNextStepInMS);
    }

    async openRefrigerationCheckModal() {
        if (!this.rmdClassGatewaysMapped) {
            const alert = await this.alertController.create({
              header: TEST_MODE_NO_SUITABLE_ODU_TITLE,
              message: `Map units in an RMD class gateway to perform a ${this.title}.`,
              cssClass: `me-info-button-css`,
              buttons: [
                {
                  text: `Ok`,
                  cssClass: `ok-button`
                }
              ]
            });
        
            await alert.present();
            return;
        }

        const modal = await this.modalController.create({
            component: SiteAnalyticsRefrigerantCheckComponent,
            cssClass: `me-custom-modal-standard contentFit`,
            backdropDismiss: false,
            componentProps: {
                site_id: this.user.active.id,
                multiSelect: this.multiSelect,
            }
        });

        modal.onDidDismiss().then((data) => {
            if (!this.siteService.handleIsConnected()) return;
            if (data && data.data && `upgrade` in data.data) {
                // we`re doing a redirect for a gateway upgrade
                this.router.navigate([`/account/` + this.user.id + `/details/subscriptions`], { queryParams: { upgrade: data.data.upgrade}});
                this.mainSiteUIService.viewAccountGatewaySubscriptionPlans();
            } else if (data.data.run == true) this.performRefrigerantCheck(data.data);
        });

        return await modal.present();
    }

    performRefrigerantCheck(data) {
        this.status = statuses.New;
        this.refrigerantsProgress = 0;
        this.updateProgress(this.refrigerantsProgress, [{status: statuses.New.id, progress: this.refrigerantsProgress}]);

        let { outdoor_units } = data;
        let refrigerantChecksToCreate = [];
        let selectedODUs = outdoor_units.filter((oduDetail: OutdoorUnitDetails) => oduDetail.checked);

        selectedODUs.forEach(odu => {
            // The RefrigerantCheckRequest Class is Extended from MaintenanceCommonRequest Class
            // The MaintenanceCommonRequest Class requires some of these fields, so instead of removing them from there
            // We are just modifying the object here at the moment of making the request
            let newRefrigerantCheckRequest = new RefrigerantCheckRequest();
            newRefrigerantCheckRequest.setBaseIds(odu.gateway_id, this.user.active.id);
            newRefrigerantCheckRequest.setData(odu, 80, odu);

            newRefrigerantCheckRequest.address = newRefrigerantCheckRequest.address.toString();
            newRefrigerantCheckRequest.loopNumber = newRefrigerantCheckRequest.loopNumber.toString();
            // newRefrigerantCheckRequest.unitSerialNo = newRefrigerantCheckRequest.unitSerialNo.toString();

            delete newRefrigerantCheckRequest.managementTableId; // The management table id is not needed in the validate schema
            delete newRefrigerantCheckRequest.unitSerialNo;
            delete newRefrigerantCheckRequest.modelName;

            refrigerantChecksToCreate.push(newRefrigerantCheckRequest);
        })

        devEnv && console.log(`Start ${this.simulate ? `Simulated` : `Real` } ${this.title}`, refrigerantChecksToCreate);

        if (this.simulate) {
            this.simulateMaintJob();
        } else {
            if (refrigerantChecksToCreate.length > 0) {
                refrigerantChecksToCreate.forEach(rcReq => {
                    this.siteService.createRefrigerantCheck(rcReq).subscribe((result) => {
                        if (result && `error` in result) {
                            console.log(`Error`, result);
                        } else {
                            console.log(`Recent ${this.title}(s)`, this.getRecentRefrigerantChecks());
                        }
                    }, (error) => {
                        this.status = statuses.Error;
                        this.refrigerantsProgress = 1;
                        console.log(`Create ${this.title} Error`, error);
                    });
                })
            }
        }
    }

    updateProgress(progressInput?: any, jobs = this.recentRefrigerantChecks) {
        let progressData;
        let showRealProgress = jobs && jobs.length > 0;
        let progressToTrack = showRealProgress ? jobs.filter(job => job.progress >= 0) : [];
        let showSimulatedProgress = this.simulate && this.rcReports && this.rcReports.length > 0 && (this.rcReports[0].status > 0 || this.rcReports[0].progress > 0);

        let socketProgress = 0;
        let totalJobProgress = progressToTrack && progressToTrack?.length > 0 ? progressToTrack.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0) : 0;
        let progress = showRealProgress ? (totalJobProgress > 1 ? 1 : totalJobProgress) : progressData?.progress ?? 0;
        
        if (showRealProgress && this.socketResult != undefined) {
            let jobIsRLD = jobs.map(job => job.id).includes(this.socketResult?.managementTableId);
            if (jobIsRLD) {
                socketProgress = this.socketResult.progress / 100;
                progress = socketProgress > this.refrigerantsProgress ? socketProgress : this.refrigerantsProgress;
                jobs = jobs.map(job => job.id == this.socketResult?.managementTableId ? ({ ...job, progress: progress, status: job.status != statuses.Cancel.id ? this.socketResult?.maintJobStatus : job.status }) : job);
                this.recentRefrigerantChecks = jobs;
            }
        };

        if (showSimulatedProgress) {
            progressData = this.getCardDisplayData(this.rcReports);
            devEnv && console.log(`${this.title} Simulated Progress`, progressData);
        } else if (showRealProgress) {
            progressData = this.getCardDisplayData(jobs);
        } else {
            if (progressData != undefined) devEnv && console.log(`${this.title} State`, progressData);
        }

        progress = progress > this.refrigerantsProgress ? progress : this.refrigerantsProgress;
        if (progress >= 1) this.status = statuses.Complete;

        if (progressData && progressData?.jobStatus == maintenanceJobStatusEnum.new) {
            this.status = statuses.New;
            progress = progressData?.progress;
        } else if (progressData && progressData?.jobStatus == maintenanceJobStatusEnum.booting) {
            this.status = statuses.Booting;
            progress = socketProgress;
        } else if (progressData && progressData?.jobStatus == maintenanceJobStatusEnum.running) {
            this.status = statuses.Running;
            progress = socketProgress;
        }

        // Get Progress AVG
        let activeJobs = jobs && jobs.length > 0 ? jobs.filter((job: miniMaintJob) => job.status == maintenanceJobStatusEnum.running) : [];
        let progressTotal = activeJobs && activeJobs.length > 0 ? activeJobs.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0) : 0;
        let progressAVG = progressTotal > 0 ? (progressTotal / activeJobs.length) : 0;
        
        // Use Progress AVG of ALL Jobs
        let progresses = [progress, progressAVG, socketProgress].filter(progVal => progVal > 0).sort();
        let progressToUse = progresses[0] >= this.refrigerantsProgress ? progresses[0] : this.refrigerantsProgress;
        progressToUse = progressToUse > 1 ? progressToUse / 100 : progressToUse;

        let progressLabel = this.progressLoadingMessage;
        let cleanedProgress = (prog) => this.simulate ? globalFunctions?.removeTrailingZeroDecimal(prog * 100, 1, true, true) 
                                                      : globalFunctions?.removeTrailingZeroDecimal(prog * 100);
        let cleanProgress = progressInput ? cleanedProgress(progressInput) : cleanedProgress(progressToUse);
        
        let progressIsActive = this.status == statuses.Running;
        let progressStateLabel = progressData?.stateMessage ?? progressLabel;
        let amountOfJobs = activeJobs.length > 0 ? activeJobs.length + ` ` : ``;
        let progressUpdateLabel = progressIsActive ? ` ${cleanProgress}% Complete` : ``;
        let progressTitle = `${amountOfJobs}${this.shortTitle}(s) ${progressStateLabel}${progressUpdateLabel}`;

        this.setCardStatus();

        this.refrigerantsProgress = progressToUse;
        this.progressUpdateMessage = progressTitle;

        // if (progressIsActive) {
        //     devEnv && console.log(`${this.shortTitle} Progress Update`, {
        //         componentData: {
        //             jobs,
        //             status: this.status,
        //             socketResult: this.socketResult,
        //             progressUpdateMessage: this.progressUpdateMessage,
        //         },
        //         progressData: {
        //             progress,
        //             progresses,
        //             activeJobs,
        //             progressAVG,
        //             cleanProgress,
        //             progressTitle,
        //             progressToUse,
        //             socketProgress,
        //         },
        //         extraData: {
        //             progressData,
        //             progressCleaned: globalFunctions?.removeTrailingZeroDecimal(progress * 100),
        //             socketProgressCleaned: globalFunctions?.removeTrailingZeroDecimal(socketProgress * 100),
        //             progressAVGCleaned: globalFunctions?.removeTrailingZeroDecimal(parseFloat(progressAVG.toString()) >= 1 ? progressAVG * 100 : progressAVG),
        //         },
        //     });
        // }
    }
}