<ion-content main-background>
  <ion-toolbar color="light">
    <ion-title color="dark">
      <h1 id="header" name="header">Settings</h1>
    </ion-title>
  </ion-toolbar>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-card me-ion-card id="cardSelectUnit" name="cardSelectUnit">
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Target Equipment</h4>
            </ion-title>
          </ion-item>
          <div class="ou-table">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="Checkbox">
                  <th mat-header-cell *matHeaderCellDef class="col-select"> Select </th>
                  <td mat-cell *matCellDef="let element" class="col-select">
                    <input type="checkbox" id="checkbox" name="checkbox_tr" [checked]="element.Checked" (change)="checkboxCount()">
                  </td>
                </ng-container>

                <ng-container matColumnDef="SystemName">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> System Name </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.SystemName}} </td>
                </ng-container>

                <ng-container matColumnDef="ModelName">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Model Name </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.ModelName}} </td>
                </ng-container>

                <ng-container matColumnDef="SerialNo">
                  <th mat-header-cell *matHeaderCellDef class="col-width"> Serial No. </th>
                  <td mat-cell *matCellDef="let element" class="col-width"> {{element.SerialNo}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-card class="ion-no-margin" me-ion-card>
          <ion-item lines="none">
            <ion-title class="ion-no-padding">
              <h4>Settings</h4>
            </ion-title>
            <div class="ion-icon-wrapper-card-header">
              <ion-button id="save" class="button-style" (click)="startTestRun()" disabled>
                Start
              </ion-button>
              <ion-button id="back" class="button-style" (click)="backToTestRunTop()">
                Back
              </ion-button>
            </div>
          </ion-item>

          <ion-card-content color="white" me-top-border class="ion-no-padding">
            <ion-grid class="ion-no-padding">

              <ion-row>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                  <ion-toolbar>
                    <ion-title slot="start" id="titleArea1" class="me-machinelist-title-area">
                      Ope mode:
                    </ion-title>
                    <ion-buttons slot="secondary">
                      <ion-button id="btnSelectMode" name="btnSelectMode" (click)="onSelectMode()">
                        {{selectedMode}}
                        <ion-icon class="me-control-drop-down-arrow" name="caret-down-sharp">
                        </ion-icon>
                      </ion-button>    
                    </ion-buttons>
                  </ion-toolbar>
                </ion-col>
                <ion-col>
                  <div *ngIf="(selectedMode=='Auto' && degreeKind == 1)" class="notes">
                    Units are operated in the cooling mode when the outside temperature is 15°C or above, <br>
                    and in the heating mode when the outside temperature is below 15°C.<br>
                    Auto mode does not support PQHY and PQRY models.<br>
                    Please select the mode manually.<br>
                    If you select Auto mode for the PQHY or PQRY models, the test run will be performed in heating mode.
                  </div>     
                  <div *ngIf="(selectedMode=='Auto' && degreeKind == 2)" class="notes">
                    Units are operated in the cooling mode when the outside temperature is 59°F or above, <br>
                    and in the heating mode when the outside temperature is below 59°F.<br>
                    Auto mode does not support PQHY and PQRY models.<br>
                    Please select the mode manually.<br>
                    If you select Auto mode for the PQHY or PQRY models, the test run will be performed in heating mode.
                  </div>     
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size="12">
                  <ion-toolbar>
                    <ion-title slot="start" id="titleArea2" name="titleArea" class="me-machinelist-title-area">
                      Test run time:
                    </ion-title>
                    <ion-buttons slot="secondary">
                      <ion-button id="btnSelectMode" name="btnSelectMode" (click)="onSelectTime()">
                        {{selectedTime}}
                        <ion-icon class="me-control-drop-down-arrow" name="caret-down-sharp">
                        </ion-icon>
                      </ion-button>         
                    </ion-buttons>
                  </ion-toolbar>
                </ion-col>
                <ion-col>
                  <div class="notes">
                    Depending on communication conditions, the test run may take a longer time.
                  </div>      
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>


        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>