import { EventEmitter } from '@angular/core';
import { Gateway, GatewayWithGroups } from '../../manage/components/classes/Gateway';
import { SiteService } from '../../sites/services/site.service';

// A class for exchanging OutdoorUnit's information with APIServer

export class OutdoorUnit {
    unitIndex: number;
    unitAddress: string;
    unitSerialNo: string;
    gatewayId: string;
    gatewaySerialNo: string;
    gatewayDeviceType: string;
    gatewayGatewayName: string;
    systemName: string;
    modelName: string;
    unitId: string;
    supported: boolean;
    managementTableId: string;
    siteId: string;

    constructor() {
        this.unitIndex = 0;
        this.unitAddress = '-';
        this.unitId = '-';
        this.unitSerialNo = '-';
        this.gatewayId = '-';
        this.gatewaySerialNo = '-';
        this.gatewayDeviceType = '-'
        this.gatewayGatewayName = '-'
        this.systemName = '-';
        this.modelName = '-';
        this.supported = false;
        this.managementTableId = '-';
        this.siteId = '-'
    }

    createUnitData(gwg: Gateway, index: number){
        // Assuming only one outdoor unit is registered
        this.gatewayId = gwg.id;
        this.systemName = gwg.outdoor_units[index].name;
        this.unitAddress = gwg.outdoor_units[index].bus_address;
        this.unitId = gwg.outdoor_units[index].id;
        this.gatewaySerialNo = gwg.serial_number;
        this.gatewayDeviceType = gwg.model.name;
        this.gatewayGatewayName = gwg.name;
        this.siteId = gwg.site_id;
    }

    setDetailData(index: number, detailData: any) {
        this.unitIndex = index;
        if ('modelName' in detailData && detailData.modelName !== null) {
            this.modelName = detailData.modelName;
        }

        if ('serialNo' in detailData && detailData.serialNo !== null) {
            this.unitSerialNo = detailData.serialNo;
        }
    }

    setFunctionsupported(detailData: any) {
        if ('isValid' in detailData && detailData.isValid !== null) {
            this.supported = detailData.isValid;
        }
    }

    copyData(target: OutdoorUnit) {
        this.unitIndex = target.unitIndex;
        this.unitAddress = target.unitAddress;
        this.unitSerialNo = target.unitSerialNo;
        this.gatewayId = target.gatewayId;
        this.gatewaySerialNo = target.gatewaySerialNo;
        this.systemName = target.systemName;
        this.modelName = target.modelName;
        this.siteId = target.siteId;
    }

    setDBIds(managementTableId: string) {
        this.managementTableId = managementTableId;
    }
}

export type OutdoorRmd = {
    gatewaySerialNo: string;
    gatewayDeviceType: string;
    gatewayGatewayName: string;
    unitIndex: number;
}

export class OutdoorUnitList {
    unitList: OutdoorUnit[];
    rmdList: OutdoorRmd[];
    notification = new EventEmitter<any>();
    unitNum: number;
    testType: TestType;


    constructor(siteService: SiteService, userActiveId: string, testType: TestType) {
        this.unitList = [];
        this.rmdList = [];
        this.unitNum = 0;
        this.createUnitList(siteService, userActiveId);
        this.testType = testType;
    }

    createUnitList(siteService: SiteService, userActiveId: string) {
        siteService.get_site_gateways_with_groups(userActiveId, true).subscribe(
            (gateways: GatewayWithGroups) => {
                this.getGatewaysInfo(gateways);
                this.getDetailInfo(siteService);
            },
            () => {
                // Since the unit list could not be acquired,
                // subsequent communication for each unit will not be performed.
                console.error("Failed to get unit list");
                this.notification.emit("communication Failed");
            });
    }

    private getGatewaysInfo(gateways: GatewayWithGroups) {
        let gateways_with_groups: Gateway[] = [];
        if (gateways && 'gateways' in gateways) {
            gateways_with_groups = gateways['gateways'] as Gateway[];
            gateways_with_groups.map(gwg => {
                this.addOutdoorUnitData(gwg)
            });
        }
    }

    private addOutdoorUnitData(gwg: Gateway) {
        const outdoor_units = gwg.outdoor_units;
        if (gwg.connection.connected === 'True' && outdoor_units.length > 0) {
            for (let i = 0; i < outdoor_units.length; i++) {
                const unitData = new OutdoorUnit();
                unitData.createUnitData(gwg,i);
                this.unitList.push(unitData);
            }
        }
    }

    private getDetailInfo(siteService: SiteService) {
        this.unitNum = 0;
        for (const index in this.unitList) {
            siteService.getMachineDetail(this.unitList[index].gatewayId, this.unitList[index].unitAddress).subscribe(
                (detailData: any) => {
                    if (detailData) {
                        this.unitList[index].setDetailData(Number(index), detailData);
                    }
                    this.getUniqueFunction(siteService, index);
                },
                () => {
                    // There is a high possibility of communication disconnection,
                    // but it may be possible to obtain the presence or absence of support, so request
                    console.error("Failed to get model name");
                    this.getUniqueFunction(siteService, index);
                });
        }
    }

    private getUniqueFunction(siteService: SiteService, index: string) {
        switch (this.testType) {
            case TestType['bcPort']:
                siteService.getUnitIncorrectportconnectionsetting(this.unitList[index].unitId, this.unitList[index].siteId).subscribe(
                    (detailData: any) => {
                        if (detailData) {
                            this.unitList[index].setFunctionsupported(detailData);
                        }
                        this.confirmed();
                    },
                    () => {
                        console.error("Failed to get support availability");
                        this.confirmed();
                    });
                break;
            case TestType['refrigerant']:
                siteService.getUnitRefrigerant(this.unitList[index].unitId, this.unitList[index].siteId).subscribe(
                    (detailData: any) => {
                        if (detailData) {
                            this.unitList[index].setFunctionsupported(detailData);
                        }
                        this.confirmed();
                    },
                    () => {
                        console.error("Failed to get support availability");
                        this.confirmed();
                    });
                break;

            default:
                this.confirmed();
                break;
        }
    }

    private confirmed() {
        this.unitNum++;
        if (this.unitNum == this.unitList.length) {
            // A unique event is issued because changes in the array of attributes cannot be detected on the screen side.
            // Notify when all data acquisition is complete
            this.notification.emit("communication finished");
        }
    }

    createRmdList() {
        for (const unit of this.unitList) {
            if (unit.supported) {
                const ret = this.deduplication(unit);
                if (ret) {
                    this.rmdList.push(unit);
                }
            }
        }
    }

    private deduplication(unit: OutdoorUnit) {
        let ret = true;
        for (const index in this.rmdList) {
            if (this.rmdList[index].gatewayDeviceType === unit.gatewayDeviceType
                && this.rmdList[index].gatewayGatewayName === unit.gatewayGatewayName && this.rmdList[index].gatewaySerialNo === unit.gatewaySerialNo) {
                ret = false;
            }
        }
        return ret
    }

    filteredSelectUnit(target: OutdoorUnit) {
        const filtered = this.unitList.filter(unitList =>
          unitList.gatewayGatewayName === target.gatewayGatewayName
          && unitList.gatewayDeviceType === target.gatewayDeviceType
          && unitList.gatewaySerialNo === target.gatewaySerialNo
        );
        return filtered;
    }

}

export enum TestType {
    bcPort,
    refrigerant,
    mtdz,
    testRun,
    totalTestNum,
}
