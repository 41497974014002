<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Map Units</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid id="i_grid_map_units" name="i_grid_map_units">
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-top: 10px">
          <ion-label color="danger" id="i_label_map_unit_message" name="i_label_map_unit_message">
            You are mapping units connected to your gateway:
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center" style="padding-left: 10px">
          <ion-label>
            <strong>
            </strong>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div style="padding-top: 10px;padding-left: 10px;">
          <ion-label id="i_label_deactivate_message" name="i_label_deactivate_message">
            Before starting the mapping process, note the following:
          </ion-label>
          <ul>
            <li>
              Verify your equipment is powered, and the gateway is properly connected.
            </li>
            <li>
              Unit M-NET addressing will be automatically determined by the physical address set on your equipment, and
              associated with the outdoor unit according to the M-NET addressing rules.
            </li>
            <li>
              This process can take over ten minutes.
            </li>
            <li>
              If you have recently powered on this gateway or any connected units please wait at least 20 minutes before attempting
              to map units.
            </li>
            <li>
              Once units are mapped, confirm all units have been properly discovered.
            </li>
          </ul>
        </div>

      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button slot="end" id="i_btn_map_units" name="i_btn_map_units" (click)="map_units()" color="primary"
      style="margin: 0 0 0 1em;">
      Map
    </ion-button>
    <ion-button slot="end" id="i_btn_ok_cancel" name="i_btn_ok_cancel" (click)="close()" color="reverse-primary"
      style="margin: 0 1em 0 1em;">
      Cancel
    </ion-button>
  </ion-toolbar>
</ion-footer>