import * as moment from 'moment-timezone';
import { ModalController } from '@ionic/angular';
import { States } from 'src/app/constants/states';
import { LoadingController } from '@ionic/angular';
import { ModelState } from 'src/app/enumerations/enums';
import { Component, OnInit, Input } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { Site } from 'src/app/features/sites/classes/site';
import { CustomValidators } from '../../../../constants/validators';
import { globalFunctions } from 'src/app/constants/globalFunctions';
import { UserService } from 'src/app/common/services/user/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { defaultTimeZone, devEnv, siteTimezones } from 'src/app/constants/kenzaconstants';

@Component({
  selector: 'app-site-admin',
  templateUrl: './site-admin.page.html',
  styleUrls: ['./site-admin.page.scss'],
})

export class SiteAdminPage implements OnInit {
  @Input() parentModelState: ModelState;
  @Input() parentSelectedSite: Site;
  tz: any;
  tzTime: any;
  selectedSite: Site;
  siteAdminPageTitle = '';
  siteAdminForm: UntypedFormGroup;
  timezoneChangeVal: any;
  adminButtonText = '';
  stateList: any[] = [];
  success = true;
  displayErrorMessage = '';
  saveEnabled = false;
  trim: (any) = CustomValidators.trim;
  detectFormChanges: (any) = globalFunctions.detectFormChanges;
  defaultTimeZone = defaultTimeZone;
  siteTimezones = siteTimezones;
  useMaskito = false;
  devEnv = devEnv;

  constructor(
    private user: UserService,
    private siteService: SiteService,
    private loading: LoadingController,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    public mainSiteUIService: MainSiteUIService,
  ) {
    // Empty
  }

  ngOnInit() {
    this.stateList = States.list;
    this.tz = moment.tz(`America/New_York`).format(`z`);
    this.tzTime = moment.tz(`America/New_York`).format(`h:mm A`);
    this.createSiteAdminForm();

    switch (this.parentModelState) {
      case ModelState.Create:
        this.siteAdminPageTitle = 'Create a New Site';
        this.adminButtonText = 'Create';
        this.siteAdminForm.patchValue({ timezone: defaultTimeZone });
        break;
      case ModelState.Update:
        this.siteAdminPageTitle = 'Edit Site Information';
        this.adminButtonText = 'Save';
        this.selectedSite = this.parentSelectedSite;
        this.initializeSiteAdminForm(this.selectedSite);
        break;
    }
    this.detectFormChanges(this.siteAdminForm);
  }

  clearErrorMessage(event) {
    this.displayErrorMessage = ``;
  }

  createSiteAdminForm() {
    this.siteAdminForm = this.formBuilder.group({
      id: [''],
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/(\S+\s*){3,}/)
        ]),
      ],
      phone: [
        '',
        Validators.compose([
          CustomValidators.phoneMinLength,
          Validators.maxLength(28),
        ]),
      ],
      company: [
        '',
        Validators.compose([Validators.pattern(/(\S+\s*){2,}/)]),
      ],
      addressOne: [
        '',
        Validators.compose([Validators.required, Validators.pattern(/(\S+\s*){3,}/)]),
      ],
      addressTwo: [
        '',
        Validators.compose([Validators.minLength(5), Validators.maxLength(50)]),
      ],
      city: [
        '',
        Validators.compose([Validators.required, Validators.pattern(/(\S+\s*){2,}/)]),
      ],
      state: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      zip: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^\d{5}$|^\d{5}-$|^\d{5}-[_]{4}$|^\d{5}-\d{4}$/),
          Validators.minLength(5),
          Validators.maxLength(10),
        ]),
      ],
      country: [''],
      timezone: [
        'America/New_York',
        Validators.compose([
          Validators.required,
        ]),
      ]
    });
  }

  initializeSiteAdminForm(site: Site) {
    this.siteAdminForm.patchValue({
      id: site.id,
      name: site.name ? site.name : '',
      phone: site.phone ? site.phone : '',
      addressOne: site.addressOne ? site.addressOne : '',
      addressTwo: site.addressTwo ? site.addressTwo : '',
      city: site.city ? site.city : '',
      state: site.state ? site.state : '',
      zip: site.zip ? site.zip : '',
      country: site.country ? site.country : '',
      company: site.company ? site.company : '',
      timezone: site.timezone ? site.timezone : defaultTimeZone,
    });
  }

  onCancel() {
    this.modalController.dismiss({
      modelState: ModelState.None,
      adminSite: null,
    });
  }

  mapSiteAdminFormToSite(): Site {
    const mappedSite: Site = new Site({
      id: this.siteAdminForm.get('id').value,
      name: this.siteAdminForm.get('name').value,
      phone: this.siteAdminForm.get('phone').value,
      addressOne: this.siteAdminForm.get('addressOne').value,
      addressTwo: this.siteAdminForm.get('addressTwo').value,
      city: this.siteAdminForm.get('city').value,
      state: this.siteAdminForm.get('state').value,
      zip: this.siteAdminForm.get('zip').value.replace(/-+_*$/g, ''),
      country: this.siteAdminForm.get('country').value,
      company: this.siteAdminForm.get('company').value,
      timezone: this.siteAdminForm.get('timezone').value,
      site_photo_name: '',
      sitestatustype_id: 1,
      current_owner_id: '',
      locations: [],
    });
    return mappedSite;
  }

  async onSubmit(submitBtn: HTMLButtonElement) {
    if (!this.siteService.handleIsConnected()) return;

    if (this.siteAdminForm.valid) {
      submitBtn.disabled = true;

      const load = await this.loading.create({
        spinner: 'lines',
        message:
          this.parentModelState == ModelState.Create
            ? 'Creating site...'
            : 'Saving site information...',
      });

      load.present();

      this.selectedSite = this.mapSiteAdminFormToSite();

      switch (this.parentModelState) {
        case ModelState.Create:
          this.user
            .createSite({
              name: this.selectedSite.name,
              phone: this.selectedSite.phone,
              company: this.selectedSite.company,
              street_line1: this.selectedSite.addressOne,
              street_line2: this.selectedSite.addressTwo,
              city: this.selectedSite.city,
              state: this.selectedSite.state,
              postal_code: this.selectedSite.zip,
              timezone: this.selectedSite.timezone,
              country: '',
              sitestatustype_id: this.selectedSite.sitestatustype_id,
            })
            .subscribe(
              (data) => {
                this.success = true;
                this.displayErrorMessage = '';
                //this.user.clearSites();
                this.user.updateSites();
                load.dismiss();
              },
              (error) => {
                load.dismiss();
                this.displayErrorMessage = error.error;
                this.success = false;
              },
              () => {
                this.modalController.dismiss({
                  modelState: ModelState.Created,
                  adminSite: this.selectedSite,
                });
              }
            );
          break;

        case ModelState.Update:
          this.user
            .updateSite({
              id: this.selectedSite.id,
              name: this.selectedSite.name,
              phone: this.selectedSite.phone,
              company: this.selectedSite.company,
              street_line1: this.selectedSite.addressOne,
              street_line2: this.selectedSite.addressTwo,
              city: this.selectedSite.city,
              state: this.selectedSite.state,
              postal_code: this.selectedSite.zip,
              timezone: this.selectedSite.timezone,
              country: '',
              sitestatustype_id: 1,
            })
            .subscribe(
              (data) => {
                this.success = true;
                localStorage.removeItem(`ZipWeatherCache`);
                this.siteService.siteEdited.emit({ type: `Refresh Weather`, user: this.user, active: new Site(data) });
                this.user.clearSites();
                this.user.updateSites();
                this.user.setActiveSite(this.selectedSite, false);
                load.dismiss();
              },
              (error) => {
                load.dismiss();
                this.displayErrorMessage = error.error;
                this.success = false;
                // dismiss model if critical site error
                if (error.message.includes("kenza rule")) this.modalController.dismiss({ modelState: ModelState.None });
              },
              () => {
                this.modalController.dismiss({
                  modelState: ModelState.Updated,
                  adminSite: this.selectedSite,
                });
              }
            );
          break;
      }
    }
  }
}