/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { UserService } from 'src/app/common/services/user/user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { MainSiteUIService } from 'src/app/common/services/ui/main-site-ui.service';
import { CustomValidators } from 'src/app/constants/validators';
import { SiteService } from 'src/app/features/sites/services/site.service';
import { Gateway } from 'src/app/features/manage/components/classes/Gateway';
import { devEnv } from 'src/app/constants/kenzaconstants';
import { GatewaySubscriptionStatusTypeEnum } from 'src/app/enumerations/enums';

@Component({
  selector: 'app-site-transfer',
  templateUrl: './site-transfer-modal.page.html',
  styleUrls: ['./site-transfer-modal.page.scss']
})
export class SiteTransferModal implements OnInit {

  currentStep: number;
  steps: Array<TransferStep> = [];
  emailForm: UntypedFormGroup;
  confirmForm: UntypedFormGroup;

  emailAddr: string;

  emailConfirmFieldWasTouched = false;

  loadMain;

  gateways: Array<GatewayStub> = [];
  gatewaysWithCoupons: Array<GatewayStub> = [];
  gatewaysWithLicenses: Array<GatewayStub> = [];

  devEnv = devEnv;
  alertOpen = false;
  @Input() modalOpen: boolean;

  constructor(
    private user: UserService,
    public formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private alertController: AlertController,
    public loadingController: LoadingController,
    public mainSiteUIService: MainSiteUIService,
    private siteService: SiteService,
  ) {

    this.emailForm = formBuilder.group({
      email1: ['', Validators.compose([
        Validators.required,
        CustomValidators.validateEmail,
        Validators.email,
        Validators.maxLength(50),
        this.emailNotOwner
      ])],
      email2: ['', Validators.compose([
        Validators.required,
        // CustomValidators.validateEmail,
        Validators.email,
        Validators.maxLength(50),
        CustomValidators.emailsDontMatch,
      ])],
      username: [user.username]
    });

    this.confirmForm = formBuilder.group({
      transferSite: [false, Validators.required]
    });

    this.makeSteps();
  }

  emailNotOwner(form) {
    try {
      if (form && form.parent) {
        const email1 = form.parent.get('email1').value;
        const username = form.parent.get('username').value;
        if (email1 && username) {
          if (email1 == username) {
            return { 'emailMatchesOwner': true };
          } 
        }
      }
    } catch {
      // do nothing
    }
    return null;
  }

  ngOnInit() {
    this.currentStep = 0;

    this.siteService.getSiteDetail(this.user.active.id).then((siteInfo) => {
      const siteGateways = siteInfo['gateways'];
      for(let i = 0; i < siteGateways.length; i++) {
        const gateway: Gateway = siteGateways[i];
        let activeSub = null;
        const subscriptionObjects = gateway['gatewaysubscription'];

        for(let j = 0; j < subscriptionObjects.length; j++) { // trying to figure out the active sub, i hate how this works...
          const gwSub = subscriptionObjects[j];
          // the options are
          // if the gateway_subscription_status_type_id is ACTIVE then this is the one.
          // if the gateway_subscrtipion_status_type_id is EXPIRED and
          //    the expires_at is NONE - then ignore this.
          //    the expires_at is a Date and we are after this date - then ignore this.
          //    the expires_at is a Date and we are = or before this date - then THIS is the active.
          const subscriptionStatusType: GatewaySubscriptionStatusTypeEnum = gwSub.gateway_subscription_status_type_id;
          const expires_at=gwSub.expires_at
          if (subscriptionStatusType == GatewaySubscriptionStatusTypeEnum.Expired) {
            if (expires_at) {
              const expiresAtDate = new Date(expires_at)
              // then in all cases take it.
              if (new Date() < expiresAtDate ) {
                activeSub = gwSub
              }
            }
            // otherwise only take if its active and we dont have anything else
          } else if ( (subscriptionStatusType == GatewaySubscriptionStatusTypeEnum.Active || 
              subscriptionStatusType == GatewaySubscriptionStatusTypeEnum.Suspended) && 
              !activeSub ) {
            activeSub = gwSub
          }
        }

        const stub = new GatewayStub(
          gateway.name, 
          gateway.serial_number, 
          gateway.coupons, 
          gateway.licenses,
          activeSub.subscriptions.name,
          activeSub.subscriptions.monthly_rate,
          gateway.active_sub['is_expired']
        );
        if (stub.coupons.length > 0) this.gatewaysWithCoupons.push(stub);
        if (stub.licenses.length > 0) this.gatewaysWithLicenses.push(stub);
        this.gateways.push(stub);
      }
    });

  }

  ionViewWillEnter() {
    this.currentStep = 0; // safty step to reset this on enter, incase of caching
  }

  makeSteps() {
    let title: string, nextBtnEnabled: boolean = null;

    let nextBtnText = "Next";
    const prevBtnText = "Back";

    // Step 0 state message
    title = "Transfer Site Ownership"
    nextBtnEnabled = true;
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, false, prevBtnText))

    // Step 1
    title = "Transfer Site Ownership (Step 1 of 3)"
    nextBtnEnabled = false;
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, true, prevBtnText));

    // Step 2
    title = "Transfer Site Ownership (Step 2 of 3)"
    nextBtnEnabled = false;
    nextBtnText = "Transfer";
    this.steps.push(new TransferStep(title, nextBtnEnabled, nextBtnText, true, prevBtnText));

    // Step 3
    title = "Transfer Site Ownership (Step 3 of 3)"
    this.steps.push(new TransferStep(title, null, null, false, null));
  
  }

  async presentConfirmCancel() {
    if (this.alertOpen == true) return;
    const confirmCancel = await this.alertController.create({
      id: `confirmCancel`,
      backdropDismiss: false,
      cssClass: `alertMsg me-info-button-css`,
      header: `Cancel Transfer Site Ownership`,
      subHeader: `Are you sure you want to cancel?`,
      message: `If you cancel, your information will not be saved.`,
      buttons: [{
        text: `Yes`, role: `ok`, id: `cancelTransferSiteSenderFormYesButton`, cssClass: `ok-button slimButton cancelTransferSiteSenderFormYesButton iBtnCancelTransferSiteSenderFormYes automationTesting`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
          this.modalController.dismiss().then(modalData => {
            this.modalOpen = false;
          })
        })
      }, {
        text: `No`, role: `cancel`, id: `cancelTransferSiteSenderFormNoButton`, cssClass: `ok-button cancel-button slimButton cancelTransferSiteSenderFormNoButton iBtnCancelTransferSiteSenderFormNo automationTesting`, handler: () => this.alertController.dismiss().then(data => {
          this.alertOpen = false;
        })
      }]
    });
    this.alertOpen = true;
    return await confirmCancel.present();
  }

  onCancel() {
    if (this.currentStep != 3) {
      this.presentConfirmCancel();
    } else {
      this.modalController.dismiss().then(data => {
        this.modalOpen = false;
      })
    }
  }

  nextStep() {
    if(this.currentStep == 1) { // between 1 and 2
      this.steps[this.currentStep + 1].nextBtnEnabled = false; // handle prev then next again
    }
    if(this.currentStep == 2) { // between 2 and 3
      this.sendTransferEmail();
      return;
    }

    this.currentStep += 1;

  }

  prevStep() {
    if(this.currentStep == 1) { // clear the form
      this.emailForm.controls.email1.reset();
      this.emailForm.controls.email2.reset();
      this.steps[this.currentStep].nextBtnEnabled = false;
    }

    if(this.currentStep == 2) {
      this.confirmForm.controls.transferSite.reset();
    }

    if (this.currentStep != 0) this.currentStep -= 1;
  }

  emailFieldUpdated() {
    if(this.emailConfirmFieldWasTouched == true) { // means the matching was confirmed but not anymore and needs a recheck
      this.emailForm.controls.email2.updateValueAndValidity();
      if(this.emailForm.controls.email2.valid && this.emailForm.controls.email1.valid) {
        this.steps[this.currentStep].nextBtnEnabled = true;
        this.emailAddr = this.emailForm.controls.email2.value;
      } else {
        this.steps[this.currentStep].nextBtnEnabled = false;
      }
    }
  }

  emailConfirmFieldUpdated() {
    if(this.emailForm.controls.email2.valid && this.emailForm.controls.email1.valid) {
      this.steps[this.currentStep].nextBtnEnabled = true;
      this.emailAddr = this.emailForm.controls.email2.value;
    } else {
      this.steps[this.currentStep].nextBtnEnabled = false;
    }
    this.emailConfirmFieldWasTouched = true;
  }

  transferConfirmed(event) {
    if(event.detail.checked) {
      this.steps[this.currentStep].nextBtnEnabled = true;
    } else {
      this.steps[this.currentStep].nextBtnEnabled = false;
    }
  }

  sendTransferEmail() {
    this.presentSpinner();
    this.siteService.transferSite(this.user.active.id, this.emailAddr).then(response => {
      this.user.updateSites();
      if (this.loadMain && this.loadMain.dismiss) {
        this.loadMain.dismiss();
        this.currentStep += 1;
      } 
    });
  }

  async presentSpinner() {
    this.loadMain = await this.loadingController.create({
      message: 'Processing site transfer request...',
      spinner: 'lines',
      duration: 10_000,
    });

    await this.loadMain.present();
  }

}

class TransferStep {
  title: string;
  nextBtnEnabled: boolean;
  nextBtnText: string;
  prevBtnShown: boolean;
  prevBtnText: string;

  constructor(title, nextBtnEnabled, nextBtnText, prevBtnShown, prevBtnText) {
    this.title = title;
    this.nextBtnEnabled = nextBtnEnabled;
    this.nextBtnText = nextBtnText;
    this.prevBtnShown = prevBtnShown;
    this.prevBtnText = prevBtnText;
  }
}

class GatewayStub {
  name: string;
  serial: string;
  coupons: any[];
  licenses: any[];
  subType: string;
  monthlyRate: number;
  isExpired: boolean

  constructor(name, serial, coupons, licenses, subType, monthlyRate, isExpired) {
    this.name = name;
    this.serial = serial;
    this.coupons = coupons;
    this.licenses = licenses;
    this.subType = subType;
    this.monthlyRate = monthlyRate;
    this.isExpired = isExpired;
  }
}