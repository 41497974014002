<ion-content main-background>
  <ion-toolbar color="dark" me-toolbar-filler class="ion-hide-md-up">
  </ion-toolbar>
  <ion-toolbar color="light">
    <ion-grid class="siteToolbarGrid">
      <ion-row>
        <ion-col class="sitePageNameCol" size="4">
          <ion-title color="dark">
            <h1 id="siteGatewayHeader" name="siteGatewayHeader">Site Gateways</h1>
          </ion-title>
        </ion-col>
        <ion-col class="siteWeatherInfoCol" size="4">
          <app-site-weather></app-site-weather>
        </ion-col>
        <ion-col class="siteRightCol" size="4">
          <ion-button (click)="user.active.transfer_locked ? user.presentActionRestricted() : registerGateway()"
            id="registerGatewayButton" name="registerGatewayButton"
            *ngIf="!user.active.transfer_locked && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.AddRemoveSiteGateway)"
           [class]="user.active.transfer_locked ? 'site-transfer-disable-modal' : ''" style="margin-right: 15px"
            [disabled]="(dataLoading && (siteGateways.length==0))">Register a Gateway</ion-button>
          <ion-button class="manageButton transferring"
            (click)="user.active.transfer_locked ? user.presentActionRestricted() : registerGateway()" id="registerGatewayButton"
            name="registerGatewayButton"
            *ngIf="user.active.transfer_locked && appAuth.doesLevelHavePermission(user.activeSiteUserLevel,appAuth.permissionEnums.AddRemoveSiteGateway)"
            [disabled]="(dataLoading && (siteGateways.length==0))">Register a Gateway</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-title class="stateMessage">
          <div class="loadingState" *ngIf="dataLoading && (siteGateways.length == 0)">
            <ion-label id="zeroStateLoading" name="zeroStateLoading"><i>Loading</i></ion-label>
            <ion-spinner name="dots" style="position:absolute; height:40px"></ion-spinner>
          </div>
          <ion-label id="zeroStateMessage" name="zeroStateMessage" color="medium" *ngIf="siteGateways.length==0 && !dataLoading">
            <i>There
              are no gateways currently registered to this site.</i>
          </ion-label>
        </ion-title>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <ion-grid>
    <ion-row>
      <ion-col size-xl="4" size-lg="6" size-md="12" size-sm="12" size="12"
        *ngFor="let gateway of siteGateways; trackBy: trackByGatewayId">
        <ion-card id="gateway" name="gateway" me-ion-card>
          <ion-item-group class="me-item-header">
            <ion-item lines="none" me-ion-card-header>
              <ion-thumbnail slot="start">
                <img *ngIf="gateway.model.class_name == gatewayModelClass.RMD" class="me-gateway-icon"
                  src="assets/imgs/gateways/rmd.svg">
                <img *ngIf="gateway.model.class_name == gatewayModelClass.MCC" class="me-gateway-icon"
                  src="assets/imgs/gateways/mcc.svg">
              </ion-thumbnail>
              <div style="width: 100%;">
                <ion-label title="{{ gateway.name }}" 
                  [ngClass]="hasPermissionForGatewayDetails()? 'me-gateway-name-label' : 'me-gateway-name-label-no-link'"
                  [routerLink]="['/manage', gateway.site_id, 'gateway', gateway.id]">
                  <strong>{{ gateway.name }}</strong>
                </ion-label>
                <div me-ion-card-sub-title>
                  <ion-label *ngIf="gateway.activeSubscription?.gateway_subscription_status_type_id != gsste.Suspended">
                    {{ gateway.activeSubscription?.subscriptions.name + ' Subscription'}}
                  </ion-label>
                  <ion-label *ngIf="gateway.activeSubscription?.gateway_subscription_status_type_id == gsste.Suspended" class="me-expired-limited-plan">
                    Expired Subscription
                  </ion-label>
                </div>
                <div *ngIf="gateway.activeSubscription?.gateway_subscription_status_type_id != gsste.Suspended" class="me-connect-status">
                  <ion-label class="connected" id="{{gateway.id}}_status" *ngIf="gateway?.connected">
                    <ion-icon class="me-connect-icon" name="cloud"></ion-icon> Online
                  </ion-label>
                  <ion-label class="disconnected" id="{{gateway.id}}_status" *ngIf="!gateway?.connected">
                    <ion-icon class="me-connect-icon" name="alert-circle-outline"></ion-icon> Disconnected
                  </ion-label>
                </div>
                <div *ngIf="gateway.activeSubscription?.gateway_subscription_status_type_id == gsste.Suspended && user.activeSiteUserLevel===LevelEnum.Owner">
                  <a class="me-upgrade-link" [routerLink]="['/account', user?.id, 'details', 'subscriptions']" [queryParams]="{upgrade: gateway.id}">Upgrade Subscription</a>
                </div>
              </div>
              <div class="ion-icon-wrapper-arrow-hide-show" slot="end" (click)="toggleIsGatewayExpanded(gateway)"
                [title]="getIsGatewayExpanded(gateway) ? 'Collapse' : 'Expand'">
                <ion-icon id="toggleGatewayCard"
                  [name]="getIsGatewayExpanded(gateway) ? 'caret-up-sharp' : 'caret-down-sharp'" class="me-toggle-icon">
                </ion-icon>
              </div>
            </ion-item>
          </ion-item-group>
          <ion-card-content me-site-gateway-card-content me-top-border *ngIf="getIsGatewayExpanded(gateway)">
            <div>
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="4"><strong>Device Type:</strong></ion-col>
                  <ion-label id="device_type" name="device_type">{{ gateway.model.name ? gateway.model.name : '' }}
                  </ion-label>
                </ion-row>
                <ion-row>
                  <ion-col size="4"><strong>Serial Number:</strong></ion-col>
                  <ion-label id="serial_number" name="serial_number">{{ gateway.serial_number
                    }} <i *ngIf="gateway.has_coupon">({{gateway.coupon_name}})</i></ion-label>
                </ion-row>
                <ion-row white-background *ngIf="gateway.model.class_name == gatewayModelClass.RMD">
                  <ion-col size="4"><strong>MAC Address:</strong></ion-col>
                  <ion-col>
                    <ion-label id="mac_address" name="mac_address">{{ gateway.mac_address }}</ion-label>
                  </ion-col>
                </ion-row>
                <ion-row white-background *ngIf="gateway.model.class_name == gatewayModelClass.RMD">
                  <ion-col size="4"><strong>Device Key:</strong></ion-col>
                  <ion-label id="device_key" name="device_key">{{ gateway.device_key }}</ion-label>
                </ion-row>
                <ion-row white-background *ngIf="gateway.model.class_name == gatewayModelClass.MCC">
                  <ion-col size="4"><strong>Registration Code:</strong></ion-col>
                  <ion-label id="registration_code" name="registration_code">{{ gateway.device_key }}</ion-label>
                </ion-row>

              </ion-grid>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <br>
  <br>
  <br>
  <br>
</ion-content>