import { SiteNotification } from '../../features/sites/components/site-alerts/classes/sitenotification';

// this is used by the site-control to display the scroll of units
export class SiteAlertMetricsData {
    // v2 

    // notification lists

    // full non-search reduced list
    v2RawSiteNotifications: SiteNotification[];
    v2RawActSiteNotifications: SiteNotification[];
    // text search reduced list
    v2SiteNotifications: SiteNotification[];

    // total raw counts - not reduced by text search
    v2RawTotalCount: number;
    v2RawTotalActCount: number;
    v2RawTotalAckCount: number;
    v2RawEquipmentActCount: number;
    v2RawMembershipActCount: number;
    v2RawGatewayActCount: number;
    v2RawPaymentActCount: number;
    v2RawEquipmentNotificationActCount: number;

    // text search reduced 

    // total number (length of v2SiteNotifications)
    v2TotalCount: number;

    // of v2TotalCount - number that are active  
    v2ActiveCount: number;
    // of v2TotalCount - number that are historical/acked
    v2AckedCount: number;

    // of v2TotalCount - number that are equipment alarms
    v2EquipmentCount: number;
    // of v2EquipmentCount - number that are Active
    v2EquipmentActCount: number;
    // of v2EquipmentCount - number that are Acked
    v2EquipmentAckCount: number;

    // of v2TotalCount - number that are membership notifications 
    v2MembershipCount: number;
    // of v2MembershipCount - number that are active
    v2MembershipActCount: number;
    // of v2MembershipCount - number that are acked
    v2MembershipAckCount: number;

    // of v2TotalCount - number that are Gateway notifications
    v2GatewayCount: number;
    // of v2GatewayCount - number that are Active
    v2GatewayActCount: number;
    // of v2GatewayCount - number that are Acked
    v2GatewayAckCount: number;

    // of v2TotalCount - number that are Payment notifications
    v2PaymentCount: number;
    // of v2PaymentCount - number that are Active
    v2PaymentActCount: number;
    // of v2PaymentCount - number that are Acked
    v2PaymentAckCount: number;

    // of v2TotalCount - number that are EquipmentNotification notifications
    v2EquipmentNotificationCount: number;
    // of v2EquipmezntNotificationCount - number that are Active
    v2EquipmentNotificationActCount: number;
    // of v2EquipmentNotificationCount - number that are Acked
    v2EquipmentNotificationAckCount: number;

    //based on text search and filter checkboxes - what is being displayed?
    v2DisplayedSiteNotifications: SiteNotification[];
    // number being displayed
    v2DisplayCount: number;

    // by units and gateways
    v2ActiveAlertsByUnits: Map<string, number>;
    v2ActiveNotificationsByGateways: Map<string, number>;

    constructor() {
        // wipe it
        this.clear();
    }

    clear() {
        // empty out the fields.
        this.v2RawSiteNotifications = [];
        this.v2SiteNotifications = [];
        this.v2DisplayedSiteNotifications = [];
        this.v2RawActSiteNotifications = [];

        this.v2RawTotalCount = 0;
        this.v2RawTotalActCount = 0;
        this.v2RawTotalAckCount = 0;
        this.v2RawEquipmentActCount = 0;
        this.v2RawMembershipActCount = 0;
        this.v2RawGatewayActCount = 0;
        this.v2RawPaymentActCount = 0;
        this.v2RawEquipmentNotificationActCount = 0;
        this.v2TotalCount = 0;
        this.v2ActiveCount = 0;
        this.v2AckedCount = 0;
        this.v2EquipmentCount = 0;
        this.v2EquipmentActCount = 0;
        this.v2EquipmentAckCount = 0;
        this.v2MembershipCount = 0;
        this.v2MembershipActCount = 0;
        this.v2MembershipAckCount = 0;
        this.v2GatewayCount = 0;
        this.v2GatewayActCount = 0;
        this.v2GatewayAckCount = 0;
        this.v2PaymentCount = 0;
        this.v2PaymentActCount = 0;
        this.v2PaymentAckCount = 0;
        this.v2EquipmentNotificationCount = 0;
        this.v2EquipmentNotificationActCount = 0;
        this.v2EquipmentNotificationAckCount = 0;
        this.v2DisplayCount = 0;
        this.v2ActiveAlertsByUnits = new Map<string, number>();
        this.v2ActiveNotificationsByGateways = new Map<string, number>();
    }

    assign(params: any) {
        // equate them all up.
        this.v2RawSiteNotifications = params.v2RawSiteNotifications;
        this.v2RawActSiteNotifications = params.v2RawActSiteNotifications;
        this.v2SiteNotifications = params.v2SiteNotifications;
        this.v2RawTotalCount = params.v2RawTotalCount;
        this.v2RawTotalActCount = params.v2RawTotalActCount;
        this.v2RawTotalAckCount = params.v2RawTotalAckCount;
        this.v2RawEquipmentActCount = params.v2RawEquipmentActCount;
        this.v2RawMembershipActCount = params.v2RawMembershipActCount;
        this.v2RawGatewayActCount = params.v2RawGatewayActCount;
        this.v2RawPaymentActCount = params.v2RawPaymentActCount;
        this.v2RawEquipmentNotificationActCount = params.v2RawEquipmentNotificationActCount;
        this.v2TotalCount = params.v2TotalCount;
        this.v2ActiveCount = params.v2ActiveCount;
        this.v2AckedCount = params.v2AckedCount;
        this.v2EquipmentActCount = params.v2EquipmentActCount;
        this.v2EquipmentAckCount = params.v2EquipmentAckCount;
        this.v2MembershipActCount = params.v2MembershipActCount;
        this.v2MembershipAckCount = params.v2MembershipAckCount;
        this.v2GatewayActCount = params.v2GatewayActCount;
        this.v2GatewayAckCount = params.v2GatewayAckCount;
        this.v2PaymentActCount = params.v2PaymentActCount;
        this.v2PaymentAckCount = params.v2PaymentAckCount;
        this.v2EquipmentNotificationActCount = params.v2EquipmentNotificationActCount;
        this.v2EquipmentNotificationAckCount = params.v2EquipmentNotificationAckCount;
        this.v2EquipmentCount = params.v2EquipmentCount;
        this.v2MembershipCount = params.v2MembershipCount;
        this.v2GatewayCount = params.v2GatewayCount;
        this.v2PaymentCount = params.v2PaymentCount;
        this.v2EquipmentNotificationCount = params.v2EquipmentNotificationCount;
        this.v2DisplayedSiteNotifications = params.v2DisplayedSiteNotifications;
        this.v2DisplayCount = params.v2DisplayCount;
        this.v2ActiveAlertsByUnits = params.v2ActiveAlertsByUnits;
        this.v2ActiveNotificationsByGateways = params.v2ActiveNotificationsByGateways;
    }

}

export class SiteNotificationCount {
    site_id: string;
    count_of_site_notifications: number;
}
