import { DisplayOptions } from "src/app/common/classes/displayOptions";
import { GatewayUnit } from "src/app/features/manage/components/classes/GatewayUnit";
import { ControlIgnorable } from "./controlIgnorable";

export class ControlSection {
    // represents a group of buttons that can be controlled.
    // i.e power, mode, fan speed etc.. 
    // for both coils and lossnays

    ignorable: ControlIgnorable // are we currently ignoring profile updates for this section?
                                // used when we've made a change to the setting in this section
                                // and want to ignore updates that arrive between our change
                                // and the reception of our change - to keep the control
                                // from visually swapping to something we didn't select while
                                // we wait for our desired change to occur and profile update 
                                // be received.
    expanded: boolean           // whether the section is expanded on the UI
                                // has no meaning for 'setTemp' section
    label:string                // the string to display with the section - optional
    label_icon: string          // the icon (or not) to displah beside the label text
    displayOptions:DisplayOptions// options for text and icons 

    constructor() {
        this.ignorable = new ControlIgnorable;
        this.expanded = false;
        this.label = '';
        this.label_icon = '';
        this.displayOptions = new DisplayOptions;
    }

    reset(hardReset: boolean) {
        // starting a new run on the display settings for this group
        
        if (hardReset) this.ignorable.reset();
        this.resetSectionCounters();
    }

    resetSectionCounters() {
        // this should be implemented in the child that extends this class
        throw new Error("Method not implemented.");
    }

    addGroupToBatch(unit: GatewayUnit, maintenanceJobActive: boolean) {
        // this should be implementd in the child taht extends this class
        throw new Error("Method not implemented.");
    }
}